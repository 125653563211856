import React, { Component } from 'react';
import '../custom.css';
import { Button, Breadcrumb, BreadcrumbItem, Form, Modal, FormGroup, OverlayTrigger, Tooltip, ControlLabel, FormControl } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import Header from './Header';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import 'braintree-web';
import DropIn from 'braintree-web-drop-in-react';
import UserProfile from './UserProfile';
import '../custom_braintree.css';
import PlacesAutocomplete, {
	geocodeByAddress
} from 'react-places-autocomplete';
import InputCheckboxComponent from './InputCheckboxComponent';
import InputBoxComponent from './InputBoxComponent';


// const BASE_URL = 'http://3.220.32.133:5000';

interface PCheckout {
	history?: Array<string>;
}

class SCheckout {
	constructor() {
	}
}

class Checkout extends Component<PCheckout, any> {

	validator: SimpleReactValidator = null;

	instance;

	constructor(props) {
		super(props);
		this.state = {
			first_name: '',
			last_name: '',
			business_name: '',
			email: '',
			zipcode: '',
			address: '',
			// payment_info: '',
			order_amount: 10,
			checkbox: false,
			terms1: false,
			order_id: null,
			clientToken: null,
			showPlaceSuccessModal: false,
			showupdateModal:true,
			//showNewRecordModal: true,
			showNewRecordModal: false,
			showDeleteListingModal: false,
			deleteRecId: '',
			showDeleteListingSuccessModal: false,

			shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,

			couponCodeAvailable: false
		}
		console.log(props)
		this.setFirstName = this.setFirstName.bind(this);
		this.setLastName = this.setLastName.bind(this);
		this.setBusinessName = this.setBusinessName.bind(this);
		this.setEmail = this.setEmail.bind(this);
		this.setZipcode = this.setZipcode.bind(this);
		this.setBillingAddress = this.setBillingAddress.bind(this);
		// this.setPaymentInfo = this.setPaymentInfo.bind(this);
		this.setTermsAndConditions = this.setTermsAndConditions.bind(this);
		this.proceedToPayment = this.proceedToPayment.bind(this);
		this.handleupdateModal = this.handleupdateModal.bind(this);
	}

	handlePlaceSuccessModal() {
		this.props.history.push('/my-listing');
	}

	async componentDidMount() {
		// console.log('this.props.location.state.order_amount');
		// console.log(this.props.location.state.order_amount);
		try {
			// Get a client token for authorization from your server
			const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/braintree/v1/getToken');
			const clientToken = response.data.clientToken;
			this.setState({ clientToken });
			console.log('clientToken is generated')
			console.log(clientToken)
		} catch (err) {
			console.error(err);
		}
	}

	async buy() {
		try {
			var email = UserProfile.getEmail();
			// Send the nonce to your server
			const { nonce } = await this
				.instance
				.requestPaymentMethod();
			console.log('nonce is created')
			await axios.post(process.env.REACT_APP_BASE_URL + '/api/braintree/v1/sandbox',
				{
					paymentMethodNonce: nonce,
					order_id: this.state.order_id,
					email: email,
					order_amount: this.state.order_amount
				}).then(res => {
					console.log(res)
					if (res.status === 200) {
						this.handleOrderResponse();
					}
					else {
						console.log('order is failed to update');
						alert('Something went wrong, please try again!');
						// this.props.history.push('/');
					}
				});
		} catch (err) {
			console.log('error in order update');
			console.error(err);
		}
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
	}
	handleupdateModal(){
		this.setState({showupdateModal:false})
	}

	setTermsAndConditions(event) {
		this.setState({ checkbox: !this.state.checkbox });
	}

	setFirstName(event) {
		this.setState({ first_name: event.target.value });
	}

	setLastName(event) {
		this.setState({ last_name: event.target.value });
	}

	setBusinessName(event) {
		this.setState({ business_name: event.target.value });
	}

	setEmail(event) {
		this.setState({ email: event.target.value });
	}

	setZipcode(event) {
		this.setState({ zipcode: event.target.value });
	}

	setBillingAddress = address => {
		this.setState({ address });

	}

	setCoordinates = address => {

		geocodeByAddress(address).then(results => {
			this.setBillingAddress(results[0].formatted_address);
			// console.log(results[0].formatted_address);
		});

	}

	proceedToPayment() {
		if (this.validator.allValid()) {
			if (this.state.couponCodeAvailable && this.state.couponCode.toLowerCase() !== 'goindnav') {
				alert('Use coupon code as GOINDNAV');
				return;
			}
			axios.post(process.env.REACT_APP_BASE_URL + '/api/create-order',
				{
					headers: { 'Content-Type': 'application/json' },
					first_name: this.state.first_name,
					last_name: this.state.last_name,
					business_name: this.state.business_name,
					email: this.state.email,
					usr_email: UserProfile.getEmail(),
					zipcode: this.state.zipcode,
					address: this.state.address,
					// payment_info: this.state.payment_info,
					order_amount: this.state.order_amount,
					place_ids: localStorage.getItem('listingNames') ? localStorage.getItem('listingNames') : ''
				}).then(res => {
					console.log(res)
					if (res.status === 200) {
						console.log('order creation successful');
						this.setState({ order_id: res.data.order_id });
						if (this.state.couponCodeAvailable && this.state.couponCode) {
							this.createCouponOrder();
						} else {
							this.buy();
						}
					}
					else {
						console.log('order creation failed');
						alert('Something went wrong, please try again!')
					}
				}).catch(function (error) {
					console.log('error in order creation');
					alert('Something went wrong, please try again!')
				});
		}
		else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	createCouponOrder = () => {
		try {
			var email = UserProfile.getEmail();
			axios.post(process.env.REACT_APP_BASE_URL + '/api/update-order-details',
				{
					order_id: this.state.order_id,
					email: email,
					order_amount: this.state.order_amount,
					couponCode: this.state.couponCode
				}).then(res => {
					console.log(res)
					if (res.status === 200) {
						this.handleOrderResponse();
					}
					else {
						console.log('order is failed to update');
						alert('Something went wrong, please try again!');
					}
				});
		} catch (err) {
			console.log('error in order update');
			console.error(err);
		}
	}

	handleOrderResponse = () => {
		console.log('order is updated with success');
		localStorage.removeItem('listingNames');
		this.setState({ showPlaceSuccessModal: true });
		var placeOrderDiv = document.getElementById('place-order-div');
		var placeOrderBtn = document.getElementById('place-order-btn');
		placeOrderDiv.removeChild(placeOrderBtn);
		this.setState({
			shoppingCartCount: 0
		})
	}

	handleNewRecordModal = () => {
		this.setState({ showNewRecordModal: false });
		localStorage.removeItem('useContactInfo');
		localStorage.removeItem('contactName');
		localStorage.removeItem('contactTitle');
		localStorage.removeItem('contactEmail');
		localStorage.removeItem('contactNo');
		
	}

	handleCheckboxChange = (name, value) => {
		this.setState({ [name]: value });
	}

	confirmDeleteListing = (recId) => {
		this.setState({
			showDeleteListingModal: true,
			deleteRecId: recId
		});
	}

	handleDeleteListingModal = () => {
		this.setState({
			showDeleteListingModal: false
		});
	}

	deleteListing = () => {
		let deleteRecId = this.state.deleteRecId;

		axios.post(process.env.REACT_APP_BASE_URL + '/api/discard-single-listing', {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			rec_id: this.state.deleteRecId,
			email: UserProfile.getEmail()
		}).then(response => {
			console.log(response)
			if (response.status === 200) {
				var listingNames = [];
				if (localStorage.getItem('listingNames')) {
					listingNames = JSON.parse(localStorage.getItem('listingNames'));
				}
				var newListingNamesArr = listingNames.filter(function (listingNameObj) { return listingNameObj.recId != deleteRecId; });

				this.setState({
					showDeleteListingSuccessModal: true,
					deleteRecId: '',
					showDeleteListingModal: false,
					shoppingCartCount: newListingNamesArr.length
				}, () => {
					if (newListingNamesArr.length > 0) {
						// update listing in localstorage
						console.log('new newListingNamesArr')
						console.log(newListingNamesArr)
						console.log(newListingNamesArr.length)
						localStorage.setItem('listingNames', JSON.stringify(newListingNamesArr));
					} else {
						localStorage.removeItem('listingNames');
						this.props.history.push('/create');
					}
					this.child.updateShoppingcart()
				})
			} else {
				this.setState({
					showErrorModal: true
				})
			}
		}).catch(error => {
			// handle your error
			console.log(error)
		});
	}

	handleDeleteListingSuccessModal = () => {
		this.setState({
			showDeleteListingSuccessModal: false
		})
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}

	child: Header = null;

	render() {
		const listingNamesArr = localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')) : [];

		return (
			<div>
				<Header data={this.props} onRef={ref => (this.child = ref)} shoppingCartCount={this.state.shoppingCartCount} />
				<div id='page-content' style={{ backgroundColor: 'white', paddingBottom: '50px', minHeight: '100vh' }}>
					<div>
						<Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
							<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
							<Link to={{ pathname: '/create' }}><span className='color-white'>Create</span></Link>&nbsp;/&nbsp;
							<BreadcrumbItem active>Checkout</BreadcrumbItem>
						</Breadcrumb>
						<div className='container-fluid' style={{ marginTop: '20px' }}>
							<div className='col-lg-10 col-lg-offset-1 padding-0-xs'>
								<div className='col-lg-5 padding-0-xs'>
									<h1>Billing Details</h1>
									<Form style={{ marginTop: '20px' }}>
										<div>
											<FormGroup>
												<ControlLabel className='font-weight-bold'>First Name</ControlLabel>
												<FormControl name='first_name' style={{ marginBottom: '5px' }} placeholder='First Name' onChange={this.setFirstName} value={this.state.first_name} />
												{this.validator.message('first name', this.state.first_name, 'required')}
											</FormGroup>
											<FormGroup>
												<ControlLabel className='font-weight-bold'>Last Name</ControlLabel>
												<FormControl name='last_name' style={{ marginBottom: '5px' }} placeholder='Last Name' onChange={this.setLastName} value={this.state.last_name} />
												{this.validator.message('last name', this.state.last_name, 'required')}
											</FormGroup>
										</div>

										<FormGroup>
											<ControlLabel className='font-weight-bold'>Business Name</ControlLabel>
											<FormControl name='business_name' style={{ marginBottom: '5px' }} placeholder='Business Name' onChange={this.setBusinessName} value={this.state.business_name} />
											{this.validator.message('business name', this.state.business_name, 'required')}
										</FormGroup>

										<FormGroup>
											<ControlLabel className='font-weight-bold'>Email Address</ControlLabel>
											<FormControl name='email' style={{ marginBottom: '5px' }} placeholder='Email Address' onChange={this.setEmail} value={this.state.email} />
											{this.validator.message('email', this.state.email, 'required')}
										</FormGroup>

										<div>
											<FormGroup>
												<ControlLabel className='font-weight-bold'>Billing Zipcode</ControlLabel>
												<FormControl name='zipcode' style={{ marginBottom: '5px' }} placeholder='Billing Zipcode' onChange={this.setZipcode} value={this.state.zipcode} />
												{this.validator.message('zipcode', this.state.zipcode, 'required')}
											</FormGroup>
										</div>

										<FormGroup>
											<ControlLabel className='font-weight-bold'>Billing Address</ControlLabel>
											{/* <FormControl name='address' style={{marginBottom: '5px'}} placeholder='Billing Address' onChange={this.setBillingAddress} value={this.state.address} /> */}
											<PlacesAutocomplete
												value={this.state.address}
												onChange={this.setBillingAddress}
												onSelect={this.setCoordinates}
											>
												{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
													<div>
														<input
															{...getInputProps({
																placeholder: 'Search Address ...',
																className: 'location-search-input',
															})}
														/>
														<div className='autocomplete-dropdown-container' style={{ borderTop: 'none' }}>
															{loading && <div>Loading...</div>}
															{suggestions.map(suggestion => {
																const className = suggestion.active
																	? 'suggestion-item--active'
																	: 'suggestion-item';
																// inline style for demonstration purpose
																const style = suggestion.active
																	? { backgroundColor: '#fafafa', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }
																	: { backgroundColor: '#ffffff', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' };
																return (
																	<div
																		{...getSuggestionItemProps(suggestion, {
																			className,
																			style,
																		})}
																	>
																		<span>{suggestion.description}</span>
																	</div>
																);
															})}
														</div>
													</div>
												)}
											</PlacesAutocomplete>
											{this.validator.message('billing address', this.state.address, 'required')}
										</FormGroup>
									</Form>
								</div>
								<div className='col-lg-1'></div>
								<div className='col-lg-6 padding-0-xs'>
									<h1>Your Order</h1>
									<div className='padding-30 padding-15-xs' style={{ border: '2px solid #f4f4f2', borderRadius: '6px' }}>
										{/* <h1>Your Order</h1> */}
										<div className='col-lg-12 col-xs-12 padding-0-xs border-bottom-1' style={{ lineHeight: '40px', marginBottom: 10 }}>
											<div className='col-lg-9 col-xs-8 text-left font-weight-bold pull-left'>Listing Name</div>
											<div className='col-lg-3 col-xs-4 text-center font-weight-bold pull-right'>Total</div>
										</div>
{/*
										{
											listingNamesArr.length > 0 ?
												listingNamesArr.map((listingNameObj) =>
													<div className='col-lg-12 col-xs-12 padding-0-xs' style={{ lineHeight: '40px', marginBottom: 10 }} key={Math.random()}>
														<div className='col-lg-9 col-xs-8 text-left'><div className='pull-left'>{listingNameObj.listingName}</div>
														</div>
														<div className='col-lg-3 col-xs-4 text-center pull-right'>$10</div>
														<i className='pull-right fa fa-trash cursor-pointer font-size-22-imp' style={{ color: 'red', position: 'absolute', right: 0, top: 8 }} onClick={() => this.confirmDeleteListing(listingNameObj.recId)}></i>
													</div>
												) : (
													<React.Fragment></React.Fragment>
												)
										}
*/}
										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<div className='font-size-13'>Site/facility listings will be invoiced monthly until the listing is deleted or suspended by the user. The initial minimum charge period is three months.</div>
										</div>
										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<InputCheckboxComponent name='terms1' checked={this.state.terms1} onHandleCheckboxChange={this.handleCheckboxChange} label='I agree to the website terms and conditions.' />
											{this.validator.message('Terms and Conditions', this.state.terms1, 'accepted')}
										</div>

										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<InputCheckboxComponent name='couponCodeAvailable' checked={this.state.couponCodeAvailable} onHandleCheckboxChange={this.handleCheckboxChange} label='I have a coupon code.' />
										</div>

										{
											!this.state.couponCodeAvailable ?

												!this.state.clientToken ?
													(
														<div style={{ textAlign: 'center' }}>
															<h1>Loading...</h1>
														</div>
													) : (
														<React.Fragment>
															<div className='col-lg-12 col-xs-12 padding-0-xs' id='braintree_custom_css'>
																<DropIn
																	options={{
																		authorization: this.state.clientToken
																	}}
																	onInstance={instance => (this.instance = instance)} />
															</div>
															<div className='col-lg-12 col-xs-12 padding-0-xs'>
																<OverlayTrigger placement='top' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>
																			Card Number: 4242 4242 4242 4242 <br />
																		Expiration Date: 01/25
																	</strong>
																	</Tooltip>
																}>
																	<label className='form-check-label cursor-pointer color-blue'>Hover to see beta user cc info</label>
																</OverlayTrigger>
															</div>
														</React.Fragment>
													)
												:
												<React.Fragment>
													<InputBoxComponent name='couponCode' value={this.state.couponCode} onHandleInputChange={this.handleInputChange} className='text-uppercase' />
													<div className='col-lg-12 col-xs-12 padding-0-xs margin-top-20'>
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	Coupon Code: GOINDNAV
																</strong>
															</Tooltip>
														}>
															<label className='form-check-label cursor-pointer color-blue'>Hover to see coupon code</label>
														</OverlayTrigger>
													</div>
												</React.Fragment>
										}


										<div className='text-center' style={{ marginTop: '10px', marginBottom: '10px' }} id='place-order-div'>
											<button type='button' className='border-radius-25-imp btn btn-default' id='place-order-btn' onClick={this.proceedToPayment}>Place Order</button>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*Modal for update plan*/}
				<Modal dialogClassName='custom-modal-step3' show={this.handleupdateModal} onhide={this.handleupdateModal} style={{ opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton data-dismiss="modal" onclick={this.handleupdateModal}></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
						<div className="d-flex justify-content-center">
							<form id="upgradeplan" style={{ backgroundColor: "white" }}>
								<div id='regi_errors' className='margin-top-15'></div>
								<div style={{ width: "200px", margin: "30px auto" }}><img src="/static/media/logo.26b073ac.svg" /></div>

								<div className='text-left color-black font-size-18  margin-bottom-20' style={{ color: '#474747', marginLeft: 10 }}><h1>Please upgrade your membership plan that best suits your needs.</h1></div>

								<div className='row container-fluid' >

									<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
										<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 700 }}>
											<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
												<div className="pricingGrid__rowItem" data-plan="premium">


													<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
													<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
												<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

													<div className="flex flex--bottom" aria-hidden="true">
														<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
															<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
															<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">29</div>
															<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
															<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
														</div>

														<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
															Per month                      </div>
													</div>



													<br /><br />
													<div className="experiment">
														<Link to={{
															pathname: '/checkout',
															state: {
																addMoreClicked: true,
															}
														}}>
															<Button type="submit" className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
														</Link>
														
													</div>
												</div>
											</div><br />

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
												<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

													<li className="margin--bottom-c4"> All Basic functionality +   </li>
													<li className="margin--bottom-c4">Search by keyword  </li>
													<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
													<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
													<li className="margin--bottom-c4">Export map results and views</li>
													<li className="margin--bottom-c4">Up to 25 listings</li>
												</ul>
											</div>
											
										</div>
									</div>

									<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
										<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 700 }}>
											<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
												<div className="pricingGrid__rowItem" data-plan="premium">


													<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
													<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
												<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

													<div className="flex flex--bottom" aria-hidden="true">
														<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
															<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
															<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">49</div>
															<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
															<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
														</div>

														<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
															Per month                      </div>
													</div>

													<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

													<br /><br />
													<div className="experiment">
														<div className="margin--bottom-c3">
															<Link to={{
																pathname: '/checkout',
																state: {
																	addMoreClicked: true,
																}
															}}>
																<Button type="submit" className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
															</Link>
														</div>
														
													</div>
												</div>
											</div><br />

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
												<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

													<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
													<li className="margin--bottom-c4">See who has viewed your listings </li>
													<li className="margin--bottom-c4">Premium placement within search results </li>
													<li className="margin--bottom-c4">Up to 100 listings </li>
												</ul>
											</div>

										</div>

									</div>

									<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
										<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 700 }}>
											<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
												<div className="pricingGrid__rowItem" data-plan="premium">


													<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">PRIVATE LABEL</h2>
													<p style={{ fontSize: 15 }} className="copy margin--top-c4">Explore custom solutions </p>

												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
												<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

													<div className="flex flex--bottom" aria-hidden="true">
														<div className="formattedPrice1 flex flex--left" data-behavior="formattedPrice" data-plan="premium">
															<div className="formattedPrice1__price--symbol--left" data-behavior="formattedPrice__symbol">Contact For Pricing</div>
															<div className="formattedPrice1__price--integer" data-behavior="formattedPrice__integer"></div>
															<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
															<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
														</div>
													</div>


													<hr />
													<div className="experiment">

														<div className="margin--bottom-c3">
															<p style={{ fontSize: 15 }} className="copy margin--top-c4">Call (312) 957-7757<br />info@industrialnavigator.com </p>

														</div>
													</div>
												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
												<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 303 }}>

													<li className="margin--bottom-c4">Implant the functionality of Industrial Navigator on your website to market your assets and services    </li>
													<li className="margin--bottom-c4">Create custom map layers  </li>
													<li className="margin--bottom-c4">Market research and logistics optimization  </li>
													<li className="margin--bottom-c4">Multi-user subscriptions </li>
												</ul>
											</div>
										</div>
									</div>
								
								</div>

							</form>

						</div>
					</Modal.Body>
				</Modal>












				<Modal show={this.state.showPlaceSuccessModal} onHide={this.handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Payment Successful
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Your listing is waiting to be approved. When your listing is active, you will be notified.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handlePlaceSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showNewRecordModal} onHide={this.handleNewRecordModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
							Congrats! You have creating a listing. You have [# of listings left] available to use.
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Do you want to add more listings?
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<Link to={{
							pathname: '/create',
							state: {
								addMoreClicked: true,
							}
						}}>
							<Button variant='btn btn-default border-radius-25-imp' className='text-uppercase btn btn-default border-radius-25-imp'>Add More</Button>
						</Link>
						<button type='button' className='btn btn-default border-radius-25-imp text-uppercase margin-left-15' onClick={this.handleNewRecordModal.bind(this)}>Not Now</button>
					</Modal.Footer>
				</Modal>

				{/* Delete confirmation modal */}
				<Modal show={this.state.showDeleteListingModal} onHide={this.handleDeleteListingModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp' onClick={this.deleteListing}>Confirm</button>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.handleDeleteListingModal}>Cancel</button>
					</Modal.Footer>
				</Modal>

				{/* Delete Listing success modal */}
				<Modal dialogClassName='custom-modal' show={this.state.showDeleteListingSuccessModal} onHide={this.handleDeleteListingSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Listing Deleted
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Listing has been deleted successfully!
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleDeleteListingSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}

	handleClose: any;
}

export default withRouter(Checkout);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)