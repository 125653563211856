import React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import * as MapboxGL from 'mapbox-gl';

import { PipelineTheme } from './CrudeOilPipelinesLayer';

interface PPetroleumProductPipelinesLayer {
	theme: PipelineTheme;
}

class SPetroleumProductPipelinesLayer {
	ref: PetroleumProductPipelinesLayer = null;
	lines: boolean = true;
	labels: boolean = true;

	constructor(parent: PetroleumProductPipelinesLayer) {
		this.ref = parent;
	}
}

export default class PetroleumProductPipelinesLayer extends React.Component<PPetroleumProductPipelinesLayer, SPetroleumProductPipelinesLayer> {

	static dataSource: string = 'Petroleum_Product_Pipelines-08d21s';

	constructor(props) {
		super(props);
		this.state = new SPetroleumProductPipelinesLayer(this);
	}

	render() {
		const { state, props } = this;
		const dataSource: string = PetroleumProductPipelinesLayer.dataSource;
		return (
			<React.Fragment>
				{
					state.lines &&
					<Layer
						id='petroleum-product-pipelines-lines'
						type='line'
						sourceId={dataSource}
						sourceLayer={dataSource}
						layerOptions={{
							'source-layer': { dataSource },
						}}
						layout={{ 'visibility': 'visible' }}
						paint={{
							'line-color': '#0066ff',
							'line-dasharray': [4, 1],
							'line-width': [
								'interpolate', ['exponential', 2], ['zoom'],
								10, 3
								, 14, 4
								, 20, 10
								, 22, 16
								, 24, 22
							]
						}}
					/>
				}
				{
					state.labels &&
					<Layer
						id='petroleum-product-pipelines-labels'
						type='symbol'
						source={dataSource}
						sourceId={dataSource}
						sourceLayer={dataSource}
						layout={{
							'symbol-placement': 'line',
							'text-font': ['Open Sans Regular'],
							'text-field': '{Opername} - {Pipename}',
							'text-size': 16,
						}}
						paint={{
							'text-color': (
								props.theme === PipelineTheme.Light
									? '#ffffff'
									: (props.theme === PipelineTheme.Dark ? '#000000' : '#777777')
							),
							'text-halo-color': (
								props.theme === PipelineTheme.Light
									? '#000000'
									: (props.theme === PipelineTheme.Dark ? '#ffffff' : '#aaaaaa')
							),
							'text-halo-width': 2,
						}}
					/>
				}
			</React.Fragment>
		)
	}
}
