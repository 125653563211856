import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesNs extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesNs extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesNs = null;

	constructor(parent: NorthAmericanRailLinesNs, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesNs extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesNs, SNorthAmericanRailLinesNs> {

	static dataSource: string = 'NARL_NS-a2cm0l';
	static sourceLayer: string = 'NARL_NS';

	constructor(props: PNorthAmericanRailLinesNs) {
		super(props, NorthAmericanRailLinesNs.dataSource, NorthAmericanRailLinesNs.sourceLayer);
		this.state = new SNorthAmericanRailLinesNs(this, NorthAmericanRailLinesNs.dataSource, NorthAmericanRailLinesNs.sourceLayer);
	}
}
