import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const Index = props => {
    
    return (
        <>
            <section style={{background:'#fbfbfb'}} className="iq-counter-section pt-0 iq-pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb-lg-0 mb-5">
                            <div className="iq-title-box iq-title-default">
                                <h2 style={{ fontFamily: 'TeXGyreAdventor-Bold, sans-serif', textTransform: 'none', color: '#142149', lineHeight: '1.5em', fontWeight: 800, letterSpacing: '1px' }} className="iq-title "> Your Site and Logistics Marketing Toolbox</h2>
                                <p style={{ fontSize: '1rem', fontFamily: 'Roboto, sans-serif', color: '#4a4a4a', fontWeight: 300, lineHeight: 1.5 }} className="iq-title-desc ">
                                    Industrial Navigator brings powerful tools for marketing your industrial sites and logistics assets.<br />
                                </p><br />
                                <ul style={{ listStyleType: 'disc',marginLeft:20 }}>
                                    <li>Paid subscribers can track who has viewed their listings and get premium placement (ahead of non-subscribers) within search results when their listings match the search criteria.</li>
                                    <li>A listing can be shared via email or in social media campaigns.</li>
                                    <li><Link  to={{ pathname: '/private-clients-info' }} >Private label </Link> solutions are for companies that own or operate portfolios of properties or a network of warehouse, transload and terminal assets, private label solutions bring the functionality of Industrial Navigator to your website under your brand identity.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Index