import siteIcon from '../help-icon.png';
// import siteIcon from '../site-marker.png';

export const ListingHelpDataForSiteSelectionModal = {
	title: `<h1 style="font-size: 25px;">HELP YOUR CLIENTS LOCATE THE PERFECT SITE!</h1>`,	
	body: `
        <iframe class="video iframe-width" width="500" height="400" src="https://www.youtube.com/embed/_WEVvJkycwo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		<p style="font-size: 14px;">Search Industrial Navigator for industrial sites, ports, terminals, transloads, warehouses,
        and distribution/fulfillment centers.
		<ul style="list-style-type: disc;">
		<li ><b>SEARCH BY: </b>Property type, location, or keyword/s</li><br />
		<li><b>40+ FILTERS: </b>Use filter criteria to find sites specific to your needs</li><br />
		<li><b>DYNAMIC MAP: </b>Search by using the powerful map features to locate crucial
        transportation assets nearby target sites (i.e., railroad networks and major ports)</li><br />
		<li><b>FAVORITES: </b>Save favorite sites/properties and share with clients</li><br />
        <li><b>CONNECT WITH LISTERS: </b>Connect directly with listing agents for price and details</li>
		</ul><br />
		<p style="font-size: 14px;">Have questions or need help? Contact us at <span style="color:#0094e9"> info@industrialnavigator.com</span>.</p>
		
	`
}