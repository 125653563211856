import React, { Component } from 'react';
import '../custom.css';
import { Button, Breadcrumb, BreadcrumbItem, Col, Row, Form, Modal, FormGroup, OverlayTrigger, Tooltip, ControlLabel, FormControl } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import Header from './Header';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import 'braintree-web';
import { CheckoutStrip } from './NewCheckOutFormCard';
import { Elements } from "@stripe/react-stripe-js";
import '../custom_braintree.css';
import { Cookies } from 'react-cookie';
import HomePageHeader from './HomePageHeader';
import { loadStripe } from "@stripe/stripe-js";
import HomePageFooter from './HomePage/qloud/footerComponents';
import MetaTags from 'react-meta-tags';

require('firebase/auth')
const promise = loadStripe("pk_live_51JQZCTI9aRcuI4Jh6A4nCIJAOhTzdxjXDvRZ1HYx6GsqY1EazrOV5PV3oYL6qUQHbDMboqDjJgeXipyZbzpXhzId00IX8JmTkY");

const cookies = new Cookies();
interface PHomePageHeader {
    onRef?: (homePageHeader: HomePageHeader) => void;
    data: any;
}

interface PCheckout {
    history?: Array<string>;
    location: any;
}

class SCheckout {
    constructor() {
    }
}

class CheckoutSubscription extends Component<PCheckout, any> {
    validator: SimpleReactValidator = null;

    instance;

    constructor(props) {
        super(props);
        this.state = {
            shoppingCartCount:  0,
            user_email: '',

        }
    }
    async componentDidMount() {
        //console.log('get user email', UserProfile.getEmail());
        console.log(this.props.location.planinfo)
        localStorage.setItem('planchoseninfo',JSON.stringify(this.props.location.planinfo));
        if (cookies.get('token') == undefined) {
        }
        else{
            this.props.history.push('/my-profile');
        }
    }
    componentWillMount() {
        localStorage.setItem('planchoseninfo',JSON.stringify(this.props.location.planinfo));
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }

    child: Header = null;

    render() {
        return (
            <div>
                <MetaTags>
					<title>Register with industrial navigator</title>
				</MetaTags>
                <Header data={this.props} onRef={ref => (this.child = ref)} shoppingCartCount={this.state.shoppingCartCount} />
                <div id='page-content' style={{ backgroundColor: 'white', paddingBottom: '50px', minHeight: '100vh' }}>
                    <div>
                        {/* <Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
                            <BreadcrumbItem active>Checkout</BreadcrumbItem>
                        </Breadcrumb> */}
                        <div className='container-fluid' style={{ marginTop: '20px' }}>
                            <div className='col-lg-10 col-lg-offset-1 '>
                                <Elements stripe={promise} >
                                    <CheckoutStrip  />
                                </Elements>

                            </div>
                        </div>
                    </div>
                </div>

                <HomePageFooter />
            </div>
        )
    }

    handleClose: any;
}

export default withRouter(CheckoutSubscription);

