import React, { Component } from 'react';
import Header from './HeaderLTE';
import SideBar from './SideBarLTE';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from './UserProfile';
import '../css/adminLTE-custom.css';
import DataTable from 'react-data-table-component';
import BulkUpload from './BulkUpload';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';
import { Modal, Button, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from './Footer';
import Details from './PrivateDetails'
import MetaTags from 'react-meta-tags';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
let listings = 0;
let UserRole = "";
let newlistings = 0;
let planid = 0;


// pk.eyJ1IjoibmhlbW1hZHkiLCJhIjoiY2sxcWkyZ3I3MThhZTNjcTNvejhxbjFudCJ9.AtnLKOwvcTU5eMdDwPXEYg

const columns = memoize(clickHandler => [
	{
		name: 'Listing Type',
		selector: 'propertyTypeLabel',
		sortable: true,
	},
	{
		name: 'Site Name',
		selector: 'listingName',
		sortable: true,
		cell: (row) => (

			<>
				<OverlayTrigger placement='top' overlay={
					<Tooltip id='tooltip'>
						<strong className='font-size-16'>
							Details Of Listing
						</strong>
					</Tooltip>
				}>
					<div style={{ textAlign: 'right' }} role='button' onClick={clickHandler} data-id={[row.rec_id, row.status, row.count_views]} data-action='list-details'> {row.listingName}</div>
				</OverlayTrigger>

				{


				}

			</>
		),
	},
	// {

	// 	name: 'Site Name',
	// 	cell: (row) => (

	// 		<>
	// 			<OverlayTrigger placement='top' overlay={
	// 				<Tooltip id='tooltip'>
	// 					<strong className='font-size-16'>
	// 						Details Of Listing
	// 					</strong>
	// 				</Tooltip>
	// 			}>
	// 				<div style={{ textAlign: 'right' }} role='button' onClick={clickHandler} data-id={[row.rec_id, row.status, row.count_views]} data-action='list-details'> {row.listingName}</div>
	// 			</OverlayTrigger>

	// 			{


	// 			}

	// 		</>
	// 	),
	// 	ignoreRowClick: true,
	// 	allowOverflow: true,
	// 	headerStyle: { textAlign: 'right' },
	// 	button: true,
	// 	sortable: true,
	// 	width: '300px',
	// },
	{
		name: 'Email',
		selector: 'OwnerEmail',
		sortable: true,

	},
	{
		name: 'Start Date',
		selector: 'start_date',
		sortable: true,
		width: '125px',
	},
	// {
	// 	name: 'Views',
	// 	selector: 'count_views',
	// 	sortable: true,
	// 	width: '100px',

	// },
	{

		name: 'Views',
		cell: (row) => (

			<>

				{
					row.count_views == null || row.count_views == 0 ?
						""
						:
						<>
							<span style={{ marginRight: 10 }}> {row.count_views}</span >
							<li style={{ display: 'inline-block' }}><span style={{ color: "#4c9cd3" }} role='button' className='font-size-15 ' onClick={clickHandler} data-id={[row.rec_id, row.status, row.count_views]} data-action='details'>Details</span></li>
						</>
					// <>
					// 	<span style={{ marginRight: 10 }}> {row.count_views}</span >
					// 	<li style={{ display: 'inline-block' }}><span style={{ color: "#4c9cd3" }} role='button' className='font-size-15 ' onClick={clickHandler} data-id={{"id":row.rec_id, "status":row.status, "email":row.who_viewed_listings}} data-action='details'>Details</span></li>
					// </>

				}

			</>

			// 
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
		sortable: true,
		width: '150px',
	},

	// {
	// 	name: 'End date',
	// 	selector: 'end_date',
	// 	sortable: true,
	// 	width: '125px'
	// },
	// {
	// 	name: 'Cost per month',
	// 	selector: 'cost_per_month',
	// 	sortable: true,
	// 	width: '150px'
	// },
	{
		name: 'Status of Listing',
		selector: 'status',
		sortable: true,
		width: '150px'
	},
	// {
	// 	name: 'Discount Code',
	// 	selector: 'couponCode',
	// 	sortable: true,
	// 	width: '150px'
	// },


	{
		cell: (row) => (
			// <button className='btn btn-danger border-radius-25-imp'
			// 	onClick={clickHandler} data-id={[row.rec_id, row.status]} data-action='change' 
			// >
			// 	{
			// 		row.status == "Inactive" ? "Activate" : "Deactivate"
			// 	}

			// </button>
			<span onClick={clickHandler} data-id={[row.rec_id, row.status, row.client_id]} data-action='change'>
				{
					row.status == "Inactive" ?
						<>
							<OverlayTrigger placement='top' overlay={
								<Tooltip id='tooltip'>
									<strong className='font-size-16'>
										Activate Listing
									</strong>
								</Tooltip>
							}>
								<i onClick={clickHandler} data-id={[row.rec_id, row.status, row.client_id]} data-action='change' style={{ color: "red" }} className="fa fa-circle-thin fs-30"></i>
							</OverlayTrigger>

						</>
						:
						<>
							<OverlayTrigger placement='top' overlay={
								<Tooltip id='tooltip'>
									<strong className='font-size-16'>
										Deactivate Listing
									</strong>
								</Tooltip>
							}>
								<i onClick={clickHandler} data-id={[row.rec_id, row.status, row.client_id]} data-action='change' style={{ color: "red" }} className="fa fa-ban fs-30"></i>
							</OverlayTrigger>

						</>

				}
			</span>

			//
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
	{
		cell: (row) => (
			// <button className='btn btn-default border-radius-25-imp'
			// 	onClick={clickHandler} data-id={row.rec_id} data-action='edit'
			// >
			// 	Edit
			// </button>
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
						Edit Listing
					</strong>
				</Tooltip>
			}>
				<i style={{ color: "#8B8B8B" }} onClick={clickHandler} data-id={row.rec_id} data-action='edit' className="fa fa-edit fs-30"></i>
			</OverlayTrigger>

		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
	{
		cell: (row) => (
			// <button className='btn btn-danger border-radius-25-imp'
			// 	onClick={clickHandler} data-id={[row.rec_id, row.status]} data-action='delete'
			// >
			// 	Delete
			// </button>
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
						Delete Listing
					</strong>
				</Tooltip>
			}>
				<i style={{ color: "red" }} onClick={clickHandler} data-id={[row.rec_id, row.status, row.client_id]} data-action='delete' className="fa fa-trash fs-30"></i>
			</OverlayTrigger>
			//
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	}
]);
// const columnsone = memoize(clickHandler => [

// 	{
// 		name: 'Site Name',
// 		selector: 'who_viewed_listings',
// 		sortable: true,
// 	}


// ]);


interface PMyListing {
	history: Array<string>;
}

class SMyListing {
	data: Array<any> = [];
	filteredData: Array<any> = [];
	export_places_list: Array<any> = [];
	export_places_list_filtered: Array<any> = [];
	deleteRecId: any = null;
	clientId: any = null;
	viewerlist: any = [];
	changeRecId: any = null;
	statusRecId: any = null;
	listingsViewData: any = [];
	showDeleteModal: boolean = false;
	showupdateplanModal: boolean = false;
	showChangeStatusModal: boolean = false;
	showWhoViewrsListingsModal: boolean = false;
	showAdminDesktopModal: boolean = false;
	detailsModal: boolean = false;
	filterString: string = '';

	//  arr = ['430', 'Active', 'kuldip@e-arth.in', 'kuldip@e-arth.in', 'admin@gmail.com', 'kuldip@e-arth.in', 'kuldip@e-arth.in', 'kuldip@e-arth.in', 'kuldip@e-arth.in', 'kuldip@e-arth.in', 'kuldip@e-arth.in', 'kuldip@e-arth.in', 'kuldip@e-arth.in', ''];
	//  n = this.arr.length;






	constructor() {

	}

}
axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
	{
		headers: {
			'Content-Type': 'application/json',
		},
		email: UserProfile.getEmail(),
	}).then(response => {
		//console.log("response of the api",response)
		//console.log("data for the api" +response)
		listings = response.data[0].sw_number_listings;
		UserRole = response.data[0].sw_role;
		//console.log("user role the user is:",UserRole);
		console.log("get my listing" + listings)
		localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
		localStorage.setItem('membershipOptions', response.data[0].options);

	});

//console.log("my listings count is" + listings)

class MyListing extends Component<PMyListing, SMyListing> {

	_isMounted = false;

	constructor(props: PMyListing) {
		super(props);
		this.state = new SMyListing();

		//this.state =  {checked: false} ;
		//checked:boolean = false;

		//this.setnewlisting = this.setnewlisting.bind(this);
		this.getnewlisting = this.getMyListing.bind(this);
	}


	handleButtonClick = (event) => {
		const id = event.target.getAttribute('data-id');
		const curr_array = id.split(',');
		console.log("curr_array", id)
		const n = curr_array.length


		const buttonAction = event.target.getAttribute('data-action');

		if (buttonAction === 'edit') {
			this.props.history.push('/edit-place-' + id);
		}
		else if (buttonAction === 'delete') {

			this.setState({
				deleteRecId: curr_array[0],
				statusRecId: curr_array[1],
				clientId: curr_array[2],
				showDeleteModal: true
			});
		}
		else if (buttonAction === 'details') {
			// this.setState({
			// 	viewerlist: curr_array,

			// 	showWhoViewrsListingsModal: true
			// });
			this.getListingsAnalytics(curr_array[0])
			//this.countFreq(curr_array, n) 
		}
		else if (buttonAction === 'list-details') {
			localStorage.setItem("rec_id", curr_array[0])
			this.setState({ detailsModal: true });

		}
		else {
			//alert(curr_array)
			this.setState({
				changeRecId: curr_array[0],
				statusRecId: curr_array[1],
				clientId: curr_array[2],
				showChangeStatusModal: true
			});
		}
	}
	getnewlisting() {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				email: UserProfile.getEmail(),
			}).then(response => {
				console.log(response)
				//console.log("data for the api" +response)
				listings = response.data[0].sw_number_listings;
				//console.log("get my listing"+listings)
				localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
				localStorage.setItem('membershipOptions', response.data[0].options);

			});
		return Number(listings);

	}
	hideDetailsModal = () => {
		this.setState({ detailsModal: false });
		localStorage.removeItem('rec_id');
	}

	// updatecountminus = () => {
	// 	newlistings = 0;
	// 	listings = 0;
	// 	axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
	// 		{
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},
	// 			email: UserProfile.getEmail(),
	// 		}).then(response => {
	// 			//console.log(response)
	// 			listings = response.data[0].sw_number_listings;
	// 			localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
	// 			localStorage.setItem('membershipOptions', response.data[0].options);
	// 			const data = {
	// 				email: UserProfile.getEmail(),
	// 				newlistings: Number(listings) - 1
	// 			}
	// 			axios.post(process.env.REACT_APP_BASE_URL + '/api/update-number-listing',
	// 				{
	// 					headers: { 'Content-Type': 'application/json' },
	// 					email: UserProfile.getEmail(),
	// 					newlistings: Number(listings) - 1


	// 				}).then(
	// 					(response) => {
	// 						//alert('updated count')

	// 					}
	// 				)
	// 			//alert(Number(newlistings))
	// 		});

	// }
	updatecountminus = () => {

		axios.post(process.env.REACT_APP_BASE_URL + '/api/update-number-listing-minus',
			{
				headers: { 'Content-Type': 'application/json' },
				clientId: this.state.clientId,

			}).then(
				(response) => {


				}
			)

	}
	updatecountplus = () => {

		axios.post(process.env.REACT_APP_BASE_URL + '/api/update-number-listing-plus',
			{
				headers: { 'Content-Type': 'application/json' },
				clientId: this.state.clientId,

			}).then(
				(response) => {
					//alert('updated count')

				}
			)

	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	componentWillMount() {
		this._isMounted = true;

		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				email: UserProfile.getEmail(),
			}).then(response => {
				//console.log("response of the api",response)
				//console.log("data for the api" +response)
				listings = response.data[0].sw_number_listings;
				UserRole = response.data[0].sw_role;
				planid = response.data[0].sw_plan_id;
				//alert(planid)
				//console.log("user role the user is:",UserRole);
				//console.log("get my listing" + listings)
				localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
				localStorage.setItem('membershipOptions', response.data[0].options);

			});
		this.getMyListing();

	}

	getMyListing = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-my-listing', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const features = res.data;
				//console.log(res.data)
				const places_list = [];
				const export_places_list = [];

				for (let i = 0; i < features.length; i++) {
					//console.log(features.length)
					const feature_start_date = new Date(features[i].createdAt);

					// console.log('feature_start_date')
					//console.log("real date",features[i].createdAt[0])
					// console.log(feature_start_date)

					const start_year = feature_start_date.getFullYear();
					const start_month = ('0' + (feature_start_date.getMonth() + 1)).slice(-2);
					var start_day = ('0' + (feature_start_date.getDate() + 1)).slice(-2);

					// const formatted_start_date = [ start_month, start_day, start_year ].join('-');
					const formatted_start_date = features[i].createdAt.substring(0, 11);;

					// const end_date = '2019-11-30'.split('-');
					// // var formatted_end_date = [ end_date[1], end_date[2], end_date[0] ].join('-');
					// const formatted_end_date = '';

					let selectedPropertyTypeLabel = '';

					if (features[i].properties.propertyType.includes('*')) {
						selectedPropertyTypeLabel = 'Multipurpose';
					} else {
						selectedPropertyTypeLabel = features[i].properties.propertyType;
					}
					if (planid == 4 || planid == 6 || planid == 7) {
						const property_obj = {
							rec_id: features[i].rec_id,
							client_id: features[i].client_id,
							propertyType: features[i].properties.propertyType,
							propertyTypeLabel: selectedPropertyTypeLabel,
							listingName: features[i].properties.listingName,
							OwnerEmail: features[i].client_email_id,
							start_date: formatted_start_date,
							count_views: features[i].total_views,
							who_viewed_listings: features[i].who_view_listings_obj,
							//isActive :features[i].active,
							//end_date: formatted_end_date,
							//couponCode: features[i].couponCode ? features[i].couponCode : '-',
							//cost_per_month: '$100',
							status: features[i].active == "Yes" ? "Active" : "Inactive",

						}
						places_list.push(property_obj)
					}
					else {
						const property_obj = {
							rec_id: features[i].rec_id,
							client_id: features[i].client_id,
							propertyType: features[i].properties.propertyType,
							propertyTypeLabel: selectedPropertyTypeLabel,
							listingName: features[i].properties.listingName,
							OwnerEmail: features[i].client_email_id,
							start_date: formatted_start_date,
							count_views: null,
							who_viewed_listings: null,
							//isActive :features[i].active,
							//end_date: formatted_end_date,
							//couponCode: features[i].couponCode ? features[i].couponCode : '-',
							//cost_per_month: '$100',
							status: features[i].active == "Yes" ? "Active" : "Inactive",

						}
						places_list.push(property_obj)
					}
					var image_urls = '';
					for (let index = 0; index < features[i].properties.image.length; index++) {
						image_urls = image_urls + "," + features[i].properties.image[index].url
					}
					const property_obj_export = {
						rec_id: features[i].rec_id,
						client_id: features[i].client_id,
						client_first_name: features[i].client_first_name,
						client_last_name: features[i].client_last_name,
						OwnerEmail: features[i].client_email_id,
						client_plan_id: features[i].client_plan_id,
						total_views: features[i].total_views,
						type: features[i].type,
						coordinates: features[i].geometry.coordinates,
						propertyTypeLabel: selectedPropertyTypeLabel,
						propertyFor: features[i].properties.propertyFor,
						listingName: features[i].properties.listingName,
						listingLogo: features[i].properties.listingLogo,
						organizationName: features[i].properties.organizationName,
						image: image_urls,
						contactName: features[i].properties.contactName,
						contactTitle: features[i].properties.contactTitle,
						contactEmail: features[i].properties.contactEmail,
						contact: features[i].properties.contact,
						description: features[i].properties.description,
						city: features[i].properties.city,
						state: features[i].properties.state,
						zipCode: features[i].properties.zipCode,
						address: features[i].properties.address,
						country: features[i].properties.country,
						acreage: features[i].properties.acreage,
						price: features[i].properties.price,
						perUnit: features[i].properties.perUnit,
						zoning: features[i].properties.zoning,
						building: features[i].properties.building,
						squareFeet: features[i].properties.squareFeet,
						interiorRoofHeight: features[i].properties.interiorRoofHeight,
						suitableFor: features[i].properties.suitableFor,
						waterSewerInPlace: features[i].properties.waterSewerInPlace,
						electricPowerInPlace: features[i].properties.electricPowerInPlace,
						serviceVoltage: features[i].properties.serviceVoltage,
						distanceToSubstation: features[i].properties.distanceToSubstation,
						naturalGasInPlace: features[i].properties.naturalGasInPlace,
						lineDiameter: features[i].properties.lineDiameter,
						plgCertification: features[i].properties.plgCertification,
						siteQualification: features[i].properties.siteQualification,
						capabilities: features[i].properties.capabilities,
						equipments: features[i].properties.equipments,
						railcarStorageCapacity: features[i].properties.railcarStorageCapacity,
						majorFeatures: features[i].properties.majorFeatures,
						specifications: features[i].properties.specifications,
						productStorage: features[i].properties.productStorage,
						productsHandled: features[i].properties.productsHandled,
						indoorStorage: features[i].properties.indoorStorage,
						marineAccess: features[i].properties.marineAccess,
						bargeAccess: features[i].properties.bargeAccess,
						docInPlace: features[i].properties.docInPlace,
						distancetoWater: features[i].properties.distancetoWater,
						classOneConnections: features[i].properties.classOneConnections,
						shortLines: features[i].properties.shortLines,
						status: features[i].active == "Yes" ? "Active" : "Inactive",
						start_date: formatted_start_date,

					}
					export_places_list.push(property_obj_export)



				}
				this.setState({
					data: places_list,
					filteredData: places_list,
					export_places_list: export_places_list,
					export_places_list_filtered: export_places_list
				});

			})
			.catch(err => {
			});
	}

	handleDeleteRecord = async () => {
		const id = this.state.deleteRecId;
		const status = this.state.statusRecId;
		localStorage.removeItem('_delete');
		await axios.post(process.env.REACT_APP_BASE_URL + '/api/delete-record', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			rec_id: id,
			email: UserProfile.getEmail()
		})
			.then(res => {
				if (res.status === 200) {
					if (status == "Active") {
						this.updatecountminus();

					}

					this.setState({
						showDeleteModal: false

					});
					this.callback();
				}
			})
	}
	handleChangeStatusRecord = async () => {
		const id = this.state.changeRecId;
		const status = this.state.statusRecId;
		localStorage.removeItem('_delete');
		let planid = null;
		let Maxlistings = 10000;
		if (status == "Inactive") {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
				{
					headers: {
						'Content-Type': 'application/json',
					},
					email: UserProfile.getEmail(),
				}).then(response => {
					planid = response.data[0].sw_plan_id;
					listings = response.data[0].sw_number_listings;

					//alert(listings)
					if (planid == 2) {

						Maxlistings = 5;

					} else if (planid == 3 || planid == 5) {

						Maxlistings = 25;
					}
					else if (planid == 4 || planid == 6) {

						Maxlistings = 100;

					}


					if (listings >= Number(localStorage.getItem('maxNoEntries') ? localStorage.getItem('maxNoEntries') : null)) {
						//alert("listing exceeded")
						this.setState({
							showChangeStatusModal: false,
							showupdateplanModal: true

						});
						this.callback();

					}
					else {
						axios.post(process.env.REACT_APP_BASE_URL + '/api/update-listing-status', {
							headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
							rec_id: id,
							email: UserProfile.getEmail()
						})
							.then(res => {
								if (res.status === 200) {
									//this.updatecount();
									if (status == "Active") {
										//alert("count increased")
										this.updatecountminus();
									}
									else {

										//alert("count decreased")
										this.updatecountplus();
									}
									this.setState({
										showChangeStatusModal: false

									});
									this.callback();
								}
								else {
									this.setState({
										showChangeStatusModal: false
									});
									alert("Invalid Address Field provided,please provide valid address.")

								}
							})
							.catch(err => {
								this.setState({
									showChangeStatusModal: false

								});
								alert("Invalid Address Field provided,please provide valid address.")

							});

					}
				});

		}
		else {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/update-listing-status', {
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				rec_id: id,
				email: UserProfile.getEmail()
			})
				.then(res => {
					if (res.status === 200) {
						//this.updatecount();
						if (status == "Active") {
							//alert("count increased")
							this.updatecountminus();
						}
						else {

							//alert("count decreased")
							this.updatecountplus();
						}
						this.setState({
							showChangeStatusModal: false

						});
						this.callback();
					}
				});

		}


	}

	handleDeleteModal = () => {
		this.setState({
			deleteRecId: null,
			statusRecId: null,
			showDeleteModal: false
		});
	}
	handleChangeStatusModal = () => {
		this.setState({
			changeRecId: null,
			statusRecId: null,
			showChangeStatusModal: false
		});
	}
	handleshowWhoViewrsListingsModal = () => {
		this.setState({

			showWhoViewrsListingsModal: false,
			listingsViewData: [],
		});


	}

	handleAdminDesktopModal = () => {
		this.setState({
			showAdminDesktopModal: false
		});
	}
	handleupdateplanModal = () => {
		this.setState({ showupdateplanModal: false });
	}

	callback = () => {
		this.getMyListing();
	}
	getListingsAnalytics = async (rec_id) => {
		const id = rec_id;
		var obj
		await axios.post(process.env.REACT_APP_BASE_URL + '/api/get-listing-analytics-data', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			rec_id: id,
			email: UserProfile.getEmail()
		})
			.then(res => {
				if (res.status === 200) {
					//console.log("response", res.data.features[0].who_view_listings_obj);
					obj = res.data.features[0].who_view_listings_obj
					// console.log("obj:", obj)
					//console.log("length", obj.length)
					var emailobj = []
					for (let index = 0; index < obj.length; index++) {
						var email = obj[index].email;
						emailobj.push(email)

					}

					this.countFreq(obj, emailobj, emailobj.length)


				}
			})

	}
	countFreq(obj, arr, n) {
		let visited = Array.from({ length: n }, (_, i) => false);
		//console.log(visited)
		// Traverse through array elements and
		// count frequencies
		for (let i = 0; i < n; i++) {
			// Skip this element if already processed
			if (visited[i] == true)
				continue;

			// Count frequency
			let count = 1;
			for (let j = i + 1; j < n; j++) {
				if (arr[i] == arr[j]) {
					visited[j] = true;
					count++;
				}
			}
			//document.write(arr[i] + " " + count + "<br/>");
			//console.log(arr[i] + " " + count + "<br/>")
			this.state.listingsViewData.push({ "name": obj[i].name, "company": obj[i].company, "email": arr[i], "count": count, "last30daycount": 0 })

		}
		//console.log("final data array", this.state.listingsViewData[0])
		// console.log("*******************")
		// const date1 = new Date("2021-01-31")
		// const date2 = new Date("2021-04-21")
		// const date3 = new Date("2021-05-09")
		// console.log("date1",date1)
		// console.log("date2",date2)

		// console.log("date1 > date2",date1 > date2) //false
		// console.log("date1 < date2",date1 < date2) //true
		// console.log("*******************")
		var d = new Date();
		//console.log("current time:", d);
		d.setMonth(d.getMonth() - 1);
		//var lastmonth=new Date(d).getTime();
		var lastmonth = new Date(d);
		//console.log("month ago", lastmonth)
		for (let i = 0; i < this.state.listingsViewData.length; i++) {
			var last30daycount = 0;
			//console.log("in the loop",this.state.listingsViewData[i].email)
			for (let index = 0; index < obj.length; index++) {
				if (this.state.listingsViewData[i].email == obj[index].email) {
					//console.log("timestamp for email",obj[index].timeStamp)
					var viewtime = new Date(Number(obj[index].timeStamp) * 1000)
					// console.log("viewtime", viewtime)
					// console.log("lastmonth", lastmonth)
					// console.log("lastmonth > viewtime", lastmonth > viewtime)
					// //var viewTime=obj[index].timeStamp
					// console.log("*******************")
					//console.log("condition of result", lastmonth > viewtime)
					if (viewtime > lastmonth) {
						//console.log("true")
						last30daycount = last30daycount + 1;
						this.state.listingsViewData[i].last30daycount = last30daycount

					}
					else {

						//console.log("false")
					}
					// Number(obj[index].timeStamp) > Number(lastmonth) ?					
					// //last30daycount=last30daycount+1;
					// this.state.listingsViewData[i].last30daycount=1

					// :
					// console.log(false)
				}

			}


		}
		// console.log("final data array", this.state.listingsViewData)
		this.setState({

			showWhoViewrsListingsModal: true
		});

	}

	setFilterString = (event) => {
		this.setState({
			filterString: event.target.value
		})
		let filtered_array = []
		let export_places_list = []
		this.state.data.forEach(element => {
			if (element.OwnerEmail) {
				if (element.listingName.toLowerCase().includes(event.target.value.toLowerCase()) || element.OwnerEmail.toLowerCase().includes(event.target.value.toLowerCase())) {
					filtered_array.push(element)
				}
			}
			else {
				if (element.listingName.toLowerCase().includes(event.target.value.toLowerCase())) {
					filtered_array.push(element)
				}
			}


		});
		this.state.export_places_list.forEach(element => {
			if (element.OwnerEmail) {
				if (element.listingName.toLowerCase().includes(event.target.value.toLowerCase()) || element.OwnerEmail.toLowerCase().includes(event.target.value.toLowerCase())) {
					export_places_list.push(element)
				}
			}
			else {
				if (element.listingName.toLowerCase().includes(event.target.value.toLowerCase())) {
					export_places_list.push(element)
				}
			}


		});
		this.setState({
			filteredData: filtered_array,
			export_places_list_filtered: export_places_list
		})


		// const filteredData = this.state.data.filter(obj =>			
		// 	obj.OwnerEmail ? obj.OwnerEmail.toLowerCase().includes(event.target.value.toLowerCase()):'' ||  obj.listingName ? obj.listingName.toLowerCase().includes(event.target.value.toLowerCase()):''

		// );
		// console.log("filteredData",filteredData)
		// this.setState({
		// 	filteredData: filteredData
		// })
	}

	// handleSort = (column, sortDirection) => {
	// 	console.log(column)
	// 	console.log(sortDirection)

	// 	// instead of setTimeout this is where you would handle your API call.
	// 	setTimeout(() => {
	// 		const dataObj = this.state.data;
	// 		console.log(dataObj)
	// 		console.log('after')
	// 		const temp = dataObj.sort(function(a,b){
	// 			// Turn your strings into dates, and then subtract them
	// 			// to get a value that is either negative, positive, or zero.
	// 			console.log(new Date(b.start_date))
	// 			return new Date(b.start_date) - new Date(a.start_date);
	// 		});
	// 		console.log(temp)
	// 		this.setState({
	// 			data: temp
	// 		})
	// 	}, 100);
	// }

	render() {
		return (
			<div style={{ backgroundColor: '#222D32' }}>
				<MetaTags>
					<title>My Listings</title>

				</MetaTags>
				<Header />
				<SideBar />
				<div className='content-wrapper adjust-top-margin'>
					<section className='content-header' style={{ backgroundColor: 'white' }}>
						<div className='row'>
							<div className='col-md-12'>
								{
									UserRole === "admin"
										?
										<>
											<div className="visible-xs-custom">
												<h2 style={{ color: "red", marginLeft: 30 }}>For the best experience, please use a larger device when using this admin tool.</h2>

											</div>
										</>
										:
										<>
										</>
								}

								<div className='col-md-12'>
									<div className='col-md-12' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, fontSize: 30
										, fontWeight: 700
										, marginTop: 5
										, marginBottom: 5
									}}
									>
										{
											UserProfile.getUserType('user_type') === 'admin'
												?

												<div className='row col-lg-12 col-sm-12 col-xs-12 col-md-12'>
													<div className='col-lg-6 col-sm-12 col-xs-12 col-md-4' style={{ float: 'left' }}>All Listings</div>
													<div className='col-lg-6 col-sm-12 col-xs-12 col-md-8' style={{ float: 'right', paddingLeft: 0 }}>

														<div className='col-sm-12 col-xs-12 float-right-none' style={{ float: "right", width: 150 }}>

															<ReactHTMLTableToExcel
																id="test-table-xls-button"
																className="download-table-xls-button btn-hover btn btn-success mb-3"
																table="table-to-xls"
																filename="MyIndustrialNavigatorListings"
																sheet="MyListings"
																buttonText="Export Listings To Excel" />
															<table hidden className="table" style={{ overflowY: 'auto', overflowX: 'scroll', height: '400px' }} id="table-to-xls">
																<thead className="thead-dark">
																	<tr>
																		<th>rec_id</th>
																		<th>client_id</th>
																		<th>client_first_name</th>
																		<th>client_last_name</th>
																		<th>client_email_id</th>
																		<th>client_plan_id</th>
																		<th>total_views</th>
																		<th>type</th>
																		<th>coordinates</th>
																		<th>propertyTypeLabel</th>
																		<th>propertyFor</th>
																		<th>listingName</th>
																		<th>listingLogo</th>
																		<th>organizationName</th>
																		<th>image</th>
																		<th>contactName</th>
																		<th>contactTitle</th>
																		<th>contactEmail</th>
																		<th>contact</th>
																		<th>description</th>
																		<th>city</th>
																		<th>state</th>
																		<th>zipCode</th>
																		<th>address</th>
																		<th>country</th>
																		<th>acreage</th>
																		<th>price</th>
																		<th>perUnit</th>
																		<th>zoning</th>
																		<th>building</th>
																		<th>squareFeet</th>
																		<th>interiorRoofHeight</th>
																		<th>suitableFor</th>
																		<th>waterSewerInPlace</th>
																		<th>electricPowerInPlace</th>
																		<th>serviceVoltage</th>
																		<th>distanceToSubstation</th>
																		<th>naturalGasInPlace</th>
																		<th>lineDiameter</th>
																		<th>plgCertification</th>
																		<th>siteQualification</th>
																		<th>capabilities</th>
																		<th>equipments</th>
																		<th>railcarStorageCapacity</th>
																		<th>majorFeatures</th>
																		<th>specifications</th>
																		<th>productStorage</th>
																		<th>productsHandled</th>
																		<th>indoorStorage</th>
																		<th>marineAccess</th>
																		<th>bargeAccess</th>
																		<th>docInPlace</th>
																		<th>distancetoWater</th>
																		<th>classOneConnections</th>
																		<th>shortLines</th>
																		<th>status</th>
																		<th>start_date</th>
																	</tr>
																</thead>
																<tbody>

																	{this.state.export_places_list_filtered.map((res) => {
																		// console.log("res", res)
																		return (
																			<tr>
																				<th>{res.rec_id}</th>
																				<th>{res.client_id}</th>
																				<th>{res.client_first_name}</th>
																				<th>{res.client_last_name}</th>
																				<th>{res.client_email_id}</th>
																				<th>{res.client_plan_id}</th>
																				<th>{res.total_views}</th>
																				<th>{res.type}</th>
																				<th>{res.coordinates}</th>
																				<th>{res.propertyTypeLabel}</th>
																				<th>{res.propertyFor}</th>
																				<th>{res.listingName}</th>
																				<th>{res.listingLogo}</th>
																				<th>{res.organizationName}</th>
																				<th>{res.image}</th>
																				<th>{res.contactName}</th>
																				<th>{res.contactTitle}</th>
																				<th>{res.contactEmail}</th>
																				<th>{res.contact}</th>
																				<th>{res.description}</th>
																				<th>{res.city}</th>
																				<th>{res.state}</th>
																				<th>{res.zipCode}</th>
																				<th>{res.address}</th>
																				<th>{res.country}</th>
																				<th>{res.acreage}</th>
																				<th>{res.price}</th>
																				<th>{res.perUnit}</th>
																				<th>{res.zoning}</th>
																				<th>{res.building}</th>
																				<th>{res.squareFeet}</th>
																				<th>{res.interiorRoofHeight}</th>
																				<th>{res.suitableFor}</th>
																				<th>{res.waterSewerInPlace}</th>
																				<th>{res.electricPowerInPlace}</th>
																				<th>{res.serviceVoltage}</th>
																				<th>{res.distanceToSubstation}</th>
																				<th>{res.naturalGasInPlace}</th>
																				<th>{res.lineDiameter}</th>
																				<th>{res.plgCertification}</th>
																				<th>{res.siteQualification}</th>
																				<th>{res.capabilities}</th>
																				<th>{res.equipments}</th>
																				<th>{res.railcarStorageCapacity}</th>
																				<th>{res.majorFeatures}</th>
																				<th>{res.specifications}</th>
																				<th>{res.productStorage}</th>
																				<th>{res.productsHandled}</th>
																				<th>{res.indoorStorage}</th>
																				<th>{res.marineAccess}</th>
																				<th>{res.bargeAccess}</th>
																				<th>{res.docInPlace}</th>
																				<th>{res.distancetoWater}</th>
																				<th>{res.classOneConnections}</th>
																				<th>{res.shortLines}</th>
																				<th>{res.status}</th>
																				<th>{res.start_date}</th>
																			</tr>
																		)
																	}


																	)}

																</tbody>
															</table>




														</div>
														<div style={{ float: 'right', marginTop: 7, width: 250 }} className='col-lg-3 col-sm-12 col-xs-12 col-md-12 float-right-none'>
															<div className='form-group'>
																{
																	this.state.filterString != '' &&
																	<span className='pull-right cursor-pointer color-blue'
																		style={Object.assign({
																		}, {
																			fontSize: 12,
																			marginTop: -18
																		}
																		) as React.CSSProperties}
																		onClick={() => {
																			this.setState({
																				filterString: '',
																				filteredData: this.state.data
																			}, () => {

																			});
																		}}
																	>
																		Clear
																	</span>
																}
																<OverlayTrigger placement='top' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>
																			You may enter site name or lister email
																		</strong>
																	</Tooltip>
																}>
																	<input type='text' className='form-control' id='' placeholder='Enter search' onChange={this.setFilterString} value={this.state.filterString} />
																</OverlayTrigger>



															</div>
														</div>
													</div>
												</div>

												:
												<div className='row col-lg-12 col-sm-12 col-xs-12 col-md-12'>
													<div className='col-lg-6 col-sm-12 col-xs-12 col-md-4' style={{ float: 'left' }}>My Listings</div>
													<div className='col-lg-6 col-sm-12 col-xs-12 col-md-8' style={{ float: 'right', paddingLeft: 0 }}>

														<div className='col-sm-12 col-xs-12 float-right-none' style={{ float: "right", width: 150 }}>

															<ReactHTMLTableToExcel
																id="test-table-xls-button"
																className="download-table-xls-button btn-hover btn btn-success mb-3"
																table="table-to-xls"
																filename="MyIndustrialNavigatorListings"
																sheet="MyListings"
																buttonText="Export Listings To Excel" />
															<table hidden className="table" style={{ overflowY: 'auto', overflowX: 'scroll', height: '400px' }} id="table-to-xls">
																<thead className="thead-dark">
																	<tr>
																		<th>rec_id</th>
																		<th>client_id</th>
																		<th>client_first_name</th>
																		<th>client_last_name</th>
																		<th>client_email_id</th>
																		<th>client_plan_id</th>
																		<th>total_views</th>
																		<th>type</th>
																		<th>coordinates</th>
																		<th>propertyTypeLabel</th>
																		<th>propertyFor</th>
																		<th>listingName</th>
																		<th>listingLogo</th>
																		<th>organizationName</th>
																		<th>image</th>
																		<th>contactName</th>
																		<th>contactTitle</th>
																		<th>contactEmail</th>
																		<th>contact</th>
																		<th>description</th>
																		<th>city</th>
																		<th>state</th>
																		<th>zipCode</th>
																		<th>address</th>
																		<th>country</th>
																		<th>acreage</th>
																		<th>price</th>
																		<th>perUnit</th>
																		<th>zoning</th>
																		<th>building</th>
																		<th>squareFeet</th>
																		<th>interiorRoofHeight</th>
																		<th>suitableFor</th>
																		<th>waterSewerInPlace</th>
																		<th>electricPowerInPlace</th>
																		<th>serviceVoltage</th>
																		<th>distanceToSubstation</th>
																		<th>naturalGasInPlace</th>
																		<th>lineDiameter</th>
																		<th>plgCertification</th>
																		<th>siteQualification</th>
																		<th>capabilities</th>
																		<th>equipments</th>
																		<th>railcarStorageCapacity</th>
																		<th>majorFeatures</th>
																		<th>specifications</th>
																		<th>productStorage</th>
																		<th>productsHandled</th>
																		<th>indoorStorage</th>
																		<th>marineAccess</th>
																		<th>bargeAccess</th>
																		<th>docInPlace</th>
																		<th>distancetoWater</th>
																		<th>classOneConnections</th>
																		<th>shortLines</th>
																		<th>status</th>
																		<th>start_date</th>
																	</tr>
																</thead>
																<tbody>

																	{this.state.export_places_list_filtered.map((res) => {
																		// console.log("res", res)
																		return (
																			<tr>
																				<th>{res.rec_id}</th>
																				<th>{res.client_id}</th>
																				<th>{res.client_first_name}</th>
																				<th>{res.client_last_name}</th>
																				<th>{res.client_email_id}</th>
																				<th>{res.client_plan_id}</th>
																				<th>{res.total_views}</th>
																				<th>{res.type}</th>
																				<th>{res.coordinates}</th>
																				<th>{res.propertyTypeLabel}</th>
																				<th>{res.propertyFor}</th>
																				<th>{res.listingName}</th>
																				<th>{res.listingLogo}</th>
																				<th>{res.organizationName}</th>
																				<th>{res.image}</th>
																				<th>{res.contactName}</th>
																				<th>{res.contactTitle}</th>
																				<th>{res.contactEmail}</th>
																				<th>{res.contact}</th>
																				<th>{res.description}</th>
																				<th>{res.city}</th>
																				<th>{res.state}</th>
																				<th>{res.zipCode}</th>
																				<th>{res.address}</th>
																				<th>{res.country}</th>
																				<th>{res.acreage}</th>
																				<th>{res.price}</th>
																				<th>{res.perUnit}</th>
																				<th>{res.zoning}</th>
																				<th>{res.building}</th>
																				<th>{res.squareFeet}</th>
																				<th>{res.interiorRoofHeight}</th>
																				<th>{res.suitableFor}</th>
																				<th>{res.waterSewerInPlace}</th>
																				<th>{res.electricPowerInPlace}</th>
																				<th>{res.serviceVoltage}</th>
																				<th>{res.distanceToSubstation}</th>
																				<th>{res.naturalGasInPlace}</th>
																				<th>{res.lineDiameter}</th>
																				<th>{res.plgCertification}</th>
																				<th>{res.siteQualification}</th>
																				<th>{res.capabilities}</th>
																				<th>{res.equipments}</th>
																				<th>{res.railcarStorageCapacity}</th>
																				<th>{res.majorFeatures}</th>
																				<th>{res.specifications}</th>
																				<th>{res.productStorage}</th>
																				<th>{res.productsHandled}</th>
																				<th>{res.indoorStorage}</th>
																				<th>{res.marineAccess}</th>
																				<th>{res.bargeAccess}</th>
																				<th>{res.docInPlace}</th>
																				<th>{res.distancetoWater}</th>
																				<th>{res.classOneConnections}</th>
																				<th>{res.shortLines}</th>
																				<th>{res.status}</th>
																				<th>{res.start_date}</th>
																			</tr>
																		)
																	}


																	)}

																</tbody>
															</table>

														</div>
														<div style={{ float: 'right', marginTop: 7, width: 220 }} className='col-lg-3 col-sm-12 col-xs-12 col-md-12 float-right-none'>
															<div className='form-group'>
																{
																	this.state.filterString != '' &&
																	<span className='pull-right cursor-pointer color-blue'
																		style={Object.assign({
																		}, {
																			fontSize: 12,
																			marginTop: -18
																		}
																		) as React.CSSProperties}
																		onClick={() => {
																			this.setState({
																				filterString: '',
																				filteredData: this.state.data
																			}, () => {

																			});
																		}}
																	>
																		Clear
																	</span>
																}

																<OverlayTrigger placement='top' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>
																			You may enter site name or lister email
																		</strong>
																	</Tooltip>
																}>
																	<input type='text' className='form-control' id='' placeholder='Enter search' onChange={this.setFilterString} value={this.state.filterString} />
																</OverlayTrigger>

															</div>
														</div>
													</div>
												</div>

										}
									</div>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, marginTop: 15
										, marginBottom: 30
									}}
									>
										{/* <BulkUpload callBack={this.callback} /> */}
									</div>
								</div>

								<div className='col-md-12'>


									<DataTable
										title=''
										columns={columns(this.handleButtonClick)}
										data={this.state.filteredData}
										defaultSortField='start_date'
										// onSort={this.handleSort}
										pagination={true}
										paginationPerPage={50}
										defaultSortAsc={false}
										paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
									/>
								</div>

							</div>
						</div>
					</section>

				</div>

				{/* Delete modal */}
				<Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal}
					style={{ padding: '0px!important', opacity: 1 }}
					animation={false}
				>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp'
							onClick={this.handleDeleteRecord}
						>
							Confirm
						</button>
						<button type='button'
							className='btn btn-default border-radius-25-imp margin-left-15'
							onClick={this.handleDeleteModal}
						>
							Cancel
						</button>
					</Modal.Footer>
				</Modal>

				{/* Change modal */}
				<Modal show={this.state.showChangeStatusModal} onHide={this.handleChangeStatusModal}
					style={{ padding: '0px!important', opacity: 1 }}
					animation={false}
				>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure to change status of given listing?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp'
							onClick={this.handleChangeStatusRecord}
						>
							Confirm
						</button>
						<button type='button'
							className='btn btn-default border-radius-25-imp margin-left-15'
							onClick={this.handleChangeStatusModal}
						>
							Cancel
						</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showupdateplanModal} onHide={this.handleupdateplanModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								You have exceeded your listings for the current plan. Please update your plan to continue  the service.
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Do you want to update your plan?
							</p>
						</div>
					</Modal.Body><br /><br />
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>


						<Link style={{ float: "left" }} to={{ pathname: '/upgrade-plan' }} ><button type='button' className='btn btn-default border-radius-25-imp'  >Update Now</button></Link>
						{/* <Link to={{
							pathname: '/checkout',
							state: {
								addMoreClicked: true,
							}
						}}>
							<Button onclick={(this.handleupdateplanModal)} style={{ float: "left" }} variant='btn btn-default border-radius-25-imp' className='text-uppercase btn btn-default border-radius-25-imp float_left'>Update Now</Button>
							
						</Link> */}
						<Link to={{
							pathname: '/my-profile',
							state: {
								addMoreClicked: true,
							}
						}}>
							<h4>Not Now</h4>
							{/*<Button variant='btn btn-default border-radius-25-imp' className='btn btn-default border-radius-25-imp text-uppercase margin-left-15'>Not  Now</Button>*/}

						</Link>
					</Modal.Footer>
				</Modal>

				{/* modal for admin user */}
				<Modal show={this.state.showAdminDesktopModal} onHide={this.handleChangeStatusModal}
					style={{ padding: '0px!important', opacity: 1 }}
					animation={false}
				>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Please use the Desktop system for best experiences.
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>

						<button type='button'
							className='btn btn-default border-radius-25-imp margin-left-15'
							onClick={this.handleChangeStatusModal}
						>
							Cancel
						</button>
					</Modal.Footer>
				</Modal>

				{/* show listings viewers  modal  */}
				<Modal dialogClassName='custom-modal-details' show={this.state.showWhoViewrsListingsModal} onHide={this.handleshowWhoViewrsListingsModal}
					style={{ padding: '0px!important', opacity: 1, innerWidth: 500 }}
					animation={false}
					width={1000}
				>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>

							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Listing Views
							</h2>
							<hr />
							{
								planid == 4 || planid == 6 || planid == 7 ?
									<>
										<table style={{ width: "100%" }}>
											<tr>
												<th align="left">
													<h3>Name</h3>

												</th>
												<th align="left">
													<h3>Email</h3>

												</th>
												<th align="left">
													<h3>Business</h3>

												</th>
												<th className="text-center">
													<h3>Total Views</h3>
												</th>
												<th className="text-center">
													<h3>Past 30 days</h3>
												</th>
											</tr>
											{

												//JSON.stringify(this.state.listingsViewData)
												this.state.listingsViewData.map((item) => {
													// console.log(item)

													return (
														<>

															<tr>
																<td align="left">
																	<span>{item.name}</span>
																</td>
																<td align="left">
																	<span>{item.email}</span>
																</td>
																<td align="left">
																	<span>{item.company}</span>
																</td>

																<td align="center">
																	<span>{item.count}</span>
																</td>
																<td align="center">
																	<span>{item.last30daycount}</span>
																</td>
															</tr>




														</>
													)

												})
											}
										</table>

									</>
									:
									<>
										<h2 style={{ color: "red" }}>Please update your plan to Power Pro to see who viewed the listings.</h2>
									</>

							}





							<div className='col-md-12'>
								{/* <DataTable
									title=''
									columns={columnsone}
									data={this.state.viewerlist}
									//defaultSortField='start_date'
									// onSort={this.handleSort}
									pagination={true}
									paginationPerPage={50}
									defaultSortAsc={false}
									paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
								/> */}

							</div>


						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>

						<button type='button'
							className='btn btn-default border-radius-25-imp margin-left-15'
							onClick={this.handleshowWhoViewrsListingsModal}
						>
							Cancel
						</button>
					</Modal.Footer>
				</Modal>
				<DetailsModal show={this.state.detailsModal} handleClose={this.hideDetailsModal} >
					<div style={{ paddingTop: '1rem', paddingLeft: '0rem', paddingRight: '0rem' }}>

						<Details />
					</div>
				</DetailsModal>
				<Footer />
			</div>
		)
	}
}

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)

const DetailsModal = ({ handleClose, show, children }) => (
	<Modal dialogClassName='quick-view-modal width-80' size='large' aria-labelledby='contained-modal-title-lg' show={show} onHide={handleClose} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', marginBottom: '0px' }}>
			<div  >
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);

export default withRouter(MyListing);