import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesShortExt extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesShortExt extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesShortExt = null;

	constructor(parent: NorthAmericanRailLinesShortExt, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesShortExt extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesShortExt, SNorthAmericanRailLinesShortExt> {

	static dataSource: string = 'NARL_SHORT_EXT-0sxr4t';
	static sourceLayer: string = 'NARL_SHORT_EXT';

	constructor(props: PNorthAmericanRailLinesShortExt) {
		super(props, NorthAmericanRailLinesShortExt.dataSource, NorthAmericanRailLinesShortExt.sourceLayer);
		this.state = new SNorthAmericanRailLinesShortExt(this, NorthAmericanRailLinesShortExt.dataSource, NorthAmericanRailLinesShortExt.sourceLayer);
	}

	getDefaultLineColor(): string {
		return this.getShortLineColor();
	}
}
