import React, { Component } from 'react';
import 'braintree-web';
import axios from 'axios';
import DropIn from 'braintree-web-drop-in-react';
import { withRouter } from 'react-router';
import UserProfile from './UserProfile';

// const BASE_URL = 'http://3.220.32.133:5000';

interface PPayment {
	location: any;
	history: Array<string>;
}

class SPayment {
	clientToken: string = null;

	constructor() {
	}
}

class Payment extends Component<PPayment, SPayment> {
	instance;

	constructor(props: PPayment) {
		super(props);
		this.state = new SPayment();
	}

	async componentDidMount() {
		// console.log('this.props.location.state.order_amount');
		// console.log(this.props.location.state.order_amount);
		try {
			// Get a client token for authorization from your server
			const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/braintree/v1/getToken');
			const clientToken = response.data.clientToken;

			this.setState({ clientToken });
		} catch (err) {
			console.error(err);
		}
	}

	async buy() {
		try {
			// var order_id = CryptoJS.AES.encrypt(this.props.location.state.order_id, 'secret key 123');
			const email = UserProfile.getEmail();
			// var order_id = this.props.location.state.order_id;
			// Send the nonce to your server
			const { nonce } = await this
				.instance
				.requestPaymentMethod();
			const response = await axios.post(process.env.REACT_APP_BASE_URL + '/api/braintree/v1/sandbox',
				{
					paymentMethodNonce: nonce,
					order_id: this.props.location.state.order_id,
					email: email,
					order_amount: this.props.location.state.order_amount
				});
			console.log(response);
			console.log('payment done');
			this.props.history.push('/listing');
		} catch (err) {
			console.error(err);
		}
	}

	render() {
		if (!this.state.clientToken) {
			return (
				<div>
					<h1>Loading...</h1>
				</div>
			);
		}
		return (
			<div>
				<DropIn
					options={{
						authorization: this.state.clientToken
					}}
					onInstance={instance => (this.instance = instance)} />
				<div style={{ width: '100%', textAlign: 'center' }}>
					<button className='button button-large button-dark button-blue button-rounded nomargin'
						onClick={this.buy.bind(this)}
					>
						Buy
						</button>
				</div>
			</div>
		);
	}
}

export default withRouter(Payment);
