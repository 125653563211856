import React, { useState } from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

function App() {

    const [expandedPanel, setExpandedPanel] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        console.log({ event, isExpanded });
        setExpandedPanel(isExpanded ? panel : false);
    };

    return (
        <div className="App">
            <section className="section-content">
                <div className="container">
                    <Accordion expanded={expandedPanel === 'panel1'} onChange={handleAccordionChange('panel1')}>

                        <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
                            <h2 style={{ margin: 15 ,textTransform: 'none',fontSize:28}}>Railroad </h2>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="col-lg-12 mb-lg-0 mb-5">
                                <div className="iq-title-box iq-title-default">
                                    <h3>Show customers the full value proposition of your franchise by placing all your marketing information on one streamlined map.</h3>
                                    <br />
                                    <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                        <li>Display your entire rail franchise, whether it is a single shortline or a transcontinental network.</li>
                                        <li>Grow car volumes by giving prospects industrial development options for locating along your right of way.</li>
                                        <li>Promote transload and team track locations within your network to grow revenue.</li>
                                        <li>Display railcar storage, repair, and cleaning facilities within your rail network to grow business lines.</li>
                                        <li>Employ search tools that allow prospects to easily search across your multiple business lines by capabilities and Class I connections.</li>
                                        <li>Market your rail franchise through e-mail and social media campaigns.</li>
                                    </ul>
                                </div>

                            </div>
                        </AccordionDetails>

                    </Accordion>

                    <Accordion expanded={expandedPanel === 'panel2'} onChange={handleAccordionChange('panel2')}>

                        <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
                            <h2 style={{ margin: 15, textTransform: 'none',fontSize:28 }}>Economic Development</h2>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="col-lg-12 mb-lg-0 mb-5">
                                <div className="iq-title-box iq-title-default"> <h3>Market Economic Development sites in your city, state, or region.</h3>
                                    <br />
                                    <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                        <li>Allow your website visitors to search for sites using the filters and criteria that matter most.</li>
                                        <li>Streamlined capabilities to allow multiple stakeholders, such as partner EDOs, brokers, and property owners to submit and maintain information on available sites.</li>
                                        <li>Control the publication of listing information on your website by designating authorization, expiration, and update protocols.</li>
                                        <li>Consulting services are available to help establish or maintain certified site programs through our partners at Strategic Development Group (SDG).</li>
                                        <li>E-mail and social media marketing campaign capabilities are built into the web interface.</li>

                                    </ul>
                                </div>

                            </div>
                        </AccordionDetails>

                    </Accordion>

                    <Accordion expanded={expandedPanel === 'panel3'} onChange={handleAccordionChange('panel3')}>

                        <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
                            <h2 style={{ margin: 15 , textTransform: 'none',fontSize:28 }}>Transload & Warehouse</h2>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="col-lg-12 mb-lg-0 mb-5">
                                <div className="iq-title-box iq-title-default"> <h3>Market a network of transload or warehouse companies.</h3>
                                    <br />
                                    <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                        <li>Allow your website visitors to search for transload locations and warehouses using the filters and criteria that matter most.</li>
                                        <li>Overlay map layers such as shortline and Class I rail networks, intermodal terminal locations, and major ports so that prospects can grasp how your assets fit into their overall supply chain.</li>
                                        <li>E-mail and social media marketing campaign capabilities are built into the web interface.</li>

                                    </ul>
                                </div>

                            </div>
                        </AccordionDetails>

                    </Accordion>
                    <Accordion expanded={expandedPanel === 'panel4'} onChange={handleAccordionChange('panel4')}>

                        <AccordionSummary expandIcon={<ExpandMore />} style={{ flexDirection: 'row-reverse' }}>
                            <h2 style={{ margin: 15 , textTransform: 'none',fontSize:28 }}>Utility Company</h2>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="col-lg-12 mb-lg-0 mb-5">
                                <div className="iq-title-box iq-title-default">
                                    <h3>Stimulate industrial growth and economic development in your service area.</h3>
                                    <br />
                                    <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                        <li>Allow your website visitors to search for sites using the filters and criteria that matter most, including power capacity and natural gas pipeline size.</li>
                                        <li>Streamlined capabilities to allow multiple stakeholders, such as government officials, economic development organizations, chambers of commerce, brokers, and property owners to submit and maintain information on available sites.</li>
                                        <li>Control the publication of listing information on your website by designating authorization, expiration, and update protocols.</li>
                                        <li>Consulting services are available to help establish or maintain certified site programs through our partners at Strategic Development Group (SDG).</li>
                                        <li>E-mail and social media marketing campaign capabilities are built into the web interface.</li>
                                    </ul>
                                </div>

                            </div>
                        </AccordionDetails>

                    </Accordion>
                </div>
            </section>



        </div>
    );
}

export default App;