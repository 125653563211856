import React from "react";
import { Steps, Step } from "react-step-builder";
// import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step1_new from "./Step1_new";
import Step2_new from "./Step2_new";
import Step3_new from "./Step3_new";
import Step4_new from "./Step4_new";
import { Button, Col, Row } from "react-bootstrap";
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';


const Navigation = (props) => {
	 const validator = new SimpleReactValidator({
        element: (message, className) => <div className='text-danger'>{message}</div>
    });

	console.log(props);
	console.log('has prev: ',props.isLast);

	if(props.state.reacatpcha_value==undefined && props.current==2){
		const regi_errors = document.getElementById('regi_errors');
		regi_errors.innerHTML = '';
		let error_html = document.createElement('div');
		error_html.innerHTML = 'Please Select Captcha';
		document.getElementById('regi_errors').appendChild(error_html);
		document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
		props.jump(1);
	}else if(props.state.reacatpcha_value!=undefined && props.current==2){
		console.log('remove class');
		const regi_errors = document.getElementById('regi_errors');
		regi_errors.innerHTML = '';
		document.getElementById("regi_errors").classList.remove("alert-danger"); 
		
	}
	//else if(props.current==1 && props.state.reacatpcha_value!=undefined){
		//props.setState("reacatpcha_value",undefined);
	//}	
	
	var btnLabel = 'Next';
	if(props.current===3){
		btnLabel = 'Submit';
		
	}else if(props.current===4){
		btnLabel = 'Pay & Checkout';
	}else{
		btnLabel = 'Next';
	}
	
	return (
		<Row align="center">
			<div className="col-lg-1"></div>
			<div className="col-lg-5">
				{/* <Button type="primary" onClick={props.prev} style={{ margin: 12 }} className={props.current==1 ? 'hidden' : 'show color-blue label-hover'}>
				Previous
				</Button> */}
				<label onClick={props.prev} style={{float:'left',marginTop:15, fontSize:15 }} className={props.current==1 ? 'hidden' : 'show color-blue label-hover'}>
					Go Back
				</label>
			</div>
			<div className="col-lg-5">
				<Button type="primary" onClick={props.next} style={{float:'right', margin:12}} className={props.current==3 ? 'hidden' : 'show btn btn-default border-radius-25-imp' }>
				{btnLabel}
				</Button>
			</div>
			<div className="col-lg-1"></div>
		</Row>
	);
};

function MultiStepRegistration() {
	const config = {
		navigation: {
		component: Navigation, // a React component with special props provided automatically
		location: "after", // or before,
		
		}
	};

	return (
		<>
		<div id='regi_errors' className='margin-top-15'></div>
		<Steps config={config}>
			<Step component={Step1_new} />
			<Step component={Step2_new} />
			<Step component={Step3_new} />
			<Step component={Step4_new} />
		</Steps>
		</>
	);
}

export default MultiStepRegistration;
