import {
	LabelValue
	, sortArray
	, removeDuplicatesFromArr
	, TInFeature
} from '../constant/Types';

export class LiFilter {

	selectedPropertyType: string = '';
	selectedPropertyFor: string = '';
	selectedPropertyFor1: string = '';
	minPrice: string = '';
	maxPrice: string = '';
	minAcreage: string = '';
	maxAcreage: string = '';
	siteZoning: string = '';
	sitePipelineAccess: string = '';
	selectedBuildingOption: string = '';
	siteMinSquareFeet: string = '';
	siteMaxSquareFeet: string = '';
	siteMinInteriorRoofHeight: string = '';
	selectedPowerToSite: string = '';
	minServiceVoltage: string = '';
	maxServiceVoltage: string = '';
	minDistanceToSubstation: string = '';
	maxDistanceToSubstation: string = '';
	naturalGasInPlace: string = '';
	minLineDiameter: string = '';
	maxLineDiameter: string = '';
	siteSuitableFor: string = '';
	siteEnvionmental: string = '';
	airAttainmentStatus: string = '';
	capabilities: string = '';
	equipments: string = '';
	railcarStorageCapacity: string = '';
	productStorage: string = '';
	productsHandled: string = '';
	pttMinAcreage: string = '';
	pttMaxAcreage: string = '';
	warehouseMinPrice: string = '';
	warehouseMaxPrice: string = '';
	warehouseCapabilities: string = '';
	temperatureControl: string = '';
	foodGrade: string = '';
	freeTradeZone: string = '';
	warehouseMinSize: string = '';
	warehouseMaxSize: string = '';
	minRoofHeight: string = '';
	maxRoofHeight: string = '';
	parkingSpaces: string = '';
	marineAccess: string = '';
	bargeAccess: string = '';
	docInPlace: string = '';
	waterways: string = '';
	navigableWater: string = '';
	classOneMultiservedCheckbox: boolean = false;
	classOneConnections: string = '';
	shortLinesLabel: string = '';
	railServed: string = '';
	railInPlace: string = '';
	railcarStorage: string = '';
	unitTrainCapable: string = '';
	railcarCapacity: string = '';
	//
	selectedUsaStates: string = '';
	//
	address: string = '';
	radius: number = 0;
	flagRadiusDisabled: boolean = true;
	//
	cityCoordinates: Array<number> = [];
	city: string = '';
	usaState: string = '';
	zipcode: string = '';
	country: string = '';
	//
	searchByKeywordValue: string = '';

	reset() {
		this.copy(new LiFilter());
	}

	resetAddress() {
		this.selectedUsaStates = '';
		this.address = '';
		this.radius = 0;
		this.flagRadiusDisabled = true;
		//
		this.cityCoordinates = [];
		this.city = '';
		this.usaState = '';
		this.zipcode = '';
		this.country = '';
	}

	copy(copy: LiFilter): LiFilter {
		if (!copy) {
			copy = new LiFilter();
		}
		this.selectedPropertyType = copy.selectedPropertyType;
		this.selectedPropertyFor = copy.selectedPropertyFor;
		this.selectedPropertyFor1 = copy.selectedPropertyFor1;
		this.minPrice = copy.minPrice;
		this.maxPrice = copy.maxPrice;
		this.minAcreage = copy.minAcreage;
		this.maxAcreage = copy.maxAcreage;
		this.siteZoning = copy.siteZoning;
		this.sitePipelineAccess = copy.sitePipelineAccess;
		this.selectedBuildingOption = copy.selectedBuildingOption;
		this.siteMinSquareFeet = copy.siteMinSquareFeet;
		this.siteMaxSquareFeet = copy.siteMaxSquareFeet;
		this.siteMinInteriorRoofHeight = copy.siteMinInteriorRoofHeight;

		this.selectedPowerToSite = copy.selectedPowerToSite;
		this.minServiceVoltage = copy.minServiceVoltage;
		this.maxServiceVoltage = copy.maxServiceVoltage;
		this.minDistanceToSubstation = copy.minDistanceToSubstation;
		this.maxDistanceToSubstation = copy.maxDistanceToSubstation;
		this.naturalGasInPlace = copy.naturalGasInPlace;
		this.minLineDiameter = copy.minLineDiameter;
		this.maxLineDiameter = copy.maxLineDiameter;

		this.siteSuitableFor = copy.siteSuitableFor;
		this.siteEnvionmental = copy.siteEnvionmental;
		this.airAttainmentStatus = copy.airAttainmentStatus;
		this.capabilities = copy.capabilities;
		this.equipments = copy.equipments;
		this.railcarStorageCapacity = copy.railcarStorageCapacity;
		this.productStorage = copy.productStorage;
		this.productsHandled = copy.productsHandled;
		this.pttMinAcreage = copy.pttMinAcreage;
		this.pttMaxAcreage = copy.pttMaxAcreage;
		this.warehouseMinPrice = copy.warehouseMinPrice;
		this.warehouseMaxPrice = copy.warehouseMaxPrice;
		this.warehouseCapabilities = copy.warehouseCapabilities;
		this.temperatureControl = copy.temperatureControl;
		this.foodGrade = copy.foodGrade;
		this.freeTradeZone = copy.freeTradeZone;
		this.warehouseMinSize = copy.warehouseMinSize;
		this.warehouseMaxSize = copy.warehouseMaxSize;
		this.minRoofHeight = copy.minRoofHeight;
		this.maxRoofHeight = copy.maxRoofHeight;
		this.parkingSpaces = copy.parkingSpaces;
		this.marineAccess = copy.marineAccess;
		this.bargeAccess = copy.bargeAccess;
		this.docInPlace = copy.docInPlace;
		this.waterways = copy.waterways;
		this.navigableWater = copy.navigableWater;
		this.classOneMultiservedCheckbox = copy.classOneMultiservedCheckbox;
		this.classOneConnections = copy.classOneConnections;
		this.shortLinesLabel = copy.shortLinesLabel;
		this.railServed =copy.railServed;
		this.railInPlace = copy.railInPlace;
		this.railcarStorage = copy.railcarStorage;
		this.unitTrainCapable = copy.unitTrainCapable;
		this.railcarCapacity = copy.railcarCapacity;
		//
		this.selectedUsaStates = copy.selectedUsaStates;
		//
		this.address = copy.address;
		this.radius = Number(copy.radius);
		this.flagRadiusDisabled = copy.flagRadiusDisabled;
		//
		this.cityCoordinates = copy.cityCoordinates;
		this.city = copy.city;
		this.usaState = copy.usaState;
		this.zipcode = copy.zipcode;
		this.country = copy.country;
		//
		this.searchByKeywordValue = copy.searchByKeywordValue;
		return this;
	}
}

export const createIntermediateArr = (inputArr: Array<string>
	, collection: Array<TInFeature>
	, parameter: string
	, selectedPropertyType: string = ''
	, selectedSubType: string = ''): Array<TInFeature> => {
	const featureRes: Array<TInFeature> = [];
	for (let i = 0; i < inputArr.length; i++) {
		for (let j = 0; j < collection.length; j++) {
			// current record from main geojson arr
			const propParamName: string = (parameter === 'selectedUsaStates' ? 'state' : parameter);
			const currentValue = collection[j].properties[propParamName];
			if (!currentValue) {
				continue;
			}
			// current record from main multiselected state arr
			if (parameter === 'selectedUsaStates' || parameter === 'city') {
				const multiSelectArr = inputArr[i].split('*');
				for (let k = 0; k < multiSelectArr.length; k++) {
					if (currentValue === multiSelectArr[k]) {
						featureRes.push(collection[j]);
						break;
					}
				}
			} else if (parameter === 'propertyType') {
				const propertyTypeArr: Array<string> = [
					'Industrial Sites & Properties*Port Terminals*Warehouse/Distribution Center',
					'Industrial Sites & Properties*Terminals and Transload Facilities*Warehouse/Distribution Center',
					'Industrial Sites & Properties*Port Terminals',
					'Industrial Sites & Properties*Terminals and Transload Facilities',
					'Port Terminals*Warehouse/Distribution Center',
					'Terminals and Transload Facilities*Warehouse/Distribution Center',
					'Industrial Sites & Properties*Warehouse/Distribution Center',
				];
				const multiSelectedArr: Array<string> = inputArr[i].split('*');
				for (let k = 0; k < multiSelectedArr.length; k++) {
					if (multiSelectedArr[k] === 'Multipurpose' && propertyTypeArr.includes(currentValue)) {
						featureRes.push(collection[j]);
						break;
					} else if (currentValue.includes(multiSelectedArr[k])) {
						featureRes.push(collection[j]);
						break;
					}
				}
			} else {
				const multiSelectedArr: Array<string> = inputArr[i].split('*');
				for (let k = 0; k < multiSelectedArr.length; k++) {
					const searchString = '\\b' + multiSelectedArr[k] + '\\b';
					if (new RegExp(searchString, 'i').test(currentValue)) {
						featureRes.push(collection[j]);
						break;
					}
				}
			}
		}
	}
	return removeDuplicatesFromArr(featureRes);
}

export const createMinMaxValueArr = (parameter: string, inputVal: string, collection: Array<TInFeature>, type: 'min' | 'max'): Array<TInFeature> => {
	const featureRes: Array<TInFeature> = [];
	for (let i = 0; i < collection.length; i++) {
		const currentValue = collection[i].properties[parameter];
		if (type === 'min') {
			if (parseFloat(currentValue) >= parseFloat(inputVal)) {
				featureRes.push(collection[i]);
			}
		} else {
			if (parseFloat(currentValue) <= parseFloat(inputVal)) {
				featureRes.push(collection[i]);
			}
		}
	}
	return featureRes;
}
