import React, { Component } from 'react';

interface PInputCheckboxComponent {
	onHandleCheckboxChange: (name: string, value: boolean) => void;
	checked?: boolean;
	disabled?: boolean;
	className?: string;
	name?: string;
	label?: string;
	style?: React.CSSProperties;
	onMouseEnter?: () => void;
	onMouseOver?: () => void;
	onMouseLeave?: () => void;
	activeHighlightedColor?: string;
}

class SInputCheckboxComponent {
	checked: boolean = false;

	constructor() {
	}
}

class InputCheckboxComponent extends Component<PInputCheckboxComponent, SInputCheckboxComponent> {
	constructor(props: PInputCheckboxComponent) {
		super(props);
		this.state = new SInputCheckboxComponent();
		console.log('this.props.checked')
		console.log(this.props.checked)
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.checked !== '' && nextProps.checked !== undefined) {
			if (nextProps.checked !== null) {
				this.setState({ checked: nextProps.checked });
			} else {
				this.setState({ checked: false });
			}
		}
	}

	handleChange = (event) => {
		// this.props.onHandleCheckboxChange(event.target.name, !this.state.checked);
		// this.setState({ checked: !this.state.checked });
		// console.log('handleChange')
		// console.log(!this.state.checked)

		// Replaced this.state.checked with this.props.checked
		this.props.onHandleCheckboxChange(event.target.name, !this.props.checked);
		this.setState({ checked: !this.props.checked });
	}

	onMouseEnter = () => {
		const { onMouseEnter } = this.props;
		if (onMouseEnter) {
			onMouseEnter();
		}
	}

	onMouseOver = () => {
		const { onMouseOver } = this.props;
		if (onMouseOver) {
			onMouseOver();
		}
	}

	onMouseLeave = () => {
		const { onMouseLeave } = this.props;
		if (onMouseLeave) {
			onMouseLeave();
		}
	}

	render() {
		const { state, props } = this;
		const { activeHighlightedColor } = props;
		return (
			<label className={props.className ? 'form-check-label ' + props.className : 'form-check-label'}
				style={Object.assign({
				}, props.style
					, props.disabled && {
						cursor: 'not-allowed'
					})}
				onMouseEnter={() => {
					this.onMouseEnter();
				}}
				onMouseOver={() => {
					this.onMouseOver();
				}}
				onMouseLeave={() => {
					this.onMouseLeave();
				}}
			>
				<input
					type='checkbox'
					name={props.name}
					checked={props.checked}
					disabled={props.disabled}
					className='form-check-input'
					onChange={this.handleChange}
				/>
				<span
					style={Object.assign({
						marginLeft: 15
					}, props.disabled && {
						cursor: 'not-allowed'
					}, activeHighlightedColor && {
						color: activeHighlightedColor,
					})}
				>
					{
						props.label
					}
				</span>
			</label>
		)
	}
}

export default InputCheckboxComponent;