import React, { useEffect, useState } from "react";
import Header from './HeaderLTE';
import SideBar from './SideBarLTE';
import Footer from './Footer';
import { useHistory } from 'react-router'
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import { Modal } from 'react-bootstrap';


function EditMembership(props) {

    const SVGIcon = () => (
        <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
            <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
        </svg>
    )

    const history = useHistory();
    //console.log("ID : ", props.match.params.id);

    const [membershipData, setMembershipData] = useState({
        id: 1,
        Notification_for: "",
        description_data: "",
        is_visible: false
    });



    const hadleInput = async (event) => {
        event.preventDefault();
        console.log(event);
        setMembershipData({ ...membershipData, [event.target.name]: event.target.value });
    }
    const [success, setSuccess] = useState(false);
    const [checkbox, setCheckbox] = useState(false);


    const handleClose = () => {
        setSuccess(false);
        // history.push('/memberships')
    }


    const notificationFormSubmit = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/update-notification-info', {
            headers: { 'Content-Type': 'application/json' },
            ...membershipData,
            // options:
            //     `[{"multi_class_I":${memberCheck.multi_class_I}},{"favorites":${memberCheck.favorites}},{"dmsg":${memberCheck.dmsg}},{"classI":${memberCheck.classI}},{"shortline":${memberCheck.shortline}},{"intermodal":${memberCheck.intermodal}},{"ports":${memberCheck.ports}}]`

        })
            .then(function (response) {

                console.log("membershipData", membershipData)
                console.log(response)
                setSuccess(true);
            })
            .catch(function (error) {
                console.log(error);
            });

    }




    useEffect(() => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/get-notification-info', {
            id: 1
        })
            .then(function (response) {
                setMembershipData({
                    ...membershipData,
                    Notification_for: response.data[0].Notification_for,
                    description_data: response.data[0].description_data,
                    is_visible: response.data[0].is_visible == 0 ? false : true
                })
                setCheckbox(response.data[0].is_visible == 0 ? false : true)
                console.log("checkbox", checkbox)


            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])




    let setIsVisible = () => {
        if (checkbox === true) {
            setCheckbox(false)
            setMembershipData({ ...membershipData, ['is_visible']: false });
        } else if (checkbox === false) {
            setCheckbox(true)
            setMembershipData({ ...membershipData, ['is_visible']: true });
        } else {
            setCheckbox(false)
            setMembershipData({ ...membershipData, ['is_visible']: false });
        }
    }
    return (
        <div style={{ backgroundColor: '#222D32' }}>
            <Header />
            <SideBar />

            <Modal show={success} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                    <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <div className='event-type info'>
                            <div className='event-indicator '>
                                <SVGIcon />
                            </div>
                        </div>
                        <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                            Notification Data Updated
                        </h2>
                        <p className='text-muted font-size-16-imp margin-bottom-0'>
                            Notification is Updated successfully.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                    <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className='content-wrapper background-white'>
                <section className='content-header'>
                    <div className='row'>
                        <div className='col-md-12'>
                            
                            <div className='col-md-12'>
								<div className='col-md-12'>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, fontSize: 30
										, fontWeight: 700
										, marginTop: 15
										, marginBottom: 30
									}}
									>
										<div >Manage Notification</div>
									</div>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, marginTop: 15
										, marginBottom: 30
									}}
									>
									</div>
								</div>
							


                                {/* {JSON.stringify(data[0])} */}


                                <div className='col-md-12'>
                                <form method="post">
                                    <div className='col-md-12 margin-bottom-10 clear-both'>


                                        <input className='margin-bottom-10 clear-both'
                                            type='checkbox'
                                            name='is_visible'
                                            checked={checkbox}
                                            onChange={setIsVisible} /> <label>{checkbox}Show Notification Modal</label>
                                    </div>

                                    <div className='col-md-7'>
                                        <div className='form-group'>
                                            <label>Notification For</label>
                                            <input type="text"
                                            placeholder='Enter notification_for'
                                                name='Notification_for'
                                                value={membershipData.Notification_for}
                                                onChange={hadleInput} className="form-control" />


                                        </div>
                                    </div>

                                    <div className='col-md-7'>
                                        <div className='form-group'>
                                            <label>Description of Notification</label>
                                            <textarea name='description_data'
                                                className='form-control margin-bottom-0-imp' onChange={hadleInput}
                                                value={membershipData.description_data}
                                                placeholder='Enter description of notification' rows={6}></textarea>


                                        </div>
                                    </div>
                                    <div className='col-md-7     text-center' style={{ paddingTop: 20 }}>
                                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={notificationFormSubmit}>Update</button>
                                    </div>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </div>
    )
}
export default withRouter(EditMembership)