import React from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { Button } from 'react-bootstrap';
import { useMount, useSetState } from 'react-use';
import gtag from 'ga-gtag';
interface Props {
  backgroundColor: string;
}

interface State {
  run: boolean;
  steps: Step[];
}



export default function BasicDemo(props: Props) {
  const { backgroundColor } = props;
  const [{ run, steps }, setState] = useSetState<State>({
    run: false,
    steps: [
      {
        content: <p>Click anywhere outside of the tooltip to exit the tour.</p>,
        locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
        placement: 'top',
        styles: {
          options: {
            width: 500,
          },
        },
        target: '.start_tour',
        title: "WELCOME TO THE VIRTUAL TOUR!"
      },
      {
        content: <p>Switch between the street map and satellite map here.</p>,
        placement: 'bottom',
        styles: {
          options: {
            width: 300,
          },
        },
        target: '.satelite_icon',
        title: "SWITCH BETWEEN MAPS"
      },
      {
        content: <p>Select one or more facility type: Industrial Sites &amp; Properties, Port Terminals, Terminals and
          Transload Facilities, or Warehouse/Distribution Center.<br /><br /> You can refine the results further by filtering for additional criteria that are specific to each
          facility type. The additional criteria will appear after you choose one or more facility types. Look
          for the “i” icon for information about search criteria that may require additional explanation.</p>,
        placement: 'bottom',
        styles: {
          options: {
            width: 500,
          },
        },
        target: '.filter_listing_criteria',
        title: 'FILTER LISTINGS CRITERIA'
      },
      {
        content: <p>Select one or more states, or type in the name of a city, address or location and set the desired radius search by location. To search international sites, please use the address field.</p>,
        placement: 'bottom',
        styles: {
          options: {
            width: 500,
          },
        },
        target: '.filter_location',
        title: "FILTER LOCATION"
      },
      {
        content: <p>Search for text within the listing. Use a comma (,) between keywords to search for multiple (either/ or) keywords within a listing or use a plus sign (+) to find listings that include all keywords.</p>,
        styles: {
          options: {
            width: 500,
          },
        },
        placement: 'top',
        target: '.keyword_search_icon',
        title: 'KEYWORD SEARCH'
      },
      {
        content: <p>Clear all filters, or clear individual filters for the criteria that was selected.</p>,
        styles: {
          options: {
            width: 400,
          },
        },
        placement: 'left',
        target: '.clear_filters',
        title: 'CLEAR LISTINGS FILTERS'
      },
      {
        content: <p>Add or remove layers of additional information and map features on top of the map, such as
        Class I railroad networks, short line railroad networks, refined product pipelines, crude oil
        pipelines, major US ports and intermodal terminals.</p>,
        styles: {
          options: {
            width: 400,
          },
        },
        placement: 'left',
        target: '.data_layers',
        title: 'ADD DATA LAYERS'
      },
      {
        content: <p>See the different icons used on the map followed by a text description of what the icon represents.</p>,
        styles: {
          options: {
            width: 400,
          },
        },
        placement: 'left',
        target: '.legend_icon',
        title: 'LEGEND'
      },
      {
        content: <p>You can view search results in a window that is one- or two- columns wide aligned to the left
        side. Clicking the icon in this location will toggle between the 1x and 2x column views.</p>,
        styles: {
          options: {
            width: 300,
          },
        },
        placement: 'left',
        target: '.view_list_one_icon',
        title: 'VIEW LISTINGS 1X1s'
      },
      {
        content: <p>Hide listings altogether to get a full view of the map.</p>,
        styles: {
          options: {
            width: 300,
          },
        },
        placement: 'left',
        target: '.hide_listnigs_icon',
        title: 'HIDE LISTINGS'
      },
      {
        content: <p>See filtered results below.</p>,
        styles: {
          options: {
            width: 300,
          },
        },
        placement: 'right',
        target: '.listings_details_section',
        title: 'LISTINGS SEARCH RESULT'
      },
      {
        content: <p>Turn this feature on so that the listings shown on the left are restricted to the ones that are
        within your current map view.</p>,
        styles: {
          options: {
            width: 500,
          },
        },
        placement: 'right',
        target: '.toggle_button_icon',
        title: 'FILTER LISTINGS BY LOCATION'
      },
      {
        content: <p>Manage your list of favorite facilities. Favorites can be shared, printed or saved as a pdf.</p>,
        styles: {
          options: {
            width: 400,
          },
        },
        placement: 'left',
        target: '.bookmark_icon',
        title: "ADD TO FAVORITES"
      },
      {
        content: <p>Use the measuring tool to measure distance between two points. Anchor the measuring line
        and change directions by clicking a location the map. End the measuring session by pressing the
        measure tool icon again.</p>,
        styles: {
          options: {
            width: 500,
          },
        },
        placement: 'bottom',
        target: '.measure_distance',
        title: "MEASURING TOOL"
      },
      {
        content: <p>Click on any map marker to see details about the site.</p>,
        styles: {
          options: {
            width: 400,
          },
        },
        placement: 'bottom',
        target: '.general_map_view',
      }
    ],
  });

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const get = document.getElementById('lilstHelpModal');
    if (get != null) {
      get.click();
      const startevent = document.getElementById('start_button');
      startevent.click();
    }
    setState({
      run: true,
    });
    gtag('event', 'vir_tour_start', {
      poll_title: 'vir_tour_start',
    })
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    if (data.action == 'close') {
      setState({
        run: false,
      });
    }
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState({ run: false });
    }
  };

  return (
    <>

      <section className="demo__hero">
        <div style={{ alignItems: 'center' }}>
          <Button className='font-Gotham-Pro-Medium font-weight-normal-imp hide-for-mobile' id="start_button" onClick={handleClickStart} style={{ border: 'none', borderRadius: '0px !important', background: props.backgroundColor, color: 'white', height: 40, width: "70%", fontSize: 16 }} >
            <b>Take Virtual Tour</b>
            <Joyride
              callback={handleJoyrideCallback}
              continuous
              locale={{
                last: "End tour",
                back: "Previous"
              }}
              hideCloseButton
              run={run}
              scrollToFirstStep
              showProgress
              steps={steps}
              styles={{
                tooltipContainer: {
                  textAlign: "left",
                },
                options: {
                  zIndex: 10000,
                  primaryColor: '#07345a',
                  textColor: 'black',
                },
              }}
            />


          </Button>

        </div>
      </section>
    </>
  );
}