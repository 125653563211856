import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesBnsf extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesBnsf extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesBnsf = null;

	constructor(parent: NorthAmericanRailLinesBnsf, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesBnsf extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesBnsf, SNorthAmericanRailLinesBnsf> {

	static dataSource: string = 'NARL_BNSF-b2jl0g';
	static sourceLayer: string = 'NARL_BNSF';

	constructor(props: PNorthAmericanRailLinesBnsf) {
		super(props, NorthAmericanRailLinesBnsf.dataSource, NorthAmericanRailLinesBnsf.sourceLayer);
		this.state = new SNorthAmericanRailLinesBnsf(this, NorthAmericanRailLinesBnsf.dataSource, NorthAmericanRailLinesBnsf.sourceLayer);
	}
}
