import React, { Component } from 'react';
import Header from './HeaderLTE';
import SideBar from './SideBarLTE';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from './UserProfile';
import SimpleReactValidator from 'simple-react-validator';
import MetaTags from 'react-meta-tags';
import { Modal, Button } from 'react-bootstrap';
import Footer from './Footer';
import '../css/adminLTE-custom.css';
import { Link } from 'react-router-dom';
let plan = "";
let downgraded_plan = '';
let Maxlistings;
let planid;

var currentTimeInSeconds = Math.floor(Date.now() / 1000);
//alert(currentTimeInSeconds)
//let listings=3;
interface PMyProfile {
	history?: Array<string>;
}


class SMyProfile {
	proId: string = '';
	fname: string = '';
	lname: string = '';
	companyName: string = '';
	email: string = '';
	phone: string = '';
	city: string = '';
	usaState: string = '';
	planid: string = '';
	listings: string = '';
	stripe_subscription_id = '';
	sw_downgrade_plan_id = '';
	statusofuser: '';
	validity: string = '';
	showProfileSuccessModal: boolean = false;
	Promotioncodeorder: boolean = false;
	PromotioncodeorderForever: boolean = false;
	showPlanCancelModal: boolean = false;
	showSuccessModal: boolean = false;
	showFailureModal: boolean = false;



	constructor() {
	}
}

class MyProfile extends Component<PMyProfile, SMyProfile> {

	validator: SimpleReactValidator = null;
	helpers: any = null;
	handleClose: any = null;




	constructor(props: PMyProfile) {
		super(props);

		this.state = new SMyProfile();
		// ???
		this.setFName = this.setFName.bind(this);
		this.setLName = this.setLName.bind(this);
		this.setCompanyName = this.setCompanyName.bind(this);
		this.setEmail = this.setEmail.bind(this);
		this.setPhone = this.setPhone.bind(this);
		this.setCity = this.setCity.bind(this);
		this.setStateValue = this.setStateValue.bind(this);
		this.setPlandid = this.setPlandid.bind(this);
		this.setlistings = this.setlistings.bind(this);
		this.setStatusofuser = this.setStatusofuser.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.cancelUserPlan = this.cancelUserPlan.bind(this)


		this.validator = new SimpleReactValidator({
			validators: {
				phone: {  // name the rule
					message: 'The :phone must be a valid phone number.',
					rule: (val, params, validator) => {
						return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/i)
					},
					messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
					required: true  // optional
				}
			}
		});
	}

	componentWillMount() {

		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				email: UserProfile.getEmail(),
			}).then(response => {
				//console.log(response.data[0])
				if (response.status === 200) {
					this.setState({
						'proId': response.data[0].id,
						'fname': response.data[0].sw_firstname,
						'lname': response.data[0].sw_lastname,
						'companyName': response.data[0].sw_companyname,
						'email': response.data[0].sw_email,
						'phone': response.data[0].sw_phoneno,
						'city': response.data[0].sw_city,
						'usaState': response.data[0].sw_state,
						'planid': response.data[0].sw_plan_id,
						'listings': response.data[0].sw_number_listings,
						'stripe_subscription_id': response.data[0].sw_stripe_subscription_id,
						'sw_downgrade_plan_id': response.data[0].sw_downgrade_plan_id,
					});
					localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
					localStorage.setItem('membershipOptions', response.data[0].options);


					axios.post(process.env.REACT_APP_BASE_URL + '/api/get-subscription-status',
						{
							headers: {
								'Content-Type': 'application/json',
							},
							subscriptionsid: this.state.stripe_subscription_id,
							// subscriptionsid:"sub_K9SXuSOCaxAKhR"
						}).then(response => {
							if (response.status === 200) {
								//console.log(response.data.discount.coupon.duration_in_months)


								this.setState({
									'statusofuser': response.data.status,
									'validity': new Date(Number(response.data.current_period_end) * 1000).toLocaleString("en-US", { day: "numeric", month: "long", year: "numeric" }),

								});
								console.log("plan end resp stripe", response.data.current_period_end);
								var d = new Date(this.state.validity);
								console.log("plan end resp stripe", d);
								//console.log("Discount", response.data.discount.coupon.duration);
								if (response.data.discount != null) {
									if (response.data.discount.coupon.duration == "forever") {
										//alert("forever")
										this.setState({

											'validity': " ",
											PromotioncodeorderForever: true

										});
									}
									if (response.data.discount != null) {
										this.setState({ Promotioncodeorder: true });
									}
									else {
										this.setState({ Promotioncodeorder: false });
									}
									console.log("coupan months", response.data.discount.coupon.duration_in_months)
									console.log("coupan months", d.getMonth())

									if (response.data.discount.coupon.duration_in_months != null) {
										d.setMonth(d.getMonth() + response.data.discount.coupon.duration_in_months - 1);
										//alert(d.setMonth(d.getMonth() +response.data.discount.coupon.duration_in_months-1))
										this.setState(
											{
												'validity': d.toLocaleString("en-US", { day: "numeric", month: "long", year: "numeric" })

											}
										)
										//alert(this.state.validity)
									}
								}

								var d1 = new Date(this.state.validity);

								//console.log("sw_downgrade_plan_id", this.state.sw_downgrade_plan_id);
								if (this.state.sw_downgrade_plan_id != null) {

									if (currentTimeInSeconds >= d1.getTime() / 1000) {
										//console.log("call api to change plan")
										axios.post(process.env.REACT_APP_BASE_URL + '/api/downgrade-user-plan-on-billing-date',
											{
												headers: {
													'Content-Type': 'application/json',
												},
												sw_plan_downgrade_id: this.state.sw_downgrade_plan_id,
												usr_email: UserProfile.getEmail(),
												// subscriptionsid:"sub_K9SXuSOCaxAKhR"
											}).then(response => {
												if (response.status === 200) {
													console.log("updated the downgraded plan");
													axios.post(process.env.REACT_APP_BASE_URL + '/api/update-listing-status-for-downgrade-plan', {
														email: UserProfile.getEmail(),
														sw_plan_downgrade_id: this.state.sw_downgrade_plan_id,
													})
														.then(function (response) {
															if (response.status === 200) {
																axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile', {
																	email: UserProfile.getEmail(),
																})
																	.then(function (response) {
																		if (response.status === 200) {
																			localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
																			localStorage.setItem('membershipOptions', response.data[0].options);

																		}
																	})
																	.catch(function (error) {
																		console.log(error);
																	});
															}
														})
												}
											});
										// console.log("today date is(1632825728):", currentTimeInSeconds)
										// console.log("stripe date is(1635417751):", response.data.current_period_end)
									}
								}
								else {

									//alert(d1.getTime() / 1000)
									if (!this.state.PromotioncodeorderForever || String(this.state.statusofuser) != 'active') {
										//alert("plan expire on billing date")
										// console.log("the current :", currentTimeInSeconds)
										// console.log("the current plan end on:", d1.getTime() / 1000)

										if (currentTimeInSeconds >= d1.getTime() / 1000) {

											console.log("call api to change plan")
											axios.post(process.env.REACT_APP_BASE_URL + '/api/downgrade-user-plan-on-billing-date',
												{
													headers: {
														'Content-Type': 'application/json',
													},
													sw_plan_downgrade_id: 2,
													usr_email: UserProfile.getEmail(),
													// subscriptionsid:"sub_K9SXuSOCaxAKhR"
												}).then(response => {
													if (response.status === 200) {
														// console.log("updated the downgraded plan");
														// alert(currentTimeInSeconds >= d1.getTime() / 1000)
														axios.post(process.env.REACT_APP_BASE_URL + '/api/update-listing-status-for-downgrade-plan', {
															email: UserProfile.getEmail(),
															sw_plan_downgrade_id: 2,
														})
															.then(function (response) {
																if (response.status === 200) {
																	axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile', {
																		email: UserProfile.getEmail(),
																	})
																		.then(function (response) {
																			if (response.status === 200) {
																				localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
																				localStorage.setItem('membershipOptions', response.data[0].options);

																			}
																		})
																		.catch(function (error) {
																			console.log(error);
																		});
																}
															})
													}
												});
											// console.log("today date is(1632825728):", currentTimeInSeconds)
											// console.log("stripe date is(1635417751):", response.data.current_period_end)
										}
									}


								}

							}
						}).catch(function (error) {
							// handle your error
						});
				}
				else {

				}
			}).catch(function (error) {
				// handle your error
			});






		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
	}

	cancelUserPlan() {
		this.setState({ showPlanCancelModal: true });
	}

	setFName(event) {
		this.setState({ fname: event.target.value });
	}

	setLName(event) {
		this.setState({ lname: event.target.value });
	}

	setCompanyName(event) {
		this.setState({ companyName: event.target.value });
	}

	setEmail(event) {
		this.setState({ email: event.target.value });
	}

	setPhone(event) {
		this.setState({ phone: event.target.value });
	}

	setCity(event) {
		this.setState({ city: event.target.value });
	}

	setStateValue(event) {
		this.setState({ usaState: event.target.value });
	}
	setPlandid(event) {
		this.setState({ planid: event.target.value });
	}
	setlistings(event) {
		this.setState({ listings: event.target.value });
	}
	setStatusofuser(event) {
		this.setState({ statusofuser: event.target.value });
	}

	updateUser(event) {
		if (this.validator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/update-user-profile',
				{
					headers: { 'Content-Type': 'application/json' },
					proId: this.state.proId,
					fname: this.state.fname,
					lname: this.state.lname,
					companyName: this.state.companyName,
					email: this.state.email,
					phone: this.state.phone,
					city: this.state.city,
					usaState: this.state.usaState,
					planid: this.state.planid,
					listings: this.state.listings,
				}).then(response => {
					// handle your response;
					var old_email = UserProfile.getEmail()
					if (this.state.email !== old_email) {
						this.setState({ showProfileSuccessModal: true });
					}
					this.setState({ showSuccessModal: true });
					UserProfile.setEmail(this.state.email);
					UserProfile.setFirstName(this.state.fname);
					UserProfile.setLastName(this.state.lname);

				}).catch(function (error) {
					// handle your error
					var errors = document.getElementById('errors');
					errors.innerHTML = '';
					let error_html = document.createElement('div');
					if (error.response.status === 600 && error.response.data.error === 'email exists') {
						error_html.innerHTML = 'Email address already exists';
					}
					else {
						for (var i = 0; i < error.response.data.errors.length; i++) {
							error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
						}
					}
					document.getElementById('errors').appendChild(error_html);
					this.setState({ showFailureModal: true });
				});
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	handleProfileSuccessModal() {
		this.setState({ showProfileSuccessModal: false });
	}
	handlePlanCanceModal() {
		this.setState({ showPlanCancelModal: false });
	}

	handleSuccessModal() {
		this.setState({ showSuccessModal: false });
	}

	handleFailureModal() {
		this.setState({ showFailureModal: false });
	}
	updateCancelUserDetails() {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-subscription-status',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				subscriptionsid: this.state.stripe_subscription_id,
				// subscriptionsid:"sub_K9SXuSOCaxAKhR"
			}).then(response => {
				if (response.status === 200) {
					this.setState({
						'statusofuser': response.data.status,
						'validity': response.data.current_period_end,

					});
					this.props.history.push('/my-listing');
				}

				else {

				}
			}).catch(function (error) {
				// handle your error
			});

	}

	handlePlanCancel() {
		console.log("stripe_subscription_id", this.state.stripe_subscription_id)
		console.log("Email", this.state.email)

		axios.post(process.env.REACT_APP_BASE_URL + '/api/cancel-user-plan', {
			email: this.state.email,
			subscriptionID: this.state.stripe_subscription_id
		})
			.then(function (response) {
				console.log("responce of Cancel", response);

			})
			.catch(function (error) {
				console.log(error);
			});


		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				email: UserProfile.getEmail(),
			}).then(response => {
				//console.log(response.data[0])
				if (response.status === 200) {
					this.setState({
						'proId': response.data[0].id,
						'fname': response.data[0].sw_firstname,
						'lname': response.data[0].sw_lastname,
						'companyName': response.data[0].sw_companyname,
						'email': response.data[0].sw_email,
						'phone': response.data[0].sw_phoneno,
						'city': response.data[0].sw_city,
						'usaState': response.data[0].sw_state,
						'planid': response.data[0].sw_plan_id,
						'listings': response.data[0].sw_number_listings,
						'stripe_subscription_id': response.data[0].sw_stripe_subscription_id
					});
					localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
					localStorage.setItem('membershipOptions', response.data[0].options);
					this.updateCancelUserDetails();

				}
				else {

				}
			}).catch(function (error) {
				// handle your error
			});

		this.handlePlanCanceModal()

	}
	handlePlanOnClick = () => {

		if (this.state.planid == "3") {
			var obj = {
				id: 3,
				membership_level_cost: 29,
				membership_level_name: "POWER SEARCH",
				membership_plan_period: "month"

			}
			sessionStorage.setItem('planNames', JSON.stringify(obj));
			this.props.history.push('/checkout-upgrade');
		}
		else if (this.state.planid == "4") {
			var obj = {
				id: 4,
				membership_level_cost: 49,
				membership_level_name: "POWER PRO",
				membership_plan_period: "month"

			}
			sessionStorage.setItem('planNames', JSON.stringify(obj));
			this.props.history.push('/checkout-upgrade');
		}
		else if (this.state.planid == "5") {
			var obj = {
				id: 5,
				membership_level_cost: 299,
				membership_level_name: "POWER SEARCH YEARLY",
				membership_plan_period: "YEAR"

			}
			sessionStorage.setItem('planNames', JSON.stringify(obj));
			this.props.history.push('/checkout-upgrade');
		}
		else if (this.state.planid == "6") {
			var obj = {
				id: 6,
				membership_level_cost: 499,
				membership_level_name: "POWER PRO YEARLY",
				membership_plan_period: "YEAR"

			}
			sessionStorage.setItem('planNames', JSON.stringify(obj));
			this.props.history.push('/checkout-upgrade');
		}
		else {
			alert("Please upgrade your plan.")
		}



	}


	render() {
		if (this.state.planid == "2") {
			plan = "BASIC";
			Maxlistings = 5;

		} else if (this.state.planid == "3") {
			plan = "POWER SEARCH";
			Maxlistings = 25;
		}
		else if (this.state.planid == "4") {
			plan = "POWER PRO";
			Maxlistings = 100;

		} else if (this.state.planid == "5") {
			plan = "POWER SEARCH YEARLY";
			Maxlistings = 25;
		} else if (this.state.planid == "6") {
			plan = "POWER PRO YEARLY";
			Maxlistings = 100;
		} else if (this.state.planid == "8") {
			plan = "UMLIMITED LISTINGS";
			Maxlistings = null;
		} 
		else {
			plan = "PRIVATE LABEL";
			Maxlistings = null;
		}
		//
		if (this.state.sw_downgrade_plan_id == "2") {
			downgraded_plan = "BASIC";

		} else if (this.state.sw_downgrade_plan_id == "3") {
			downgraded_plan = "POWER SEARCH";
		}
		else if (this.state.sw_downgrade_plan_id == "4") {
			downgraded_plan = "POWER PRO";

		} else if (this.state.sw_downgrade_plan_id == "5") {
			downgraded_plan = "POWER SEARCH YEARLY";
		} else if (this.state.sw_downgrade_plan_id == "6") {
			downgraded_plan = "POWER PRO YEARLY";
		} else {
			downgraded_plan = "Plan Not Choosen";
		}

		return (
			//style={{ backgroundColor: '#222D32' }}
			<div style={{ backgroundColor: '#222D32' }}>
				<MetaTags>
					<title>My Profile</title>
				</MetaTags>
				<Header />
				<SideBar />
				<div className='content-wrapper background-white'>
					<section className='content-header'>
						<div className='row'>
							<div className='col-md-12'>
								<div className='col-md-12'>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, fontSize: 30
										, fontWeight: 700
										, marginTop: 15
										, marginBottom: 30
									}}
									>
										<div >My Profile</div>
									</div>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, marginTop: 15
										, marginBottom: 30
									}}
									>
									</div>
								</div>
							</div>
							<div className='col-md-offset-1 col-md-10 padding-top-50'>
								<div style={{ paddingTop: 20 }} className='col-md-12'>

									{
										this.state.planid == "7" ?
											''
											:
											<>
												<div className=''>
													{
														this.state.sw_downgrade_plan_id == null ?
															<>
																{/* <h1>null value is there</h1> */}
															</>
															:
															<>
																{
																	(UserProfile.getUserType('user_type') != 'admin') && <div className="alert" style={{ paddingLeft: 20, backgroundColor: 'red', paddingBottom: 0, paddingTop: 0 }} role="alert"><h3 style={{ color: 'white', margin: 10 }}>Note: You have downgraded your current plan to {downgraded_plan}. Your new plan will be reflected on {this.state.validity}.</h3></div>
																}


															</>
													}
													{
														Number(this.state.sw_downgrade_plan_id) == 2 || Number(this.state.planid) == 2
															?
															<>
																{/* <h3>You may manage your plan below. </h3> */}

															</>
															:
															<>
																{
																	this.state.PromotioncodeorderForever ?
																		<>
																			<div className="alert" style={{ paddingLeft: 20, paddingBottom: 0, paddingTop: 0, backgroundColor: '#0094e9' }} role="alert"><h3 style={{ color: 'white', margin: 10 }}>You are using a promotion that does not expire.</h3></div>

																		</>
																		:
																		<>
																			{
																				this.state.Promotioncodeorder ?
																					<>
																						<div className="alert" style={{ paddingLeft: 20, paddingBottom: 0, paddingTop: 0, backgroundColor: '#0094e9' }} role="alert"><h3 style={{ color: 'white', margin: 10 }} >Your promotion will expire on {this.state.validity}.  If you decide to continue your subscription your next billing date will be approximately 30 days from the trial end date. </h3></div>
																						{/* <button style={{ marginLeft: 30, marginRight: 10, marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp' onClick={() => { this.handlePlanOnClick() }}  >CONTINUE</button> */}

																					</>
																					:
																					<>
																						{/* <h3  >You may manage your plan below. </h3> */}

																					</>
																			}

																		</>
																}

															</>
													}
												</div>
											</>
									}
									{/* <div style={{ flex: '1 0 auto', color: 'rgba(0,0,0,.87)', fontSize: 22, fontWeight: 600, marginBottom: 30 }}>My Profile</div> */}
								</div>
								<div id='profile_update_success' className='col-lg-12'></div>
								{/* <div id='profile_update_success' className='alert alert-success alert-dismissible' style={{display: 'none'}}>
									<button type='button' onClick={this.dismissSuccess} className='close' data-dismiss='alert' aria-hidden='true'>×</button>
									Your profile updated successfully.
								</div> */}
								{/* <div id='errors' className='margin-top-15'></div> */}
								<form>
									<div className='col-md-4'>
										<div className='form-group'>
											<label>First name</label>
											<input type='text' className='form-control' id='' placeholder='First name' onChange={this.setFName} value={this.state.fname} />
											{this.validator.message('first name', this.state.fname, 'required')}
										</div>
									</div>
									<div className='col-md-4'>
										<div className='form-group'>
											<label>Last Name</label>
											<input type='text' className='form-control' id='' placeholder='Last name' onChange={this.setLName} value={this.state.lname} />
											{this.validator.message('last name', this.state.lname, 'required')}
										</div>
									</div>
									<div className='col-md-4'>
										<div className='form-group'>
											<label>Company name</label>
											<input type='text' className='form-control' id='' placeholder='Company name' onChange={this.setCompanyName} value={this.state.companyName} />
											{this.validator.message('company name', this.state.companyName, 'required')}
										</div>
									</div>
									<div className='col-md-4'>
										<div className='form-group'>
											<label>Email address</label>
											<input type='email' className='form-control' id='' placeholder='Email address' onChange={this.setEmail} value={this.state.email} />
											{this.validator.message('email', this.state.email, 'required')}
										</div>
									</div>
									<div className='col-md-4'>
										<div className='form-group'>
											<label>Phone Number</label>
											<input type='text' className='form-control' id='' placeholder='Phone Number' onChange={this.setPhone} value={this.state.phone} />
											{/* {this.validator.message('phone number', this.state.phone, 'required|phone')} */}
										</div>
									</div>
									<div className='col-md-4'>
										<div className='form-group'>
											<label>City</label>
											<input type='text' className='form-control' id='' placeholder='City' onChange={this.setCity} value={this.state.city} />
											{/* {this.validator.message('city', this.state.city, 'required')} */}
										</div>
									</div>
									<div className='col-md-4'>
										<div className='form-group'>
											<label>State</label>
											<select id='usaState' className='form-control custom-select-box font-weight-normal' onChange={this.setStateValue} value={this.state.usaState}>
												<option value=''>Select State..</option>
												<option value='AL'>Alabama</option>
												<option value='AK'>Alaska</option>
												<option value='AZ'>Arizona</option>
												<option value='AR'>Arkansas</option>
												<option value='CA'>California</option>
												<option value='CO'>Colorado</option>
												<option value='CT'>Connecticut</option>
												<option value='DE'>Delaware</option>
												<option value='DC'>District Of Columbia</option>
												<option value='FL'>Florida</option>
												<option value='GA'>Georgia</option>
												<option value='HI'>Hawaii</option>
												<option value='ID'>Idaho</option>
												<option value='IL'>Illinois</option>
												<option value='IN'>Indiana</option>
												<option value='IA'>Iowa</option>
												<option value='KS'>Kansas</option>
												<option value='KY'>Kentucky</option>
												<option value='LA'>Louisiana</option>
												<option value='ME'>Maine</option>
												<option value='MD'>Maryland</option>
												<option value='MA'>Massachusetts</option>
												<option value='MI'>Michigan</option>
												<option value='MN'>Minnesota</option>
												<option value='MS'>Mississippi</option>
												<option value='MO'>Missouri</option>
												<option value='MT'>Montana</option>
												<option value='NE'>Nebraska</option>
												<option value='NV'>Nevada</option>
												<option value='NH'>New Hampshire</option>
												<option value='NJ'>New Jersey</option>
												<option value='NM'>New Mexico</option>
												<option value='NY'>New York</option>
												<option value='NC'>North Carolina</option>
												<option value='ND'>North Dakota</option>
												<option value='OH'>Ohio</option>
												<option value='OK'>Oklahoma</option>
												<option value='OR'>Oregon</option>
												<option value='PA'>Pennsylvania</option>
												<option value='RI'>Rhode Island</option>
												<option value='SC'>South Carolina</option>
												<option value='SD'>South Dakota</option>
												<option value='TN'>Tennessee</option>
												<option value='TX'>Texas</option>
												<option value='UT'>Utah</option>
												<option value='VT'>Vermont</option>
												<option value='VA'>Virginia</option>
												<option value='WA'>Washington</option>
												<option value='WV'>West Virginia</option>
												<option value='WI'>Wisconsin</option>
												<option value='WY'>Wyoming</option>
											</select>
											{/* {this.validator.message('usaState', this.state.usaState, 'required')} */}
										</div>
									</div>

									<div className='col-md-4'>
										<div className='form-group'>
											<label>Current Plan</label>
											<input type='text' className='form-control' id='' value={plan} readOnly />
											{/*{this.state.planid}*/}
										</div>
									</div>
									{
										this.state.planid == "7" ?
											''
											:
											<div className='col-md-4'>
												<div className='form-group'>
													<label>Remaining Listings</label>
													<input type='text' className='form-control' id='' value={Number(localStorage.getItem('maxNoEntries') ? localStorage.getItem('maxNoEntries') : null) - Number(this.state.listings)} readOnly />
													{/*{this.state.planid}*/}
												</div>
											</div>
									}

									<div className='col-md-4'>
										<div className='form-group'>
											<label>Status</label>
											<input type='text' className='form-control text-uppercase' id='' value={this.state.planid == "2" || this.state.planid == "7" || this.state.sw_downgrade_plan_id == "2" ? "active" : this.state.statusofuser} readOnly />

										</div>
									</div>
									{
										this.state.planid == "7" ?
											''
											:
											<div className='col-md-4'>
												<div className='form-group'>
													<label>Next Billing Date</label>
													<input type='text' className='form-control' id='' value={this.state.planid == "2" ? "" : this.state.validity} readOnly />

												</div>
											</div>
									}
									{/* <div className="alert alert-success" role="alert">...</div>
									<div className="alert alert-info" role="alert">...</div>
									<div className="alert alert-warning" role="alert">...</div>
									<div className="alert alert-danger" role="alert">...</div> */}




									{/*<h1>{listingLength}</h1>*/}
									<div className='col-md-12 text-center' style={{ paddingTop: 20, paddingBottom: 20 }}>
										<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.updateUser}>Update</button>
									</div>
								</form>
							</div>
							{
								this.state.planid == "7" ?
									''
									:
									<>
										<div className='col-md-offset-1 col-md-10 padding-15'>
											{/* {
												this.state.sw_downgrade_plan_id == null ?
													<>
														
													</>
													:
													<>
														<div className="alert" style={{ paddingLeft: 20, backgroundColor: 'red', paddingBottom: 0, paddingTop: 0 }} role="alert"><h3 style={{ color: 'white' }}>Note: You have downgraded your current plan to {downgraded_plan}. Your new plan will be reflected on {this.state.validity}.</h3></div>

													</>
											} */}

											{
												Number(this.state.sw_downgrade_plan_id) == 2 || Number(this.state.planid) == 2
													?
													<>
														{/* <h3>You may manage your plan below. </h3> */}

													</>
													:
													<>
														{
															this.state.PromotioncodeorderForever ?
																<>
																	{/* <div className="alert" style={{ paddingLeft: 20, paddingBottom: 0, paddingTop: 0, backgroundColor: '#0094e9' }} role="alert"><h3 style={{ color: 'white' }}>You are using a promotion that does not expire.</h3></div> */}

																</>
																:
																<>
																	{
																		this.state.Promotioncodeorder ?
																			<>
																				{/* <div className="alert" style={{ paddingLeft: 20, paddingBottom: 0, paddingTop: 0, backgroundColor: '#0094e9' }} role="alert"><h3 style={{ color: 'white' }} >Your promotion will expire on {this.state.validity}.  If you decide to continue your subscription your next billing date will be approximately 30 days from today. </h3></div> */}
																				<button style={{ marginLeft: 30, marginRight: 10, marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp' onClick={() => { this.handlePlanOnClick() }}  >CONTINUE</button>

																			</>
																			:
																			<>
																				{/* <h3  >You may manage your plan below. </h3> */}

																			</>
																	}

																</>
														}

													</>
											}
											{/* <button style={{ marginLeft: 30 }} type='button' className='btn btn-default border-radius-25-imp' onClick={() => { this.handlePlanOnClick() }}  >CONTINUE</button><br /><br /> */}


											{
												Number(this.state.sw_downgrade_plan_id) == 2 ?
													Number(this.state.planid) == 3 ?
														<>
															<Link style={{ margin: 30 }} to={{ pathname: '/upgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >UPGRADE</button></Link>
															{
																Number(this.state.sw_downgrade_plan_id) == 2 ?
																	<>
																	</>
																	:
																	<>
																		<Link style={{ margin: 30 }} ><button style={{ marginBottom: 10 }} onClick={this.cancelUserPlan} type='button' className='btn btn-default border-radius-25-imp'  >CANCEL</button></Link>

																	</>
															}

														</>
														:
														<>


														</>


													:
													Number(this.state.planid) == 2 ?

														<>
															<Link style={{ margin: 30 }} to={{ pathname: '/upgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >UPGRADE</button></Link>

														</>

														:
														Number(this.state.planid) == 3 ?

															<>
																<Link style={{ margin: 30 }} to={{ pathname: '/upgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >UPGRADE</button></Link>
																{
																	this.state.PromotioncodeorderForever ?
																		<>
																		</>
																		:
																		<>
																			<Link style={{ margin: 30 }} to={{ pathname: '/downgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >DOWNGRADE</button></Link>

																		</>
																}
																<Link style={{ margin: 30 }} ><button style={{ marginBottom: 10 }} onClick={this.cancelUserPlan} type='button' className='btn btn-default border-radius-25-imp'  >CANCEL</button></Link>
															</>
															:
															Number(this.state.planid) == 4 ?
																<>
																	{/* <Link style={{ margin: 30 }} to={{ pathname: '/upgrade-plan' }} ><button type='button' className='btn btn-default border-radius-25-imp'  >UPGRADE</button></Link><br /><br /> */}
																	{
																		this.state.PromotioncodeorderForever ?
																			<>
																			</>
																			:
																			<>
																				<Link style={{ margin: 30 }} to={{ pathname: '/downgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >DOWNGRADE</button></Link>

																			</>
																	}
																	{
																		Number(this.state.sw_downgrade_plan_id) == 2 ?
																			<>
																			</>
																			:
																			<>
																				<Link style={{ margin: 30 }} ><button style={{ marginBottom: 10 }} onClick={this.cancelUserPlan} type='button' className='btn btn-default border-radius-25-imp'  >CANCEL</button></Link>

																			</>
																	}
																</>
																:
																Number(this.state.planid) == 5 ?
																	<>
																		<Link style={{ margin: 30 }} to={{ pathname: '/upgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >UPGRADE</button></Link>
																		{
																			this.state.PromotioncodeorderForever ?
																				<>
																				</>
																				:
																				<>
																					<Link style={{ margin: 30 }} to={{ pathname: '/downgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >DOWNGRADE</button></Link>

																				</>
																		}
																		{
																			Number(this.state.sw_downgrade_plan_id) == 2 ?
																				<>
																				</>
																				:
																				<>
																					<Link style={{ margin: 30 }} ><button style={{ marginBottom: 10 }} onClick={this.cancelUserPlan} type='button' className='btn btn-default border-radius-25-imp'  >CANCEL</button></Link>

																				</>
																		}
																	</>
																	:
																	<>
																		{
																			this.state.PromotioncodeorderForever ?
																				<>
																				</>
																				:
																				<>
																					<Link style={{ margin: 30 }} to={{ pathname: '/downgrade-plan' }} ><button style={{ marginBottom: 10 }} type='button' className='btn btn-default border-radius-25-imp'  >DOWNGRADE</button></Link>

																				</>
																		}
																		{
																			Number(this.state.sw_downgrade_plan_id) == 2 ?
																				<>
																				</>
																				:
																				<>
																					<Link style={{ margin: 30 }} ><button style={{ marginBottom: 10 }} onClick={this.cancelUserPlan} type='button' className='btn btn-default border-radius-25-imp'  >CANCEL</button></Link>

																				</>
																		}
																	</>


											}

										</div>

									</>
							}
							<div style={{ paddingTop: 10 }} className='col-md-offset-1 col-md-10'>
								<h3 style={{ fontSize: 15 }}>If you need to delete your account, please contact us at info@industrialnavigator.com.</h3>
							</div>
							<br />
						</div>





					</section>

				</div>

				<Modal show={this.state.showProfileSuccessModal} onHide={this.handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Verify Email
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								We have sent email verification link to your new email id, please verify it for next login. You are not able to Login until you verify it.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleProfileSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showSuccessModal} onHide={this.handleSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Profile Updated
							</h2>
							{/* <p className='text-muted font-size-16-imp margin-bottom-0'>
								You profile is updated successfully.
							</p> */}
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showFailureModal} onHide={this.handleFailureModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type error'>
								<div className='event-indicator '>
									<SVGIconError />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Error
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								<div id='errors'></div>
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleFailureModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				{/* cancel confirmation modal */}
				<Modal show={this.state.showPlanCancelModal} onHide={this.handlePlanCanceModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure to cancel your plan?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp' onClick={() => { this.handlePlanCancel() }}>Confirm</button>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.handlePlanCanceModal.bind(this)}>&nbsp;&nbsp;No&nbsp;&nbsp;&nbsp;&nbsp;</button>
					</Modal.Footer>
				</Modal>


				<Footer />
			</div >
		)
	}
}

export default withRouter(MyProfile);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)

const SVGIconError = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' />
	</svg>
)