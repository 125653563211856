import siteIcon from '../site-marker.png';
import portTerminalIcon from '../port-terminal.png';
import otherTerminalIcon from '../terminaltrans.png';
import warehouseIcon from '../warehouse-marker.png';
import multiPurposeIcon from '../multi.png';
import intermodalIcon from '../intermodal.png';
import majorPortsIcon from '../major-port.png';

const siteImage = new Image();
siteImage.src = siteIcon;
siteImage.width = 28;
siteImage.height = 28;
export const siteImages = ['siteImage', siteImage];

const portTerminalImage = new Image();
portTerminalImage.src = portTerminalIcon;
portTerminalImage.width = 28;
portTerminalImage.height = 28;
export const portTerminalImages = ['portTerminalImage', portTerminalImage];

const otherTerminalImage = new Image();
otherTerminalImage.src = otherTerminalIcon;
otherTerminalImage.width = 28;
otherTerminalImage.height = 28;
export const otherTerminalImages = ['otherTerminalImage', otherTerminalImage];

const warehouseImage = new Image();
warehouseImage.src = warehouseIcon;
warehouseImage.width = 28;
warehouseImage.height = 28;
export const warehouseImages = ['warehouseImage', warehouseImage];

const multiPurposeImage = new Image();
multiPurposeImage.src = multiPurposeIcon;
multiPurposeImage.width = 28;
multiPurposeImage.height = 28;
export const multiPurposeImages = ['multiPurposeImage', multiPurposeImage];

const intermodalImage = new Image();
intermodalImage.src = intermodalIcon;
intermodalImage.width = 28;
intermodalImage.height = 28;
export const intermodalImages = ['intermodalImage', intermodalImage];
