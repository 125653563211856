export interface TChildSection {
	label: string;
	displayLabel: boolean;
	name: string;
	type: 'text' | 'number' | 'image';
	fontSize?: number;
	lineWidth?: number;
	multiSelect?: boolean;
}

export interface TSection {
	heading: string;
	displayHeading: boolean;
	fontSize: number;
	lineWidth: number;
	children: Array<TChildSection>;
}

export const PDFFIELDS: Array<TSection> = [
	{
		heading: 'Listing Name',
		displayHeading: false,
		fontSize: 0,
		lineWidth: 0,
		children: [{
			label: 'Listing Name:',
			displayLabel: false,
			name: 'listingName',
			type: 'text',
			fontSize: 18,
			lineWidth: 0.5,
		}]
	},
	{
		heading: 'Address',
		displayHeading: false,
		fontSize: 0,
		lineWidth: 0,
		children: [{
			label: 'Address:',
			displayLabel: false,
			name: 'address',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		}]
	},
	{
		heading: 'Image',
		displayHeading: false,
		fontSize: 0,
		lineWidth: 0,
		children: [{
			label: 'Image:',
			displayLabel: false,
			name: 'image',
			type: 'image',
		}]
	},
	{
		heading: 'Organization Name',
		displayHeading: true,
		fontSize: 14,
		lineWidth: 0.5,
		children: [{
			label: 'Listing Logo:',
			displayLabel: false,
			name: 'listingLogo',
			type: 'image',
		},
		{
			label: 'Organization Name:',
			displayLabel: false,
			name: 'organizationName',
			type: 'text',
			fontSize: 14,
			lineWidth: 0,
		}]
	},
	{
		heading: 'Contact Information',
		displayHeading: true,
		fontSize: 14,
		lineWidth: 0.5,
		children: [{
			label: 'Contact Name:',
			displayLabel: false,
			name: 'contactName',
			type: 'text',
			fontSize: 14,
			lineWidth: 0,
		},
		{
			label: 'Contact Title:',
			displayLabel: false,
			name: 'contactTitle',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		},
		{
			label: 'Contact:',
			displayLabel: false,
			name: 'contact',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		},
		{
			label: 'Company Website:',
			displayLabel: false,
			name: 'companyWebsiteUrl',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		}]
	},
	{
		heading: 'Description',
		displayHeading: true,
		fontSize: 14,
		lineWidth: 0.5,
		children: [{
			label: 'Description:',
			displayLabel: false,
			name: 'description',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		}]
	},
	{
		heading: 'Major Features',
		displayHeading: true,
		fontSize: 14,
		lineWidth: 0.5,
		children: [{
			label: 'Major Features:',
			displayLabel: false,
			name: 'majorFeatures',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		}]
	},
	{
		heading: 'Specifications',
		displayHeading: true,
		fontSize: 14,
		lineWidth: 0.5,
		children: [{
			label: 'Specifications:',
			displayLabel: false,
			name: 'specifications',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		}]
	},
	{
		heading: 'Other Features',
		displayHeading: true,
		fontSize: 14,
		lineWidth: 0.5,
		children: [{
			label: 'Property type:',
			displayLabel: true,
			name: 'propertyType',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Price ($):',
			displayLabel: true,
			name: 'price',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Per Unit:',
			displayLabel: true,
			name: 'perUnit',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Acreage:',
			displayLabel: true,
			name: 'acreage',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Zoning:',
			displayLabel: true,
			name: 'zoning',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Building: ',
			displayLabel: true,
			name: 'building',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Building Sq. ft:',
			displayLabel: true,
			name: 'squareFeet',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Interior roof height (ft):',
			displayLabel: true,
			name: 'interiorRoofHeight',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Suitable for:',
			displayLabel: true,
			name: 'suitableFor',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Pipeline access:',
			displayLabel: true,
			name: 'pipelineAccess',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Water sewer in place:',
			displayLabel: true,
			name: 'waterSewerInPlace',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Electric service in place:',
			displayLabel: true,
			name: 'electricPowerInPlace',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Service voltage (kV):',
			displayLabel: true,
			name: 'serviceVoltage',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Distance to substation (miles):',
			displayLabel: true,
			name: 'distanceToSubstation',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Natural gas service in place:',
			displayLabel: true,
			name: 'naturalGasInPlace',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Line diameter (inches):',
			displayLabel: true,
			name: 'lineDiameter',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Environmental:',
			displayLabel: true,
			name: 'environmental',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Air attainment status:',
			displayLabel: true,
			name: 'airAttainmentStatus',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Capabilities:',
			displayLabel: true,
			name: 'capabilities',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Equipment:',
			displayLabel: true,
			name: 'equipments',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Products handled:',
			displayLabel: true,
			name: 'productsHandled',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Product storage:',
			displayLabel: true,
			name: 'productStorage',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Indoor storage:',
			displayLabel: true,
			name: 'indoorStorage',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Ports, Terminals & Transloads acreage:',
			displayLabel: true,
			name: 'indoorStorage',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Warehouse Price ($):',
			displayLabel: true,
			name: 'warehousePrice',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Per Unit:',
			displayLabel: true,
			name: 'warehousePricePerUnit',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Warehouse capabilities:',
			displayLabel: true,
			name: 'warehouseCapabilities',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Temperature control:',
			displayLabel: true,
			name: 'temperatureControl',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Food grade:',
			displayLabel: true,
			name: 'foodGrade',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'FTZ:',
			displayLabel: true,
			name: 'freeTradeZone',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
		},
		{
			label: 'Warehouse min size (sq. ft):',
			displayLabel: true,
			name: 'warehouseMinSize',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
		},
		{
			label: 'Warehouse max size (sq. ft):',
			displayLabel: true,
			name: 'warehouseMaxSize',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
		},
		{
			label: 'Clear height:',
			displayLabel: true,
			name: 'roofHeight',
			type: 'number',
			fontSize: 12,
			lineWidth: 0,
		},
		{
			label: 'Parking spaces:',
			displayLabel: true,
			name: 'parkingSpaces',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Marine access:',
			displayLabel: true,
			name: 'marineAccess',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Dock in place:',
			displayLabel: true,
			name: 'docInPlace',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Port Depth(ft):',
			displayLabel: true,
			name: 'portDepth',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Port Length(ft):',
			displayLabel: true,
			name: 'portLength',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Waterways:',
			displayLabel: true,
			name: 'waterways',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Distance to navigable water (miles):',
			displayLabel: true,
			name: 'distancetoWater',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Class I connections:',
			displayLabel: true,
			name: 'classOneConnections',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Short lines:',
			displayLabel: true,
			name: 'shortLines',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: true,
		},
		{
			label: 'Rail in place:',
			displayLabel: true,
			name: 'railInPlace',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Rail car storage capacity:',
			displayLabel: true,
			name: 'railcarCapacity',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Railcar storage:',
			displayLabel: true,
			name: 'railcarStorage',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Unit train capable:',
			displayLabel: true,
			name: 'unitTrainCapable',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Railcar spots/capacity:',
			displayLabel: true,
			name: 'railcarCapacity',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},


		{
			label: 'Total Dock Doors:',
			displayLabel: true,
			name: 'TotalDockDoors',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Drive In Dock Doors:',
			displayLabel: true,
			name: 'DriveInDockDoors',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Dock Level Doors:',
			displayLabel: true,
			name: 'DockLevelDoors',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Total Warehouse Area:',
			displayLabel: true,
			name: 'TotalWarehouseArea',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Total Warehouse Available Area:',
			displayLabel: true,
			name: 'TotalWarehouseAvailableArea',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		},
		{
			label: 'Total Warehouse Office Space Area:',
			displayLabel: true,
			name: 'TotalWarehouseOfficeSpaceArea',
			type: 'text',
			fontSize: 12,
			lineWidth: 0,
			multiSelect: false,
		}
	
	
	
	]
	}
];