import React, { useEffect, useState } from 'react';

const SearchInput = ({ value, handleInputChange }) => {
	const [inputValue, setInputValue] = useState(value);

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const onInputChange = (event) => {
		const currentValue = event.target.value;
		setInputValue(currentValue);
		handleInputChange(currentValue.trim());
	};

	return (
		<input
			type='text'
			className='form-control'
			style={{ marginBottom: '5px' }}
			onChange={onInputChange}
			value={inputValue}
		/>
	);
};

export default React.memo(SearchInput);
