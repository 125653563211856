import React, { Component } from 'react';
import Header from '../HeaderLTE';
import SideBar from '../SideBarLTE';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from '../UserProfile';
import '../../css/adminLTE-custom.css';
import { Modal, Button, Row } from 'react-bootstrap';
import Footer from '../Footer';
import InputBoxComponent from '../InputBoxComponent';
import SimpleReactValidator from 'simple-react-validator';
import InputMultiSelectComponent from '../InputMultiSelectComponent';
import InputCheckboxComponent from '../InputCheckboxComponent';

class CreateMembership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page_name: '',
            page_url: '',
            page_title: '',
            page_meta_robots: '',
            page_meta_discription: '',
            membership_is_active: '',
            showDeleteModal: false,
            showSuccessModal: false,
            showFailureModal: false,
        }

        this.validator = new SimpleReactValidator();
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    }

    handleMultiSelect = (name, valueArr) => {
        console.log(name)
        console.log(valueArr)
        console.log(valueArr.value)
        this.setState({
            [name]: valueArr.value
        })
    }
    // submit form
    configureNewPage() {
        console.log(this.state)
        if (this.validator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/create-seo-page',
                {
                    headers: { 'Content-Type': 'application/json' },
                    email: UserProfile.getEmail(),
                    page_name: this.state.page_name,
                    page_url: this.state.page_url,
                    page_title: this.state.page_title,
                    page_meta_robots: this.state.page_meta_robots,
                    page_meta_discription: this.state.page_meta_discription,
                }).then(response => {
                    // handle your response;
                    console.log('response is ')
                    console.log(response)
                    this.setState({ showSuccessModal: true });

                }).catch(function (error) {
                    // handle your error
                    this.setState({ showFailureModal: true });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }

    }

    handleSuccessModal = () => {
        this.setState({
            showSuccessModal: false
        }, () => {
            this.props.history.push('/seomodule');
        });
    }

    handleCheckboxChange = (name, value) => {
        console.log(name)
        console.log(value)
        this.setState({ [name]: value });
    }

    render() {
        return (
            <div style={{ backgroundColor: '#222D32' }}>
                <Header />
                <SideBar />
                <div className='content-wrapper background-white'>
                    <section className='content-header'>
                        <div className='row'>
                            <div className='col-md-offset-1 col-md-10'>
                                <div className='col-md-12'>
                                    <div style={{ flex: '1 0 auto', color: 'rgba(0,0,0,.87)', fontSize: 22, fontWeight: 400, marginTop: 15, marginBottom: 30 }}>Configure Page SEO</div>
                                    <form>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Page Name</label>
                                                <InputBoxComponent name='page_name' value={this.state.page_name} onHandleInputChange={this.handleInputChange} />
                                                {this.validator.message('page name', this.state.page_name, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Page URL</label>
                                                <InputBoxComponent name='page_url' value={this.state.page_url} onHandleInputChange={this.handleInputChange} />
                                                {this.validator.message('page url', this.state.page_url, 'required')}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Page Title</label>
                                                <InputBoxComponent name='page_title' value={this.state.page_title} onHandleInputChange={this.handleInputChange} />
                                                {this.validator.message('page title', this.state.page_title, 'required')}
                                            </div>
                                        </div>
                                        <div style={{ padding: 0 }} className='col-md-12'>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>Page Meta Robots</label>
                                                    <InputBoxComponent name='page_meta_robots' value={this.state.page_meta_robots} onHandleInputChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className='form-group'>
                                                    <label>Page Meta Description</label>
                                                    <InputBoxComponent name='page_meta_discription' value={this.state.page_meta_discription} onHandleInputChange={this.handleInputChange} />
                                                </div>
                                            </div>
                                        </div>



                                        <div className='col-md-12 text-center' style={{ paddingTop: 20 }}>
                                            <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.configureNewPage.bind(this)}>Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <Modal show={this.state.showSuccessModal} onHide={this.handleSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Page Details Added
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                            Page Details is added successfully.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleSuccessModal}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showFailureModal} onHide={this.handleFailureModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type error'>
                                <div className='event-indicator '>
                                    <SVGIconError />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Error
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                Something went wrong, try again later!
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleFailureModal}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Footer />
            </div>
        )
    }
}

export default withRouter(CreateMembership);

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)

const SVGIconError = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' />
    </svg>
)