export const HelpData = {
	siteSearch: {
		'label': `Site Search Criteria`,
		'title': `Site Search Criteria`,
		'body': `
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Zoning</span>: Select from a number of geographicalzones relating to land use, certifications and incentives. </div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Pipeline access</span>: Does site have pipelines for the following products located on or near the boundary of the site. </div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Suitable for</span>: This field is for the listing organization to state their preferences for site use.</div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Environmental</span>: Indicates level of environmental site assessment
				(ESA) performed for the site: Phase I assesses risk of contamination
				based primarily on prior use; Phase II typically involves sampling for toxic chemicals.</div>
			`,
    },
    pttSearch: {
		'label': `Ports, Terminals & Transloads Search Criteria`,
		'title': `Ports, Terminals & Transloads Search Criteria`,
		'body': `
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Stay tuned for field definitions to be added in the future.</span>: </div>
			`,
    },
    warehoueSearch: {
		'label': `Warehouse Search Criteria`,
		'title': `Warehouse Search Criteria`,
		'body': `
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Foreign Trade Zone</span>: Search for sites with foreign trade status or a minor
				boundary modification approved by the Foreign-Trade Zone Board (including sites with active and inactive status).</div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Clear Height</span>: The height throughout the majority of the storage
				space that products can be stacked and handled so as not to interfere with ceiling, trusses, etc.</div>
			`,
    },
    marineAccessSearch: {
		'label': `Marine Access Search Criteria`,
		'title': `Marine Access Search Criteria`,
		'body': `
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Marine access</span>: Shallow draft access is suitable for inland barges, requiring 9 -12
				feet of water depth for navigation.Deep draft access is associated with ships engaged in international trade in coastal areas, large river systems near the coast
				and the Great Lakes.</div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Total Length</span>: Total length of dock usable for vessel berths.</div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Draft</span>: Depth along the dock including any restrictions for the waterway or approaches.</div>
			`,
    },
    servingCarrierSearch: {
		'label': `Rail Access Search Criteria`,
		'title': `Rail Access Search Criteria`,
		'body': `
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Multi-served class I connections</span>: Choose this option for sites that are
				served by more than one Class I railroads, either through direct connection or by virtue of a short line that makes regular interchanges with multiple Class I carriers.</div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Class I connections</span>: Filter by one or more of the seven Class I railroads. Results show
				sites that are directly served, open to switching or connected via a short-line to the selected Class I railroad.</div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Short lines</span>: Filter to select the short line providing rail service to the property.</div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Railcar storage</span>: Search for whether the site accepts railcar storage for different categories </div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Unit train capable</span>: Unit train capable facilities are able to handle unit
				trains (typically in excess of 100 railcars or 6500 feet long) containing a single commodity without breaking up the train. </div>
				<div style="float: left; clear: both;"><span style="font-weight: bold;">Railcar spots/capacity</span>: Typically, railcars need 65 feet for the length of the car
				and the knuckle, but layout of the facility and type of railcar used can influence the capacity.</div>
			`,
	},
}