import { Tabs, Tab, Modal, Button, ProgressBar, Glyphicon, Panel } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from "react-router-dom";
import LinkedInTag from 'react-linkedin-insight';
let MembershipQuestionnaire


const recaptchaRef = React.createRef();

function Step3New(props) {
	const history = useHistory()
	const [plans, setplans] = useState([]);
	const [key, setKey] = useState('Monthly');
	const [staticPlanArr, setStaticPlanArr] = useState([]);
	const [selectedPlan, setSelectedPlanArr] = useState([]);
	const { register, handleSubmit, watch, formState: { errors } } = useForm();
	
	var MembershipQuestionnaire = {
		questionOneArr: props.state.selectquestionOneArr,
		questionTwoArr: props.state.selectquestionTwoArr,
		questionThreeArr: props.state.selectquestionThreeArr,
		questionFourArr: props.state.selectquestionFourArr,
		checkboxone: props.state.checkboxone,
		checkboxtwo: props.state.checkboxtwo

	}

	
	useEffect(() => {
		// const oneArray  = JSON.parse(sessionStorage.getItem('one'))
		// const twoArray  = JSON.parse(sessionStorage.getItem('two'))
		// const threeArray  = JSON.parse(sessionStorage.getItem('three'))
		// const fourArray  = JSON.parse(sessionStorage.getItem('four'))\


		// console.log("step 3 props selectquestionOneArr",props.state.selectquestionOneArr)

		// console.log("step 3 props selectquestionTwoArr",props.state.selectquestionTwoArr)
		// console.log("step 3 props selectquestionThreeArr",props.state.selectquestionThreeArr)
		// console.log("step 3 props selectquestionFourArr",props.state.selectquestionFourArr)

		const oneArray  = props.state.selectquestionOneArr
		const twoArray  = props.state.selectquestionTwoArr
		const threeArray  = props.state.selectquestionThreeArr
		const fourArray  = props.state.selectquestionFourArr

		

		if(oneArray == null || oneArray.length === 0 ){
			alert("Please complete the questionnaire before proceeding. ")
			props.jump(2);
		}else if(twoArray == null || twoArray.length === 0 ){
			alert("Please complete the questionnaire before proceeding. ")
			props.jump(2);
		}else if(threeArray == null || threeArray.length === 0 ){
			alert("Please complete the questionnaire before proceeding. ")
			props.jump(2);
		}else if(fourArray == null || fourArray.length === 0 ){
			alert("Please complete the questionnaire before proceeding. ")
			props.jump(2);
		}
	}, [])

	

	// const [testingDataone, setTestingDataone] = useState(questionOneArr.valueArr)
	
	// testingDataone.map((itm)=>{
	// 	console.log('itm',itm)
	// })


	// console.log("2 pops data",testingDataone)

	//console.log("2nd page data print", MembershipQuestionnaire)
	// if (MembershipQuestionnaire.questionOneArr == null && MembershipQuestionnaire.questionTwoArr == null && MembershipQuestionnaire.questionThreeArr == null && MembershipQuestionnaire.questionFourArr == null) {
	// 	alert("Please complete the questionnaire before proceeding.");
	// 	props.jump(2);
	// }
	// else if (MembershipQuestionnaire.questionTwoArr == null) {
	// 	alert("Please complete the questionnaire before proceeding.");
	// 	props.jump(2);
		
	// }
	// else if (MembershipQuestionnaire.questionThreeArr == null) {
	// 	alert("Please complete the questionnaire before proceeding.");
	// 	props.jump(2);
	// }
	// else if (MembershipQuestionnaire.questionFourArr == null) {
	// 	alert("Please complete the questionnaire before proceeding.");
	// 	props.jump(2);
	// }

	// }
	// if (MembershipQuestionnaire.questionOneArr == null) {
	// 	alert("please fill the complete form ");
	// 	props.jump(2);
	// }
	// else {
	// 	if (MembershipQuestionnaire.questionTwoArr == null) {
	// 		alert("please fill the complete form ");
	// 		props.jump(2);
	// 	}
	// 	else {
	// 		if (MembershipQuestionnaire.questionThreeArr == null) {
	// 			alert("please fill the complete form ");
	// 			props.jump(2);
	// 		}
	// 		else {
	// 			if (MembershipQuestionnaire.questionFourArr == null) {
	// 				alert("please fill the complete form ");
	// 				props.jump(2);
	// 			}
	// 		}
	// 	}

	// }



	const onSubmit = data => {
		var formData = props.state;
		console.log("value of conferm password is:", formData.confrim_password)
		if (formData.password != formData.confrim_password) {
			alert("Password does not match")
			props.jump(1)
		}
		else {

			//handle your error
			const regi_errors = document.getElementById('regi_errors');
			regi_errors.innerHTML = '';
			let error_html = document.createElement('div');
			var plan_names_arr = [];

			if (formData.plan == undefined) {
				error_html.innerHTML = 'Please Select Plan';
				document.getElementById('regi_errors').appendChild(error_html);
				document.getElementById('regi_errlistingNameors').classList.add('alert', 'alert-danger', 'display-block');
				regi_errors.scrollIntoView({ behavior: 'smooth' });
			} else {
				//console.log('submit plan');

				var choosedPlan = plans.filter(function (plansObj) { return plansObj.id == formData.plan; });
				//console.log('data: ',choosedPlan[0].membership_level_name);

				// create static array

				//get plan array
				var plan_names_arr = [{
					recId: formData.plan,
					listingName: choosedPlan[0].membership_level_name
				}];

				//get answer option array
				// var ques_ans_json_arr = null;
				// if (formData.question_1 && formData.answer_1) {
				// 	var ques_ans_json_arr = [{
				// 		question: formData.question_1,
				// 		answer: formData.answer_1
				// 	}];
				// }
				// console.log('formData of questionire: ', formData);
				// console.log("start value of MembershipQuestionnaire", MembershipQuestionnaire)

				LinkedInTag.track('9791956');
				axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user-multistep',
					{
						headers: { 'Content-Type': 'application/json' },
						fname: formData.fname,
						lname: formData.lname,
						email: formData.email,
						companyName: formData.companyName,
						password: formData.password,
						reacatpcha_value: formData.reacatpcha_value,
						question_1: formData.question_1,
						answer_1: formData.answer_1,
						plan: formData.plan,
						ques_ans_json_arr: MembershipQuestionnaire,
						plan_names_arr: plan_names_arr


					}).then(response => {
						// console.log(response);

						// handle your response;
						if (response.status === 200) {
							var planNamesArr = [];
							var newListingNamesArr = plans.filter(function (plansObj) { return plansObj.id == formData.plan; });
							setSelectedPlanArr(newListingNamesArr);

							//set plans into local storage
							localStorage.setItem('planNames', JSON.stringify(newListingNamesArr));
							localStorage.setItem('UseremailId', JSON.stringify({ email: response.data.email, password: formData.password }));
							//history.push('/checkout-subscription');


							history.push({
								pathname: '/checkout-subscription',
								state: {  // location state
									email: response.data.email,
									plan_arr: choosedPlan,
									password: formData.password
								},
							});
						}
					}).catch(function (error) {
						console.log('error api', error);

						// handle your error
						const regi_errors = document.getElementById('regi_errors');
						regi_errors.innerHTML = '';
						let error_html = document.createElement('div');
						if (error.response.status === 600 && error.response.data.error === 'email exists') {
							error_html.innerHTML = 'You already have an account, please login!';
							props.jump(1);
						} else if (error.response.status === 601) {
							error_html.innerHTML = 'Captcha verification failed, please try again!';
							props.jump(1);
						} else {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								if (error.response.data.errors[i].param == 'fname' || error.response.data.errors[i].param == 'lname' || error.response.data.errors[i].param == 'email' || error.response.data.errors[i].param == 'password') {
									props.jump(1);
								} else {
									props.jump(3);
								}
								error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
							}
						}
						document.getElementById('regi_errors').appendChild(error_html);
						document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
						regi_errors.scrollIntoView({ behavior: 'smooth' })
					});

			}
		}
	};

	const onRecaptchaChange = event => {
		console.log('Captcha value: ', event);
		props.setState({ reacatpcha_value: event });
	};


	useEffect(() => {
		setplans([{ "id": 2, "membership_level_name": "Basic", "membership_level_cost": 0, "max_no_entries": "2", "active": 1, "created_at": "2021-07-23T08:48:34.000Z", "membership_description": "Get started marketing and browsing", "membership_features": ['Search by Location', 'Message listers for pricing and details', 'Basic map layers (Class I railroads, major highways, major ports)', '5 free listings '], "membership_plan_period": 'month' },
		{ "id": 3, "membership_level_name": "Power Search", "membership_level_cost": "29", "max_no_entries": "2", "active": 1, "created_at": "2021-07-23T08:48:34.000Z", "membership_description": "Expand your search and listing capabilities", "membership_features": ['All Basic functionality+', 'Search by keyword', 'Search for listings with access to multiple rail carriers ', 'Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines)', 'Export map results and views', 'Up to 25 listings', 'See who has viewed my listings'], "membership_plan_period": 'month' },
		{ "id": 4, "membership_level_name": "Power Pro", "membership_level_cost": "49", "max_no_entries": "2", "active": 1, "created_at": "2021-07-23T08:48:34.000Z", "membership_description": "Market a portfolio of sites or a suite of logistics services", "membership_features": ['All of Basic and Power Search functionality +', 'See who has viewed your listings', 'Premium placement within search results', 'Up to 100 listings'], "membership_plan_period": 'month' },
		{ "id": 5, "membership_level_name": "Power Search (Yearly)", "membership_level_cost": "299", "max_no_entries": "25", "active": 1, "created_at": "2021-07-23T08:48:34.000Z", "membership_description": "Explore custom solutions", "membership_features": ['Implant the functionality of Industrial Navigator on your website to market your assets and services', 'Create custom map layers ', 'Market research and logistics optimization', 'Multi-user subscriptions'], "membership_plan_period": 'Year' },
		{ "id": 6, "membership_level_name": "Power Pro (Yearly)", "membership_level_cost": "499", "max_no_entries": "100", "active": 1, "created_at": "2021-07-23T08:48:34.000Z", "membership_description": "Explore custom solutions", "membership_features": ['Implant the functionality of Industrial Navigator on your website to market your assets and services', 'Create custom map layers ', 'Market research and logistics optimization', 'Multi-user subscriptions'], "membership_plan_period": 'Year' },
		]);

		// axios.get(process.env.REACT_APP_BASE_URL + '/api/get-all-membership-plans', {
		// 	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
		// })
		// .then(res => {
		// 	var plansdata = res.data;
		// 	setplans(res.data);
		// 	console.log(res.data);

		// })
		// .catch(err => {
		// 	console.log('error ', err);
		// });
	}, []);


	const validator = new SimpleReactValidator({
		element: (message, className) => <div className='text-danger'>{message}</div>
	});

	const handleOnClick = event => {
		props.setState('plan', event);
	}

	return (
		<form id="Step3New" onSubmit={handleSubmit(onSubmit)}>
			<div id='regi_errors' className='margin-top-15'></div>

			<div className='text-left color-black font-size-18  margin-bottom-20' style={{ color: '#474747' }}>Please choose a membership plan that best suits your needs.</div>

			<Tabs
				//id="controlled-tab-example"
				activeKey={key}
				onSelect={(k) => setKey(k)}
				className="mb-3"
			>
				<Tab eventKey="Monthly" title="Monthly" style={{ color: "black" }} >
					<>
						<div className='row container-fluid' >
							<div style={{ padding: 0 }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
								<div className="make-height-1070 reduce-table-height" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 950 }}>
									<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
										<div className="pricingGrid__rowItem" data-plan="premium">


											<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">BASIC</h2>
											<p style={{ fontSize: 15 }} className="copy margin--top-c4">Get started marketing and browsing. </p>

										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
										<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

											<div className="flex flex--bottom" aria-hidden="true">
												<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
													<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">FREE</div>
													<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer"></div>
													<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
													<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
												</div>

												<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
												</div>
											</div><br /><br />
											<div className="experiment">

												<div className="margin--bottom-c3">
													<Button type="submit" onClick={() => { handleOnClick(2) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: "#00B2FF", color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
												</div>
											</div>
										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
										<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

											<li className="margin--bottom-c4"> Search by Location    </li>
											<li className="margin--bottom-c4">Message listers for pricing and details  </li>
											<li className="margin--bottom-c4">Basic map layers (Class I railroads,major highways, major ports)</li>
											<li className="margin--bottom-c4">5 free listings </li>
										</ul>
									</div>
									{/*
						<div className="pricingGrid__rowItem" data-plan="premium">


							<h2 style={{ fontSize: 30, marginLeft: 15, marginTop: 30 }} className="h5 pricingPlans__header">BASIC</h2>
							<p style={{ fontSize: 15, marginLeft: 15 }} className="copy margin--top-c4">Get started marketing and browsing. </p>

						</div>
						<div className="flex flex--bottom" aria-hidden="true" style={{ marginLeft: 15 }}>
							<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
								<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">FREE</div>
								<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer"></div>
								<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
								<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
							</div>

							<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
							</div>
						</div>
						
						<div className="d-flex justify-content-center">
							
						</div>
						<ul className="pricingPlans__featuresRow__list" style={{ paddingLeft: 15, fontFamily: "Means Web,Georgia,Times,Times New Roman,serif", paddingRight: 5 }}>
							<li style={{ margin: 8 }}> Search by Location</li>
							<li style={{ margin: 8 }}> Message listers for pricing and details</li>
							<li style={{ margin: 8 }}> Basic map layers (Class I railroads, major highways, major ports)</li>
							<li style={{ margin: 8 }}> 5 free listings</li>
						</ul>
						
						<div className="d-flex justify-content-center">
							<Button type="submit" onClick={() => { handleOnClick(2) }} bsStyle="warning" bsSize="large" style={{ backgroundColor: "#00B2FF", position: 'absolute', bottom: '2%' }} className="btn btn-default border-radius-25-imp" block>
								Checkout
							</Button>
						</div>
						*/}
								</div>
							</div>

							<div style={{ padding: 0 }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
								<div className="make-height-1070 reduce-table-height" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 950 }}>
									<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
										<div className="pricingGrid__rowItem" data-plan="premium">


											<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
											<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
										<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

											<div className="flex flex--bottom" aria-hidden="true">
												<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
													<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
													<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">29</div>
													<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
													<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
												</div>

												<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
													/per month                  </div>
											</div>



											<br /><br />
											<div className="experiment">

												<div className="margin--bottom-c3">
													<Button type="submit" onClick={() => { handleOnClick(3) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
												</div>
											</div>
										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
										<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

											<li className="margin--bottom-c4"> All Basic functionality +   </li>
											<li className="margin--bottom-c4">Search by keyword  </li>
											<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
											<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
											<li className="margin--bottom-c4">Export map results and views</li>
											<li className="margin--bottom-c4">Up to 25 listings</li>
										</ul>
									</div>
									{/*
						<h3 style={{ marginLeft: 15, fontFamily: "Means Web,Georgia,Times,Times New Roman,serif", marginTop: 30, height: 30 }}>POWER SEARCH</h3>

						<div className="flex flex--bottom" aria-hidden="true" style={{ marginLeft: 15 }}>
							<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
								<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
								<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">29</div>
								<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
								<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
							</div>

							<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
								Per month                      </div>
						</div>

						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Expand your search and listing capabilities</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> All Basic functionality +</li>
							<li style={{ margin: 8 }}> Search by keyword</li>
							<li style={{ margin: 8 }}> Search for listings with access to multiple rail carriers</li>
							<li style={{ margin: 8 }}> Advanced map layers (Class I and Shortline railroads, intermodal
								terminals, crude pipelines and refined product pipelines)</li>
							<li style={{ margin: 8 }}> Export map results and views</li>
							<li style={{ margin: 8 }}> Up to 25 listings</li>

						</ul>
						<div className="d-flex justify-content-center">
							<Button type="submit" onClick={() => { handleOnClick(3) }} bsStyle="warning" bsSize="large" style={{ backgroundColor: "#8A8A8A", position: 'absolute', bottom: '2%' }} className="btn btn-default border-radius-25-imp" block>
								Checkout
							</Button>
						</div>
					*/}
								</div>
							</div>

							<div style={{ padding: 0 }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
								<div className="make-height-1070 reduce-table-height" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 950 }}>
									<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
										<div className="pricingGrid__rowItem" data-plan="premium">


											<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
											<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
										<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

											<div className="flex flex--bottom" aria-hidden="true">
												<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
													<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
													<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">49</div>
													<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
													<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
												</div>

												<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
													/per month                      </div>
											</div>

											<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

											<br /><br />
											<div className="experiment">

												<div className="margin--bottom-c3">
													<Button type="submit" onClick={() => { handleOnClick(4) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
												</div>
											</div>
										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
										<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

											<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
											<li className="margin--bottom-c4">See who has viewed your listings </li>
											<li className="margin--bottom-c4">Premium placement within search results </li>
											<li className="margin--bottom-c4">Up to 100 listings </li>
										</ul>
									</div>
									{/*

						<h3 style={{ textAlign: 'center', height: 30 }}>PREMIUM PRO</h3>
						<h4 style={{ fontSize: 20 }} className="text-center">
							$ 49/month
						</h4>
						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Market a portfolio of sites or a suite of logistics services</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> All of Basic and Power Search functionality +</li>
							<li style={{ margin: 8 }}> See who has viewed your listings</li>
							<li style={{ margin: 8 }}> Premium placement within search results</li>
							<li style={{ margin: 8 }}> Up to 100 listings</li>
							<li style={{ margin: 8 }}> See who has viewed my listings</li>

						</ul>
						<div className="d-flex justify-content-center">
							<Button type="submit" onClick={() => { handleOnClick(4) }} bsStyle="warning" bsSize="large" style={{ backgroundColor: "#00316B", position: 'absolute', bottom: '2%' }} className="btn btn-default border-radius-25-imp" block>
								Checkout
							</Button>
						</div>
						*/}
								</div>

							</div>

							<div style={{ padding: 0 }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
								<div className="make-height-1070 reduce-table-height" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 950 }}>
									<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
										<div className="pricingGrid__rowItem" data-plan="premium">


											<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">Private Label and Custom Solutions</h2>
											<p style={{ fontSize: 15 }} className="copy margin--top-c4">Explore custom solutions </p>

										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
										<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

											<div className="flex flex--bottom" aria-hidden="true">
												<div className="formattedPrice1 flex flex--left" data-behavior="formattedPrice" data-plan="premium">
													<div className="formattedPrice1__price--symbol--left" data-behavior="formattedPrice__symbol">Contact For Pricing</div>
													<div className="formattedPrice1__price--integer" data-behavior="formattedPrice__integer"></div>
													<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
													<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
												</div>
											</div>


											<hr />
											<div className="experiment">

												<div className="margin--bottom-c3">
													<p style={{ fontSize: 15 }} className="copy margin--top-c4">Call (312) 957-7757<br />info@industrialnavigator.com </p>

												</div>
											</div>
										</div>
									</div>

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
										<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

											<li className="margin--bottom-c4">Implant the functionality of Industrial Navigator on your website to market your assets and services    </li>
											<li className="margin--bottom-c4">Create custom map layers  </li>
											<li className="margin--bottom-c4">Market research and logistics optimization  </li>
											<li className="margin--bottom-c4">Multi-user subscriptions </li>
										</ul>
									</div>

									{/*

						<h3 style={{ textAlign: 'center', height: 30 }}>PRIVATE LABEL AND CORPORATE SOLUTIONS</h3>
						<h4 style={{ fontSize: 20, verticalAlign: 'text-top' }} className="text-center">
							Contact for pricing
						</h4>

						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Explore custom solutions</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> Implant the functionality of Industrial Navigator on your website to market your assets and services</li>
							<li style={{ margin: 8 }}> Create custom map layers </li>
							<li style={{ margin: 8 }}> Market research and logistics optimization</li>
							<li style={{ margin: 8 }}> Multi-user subscriptions</li>

						</ul>
						<div className="d-flex justify-content-center">
							
							<label style={{ color: '#000', position: 'absolute', bottom: '2%', fontSize: 18 }}>
								Call (312) 957-7757
							</label>

						</div>
						*/}
								</div>
							</div>
						</div>



					</>

				</Tab>
				<Tab eventKey="Yearly" title="Yearly" style={{ color: "black" }}>
					<>
						<div className='row container-fluid' >
							<div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
								<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 750 }}>
									<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
										<div className="pricingGrid__rowItem" data-plan="premium">


											<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
											<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
										<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

											<div className="flex flex--bottom" aria-hidden="true">
												<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
													<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
													<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">299</div>
													<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
													<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
												</div>

												<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
													/per year                  </div>
											</div>



											<br /><br />
											<div className="experiment">

												<div className="margin--bottom-c3">
													<Button type="submit" onClick={() => { handleOnClick(5) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
												</div>
											</div>
										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
										<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

											<li className="margin--bottom-c4"> All Basic functionality +   </li>
											<li className="margin--bottom-c4">Search by keyword  </li>
											<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
											<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
											<li className="margin--bottom-c4">Export map results and views</li>
											<li className="margin--bottom-c4">Up to 25 listings</li>
										</ul>
									</div>
									{/*
						<h3 style={{ marginLeft: 15, fontFamily: "Means Web,Georgia,Times,Times New Roman,serif", marginTop: 30, height: 30 }}>POWER SEARCH</h3>

						<div className="flex flex--bottom" aria-hidden="true" style={{ marginLeft: 15 }}>
							<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
								<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
								<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">29</div>
								<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
								<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
							</div>

							<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
								Per month                      </div>
						</div>

						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Expand your search and listing capabilities</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> All Basic functionality +</li>
							<li style={{ margin: 8 }}> Search by keyword</li>
							<li style={{ margin: 8 }}> Search for listings with access to multiple rail carriers</li>
							<li style={{ margin: 8 }}> Advanced map layers (Class I and Shortline railroads, intermodal
								terminals, crude pipelines and refined product pipelines)</li>
							<li style={{ margin: 8 }}> Export map results and views</li>
							<li style={{ margin: 8 }}> Up to 25 listings</li>

						</ul>
						<div className="d-flex justify-content-center">
							<Button type="submit" onClick={() => { handleOnClick(3) }} bsStyle="warning" bsSize="large" style={{ backgroundColor: "#8A8A8A", position: 'absolute', bottom: '2%' }} className="btn btn-default border-radius-25-imp" block>
								Checkout
							</Button>
						</div>
					*/}
								</div>
							</div>

							<div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
								<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 750 }}>
									<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
										<div className="pricingGrid__rowItem" data-plan="premium">


											<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
											<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
										<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

											<div className="flex flex--bottom" aria-hidden="true">
												<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
													<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
													<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">499</div>
													<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
													<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
												</div>

												<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
													/per year                      </div>
											</div>

											<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts">  </p>

											<br /><br />
											<div className="experiment">

												<div className="margin--bottom-c3">
													<Button type="submit" onClick={() => { handleOnClick(6) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
												</div>
											</div>
										</div>
									</div><br />

									<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
										<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

											<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
											<li className="margin--bottom-c4">See who has viewed your listings </li>
											<li className="margin--bottom-c4">Premium placement within search results </li>
											<li className="margin--bottom-c4">Up to 100 listings </li>
										</ul>
									</div>
									{/*

						<h3 style={{ textAlign: 'center', height: 30 }}>PREMIUM PRO</h3>
						<h4 style={{ fontSize: 20 }} className="text-center">
							$ 49/month
						</h4>
						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Market a portfolio of sites or a suite of logistics services</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> All of Basic and Power Search functionality +</li>
							<li style={{ margin: 8 }}> See who has viewed your listings</li>
							<li style={{ margin: 8 }}> Premium placement within search results</li>
							<li style={{ margin: 8 }}> Up to 100 listings</li>
							<li style={{ margin: 8 }}> See who has viewed my listings</li>

						</ul>
						<div className="d-flex justify-content-center">
							<Button type="submit" onClick={() => { handleOnClick(4) }} bsStyle="warning" bsSize="large" style={{ backgroundColor: "#00316B", position: 'absolute', bottom: '2%' }} className="btn btn-default border-radius-25-imp" block>
								Checkout
							</Button>
						</div>
						*/}
								</div>

							</div>

						</div>
					</>
				</Tab>

			</Tabs>




			{/* {plans.map((plan, index) => (
				<>
					<div>
						<input
							key={index}
							type='radio'
							name='plan'
							checked={props.checked}
							onChange={props.handleChange}
							className="Step3New_radio"
							value={plan.id}
						/>
						{' '}
						<label>
							{plan.membership_level_name} ({plan.membership_level_cost > 0 ? '$' : ''}{plan.membership_level_cost > 0 ? plan.membership_level_cost : 'Free'})
						</label>
					</div>
				</>
			))} */}

			{/* <div className='col_full col-lg-12 center padding-top-20 margin-top-7'>
				<Button type="submit" className="btn btn-default border-radius-25-imp">
					Register
				</Button>
			</div> */}
		</form>
	);
}

export default Step3New;
