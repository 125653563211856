import React, { Component } from 'react';

interface PInputTextareaComponent {
	value?: string;
	onHandleTextareaChange: (name: string, value: string) => void;
	name: string;
}

class SInputTextareaComponent {
	value: string = '';

	constructor() {
	}
}

class InputTextareaComponent extends Component<PInputTextareaComponent, SInputTextareaComponent> {
	constructor(props: PInputTextareaComponent) {
		super(props);
		this.state = new SInputTextareaComponent();
	}

	handleTextareaChange = (event) => {
		const value = event.target.value.replace(/[^\x00-\x7F]/g, '');
		this.setState({ value: value });
		this.props.onHandleTextareaChange(event.target.name, value);
	}

	render() {
		return (
			<textarea
				name={this.props.name}
				className='form-control'
				style={{ marginBottom: '5px' }}
				onChange={(event) => { this.handleTextareaChange(event) }}
				value={this.props.value ? this.props.value : this.state.value}
			></textarea>
		)
	}
}

export default InputTextareaComponent;