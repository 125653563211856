export const roadWaysMapLayersLines: Array<string> = [
	'road-motorway-trunk'
	, 'road-motorway-trunk-case'
	, 'road-major-link'
	, 'road-major-link-case'
	, 'road-primary'
	, 'road-primary-case'
	, 'road-secondary-tertiary'
	, 'road-secondary-tertiary-case'
	, 'road-minor'
	, 'road-minor-case'
	, 'road-minor-low'
	, 'road-street'
	, 'road-street-case'
	, 'road-street-low'
];

export const roadWaysMapLayersText: Array<string> = [
	'road-number-shield'
	, 'road-label'
];
