import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesUp extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesUp extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesUp = null;

	constructor(parent: NorthAmericanRailLinesUp, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesUp extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesUp, SNorthAmericanRailLinesUp> {

	static dataSource: string = 'NARL_UP-08mm0i';
	static sourceLayer: string = 'NARL_UP';

	constructor(props: PNorthAmericanRailLinesUp) {
		super(props, NorthAmericanRailLinesUp.dataSource, NorthAmericanRailLinesUp.sourceLayer);
		this.state = new SNorthAmericanRailLinesUp(this, NorthAmericanRailLinesUp.dataSource, NorthAmericanRailLinesUp.sourceLayer);
	}
}
