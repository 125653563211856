import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection'
import { Link } from 'react-router-dom';
import img from '../../public/assets/images/others/02.png'
import Search from "../../public/assets/revslider/assets/Search.svg";
import CrusialLinks from '../../../CrusialLinkSearcher'
const Index = props => {

    return (
        <>
            <section className="section-content">
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: -20 }} className="col-lg-12 col-sm-12 mb-lg-0 mb-5">
                            <TitleSection
                                className='iq-title-box-2 text-left iq-title-box-1'
                                title='Search for Free!'
                                description='Search all of our listings, or search by category below. '
                                titleIcon='' />
                        </div>
                        <div className="col-lg-12 col-sm-12 mb-lg-0 mb-5">
                            <CrusialLinks />
                        </div>



                    </div>
                </div>
            </section>
        </>
    );
}

export default Index