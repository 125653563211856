import React from 'react';
import { Layer, Source } from 'react-mapbox-gl';

import MbExpr from '../../helpers/mapboxExpressions';
import rrNameToAcronym from '../../constant/RailroadNameToAcronym';

export interface PNorthAmericanRailLinesLayer {
	linesToInclude: Array<string>;
	flagShowLabels: boolean;
	flagShowLines: boolean;
}

export class SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesLayer<PNorthAmericanRailLinesLayer, SNorthAmericanRailLinesLayer> = null;
	linesToInclude: Array<string> = [];
	flagShowLabels: boolean = true;
	flagShowLines: boolean = true;
	dataSource: string = null;
	sourceLayer: string = null;

	constructor(parent: NorthAmericanRailLinesLayer<PNorthAmericanRailLinesLayer, SNorthAmericanRailLinesLayer>
		, dataSource: string, sourceLayer: string) {
		this.ref = parent;
		const { props } = parent;
		this.linesToInclude = props.linesToInclude;
		this.flagShowLabels = props.flagShowLabels;
		this.flagShowLines = props.flagShowLines;
		this.dataSource = dataSource;
		this.sourceLayer = sourceLayer;
	}
}



export default class NorthAmericanRailLinesLayer<P extends PNorthAmericanRailLinesLayer, S extends SNorthAmericanRailLinesLayer> extends React.Component<P, S> {

	constructor(props: P, dataSource: string, sourceLayer: string) {
		super(props);
	}

	static fillClassOneColorsArr(): Array<string> {
		const classOneArr: Array<string> = [
			'UP'
			, 'CSX'
			, 'CSXT'
			, 'NS'
			, 'CN'
			, 'BNSF'
			, 'KCS'
			, 'CPRS'
			, 'CP'
		];
		const classOneColorsInitial: Array<string> = [
			'UP', '#fcbf28'
			, 'CSX', '#073283'
			, 'CSXT', '#073283'
			, 'NS', '#000000'
			, 'CN', '#ffffff'
			, 'BNSF', '#d9623a'
			, 'KCS', '#868686'
			, 'CPRS', '#ff0000'
			, 'CP', '#ff0000'
		];
		const res: Array<string> = [];
		for (let i = 0; i < classOneColorsInitial.length; i++) {
			res.push(classOneColorsInitial[i]);
		}
		let suffix: string = '';
		for (let i = 0; i < 12; i++) {
			suffix += '-';
			for (let j = 0; j < classOneArr.length; j++) {
				res.push(classOneArr[j] + suffix);
				res.push('#ff00ff'); // Magenta color
			}
		}
		return res;
	}

	static classOneColors: Array<string> = NorthAmericanRailLinesLayer.fillClassOneColorsArr();

	getClassOneColors(): Array<string> {
		return NorthAmericanRailLinesLayer.classOneColors;
	}

	getShortLineColor(): string {
		return '#00ffff';
	}

	getMultiClassILineColor(): string {
		return '#ff00ff'; // Magenta color
	}

	getDefaultLineColor(): string {
		return this.getMultiClassILineColor();
	}

	componentWillReceiveProps(nextProps: PNorthAmericanRailLinesLayer) {
		if (!nextProps.linesToInclude && nextProps.linesToInclude.length > 0) {
			this.setState({
				linesToInclude: nextProps.linesToInclude,
			});
		}
		this.forceUpdate();
	}

	render() {
		const { state } = this;
		const { linesToInclude } = state;
		const { flagShowLines, flagShowLabels } = state;
		const { dataSource, sourceLayer } = state;
		const classOneInclude = (linesToInclude.length === 0 || (linesToInclude.length === 1 && linesToInclude[0] === '') ?
			[] :
			linesToInclude.map(val => rrNameToAcronym.classOne[val] || val)
		).flat();
		const shortLineInclude = (linesToInclude.length === 0 || (linesToInclude.length === 1 && linesToInclude[0] === '') ?
			[] :
			linesToInclude.map(val => rrNameToAcronym.shortLine[val] || val)
		).flat();
		const rrFilter = ['any',
			['in', ['get', 'RROWNER1'], ['literal', shortLineInclude]],
			['in', ['get', 'RROWNER2'], ['literal', shortLineInclude]],
			['in', ['get', 'RROWNER3'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS1'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS2'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS3'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS4'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS5'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS6'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS7'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS8'], ['literal', shortLineInclude]],
			['in', ['get', 'TRKRGHTS9'], ['literal', shortLineInclude]],
			['in', ['get', 'RROWNER1'], ['literal', classOneInclude]],
			['in', ['get', 'RROWNER2'], ['literal', classOneInclude]],
			['in', ['get', 'RROWNER3'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS1'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS2'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS3'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS4'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS5'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS6'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS7'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS8'], ['literal', classOneInclude]],
			['in', ['get', 'TRKRGHTS9'], ['literal', classOneInclude]],
		]
		return (
			<React.Fragment>
				{flagShowLines &&
					<Layer
						id={'north-american-rail-lines-lines_' + dataSource}
						type='line'
						sourceId={dataSource}
						sourceLayer={sourceLayer}
						layerOptions={{
							'source-layer': sourceLayer,
						}}
						filter={rrFilter}
						layout={{ 'visibility': 'visible' }}
						paint={{
							'line-color': MbExpr.match(
								['concat'
									, ''
									, ['case', ['has', 'RROWNER1'], ['get', 'RROWNER1'], '']
									, ['case', ['has', 'RROWNER2'], ['concat', '-'], '']
									, ['case', ['has', 'RROWNER3'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS1'], ['case', ['has', 'RROWNER1'], ['concat', '-'], ['get', 'TRKRGHTS1']], '']
									, ['case', ['has', 'TRKRGHTS2'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS3'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS4'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS5'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS6'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS7'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS8'], ['concat', '-'], '']
									, ['case', ['has', 'TRKRGHTS9'], ['concat', '-'], '']
								]
								, this.getClassOneColors()
								, this.getDefaultLineColor()
							),
							'line-width': [
								'interpolate', ['exponential', 2], ['zoom'],
								10, 3
								, 14, 4
								, 20, 10
								, 22, 16
								, 24, 22
							]
						}}
					/>
				}
				{flagShowLabels &&
					<Layer
						id={'north-american-rail-lines-labels_' + dataSource}
						type='symbol'
						sourceId={dataSource}
						sourceLayer={sourceLayer}
						filter={rrFilter}
						layerOptions={{
							'source-layer': sourceLayer
						}}
						layout={{
							'visibility': 'visible',
							'symbol-placement': 'line',
							'text-font': ['Open Sans Regular'],
							'text-field':
								['concat'
									, ''
									, ['case', ['has', 'RROWNER1'], ['concat', '', ['get', 'RROWNER1']], '']
									, ['case', ['has', 'RROWNER2'], ['concat', ' - ', ['get', 'RROWNER2']], '']
									, ['case', ['has', 'RROWNER3'], ['concat', ' - ', ['get', 'RROWNER3']], '']
									, ['case', ['has', 'TRKRGHTS1'], ['case', ['has', 'RROWNER1'], ['concat', ' - ', ['get', 'TRKRGHTS1']], ['get', 'TRKRGHTS1']], '']
									, ['case', ['has', 'TRKRGHTS2'], ['concat', ' - ', ['get', 'TRKRGHTS2']], '']
									, ['case', ['has', 'TRKRGHTS3'], ['concat', ' - ', ['get', 'TRKRGHTS3']], '']
									, ['case', ['has', 'TRKRGHTS4'], ['concat', ' - ', ['get', 'TRKRGHTS4']], '']
									, ['case', ['has', 'TRKRGHTS5'], ['concat', ' - ', ['get', 'TRKRGHTS5']], '']
									, ['case', ['has', 'TRKRGHTS6'], ['concat', ' - ', ['get', 'TRKRGHTS6']], '']
									, ['case', ['has', 'TRKRGHTS7'], ['concat', ' - ', ['get', 'TRKRGHTS7']], '']
									, ['case', ['has', 'TRKRGHTS8'], ['concat', ' - ', ['get', 'TRKRGHTS8']], '']
									, ['case', ['has', 'TRKRGHTS9'], ['concat', ' - ', ['get', 'TRKRGHTS9']], '']
								]
							,
							'text-size': 20,
						}}
						paint={{
							'text-halo-color': '#f5f0e1',
							'text-halo-width': 3
						}}
					/>
				}
			</React.Fragment>
		)
	}
}
