import React, { useEffect, useState } from "react";
import Header from '../HeaderLTE';
import SideBar from '../SideBarLTE';
import Footer from '../Footer';
import { useHistory } from 'react-router'
import InputMultiSelectComponent from '../InputMultiSelectComponent';
import InputCheckboxComponent from '../InputCheckboxComponent';
import InputBoxComponent from '../InputBoxComponent'
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Alert } from "antd";


function EditMembership(props) {

    const SVGIcon = () => (
        <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
            <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
        </svg>
    )

    const history = useHistory();
    //console.log("ID : ", props.match.params.id);
    const [FName, setFName] = useState("")
    const [LName, setLName] = useState("")
    const [email, setemail] = useState("")
    const [oldemail, setoldemail] = useState("")
    const [company, setcompany] = useState("")

    const hadleInput = async (event) => {
        event.preventDefault();
        console.log(event);
        if ([event.target.name] == "FName") {
            setFName(event.target.value)
        }
        else if ([event.target.name] == "LName") {
            setLName(event.target.value)
        }
        else if ([event.target.name] == "email") {
            setemail(event.target.value)
        }
        else if ([event.target.name] == "company") {
            setcompany(event.target.value)
        }
        // setMembershipData({ ...membershipData, [event.target.name]: event.target.value });
    }
    const [success, setSuccess] = useState(false);

    const handleClose = () => {
        setSuccess(false);
        history.push('/private-clients')
    }
    const FormSubmit = () => {

        axios.post(process.env.REACT_APP_BASE_URL + '/api/update-private-user', {
            headers: { 'Content-Type': 'application/json' },
            FName: FName,
            id: props.match.params.id,
            LName: LName,
            company: company,
            email: email,
            full_name: FName + ' ' + LName,
            oldemail: oldemail
        })
            .then(function (response) {
                console.log(response.status)
                if(response.status != 200){
                    alert("Sorry, this email address already exists in the system. Please try again. ")
                }
                else{
                    setSuccess(true);
                }
                
            })
            .catch(function (error) {
                console.log(error);
                alert("Sorry, this email address already exists in the system. Please try again. ")
            });

    }
    useEffect(() => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/get-private-client-user', {
            headers: {
                'Content-Type': 'application/json',
            },
            id: props.match.params.id,
        }).then(response => {
            // console.log(response.data[0])
            var name = response.data[0].client_name.split(' ')
            setFName(name[0])
            setLName(name[1])
            setemail(response.data[0].client_email)
            setoldemail(response.data[0].client_email)
            setcompany(response.data[0].company)

        })
            .catch(function (error) {
                console.log(error);
            });
    }, [])


    const [data, setData] = useState(
        [{
            multi_class_I: false,
            favorites: false,
            dmsg: false,
            classI: false,
            shortline: false,
            intermodal: false,
            ports: false
        }]
    )

    var obj = {
        multi_class_I: data[0].multi_class_I,
        favorites: data[0].favorites,
        dmsg: data[0].dmsg,
        classI: data[0].classI,
        shortline: data[0].shortline,
        intermodal: data[0].intermodal,
        ports: data[0].ports
    }

    let setKeywords = () => {
        if (obj.multi_class_I === true) {
            obj.multi_class_I = false
        } else if (obj.multi_class_I === false) {
            obj.multi_class_I = true
        } else {
            obj.multi_class_I = false
        }
    }

    let setFavorites = () => {
        if (obj.favorites === true) {
            obj.favorites = false
        } else if (obj.favorites === false) {
            obj.favorites = true
        } else {
            obj.favorites = false
        }
    }

    let setDmsg = () => {
        if (obj.dmsg === true) {
            obj.dmsg = false
        } else if (obj.dmsg === false) {
            obj.dmsg = true
        } else {
            obj.dmsg = false
        }
    }

    let setClassI = () => {
        if (obj.classI === true) {
            obj.classI = false
        } else if (obj.classI === false) {
            obj.classI = true
        } else {
            obj.classI = false
        }
    }

    let setShortline = () => {
        if (obj.shortline === true) {
            obj.shortline = false
        } else if (obj.shortline === false) {
            obj.shortline = true
        } else {
            obj.shortline = false
        }
    }

    let setIntermodal = () => {
        if (obj.intermodal === true) {
            obj.intermodal = false
        } else if (obj.intermodal === false) {
            obj.intermodal = true
        } else {
            obj.intermodal = false
        }
    }

    let setPorts = () => {
        if (obj.ports === true) {
            obj.ports = false
        } else if (obj.ports === false) {
            obj.ports = true
        } else {
            obj.ports = false
        }
    }




    const hsub = () => {


        console.log("obj", obj)

    }




    return (
        <div style={{ backgroundColor: '#222D32' }}>
            <Header />
            <SideBar />

            <Modal show={success} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                    <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <div className='event-type info'>
                            <div className='event-indicator '>
                                <SVGIcon />
                            </div>
                        </div>
                        <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                            Private Client Updated
                        </h2>
                        <p className='text-muted font-size-16-imp margin-bottom-0'>
                            Private Client is Updated successfully.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                    <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className='content-wrapper background-white'>
                <section className='content-header'>
                    <div className='row'>
                        <div className='col-md-offset-1 col-md-10'>
                            <div className='col-md-12'>
                                <div style={{ flex: '1 0 auto', color: 'rgba(0,0,0,.87)', fontSize: 22, fontWeight: 400, marginTop: 15, marginBottom: 30 }}>Edit Private Client Details</div>

                                <form method="post">
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Client First  Name</label>
                                                <input type="text"
                                                    name='FName'
                                                    value={FName}
                                                    onChange={hadleInput} className="form-control" />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Client Last  Name</label>
                                                <input type="text"
                                                    name='LName'
                                                    value={LName}
                                                    onChange={hadleInput} className="form-control" />


                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Company</label>
                                                <input type='text'
                                                    name='company'
                                                    style={{ height: 40 }}
                                                    value={company}
                                                    onChange={hadleInput}
                                                    className="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Email address</label>
                                                <input type='text'
                                                    name='email'
                                                    style={{ height: 40 }}
                                                    value={email}
                                                    onChange={hadleInput}
                                                    className="form-control" />

                                            </div>
                                        </div>

                                    </div>


                                    <div className='col-md-8 text-center' style={{ paddingTop: 20 }}>
                                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={FormSubmit}>Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </div>
    )
}
export default withRouter(EditMembership)