import React from 'react';
const Index = props => {
    return (
        <>
            <div className={"iq-title-box iq-title-default " + props.className}>
                <div className={props.iconclass ? "iq-title-icon" : ''}></div>
                {/* <span  className="iq-subtitle"  > {props.subTitle}</span> */}
                <h2 style={{fontFamily: 'TeXGyreAdventor-Bold, sans-serif',textTransform: 'none',color: '#142149',lineHeight:'1.5em',fontWeight: 800,letterSpacing: '1px'}} className={"iq-title " + props.titleclassName} > {props.title}</h2>
                <p style={{ fontSize: '15px', fontFamily: 'Roboto, sans-serif', color: '#4a4a4a',fontWeight: 300,lineHeight: 1.5}} className={"iq-title-desc " + props.descclassName}  > {props.description}</p>
            </div>
        </>
    );
}

export default Index