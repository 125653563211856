import React, { Component } from 'react';

interface PListingInstructions {
}

class SListingInstructions {
	constructor() {
	}
}

class ListingInstructions extends Component<PListingInstructions, SListingInstructions> {
    render() {
        return (
            <div className='col-md-12 col-sm-12 margin-bottom-20 padding-0-xs'>
                <div className='color-white'
                    style={{
                        padding: 15
                        , border: '1px solid #ccc'
                        , borderRadius: 4
                        , backgroundColor: '#0094e9'
                    }}
                >
                    <div className='font-size-22 font-weight-bold margin-bottom-10 text-center text-uppercase'>
                        Instructions on How to Create a Listing
                    </div>
                    <span>
                        Please select the type of listing to be created. Please note that different selections will be displayed based on the type of listing to be created. If there is a specification or field that would be useful to add to what is already available, please suggest it at info@industrialnavigator.com. You may add multiple listings and check out and purchase your listing at the end of the listing creation. If there are any questions regarding this form, please contact us at +1 (312) 957-7757 or info@industrialnavigator.com.
                    </span><br /><br />
                    <b>
                    At minimum, you must enter Listing Name, Contact Email Address, and Address/Geolocation of the listing.
                    </b>
                </div>
            </div>
        )
    }
}

export default ListingInstructions;