import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesKcs extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesKcs extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesKcs = null;

	constructor(parent: NorthAmericanRailLinesKcs, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesKcs extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesKcs, SNorthAmericanRailLinesKcs> {

	static dataSource: string = 'NARL_KCS-bqt3av';
	static sourceLayer: string = 'NARL_KCS';

	constructor(props: PNorthAmericanRailLinesKcs) {
		super(props, NorthAmericanRailLinesKcs.dataSource, NorthAmericanRailLinesKcs.sourceLayer);
		this.state = new SNorthAmericanRailLinesKcs(this, NorthAmericanRailLinesKcs.dataSource, NorthAmericanRailLinesKcs.sourceLayer);
	}
}
