import { jsPDF } from 'jspdf';
import * as font from '../../assets/fonts/Gotham-Pro-Black-Bold';
import axios from 'axios';

import PdfDetailsDocGen from './PdfDetailsDocGen';
var color =  JSON.parse(localStorage.getItem('PrivateClientData')) ? JSON.parse(localStorage.getItem('PrivateClientData')).color.split(',') :[7, 52, 90];
var ImgUrl =  JSON.parse(localStorage.getItem('PrivateClientData')) ? JSON.parse(localStorage.getItem('PrivateClientData')).ImgUrl :'';

export default class PdfBookmarksDocGen extends PdfDetailsDocGen {

	printTitlePage = () => {
		const { pageHeight, pageWidth, doc } = this;
		const vCenter: number = pageHeight / 2 - 30;
		const hCenter: number = pageWidth / 2 - 40;
		//this.addImage('../../IN-logo.png', 'JPEG', hCenter, vCenter, 80, 15);	
		while (ImgUrl == null || ImgUrl == '') {
			// code block to be executed
			ImgUrl =  JSON.parse(localStorage.getItem('PrivateClientData')).ImgUrl 
			color =  JSON.parse(localStorage.getItem('PrivateClientData')).color.split(',')
			setTimeout(function() {
				console.log(ImgUrl,color);
			},2000);
		  }	
		  var img = new Image();	
		  img.onerror = function() {
			  alert('Cannot load image: "'+ImgUrl+'"');		};
		  img.onload = function() {
			  return img;
		  };
		  img.src = ImgUrl;
		  console.log('img',img)
		this.addImage(img, 'JPEG', hCenter, vCenter-30, 70, 25);
		this.addFileToVFS('Gotham Pro Black-bold.ttf', font.font);
		this.addFont('Gotham Pro Black-bold.ttf', 'Gotham Pro Black', 'bold');
		this.setFont('Gotham Pro Black', 'bold');
		this.setTextColor(Number(color[0]), Number(color[1]), Number(color[2]));
		this.setFontSize(26);
		this.text('Favorites Packet', hCenter , vCenter + 20);//hCenter + 8
		// this.text(JSON.parse(localStorage.getItem('PrivateClientData')).PrivateClientOrg, 25, vCenter + 35,{align:'left'});
		this.addPage();
		this.setFont('Arial', 'normal');
		this.setTextColor(0, 0, 0);
	}

	createBookmarksPDF = (arrGenPdf: Array<any>, features: Array<any>) => {
		if (arrGenPdf.length <= 0) {
			alert('Select places to create PDF');
			return;
		}
		this.initPdfDocument();
		this.printTitlePage();
		let y: number = 20;
		// for (let index = 0; index < arrGenPdf.length; index++) {
		// 	for (let fIndex = 0; fIndex < features.length; fIndex++) {
		// 		if (features[fIndex].rec_id !== arrGenPdf[index]) {
		// 			continue;
		// 		}
		// 		y = this.printPdfFields(features[fIndex].properties, y);
		// 	}
		// 	if (index !== arrGenPdf.length - 1) {
		// 		this.addPage();
		// 		y = 20;
		// 	}
		// }

		const promise = new Promise((resolve, reject) => {
			for (let index = 0; index < arrGenPdf.length; index++) {
				for (let fIndex = 0; fIndex < features.length; fIndex++) {
	
					if (features[fIndex].rec_id !== arrGenPdf[index]) {
						continue;
					}
					axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
						{
							headers: {
								'Content-Type': 'application/json',
								// 'x-access-token': cookies.get('token')
							},
							// withCredentials: true,
							imgurl: features[fIndex].properties.image[0].url,
						}).then(response => {
							if (response.status === 200) {
								var final_base_64_url = 'data:image/jpeg;base64,' + response.data.base64;
								features[fIndex].properties.image[0].url = final_base_64_url;
								axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
									{
										headers: {
											'Content-Type': 'application/json',
											// 'x-access-token': cookies.get('token')
										},
										// withCredentials: true,
										imgurl: features[fIndex].properties.listingLogo,
									}).then(response => {
										if (response.status === 200) {
											var final_base_64_url_logo = 'data:image/jpeg;base64,' + response.data.base64;
											features[fIndex].properties.listingLogo = final_base_64_url_logo;
											y = this.printPdfFields(features[fIndex].properties, y);
											if (index !== arrGenPdf.length - 1) {
												this.addPage();
												y = 20;
											}
											if (index == arrGenPdf.length -1 ) {
												resolve(index);
											}
										}
									}).catch(function (error) {
										console.log(error)
										y = this.printPdfFields(features[fIndex].properties, y);
										if (index !== arrGenPdf.length - 1) {
											this.addPage();
											y = 20;
										}
										alert('Image Correpted...Something went wrong')
										if (index == arrGenPdf.length -1 ) {
											resolve(index);
										}
									});
							}
						}).catch(function (error) {
							console.log(error)
							y = this.printPdfFields(features[fIndex].properties, y);
							if (index !== arrGenPdf.length - 1) {
								this.addPage();
								y = 20;
							}
							if (index == arrGenPdf.length -1 ) {
								resolve(index);
							}
							alert('Image Correpted...Something went wrong')
						});
					// y = this.printPdfFields(features[fIndex].properties, y);
				}
				
			}
			


		});

		promise.then((d) => {
			setTimeout(() => {
				this.doc.save('IndustrialNavigator.pdf');
			}, 3000);
			// this.doc.save('IndustrialNavigator.pdf');
		});
		
	}

}
