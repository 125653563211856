import React, { Component } from 'react';
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from 'react-places-autocomplete';
import axios from 'axios';

interface PInputAddressComponent {
	value?: string;
	location?: string;
	hideAutoCompleteBorder?: boolean;
	onHandleAddressChange: (
		address: string
		, coordinates: Array<number>
		, city: string
		, usaState: string
		, zipCode: string
		, country: string
	) => void;
}

class SInputAddressComponent {
	address: string = '';
	usaState: string = '';
	country: string = '';
	coordinates: Array<number> = null;
	zipCode: string = '';
	city: string = '';

	constructor() {
	}
}

class InputAddressComponent extends Component<PInputAddressComponent, SInputAddressComponent> {
	constructor(props: PInputAddressComponent) {
		super(props);
		this.state = new SInputAddressComponent();
		// console.log('address is as below')
		// console.log(this.props.location)
	}

	componentWillReceiveProps(nextProps: PInputAddressComponent) {
		this.setState({ address: nextProps.value });
	}

	handleAddressChange = (address) => {
		console.log('handleAddressChange');
		console.log(address);
		if (address === '') {
			this.setState({
				address: '',
				coordinates: null,
				city: '',
				usaState: '',
				zipCode: '',
				country: ''
			}, () => {
				const { state } = this;
				this.props.onHandleAddressChange(state.address, state.coordinates
					, state.city, state.usaState, state.zipCode, state.country);
			});
		} else {
			this.setState({ address });
		}
	};

	handleSelectAddress = (address) => {
		console.log('address');
		console.log(address);
		this.handleAddressChange(address);
		geocodeByAddress(address)
			.then(results => {
				console.log(results);
				let address_components = results[0].address_components;
				let city = '', usaState = '', country = '', zipCode = '';
				for (let index = 0; index < address_components.length; index++) {
					const element = address_components[index];
					if (element.types[0] === 'locality') {
						city = element.long_name;
					}
					if (element.types[0] === 'administrative_area_level_1') {
						usaState = element.short_name;
					}
					if (element.types[0] === 'country') {
						country = element.short_name;
					}
					if (element.types[0] === 'postal_code') {
						zipCode = element.short_name;
					}
				}
				this.setState({
					city: city,
					usaState: usaState,
					country: country,
					zipCode: zipCode
				}, () => {
					getLatLng(results[0]).then(latLng => {
						this.setState({ coordinates: [latLng['lng'], latLng['lat']] }, () => {
							axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latLng['lat'] + '&lon=' + latLng['lng'] + '&addressdetails=1%22')
								.then(response => {
									console.log('stuck in openstreetmap');
									console.log(response);
									// if (this.state.city === '') {
									// 	this.setState({
									// 		city: response.data.address.city
									// 	});
									// }
									if (this.state.zipCode === '') {
										this.setState({
											zipCode: response.data.address.postcode,
										});
									}
									const { state } = this;
									this.props.onHandleAddressChange(state.address, state.coordinates
										, state.city, state.usaState, state.zipCode, state.country);
								}).catch(function (error) {
									console.log(error);
									console.log(error.json());
								});
						});
					});
				});
			})
			.catch(error => {
				console.log('asdfasd');
				console.error('Error', error);
			});
	};

	render() {
		return (
			<PlacesAutocomplete
				value={this.props.value || this.state.address}
				onChange={this.handleAddressChange}
				onSelect={this.handleSelectAddress}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div>
						<input
							{...getInputProps({
								placeholder: 'Search City, State or Address ...',
								className: 'location-search-input',
								autoComplete: 'disabled'
							})}
						/>
						{/* <div className='autocomplete-dropdown-container'> */}
						<div className={`${this.props.location == 'create_place' ? 'autocomplete-dropdown-container z-index-1000' : 'autocomplete-dropdown-container'} ${this.props.hideAutoCompleteBorder && 'no-border'}`}>
							{loading && <div>Loading...</div>}
							{suggestions.map(suggestion => {
								const className = suggestion.active
									? 'suggestion-item--active'
									: 'suggestion-item';
								// inline style for demonstration purpose
								const style = suggestion.active
									? { backgroundColor: '#fafafa', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' }
									: { backgroundColor: '#ffffff', cursor: 'pointer', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', paddingBottom: '5px' };
								return (
									<div
										{...getSuggestionItemProps(suggestion, {
											className,
											style,
										})}
									>
										<span>{suggestion.description}</span>
									</div>
								);
							})}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
		)
	}
}

export default InputAddressComponent;