import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection';
import { Link } from 'react-router-dom';
import createlist from "../../public/assets/images/work/datalayernew.png";
const Index = props => {
    const [supports] = useState([
        { title: 'Search by location' },
        { title: 'Search by keyword' },
        { title: 'Filter by 40+ search criteria' },
        { title: 'Basic and advanced map layers including Class I Railroads Intermodal Terminals Crude Pipelines Shortline Railroads Major Ports Refined Produce Pipelines' }
    ]);
    return (
        <>
            <section style={{ background: '#fbfbfb' }} className="iq-counter-section pt-0 iq-pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-lg-0 mb-5">

                            <TitleSection className='iq-title-box-2'
                                title='Subscriptions Designed for Searchers'
                                subTitle='About Us'
                                description='Industrial Navigator Power Search subscriptions have all of the capabilities needed to conduct the searches you need to build and improve your supply chain.'
                                titleIcon='' />

                            <div className="iq-list iq-two-column">
                                {/* <ul className="iq-list-with-icon">
                                    {
                                        supports.map((support, index) => (
                                            <li key={index}><i className="fa fa-check"></i>{support.title}</li>

                                        ))
                                    }
                                </ul> */}
                                <ul className="iq-list-with-icon">
                                <li><i className="fa fa-check"></i>Basic and advanced map layers including Class I Railroads, Intermodal Terminals, Crude Pipelines, Shortline Railroads, Major Ports and Refined Product Pipelines</li>
                                    <li><i className="fa fa-check"></i>Search by location</li>
                                    <li><i className="fa fa-check"></i>Search by keyword</li>
                                    <li><i className="fa fa-check"></i>Filter by 40+ search criteria</li>
                                   
                                </ul>
                            </div>
                            <div style={{ marginBottom: '20px' }} className="iq-btn-container mt-4"> 
                                {/* <a className="iq-button iq-btn-medium iq-btn-round iq-btn-flat d-inline" href="/plans-and-pricing">See Plans</a> */}
                                <div class="btn-group" role="group" aria-label="Basic example"> 
                                    <button style={{ background: '#009df5', color: '#ffffff', minWidth: 180 }} type="button" class="btn">
                                        <Link style={{ color: '#ffffff' }} to={{ pathname: "/register-and-checkout", planinfo: { id: 3,name:'Power Search Monthly',price:'$29/month' } }}>
                                            Power Search Monthly<br />$29/month
                                        </Link>
                                        {/* <a style={{ color: '#ffffff' }} href="/plans-and-pricing">Power Search Monthly<br />$29/month</a> */}
                                    </button>
                                    <button style={{ background: '#053359', color: '#ffffff', minWidth: 180 }} type="button" class="btn btn-secondary">
                                        {/* <a style={{ color: '#ffffff' }} href="/plans-and-pricing">Power Search Annual<br />$299/year</a> */}
                                        <Link style={{ color: '#ffffff' }} to={{ pathname: "/register-and-checkout", planinfo: { id: 5,name:'Power Search Yearly',price:'$299/month' } }}>
                                            Power Search Annual<br />$299/year
                                        </Link>
                                    </button>
                                    <button style={{ background: '#008fe4', color: '#ffffff', minWidth: 180, minHeight: 59 }} type="button" class="btn btn-secondary">
                                        <a style={{ color: '#ffffff' }} href="/plans-and-pricing">See All Plans</a>
                                    </button>
                                </div>
                            </div>


                        </div>
                        <div className="col-lg-1 col-md-2 col-sm-12 wow fadeInUp" data-wow-delay="0.4s"></div>
                        <div className="col-lg-5">
                            <div className="iq-counter text-center iq-box-shadow iq-counter-style-1">
                                <div className="counter-content">
                                    <img src={createlist} className="img-fluid" alt="qloud" />
                                    {/* <p className="iq-counter-info">
                                        <span className="timer" data-to={list.timer}
                                            data-speed="5000">{list.timer}</span>
                                        <span className="counter-after-content">{list.content}</span>
                                        <span className="counter-symbol">{list.symbol}</span>
                                        </p>
                                    <h5 className="counter-title-text counter-desc">{list.title}</h5> */}
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    {
                                        List2.map((list, index2) => (
                                            <div className="iq-counter text-center iq-box-shadow iq-counter-style-1" key={index2}>
                                                <div className="counter-content">
                                                    <p className="iq-counter-info">
                                                        <span className="timer" data-to={list.timer}
                                                            data-speed="5000">{list.timer}</span>
                                                        <span className="counter-after-content">{list.content}</span>
                                                        <span className="counter-symbol">{list.symbol}</span></p>
                                                    <h5 className="counter-title-text counter-desc">{list.title}</h5>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    {
                                        List2.map((list, index2) => (
                                            <div className="iq-counter text-center iq-box-shadow iq-counter-style-1" key={index2}>
                                                <div className="counter-content">
                                                    <p className="iq-counter-info">
                                                        <span className="timer" data-to={list.timer}
                                                            data-speed="5000">{list.timer}</span>
                                                        <span className="counter-after-content">{list.content}</span>
                                                        <span className="counter-symbol">{list.symbol}</span></p>
                                                    <h5 className="counter-title-text counter-desc">{list.title}</h5>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Index