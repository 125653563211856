import React, { Component } from 'react';
import '../custom.css';
import { Button, Breadcrumb, BreadcrumbItem, Form, Modal, FormGroup, OverlayTrigger, Tooltip, ControlLabel, FormControl } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import Header from './Header';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import UserProfile from './UserProfile';
import { CheckoutStrip } from './upgradeCheckoutCard';
import { Elements } from "@stripe/react-stripe-js";
import '../custom_braintree.css';
import InputCheckboxComponent from './InputCheckboxComponent';
import InputBoxComponent from './InputBoxComponent';
import { Cookies } from 'react-cookie';
import HomePageHeader from './HomePageHeader';
import { loadStripe } from "@stripe/stripe-js";
const promise = loadStripe("pk_live_51JQZCTI9aRcuI4Jh6A4nCIJAOhTzdxjXDvRZ1HYx6GsqY1EazrOV5PV3oYL6qUQHbDMboqDjJgeXipyZbzpXhzId00IX8JmTkY");
let planid;
let userDowngradePlanId;
let subscriptionId;
let priceId;
let customerId;

interface PCheckout {
	history?: Array<string>;
}



class CheckoutSubscription extends Component<PCheckout, any> {
	validator: SimpleReactValidator = null;

	instance;

	constructor(props) {
		super(props);
		this.state = {
			shoppingCartCount: 0,
			couponCodeAvailable: false,
			paywithCard: true,
			CardAvailable:false,
			isOrderFree: false,
			payment_type: 'online',
			planArr: localStorage.getItem('planNames') ? JSON.parse(localStorage.getItem('planNames')) : [],
			emailid: localStorage.getItem('UseremailId') ? JSON.parse(localStorage.getItem('UseremailId')) : [],
			// user_email: emailid.email
			sessionPlandata: null,
		}
		// this.setPaymentInfo = this.setPaymentInfo.bind(this);
		this.setTermsAndConditions = this.setTermsAndConditions.bind(this);
	}

	handlePlaceSuccessModal() {
		//this.props.history.push('/my-listing');
		this.props.history.push('/');
	}

	async componentDidMount() {

		const planAarraydata = JSON.parse(sessionStorage.getItem('planNames'));
		if (planAarraydata) {
			this.setState({ sessionPlandata: planAarraydata });
		}
		else {
			this.props.history.push('/');
		}

	}
	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		// axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
		// 	{
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		email: UserProfile.getEmail(),
		// 	}).then(response => {
		// 		//console.log(response)				
		// 		planid = response.data[0].sw_plan_id;
		// 		userDowngradePlanId = response.data[0].sw_downgrade_plan_id;
		// 		subscriptionId = response.data[0].sw_stripe_subscription_id;
				// if (subscriptionId != null) {
				// 	axios.post(process.env.REACT_APP_BASE_URL + '/api/get-subscription-status',
				// 		{
				// 			headers: {
				// 				'Content-Type': 'application/json',
				// 			},
				// 			subscriptionsid: subscriptionId,
				// 			// subscriptionsid:"sub_K9SXuSOCaxAKhR"
				// 		}).then(response => {
				// 			if (response.status === 200) {
				// 				alert(response.data.discount)
				// 				if (response.data.discount != null) {
				// 					this.setState({CardAvailable:true});
									
				// 				}
				// 			}
				// 		});

				// }


		// 	});
	}

	setTermsAndConditions(event) {
		this.setState({ checkbox: !this.state.checkbox });
	}

	handleCheckboxChange = (name, value) => {
		this.setState({ [name]: value });
	}
	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}
	createCouponOrder = () => {

		try {
			//var email = email;
			//console.log("email id in coupan order", this.state.emailid.email)
			var email = UserProfile.getEmail();
			const planData = JSON.parse(sessionStorage.getItem('planNames'));
			const user_email = UserProfile.getEmail();
			const promocode_obj = { SME: "promo_1Jk2QqI9aRcuI4JhhulVFQCc", SDG: "promo_1JkB4UI9aRcuI4JhfXn1sw0h", EARLY: "promo_1JkB3mI9aRcuI4Jhko6ZlaLQ" }
			const code = this.state.couponCode;
			//alert(promocode_obj[code])
			const promotion_code = promocode_obj[code];

			if (planData.id == 3) {
				priceId = 'price_1K2dBhI9aRcuI4Jh6Qr5fxnx'
			}
			else if (planData.id == 4) {
				priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om'
			}
			var self = this;
			if (planid == 2 || userDowngradePlanId == 2) {

			}
			axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
				email: user_email,

				//customerId:'',
			}).then(function (response) {
				console.log("responce data email", response.data.customer.email);
				localStorage.setItem('CustomerId', response.data.customer.id);
				customerId = response.data.customer.id
				console.log("customer id:", customerId)
				console.log("price id", priceId)
				console.log("promo code", promotion_code)

				axios.post(process.env.REACT_APP_BASE_URL + '/api/create-coupan-order', {

					priceId: priceId,
					customerId: customerId,
					promotion_code: promotion_code,
				})
					.then(function (response) {
						console.log('response succes', response);
						if (response.status === 200) {

							localStorage.setItem('SubscriptionId', response.data.subscription.id);
							console.log("subscription id for coupan order:", response.data.subscription.id)
							console.log("email id for updating order:", user_email)

							axios.post(process.env.REACT_APP_BASE_URL + '/api/update-subscription-id-upgrade-coupan-order', {
								usr_email: user_email,
								subscription_id: response.data.subscription.id,
								plan_id: planData.id,
								'source':'Upgrade Profile',
								//customerId:'',
							}).then(function (responce) {
								if (response.status === 200) {
									localStorage.removeItem('planNames');
									self.props.history.push('/upgrade-success');
								}
								else {
									alert("Something went wrong");
								}


							});
						}

					})
					.catch(function (error) {
						console.log('response error', error);
						alert("Invalid promo code used.")
						console.log(error);
					});

			});
		} catch (err) {
			console.log('error in order update');
			console.error(err);
		}
	}


	child: Header = null;

	render() {
		return (
			<div>
				<Header data={this.props} onRef={ref => (this.child = ref)} shoppingCartCount={0} />
				<div id='page-content' style={{ backgroundColor: 'white', paddingBottom: '50px', minHeight: '100vh' }}>
					<div>
						<Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
							<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
							<Link to={{ pathname: '/create' }}><span className='color-white'>Create</span></Link>&nbsp;/&nbsp;
							<BreadcrumbItem active>Checkout</BreadcrumbItem>
						</Breadcrumb>
						<div className='container-fluid' style={{ marginTop: '20px' }}>
							<div className='col-lg-10 col-lg-offset-1 padding-0-xs'>

								<div className='col-lg-8 padding-0-xs'>
									<h1>Your Order</h1>
									<div className='padding-30 padding-15-xs' style={{ border: '2px solid #f4f4f2', borderRadius: '6px' }}>
										{/* <h1>Your Order</h1> */}
										<div className='col-lg-12 col-xs-12 padding-0-xs border-bottom-1' style={{ lineHeight: '40px', marginBottom: 10 }}>
											<div className='col-lg-9 col-xs-8 text-left font-weight-bold pull-left padding-0'>Plan Name</div>
											<div className='col-lg-3 col-xs-4 text-center font-weight-bold pull-right'>Total</div>
										</div>

										<div className='col-lg-12 col-xs-12 padding-0-xs' style={{ lineHeight: '40px', marginBottom: 10 }} key={Math.random()}>
											<div className='col-lg-9 col-xs-8 text-left padding-0'><div className='pull-left'><b style={{ fontSize: 25 }}>{this.state.sessionPlandata ? this.state.sessionPlandata.membership_level_name : ''}</b></div>
											</div>
											<div className='col-lg-3 col-xs-4 text-center pull-right' style={{ fontSize: 25 }}><b>${this.state.sessionPlandata ? this.state.sessionPlandata.membership_level_cost : ''}</b> </div>
										</div>

										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<div className='font-size-13'>Your plan will be invoiced monthly until you cancel your account. You may cancel, upgrade, or downgrade your account by navigating to your profile. </div>
										</div>
										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											{/* <InputCheckboxComponent name='terms1' checked={this.state.terms1} onHandleCheckboxChange={this.handleCheckboxChange} label='I agree to the website terms and conditions.' />
											{this.validator.message('Terms and Conditions', this.state.terms1, 'accepted')} */}
										</div>
										{
											(!this.state.isOrderFree) &&
											<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
												<InputCheckboxComponent name='couponCodeAvailable' checked={this.state.couponCodeAvailable} onHandleCheckboxChange={this.handleCheckboxChange} label='I have a coupon code.' /><br />

												{/* <div>
													<h3> Fill the card details and procees to payment</h3>
													<Elements stripe={promise} >
														<CheckoutStrip />
													</Elements>
												</div> */}
											</div>
										}
										{/* {
											(!this.state.paywithCard ?
												(<></>)
												: (
													<div>
														<h3> fill the card details and procees to payment</h3>
														<Elements stripe={promise}>
															<CheckoutStrip />
														</Elements>
													</div>
												))
										} */}






										{
											!this.state.couponCodeAvailable ?
												!this.state.couponCodeAvailable && this.state.isOrderFree ?
													(<></>)
													:
													<div>
														<h3 style={{ marginLeft: 15 }}> Enter credit card details below to process payment.</h3>

														<Elements stripe={promise} >
															<CheckoutStrip />
														</Elements>
													</div>
												// (!this.state.clientToken ?
												// 	(
												// 		<div style={{ textAlign: 'center' }}>
												// 			<h1>Loading...</h1>
												// 		</div>
												// 	) : (
												// 		<React.Fragment>
												// 			<div className='col-lg-12 col-xs-12 padding-0-xs' id='braintree_custom_css'>
												// 				<DropIn
												// 					options={{
												// 						authorization: this.state.clientToken
												// 					}}
												// 					onInstance={instance => (this.instance = instance)} />
												// 			</div>
												// 			<div className='col-lg-12 col-xs-12 padding-0-xs'>
												// 				<OverlayTrigger placement='top' overlay={
												// 					<Tooltip id='tooltip'>
												// 						<strong className='font-size-16'>
												// 							Card Number: 4242 4242 4242 4242 <br />
												// 							Expiration Date: 01/25
												// 						</strong>
												// 					</Tooltip>
												// 				}>
												// 					<label className='form-check-label cursor-pointer color-blue'>Hover to see beta user cc info</label>
												// 				</OverlayTrigger>
												// 			</div>
												// 		</React.Fragment>
												// 	))
												:
												<React.Fragment>
													{/* <div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
														<InputCheckboxComponent name='terms1' checked={this.state.terms1} onHandleCheckboxChange={this.handleCheckboxChange} label='I agree to the website ' /><Link to={{ pathname: '/terms-and-conditions' }} ><b> terms and conditions</b></Link><b>.</b>
														{this.validator.message('Terms and Conditions', this.state.terms1, 'accepted')}
													</div> */}

													<InputBoxComponent name='couponCode' value={this.state.couponCode} onHandleInputChange={this.handleInputChange} />

													<div className='text-center' style={{ marginTop: '10px', marginBottom: '10px' }} id='place-order-div'>
														<button type='button' className='border-radius-25-imp btn btn-default' id='place-order-btn' onClick={this.createCouponOrder} >Proceed</button>
													</div>
													{/* <div className='col-lg-12 col-xs-12 padding-0-xs margin-top-20'>
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	Coupon Code: promo_1JcP9lI9aRcuI4JhC0LusKqm
																</strong>
															</Tooltip>
														}>
															<label className='form-check-label cursor-pointer color-blue'>Hover to see coupon code</label>
														</OverlayTrigger>
													</div> */}
												</React.Fragment>
										}


										{/* <div className='text-center' style={{ marginTop: '10px', marginBottom: '10px' }} id='place-order-div'>
											<button type='button' className='border-radius-25-imp btn btn-default' id='place-order-btn' onClick={this.proceedToPayment}>Use the app!</button>
										</div> */}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	handleClose: any;
}

export default withRouter(CheckoutSubscription);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)