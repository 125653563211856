import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
// import HomePageFooter from './HomePageFooter';
import HomePageFooter from './HomePage/qloud/footerComponents';
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Home from './HomePage';
import logo from '../logo.svg';
import InputBoxComponent from './InputBoxComponent';
import axios from 'axios';
import {Helmet} from "react-helmet";
import '../css/priceplans.css';
import MultiStepRegistration from './Register/MultiStepRegistration';
import UserProfile from './UserProfile';
import SimpleReactValidator from 'simple-react-validator';


const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: Header) => void;
    data?: any;
    shoppingCartCount?: number;
    bookmarksCount?: number;
    logo?: string;
    btnBackgroundColor?: string;
    iconColor?: string;
    secondaryColor?: string;
    history: Array<any>;
}


class PlansAndPricing extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    loginValidator: SimpleReactValidator = null;
    OTPValidator: SimpleReactValidator = null;
    helpers: any = null;

    constructor(props: PHeader) {
        super(props);
        this.state = {
            showLoginModal: false,
            showForgotPasswordModal: false,
            login_email: '',
            login_password: '',
            isLoggedIn: false,
            nextURL: '',
            propertyType: '',
            propertySubType: '',
            fpEmail: '',
            showLoginSuccessModal: false,
            showLogoutSuccessModal: false,
            showRegistrationModal: false,
            fname: '',
            lname: '',
            companyName: '',
            password: '',
            confirm_password: '',
            email: '',
            showRegistrationSuccessModal: false,
            reacatpcha_value: null,
            // shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
            bookmarksCount: props.bookmarksCount ? props.bookmarksCount : 0,
            shoppingCartCount: props.shoppingCartCount ? props.shoppingCartCount : 0,
        }
        //this.handleMyListing = this.handleMyListing.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.checkIfLoginPage = this.checkIfLoginPage.bind(this);
        this.setLoginEmail = this.setLoginEmail.bind(this);
        this.setLoginPassword = this.setLoginPassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);


        this.validator = new SimpleReactValidator({
            validators: {
                phone: {  // name the rule
                    message: 'The :phone must be a valid phone number.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                    required: true  // optional
                }
            }
        });
    }

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }

    componentWillMount() {
        this.loginValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        });
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
        this.OTPValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }


    handleShow(nextURL, type = '', subType = '') {
        this.setState({
            nextURL: nextURL,
            propertyType: type,
            propertySubType: subType,
        });

        if (cookies.get('token') !== undefined) {
            fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
                    'x-access-token': cookies.get('token')
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        if (nextURL === '/my-profile') {
                            // this.props.data.history.push('/create');
                            this.props.history.push({
                                pathname: '/my-profile',
                                state: {
                                    propertyType: this.state.propertyType,
                                    propertySubType: this.state.propertySubType,
                                }
                            })
                        }
                    } else {
                        UserProfile.forgetEmail();
                        cookies.remove('token');
                        this.props.history.push('/');
                        window.scrollTo(0, 0);
                        // const error = new Error(res.error);
                        // throw error;
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false, redirect: true });
                });
        } else {
            this.handleOpen();
        }
    }
    handleOpen = () => {
        this.setState({ showRegistrationModal: true });
    }
    handleOTPVerification = () => {
        if (this.OTPValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    otp: this.state.otp,
                    login_email: this.state.login_email,
                }).then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        this.handleOTPModal();
                        this.handleLoginSuccessResponse(response);
                    }
                }).catch(function (error) {
                    // handle your error
                });
        } else {
            this.OTPValidator.showMessages();
            this.forceUpdate();
        }
    }
    logoutUser() {
        // UserProfile.forgetEmail();
        // UserProfile.forgetFirstName();
        // UserProfile.forgetLastName();
        cookies.remove('token');
        localStorage.clear();
        this.setState({ isLoggedIn: false, showLogoutSuccessModal: true });
        this.props.data.history.push('/');
    }
    handleClose() {
        this.setState({ showRegistrationModal: false });
    }
    checkIfLoginPage() {
        if (this.props.data.location.pathname === '/register') {
            this.handleClose();
        } else {
            this.props.data.history.push({
                pathname: '/register',
                state: {
                    nextURL: '/create',
                }
            })
        }
    }
    setLoginEmail(event) {
        this.setState({ login_email: event.target.value });
    }
    setLoginPassword(event) {
        this.setState({ login_password: event.target.value });
    }
    handleLogin() {
        if (this.loginValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    login_email: this.state.login_email,
                    login_password: this.state.login_password,
                }).then((response: {
                    status: number
                    , data: {
                        bookmarks_str: string
                        , email: string
                        , first_name: string
                        , last_name: string
                        , max_no_entries: string
                        , options: string
                        , place_names_arr: string //Array<any>
                        , user_type: string
                        , token: string
                    }
                }) => {
                    document.getElementById('login_error').style.display = 'none';
                    document.getElementById('verify_email_error').style.display = 'none';
                    console.log(response);
                    this.handleClose();
                    // alert(window.location.href)
                    if (response.status === 200) {
                        let bookmarksCount = 0;
                        console.log('response.data')
                        console.log(response.data)
                        if (response.data.bookmarks_str) {
                            const bookmarksArr = response.data.bookmarks_str.split(',');
                            bookmarksCount = bookmarksArr.length;
                        }
                        localStorage.setItem('bookmarks', response.data.bookmarks_str);
                        localStorage.setItem('listingNames', response.data.place_names_arr);
                        localStorage.setItem('maxNoEntries', response.data.max_no_entries);
                        localStorage.setItem('membershipOptions', response.data.options);

                        if (response.data.user_type === 'admin') {
                            this.setState({
                                showOTPModal: true
                            });
                        } else {
                            this.handleLoginSuccessResponse(response);
                        }
                    } else {
                        this.setState({ isLoggedIn: false });
                        if (response.status === 204) {
                            document.getElementById('verify_email_error').style.display = 'block';
                            document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
                        } else {
                            document.getElementById('login_error').style.display = 'block';
                            document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                }).catch((error) => {
                    // handle your error
                    // document.getElementById('login_error').style.display = 'block';
                    // document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                });
        } else {
            this.loginValidator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    handleShowForgotPasswordModal = () => {
        this.setState({ showForgotPasswordModal: true });
        this.handleClose();
    }
    handleLoginSuccessModal() {
        this.setState({ showLoginSuccessModal: false });
    }

    handleRegistrationSuccessModal = () => {
        this.setState({ showRegistrationSuccessModal: false });
    }

    showRegistrationModal = () => {
        this.setState({
            showRegistrationModal: true,
            showLoginModal: false,
        });
    }
    handleForgotPasswordClose = () => {
        this.setState({ showForgotPasswordModal: false });
        this.handleOpen();
    }
    setFPEmail = (event) => {
        this.setState({ fpEmail: event.target.value });
    }
    handleLogoutSuccessModal = () => {
        this.setState({ showLogoutSuccessModal: false });
    }
    handleOTPModal = () => {
        this.setState({ showOTPModal: false });
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    }

    handleRegistrationModal = () => {
        this.setState({ showRegistrationModal: false });
    }
    handleLoginSuccessResponse = response => {
        UserProfile.setEmail(response.data.email);
        UserProfile.setFirstName(response.data.first_name);
        UserProfile.setLastName(response.data.last_name);
        UserProfile.setUserType(response.data.user_type);

        this.setState({
            isLoggedIn: true,
            showLoginSuccessModal: true,
        });

        cookies.set('token', response.data.token);
        this.forceUpdate();

        if (this.props.data.location.pathname === '/register' || this.props.data.location.pathname === '/success' || this.state.nextURL === '/create') {
            // this.props.data.history.push('/create');
            this.props.data.history.push({
                pathname: '/create',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
        else if (this.state.nextURL === '/listing') {
            // this.props.data.history.push('/listing');
            this.props.data.history.push({
                pathname: '/listing',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
    }
    sendFPEmail = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
            {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                fpEmail: this.state.fpEmail
            }).then(response => {
                document.getElementById('fp_success').style.display = 'block';
                document.getElementById('fp_error').style.display = 'none';
            }).catch(function (error) {
                document.getElementById('fp_success').style.display = 'none';
                document.getElementById('fp_error').style.display = 'block';
                // alert('Something went wrong! Please try after sometime.')
            });
    }


    child: Header = null;



    render() {

        return (
            <>
                <Helmet>
                    <title>Terms And Conditions</title>
                    <meta name='description' content="Description" />
                    <meta property='og:title' content="About us" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>

                <Header data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 1400, fontFamily: "Roboto, sans-serif" }}>
                    <div className="justify-content-center">
                    <div className='col-lg-2 col-md-12 col-sm-12 padding-0' ></div> 
                        <div className='col-lg-8 col-md-12 col-sm-12 padding-0' >

                             <div className="container-fluid" style={{ marginTop: 100, fontFamily: "Roboto, sans-serif" }} >
                                <h1 className="content_area" style={{  fontWeight: 600, fontFamily: 'Roboto, sans-serif' ,marginLeft:15 }}>Terms of Use Agreement </h1>
                                
                            <p style={{ fontSize: "16px", marginLeft: 15 }} className="copy margin--top-c4">NOTE THAT THESE TERMS OF USE CONTAIN A LIMITATION OF OUR LIABILITY.</p>

                                <div className='container-fluid' >
                                    <p className="para_content-new">  This website (accessible through the domain <b>industrialnavigator.com</b>) (the “Website”), is owned and operated by Professional Logistics Group, Inc. (“PLG”). This Agreement is a legal contract between you and PLG and applies to your use of the Website, including any information and materials therein.  By accessing all or any part of the Website, you fully accept and agree to comply with all of the terms and conditions set out in this Agreement. You may not use the Website except as expressly provided in this Agreement. If you do not agree to this Terms of Use Agreement, you should not access or use the Website.

                                        <br /><br /><b style={{textDecoration: 'underline'}}>Pricing and Access</b><br /><br />
                                        The Website offers three levels of access, each for a different price, and each with specific features.  The Basic plan is free and includes some limited functionality and listings.  The Power Search plan is $29/month or $299/year and includes the same features as the basic plan, plus some enhancements.  The Power Pro plan is $49/month or $499/year and includes the same features as Power Search with additional enhancements.  For more detailed information about what is included within each plan, <Link target="_blank" to={{ pathname: '/plans-and-pricing' }} ><b>click here</b></Link>.

                                        <br /><br />Downgrading: You can downgrade your plan at any time.  Your new payment will begin with the next month’s payment, and your access will change at the beginning of the next month.  At that time, to the extent you have more listings than allowed, your oldest listings will be automatically deactivated until your active listings are at the maximum allowed for the downgraded plan.  You can subsequently activate and deactivate listings on your listings administration dashboard.

                                        <br /><br />Upgrading: You can upgrade your plan at any time.  Your new payment will begin with the next month’s payment, but your access will change immediately.
                                        <br /><br />Auto-renew:  Unless cancelled at least 24 hours before the end of your plan (the end of the month, or the end of the year), your subscription will automatically renew.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>Plan Ownership <br /><br /></b>
                                        A plan, and the listings associated with it, are owned and controlled by the person(s) controlling the email associated therewith.  PLG is not obligated to, and will not, transfer plans or listings absent verifiable proof of agreement of the transfer, or a final, non-appealable order of a court of competent jurisdiction.  PLG will not arbitrate disputes between or among individuals and entities regarding ownership or control of a plan or listings.
                                        <br /><br /><b style={{textDecoration: 'underline'}}> Listings<br /><br /></b>
                                        Listings are limited to those meeting specific requirements as follows:

                                        <br /><br />Industrial Sites and Properties: True industrial sites/properties; over 20 acres, Greenfield or Brownfield sites and buildings.

                                        <br /><br />Terminals and Trainloads: Port facilities, bulk terminals and transload facilities handling bulk, breakbulk and project cargo.

                                        <br /><br />Warehouse/Distribution Centers: Covered storage of over 100,000 square feet; for sale, lease or on-demand 3PL/public warehouse service in North America.

                                        <br /><br />PLG reserves the right to reject or cancel listings that do not meet the above listed criteria.

                                        <br /> PLG also reserves the right, in its sole discretion, to deactivate listings that appear out-of-date and for which PLG is unable to contact the user associated with that listing.
                                        <br /><br /><b style={{textDecoration: 'underline'}}> Copyright<br /><br /></b>
                                        This Website is the property of PLG and its affiliates and licensors, and is protected from unauthorized copying and dissemination by United States copyright law, trademark law, international conventions and other intellectual property laws. All PLG product or service names or logos appearing on the Website are either trademarks or registered trademarks of PLG and/or its affiliates. PLG does not, and will not, waive any of its trademark or other intellectual property rights concerning any such name or logo unless such waiver is in writing signed by PLG. All other product or service names or logos are trademarks or registered trademarks of their respective owners, are used by PLG with permission, and may not be used by anyone else without consent from such owners.  PLG does not grant you permission to use such other parties’ product or service names or logos.

                                        <br /> No part of the Website may be reproduced or transmitted in any form, by any means, electronic or mechanical, including photocopying and recording, except that PLG authorizes you to view, copy, download, and print PLG documents and materials that are available on the Wesbite, subject to the following conditions:<br />
                                        <div className="col-md-12">&bull;&nbsp;The documents and materials may be used solely for noncommercial, informational and/or personal purposes only.</div>
                                        <div className="col-md-12">&bull;&nbsp;The documents and materials may not be modified. </div>
                                        <div className="col-md-12">&bull;&nbsp;Copyright, trademark, and other proprietary notices may not be removed.
                                        </div>

                                        <br /><br /> You may also provide a link to the Website without limitation, but you may not provide any of the Website’s content or materials on another website (by copying, framing, embedding or otherwise) without PLG’s prior written approval.

                                        <br /><br /> Nothing contained on the Website should be construed as granting, by implication, estoppel, or otherwise, any license or right to use the Website or any materials displayed or accessible on the Website, through the use of framing or otherwise, except: (a) as expressly permitted by these terms and conditions; or (b) with the prior written permission of PLG or such third party that may own the copyright of or other rights to the material displayed on the Website.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>No Warranty <br /><br /></b>
                                        Your use of the Website is at your own risk. PLG does not warrant the accuracy or timeliness of the information and materials contained on the Website. PLG has no liability for any loss, or errors or omissions, based on information contained on the Website, whether provided by PLG our affiliates, our licensors or suppliers, or other users.

                                        <br /><br />PLG does not warrant, guarantee, vouch for, or confirm the information contained in listings posted by others on the Website.  Your use or reliance on that information is at your own risk.

                                        <br /><br />The information and materials contained on the Website, including text, graphics, links or other items, are provided “as is” and “as available.” PLG does not warrant the accuracy, adequacy, timeliness, or completeness of this information, materials, or the Website’s safety and security, and expressly disclaim liability for errors, omissions or other defects, or delays or interruptions in this information and materials, and the Website’s interoperability, unavailability, or security vulnerabilities. Some jurisdictions may prohibit a disclaimer of warranties and you may have other rights that vary from jurisdiction to jurisdiction.

                                        <br /><br />The Website may contain links to third-party websites (“Linked Sites”). Such Linked Sites are not under PLG’S control, and PLG is not responsible for their content, security, functionality or any other information, product or service offered on any Linked Site.  Inclusion of a link to a Linked Site is not an indication of any partnership, affiliation endorsement or any other relationship between PLG and the Linked Site or its owner.  For the terms of use and other policies pertaining to the Linked Sites you should review the terms of use and policies posted on the Linked Sites.

                                        <br /><br />PLG assumes no responsibility for, and does not insure, guarantee, or otherwise protect you with regard to purchases you make from vendors linked to or otherwise mentioned on the Site. The Site shall not be construed as an offer by PLG to sell or lease any goods or services of any vendor mentioned or linked to on the Site at any particular price or for other value.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>Disclaimers<br /><br /></b>
                                        <br />PLG may alter, suspend, or discontinue the Website at any time for any reason, without notice or cost. The Website may become unavailable due to maintenance or malfunction of computer equipment or other reasons and PLG shall not be liable for any damage caused by such unavailability.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>Indemnity <br /><br /></b>
                                        By using the Website, you agree to indemnify, hold harmless and defend PLG and its officers, directors, agents and affiliates from any claims, damages, losses, liabilities, and all costs and expenses of defense, including but not limited to, attorneys' fees, resulting directly or indirectly from a claim by a third party that is based, either directly or indirectly, on your use of the Website or the information herein, or your violation of this Agreement or the rights of others, or your termination of access to the Website. The information on the Website is provided with the understanding that PLG providing such information does not constitute the rendering of professional advice or services.
                                        <br /><br /><b style={{textDecoration: 'underline'}}> LIMITATION ON PLG’S LIABILITY <br /><br /></b>
                                        <b>BECAUSE THE AMOUNT OF DAMAGES FOR YOUR USE OF THE WEBSITE IS UNCERTAIN AND NOT EASILY QUANTIFIABLE, PLG’S AGGREGATE LIABILITY FOR ANY AND ALL CLAIMS, LOSSES, LIABILITIES OR DAMAGES IN CONNECTION WITH THIS AGREEMENT, YOUR ACCESS TO AND USE OF, OR INABILITY TO ACCESS OR USE, THE WEBSITE, WHETHER AS A RESULT OF A BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE OR PASSIVE) OR ANY OTHER CAUSE OF ACTION AT LAW OR IN EQUITY, REGARDLESS OF THE THEORY OF LIABILITY, IS LIMITED TO NO MORE THAN THE HIGHER OF FIFTY (US $50) DOLLARS OR THE AMOUNT OF YOUR CURRENT SUBSCRIPTION FEE.

                                        <br />IN ADDITION, PLG WILL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR LOST PROFITS, CONSEQUENTIAL, INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY OR SPECIAL DAMAGES.</b>
                                        <br /><br /><b style={{textDecoration: 'underline'}}>Acceptable Use <br /><br /></b>
                                        You may not use the Website to:
                                        <ol>
                                            <li>Violate or attempt to violate the security of the Website in any way, including, but not limited to, attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorization.
                                            </li>
                                            <li>Transmit via or through the Website any information, data, text, images, files, links, software, chat, communication or other materials (“Content”) that is, or which PLG considers in its sole discretion to be, unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, offensive, obscene, pornographic, hateful or threatening to any group defined by race, religion, gender, national origin or sexual orientation, or otherwise objectionable, including without limitation blatant expressions of bigotry, prejudice, racism, hatred or excessive profanity or post any obscene, lewd, lascivious, excessively violent, harassing or otherwise objectionable content; </li>
                                            <li>Introduce viruses, worms, Trojan horses and/or harmful code on the Internet;
                                            </li>
                                            <li>Display material that exploits children under 18 years of age;
                                            </li>
                                            <li>Impersonate any other person; </li>
                                            <li>Intentionally or unintentionally violate any applicable local, state, national international or foreign law, including but not limited to any regulations having the force of law while using or accessing the Website or in connection with your use of the Website, in any manner; or
                                            </li>
                                            <li>Invade the privacy or violate any personal or proprietary right (including intellectual property rights) of any person or entity.
                                            </li>
                                        </ol>
                                        <br />These are some, though not all, of the activities that may result in removal of content or barring access to the Website. The final decision of whether any information, code or other material is in violation of any of these policies is at the sole discretion of PLG. You agree that violations by you, or any person or entity acting on your behalf, of the terms of this Agreement will entitle PLG, in addition to any other remedies including referral for criminal prosecution, to remove any content, code or other material, and take any other actions that PLG believes is necessary to protect itself, its users, and the Website.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>User Provided Content  <br /><br /></b>
                                        Other than as related to actual listings on the Website, any information, ideas, concepts, know-how, or techniques (“Ideas”) contained in any communication to PLG through the Website or email shall become the exclusive property of PLG and PLG may make use of the Ideas anywhere, in any medium, for any purpose, including developing and offering products and services using such Ideas, without compensation to you.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>Governing Law<br /><br /></b>
                                        This Agreement shall be governed and construed in accordance with the laws of the State of Illinois without giving effect to conflict of law principles. You and PLG agree to submit to the personal and exclusive jurisdiction of the state and federal courts located in Cook County, Illinois for the purpose of litigating all disputes relating to the Website.

                                        <br /><br /><b style={{textDecoration: 'underline'}}>Policies<br /><br /></b>
                                        You shall use the Website only in compliance with PLG’s published policies then in effect and all applicable laws and regulations.

                                        <br />You can view PLG’s <a target="_blank" href='https://plgconsulting.com/privacy-policy/'> privacy policy for the Website here</a>. 

                                        <br /><br /><b style={{textDecoration: 'underline'}}>Revisions to the Terms of Use<br /><br /></b>
                                        We may revise this Agreement from time to time. If we do, those revisions will supersede prior versions. Unless we say otherwise, revisions will be effective upon the effective date indicated at the top of this Agreement. All revisions will result in an update to the effective date of this Agreement at the top of the page. We encourage you to check the effective date of this Agreement whenever you visit PLG’s Website. Your continued access or use of the Website constitutes your acceptance of any revisions. If you do not agree to the revisions, you should stop using the Website.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>Consent to Electronic Communications<br /><br /></b>
                                        If you provide us with your email address, and choose to receive email communications from us, you thereby consent to receiving certain electronic communications from PLG.  If allowable under applicable law, any notices, agreements, disclosures, or other communications that PLG is required by law to send to you in writing will be sent to you electronically, if allowed by applicable law.  If applicable law does not provide for such electronic communications to satisfy legal communication requirements, including that such communications be in writing, then by providing us with your email address and choosing to receive emails from us, you agree that such electronic communications satisfy any legal communication requirements, including that such communications be in writing.

                                        <br /><br /><b style={{textDecoration: 'underline'}}> Children<br /><br /></b>
                                        This Website is intended for use by persons age 18 and over. By using the Website, or signing up for the Website, you hereby represent and warrant that you are 18 years old or over.  If PLG discovers that you are under the age of 18, PLG reserves the right to block or otherwise prevent your future access to or use of the Website.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>General<br /><br /></b>
                                        This Agreement constitutes the entire and exclusive agreement between You and PLG regarding use of and access to the Website.

                                        <br /><br />You may not assign or transfer this Agreement or your rights hereunder, in whole or in part, by operation of law or otherwise, without PLG’s prior written consent. PLG may assign this Agreement at any time without notice.

                                        <br /><br />The failure to require performance of any provision will not affect PLG’s right to require performance at any time thereafter, nor will a waiver of any breach or default of this Agreement or any provision of this Agreement constitute a waiver of any subsequent breach or default or a waiver of the provision itself.

                                        <br /><br />In the event that any part of this Agreement is held to be invalid or unenforceable, the unenforceable part will be given effect to the greatest extent possible and the remaining parts will remain in full force and effect.

                                        <br /><br />Neither PLG nor you will be in violation of this Agreement if a failure to perform any obligation is due to an event beyond such party’s control, such as significant failure of a part of the power grid, significant failure of the Internet, natural disaster, war, riot, insurrection, epidemic, strikes or other organized labor action, terrorism, or other similar events.

                                        <br /><br />Upon termination or expiration of this Agreement, any provision that by its nature or express terms should survive will survive such termination or expiration.
                                        <br /><br /><b style={{textDecoration: 'underline'}}>Contact Information<br /><br /></b>
                                        The Website is offered by Professional Logistics Group, Inc., 125 South Clark St., 17th Floor, Chicago, Illinois, 60603. You may contact us by sending correspondence to the foregoing address or by emailing us at info@industrialnavigator.com. Report misuse of the Website at info@industrialnavigator.com.

                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>






                <HomePageFooter />
                <Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Forgot Password
                                    </h2>
                                    <form style={{ width: '95%', marginLeft: '2%', marginRight: '2%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
                                        <div id='fp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        {/* <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
                                        </div> */}
                                        <input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />

                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div>
                            <div style={{ padding: '3rem', backgroundColor: 'white' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
                                        <img src={logo} />
                                    </div>
                                    <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
                                        <div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Enter email address and password to login</div>
                                        <div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
                                        <div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='login_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setLoginEmail} value={this.state.login_email} />
                                        </div>
                                        {this.loginValidator.message('email', this.state.login_email, 'required')}
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword} value={this.state.login_password} />
                                        </div>
                                        {this.loginValidator.message('password', this.state.login_password, 'required')}
                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin}>Sign In</button>
                                        </div>
                                        <div className='col_full center padding-top-20'>
                                            <span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
                                        </div>
                                    </form>
                                    <div style={{ paddingTop: '1rem' }}>
                                        <small>
                                            <div className='text-center text-black font-weight-bold'>
                                                Don't have an account? <span className='cursor-pointer color-blue' onClick={this.showRegistrationModal}>Sign Up</span></div>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Login Successful
                            </h2>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showLogoutSuccessModal} onHide={this.handleLogoutSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Logout Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                You are logged out of the system
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLogoutSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName='custom-modal-step3' show={this.state.showRegistrationModal} onHide={this.handleRegistrationModal} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
                        <div>
                            <div>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '30px auto' }}>
                                        <img src={logo} />
                                    </div>
                                    {/* <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Welcome to Industrial Navigator!
									</h2> */}
                                    <MultiStepRegistration />
                                    {/* <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
										<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Please enter your details below to register for Industrial Navigator.</div>

										<div id='regi_errors' className='margin-top-15'></div>

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
										</div>
										{this.validator.message('first name', this.state.fname, 'required')}

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
										</div>
										{this.validator.message('last name', this.state.lname, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
										</div>
										{this.validator.message('company name', this.state.companyName, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail.bind(this)} value={this.state.email} />
										</div>
										{this.validator.message('email', this.state.email, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
										</div>
										{this.validator.message('password', this.state.password, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
										</div>
										{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
										<ReCAPTCHA
											ref={recaptchaRef}
											// size='invisible'
											className='margin-top-15'
											onChange={this.onRecaptchaChange.bind(this)}
											sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
										/>
										{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
										<div className='col_full center padding-top-20'>
											<button className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
										</div>
									</form> */}
                                    {/* <div style={{ paddingTop: '1rem' }}>
										<small>
											<div className='text-center text-black font-weight-bold'>
												Already have an account? <span className='cursor-pointer color-blue' onClick={this.checkIfLoginPage}>Sign In</span></div>
										</small>
									</div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Registration Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                Please verify your email address before adding a listing.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showOTPModal} onHide={this.handleOTPModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Verify OTP
                                    </h2>
                                    <form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='otp_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email.</div>
                                        <div id='otp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        <InputBoxComponent type='number' min='0' name='otp' onHandleInputChange={this.handleInputChange} />
                                        {this.OTPValidator.message('otp', this.state.otp, 'required')}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPVerification}>Verify</button>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default withRouter(PlansAndPricing);

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)