import React, { useState } from 'react';
import TitleSection from '../qloud/titlesection';
import ContactUS from '../../ContactUs'
const Index = props => {

    return (
        <>
            <section className='section-content'>
               <div className="container">
                    <div className="row">
                        <div style={{marginTop:70}} className="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                            <TitleSection
                                className='text-left iq-title-box-2'
                                title='Get started!'
                                subTitle='Lets Get Started'
                                titleIcon=''
                                description='Basic search/listing functionality is FREE to registered users. Enhanced search options and map datasets available with subscription. Contact us for help, custom solutions or specialized logistics and site consulting.'
                            />
                            <div className="iq-btn-container">
                                <li className='' style={{ display: 'inline-block', padding: '15px 15px 15px 0px' }}><a  href="/plans-and-pricing" style={{padding:10}} className="nav-item login iq-button iq-btn-outline iq-btn-round" >See Plans</a></li>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                              <ContactUS />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Index