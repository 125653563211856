import React, { Component } from 'react';
var nf = new Intl.NumberFormat();
interface PFeatures {
	feature: any;
}

class SFeatures {
	feature: any = null;

	constructor(ref: Features) {
		this.feature = ref.props.feature;
	}
}

class Features extends Component<PFeatures, SFeatures> {
	constructor(props: PFeatures) {
		super(props);
		this.state = new SFeatures(this);
	}

	formatPrice(price) {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0
		});
		const formattedPrice = formatter.format(price);
		return formattedPrice;
	}

	renderFeature(properties) {
		return <React.Fragment>
			{properties.propertyType &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Property type: </span> {properties.propertyType.replace(/\*/g, ', ')}</li>
			}
			{properties.propertyFor &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Property type:</span> {properties.propertyFor}</li>
			}
			{properties.price &&
				<li className='pull-left padding-left-right-10'>
					<span className='font-weight-bold'>Price:</span> {properties.price !== '' ? this.formatPrice(properties.price) : ''}
					{properties.perUnit &&
						<span> / {properties.perUnit}</span>
					}
				</li>
			}
			{properties.acreage &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Acreage:</span> {properties.acreage !== '' ? properties.acreage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}</li>
			}
			{properties.zoning &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Zoning:</span> {properties.zoning.replace(/\*/g, ', ')}</li>
			}
			{properties.building &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Building:</span> {properties.building}</li>
			}
			{properties.squareFeet &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Sq. ft:</span> {properties.squareFeet !== '' ? properties.squareFeet.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}</li>
			}
			{properties.interiorRoofHeight &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Interior roof height (ft):</span> {properties.interiorRoofHeight !== '' ? properties.interiorRoofHeight.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''}</li>
			}
			{properties.suitableFor &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Suitable for:</span> {properties.suitableFor !== '' ? properties.suitableFor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace(/\*/g, ', ') : ''}</li>
			}
			{properties.shortLines &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Short Lines:</span> {properties.shortLines.replace(/\*/g, ', ')}</li>
			}
			{properties.classOneConnections &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Class I connections:</span> {properties.classOneConnections.replace(/\*/g, ', ')}</li>
			}
			{properties.railInPlace &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Rail in place:</span> {properties.railInPlace}</li>
			}
			{properties.railcarCapacity &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Rail car storage capacity:</span> {properties.railcarCapacity}</li>
			}
			{properties.pipelineAccess &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Pipeline access:</span> {properties.pipelineAccess.replace(/\*/g, ', ')}</li>
			}
			{properties.waterSewerInPlace &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Water sewer in place:</span> {properties.waterSewerInPlace}</li>
			}
			{properties.electricPowerInPlace &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Electric power in place:</span> {properties.electricPowerInPlace}</li>
			}
			{properties.serviceVoltage &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Service voltage (kV):</span> {properties.serviceVoltage}</li>
			}
			{properties.distanceToSubstation &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Distance to Substation (miles):</span> {properties.distanceToSubstation}</li>
			}
			{properties.naturalGasInPlace &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Natural gas service in place:</span> {properties.naturalGasInPlace}</li>
			}
			{properties.lineDiameter &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Line diameter (inches):</span> {properties.lineDiameter}</li>
			}
			{properties.environmental &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Environmental:</span> {properties.environmental}</li>
			}
			{properties.airAttainmentStatus &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Air attainment status:</span> {properties.airAttainmentStatus}</li>
			}
			{properties.capabilities.replace('Other', '') &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Capabilities:</span> {properties.capabilities.replace('Other*', '').replace('*Other', '').replace(/\*/g, ', ')}</li>
			}
			{properties.otherCapabilities &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Other Capabilities:</span> {properties.otherCapabilities}</li>
			}
			{properties.railcarStorageCapacity &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Railcar Storage Capacity:</span> {properties.railcarStorageCapacity.replace(/\*/g, ', ')}</li>
			}
			{properties.railcarStorage &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Railcar Storage:</span> {properties.railcarStorage.replace(/\*/g, ', ')}</li>
			}
			{properties.unitTrainCapable &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Unit train capable:</span> {properties.unitTrainCapable}</li>
			}


			
			{properties.otherEquipments &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Other Equipments:</span> {properties.otherEquipments}</li>
			}
			{properties.otherProductStorage &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Other Product Storage:</span> {properties.otherProductStorage}</li>
			}
			

			{properties.onsiteStorageCapacity &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Onsite storage capacity:</span> {properties.onsiteStorageCapacity}</li>
			}
			{properties.tankage &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Tankage:</span> {properties.tankage}</li>
			}
			{properties.nameplateCapacity &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Nameplate capacity:</span> {properties.nameplateCapacity}</li>
			}
			{/* { (properties.bulkType !== '' && properties.bulkType !== undefined) &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Bulk type:</span> {properties.bulkType}</li>
			} */}
			{properties.productsHandled.replace('Other', '') &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Products Handled:</span> {properties.productsHandled.replace('Other*', '').replace('*Other', '').replace(/\*/g, ', ')}</li>
			}
			{properties.otherProductsHandled &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Other Products Handled:</span> {properties.otherProductsHandled}</li>
			}
			{properties.indoorStorage &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Indoor storage:</span> {properties.indoorStorage}</li>
			}
			{properties.marineAccess &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Marine access:</span> {properties.marineAccess}</li>
			}
			{properties.docInPlace &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Dock in place:</span> {properties.docInPlace}</li>
			}
			{properties.waterways &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Waterways:</span> {properties.waterways}</li>
			}
			{properties.distancetoWater &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Distance to water:</span> {properties.distancetoWater} miles</li>
			}
			{properties.warehousePrice &&
				<li className='pull-left padding-left-right-10'>
					<span className='font-weight-bold'>Warehouse price:</span> {properties.warehousePrice !== '' ? this.formatPrice(properties.warehousePrice) : ''}
					{properties.warehousePricePerUnit &&
						<span> / {properties.warehousePricePerUnit}</span>
					}
				</li>
			}
			{properties.warehouseCapabilities &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Warehouse capabilities:</span> {properties.warehouseCapabilities}</li>
			}
			{properties.warehouseMinSize &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Warehouse min size (sq ft):</span> {nf.format(properties.warehouseMinSize)}</li>
			}
			{properties.warehouseMaxSize &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Warehouse max size (sq ft):</span> {nf.format(properties.warehouseMaxSize)}</li>
			}
			{properties.temperatureControl &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Temperature control:</span> {properties.temperatureControl}</li>
			}
			{properties.parkingSpaces &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Parking spaces:</span> {nf.format(properties.parkingSpaces)}</li>
			}
			{properties.foodGrade &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Food grade:</span> {properties.foodGrade}</li>
			}
			{properties.freeTradeZone &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Foreign trade zone:</span> {properties.freeTradeZone}</li>
			}
			{properties.roofHeight &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Clear height (ft):</span> {nf.format(properties.roofHeight)}</li>
			}
			{properties.parkingCapacity &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Parking capacity:</span> {properties.parkingCapacity}</li>
			}



			{properties.TotalDockDoors &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Total Dock Doors:</span> {properties.TotalDockDoors}</li>
			}
			{properties.DriveInDockDoors &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Drive In Dock Doors:</span> {properties.DriveInDockDoors}</li>
			}
			{properties.DockLevelDoors &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Dock Level Doors:</span> {properties.DockLevelDoors}</li>
			}
			{properties.TotalWarehouseArea &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Total Warehouse Area (sq ft):</span> {properties.TotalWarehouseArea}</li>
			}
			{properties.TotalWarehouseAvailableArea &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Total Warehouse Available Area (sq ft):</span> {properties.TotalWarehouseAvailableArea}</li>
			}
			{properties.TotalWarehouseOfficeSpaceArea &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Total Warehouse Office Space Area (sq ft):</span> {properties.TotalWarehouseOfficeSpaceArea}</li>
			}
			{properties.securedParking &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Secured Parking:</span> {properties.securedParking}</li>
			}
			{properties.portDepth &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Port Depth(ft):</span> {properties.portDepth}</li>
			}
			{properties.portLength &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Port Length(ft):</span> {properties.portLength}</li>
			}

			{properties.nearestInterstate &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Nearest interstate:</span> {properties.nearestInterstate}
					{properties.milesToInterstate &&
						<React.Fragment> ({properties.milesToInterstate} miles away)</React.Fragment>
					}
				</li>
			}
			{/* { properties.milesToInterstate &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Miles to interstate:</span> {properties.milesToInterstate}</li>
			} */}
			{properties.nearestAirport &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Nearest airport:</span> {properties.nearestAirport}
					{properties.milesToAirport &&
						<React.Fragment> ({properties.milesToAirport} miles away)</React.Fragment>
					}
				</li>
			}
			{/* { properties.milesToAirport &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Miles to airport:</span> {properties.milesToAirport}</li>
			} */}
			{properties.silos &&
				<li className='pull-left padding-left-right-10'><span className='font-weight-bold'>Silo:</span> {properties.silos}</li>
			}
		</React.Fragment>;
	}

	render() {
		return (
			<React.Fragment>
				{
					this.props.feature.properties.majorFeatures !== '' &&
					<article className='margin-bottom-20 border-bottom-1 color-black'>
						<header>
							<h2 className='margin-bottom-15 text-decoration-underline'>Major Features</h2>
						</header>
						<p>{this.props.feature.properties.majorFeatures.replace(/[^\x00-\x7F]/g, '')}</p>
					</article>
				}
				{
					this.props.feature.properties.specifications !== '' &&
					<article className='margin-bottom-20 border-bottom-1 color-black'>
						<header>
							<h2 className='margin-bottom-15 text-decoration-underline'>Specifications</h2>
						</header>
						<p>{this.props.feature.properties.specifications.replace(/[^\x00-\x7F]/g, '')}</p>
					</article>
				}
				<article className='margin-bottom-20 color-black'>
					<header>
						<h2 className='margin-bottom-15 text-decoration-underline'>Other Features</h2>
					</header>
					<ul className='bullets features-list'>
						{this.renderFeature(this.props.feature.properties)}
					</ul>
				</article>
			</React.Fragment>
		);
	}
}

export default Features;