import React, { useState } from 'react';
import createlist from "../../public/assets/revslider/assets/CreateListing.svg";
const Index = props => {

    return (
        <>
            <section style={{background:'#fbfbfb'}} className="section-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <img style={{ maxWidht: 570, maxHeight: 431, marginTop: -70 }} src={createlist} className="img-fluid" alt="qloud" />
                        </div>
                        <div className="col-lg-6 mb-lg-0 mb-5">
                            <div className="iq-title-box iq-title-default">
                                <h2 style={{ fontFamily: 'TeXGyreAdventor-Bold, sans-serif', textTransform: 'none', color: '#142149', lineHeight: '1.5em', fontWeight: 800, letterSpacing: '1px' }} className="iq-title ">How It Works</h2>
                                <p style={{ fontSize: '1rem', fontFamily: 'Roboto, sans-serif', color: '#4a4a4a', fontWeight: 300, lineHeight: 1.5 }} className="iq-title-desc ">
                                    Industrial Navigator private label is a custom solution designed to meet your needs.<br />
                                </p><br />
                                <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                    <li>First, we learn about your portfolio of assets and marketing goals.</li>
                                    <li>Develop a custom solution to integrate Industrial Navigator's search capabilities within your existing website based on your goals.</li>
                                    <li>Provide support as required for migrating information about your portfolio into the private label application.</li>
                                    <li>Provide advice on how to use the private label app to support your marketing campaigns and track their success.</li>
                                    <li>As part of your private label subscription, all your assets will be marketed on Industrial Navigator website. at no additional cost.</li>
                                    <li>Provide an annual or multi-year license to use the app and deliver ongoing support.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Index