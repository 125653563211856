import React, { Component } from 'react';
import '../custom.css';
import { Button, Breadcrumb, BreadcrumbItem, Col, Row, Form, Modal, FormGroup, OverlayTrigger, Tooltip, ControlLabel, FormControl } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import Header from './Header';
//import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import 'braintree-web';
import DropIn from 'braintree-web-drop-in-react';
import UserProfile from './UserProfile';
import { CheckoutStrip } from './checkoutstrip';
import { Elements } from "@stripe/react-stripe-js";
import '../custom_braintree.css';
import PlacesAutocomplete, {
	geocodeByAddress
} from 'react-places-autocomplete';
import InputCheckboxComponent from './InputCheckboxComponent';
import InputBoxComponent from './InputBoxComponent';
import { Cookies } from 'react-cookie';
import HomePageHeader from './HomePageHeader';
import { loadStripe } from "@stripe/stripe-js";
import fire from './FireBase';


require('firebase/auth')
const promise = loadStripe("pk_live_51JQZCTI9aRcuI4Jh6A4nCIJAOhTzdxjXDvRZ1HYx6GsqY1EazrOV5PV3oYL6qUQHbDMboqDjJgeXipyZbzpXhzId00IX8JmTkY");
const planNamesArr = localStorage.getItem('planNames') ? JSON.parse(localStorage.getItem('planNames')) : [];
// const id = planNamesArr[0].id;
// const id=3;
let priceId;
let customerId;


// console.log("email at top",email)
// const BASE_URL = 'http://3.220.32.133:5000';

const cookies = new Cookies();


interface PHomePageHeader {
	onRef?: (homePageHeader: HomePageHeader) => void;
	data: any;
}

// interface PCheckout extends PHomePageHeader {
//    history?: Array<string>;
// }

interface PCheckout {
	history?: Array<string>;
}

class SCheckout {
	constructor() {
	}
}

class CheckoutSubscription extends Component<PCheckout, any> {
	validator: SimpleReactValidator = null;

	instance;

	constructor(props) {
		super(props);
		this.state = {
			first_name: '',
			last_name: '',
			business_name: '',
			email: '',
			zipcode: '',
			address: '',
			payment_info: '',
			order_amount: Number(props.location.state.plan_arr[0].membership_level_cost),
			checkbox: false,
			terms1: false,
			order_id: null,
			clientToken: null,
			showPlaceSuccessModal: false,
			showNewRecordModal: false,
			showDeleteListingModal: false,
			showPayCardModal: false,
			deleteRecId: '',
			coupanOrderSuccess: false,
			showDeleteListingSuccessModal: false,
			shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
			couponCodeAvailable: false,
			paywithCard: true,
			user_email: props.location.state.email,
			plan_arr: props.location.state.plan_arr,
			password: props.location.state.password,
			isOrderFree: Number(props.location.state.plan_arr[0].membership_level_cost) > 0 ? false : true,
			payment_type: Number(props.location.state.plan_arr[0].membership_level_cost) > 0 ? 'online' : 'free',
			planArr: localStorage.getItem('planNames') ? JSON.parse(localStorage.getItem('planNames')) : [],
			emailid: localStorage.getItem('UseremailId') ? JSON.parse(localStorage.getItem('UseremailId')) : [],
			// user_email: emailid.email
		}

		this.setFirstName = this.setFirstName.bind(this);
		this.setLastName = this.setLastName.bind(this);
		this.setBusinessName = this.setBusinessName.bind(this);
		this.setEmail = this.setEmail.bind(this);
		this.setZipcode = this.setZipcode.bind(this);
		this.setBillingAddress = this.setBillingAddress.bind(this);
		// this.setPaymentInfo = this.setPaymentInfo.bind(this);
		this.setTermsAndConditions = this.setTermsAndConditions.bind(this);
		this.proceedToPayment = this.proceedToPayment.bind(this);

	}

	handlePlaceSuccessModal() {
		//this.props.history.push('/my-listing');
		this.props.history.push('/');
	}

	async componentDidMount() {
		//console.log('get user email', UserProfile.getEmail());
		// console.log('this.props.location.state.order_amount');
		// console.log(this.props.location.state.order_amount);

		// try {
		// 	// Get a client token for authorization from your server
		// 	const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/braintree/v1/getToken');
		// 	const clientToken = response.data.clientToken;
		// 	this.setState({ clientToken });
		// 	// console.log('clientToken is generated')
		// 	// console.log(clientToken)
		// } catch (err) {
		// 	console.error(err);
		// }
	}


	autoLogin() {
		try {
			console.log('handle auto login');
			axios.post(process.env.REACT_APP_BASE_URL + '/api/auto-login-user',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					login_email: this.state.user_email,
					login_password: this.state.password,
				}).then((response: {
					status: number
					, data: {
						bookmarks_str: string
						, email: string
						, first_name: string
						, last_name: string
						, max_no_entries: string
						, options: string
						, place_names_arr: string //Array<any>
						, user_type: string
						, token: string
					}
				}) => {
					document.getElementById('login_error').style.display = 'none';
					document.getElementById('verify_email_error').style.display = 'none';
					console.log('autoLogin api response: ', response);

					//this.handleClose();

					if (response.status === 200) {
						let bookmarksCount = 0;
						console.log('response.data')
						console.log(response.data)
						if (response.data.bookmarks_str) {
							const bookmarksArr = response.data.bookmarks_str.split(',');
							bookmarksCount = bookmarksArr.length;
						}
						localStorage.setItem('bookmarks', response.data.bookmarks_str);
						localStorage.setItem('listingNames', response.data.place_names_arr);
						localStorage.setItem('maxNoEntries', response.data.max_no_entries);
						localStorage.setItem('membershipOptions', response.data.options);
						localStorage.removeItem('questionOneArr');
						localStorage.removeItem('questionTwoArr');
						localStorage.removeItem('questionThreeArr');
						localStorage.removeItem('questionFourArr');
						localStorage.removeItem('checkboxone');
						localStorage.removeItem('checkboxtwo');

						this.handleLoginSuccessResponse(response);
					} else {
						this.setState({ isLoggedIn: false });
						if (response.status === 204) {
							document.getElementById('verify_email_error').style.display = 'block';
							document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
						} else {
							document.getElementById('login_error').style.display = 'block';
							document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
						}
					}
				}).catch((error) => {
					console.log('error auto login api: ', error);
					// handle your error
					document.getElementById('login_error').style.display = 'block';
					document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
				});
		} catch (err) {
			console.log('error in auto login');
			console.error(err);
		}
	}

	// async buy() {
	// 	try {
	// 		var email = this.state.user_email;
	// 		// var email = UserProfile.getEmail();
	// 		// Send the nonce to your server
	// 		const { nonce } = await this
	// 			.instance
	// 			.requestPaymentMethod();
	// 		await axios.post(process.env.REACT_APP_BASE_URL + '/api/braintree/v1/sandbox',
	// 			{
	// 				paymentMethodNonce: nonce,
	// 				order_id: this.state.order_id,
	// 				email: email,
	// 				order_amount: this.state.order_amount
	// 			}).then(res => {
	// 				console.log(res)
	// 				if (res.status === 200) {
	// 					// this.handleOrderResponse();
	// 					this.autoLogin();
	// 				}
	// 				else {
	// 					console.log('order is failed to update');
	// 					alert('Something went wrong, please try again!');
	// 					// this.props.history.push('/');
	// 				}
	// 			});
	// 	} catch (err) {
	// 		console.log('error in order update');
	// 		console.error(err);
	// 	}
	// }

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
	}

	setTermsAndConditions(event) {
		this.setState({ checkbox: !this.state.checkbox });
	}

	setFirstName(event) {
		this.setState({ first_name: event.target.value });
	}

	setLastName(event) {
		this.setState({ last_name: event.target.value });
	}

	setBusinessName(event) {
		this.setState({ business_name: event.target.value });
	}

	setEmail(event) {
		this.setState({ email: event.target.value });
	}

	setZipcode(event) {
		this.setState({ zipcode: event.target.value });
	}

	setBillingAddress = address => {
		this.setState({ address });

	}

	setCoordinates = address => {

		geocodeByAddress(address).then(results => {
			this.setBillingAddress(results[0].formatted_address);
			// console.log(results[0].formatted_address);
		});

	}

	proceedToPayment() {
		if (this.validator.allValid()) {
			// if (this.state.couponCodeAvailable && this.state.couponCode.toLowerCase() !== 'goindnav') {
			// 	const promocode_obj={"name1":"value1","name2":"value2"}
			// 	//console.log(promocode_obj)
			// 	alert(promocode_obj.name1);
			// 	return;
			// }
			axios.post(process.env.REACT_APP_BASE_URL + '/api/create-order',
				{
					headers: { 'Content-Type': 'application/json' },
					first_name: this.state.first_name,
					last_name: this.state.last_name,
					business_name: this.state.business_name,
					email: this.state.emailid.email,
					usr_email: this.state.user_email,
					zipcode: this.state.zipcode,
					address: this.state.address,
					// payment_info: this.state.payment_info,
					order_amount: this.state.order_amount,
					place_ids: localStorage.getItem('planNames') ? localStorage.getItem('planNames') : '',
					order_type: 'membership_plan',
					payment_type: this.state.payment_type,
					plan_id: this.state.planArr[0].id,
					customer_id: localStorage.getItem('CustomerId'),
					subscription_id: localStorage.getItem('SubscriptionId'),

				}).then(res => {
					console.log(res)
					if (res.status === 200) {
						// this.setState({
						// 	isLoggedIn: true,
						// 	showPlaceSuccessModal: true,
						// });
						// console.log('order creation successful');
						// console.log('couponCodeAvailable',this.state.couponCodeAvailable);
						// console.log('couponCode',this.state.couponCode);


						//this.setState({ showPlaceSuccessModal: true});
						this.setState({ order_id: res.data.order_id });
						if (this.state.couponCodeAvailable && this.state.couponCode) {
							console.log('inside createCouponOrder');
							this.createCouponOrder();
						} else if (this.state.isOrderFree && this.state.order_amount <= 0) {
							console.log('create order for free amount');
							this.createFreeOrder();
							// this.autoLogin();
						} else {
							console.log('inside buy');
							//this.buy();
							//this.openshowPayCardModal();
							this.loginUser();
							//this.autoLogin();

						}

					}
					else {
						console.log('order creation failed');
						alert('Something went wrong, please try again!')
					}
				}).catch(function (error) {
					console.log('error in order creation');
					alert('Please Make Sure Payment Is Done !')
				});
		}
		else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	createFreeOrder = () => {
		try {
			var email = this.state.user_email;
			axios.post(process.env.REACT_APP_BASE_URL + '/api/update-free-order-details',
				{
					order_id: this.state.order_id,
					email: email,
					order_amount: this.state.order_amount,
				}).then(res => {
					console.log(res)
					if (res.status === 200) {
						//this.setState({ showPlaceSuccessModal: true});
						this.autoLogin();

					}
					else {
						console.log('order(0) is failed to update');
						alert('Something went wrong, please try again!');
					}
				});
		} catch (err) {
			console.log('error in order(0) update');
			console.error(err);
		}
	}

	// 
	createCouponOrder = () => {
				
		try {
			//var email = email;
			console.log("email id in coupan order", this.state.emailid.email)
			const user_email = this.state.emailid.email;
			//const promocode_obj = { "SME": "promo_1Jk2QqI9aRcuI4JhhulVFQCc", "SDG": "promo_1Jk2RUI9aRcuI4JhrLpj3tIy", "EARLY": "promo_1Jk2SKI9aRcuI4JhLmnxomZt" }
			const promocode_obj = { SME: "promo_1Jk2QqI9aRcuI4JhhulVFQCc", SDG: "promo_1JkB4UI9aRcuI4JhfXn1sw0h", EARLY: "promo_1JkB3mI9aRcuI4Jhko6ZlaLQ" }
			const code=this.state.couponCode;
			//alert(promocode_obj[code])
			const promotion_code = promocode_obj[code];

			if (this.state.planArr[0].id == 3) {
				//priceId = 'price_1JjOYtI9aRcuI4Jh7V3hOhIL'
				//priceId for the one day subscription
				priceId ='price_1K2dBhI9aRcuI4Jh6Qr5fxnx'
				
			}
			else if (this.state.planArr[0].id == 4) {
				priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om'
			}

			var self = this;
			axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
				email: this.state.emailid.email,

				//customerId:'',
			}).then(function (response) {
				console.log("responce data email", response.data.customer.email);
				localStorage.setItem('CustomerId', response.data.customer.id);
				customerId = response.data.customer.id
				console.log("customer id:", customerId)
				console.log("price id", priceId)
				console.log("promo code", promotion_code)

				axios.post(process.env.REACT_APP_BASE_URL + '/api/create-coupan-order', {

					priceId: priceId,
					customerId: customerId,
					promotion_code: promotion_code,
				})
					.then(function (response) {
						console.log('response succes', response);
						if (response.status === 200) {

							localStorage.setItem('SubscriptionId', response.data.subscription.id);
							console.log("subscription id for coupan order:", response.data.subscription.id)
							console.log("email id for updating order:", user_email)

							axios.post(process.env.REACT_APP_BASE_URL + '/api/update-subscription-id', {
								usr_email: user_email,
								subscription_id: response.data.subscription.id,
								//customerId:'',
							}).then()

							self.autoLogin();

						}

					})
					.catch(function (error) {
						console.log('response error', error);
						alert("Invalid promo code used.")
						console.log(error);
					});

			});
		} catch (err) {
			console.log('error in order update');
			console.error(err);
		}
	}


	handleOrderResponse = () => {
		console.log('order is updated with success');
		//localStorage.removeItem('listingNames');
		localStorage.removeItem('planNames');
		//this.setState({ showPlaceSuccessModal: true });
		var placeOrderDiv = document.getElementById('place-order-div');
		var placeOrderBtn = document.getElementById('place-order-btn');
		placeOrderDiv.removeChild(placeOrderBtn);
		this.setState({
			shoppingCartCount: 0
		});
	}

	handleNewRecordModal = () => {
		this.setState({ showNewRecordModal: false });
		localStorage.removeItem('useContactInfo');
		localStorage.removeItem('contactName');
		localStorage.removeItem('contactTitle');
		localStorage.removeItem('contactEmail');
		localStorage.removeItem('contactNo');
	}

	handleCheckboxChange = (name, value) => {
		this.setState({ [name]: value });
	}

	confirmDeleteListing = (recId) => {
		this.setState({
			showDeleteListingModal: true,
			deleteRecId: recId
		});
	}

	handleDeleteListingModal = () => {
		this.setState({
			showDeleteListingModal: false
		});
	}
	openshowPayCardModal = () => {
		this.setState({
			showPayCardModal: true
		});

	}
	loginUser = () => {
		const Paymentstatus = localStorage.getItem('PaymentIntent') ? JSON.parse(localStorage.getItem('PaymentIntent')) : [];
		console.log("paymentstatusobj", Paymentstatus.statusofuser.paymentIntent.status)
		if (Paymentstatus.statusofuser.paymentIntent.status == "succeeded") {
			localStorage.removeItem('PaymentIntent');


			console.log("paymentstatusobj is removed", Paymentstatus.statusofuser.paymentIntent.status)
			this.autoLogin();
		}
	}
	handleshowPayCardModal = () => {
		this.setState({
			showPayCardModal: false
		});
	}

	deleteListing = () => {
		let deleteRecId = this.state.deleteRecId;

		axios.post(process.env.REACT_APP_BASE_URL + '/api/discard-single-listing', {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			rec_id: this.state.deleteRecId,
			email: this.state.user_email
		}).then(response => {
			console.log(response)
			if (response.status === 200) {
				var listingNames = [];
				if (localStorage.getItem('planNames')) {
					listingNames = JSON.parse(localStorage.getItem('planNames'));
				}
				var newListingNamesArr = listingNames.filter(function (listingNameObj) { return listingNameObj.id != deleteRecId; });

				this.setState({
					showDeleteListingSuccessModal: true,
					deleteRecId: '',
					showDeleteListingModal: false,
					shoppingCartCount: newListingNamesArr.length
				}, () => {
					if (newListingNamesArr.length > 0) {
						// update listing in localstorage
						console.log('new newListingNamesArr')
						console.log(newListingNamesArr)
						console.log(newListingNamesArr.length)
						localStorage.setItem('planNames', JSON.stringify(newListingNamesArr));
					} else {
						localStorage.removeItem('planNames');
						this.props.history.push('/');
					}
					this.child.updateShoppingcart()
				})
			} else {
				this.setState({
					showErrorModal: true
				})
			}
		}).catch(error => {
			// handle your error
			console.log(error)
		});
	}

	handleDeleteListingSuccessModal = () => {
		this.setState({
			showDeleteListingSuccessModal: false
		})
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleLoginSuccessResponse = response => {

		console.log('order is updated with success');
		localStorage.removeItem('listingNames');
		localStorage.removeItem('UseremailId');

		//this.setState({ showPlaceSuccessModal: true });
		var placeOrderDiv = document.getElementById('place-order-div');
		var placeOrderBtn = document.getElementById('place-order-btn');
		placeOrderDiv.removeChild(placeOrderBtn);
		this.setState({
			shoppingCartCount: 0
		});

		console.log('inside handleLoginSuccessResponse', response);
		UserProfile.setEmail(response.data.email);
		UserProfile.setFirstName(response.data.first_name);
		UserProfile.setLastName(response.data.last_name);
		UserProfile.setUserType(response.data.user_type);
		UserProfile.setCompanyName(response.data.companyName);
		
		this.setState({
			isLoggedIn: true
		});

		cookies.set('token', response.data.token);
		console.log(response.data);
		localStorage.removeItem('planNames');
		this.props.history.push('/upgrade-success');
		//this.setState({ showPlaceSuccessModal: true });
		//this.props.history.push('/');
	}

	child: Header = null;

	render() {
		const listingNamesArr = localStorage.getItem('listingNamesArr') ? JSON.parse(localStorage.getItem('listingNamesArr')) : [];
		const planNamesArr = localStorage.getItem('planNames') ? JSON.parse(localStorage.getItem('planNames')) : [];

		return (
			<div>
				<Header data={this.props} onRef={ref => (this.child = ref)} shoppingCartCount={this.state.shoppingCartCount} />
				<div id='page-content' style={{ backgroundColor: 'white', paddingBottom: '50px', minHeight: '100vh' }}>
					<div>
						<Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
							<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
							<Link to={{ pathname: '/create' }}><span className='color-white'>Create</span></Link>&nbsp;/&nbsp;
							<BreadcrumbItem active>Checkout</BreadcrumbItem>
						</Breadcrumb>
						<div className='container-fluid' style={{ marginTop: '20px' }}>
							<div className='col-lg-10 col-lg-offset-1 '>
								<div className='col-lg-5 '>
									{/* <h1>Billing Details</h1> */}
									<Form style={{ marginTop: '20px' }}>
										<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
										<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
										{
											planNamesArr.length > 0 ?
												planNamesArr.map((planNameObj) =>
													<div className='col-lg-12 col-xs-12 padding-0-xs' style={{ lineHeight: '40px', marginBottom: 10 }} key={Math.random()}>
														{/* <h2 style={{ fontSize: 30 }}><b>{planNameObj.membership_level_name}</b></h2><br />
														<h1 style={{ fontSize: 40 }}>{planNameObj.membership_level_cost > 0 ? '$' : ''} {planNameObj.membership_level_cost > 0 ? planNameObj.membership_level_cost : 'Free'} {planNameObj.membership_level_cost > 0 ? '.00' : ''}</h1> */}

													</div>
												) : (
													<React.Fragment></React.Fragment>
												)
										}






									</Form>
								</div>
								<div className='col-lg-1'></div>
								<div className='col-lg-8'>
									<h1>Your Order</h1>
									<div className='padding-30 padding-15-xs' style={{ border: '0px solid #f4f4f2', borderRadius: '6px' }}>
										{/* <h1>Your Order</h1> */}
										<div className='col-lg-12 col-xs-12 padding-0-xs border-bottom-1' style={{ lineHeight: '40px', marginBottom: 10 }}>
											<div className='col-lg-9 col-xs-8 text-left font-weight-bold pull-left padding-0'>Plan Name</div>
											<div className='col-lg-3 col-xs-4 text-center font-weight-bold pull-right'>Total</div>
										</div>

										{
											planNamesArr.length > 0 ?
												planNamesArr.map((planNameObj) =>
													<div className='col-lg-12 col-xs-12 padding-0-xs ' style={{ lineHeight: '40px', marginBottom: 10 }} key={Math.random()}>
														<div className='col-lg-9 col-xs-8 text-left reduce-font-size-20 increase-font-size-25 padding-0'><div className='pull-left reduce-font-size-20'><b >{planNameObj.membership_level_name}</b></div>
														</div>
														<div className='col-lg-3 col-xs-4 text-center pull-right reduce-font-size-20 increase-font-size-25' ><b>{planNameObj.membership_level_cost > 0 ? '$' : ''}{planNameObj.membership_level_cost > 0 ? planNameObj.membership_level_cost : 'Free '}</b> </div>
														<i className='pull-right fa fa-trash cursor-pointer font-size-22-imp' style={{ color: 'red', position: 'absolute', right: 0, top: 8 }} onClick={() => this.confirmDeleteListing(planNameObj.id)}></i>
													</div>
												) : (
													<React.Fragment></React.Fragment>
												)
										}

										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<div className='font-size-13'>Your plan will be invoiced monthly until you cancel your account. You may cancel, upgrade, or downgrade your account by navigating to your profile. </div>
										</div>
										{/* <div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<InputCheckboxComponent name='terms1' checked={this.state.terms1} onHandleCheckboxChange={this.handleCheckboxChange} label='I agree to the website terms and conditions.' />
											{this.validator.message('Terms and Conditions', this.state.terms1, 'accepted')}
										</div> */}
										{
											(!this.state.isOrderFree) && !(this.state.planArr[0].id == 5) && !(this.state.planArr[0].id == 6) &&
											<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>

												<InputCheckboxComponent name='couponCodeAvailable' checked={this.state.couponCodeAvailable} onHandleCheckboxChange={this.handleCheckboxChange} label='I have a coupon code.' /><br />

												{/* <div>
													<h3> Fill the card details and procees to payment</h3>
													<Elements stripe={promise} >
														<CheckoutStrip />
													</Elements>
												</div> */}
											</div>
										}
										{/* {
											(!this.state.paywithCard ?
												(<></>)
												: (
													<div>
														<h3> fill the card details and procees to payment</h3>
														<Elements stripe={promise}>
															<CheckoutStrip />
														</Elements>
													</div>
												))
										} */}






										{
											!this.state.couponCodeAvailable ?
												!this.state.couponCodeAvailable && this.state.isOrderFree ?
													(<></>)
													:
													<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20  '>

														{/* <h3> Enter credit card details below to process payment.</h3> */}
														<Elements stripe={promise} >
															<CheckoutStrip />
														</Elements>

													</div>
												// <Row style={{marginLeft:15}}>
												// 	<Col lg="12" xs="12" offset="1" className="col-12 padding-0-xs margin-bottom-20">
												// 		{/* <h3> Enter credit card details below to process payment.</h3> */}
												// 		<Elements stripe={promise} >
												// 			<CheckoutStrip />
												// 		</Elements>
												// 	</Col>
												// </Row>

												:
												<React.Fragment>
													<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
														<InputCheckboxComponent name='terms1' checked={this.state.terms1} onHandleCheckboxChange={this.handleCheckboxChange} label='I agree to the website ' /><Link target='_blank' to={{ pathname: '/terms-and-conditions' }} ><b> terms and conditions</b></Link><b>.</b>
														{this.validator.message('Terms and Conditions', this.state.terms1, 'accepted')}
													</div>

													<InputBoxComponent name='couponCode' value={this.state.couponCode} onHandleInputChange={this.handleInputChange}  />

													<div className='text-center' style={{ marginTop: '10px', marginBottom: '10px' }} id='place-order-div'>
														<button type='button' className='border-radius-25-imp btn btn-default' id='place-order-btn' onClick={this.proceedToPayment}>Proceed</button>
													</div>
													{/* <div className='col-lg-12 col-xs-12 padding-0-xs margin-top-20'>
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	Coupon Code: promo_1JcP9lI9aRcuI4JhC0LusKqm
																</strong>
															</Tooltip>
														}>
															<label className='form-check-label cursor-pointer color-blue'>Hover to see coupon code</label>
														</OverlayTrigger>
													</div> */}
												</React.Fragment>
										}

										{
											this.state.isOrderFree ?
												<>
													<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
														<InputCheckboxComponent name='terms1' checked={this.state.terms1} onHandleCheckboxChange={this.handleCheckboxChange} label='I agree to the website  ' /><Link to={{ pathname: '/terms-and-conditions' }} ><b> terms and conditions</b></Link><b>.</b>
														{this.validator.message('Terms and Conditions', this.state.terms1, 'accepted')}
													</div>

													<div className='text-center' style={{ marginTop: '10px', marginBottom: '10px' }} id='place-order-div'>
														<button type='button' className='border-radius-25-imp btn btn-default' id='place-order-btn' onClick={this.proceedToPayment}>Proceed</button>
													</div>

												</>
												:
												<>

												</>
										}




									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal show={this.state.showPlaceSuccessModal} onHide={this.handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton onClick={this.handlePlaceSuccessModal.bind(this)}></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-22 margin-bottom-10' style={{ textTransform: 'none' }}>
								Welcome!
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Thanks for subscribing to Industrial Navigator, a unique web tool that simplifies the process of finding and marketing industrial properties and logistics assets.Industrial Navigator is powered by PLG Consulting, which provides custom logistics and supply chain consulting for the industrial economy.
								We welcome your feedback. We are here to help if you encounter any problems or need additional advice. Contact us at info@industrialnavigator.com.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handlePlaceSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showNewRecordModal} onHide={this.handleNewRecordModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Add More?
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Do you want to add more plans?
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<Link to={{
							pathname: '/create',
							state: {
								addMoreClicked: true,
							}
						}}>
							<Button variant='btn btn-default border-radius-25-imp' className='text-uppercase btn btn-default border-radius-25-imp'>Add More</Button>
						</Link>
						<button type='button' className='btn btn-default border-radius-25-imp text-uppercase margin-left-15' onClick={this.handleNewRecordModal.bind(this)}>Not Now</button>
					</Modal.Footer>
				</Modal>
				{/*Modal for filling the card details..*/}

				<Modal show={this.state.showPayCardModal} onHide={this.handleshowPayCardModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>

							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Fill the card details and procees to payment
							</h2>
							<h3> </h3>
							<Elements stripe={promise}>
								<CheckoutStrip />
							</Elements>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.handleshowPayCardModal}>Cancel</button>
					</Modal.Footer>
				</Modal>

				{/* Delete confirmation modal */}
				<Modal show={this.state.showDeleteListingModal} onHide={this.handleDeleteListingModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp' onClick={this.deleteListing}>Confirm</button>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.handleDeleteListingModal}>Cancel</button>
					</Modal.Footer>
				</Modal>

				{/* Delete Listing success modal */}
				<Modal dialogClassName='custom-modal' show={this.state.showDeleteListingSuccessModal} onHide={this.handleDeleteListingSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Listing Deleted
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Listing has been deleted successfully!
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleDeleteListingSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}

	handleClose: any;
}

export default withRouter(CheckoutSubscription);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)