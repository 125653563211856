import React from 'react';
// import Link from "next/link";
import { Link } from 'react-router-dom';
import { FooterMain, FooterLogo, FooterAddress, FooterLinks } from '../../qloud';

class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            footerText: [
                {
                    icon: true,
                    iconClass: 'fa fa-phone',
                    text: '+1 (312) 957-7757',
                    href: 'tel:+1 (312) 957-7757',
                },
                {
                    icon: true,
                    iconClass: 'fa fa-envelope',
                    text: 'info@industrialnavigator.com '
                },
                {
                    icon: true,
                    iconClass: 'fa fa-home',
                    text: '125 S. Clark St.,17th Floor Chicago, IL 60603'
                },
                {
                    icon: true,
                    iconClass: 'fa fa-twitter',
                    href: 'https://twitter.com/IndNavigator',
                },
                {
                    icon: true,
                    iconClass: 'fa fa-linkedin',
                    href: 'https://www.linkedin.com/showcase/industrial-navigator%C2%AE/?viewAsMember=true',
                }
            ],
            footerHome: [
                {
                    section: [
                        {
                            href: '/about-us',
                            title: 'About Us'
                        },
                        {
                            href: '/plans-and-pricing',
                            title: 'Plans and Pricing'
                        },
                        {
                            href: '/view-listings',
                            title: 'View Listings'
                        },
                        {
                            href: '/create-listings',
                            title: 'Create Listings'
                        },
                        {
                            href: '/search-north-american-industrial-sites',
                            title: 'North American Industrial Sites'
                        },
                        {
                            href: '/search-north-american-terminal-and-transloads-sites',
                            title: 'North American Port, Terminal & Transloads Sites'
                        },
                        {
                            href: '/search-north-american-warehouse-and-distributions-sites',
                            title: 'North American Warehouse & Distribution Sites'
                        }
                        // {
                        //     href: '#',
                        //     title: 'Service'
                        // }
                    ]
                },

            ],
            footerAbout: [
                {
                    section: [
                        {
                            href: '#',
                            title: 'Home'
                        },
                        {
                            href: '#',
                            title: 'Blog'
                        },
                        {
                            href: '#',
                            title: 'Careers'
                        },
                        {
                            href: '#',
                            title: 'Clients'
                        },
                        {
                            href: '#',
                            title: 'Service'
                        }
                    ]
                },

            ],
            footerUseful: [
                {
                    section: [
                        {
                            href: '#',
                            title: 'Our Team'
                        },
                        {
                            href: '#',
                            title: 'Pricing Plan'
                        },
                        {
                            href: '#',
                            title: 'FAQ'
                        },
                        {
                            href: '#',
                            title: 'Clients'
                        },
                        {
                            href: '#',
                            title: 'Contact Us'
                        }
                    ]
                },
            ]
        }

    }

    render() {
        const { footerText, footerHome, footerAbout, footerUseful } = this.state;
        return (
            <>
                <FooterMain className={" iq-over-dark-90"}>
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-sm-6 ">
                                    <div className="widget">
                                        <div className="textwidget ">
                                            <FooterLogo
                                                sectionImage={'../public/assets/images/logo.png'}
                                                sectionText={"Industrial Site Search Made Easy"}

                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 mt-4 mt-lg-0 mt-md-0 ">
                                    <FooterLinks title={"MENU"} content={footerHome} />
                                </div>


                                <div className="col-lg-3 col-md-6 col-sm-6 mt-lg-0 mt-4">
                                    <FooterAddress addressContent={footerText} addressTitle={"Contact Us"} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className='font-Gotham-Pro-Medium'
                        style={{ paddingTop: 30, paddingBottom: 30 }}
                    >
                        <div className='container-fluid show-inline-footer'>
                            <div className='col-lg-4 col-md-4 col-sm-5 col-xs-6 font-size-13' style={{ color: '#FFFFFF' }}>
                                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                    © Copyright 2022 Industrial Navigator
                                </div>
                            </div>
                            <div className='font-size-13'
                                style={{ color: '#FFFFFF' }}
                            >
                                <Link target="_blank" style={{ margin: 10, color: "white" }} to={{ pathname: '/terms-and-conditions' }} ><div className='pull-right' style={{ marginLeft: 20, paddingTop: 5, paddingBottom: 5 }}>Terms of Use</div></Link>
                                <Link target="_blank" style={{ margin: 10, color: "white" }} to={{ pathname: '/privacy-policy' }} ><div className='pull-right' style={{ marginLeft: 20, paddingTop: 5, paddingBottom: 5 }}>Privacy Policy</div></Link>
                                <Link style={{ margin: 10, color: "white" }} to={{ pathname: '/dcma' }} ><div className='pull-right' style={{ paddingTop: 5, paddingBottom: 5 }}>DCMA</div></Link>
                            </div>
                        </div>

                        <div className='container-fluid hide-inline-footer'>
                            <div className='col-md-8 font-size-13' style={{ color: '#FFFFFF' }}>
                                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                                    © Copyright 2022 Industrial Navigator
                                </div>
                            </div>
                            <div className='col-md-8 font-size-13'
                                style={{ color: '#FFFFFF',paddingLeft:0 }}
                            >
                                <Link style={{ margin: 30, color: "white" }} to={{ pathname: '/dcma' }} ><div className='col-md-4' style={{ paddingTop: 5, paddingBottom: 5 }}>DCMA</div></Link>
                                <Link target="_blank" style={{ margin: 30, color: "white" }} to={{ pathname: '/terms-and-conditions' }} ><div className='col-md-4' style={{ paddingTop: 5, paddingBottom: 5 }}>Terms of Use</div></Link>
                                <Link target="_blank" style={{ margin: 30, color: "white" }} to={{ pathname: '/privacy-policy' }} ><div className='col-md-4' style={{ paddingTop: 5, paddingBottom: 5 }}>Privacy Policy</div></Link>
                            </div>
                        </div>

                    </div>
                </FooterMain>

            </>
        );
    }

}

export default Index;