import { jsPDF, Font, TextOptionsLight, ImageCompression } from 'jspdf';

export class FontStub implements Font {
	id: number;
	encoding: string;
	fontName: string;
	fontStyle: string;
	isStandardFont: boolean;
	metadata: any;
	objectNumber: number;
	postScriptName: string;
}

export default class BasePdfDocGen {
	doc: jsPDF = null;
	docStorage: jsPDF = null;
	pageWidth: number = 0;
	pageHeight: number = 0;

	initPdfDocument = () => {
		const docStorage: jsPDF = new jsPDF();
		this.doc = docStorage;
		this.docStorage = docStorage;
		const { pageSize } = this.doc.internal;
		this.pageWidth = pageSize.getWidth();
		this.pageHeight = pageSize.getHeight();
	}

	setFontSize(size: number): jsPDF {
		if (this.doc) {
			return this.doc.setFontSize(size);
		}
		if (this.docStorage) {
			return this.docStorage.setFontSize(size);
		}
		return null;
	}

	setTextColor(ch1: number, ch2: number, ch3: number, ch4?: number): jsPDF {
		if (this.doc) {
			return this.doc.setTextColor(ch1, ch2, ch3, ch4);
		}
		if (this.docStorage) {
			return this.docStorage.setTextColor(ch1, ch2, ch3, ch4);
		}
		return null;
	}

	addPage(): jsPDF {
		if (!this.doc) {
			return null;
		}
		return this.doc.addPage();
	}

	text(
		text: string | string[],
		x: number,
		y: number,
		options?: TextOptionsLight,
		transform?: number | any
	): jsPDF {
		if (!this.doc) {
			return null;
		}
		return this.doc.text(text, x, y, options, transform);
	}

	setLineWidth(width: number): jsPDF {
		if (this.doc) {
			return this.doc.setLineWidth(width);
		}
		if (this.docStorage) {
			return this.docStorage.setLineWidth(width);
		}
		return null;
	}

	getTextWidth(text: string): number {
		if (this.doc) {
			return this.doc.getTextWidth(text);
		}
		if (this.docStorage) {
			return this.docStorage.getTextWidth(text);
		}
		return 0;
	}

	getTextDimensions(text: string | string[]): { w: number; h: number } {
		if (this.doc) {
			return this.doc.getTextDimensions(text as string);
		}
		if (this.docStorage) {
			return this.docStorage.getTextDimensions(text as string);
		}
		return { w: 0, h: 0 };
	}

	splitTextToSize(text: string, maxlen: number, options?: any): any {
		if (this.doc) {
			return this.doc.splitTextToSize(text, maxlen, options);
		}
		if (this.docStorage) {
			return this.docStorage.splitTextToSize(text, maxlen, options);
		}
		return '';
	}

	line(x1: number, y1: number, x2: number, y2: number): jsPDF {
		if (!this.doc) {
			return null;
		}
		return this.doc.line(x1, y1, x2, y2);
	}

	addImage(
		imageData: string | HTMLImageElement | HTMLCanvasElement | Uint8Array,
		format: string,
		x: number,
		y: number,
		w: number,
		h: number,
		alias?: string,
		compression?: ImageCompression,
		rotation?: number
	): jsPDF {
		if (!this.doc) {
			return null;
		}
		return this.doc.addImage(imageData, format, x, y, w, h, alias, compression, rotation);
	}

	addFileToVFS(filename: string, filecontent: string): jsPDF {
		if (!this.doc) {
			return null;
		}
		return this.doc.addFileToVFS(filename, filecontent);
	}

	getFont(): Font {
		if (this.doc) {
			return this.doc.getFont();
		}
		if (this.docStorage) {
			return this.docStorage.getFont();
		}
		return new FontStub();
	}

	setFont(
		fontName: string,
		fontStyle?: string,
		fontWeight?: string | number
	): jsPDF {
		if (this.doc) {
			return this.doc.setFont(fontName, fontStyle, fontWeight);
		}
		if (this.docStorage) {
			return this.docStorage.setFont(fontName, fontStyle, fontWeight);
		}
		return null;
	}

	addFont(
		postScriptName: string,
		id: string,
		fontStyle: string,
		fontWeight?: string | number,
		encoding?:
			| 'StandardEncoding'
			| 'MacRomanEncoding'
			| 'Identity-H'
			| 'WinAnsiEncoding',
		isStandardFont?: boolean
	): string {
		if (this.doc) {
			return this.doc.addFont(postScriptName, id, fontStyle, fontWeight, encoding, isStandardFont);
		}
		if (this.docStorage) {
			return this.docStorage.addFont(postScriptName, id, fontStyle, fontWeight, encoding, isStandardFont);
		}
		return null;
	}

	isNewDocPage = (y: number, delta: number): boolean => {
		const { pageHeight, pageWidth } = this;
		return (y + delta >= pageHeight);
	}

	addDocPage = (y: number, delta: number): number => {
		const { pageHeight, pageWidth } = this;
		if (y + delta >= pageHeight && this.doc !== null) {
			this.doc = this.docStorage = this.addPage();
			y = 20;
		}
		return y;
	}

}
