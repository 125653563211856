import { Tabs, Tab, Modal, Button, ProgressBar, Glyphicon, Panel } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from "react-router-dom";
import Header from './HeaderLTE';
import UserProfile from './UserProfile';
import MetaTags from 'react-meta-tags';
import '../css/priceplans.css';

import { Link } from 'react-router-dom';
let email = UserProfile.getEmail()
const recaptchaRef = React.createRef();

function UpgradePlan() {


	//console.log("emaild for user:", email)
	const [planid, setplanid] = useState(false);
	const history = useHistory();


	const onSubmit = data => {
		// var formData = this.state;
		var formData = this;


		var plan_names_arr = [];

		if (formData.plan == undefined) {
			// error_html.innerHTML = 'Please Select Plan';
			// document.getElementById('regi_errors').appendChild(error_html);
			document.getElementById('regi_errlistingNameors').classList.add('alert', 'alert-danger', 'display-block');
			// regi_errors.scrollIntoView({ behavior: 'smooth' });
		} else {
			//console.log('submit plan');

			// var choosedPlan = plans.filter(function (plansObj) { return plansObj.id == formData.plan; });

			// var plan_names_arr = [{
			//     recId: formData.plan,
			//     listingName: choosedPlan[0].membership_level_name
			// }];



			// var planNamesArr = [];
			// var newListingNamesArr = plans.filter(function (plansObj) { return plansObj.id == formData.plan; });
			// setSelectedPlanArr(newListingNamesArr);

			// //set plans into local storage
			// localStorage.setItem('planNames', JSON.stringify(newListingNamesArr));
			// localStorage.setItem('UseremailId', JSON.stringify({ email: email }));
			// //history.push('/checkout-subscription');

			// history.push({
			//     pathname: '/checkout-subscription',
			//     state: {  // location state
			//         email: email,
			//         plan_arr: choosedPlan,

			//     },
			// });

		}
	};


	useEffect(() => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile', {
			email: UserProfile.getEmail(),
		})
			.then(function (response) {
				//console.log(response);
				setplanid(response.data[0].sw_plan_id)


				localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
				localStorage.setItem('membershipOptions', response.data[0].options);
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);


	const validator = new SimpleReactValidator({
		element: (message, className) => <div className='text-danger'>{message}</div>
	});

	const handleOnClick = event => {
		this.setplans(event);
	}
	useEffect(() => {
		sessionStorage.removeItem('planNames')
	}, [])
	//checkout-upgrade
	const handlePlanOnClick = (id, planName, planAmount) => {
		var obj = {
			id: id,
			membership_level_cost: planAmount,
			membership_level_name: planName,
			membership_plan_period: "month"

		}
		sessionStorage.setItem('planNames', JSON.stringify(obj));
		history.push('/checkout-upgrade');
	}

	return (<>
		<MetaTags>
			<title>Upgrade Plan</title>

		</MetaTags>
		<Header />
		{/* <form id="Step3New" onSubmit={handleSubmit(onSubmit)}> */}
		<form className="reduce-right-margin" id="Step3New" >
			<div id='regi_errors' className='margin-top-15'></div>

			{/* <div className='text-left color-black font-size-18  margin-bottom-20' style={{ color: '#474747' }}>Please choose a membership plan that best suits your needs.</div> */}
			<section className='margin-bottom-0' style={{ minHeight: 1250, fontFamily: "Means Web,Georgia,Times,Times New Roman,serif" }}>
				<div className="d-flex justify-content-center">
					<div className='col-lg-8 col-md-12 col-sm-12 padding-0 ' >
						{/*<div style={{ marginLeft: "200px", marginTop: "100px", marginRight: "200px" }} >*/}
						<div className="container-fluid" style={{ marginTop: 100, fontFamily: "Means Web,Georgia,Times,Times New Roman,serif" }} >
							<h1 className="content_area" style={{ marginLeft: 15 }}>Upgrade Your Plan </h1>
							<p style={{ fontSize: "18px", marginLeft: 15 }} className="copy margin--top-c4">Please choose a membership plan that best suits your needs. </p>
							<div className='container-fluid' >
								{
									planid == 2 ?
										<>
											<Tabs defaultActiveKey="Monthly" className=""  >
												<Tab eventKey="Monthly" title="Monthly Plans" style={{ color: "black" }}>
													<>
														<div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom " aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">29</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>/per month                      </div>
																		</div>



																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(3, "POWER SEARCH", 29) }} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																			</div>
																		</div>
																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All Basic functionality +   </li>
																		<li className="margin--bottom-c4">Search by keyword  </li>
																		<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
																		<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
																		<li className="margin--bottom-c4">Export map results and views</li>
																		<li className="margin--bottom-c4">Up to 25 listings</li>
																	</ul>
																</div>

															</div>
														</div>

														{/* <div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">29</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per month                      </div>
																		</div>



																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				
																				<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(3, "POWER SEARCH", 29) }}
																					style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }}
																				>
																					GET STARTED
																				</Button>

																			</div>
																		</div>
																	</div>
																</div><br />

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All Basic functionality +   </li>
																		<li className="margin--bottom-c4">Search by keyword  </li>
																		<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
																		<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
																		<li className="margin--bottom-c4">Export map results and views</li>
																		<li className="margin--bottom-c4">Up to 25 listings</li>
																	</ul>
																</div>

															</div>
														</div> */}
														<div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">49</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per month                      </div>
																		</div>

																		<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(4, "POWER PRO", 49) }} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																			</div>
																		</div>
																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
																		<li className="margin--bottom-c4">See who has viewed your listings </li>
																		<li className="margin--bottom-c4">Premium placement within search results </li>
																		<li className="margin--bottom-c4">Up to 100 listings </li>
																	</ul>
																</div>

															</div>

														</div>


														{/* <div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">49</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per month                      </div>
																		</div>

																		<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				
																				<Button onClick={() => { handlePlanOnClick(4, "POWER PRO", 49) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																			</div>
																		</div>
																	</div>
																</div><br />

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
																		<li className="margin--bottom-c4">See who has viewed your listings </li>
																		<li className="margin--bottom-c4">Premium placement within search results </li>
																		<li className="margin--bottom-c4">Up to 100 listings </li>
																	</ul>
																</div>

															</div>

														</div> */}

													</>

												</Tab>
												<Tab eventKey="Yearly" title="Yearly Plans" style={{ color: "black" }}>
													<>
														<div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">299 </div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per year                      </div>
																		</div>



																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(5, "POWER SEARCH YEARLY", 299) }} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																			</div>
																		</div>
																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All Basic functionality +   </li>
																		<li className="margin--bottom-c4">Search by keyword  </li>
																		<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
																		<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
																		<li className="margin--bottom-c4">Export map results and views</li>
																		<li className="margin--bottom-c4">Up to 25 listings</li>
																	</ul>
																</div>

															</div>
														</div>

														{/* <div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">299</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per year                     </div>
																		</div>



																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				
																				<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(5, "POWER SEARCH YEARLY", 299) }}
																					style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }}
																				>
																					GET STARTED
																				</Button>

																			</div>
																		</div>
																	</div>
																</div><br />

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All Basic functionality +   </li>
																		<li className="margin--bottom-c4">Search by keyword  </li>
																		<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
																		<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
																		<li className="margin--bottom-c4">Export map results and views</li>
																		<li className="margin--bottom-c4">Up to 25 listings</li>
																	</ul>
																</div>

															</div>
														</div> */}
														<div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">499</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per year                      </div>
																		</div>

																		<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(6, "POWER PRO YEARLY", 499) }} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																			</div>
																		</div>
																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
																		<li className="margin--bottom-c4">See who has viewed your listings </li>
																		<li className="margin--bottom-c4">Premium placement within search results </li>
																		<li className="margin--bottom-c4">Up to 100 listings </li>
																	</ul>
																</div>

															</div>

														</div>


														{/* <div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">499</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per year                      </div>
																		</div>

																		<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				
																				<Button onClick={() => { handlePlanOnClick(6, "POWER PRO YEARLY", 499) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																			</div>
																		</div>
																	</div>
																</div><br />

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
																		<li className="margin--bottom-c4">See who has viewed your listings </li>
																		<li className="margin--bottom-c4">Premium placement within search results </li>
																		<li className="margin--bottom-c4">Up to 100 listings </li>
																	</ul>
																</div>

															</div>

														</div> */}

													</>

												</Tab>
											</Tabs>

										</>
										:
										planid == 3 ?

											<>
												<div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
													<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
														<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
															<div className="pricingGrid__rowItem" data-plan="premium">


																<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
																<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

															</div>
														</div>

														<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
															<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																<div className="flex flex--bottom" aria-hidden="true">
																	<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																		<div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
																		<div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">49</div>
																		<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																		<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																	</div>

																	<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																		&nbsp;/per month                      </div>
																</div>

																<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

																<br /><br />
																<div className="experiment">

																	<div className="margin--bottom-c3">
																		<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(4, "POWER PRO", 49) }} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																	</div>
																</div>
															</div>
														</div>

														<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
															<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
																<li className="margin--bottom-c4">See who has viewed your listings </li>
																<li className="margin--bottom-c4">Premium placement within search results </li>
																<li className="margin--bottom-c4">Up to 100 listings </li>
															</ul>
														</div>

													</div>

												</div>


											</>

											:
											planid == 4 ?
												<>
													<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
														<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
															<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																<div className="pricingGrid__rowItem" data-plan="premium">


																	<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
																	<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

																</div>
															</div>

															<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																	<div className="flex flex--bottom" aria-hidden="true">
																		<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																			<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
																			<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">299</div>
																			<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																			<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																		</div>

																		<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																			&nbsp;/per year                     </div>
																	</div>



																	<br /><br />
																	<div className="experiment">

																		<div className="margin--bottom-c3">

																			<Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={() => { handlePlanOnClick(5, "POWER SEARCH YEARLY", 299) }}
																				style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }}
																			>
																				GET STARTED
																			</Button>

																		</div>
																	</div>
																</div>
															</div><br />

															<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																	<li className="margin--bottom-c4"> All Basic functionality +   </li>
																	<li className="margin--bottom-c4">Search by keyword  </li>
																	<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
																	<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
																	<li className="margin--bottom-c4">Export map results and views</li>
																	<li className="margin--bottom-c4">Up to 25 listings</li>
																</ul>
															</div>

														</div>
													</div>

													<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
														<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
															<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																<div className="pricingGrid__rowItem" data-plan="premium">


																	<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
																	<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

																</div>
															</div>

															<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																	<div className="flex flex--bottom" aria-hidden="true">
																		<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																			<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
																			<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">499</div>
																			<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																			<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																		</div>

																		<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																			&nbsp;/per year                      </div>
																	</div>

																	<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

																	<br /><br />
																	<div className="experiment">

																		<div className="margin--bottom-c3">

																			<Button onClick={() => { handlePlanOnClick(6, "POWER PRO YEARLY", 499) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																		</div>
																	</div>
																</div>
															</div><br />

															<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																	<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
																	<li className="margin--bottom-c4">See who has viewed your listings </li>
																	<li className="margin--bottom-c4">Premium placement within search results </li>
																	<li className="margin--bottom-c4">Up to 100 listings </li>
																</ul>
															</div>

														</div>

													</div>

												</>
												:
												planid == 5 ?

													<>
														<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
															<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium">


																		<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
																		<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

																	</div>
																</div>

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																	<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																		<div className="flex flex--bottom" aria-hidden="true">
																			<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																				<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
																				<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">499</div>
																				<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																				<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																			</div>

																			<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
																				&nbsp;/per year                      </div>
																		</div>

																		<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

																		<br /><br />
																		<div className="experiment">

																			<div className="margin--bottom-c3">
																				{/* <Button type="submit" onClick={() => { handleOnClick(4) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button> */}

																				<Button onClick={() => { handlePlanOnClick(6, "POWER PRO YEARLY", 499) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
																			</div>
																		</div>
																	</div>
																</div><br />

																<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																	<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																		<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
																		<li className="margin--bottom-c4">See who has viewed your listings </li>
																		<li className="margin--bottom-c4">Premium placement within search results </li>
																		<li className="margin--bottom-c4">Up to 100 listings </li>
																	</ul>
																</div>

															</div>

														</div>

													</>
													:
													planid == 6 ?
														<>
															<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
																<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																	<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																		<div className="pricingGrid__rowItem" data-plan="premium">


																			<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">PRIVATE LABEL</h2>
																			<p style={{ fontSize: 15 }} className="copy margin--top-c4">Explore custom solutions </p>

																		</div>
																	</div>

																	<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																		<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																			<div className="flex flex--bottom" aria-hidden="true">
																				<div className="formattedPrice1 flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																					<div className="formattedPrice1__price--symbol--left" data-behavior="formattedPrice__symbol">Contact For Pricing</div>
																					<div className="formattedPrice1__price--integer" data-behavior="formattedPrice__integer"></div>
																					<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																					<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																				</div>
																			</div>


																			<hr />
																			<div className="experiment">

																				<div className="margin--bottom-c3">
																					<p style={{ fontSize: 15 }} className="copy margin--top-c4">Call (312) 957-7757<br />info@industrialnavigator.com </p>

																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																		<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																			<li className="margin--bottom-c4">Implant the functionality of Industrial Navigator on your website to market your assets and services    </li>
																			<li className="margin--bottom-c4">Create custom map layers  </li>
																			<li className="margin--bottom-c4">Market research and logistics optimization  </li>
																			<li className="margin--bottom-c4">Multi-user subscriptions </li>
																		</ul>
																	</div>

																	{/*

						<h3 style={{ textAlign: 'center', height: 30 }}>PRIVATE LABEL AND CORPORATE SOLUTIONS</h3>
						<h4 style={{ fontSize: 20, verticalAlign: 'text-top' }} className="text-center">
							Contact for pricing
						</h4>

						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Explore custom solutions</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> Implant the functionality of Industrial Navigator on your website to market your assets and services</li>
							<li style={{ margin: 8 }}> Create custom map layers </li>
							<li style={{ margin: 8 }}> Market research and logistics optimization</li>
							<li style={{ margin: 8 }}> Multi-user subscriptions</li>

						</ul>
						<div className="d-flex justify-content-center">
							
							<label style={{ color: '#000', position: 'absolute', bottom: '2%', fontSize: 18 }}>
								Call (312) 957-7757
							</label>

						</div>
						*/}
																</div>
															</div>

														</>
														:
														<>
															<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
																<div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
																	<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
																		<div className="pricingGrid__rowItem" data-plan="premium">


																			<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">PRIVATE LABEL</h2>
																			<p style={{ fontSize: 15 }} className="copy margin--top-c4">Explore custom solutions </p>

																		</div>
																	</div>

																	<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
																		<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

																			<div className="flex flex--bottom" aria-hidden="true">
																				<div className="formattedPrice1 flex flex--left" data-behavior="formattedPrice" data-plan="premium">
																					<div className="formattedPrice1__price--symbol--left" data-behavior="formattedPrice__symbol">Contact For Pricing</div>
																					<div className="formattedPrice1__price--integer" data-behavior="formattedPrice__integer"></div>
																					<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
																					<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
																				</div>
																			</div>


																			<hr />
																			<div className="experiment">

																				<div className="margin--bottom-c3">
																					<p style={{ fontSize: 15 }} className="copy margin--top-c4">Call (312) 957-7757<br />info@industrialnavigator.com </p>

																				</div>
																			</div>
																		</div>
																	</div>

																	<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
																		<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

																			<li className="margin--bottom-c4">Implant the functionality of Industrial Navigator on your website to market your assets and services    </li>
																			<li className="margin--bottom-c4">Create custom map layers  </li>
																			<li className="margin--bottom-c4">Market research and logistics optimization  </li>
																			<li className="margin--bottom-c4">Multi-user subscriptions </li>
																		</ul>
																	</div>

																	{/*

						<h3 style={{ textAlign: 'center', height: 30 }}>PRIVATE LABEL AND CORPORATE SOLUTIONS</h3>
						<h4 style={{ fontSize: 20, verticalAlign: 'text-top' }} className="text-center">
							Contact for pricing
						</h4>

						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Explore custom solutions</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> Implant the functionality of Industrial Navigator on your website to market your assets and services</li>
							<li style={{ margin: 8 }}> Create custom map layers </li>
							<li style={{ margin: 8 }}> Market research and logistics optimization</li>
							<li style={{ margin: 8 }}> Multi-user subscriptions</li>

						</ul>
						<div className="d-flex justify-content-center">
							
							<label style={{ color: '#000', position: 'absolute', bottom: '2%', fontSize: 18 }}>
								Call (312) 957-7757
							</label>

						</div>
						*/}
																</div>
															</div>

														</>

								}
							</div>

						</div>
					</div>
				</div>
			</section>







		</form>
	</>);
}

export default UpgradePlan;
