import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection'
import { Link } from 'react-router-dom';
import ContactUs from '../../../ContactUsPrivateLable';
const Index = props => {

    return (
        <>
            <section style={{ backgroundColor: '#fbfbfb' }} className="section-content">
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: -50 }} className="col-lg-12 col-sm-12 mb-lg-0 mb-5">
                            <div className={"iq-title-box iq-title-default " + props.className}>
                                <h2 style={{ fontFamily: 'TeXGyreAdventor-Bold, sans-serif', textTransform: 'none', color: '#142149', lineHeight: '1.5em', fontWeight: 800, letterSpacing: '1px' }} className="iq-title " >Request a Private Label Demo!</h2>
                                </div>
                        </div>
                        <div className="col-lg-7 col-sm-12 mb-lg-0 mb-5">
                            <div className='adjust-contact-form'>
                            <ContactUs />

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default Index