import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import InputCheckboxComponent from '../InputCheckboxComponent';
import axios from 'axios';
import UserProfile from '../UserProfile';
import { Carousel } from 'react-responsive-carousel';
import BookmarPDFGenPrivate from '../pdf/BookmarkPDFGenPrivate';
import ReactToPrint from 'react-to-print';
import PdfBookmarksDocGen from '../pdf/PdfBookmarkDocGenPrivate';
import Details from '../PrivateDetails';
let componentRef;
interface PBookmarks {
	history?: Array<string>;
}

class SBookmarks {
	email: string = UserProfile.getEmail() ? UserProfile.getEmail() : '';
	features: Array<any> = [];
	generate_pdf_arr: Array<any> = [];
	shareable_link_arr: Array<any> = [];
	addFavModal: boolean = false;
	detailsModal: boolean = false;
	pdfModal: boolean = false;
	bookmarksCount: number = 0;
	count: number = 0;
	place = '';

	constructor() {
	}
}

class Bookmarks extends Component<PBookmarks, SBookmarks> {

	constructor(props) {
		super(props);
		this.state = new SBookmarks();
	}

	componentWillMount() {
		//alert(typeof(localStorage.getItem('bookmarks')))
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-bookmark-list-private', {
			places: localStorage.getItem('bookmarksprivate')

		})
			.then(response => {
				console.log('bookmark resp', response)

				if (response.status === 200) {
					const count = response.data.places.length;
					if (count) {
						for (let i = 0; i < count; i++) {
							const generate_pdf_label = 'generate_pdf_' + response.data.places[i].rec_id;
							const shareable_link_label = 'shareable_link_' + response.data.places[i].rec_id;
							this.setState({
								[generate_pdf_label]: false,
								[shareable_link_label]: false,
							} as any);
						}
					}

					this.setState({
						features: response.data.places,
						bookmarksCount: response.data.places.length
					});
				}
			});
	}
	getRecords = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-bookmark-list-private', {
			places: localStorage.getItem('bookmarksprivate')

		})
			.then(response => {
				console.log('bookmark resp', response)

				if (response.status === 200) {
					const count = response.data.places.length;
					if (count) {
						for (let i = 0; i < count; i++) {
							const generate_pdf_label = 'generate_pdf_' + response.data.places[i].rec_id;
							const shareable_link_label = 'shareable_link_' + response.data.places[i].rec_id;
							this.setState({
								[generate_pdf_label]: false,
								[shareable_link_label]: false,
							} as any);
						}
					}

					this.setState({
						features: response.data.places,
						bookmarksCount: response.data.places.length
					});
				}
			});
	}
	getFirstImage = (json) => {
		let res: string = null;
		for (let key in json) {
			if (json.hasOwnProperty(key)) {
				const url = json[key].url;
				res = url;
				break;
			}
		}
		return res;
	}

	formatPrice(price) {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0
		});
		const formattedPrice = formatter.format(price);
		return formattedPrice;
	}

	handleCheckboxChange = (name, value) => {
		// console.log(name)
		// console.log(value)

		const checkbox_type = name.split('_');
		const checkbox_name = checkbox_type[0] + '_' + checkbox_type[1];
		const checkbox_id = checkbox_type[2];
		console.log(checkbox_type)

		let { generate_pdf_arr, shareable_link_arr } = this.state;

		if (checkbox_name === 'generate_pdf') {
			generate_pdf_arr = this.updateCheckboxArray(generate_pdf_arr, checkbox_id, value);
		} else if (checkbox_name === 'shareable_link') {
			shareable_link_arr = this.updateCheckboxArray(shareable_link_arr, checkbox_id, value);
		}

		// console.log('generate_pdf_arr')
		// console.log(generate_pdf_arr)

		// console.log('shareable_link_arr')
		// console.log(shareable_link_arr)

		this.setState({
			[name]: value,
			generate_pdf_arr: generate_pdf_arr,
			shareable_link_arr: shareable_link_arr,
		} as any);
	}

	updateCheckboxArray = (arr, id, value) => {
		if (arr.length) {
			const index = arr.indexOf(id);
			if (index > -1 && value === false) {
				arr.splice(index, 1);
			} else {
				arr.push(id);
			}
		} else {
			if (value === true) {
				arr.push(id);
			}
		}
		return arr;
	}

	createPDF = () => {
		const { generate_pdf_arr } = this.state;
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-bookmark-list-private', {
			places: localStorage.getItem('bookmarksprivate')

		})
			.then(response => {
				if (response.status === 200) {
					(new PdfBookmarksDocGen()).createBookmarksPDF(generate_pdf_arr, response.data.places);
				}
			});


		
	}
	showpdfModal = () => {

		this.setState({ pdfModal: true });
	}
	hidepdfModal = () => {
		this.setState({ pdfModal: false });

	}

	createShareableLink = () => {
		const { shareable_link_arr } = this.state;
		console.log('shareable_link_arr')
		console.log(shareable_link_arr)
		if (shareable_link_arr.length) {
			const shareable_link_str = shareable_link_arr.join(',');
			console.log('/places/' + shareable_link_str)
			this.props.history.push('/places-' + shareable_link_str);
		} else {
			alert('Select places to create shareable link');
		}
	}

	deleteFromBookmarks = (place_id) => {

		var bookmarkArrPrivate = localStorage.getItem('bookmarksprivate') ? JSON.parse(localStorage.getItem('bookmarksprivate')) : [];
		console.log('bookmarkArrPrivate:Before', bookmarkArrPrivate)
		var index = bookmarkArrPrivate.indexOf(place_id)
		if (index > -1) {
			bookmarkArrPrivate.splice(index, 1);
		}
		console.log('bookmarkArrPrivate:After', bookmarkArrPrivate)
		localStorage.setItem('bookmarksprivate', JSON.stringify(bookmarkArrPrivate))
		this.getRecords();
		this.showAddFavModal();
		this.forceUpdate();
		// alert(place_id);
		// return;
		// axios.post(process.env.REACT_APP_BASE_URL + '/api/update-bookmark-list', {
		// 	headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
		// 	place_id: place_id,
		// 	email: this.state.email
		// })
		// 	.then(res => {
		// 		console.log(res.data)
		// 		localStorage.setItem('bookmarks', res.data.bookmarks);
		// 		const current_features = this.state.features;
		// 		const new_features = [];
		// 		for (let i = 0; i < current_features.length; i++) {
		// 			if (Number(current_features[i].rec_id) === Number(place_id)) {
		// 				console.log('\n\ncurrent_features[i].client_id')
		// 				console.log(current_features[i].rec_id)
		// 			} else {
		// 				new_features.push(current_features[i]);
		// 			}
		// 		}
		// 		const bookmarksCount = new_features.length;

		// 		this.setState({
		// 			features: new_features,
		// 			bookmarksCount: bookmarksCount
		// 		}, () => {
		// 			this.forceUpdate();
		// 			this.showAddFavModal();
		// 		});
		// 		this.forceUpdate();
		// 	}).catch(err => {
		// 		console.log(err);
		// 	});
	}

	componentDidMount() {
	}

	showAddFavModal = () => {
		this.setState({ addFavModal: true });
	}

	hideAddFavModal = () => {
		this.setState({ addFavModal: false });
	}
	showDetailsModal = (rec_id) => {
		localStorage.setItem("rec_id", rec_id)
		this.setState({ detailsModal: true });
	}

	hideDetailsModal = () => {
		this.setState({ detailsModal: false });
		localStorage.removeItem('rec_id');
	}

	child: Header = null;

	render() {
		const { place } = this.state;
		const { features } = this.state;
		return (
			<div className='padding-bottom-50'>

				<div id='page-content' style={{ minHeight: '100vh' }}>

					<section className='container-fluid'>
						<Col className = 'padding-30px' lg={12} md={12} sm={12}>
							<h2 style={{paddingLeft:0}} className='col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0-xs font-size-25'>Favorites</h2>
							<b style={{fontWeight:500,fontSize:16}}>Welcome to the favorites section. All of the items that you mark as favorites will show here. You may build a PDF packet with all listings by checking each site or facility desired, and then clicking "Generate PDF". You may see details of each listing by clicking on the listing name. </b><br /><br />
						</Col>

						{
							features.length > 0 &&
							<Col lg={12} md={12} sm={12} className='margin-bottom-20'>
								<MediaQuery minDeviceWidth={768}>
									<Col lg={1} md={1} sm={1}>
										<Button className='text-uppercase border-radius-25-imp btn btn-default'
											onClick={this.createPDF}
										>
											Generate PDF
										</Button>
										{/* <Button className='text-uppercase border-radius-25-imp btn btn-default margin-left-30'
											onClick={this.createShareableLink}
										>
											Generate Shareable Link
										</Button> */}
									</Col>
								</MediaQuery>
								<MediaQuery maxDeviceWidth={768}>
									<Col xs={12} className='padding-0-xs'>
										<Button className='text-uppercase border-radius-25-imp btn btn-default margin-bottom-20'
											onClick={this.createPDF}
										>
											Generate PDF
										</Button>
									</Col>
									<Col xs={12} className='padding-0-xs'>
										{/* <Button className='text-uppercase border-radius-25-imp btn btn-default margin-bottom-20 clear-both'
											onClick={this.createShareableLink}
										>
											Generate Shareable Link
										</Button> */}
									</Col>
								</MediaQuery>
							</Col>
						}
						<ul className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ listStyle: 'none' }}>
							{
								this.renderFeatures()
							}
						</ul>
					</section>
				</div>



				<ConfirmationModal show={this.state.addFavModal} handleClose={this.hideAddFavModal} >
					<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
						<div className='event-type info'>
							<div className='event-indicator '>
								<SVGIcon />
							</div>
						</div>
						<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
							Place removed from favorites
						</h2>
						<p className='text-muted font-size-16-imp margin-bottom-0'>
							Place has been removed from favorites successfully!
						</p>
					</div>
				</ConfirmationModal>
				<DetailsModal show={this.state.detailsModal} handleClose={this.hideDetailsModal} >
					<div style={{ paddingTop: '1rem', paddingLeft: '0rem', paddingRight: '0rem' }}>

						<Details />
					</div>
				</DetailsModal>
				<DetailsModal show={this.state.pdfModal} handleClose={this.hidepdfModal} >
					<div style={{ paddingTop: '1rem', paddingLeft: '0rem', paddingRight: '0rem' }}>

						<div>

							<div style={{ display: 'block' }} >
								<BookmarPDFGenPrivate generate_pdf_arr={this.state.generate_pdf_arr} ref={(response) => (componentRef = response)} />
							</div>


							<ReactToPrint
								content={() => componentRef}
								trigger={() => <Button className='text-uppercase border-radius-25-imp btn btn-default margin-bottom-20'>
								Print to PDF!
							</Button>}
							/>
						</div>
					</div>
				</DetailsModal>
			</div>

		)
	}

	renderFeatures(): React.ReactNode {
		const { features } = this.state;
		if (features.length <= 0) {
			return (
				<h2 className='text-center'>No Favorites Found</h2>
			)
		}
		return (
			Object.keys(features).map((key, index) => (
				<li key={index} className='col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0-xs'>
					<MediaQuery minDeviceWidth={768}>
						<div className='item margin-bottom-0 pull-left adjust-img-size-modal' id={'listing-' + key} style={{ padding: 25, width: '100%' }}>
							<div className='real-estate-item-image pull-left' style={{ width: 345 }}>
								{/* {
								features[key].properties.propertyType.includes('Industrial Sites & Properties') &&
								<div className='badge badge-danger background-dark-blue font-weight-bold-imp'>{ features[key].properties.propertyFor }</div>
							} */}
								<div className='height-220' style={{ height: 220 }}>
									<img src={this.getFirstImage(features[key].properties.image)} alt='img' style={{ height: '100%', width: '100%' }} />
								</div>
								{/* {
								features[key].properties.propertyType.includes('Industrial Sites & Properties') && 
								<div className='real-estate-item-price font-weight-bold'>
									<span className='font-size-23-imp text-uppercase'>{ this.formatPrice(features[key].properties.price) }
									{ features[key].properties.perUnit != '' && '/'+features[key].properties.perUnit }</span>
								</div>
							} */}
							</div>
							<div className='real-estate-item-desc padding-left-30px reduce-padding-to-0-privatefav reduce-padding-to-0px reduce-font-size-15px text-left pull-left' style={{ marginTop: 0, width: 345 }}>
								<div style={{}}>
									{/* <Link to={{ pathname: '/details-' + features[key].rec_id }} className='detail'>
										<h3 className='margin-top-0 margin-bottom-20-imp pull-left word-break-all text-uppercase font-size-20-imp'>
											{features[key].properties.listingName}
										</h3>
									</Link> */}
									<h3 onClick={() => this.showDetailsModal(features[key].rec_id)} className='margin-top-0 margin-bottom-20-imp pull-left word-break-all text-uppercase font-size-20-imp'>
										{features[key].properties.listingName}
									</h3>
								</div>
								{
									features[key].properties.listingLogo &&
									<div className='clear-both pull-left margin-bottom-20' style={{ width: 100, height: 40 }}>
										<img style={{ height: '100%' }} src={features[key].properties.listingLogo} />
									</div>
								}
								{
									features[key].properties.city &&
									<div className='clear-both font-size-18 margin-bottom-20'>
										{features[key].properties.city ? features[key].properties.city + ' · ' : ''}
										{features[key].properties.state}
									</div>
								}
								<div className='clear-both'>
									<InputCheckboxComponent name={'generate_pdf_' + features[key].rec_id}
										checked={this.state['generate_pdf_' + features[key].rec_id]}
										onHandleCheckboxChange={this.handleCheckboxChange}
										label='Add to PDF Packet'
									/>
								</div>
								{/* <div className='clear-both margin-bottom-20'>
									<InputCheckboxComponent name={'shareable_link_' + features[key].rec_id}
										checked={this.state['shareable_link_' + features[key].rec_id]}
										onHandleCheckboxChange={this.handleCheckboxChange}
										label='Add to Shareable Link'
									/>
								</div> */}
								<div className='clear-both'>
									<Button className='text-uppercase border-radius-25-imp reduce-font-size-11 btn btn-danger'
										onClick={() => this.deleteFromBookmarks(features[key].rec_id)}
									>
										&nbsp;&nbsp;Remove from Favorites&nbsp;&nbsp;
									</Button>
								</div>
							</div>
						</div>
					</MediaQuery>
					<MediaQuery maxDeviceWidth={767}>
						<div className='item margin-bottom-0 pull-left' id={'listing-' + key} style={{ padding: 15, width: '100%' }}>
							<div className='real-estate-item-image pull-left'>
								{
									features[key].properties.propertyType.includes('Industrial Sites & Properties') &&
									<div className='badge badge-danger background-dark-blue font-weight-bold-imp'>{features[key].properties.propertyFor}</div>
								}
								<div style={{ height: 'auto' }}>
									<img src={this.getFirstImage(features[key].properties.image)} alt='img' style={{ height: '100%', width: '100%' }} />
								</div>
								{
									features[key].properties.propertyType.includes('Industrial Sites & Properties') &&
									<div className='real-estate-item-price font-weight-bold'>
										<span className='font-size-23-imp text-uppercase'>{this.formatPrice(features[key].properties.price)}
											{features[key].properties.perUnit != '' && '/' + features[key].properties.perUnit}</span>
									</div>
								}
							</div>
							<div style={{ padding: 0 }} className='real-estate-item-desc text-left pull-left padding-0-imp-xs'>
								<div style={{ fontSize: 15 }}>
									<div style={{ fontSize: 15 }}>
										<h3 onClick={() => this.showDetailsModal(features[key].rec_id)} className='margin-top-0 margin-bottom-20-imp pull-left word-break-all text-uppercase'>
											{features[key].properties.listingName}
										</h3>
									</div>
								</div>
								{
									features[key].properties.listingLogo &&
									<div className='clear-both pull-left margin-bottom-20' style={{ width: 80, height: 40 }}>
										<img style={{ height: '100%' }} src={features[key].properties.listingLogo} />
									</div>
								}
								{
									features[key].properties.city &&
									<div className='clear-both font-size-18 margin-bottom-20'>
										{features[key].properties.city ? features[key].properties.city + ' · ' : ''}
										{features[key].properties.state}
									</div>
								}
								<div className='clear-both'>
									<InputCheckboxComponent name={'generate_pdf_' + features[key].rec_id}
										checked={this.state['generate_pdf_' + features[key].rec_id]}
										onHandleCheckboxChange={this.handleCheckboxChange}
										label='Add to PDF Packet'
									/>
								</div>
								{/* <div className='clear-both margin-bottom-20'>
									<InputCheckboxComponent name={'shareable_link_' + features[key].rec_id}
										checked={this.state['shareable_link_' + features[key].rec_id]}
										onHandleCheckboxChange={this.handleCheckboxChange}
										label='Add to Shareable Link'
									/>
								</div> */}
								<div className='clear-both'>
									<Button className='text-uppercase border-radius-25-imp btn btn-danger'
										onClick={() => this.deleteFromBookmarks(features[key].rec_id)}
									>
										Remove from Favorites
									</Button>
								</div>
							</div>
						</div>
					</MediaQuery>
				</li>
			))
		)
	}
}

export default withRouter(Bookmarks);

const ConfirmationModal = ({ handleClose, show, children }) => {
	return (
		<Modal show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
			<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
			<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
				<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
					{children}
				</div>
			</Modal.Body>
			<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
				<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
			</Modal.Footer>
		</Modal>
	);
};
const DetailsModal = ({ handleClose, show, children }) => (
	<Modal dialogClassName='quick-view-modal width-80' size='large' aria-labelledby='contained-modal-title-lg' show={show} onHide={handleClose} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', marginBottom: '0px' }}>
			<div  >
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)