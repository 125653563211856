import React, { Component } from 'react';
import Header from '../HeaderLTE';
import SideBar from '../SideBarLTE';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from '../UserProfile';
import '../../css/adminLTE-custom.css';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { Modal, Button, Row ,OverlayTrigger, Tooltip} from 'react-bootstrap';
import Footer from '../Footer';

const columns = memoize(clickHandler => [
	{
		name: 'Name',
		selector: 'client_name',
		sortable: true,
	},
	{
		name: 'Email',
		selector: 'client_email',
		sortable: true,
	},
	{
		name: 'Company',
		selector: 'company',
		sortable: true,
		//width: '125px',
	},
	{
		name: 'Creation Date',
		selector: 'start_date',
		sortable: true,
		//width: '125px'
	},
	{
		cell: (row) => (
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
					Edit Client Details
					</strong>
				</Tooltip>
			}>
				<i style={{ color: "#8B8B8B" }} onClick={clickHandler} data-id={row.id} data-action='edit' className="fa fa-edit fs-30"></i>
			</OverlayTrigger>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
	
	{
		cell: (row) => (
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
						Delete Client 
					</strong>
				</Tooltip>
			}>
				<i style={{ color: "red" }} onClick={clickHandler} data-id={row.id} data-action='delete' className="fa fa-trash fs-30"></i>
			</OverlayTrigger>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	}
]);

class Membership extends Component {

	// _isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			showDeleteModal: false,
			data: []
		}
	}

	handleButtonClick = (event) => {
		const id = event.target.getAttribute('data-id');
		const buttonAction = event.target.getAttribute('data-action');

		if (buttonAction === 'edit') {
			this.props.history.push('/edit-private-client-details-' + id);
		} else {
			this.setState({
				deleteRecId: id,
				showDeleteModal: true
			});
		}
	}



	componentWillUnmount() {
		// this._isMounted = false;
	}

	componentDidMount() {
		//this.getClients();
		 axios.post(process.env.REACT_APP_BASE_URL + '/api/get-private-clients', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const clients = res.data;
				console.log("data fetched", clients[0])
				console.log("plans.length", clients.length)
				// const client_list = [{
				// 	id: 1,
				// 	client_email: "private@mail.com",
				// 	client_name: "kuldip awachar",
				// 	company: "test",

				// }];
				const client_list = [];
				for (let i = 0; i < clients.length; i++) {
					// const created_date = new Date(client_list[i].created_at);
					// alert("inside for loop")
					// console.log('created_date')
					// // console.log(plans[i].createdAt)
					// console.log(client_list[i].created_at)

					// const start_year = created_date.getFullYear();
					// const start_month = ('0' + (created_date.getMonth() + 1)).slice(-2);
					// var start_day = ('0' + (created_date.getDate() + 1)).slice(-2);

					// // const formatted_start_date = [ start_month, start_day, start_year ].join('-');
					// const formatted_start_date = [start_year, start_month, start_day].join('-');

					// console.log('formatted_start_date')
					// console.log(client_list[i].created_at)

					const property_obj = {
						id: clients[i].id,
						client_email: clients[i].client_email,
						client_name: clients[i].client_name,
						company: clients[i].company,
						start_date:clients[i].created_at.substring(0, 10),

					}
					console.log("property_obj", property_obj)
					client_list.push(property_obj)
				}				
				this.setState({ data: client_list });
			})
			.catch(err => {
			});
	}

	 getClients = async () => {
		
	}

	handleDeleteRecord = async () => {
		const id = this.state.deleteRecId;
		localStorage.removeItem('_delete');
		await axios.post(process.env.REACT_APP_BASE_URL + '/api/delete-private-member', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			rec_id: id,
		})
			.then(res => {
				if (res.status === 200) {
					this.setState({
						showDeleteModal: false
					});
					this.callback();
					this.props.history.push('/my-profile');
				}
			})
	}

	handleDeleteModal = () => {
		this.setState({
			deleteRecId: null,
			showDeleteModal: false
		});
	}

	callback = () => {
		this.getClients();
	}

	// handleSort = (column, sortDirection) => {
	// 	console.log(column)
	// 	console.log(sortDirection)

	// 	// instead of setTimeout this is where you would handle your API call.
	// 	setTimeout(() => {
	// 		const dataObj = this.state.data;
	// 		console.log(dataObj)
	// 		console.log('after')
	// 		const temp = dataObj.sort(function(a,b){
	// 			// Turn your strings into dates, and then subtract them
	// 			// to get a value that is either negative, positive, or zero.
	// 			console.log(new Date(b.start_date))
	// 			return new Date(b.start_date) - new Date(a.start_date);
	// 		});
	// 		console.log(temp)
	// 		this.setState({
	// 			data: temp
	// 		})
	// 	}, 100);
	// }

	createNewHandler = () => {
		console.log('this.props')
		console.log(this.props)
		this.props.history.push('/create-private-client');
	}

	render() {
		return (
			<div style={{ backgroundColor: '#222D32' }}>
				<Header />
				<SideBar />
				<div className='content-wrapper'>
					<section className='content-header' style={{ backgroundColor: 'white' }}>
						<div className='row'>
							<div className='col-md-12'>
								<div className='col-md-12'>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, fontSize: 30
										, fontWeight: 700
										, marginTop: 15
										, marginBottom: 30
									}}
									>
										<div>Private Clients</div>
									</div>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, marginTop: 15
										, marginBottom: 30
									}}
									>
										<button className='btn btn-default border-radius-25-imp pull-right' onClick={this.createNewHandler}>
											Create New
										</button>
									</div>
								</div>
								<div  className='col-md-12'>
									<DataTable
										title=''
										columns={columns(this.handleButtonClick)}
										data={this.state.data}
										defaultSortField='start_date'
										// onSort={this.handleSort}
										pagination={true}
										paginationPerPage={50}
										defaultSortAsc={false}
										paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
									/>
								</div>
							</div>
						</div>
					</section>
				</div>

				{/* Delete modal */}
				<Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal}
					style={{ padding: '0px!important', opacity: 1 }}
					animation={false}
				>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp'
							onClick={this.handleDeleteRecord}
						>
							Confirm
						</button>
						<button type='button'
							className='btn btn-default border-radius-25-imp margin-left-15'
							onClick={this.handleDeleteModal}
						>
							Cancel
						</button>
					</Modal.Footer>
				</Modal>

				<Footer />
			</div>
		)
	}
}

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)

export default withRouter(Membership);