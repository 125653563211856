import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePage/qloud/footerComponents';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Helmet } from "react-helmet";
import axios from 'axios';
import '../css/priceplans.css';
import SimpleReactValidator from 'simple-react-validator';


const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: HomePageHeader) => void;
    data?: any;
}


class NorthAmericanSites extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    helpers: any = null;

    constructor(props: PHeader) {
        super(props);
        this.state = {
            email: ''
        }
    }

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }




    child: HomePageHeader = null;



    render() {

        return (
            <>
                <Helmet>
                    <title>Trending Sites On Industrial Navigator</title>
                    <meta property="og:url" content={window.location.href} />
                    <meta name='description' content="Trending Sites On Industrial Navigator" />
                    <meta property='og:title' content="Trending Sites On Industrial Navigator" />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>

                <HomePageHeader data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 1200, fontFamily: "Roboto, sans-serif" }}>
                    <div className="justify-content-center">
                        <div className='col-lg-2 col-md-12 col-sm-12 padding-0 ' ></div>
                        <div className='col-lg-8 col-md-12 col-sm-12 padding-0 ' >

                            <div className="container-fluid" style={{ marginTop: 100, fontFamily: "Roboto, sans-serif" }} >
                                <h1 className="content_area" style={{ fontWeight: 600, fontFamily: 'Roboto, sans-serif', marginLeft: 15 }}>North American Industrial Sites</h1>
                                <div className='container-fluid' >
                                    <p className="para_content-new">
                                        <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                            <li>
                                                <h2>Industrial Site Offerings </h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                    <li><Link to={{ pathname: '/north-american-industrial-sites-for-lease' }}><span>Industrial Sites For Lease</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-industrial-sites-for-sale' }}><span>Industrial Sites For Sale</span></Link></li>

                                                </ul>

                                            </li><br />
                                            <li>
                                                <h2>Industrial Site Location</h2>
                                                <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                                    <li><h3>Country</h3>
                                                        <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-in-canada' }}><span>Industrial Sites - Canada</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-in-mexico' }}><span>Industrial Sites - Mexico</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-in-us' }}><span>Industrial Sites - US</span></Link></li>
                                                        </ul>
                                                    </li>

                                                    <li><h3 style={{paddingTop:25}}>US States</h3>
                                                        <ul className='row' style={{ listStyleType: 'none', marginLeft: 20 }}>


                                                        <li>
                                                                <div className="col-md-4">
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-alabama' }}><span>Alabama</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-arizona' }}><span>Arizona</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-arkansas' }}><span>Arkansas</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-california' }}><span>California</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-colorado' }}><span>Colorado</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-connecticut' }}><span>Connecticut</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-delaware' }}><span>Delaware</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-florida' }}><span>Florida</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-georgia' }}><span>Georgia</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-idaho' }}><span>Idaho</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-illinois' }}><span>Illinois</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-indiana' }}><span>Indiana</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-iowa' }}><span>Iowa</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-kansas' }}><span>Kansas</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-kentucky' }}><span>Kentucky</span></Link></div></li>

                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="col-md-4">
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-louisiana' }}><span>Louisiana</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-maine' }}><span>Maine</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-maryland' }}><span>Maryland</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-massachusetts' }}><span>Massachusetts</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-michigan' }}><span>Michigan</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-minnesota' }}><span>Minnesota</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-mississippi' }}><span>Mississippi</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-missouri' }}><span>Missouri</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-montana' }}><span>Montana</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-nebraska' }}><span>Nebraska</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-nevada' }}><span>Nevada</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-new-hampshire' }}><span>New Hampshire</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-new-jersey' }}><span>New Jersey</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-new-mexico' }}><span>New Mexico</span></Link></div></li>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="col-md-4">
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-new-york' }}><span>New York</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-north-carolina' }}><span>North Carolina</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-ohio' }}><span>Ohio</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-oklahoma' }}><span>Oklahoma</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-oregon' }}><span>Oregon</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-pennsylvania' }}><span>Pennsylvania</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-south-carolina' }}><span>South Carolina</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-south-dakota' }}><span>South Dakota</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-tennessee' }}><span>Tennessee</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-texas' }}><span>Texas</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-utah' }}><span>Utah</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-virginia' }}><span>Virginia</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-west-virginia' }}><span>West Virginia</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-industrial-sites-wisconsin' }}><span>Wisconsin</span></Link></div></li>
                                                                </div>
                                                            </li>
                                                            </ul>
                                                    </li>
                                                    <li><h3 style={{paddingTop:25}}>Rail Served Industrial Sites</h3>
                                                        <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-rail-served-UP' }}><span>Industrial Sites Connected to UP</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-rail-served-BNSF' }}><span>Industrial Sites Connected to BNSF</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-rail-served-CP' }}><span>Industrial Sites Connected to CP</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-rail-served-KCS' }}><span>Industrial Sites Connected to KCS</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-rail-served-CN' }}><span>Industrial Sites Connected to CN</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-rail-served-NS' }}><span>Industrial Sites Connected to NS</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-industrial-sites-rail-served-CSX' }}><span>Industrial Sites Connected to CSX</span></Link></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>


                                    </p><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <HomePageFooter />




            </>
        )
    }
}

export default withRouter(NorthAmericanSites);

