import { Point } from 'geojson';

export type LabelValue = { 'label': string, 'value': string };

export class FormItem {
	key: string = '';
	type: 'dropdown' | 'input' = null;
}

export const sortArray = (array: Array<LabelValue>): Array<LabelValue> => {
	if (!array) {
		return array;
	}
	const result: Array<LabelValue> = array.sort((a: LabelValue, b: LabelValue) => {
		const valueA = a.value.toLowerCase(), valueB = b.value.toLowerCase();
		if (valueA < valueB) {
			return -1;
		}
		if (valueA > valueB) {
			return 1;
		}
		return 0;
	});
	return result;
}

export function removeDuplicatesFromArr<T>(arr: Array<T>): Array<T> {
	const s = new Set(arr);
	const it = s.values();
	const res = Array.from(it);
	return res;
}

export function plainArray(arr: Array<string>): Array<string> {
	const arr4Process: Array<string> = [];
	for (let i = 0; i < arr.length; i++) {
		const str: string = arr[i];
		const arrTmp: Array<string> = str ? str.split('*') : [];
		arr4Process.push(...arrTmp);
	}
	return arr4Process;
}

export function splitFilter(str: string): Array<string> {
	let arr: Array<string> = str ? str.split('*') : [];
	arr = removeDuplicatesFromArr(arr);
	return arr.sort();
}

export function joinFilter(arr: Array<string>): string {
	arr = plainArray(arr);
	arr = removeDuplicatesFromArr(arr);
	const res: string = arr && arr.length > 0 ? arr.sort().join('*') : '';
	return res;
}

export function joinFilterV(arrIn: Array<LabelValue>): string {
	const arr: Array<string> = arrIn && arrIn.length > 0 ? arrIn.map((val: LabelValue) => val.value) : [];
	return joinFilter(arr);
}

export function joinFilterL(arrIn: Array<LabelValue>): string {
	const arr: Array<string> = arrIn && arrIn.length > 0 ? arrIn.map((val: LabelValue) => val.label) : [];
	return joinFilter(arr);
}

export function reJoinFilter(str: string, ...arrRemove: Array<string>): string {
	let arr: Array<string> = splitFilter(str);
	arrRemove.forEach((item: string) => {
		arr = arr.filter((value: string) => {
			return (item === value);
		});
	});
	return joinFilter(arr);
}

export type TPosition = number[]; // [number, number] | [number, number, number];

export class TPoint {
	type: 'Point';
	coordinates: TPosition = [];
}

export class TInProperty {
	acreage: string = '';
	address: string = '';
	bargeAccess: string = '';
	building: string = '';
	capabilities: string = '';
	city: string = '';
	classOneConnections: string = '';
	contact: string = '';
	contactEmail: string = '';
	contactName: string = '';
	contactTitle: string = '';
	companyWebsiteUrl:string = '';
	country: string = '';
	description: string = '';
	distancetoWater: string = '';
	docInPlace: string = '';
	electricPowerInPlace: string = '';
	equipments: string = '';
	image: [{ url: string }] = [{ url: '' }];
	Brochures: [{ url: string }] = [{ url: '' }];
	indoorStorage: string = '';
	interiorRoofHeight: string = '';
	listingLogo: string = '';
	listingName: string = '';
	majorFeatures: string = '';
	marineAccess: string = '';
	organizationName: string = '';
	perUnit: string = '';
	plgCertification: string = '';
	price: string = '';
	productStorage: string = '';
	productsHandled: string = '';
	propertyFor: string = '';
	propertyType: string = '';
	pttAcreage: string = '';
	railInPlace: string = '';
	railcarCapacity: string = '';
	railcarStorage: string = '';
	railcarStorageCapacity: string = '';
	shortLines: string = '';
	silos: string = '';
	siteQualification: string = '';
	specifications: string = '';
	squareFeet: string = '';
	state: string = '';
	suitableFor: string = '';
	unitTrainCapable: string = '';
	warehouseCapabilities: string = '';
	waterSewerInPlace: string = '';
	zipCode: string = '';
	zoning: string = '';
}

export class TInFeature {
	active: 'Yes' = 'Yes';
	client_first_name: string = '';
	client_id: string = ''; // number
	client_plan_id: string = ''; // number
	client_email_id: string = ''; // number
	client_last_name: string = '';
	couponCode: string = '';
	geometry: TPoint = new TPoint();
	orderStatus: string = '';
	properties: TInProperty = new TInProperty();
	rec_id: string = ''; // number
	type: 'Feature' = 'Feature'
	createdAt: string = ''; // datetime
	updatedAt: string = ''; // datetime
}

export class TGeoResponse {
	type: 'FeatureCollection' = 'FeatureCollection';
	features: Array<TInFeature> = [];
}