import { LabelValue, sortArray, joinFilterV } from './Types';

enum EPropType {
	industrial = 'Industrial Sites & Properties'
	, ports = 'Port Terminals'
	, transload = 'Terminals and Transload Facilities'
	, warehouse = 'Warehouse/Distribution Center'
	, multip = 'Multipurpose'
}

class FilterProp {
	static sep: string = '*';

	comb = (item01: string, item02: string): string => {
		return '' + item01 + FilterProp.sep + item02;
	}

	deco = (str: string): Array<EPropType> => {
		const res: Array<EPropType> = [];
		const arr = str.split(FilterProp.sep);
		for (let i = 0; i < arr.length; i++) {
			Object.keys(EPropType).forEach((key: string) => {
				if (EPropType[key] === arr[i]) {
					res.push(EPropType[key]);
				}
			});
		}
		return res;
	}
}

export const arrPropertyTypes: Array<LabelValue> = [
	{ 'label': 'Industrial Sites & Properties', 'value': 'Industrial Sites & Properties' },
	{ 'label': 'Port Terminals', 'value': 'Port Terminals' },
	{ 'label': 'Terminals and Transload Facilities', 'value': 'Terminals and Transload Facilities' },
	{ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' },
	// { 'label': 'Multipurpose', 'value': 'Multipurpose' },
];

export const propertyTypesOptions: Array<LabelValue> = [...sortArray(arrPropertyTypes)];
