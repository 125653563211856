import React, { useEffect, lazy,Suspense, useState } from 'react';
import { index } from './config/plugins';
import logoImg from './public/assets/images/logo-black.png';
import Layout from './Layouts/MainLayout';
import Home from './home';
import MapLayers from './map_layers';
import GteStarted from './get-started';
import Process from './process';
import CrusialLinks from '../CrusialLinks';
// const Layout = lazy(() => import('./Layouts/MainLayout'));
//  const Home = lazy(() => import('./home'));
// const MapLayers = lazy(() => import('./map_layers'));
// const GteStarted = lazy(() => import('./get-started'));
//  const Process = lazy(() => import('./process'));
// const CrusialLinks = lazy(() => import('../CrusialLinks'));

const Index = props => {


    useEffect(() => {
        setTimeout(() => {
            index();
        }, 500);
    });
    return (
        <>
            {/* <Suspense
                fallback={
                    <div style={{ textAlign: 'center', marginTop: 200 }} className="spin">
                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 50 }}></i>
                    </div>
                }>

            </Suspense> */}
             <Layout mainHeaderClass={"header-main header2"} logoImg={logoImg} >
                    <div>

                        <Home />
                    </div>
                    <Process />
                    <CrusialLinks />
                    <MapLayers />
                    <GteStarted />

                </Layout>

        </>
    );
}

export default Index;
