import React, { useRef, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';



function DraggableMarker() {

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

    const mapContainer = useRef(null);

    const [mapView, setMapView] = useState(false)
    useEffect(() => {
        const coordinates = document.getElementById('coordinates');
        const coordinates1 = document.getElementById('coordinates1'); 
        const map = new mapboxgl.Map({
            container: mapContainer.current,
            //style: 'mapbox://styles/mapbox/streets-v11',
            style: mapView === true ? 'mapbox://styles/mapbox/streets-v11' : "mapbox://styles/mapbox/satellite-streets-v11?optimize=true",
            center: [-97.91, 39.49],
            //center: [-97.91,  39.49],
            zoom: 2
        });

        const marker = new mapboxgl.Marker({
            draggable: true
        })
            .setLngLat([-97.91, 39.49])
            .addTo(map);

        function onDragEnd() {
            const lngLat = marker.getLngLat();
            coordinates.style.display = 'block';
            coordinates1.style.display = 'block'; 
            coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;
            // console.log("lngLat",lngLat)
            sessionStorage.setItem('temp_address_coordinates', JSON.stringify({
                "coordinates": [
                    lngLat.lng,
                    lngLat.lat
                ]
            }))
            axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lngLat.lng},${lngLat.lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`, {
                headers: {
                    "Content-type": "application/json"
                }
            })
                .then((response) => {
                    console.log("Address response", response.data.features[0])
                   // console.log('city')
                   const shortCode = []
                   for(let i=0;i<response.data.features[0].context.length;i++){
                      if(response.data.features[0].context[i].short_code){
                          shortCode.push(
                            {
                                short_code:response.data.features[0].context[i].short_code,
                                state:response.data.features[0].context[i].text
                            }
                          )
                      }
                   }
                   
             //console.log("shortCode",shortCode[0].short_code.split('-'))
             var temp = shortCode[0].short_code.split('-')
             //console.log('temp',temp)
                    sessionStorage.setItem('temp_address_coordinates', JSON.stringify({
                        "coordinates": [
                            lngLat.lng,
                            lngLat.lat
                        ],
                        "city": response.data.features[0].context[1].text,
                        'state':temp[1],
                        'address':response.data.features[0].place_name,
                        'country':temp[0],
                    }))
                })
                .catch((error) => {
                    console.log("response", error)
                })
        }

        marker.on('dragend', onDragEnd);
    })

    const mapStateChange = () => {
        setMapView(!mapView)
    }








    return (
        <div>
            <div ref={mapContainer} style={{ height: 450 }} />
            <pre id="coordinates" class="draggableMarkercoordinates"></pre>
            <pre id="showsatalliteIcons" class="showsatalliteIconsStyle" >

                {
                    mapView === true
                        ?
                        <svg viewBox='0 0 512 512' style={{
                            width: 50,
                            height: 50,
                        }} onClick={mapStateChange} style={{ color: 'black' }}>
                            <path fill='currentColor'
                                d='M502.60969,310.04206l-96.70393,96.71625a31.88151,31.88151,0,0,1-45.00765,0L280.572,326.34115l-9.89231,9.90759a190.56343,190.56343,0,0,1-5.40716,168.52287c-4.50077,8.50115-16.39342,9.59505-23.20707,2.79725L134.54715,400.05428l-17.7999,17.79929c.70324,2.60972,1.60965,5.00067,1.60965,7.79793a32.00544,32.00544,0,1,1-32.00544-32.00434c2.79735,0,5.18838.90637,7.7982,1.60959l17.7999-17.79929L4.43129,269.94287c-6.798-6.81342-5.70409-18.6119,2.79735-23.20627a190.58161,190.58161,0,0,1,168.52864-5.407l9.79854-9.79821-80.31053-80.41716a32.002,32.002,0,0,1,0-45.09987L201.96474,9.29814A31.62639,31.62639,0,0,1,224.46868,0a31.99951,31.99951,0,0,1,22.59759,9.29814l80.32615,80.30777,47.805-47.89713a33.6075,33.6075,0,0,1,47.50808,0l47.50807,47.50645a33.63308,33.63308,0,0,1,0,47.50644l-47.805,47.89713L502.71908,265.036A31.78938,31.78938,0,0,1,502.60969,310.04206ZM219.56159,197.433l73.82505-73.82252-68.918-68.9-73.80942,73.80689Zm237.74352,90.106-68.90233-68.9156-73.825,73.82252,68.918,68.9Z'
                            >
                            </path>
                        </svg>
                        // <label onClick={mapStateChange} style={{color:'black'}}> MapData</label>

                        :
                        <svg viewBox='0 0 512 512' style={{
                            width: 50,
                            height: 50,
                        }} onClick={mapStateChange} style={{ color: 'black' }}>
                            <path fill='currentColor'
                                d='M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm-32 50.8v11.3c0 11.9-12.5 19.6-23.2 14.3l-24-12c14.9-6.4 30.7-10.9 47.2-13.6zm32 369.8V456c-110.3 0-200-89.7-200-200 0-29.1 6.4-56.7 17.6-81.7 9.9 14.7 25.2 37.4 34.6 51.1 5.2 7.6 11.2 14.6 18.1 20.7l.8.7c9.5 8.6 20.2 16 31.6 21.8 14 7 34.4 18.2 48.8 26.1 10.2 5.6 16.5 16.3 16.5 28v32c0 8.5 3.4 16.6 9.4 22.6 15 15.1 24.3 38.7 22.6 51.3zm42.7 22.7l17.4-46.9c2-5.5 3.3-11.2 4.8-16.9 1.1-4 3.2-7.7 6.2-10.7l11.3-11.3c8.8-8.7 13.7-20.6 13.7-33 0-8.1-3.2-15.9-8.9-21.6l-13.7-13.7c-6-6-14.1-9.4-22.6-9.4H232c-9.4-4.7-21.5-32-32-32s-20.9-2.5-30.3-7.2l-11.1-5.5c-4-2-6.6-6.2-6.6-10.7 0-5.1 3.3-9.7 8.2-11.3l31.2-10.4c5.4-1.8 11.3-.6 15.5 3.1l9.3 8.1c1.5 1.3 3.3 2 5.2 2h5.6c6 0 9.8-6.3 7.2-11.6l-15.6-31.2c-1.6-3.1-.9-6.9 1.6-9.3l9.9-9.6c1.5-1.5 3.5-2.3 5.6-2.3h9c2.1 0 4.2-.8 5.7-2.3l8-8c3.1-3.1 3.1-8.2 0-11.3l-4.7-4.7c-3.1-3.1-3.1-8.2 0-11.3L264 112l4.7-4.7c6.2-6.2 6.2-16.4 0-22.6l-28.3-28.3c2.5-.1 5-.4 7.6-.4 78.2 0 145.8 45.2 178.7 110.7l-13 6.5c-3.7 1.9-6.9 4.7-9.2 8.1l-19.6 29.4c-5.4 8.1-5.4 18.6 0 26.6l18 27c3.3 5 8.4 8.5 14.1 10l29.2 7.3c-10.8 84-73.9 151.9-155.5 169.7z'
                            >
                            </path>
                        </svg>

                }


            </pre>
            <pre id="coordinates1" class="draggableMarkerContent"> Please place the map marker on the desired location to set the address.</pre>
        </div>
    )
}

export default DraggableMarker
