import React, { Component } from 'react';
import Header from './Header';
import SimpleReactValidator from 'simple-react-validator';
import { Cookies } from 'react-cookie';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import UserProfile from './UserProfile';
import gtag from 'ga-gtag';
let priceId;
let customerId;
const cookies = new Cookies();
interface PTimerSignUp {
    history?: Array<string>;
}

class STimerSignUp {
    constructor() {
    }
}

class TimerSignUp extends Component<STimerSignUp, any> {

    registerValidator: SimpleReactValidator = null;
    constructor(props) {
        super(props);
        this.state = {
            fname: '',
            lname: '',
            companyName: '',
            password: '',
            confirm_password: '',
            reg_email: '',
            timerSignModal: false,
            showLoginSuccessModal: false,
        }
    }


    componentWillMount() {
        this.registerValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }


    componentDidMount() {
    }

    showTimerModal = () => {
        this.setState({ timerSignModal: true });
    }

    hideTimerModal = () => {
        this.setState({ timerSignModal: false });
    }
    setFName(event) {
        this.setState({ fname: event.target.value });
    }

    setLName(event) {
        this.setState({ lname: event.target.value });
    }

    setCompanyName(event) {
        this.setState({ companyName: event.target.value });
    }

    setPassword(event) {
        this.setState({ password: event.target.value });
    }

    setConfirmPassword(event) {
        this.setState({ confirm_password: event.target.value });
    }
    setEmailreg(event) {
        this.setState({ reg_email: event.target.value });
    }
    registerUser(event) {
        if (this.registerValidator.allValid()) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*';
            let randon_password = '';
            const charactersLength = characters.length;
            gtag('event', 'free_trial_register', {
                poll_title: 'free_trial_register',
            })
    
            for (let i = 0; i < 6; i++) {
                randon_password += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            console.log("random passwprd", randon_password)
            this.setState({ password: randon_password });
            axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user-limited-offer',
                {
                    headers: { 'Content-Type': 'application/json' },
                    fname: this.state.fname,
                    lname: this.state.lname,
                    email: this.state.reg_email,
                    companyName: this.state.companyName,
                    password: randon_password,
                    reacatpcha_value: '',
                    'source': 'View Listings Limited Offer',
                }).then(response => {
                    // handle your response;
                    if (response.status === 200) {
                        // this.setState({
                        // 	fname: '',
                        // 	lname: '',
                        // 	companyName: '',
                        // 	//password: '',
                        // 	confirm_password: ''
                        // });
                        this.createCouponOrder()

                    }
                }).catch((error) => {
                    console.log('error')
                    alert('failed')
                    console.log(error.response)
                    // handle your error
                    const regi_errors = document.getElementById('regi_errors');
                    regi_errors.innerHTML = '';
                    const error_html = document.createElement('div');
                    if (error.response.status === 600 && error.response.data.error === 'email exists') {
                        error_html.innerHTML = 'You already have an account, please login!';
                    } else if (error.response.status === 601) {
                        error_html.innerHTML = 'Captcha verification failed, please try again!';
                    } else {
                        for (let i = 0; i < error.response.data.errors.length; i++) {
                            error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
                        }
                    }
                    document.getElementById('regi_errors').appendChild(error_html);
                    document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
                    regi_errors.scrollIntoView({ behavior: 'smooth' })
                });
        } else {
            this.registerValidator.showMessages();
            this.forceUpdate();
        }
    }
    createCouponOrder = () => {

        try {
            const user_email = this.state.reg_email;
            //const promocode_obj = { "SME": "promo_1Jk2QqI9aRcuI4JhhulVFQCc", "SDG": "promo_1Jk2RUI9aRcuI4JhrLpj3tIy", "EARLY": "promo_1Jk2SKI9aRcuI4JhLmnxomZt" }
            // const promocode_obj = { SME: "promo_1Jk2QqI9aRcuI4JhhulVFQCc", SDG: "promo_1JkB4UI9aRcuI4JhfXn1sw0h", EARLY: "promo_1JkB3mI9aRcuI4Jhko6ZlaLQ" }
            const promotion_code = 'promo_1MHjHOI9aRcuI4JhAbvgYRCt';
            priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om';
            var self = this;
            axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
                email: this.state.reg_email,
                //customerId:'',
            }).then(function (response) {
                localStorage.setItem('CustomerId', response.data.customer.id);
                customerId = response.data.customer.id

                axios.post(process.env.REACT_APP_BASE_URL + '/api/create-coupan-order', {

                    priceId: priceId,
                    customerId: customerId,
                    promotion_code: promotion_code,
                })
                    .then(function (response) {
                        if (response.status === 200) {

                            localStorage.setItem('SubscriptionId', response.data.subscription.id);

                            axios.post(process.env.REACT_APP_BASE_URL + '/api/update-stripe-subscription-id-customer-id', {
                                usr_email: user_email,
                                subscription_id: response.data.subscription.id,
                                customerId: customerId,
                                //customerId:'',
                            }).then(function (response) {
                                self.autoLogin();
                            });
                        }

                    })
                    .catch(function (error) {
                        console.log('response error', error);
                        alert("Invalid promo code used.")
                        console.log(error);
                    });

            });
        } catch (err) {
            console.log('error in order update');
            console.error(err);
        }
    }
    autoLogin = () => {
        try {
            console.log('handle auto login', this.state.reg_email, this.state.password);
            axios.post(process.env.REACT_APP_BASE_URL + '/api/auto-login-user',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    login_email: this.state.reg_email,
                    login_password: this.state.password,
                }).then((response: {
                    status: number
                    , data: {
                        bookmarks_str: string
                        , email: string
                        , first_name: string
                        , last_name: string
                        , max_no_entries: string
                        , options: string
                        , place_names_arr: string //Array<any>
                        , user_type: string
                        , token: string
                    }
                }) => {
                    // document.getElementById('login_error').style.display = 'none';
                    // document.getElementById('verify_email_error').style.display = 'none';
                    console.log('autoLogin api response: ', response);

                    //this.handleClose();

                    if (response.status === 200) {
                        let bookmarksCount = 0;
                        if (response.data.bookmarks_str) {
                            const bookmarksArr = response.data.bookmarks_str.split(',');
                            bookmarksCount = bookmarksArr.length;
                        }
                        localStorage.setItem('bookmarks', response.data.bookmarks_str);
                        localStorage.setItem('listingNames', response.data.place_names_arr);
                        localStorage.setItem('maxNoEntries', response.data.max_no_entries);
                        localStorage.setItem('membershipOptions', response.data.options);
                        localStorage.removeItem('questionOneArr');
                        localStorage.removeItem('questionTwoArr');
                        localStorage.removeItem('questionThreeArr');
                        localStorage.removeItem('questionFourArr');
                        localStorage.removeItem('checkboxone');
                        localStorage.removeItem('checkboxtwo');

                        this.handleLoginSuccessResponse(response);
                    } else {
                        alert("Something went wrong")
                    }
                }).catch((error) => {
                    console.log('error auto login api: ', error);
                });
        } catch (err) {
            console.log('error in auto login');
            console.error(err);
        }
    }
    handleLoginSuccessResponse = response => {

        console.log('order is updated with success');
        localStorage.removeItem('listingNames');
        localStorage.removeItem('UseremailId');
        this.setState({
            shoppingCartCount: 0
        });

        UserProfile.setEmail(response.data.email);
        UserProfile.setFirstName(response.data.first_name);
        UserProfile.setLastName(response.data.last_name);
        UserProfile.setUserType(response.data.user_type);
        UserProfile.setCompanyName(response.data.companyName);

        this.setState({ showLoginSuccessModal: true });
        
      
        cookies.set('token', response.data.token);
        localStorage.removeItem('planNames');
    }
    handleLoginSuccessModal() {
        this.setState({ showLoginSuccessModal: false });
        const get = document.getElementById('limitedOfferSignupModal');
        if (get != null) {
            get.click();
        }
    }

    child: Header = null;

    render() {
        return (
            <div style={{ backgroundColor: '#f8f9fa', paddingTop: '.5rem', borderRadius: 6 }}>

                <form id='slider-subscribe-form' style={{ width: '99%', marginLeft: '0%', paddingBottom: '10px' }} className='nobottommargin reduce-margin-form'>
                    <div className='color-black  margin-bottom-20' style={{ color: '#474747', fontSize: 14 }}><b style={{ fontSize: 35,fontWeight:800 }}>LIMITED TIME OFFER</b> <br /><br />Sign up for a 60 day free trial of <b>Power Pro</b>. Get access to all the advanced features and data layers, and leverage the power of this site search tool. Sign up is quick and easy. Join our growing community of users!</div>

                    <div id='regi_errors' className='margin-top-15'></div>

                    <div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
                        <input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
                    </div>
                    {this.registerValidator.message('first name', this.state.fname, 'required')}

                    <div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
                        <input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
                    </div>
                    {this.registerValidator.message('last name', this.state.lname, 'required')}
                    <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                        <input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
                    </div>
                    {this.registerValidator.message('company name', this.state.companyName, 'required')}
                    <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                        <input type='email' name='reg_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmailreg.bind(this)} value={this.state.reg_email} />
                    </div>
                    {this.registerValidator.message('email', this.state.reg_email, 'required')}
                    <div className='col_full center padding-top-20'>
                        <button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
                    </div>
                </form>
                <Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div><br />
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Registration Successful
                            </h2>
                            <p className='text-muted text-left font-size-16-imp margin-bottom-0'>
                                Congratulations on creating an account!<br /><br />Your unique password is {this.state.password}. You can change it later by clicking on "forgot password" when logging in next time.<br /><br /> Now that you have subscribed, you may create and list up to 100 properties on our platform for free. You may also browse all listings in our app.  For any questions, please email us at info@industrialnavigator.com, or call +1 (312) 957-7757.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }


}

export default TimerSignUp;


const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)