import React from 'react';
import { FeatureCollection, Feature, Point } from 'geojson';
import { Cluster, Marker } from 'react-mapbox-gl';
import { clusterMarker, ICON_SIZE } from './MapCluster';

interface PMapCluster4MajorPort {
	collection: FeatureCollection;
	onCollectionMarkerClick: (feature: Feature) => void;
	onMarkersClusterClick: (coordinates: GeoJSON.Position, pointsCount: number) => void;
	icon?: string;
	clusterColor?: string;
}

class SMapCluster4MajorPort {
	ref: MapCluster4MajorPort = null;

	constructor(parent: MapCluster4MajorPort) {
		this.ref = parent;
	}
}

export default class MapCluster4MajorPort extends React.Component<PMapCluster4MajorPort, SMapCluster4MajorPort> {

	constructor(props: PMapCluster4MajorPort) {
		super(props);
		this.state = new SMapCluster4MajorPort(this);
	}

	render() {
		const { props } = this;
		return (
			<Cluster ClusterMarkerFactory={
				(coordinates: GeoJSON.Position, pointsCount: number, getLeaves: (limit?: number, offset?: number) => Array<React.ReactElement>) => {
					return clusterMarker(coordinates, pointsCount, props.clusterColor, props.onMarkersClusterClick);
				}}
				zoomOnClick
			>
				{
					props.collection && props.collection.features && props.collection.features.map((feature: Feature, index: number) => {
						const point: Point = feature.geometry as Point;
						return (
							<Marker
								className='map-unclustered-marker'
								key={'MapCluster4MajorPort_collection_' + (JSON.stringify(feature))}
								onClick={(event: React.MouseEvent) => { props.onCollectionMarkerClick(feature); }}
								coordinates={point.coordinates}
							>
								<img src={props.icon} width={ICON_SIZE} height={ICON_SIZE} />
							</Marker>
						)
					})
				}
			</Cluster>
		)
	}
}
