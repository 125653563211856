import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from './UserProfile';
import '../css/adminLTE-custom.css';
import * as XLSX from "xlsx";
import Footer from './Footer';
import Header from './HeaderLTE';
import SideBar from './SideBarLTE';
import MetaTags from 'react-meta-tags';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';


interface PMyListing {
    history: Array<string>;
}

class SMyListing {
    data: {};
    first_name: '';
    last_name: '';
    usa_state: string = '';
    country: string = '';
    coordinates: [123, 123]
    submitbutton: boolean = false;
    invalidData: boolean = false;
    planid: '';
    recordsInsertSuccess: boolean = false;

    constructor() {
    }

}
class MyListing extends Component<PMyListing, SMyListing> {

    _isMounted = false;
    constructor(props: PMyListing) {
        super(props);
        this.state = new SMyListing();

        this.readExcel = this.readExcel.bind(this);
        this.submitRecords = this.submitRecords.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillMount() {
        this._isMounted = true;
        axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                email: UserProfile.getEmail(),
            }).then(response => {
                this.setState({
                    planid: response.data[0].sw_plan_id
                })
            });

    }


    readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            this.setState({
                data: d
            })
            for (let index = 0; index < Object.keys(this.state.data).length; index++) {

                if (this.state.data[index].propertyTypeLabel) {
                    console.log(this.state.data[index].propertyTypeLabel)
                }
                else {
                    alert("Property type is missing for record " + (index + 1))
                    this.setState({
                        invalidData: true
                    })

                }
                if (this.state.data[index].Latitude) {
                    if (this.state.data[index].Longitude) {
                        this.state.data[index].coordinates = [Number(this.state.data[index].Longitude), Number(this.state.data[index].Latitude)]
                    }
                    else
                        if (this.state.data[index].address) {
                            geocodeByAddress(this.state.data[index].address)
                                .then(results => {
                                    // console.log(results);
                                    let address_components = results[0].address_components;
                                    let city = '', usaState = '', country = '', zipCode = '';
                                    for (let index = 0; index < address_components.length; index++) {
                                        const element = address_components[index];
                                        if (element.types[0] === 'locality') {
                                            city = element.short_name;
                                        }
                                        if (element.types[0] === 'administrative_area_level_1') {
                                            usaState = element.short_name;
                                        }
                                        if (element.types[0] === 'country') {
                                            country = element.short_name;
                                        }
                                        if (element.types[0] === 'postal_code') {
                                            zipCode = element.short_name;
                                        }
                                    }
                                    // console.log("city,usaState,country,zipCode", city, usaState, country, zipCode)
                                    this.setState({
                                        usa_state: usaState,
                                        country: country,
                                    }, () => {
                                        getLatLng(results[0]).then(latLng => {
                                            this.setState({ coordinates: [latLng['lng'], latLng['lat']] }, () => {
                                                axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latLng['lat'] + '&lon=' + latLng['lng'] + '&addressdetails=1%22')
                                                    .then(response => {
                                                        this.state.data[index].coordinates = this.state.coordinates
                                                        if (this.state.coordinates.length != 2) {
                                                            alert("Address is missing for record " + (index + 1))
                                                            this.setState({
                                                                invalidData: true
                                                            })
                                                        }
                                                    }).catch(function (error) {
                                                        console.log(error);
                                                        console.log(error.json());
                                                        alert("Invalid Address for record " + (index + 1))
                                                        this.setState({
                                                            invalidData: true
                                                        })
                                                    });
                                            });
                                        });
                                    });
                                })
                                .catch(error => {
                                    console.error('Error', error);
                                    alert("Invalid Address for record " + (index + 1))
                                    this.setState({
                                        invalidData: true
                                    })
                                });
                        }
                        else {
                            alert("Address is missing for record " + (index + 1))
                            this.setState({
                                invalidData: true
                            })
                        }




                }
                else {
                    if (this.state.data[index].address) {
                        geocodeByAddress(this.state.data[index].address)
                            .then(results => {
                                // console.log(results);
                                let address_components = results[0].address_components;
                                let city = '', usaState = '', country = '', zipCode = '';
                                for (let index = 0; index < address_components.length; index++) {
                                    const element = address_components[index];
                                    if (element.types[0] === 'locality') {
                                        city = element.short_name;
                                    }
                                    if (element.types[0] === 'administrative_area_level_1') {
                                        usaState = element.short_name;
                                    }
                                    if (element.types[0] === 'country') {
                                        country = element.short_name;
                                    }
                                    if (element.types[0] === 'postal_code') {
                                        zipCode = element.short_name;
                                    }
                                }
                                // console.log("city,usaState,country,zipCode", city, usaState, country, zipCode)
                                this.setState({
                                    usa_state: usaState,
                                    country: country,
                                }, () => {
                                    getLatLng(results[0]).then(latLng => {
                                        this.setState({ coordinates: [latLng['lng'], latLng['lat']] }, () => {
                                            axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latLng['lat'] + '&lon=' + latLng['lng'] + '&addressdetails=1%22')
                                                .then(response => {
                                                    this.state.data[index].coordinates = this.state.coordinates
                                                    if (this.state.coordinates.length != 2) {
                                                        alert("Address is missing for record " + (index + 1))
                                                        this.setState({
                                                            invalidData: true
                                                        })
                                                    }
                                                }).catch(function (error) {
                                                    console.log(error);
                                                    console.log(error.json());
                                                    alert("Invalid Address for record " + (index + 1))
                                                    this.setState({
                                                        invalidData: true
                                                    })
                                                });
                                        });
                                    });
                                });
                            })
                            .catch(error => {
                                console.error('Error', error);
                                alert("Invalid Address for record " + (index + 1))
                                this.setState({
                                    invalidData: true
                                })
                            });
                    }
                    else {

                        if (this.state.data[index].city && this.state.data[index].state) {
                            geocodeByAddress(this.state.data[index].city + ',' + this.state.data[index].state)
                                .then(results => {
                                    // console.log(results);
                                    let address_components = results[0].address_components;
                                    let city = '', usaState = '', country = '', zipCode = '';
                                    for (let index = 0; index < address_components.length; index++) {
                                        const element = address_components[index];
                                        if (element.types[0] === 'locality') {
                                            city = element.short_name;
                                        }
                                        if (element.types[0] === 'administrative_area_level_1') {
                                            usaState = element.short_name;
                                        }
                                        if (element.types[0] === 'country') {
                                            country = element.short_name;
                                        }
                                        if (element.types[0] === 'postal_code') {
                                            zipCode = element.short_name;
                                        }
                                    }
                                    console.log("city,usaState,country,zipCode", city, usaState, country, zipCode)
                                    this.setState({
                                        usa_state: usaState,
                                        country: country,
                                    }, () => {
                                        getLatLng(results[0]).then(latLng => {
                                            this.setState({ coordinates: [latLng['lng'], latLng['lat']] }, () => {
                                                axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latLng['lat'] + '&lon=' + latLng['lng'] + '&addressdetails=1%22')
                                                    .then(response => {
                                                        this.state.data[index].coordinates = this.state.coordinates
                                                        if (this.state.coordinates.length != 2) {
                                                            alert("Address is missing for record " + (index + 1))
                                                            this.setState({
                                                                invalidData: true
                                                            })
                                                        }
                                                    }).catch(function (error) {
                                                        console.log(error);
                                                        console.log(error.json());
                                                        alert("Invalid Address for record " + (index + 1))
                                                        this.setState({
                                                            invalidData: true
                                                        })
                                                    });
                                            });
                                        });
                                    });
                                })
                                .catch(error => {
                                    console.error('Error', error);
                                    alert("Invalid Address for record " + (index + 1))
                                    this.setState({
                                        invalidData: true
                                    })
                                });
                        } else {
                            alert("Address is missing for record " + (index + 1))
                            this.setState({
                                invalidData: true
                            })
                        }



                    }


                }
                if (this.state.data[index].listingName) {
                    console.log(this.state.data[index].listingName)
                }
                else {
                    alert("listing name is missing for record " + (index + 1))
                    this.setState({
                        invalidData: true
                    })

                }
                if (this.state.data[index].contactEmail) {
                    console.log(this.state.data[index].contactEmail)
                }
                else {
                    alert("Contact Email is missing for record " + (index + 1))
                    this.setState({
                        invalidData: true
                    })

                }
                if (this.state.data[index].state) {
                    console.log(this.state.data[index].state)
                }
                else {
                    if (this.state.data[index].address) {
                        geocodeByAddress(this.state.data[index].address)
                            .then(results => {
                                // console.log(results);
                                let address_components = results[0].address_components;
                                let city = '', usaState = '', country = '', zipCode = '';
                                for (let index = 0; index < address_components.length; index++) {
                                    const element = address_components[index];
                                    if (element.types[0] === 'locality') {
                                        city = element.short_name;
                                    }
                                    if (element.types[0] === 'administrative_area_level_1') {
                                        usaState = element.short_name;
                                    }
                                    if (element.types[0] === 'country') {
                                        country = element.short_name;
                                    }
                                    if (element.types[0] === 'postal_code') {
                                        zipCode = element.short_name;
                                    }
                                }
                                // console.log("city,usaState,country,zipCode", city, usaState, country, zipCode)
                                if (usaState == '') {
                                    alert("State is missing for record " + (index + 1))
                                    this.setState({
                                        invalidData: true
                                    })
                                }
                                this.setState({
                                    usa_state: usaState,
                                    country: country,
                                });
                                this.state.data[index].state = usaState
                            })
                            .catch(error => {
                                console.error('Error', error);
                            });
                    }
                    else {
                        alert("Address is missing for record " + (index + 1))
                        this.setState({
                            invalidData: true
                        })
                    }


                }
                if (this.state.data[index].country) {
                    console.log(this.state.data[index].country)
                }
                else {
                    if (this.state.data[index].address) {
                        geocodeByAddress(this.state.data[index].address)
                            .then(results => {
                                // console.log(results);
                                let address_components = results[0].address_components;
                                let city = '', usaState = '', country = '', zipCode = '';
                                for (let index = 0; index < address_components.length; index++) {
                                    const element = address_components[index];
                                    if (element.types[0] === 'locality') {
                                        city = element.short_name;
                                    }
                                    if (element.types[0] === 'administrative_area_level_1') {
                                        usaState = element.short_name;
                                    }
                                    if (element.types[0] === 'country') {
                                        country = element.short_name;
                                    }
                                    if (element.types[0] === 'postal_code') {
                                        zipCode = element.short_name;
                                    }
                                }
                                // console.log("city,usaState,country,zipCode", city, usaState, country, zipCode)
                                if (country == '') {
                                    alert("Country is missing for record " + (index + 1))
                                    this.setState({
                                        invalidData: true
                                    })
                                }
                                this.setState({
                                    usa_state: usaState,
                                    country: country,
                                });
                                this.state.data[index].country = this.state.country
                            })
                            .catch(error => {
                                console.error('Error', error);
                            });
                    }
                    else {
                        alert("Country is missing for record " + (index + 1))
                        this.setState({
                            invalidData: true
                        })
                    }

                }
            }
            setTimeout(() => {
                if (this.state.invalidData) {
                    alert("Invalid data")
                    this.setState({
                        data: {}
                    })
                }
                else {
                    this.setState({
                        data: d
                    })
                    console.log("final data object:", d)
                }
            }, 3000);


        });
    }
    submitRecords = () => {
        if (this.state.data) {
            if (Object.keys(this.state.data).length == 0) {
                alert("Please upload proper data sheet")
            }
            else {
                document.getElementById("saveButon").setAttribute('disabled', 'disabled');
                this.setState({ submitbutton: true })//Object.keys(this.state.data).length
                for (let index = 0; index < Object.keys(this.state.data).length; index++) {
                    var basicData = {
                        'propertyType': this.state.data[index].propertyTypeLabel,
                        'propertyFor': this.state.data[index].propertyFor ? this.state.data[index].propertyFor : '',
                        'listingName': this.state.data[index].listingName ? this.state.data[index].listingName : '',
                        'listingLogo': this.state.data[index].listingLogo ? this.state.data[index].listingLogo : '',
                        'organizationName': this.state.data[index].organizationName ? this.state.data[index].organizationName : '',
                        'image': '',
                        'contactName': this.state.data[index].contactName ? this.state.data[index].contactName : '',
                        'contactTitle': this.state.data[index].contactTitle ? this.state.data[index].contactTitle : '',
                        'contactEmail': this.state.data[index].contactEmail ? this.state.data[index].contactEmail : '',
                        'contact': this.state.data[index].contact ? this.state.data[index].contact : '',
                        'description': this.state.data[index].description ? this.state.data[index].description : '',
                        'city': this.state.data[index].city ? this.state.data[index].city : '',
                        'state': this.state.data[index].state ? this.state.data[index].state : '',
                        'zipCode': this.state.data[index].zipCode ? this.state.data[index].zipCode : '',
                        'address': this.state.data[index].address ? this.state.data[index].address : '',
                        'country': this.state.data[index].country ? this.state.data[index].country : '',
                    }
                    var siteData = {
                        'price': this.state.data[index].price ? String(this.state.data[index].price) : '',
                        'perUnit': this.state.data[index].perUnit ? this.state.data[index].perUnit : '',
                        'acreage': this.state.data[index].acreage ? String(this.state.data[index].acreage) : '',
                        'zoning': this.state.data[index].zoning ? this.state.data[index].zoning : '',
                        'building': this.state.data[index].building ? this.state.data[index].building : '',
                        'squareFeet': this.state.data[index].squareFeet ? this.state.data[index].squareFeet : '',
                        'interiorRoofHeight': this.state.data[index].interiorRoofHeight ? this.state.data[index].interiorRoofHeight : '',
                        'suitableFor': this.state.data[index].suitableFor ? this.state.data[index].suitableFor : '',
                        'pipelineAccess': this.state.data[index].pipelineAccess ? this.state.data[index].pipelineAccess : '',
                        'waterSewerInPlace': this.state.data[index].waterSewerInPlace ? this.state.data[index].waterSewerInPlace : '',
                        'electricPowerInPlace': this.state.data[index].electricPowerInPlace ? this.state.data[index].electricPowerInPlace : '',
                        'serviceVoltage': this.state.data[index].serviceVoltage ? this.state.data[index].serviceVoltage : '',
                        'distanceToSubstation': this.state.data[index].distanceToSubstation ? this.state.data[index].distanceToSubstation : '',
                        'naturalGasInPlace': this.state.data[index].naturalGasInPlace ? this.state.data[index].naturalGasInPlace : '',
                        'lineDiameter': this.state.data[index].lineDiameter ? this.state.data[index].lineDiameter : '',
                        'environmental': this.state.data[index].environmental ? this.state.data[index].environmental : '',
                        'airAttainmentStatus': this.state.data[index].airAttainmentStatus ? this.state.data[index].airAttainmentStatus : '',
                        'plgCertification': 'No',
                        'siteQualification': 'No',
                    }
                    var terminalPortTransloadData = {
                        'capabilities': this.state.data[index].capabilities ? this.state.data[index].capabilities : '',
                        'equipments': this.state.data[index].equipments ? this.state.data[index].equipments : '',
                        'railcarStorageCapacity': this.state.data[index].railcarStorageCapacity ? String(this.state.data[index].railcarStorageCapacity) : '',
                        'majorFeatures': this.state.data[index].majorFeatures ? this.state.data[index].majorFeatures : '',
                        'specifications': this.state.data[index].specifications ? this.state.data[index].specifications : '',
                        'productStorage': this.state.data[index].productStorage ? this.state.data[index].productStorage : '',
                        'productsHandled': this.state.data[index].productsHandled ? this.state.data[index].productsHandled : '',
                        'indoorStorage': this.state.data[index].indoorStorage ? this.state.data[index].indoorStorage : '',
                        'pttAcreage': this.state.data[index].pttAcreage ? this.state.data[index].pttAcreage : ''
                    }
                    var warehouseData = {
                        'warehousePrice': this.state.data[index].warehousePrice ? this.state.data[index].warehousePrice : '',
                        'warehousePricePerUnit': this.state.data[index].warehousePricePerUnit ? this.state.data[index].warehousePricePerUnit : '',
                        'warehouseCapabilities': this.state.data[index].warehouseCapabilities ? this.state.data[index].warehouseCapabilities : '',
                        'warehouseMinSize': this.state.data[index].warehouseMinSize ? this.state.data[index].warehouseMinSize : '',
                        'warehouseMaxSize': this.state.data[index].warehouseMaxSize ? this.state.data[index].warehouseMaxSize : '',
                        'temperatureControl': this.state.data[index].temperatureControl ? this.state.data[index].temperatureControl : '',
                        'parkingSpaces': this.state.data[index].parkingSpaces ? this.state.data[index].parkingSpaces : '',
                        'foodGrade': this.state.data[index].foodGrade ? this.state.data[index].foodGrade : '',
                        'freeTradeZone': this.state.data[index].freeTradeZone ? this.state.data[index].freeTradeZone : '',
                        'roofHeight': this.state.data[index].roofHeight ? this.state.data[index].roofHeight : '',
                        'warehouseAudits': 'No',
                    }
                    var marineAccessData = {
                        'marineAccess': this.state.data[index].marineAccess ? this.state.data[index].marineAccess : '',
                        'bargeAccess': this.state.data[index].bargeAccess ? this.state.data[index].bargeAccess : '',
                        'docInPlace': this.state.data[index].docInPlace ? this.state.data[index].docInPlace : '',
                        'waterways': this.state.data[index].waterways ? this.state.data[index].waterways : '',
                        'distancetoWater': this.state.data[index].distancetoWater ? this.state.data[index].distancetoWater : '',
                    }
                    var servingCarriersData = {
                        'classOneConnections': this.state.data[index].classOneConnections ? this.state.data[index].classOneConnections : '',
                        'shortLines': this.state.data[index].shortLines ? this.state.data[index].shortLines : '',
                        'railInPlace': this.state.data[index].railInPlace ? this.state.data[index].railInPlace : '',
                        'railcarStorage': this.state.data[index].railcarStorage ? this.state.data[index].railcarStorage : '',
                        'unitTrainCapable': this.state.data[index].unitTrainCapable ? this.state.data[index].unitTrainCapable : '',
                        'railcarCapacity': this.state.data[index].railcarCapacity ? this.state.data[index].railcarCapacity : '',
                    }
                    var additionalAttributesData = {
                        'parkingCapacity': this.state.data[index].parkingCapacity ? this.state.data[index].parkingCapacity : '',
                        'nearestInterstate': this.state.data[index].nearestInterstate ? this.state.data[index].nearestInterstate : '',
                        'milesToInterstate': this.state.data[index].milesToInterstate ? this.state.data[index].milesToInterstate : '',
                        'nearestAirport': this.state.data[index].nearestAirport ? this.state.data[index].nearestAirport : '',
                        'milesToAirport': this.state.data[index].milesToAirport ? this.state.data[index].milesToAirport : '',
                        'silos': this.state.data[index].silos ? this.state.data[index].silos : '',
                    }
                    var consolidatedObj = Object.assign(basicData, siteData, terminalPortTransloadData, warehouseData, marineAccessData, servingCarriersData, additionalAttributesData);
                    // console.log('coordinatoes', this.state.data[index].coordinates.split(","))
                    var placeData = {
                        'rec_id': null,
                        'client_id': null,
                        'client_first_name': UserProfile.getFirstName(),
                        'client_last_name': UserProfile.getLastName(),
                        'client_email_id': UserProfile.getEmail(),
                        'client_plan_id': this.state.planid,
                        'total_views': 0,
                        'who_view_listings_obj': [],
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': this.state.data[index].coordinates ? Array.from(this.state.data[index].coordinates, Number) : '',
                        },
                        'properties': consolidatedObj,
                        'orderStatus': 'Successful',
                        'active': 'No',
                        'createdAt': '',
                        'updatedAt': '',
                    }
                    console.log('placeData', placeData);
                    const formData = new FormData();
                    formData.append('placeData', JSON.stringify(placeData));
                    setTimeout(() => { this.submitFormData(formData, index); }, 5000);
                }

            }

        }
        else {
            alert("Please upload excel file")
        }

        // this.setState({ submitbutton: false })

    }
    async submitFormData(formData, index) {
        alert("Record " + (index + 1) + " inserted")
        await axios.post(process.env.REACT_APP_BASE_URL + '/api/create-geojson-record-for-excel', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log('create response');
            console.log(response);
            if (response.status == 200) {
                if (index == Object.keys(this.state.data).length - 1) {
                    alert("All records inserted successfully.")
                    this.props.history.push('/my-listing');
                    document.getElementById("saveButon").setAttribute('enable', 'enable');
                }
                // else{
                //     alert((index + 1 ) + "Record inserted")
                // }
            }
            else {
                alert('Something went wrong. If you are still having problems please contact us at info@industrialnavigator.com.')

            }

        }).catch(error => {
            // handle your error
            console.log(error)
            // alert('Something went wrong. If you are still having problems please contact us at info@industrialnavigator.com.')
            // document.getElementById("saveButon").setAttribute('enable', 'enable');
        });

    }


    render() {
        return (
            <>
                <div style={{ backgroundColor: '#222D32' }}>
                    <MetaTags>
                        <title>Import Listings</title>

                    </MetaTags>
                    <Header />
                    <SideBar />
                    <div className='content-wrapper'>
                        <section className='content-header' style={{ backgroundColor: 'white' }}>
                            <div className='row'>
                                <div className='col-md-12'>

                                    <div className='col-md-12'>
                                        <div className='col-md-6' style={{
                                            flex: '1 0 auto'
                                            , color: 'rgba(0, 0, 0, 0.87)'
                                            , fontSize: 30
                                            , fontWeight: 700
                                            , marginTop: 15
                                            , marginBottom: 30
                                        }}
                                        >
                                            <div >Import Listings Records</div>
                                        </div>

                                    </div>

                                    <div style={{ marginLeft: 15 }} className='col-md-12'>
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                const file = e.target.files[0];
                                                this.readExcel(file);
                                            }}
                                        /><br />
                                        <button id='saveButon' onClick={this.submitRecords} className='btn btn-default border-radius-25-imp'>
                                            {
                                                this.state.submitbutton ?
                                                    <i className="fa fa-spinner fa-spin"></i> :
                                                    ''


                                            }
                                            Submit Listings
                                        </button>
                                        {
                                            this.state.submitbutton ?
                                                <span> Your listing will be submitted shortly.</span> :
                                                ''


                                        }
                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>


                    <Footer />
                </div>
            </>
        )
    }
}

export default withRouter(MyListing);