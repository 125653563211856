import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import UserProfile from './UserProfile';
import { Cookies } from 'react-cookie';

// const BASE_URL = 'http://3.220.32.133:5000';
const cookies = new Cookies();

export default function withAuth(ComponentToProtect) {
	return class extends Component<any, any> {
		constructor(props) {
			super(props);
			this.state = {
				loading: true,
				redirect: false,
			};
		}
		componentDidMount() {
			fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
					'x-access-token': cookies.get('token')
				},
			})
				.then((res) => {
					if (res.status === 200) {
						this.setState({ loading: false });
					} else {
						UserProfile.forgetEmail();
						UserProfile.forgetFirstName();
						UserProfile.forgetLastName();
						cookies.remove('token');
						localStorage.clear();
						// this.props.data.history.push('/');
						this.props.history.push('/');
						const error = new Error((res as any).error);
						throw error;
					}
				})
				.catch(err => {
					console.error(err);
					this.setState({ loading: false, redirect: true });
				});
		}
		render() {
			const { loading, redirect } = this.state;
			if (loading) {
				return null;
			}
			if (redirect) {
				return <Redirect to='/register' />;
			}
			return (
				<React.Fragment>
					<ComponentToProtect {...this.props} />
				</React.Fragment>
			);
		}
	}
}