import React from 'react';
import img1 from '../../../public/assets/images/logo.png'
import logo from '../../../public/assets/images/logo.svg';
const Index = (props) => {

    return (
        <>
            <p >
                <img alt="img" className="mb-4 img-fluid" src={logo} width="300"  />
                <br /><br />
                {props.sectionText}
            </p>


        </>
    );
};

export default Index;