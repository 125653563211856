import React from 'react';
import { Modal } from 'react-bootstrap';

const HelpModal = ({ handleClose, show, children }) => (
	<Modal show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px', float: 'left' }}>
			<div className='col-lg-12' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);

export default HelpModal;
