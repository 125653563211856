export interface TIntermodal {
	'X': number;
	'Y': number;
	'OBJECTID': number;
	'TERMINAL': string;
	'TERM_ADDRE': string;
	'CITY': string;
	'STATE': string;
	'ZIP_CODE': number;
	'PORT': string;
	'DATE': string;
	'RAIL_COMPA': string;
	'EQUIPMENT': string;
	'ACCESS_ROA': string;
	'SPLC': number;
	'LAT': number;
	'LON': number;
}

const intermodalJSON: Array<TIntermodal> = [
	{
		'X': -73.709533,
		'Y': 42.914715,
		'OBJECTID': 1,
		'TERMINAL': 'Albany, NY - Mechanicville',
		'TERM_ADDRE': '50 Route 67',
		'CITY': 'Mechanicville',
		'STATE': 'NY',
		'ZIP_CODE': 12118,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 171760,
		'LAT': 42.914715,
		'LON': -73.709533
	},
	{
		'X': -106.653344,
		'Y': 35.04758,
		'OBJECTID': 2,
		'TERMINAL': 'Albuquerque, NM',
		'TERM_ADDRE': '100 Woodward Road S.E.',
		'CITY': 'Albuquerque',
		'STATE': 'NM',
		'ZIP_CODE': 87102,
		'PORT': '',
		'DATE': '2018/09/01 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 774900,
		'LAT': 35.04758,
		'LON': -106.653344
	},
	{
		'X': -97.347026,
		'Y': 32.999881,
		'OBJECTID': 3,
		'TERMINAL': 'Alliance, TX',
		'TERM_ADDRE': '1111 Intermodal Parkway',
		'CITY': 'Haslet',
		'STATE': 'TX',
		'ZIP_CODE': 76052,
		'PORT': '',
		'DATE': '2018/09/02 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 668100,
		'LAT': 32.999881,
		'LON': -97.347026
	},
	{
		'X': -91.509663,
		'Y': 44.251243,
		'OBJECTID': 4,
		'TERMINAL': 'Arcadia, WI',
		'TERM_ADDRE': 'One Ashley Way',
		'CITY': 'Arcadia',
		'STATE': 'WI',
		'ZIP_CODE': 54612,
		'PORT': '',
		'DATE': '2018/09/01 00:00:00+00',
		'RAIL_COMPA': 'CN',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'South Washington St.',
		'SPLC': 328754,
		'LAT': 44.251243,
		'LON': -91.509663
	},
	{
		'X': -84.655069,
		'Y': 33.825798,
		'OBJECTID': 5,
		'TERMINAL': 'Atlanta, GA - Austell',
		'TERM_ADDRE': '6000 Dr. Luke Glenn Garrett, Jr. Memorial Highway',
		'CITY': 'Austell',
		'STATE': 'GA',
		'ZIP_CODE': 30106,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 456496,
		'LAT': 33.825798,
		'LON': -84.655069
	},
	{
		'X': -84.367084,
		'Y': 33.751635,
		'OBJECTID': 6,
		'TERMINAL': 'Atlanta, GA - Hulsey',
		'TERM_ADDRE': '173 Boulvard S.E.',
		'CITY': 'Atlanta',
		'STATE': 'GA',
		'ZIP_CODE': 30312,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 456201,
		'LAT': 33.751635,
		'LON': -84.367084
	},
	{
		'X': -84.439188,
		'Y': 33.793983,
		'OBJECTID': 7,
		'TERMINAL': 'Atlanta, GA - Inman',
		'TERM_ADDRE': '1600 Marietta Road NW, Gate 6',
		'CITY': 'Atlanta',
		'STATE': 'GA',
		'ZIP_CODE': 30318,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 456200,
		'LAT': 33.793983,
		'LON': -84.439188
	},
	{
		'X': -71.592254,
		'Y': 42.542714,
		'OBJECTID': 8,
		'TERMINAL': 'Ayer, MA',
		'TERM_ADDRE': '133 Barnum Road',
		'CITY': 'Ayer',
		'STATE': 'MA',
		'ZIP_CODE': 1432,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS, ST',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 141260,
		'LAT': 42.542714,
		'LON': -71.592254
	},
	{
		'X': -76.554452,
		'Y': 39.292769,
		'OBJECTID': 9,
		'TERMINAL': 'Baltimore, MD',
		'TERM_ADDRE': '4800 East Lombard Street',
		'CITY': 'Baltimore',
		'STATE': 'MD',
		'ZIP_CODE': 21224,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 234004,
		'LAT': 39.292769,
		'LON': -76.554452
	},
	{
		'X': -76.549294,
		'Y': 39.262025,
		'OBJECTID': 10,
		'TERMINAL': 'Baltimore, MD - Seagirt Marine Terminal',
		'TERM_ADDRE': '4801 Keith Avenue',
		'CITY': 'Baltimore',
		'STATE': 'MD',
		'ZIP_CODE': 21224,
		'PORT': 'Port of Baltimore',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Vail St.',
		'SPLC': 234000,
		'LAT': 39.262025,
		'LON': -76.549294
	},
	{
		'X': -86.949675,
		'Y': 33.410012,
		'OBJECTID': 11,
		'TERMINAL': 'Bessemer, AL - Central Alabama ICTF (CAICTF)',
		'TERM_ADDRE': '2401 5th Avenue',
		'CITY': 'North Bessemer',
		'STATE': 'AL',
		'ZIP_CODE': 35020,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 472780,
		'LAT': 33.410012,
		'LON': -86.949675
	},
	{
		'X': -75.333989,
		'Y': 40.603399,
		'OBJECTID': 12,
		'TERMINAL': 'Bethlehem, PA',
		'TERM_ADDRE': '2400 Commerce Center Boulevard',
		'CITY': 'Bethlehem',
		'STATE': 'PA',
		'ZIP_CODE': 18015,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 204212,
		'LAT': 40.603399,
		'LON': -75.333989
	},
	{
		'X': -87.038192,
		'Y': 33.301637,
		'OBJECTID': 13,
		'TERMINAL': 'Birmingham, AL',
		'TERM_ADDRE': '7100 Crescent Way',
		'CITY': 'McCalla',
		'STATE': 'AL',
		'ZIP_CODE': 35111,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 472600,
		'LAT': 33.301637,
		'LON': -87.038192
	},
	{
		'X': -87.673448,
		'Y': 41.662167,
		'OBJECTID': 14,
		'TERMINAL': 'Blue Island, IL',
		'TERM_ADDRE': '2050 Prairie Street',
		'CITY': 'Blue Island',
		'STATE': 'IL',
		'ZIP_CODE': 60406,
		'PORT': '',
		'DATE': '2018/11/04 00:00:00+00',
		'RAIL_COMPA': 'IAIS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'W 119th St.',
		'SPLC': 381380,
		'LAT': 41.662167,
		'LON': -87.673448
	},
	{
		'X': -78.83474,
		'Y': 42.816128,
		'OBJECTID': 15,
		'TERMINAL': 'Buffalo, NY',
		'TERM_ADDRE': '257 Lake Avenue',
		'CITY': 'Blasdell',
		'STATE': 'NY',
		'ZIP_CODE': 14219,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 185400,
		'LAT': 42.816128,
		'LON': -78.83474
	},
	{
		'X': -78.786618,
		'Y': 42.889033,
		'OBJECTID': 16,
		'TERMINAL': 'Buffalo, NY',
		'TERM_ADDRE': '500 Bison Parkway',
		'CITY': 'Buffalo',
		'STATE': 'NY',
		'ZIP_CODE': 14227,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 185400,
		'LAT': 42.889033,
		'LON': -78.786618
	},
	{
		'X': -77.653536,
		'Y': 39.902587,
		'OBJECTID': 17,
		'TERMINAL': 'Chambersburg, PA',
		'TERM_ADDRE': '700 Kriner Road',
		'CITY': 'Chambersburg',
		'STATE': 'PA',
		'ZIP_CODE': 17202,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 209740,
		'LAT': 39.902587,
		'LON': -77.653536
	},
	{
		'X': -79.995399,
		'Y': 32.866511,
		'OBJECTID': 18,
		'TERMINAL': 'Charleston, SC',
		'TERM_ADDRE': '4201 Meeting Street',
		'CITY': 'North Charleston',
		'STATE': 'SC',
		'ZIP_CODE': 29405,
		'PORT': 'Port of Charleston',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 447850,
		'LAT': 32.866511,
		'LON': -79.995399
	},
	{
		'X': -80.89492,
		'Y': 35.274212,
		'OBJECTID': 19,
		'TERMINAL': 'Charlotte, NC',
		'TERM_ADDRE': '5430 Hovis Road',
		'CITY': 'Charlotte',
		'STATE': 'NC',
		'ZIP_CODE': 28208,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 417500,
		'LAT': 35.274212,
		'LON': -80.89492
	},
	{
		'X': -80.957513,
		'Y': 35.196983,
		'OBJECTID': 20,
		'TERMINAL': 'Charlotte, NC',
		'TERM_ADDRE': '5710 West Boulevard',
		'CITY': 'Charlotte',
		'STATE': 'NC',
		'ZIP_CODE': 28208,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Interstate Highway NC-160',
		'SPLC': 417500,
		'LAT': 35.196983,
		'LON': -80.957513
	},
	{
		'X': -87.613143,
		'Y': 41.623307,
		'OBJECTID': 21,
		'TERMINAL': 'Chiacgo, IL - Dolton (Yard Center)',
		'TERM_ADDRE': '147th Street & Indiana Avenue',
		'CITY': 'Dolton',
		'STATE': 'IL',
		'ZIP_CODE': 60419,
		'PORT': '',
		'DATE': '2018/08/05 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 381412,
		'LAT': 41.623307,
		'LON': -87.613143
	},
	{
		'X': -87.91505,
		'Y': 41.899018,
		'OBJECTID': 22,
		'TERMINAL': 'Chiacgo, IL - Northlake (Global II)',
		'TERM_ADDRE': '301 W. Lake Street',
		'CITY': 'Northlake',
		'STATE': 'IL',
		'ZIP_CODE': 60164,
		'PORT': '',
		'DATE': '2018/08/02 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '47th Avenue',
		'SPLC': 381238,
		'LAT': 41.899018,
		'LON': -87.91505
	},
	{
		'X': -87.676976,
		'Y': 41.783835,
		'OBJECTID': 23,
		'TERMINAL': 'Chicago, IL -  59th Street',
		'TERM_ADDRE': '2101 West 59th Street',
		'CITY': 'Chicago',
		'STATE': 'IL',
		'ZIP_CODE': 60636,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 380639,
		'LAT': 41.783835,
		'LON': -87.676976
	},
	{
		'X': -87.793075,
		'Y': 41.764727,
		'OBJECTID': 24,
		'TERMINAL': 'Chicago, IL -  Bedford Park',
		'TERM_ADDRE': '7000 West 71st Street',
		'CITY': 'Bedford Park',
		'STATE': 'IL',
		'ZIP_CODE': 60638,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 381315,
		'LAT': 41.764727,
		'LON': -87.793075
	},
	{
		'X': -87.680974,
		'Y': 41.860547,
		'OBJECTID': 25,
		'TERMINAL': 'Chicago, IL - 14th Street (Global I)',
		'TERM_ADDRE': '1425 South Western Avenue',
		'CITY': 'Chicago',
		'STATE': 'IL',
		'ZIP_CODE': 60608,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 380415,
		'LAT': 41.860547,
		'LON': -87.680974
	},
	{
		'X': -87.63661,
		'Y': 41.805529,
		'OBJECTID': 26,
		'TERMINAL': 'Chicago, IL - 47th Street',
		'TERM_ADDRE': '361 West 47th Street',
		'CITY': 'Chicago',
		'STATE': 'IL',
		'ZIP_CODE': 60609,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 380403,
		'LAT': 41.805529,
		'LON': -87.63661
	},
	{
		'X': -87.618861,
		'Y': 41.777903,
		'OBJECTID': 27,
		'TERMINAL': 'Chicago, IL - 63rd Street',
		'TERM_ADDRE': '169 East 63rd Street',
		'CITY': 'Chicago',
		'STATE': 'IL',
		'ZIP_CODE': 60637,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 380646,
		'LAT': 41.777903,
		'LON': -87.618861
	},
	{
		'X': -87.577341,
		'Y': 41.705694,
		'OBJECTID': 28,
		'TERMINAL': 'Chicago, IL - Calumet',
		'TERM_ADDRE': '2040 East 106th Street',
		'CITY': 'Chicago',
		'STATE': 'IL',
		'ZIP_CODE': 60617,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'South Norfolk Railroad Access Rd.',
		'SPLC': 381443,
		'LAT': 41.705694,
		'LON': -87.577341
	},
	{
		'X': -87.760419,
		'Y': 41.84341,
		'OBJECTID': 29,
		'TERMINAL': 'Chicago, IL - Cicero',
		'TERM_ADDRE': '5601 West 26th Street',
		'CITY': 'Cicero',
		'STATE': 'IL',
		'ZIP_CODE': 60804,
		'PORT': '',
		'DATE': '2018/09/03 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Ogden Ave.; Historic U.S. 66',
		'SPLC': 380000,
		'LAT': 41.84341,
		'LON': -87.760419
	},
	{
		'X': -87.708915,
		'Y': 41.820316,
		'OBJECTID': 30,
		'TERMINAL': 'Chicago, IL - Corwith',
		'TERM_ADDRE': '3526 West 43rd Street',
		'CITY': 'Chicago',
		'STATE': 'IL',
		'ZIP_CODE': 60632,
		'PORT': '',
		'DATE': '2018/09/04 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'South Kedzie Ave.',
		'SPLC': 380000,
		'LAT': 41.820316,
		'LON': -87.708915
	},
	{
		'X': -87.893639,
		'Y': 41.941251,
		'OBJECTID': 31,
		'TERMINAL': 'Chicago, IL - Franklin Park (Bensenville Intermodal Terminal)',
		'TERM_ADDRE': '10800 South Franklin Avenue',
		'CITY': 'Franklin Park',
		'STATE': 'IL',
		'ZIP_CODE': 60131,
		'PORT': '',
		'DATE': '2018/09/08 00:00:00+00',
		'RAIL_COMPA': 'CP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 381229,
		'LAT': 41.941251,
		'LON': -87.893639
	},
	{
		'X': -87.651856,
		'Y': 41.582286,
		'OBJECTID': 32,
		'TERMINAL': 'Chicago, IL - Harvey',
		'TERM_ADDRE': '16800 Center Avenue',
		'CITY': 'Harvey',
		'STATE': 'IL',
		'ZIP_CODE': 60428,
		'PORT': '',
		'DATE': '2018/09/02 00:00:00+00',
		'RAIL_COMPA': 'CN',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'West Avenue',
		'SPLC': 380002,
		'LAT': 41.582286,
		'LON': -87.651856
	},
	{
		'X': -88.115259,
		'Y': 41.46938,
		'OBJECTID': 33,
		'TERMINAL': 'Chicago, IL - Joliet (Global IV)',
		'TERM_ADDRE': '3000 Centerpoint Way',
		'CITY': 'Joliet',
		'STATE': 'IL',
		'ZIP_CODE': 60436,
		'PORT': '',
		'DATE': '2018/08/04 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 384066,
		'LAT': 41.46938,
		'LON': -88.115259
	},
	{
		'X': -87.686656,
		'Y': 41.755706,
		'OBJECTID': 34,
		'TERMINAL': 'Chicago, IL - Landers',
		'TERM_ADDRE': '7540 S. Western Avenue',
		'CITY': 'Chicago',
		'STATE': 'IL',
		'ZIP_CODE': 60620,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 380696,
		'LAT': 41.755706,
		'LON': -87.686656
	},
	{
		'X': -88.148776,
		'Y': 41.400244,
		'OBJECTID': 35,
		'TERMINAL': 'Chicago, IL - Logistics Park Chicago (LPC)',
		'TERM_ADDRE': '26664 Elwood International Port Road',
		'CITY': 'Elwood',
		'STATE': 'IL',
		'ZIP_CODE': 60421,
		'PORT': '',
		'DATE': '2018/09/05 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 384020,
		'LAT': 41.400244,
		'LON': -88.148776
	},
	{
		'X': -89.101932,
		'Y': 41.902382,
		'OBJECTID': 36,
		'TERMINAL': 'Chicago, IL - Rochelle (Global III)',
		'TERM_ADDRE': '2701 Intermodal Drive',
		'CITY': 'Rochelle',
		'STATE': 'IL',
		'ZIP_CODE': 61068,
		'PORT': '',
		'DATE': '2018/08/03 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 382870,
		'LAT': 41.902382,
		'LON': -89.101932
	},
	{
		'X': -87.868998,
		'Y': 41.965481,
		'OBJECTID': 37,
		'TERMINAL': 'Chicago, IL - Schiller Park',
		'TERM_ADDRE': '9665 Lawrence Avenue',
		'CITY': 'Schiller Park',
		'STATE': 'IL',
		'ZIP_CODE': 60176,
		'PORT': '',
		'DATE': '2018/09/09 00:00:00+00',
		'RAIL_COMPA': 'CP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 380000,
		'LAT': 41.965481,
		'LON': -87.868998
	},
	{
		'X': -87.871839,
		'Y': 41.74866,
		'OBJECTID': 38,
		'TERMINAL': 'Chicago, IL - Willow Springs',
		'TERM_ADDRE': '7600 Santa Fe Drive',
		'CITY': 'Hodgkins',
		'STATE': 'IL',
		'ZIP_CODE': 60525,
		'PORT': '',
		'DATE': '2018/09/06 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '75th St.',
		'SPLC': 381299,
		'LAT': 41.74866,
		'LON': -87.871839
	},
	{
		'X': -91.41089,
		'Y': 44.928159,
		'OBJECTID': 39,
		'TERMINAL': 'Chippewa Falls, WI',
		'TERM_ADDRE': '1160 West River Street',
		'CITY': 'Chippewa Falls',
		'STATE': 'WI',
		'ZIP_CODE': 54729,
		'PORT': '',
		'DATE': '2018/09/03 00:00:00+00',
		'RAIL_COMPA': 'CN',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 328180,
		'LAT': 44.928159,
		'LON': -91.41089
	},
	{
		'X': -84.53949,
		'Y': 39.107642,
		'OBJECTID': 40,
		'TERMINAL': 'Cincinnati, OH - Gest Street',
		'TERM_ADDRE': '1276 W. 8th Street',
		'CITY': 'Cincinnati',
		'STATE': 'OH',
		'ZIP_CODE': 45203,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Mclean Avenue',
		'SPLC': 359900,
		'LAT': 39.107642,
		'LON': -84.53949
	},
	{
		'X': -84.538938,
		'Y': 39.121495,
		'OBJECTID': 41,
		'TERMINAL': 'Cincinnati, OH - Queensgate Yard',
		'TERM_ADDRE': '2149 Western Avenue',
		'CITY': 'Cincinnati',
		'STATE': 'OH',
		'ZIP_CODE': 45238,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 359900,
		'LAT': 39.121495,
		'LON': -84.538938
	},
	{
		'X': -84.4219,
		'Y': 39.265949,
		'OBJECTID': 42,
		'TERMINAL': 'Cincinnati, OH - Sharonville',
		'TERM_ADDRE': '3155 E. Sharon Road',
		'CITY': 'Sharonville',
		'STATE': 'OH',
		'ZIP_CODE': 45241,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 359814,
		'LAT': 39.265949,
		'LON': -84.4219
	},
	{
		'X': -81.571393,
		'Y': 41.56574,
		'OBJECTID': 43,
		'TERMINAL': 'Cleveland, OH - Collinwood Yard',
		'TERM_ADDRE': '601 East 152nd Street',
		'CITY': 'Cleveland',
		'STATE': 'OH',
		'ZIP_CODE': 44110,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 341800,
		'LAT': 41.56574,
		'LON': -81.571393
	},
	{
		'X': -80.731105,
		'Y': 28.349469,
		'OBJECTID': 44,
		'TERMINAL': 'Cocoa, FL',
		'TERM_ADDRE': '317 Rosa L. Jones Drive',
		'CITY': 'Cocoa',
		'STATE': 'FL',
		'ZIP_CODE': 32922,
		'PORT': '',
		'DATE': '2018/11/01 00:00:00+00',
		'RAIL_COMPA': 'FEC',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 496169,
		'LAT': 28.349469,
		'LON': -80.731105
	},
	{
		'X': -82.951401,
		'Y': 39.790271,
		'OBJECTID': 45,
		'TERMINAL': 'Columbus, OH',
		'TERM_ADDRE': '3329 Thoroughbred Drive',
		'CITY': 'Columbus',
		'STATE': 'OH',
		'ZIP_CODE': 43217,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 353279,
		'LAT': 39.790271,
		'LON': -82.951401
	},
	{
		'X': -83.126454,
		'Y': 40.001044,
		'OBJECTID': 46,
		'TERMINAL': 'Columbus, OH - Buckeye Yard',
		'TERM_ADDRE': '2351 Westbelt Drive',
		'CITY': 'Columbus',
		'STATE': 'OH',
		'ZIP_CODE': 43228,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 353100,
		'LAT': 40.001044,
		'LON': -83.126454
	},
	{
		'X': -118.150827,
		'Y': 33.992615,
		'OBJECTID': 47,
		'TERMINAL': 'Commerce, CA',
		'TERM_ADDRE': '5600 East 26th Street',
		'CITY': 'Commerce',
		'STATE': 'CA',
		'ZIP_CODE': 90040,
		'PORT': '',
		'DATE': '2018/09/07 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883000,
		'LAT': 33.992615,
		'LON': -118.150827
	},
	{
		'X': -95.83405,
		'Y': 41.234138,
		'OBJECTID': 48,
		'TERMINAL': 'Council Bluffs, IA',
		'TERM_ADDRE': '2722 South Avenue',
		'CITY': 'Council Bluffs',
		'STATE': 'IA',
		'ZIP_CODE': 51503,
		'PORT': '',
		'DATE': '2018/08/06 00:00:00+00',
		'RAIL_COMPA': 'UP, IAIS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 549260,
		'LAT': 41.234138,
		'LON': -95.83405
	},
	{
		'X': -74.06553,
		'Y': 40.755974,
		'OBJECTID': 49,
		'TERMINAL': 'Croxton, NJ',
		'TERM_ADDRE': '125 County Road',
		'CITY': 'Jersey City',
		'STATE': 'NJ',
		'ZIP_CODE': 7307,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 191560,
		'LAT': 40.755974,
		'LON': -74.06553
	},
	{
		'X': -96.653483,
		'Y': 32.781645,
		'OBJECTID': 50,
		'TERMINAL': 'Dallas, TX - Mesquite',
		'TERM_ADDRE': '4100 Forney Road',
		'CITY': 'Mesquite',
		'STATE': 'TX',
		'ZIP_CODE': 75149,
		'PORT': '',
		'DATE': '2018/08/07 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Chase Rd.',
		'SPLC': 667247,
		'LAT': 32.781645,
		'LON': -96.653483
	},
	{
		'X': -96.693485,
		'Y': 32.615525,
		'OBJECTID': 51,
		'TERMINAL': 'Dallas, TX - Wilmer (Dallas Intermodal Terminal)',
		'TERM_ADDRE': '3701 South Interstate 45',
		'CITY': 'Wilmer',
		'STATE': 'TX',
		'ZIP_CODE': 75172,
		'PORT': '',
		'DATE': '2018/08/08 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'South Interstate 45 Service Rd.',
		'SPLC': 667285,
		'LAT': 32.615525,
		'LON': -96.693485
	},
	{
		'X': -88.912284,
		'Y': 39.870851,
		'OBJECTID': 52,
		'TERMINAL': 'Decatur, IL - ADM Intermodal Ramp (Midwest Inland Port)',
		'TERM_ADDRE': '3095 E Parkway Drive',
		'CITY': 'Decatur',
		'STATE': 'IL',
		'ZIP_CODE': 62526,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS, CSX, CN',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 392200,
		'LAT': 39.870851,
		'LON': -88.912284
	},
	{
		'X': -104.962827,
		'Y': 39.773985,
		'OBJECTID': 53,
		'TERMINAL': 'Denver, CO',
		'TERM_ADDRE': '4085 York Street',
		'CITY': 'Denver',
		'STATE': 'CO',
		'ZIP_CODE': 80216,
		'PORT': '',
		'DATE': '2018/08/09 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 744300,
		'LAT': 39.773985,
		'LON': -104.962827
	},
	{
		'X': -104.995374,
		'Y': 39.796963,
		'OBJECTID': 54,
		'TERMINAL': 'Denver, CO - Irondale',
		'TERM_ADDRE': '585 West 53rd Place',
		'CITY': 'Denver',
		'STATE': 'CO',
		'ZIP_CODE': 80216,
		'PORT': '',
		'DATE': '2018/09/08 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 744300,
		'LAT': 39.796963,
		'LON': -104.995374
	},
	{
		'X': -83.119179,
		'Y': 42.317308,
		'OBJECTID': 55,
		'TERMINAL': 'Detroit, MI',
		'TERM_ADDRE': '6750 Dix Street',
		'CITY': 'Detroit',
		'STATE': 'MI',
		'ZIP_CODE': 48209,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Vernor Hwy.',
		'SPLC': 318100,
		'LAT': 42.317308,
		'LON': -83.119179
	},
	{
		'X': -83.12218,
		'Y': 42.297608,
		'OBJECTID': 56,
		'TERMINAL': 'Detroit, MI - Delray',
		'TERM_ADDRE': '8501 Fort Street',
		'CITY': 'Detroit',
		'STATE': 'MI',
		'ZIP_CODE': 48209,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 318338,
		'LAT': 42.297608,
		'LON': -83.12218
	},
	{
		'X': -83.230993,
		'Y': 42.378626,
		'OBJECTID': 57,
		'TERMINAL': 'Detroit, MI - Detroit Intermodal Terminal',
		'TERM_ADDRE': '12594 Westwood Street',
		'CITY': 'Detroit',
		'STATE': 'MI',
		'ZIP_CODE': 48223,
		'PORT': '',
		'DATE': '2018/09/10 00:00:00+00',
		'RAIL_COMPA': 'CP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Fullerton Ave.',
		'SPLC': 318100,
		'LAT': 42.378626,
		'LON': -83.230993
	},
	{
		'X': -83.116799,
		'Y': 42.322324,
		'OBJECTID': 58,
		'TERMINAL': 'Detroit, MI - Livernois',
		'TERM_ADDRE': '2725 Livernois Avenue',
		'CITY': 'Detroit',
		'STATE': 'MI',
		'ZIP_CODE': 48209,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 318138,
		'LAT': 42.322324,
		'LON': -83.116799
	},
	{
		'X': -83.11771,
		'Y': 42.451656,
		'OBJECTID': 59,
		'TERMINAL': 'Detroit, MI - Moterm Intermodal Facility (MOT)',
		'TERM_ADDRE': '923 East 8-Mile Road',
		'CITY': 'Ferndale',
		'STATE': 'MI',
		'ZIP_CODE': 48220,
		'PORT': '',
		'DATE': '2018/09/04 00:00:00+00',
		'RAIL_COMPA': 'CN',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Fern St.',
		'SPLC': 315986,
		'LAT': 42.451656,
		'LON': -83.11771
	},
	{
		'X': -98.090503,
		'Y': 26.179158,
		'OBJECTID': 60,
		'TERMINAL': 'Donna, TX - Rio Valley',
		'TERM_ADDRE': '100 North Whalen Road',
		'CITY': 'Donna',
		'STATE': 'TX',
		'ZIP_CODE': 78537,
		'PORT': '',
		'DATE': '2018/08/10 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 689973,
		'LAT': 26.179158,
		'LON': -98.090503
	},
	{
		'X': -92.098748,
		'Y': 46.758181,
		'OBJECTID': 61,
		'TERMINAL': 'Duluth, MN - Clure Public Marine Terminal',
		'TERM_ADDRE': '1310 Port Terminal Drive',
		'CITY': 'Duluth',
		'STATE': 'MN',
		'ZIP_CODE': 55802,
		'PORT': 'Port of Duluth-Superior',
		'DATE': '2018/09/05 00:00:00+00',
		'RAIL_COMPA': 'CN',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 500900,
		'LAT': 46.758181,
		'LON': -92.098748
	},
	{
		'X': -90.106173,
		'Y': 38.641795,
		'OBJECTID': 62,
		'TERMINAL': 'East St. Louis, IL',
		'TERM_ADDRE': '3900 Rose Lake Road',
		'CITY': 'East St. Louis',
		'STATE': 'IL',
		'ZIP_CODE': 62201,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 396640,
		'LAT': 38.641795,
		'LON': -90.106173
	},
	{
		'X': -106.488887,
		'Y': 31.752085,
		'OBJECTID': 63,
		'TERMINAL': 'El Paso, TX',
		'TERM_ADDRE': '805 South Santa Fe Street',
		'CITY': 'El Paso',
		'STATE': 'TX',
		'ZIP_CODE': 79901,
		'PORT': '',
		'DATE': '2018/09/09 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 696900,
		'LAT': 31.752085,
		'LON': -106.488887
	},
	{
		'X': -74.155955,
		'Y': 40.677882,
		'OBJECTID': 64,
		'TERMINAL': 'Elizabeth Marine Terminal, NJ - ExpressRail (Port Elizabeth)',
		'TERM_ADDRE': '2380 Tripoli Street',
		'CITY': 'Port Elizabeth',
		'STATE': 'NJ',
		'ZIP_CODE': 7201,
		'PORT': 'Port of New York and New Jersey',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Corbin St.; N Fleet St.',
		'SPLC': 191821,
		'LAT': 40.677882,
		'LON': -74.155955
	},
	{
		'X': -74.17603,
		'Y': 40.659354,
		'OBJECTID': 65,
		'TERMINAL': 'Erail, NJ',
		'TERM_ADDRE': '322 Third Street',
		'CITY': 'Elizabeth',
		'STATE': 'NJ',
		'ZIP_CODE': 7206,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Veterans Memorial Dr.',
		'SPLC': 191822,
		'LAT': 40.659354,
		'LON': -74.17603
	},
	{
		'X': -84.602746,
		'Y': 33.54481,
		'OBJECTID': 66,
		'TERMINAL': 'Fairburn, GA',
		'TERM_ADDRE': '6700 McLarin Road',
		'CITY': 'Fairburn',
		'STATE': 'GA',
		'ZIP_CODE': 30213,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 456388,
		'LAT': 33.54481,
		'LON': -84.602746
	},
	{
		'X': -80.139558,
		'Y': 26.085602,
		'OBJECTID': 67,
		'TERMINAL': 'Fort Lauderdale, FL',
		'TERM_ADDRE': '3125 S. Andrews Avenue',
		'CITY': 'Ft. Lauderdale',
		'STATE': 'FL',
		'ZIP_CODE': 33316,
		'PORT': 'Port Everglades',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, FEC',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 499440,
		'LAT': 26.085602,
		'LON': -80.139558
	},
	{
		'X': -80.126074,
		'Y': 26.075485,
		'OBJECTID': 68,
		'TERMINAL': 'Fort Lauderdale, FL - Intermodal Container Transfer Facility (ICTF)',
		'TERM_ADDRE': '1500 Eller Drive',
		'CITY': 'Fort Lauderdale',
		'STATE': 'FL',
		'ZIP_CODE': 33316,
		'PORT': 'Port Everglades',
		'DATE': '2018/11/02 00:00:00+00',
		'RAIL_COMPA': 'FEC',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 499440,
		'LAT': 26.075485,
		'LON': -80.126074
	},
	{
		'X': -80.32299,
		'Y': 27.439775,
		'OBJECTID': 69,
		'TERMINAL': 'Fort Pierce, FL',
		'TERM_ADDRE': '353 Florida Avenue',
		'CITY': 'Ft. Pierce',
		'STATE': 'FL',
		'ZIP_CODE': 34950,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, FEC',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Indian Hill Dr.',
		'SPLC': 496840,
		'LAT': 27.439775,
		'LON': -80.32299
	},
	{
		'X': -78.176458,
		'Y': 38.985865,
		'OBJECTID': 70,
		'TERMINAL': 'Front Royal, VA - Virginia Inland Port (VIP)',
		'TERM_ADDRE': 'Route 522 & 344 North',
		'CITY': 'Front Royal',
		'STATE': 'VA',
		'ZIP_CODE': 22630,
		'PORT': 'Port of Virginia',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Winchester Rd.',
		'SPLC': 253410,
		'LAT': 38.985865,
		'LON': -78.176458
	},
	{
		'X': -84.549134,
		'Y': 38.271637,
		'OBJECTID': 71,
		'TERMINAL': 'Georgetown, KY',
		'TERM_ADDRE': '601 Cherry Blossom Way',
		'CITY': 'Georgetown',
		'STATE': 'KY',
		'ZIP_CODE': 40324,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 285582,
		'LAT': 38.271637,
		'LON': -84.549134
	},
	{
		'X': -77.740147,
		'Y': 39.759564,
		'OBJECTID': 72,
		'TERMINAL': 'Greencastle, PA - Franklin County Regional Intermodal Facility',
		'TERM_ADDRE': '612 Antrim Commons Drive',
		'CITY': 'Greencastle',
		'STATE': 'PA',
		'ZIP_CODE': 17225,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 209730,
		'LAT': 39.759564,
		'LON': -77.740147
	},
	{
		'X': -79.853165,
		'Y': 36.057001,
		'OBJECTID': 73,
		'TERMINAL': 'Greensboro, NC',
		'TERM_ADDRE': '1105 Merritt Drive',
		'CITY': 'Greensboro',
		'STATE': 'NC',
		'ZIP_CODE': 27407,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 412750,
		'LAT': 36.057001,
		'LON': -79.853165
	},
	{
		'X': -76.892941,
		'Y': 40.310366,
		'OBJECTID': 74,
		'TERMINAL': 'Harrisburg, PA',
		'TERM_ADDRE': '3500 Industrial Road',
		'CITY': 'Harrisburg',
		'STATE': 'PA',
		'ZIP_CODE': 17110,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 206260,
		'LAT': 40.310366,
		'LON': -76.892941
	},
	{
		'X': -95.317413,
		'Y': 29.786553,
		'OBJECTID': 75,
		'TERMINAL': 'Houston, TX - Englewood (Wallisville Rd)',
		'TERM_ADDRE': '5500 Wallisville Road',
		'CITY': 'Houston',
		'STATE': 'TX',
		'ZIP_CODE': 77020,
		'PORT': '',
		'DATE': '2018/08/12 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 684807,
		'LAT': 29.786553,
		'LON': -95.317413
	},
	{
		'X': -95.98128,
		'Y': 29.459957,
		'OBJECTID': 76,
		'TERMINAL': 'Houston, TX - Kendleton',
		'TERM_ADDRE': '11538 Gin Road',
		'CITY': 'Beasley',
		'STATE': 'TX',
		'ZIP_CODE': 77417,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'KCS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'W Tavener Rd.',
		'SPLC': 686478,
		'LAT': 29.459957,
		'LON': -95.98128
	},
	{
		'X': -95.004574,
		'Y': 29.67558,
		'OBJECTID': 77,
		'TERMINAL': 'Houston, TX - La Porte (Barbours Cut)',
		'TERM_ADDRE': '515 East Barbours Cut Boulevard',
		'CITY': 'La Porte',
		'STATE': 'TX',
		'ZIP_CODE': 77571,
		'PORT': 'Port of Houston',
		'DATE': '2018/08/13 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 684815,
		'LAT': 29.67558,
		'LON': -95.004574
	},
	{
		'X': -95.292245,
		'Y': 29.638421,
		'OBJECTID': 78,
		'TERMINAL': 'Houston, TX - Pearland',
		'TERM_ADDRE': '214 Brisbane Street',
		'CITY': 'Houston',
		'STATE': 'TX',
		'ZIP_CODE': 77061,
		'PORT': '',
		'DATE': '2018/09/10 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 684800,
		'LAT': 29.638421,
		'LON': -95.292245
	},
	{
		'X': -95.291541,
		'Y': 29.812494,
		'OBJECTID': 79,
		'TERMINAL': 'Houston, TX - Settegast (Kirkpatrick, Blvd)',
		'TERM_ADDRE': '6800 Kirkpatrick Boulevard',
		'CITY': 'Houston',
		'STATE': 'TX',
		'ZIP_CODE': 77028,
		'PORT': '',
		'DATE': '2018/08/11 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 684800,
		'LAT': 29.812494,
		'LON': -95.291541
	},
	{
		'X': -86.760675,
		'Y': 34.641352,
		'OBJECTID': 80,
		'TERMINAL': 'Huntsville, AL',
		'TERM_ADDRE': '2850 Wall Triana Highway SW',
		'CITY': 'Huntsville',
		'STATE': 'AL',
		'ZIP_CODE': 35824,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 471340,
		'LAT': 34.641352,
		'LON': -86.760675
	},
	{
		'X': -86.16636,
		'Y': 39.747942,
		'OBJECTID': 81,
		'TERMINAL': 'Indianapolis, IN',
		'TERM_ADDRE': '1585 South Senate Avenue',
		'CITY': 'Indianapolis',
		'STATE': 'IN',
		'ZIP_CODE': 46225,
		'PORT': '',
		'DATE': '2018/09/08 00:00:00+00',
		'RAIL_COMPA': 'CN, INRD',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Wisconsin St.',
		'SPLC': 368700,
		'LAT': 39.747942,
		'LON': -86.16636
	},
	{
		'X': -86.372963,
		'Y': 39.756377,
		'OBJECTID': 82,
		'TERMINAL': 'Indianapolis, IN - Plainfield Intermodal Terminal',
		'TERM_ADDRE': '501 South County Road 800 E.',
		'CITY': 'Avon',
		'STATE': 'IN',
		'ZIP_CODE': 46123,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Dan Jones Rd.; Main Rd.',
		'SPLC': 368700,
		'LAT': 39.756377,
		'LON': -86.372963
	},
	{
		'X': -90.151546,
		'Y': 32.256403,
		'OBJECTID': 83,
		'TERMINAL': 'Jackson, MS',
		'TERM_ADDRE': '360 Industrial Road',
		'CITY': 'Richland',
		'STATE': 'MS',
		'ZIP_CODE': 39218,
		'PORT': '',
		'DATE': '2018/08/04 00:00:00+00',
		'RAIL_COMPA': 'KCS, CN',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Industrial Park Drive',
		'SPLC': 487230,
		'LAT': 32.256403,
		'LON': -90.151546
	},
	{
		'X': -81.7656,
		'Y': 30.387001,
		'OBJECTID': 84,
		'TERMINAL': 'Jacksonville, FL',
		'TERM_ADDRE': '5902 Sportsman Club Road',
		'CITY': 'Jacksonville',
		'STATE': 'FL',
		'ZIP_CODE': 32219,
		'PORT': 'Port of Jacksonville',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 491200,
		'LAT': 30.387001,
		'LON': -81.7656
	},
	{
		'X': -81.730413,
		'Y': 30.368977,
		'OBJECTID': 85,
		'TERMINAL': 'Jacksonville, FL',
		'TERM_ADDRE': '6098 Soutel Drive',
		'CITY': 'Jacksonville',
		'STATE': 'FL',
		'ZIP_CODE': 32219,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Soutel Access Rd. S.; N Edgewood Dr.',
		'SPLC': 419200,
		'LAT': 30.368977,
		'LON': -81.730413
	},
	{
		'X': -81.613927,
		'Y': 30.257438,
		'OBJECTID': 86,
		'TERMINAL': 'Jacksonville, FL - Fowden Intermodal Terminal',
		'TERM_ADDRE': '6140 Philips Highway',
		'CITY': 'Jacksonville',
		'STATE': 'FL',
		'ZIP_CODE': 32216,
		'PORT': '',
		'DATE': '2018/11/03 00:00:00+00',
		'RAIL_COMPA': 'FEC',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 491200,
		'LAT': 30.257438,
		'LON': -81.613927
	},
	{
		'X': -81.571033,
		'Y': 30.401564,
		'OBJECTID': 87,
		'TERMINAL': 'Jacksonville, FL - JaxPort ICTF',
		'TERM_ADDRE': '9600 New Berlin Road',
		'CITY': 'Jacksonville',
		'STATE': 'FL',
		'ZIP_CODE': 32226,
		'PORT': 'Port of Jacksonville',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 491200,
		'LAT': 30.401564,
		'LON': -81.571033
	},
	{
		'X': -88.057364,
		'Y': 41.538605,
		'OBJECTID': 88,
		'TERMINAL': 'Joliet, IL',
		'TERM_ADDRE': '785 Draper Avenue',
		'CITY': 'Joliet',
		'STATE': 'IL',
		'ZIP_CODE': 60432,
		'PORT': '',
		'DATE': '2018/09/06 00:00:00+00',
		'RAIL_COMPA': 'CN',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 384040,
		'LAT': 41.538605,
		'LON': -88.057364
	},
	{
		'X': -94.959619,
		'Y': 38.786526,
		'OBJECTID': 89,
		'TERMINAL': 'Kansas City - Logistics Park Kansas City (LPKC)',
		'TERM_ADDRE': '32880 West 191 Street',
		'CITY': 'Edgerton',
		'STATE': 'KS',
		'ZIP_CODE': 66021,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 584092,
		'LAT': 38.786526,
		'LON': -94.959619
	},
	{
		'X': -94.43502,
		'Y': 39.178288,
		'OBJECTID': 90,
		'TERMINAL': 'Kansas City, MO',
		'TERM_ADDRE': '4800 North Kimball Drive',
		'CITY': 'Kansas City',
		'STATE': 'MO',
		'ZIP_CODE': 64161,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Bobtail Lane',
		'SPLC': 566793,
		'LAT': 39.178288,
		'LON': -94.43502
	},
	{
		'X': -94.524913,
		'Y': 39.125981,
		'OBJECTID': 91,
		'TERMINAL': 'Kansas City, MO',
		'TERM_ADDRE': '4801 Gardner Avenue',
		'CITY': 'Kansas City',
		'STATE': 'MO',
		'ZIP_CODE': 64120,
		'PORT': '',
		'DATE': '2018/08/14 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 566900,
		'LAT': 39.125981,
		'LON': -94.524913
	},
	{
		'X': -94.55765,
		'Y': 38.855751,
		'OBJECTID': 92,
		'TERMINAL': 'Kansas City, MO - International Freight Gateway (IFG)',
		'TERM_ADDRE': '3001 East 150 Hwy',
		'CITY': 'Kansas City',
		'STATE': 'MO',
		'ZIP_CODE': 64147,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, KCS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Thunderbird Rd.',
		'SPLC': 566907,
		'LAT': 38.855751,
		'LON': -94.55765
	},
	{
		'X': -74.098216,
		'Y': 40.740286,
		'OBJECTID': 93,
		'TERMINAL': 'Kearny, NJ',
		'TERM_ADDRE': '700 Old Fish House Road',
		'CITY': 'Kearny',
		'STATE': 'NJ',
		'ZIP_CODE': 7032,
		'PORT': 'Port of New York and New Jersey',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Pennsylvania Ave.',
		'SPLC': 191488,
		'LAT': 40.740286,
		'LON': -74.098216
	},
	{
		'X': -99.39003,
		'Y': 27.4965,
		'OBJECTID': 94,
		'TERMINAL': 'Laredo, TX',
		'TERM_ADDRE': '604 Serrano Road',
		'CITY': 'Laredo',
		'STATE': 'TX',
		'ZIP_CODE': 78046,
		'PORT': '',
		'DATE': '2018/08/02 00:00:00+00',
		'RAIL_COMPA': 'KCS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 699380,
		'LAT': 27.4965,
		'LON': -99.39003
	},
	{
		'X': -99.46248,
		'Y': 27.665074,
		'OBJECTID': 95,
		'TERMINAL': 'Laredo, TX',
		'TERM_ADDRE': 'Interstate Highway 35, North Mile Marker #12',
		'CITY': 'Laredo',
		'STATE': 'TX',
		'ZIP_CODE': 78041,
		'PORT': '',
		'DATE': '2018/08/17 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Interstate 35 Frontage Rd.',
		'SPLC': 699378,
		'LAT': 27.665074,
		'LON': -99.46248
	},
	{
		'X': -115.070534,
		'Y': 36.273183,
		'OBJECTID': 96,
		'TERMINAL': 'Las Vegas, NV',
		'TERM_ADDRE': '4740 Tropical Parkway',
		'CITY': 'Las Vegas',
		'STATE': 'NV',
		'ZIP_CODE': 89030,
		'PORT': '',
		'DATE': '2018/08/16 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 869150,
		'LAT': 36.273183,
		'LON': -115.070534
	},
	{
		'X': -121.261799,
		'Y': 37.836535,
		'OBJECTID': 97,
		'TERMINAL': 'Lathrop, CA',
		'TERM_ADDRE': '1000 E. Roth Road',
		'CITY': 'French Camp',
		'STATE': 'CA',
		'ZIP_CODE': 95231,
		'PORT': '',
		'DATE': '2018/08/15 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 875670,
		'LAT': 37.836535,
		'LON': -121.261799
	},
	{
		'X': -74.02336,
		'Y': 40.825313,
		'OBJECTID': 98,
		'TERMINAL': 'Little Ferry, NJ',
		'TERM_ADDRE': '2200 83rd Street',
		'CITY': 'North Bergen',
		'STATE': 'NJ',
		'ZIP_CODE': 7047,
		'PORT': 'Port of New York and New Jersey',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 191268,
		'LAT': 40.825313,
		'LON': -74.02336
	},
	{
		'X': -118.224696,
		'Y': 33.807384,
		'OBJECTID': 99,
		'TERMINAL': 'Long Beach, CA - ICTF',
		'TERM_ADDRE': '2401 E. Sepulveda Boulevard',
		'CITY': 'Long Beach',
		'STATE': 'CA',
		'ZIP_CODE': 90810,
		'PORT': 'Port of Los Angeles/Long Beach',
		'DATE': '2018/08/21 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883716,
		'LAT': 33.807384,
		'LON': -118.224696
	},
	{
		'X': -118.191262,
		'Y': 33.745442,
		'OBJECTID': 100,
		'TERMINAL': 'Long Beach, CA - International Transportation Service (ITS/Pier G)',
		'TERM_ADDRE': '1281 Pier G Way',
		'CITY': 'Long Beach',
		'STATE': 'CA',
		'ZIP_CODE': 90802,
		'PORT': 'Port of Long Beach',
		'DATE': '2018/12/11 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883710,
		'LAT': 33.745442,
		'LON': -118.191262
	},
	{
		'X': -118.211477,
		'Y': 33.766286,
		'OBJECTID': 101,
		'TERMINAL': 'Long Beach, CA - Long Beach Container Terminal (LBCT/Pier E)',
		'TERM_ADDRE': '201 S. Pico Avenue',
		'CITY': 'Long Beach',
		'STATE': 'CA',
		'ZIP_CODE': 90802,
		'PORT': 'Port of Long Beach',
		'DATE': '2018/12/12 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883733,
		'LAT': 33.766286,
		'LON': -118.211477
	},
	{
		'X': -118.189885,
		'Y': 33.740613,
		'OBJECTID': 102,
		'TERMINAL': 'Long Beach, CA - Pacific Container Terminal (PCT/Pier J)',
		'TERM_ADDRE': '1521 Pier J Avenue',
		'CITY': 'Long Beach',
		'STATE': 'CA',
		'ZIP_CODE': 90802,
		'PORT': 'Port of Long Beach',
		'DATE': '2018/12/13 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883209,
		'LAT': 33.740613,
		'LON': -118.189885
	},
	{
		'X': -118.237632,
		'Y': 33.772376,
		'OBJECTID': 103,
		'TERMINAL': 'Long Beach, CA - SSA Terminals (Pier A)',
		'TERM_ADDRE': '700 Pier A Plaza',
		'CITY': 'Long Beach',
		'STATE': 'CA',
		'ZIP_CODE': 90813,
		'PORT': 'Port of Long Beach',
		'DATE': '2018/12/14 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883743,
		'LAT': 33.772376,
		'LON': -118.237632
	},
	{
		'X': -117.93297,
		'Y': 34.008741,
		'OBJECTID': 104,
		'TERMINAL': 'Los Angeles, CA - City of Industry',
		'TERM_ADDRE': '17225 Arenth Avenue',
		'CITY': 'City of Industry',
		'STATE': 'CA',
		'ZIP_CODE': 91748,
		'PORT': '',
		'DATE': '2018/08/20 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883619,
		'LAT': 34.008741,
		'LON': -117.93297
	},
	{
		'X': -118.186625,
		'Y': 34.009885,
		'OBJECTID': 105,
		'TERMINAL': 'Los Angeles, CA - East Washington Blvd',
		'TERM_ADDRE': '4341 East Washington Boulevard',
		'CITY': 'City of Commerce',
		'STATE': 'CA',
		'ZIP_CODE': 90023,
		'PORT': '',
		'DATE': '2018/08/18 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883000,
		'LAT': 34.009885,
		'LON': -118.186625
	},
	{
		'X': -118.190282,
		'Y': 34.009538,
		'OBJECTID': 106,
		'TERMINAL': 'Los Angeles, CA - Hobart',
		'TERM_ADDRE': '4000 East Sheila Street',
		'CITY': 'Los Angeles',
		'STATE': 'CA',
		'ZIP_CODE': 90023,
		'PORT': 'Port of Los Angeles/Long Beach',
		'DATE': '2018/09/12 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883000,
		'LAT': 34.009538,
		'LON': -118.190282
	},
	{
		'X': -118.224276,
		'Y': 34.059189,
		'OBJECTID': 107,
		'TERMINAL': 'Los Angeles, CA - Lamar St (LATC)',
		'TERM_ADDRE': '750 Lamar Street',
		'CITY': 'Los Angeles',
		'STATE': 'CA',
		'ZIP_CODE': 90031,
		'PORT': 'Port of Los Angeles/Long Beach',
		'DATE': '2018/08/19 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883205,
		'LAT': 34.059189,
		'LON': -118.224276
	},
	{
		'X': -118.254065,
		'Y': 33.756039,
		'OBJECTID': 108,
		'TERMINAL': 'Los Angeles, CA - Terminal Island Container Transfer Facility (TICTF)',
		'TERM_ADDRE': '701 New Dock Street',
		'CITY': 'Terminal Island',
		'STATE': 'CA',
		'ZIP_CODE': 90731,
		'PORT': 'Port of Los Angeles',
		'DATE': '2018/12/08 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883213,
		'LAT': 33.756039,
		'LON': -118.254065
	},
	{
		'X': -85.743366,
		'Y': 38.137586,
		'OBJECTID': 109,
		'TERMINAL': 'Louisville, KY',
		'TERM_ADDRE': '8021 National Turnpike Road',
		'CITY': 'Louisville',
		'STATE': 'KY',
		'ZIP_CODE': 40214,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 286550,
		'LAT': 38.137586,
		'LON': -85.743366
	},
	{
		'X': -85.656383,
		'Y': 38.182269,
		'OBJECTID': 110,
		'TERMINAL': 'Louisville, KY - Appliance Park',
		'TERM_ADDRE': '4913 Heller Street',
		'CITY': 'Louisville',
		'STATE': 'KY',
		'ZIP_CODE': 40218,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 286557,
		'LAT': 38.182269,
		'LON': -85.656383
	},
	{
		'X': -85.682558,
		'Y': 38.186729,
		'OBJECTID': 111,
		'TERMINAL': 'Louisville, KY - Buechel',
		'TERM_ADDRE': '4705 Jennings Lane',
		'CITY': 'Louisville',
		'STATE': 'KY',
		'ZIP_CODE': 40218,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 286555,
		'LAT': 38.186729,
		'LON': -85.682558
	},
	{
		'X': -90.250134,
		'Y': 35.193044,
		'OBJECTID': 112,
		'TERMINAL': 'Marion, AR',
		'TERM_ADDRE': '5500 Kuhn Road',
		'CITY': 'Marion',
		'STATE': 'AR',
		'ZIP_CODE': 72364,
		'PORT': '',
		'DATE': '2018/08/22 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 605153,
		'LAT': 35.193044,
		'LON': -90.250134
	},
	{
		'X': -83.057637,
		'Y': 40.602324,
		'OBJECTID': 113,
		'TERMINAL': 'Marion, OH - Ohio Valley Intermodal Facility',
		'TERM_ADDRE': '3001 Harding Highway East',
		'CITY': 'Marion',
		'STATE': 'OH',
		'ZIP_CODE': 43302,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, KCS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 348540,
		'LAT': 40.602324,
		'LON': -83.057637
	},
	{
		'X': -89.898348,
		'Y': 35.023417,
		'OBJECTID': 114,
		'TERMINAL': 'Memphis, TN',
		'TERM_ADDRE': '4814 Lamar Avenue',
		'CITY': 'Memphis',
		'STATE': 'TN',
		'ZIP_CODE': 38118,
		'PORT': '',
		'DATE': '2018/09/13 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 439900,
		'LAT': 35.023417,
		'LON': -89.898348
	},
	{
		'X': -90.153006,
		'Y': 35.044849,
		'OBJECTID': 115,
		'TERMINAL': 'Memphis, TN',
		'TERM_ADDRE': '3588 Paul R.Lowry Road',
		'CITY': 'Memphis',
		'STATE': 'TN',
		'ZIP_CODE': 38109,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, CN',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Riverport Rd.',
		'SPLC': 439875,
		'LAT': 35.044849,
		'LON': -90.153006
	},
	{
		'X': -89.579726,
		'Y': 35.01392,
		'OBJECTID': 116,
		'TERMINAL': 'Memphis, TN - Rossville',
		'TERM_ADDRE': '3000 Norfolk Southern Way',
		'CITY': 'Rossville',
		'STATE': 'TN',
		'ZIP_CODE': 38066,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 439790,
		'LAT': 35.01392,
		'LON': -89.579726
	},
	{
		'X': -80.30674,
		'Y': 25.833372,
		'OBJECTID': 117,
		'TERMINAL': 'Miami, FL',
		'TERM_ADDRE': '7300 N.W. 69th Avenue',
		'CITY': 'Miami Springs',
		'STATE': 'FL',
		'ZIP_CODE': 33166,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, FEC',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 499600,
		'LAT': 25.833372,
		'LON': -80.30674
	},
	{
		'X': -80.163483,
		'Y': 25.771088,
		'OBJECTID': 118,
		'TERMINAL': 'Miami, FL - Port of Miami Terminal Operating Company (POMTOC)',
		'TERM_ADDRE': '635 Australia Way',
		'CITY': 'Miami',
		'STATE': 'FL',
		'ZIP_CODE': 33132,
		'PORT': 'Port of Miami',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, FEC',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Port Blvd.',
		'SPLC': 499616,
		'LAT': 25.771088,
		'LON': -80.163483
	},
	{
		'X': -93.256901,
		'Y': 45.020417,
		'OBJECTID': 119,
		'TERMINAL': 'Minneapolis, MN - Shoreham Yard',
		'TERM_ADDRE': '615 – 30th Avenue N.E.',
		'CITY': 'Minneapolis',
		'STATE': 'MN',
		'ZIP_CODE': 55418,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 504690,
		'LAT': 45.020417,
		'LON': -93.256901
	},
	{
		'X': -74.846553,
		'Y': 40.192008,
		'OBJECTID': 120,
		'TERMINAL': 'Morrisville, PA',
		'TERM_ADDRE': '98 Cabot Boulevard',
		'CITY': 'East Langhorne',
		'STATE': 'PA',
		'ZIP_CODE': 19047,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 207310,
		'LAT': 40.192008,
		'LON': -74.846553
	},
	{
		'X': -86.760275,
		'Y': 36.090802,
		'OBJECTID': 121,
		'TERMINAL': 'Nashville, TN',
		'TERM_ADDRE': '3086 Sidco Drive',
		'CITY': 'Nashville',
		'STATE': 'TN',
		'ZIP_CODE': 37204,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 434300,
		'LAT': 36.090802,
		'LON': -86.760275
	},
	{
		'X': -90.15564,
		'Y': 29.926148,
		'OBJECTID': 122,
		'TERMINAL': 'New Orleans, LA',
		'TERM_ADDRE': '539 Bridge City Avenue',
		'CITY': 'Westwego',
		'STATE': 'LA',
		'ZIP_CODE': 70094,
		'PORT': '',
		'DATE': '2018/09/14 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Seven Oaks Rd.',
		'SPLC': 647000,
		'LAT': 29.926148,
		'LON': -90.15564
	},
	{
		'X': -90.199593,
		'Y': 29.971143,
		'OBJECTID': 123,
		'TERMINAL': 'New Orleans, LA',
		'TERM_ADDRE': '2351 Hickory Avenue',
		'CITY': 'Harahan',
		'STATE': 'LA',
		'ZIP_CODE': 70123,
		'PORT': 'Port of New Orleans',
		'DATE': '2018/09/07 00:00:00+00',
		'RAIL_COMPA': 'CN',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Lead St.',
		'SPLC': 646505,
		'LAT': 29.971143,
		'LON': -90.199593
	},
	{
		'X': -90.043027,
		'Y': 29.984347,
		'OBJECTID': 124,
		'TERMINAL': 'New Orleans, LA',
		'TERM_ADDRE': '2900 Florida Avenue',
		'CITY': 'New Orleans',
		'STATE': 'LA',
		'ZIP_CODE': 70117,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 647000,
		'LAT': 29.984347,
		'LON': -90.043027
	},
	{
		'X': -90.205272,
		'Y': 29.917836,
		'OBJECTID': 125,
		'TERMINAL': 'New Orleans, LA',
		'TERM_ADDRE': '100 Avondale Garden Road',
		'CITY': 'Avondale',
		'STATE': 'LA',
		'ZIP_CODE': 70094,
		'PORT': '',
		'DATE': '2018/08/23 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 646528,
		'LAT': 29.917836,
		'LON': -90.205272
	},
	{
		'X': -74.186455,
		'Y': 40.637148,
		'OBJECTID': 126,
		'TERMINAL': 'New York Container Terminal, NY - ExpressRail (Staten Island)',
		'TERM_ADDRE': '300 Western Avenue',
		'CITY': 'Staten Island',
		'STATE': 'NY',
		'ZIP_CODE': 10303,
		'PORT': 'Port of New York and New Jersey',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 178964,
		'LAT': 40.637148,
		'LON': -74.186455
	},
	{
		'X': -76.320643,
		'Y': 36.915037,
		'OBJECTID': 127,
		'TERMINAL': 'Norfolk, VA - Norfolk International Terminals (NIT)',
		'TERM_ADDRE': '7737 Hampton Boulevard',
		'CITY': 'Norfolk',
		'STATE': 'VA',
		'ZIP_CODE': 23505,
		'PORT': 'Port of Virginia',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Terminal Blvd.',
		'SPLC': 261004,
		'LAT': 36.915037,
		'LON': -76.320643
	},
	{
		'X': -76.26445,
		'Y': 36.802166,
		'OBJECTID': 128,
		'TERMINAL': 'Norfolk, VA - Portlock',
		'TERM_ADDRE': '1710 Atlantic Avenue',
		'CITY': 'Chesapeake',
		'STATE': 'VA',
		'ZIP_CODE': 23324,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Narrow St.',
		'SPLC': 261206,
		'LAT': 36.802166,
		'LON': -76.26445
	},
	{
		'X': -76.329789,
		'Y': 36.854061,
		'OBJECTID': 129,
		'TERMINAL': 'Norfolk, VA - Portsmouth Marine Terminal (PMT)',
		'TERM_ADDRE': 'Intersection of Cleveland Street and Lee Avenue',
		'CITY': 'Portsmouth',
		'STATE': 'VA',
		'ZIP_CODE': 23707,
		'PORT': 'Port of Virginia',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Hill Rd.',
		'SPLC': 261100,
		'LAT': 36.854061,
		'LON': -76.329789
	},
	{
		'X': -76.355415,
		'Y': 36.87166,
		'OBJECTID': 130,
		'TERMINAL': 'Norfolk, VA - Virginia International Gateway (VIG)',
		'TERM_ADDRE': '1000 APM Terminal Boulevard',
		'CITY': 'Portsmouth',
		'STATE': 'VA',
		'ZIP_CODE': 23703,
		'PORT': 'Port of Virginia',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Virginia International Gateway Blvd.',
		'SPLC': 261121,
		'LAT': 36.87166,
		'LON': -76.355415
	},
	{
		'X': -83.736999,
		'Y': 41.184923,
		'OBJECTID': 131,
		'TERMINAL': 'North Baltimore, OH - Northwest Ohio ICTF',
		'TERM_ADDRE': '17000 Deshler Road',
		'CITY': 'North Baltimore',
		'STATE': 'OH',
		'ZIP_CODE': 45872,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Wingston Rd.',
		'SPLC': 343689,
		'LAT': 41.184923,
		'LON': -83.736999
	},
	{
		'X': -74.027197,
		'Y': 40.796149,
		'OBJECTID': 132,
		'TERMINAL': 'North Bergen, NJ',
		'TERM_ADDRE': '6201 Tonnelle Avenue',
		'CITY': 'North Bergen',
		'STATE': 'NJ',
		'ZIP_CODE': 7047,
		'PORT': 'Port of New York and New Jersey',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 191410,
		'LAT': 40.796149,
		'LON': -74.027197
	},
	{
		'X': -122.300931,
		'Y': 37.799871,
		'OBJECTID': 133,
		'TERMINAL': 'Oakland, CA',
		'TERM_ADDRE': '1408 Middle Harbor Road',
		'CITY': 'Oakland',
		'STATE': 'CA',
		'ZIP_CODE': 94607,
		'PORT': 'Port of Oakland',
		'DATE': '2018/08/24 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Adeline St.',
		'SPLC': 876430,
		'LAT': 37.799871,
		'LON': -122.300931
	},
	{
		'X': -122.312138,
		'Y': 37.803528,
		'OBJECTID': 134,
		'TERMINAL': 'Oakland, CA - Oakland International Gateway (OIG)',
		'TERM_ADDRE': '333 Maritime Street',
		'CITY': 'Oakland',
		'STATE': 'CA',
		'ZIP_CODE': 94607,
		'PORT': 'Port of Oakland',
		'DATE': '2018/09/15 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 876411,
		'LAT': 37.803528,
		'LON': -122.312138
	},
	{
		'X': -95.92925,
		'Y': 41.217681,
		'OBJECTID': 135,
		'TERMINAL': 'Omaha, NE',
		'TERM_ADDRE': '4370 Gibson Road',
		'CITY': 'Omaha',
		'STATE': 'NE',
		'ZIP_CODE': 68107,
		'PORT': '',
		'DATE': '2018/09/16 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 553200,
		'LAT': 41.217681,
		'LON': -95.92925
	},
	{
		'X': -75.143453,
		'Y': 39.900933,
		'OBJECTID': 136,
		'TERMINAL': 'Philadelphia, PA - Greenwhich',
		'TERM_ADDRE': '3400 S. Christopher Columbus Boulevard',
		'CITY': 'Philadelphia',
		'STATE': 'PA',
		'ZIP_CODE': 19148,
		'PORT': 'Port of Philadelphia',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, CP, NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'South Delaware Ave.',
		'SPLC': 207800,
		'LAT': 39.900933,
		'LON': -75.143453
	},
	{
		'X': -112.160564,
		'Y': 33.515564,
		'OBJECTID': 137,
		'TERMINAL': 'Phoenix, AZ - Glendale',
		'TERM_ADDRE': '5281 North Tom Murray Avenue',
		'CITY': 'Glendale',
		'STATE': 'AZ',
		'ZIP_CODE': 85301,
		'PORT': '',
		'DATE': '2018/09/17 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 797000,
		'LAT': 33.515564,
		'LON': -112.160564
	},
	{
		'X': -79.776842,
		'Y': 40.397228,
		'OBJECTID': 138,
		'TERMINAL': 'Pittsburgh, PA',
		'TERM_ADDRE': '701 Wall Avenue',
		'CITY': 'Wall',
		'STATE': 'PA',
		'ZIP_CODE': 15148,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 218317,
		'LAT': 40.397228,
		'LON': -79.776842
	},
	{
		'X': -80.062307,
		'Y': 40.476707,
		'OBJECTID': 139,
		'TERMINAL': 'Pittsburgh, PA - McKees Rocks',
		'TERM_ADDRE': '1000 Intermodal Way',
		'CITY': 'McKees Rocks',
		'STATE': 'PA',
		'ZIP_CODE': 15136,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 218714,
		'LAT': 40.476707,
		'LON': -80.062307
	},
	{
		'X': -74.152375,
		'Y': 40.690263,
		'OBJECTID': 140,
		'TERMINAL': 'Port Newark Container Terminal, NJ - ExpressRail (Newark)',
		'TERM_ADDRE': '241 Calcutta Street',
		'CITY': 'Newark',
		'STATE': 'NJ',
		'ZIP_CODE': 7114,
		'PORT': 'Port of New York and New Jersey',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 191651,
		'LAT': 40.690263,
		'LON': -74.152375
	},
	{
		'X': -122.646458,
		'Y': 45.487224,
		'OBJECTID': 141,
		'TERMINAL': 'Portland, OR - Brooklyn',
		'TERM_ADDRE': '1862 S.E. Holgate Boulevard',
		'CITY': 'Portland',
		'STATE': 'OR',
		'ZIP_CODE': 97202,
		'PORT': 'Port of Portland',
		'DATE': '2018/08/25 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 853489,
		'LAT': 45.487224,
		'LON': -122.646458
	},
	{
		'X': -122.722648,
		'Y': 45.552207,
		'OBJECTID': 142,
		'TERMINAL': 'Portland, OR - Terminal 2 (Guilds Lake)',
		'TERM_ADDRE': '3930 N.W. Yeon Road',
		'CITY': 'Portland',
		'STATE': 'OR',
		'ZIP_CODE': 97210,
		'PORT': 'Port of Portland',
		'DATE': '2018/09/28 00:00:00+00',
		'RAIL_COMPA': 'BNSF, UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 853470,
		'LAT': 45.552207,
		'LON': -122.722648
	},
	{
		'X': -122.75194,
		'Y': 45.638961,
		'OBJECTID': 143,
		'TERMINAL': 'Portland, OR - Terminal 6',
		'TERM_ADDRE': '7201 N. Marine Drive',
		'CITY': 'Portland',
		'STATE': 'OR',
		'ZIP_CODE': 97203,
		'PORT': 'Port of Portland',
		'DATE': '2018/09/18 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 853470,
		'LAT': 45.638961,
		'LON': -122.75194
	},
	{
		'X': -76.319269,
		'Y': 36.850172,
		'OBJECTID': 144,
		'TERMINAL': 'Portsmouth, VA',
		'TERM_ADDRE': '1 Harper Avenue',
		'CITY': 'Portsmouth',
		'STATE': 'VA',
		'ZIP_CODE': 23707,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 261100,
		'LAT': 36.850172,
		'LON': -76.319269
	},
	{
		'X': -76.793245,
		'Y': 40.259638,
		'OBJECTID': 145,
		'TERMINAL': 'Rutherford, PA',
		'TERM_ADDRE': '5050 Paxton Street',
		'CITY': 'Harrisburg',
		'STATE': 'PA',
		'ZIP_CODE': 17111,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 206297,
		'LAT': 40.259638,
		'LON': -76.793245
	},
	{
		'X': -112.018014,
		'Y': 40.749946,
		'OBJECTID': 146,
		'TERMINAL': 'Salt Lake City, UT',
		'TERM_ADDRE': '1045 South 5500 West',
		'CITY': 'Salt Lake City',
		'STATE': 'UT',
		'ZIP_CODE': 84104,
		'PORT': '',
		'DATE': '2018/08/26 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 762930,
		'LAT': 40.749946,
		'LON': -112.018014
	},
	{
		'X': -98.638744,
		'Y': 29.302729,
		'OBJECTID': 147,
		'TERMINAL': 'San Antonio, TX - SAIT',
		'TERM_ADDRE': '13001 IH-35 South',
		'CITY': 'Von Ormy',
		'STATE': 'TX',
		'ZIP_CODE': 78073,
		'PORT': '',
		'DATE': '2018/08/27 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Interstate 35 Access Rd.',
		'SPLC': 687514,
		'LAT': 29.302729,
		'LON': -98.638744
	},
	{
		'X': -117.320254,
		'Y': 34.106012,
		'OBJECTID': 148,
		'TERMINAL': 'San Bernardino, CA',
		'TERM_ADDRE': '1535 West 4th Street',
		'CITY': 'San Bernardino',
		'STATE': 'CA',
		'ZIP_CODE': 92411,
		'PORT': '',
		'DATE': '2018/09/19 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 880270,
		'LAT': 34.106012,
		'LON': -117.320254
	},
	{
		'X': -118.286313,
		'Y': 33.757494,
		'OBJECTID': 149,
		'TERMINAL': 'San Pedro, CA - West Basin Container Terminal (WBCT)',
		'TERM_ADDRE': '2050 John S. Gibson Boulevard',
		'CITY': 'San Pedro',
		'STATE': 'CA',
		'ZIP_CODE': 90731,
		'PORT': 'Port of Los Angeles',
		'DATE': '2018/12/09 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883252,
		'LAT': 33.757494,
		'LON': -118.286313
	},
	{
		'X': -106.745795,
		'Y': 31.895863,
		'OBJECTID': 150,
		'TERMINAL': 'Santa Teresa, NM',
		'TERM_ADDRE': '9050 Strauss Road',
		'CITY': 'Santa Teresa',
		'STATE': 'NM',
		'ZIP_CODE': 88008,
		'PORT': '',
		'DATE': '2018/08/28 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 779565,
		'LAT': 31.895863,
		'LON': -106.745795
	},
	{
		'X': -81.147246,
		'Y': 32.065481,
		'OBJECTID': 151,
		'TERMINAL': 'Savannah, GA',
		'TERM_ADDRE': '3000 Tremont Road',
		'CITY': 'Savannah',
		'STATE': 'GA',
		'ZIP_CODE': 31405,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 461450,
		'LAT': 32.065481,
		'LON': -81.147246
	},
	{
		'X': -81.155859,
		'Y': 32.131099,
		'OBJECTID': 152,
		'TERMINAL': 'Savannah, GA - Garden City Marine Terminal (Mason ICTF)',
		'TERM_ADDRE': '3 North Main Street',
		'CITY': 'Garden City',
		'STATE': 'GA',
		'ZIP_CODE': 31408,
		'PORT': 'Port of Savannah',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Williams Rd.; S. Coast Hwy',
		'SPLC': 461417,
		'LAT': 32.131099,
		'LON': -81.155859
	},
	{
		'X': -81.144147,
		'Y': 32.116716,
		'OBJECTID': 153,
		'TERMINAL': 'Savannah, GA - Garden City Terminal (Chatham ICTF)',
		'TERM_ADDRE': '2 Main Street',
		'CITY': 'Savannah',
		'STATE': 'GA',
		'ZIP_CODE': 31408,
		'PORT': 'Port of Savannah',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Brampton Rd.; Tomochichi Rd.',
		'SPLC': 461446,
		'LAT': 32.116716,
		'LON': -81.144147
	},
	{
		'X': -122.334462,
		'Y': 47.561968,
		'OBJECTID': 154,
		'TERMINAL': 'Seattle, WA',
		'TERM_ADDRE': '4700 Denver Avenue South',
		'CITY': 'Seattle',
		'STATE': 'WA',
		'ZIP_CODE': 98134,
		'PORT': 'Port of Seattle',
		'DATE': '2018/08/29 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '2nd Avenue',
		'SPLC': 845200,
		'LAT': 47.561968,
		'LON': -122.334462
	},
	{
		'X': -122.337834,
		'Y': 47.578082,
		'OBJECTID': 155,
		'TERMINAL': 'Seattle, WA - Seattle International Gateway (SIG)',
		'TERM_ADDRE': '44 South Hanford Street',
		'CITY': 'Seattle',
		'STATE': 'WA',
		'ZIP_CODE': 98134,
		'PORT': 'Port of Seattle',
		'DATE': '2018/09/20 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 845200,
		'LAT': 47.578082,
		'LON': -122.337834
	},
	{
		'X': -122.26888,
		'Y': 47.492471,
		'OBJECTID': 156,
		'TERMINAL': 'Seattle, WA - South Seattle',
		'TERM_ADDRE': '12400 51st Place South',
		'CITY': 'Seattle',
		'STATE': 'WA',
		'ZIP_CODE': 98178,
		'PORT': 'Port of Seattle',
		'DATE': '2018/09/21 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 845203,
		'LAT': 47.492471,
		'LON': -122.26888
	},
	{
		'X': -119.751524,
		'Y': 39.53246,
		'OBJECTID': 157,
		'TERMINAL': 'Sparks, NV',
		'TERM_ADDRE': '1151 Nugget Avenue',
		'CITY': 'Sparks',
		'STATE': 'NV',
		'ZIP_CODE': 89431,
		'PORT': '',
		'DATE': '2018/08/30 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 864168,
		'LAT': 39.53246,
		'LON': -119.751524
	},
	{
		'X': -117.322251,
		'Y': 47.673991,
		'OBJECTID': 158,
		'TERMINAL': 'Spokane, WA',
		'TERM_ADDRE': '1800 North Dickey Road',
		'CITY': 'Spokane',
		'STATE': 'WA',
		'ZIP_CODE': 99211,
		'PORT': '',
		'DATE': '2018/09/22 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 840600,
		'LAT': 47.673991,
		'LON': -117.322251
	},
	{
		'X': -72.613174,
		'Y': 42.098446,
		'OBJECTID': 159,
		'TERMINAL': 'Springfield, MA',
		'TERM_ADDRE': '151 Day Street',
		'CITY': 'West Springfield',
		'STATE': 'MA',
		'ZIP_CODE': 1089,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 148250,
		'LAT': 42.098446,
		'LON': -72.613174
	},
	{
		'X': -90.212046,
		'Y': 38.689583,
		'OBJECTID': 160,
		'TERMINAL': 'St. Louis, MO',
		'TERM_ADDRE': '333 East Carrie Avenue',
		'CITY': 'St. Louis',
		'STATE': 'MO',
		'ZIP_CODE': 63147,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 567500,
		'LAT': 38.689583,
		'LON': -90.212046
	},
	{
		'X': -90.209535,
		'Y': 38.524627,
		'OBJECTID': 161,
		'TERMINAL': 'St. Louis, MO - Dupo',
		'TERM_ADDRE': 'Highway 3 & State Street',
		'CITY': 'Dupo',
		'STATE': 'IL',
		'ZIP_CODE': 62239,
		'PORT': '',
		'DATE': '2018/08/31 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Carondolette Avenue',
		'SPLC': 396658,
		'LAT': 38.524627,
		'LON': -90.209535
	},
	{
		'X': -90.313661,
		'Y': 38.602555,
		'OBJECTID': 162,
		'TERMINAL': 'St. Louis, MO - Lindenwood',
		'TERM_ADDRE': '3500 Wellington Avenue',
		'CITY': 'St. Louis',
		'STATE': 'MO',
		'ZIP_CODE': 63139,
		'PORT': '',
		'DATE': '2018/09/23 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Ellendale Ave.',
		'SPLC': 567500,
		'LAT': 38.602555,
		'LON': -90.313661
	},
	{
		'X': -93.174527,
		'Y': 44.970069,
		'OBJECTID': 163,
		'TERMINAL': 'St. Paul, MN - Midway',
		'TERM_ADDRE': '1701 Pierce Butler Route',
		'CITY': 'St. Paul',
		'STATE': 'MN',
		'ZIP_CODE': 55104,
		'PORT': '',
		'DATE': '2018/09/24 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 504450,
		'LAT': 44.970069,
		'LON': -93.174527
	},
	{
		'X': -71.792085,
		'Y': 42.226861,
		'OBJECTID': 164,
		'TERMINAL': 'Stackbridge, MA',
		'TERM_ADDRE': '53 Wiser Avenue',
		'CITY': 'Worcester',
		'STATE': 'MA',
		'ZIP_CODE': 1607,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Blackstone River Rd.',
		'SPLC': 145460,
		'LAT': 42.226861,
		'LON': -71.792085
	},
	{
		'X': -121.169307,
		'Y': 37.899332,
		'OBJECTID': 165,
		'TERMINAL': 'Stockton, CA',
		'TERM_ADDRE': '6540 South Austin Road',
		'CITY': 'Stockton',
		'STATE': 'CA',
		'ZIP_CODE': 95215,
		'PORT': '',
		'DATE': '2018/09/25 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Arch Rd.',
		'SPLC': 875640,
		'LAT': 37.899332,
		'LON': -121.169307
	},
	{
		'X': -76.036054,
		'Y': 43.07121,
		'OBJECTID': 166,
		'TERMINAL': 'Syracuse, NY',
		'TERM_ADDRE': '600 Fremont Road',
		'CITY': 'Syracuse',
		'STATE': 'NY',
		'ZIP_CODE': 13057,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': 'Old Fairmont Rd.',
		'SPLC': 182340,
		'LAT': 43.07121,
		'LON': -76.036054
	},
	{
		'X': -122.407768,
		'Y': 47.269537,
		'OBJECTID': 167,
		'TERMINAL': 'Tacoma, WA - North Yard',
		'TERM_ADDRE': '711 Port of Tacoma Road',
		'CITY': 'Tacoma',
		'STATE': 'WA',
		'ZIP_CODE': 98421,
		'PORT': 'Port of Tacoma',
		'DATE': '2018/09/26 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 846203,
		'LAT': 47.269537,
		'LON': -122.407768
	},
	{
		'X': -122.412016,
		'Y': 47.26366,
		'OBJECTID': 168,
		'TERMINAL': 'Tacoma, WA - South Yard',
		'TERM_ADDRE': '1101 Milwaukee Way',
		'CITY': 'Tacoma',
		'STATE': 'WA',
		'ZIP_CODE': 98421,
		'PORT': 'Port of Tacoma',
		'DATE': '2018/09/27 00:00:00+00',
		'RAIL_COMPA': 'BNSF',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 846212,
		'LAT': 47.26366,
		'LON': -122.412016
	},
	{
		'X': -122.407759,
		'Y': 47.257517,
		'OBJECTID': 169,
		'TERMINAL': 'Tacoma, WA - Tacoma South (TacSim)',
		'TERM_ADDRE': '1738 Milwaukee Way',
		'CITY': 'Tacoma',
		'STATE': 'WA',
		'ZIP_CODE': 98421,
		'PORT': 'Port of Tacoma',
		'DATE': '2018/09/01 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 846214,
		'LAT': 47.257517,
		'LON': -122.407759
	},
	{
		'X': -82.383968,
		'Y': 27.960808,
		'OBJECTID': 170,
		'TERMINAL': 'Tampa, FL',
		'TERM_ADDRE': '1901 North 62nd Street',
		'CITY': 'Tampa',
		'STATE': 'FL',
		'ZIP_CODE': 33619,
		'PORT': 'Port Tampa Bay',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 497800,
		'LAT': 27.960808,
		'LON': -82.383968
	},
	{
		'X': -75.705997,
		'Y': 41.385279,
		'OBJECTID': 171,
		'TERMINAL': 'Taylor, PA',
		'TERM_ADDRE': 'One Depot Street',
		'CITY': 'Taylor',
		'STATE': 'PA',
		'ZIP_CODE': 18517,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 200676,
		'LAT': 41.385279,
		'LON': -75.705997
	},
	{
		'X': -118.253457,
		'Y': 33.740927,
		'OBJECTID': 172,
		'TERMINAL': 'Terminal Island, CA - Global Gateway South',
		'TERM_ADDRE': '614 Terminal Way',
		'CITY': 'Terminal Island',
		'STATE': 'CA',
		'ZIP_CODE': 90731,
		'PORT': 'Port of Los Angeles',
		'DATE': '2018/12/06 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883735,
		'LAT': 33.740927,
		'LON': -118.253457
	},
	{
		'X': -118.241489,
		'Y': 33.728437,
		'OBJECTID': 173,
		'TERMINAL': 'Terminal Island, CA - Pier 400 (APM Terminals Pacific)',
		'TERM_ADDRE': '2500 Navy Way',
		'CITY': 'Terminal Island',
		'STATE': 'CA',
		'ZIP_CODE': 90731,
		'PORT': 'Port of Los Angeles',
		'DATE': '2018/12/05 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883737,
		'LAT': 33.728437,
		'LON': -118.241489
	},
	{
		'X': -118.223799,
		'Y': 33.761171,
		'OBJECTID': 174,
		'TERMINAL': 'Terminal Island, CA - Total Terminals International (TTI/Pier T)',
		'TERM_ADDRE': '301 Mediterranean Way',
		'CITY': 'Long Beach',
		'STATE': 'CA',
		'ZIP_CODE': 90802,
		'PORT': 'Port of Long Beach',
		'DATE': '2018/12/10 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883734,
		'LAT': 33.761171,
		'LON': -118.223799
	},
	{
		'X': -83.589452,
		'Y': 41.637645,
		'OBJECTID': 175,
		'TERMINAL': 'Toledo, OH',
		'TERM_ADDRE': '2101 Hill Avenue',
		'CITY': 'Toledo',
		'STATE': 'OH',
		'ZIP_CODE': 43607,
		'PORT': '',
		'DATE': '2018/08/01 00:00:00+00',
		'RAIL_COMPA': 'NS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 343138,
		'LAT': 41.637645,
		'LON': -83.589452
	},
	{
		'X': -110.848336,
		'Y': 32.126437,
		'OBJECTID': 176,
		'TERMINAL': 'Tucson, AZ',
		'TERM_ADDRE': '6800 E. Century Park Drive',
		'CITY': 'Tucson',
		'STATE': 'AZ',
		'ZIP_CODE': 85706,
		'PORT': '',
		'DATE': '2018/09/02 00:00:00+00',
		'RAIL_COMPA': 'UP',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': 'Third Driveway Entrance',
		'SPLC': 799000,
		'LAT': 32.126437,
		'LON': -110.848336
	},
	{
		'X': -118.273289,
		'Y': 33.77032,
		'OBJECTID': 177,
		'TERMINAL': 'Wilmington, CA - TraPac Intermodal Container Transfer Facility',
		'TERM_ADDRE': '630 West Harry Bridges Boulevard',
		'CITY': 'Wilmington',
		'STATE': 'CA',
		'ZIP_CODE': 90744,
		'PORT': 'Port of Los Angeles',
		'DATE': '2018/12/07 00:00:00+00',
		'RAIL_COMPA': 'PHL',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 883257,
		'LAT': 33.77032,
		'LON': -118.273289
	},
	{
		'X': -77.952419,
		'Y': 34.191224,
		'OBJECTID': 178,
		'TERMINAL': 'Wilmington, NC - Container Terminal',
		'TERM_ADDRE': '1 Shipyard Boulevard',
		'CITY': 'Wilmington',
		'STATE': 'NC',
		'ZIP_CODE': 28401,
		'PORT': 'Port of Wilmington',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 409160,
		'LAT': 34.191224,
		'LON': -77.952419
	},
	{
		'X': -81.693226,
		'Y': 27.939953,
		'OBJECTID': 179,
		'TERMINAL': 'Winter Haven, FL - Central Florida Intermodal Logistics Center (CFILC)',
		'TERM_ADDRE': '3935 Intermodal Drive',
		'CITY': 'Winter Haven',
		'STATE': 'FL',
		'ZIP_CODE': 33884,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX, EVWR',
		'EQUIPMENT': 'COFC',
		'ACCESS_ROA': '',
		'SPLC': 497540,
		'LAT': 27.939953,
		'LON': -81.693226
	},
	{
		'X': -71.789244,
		'Y': 42.259951,
		'OBJECTID': 180,
		'TERMINAL': 'Worcester, MA',
		'TERM_ADDRE': '165 Grafton Street',
		'CITY': 'Worcester',
		'STATE': 'MA',
		'ZIP_CODE': 1604,
		'PORT': '',
		'DATE': '2018/09/11 00:00:00+00',
		'RAIL_COMPA': 'CSX',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 145460,
		'LAT': 42.259951,
		'LON': -71.789244
	},
	{
		'X': -96.509452,
		'Y': 33.025555,
		'OBJECTID': 181,
		'TERMINAL': 'Wylie, TX',
		'TERM_ADDRE': '2800 State Highway 78 North',
		'CITY': 'Wylie',
		'STATE': 'TX',
		'ZIP_CODE': 75098,
		'PORT': '',
		'DATE': '2018/08/03 00:00:00+00',
		'RAIL_COMPA': 'KCS',
		'EQUIPMENT': 'TOFC/COFC',
		'ACCESS_ROA': '',
		'SPLC': 663676,
		'LAT': 33.025555,
		'LON': -96.509452
	}
];

export default intermodalJSON;
