import React from 'react';
import { Cluster, Marker } from 'react-mapbox-gl';
import { FeatureCollection, Feature, Point } from 'geojson';

import {
	TInFeature
	, TInProperty
} from '../constant/Types';

// was 18, 20, 30, 50
export const MARKER_SIZES = {
	default: 20,
	small: 28,
	medium: 36,
	large: 50,
	extraLarge: 56,
};

export const POINT_GROUP_BORDERS = {
	small: 10,
	medium: 50,
	large: 100,
	extraLarge: 1000,
};

// was 15
export const ICON_SIZE = 18;

export interface TFeature {
	rec_id?: string | number;
	client_first_name?: string;
	geometry?: {
		coordinates?: GeoJSON.Position,
	};
	properties?: TInProperty;
	LON?: number;
	LAT?: number;
}

export const defineClusterSize = (pointsCount: number) => {
	if (pointsCount >= POINT_GROUP_BORDERS.extraLarge) {
		return MARKER_SIZES.extraLarge;
	}
	if (pointsCount >= POINT_GROUP_BORDERS.large) {
		return MARKER_SIZES.large;
	}
	if (pointsCount >= POINT_GROUP_BORDERS.medium) {
		return MARKER_SIZES.medium;
	}
	if (pointsCount >= POINT_GROUP_BORDERS.small) {
		return MARKER_SIZES.small;
	}
	return MARKER_SIZES.default;
}

export const clusterMarker = (coordinates: GeoJSON.Position
	, pointsCount: number
	, clusterColor: string
	, onMarkersClusterClick: (coordinates: GeoJSON.Position, pointsCount: number) => void
) => {
	if (!coordinates) {
		return null;
	}
	const size = defineClusterSize(pointsCount);
	return (
		<Marker
			key={'clusterMarker_' + coordinates.toString()}
			coordinates={coordinates}
			className='map-cluster-marker'
			style={{
				backgroundColor: clusterColor,
				height: `${size}px`,
				width: `${size}px`,
			}}
			onClick={(event: React.MouseEvent) => {
				if (onMarkersClusterClick) {
					onMarkersClusterClick(coordinates, pointsCount);
				}
			}}
		>
			<p className='cluster-point-count'>{pointsCount}</p>
		</Marker>
	);
}

interface PMapCluster {
	data: Array<TFeature>;
	onMarkerClick: (feature: TFeature) => void;
	onMarkersClusterClick: (coordinates: GeoJSON.Position, pointsCount: number) => void;
	icon?: string;
	clusterColor?: string;
}

class SMapCluster {
	ref: MapCluster = null;

	constructor(parent: MapCluster) {
		this.ref = parent;
	}
}

export default class MapCluster extends React.Component<PMapCluster, SMapCluster> {

	constructor(props: PMapCluster) {
		super(props);
		this.state = new SMapCluster(this);
	}

	render() {
		const { props } = this;
		return (
			<Cluster ClusterMarkerFactory={
				(coordinates: GeoJSON.Position, pointsCount: number, getLeaves: (limit?: number, offset?: number) => Array<React.ReactElement>) => {
					return clusterMarker(coordinates, pointsCount, props.clusterColor, props.onMarkersClusterClick);
				}}
				zoomOnClick={false}
				onClick={(event: React.MouseEvent) => {
				}}
			>
				{
					props.data && props.data.map((feature: TFeature, index: number) => {
						return (
							<Marker
								className='map-unclustered-marker'
								key={'MapCluster_data_' + (feature.rec_id ? feature.rec_id : JSON.stringify(feature))}
								onClick={(event: React.MouseEvent) => { props.onMarkerClick(feature); }}
								coordinates={feature.geometry ? feature.geometry.coordinates : [feature.LON, feature.LAT]}
							>
								<img src={props.icon} width={ICON_SIZE} height={ICON_SIZE} />
							</Marker>
						)
					})
				}
			</Cluster>
		)
	}
}
