import React, { useState } from 'react';
import DataLayers from '../public/assets/images/work/datalayernew.png';
import { Link } from 'react-router-dom';
const Index = props => {
    const [AdvancedMapLayers] = useState([
        { title: 'Shortline Railroads' },
        { title: 'Crude Oil Pipelines' },
        { title: 'Refined Product Pipelines' },
        { title: 'Intermodal Terminals' },
    ]);
    const [BasicMapLayers] = useState([
        { title: 'Roads' },
        { title: 'Highways' },
        { title: 'Major Ports' },
        { title: 'Class I railroads' }
        
    ]);
    return (
        <>
            {/* <section className="main-bg section-content" style={{ backgroundImage: `url(${DataLayers})`, backgroundAttachment: "fixed", backgroundSize: "cover" }}> */}
            <section className="main-bg section-content" style={{ backgroundAttachment: "fixed", backgroundSize: "cover" }}>
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: 70 }} className="col-lg-6 mb-lg-0 mb-5">
                            <div className=" iq-title-box  iq-title-box-2 wow fadeInUp" data-wow-duration="0.6s">
                                <h2 style={{ textTransform: 'none' }} className="iq-title text-white">Navigate with Map Layers</h2>
                                <p className="iq-title-desc text-white"> Customize your map view by toggling map layers on and off. Map layers contain map locations and routes  vital transportation connections.
                                </p>
                            </div>
                            <p className="iq-title-desc text-white">Basic Map Layers (available to all subsribers):</p>
                            <div className="iq-list  iq-two-column">
                                <ul className="iq-list-with-icon">
                                    {
                                        BasicMapLayers.map((language, index) => (
                                            <li className="text-white" key={index}><i className="fa fa-check"></i>{language.title}</li>
                                        ))
                                    }
                                </ul>
                            </div>

                            <p style={{marginTop:20}} className="iq-title-desc text-white">Advanced  Map Layers (available to <Link target="_blank" style={{ color: "white" }} to={{ pathname: '/plans-and-pricing' }} >Power Search and Power Pro</Link> subscribers):</p>

                            <div className="iq-list  iq-two-column">
                                <ul className="iq-list-with-icon">
                                    {
                                        AdvancedMapLayers.map((language, index) => (
                                            <li className="text-white" key={index}><i className="fa fa-check"></i>{language.title}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            {/* <div style={{ paddingTop: 20 }} className="iq-btn-container mt-4">
                                <br /><p className="text-white">And much more!</p>
                            </div> */}
                        </div>
                        <div className="col-lg-6 align-self-stretch align-self-center">
                            <img style={{ maxHeight: 600, width: 'auto', height: 'auto' }} className="hover-img img-fluid adjust-image-data" src={DataLayers} alt="fancybox" />
                            
                           
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
}

export default Index