import React, { useEffect } from 'react';
import '../public/style.css'
import { index } from '../config/plugins';
import { ScrollTop, } from '../qloud';
import Footer from '../qloud/footerComponents/index';
const Layout = props => {
    useEffect(() => {
        setTimeout(() => {
            index();
        }, 500);
    });

    return (
        <>
            {props.children}
            <Footer />
            <ScrollTop />
        </>
    );
}

export default Layout