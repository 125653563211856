import React, { Component } from 'react';
import '../custom.css';
import { Breadcrumb, BreadcrumbItem, Modal, Row, Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import Features from './Features';
import { Link, withRouter } from 'react-router-dom';
import ReactMapboxGl, { Layer, Feature, ZoomControl } from 'react-mapbox-gl';
import siteIcon from '../site-marker.png';
import portTerminalIcon from '../port-terminal.png';
import otherTerminalIcon from '../terminaltrans.png';
import warehouseIcon from '../warehouse-marker.png';
import axios from 'axios';
import UserProfile from './UserProfile';
import addressIcon from '../assets/icons/address.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import MediaQuery from 'react-responsive';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import PdfDetailsDocGen from './pdf/PdfDetailsDocGen';

const siteImage = new Image();
siteImage.src = siteIcon;
siteImage.width = 28;
siteImage.height = 28;
(siteImage as any).alpha = 0;
export const siteImages = ['siteImage', siteImage];

const portTerminalImage = new Image();
portTerminalImage.src = portTerminalIcon;
portTerminalImage.width = 28;
portTerminalImage.height = 28;
(portTerminalImage as any).alpha = 0;
export const portTerminalImages = ['portTerminalImage', portTerminalImage];

const otherTerminalImage = new Image();
otherTerminalImage.src = otherTerminalIcon;
otherTerminalImage.width = 28;
otherTerminalImage.height = 28;
(otherTerminalImage as any).alpha = 0;
const otherTerminalImages = ['otherTerminalImage', otherTerminalImage];

const warehouseImage = new Image();
warehouseImage.src = warehouseIcon;
warehouseImage.width = 28;
warehouseImage.height = 28;
(warehouseImage as any).alpha = 0;
export const warehouseImages = ['warehouseImage', warehouseImage];

const Mapbox = ReactMapboxGl({
    accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
});

interface PDetails {
    match: any;
    history: any;
    onStyleLoad?: (map: any) => any;
}

class SDetails {

    constructor() {
    }
}

class Details extends Component<PDetails, any> {
    child: Header = null;
    onDrag: any = null;

    constructor(props: PDetails) {
        super(props);
        this.state = {
            zoom: [16],
            showContactUs: false,
            firstname: UserProfile.getFirstName() ? UserProfile.getFirstName() : '',
            lastname: UserProfile.getLastName() ? UserProfile.getLastName() : '',
            email: UserProfile.getEmail() ? UserProfile.getEmail() : '',
            message: '',
            show: false,
            formattedPrice: '',
            renderSatelliteMap: true,
            mapType: '',

            place: '',
            center: '',
            markerPoints: '',
            place_owner_name: '',
            place_id: '',
            addFavModal: false,
            deleteFavModal: false,
            bookmarksCount: 0
        }
        this.goBack = this.goBack.bind(this);
    }

    componentDidMount() {
        this.changeMapStyle();
        var rec_id = localStorage.getItem('rec_id')
        this.getData(rec_id);
    }

    getData = async (id) => {
        await axios.post(process.env.REACT_APP_BASE_URL + '/api/get-place-details', {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            rec_id: id,
        })
            .then(res => {
                console.log(res.data.feature)
                var place = res.data.feature;
                if (res.status === 200) {
                    var formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0
                    });
                    let formattedPrice = formatter.format(place.properties.price);

                    this.setState({
                        place: place,
                        center: place.geometry.coordinates,
                        markerPoints: [{ lng: place.geometry.coordinates[0] - 0.00002, lat: place.geometry.coordinates[1] - 0.00002 }, { lng: place.geometry.coordinates[0] + 0.00002, lat: place.geometry.coordinates[1] + 0.00002 }],
                        place_owner_name: place.properties.contactName == "" ? 'Listing Contact' : place.properties.contactName,
                        place_id: place.rec_id,
                        formattedPrice: formattedPrice
                    }, () => {
                        const bookmarksStr = localStorage.getItem('bookmarksprivate') ? localStorage.getItem('bookmarksprivate') : '';
                        if (bookmarksStr) {
                            const bookmarksArr = bookmarksStr.split(',');
                            if (bookmarksArr.includes(place.rec_id)) {
                                document.getElementById('bookmark-btn').classList.add('color-faint-blue');
                            }
                            this.setState({
                                bookmarksCount: bookmarksArr.length
                            })
                        }
                    });
                }
            }).catch(err => {
                console.log(err);
            });
    }

    onToggleHover(cursor, { map }) {
        map.getCanvas().style.cursor = cursor;
    }

    onStyleLoad(map) {
        const { onStyleLoad } = this.props;
        return onStyleLoad && onStyleLoad(map);
    };

    markerClick = (place, feature) => {
        this.setState({
            center: place.geometry.coordinates,
            zoom: [16],
            place,
            markerPoints: [{ lng: place.geometry.coordinates[0] - 0.00002, lat: place.geometry.coordinates[1] - 0.00002 }, { lng: place.geometry.coordinates[0] + 0.00002, lat: place.geometry.coordinates[1] + 0.00002 }]
        });
    };

    goBack() {
        this.props.history.goBack();
    }

    toggleContinueReading() {
        if (document.getElementById('description').style.maxHeight === '250px') {
            document.getElementById('description').style.maxHeight = '50px';
            document.getElementById('description').style.overflow = 'hidden';
        }
        else {
            document.getElementById('description').style.maxHeight = '250px';
            document.getElementById('description').style.overflow = 'visible';
        }
    }

    handleContactUsClose = () => {
        this.setState({ showContactUs: false });
    }

    handleContactUsShow = () => {
        this.setState({ showContactUs: true });
    }

    setFirstName = (event) => {
        this.setState({ firstname: event.target.value });
    }

    setLastName = (event) => {
        this.setState({ lastname: event.target.value });
    }

    setEmail = (event) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(event.target.value)) {
            document.getElementById("emailValidation").innerHTML = "Please enter valid email."
            this.setState({ email: event.target.value });
        }
        else {
            document.getElementById("emailValidation").innerHTML = ""
            this.setState({ email: event.target.value });
        }
    }
    openInNewTab = (url) => {
        if (!url.match(/^https?:\/\//i)) {
            url = 'http://' + url;
        }
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    setMessage = (event) => {
        this.setState({ message: event.target.value });
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleDismiss = () => {
        this.setState({ show: false });
    }

    handleContactUs = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us',
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'x-access-token': cookies.get('token')
                },
                // withCredentials: true,
                place_owner_name: this.state.place_owner_name,
                place_id: this.state.place_id,
                name: this.state.firstname + ' ' + this.state.lastname,
                email: this.state.email,
                message: this.state.message,
            }).then(response => {
                console.log(response)
                if (response.status === 200) {
                    this.handleContactUsClose();
                    this.handleShow();
                    document.getElementById('main-content').scrollIntoView({ behavior: 'smooth' });
                } else {
                    this.handleDismiss();
                }
            }).catch(function (error) {
                console.log(error)
            });
    }

    changeMapStyle = () => {
        this.setState({ renderSatelliteMap: !this.state.renderSatelliteMap });
        if (this.state.renderSatelliteMap) {
            this.setState({ mapType: process.env.REACT_APP_MAPBOX_STYLE_SATELLITE });
        } else {
            this.setState({ mapType: process.env.REACT_APP_MAPBOX_STYLE_STREETS });
        }
        this.forceUpdate();
    }

    formatPhoneNumber(phoneNumberString) {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '')
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }
        return null
    }

    createStrFromArr = (inputArr) => {
        var arr = inputArr.split('*');
        var str = '';
        for (var k = 0; k < arr.length; k++) {
            if (str) {
                str = str + ', ' + arr[k];
            } else {
                str = arr[k];
            }
        }
        return str;
    }

    createPDF = () => {
        console.log('this.state.place.properties', this.state.place.properties.listingLogo)
        axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'x-access-token': cookies.get('token')
                },
                // withCredentials: true,
                imgurl: this.state.place.properties.image[0].url,
            }).then(response => {
                if (response.status === 200) {
                    var final_base_64_url = 'data:image/jpeg;base64,' + response.data.base64;
                    this.state.place.properties.image[0].url = final_base_64_url;
                    axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                // 'x-access-token': cookies.get('token')
                            },
                            // withCredentials: true,
                            imgurl: this.state.place.properties.listingLogo,
                        }).then(response => {
                            if (response.status === 200) {
                                var final_base_64_url_logo = 'data:image/jpeg;base64,' + response.data.base64;
                                this.state.place.properties.listingLogo = final_base_64_url_logo;
                                // console.log('this.state.place.properties',this.state.place.properties);
                                (new PdfDetailsDocGen()).createPDF(this.state.place.properties);
                            }
                        }).catch(function (error) {
                            console.log(error)
                            alert('Image Correpted...Something went wrong')
                        });
                }
            }).catch(function (error) {
                console.log(error)
                alert('Image Correpted...Something went wrong')
            });


        // (new PdfDetailsDocGen()).createPDF(this.state.place.properties);
    }

    // addToBookmarkList = (place_id) => {
    //     console.log(place_id)
    //     axios.post(process.env.REACT_APP_BASE_URL + '/api/update-bookmark-list', {
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    //         place_id: place_id,
    //         email: this.state.email
    //     })
    //         .then(res => {
    //             console.log(res.data)
    //             localStorage.setItem('bookmarks', res.data.bookmarks);
    //             let bookmarksArr = [];
    //             if (res.data.bookmarks) {
    //                 bookmarksArr = res.data.bookmarks.split(',');
    //             }
    //             const bookmarksCount = bookmarksArr.length;
    //             this.setState({
    //                 bookmarksCount: bookmarksCount
    //             })
    //             if (res.data.type === 'add') {
    //                 document.getElementById('bookmark-btn').classList.add('color-faint-blue');
    //                 this.showAddFavModal();
    //             } else {
    //                 this.showDeleteFavModal();
    //                 document.getElementById('bookmark-btn').classList.remove('color-faint-blue');
    //             }
    //         }).catch(err => {
    //             console.log(err);
    //         });
    // }

    showAddFavModal = () => {
        this.setState({ addFavModal: true });
    }

    hideAddFavModal = () => {
        this.setState({ addFavModal: false });
    }

    showDeleteFavModal = () => {
        this.setState({ deleteFavModal: true });
    }

    hideDeleteFavModal = () => {
        this.setState({ deleteFavModal: false });
    }

    getFirstImage = (json) => {
        for (var key in json) {
            if (json.hasOwnProperty(key)) {
                var url = json[key].url;
                return url;
            }
        }
    }

    render() {
        const { center, zoom, place, markerPoints } = this.state;
        const feature = place;
        const sharableString = place ?  place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-') +'-'+ place.properties.city + '-' + place.properties.state + '-' + place.properties.listingName + '-' + place.rec_id : '';

        console.log("sharableString",sharableString.replaceAll(' ', '-'))
        return (
            <div className='padding-bottom-50'>
                <Helmet>
                    <title>{place && place.properties.listingName}</title>
                    <meta name='description' content={place && place.properties.description} />
                    <meta property='og:title' content={place && place.properties.listingName} />
                    <meta property='og:image' content={place && place.properties.image[0].url ? place && place.properties.image[0].url : ''} />
                </Helmet>

                <div id='page-content' style={{ marginBottom: 0, borderBottom: 'none', padding: 0, minHeight: '100vh' }} >

                    <section className=''>
                        {place &&
                            <>
                                <div className='col-lg-12 padding-0'>
                                    <div className='real-estate-item-image margin-bottom-20'>
                                        <Carousel
                                            autoPlay={true}
                                            stopOnHover={true}
                                            infiniteLoop={true}
                                            swipeable={true}
                                            dynamicHeight={true}
                                            showThumbs={place.properties.image && place.properties.image.length > 1 ? true : false}
                                            emulateTouch={true}
                                            interval={3000}
                                            className={place.properties.image && place.properties.image.length > 1 ? 'margin-bottom-120' : ''}
                                        >
                                            {place.properties.image.map((value, index) => {
                                                return <div key={index}><img src={value.url} /></div>
                                            })}
                                        </Carousel>
                                        <div style={{ position: 'absolute', height: 100, left: 0, right: 0, bottom: 0, backgroundColor: 'black', opacity: 0.6 }}></div>
                                        <div className='' style={{ position: 'absolute', bottom: 12, zIndex: 1, width: '99%' }}>
                                            <div className='col-lg-8 col-lg-offset-2 temp1 '>
                                                <div className='pull-left color-white reduce-font-size-15 reduce-font-size-12px reduce-font-size-16 reduce-font-size-18  margin-botton-10' style={{ fontSize: '20px', fontWeight: 'bold', paddingRight: '10px', width: '95%' }}>
                                                    {place.properties.listingName}


                                                </div>
                                            </div>
                                            <div className='col-lg-8 col-lg-offset-2 temp1 margin-bottom-30 '>
                                                <div className='clear-both'
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <i className='fa font-size-20-imp fa-map-marker pull-left color-white reduce-margin-top-10' aria-hidden='true'></i>
                                                    <div className='font-size-14 reduce-font-size-11 pull-left color-white reduce-margin-top-10'
                                                        style={{
                                                            marginLeft: 2,
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        {place.properties.address}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ position: 'absolute', bottom: 0, right: 0, marginRight: '1%', zIndex: 2 }}>
                                            {/* <div className='pull-left' style={{ padding: 10 }}>
                                                <OverlayTrigger placement='bottom' overlay={
                                                    <Tooltip id='social-media-tooltip'>
                                                        <strong className='font-size-16'>Add to Favorites</strong>
                                                    </Tooltip>
                                                }>
                                                    <i id='bookmark-btn' className='cursor-pointer left-allign-icons reduce-font-size-15 fa font-size-20-imp fa-star color-white hover'
                                                        onClick={() => this.addToBookmarkList(place.rec_id)}></i>
                                                </OverlayTrigger>
                                            </div> */}

                                            {/* <div className='pull-left' style={{ padding: 10 }}>
                                                <OverlayTrigger placement='bottom' overlay={
                                                    <Tooltip id='social-media-tooltip'>
                                                        <strong className='font-size-16'>Share on Facebook</strong>
                                                    </Tooltip>
                                                }>
                                                    <FacebookShareButton url={window.location.origin + '/details-' + place.rec_id}>
                                                        <i className='color-white fa left-allign-icons reduce-font-size-15 font-size-20-imp fa-facebook cursor-pointer hover'></i>
                                                    </FacebookShareButton>
                                                </OverlayTrigger>
                                            </div> */}

                                            <div className='pull-left' style={{ padding: 10 }}>
                                                <OverlayTrigger placement='bottom' overlay={
                                                    <Tooltip id='social-media-tooltip'>
                                                        <strong className='font-size-16'>Share on Twitter</strong>
                                                    </Tooltip>
                                                }>
                                                    <TwitterShareButton url={window.location.origin + '/details-' + sharableString.replaceAll(' ', '-').toLowerCase().replaceAll('&', '')}>
                                                        <i className='color-white left-allign-icons reduce-font-size-15 fa font-size-20-imp fa-twitter cursor-pointer hover'></i>
                                                    </TwitterShareButton>
                                                </OverlayTrigger>
                                            </div>

                                            <div className='pull-left' style={{ padding: 10 }}>
                                                <OverlayTrigger placement='bottom' overlay={
                                                    <Tooltip id='social-media-tooltip'>
                                                        <strong className='font-size-16'>Share on LinkedIn</strong>
                                                    </Tooltip>
                                                }>
                                                    <a href={"https://www.linkedin.com/shareArticle?mini=true&url=https://share.industrialnavigator.com/industrialnavigator.php?" + sharableString.replaceAll(' ', '-').toLowerCase().replaceAll('&', '')} target="_blank"><i className="fab fa-linkedin-in color-white left-allign-icons reduce-font-size-15 fa font-size-20-imp cursor-pointer hover"></i></a>
                                                </OverlayTrigger>
                                            </div>

                                            <div className='pull-left' style={{ padding: 10 }}>
                                                <OverlayTrigger placement='bottom' overlay={
                                                    <Tooltip id='social-media-tooltip'>
                                                        <strong className='font-size-16'>Share on Email</strong>
                                                    </Tooltip>
                                                }>
                                                    <EmailShareButton onClick={() => { }} openShareDialogOnClick

                                                        url={window.location.origin + '/details-' + sharableString.replaceAll(' ', '-').toLowerCase().replaceAll('&', '')}
                                                        subject={'Industrial Navigator'}
                                                        body={'Industrial Navigator'}
                                                    >
                                                        <i className='color-white left-allign-icons fa reduce-font-size-15 font-size-20-imp fa-envelope cursor-pointer hover'></i>
                                                    </EmailShareButton>
                                                </OverlayTrigger>
                                            </div>

                                            <div className='pull-left' style={{ top: 25, padding: 10 }}>
                                                <OverlayTrigger placement='bottom' overlay={
                                                    <Tooltip id='social-media-tooltip'>
                                                        <strong className='font-size-16'>Generate PDF</strong>
                                                    </Tooltip>
                                                }>
                                                    <i className='color-white fa left-allign-icons reduce-font-size-15 font-size-20-imp fa-file-pdf-o cursor-pointer hover' onClick={this.createPDF}></i>
                                                </OverlayTrigger>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='col-md-8 col-sm-8 pull-left'>
                                        <section className='block' id='main-content'>
                                            {this.state.show &&
                                                <Alert bsStyle='success' onDismiss={this.handleDismiss}>
                                                    <h2 style={{ marginTop: 0, marginBottom: 10 }}>Success!</h2>
                                                    <p>
                                                        Your request has been sent. We will get in touch with you!
                                                    </p>
                                                </Alert>
                                            }
                                        </section>
                                    </div> */}
                                </div>
                                <div className='col-lg-12 qv-body-content' style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 5, paddingRight: 5 }}>
                                    {
                                        this.state.show &&
                                        <Alert bsStyle='success' onDismiss={this.handleDismiss}>
                                            <h2 style={{ marginTop: 0, marginBottom: 10, color: 'white' }}>Success!</h2>
                                            <p>
                                                Your message has been sent!
                                            </p>
                                        </Alert>
                                    }
                                    <div className='col-lg-8 col-lg-offset-2 temp'>
                                        {
                                            place.properties.organizationName &&
                                            <div className='margin-bottom-20 border-bottom-1 color-black'>
                                                <header>
                                                    <h2 className='margin-bottom-15 text-decoration-underline'>Organization Name</h2>
                                                </header>
                                                {
                                                    place.properties.listingLogo !== '' &&
                                                    <div style={{ width: 150, height: 40, marginBottom: 10 }}>
                                                        <img style={{ height: '100%' }} src={place.properties.listingLogo} />
                                                    </div>
                                                }
                                                <p>{place.properties.organizationName !== undefined && place.properties.organizationName !== '' ? place.properties.organizationName : ''}</p>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-lg-8 col-lg-offset-2 temp'>
                                        {

                                            <div className='margin-bottom-20 border-bottom-1'>
                                                <header>
                                                    <h2 className='margin-bottom-15 text-decoration-underline text-decoration-underline color-black'>Contact Information</h2>
                                                </header>
                                                <div>
                                                    <div className='font-size-14 color-black' style={{ fontWeight: 600, marginBottom: 10 }}>
                                                        {place.properties.contactName || ''}{place.properties.contactTitle ? '/' + place.properties.contactTitle : ''}
                                                    </div>
                                                    <div className='font-size-14 color-black' style={{ fontWeight: 600, marginBottom: 10 }}>
                                                        {/* {place.properties.companyWebsiteUrl && place.properties.companyWebsiteUrl != '' && 'Company Website: ' + place.properties.companyWebsiteUrl} */}

                                                        {
                                                            place.properties.companyWebsiteUrl && place.properties.companyWebsiteUrl != '' &&
                                                            <a onClick={() => this.openInNewTab(place.properties.companyWebsiteUrl)}>Company Website</a>
                                                        }
                                                    </div>
                                                    {/* {
                                                        this.state.planId == '2' ?
                                                            <>
                                                                <div style={{ display: 'flex' }} className='position-relative margin-bottom-20 color-blue'>
                                                                    <OverlayTrigger placement='right' overlay={
                                                                        <Tooltip id='social-media-tooltip'>
                                                                            <strong style={{
                                                                                fontSize: 16
                                                                                , fontWeight: 'bold'
                                                                                , color: 'rgba(3, 61, 245, 1.0)'
                                                                                , textDecoration: 'none'
                                                                            }} className='font-size-16'>Please upgrade your plan to Power Search or Power Pro to use this feature. See </strong>
                                                                            <a href={'/plans-and-pricing'}
                                                                                style={{
                                                                                    fontSize: 16
                                                                                    , fontWeight: 'bold'
                                                                                    , color: 'rgba(3, 61, 245, 1.0)'
                                                                                    , textDecoration: 'underline'
                                                                                }}
                                                                            >
                                                                                <span
                                                                                    style={{
                                                                                        fontSize: 16
                                                                                        , fontWeight: 'bold'
                                                                                        , color: 'rgba(3, 61, 245, 1.0)'
                                                                                        , textDecoration: 'underline'
                                                                                    }}
                                                                                >
                                                                                    Plans and Pricing
                                                                                </span>
                                                                            </a>
                                                                        </Tooltip>
                                                                    }>
                                                                        <div>
                                                                            <i className='fa font-size-20-imp fa-address-book cursor-pointer' aria-hidden='true' ></i>
                                                                            <span className='font-size-14 cursor-pointer font-weight-bold' style={{ marginLeft: 10 }} >Contact</span>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>

                                                            </>
                                                            :
                                                            <>
                                                                <div style={{ display: 'flex' }} className='position-relative margin-bottom-20 color-blue'>
                                                                    <i className='fa font-size-20-imp fa-address-book cursor-pointer' aria-hidden='true' onClick={this.handleContactUsShow}></i>
                                                                    <span className='font-size-14 cursor-pointer font-weight-bold' style={{ marginLeft: 10 }} onClick={this.handleContactUsShow}>Contact</span>
                                                                </div>
                                                            </>
                                                    } */}

                                                    <div style={{ display: 'flex' }} className='position-relative margin-bottom-20 color-blue'>
                                                        <i className='fa font-size-20-imp fa-address-book cursor-pointer' aria-hidden='true' onClick={this.handleContactUsShow}></i>
                                                        <span className='font-size-14 cursor-pointer font-weight-bold' style={{ marginLeft: 10 }} onClick={this.handleContactUsShow}>Contact</span>
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-lg-8 col-lg-offset-2 temp'>
                                        <div className='margin-bottom-20 border-bottom-1 color-black'>
                                            <header>
                                                <h2 className='margin-bottom-15 text-decoration-underline'>Description</h2>
                                            </header>
                                            <p className='font-size-14'>{place.properties.description}</p>
                                        </div>
                                    </div>
                                    <div className='col-lg-8 col-lg-offset-2 temp'>
                                        <div className='margin-bottom-20 border-bottom-1 color-black'>
                                            <header>
                                                <h2 className='margin-bottom-15 text-decoration-underline'>Brochures</h2>
                                            </header>
                                            {
                                                place.properties.Brochures &&
                                                <div>
                                                    {
                                                        place.properties.Brochures.map((value, index) => {
                                                            return <div key={index}><a href={value.url} >Download the brochure here.</a></div>
                                                        })
                                                    }
                                                </div>
                                            }

                                        </div>
                                    </div>

                                    <div className='col-lg-8 col-lg-offset-2 temp margin-bottom-20'>
                                        <Features feature={place} />
                                    </div>
                                    <div className='col-lg-8 col-lg-offset-2 temp margin-bottom-20'>
                                        <Mapbox
                                            style={this.state.mapType}
                                            onStyleLoad={map => {
                                                this.onStyleLoad(map);
                                            }}
                                            zoom={[16]}
                                            center={[place.geometry.coordinates[0], place.geometry.coordinates[1]]}
                                            containerStyle={{
                                                height: '300px'
                                            }}
                                        >
                                            <ZoomControl />

                                            {place.properties.propertyType === 'Industrial Sites & Properties' &&
                                                <Layer
                                                    type='symbol'
                                                    id='siteMarker'
                                                    layout={{
                                                        'icon-image': 'siteImage',
                                                        'icon-allow-overlap': false
                                                    }}
                                                    images={siteImages}
                                                >
                                                    {place !== undefined &&
                                                        <Feature
                                                            key={place.rec_id}
                                                            onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                                                            onMouseLeave={this.onToggleHover.bind(this, '')}
                                                            onClick={this.markerClick.bind(this, place)}
                                                            coordinates={place.geometry.coordinates}
                                                        />
                                                    }
                                                </Layer>
                                            }
                                            {place.properties.propertyType === 'Port Terminals' &&
                                                <Layer
                                                    type='symbol'
                                                    id='portTerminalMarker'
                                                    layout={{
                                                        'icon-image': 'portTerminalImage',
                                                        'icon-allow-overlap': false
                                                    }}
                                                    images={portTerminalImages}
                                                >
                                                    {place !== undefined &&
                                                        <Feature
                                                            key={place.rec_id}
                                                            onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                                                            onMouseLeave={this.onToggleHover.bind(this, '')}
                                                            coordinates={place.geometry.coordinates}
                                                        />
                                                    }
                                                </Layer>
                                            }

                                            {place.properties.propertyType === 'Terminals and Transload Facilities' &&
                                                <Layer
                                                    type='symbol'
                                                    id='otherTerminalMarker'
                                                    layout={{
                                                        'icon-image': 'otherTerminalImage',
                                                        'icon-allow-overlap': false
                                                    }}
                                                    images={otherTerminalImages}
                                                >
                                                    {place !== undefined &&
                                                        <Feature
                                                            key={place.rec_id}
                                                            onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                                                            onMouseLeave={this.onToggleHover.bind(this, '')}
                                                            coordinates={place.geometry.coordinates}
                                                        />
                                                    }
                                                </Layer>
                                            }

                                            {place.properties.propertyType === 'Warehouse/Distribution Center' &&
                                                <Layer
                                                    type='symbol'
                                                    id='warehouseMarker'
                                                    layout={{
                                                        'icon-image': 'warehouseImage',
                                                        'icon-allow-overlap': false
                                                    }}
                                                    images={warehouseImages}
                                                >
                                                    {place !== undefined &&
                                                        <Feature
                                                            key={place.rec_id}
                                                            onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                                                            onMouseLeave={this.onToggleHover.bind(this, '')}
                                                            coordinates={place.geometry.coordinates}
                                                        />
                                                    }
                                                </Layer>
                                            }

                                            {
                                                (place.properties.propertyType === 'Industrial Sites & Properties*Port Terminals*Warehouse/Distribution Center' ||
                                                    place.properties.propertyType === 'Industrial Sites & Properties*Terminals and Transload Facilities*Warehouse/Distribution Center' ||
                                                    place.properties.propertyType === 'Industrial Sites & Properties*Port Terminals' ||
                                                    place.properties.propertyType === 'Industrial Sites & Properties*Terminals and Transload Facilities' ||
                                                    place.properties.propertyType === 'Port Terminals*Warehouse/Distribution Center' ||
                                                    place.properties.propertyType === 'Terminals and Transload Facilities*Warehouse/Distribution Center' ||
                                                    place.properties.propertyType === 'Industrial Sites & Properties*Warehouse/Distribution Center') &&

                                                <Layer
                                                    type='symbol'
                                                    id='multiPurposeMarker'
                                                    layout={{
                                                        'icon-image': 'multiPurposeImage',
                                                        'icon-allow-overlap': false
                                                    }}
                                                // images={multiPurposeImages}
                                                >
                                                    {place !== undefined &&
                                                        <Feature
                                                            key={place.rec_id}
                                                            onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
                                                            onMouseLeave={this.onToggleHover.bind(this, '')}
                                                            coordinates={place.geometry.coordinates}
                                                        />
                                                    }
                                                </Layer>
                                            }

                                        </Mapbox>
                                        <div style={{ position: 'absolute', top: 20, left: 20, right: 20, height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Button onClick={this.changeMapStyle}>Change style</Button>
                                        </div>
                                    </div>
                                </div>

                            </>

                        }

                    </section>
                </div>

                {place &&
                    <Modal dialogClassName='custom-contact-modal-95'
                        show={this.state.showContactUs}
                        onHide={this.handleContactUsClose}
                        animation={false}
                    >
                        <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                        <div className='container-fluid'>
                            <div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <div className='col-md-6 col-sm-0 col-xs-0' style={{ backgroundImage: 'url(' + this.getFirstImage(place.properties.image) + ')', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: 'auto' }}>
                                </div>
                                <div className='col-md-6 col-sm-12 col-xs-12' style={{ padding: '3rem' }}>
                                    <h2 className='text-black font-size-30' style={{ paddingTop: '1rem' }}>Contact us</h2>
                                    <h4 className='text-black font-size-16'>Enter your contact details and your message will be sent to the contact name and organization name listed.</h4>
                                    <form className='col-md-12 col-xs-12'>
                                        <Row className='col-md-12 col-xs-12'>
                                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                                <label className='font-weight-bold' htmlFor=''>Contact Name</label>
                                                <input type='text' className='form-control margin-bottom-5' value={place.properties.contactName} readOnly></input>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                                <label className='font-weight-bold' htmlFor=''>Organization Name</label>
                                                <input type='text' className='form-control margin-bottom-5' value={place.properties.organizationName} readOnly></input>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                                <label className='font-weight-bold' htmlFor=''>Your First Name</label>
                                                <input type='text' className='form-control margin-bottom-5' onChange={this.setFirstName} value={this.state.firstname}></input>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                                <label className='font-weight-bold' htmlFor=''>Your Last Name</label>
                                                <input type='text' className='form-control margin-bottom-5' onChange={this.setLastName} value={this.state.lastname}></input>
                                            </div>
                                            <div className='col-md-6 col-sm-12 col-xs-12'>
                                                <label className='font-weight-bold' htmlFor=''>Your Email</label>
                                                <input type='email' className='form-control margin-bottom-5' onChange={this.setEmail} value={this.state.email}></input>
                                                <p style={{ color: '#a94442' }} id="emailValidation"></p>
                                            </div>
                                            <div className='col-md-12 col-sm-12 col-xs-12'>
                                                <label className='font-weight-bold' htmlFor=''>Your Message</label>
                                                <textarea className='form-control margin-bottom-5' onChange={this.setMessage} value={this.state.message}></textarea>
                                            </div>
                                            <div className='col-md-12 col-sm-12 col-xs-12'>
                                                <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleContactUs}>Submit</button>
                                            </div>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal>
                }


                <ConfirmationModal show={this.state.addFavModal} handleClose={this.hideAddFavModal} >
                    <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <div className='event-type info'>
                            <div className='event-indicator '>
                                <SVGIcon />
                            </div>
                        </div>
                        <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                            Place added to favorites
                        </h2>
                        <p className='text-muted font-size-16-imp margin-bottom-0'>
                            Place has been added to favorites successfully!
                        </p>
                    </div>
                </ConfirmationModal>

                <ConfirmationModal show={this.state.deleteFavModal} handleClose={this.hideDeleteFavModal} >
                    <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <div className='event-type warning'>
                            <div className='event-indicator '>
                                <SVGErrorIcon />
                            </div>
                        </div>
                        <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                            Place removed from favorites
                        </h2>
                        <p className='text-muted font-size-16-imp margin-bottom-0'>
                            Place has been removed from favorites successfully!
                        </p>
                    </div>
                </ConfirmationModal>
            </div>
        );
    }
}

export default withRouter(Details);

const ConfirmationModal = ({ handleClose, show, children }) => {
    return (
        <Modal show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
            <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
            <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                    {children}
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                <button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
            </Modal.Footer>
        </Modal>
    );
};

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)

const SVGErrorIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
    </svg>
)