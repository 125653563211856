import React, { Component } from 'react';
import '../custom.css';
import { Button, Breadcrumb, BreadcrumbItem, Form, Modal, FormGroup, OverlayTrigger, Tooltip, ControlLabel, FormControl } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import Header from './Header';

import { Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import 'braintree-web';
import DropIn from 'braintree-web-drop-in-react';
import UserProfile from './UserProfile';
import { CheckoutStrip } from './upgradeCheckoutCard';
import { Elements } from '@stripe/react-stripe-js';
import '../custom_braintree.css';
import PlacesAutocomplete, {
	geocodeByAddress
} from 'react-places-autocomplete';
import InputCheckboxComponent from './InputCheckboxComponent';
import InputBoxComponent from './InputBoxComponent';
import { Cookies } from 'react-cookie';
import HomePageHeader from './HomePageHeader';
import { loadStripe } from '@stripe/stripe-js';
const promise = loadStripe('pk_live_51JQZCTI9aRcuI4Jh6A4nCIJAOhTzdxjXDvRZ1HYx6GsqY1EazrOV5PV3oYL6qUQHbDMboqDjJgeXipyZbzpXhzId00IX8JmTkY');

// const id = planNamesArr[0].id;
// const id=3;
let priceId;
let customerId;

const cookies = new Cookies();

interface PHomePageHeader {
	onRef?: (homePageHeader: HomePageHeader) => void;
	data: any;
}

interface PCheckout {
	history?: Array<string>;
}

class SCheckout {
	constructor() {
	}
}

class CheckoutDowngrade extends Component<PCheckout, any> {
	validator: SimpleReactValidator = null;

	instance;

	constructor(props) {
		super(props);
		this.state = {


			checkbox: false,
			terms1: false,
			order_id: null,
			// clientToken: null,
			// showPlaceSuccessModal: false,
			// showNewRecordModal: false,
			// showDeleteListingModal: false,
			// showPayCardModal: false,
			// deleteRecId: '',
			// coupanOrderSuccess: false,
			// showDeleteListingSuccessModal: false,
			shoppingCartCount: 0,
			couponCodeAvailable: false,
			paywithCard: true,
			//user_email: props.location.state.email,
			//plan_arr: props.location.state.plan_arr,
			//password: props.location.state.password,
			isOrderFree: false,
			payment_type: 'online',
			planArr: localStorage.getItem('planNames') ? JSON.parse(localStorage.getItem('planNames')) : [],
			emailid: localStorage.getItem('UseremailId') ? JSON.parse(localStorage.getItem('UseremailId')) : [],
			// user_email: emailid.email
			sessionPlandata: null,
			subscriptionKey: null,
		}

		// this.setFirstName = this.setFirstName.bind(this);
		// this.setLastName = this.setLastName.bind(this);
		// this.setBusinessName = this.setBusinessName.bind(this);
		// this.setEmail = this.setEmail.bind(this);
		// this.setZipcode = this.setZipcode.bind(this);
		// this.setBillingAddress = this.setBillingAddress.bind(this);
		// this.setPaymentInfo = this.setPaymentInfo.bind(this);
		this.setTermsAndConditions = this.setTermsAndConditions.bind(this);
		//this.proceedToPayment = this.proceedToPayment.bind(this);


	}





	handlePlaceSuccessModal() {
		//this.props.history.push('/my-listing');
		this.props.history.push('/');
	}

	async componentDidMount() {

		const planAarraydata = JSON.parse(sessionStorage.getItem('planNames'));
		if (planAarraydata) {
			this.setState({ sessionPlandata: planAarraydata });
		}
		else {
			this.props.history.push('/');
		}
		var self = this;
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile', {
			email: UserProfile.getEmail(),
		})
			.then(function (response) {
				if (response.status === 200) {
					console.log('get-user-profile', response);
					var data = response.data[0].sw_stripe_subscription_id
					console.log('data==>', data);
					localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
					localStorage.setItem('membershipOptions', response.data[0].options);
					self.setState({
						subscriptionKey: data
					})
				}


			})
			.catch(function (error) {
				console.log(error);
			});
	}

	// autoLogin() {
	// 	try {
	// 		console.log('handle auto login');
	// 		axios.post(process.env.REACT_APP_BASE_URL + '/api/auto-login-user',
	// 			{
	// 				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
	// 				login_email: this.state.user_email,
	// 				login_password: this.state.password,
	// 			}).then((response: {
	// 				status: number
	// 				, data: {
	// 					bookmarks_str: string
	// 					, email: string
	// 					, first_name: string
	// 					, last_name: string
	// 					, max_no_entries: string
	// 					, options: string
	// 					, place_names_arr: string //Array<any>
	// 					, user_type: string
	// 					, token: string
	// 				}
	// 			}) => {
	// 				document.getElementById('login_error').style.display = 'none';
	// 				document.getElementById('verify_email_error').style.display = 'none';
	// 				console.log('autoLogin api response: ', response);

	// 				//this.handleClose();

	// 				if (response.status === 200) {
	// 					let bookmarksCount = 0;
	// 					console.log('response.data')
	// 					console.log(response.data)
	// 					if (response.data.bookmarks_str) {
	// 						const bookmarksArr = response.data.bookmarks_str.split(',');
	// 						bookmarksCount = bookmarksArr.length;
	// 					}
	// 					localStorage.setItem('bookmarks', response.data.bookmarks_str);
	// 					localStorage.setItem('listingNames', response.data.place_names_arr);
	// 					localStorage.setItem('maxNoEntries', response.data.max_no_entries);
	// 					localStorage.setItem('membershipOptions', response.data.options);

	// 					this.handleLoginSuccessResponse(response);
	// 				} else {
	// 					this.setState({ isLoggedIn: false });
	// 					if (response.status === 204) {
	// 						document.getElementById('verify_email_error').style.display = 'block';
	// 						document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
	// 					} else {
	// 						document.getElementById('login_error').style.display = 'block';
	// 						document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
	// 					}
	// 				}
	// 			}).catch((error) => {
	// 				console.log('error auto login api: ', error);
	// 				// handle your error
	// 				document.getElementById('login_error').style.display = 'block';
	// 				document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
	// 			});
	// 	} catch (err) {
	// 		console.log('error in auto login');
	// 		console.error(err);
	// 	}
	// }

	// async buy() {
	// 	try {
	// 		var email = this.state.user_email;
	// 		// var email = UserProfile.getEmail();
	// 		// Send the nonce to your server
	// 		const { nonce } = await this
	// 			.instance
	// 			.requestPaymentMethod();
	// 		await axios.post(process.env.REACT_APP_BASE_URL + '/api/braintree/v1/sandbox',
	// 			{
	// 				paymentMethodNonce: nonce,
	// 				order_id: this.state.order_id,
	// 				email: email,
	// 				order_amount: this.state.order_amount
	// 			}).then(res => {
	// 				console.log(res)
	// 				if (res.status === 200) {
	// 					// this.handleOrderResponse();
	// 					this.autoLogin();
	// 				}
	// 				else {
	// 					console.log('order is failed to update');
	// 					alert('Something went wrong, please try again!');
	// 					// this.props.history.push('/');
	// 				}
	// 			});
	// 	} catch (err) {
	// 		console.log('error in order update');
	// 		console.error(err);
	// 	}
	// }

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
	}

	setTermsAndConditions(event) {
		this.setState({ checkbox: !this.state.checkbox });
	}

	// setFirstName(event) {
	// 	this.setState({ first_name: event.target.value });
	// }

	// setLastName(event) {
	// 	this.setState({ last_name: event.target.value });
	// }

	// setBusinessName(event) {
	// 	this.setState({ business_name: event.target.value });
	// }

	setEmail(event) {
		this.setState({ email: event.target.value });
	}

	// setZipcode(event) {
	// 	this.setState({ zipcode: event.target.value });
	// }

	// setBillingAddress = address => {
	// 	this.setState({ address });

	// }

	// setCoordinates = address => {

	// 	geocodeByAddress(address).then(results => {
	// 		this.setBillingAddress(results[0].formatted_address);
	// 		// console.log(results[0].formatted_address);
	// 	});

	// }

	// proceedToPayment() {
	// 	if (this.validator.allValid()) {
	// 		if (this.state.couponCodeAvailable && this.state.couponCode.toLowerCase() !== 'goindnav') {
	// 			alert('Use coupon code as GOINDNAV');
	// 			return;
	// 		}
	// 		axios.post(process.env.REACT_APP_BASE_URL + '/api/create-order',
	// 			{
	// 				headers: { 'Content-Type': 'application/json' },
	// 				first_name: this.state.first_name,
	// 				last_name: this.state.last_name,
	// 				business_name: this.state.business_name,
	// 				email: this.state.email,
	// 				usr_email: this.state.user_email,
	// 				zipcode: this.state.zipcode,
	// 				address: this.state.address,
	// 				// payment_info: this.state.payment_info,
	// 				order_amount: this.state.order_amount,
	// 				place_ids: localStorage.getItem('planNames') ? localStorage.getItem('planNames') : '',
	// 				order_type: 'membership_plan',
	// 				payment_type: this.state.payment_type,
	// 				plan_id: this.state.planArr[0].id,
	// 				customer_id: 0,
	// 				subscription_id: localStorage.getItem('SubscriptionId'),

	// 			}).then(res => {
	// 				console.log(res)
	// 				if (res.status === 200) {
	// 					// this.setState({
	// 					// 	isLoggedIn: true,
	// 					// 	showPlaceSuccessModal: true,
	// 					// });
	// 					// console.log('order creation successful');
	// 					// console.log('couponCodeAvailable',this.state.couponCodeAvailable);
	// 					// console.log('couponCode',this.state.couponCode);


	// 					//this.setState({ showPlaceSuccessModal: true});
	// 					this.setState({ order_id: res.data.order_id });
	// 					if (this.state.couponCodeAvailable && this.state.couponCode) {
	// 						console.log('inside createCouponOrder');
	// 						this.createCouponOrder();
	// 					} else if (this.state.isOrderFree && this.state.order_amount <= 0) {
	// 						console.log('create order for free amount');
	// 						this.createFreeOrder();
	// 						// this.autoLogin();
	// 					} else {
	// 						console.log('inside buy');
	// 						//this.buy();
	// 						//this.openshowPayCardModal();
	// 						this.loginUser();
	// 						//this.autoLogin();

	// 					}

	// 				}
	// 				else {
	// 					console.log('order creation failed');
	// 					alert('Something went wrong, please try again!')
	// 				}
	// 			}).catch(function (error) {
	// 				console.log('error in order creation');
	// 				alert('Please Make Sure Payment Is Done !')
	// 			});
	// 	}
	// 	else {
	// 		this.validator.showMessages();
	// 		this.forceUpdate();
	// 	}
	// }

	// createFreeOrder = () => {
	// 	try {
	// 		var email = this.state.user_email;
	// 		axios.post(process.env.REACT_APP_BASE_URL + '/api/update-free-order-details',
	// 			{
	// 				order_id: this.state.order_id,
	// 				email: email,
	// 				order_amount: this.state.order_amount,
	// 			}).then(res => {
	// 				console.log(res)
	// 				if (res.status === 200) {
	// 					//this.setState({ showPlaceSuccessModal: true});
	// 					this.autoLogin();
	// 				}
	// 				else {
	// 					console.log('order(0) is failed to update');
	// 					alert('Something went wrong, please try again!');
	// 				}
	// 			});
	// 	} catch (err) {
	// 		console.log('error in order(0) update');
	// 		console.error(err);
	// 	}
	// }

	// // 
	// createCouponOrder = () => {
	// 	try {
	// 		//var email = email;
	// 		console.log('email id in coupan order', this.state.emailid.email)
	// 		const user_email = this.state.emailid.email;

	// 		if (this.state.planArr[0].id == 3) {
	// 			priceId = 'price_1JT60PKHM8yqYUueaSCS4qkV'
	// 		}
	// 		else if (this.state.planArr[0].id == 4) {
	// 			priceId = 'price_1JT61PKHM8yqYUueM6BGGKVA'
	// 		}
	// 		var self = this;
	// 		axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
	// 			email: this.state.emailid.email,

	// 			//customerId:'',
	// 		}).then(function (response) {
	// 			console.log('responce data email', response.data.customer.email);

	// 			customerId = response.data.customer.id
	// 			console.log('customer id:', customerId)
	// 			console.log('price id', priceId)
	// 			//customerId=this.setState({customerId})  
	// 			//create subscription for the customer on the stripe
	// 			axios.post(process.env.REACT_APP_BASE_URL + '/api/create-coupan-order', {
	// 				priceId: priceId,
	// 				customerId: customerId,
	// 			}).then(function (response) {
	// 				console.log('coupan order api resp:', response);
	// 				if (response.status === 200) {

	// 					localStorage.setItem('SubscriptionId', response.data.subscription.id);
	// 					console.log('subscription id for coupan order:', response.data.subscription.id)
	// 					console.log('email id for updating order:', user_email)

	// 					axios.post(process.env.REACT_APP_BASE_URL + '/api/update-subscription-id', {
	// 						usr_email: user_email,
	// 						subscription_id: response.data.subscription.id,
	// 						//customerId:'',
	// 					}).then()

	// 					self.autoLogin();

	// 				}
	// 				else {
	// 					console.log('order is failed to update');
	// 					alert('Something went wrong, please try again!');
	// 				}

	// 			})

	// 		});
	// 	} catch (err) {
	// 		console.log('error in order update');
	// 		console.error(err);
	// 	}
	// }


	// handleOrderResponse = () => {
	// 	console.log('order is updated with success');
	// 	//localStorage.removeItem('listingNames');
	// 	localStorage.removeItem('planNames');
	// 	this.setState({ showPlaceSuccessModal: true });
	// 	var placeOrderDiv = document.getElementById('place-order-div');
	// 	var placeOrderBtn = document.getElementById('place-order-btn');
	// 	placeOrderDiv.removeChild(placeOrderBtn);
	// 	this.setState({
	// 		shoppingCartCount: 0
	// 	});
	// }

	// handleNewRecordModal = () => {
	// 	this.setState({ showNewRecordModal: false });
	// 	localStorage.removeItem('useContactInfo');
	// 	localStorage.removeItem('contactName');
	// 	localStorage.removeItem('contactTitle');
	// 	localStorage.removeItem('contactEmail');
	// 	localStorage.removeItem('contactNo');
	// }

	handleCheckboxChange = (name, value) => {
		this.setState({ [name]: value });
	}

	// confirmDeleteListing = (recId) => {
	// 	this.setState({
	// 		showDeleteListingModal: true,
	// 		deleteRecId: recId
	// 	});
	// }

	// handleDeleteListingModal = () => {
	// 	this.setState({
	// 		showDeleteListingModal: false
	// 	});
	// }
	// openshowPayCardModal = () => {
	// 	this.setState({
	// 		showPayCardModal: true
	// 	});

	// }
	// loginUser = () => {
	// 	const Paymentstatus = localStorage.getItem('PaymentIntent') ? JSON.parse(localStorage.getItem('PaymentIntent')) : [];
	// 	console.log('paymentstatusobj', Paymentstatus.statusofuser.paymentIntent.status)
	// 	if (Paymentstatus.statusofuser.paymentIntent.status == 'succeeded') {
	// 		localStorage.removeItem('PaymentIntent');
	// 		console.log('paymentstatusobj is removed', Paymentstatus.statusofuser.paymentIntent.status)
	// 		this.autoLogin();
	// 	}
	// }
	// handleshowPayCardModal = () => {
	// 	this.setState({
	// 		showPayCardModal: false
	// 	});
	// }

	// deleteListing = () => {
	// 	let deleteRecId = this.state.deleteRecId;

	// 	axios.post(process.env.REACT_APP_BASE_URL + '/api/discard-single-listing', {
	// 		headers: {
	// 			'Content-Type': 'multipart/form-data'
	// 		},
	// 		rec_id: this.state.deleteRecId,
	// 		email: this.state.user_email
	// 	}).then(response => {
	// 		console.log(response)
	// 		if (response.status === 200) {
	// 			var listingNames = [];
	// 			if (localStorage.getItem('planNames')) {
	// 				listingNames = JSON.parse(localStorage.getItem('planNames'));
	// 			}
	// 			var newListingNamesArr = listingNames.filter(function (listingNameObj) { return listingNameObj.id != deleteRecId; });

	// 			this.setState({
	// 				showDeleteListingSuccessModal: true,
	// 				deleteRecId: '',
	// 				showDeleteListingModal: false,
	// 				shoppingCartCount: newListingNamesArr.length
	// 			}, () => {
	// 				if (newListingNamesArr.length > 0) {
	// 					// update listing in localstorage
	// 					console.log('new newListingNamesArr')
	// 					console.log(newListingNamesArr)
	// 					console.log(newListingNamesArr.length)
	// 					localStorage.setItem('planNames', JSON.stringify(newListingNamesArr));
	// 				} else {
	// 					localStorage.removeItem('planNames');
	// 					this.props.history.push('/create');
	// 				}
	// 				this.child.updateShoppingcart()
	// 			})
	// 		} else {
	// 			this.setState({
	// 				showErrorModal: true
	// 			})
	// 		}
	// 	}).catch(error => {
	// 		// handle your error
	// 		console.log(error)
	// 	});
	// }

	// handleDeleteListingSuccessModal = () => {
	// 	this.setState({
	// 		showDeleteListingSuccessModal: false
	// 	})
	// }

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}

	// handleLoginSuccessResponse = response => {

	// 	console.log('order is updated with success');
	// 	localStorage.removeItem('listingNames');

	// 	this.setState({ showPlaceSuccessModal: true });
	// 	var placeOrderDiv = document.getElementById('place-order-div');
	// 	var placeOrderBtn = document.getElementById('place-order-btn');
	// 	placeOrderDiv.removeChild(placeOrderBtn);
	// 	this.setState({
	// 		shoppingCartCount: 0
	// 	});

	// 	console.log('inside handleLoginSuccessResponse', response);
	// 	UserProfile.setEmail(response.data.email);
	// 	UserProfile.setFirstName(response.data.first_name);
	// 	UserProfile.setLastName(response.data.last_name);
	// 	UserProfile.setUserType(response.data.user_type);

	// 	// this.setState({
	// 	// 	isLoggedIn: true,
	// 	// 	showPlaceSuccessModal: true,
	// 	// });
	// 	this.setState({
	// 		isLoggedIn: true
	// 	});

	// 	cookies.set('token', response.data.token);
	// 	console.log(response.data);
	// 	localStorage.removeItem('planNames');
	// 	//this.setState({ showPlaceSuccessModal: true });
	// 	//this.props.history.push('/');
	// }




	handleDowngrade = () => {


		if (this.validator.allValid()) {
			var id = this.state.sessionPlandata.id
			if (id == 3) {
				priceId = 'price_1K2dBhI9aRcuI4Jh6Qr5fxnx'
			}
			else if (id == 4) {
				priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om'
			}
			else if (id == 5) {
				priceId = 'price_1JidsnI9aRcuI4JhRaVojddT'
			}
			else if (id == 6) {
				priceId = 'price_1JidtLI9aRcuI4JhAgcKR8aI'
			}		
			

			const getsubscriptionKey = this.state.subscriptionKey

 
			console.log('getsubscriptionKey', getsubscriptionKey)
			axios.post(process.env.REACT_APP_BASE_URL + '/api/downgrade-user-plan', {
				email: UserProfile.getEmail(),
				planid: id,
				priceId: priceId,
				subscriptionID: getsubscriptionKey,

				//customerId:'',
			})
				.then(function (response) {
					console.log('responce of downgrade', response);
					

				})
				.catch(function (error) {
					console.log(error);
				});
			this.props.history.push('/upgrade-success');
		}
		else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	child: Header = null;


	render() {
		const listingNamesArr = localStorage.getItem('listingNamesArr') ? JSON.parse(localStorage.getItem('listingNamesArr')) : [];


		return (
			<div>
				<Header data={this.props} onRef={ref => (this.child = ref)} shoppingCartCount={this.state.shoppingCartCount} />
				<div id='page-content' style={{ backgroundColor: 'white', paddingBottom: '50px', minHeight: '100vh' }}>
					<div>
						<Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
							<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
							<Link to={{ pathname: '/create' }}><span className='color-white'>Create</span></Link>&nbsp;/&nbsp;
							<BreadcrumbItem active>Checkout</BreadcrumbItem>
						</Breadcrumb>
						<div className='container-fluid' style={{ marginTop: '20px' }}>
							<div className='col-lg-10 col-lg-offset-1 padding-0-xs'>
								<div className='col-lg-5 padding-0-xs'>
									{/* <h1>Billing Details</h1> */}
									{/* <Form style={{ marginTop: '20px' }}>
										<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
										<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
										{
											planData.length > 0 ?
											planData.map((planNameObj) =>
													<div className='col-lg-12 col-xs-12 padding-0-xs' style={{ lineHeight: '40px', marginBottom: 10 }} key={Math.random()}>
															</div>
												) : (
													<React.Fragment></React.Fragment>
												)
										}






									</Form> */}
								</div>
								<div className='col-lg-1'></div>
								<div className='col-lg-8 padding-0-xs'>
									<h1>Your Order</h1>
									<div className='padding-30 padding-15-xs' style={{ border: '2px solid #f4f4f2', borderRadius: '6px' }}>
										{/* <h1>Your Order</h1> */}
										<div className='col-lg-12 col-xs-12 padding-0-xs border-bottom-1' style={{ lineHeight: '40px', marginBottom: 10 }}>
											<div className='col-lg-9 col-xs-8 text-left font-weight-bold pull-left padding-0'>Plan Name</div>
											<div className='col-lg-3 col-xs-4 text-center font-weight-bold pull-right'>Total</div>
										</div>

										<div className='col-lg-12 col-xs-12 padding-0-xs' style={{ lineHeight: '40px', marginBottom: 10 }} key={Math.random()}>
											<div className='col-lg-9 col-xs-8 text-left padding-0'><div className='pull-left'><b style={{ fontSize: 25 }}>{this.state.sessionPlandata ? this.state.sessionPlandata.membership_level_name : ''}</b></div>
											</div>
											<div className='col-lg-3 col-xs-4 text-center pull-right' style={{ fontSize: 25 }}><b>${this.state.sessionPlandata ? this.state.sessionPlandata.membership_level_cost : ''}</b> </div>
										</div>

										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<div className='font-size-13'>Site/facility listings will be invoiced monthly until the listing is deleted or suspended by the user. The initial minimum charge period is three months.</div>
										</div>
										<div className='col-lg-12 col-xs-12 padding-0-xs margin-bottom-20'>
											<InputCheckboxComponent name='terms1' checked={this.state.terms1} onHandleCheckboxChange={this.handleCheckboxChange} label='I agree to the website ' /><Link target="_blank"  to={{ pathname: '/terms-and-conditions' }} ><b> terms and conditions</b></Link><b>.</b>
											{this.validator.message('Terms and Conditions', this.state.terms1, 'accepted')}
										</div>





										<div className='text-center' style={{ marginTop: '10px', marginBottom: '10px' }} id='place-order-div'>
											<button type='button' className='border-radius-25-imp btn btn-default' id='place-order-btn'
												onClick={() => { this.handleDowngrade() }}>
												Downgrade Subscription
											</button>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	handleClose: any;
}

export default withRouter(CheckoutDowngrade);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)
