import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection';
import createlist from "../../public/assets/revslider/assets/CreateListing.svg";
const Index = props => {
    
    return (
        <>
            <section className="section-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <img style={{maxWidht:570,maxHeight:431,marginTop:-70}} src={createlist} className="img-fluid" alt="qloud" />
                        </div>
                        <div style={{marginTop:-20}} className="col-lg-6 col-sm-12 mb-lg-0 mb-5">
                            <TitleSection
                                className='text-left iq-title-box-1'
                                title='Industrial Site and Logistics Marketing Made Easy '
                                description='Industrial Navigator puts your sites and transportation assets in front of active prospects who understand their true value. With advanced filtering capabilities, searchers can quickly find the link between their needs and your sites or transportation assets. On Industrial Navigator, your listings will not drown in a sea of irrelevant residential and commercial listings, because listings are limited to industrial sites, warehouses, distribution centers, ports, terminals and transloads. Map layers highlight the proximity of your property to valuable transportation networks, such as railroads, highways, ports, and pipelines. '
                                titleIcon='' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Index