export enum ClassOne {
	BNSF = 'BNSF',
	CN = 'CN',
	CP = 'CP',
	CSX = 'CSX',
	KCS = 'KCS',
	NS = 'NS',
	UP = 'UP',
}

export default {
	classOne: {
		'BNSF': ['BNSF'], 'BNSF Railway': ['BNSF'],
		'CN': ['CN'], 'Canadian National Railway': ['CN'],
		'CP': ['CP', 'CPRS'], 'Canadian Pacific Railway': ['CP'],
		'CSX': ['CSX', 'CSXT'], 'CSX Transportation': ['CSXT'],
		'KCS': ['KCS'], 'Kansas City Southern Railway': ['KCS'],
		'NS': ['NS'], 'Norfolk Southern Railway': ['NS'],
		'UP': ['UP'], 'Union Pacific Railroad': ['UP'],
	},
	shortLine: {
		'A&R Terminal Railroad': ['ART'],
		'Aberdeen and Rockfish Railroad': ['AR'],
		'Aberdeen, Carolina and Western Railway': ['ACWR'],
		'Acadiana Railway': ['AKDN'],
		'Adams-Warnock Railway': ['AWRY'],
		'Adrian and Blissfield Rail Road': ['ADBF'],
		'Affton Terminal Services Railroad': ['AT'],
		'Ag Valley Railroad': ['AVRR'],
		'Aiken Railway': ['AIKR'],
		'Airlake Terminal Railway': ['ALKT'],
		'Akron Barberton Cluster Railway': ['AB'],
		'Alabama and Gulf Coast Railway': ['AGR'],
		'Alabama and Tennessee River Railway': ['ATN'],
		'Alabama Export Railroad': ['ALE'],
		'Alabama Southern Railroad': ['ABS'],
		'Alabama Warrior Railway': ['ABWR'],
		'Alamo Gulf Coast Railroad': ['AGCR'],
		'Alamo North Texas Railroad': ['ANTX'],
		'Alaska Railroad': ['ARR'],
		'Albany and Eastern Railroad': ['AERC'],
		'Albany Port Railroad': ['APD'],
		'Alexander Railroad': ['ARC'],
		'Aliquippa and Ohio River Railroad': ['AOR'],
		'Allegheny Valley Railroad': ['AVR'],
		'Allentown and Auburn Railroad': ['ALLN'],
		'Alton and Southern Railway': ['ALS'],
		'AN Railway': ['AN'],
		'Angelina and Neches River Railroad': ['ANR'],
		'Ann Arbor Railroad': ['AA'],
		'Apache Railway': ['APA'],
		'Appalachian and Ohio Railroad': ['AO'],
		'Arcade and Attica Railroad': ['ARA'],
		'Arizona and California Railroad': ['ARZC'],
		'Arizona Central Railroad': ['AZCR'],
		'Arizona Eastern Railway': ['AZER'],
		'Arkansas-Oklahoma Railroad': ['AOK'],
		'Arkansas and Missouri Railroad': ['AM'],
		'Arkansas Midland Railroad': ['AKMD'],
		'Arkansas Southern Railroad': ['ARS'],
		'Arkansas, Louisiana and Mississippi Railroad': ['ALM'],
		'Ashland Railway': ['ASRY'],
		'Ashtabula, Carson and Jefferson Railroad': ['ACJR'],
		'AT&L Railroad': ['ATLT'],
		'Atlantic and Western Railway': ['ATW'],
		'Austin Western Railroad': ['AWRR'],
		'Autauga Northern Railroad': ['AUT'],
		'B&H Rail Corporation': ['BH'],
		'Baja California Railroad': ['BJRR'],
		'Baldwin City & Southern Railroad': ['BCSO'],
		'Ballard Terminal Railroad': ['BDTL'],
		'Baton Rouge Southern Railroad': ['BRS'],
		'Batten Kill Railroad': ['BKRR'],
		'Bauxite and Northern Railway': ['BXN'],
		'Bay Colony Railroad': ['BCLR'],
		'Bay Line Railroad': ['BAYL'],
		'Bayway Terminal Switching Company': ['BAWT'],
		'Beech Mountain Railroad': ['BEEM'],
		'Bee Line Railroad': ['BLEX'],
		'Bellingham International Railroad': ['BIRR'],
		'Belt Railway of Chicago': ['BRC'],
		'Belvidere and Delaware River Railway': ['BDRV'],
		'Bessemer and Lake Erie Railroad': ['BLE'],
		'BG&CM Railroad': ['BGCM'],
		'Big 4 Terminal Railroad': ['BFT'],
		'Big Spring Rail System': ['BSR'],
		'Bighorn Divide and Wyoming Railroad': ['BDW'],
		'Birmingham Terminal Railway': ['BHRR'],
		'Bi-State Development Agency': ['BSDA'],
		'Black River and Western Corporation': ['BRW'],
		'Blacklands Railroad': ['BLR'],
		'Blackwell Northern Gateway Railroad': ['BNG'],
		'Bloomer Line': ['BLOL'],
		'Blue Rapids Railway': ['BLRR'],
		'Blue Ridge Southern Railroad': ['BLU'],
		'Bogalusa Bayou Railroad': ['BBAY'],
		'Boise Valley Railroad': ['BVRR'],
		'Boone and Scenic Valley Railroad': ['BSVY'],
		'Border Transload & Transfer': ['BT'],
		'Border Pacific Railroad': ['BOP'],
		'Brandywine Valley Railroad': ['BVRY'],
		'Branford Steam Railroad': ['BRFD'],
		'Brookhaven Rail': ['BHR'],
		'Brownsville and Rio Grande International Railroad': ['BRG'],
		'Buckingham Branch Railroad': ['BB'],
		'Bucyrus Industrial Railroad': ['BIR'],
		'Buffalo and Pittsburgh Railroad': ['BPRR'],
		'Buffalo Southern Railroad': ['BSOR'],
		'Burlington Junction Railway': ['BJRY'],
		'Butte, Anaconda and Pacific Railway': ['BAP'],
		'C&NC Railroad': ['CNUR'],
		'Caldwell County Railroad': ['CWCY'],
		'California Northern Railroad': ['CFNR'],
		'Camden & Southern Railroad': ['CSR'],
		'Camp Chase Railway': ['CAMY'],

		'Caney Fork and Western Railroad': ['CFWR'],
		'Canton Railroad': ['CTN'],
		'Cape Fear Railways': ['CF'],
		'Cape May Seashore Lines': ['CMSL'],
		'Carolina Coastal Railway': ['CLNA'],
		'Carolina Piedmont Railroad': ['CPDR'],
		'Cascade and Columbia River Railroad': ['CSCD'],
		'CaterParrott Railnet': ['CPR'],
		'CBEC Railroad': ['CBEC'],
		'Cedar Rapids and Iowa City Railway': ['CIC'],
		'Central California Traction Company': ['CCT'],
		'Central Indiana and Western Railroad': ['CEIW'],
		'Central Maine and Quebec Railway': ['CMQ'],
		'Central Midland Railway': ['CMR'],
		'Central Montana Rail, Inc.': ['CM'],
		'Central New England Railroad': ['CNZR'],
		'Central New York Railroad': ['CNYK'],
		'Central Oregon and Pacific Railroad': ['CORP'],
		'Central Railroad of Indiana': ['CIND'],
		'Central Railroad of Indianapolis': ['CERA'],
		'Central Washington Railroad': ['CWRR'],
		'CG Railway': ['CGR'],
		'Charlotte Southern Railroad': ['CHS'],
		'Chattahoochee Industrial Railroad': ['CIRR'],
		'Chattooga and Chickamauga Railway': ['CCKY'],
		'Chesapeake and Albemarle Railroad': ['CA'],
		'Chesapeake and Indiana Railroad': ['CKIN'],
		'Chessie Logistics': ['CLCY'],
		'Chestnut Ridge Railroad': ['CHR'],
		'Chicago-Chemung Railroad': ['CCUO'],
		'Chicago Heights Terminal Transfer': ['CHTT'],
		'Chicago Junction Railway': ['CJRY'],
		'Chicago Port & Rail': ['CPRL'],
		'Chicago Port Railroad': ['CPC'],
		'Chicago Rail Link': ['CRL'],
		'Chicago South Shore and South Bend Railroad': ['CSS'],
		'Chicago, Fort Wayne and Eastern Railroad': ['CFE'],
		'Chicago, St. Paul & Pacific Railroad': ['CSP'],
		'Cicero Central Railroad': ['CECR'],
		'Cimarron Valley Railroad': ['CVR'],
		'Cincinnati Eastern Railroad': ['CCER'],
		'City of Prineville Railway': ['COP'],
		'Clackamas Valley Railroad': ['CVLY'],
		'Clarendon and Pittsford Railroad': ['CLP'],
		'Cleveland and Cuyahoga Railroad': ['CCYH'],
		'Cleveland Port Railroad': ['CPRY'],
		'Cleveland Works Railway': ['CWRO'],
		'Clinton Terminal Railroad': ['CTR'],
		'Cloquet Terminal Railroad': ['CTRR'],
		'CMC Railroad': ['CMC'],
		'Coffeen and Western Railroad': ['CAEG'],
		'Colorado and Wyoming Railway': ['CW'],
		'Columbia and Cowlitz Railway': ['CLC'],
		'Columbia Basin Railroad': ['CBRW'],
		'Columbia Business Center Railroad': ['CBCX'],
		'Columbia Terminal Railroad': ['CT'],
		'Columbia & Walla Walla Railway': ['CWW'],
		'Columbus and Chattahoochee Railroad': ['CCH'],
		'Columbus and Greenville Railway': ['CAGY'],
		'Columbus and Ohio River Rail Road': ['CUOH'],
		'Commonwealth Railway': ['CWRY'],
		'Conecuh Valley Railroad': ['COEH'],
		'Connecticut Southern Railroad': ['CSOR'],
		'Coopersville and Marne Railway': ['CPMY'],
		'Coos Bay Rail Link': ['CBRL'],
		'Copper Basin Railway': ['CBRY'],
		'Corpus Christi Terminal Railroad': ['CCPN'],
		'Crab Orchard and Egyptian Railroad': ['COER'],
		'Dakota & Iowa Railroad': ['DAIR'],
		'D&W Railroad': ['DWRV'],
		'Dakota Northern Railroad': ['DN'],
		'Dakota Southern Railway': ['DSRC'],
		'Dakota, Missouri Valley and Western Railroad': ['DMVW'],
		'Dallas, Garland and Northeastern Railroad': ['DGNO'],
		'Dardanelle and Russellville Railroad': ['DR'],
		'De Queen and Eastern Railroad': ['DQE'],
		'Decatur Central Railroad': ['DCC'],
		'Decatur & Eastern Illinois Railroad': ['DREI'],
		'Decatur Junction Railway': ['DT'],
		'Delaware-Lackawanna Railroad': ['DL'],
		'Delray Connecting Railroad': ['DC'],
		'Delmarva Central Railroad': ['DCR'],
		'Delta Southern Railroad': ['DSRR'],
		'Delta Valley and Southern Railway': ['DVS'],
		'Denver Rock Island Railroad': ['DRIR'],
		'Depew, Lancaster and Western Railroad': ['DLWR'],
		'Deseret Power Railroad': ['DPRW'],
		'Detroit Connecting Railroad': ['DCON'],
		'Dover and Delaware River Railroad': ['DD'],
		'Dover & Rockaway River Railroad': ['DRRV'],
		'Dubois County Railroad': ['DCRR'],
		'East Brookfield and Spencer Railroad': ['EBSR'],
		'East Camden and Highland Railroad': ['EACH'],
		'East Cooper and Berkeley Railroad': ['ECBR'],
		'East Erie Commercial Railroad': ['EEC'],
		'East Jersey Railroad': ['EJR'],
		'East Penn Railroad': ['ESPN'],
		'East Tennessee Railway': ['ETRY'],
		'East Troy Electric Railroad': ['ETER'],
		'Eastern Alabama Railway': ['EARY'],
		'Eastern Idaho Railroad': ['EIRR'],
		'Eastern Illinois Railroad': ['EIRC'],
		'Eastern Kentucky Railway Company': ['EKRC'],
		'Eastern Maine Railway': ['EMRY'],
		'Effingham Railroad': ['EFRR'],
		'El Dorado and Wesson Railway': ['EDW'],
		'Elizabethtown Industrial Railroad': ['EZR'],
		'Elk River Railroad': ['ELKR'],
		'Elkhart and Western Railroad': ['EWR'],
		'Ellis and Eastern Company': ['EE'],
		'Escalante Western Railway': ['EW'],
		'Escanaba and Lake Superior Railroad': ['ELS'],
		'Evansville Western Railway': ['EVWR'],
		'Everett Railroad': ['EV'],
		'Fairfield Southern Company': ['FS'],
		'Falls Road Railroad': ['FRR'],
		'Farmrail Corporation': ['FMRC'],
		'FFG&C Railroad': ['FFGC'],
		'Finger Lakes Railway': ['FGLK'],
		'First Coast Railroad': ['FCRD'],
		'Flats Industrial Railroad': ['FIR'],
		'Florida Central Railroad': ['FCEN'],
		'Florida East Coast Railway': ['FEC'],
		'Florida, Gulf and Atlantic Railroad': ['FGAR'],
		'Florida Midland Railroad': ['FMID'],
		'Florida Northern Railroad': ['FNOR'],
		'Fordyce and Princeton Railroad': ['FP'],
		'Fore River Transportation Corporation': ['FRVT'],
		'Fort Smith Railroad': ['FSR'],
		'Fort Worth and Western Railroad': ['FWWR'],
		'Foxville and Northern Railroad': ['FVN'],
		'Fredonia Valley Railroad': ['FVRR'],
		'FreightCar Short Line': ['FCSL'],
		'FTRL Railway': ['FTRL'],
		'Fulton County Railroad': ['FC'],
		'Fulton County Railway': ['FCR'],
		'Galveston Railroad': ['GVSR'],
		'Garden City Western Railway': ['GCW'],
		'Gardendale Railroad': ['GRD'],
		'Gary Railway': ['GRW'],
		'Gateway Eastern Railway': ['GWWE'],
		'Gateway Industrial Railroad': ['GIRR'],
		'Geaux Geaux Railroad': ['GOGR'],
		'Georges Creek Railway': ['GCK'],
		'Georgetown Railroad': ['GRR'],
		'Georgia and Florida Railway': ['GFRR'],
		'Georgia Central Railway': ['GC'],
		'Georgia Northeastern Railroad': ['GNRR'],
		'Georgia Southern Railway': ['GS'],
		'Georgia Southwestern Railroad': ['GSWR'],
		'Georgia Woodlands Railroad': ['GWRC'],
		'Gettysburg and Northern Railroad': ['GET'],
		'Global Container Terminals New York': ['GCTX'],
		'Golden Isles Terminal Railroad': ['GITM'],
		'Golden Triangle Railroad': ['GTRA'],
		'Goose Lake Railway': ['GLRY'],
		'Grafton and Upton Railroad': ['GU'],
		'Grainbelt Corporation': ['GNBC'],
		'Grand Elk Railroad': ['GDLK'],
		'Grand Rapids Eastern Railroad': ['GR'],
		'Grand River Railway': ['GRRL'],
		'Great Lakes Central Railroad': ['GLC'],
		'Great Northwest Railroad': ['GRNW'],
		'Great Walton Railroad': ['GRWR'],
		'Great Western Railway of Colorado': ['GWR'],
		'Central Texas & Colorado River': ['CTXR'],
		'Carlton Train Railway Company': ['CTRW'],
		'Kettle Falls International Railway': ['KFR'],
		'Savannah Industrial Transportaton': ['SIT'],
		'Green Mountain Railroad': ['GMRC'],
		'Greenville and Western Railway': ['GRLW'],
		'Grenada Railway': ['GRYR'],
		'Gulf Coast Switching': ['GCSX'],
		'Hainesport Secondary Railroad': ['HSCR'],
		'Hampton and Branchville Railroad': ['HB'],
		'Hartwell Railroad': ['HRT'],
		'Heart of Georgia Railroad': ['HOG'],
		'Heritage Railroad': ['HR'],
		'Herrin Railroad': ['HRRL'],
		'High Point, Thomasville & Denton Railroad': ['HPTD'],
		'Hilton & Albany Railroad': ['HAL'],
		'Hondo Railway': ['HRR'],
		'Hoffman Transportation': ['HTCX'],
		'Hoosier Southern Railroad': ['HOS'],
		'Housatonic Railroad': ['HRRC'],
		'Huntsville and Madison County Airport Authority': ['HMQR'],
		'Huntsville and Madison County Railroad Authority': ['HMCR'],
		'Huron and Eastern Railway': ['HESR'],
		'Hussey Terminal Railroad': ['HTRC'],
		'Idaho and Sedalia Transportation Company': ['ISR'],
		'Idaho Northern and Pacific Railroad': ['INPR'],
		'Illini Terminal Railroad': ['ITR'],
		'Illinois and Midland Railroad': ['IMRR'],
		'Illinois Railway': ['IR'],
		'Illinois Western Railroad': ['ILW'],
		'Indiana and Ohio Railway': ['IORY'],
		'Indiana Eastern Railroad': ['IERR'],
		'Indiana Harbor Belt Railroad': ['IHB'],
		'Indiana Northeastern Railroad': ['IN'],
		'Indiana Rail Road': ['INRD'],
		'Indiana Southern Railroad': ['ISRR'],
		'Indiana Southwestern Railway': ['ISW'],
		'Iowa Interstate Railroad': ['IAIS'],
		'Iowa & Middletown Railway': ['IMRY'],
		'Iowa Northern Railway': ['IANR'],
		'Iowa River Railroad': ['IARR'],
		'Iowa Southern Railway': ['ISRY'],
		'Iowa Traction Railroad': ['IATR'],
		'Ithaca Central Railroad': ['ITHR'],
		'Jackson & Lansing Railroad': ['JAIL'],
		'Jacksonville Port Terminal Railroad': ['JXPT'],
		'Joppa and Eastern Railroad': ['JE'],
		'Juniata Terminal Company': ['JTFS'],
		'Juniata Valley Railroad': ['JVRR'],
		'Kanawha River Railroad': ['KNWA'],
		'Kanawha River Terminal': ['KRT'],
		'Kankakee, Beaverville and Southern Railroad': ['KBSR'],
		'Kansas and Oklahoma Railroad': ['KO'],
		'Kansas City Transportation Lines': ['KCTL'],
		'Kaw River Railroad': ['KAW'],
		'Kendallville Terminal Railway': ['KTR'],
		'Kennewick Terminal Railroad': ['KET'],
		'Kentucky and Tennessee Railway': ['KT'],
		'Keokuk Junction Railway': ['KJRY'],
		'Kiamichi Railroad': ['KRR'],
		'Kingman Terminal Railroad': ['KGTR'],
		'Kinston and Snow Hill Railroad': ['KSH'],
		'Kiski Junction Railroad': ['KJR'],
		'Klamath Northern Railway': ['KNOR'],
		'KM Railways': ['KM'],
		'Knoxville and Holston River Railroad': ['KXHR'],
		'Kodak Park Railroad': ['KPK'],
		'KWT Railway': ['KWT'],
		'Kyle Railroad': ['KYLE'],
		'Lake Michigan and Indiana Railroad': ['LMIC'],
		'Lake State Railway': ['LSRC'],
		'Lake Superior and Ishpeming Railroad': ['LSI'],
		'Lake Terminal Railroad': ['LT'],
		'Lancaster and Chester Railway': ['LC'],
		'Landisville Railroad': ['LVR'],
		'Lapeer Industrial Railroad': ['LIRR'],
		'LaSalle Railway': ['LSRY'],
		'Laurinburg and Southern Railroad': ['LRS'],
		'Leetsdale Industrial Terminal Railway': ['LIT'],
		'Lehigh Railway': ['LWRY'],
		'Lehigh Valley Rail Management - Bethlehem Division': ['LVRB'],
		'Lehigh Valley Rail Management - Johnstown Division': ['LVRJ'],
		'Lewisburg and Buffalo Creek Railroad': ['LBCX'],
		'Little Kanawha River Railroad': ['LKRR'],
		'Little Rock and Western Railway': ['LRWN'],
		'Little Rock Port Authority Railroad': ['LRPA'],
		'Live Oak Railroad': ['LOR'],
		'Livonia, Avon and Lakeville Railroad': ['LAL'],
		'Longview Switching Company': ['LVSW'],
		'Lorain Northern Railroad': ['LNOR'],
		'Los Angeles Junction Railway': ['LAJ'],
		'Louisiana and Delta Railroad': ['LDRR'],
		'Louisiana and North West Railroad': ['LNW'],
		'Louisiana Southern Railroad': ['LAS'],
		'Louisville and Indiana Railroad': ['LIRC'],
		'Lubbock and Western Railway': ['LBWR'],
		'Lucas Oil Rail Lines': ['LORL'],
		'Luxapalila Valley Railroad': ['LXVR'],
		'Luzerne and Susquehanna Railway': ['LS'],
		'Lycoming Valley Railroad': ['LVRR'],
		'M&B Railroad': ['MNBR'],
		'Madison Railroad': ['CMPA'],
		'Mahoning Valley Railway': ['MVRY'],
		'Maine Northern Railway': ['MNRY'],
		'Manning Rail': ['MANN'],
		'Manufacturers\' Junction Railway': ['MJ'],
		'Marquette Rail, LLC': ['MQT'],
		'Maryland and Delaware Railroad': ['MDDE'],
		'Maryland Midland Railway': ['MMID'],
		'Massachusetts Central Railroad': ['MCER'],
		'Massachusetts Coastal Railroad': ['MC'],
		'Massena Terminal Railroad': ['MSTR'],
		'Meeker Southern Railroad': ['MSN'],
		'Meridian Southern Railway': ['MDS'],
		'MG Rail, Inc.': ['MGRI'],
		'Michigan Shore Railroad': ['MS'],
		'Michigan Southern Railroad': ['MSO'],
		'Mid-Michigan Railroad': ['MMRR'],
		'Middletown and Hummelstown Railroad': ['MIDH'],
		'Middletown and New Jersey Railroad': ['MNJ'],
		'Mineral Range Railroad': ['MRAX'],
		'Minnesota Commercial Railway': ['MNNR'],
		'Minnesota Northern Railroad': ['MNN'],
		'Minnesota Prairie Line, Inc.': ['MPLI'],
		'Minnesota, Dakota and Western Railway': ['MDW'],
		'Mission Mountain Railroad': ['MMT'],
		'Mississippi Central Railroad': ['MSCI'],
		'Mississippi Delta Railroad': ['MSDR'],
		'Mississippi Export Railroad': ['MSE'],
		'Mississippi Southern Railroad': ['MSR'],
		'Mississippi Tennessee Railroad': ['MTNR'],
		'Itawamba Mississippian Railway': ['MSRW'],
		'Missouri and Northern Arkansas Railroad': ['MNA'],
		'Missouri North Central Railroad': ['MNC'],
		'Modesto and Empire Traction Company': ['MET'],
		'Mohawk, Adirondack and Northern Railroad': ['MHWA'],
		'Montana Rail Link': ['MRL'],
		'Morristown and Erie Railway': ['ME'],
		'Moscow, Camden and San Augustine Railroad': ['MCSA'],
		'Mount Hood Railroad': ['MH'],
		'Mount Vernon Terminal Railway': ['MVT'],
		'Napoleon, Defiance & Western Railroad': ['NDW'],
		'Nashville and Eastern Railroad': ['NERR'],
		'Nashville and Western Railroad': ['NWR'],
		'Natchez Railway': ['NTZR'],
		'Naugatuck Railroad Company': ['NAUG'],
		'Northampton Switching Company': ['NDCR'],
		'Nebraska Central Railroad': ['NCRC'],
		'Nebraska Kansas Colorado Railway': ['NKCR'],
		'Nebraska Northwestern Railroad': ['NNW'],
		'Nevada Industrial Switch': ['NISX'],
		'New Castle Industrial Railroad': ['NCIR'],
		'New Century AirCenter Railroad': ['JCAX'],
		'New England Central Railroad': ['NECR'],
		'New England Southern Railroad': ['NEGS'],
		'New Hampshire Central Railroad': ['NHCR'],
		'New Hampshire Northcoast Corporation': ['NHN'],
		'New Hope and Ivyland Railroad': ['NHRR'],
		'New Jersey Rail Carriers, LLC': ['NJRC'],
		'New Jersey Seashore Lines': ['NJSL'],
		'New Orleans and Gulf Coast Railway': ['NOGC'],
		'New Orleans Public Belt Railroad': ['NOPB'],
		'New York and Atlantic Railway': ['NYA'],
		'New York and Lake Erie Railroad': ['NYLE'],
		'New York and Ogdensburg Railway': ['NYOG'],
		'New York New Jersey Rail, LLC': ['NYNJ'],
		'New York, Susquehanna and Western Railway': ['NYSW'],
		'Newburgh and South Shore Railroad': ['NSR'],
		'Nittany and Bald Eagle Railroad': ['NBER'],
		'Norfolk and Portsmouth Belt Line Railroad': ['NPB'],
		'North Carolina and Virginia Railroad': ['NCVA'],
		'North Louisiana & Arkansas Railroad': ['NLA'],
		'North Shore Railroad': ['NSHR'],
		'Northern Lines Railway': ['NLR'],
		'Northern Ohio and Western Railway': ['NOW'],
		'Northern Plains Railroad': ['NPR'],
		'Northwestern Oklahoma Railroad': ['NOKL'],
		'Northwestern Pacific Railroad': ['NWP'],
		'Oakland Global Rail Enterprise': ['OGRE'],
		'Ogeechee Railway': ['OGEE'],
		'Ohi-Rail Corporation': ['OHIC'],
		'Ohio Central Railroad': ['OHCR'],
		'Ohio South Central Railroad': ['OSCR'],
		'Ohio Southern Railroad': ['OSRR'],
		'Ohio Terminal Railway': ['OHIO'],
		'Oil Creek and Titusville Lines, Inc.': ['OCTL'],
		'Old Augusta Railroad': ['OAR'],
		'Olympia and Belmore Railroad': ['OLYO'],
		'Omaha, Lincoln and Beatrice Railway': ['OLB'],
		'Ontario Central Railroad': ['ONCT'],
		'Ontario Midland Railroad': ['OMID'],
		'Orange Port Terminal Railway': ['OPT'],
		'Oregon Eastern Railroad': ['OERR'],
		'Oregon Pacific Railroad': ['OPR'],
		'Oregon Railconnect': ['ORC'],
		'Otter Tail Valley Railroad': ['OTVR'],
		'Ouachita Railroad': ['OUCH'],
		'Owego and Harford Railway': ['OHRY'],
		'Ozark Valley Railroad': ['OVRR'],
		'Pacific Harbor Line, Inc.': ['PHL'],
		'Pacific Sun Railroad': ['PSRR'],
		'Paducah and Illinois Railroad': ['PI'],
		'Paducah and Louisville Railway': ['PAL'],
		'Palouse River and Coulee City Railroad': ['PCC'],
		'Pan Am Railways': ['PAR'],
		'Pan Am Southern': ['PAS'],
		'Panhandle Northern Railroad': ['PNR'],
		'Patriot Woods Railroad': ['PAW'],
		'Pecos Valley Southern Railway': ['PVS'],
		'Pee Dee River Railway': ['PDRR'],
		'Pend Oreille Valley Railroad': ['POVA'],
		'Peninsula Terminal Company': ['PT'],
		'Pennsylvania and Southern Railway': ['PSCC'],
		'Pennsylvania Southwestern Railroad': ['PSWR'],
		'Perry County Railroad': ['PCYR'],
		'Peru Industrial Railroad': ['PIRR'],
		'Pickens Railway, Honea Path Division': ['PICK'],
		'Pioneer Industrial Railroad': ['PRY'],
		'Pioneer Valley Railroad': ['PVRR'],
		'Pittsburgh and Ohio Central Railroad': ['POHC'],
		'Pittsburgh, Allegheny and McKees Rocks Railroad': ['PAM'],
		'Plainsman Switching Company': ['PMSW'],
		'Plainview Terminal Company': ['PTM'],
		'Point Comfort and Northern Railway': ['PCN'],
		'Port Bienville Short Line Railroad': ['PBVR'],
		'Port Harbor Railroad': ['PHRR'],
		'Port Jersey Railroad': ['PJR'],
		'Port Manatee Railroad': ['MAUP'],
		'Port of Beaumont Railroad': ['PBR'],
		'Port of Catoosa Terminal Railroad': ['POCA'],
		'Port of Muskogee Railroad': ['PMR'],
		'Port of Palm Beach District': ['PPBD'],
		'Port of Tucson Railroad': ['POT'],
		'Port Rail, Inc': ['PRLC'],
		'Port Terminal Railroad Association': ['PTRA'],
		'Port Terminal Railroad of South Carolina': ['PTR'],
		'Portland and Western Railroad': ['PNWR'],
		'Portland Terminal Railroad': ['PTRC'],
		'Portland Vancouver Junction Railroad': ['PVJR'],
		'Prescott and Northwestern Railroad': ['PNW'],
		'Progressive Rail, Inc.': ['PGR'],
		'Providence and Worcester Railroad': ['PW'],
		'Puget Sound and Pacific Railroad': ['PSAP'],
		'Quincy Railroad': ['QRR'],
		'Quinwood Coal Company': ['QCC'],
		'Rainer Rail': ['RANR'],
		'Rawhide Short Line': ['RHSL'],
		'R.J. Corman Railroad/Allentown Lines': ['RJCN'],
		'R.J. Corman Railroad/Bardstown Line': ['RJCR'],
		'R.J. Corman Railroad/Carolina Line': ['RJCA'],
		'R.J. Corman Railroad/Central Kentucky Lines': ['RJCC'],
		'R.J. Corman Railroad/Cleveland Line': ['RJCL'],
		'R.J. Corman Railroad/Memphis Line': ['RJCM'],
		'R.J. Corman Railroad/Pennsylvania Lines': ['RJCP'],
		'R.J. Corman Railroad/Tennessee Terminal': ['RJCK'],
		'R.J. Corman Railroad/Texas Lines': ['RJCD'],
		'R.J. Corman Railroad/West Virginia Line': ['RJCV'],
		'R.J. Corman Railroad/Western Ohio Lines': ['RJCW'],
		'Rapid City, Pierre and Eastern Railroad': ['RCPE'],
		'Raritan Central Railway': ['RCRY'],
		'Reading Blue Mountain and Northern Railroad': ['RBMN'],
		'Red River Valley and Western Railroad': ['RRVW'],
		'Republic N&T Railroad': ['NTRY'],
		'Riceboro Southern Railway': ['RSOR'],
		'Richmond Pacific Railroad': ['RPRC'],
		'Rio Valley Switching Company': ['RVSC'],
		'Ripley & New Albany Railroad': ['RNAB'],
		'Riverport Railroad': ['RVPR'],
		'Rochester and Southern Railroad': ['RSR'],
		'Rock and Rail LLC': ['RRRR'],
		'Rockdale, Sandow and Southern Railroad': ['RSS'],
		'Rogue Valley Terminal Railroad': ['RVT'],
		'Republic Short Line': ['RSL'],
		'S&L Railroad': ['SLGG'],
		'Sabine River and Northern Railroad': ['SRN'],
		'Sacramento Southern Railroad': ['SSRR'],
		'Sacramento Valley Railroad': ['SAV'],
		'Salt Lake, Garfield and Western Railway': ['SLGW'],
		'San Antonio Central Railroad': ['SAC'],
		'San Diego and Imperial Valley Railroad': ['SDIY'],
		'San Francisco Bay Railway': ['SFBR'],
		'San Joaquin Valley Railroad': ['SJVR'],
		'San Manuel Arizona Railroad': ['SMA'],
		'San Luis and Rio Grande Railroad': ['SLRG'],
		'San Luis Central Railroad': ['SLC'],
		'San Pedro Valley Railroad': ['SPVR'],
		'Santa Teresa Southern Railroad': ['STSR'],
		'Sand Springs Railway': ['SS'],
		'Sandersville Railroad': ['SAN'],
		'Santa Cruz, Big Trees and Pacific Railway': ['SCBG'],
		'Santa Maria Valley Railroad': ['SMV'],
		'Saratoga and North Creek Railroad': ['SNC'],
		'Savage Bingham and Garfield Railroad': ['SBG'],
		'Savannah Port Terminal Railroad': ['SAPT'],
		'Seaview Railroad': ['SVTX'],
		'Seminole Gulf Railway': ['SGLR'],
		'SEMO Port Railroad': ['SE'],
		'Sequatchie Valley Switching Company': ['SQSC'],
		'Shamokin Valley Railroad': ['SVRR'],
		'Shenandoah Valley Railroad': ['SV'],
		'Sierra Northern Railway': ['SERA'],
		'Sisseton Milbank Railroad': ['SMRR'],
		'SJRE Railroad': ['SJRE'],
		'SMS Rail Lines of New York, LLC': ['SNY'],
		'SMS Rail Service, Inc.': ['SLRS'],
		'South Branch Valley Railroad': ['SBVR'],
		'South Buffalo Railway': ['SB'],
		'South Carolina Central Railroad': ['SCRF'],
		'South Central Florida Express, Inc.': ['SCXF'],
		'South Central Tennessee Railroad': ['SCTR'],
		'South Chicago and Indiana Harbor Railway': ['SCIH'],
		'South Kansas and Oklahoma Railroad': ['SKOL'],
		'South Plains Lamesa Railroad': ['SLAL'],
		'South Plains Switching, Ltd': ['SAW'],
		'Southern California Railroad': ['SCRR'],
		'Southern Electric Railroad Company': ['SERC'],
		'Southern Indiana Railway': ['SIND'],
		'Southern Railroad of New Jersey': ['SRNJ'],
		'Southern Switching Company': ['SSC'],
		'Southwest Gulf Railroad': ['SWG'],
		'Southwest Pennsylvania Railroad': ['SWP'],
		'Southwestern Railroad': ['SW'],
		'Squaw Creek Southern Railroad': ['SCS'],
		'St. Croix Valley Railroad': ['SCXY'],
		'St. Lawrence and Atlantic Railroad': ['SLR'],
		'St. Maries River Railroad': ['STMA'],
		'St. Mary\'s Railroad': ['SM'],
		'St. Marys Railway West': ['SMW'],
		'Stillwater Central Railroad': ['SLWC'],
		'St. Paul and Pacific Northwest Railroad': ['SPPN'],
		'St. Paul and Pacific Railroad': ['SPPR'],
		'Stockton Public Belt Railway': ['SPBT'],
		'Stockton Terminal and Eastern Railroad': ['STE'],
		'Stourbridge Railroad': ['SBRR'],
		'Strasburg Rail Road': ['SRC'],
		'Swan Ranch Railroad': ['SRRR'],
		'Tacoma Rail': ['TMBL'],
		'Tacoma Rail Mountain Division': ['TRMW'],
		'Tazewell and Peoria Railroad': ['TZPR'],
		'Temple and Central Texas Railway': ['TC'],
		'Tennessee Southern Railroad': ['TSRR'],
		'Tennken Railroad': ['TKEN'],
		'Terminal Railroad Association of St. Louis': ['TRRA'],
		'Terminal Railway Alabama State Docks': ['TASD'],
		'Texas & New Mexico Railroad': ['TXN'],
		'Texas and Eastern Railroad': ['TESR'],
		'Texas and Northern Railway': ['TN'],
		'Texas and Oklahoma Railroad': ['TXOR'],
		'Texas Central Business Lines Corporation': ['TCB'],
		'Texas City Terminal Railway': ['TCT'],
		'Texas North Western Railway': ['TXNW'],
		'Texas Northeastern Railroad': ['TNER'],
		'Texas Pacifico Transportation': ['TXPF'],
		'Texas Rock Crusher Railway': ['TXR'],
		'Texas South-Eastern Railroad': ['TSE'],
		'Texas, Gonzales and Northern Railway': ['TXGN'],
		'Thermal Belt Railway': ['TBRY'],
		'Three Notch Railroad': ['TNHR'],
		'Timber Rock Railroad': ['TIBR'],
		'Toledo Junction Railroad': ['TJR'],
		'Toledo, Peoria and Western Railway': ['TPW'],
		'Tomahawk Railway': ['TR'],
		'Tradepoint Rail': ['TPR'],
		'Trona Railway': ['TRC'],
		'Tulsa-Sapulpa Union Railway': ['TSU'],
		'Turners Island, LLC': ['TI'],
		'Twin Cities and Western Railroad': ['TCWR'],
		'Tyburn Railroad': ['TYBR'],
		'Tyner Terminal Railway': ['TYNT'],
		'Union City Terminal Railroad': ['UCTR'],
		'Union County Industrial Railroad': ['UCIR'],
		'Union Railroad': ['URR'],
		'Upper Merion and Plymouth Railroad': ['UMP'],
		'Utah Railway': ['UTAH'],
		'Utah Central Railway': ['UCRY'],
		'V&S Railway': ['VSR'],
		'Valdosta Railway': ['VR'],
		'Vandalia Railroad': ['VRRC'],
		'Ventura County Railroad': ['VCRR'],
		'Vermilion Valley Railroad': ['VVRR'],
		'Vermont Railway': ['VTR'],
		'Vicksburg Southern Railroad': ['VSOR'],
		'Virginia Southern Railroad': ['VSRR'],
		'Wabash Central Railroad': ['WBCR'],
		'Walking Horse Railroad': ['WHRR'],
		'Wallowa Union Railroad Authority': ['WURR'],
		'Warren and Saline River Railroad': ['WSR'],
		'Warren and Trumbull Railroad': ['WTRM'],
		'Washington and Idaho Railway': ['WIR'],
		'Washington County Railroad': ['WACR'],
		'Washington Eastern Railroad': ['WERR'],
		'Washington Royal Line': ['WRL'],
		'Wellsboro and Corning Railroad': ['WCOR'],
		'West Belt Railway': ['WBRW'],
		'West Isle Line': ['WFS'],
		'West Michigan Railroad': ['WMI'],
		'West Shore Railroad Corporation': ['WSRC'],
		'West Tennessee Railroad': ['WTNN'],
		'Western Maryland Scenic Railroad': ['WMSR'],
		'Western New York and Pennsylvania Railroad': ['WNYP'],
		'Western Rail Road': ['WRRC'],
		'Western Washington Railroad': ['WWR'],
		'Wheeling and Lake Erie Railway': ['WE'],
		'White Deer and Reading Railroad': ['WDRR'],
		'Wichita Terminal Association': ['WTA'],
		'Wichita, Tillman and Jackson Railway': ['WTJR'],
		'Willamette Valley Railway': ['WVR'],
		'Wilmington Terminal Railroad': ['WTRY'],
		'Wilmington and Western Railroad': ['WWRC'],
		'Winchester and Western Railroad': ['WW'],
		'Winston-Salem Southbound Railway': ['WSS'],
		'Wiregrass Central Railroad': ['WGCR'],
		'Wisconsin and Southern Railroad': ['WSOR'],
		'Wisconsin Great Northern Railroad': ['WGNR'],
		'Wisconsin Northern Railroad': ['WN'],
		'Wolf Creek Railroad': ['WCKR'],
		'Yakima Central Railroad': ['YCR'],
		'Yadkin Valley Railroad': ['YVRR'],
		'Yellowstone Valley Railroad': ['YSVR'],
		'York Railway': ['YRC'],
		'Youngstown and Austintown Railroad': ['YARR'],
		'Youngstown and Southeastern Railroad': ['YSRR'],
		'Youngstown Belt Railroad': ['YB'],
		'Yreka Western Railroad': ['YW']
	}
}