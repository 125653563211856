import React, { Component } from 'react';

interface PInputImageComponent {
	onHandleImageChange: (name: string, event: any) => void;
	name?: string;
	multiple?: boolean;
}

class SInputImageComponent {
	image: string = '';

	constructor() {
	}
}

class InputImageComponent extends Component<PInputImageComponent, SInputImageComponent> {
	constructor(props: PInputImageComponent) {
		super(props);
		this.state = new SInputImageComponent();
	}

	handleImageChange = (event: any) => {
		if (event.target.files.length) {
			var html = ''
			for (var i = 0; i < event.target.files.length; i++) {
				if (!event.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
					alert('Please select valid image of jpg, jpeg, png or bmp format!');
					return false;
				}
				html += event.target.files[i].name + ' ';
			}
			this.setState({ image: event.target.files });
			// event.currentTarget.parentNode.nextSibling.innerHTML = html;
			this.props.onHandleImageChange(event.target.name, event);
		}
		else {
			this.setState({ image: '' });
			event.currentTarget.parentNode.nextSibling.innerHTML = '';
		}
	}

	render() {
		return (
			<div style={{ position: 'relative', marginBottom: 7 }}>
				<button type='button' className='btn btn-default border-radius-25-imp margin-right-10'>
					Choose File...
					<input type='file' className='custom-file-input' name={this.props.name}
						onChange={this.handleImageChange} multiple={this.props.multiple}
					/>
				</button>
				{/* <span style={{ whiteSpace: 'pre-wrap' }} className='label custom-file-input-label'></span> */}
			</div>
		)
	}
}

export default InputImageComponent;