import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import MetaTags from 'react-meta-tags';
import { withRouter, Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import SimpleReactValidator from 'simple-react-validator';


const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: Header) => void;
    data?: any;
    shoppingCartCount?: number;
    bookmarksCount?: number;
    logo?: string;
    btnBackgroundColor?: string;
    iconColor?: string;
    secondaryColor?: string;
    history: Array<any>;
}


class RedirectUser extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    loginValidator: SimpleReactValidator = null;
    OTPValidator: SimpleReactValidator = null;
    helpers: any = null;

    constructor(props: PHeader) {
        super(props);
        this.state = {
            showLoginModal: false,
            showForgotPasswordModal: false,
            login_email: '',
            login_password: '',
            isLoggedIn: false,
            nextURL: '',
            propertyType: '',
            propertySubType: '',
            fpEmail: '',
            showLoginSuccessModal: false,
            showLogoutSuccessModal: false,
            showRegistrationModal: false,
            fname: '',
            lname: '',
            companyName: '',
            password: '',
            confirm_password: '',
            email: '',
            showRegistrationSuccessModal: false,
            reacatpcha_value: null,
            // shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
            bookmarksCount: props.bookmarksCount ? props.bookmarksCount : 0,
            shoppingCartCount: props.shoppingCartCount ? props.shoppingCartCount : 0,
        }
       
    }

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }

    componentWillMount() {
       this.props.history.push('/view-listings');
    }

    child: Header = null;



    render() {

        return (
            <>
                <MetaTags>
                    <title>Terms And Conditions</title>
                    <meta name='description' content="Description" />
                    <meta property='og:title' content="About us" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property='og:image' content="Image url" />
                </MetaTags>

            </>
        )
    }
}

export default withRouter(RedirectUser);

