import React, { useEffect } from 'react';
import { rev_slider_1_1 } from '../config/revslider';
import RevSlider from '../qloud/RevSlider'
import slider2 from '../public/assets/revslider/assets/slider2.png';
import HomeHeaderImage from '../public/assets/images/work/IndustrialNavMapFinal-01.png';
import HomeButtons from '../../HomeButton'
import HomePageVideo from '../../HomepageVideoelement'

const Index = props => {
    useEffect(() => {
        setTimeout(() => {
            rev_slider_1_1();
        }, 500);
    });
    return (
        <>
            <RevSlider
                revId={"rev_slider_1_1"}
            >
                <rs-slide data-key="rs-2" data-title="Slide" data-anim="ei:d;eo:d;s:1000;r:0;t:fade;sl:0;">
                    <img src={slider2} style={{ marginTop: -80 }} alt="qloud" title="slider2" width="100%" data-parallax="off" className="rev-slidebg image-background-home" data-no-retina />
                    <div className='container'>
                        <div className='adjust-top-homepage'>
                            <div style={{ minWidth: 650 }} className='title-font-size textgyreadventor'>
                                <div style={{ position: 'absolute', fontFamily: 'TeXGyreAdventor-Bold !important' }} className="bottom-left">
                                    <rs-layer id="slider-2-slide-2-layer-0" className="textgyreadventor head-title" data-type="text" data-color="#007bfc" data-rsp_ch="on" data-xy="xo:136px,96px,67px,41px;y:m;yo:-119px,-100px,-274px,-254px;" data-text="w:normal;s:70,49,45,36;l:90,64,48,29;fw:700;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:600;sp:1000;sR:600;" data-frame_999="o:0;st:w;sR:7400;" style={{ zIndex: 8, fontFamily: 'TeXGyreAdventor-Bold !important', visibility: 'visible', textAlign: 'left', lineHeight: '50px', letterSpacing: '0px', marginTop: 0, fontWeight: 700, fontSize: '65px', borderColor: '#4a4a4a', borderStyle: 'none', margin: '0px', borderRadius: 0, padding: 0, color: '#009df5', width: 'auto', height: 'auto', textDecoration: 'none', minHeight: 0, minWidth: 0, transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)', transformOrigin: '50% 50% 0px' }} data-idcheck="true" data-stylerecorder="true"  data-initialised="true">Industrial Site
                                    </rs-layer>
                                </div><br /><br /><br /><br />
                                <div style={{ position: 'absolute', fontFamily: 'TeXGyreAdventor-Bold !important' }} className="bottom-left">
                                    <rs-layer id="slider-2-slide-2-layer-0" className="textgyreadventor" data-type="text" data-color="#007bfc" data-rsp_ch="on" data-xy="xo:136px,96px,67px,41px;y:m;yo:-119px,-100px,-274px,-254px;" data-text="w:normal;s:70,49,45,36;l:90,64,48,29;fw:700;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:600;sp:1000;sR:600;" data-frame_999="o:0;st:w;sR:7400;" style={{ zIndex: 8, fontFamily: 'TeXGyreAdventor-Bold !important', visibility: 'visible', textAlign: 'left', lineHeight: '50px', letterSpacing: '0px', marginTop: 0, fontWeight: 700, fontSize: '50px', borderColor: '#4a4a4a', borderStyle: 'none', margin: '0px', borderRadius: 0, padding: 0, color: 'rgb(20, 33, 73)', width: 'auto', height: 'auto', textDecoration: 'none', minHeight: 0, minWidth: 0, transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)', transformOrigin: '50% 50% 0px' }} data-idcheck="true" data-stylerecorder="true" data-initialised="true">Search Made Easy

                                    </rs-layer>
                                </div><br /><br /><br /><br /><br /><br />
                                <div style={{ position: 'absolute' }} className="bottom-left">
                                    <rs-layer id="slider-2-slide-2-layer-2" data-type="text"
                                        data-color="#4a4a4a" data-rsp_ch="on" data-xy="xo:137px,97px,65px,40px;yo:448px,329px,185px,163px;"
                                        data-text="w:normal;s:20,14,20,12;l:35,24,35,24;" data-frame_0="x:-50,-35,-26,-16;"
                                        data-frame_1="st:1000;sp:1000;sR:1000;" data-frame_999="o:0;st:w;sR:7000;" className='title-text' style={{ zIndex: 10, fontFamily: "Roboto , sans-serif", fontSize: '15px', fontWeight: 300, textDecoration: 'none', width: 'auto', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', filter: 'blur(0px) grayscale(0%) brightness(100%)', opacity: 1, transformOrigin: '50% 50% 0px', color: '#4a4a4a' }}>
                                        Industrial Navigator simplifies the process of searching for and marketing  <br />
                                        the properties and facilities that play a critical role in the  industrial supply chain.<br /><br />
                                        Industrial sites, ports, terminals, transload facilities, warehouses, and distribution <br />
                                        centers are within reach with Industrial Navigator’s  easy-to-use maps and filters.
                                        <br /><br />
                                        <HomePageVideo /><br />

                                        <b style={{ color: 'rgb(0, 157, 245)', fontWeight: 700 }}>Quick Location Search</b>
                                    </rs-layer>
                                </div><br /><br /><br /><br />
                                <div style={{ position: 'absolute', marginTop: 150 }} className="bottom-left">
                                    <HomeButtons />
                                </div>

                            </div>
                            <div style={{ minWidth: '40%' }} className='reduce-title-font-size textgyreadventor' >
                                <div style={{ position: 'absolute', fontFamily: 'TeXGyreAdventor-Bold !important' }} className="bottom-left">
                                    <rs-layer id="slider-2-slide-2-layer-0" className="textgyreadventor head-title" data-type="text" data-color="#007bfc" data-rsp_ch="on" data-xy="xo:136px,96px,67px,41px;y:m;yo:-119px,-100px,-274px,-254px;" data-text="w:normal;s:70,49,45,36;l:90,64,48,29;fw:700;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:600;sp:1000;sR:600;" data-frame_999="o:0;st:w;sR:7400;" style={{ zIndex: 8, fontFamily: 'TeXGyreAdventor-Bold !important', visibility: 'visible', textAlign: 'left', letterSpacing: '0px', fontWeight: 700, fontSize: '45px', borderColor: '#4a4a4a', borderStyle: 'none', margin: '0px', borderRadius: 0, padding: 0, color: '#009df5', width: 'auto', height: 'auto', textDecoration: 'none', minHeight: 0, minWidth: 0, transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)', transformOrigin: '50% 50% 0px' }} data-idcheck="true" data-stylerecorder="true" data-initialised="true">Industrial Site
                                    </rs-layer>
                                </div><br /><br /><br />
                                <div style={{ position: 'absolute', fontFamily: 'TeXGyreAdventor-Bold !important' }} className="bottom-left">
                                    <rs-layer id="slider-2-slide-2-layer-0" className="textgyreadventor" data-type="text" data-color="#007bfc" data-rsp_ch="on" data-xy="xo:136px,96px,67px,41px;y:m;yo:-119px,-100px,-274px,-254px;" data-text="w:normal;s:70,49,45,36;l:90,64,48,29;fw:700;" data-frame_0="x:-50,-35,-26,-16;" data-frame_1="st:600;sp:1000;sR:600;" data-frame_999="o:0;st:w;sR:7400;" style={{ zIndex: 8, fontFamily: 'TeXGyreAdventor-Bold !important', visibility: 'visible', textAlign: 'left', letterSpacing: '0px', fontWeight: 700, fontSize: '40px', borderColor: '#4a4a4a', borderStyle: 'none', margin: '0px', borderRadius: 0, padding: 0, color: 'rgb(20, 33, 73)', width: 'auto', height: 'auto', textDecoration: 'none', minHeight: 0, minWidth: 0, transform: 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)', transformOrigin: '50% 50% 0px' }} data-idcheck="true" data-stylerecorder="true" data-initialised="true">Search Made Easy
                                    </rs-layer>
                                </div><br /><br /><br /><br /><br /><br />
                                <div className="bottom-left margin-top-30px">
                                    <rs-layer id="slider-2-slide-2-layer-2" data-type="text"
                                        data-color="#4a4a4a" data-rsp_ch="on" data-xy="xo:137px,97px,65px,40px;yo:448px,329px,185px,163px;"
                                        data-text="w:normal;s:20,14,20,12;l:35,24,35,24;" data-frame_0="x:-50,-35,-26,-16;"
                                        data-frame_1="st:1000;sp:1000;sR:1000;" data-frame_999="o:0;st:w;sR:7000;" className='title-text' style={{ zIndex: 10, fontFamily: "Roboto , sans-serif", fontSize: '15px', fontWeight: 300, textDecoration: 'none', width: 'auto', minHeight: '0px', minWidth: '0px', maxHeight: 'none', maxWidth: 'none', filter: 'blur(0px) grayscale(0%) brightness(100%)', opacity: 1, transformOrigin: '50% 50% 0px', color: '#4a4a4a' }}>
                                        Industrial Navigator simplifies the process of searching for and marketing the properties and facilities that play a critical role in the  industrial supply chain.<br /><br />
                                        Industrial sites, ports, terminals, transload facilities, warehouses, and distribution
                                        centers are within reach with Industrial Navigator’s  easy-to-use maps and filters.
                                        <br /><br />
                                        <HomePageVideo /><br />
                                        <b style={{ color: 'rgb(0, 157, 245)', fontWeight: 600 }}>Quick Location Search</b>

                                    </rs-layer>
                                </div>
                                <div style={{ marginTop: 0 }} className="bottom-left">
                                    <HomeButtons />
                                </div>
                            </div>
                        </div>


                        <div className='homepage-image float-right' style={{ position: 'absolute', top: '5%', marginLeft: 650 }} >
                            <img src={HomeHeaderImage} className='homepage-image' alt="IMG" />
                        </div>
                    </div>
                </rs-slide>
            </RevSlider>
        </>
    );
}

export default Index