import React, { Component } from 'react';
import { Modal, Breadcrumb, Tab, Tabs, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import HomePageFooter from './HomePageFooter';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import { FaBeer } from 'react-icons/fa';
class AboutUs extends Component {

    constructor(props) {
        super(props)
    }
    async componentDidMount() {

        sessionStorage.removeItem('planNames')

    }

    render() {
        return (
            <>
                <MetaTags>
                    <title>Success</title>
                    <meta name='description' content="Description" />
                    <meta property='og:title' content="About us" />
                    <meta property='og:image' content="Image url" />
                </MetaTags>

                <Header data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 700, marginLeft: 15, marginRight: 15 }}>
                    <div className="justify-content-center">
                        <div className='col-lg-10 col-md-12 col-sm-12 padding-0'>
                            <div className="content">
                                <br /><br />
                                <h1 className="content_area">Welcome!
                                </h1>
                                <p  className="para_content">
                                    Thanks for subscribing to Industrial Navigator, a unique web tool that simplifies the process of finding and marketing industrial properties and logistics assets.<br /><br />

                                    Industrial Navigator is powered by PLG Consulting, which provides custom logistics and supply chain consulting for the industrial economy.

                                    We welcome your feedback. We are here to help if you encounter any problems or need additional advice. Contact us at info@industrialnavigator.com.</p><br /><br />
                                <Link style={{ margin: 30 }} to={{ pathname: '/create-listings' }} ><button style={{ marginTop: 20 }} type='button' className='btn btn-default border-radius-25-imp'  >Create a listing</button></Link>
                                <Link style={{ margin: 30 }} to={{ pathname: '/view-listings' }} ><button style={{ marginTop: 20 }} type='button' className='btn btn-default border-radius-25-imp'  >Browse  listings</button></Link><br /><br />

                                {/* <FontAwesomeIcon icon="fa-regular fa-location-dot" /> */}

                                {/* <i className='fa fa-star fs-30'
                                    style={{ marginTop: "-10px", color: this.props.iconColor ? this.props.iconColor : '#053359' }}
                                    aria-hidden='true'
                                >
                                </i>
                                <FaBeer />
                                <i class="fa-solid fa-location-dot"></i> */}
                            </div>
                        </div>
                    </div>
                </section>

                <HomePageFooter />
            </>
        )
    }
}

export default withRouter(AboutUs);