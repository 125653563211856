import React, { useEffect, useState } from "react";
import Header from '../HeaderLTE';
import SideBar from '../SideBarLTE';
import Footer from '../Footer';
import { useHistory } from 'react-router'
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import { Modal } from 'react-bootstrap';


function EditPageDetails(props) {

    const SVGIcon = () => (
        <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
            <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
        </svg>
    )

    const history = useHistory();
    //console.log("ID : ", props.match.params.id);
    const [PageName, setPageName] = useState("")
    const [PageURL, setPageURL] = useState("")
    const [PageMetaRobots, setPageMetaRobots] = useState("")
    const [PageDiscription, setPageDiscription] = useState("")
    const [PageTitle, setPageTitle] = useState("")

    const hadleInput = async (event) => {
        event.preventDefault();
        console.log(event);
        if ([event.target.name] == "PageName") {
            setPageName(event.target.value)
        }
        else if ([event.target.name] == "PageURL") {
            setPageURL(event.target.value)
        }
        else if ([event.target.name] == "PageMetaRobots") {
            setPageMetaRobots(event.target.value)
        }
        else if ([event.target.name] == "PageTitle") {
            setPageTitle(event.target.value)
        }
        else if ([event.target.name] == "PageDiscription") {
            setPageDiscription(event.target.value)
        }
    }
    const [success, setSuccess] = useState(false);

    const handleClose = () => {
        setSuccess(false);
        history.push('/seomodule')
    }
    const FormSubmit = () => {

        axios.post(process.env.REACT_APP_BASE_URL + '/api/update-page-seo-info', {
            headers: { 'Content-Type': 'application/json' },
            PageName: PageName,
            id: props.match.params.id,
            PageURL: PageURL,
            PageTitle: PageTitle,
            PageMetaRobots: PageMetaRobots,
            PageDiscription: PageDiscription
        })
            .then(function (response) {
                console.log(response.status)
                if (response.status != 200) {
                    alert("Something went wrong ")
                }
                else {
                    setSuccess(true);
                }

            })
            .catch(function (error) {
                console.log(error);
                alert("Something went wrong ")
            });

    }
    useEffect(() => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/get-page-seo-details', {
            headers: {
                'Content-Type': 'application/json',
            },
            id: props.match.params.id,
        }).then(response => {
            // console.log(response.data[0])
            setPageName(response.data[0].PageName)
            setPageURL(response.data[0].PageUrl)
            setPageMetaRobots(response.data[0].PageMetaRobot)
            setPageDiscription(response.data[0].PageMetaDiscription)
            setPageTitle(response.data[0].PageTitle)

        })
            .catch(function (error) {
                console.log(error);
            });
    }, [])



    return (
        <div style={{ backgroundColor: '#222D32' }}>
            <Header />
            <SideBar />

            <Modal show={success} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                    <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <div className='event-type info'>
                            <div className='event-indicator '>
                                <SVGIcon />
                            </div>
                        </div>
                        <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                            Page Details Updated
                        </h2>
                        <p className='text-muted font-size-16-imp margin-bottom-0'>
                            Page Details is Updated successfully.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                    <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className='content-wrapper background-white'>
                <section className='content-header'>
                    <div className='row'>
                        <div className='col-md-offset-1 col-md-10'>
                            <div className='col-md-12'>
                                <div style={{ flex: '1 0 auto', color: 'rgba(0,0,0,.87)', fontSize: 22, fontWeight: 400, marginTop: 15, marginBottom: 30 }}>Edit Page Details</div>

                                <form method="post">
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                                <label>Page Name</label>
                                                <input type="text"
                                                    name='PageName'
                                                    value={PageName}
                                                    onChange={hadleInput} className="form-control" />


                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                            <label>Page URL</label>
                                                <input type="text"
                                                    name='PageURL'
                                                    value={PageURL}
                                                    onChange={hadleInput} className="form-control" />


                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                            <label>Page Title</label>
                                                <input type='text'
                                                    name='PageTitle'
                                                    style={{ height: 40 }}
                                                    value={PageTitle}
                                                    onChange={hadleInput}
                                                    className="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="row">
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                            <label>Page Meta Robots</label>
                                                <input type='text'
                                                    name='PageMetaRobots'
                                                    style={{ height: 40 }}
                                                    value={PageMetaRobots}
                                                    onChange={hadleInput}
                                                    className="form-control" />

                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='form-group'>
                                            <label>Page Meta Description</label>
                                                <input type='textarea'
                                                    name='PageDiscription'
                                                    style={{ height: 40 }}
                                                    value={PageDiscription}
                                                    onChange={hadleInput}
                                                    className="form-control" />

                                            </div>
                                        </div>

                                    </div>


                                    <div className='col-md-8 text-center' style={{ paddingTop: 20 }}>
                                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={FormSubmit}>Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </div>
    )
}
export default withRouter(EditPageDetails)