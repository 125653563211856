import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import ReCAPTCHA from 'react-google-recaptcha';
const recaptchaRef = React.createRef();

function Step1New(props) {
    const validator = new SimpleReactValidator({
        element: (message, className) => <div className='text-danger'>{message}</div>
    });
      
    const onRecaptchaChange = event => {
        console.log('Captcha value: ', event);
        props.setState("reacatpcha_value",event);
        console.log('remove class');
        const regi_errors = document.getElementById('regi_errors');
        regi_errors.innerHTML = '';
        document.getElementById("regi_errors").classList.remove("alert-danger"); 
    };

    const onSubmit = data => {
        var formData = props.state;
        console.log('form submit: ',formData);
    };

    return (
        /*marginLeft: '10%',*/

       <form
                id='slider-subscribe-form'
                style={{ width: '70%', paddingBottom: '10px', margin: '0 auto' }}
                className='nobottommargin reduce-margin-form'
            >
                <div className='text-left color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>
                    Enter the following information below and proceed to the questionnaire.
                </div>
                <div id='regi_errors' className='margin-top-15 text-center'></div>
                <div style={{width:"100%"}} className='input-group margin-top-15 col-lg-12 col-md-12 col-sm-12'>
                    <input
                        type='text'
                        name='fname'
                        className='form-control input-lg not-dark required email'
                        placeholder='Enter your First Name..'
                        onChange={props.handleChange}
                        value={props.getState("fname", "")}
                    />
                </div>
                {validator.message('first name', props.getState("fname", ""), 'required')}
                
                <div style={{width:"100%"}}
                    className='input-group margin-top-15 col-lg-12 col-md-12 col-sm-12'
                >
                    <input
                        type='text'
                        name='lname'
                        className='form-control input-lg not-dark required email'
                        placeholder='Enter your Last Name..'
                        onChange={props.handleChange}
                        value={props.getState("lname", "")}
                    />
                </div>
                

                <div style={{width:"100%"}}
                    className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'
                >
                    <input
                        type='text'
                        name='companyName'
                        className='form-control input-lg not-dark required email'
                        placeholder='Enter your Company Name..'
                        onChange={props.handleChange}
                        value={props.getState("companyName", "")}
                    />
                </div>
               

                <div style={{width:"100%"}}
                    className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'
                >
                    <input
                        type='email'
                        name='email'
                        className='form-control input-lg not-dark required email'
                        placeholder='Enter your Email Address..'
                        onChange={props.handleChange}
                        value={props.getState("email", "")}
                    />
                </div>
                

                <div style={{width:"100%"}}
                    className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'
                >
                    <input
                        type='password'
                        name='password'
                        className='form-control input-lg not-dark required email'
                        placeholder='Enter your Password..'
                        onChange={props.handleChange}
                        value={props.getState("password", "")}
                    />
                </div>
                

                <div style={{width:"100%"}}
                    className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'
                >
                    <input
                        type='password'
                        name='confrim_password'
                        className='form-control input-lg not-dark required email'
                        placeholder='Confirm Password..'
                        onChange={props.handleChange}
                        value={props.getState("confrim_password", "")}
                    />
                </div>
               
                <ReCAPTCHA
                    ref={ recaptchaRef }
                    // size='invisible'
                    className='margin-top-15'                    
                    onChange={ onRecaptchaChange }
                    //sitekey= "6LejQAMcAAAAADVnddekcOp3p42uaeAEDp6qqNQA"
                    sitekey={ process.env.REACT_APP_RECAPTCHA_TOKEN }
                    
                />

                {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    // size='invisible'
                    className='margin-top-15'
                    onChange={this.onRecaptchaChange.bind(this)}
                    sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
                />
                {this.validator.message('captcha', this.state.reacatpcha_value, 'required')}

                <div
                    className='col_full center padding-top-20'
                >
                    <button
                        className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp'
                        type='button'
                        name='template-contactform-submit'
                        value='submit'
                        onClick={this.registerUser.bind(this)}
                    >
                        Register
                    </button>
                </div> */}
            </form>
  );
}

export default Step1New;
