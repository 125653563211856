import React, { Component, useEffect, useState } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip, Container, Row, Card, Tabs, Tab, Image, Panel } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import InputMultiSelectComponent from '../InputMultiSelectComponent';
import { LabelValue, sortArray, removeDuplicatesFromArr } from '../../constant/Types';
import InputCheckboxComponent from '../InputCheckboxComponent';
import { Link } from 'react-router-dom';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';


function Step2New(props) {
	const validator = new SimpleReactValidator({
		element: (message, className) => <div className='text-danger'>{message}</div>
	});

	const colorData = [
		{ value: 'ocean', label: 'Ocean', color: '#00B8D9', isFixed: true },
		{ value: 'blue', label: 'Blue', color: '#0052CC', isDisabled: true },
		{ value: 'purple', label: 'Purple', color: '#5243AA' },
		{ value: 'red', label: 'Red', color: '#FF5630', isFixed: true },
		{ value: 'orange', label: 'Orange', color: '#FF8B00' },
		{ value: 'yellow', label: 'Yellow', color: '#FFC400' },
		{ value: 'green', label: 'Green', color: '#36B37E' },
		{ value: 'forest', label: 'Forest', color: '#00875A' },
		{ value: 'slate', label: 'Slate', color: '#253858' },
		{ value: 'silver', label: 'Silver', color: '#666666' },
	];

	const [questionOneArr, selectquestionOneArr] = useState([]);
	const [questionTwoArr, setQuestionTwoArr] = useState([]);
	const [questionThreeArr, setQuestionThreeArr] = useState([]);
	const [questionFourArr, setQuestionFourArr] = useState([]);
	const [questionFiveArr, setQuestionFiveArr] = useState([]);
	const [questionSixArr, setQuestionSixArr] = useState([]);
	const [checkboxone, setCheckboxone] = useState(false);
	const [checkboxtwo, setCheckboxtwo] = useState(false);
	const [checkboxthree, setCheckboxthree] = useState(false);
	const [checkboxthreelabel, setCheckboxthreelabel] = useState('I agree to the <a href="#">Privacy Policy</a> and <a href="#"Terms and Conditions</a> of Industrial Navigator');
	var formData = props.state;
	// console.log("value of conferm password is:", formData.confrim_password)
	// if (formData.password != formData.confrim_password) {
	// 	alert("Password does not match")
	// 	props.jump(1)
	// }

	//console.log("props data",props.state.reacatpcha_value)

	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

	// 	if (!pattern.test(input["email"])) {

	// 	  isValid = false;

	// 	  errors["email"] = "Please enter valid email address.";

	// 	}

	//   }

	if (formData.fname == "" || formData.fname == null) {
		alert("Please enter your first name.")
		props.jump(1)
	} else if (formData.lname == "" || formData.lname == null) {
		alert("Please enter your last name.")
		props.jump(1)
	} else if (formData.companyName == "" || formData.companyName == null) {
		alert("Please enter your company name.")
		props.jump(1)
	} else if (formData.email == "" || formData.email == null) {
		alert("Please enter your email.")
		props.jump(1)
	} else if (!pattern.test(formData.email)) {
		alert("Please enter your valid email.")
		props.jump(1)
	} else if (formData.password == "" || formData.password == null) {
		alert("Please enter your password.")
		props.jump(1)
	} else if (formData.confrim_password == "" || formData.confrim_password == null) {
		alert("Please enter your confirm password.")
		props.jump(1)
	} else if (formData.password != formData.confrim_password) {
		alert("The passwords you entered do not match.")
		props.jump(1)
	}

	sessionStorage.removeItem('one')
	sessionStorage.removeItem('three')
	sessionStorage.removeItem('two')
	sessionStorage.removeItem('four')

	//const oneArray  = JSON.parse(sessionStorage.getItem('one'))

	// var rrrr = []
	// const a = oneArray.map((itm)=>{
	// 	return rrrr.push(itm.value)
	// })

	// const dd = JSON.stringify(a)
	// const bbb =dd.replace(/[\[\]']+/g,'')
	// const tttt =bbb.replace(/['"]+/g, '')

	// console.log("tttt",tttt)

	useEffect(() => {

		// localStorage.removeItem('questionTwoArr');
		// localStorage.removeItem('questionThreeArr');
		// localStorage.removeItem('questionFourArr');
		// localStorage.removeItem('checkboxone');
		// localStorage.removeItem('checkboxtwo');
		selectquestionOneArr([{ 'label': 'Industrial Sites & Properties', 'value': 'Industrial Sites & Properties' },
		{ 'label': 'Port Terminals', 'value': 'Port Terminals' },
		{ 'label': 'Terminals and Transload Facilities', 'value': 'Terminals and Transload Facilities' },
		{ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' }
		]);

		setQuestionTwoArr([{ 'label': 'Search for sites, terminals, warehouses or facilities', 'value': 'Search for sites, terminals, warehouses or facilities' },
		{ 'label': 'Purchase, lease or contract for sites, terminals, warehouses or facilities', 'value': 'Purchase, lease or contract for sites, terminals, warehouses or facilities' },
		{ 'label': 'List and promote sites, terminals, warehouse or facilities', 'value': 'List and promote sites, terminals, warehouse or facilities' },
		{ 'label': 'View maps showing transportation networks and assets', 'value': 'View maps showing transportation networks and assets' },		
		]);

		setQuestionThreeArr([{ 'label': 'Immediately', 'value': 'Immediately' },
		{ 'label': '', 'value': '' },
		{ 'label': '3-6 Months', 'value': '3-6 Months' },
		{ 'label': 'I am just browsing', 'value': 'I am just browsing' },
		]);

		setQuestionFourArr([{ 'label': 'Class I Railroads', 'value': 'Class I Railroads' },
		{ 'label': 'Short Line Railroads', 'value': 'Short Line Railroads' },
		{ 'label': 'Intermodal Terminals', 'value': 'Intermodal Terminals' },
		{ 'label': 'Crude Oil Pipelines', 'value': 'Crude Oil Pipelines' },
		{ 'label': 'Petroleum Products Pipelines', 'value': 'Petroleum Products Pipelines' },
		{ 'label': 'Major Ports', 'value': 'Major Ports' },
		{ 'label': 'Roadways', 'value': 'Roadways' },
		{ 'label': 'Other (please specify)', 'value': 'Other (please specify)' },
		
		]);

		setQuestionFiveArr([{ 'label': 'Advertisement', 'value': 'Advertisement' },
		{ 'label': 'eNewsletter', 'value': 'eNewsletter' },
		{ 'label': 'Internet Search', 'value': 'Internet Search' },
		{ 'label': 'Networking Event', 'value': 'Networking Event' },
		{ 'label': 'PLG Consulting Client', 'value': 'PLG Consulting Client' },
		{ 'label': 'Referral - Industrial Navigator Client', 'value': 'Referral - Industrial Navigator Client' },
		{ 'label': 'Referral - Partner/Affiliate', 'value': 'Referral - Partner/Affiliate' },
		{ 'label': 'Social Media', 'value': 'Social Media' },
		{ 'label': 'Speaking Engagement', 'value': 'Speaking Engagement' },
		{ 'label': 'Other', 'value': 'Other' },
		]);

		setQuestionSixArr([{ 'label': 'Immediately', 'value': 'Immediately' },
		{ 'label': '1-3 Months', 'value': '1-3 Months' },
		{ 'label': '3-6 Months', 'value': '3-6 Months' },
		{ 'label': 'I am just browsing', 'value': 'I am just browsing' },
		]);
		props.setState("question_1", "How did you find Industrial Navigator?");
	}, []);

	console.log("props", props.state.questionOneArr)


	const handleMultiSelectOne = (name, valueArr) => {
		console.log('on handleMultiSelect: ', name, valueArr);
		var questionOneArr = {
			name: name,
			valueArr: valueArr

		}
		// localStorage.setItem('questionOneArr', JSON.stringify(questionOneArr));	
		sessionStorage.setItem('one', JSON.stringify(valueArr))
		// props.setState('selectquestionOneArr',valueArr);
	}
	const handleMultiSelectTwo = (name, valueArr) => {
		console.log('on handleMultiSelect: ', name, valueArr);
		var questionTwoArr = {
			name: name,
			valueArr: valueArr

		}
		// localStorage.setItem('questionTwoArr', JSON.stringify(questionTwoArr));	
		sessionStorage.setItem('two', JSON.stringify(valueArr))
		//props.setState("questionOneArr",valueArr);
	}
	const handleMultiSelectThree = (name, valueArr) => {
		console.log('on handleMultiSelect: ', name, valueArr);
		var questionThreeArr = {
			name: name,
			valueArr: valueArr

		}
		// localStorage.setItem('questionThreeArr', JSON.stringify(questionThreeArr));	
		sessionStorage.setItem('three', JSON.stringify(valueArr))
		//props.setState("questionOneArr",valueArr);
	}
	const handleMultiSelectFour = (name, valueArr) => {
		console.log('on handleMultiSelect: ', name, valueArr);
		var questionFourArr = {
			name: name,
			valueArr: valueArr

		}
		localStorage.setItem('questionFourArr', JSON.stringify(questionFourArr));
		sessionStorage.setItem('four', JSON.stringify(valueArr))
		//props.setState("questionOneArr",valueArr);
	}


	// const handleCheckboxChange = (name, value) => {
	// 	console.log('handle checbox select',name, value);
	// 	if(name=="checkboxone"){
	// 		props.setState("checkboxone",value);
	// 	}else if(name=="checkboxtwo"){
	// 		props.setState("checkboxtwo",value);
	// 	}else{
	// 		props.setState("checkboxthree",value);
	// 	}
	// }
	const handleCheckboxChange = (name, value) => {
		console.log('handle checbox select', name, value);
		if (name == "checkboxone") {
			props.setState("checkboxone", value);
			//localStorage.setItem('checkboxone', JSON.stringify(value));
		} else if (name == "checkboxtwo") {
			props.setState("checkboxtwo", value);
			//localStorage.setItem('checkboxtwo', JSON.stringify(value));
		}
	}

	const animatedComponents = makeAnimated();

	const handleDropdownChange = (value, name) => {
		console.log('handleDropdownChange')
		console.log(name)
		console.log(value)

		var questionFourArr = {
			name: name,
			value: value

		}
		props.setState(`${name}`, value);
	}

	return (
		<form
			id='questionaire-form'
			style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }}
			className='nobottommargin reduce-margin-form'
		>
			<div
				className='text-left color-black font-size-18 margin-bottom-20'
				style={{ color: '#474747' }}
			>
				Membership Questionnaire
			</div>
			<div id='regi_errors' className='margin-top-15'></div>


			<div
				className='input-group margin-top-15 col-lg-12'
			>
				<div className='margin-30 padding-15-xs clear-both' id='create-listing-form'>
					<Row>

						<div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>I am interested in</label>
							<Select
								closeMenuOnSelect={false}
								components={animatedComponents}
								name='contselectquestionOneArrent'
								isMulti
								options={sortArray(questionOneArr)}
								value={props.state.selectquestionOneArr}
								onChange={(value) => handleDropdownChange(value, 'selectquestionOneArr')}
							/>
						</div>

						<div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
						<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>I am looking to</label>
						<Select
							closeMenuOnSelect={false}
							components={animatedComponents}
							name='selectquestionTwoArr'
							isMulti
							options={sortArray(questionTwoArr)}
							value={props.state.selectquestionTwoArr}
							onChange={(value) => handleDropdownChange(value, 'selectquestionTwoArr')}
						/>
						</div>
						

						<div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>Which of the following maps would interest you most:</label>
							<Select
								closeMenuOnSelect={false}
								components={animatedComponents}
								name='selectquestionThreeArr'
								isMulti
								options={questionFourArr}
								value={props.state.selectquestionThreeArr}
								onChange={(value) => handleDropdownChange(value, 'selectquestionThreeArr')}
							/>
						</div>


						<div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>How did you hear about Industrial Navigator?</label>
							<Select
								closeMenuOnSelect={false}
								components={animatedComponents}
								name='selectquestionFourArr'
								isMulti
								options={sortArray(questionFiveArr)}
								value={props.state.selectquestionFourArr}
								onChange={(value) => handleDropdownChange(value, 'selectquestionFourArr')}
							/>
						</div>



						{/* <div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>I am interested in</label>
							<InputMultiSelectComponent name='selectquestionOneArr'
								options={sortArray(questionOneArr)}
								onHandleMultiSelect={handleMultiSelectOne}
								isMulti={true}
								value={props.getState("selectquestionOneArr", "")}



							/>
						</div> */}
						{/* <div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>I am looking to</label>
							<InputMultiSelectComponent name='selectquestionTwoArr'
								value={props.getState("selectquestionTwoArr", "")}
								options={sortArray(questionTwoArr)}
								onHandleMultiSelect={handleMultiSelectTwo}
								isMulti={true}

							/>
						</div> */}
						{/*
						<div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>How soon do you plan to list/buy an Industrial Property?</label>
							<InputMultiSelectComponent name='selectquestionThreeArr'
								value={props.getState("selectquestionThreeArr", "")}
								options={sortArray(questionThreeArr)}
								onHandleSingleSelect={handleMultiSelect}
								isMulti={false}
							/>
						</div>
					*/}

						{/* <div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>Which Modes of Transportation are you most interested in reviewing on our Map Data Layers?</label>
							<InputMultiSelectComponent name='selectquestionFourArr'
								value={props.getState("selectquestionFourArr", "")}
								options={sortArray(questionFourArr)}
								onHandleMultiSelect={handleMultiSelectThree}
								isMulti={true}
							/>
						</div>
						<div className='col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
							<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>How did you hear about Industrial Navigator?</label>
							<InputMultiSelectComponent name='selectquestionFiveArr'
								value={props.getState("selectquestionFiveArr", "")}
								options={sortArray(questionFiveArr)}
								onHandleMultiSelect={handleMultiSelectFour}
								isMulti={true}
							/>
						</div> */}
					</Row>
				</div>
			</div>
			<div id='checkboxForm'>
				<Row>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
						<InputCheckboxComponent name='checkboxone'
							//checked={props.getState("checkboxone", "")}
							onHandleCheckboxChange={handleCheckboxChange}
							label='Get the latest Industrial Navigator news delivered right to your inbox!'
						/>
					</div>

				</Row>
				<Row>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
						<InputCheckboxComponent name='checkboxtwo'
							//checked={props.getState("checkboxtwo", "")}
							onHandleCheckboxChange={handleCheckboxChange}
							label='I am also interested in PLG Consulting services news and information!'
						/>
					</div>
				</Row>
				{/* <Row>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
						<InputCheckboxComponent name='checkboxthree'
							checked={props.getState("checkboxthree","")}
							onHandleCheckboxChange={handleCheckboxChange}
							label={<><span>I agree to the </span><Link to={'#'}>Privacy Policy</Link><span> and </span><Link to={'#'}>Terms and Conditions</Link><span> of Industrial Navigator. </span></>}
						/>
					</div>
				</Row> */}
			</div>
			<div className='input-group margin-top-15 col-lg-12'>
				<input
					type='hidden'
					name='answer_1'
					className='form-control input-lg not-dark required email'
					placeholder='How did you find Industrial Navigator?'
					onChange={props.handleChange}
					value={props.getState("answer_1", "")}
				/>
			</div>
			{validator.message('answer ', props.getState("answer_1", ""), 'required')}
		</form>
	);
}

export default Step2New;
