import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection'
import { Link } from 'react-router-dom';
const Index = props => {

    return (
        <>
            <section style={{backgroundColor:'#fbfbfb'}} className="section-content">
                <div className="container">
                    <div className="row">
                        <div style={{ marginTop: -50 }} className="col-lg-12 col-sm-12 mb-lg-0 mb-5">
                            <TitleSection
                                className='iq-title-box-2 text-left iq-title-box-1'
                                title='List Your First Site For Free!'
                                description='A free, basic membership includes up to five listings on Industrial Navigator. '
                                titleIcon='' />
                        </div>
                        <div  className="col-lg-12 col-sm-12 mb-lg-0 mb-5">
                            <Link style={{ float: "left" }} to={{ pathname: '/create-listings' }} >
                                <button type='button' className='btn btn-default border-radius-25-imp'  >Create Listing</button>
                            </Link>
                        </div>


                    </div>
                </div>
            </section>
        </>
    );
}

export default Index