import React, { useEffect, useState } from "react";
import Header from './HeaderLTE';
import SideBar from './SideBarLTE';
import Footer from './Footer';
import { useHistory } from 'react-router'
import { withRouter } from 'react-router-dom'
import axios from 'axios';
import { Modal } from 'react-bootstrap';


function SiteCustomization(props) {

    const SVGIcon = () => (
        <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
            <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
        </svg>
    )

    const history = useHistory();
    //console.log("ID : ", props.match.params.id);

    const [manageCount, setCountforListingsDetails] = useState({
        "data_for":"count_for_listings_details_for_non_login_user",
        "count": 0,
        "timeSpent":5
    });



    const hadleInput = async (event) => {
        event.preventDefault();
        setCountforListingsDetails({ ...manageCount, [event.target.name]: event.target.value });
    }
    const [success, setSuccess] = useState(false);

    const handleClose = () => {
        setSuccess(false);
    }


    const countFormSubmit = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/update-site-customization-info', {
            headers: { 'Content-Type': 'application/json' },
            manageCount:JSON.stringify(manageCount)   

        })
            .then(function (response) {
                console.log(response.status)
                if (response.status == 200) {
                    setSuccess(true);
                }
                else {
                    alert("Something went wrong")
                }
               
            })
            .catch(function (error) {
                console.log(error);
            });

    }




    useEffect(() => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/get-site-customization-info', {
            id: 1
        })
            .then(function (response) {
                setCountforListingsDetails({
                    ...manageCount,
                    count_for_listings_details_for_non_login_user: JSON.parse(response.data[0].description_data).count_for_listings_details_for_non_login_user,
                    count: JSON.parse(response.data[0].description_data).count,
                    timeSpent:JSON.parse(response.data[0].description_data).timeSpent,
                })


            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])




 
    return (
        <div style={{ backgroundColor: '#222D32' }}>
            <Header />
            <SideBar />

            <Modal show={success} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                    <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <div className='event-type info'>
                            <div className='event-indicator '>
                                <SVGIcon />
                            </div>
                        </div>
                        <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none',marginTop:20 }}>
                            Data  Updated
                        </h2>
                        <p className='text-muted font-size-16-imp margin-bottom-0'>
                            Data Updated successfully.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                    <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>

            <div className='content-wrapper background-white'>
                <section className='content-header'>
                    <div className='row'>
                        <div className='col-md-12'>

                            <div className='col-md-12'>
                                <div className='col-md-12'>
                                    <div className='col-md-6' style={{
                                        flex: '1 0 auto'
                                        , color: 'rgba(0, 0, 0, 0.87)'
                                        , fontSize: 30
                                        , fontWeight: 700
                                        , marginTop: 15
                                        , marginBottom: 30
                                    }}
                                    >
                                        <div >Site Customization</div>
                                    </div>
                                    <div className='col-md-6' style={{
                                        flex: '1 0 auto'
                                        , color: 'rgba(0, 0, 0, 0.87)'
                                        , marginTop: 15
                                        , marginBottom: 30
                                    }}
                                    >
                                    </div>
                                </div>



                                {/* {JSON.stringify(data[0])} */}


                                <div className='col-md-12'>
                                    <form method="post">
                                        <div className='col-md-12'>
                                            <div className='col-md-2 form-group'>
                                                <label>View registration modal (place count) </label>
                                                <input type="number"
                                                    placeholder='Enter Count'
                                                    name='count'
                                                    value={manageCount.count}
                                                    onChange={hadleInput} className="form-control" />
                                            </div>
                                        </div>
                                        <div className='col-md-12'>
                                            <div className='col-md-2 form-group'>
                                                <label>View registration modal timer (time spent)</label>
                                                <input type="number"
                                                    placeholder='Enter Count'
                                                    name='timeSpent'
                                                    value={manageCount.timeSpent}
                                                    onChange={hadleInput} className="form-control" />
                                            </div>
                                        </div>


                                        <div className='col-md-7' style={{ paddingTop: 20 }}>
                                            <button type='button' className='btn btn-default border-radius-25-imp' onClick={countFormSubmit}>Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </div>
    )
}
export default withRouter(SiteCustomization)