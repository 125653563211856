import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import HomePageFooter from './HomePage/qloud/footerComponents';
import UserProfile from './UserProfile';
// import HomePageFooter from './HomePageFooter';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import HomePageHeader from './HomePageHeader';
import {Helmet} from "react-helmet";
class AboutUs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            getHelpModal: false,
            showAlert: false,
            PageUrl:' ',
            PageTitle:' ',
            PageMetaRobot:' ',
            PageMetaDiscription:' ',
        }
    }
    componentWillMount() {
        this.getMetaTags()
    }
    getMetaTags = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-page-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const plans = res.data;
				// console.log(res.data)
				for (let i = 0; i < plans.length; i++) {
					if(plans[i].PageUrl == window.location.pathname ){
						this.setState({ 
							PageUrl: plans[i].PageUrl, 
							PageTitle: plans[i].PageTitle,
							PageMetaRobot: plans[i].PageMetaRobot,
							PageMetaDiscription: plans[i].PageMetaDiscription,
						});
						return 0;
					}
					else {
						this.setState({ 
							PageUrl: ' ', 
							PageTitle: ' ',
							PageMetaRobot: ' ',
							PageMetaDiscription: ' ',
						});
					}
				}
			})
			.catch(err => {
			});
	}
    showModalGetHelp = () => {
        this.setState({
            getHelpModal: true,
        });

    }
    hideModalGetHelp = () => {
        this.setState({
            getHelpModal: false,
        });

    }
    handleShow = () => {
        this.setState({ showAlert: true });
    }

    handleDismiss = () => {
        this.setState({ showAlert: false });
    }
    setFirstName = (event) => {
        this.setState({ firstName: event.target.value });
    }

    setLastName = (event) => {
        this.setState({ lastName: event.target.value });
    }

    setEmail = (event) => {
        this.setState({ email: event.target.value });
    }

    setMessage = (event) => {
        this.setState({ message: event.target.value });
    }
    handleContactUsGeneral = () => {   

        if (this.state.firstName && this.state.lastName && this.state.email && this.state.message != '' || null) {
            this.setState({ contactusbutton: true })
            document.getElementById("contactusButon").setAttribute('disabled', 'disabled');
            axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'x-access-token': cookies.get('token')
                    },
                    // withCredentials: true,
                    place_owner_name: '',
                    place_id: '',
                    name: this.state.firstName + ' ' + this.state.lastName,
                    email: this.state.email,
                    message: this.state.message,
                }).then(response => {
                    if (response.status === 200) {
                        this.handleShow()
                        this.setState({
                            message: '',
                            contactusbutton: false,
                            // showSuccessModal: true,
                        });
                    } else {
                        this.handleDismiss()
                        alert('Something went wrong')
                        this.setState({ contactusbutton: false });
                    }
                }).catch(function (error) {
                    console.log(error)
                });
        } else {
            // this.validator.showMessages();
            alert('Please fill all necessary information')
            this.forceUpdate();
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>{this.state.PageTitle != " " ? this.state.PageTitle : "About Us"}</title>
                    <meta name='description' content={this.state.PageMetaDiscription != " " ? this.state.PageMetaDiscription : "Discription"} />
                    <meta name="robots" content={this.state.PageMetaRobot != " " ? this.state.PageMetaRobot : " "} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>

                {/* <Header data={this.props} onRef={ref => (this.child = ref)} /> */}
                <HomePageHeader data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 1400, marginTop: 50 }}>
                    <div class="d-flex justify-content-center">
                        <div className='col-lg-10 col-md-12 col-sm-12 padding-0'>
                            <div className="content">
                                <p> <h1 class="content_area page-title-text" style={{ marginTop: 80, fontWeight: 600, fontFamily: 'Roboto, sans-serif' }}><span style={{ color: 'rgb(20, 33, 73)' }}>About</span>  <span style={{ color: 'rgb(0, 157, 245)' }} class="highlight">Industrial Navigator</span>
                                </h1></p>
                                <h2 style={{ textTransform: 'none' }}><b className="sub_title">The Location & Logistics Advantage</b><br /></h2>
                                <p className="para_content">The decisions businesses make about industrial sites, warehouses and transportation facilities determine the strength and flexibility of their supply chains. Wise choices lead to competitive advantages. Poor choices cause ongoing headaches and inefficiencies.
                                    Despite the importance of these decisions, there are scant resources for businesses to easily identify and compare options. Likewise, owners of sites and transportation facilities have a difficult job finding potential users who can unleash the real value of their assets.
                                    Industrial Navigator is where these parties come together. It provides curated listings on a platform that allows users to filter and compare sites and transportation facilities by the criteria that are critical to success.</p>
                                <h2 style={{ textTransform: 'none' }}><b className="sub_title">Who We Serve</b><br /></h2>
                                <p className="para_content"> Industrial Navigator solves myriad business issues for those who search for or market industrial properties, ports, terminals, transloads, warehouses and distribution centers.
                                </p>

                                <div className="row para_content">
                                    <div className="col-md-4">&bull;&nbsp;Class I railroads</div>
                                    <div className="col-md-4">&bull;&nbsp;National industrial real estate brokerages</div>
                                    <div className="col-md-4">&bull;&nbsp;Economic development organizations (EDO’s)</div>
                                </div>
                                <div className="row para_content">
                                    <div className="col-md-4">&bull;&nbsp;Short-line railroads</div>
                                    <div className="col-md-4">&bull;&nbsp;Regional/local industrial real estate brokerages</div>
                                    <div className="col-md-4">&bull;&nbsp;Regional utility companies</div>
                                </div>
                                <div className="row para_content">
                                    <div className="col-md-4">&bull;&nbsp;Rail holding companies</div>
                                    <div className="col-md-4">&bull;&nbsp;Port agencies</div>
                                    <div className="col-md-4">&bull;&nbsp;Transload facilities</div>
                                </div>
                                <div className="row para_content">
                                    <div className="col-md-4">&bull;&nbsp;Terminals</div>
                                    <div className="col-md-4">&bull;&nbsp;Trucking companies</div>
                                    <div className="col-md-4">&bull;&nbsp;Marine operators</div>
                                </div>
                                <div className="row para_content">
                                    <div className="col-md-4">&bull;&nbsp;Warehouse developers/owners</div>
                                    <div className="col-md-4">&bull;&nbsp;3PL warehouse operators</div>
                                    <div className="col-md-4">&bull;&nbsp;Companies looking to lease extra space</div>
                                </div><br /><br />




                                <p className="para_content">Buyers are companies or organizations who are looking to purchase or lease industrial sites for site development, site selection or the location of new manufacturing or warehouse facilities.  <br /><br />These organization include:</p>

                                <div className="row para_content">
                                    <div className="col-md-4">&bull;&nbsp;Site selection firms</div>
                                    <div className="col-md-4">&bull;&nbsp;Shipper companies</div>
                                    <div className="col-md-4">&bull;&nbsp;Manufacturing companies</div>
                                </div>
                                <div className="row para_content">
                                    <div className="col-md-4">&bull;&nbsp;Logistics companies</div>
                                    <div className="col-md-4">&bull;&nbsp;Industrial developers</div>

                                </div><br /><br />

                                <h2 style={{ textTransform: 'none' }}><b className="sub_title">Consulting Services</b><br /></h2>
                                <p className="para_content">Industrial Navigator is powered by PLG Consulting, a consultancy that has solved complex logistics and supply chain challenges for more than 20 years.  Senior executives from the world’s largest, shippers, transportation companies and private equity firms trust PLG Consulting to:<br /></p>
                                <div className="ul_list">
                                    <ul>
                                        <li className='para_content'>Improve operations</li>
                                        <li className='para_content'>Reduce costs</li>
                                        <li className='para_content'>Penetrate markets</li>
                                        <li className='para_content'>Mitigate risk</li>
                                        <li className='para_content'>Invest wisely</li>
                                    </ul>

                                </div>


                                <p className="para_content">Solutions that combine the functionality of Industrial Navigator with the bespoke consulting services of PLG Consulting are available. <a onClick={this.showModalGetHelp}>Contact Us </a>  for more details.  </p>
                                {/* <p><b className="sub_title">Industrial Navigator Team</b><br /></p>
                                <p className="para_content">Industrial Navigator is a powerful site selection tool designed by a team with extensive experience in building and optimizing supply chains across all modes of transportation and software development.
                                </p> */}
                            </div>
                        </div>
                    </div>
                </section>
                <Modal show={this.state.getHelpModal} onHide={this.hideModalGetHelp} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ paddingTop: '2rem', paddingLeft: '1rem', paddingRight: '1rem' }} closeButton>
                        <h3 style={{ fontSize: 20 }}>CONTACT US</h3>

                    </Modal.Header>
                    <Modal.Body style={{ background: 'white', paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                        <div>
                            <b style={{ fontWeight: 500, fontSize: 16 }}> Please email us at info@industrialnavigator.com or call us at +1 (312) 957-7757 for any feedback or questions.</b>
                        </div>
                        {
                            this.state.showAlert &&
                            <Alert bsStyle='success' onDismiss={this.handleDismiss}>
                                <h2 style={{ marginTop: 0, marginBottom: 10 }}>Success!</h2>
                                <p>
                                    Your message has been sent!
                                </p>
                            </Alert>
                        }
                        <div style={{ paddingLeft: 0, paddingRight: 0 }} className='col-lg-12 col-md-12 mt-5 mt-md-0 padding-top-30'>
                            <div className='row'>
                                <form style={{ width: '100%' }}>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <input type='text' className='form-control margin-bottom-0-imp' onChange={this.setFirstName} value={this.state.firstName} required placeholder='Your First Name'></input>
                                        {/* {this.validator.message('first name', this.state.firstName, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <input type='text' className='form-control margin-bottom-0-imp' onChange={this.setLastName} value={this.state.lastName} required placeholder='Your Last Name'></input>
                                        {/* {this.validator.message('last name', this.state.lastName, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <input type='email' className='form-control margin-bottom-0-imp' onChange={this.setEmail} value={this.state.email} required placeholder='Your Email'></input>
                                        {/* {this.validator.message('email', this.state.email, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <textarea className='form-control margin-bottom-0-imp' onChange={this.setMessage} value={this.state.message} placeholder='Your message' rows={6}></textarea>
                                        {/* {this.validator.message('message', this.state.message, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <Button disabled={this.state.contactusbutton} id='contactusButon' style={{ borderRadius: '0px !important', background: '#4DAFFF', height: 50, width: '100%', fontSize: 18, paddingLeft: 25, paddingRight: 25 }} onClick={this.handleContactUsGeneral}>
                                            <span className='pull-left font-Gotham-Pro-Medium font-weight-normal'>Contact Us</span>
                                            {
                                                this.state.contactusbutton ?
                                                    <i className="fa fa-spinner fa-spin fs-30"></i> :
                                                    ''


                                            }
                                            <span className='pull-right'><RightArrowSVGIcon /></span></Button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideModalGetHelp}>Close</button>
                    </Modal.Footer>
                </Modal>


                <HomePageFooter />
            </>
        )
    }
}

export default withRouter(AboutUs);
const RightArrowSVGIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
    >
        <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
            <g fill='#FFF' transform='translate(-1166 -2563)'>
                <g transform='translate(-27)'>
                    <g transform='translate(0 2140)'>
                        <g transform='translate(857 120)'>
                            <g transform='translate(0 286)'>
                                <g transform='translate(336 17)'>
                                    <path d='M10.334.244L20 10l-9.666 9.756-1.167-1.179 7.671-7.744H0V9.167h16.839L9.167 1.422 10.334.244z'></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)