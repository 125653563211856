import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import UserProfile from './UserProfile';
import { Cookies } from 'react-cookie';
import { Button, Modal } from 'react-bootstrap';

const cookies = new Cookies();

interface PHeaderLTE {
	history: Array<any>;
}

class SHeaderLTE {
	isLoggedIn: boolean = false;
	showLogoutSuccessModal: boolean = false;

	constructor() {
	}
}

class HeaderLTE extends Component<PHeaderLTE, SHeaderLTE> {

	constructor(props: PHeaderLTE) {
		super(props);
		this.state = new SHeaderLTE();
		this.logoutUser = this.logoutUser.bind(this);
	}

	handleShow(nextURL, type = '', subType = '') {
		console.log('type ' + type);

		if (cookies.get('token') !== undefined) {
			fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
					'x-access-token': cookies.get('token')
				},
			})
				.then(res => {
					if (res.status === 200) {
						if (nextURL === '/create-listings') {
							this.props.history.push({
								pathname: '/create-listings',
								state: {
									propertyType: '',
									propertySubType: '',
								}
							})
						} else if (nextURL === '/view-listings') {
							this.props.history.push({
								pathname: '/view-listings',
								state: {
									propertyType: '',
									propertySubType: '',
								}
							})
						}
					} else {
						UserProfile.forgetEmail();
						cookies.remove('token');
						this.props.history.push('/');
						window.scrollTo(0, 0);
					}
				})
				.catch(err => {
					console.error(err);
				});
		} else {
			this.handleOpen();
		}
	}

	handleOpen = () => {
	}

	logoutUser() {
		this.setState({ isLoggedIn: false, showLogoutSuccessModal: true });
	}

	handleLogoutSuccessModal = () => {
		this.props.history.push('/');
		this.setState({ showLogoutSuccessModal: false });
		UserProfile.forgetEmail();
		UserProfile.forgetFirstName();
		UserProfile.forgetLastName();
		cookies.remove('token');
	}

	render() {
		const isLoggedIn = UserProfile.getEmail();

		return (
			<header className='main-header'>
				<Link className='logo' to={{ pathname: '/' }}>
					<span className='logo-mini'><b>I</b>N</span>
					<span className='logo-lg'><b>Industrial</b>Navigator</span>
				</Link>
				<nav className='navbar navbar-static-top navbar-custom'>
					<a href='#' className='sidebar-toggle' data-toggle='push-menu' role='button'>
						<span className='sr-only'>Toggle navigation</span>
					</a>
					<ul style={{ marginBottom: 0 }} className='pull-left'>
						{/* <li className="padding-12-15 reduce-padding-size" style={{ display: 'inline-block' }}><Link to={{ pathname: '/' }} className='text-white font-size-17 font-weight-500 font-family-montserrat menu-hover'>Home</Link></li>
						<li  className="padding-12-15 reduce-padding-size" style={{ display: 'inline-block' }}><Link to={{ pathname: '/about-us' }} className='text-white font-size-17 font-weight-500 font-family-montserrat menu-hover'>About Us</Link></li> */}
						<li  className="padding-12-15 reduce-padding-size" style={{ display: 'inline-block' }}><Link to={{ pathname: '/view-listings' }} className='text-white font-size-17 font-weight-500 font-family-montserrat menu-hover'>View Listings</Link></li>
						<li  className="padding-12-15 reduce-padding-size" style={{ display: 'inline-block' }}><Link to={{ pathname: '/create-listings' }} className='text-white font-size-17 font-weight-500 font-family-montserrat menu-hover'>Create Listings</Link></li>
						{/* <li className="padding-12-15 reduce-padding-size" style={{ display: 'inline-block' }}><span role='button' className='text-white font-size-17 font-weight-500 font-family-montserrat menu-hover' onClick={this.handleShow.bind(this, '/view-listings', '', '')}>Browse</span></li>
						<li className="padding-12-15 reduce-padding-size" style={{ display: 'inline-block' }}><span role='button' className='text-white font-size-17 font-weight-500 font-family-montserrat menu-hover' onClick={this.handleShow.bind(this, '/create-listings', '', '')}>Create Listings</span></li> */}
					</ul>
					<div className='navbar-custom-menu'>
						<ul className='nav navbar-nav' style={{ display: 'block', flexDirection: 'unset' }}>
							<li className='dropdown user user-menu'>
								<a href='#' className='dropdown-toggle' data-toggle='dropdown'>
									<div className='pull-left name-initials color-blue ' style={{  bottom: 8, right: 115,marginRight:5,marginTop:-3 }}>
										{UserProfile.getFirstName().charAt(0).toUpperCase() + UserProfile.getLastName().charAt(0).toUpperCase()}
									</div>
									<span className='hidden-xs hidden-xs-user-name'>{UserProfile.getFirstName().charAt(0).toUpperCase() + UserProfile.getFirstName().slice(1)}&nbsp;
                                {UserProfile.getLastName().charAt(0).toUpperCase() + UserProfile.getLastName().slice(1)}</span>
								</a>
								<ul className='dropdown-menu'>
									<li className='user-header'>
										{/* <img src='img/user2-160x160.jpg' className='img-circle' alt='User Image' /> */}
										<div style={{ margin: '0 auto', width: '30%' }}>
											<div className='name-initials color-blue' style={{ padding: 25, borderRadius: '50%' }}>
												{UserProfile.getFirstName().charAt(0).toUpperCase() + UserProfile.getLastName().charAt(0).toUpperCase()}
											</div>
										</div>
										<p>
											{UserProfile.getFirstName().charAt(0).toUpperCase() + UserProfile.getFirstName().slice(1)}&nbsp;
                                            {UserProfile.getLastName().charAt(0).toUpperCase() + UserProfile.getLastName().slice(1)}
										</p>
									</li>
									<li className='user-footer'>
										<div className='pull-left'>
											<Link to={{ pathname: '/my-profile' }} className='btn btn-default border-radius-25-imp'>My Profile</Link>
										</div>
										<div className='pull-right'>
											<a href='#' className='btn btn-default border-radius-25-imp' onClick={this.logoutUser}>Logout</a>
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</nav>
				<Modal show={this.state.showLogoutSuccessModal} onHide={this.handleLogoutSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Logout Successful
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								You are logged out of the system
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLogoutSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
			</header>
		)
	}
}

export default withRouter(HeaderLTE);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)