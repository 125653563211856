import React, { Component } from 'react';
import '../custom.css';
import { Row, Breadcrumb, BreadcrumbItem, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import ListingInstructions from './ListingInstructions';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import UserProfile from './UserProfile';
import { Cookies } from 'react-cookie';
import { LabelValue, sortArray, removeDuplicatesFromArr } from '../constant/Types';
import { shortLinesOptions } from '../constant/ShortLines';
import { arrWaterWays } from '../constant/WaterWays';
import InputImageComponent from './InputImageComponent';
import InputPdfComponent from './InputPdfComponent';
import InputTextareaComponent from './InputTextareaComponent';
import InputAddressComponent from './InputAddressComponent';
import InputBoxComponent from './InputBoxComponent';
import InputMultiSelectComponent from './InputMultiSelectComponent';
import InputCheckboxComponent from './InputCheckboxComponent';
import EditDraggableMarker from './EditDraggableMarker';
import Select, { StylesConfig } from 'react-select';

const cookies = new Cookies();

interface PEditPlace {
	match: any;
	history: Array<string>;
}

class SEditPlace {

	constructor() {
	}
}

class EditPlace extends Component<PEditPlace, any> {

	validator: SimpleReactValidator = null;
	helpers: any = null;
	child: Header = null;

	constructor(props: PEditPlace) {
		super(props);
		this.state = {
			selectedPropertyFor: '',
			selectedPropertyType: '',
			showmarkerModal: false,
			acreage: '',
			selectedBuildingOption: '',
			sitesSquareFeet: '',
			sitesInteriorRoofHeight: '',
			docInPlace: '',
			description: '',
			first_name: '',
			client_plan_id: 0,
			last_name: '',
			client_email_id: '',
			navigableWater: '',
			selectedPowerToSite: '',
			serviceVoltage: '',
			distanceToSubstation: '',
			naturalGasInPlace: '',
			lineDiameter: '',
			selectedWaterSewer: '',
			distanceFromRailway: '',

			shortLinesStr: '',
			placeName: '',
			listingLogo: '',
			imagePreviewUrl: '',
			organizationName: '',
			companyWebsiteUrl: '',
			phone: '',
			price: '',
			perUnit: '',
			address: '',
			coordinates: '',
			swap_coordinates:'',
			city: '',
			manualcity: '',
			usaState: '',
			usaStateManual: '',
			zipCode: '',
			country: '',
			image: '',
			selectedImage: '',
			files: [],
			urls: [],
			pdf: [],
			pdfurls: [],
			contactName: '',
			contactTitle: '',
			contactEmail: '',

			zoningArr: [
				{ 'label': 'Opportunity Zone', 'value': 'Opportunity Zone' },
				{ 'label': 'FTZ', 'value': 'FTZ' },
				{ 'label': 'Industrial', 'value': 'Industrial' },
				{ 'label': 'Warehouse', 'value': 'Warehouse' },
				{ 'label': 'Commercial', 'value': 'Commercial' },
				{ 'label': 'Tax Increment Financing (TIF)', 'value': 'Tax Increment Financing (TIF)' },
			],
			siteSuitableForArr: [
				// { 'label': 'Distribution/warehouse', 'value': 'Distribution/warehouse' },
				// { 'label': 'Cold storage', 'value': 'Cold storage' },
				// { 'label': 'Manufacturing', 'value': 'Manufacturing' },
				// { 'label': 'Indoor rail', 'value': 'Indoor rail' },
				// { 'label': 'Indoor truck', 'value': 'Indoor truck' },
				{ 'label': 'Heavy Industrial', 'value': 'Heavy Industrial' },
				{ 'label': 'Light manufacturing', 'value': 'Light manufacturing' },
				{ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' },
			],
			pipelineAccessArr: [
				{ 'label': 'Natural gas', 'value': 'Natural gas' },
				{ 'label': 'Refined products', 'value': 'Refined products' },
				{ 'label': 'NGLs', 'value': 'NGLs' },
				{ 'label': 'Crude', 'value': 'Crude' }
			],
			envionmentalArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'Phase I complete', 'value': 'Phase I complete' },
				{ 'label': 'Phase II complete', 'value': 'Phase II complete' }
			],

			airAttainmentStatusArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'Attainment', 'value': 'Attainment' },
				{ 'label': 'Non-Attainment for 8-hour Ozone', 'value': 'Non-Attainment for 8-hour Ozone' },
			],
			railcarStorageArr: [
				{ 'label': 'SIT', 'value': 'SIT' },
				{ 'label': 'Loaded', 'value': 'Loaded' },
				{ 'label': 'Empty', 'value': 'Empty' },
				{ 'label': 'Loaded hazmat', 'value': 'Loaded hazmat' },
				{ 'label': 'Hazmat residue', 'value': 'Hazmat residue' },
			],

			siteZoning: '',
			siteSuitableFor: '',
			siteClassOneConnections: '',
			siteShortLines: '',
			siteRailInPlaceOption: '',
			siteBargeAccess: '',

			propertyTypeArr: [
				{ 'label': 'Industrial Sites & Properties', 'value': 'Industrial Sites & Properties' },
				{ 'label': 'Ports, Terminals & Transloads', 'value': 'Ports, Terminals & Transloads' },
				{ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' },
			],

			listingForArr: [
				{ 'label': 'For Lease', 'value': 'For Lease' },
				{ 'label': 'For Sale', 'value': 'For Sale' },
				// { 'label': 'Lease and/or Sale', 'value': 'Lease and/or Sale' },
			],

			capabilitiesArr: [
				{ 'label': 'Rail', 'value': 'Rail' },
				{ 'label': 'Barge', 'value': 'Barge' },
				{ 'label': 'Truck', 'value': 'Truck' },
				{ 'label': 'Tug', 'value': 'Tug' },
				{ 'label': 'Warehouse', 'value': 'Warehouse' },
				{ 'label': 'Tank storage', 'value': 'Tank storage' },
				{ 'label': 'Bulk outside storage', 'value': 'Bulk outside storage' },
				{ 'label': 'Unit train', 'value': 'Unit train' },
				{ 'label': 'Storage-in-transit (SIT)', 'value': 'Storage-in-transit (SIT)' },
				{ 'label': 'Hazmat', 'value': 'Hazmat' },
				{ 'label': 'Laydown yard', 'value': 'Laydown yard' },
				{ 'label': 'Intermodal', 'value': 'Intermodal' },
				{ 'label': 'Other', 'value': 'Other' },
			],
			equipmentsArr: [
				{ 'label': 'Front-end loader', 'value': 'Front-end loader' },
				{ 'label': 'Stacker/reclaimer', 'value': 'Stacker/reclaimer' },
				{ 'label': 'RT crane', 'value': 'RT crane' },
				{ 'label': 'Belt conveyor', 'value': 'Belt conveyor' },
				{ 'label': 'Railcar rotary dump', 'value': 'Railcar rotary dump' },
				{ 'label': 'Railcar bottom dump', 'value': 'Railcar bottom dump' },
				{ 'label': 'Shiploader', 'value': 'Shiploader' },
				{ 'label': 'Barge unloader', 'value': 'Barge unloader' },
				{ 'label': 'Liquid pumps', 'value': 'Liquid pumps' },
				{ 'label': 'Forklift', 'value': 'Forklift' },
				{ 'label': 'Container handling', 'value': 'Container handling' },
				{ 'label': 'Other', 'value': 'Other' },
			],
			selectOptionsArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'Yes', 'value': 'Yes' },
				{ 'label': 'No', 'value': 'No' }
			],
			// bulkTypeArr: [
			// 	{ 'label': 'Dry', 'value': 'Dry' },
			// 	{ 'label': 'Liquid', 'value': 'Liquid' },
			// 	{ 'label': 'Breakbulk', 'value': 'Breakbulk' },
			// 	{ 'label': 'Haz Mat', 'value': 'Haz Mat' },
			// ],
			productsHandledArr: [
				{ 'label': 'Metals', 'value': 'Metals' },
				{ 'label': 'Forest products', 'value': 'Forest products' },
				{ 'label': 'Project Cargo', 'value': 'Project Cargo' },
				{ 'label': 'Aggregates', 'value': 'Aggregates' },
				{ 'label': 'Grains', 'value': 'Grains' },
				{ 'label': 'Fertilizer', 'value': 'Fertilizer' },
				{ 'label': 'Coal', 'value': 'Coal' },
				{ 'label': 'Petcoke', 'value': 'Petcoke' },
				{ 'label': 'Lumber', 'value': 'Lumber' },
				{ 'label': 'Fly ash', 'value': 'Fly ash' },
				{ 'label': 'Hazmat', 'value': 'Hazmat' },
				{ 'label': 'Liquid Bulk', 'value': 'Liquid Bulk' },
				{ 'label': 'Breakbulk', 'value': 'Breakbulk' },
				{ 'label': 'Chemicals', 'value': 'Chemicals' },
				{ 'label': 'Plastics', 'value': 'Plastics' },
				{ 'label': 'Sand', 'value': 'Sand' },
				{ 'label': 'Dry Bulk', 'value': 'Dry Bulk' },
				{ 'label': 'Crude Oil', 'value': 'Crude Oil' },
				{ 'label': 'Refined products', 'value': 'Refined products' },
				{ 'label': 'Other', 'value': 'Other' },
			],
			indoorStorageArr: [
				{ 'label': 'Yes', 'value': 'Yes' },
				{ 'label': 'No', 'value': 'No' }
			],
			temperatureControlArr: [
				{ 'label': 'Chilled (+33 to +65 degrees)', 'value': 'Chilled (+33 to +65 degrees)' },
				{ 'label': 'Frozen (-10 to +32 degrees)', 'value': 'Frozen (-10 to +32 degrees)' },
			],
			marineAccessArr: [
				{ 'label': 'Barge/Shallow', 'value': 'Barge/Shallow' },
				{ 'label': 'Vessel/Deepwater', 'value': 'Vessel/Deepwater' },
			],
			usaStateArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'AL', 'value': 'AL' },
				{ 'label': 'AK', 'value': 'AK' },
				{ 'label': 'AZ', 'value': 'AZ' },
				{ 'label': 'AR', 'value': 'AR' },
				{ 'label': 'CA', 'value': 'CA' },
				{ 'label': 'CO', 'value': 'CO' },
				{ 'label': 'CT', 'value': 'CT' },
				{ 'label': 'DE', 'value': 'DE' },
				{ 'label': 'FL', 'value': 'FL' },
				{ 'label': 'GA', 'value': 'GA' },
				{ 'label': 'HI', 'value': 'HI' },
				{ 'label': 'ID', 'value': 'ID' },
				{ 'label': 'IL', 'value': 'IL' },
				{ 'label': 'IN', 'value': 'IN' },
				{ 'label': 'IA', 'value': 'IA' },
				{ 'label': 'KS', 'value': 'KS' },
				{ 'label': 'KY', 'value': 'KY' },
				{ 'label': 'LA', 'value': 'LA' },
				{ 'label': 'ME', 'value': 'ME' },
				{ 'label': 'MD', 'value': 'MD' },
				{ 'label': 'MA', 'value': 'MA' },
				{ 'label': 'MI', 'value': 'MI' },
				{ 'label': 'MN', 'value': 'MN' },
				{ 'label': 'MS', 'value': 'MS' },
				{ 'label': 'MO', 'value': 'MO' },
				{ 'label': 'MT', 'value': 'MT' },
				{ 'label': 'NE', 'value': 'NE' },
				{ 'label': 'NV', 'value': 'NV' },
				{ 'label': 'NH', 'value': 'NH' },
				{ 'label': 'NJ', 'value': 'NJ' },
				{ 'label': 'NM', 'value': 'NM' },
				{ 'label': 'NY', 'value': 'NY' },
				{ 'label': 'NC', 'value': 'NC' },
				{ 'label': 'ND', 'value': 'ND' },
				{ 'label': 'OH', 'value': 'OH' },
				{ 'label': 'OK', 'value': 'OK' },
				{ 'label': 'OR', 'value': 'OR' },
				{ 'label': 'PA', 'value': 'PA' },
				{ 'label': 'RI', 'value': 'RI' },
				{ 'label': 'SC', 'value': 'SC' },
				{ 'label': 'SD', 'value': 'SD' },
				{ 'label': 'TN', 'value': 'TN' },
				{ 'label': 'TX', 'value': 'TX' },
				{ 'label': 'UT', 'value': 'UT' },
				{ 'label': 'VT', 'value': 'VT' },
				{ 'label': 'VA', 'value': 'VA' },
				{ 'label': 'WA', 'value': 'WA' },
				{ 'label': 'WV', 'value': 'WV' },
				{ 'label': 'WI', 'value': 'WI' },
				{ 'label': 'WY', 'value': 'WY' },
			],

			waterwaysArr: [],
			classOneConnectionsArr: [
				{ 'label': 'BNSF', 'value': 'BNSF' },
				{ 'label': 'CN', 'value': 'CN' },
				{ 'label': 'CP', 'value': 'CP' },
				{ 'label': 'CSX', 'value': 'CSX' },
				{ 'label': 'KCS', 'value': 'KCS' },
				{ 'label': 'NS', 'value': 'NS' },
				{ 'label': 'UP', 'value': 'UP' }
			],

			facilities: '',
			capabilities: '',
			equipments: '',
			acres: '',
			majorFeatures: '',
			specifications: '',
			railcarStorageCapacity: '',
			productStorage: '',
			nameplateCapacity: '',
			// bulkType: '',
			productsHandled: '',
			otherCapabilities: '',
			otherEquipments: '',
			otherProductStorage: '',
			otherProductsHandled: '',
			indoorStorage: '',
			marineAccess: '',
			bargeAccess: '',
			portDepth: '',
			portLength: '',
			facilityDocInPlace: '',
			facilityVesselAceess: '',
			facilityMileMarker: '',
			facilityPipelineAccess: '',
			facilityClassOneConnections: '',
			facilityShortLines: '',
			facilitySiloCapacity: '',

			plgCertification: false,
			warehouseAudits: false,
			siteQualification: false,

			showSuccessModal: false,
			submitbutton: false,
			showErrorModal: false,

			productStorageArr: [
				{ 'label': 'Tankage', 'value': 'Tankage' },
				{ 'label': 'Silos', 'value': 'Silos' },
				{ 'label': 'Warehouse', 'value': 'Warehouse' },
				{ 'label': 'Outdoor', 'value': 'Outdoor' },
				{ 'label': 'Indoor Storage', 'value': 'Indoor Storage' },
				{ 'label': 'Other', 'value': 'Other' },
			],

			parkingCapacityArr: [
				{ 'label': 'Cars', 'value': 'Cars' },
				{ 'label': 'Trailers/containers', 'value': 'Trailers/containers' }
			],
			parkingCapacity: '',
			nearestInterstate: '',
			milesToInterstate: '',
			nearestAirport: '',
			milesToAirport: '',
			silos: '',
			railInPlace: '',
			railcarStorage: '',

			showUploadedImagesModal: false,
			showUploadedPdfModal: false,
			showDeleteImageModal: false,
			deleteImageUrl: '',
			showDeleteImageSuccessModal: false,
			showDeletePdfSuccessModal: false,
			uploadedImagesArr: [],
			imageType: '',

			orderStatus: '',
			active: '',

			pttAcreage: '',
			unitTrainCapable: '',
			railcarCapacity: '',

			warehouseCapabilitiesArr: [
				{ 'label': 'Packaging', 'value': 'Packaging' },
				{ 'label': 'Drumming', 'value': 'Drumming' },
				{ 'label': 'Hazmat', 'value': 'Hazmat' },
				{ 'label': 'Pick & Pack', 'value': 'Pick & Pack' },
				{ 'label': 'Built to Suit', 'value': 'Built to Suit' },
				{ 'label': 'Kitting', 'value': 'Kitting' },
				{ 'label': 'Other', 'value': 'Other' },
			],
			warehouseCapabilities: '',

			warehousePrice: '',
			warehousePricePerUnit: '',
		};
		this.onRemove = this.onRemove.bind(this);
		this.onRemoveLogo = this.onRemoveLogo.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handlePDFFiles = this.handlePDFFiles.bind(this);
		this.setAcreage = this.setAcreage.bind(this);
		this.updatePlace = this.updatePlace.bind(this);
		this.setWaterSewer = this.setWaterSewer.bind(this);

		this.validator = new SimpleReactValidator({
			validators: {
				phone: {  // name the rule
					message: 'The :phone must be a valid contact number.',
					rule: (val, params, validator) => {
						return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/i)
					},
					messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
					required: true  // optional
				}
			}
		});
	}

	showDraggableMarkerModal = () => {
		this.setState({
			showmarkerModal: true
		})
		sessionStorage.removeItem('address_coordinates')
		sessionStorage.removeItem('temp_address_coordinates')
	}

	SaveDraggableMarkerModal = () => {

		var addres_coordinates = sessionStorage.getItem('temp_address_coordinates')
		var temp1 = JSON.parse(addres_coordinates)

		this.setState({
			showmarkerModal: false,
			coordinates: temp1.coordinates,
			swap_coordinates:[temp1.coordinates[1],temp1.coordinates[0]],
			address: temp1.address,
			city: temp1.city,
			country: temp1.country,
			usaState: temp1.state,

		})
		sessionStorage.setItem('address_coordinates', addres_coordinates)

		sessionStorage.removeItem('temp_address_coordinates')
	}

	CloseDraggableMarkerModal = () => {
		sessionStorage.removeItem('temp_address_coordinates')

		this.setState({
			showmarkerModal: false
		})


	}

	sortArray = (array) => {
		var result = array.sort(function (a, b) {
			var valueA = a.value.toLowerCase(), valueB = b.value.toLowerCase();
			if (valueA < valueB)
				return -1;
			if (valueA > valueB)
				return 1;
			return 0;
		});
		return result;
	}

	componentDidMount() {
		console.log(this.props)
		this.getData(this.props.match.params.id);
	}

	getData = async (id) => {
		await axios.post(process.env.REACT_APP_BASE_URL + '/api/get-place-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			rec_id: id,
		})
			.then(res => {
				if (res.status === 200) {
					var feature = res.data.feature;
					console.log(res.data.feature)

					let propertyType = res.data.feature.properties.propertyType;
					let selectedPropertyType = '';

					if (propertyType.includes('Industrial Sites & Properties')) {
						selectedPropertyType = 'Industrial Sites & Properties';
					}
					if (propertyType.includes('Port Terminals') || propertyType.includes('Terminals and Transload Facilities') || propertyType.includes('Terminals') || propertyType.includes('Ports')) {
						selectedPropertyType = selectedPropertyType !== '' ? selectedPropertyType + '*Ports, Terminals & Transloads' : 'Ports, Terminals & Transloads';
					}
					if (propertyType.includes('Warehouse/Distribution Center')) {
						selectedPropertyType = selectedPropertyType !== '' ? selectedPropertyType + '*Warehouse/Distribution Center' : 'Warehouse/Distribution Center';
					}

					console.log('new is ')
					console.log(res.data.feature.properties.listingLogo)

					this.setState({
						rec_id: res.data.feature.rec_id,
						client_id: res.data.feature.client_id,
						first_name: res.data.feature.client_first_name,
						last_name: res.data.feature.client_last_name,
						client_plan_id: res.data.feature.client_plan_id,
						client_email_id: res.data.feature.client_email_id,


						selectedPropertyType: selectedPropertyType,
						selectedPropertyFor: res.data.feature.properties.propertyFor,
						placeName: res.data.feature.properties.listingName,
						organizationName: res.data.feature.properties.organizationName !== undefined ? res.data.feature.properties.organizationName : '',
						companyWebsiteUrl: res.data.feature.properties.companyWebsiteUrl !== undefined ? res.data.feature.properties.companyWebsiteUrl : '',
						listingLogo: res.data.feature.properties.listingLogo !== undefined ? res.data.feature.properties.listingLogo : '',
						image: res.data.feature.properties.image !== undefined ? res.data.feature.properties.image : '',
						pdf: res.data.feature.properties.Brochures !== undefined ? res.data.feature.properties.Brochures : '',
						contactName: res.data.feature.properties.contactName !== undefined ? res.data.feature.properties.contactName : '',
						contactTitle: res.data.feature.properties.contactTitle !== undefined ? res.data.feature.properties.contactTitle : '',
						contactEmail: res.data.feature.properties.contactEmail !== undefined ? res.data.feature.properties.contactEmail : '',
						phone: res.data.feature.properties.contact !== undefined ? res.data.feature.properties.contact : '',
						description: res.data.feature.properties.description !== undefined ? res.data.feature.properties.description : '',
						coordinates: res.data.feature.geometry.coordinates !== undefined ? res.data.feature.geometry.coordinates : '',
						swap_coordinates: res.data.feature.geometry.coordinates !== undefined ? [Number(res.data.feature.geometry.coordinates[1]),Number(res.data.feature.geometry.coordinates[0])] : '',
						city: res.data.feature.properties.city !== undefined ? res.data.feature.properties.city : '',
						manualcity: res.data.feature.properties.city !== undefined ? res.data.feature.properties.city : '',
						usaState: res.data.feature.properties.state !== undefined ? res.data.feature.properties.state : '',
						usaStateManual: res.data.feature.properties.state !== undefined ? res.data.feature.properties.state : '',
						zipCode: res.data.feature.properties.zipCode !== undefined ? res.data.feature.properties.zipCode : '',
						address: res.data.feature.properties.address !== undefined ? res.data.feature.properties.address : '',
						country: res.data.feature.properties.country !== undefined ? res.data.feature.properties.country : '',


						price: res.data.feature.properties.price ? res.data.feature.properties.price : '',
						perUnit: res.data.feature.properties.perUnit ? res.data.feature.properties.perUnit : '',
						acreage: res.data.feature.properties.acreage ? res.data.feature.properties.acreage : '',
						siteZoning: res.data.feature.properties.zoning ? res.data.feature.properties.zoning : '',
						selectedBuildingOption: res.data.feature.properties.building ? res.data.feature.properties.building : '',

						sitesSquareFeet: res.data.feature.properties.squareFeet ? res.data.feature.properties.squareFeet : '',
						sitesInteriorRoofHeight: res.data.feature.properties.interiorRoofHeight ? res.data.feature.properties.interiorRoofHeight : '',
						siteSuitableFor: res.data.feature.properties.suitableFor ? res.data.feature.properties.suitableFor : '',
						sitePipelineAccess: res.data.feature.properties.pipelineAccess ? res.data.feature.properties.pipelineAccess : '',
						selectedWaterSewer: res.data.feature.properties.waterSewerInPlace ? res.data.feature.properties.waterSewerInPlace : '',

						selectedPowerToSite: res.data.feature.properties.electricPowerInPlace ? res.data.feature.properties.electricPowerInPlace : '',
						serviceVoltage: res.data.feature.properties.serviceVoltage ? res.data.feature.properties.serviceVoltage : '',
						distanceToSubstation: res.data.feature.properties.distanceToSubstation ? res.data.feature.properties.distanceToSubstation : '',
						naturalGasInPlace: res.data.feature.properties.naturalGasInPlace ? res.data.feature.properties.naturalGasInPlace : '',
						lineDiameter: res.data.feature.properties.lineDiameter ? res.data.feature.properties.lineDiameter : '',

						siteEnvironmental: res.data.feature.properties.environmental ? res.data.feature.properties.environmental : '',
						airAttainmentStatus: res.data.feature.properties.airAttainmentStatus ? res.data.feature.properties.airAttainmentStatus : '',


						capabilities: res.data.feature.properties.capabilities ? res.data.feature.properties.capabilities : '',
						equipments: res.data.feature.properties.equipments ? res.data.feature.properties.equipments : '',
						railcarStorageCapacity: res.data.feature.properties.railcarStorageCapacity ? res.data.feature.properties.railcarStorageCapacity : '',
						majorFeatures: res.data.feature.properties.majorFeatures ? res.data.feature.properties.majorFeatures : '',
						specifications: res.data.feature.properties.specifications ? res.data.feature.properties.specifications : '',
						tankage: res.data.feature.properties.tankage ? res.data.feature.properties.tankage : '',
						nameplateCapacity: res.data.feature.properties.nameplateCapacity ? res.data.feature.properties.nameplateCapacity : '',
						// bulkType: res.data.feature.properties.bulkType,
						productStorage: res.data.feature.properties.productStorage ? res.data.feature.properties.productStorage : '',
						productsHandled: res.data.feature.properties.productsHandled ? res.data.feature.properties.productsHandled : '',
						otherCapabilities: res.data.feature.properties.otherCapabilities ? res.data.feature.properties.otherCapabilities : '',
						otherEquipments: res.data.feature.properties.otherEquipments ? res.data.feature.properties.otherEquipments : '',
						otherProductStorage: res.data.feature.properties.otherProductStorage ? res.data.feature.properties.otherProductStorage : '',
						otherProductsHandled: res.data.feature.properties.otherProductsHandled ? res.data.feature.properties.otherProductsHandled : '',
						indoorStorage: res.data.feature.properties.indoorStorage ? res.data.feature.properties.indoorStorage : '',
						pttAcreage: res.data.feature.properties.pttAcreage ? res.data.feature.properties.pttAcreage : '',
						unitTrainCapable: res.data.feature.properties.unitTrainCapable ? res.data.feature.properties.unitTrainCapable : '',
						railcarCapacity: res.data.feature.properties.railcarCapacity ? res.data.feature.properties.railcarCapacity : '',


						warehousePrice: res.data.feature.properties.warehousePrice ? res.data.feature.properties.warehousePrice : '',
						warehousePricePerUnit: res.data.feature.properties.warehousePricePerUnit ? res.data.feature.properties.warehousePricePerUnit : '',
						TotalDockDoors: res.data.feature.properties.TotalDockDoors ? res.data.feature.properties.TotalDockDoors : '',
						DriveInDockDoors: res.data.feature.properties.DriveInDockDoors ? res.data.feature.properties.DriveInDockDoors : '',
						DockLevelDoors: res.data.feature.properties.DockLevelDoors ? res.data.feature.properties.DockLevelDoors : '',
						TotalWarehouseArea: res.data.feature.properties.TotalWarehouseArea ? res.data.feature.properties.TotalWarehouseArea : '',
						TotalWarehouseAvailableArea: res.data.feature.properties.TotalWarehouseAvailableArea ? res.data.feature.properties.TotalWarehouseAvailableArea : '',
						TotalWarehouseOfficeSpaceArea: res.data.feature.properties.TotalWarehouseOfficeSpaceArea ? res.data.feature.properties.TotalWarehouseOfficeSpaceArea : '',
						warehouseCapabilities: res.data.feature.properties.warehouseCapabilities ? res.data.feature.properties.warehouseCapabilities : '',
						warehouseMinSize: res.data.feature.properties.warehouseMinSize ? res.data.feature.properties.warehouseMinSize : '',
						warehouseMaxSize: res.data.feature.properties.warehouseMaxSize ? res.data.feature.properties.warehouseMaxSize : '',
						temperatureControl: res.data.feature.properties.temperatureControl ? res.data.feature.properties.temperatureControl : '',
						securedParking: res.data.feature.properties.securedParking ? res.data.feature.properties.securedParking : '',
						parkingSpaces: res.data.feature.properties.parkingSpaces ? res.data.feature.properties.parkingSpaces : '',
						foodGrade: res.data.feature.properties.foodGrade ? res.data.feature.properties.foodGrade : '',
						freeTradeZone: res.data.feature.properties.freeTradeZone ? res.data.feature.properties.freeTradeZone : '',
						roofHeight: res.data.feature.properties.roofHeight ? res.data.feature.properties.roofHeight : '',

						marineAccess: res.data.feature.properties.marineAccess ? res.data.feature.properties.marineAccess : '',
						bargeAccess: res.data.feature.properties.bargeAccess ? res.data.feature.properties.bargeAccess : '',
						portDepth: res.data.feature.properties.portDepth ? res.data.feature.properties.portDepth : '',
						portLength: res.data.feature.properties.portLength ? res.data.feature.properties.portLength : '',
						docInPlace: res.data.feature.properties.docInPlace ? res.data.feature.properties.docInPlace : '',
						waterways: res.data.feature.properties.waterways ? res.data.feature.properties.waterways : '',
						navigableWater: res.data.feature.properties.distancetoWater ? res.data.feature.properties.distancetoWater : '',
						siteClassOneConnections: res.data.feature.properties.classOneConnections ? res.data.feature.properties.classOneConnections : '',
						siteShortLines: res.data.feature.properties.shortLines ? res.data.feature.properties.shortLines : '',
						selectedRailInPlace: res.data.feature.properties.railInPlace ? res.data.feature.properties.railInPlace : '',
						railcarStorage: res.data.feature.properties.railcarStorage ? res.data.feature.properties.railcarStorage : '',
						// siteRailInPlaceOption: res.data.feature.properties.railInPlaceOption,

						plgCertification: res.data.feature.properties.plgCertification === 'Yes' ? true : false,
						warehouseAudits: res.data.feature.properties.warehouseAudits === 'Yes' ? true : false,
						siteQualification: res.data.feature.properties.siteQualification === 'Yes' ? true : false,

						orderStatus: res.data.feature.orderStatus ? res.data.feature.orderStatus : '',
						active: res.data.feature.active ? res.data.feature.active : '',

						createdAt: res.data.feature.createdAt ? res.data.feature.createdAt : '',
						updatedAt: res.data.feature.updatedAt ? res.data.feature.updatedAt : '',

						parkingCapacity: res.data.feature.properties.parkingCapacity ? res.data.feature.properties.parkingCapacity : '',
						nearestInterstate: res.data.feature.properties.nearestInterstate ? res.data.feature.properties.nearestInterstate : '',
						milesToInterstate: res.data.feature.properties.milesToInterstate ? res.data.feature.properties.milesToInterstate : '',
						nearestAirport: res.data.feature.properties.nearestAirport ? res.data.feature.properties.nearestAirport : '',
						milesToAirport: res.data.feature.properties.milesToAirport ? res.data.feature.properties.milesToAirport : '',
						silos: res.data.feature.properties.silos ? res.data.feature.properties.silos : ''
					}, () => {
						this.createMultiSelectArr('selectedPropertyType', this.state.selectedPropertyType);
						// this.setBuildingOption(this.state.selectedBuildingOption);
						this.setFacilityRailcarStorageCapacity(this.state.capabilities);
						// this.setFacilityBTTankage(this.state.nameplateCapacity);
						this.setSiteBargeAccess(this.state.marineAccess);




					});

				}
			}).catch(err => {
				console.log(err);
			});
	}

	createMultiSelectArr(name, selectedOptionsStr) {
		var selectedOptionsArr = selectedOptionsStr.split('*');
		console.log('selectedOptionsArr')
		console.log(selectedOptionsArr)
		for (var i = 0; i < selectedOptionsArr.length; i++) {
			this.processMultiSelectDependencies(name, selectedOptionsArr[i]);
		}
		// this.setState({ [name]: selectedOptionsStr });
	}

	setPropertyFor = (event) => {
		this.setState({ selectedPropertyFor: event.target.value });
	}

	setRailInPlace(event) {
		this.setState({ selectedRailInPlace: event.target.value });
	}

	setAcreage(event) {
		this.setState({ acreage: event.target.value });
	}

	setBuildingOption(value) {
		this.setState({ selectedBuildingOption: value });
	}

	setSitesSquareFeet(event) {
		this.setState({ sitesSquareFeet: event.target.value });
	}

	setSitesInteriorRoofHeight(event) {
		this.setState({ sitesInteriorRoofHeight: event.target.value });
	}

	// setFacilityBTTankage = (value) => {
	// 	if(value === 'Yes')
	// 	{
	// 		document.getElementById('facilityNameplaceDiv').classList.remove('display-none');
	// 	}
	// 	else if(value === 'No')
	// 	{
	// 		document.getElementById('facilityNameplaceDiv').classList.add('display-none');
	// 	}
	// 	else
	// 	{
	// 		document.getElementById('facilityNameplaceDiv').classList.add('display-none');
	// 	}
	// }

	setSiteDocInPlace(event) {
		this.setState({ docInPlace: event.target.value });
	}

	setPowerToSite(event) {
		this.setState({ selectedPowerToSite: event.target.value });
	}

	setWaterSewer(event) {
		this.setState({ selectedWaterSewer: event.target.value });
	}

	componentWillMount() {

		//this.fetchUserBasicDetails();

		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
	}

	fetchUserBasicDetails() {
		// alert(UserProfile.getEmail())
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-basic-info',
			{
				headers: {
					'Content-Type': 'application/json',
					// 'x-access-token': cookies.get('token')
				},
				// withCredentials: true,
				email: UserProfile.getEmail(),
			}).then(response => {
				if (response.status === 200) {
					if (response.data.length > 0) {
						this.setState({
							'first_name': response.data[0].sw_firstname,
							'last_name': response.data[0].sw_lastname,
							'client_email_id': UserProfile.getEmail()
						});
					}
					else {
						this.setState({
							'first_name': response.data.sw_firstname,
							'last_name': response.data.sw_lastname,
							'client_email_id': UserProfile.getEmail()
						});
					}
				}
				else {

				}
			}).catch(function (error) {
				// handle your error
			});
	}

	cancelEditChanges() {
		window.location.reload()
	}


	async updatePlace(event) {
		// console.log(this.validator.allValid())
		const formData = new FormData();
		console.log('listingLogo')
		console.log(this.state.listingLogo)

		if (this.state.listingLogo !== null) {
			for (var i = 0; i < this.state.listingLogo.length; i++) {
				formData.append('logo', this.state.listingLogo[i])
			}
		}

		// if (this.state.selectedImage !== null) {
		// 	for (var i = 0; i < this.state.selectedImage.length; i++) {
		// 		formData.append('file', this.state.selectedImage[i])
		// 	}
		// }

		if (this.state.files !== null) {
			for (var i = 0; i < this.state.files.length; i++) {
				formData.append('file', this.state.files[i])
			}
		}
		if (this.state.pdf !== null) {
			for (var i = 0; i < this.state.pdf.length; i++) {
				formData.append('pdf', this.state.pdf[i])
			}
		}

		var propertyType = '';

		if (this.state.selectedPropertyType.includes('Industrial Sites & Properties') && this.state.selectedPropertyType.includes('Ports, Terminals & Transloads') && this.state.selectedPropertyType.includes('Warehouse')) {
			if (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel')) {
				propertyType = 'Industrial Sites & Properties*Port Terminals*Warehouse/Distribution Center';
			} else {
				propertyType = 'Industrial Sites & Properties*Terminals and Transload Facilities*Warehouse/Distribution Center';
			}
		} else if (this.state.selectedPropertyType.includes('Industrial Sites & Properties') && this.state.selectedPropertyType.includes('Ports, Terminals & Transloads')) {
			if (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel')) {
				propertyType = 'Industrial Sites & Properties*Port Terminals';
			} else {
				propertyType = 'Industrial Sites & Properties*Terminals and Transload Facilities';
			}
		} else if (this.state.selectedPropertyType.includes('Ports, Terminals & Transloads') && this.state.selectedPropertyType.includes('Warehouse')) {
			if (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel')) {
				propertyType = 'Port Terminals*Warehouse/Distribution Center';
			} else {
				propertyType = 'Terminals and Transload Facilities*Warehouse/Distribution Center';
			}
		} else if (this.state.selectedPropertyType.includes('Industrial Sites & Properties') && this.state.selectedPropertyType.includes('Warehouse')) {
			propertyType = 'Industrial Sites & Properties*Warehouse/Distribution Center';
		} else if (this.state.selectedPropertyType === 'Industrial Sites & Properties') {
			propertyType = 'Industrial Sites & Properties';
		} else if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel'))) {
			propertyType = 'Port Terminals';
		} else if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && (!this.state.marineAccess.includes('Barge') || !this.state.marineAccess.includes('Vessel'))) {
			propertyType = 'Terminals and Transload Facilities';
		} else {
			propertyType = 'Warehouse/Distribution Center';
		}

		// if(this.state.selectedPropertyType === 'Industrial Sites & Properties') {
		// 	propertyType = 'Industrial Sites & Properties';
		// } else if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && this.state.marineAccess === 'Barge/Vessel access') {
		// 	propertyType = 'Port Terminals';
		// } else if(this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && this.state.marineAccess === '') {
		// 	propertyType = 'Terminals and Transload Facilities';
		// } else {
		// 	propertyType = 'Warehouses';
		// }
		let placeData;

		if (this.validator.allValid()) {
			if (this.state.city == '' || this.state.usaState == '') {
				await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${this.state.coordinates[0]},${this.state.coordinates[1]}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`, {
					headers: {
						"Content-type": "application/json"
					}
				})
					.then((response) => {
						console.log("Address response", response.data.features[0])

						const shortCode = []
						for (let i = 0; i < response.data.features[0].context.length; i++) {
							if (response.data.features[0].context[i].short_code) {
								shortCode.push(
									{
										short_code: response.data.features[0].context[i].short_code,
										state: response.data.features[0].context[i].text
									}
								)
							}
						}
						var temp = shortCode[0].short_code.split('-')
						// console.log('city')
						this.setState({
							city: response.data.features[0].context[1].text,
							usaState: temp[1],
						})

					})
					.catch((error) => {
						console.log("response", error)
					})
			}

			const basicData = {
				'propertyType': propertyType,
				'propertyFor': this.state.selectedPropertyFor,
				'listingName': this.state.placeName,
				'listingLogo': '',
				'organizationName': this.state.organizationName,
				'companyWebsiteUrl': this.state.companyWebsiteUrl,
				'image': '',
				'contactName': this.state.contactName,
				'contactTitle': this.state.contactTitle,
				'contactEmail': this.state.contactEmail,
				'contact': this.state.phone,
				'description': this.state.description,
				'city': this.state.city,// != '' ? this.state.manualcity : this.state.city,
				'state': this.state.usaState,// != '' ? this.state.usaStateManual : this.state.usaState,
				'zipCode': this.state.zipCode,
				'address': this.state.address,
				'country': this.state.country,
			}


			const siteData = {
				'price': this.state.price,
				'perUnit': this.state.perUnit,
				'acreage': this.state.acreage,
				'zoning': this.state.siteZoning,
				'building': this.state.selectedBuildingOption,
				'squareFeet': this.state.selectedBuildingOption === 'Yes' ? this.state.sitesSquareFeet : '',
				'interiorRoofHeight': this.state.selectedBuildingOption === 'Yes' ? this.state.sitesInteriorRoofHeight : '',
				'suitableFor': this.state.siteSuitableFor,
				'pipelineAccess': this.state.sitePipelineAccess,
				'waterSewerInPlace': this.state.selectedWaterSewer,
				'electricPowerInPlace': this.state.selectedPowerToSite,
				'serviceVoltage': this.state.selectedPowerToSite === 'Yes' ? this.state.serviceVoltage : '',
				'distanceToSubstation': this.state.selectedPowerToSite === 'Yes' ? this.state.distanceToSubstation : '',
				'naturalGasInPlace': this.state.naturalGasInPlace,
				'lineDiameter': this.state.naturalGasInPlace === 'Yes' ? this.state.lineDiameter : '',
				'environmental': this.state.siteEnvironmental,
				'airAttainmentStatus': this.state.airAttainmentStatus,
				'plgCertification': this.state.plgCertification == true ? 'Yes' : 'No',
				'siteQualification': this.state.siteQualification == true ? 'Yes' : 'No',
			}

			const terminalPortTransloadData = {
				'capabilities': this.state.capabilities,
				'equipments': this.state.equipments,
				'railcarStorageCapacity': this.state.railcarStorageCapacity,
				'majorFeatures': this.state.majorFeatures,
				'specifications': this.state.specifications,
				'productStorage': this.state.productStorage,
				'nameplateCapacity': this.state.nameplateCapacity,
				// 'bulkType': this.state.bulkType,
				'productsHandled': this.state.productsHandled,
				'otherCapabilities': this.state.otherCapabilities,
				'otherEquipments': this.state.otherEquipments,
				'otherProductStorage': this.state.otherProductStorage,
				'otherProductsHandled': this.state.otherProductsHandled,
				'indoorStorage': this.state.indoorStorage,
				'pttAcreage': this.state.pttAcreage,
				'unitTrainCapable': this.state.unitTrainCapable,
				'railcarCapacity': this.state.railcarCapacity,
			}

			const warehouseData = {
				'warehousePrice': this.state.warehousePrice,
				'TotalDockDoors': this.state.TotalDockDoors,
				'DriveInDockDoors': this.state.DriveInDockDoors,
				'DockLevelDoors': this.state.DockLevelDoors,
				'TotalWarehouseArea': this.state.TotalWarehouseArea,
				'TotalWarehouseAvailableArea': this.state.TotalWarehouseAvailableArea,
				'TotalWarehouseOfficeSpaceArea': this.state.TotalWarehouseOfficeSpaceArea,
				'warehousePricePerUnit': this.state.warehousePricePerUnit,
				'warehouseCapabilities': this.state.warehouseCapabilities,
				'warehouseMinSize': this.state.warehouseMinSize,
				'warehouseMaxSize': this.state.warehouseMaxSize,
				'temperatureControl': this.state.temperatureControl,
				'securedParking': this.state.securedParking,
				'parkingSpaces': this.state.parkingSpaces,
				'foodGrade': this.state.foodGrade,
				'freeTradeZone': this.state.freeTradeZone,
				'roofHeight': this.state.roofHeight,
				'warehouseAudits': this.state.warehouseAudits == true ? 'Yes' : 'No',
			}

			const marineAccessData = {
				'marineAccess': this.state.marineAccess,
				'bargeAccess': this.state.bargeAccess,
				'docInPlace': this.state.docInPlace,
				'waterways': this.state.waterways,
				'distancetoWater': this.state.navigableWater,
				'portDepth': this.state.portDepth,
				'portLength': this.state.portLength
			}

			const servingCarriersData = {
				'classOneConnections': this.state.siteClassOneConnections,
				'shortLines': this.state.siteShortLines,
				'railServed': this.state.siteClassOneConnections == '' && this.state.siteShortLines == '' ? 'No':'Yes',
				'railInPlace': this.state.selectedRailInPlace,
				'railcarStorage': this.state.railcarStorage,
			}

			const additionalAttributesData = {
				'parkingCapacity': this.state.parkingCapacity,
				'nearestInterstate': this.state.nearestInterstate,
				'milesToInterstate': this.state.milesToInterstate,
				'nearestAirport': this.state.nearestAirport,
				'milesToAirport': this.state.milesToAirport,
				'silos': this.state.silos,
			}

			const consolidatedObj = Object.assign(basicData, siteData, terminalPortTransloadData, warehouseData, marineAccessData, servingCarriersData, additionalAttributesData);

			var dragableMarker = JSON.parse(sessionStorage.getItem('address_coordinates'));
			var data = this.state.coordinates
			if (typeof (data) == 'string') {
				var data1 = this.state.coordinates.split(",");
				var covalue = data1.map(i => Number(i))

			} else {
				var covalue = this.state.coordinates;
			}
			placeData = {
				'rec_id': this.state.rec_id,
				'client_id': this.state.client_id,
				'client_first_name': this.state.first_name,
				'client_last_name': this.state.last_name,
				'client_email_id': this.state.client_email_id,
				'client_plan_id': UserProfile.getUserType('user_type') === 'admin' ? 0 : this.state.client_plan_id,
				'total_views': 0,
				'who_view_listings_obj': [],
				'type': 'Feature',
				// 'geometry': {
				// 	'type': 'Point',
				// 	'coordinates': this.state.coordinates
				// },
				'geometry': {
					'type': 'Point',
					'coordinates': covalue
				},
				'properties': consolidatedObj,
				'orderStatus': this.state.orderStatus,
				'active': this.state.active,
				'createdAt': this.state.createdAt,
				'updatedAt': this.state.updatedAt,
			}

			formData.append('placeData', JSON.stringify(placeData));
			this.setState({ submitbutton: true })

			await axios.post(process.env.REACT_APP_BASE_URL + '/api/update-geojson-record', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				},
			}).then(response => {
				if (response.status === 200) {
					console.log(response);
					this.setState({
						showSuccessModal: true,
						submitbutton: false
					})
					sessionStorage.removeItem("address_coordinates")

				} else {
					this.setState({
						showErrorModal: true,
						submitbutton: false
					})
				}
			}).catch(error => {
				// handle your error
				console.log(error)
				this.setState({ submitbutton: false })
			});
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}

	setFacilityAcres = (value: string) => {
		if (value === 'Warehouse') {
			document.getElementById('facilityAcresDiv').classList.remove('display-none');
		}
	}

	setSiteBargeAccess = (value: string) => {
		if (value.includes('Barge') || value.includes('Vessel')) {
			this.setState({ marineAccess: value, bargeAccess: 'Yes' });
			document.getElementById('siteDocInPlaceDiv').classList.remove('display-none');
			document.getElementById('siteWhichRiverDiv').classList.remove('display-none');
		} else {
			this.setState({ marineAccess: value, bargeAccess: 'No' });
			document.getElementById('siteDocInPlaceDiv').classList.add('display-none');
			document.getElementById('siteWhichRiverDiv').classList.add('display-none');
		}
	}

	setFacilityRailcarStorageCapacity = (value: string) => {
		// if (value === 'Rail') {
		// 	document.getElementById('facilityRailcarStorageCapacityDiv').classList.remove('display-none');
		// }
		if (value.toLowerCase().includes('Rail'.toLowerCase())) {
			document.getElementById('facilityRailcarStorageCapacityDiv').classList.remove('display-none');
		}
	}

	removeFacilityRailcarStorageCapacity = () => {
		document.getElementById('facilityRailcarStorageCapacityDiv').classList.add('display-none');
	}

	addColdStorageInCapabilities = (value: string) => {
		const capabilitiesArr = this.state.capabilitiesArr;
		if (value === 'Warehouse') {
			let found = false;
			for (let i = 0; i < capabilitiesArr.length; i++) {
				if (capabilitiesArr[i].label === 'Cold storage') {
					found = true;
					break;
				}
			}
			if (found === false) {
				capabilitiesArr.push(
					{ 'label': 'Cold storage', 'value': 'Cold storage' }
				);
			}
			this.setState({ capabilitiesArr: capabilitiesArr });
		}
	}

	removeColdStorageFromCapabilities = () => {
		const capabilitiesArr = this.state.capabilitiesArr;
		for (let i = 0; i < capabilitiesArr.length; i++) {
			if (capabilitiesArr[i].value == 'Cold storage')
				capabilitiesArr.splice(i);
		}
		this.setState({ capabilitiesArr: capabilitiesArr });
	}

	removeFacilityAcres = () => {
		document.getElementById('facilityAcresDiv').classList.add('display-none');
	}

	setCheckboxForm = () => {
		document.getElementById('checkboxForm').classList.remove('display-none');
	}

	unsetCheckboxForm = () => {
		document.getElementById('checkboxForm').classList.add('display-none');
	}


	setSiteForm = (value: string) => {
		document.getElementById('basicForm').classList.remove('display-none');
		document.getElementById('marineAccessForm').classList.remove('display-none');
		document.getElementById('servingCarriersForm').classList.remove('display-none');
		document.getElementById('siteForm').classList.remove('display-none');
		// document.getElementById('listingForDropdownDiv').classList.remove('display-none');
		document.getElementById('additionalAttributesForm').classList.remove('display-none');
	}

	setListingFor = (value: string) => {
		if (value.includes('Sites') || value.includes('Warehouse')) {
			document.getElementById('listingForDropdownDiv').classList.remove('display-none');
		}

		if (value.includes('Warehouse')) {
			this.addPTTOptionInListingFor();
		}
	}

	unsetListingFor = () => {
		document.getElementById('listingForDropdownDiv').classList.add('display-none');
	}

	addPTTOptionInListingFor = () => {
		const listingForArr = this.state.listingForArr;
		let found = false;
		for (let i = 0; i < listingForArr.length; i++) {
			if (listingForArr[i].label === 'For 3PL/Public Warehouse Services') {
				found = true;
				break;
			}
		}
		if (found === false) {
			listingForArr.push(
				{ 'label': 'For 3PL/Public Warehouse Services', 'value': 'For 3PL/Public Warehouse Services' }
			);
		}
		this.setState({ listingForArr: listingForArr });
	}

	removePTTOptionFromListingFor = () => {
		const listingForArr = this.state.listingForArr;
		for (let i = 0; i < listingForArr.length; i++) {
			if (listingForArr[i].value === 'For 3PL/Public Warehouse Services') {
				listingForArr.splice(i);
			}
		}
		if (this.state.selectedPropertyFor.includes('For 3PL/Public Warehouse Services')) {
			// remove this selected dropdown value
			const newListingFor = this.state.selectedPropertyFor.split('*');
			for (let j = 0; j < newListingFor.length; j++) {
				if (newListingFor[j] === 'For 3PL/Public Warehouse Services')
					newListingFor.splice(j);
			}

			this.setState({ selectedPropertyFor: newListingFor.length > 0 ? newListingFor.join('*') : null, listingForArr: listingForArr });
		} else {
			this.setState({ listingForArr: listingForArr });
		}
	}

	unsetSiteForm = () => {
		document.getElementById('siteForm').classList.add('display-none');
		// document.getElementById('listingForDropdownDiv').classList.add('display-none');
		this.resetSiteCheckboxes();
	}

	setPortTerminalTransloadForm = () => {
		document.getElementById('basicForm').classList.remove('display-none');
		document.getElementById('marineAccessForm').classList.remove('display-none');
		document.getElementById('servingCarriersForm').classList.remove('display-none');
		document.getElementById('terminalPortForm').classList.remove('display-none');
		document.getElementById('additionalAttributesForm').classList.remove('display-none');
	}

	unsetPortTerminalTransloadForm = () => {
		document.getElementById('terminalPortForm').classList.add('display-none');
	}

	setWarehouseForm = () => {
		document.getElementById('basicForm').classList.remove('display-none');
		document.getElementById('warehouseForm').classList.remove('display-none');
		document.getElementById('servingCarriersForm').classList.remove('display-none');
		document.getElementById('additionalAttributesForm').classList.remove('display-none');
	}

	unsetWarehouseForm = () => {
		document.getElementById('warehouseForm').classList.add('display-none');
	}

	unsetBasicForm = () => {
		document.getElementById('basicForm').classList.add('display-none');
		document.getElementById('marineAccessForm').classList.add('display-none');
		document.getElementById('servingCarriersForm').classList.add('display-none');
		document.getElementById('additionalAttributesForm').classList.add('display-none');
	}

	setMarineAccessForm = () => {
		document.getElementById('marineAccessForm').classList.remove('display-none');
	}

	unsetMarineAccessForm = () => {
		document.getElementById('marineAccessForm').classList.add('display-none');
	}

	resetSiteCheckboxes = () => {
		this.setState({
			plgCertification: false,
			siteQualification: false,
		})
	}

	resetWarehouseCheckboxes = () => {
		this.setState({
			warehouseAudits: false,
			siteQualification: false,
		})
	}

	processMultiSelectDependencies = (name, value) => {
		console.log('name')
		console.log(name)
		console.log(value)
		if (name === 'selectedPropertyType') {
			this.setCheckboxForm();
			if (value === 'Industrial Sites & Properties') {
				this.setSiteForm(value);
				this.setListingFor(value);
				this.setMarineAccessForm();
			} else if (value === 'Ports, Terminals & Transloads') {
				this.setPortTerminalTransloadForm();
				this.setMarineAccessForm();
			} else if (value === 'Warehouse/Distribution Center') {
				this.setWarehouseForm();
				// this.setFacilityAcres(value);
				this.addColdStorageInCapabilities(value);
				this.setListingFor(value);
			}
		}
		// if(name === 'selectedBuildingOption') {
		// 	this.setBuildingOption(value);
		// }

		// if(name === 'facilities') {
		// 	if(value === 'Warehouse') {
		// 		this.setFacilityAcres(value);
		// 		this.addColdStorageInCapabilities(value);
		// 	}
		// } else if(name === 'tankage') {
		// 	this.setFacilityBTTankage(value);
		// } else 
		if (name === 'marineAccess') {
			this.setSiteBargeAccess(value);
		} else if (name === 'bargeAccess') {
			this.handleFacilityBargeVesselAccessChange(value);
		} else if (name === 'capabilities') {
			this.setFacilityRailcarStorageCapacity(value);
		}
	}

	handleFacilityBargeVesselAccessChange = (value) => {
		alert('Unexpected handleFacilityBargeVesselAccessChange: ' + value);
	}

	handleMultiSelect = (name, valueArr) => {
		let multiSelectStr = '';
		if (Object.prototype.toString.call(valueArr) === '[object Object]') {
			multiSelectStr = valueArr.label;
			this.processMultiSelectDependencies(name, valueArr.label);
		} else {
			if (valueArr != null) {
				for (var i = 0; i < valueArr.length; i++) {
					this.processMultiSelectDependencies(name, valueArr[i].label);
					if (multiSelectStr === '') {
						multiSelectStr = valueArr[i].label;
					}
					else {
						multiSelectStr += '*' + valueArr[i].label;
					}
				}
			} else {
				this.processMultiSelectDependencies(name, '');
			}
		}
		console.log('multiSelectStr')
		console.log(multiSelectStr)
		if (name === 'selectedPropertyType') {
			if (multiSelectStr !== '') {
				this.setCheckboxForm();
			} else {
				this.unsetCheckboxForm();
			}

			if (!multiSelectStr.includes('Sites')) {
				this.unsetSiteForm();
			}
			if (!multiSelectStr.includes('Ports, Terminals & Transloads')) {
				this.unsetPortTerminalTransloadForm();
			}

			if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Ports, Terminals & Transloads')) {
				this.unsetMarineAccessForm();
			}

			if (!multiSelectStr.includes('Warehouse')) {
				this.unsetWarehouseForm();
			}
			if (multiSelectStr === '') {
				this.unsetBasicForm();
			}
			if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Warehouse')) {
				this.unsetListingFor();
			}

			if (!multiSelectStr.includes('Warehouse')) {
				this.removePTTOptionFromListingFor();
			}
		}

		// if (name === 'facilities' && !multiSelectStr.includes('Warehouse')) {
		// 	this.removeColdStorageFromCapabilities();
		// 	this.removeFacilityAcres();
		// }

		if (name === 'selectedBuildingOption') {
			this.setBuildingOption(valueArr.value);
		}

		if (name === 'capabilities' && !multiSelectStr.includes('Rail')) {
			this.removeFacilityRailcarStorageCapacity();
		}

		this.setState({ [name]: multiSelectStr });
	}

	handleMultiSelect1 = (name, valueArr) => {
		let multiSelectStr = '';
		if (Object.prototype.toString.call(valueArr) === '[object Object]') {
			multiSelectStr = valueArr.value;
			this.processMultiSelectDependencies(name, valueArr.value);
		} else {
			if (valueArr != null) {
				for (var i = 0; i < valueArr.length; i++) {
					this.processMultiSelectDependencies(name, valueArr[i].value);
					if (multiSelectStr === '') {
						multiSelectStr = valueArr[i].value;
					}
					else {
						multiSelectStr += '*' + valueArr[i].value;
					}
				}
			} else {
				this.processMultiSelectDependencies(name, '');
			}
		}
		console.log('multiSelectStr')
		console.log(multiSelectStr)
		if (name === 'selectedPropertyType') {
			if (multiSelectStr !== '') {
				this.setCheckboxForm();
			} else {
				this.unsetCheckboxForm();
			}

			if (!multiSelectStr.includes('Sites')) {
				this.unsetSiteForm();
			}
			if (!multiSelectStr.includes('Ports, Terminals & Transloads')) {
				this.unsetPortTerminalTransloadForm();
			}

			if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Ports, Terminals & Transloads')) {
				this.unsetMarineAccessForm();
			}

			if (!multiSelectStr.includes('Warehouse')) {
				this.unsetWarehouseForm();
			}
			if (multiSelectStr === '') {
				this.unsetBasicForm();
			}
			if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Warehouse')) {
				this.unsetListingFor();
			}

			if (!multiSelectStr.includes('Warehouse')) {
				this.removePTTOptionFromListingFor();
			}
		}

		// if (name === 'facilities' && !multiSelectStr.includes('Warehouse')) {
		// 	this.removeColdStorageFromCapabilities();
		// 	this.removeFacilityAcres();
		// }

		if (name === 'selectedBuildingOption') {
			this.setBuildingOption(valueArr.value);
		}

		if (name === 'capabilities' && !multiSelectStr.includes('Rail')) {
			this.removeFacilityRailcarStorageCapacity();
		}

		this.setState({ [name]: multiSelectStr });
	}
	handleMultiSelectusa = (name, valueArr) => {
		let multiSelectStr = '';
		if (Object.prototype.toString.call(valueArr) === '[object Object]') {
			multiSelectStr = valueArr.value;
			this.processMultiSelectDependencies(name, valueArr.value);
		} else {
			if (valueArr != null) {
				for (var i = 0; i < valueArr.length; i++) {
					this.processMultiSelectDependencies(name, valueArr[i].value);
					if (multiSelectStr === '') {
						multiSelectStr = valueArr[i].value;
					}
					else {
						multiSelectStr += '*' + valueArr[i].value;
					}
				}
			} else {
				this.processMultiSelectDependencies(name, '');
			}
		}
		console.log('multiSelectStr')
		console.log(multiSelectStr)
		if (name === 'selectedPropertyType') {
			if (multiSelectStr !== '') {
				this.setCheckboxForm();
			} else {
				this.unsetCheckboxForm();
			}

			if (!multiSelectStr.includes('Sites')) {
				this.unsetSiteForm();
			}
			if (!multiSelectStr.includes('Ports, Terminals & Transloads')) {
				this.unsetPortTerminalTransloadForm();
			}

			if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Ports, Terminals & Transloads')) {
				this.unsetMarineAccessForm();
			}

			if (!multiSelectStr.includes('Warehouse')) {
				this.unsetWarehouseForm();
			}
			if (multiSelectStr === '') {
				this.unsetBasicForm();
			}
			if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Warehouse')) {
				this.unsetListingFor();
			}

			if (!multiSelectStr.includes('Warehouse')) {
				this.removePTTOptionFromListingFor();
			}
		}

		if (name === 'selectedBuildingOption') {
			this.setBuildingOption(valueArr.value);
		}

		if (name === 'capabilities' && !multiSelectStr.includes('Rail')) {
			this.removeFacilityRailcarStorageCapacity();
		}

		this.setState({ [name]: multiSelectStr });
	}

	handleNameChange = value => {
		this.setState({ placeName: value });
	}

	handleContactNameChange = value => {
		this.setState({ contactName: value });
	}

	handleContactTitleChange = value => {
		this.setState({ contactTitle: value });
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
		if (name == 'swap_coordinates') {
			var data = this.state.swap_coordinates
			if (typeof (data) === 'object') {
				var tempCoordinates = data
			} else {
				var temp = data
				var tempCoordinates = temp.split(',')
			}
			this.setState({
				coordinates:[Number(tempCoordinates[1]),Number(tempCoordinates[0])]
			})

			axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${tempCoordinates[1]},${tempCoordinates[0]}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`, {
				headers: {
					"Content-type": "application/json"
				}
			})
				.then((response) => {
					console.log("Address response", response.data.features[0])

					this.setState({
						city: response.data.features[0].context[1].text,
						usaState: response.data.features[0].context[2].short_code,
						address: response.data.features[0].place_name,
						country: response.data.features[0].context[3].short_code,
					})

				})
				.catch((error) => {
					console.log("response", error)
				})
		}
	}
	handleEmailInputChange = (name, value) => {
		this.setState({ [name]: value });
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if (!pattern.test(value)) {
			document.getElementById("emailValidation").innerHTML = "Please enter valid email."
		}
		else {
			document.getElementById("emailValidation").innerHTML = ""

		}

	}

	handleContactChange = value => {
		this.setState({ phone: value });
	}

	handleImageChange = (name, event) => {
		console.log('file is')
		console.log(event.target.files)
		this.setState({ [name]: event.target.files });

		let reader = new FileReader();
		let file = event.target.files[0];

		reader.onloadend = () => {
			this.setState({
				imagePreviewUrl: reader.result
			});
		}

		reader.readAsDataURL(file)
	}

	onRemoveLogo() {
		var { listingLogo, imagePreviewUrl } = this.state;


		this.setState({
			...this.state,
			listingLogo: '',
			imagePreviewUrl: ''
		});
	}

	handleFiles(file) {

		// this could be refactored to not use the file reader

		var reader = new FileReader();

		reader.onloadend = () => {

			var imageUrl = window.URL.createObjectURL(file);

			this.setState({
				files: [file, ...this.state.files],
				urls: [imageUrl, ...this.state.urls]
			});

		}

		reader.readAsDataURL(file);
	}

	onRemove(index) {
		var { files, urls } = this.state;
		let newFiles = files.filter((file, i) => i !== index);
		let newUrls = urls.filter((url, i) => i !== index);

		this.setState({
			...this.state,
			files: newFiles,
			urls: newUrls
		});
	}
	onRemovePDF(index) {
		var { pdf, pdfurls } = this.state;
		let newFiles = pdf.filter((pdf, i) => i !== index);
		let newUrls = pdfurls.filter((pdfurls, i) => i !== index);

		this.setState({
			...this.state,
			pdf: newFiles,
			pdfurls: newUrls
		});
	}

	changeHandler = (name, event) => {
		console.log('new file is')
		console.log(event.target.name)
		console.log(event.target.files)
		const files = event.target.files;
		[].forEach.call(files, this.handleFiles);
	}
	changeHandlerPDF = (name, event) => {
		console.log('new file is')
		console.log(event.target.name)
		console.log(event.target.files)
		const files = event.target.files;
		[].forEach.call(files, this.handlePDFFiles);
	}
	handlePDFFiles(file) {

		// this could be refactored to not use the file reader

		var reader = new FileReader();

		reader.onloadend = () => {

			var imageUrl = window.URL.createObjectURL(file);

			this.setState({
				pdf: [file, ...this.state.pdf],
				pdfurls: [imageUrl, ...this.state.pdfurls]
			});

		}

		reader.readAsDataURL(file);
	}

	handlePriceChange = value => {
		this.setState({ price: value });
	}

	handleAddressChange = (address, coordinates, city, usaState, zipCode, country) => {
		var coordinatesnew = [coordinates[1],coordinates[0]]
		this.setState({
			address: address,
			coordinates: coordinates,
			swap_coordinates : coordinatesnew,
			city: city,
			usaState: usaState,
			zipCode: zipCode,
			country: country,
		});
		console.log(this.state);

	}
	clearAddress = () => {
		this.handleAddressChange('', [], '', '', '', '');

	}

	handleTextareaChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleCheckboxChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleSuccessModal = () => {
		this.setState({ showSuccessModal: false });
		this.props.history.push('/my-listing/');
	}

	handleErrorModal = () => {
		this.setState({ showErrorModal: false });
		this.props.history.push('/my-listing/');
	}

	showUploadedImages = (type) => {
		console.log(type)
		if (type === 'logo') {
			this.setState({
				imageType: type,
				uploadedImagesArr: this.state.listingLogo ? [{ 'url': this.state.listingLogo }] : [],
				showUploadedImagesModal: true,
			});
		} else if (type === 'images') {
			this.setState({
				imageType: type,
				uploadedImagesArr: this.state.image,
				showUploadedImagesModal: true,
			});
		}
		else {
			this.setState({
				imageType: type,
				uploadedImagesArr: this.state.pdf,
				showUploadedPdfModal: true,
			});
		}
	}

	handleUploadedImagesModal = () => {
		this.setState({ showUploadedImagesModal: false });
	}
	handleUploadedPdfModal = () => {
		this.setState({ showUploadedPdfModal: false });
	}

	confirmDeleteImage = (url) => {
		this.setState({
			showDeleteImageModal: true,
			deleteImageUrl: url
		});
	}

	handleDeleteImageModal = () => {
		this.setState({
			showDeleteImageModal: false
		});
	}

	deleteImage = () => {
		let imageArr;
		const imageType = this.state.imageType;

		if (imageType === 'logo') {
			imageArr = [{ 'url': this.state.listingLogo }];
		} else if (imageType == 'images') {
			imageArr = this.state.image;
		}
		else {
			imageArr = this.state.pdf;
		}
		console.log('imageArr')
		console.log(imageArr)
		let url = this.state.deleteImageUrl;
		const newImageArr = imageArr.filter(function (image) { return image.url != url; });
		console.log(newImageArr)

		// return;

		axios.post(process.env.REACT_APP_BASE_URL + '/api/delete-image', {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			rec_id: this.state.rec_id,
			image_url: url,
			imageType: imageType
		}).then(response => {
			console.log('delete response');
			console.log(response);
			if (response.status === 200) {
				console.log(response);
				this.setState({
					uploadedImagesArr: newImageArr,
					deleteImageUrl: '',
					showDeleteImageModal: false,
				}, () => {
					if (imageType === 'logo') {
						this.setState({
							listingLogo: newImageArr,
							showDeleteImageSuccessModal: true,
						});
					} else if (imageType == 'images') {
						this.setState({
							image: newImageArr,
							showDeleteImageSuccessModal: true,
						});
					}
					else {
						this.setState({
							pdf: newImageArr,
							showDeletePdfSuccessModal: true,
						});
					}
				});
			} else {
				this.setState({
					showErrorModal: true
				})
			}
		}).catch(error => {
			// handle your error
			console.log(error)
		});
	}

	handleDeleteImageSuccessModal = () => {
		this.setState({
			showDeleteImageSuccessModal: false
		})
	}
	handleDeletePdfSuccessModal = () => {
		this.setState({
			showDeletePdfSuccessModal: false
		})
	}



	render() {
		const selectedOptionsStyles = {
			color: '#4c9cd3',
			backgroundColor: '#d8ebf8'
		};

		const optionsListStyles = {
			backgroundColor: '#d8ebf8',
			color: '#4c9cd3'
		};
		const dragableMarkerData = JSON.parse(sessionStorage.getItem('address_coordinates'))
		const { urls, pdfurls, files, isDragging } = this.state;
		let { imagePreviewUrl } = this.state;
		let $imagePreview = null;
		if (imagePreviewUrl) {
			$imagePreview = (<img src={imagePreviewUrl} className="imagePreview" />);
		} else {
			$imagePreview = ""
		}


		const shortLinesOptionsTmp: Array<LabelValue> = [...shortLinesOptions];
		return (
			<div className='padding-bottom-50'>
				<Header data={this.props} onRef={ref => (this.child = ref)} />
				<div id='page-content' style={{ paddingBottom: '50px', minHeight: '100vh', zIndex: 1 }}>
					<div>
						<Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
							<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
							<BreadcrumbItem active>Edit</BreadcrumbItem>
						</Breadcrumb>
						<div className='container padding-top-30'>
							<div className='col-md-12 col-sm-12 padding-0-xs'>
								<ListingInstructions />
								<div className='col-md-12 col-sm-12 padding-0-xs'>
									<form style={{ width: '100%' }}>
										<div className='block-heading' id='details'>
											{/* <a href='#additionalinfo' className='btn btn-sm btn-default pull-right'>Additional Info <i className='fa fa-chevron-down'></i></a> */}
											<h4><span className='heading-icon'><i className='fa fa-caret-right icon-design'></i><i className='fa fa-home' style={{ color: 'white' }}></i></span>Listing Details</h4>
										</div>
										<div className='padding-as25 margin-30 lgray-bg padding-15-xs'>
											<Row>
												<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
													<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>Listing type</label>
													<InputMultiSelectComponent name='selectedPropertyType' value={this.state.selectedPropertyType} options={this.sortArray(this.state.propertyTypeArr)} onHandleMultiSelect={this.handleMultiSelect} isMulti={true} />
												</div>
												<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='listingForDropdownDiv'>
													<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
														{
															this.state.selectedPropertyType.includes("Warehouse") ? "For Sale/Lease/3PL" : "For Sale/Lease"
														}
													</label>
													<InputMultiSelectComponent name='selectedPropertyFor'
														value={this.state.selectedPropertyFor}
														options={this.state.listingForArr}
														onHandleMultiSelect={this.handleMultiSelect}
														isMulti={true}
													/>
												</div>
											</Row>
											<hr />

											<div id='basicForm' className='display-none'>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Listing Name</label>
														<InputBoxComponent name='placeName' type='text'
															onHandleInputChange={this.handleInputChange}
															value={this.state.placeName}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-14'>
																	Image names will show when uploaded. If no image names show, nothing has been uploaded.
																</strong>
															</Tooltip>
														}>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>
																Listing Organization Logo (300X100 in pixels) <i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
															</label></OverlayTrigger>
														<InputImageComponent name='listingLogo' multiple={false}
															onHandleImageChange={this.handleImageChange}
														/>
														<span className='cursor-pointer' onClick={() => this.showUploadedImages('logo')}>Show Uploaded Logo</span>
														{
															imagePreviewUrl &&

															<div className="imagePreviewContainer">
																<div className="previewItem" >
																	{/* <img className="imagePreview" src={url} /> */}
																	<img src={imagePreviewUrl} className="imagePreview" />
																	<br />

																	<label className="material-icons"
																		style={{ color: 'red', fontSize: 12, justifyContent: 'center', display: 'grid', marginBottom: '-1px' }}
																		onClick={() => this.onRemoveLogo()}>Remove</label>
																</div>
															</div>

														}
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Contact Organization</label>
														<InputBoxComponent name='organizationName' type='text'
															onHandleInputChange={this.handleInputChange}
															value={this.state.organizationName}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Company Website</label>
														<InputBoxComponent name='companyWebsiteUrl' value={this.state.companyWebsiteUrl} onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 clear-both'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Contact Name</label>
														<InputBoxComponent name='contactName' type='text'
															onHandleInputChange={this.handleInputChange}
															value={this.state.contactName}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Contact Title</label>
														<InputBoxComponent name='contactTitle' type='text'
															onHandleInputChange={this.handleInputChange}
															value={this.state.contactTitle}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Contact Email</label>
														<InputBoxComponent name='contactEmail' type='email'
															onHandleInputChange={this.handleEmailInputChange}
															value={this.state.contactEmail}
														/>
														<p style={{ color: '#a94442' }} id="emailValidation"></p>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Contact Phone</label>
														<InputBoxComponent name='phone' type='number'
															onHandleInputChange={this.handleInputChange}
															value={this.state.phone}
														/>
														{this.validator.message('phone', this.state.phone, 'phone')}
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<OverlayTrigger placement='right' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-14'>
																	Listing Images names will show when uploaded. If no image names show,<br /> nothing has been uploaded.
																	You may multi-select images to upload at once.
																</strong>
															</Tooltip>
														}>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
																Listing Images (900X600 in pixels) <i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
															</label>
														</OverlayTrigger>
														<InputImageComponent name='selectedImage'
															multiple={true}
															// onHandleImageChange={this.handleImageChange}
															onHandleImageChange={this.changeHandler}
														/>
														<span className='cursor-pointer' onClick={() => this.showUploadedImages('images')}>Show Uploaded Images</span>
														<div className="imagePreviewContainer">
															{
																urls && (urls.map((url, i) => (
																	<div style={{ width: 90, padding: 5, margin: 5, backgroundColor: '#fff', boxShadow: '1px 2px 5px 0px hsla(0, 0%, 0%, 0.35)' }}>
																		<img className="imagePreview" src={url} />
																		<br />

																		<label className="material-icons"
																			style={{ color: 'red', fontSize: 12, justifyContent: 'center', display: 'grid', marginBottom: '-1px' }}
																			onClick={() => this.onRemove(i)}>Remove</label>

																		{/* <div className="details">
																			<h6>{files[i].name}</h6>
																			<h6>{files[i].size.toLocaleString()} KBs</h6>
																			<h6>{files[i].type}</h6>
																			
																		</div> */}
																	</div>
																)))
															}
														</div>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='usaStateManual'>Brochure upload</label>

														<InputPdfComponent name='selectedImage' multiple={true} /*onHandleImageChange={this.handleImageChange}*/ onHandlePdfChange={this.changeHandlerPDF} />
														<span className='cursor-pointer' onClick={() => this.showUploadedImages('pdf')}>Show Uploaded Brochures</span>
														<div className="imagePreviewContainer">
															{
																pdfurls && (pdfurls.map((pdfurls, i) => (
																	<div className="previewItem">
																		<img className="imagePreview" src={pdfurls} />
																		<br />

																		<label className="material-icons"
																			style={{ color: 'red', fontSize: 12, justifyContent: 'center', display: 'grid', marginBottom: '-1px' }}
																			onClick={() => this.onRemovePDF(i)}>Remove</label>

																		{/* <div className="details">
																			<h6>{files[i].name}</h6>
																			<h6>{files[i].size.toLocaleString()} KBs</h6>
																			<h6>{files[i].type}</h6>
																			
																		</div> */}
																	</div>
																)))
															}
														</div>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>
															Type address or
														</label>
														{' '}
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5, cursor: 'pointer', color: '#0070ff' }} htmlFor='' onClick={this.showDraggableMarkerModal}>set location</label>
														<InputAddressComponent value={this.state.address}
															onHandleAddressChange={this.handleAddressChange}
														/>
														{
															this.state.address
															&&
															<button type='button'
																className='close text-uppercase font-weight-bold'
																style={{ fontSize: 12, width: 50, margin: 5, float: 'left' }}
																onClick={() => this.clearAddress()}
															>
																clear
															</button>
														}
														<div style={{ marginBottom: 5 }}></div>
														{this.validator.message('address', this.state.coordinates ? this.state.coordinates : dragableMarkerData, 'required')}

													</div>
													{





														// dragableMarkerData
														// 	?
														// 	<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>

														// 		<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Coordinates</label>
														// 		<input type="text" value={`${dragableMarkerData.coordinates[0]} , ${dragableMarkerData.coordinates[1]}  `} readOnly />
														// 		<div style={{ marginBottom: 5 }}></div>


														// 	</div>
														// 	:
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>

															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Coordinates</label>
															<InputBoxComponent name='swap_coordinates' value={this.state.swap_coordinates} onHandleInputChange={this.handleInputChange} />
															<div style={{ marginBottom: 5 }}></div>


														</div>
													}
												</Row>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 col-xs-12  clear-both'>
														<p className='text-uppercase' style={{ fontSize: 12, paddingBottom: 5 }}><b>These fields are optional.</b> If you notice in your listing that the city and state fields do not show correctly, please add your city and state fields manually below. </p>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 clear-both'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='city'>City</label>
														<InputBoxComponent name='city' value={this.state.city} onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='usaState'>State</label>

														<InputMultiSelectComponent name='usaState'
															options={sortArray(this.state.usaStateArr)}
															onHandleSingleSelect={this.handleMultiSelectusa}
															isMulti={false}
															value={this.state.usaState}
														/>
													</div>


												</Row>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Description</label>
														<InputTextareaComponent name='description'
															value={this.state.description}
															onHandleTextareaChange={this.handleTextareaChange}
														/>
													</div>
												</Row>
												<hr />
											</div>

											<div id='siteForm' className='display-none'>
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Property Attributes</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Price in $</label>
														<InputBoxComponent type='number' min='0' name='price'
															value={this.state.price}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Per Unit</label>
														<InputBoxComponent type='text' name='perUnit'
															value={this.state.perUnit}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 pull-left margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputMin'>Acreage</label>
														<InputBoxComponent type='number' min='0' name='acreage'
															value={this.state.acreage}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputZoning'>Zoning</label>
														<InputMultiSelectComponent name='siteZoning'
															value={this.state.siteZoning}
															options={this.sortArray(this.state.zoningArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Pipeline Access</label>
														<InputMultiSelectComponent name='sitePipelineAccess'
															value={this.state.sitePipelineAccess}
															options={this.sortArray(this.state.pipelineAccessArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>Building</label>
														<InputMultiSelectComponent name='selectedBuildingOption'
															value={this.state.selectedBuildingOption}
															options={this.sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													<div id='buildingOptionsIfYesDiv' className={`${this.state.selectedBuildingOption && this.state.selectedBuildingOption === 'Yes' ? '' : 'display-none'}`}>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Building Sq. ft</label>
															<InputBoxComponent type='number' min='0' name='sitesSquareFeet'
																value={this.state.sitesSquareFeet}
																onHandleInputChange={this.handleInputChange}
															/>
														</div>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Clear Height (in ft)</label>
															<InputBoxComponent type='number' min='0' name='sitesInteriorRoofHeight'
																value={this.state.sitesInteriorRoofHeight}
																onHandleInputChange={this.handleInputChange}
															/>
														</div>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Electric Service in place</label>
														<InputMultiSelectComponent name='selectedPowerToSite'
															value={this.state.selectedPowerToSite}
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													<div className={`${this.state.selectedPowerToSite && this.state.selectedPowerToSite === 'Yes' ? '' : 'display-none'}`}>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Service Voltage (kV)</label>
															<InputBoxComponent type='number' min='0' name='serviceVoltage' value={this.state.serviceVoltage} onHandleInputChange={this.handleInputChange} />
														</div>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Distance to Substation (miles)</label>
															<InputBoxComponent type='number' min='0' name='distanceToSubstation' value={this.state.distanceToSubstation} onHandleInputChange={this.handleInputChange} />
														</div>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Natural gas in place</label>
														<InputMultiSelectComponent name='naturalGasInPlace'
															value={this.state.naturalGasInPlace}
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													<div className={`${this.state.naturalGasInPlace && this.state.naturalGasInPlace === 'Yes' ? '' : 'display-none'}`}>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Line diameter (inches)</label>
															<InputBoxComponent type='number' min='0' name='lineDiameter' value={this.state.lineDiameter} onHandleInputChange={this.handleInputChange} />
														</div>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Suitable for</label>
														<InputMultiSelectComponent name='siteSuitableFor'
															value={this.state.siteSuitableFor}
															options={this.sortArray(this.state.siteSuitableForArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Environmental</label>
														<InputMultiSelectComponent name='siteEnvironmental'
															value={this.state.siteEnvironmental}
															options={this.sortArray(this.state.envionmentalArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Air attainment status</label>
														<InputMultiSelectComponent name='airAttainmentStatus'
															value={this.state.airAttainmentStatus}
															options={this.sortArray(this.state.airAttainmentStatusArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
														/>
													</div>
												</Row>
												<hr />
											</div>

											<div id='terminalPortForm' className='display-none'>
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Terminal, Port and Transload Criteria</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Capabilities</label>
														<InputMultiSelectComponent name='capabilities'
															value={this.state.capabilities}
															options={this.sortArray(this.state.capabilitiesArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
													{
														this.state.capabilities.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Capabilities</label>
															<InputBoxComponent name='otherCapabilities' value={this.state.otherCapabilities} onHandleInputChange={this.handleInputChange} />
														</div>
													}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Equipment</label>
														<InputMultiSelectComponent name='equipments'
															value={this.state.equipments}
															options={this.sortArray(this.state.equipmentsArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
													{
														this.state.equipments.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Equipment</label>
															<InputBoxComponent name='otherEquipments' value={this.state.otherEquipments} onHandleInputChange={this.handleInputChange} />
														</div>
													}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='facilityRailcarStorageCapacityDiv'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Railcar storage capacity</label>
														<InputBoxComponent type='number' min='0' name='railcarStorageCapacity'
															value={this.state.railcarStorageCapacity}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='facilityAcresDiv'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Acres</label>
														<InputBoxComponent type='number' min='0' name='acres'
															value={this.state.acres}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Major features</label>
														<InputTextareaComponent name='majorFeatures'
															value={this.state.majorFeatures}
															onHandleTextareaChange={this.handleTextareaChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20' style={{ marginBottom: 8 }}>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Specifications</label>
														<InputTextareaComponent name='specifications'
															value={this.state.specifications}
															onHandleTextareaChange={this.handleTextareaChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Product storage</label>
														<InputMultiSelectComponent name='productStorage'
															options={this.sortArray(this.state.productStorageArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															value={this.state.productStorage}
														/>
													</div>
													{
														this.state.productStorage.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Product storage</label>
															<InputBoxComponent name='otherProductStorage' value={this.state.otherProductStorage} onHandleInputChange={this.handleInputChange} />
														</div>
													}
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Tankage</label>
														<InputMultiSelectComponent name='tankage' value={ this.state.tankage } options={ this.sortArray(this.state.selectOptionsArr) } onHandleSingleSelect={ this.handleMultiSelect } isMulti={false} />
													</div> */}
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='facilityNameplaceDiv' style={{ marginBottom: 30 }}>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Nameplate capacity</label>
														<InputBoxComponent type='text' name='nameplateCapacity' value={ this.state.nameplateCapacity } onHandleInputChange={ this.handleInputChange } />
													</div> */}
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Bulk type</label>
														<InputMultiSelectComponent name='bulkType' value={ this.state.bulkType } options={ this.sortArray(this.state.bulkTypeArr) } onHandleMultiSelect={ this.handleMultiSelect } isMulti={true} />
													</div> */}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Products handled</label>
														<InputMultiSelectComponent name='productsHandled'
															value={this.state.productsHandled}
															options={this.sortArray(this.state.productsHandledArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
													{
														this.state.productsHandled.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Product handled</label>
															<InputBoxComponent name='otherProductsHandled' value={this.state.otherProductsHandled} onHandleInputChange={this.handleInputChange} />
														</div>
													}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputMin'>Ports, Terminals & Transloads Acreage</label>
														<InputBoxComponent type='number' min='0' name='pttAcreage'
															value={this.state.pttAcreage}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Indoor storage</label>
														<InputMultiSelectComponent name='indoorStorage' value={ this.state.indoorStorage } options={ this.sortArray(this.state.indoorStorageArr) } onHandleSingleSelect={ this.handleMultiSelect } isMulti={false} />
													</div> */}
												</Row>
												<hr />
											</div>

											<div id='warehouseForm' className='display-none'>
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Warehouse/Distribution Center Criteria</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Warehouse Price in $</label>
														<InputBoxComponent type='number' min='0' name='warehousePrice'
															value={this.state.warehousePrice}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Per Unit</label>
														<InputBoxComponent type='text' name='warehousePricePerUnit'
															value={this.state.warehousePricePerUnit}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Warehouse Capabilities</label>
														<InputMultiSelectComponent name='warehouseCapabilities'
															value={this.state.warehouseCapabilities}
															options={this.sortArray(this.state.warehouseCapabilitiesArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Min Size (available sq. ft)</label>
														<InputBoxComponent type='number' min='0' name='warehouseMinSize'
															value={this.state.warehouseMinSize}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Max Size (available sq. ft)</label>
														<InputBoxComponent type='number' min='0' name='warehouseMaxSize'
															value={this.state.warehouseMaxSize}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Temperature control</label>
														<InputMultiSelectComponent name='temperatureControl'
															value={this.state.temperatureControl}
															options={this.sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Secured Parking</label>
														<InputMultiSelectComponent name='securedParking'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
															value={this.state.securedParking}
														/>
													</div>



													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Total dock doors</label>
														<InputBoxComponent type='number' min='0' name='TotalDockDoors'
															value={this.state.TotalDockDoors}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Drive in dock doors</label>
														<InputBoxComponent type='number' min='0' name='DriveInDockDoors'
															value={this.state.DriveInDockDoors}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Dock level doors</label>
														<InputBoxComponent type='number' min='0' name='DockLevelDoors'
															value={this.state.DockLevelDoors}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Total area (sq ft)</label>
														<InputBoxComponent type='number' min='0' name='TotalWarehouseArea'
															value={this.state.TotalWarehouseArea}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Available area (sq ft)</label>
														<InputBoxComponent type='number' min='0' name='TotalWarehouseAvailableArea'
															value={this.state.TotalWarehouseAvailableArea}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Office Space (sq ft)</label>
														<InputBoxComponent type='number' min='0' name='TotalWarehouseOfficeSpaceArea'
															value={this.state.TotalWarehouseOfficeSpaceArea}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>




													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Parking spaces (for both inbound and outbound)</label>
														<InputBoxComponent type='number' min='0' name='parkingSpaces'
															value={this.state.parkingSpaces}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Food grade</label>
														<InputMultiSelectComponent name='foodGrade'
															value={this.state.foodGrade}
															options={this.sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>FTZ (FOREIGN TRADE ZONE)</label>
														<InputMultiSelectComponent name='freeTradeZone'
															value={this.state.freeTradeZone}
															options={this.sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Clear height (in ft)</label>
														<InputBoxComponent type='number' min='0' name='roofHeight'
															value={this.state.roofHeight}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
												</Row>
												<hr />
											</div>

											<div id='marineAccessForm' className='display-none'>
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Marine Access</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Marine access</label>
														<InputMultiSelectComponent name='marineAccess'
															value={this.state.marineAccess}
															options={this.sortArray(this.state.marineAccessArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='siteDocInPlaceDiv'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Dock in place now?</label>
														<InputMultiSelectComponent name='docInPlace'
															value={this.state.docInPlace}
															options={this.sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													{
														this.state.docInPlace.toLowerCase().includes('Yes'.toLowerCase()) &&
														<>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Depth (ft)</label>
																<InputBoxComponent type='number' min='0' name='portDepth'
																	value={this.state.portDepth}
																	onHandleInputChange={this.handleInputChange}
																/>
															</div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Length (ft)</label>
																<InputBoxComponent type='number' min='0' name='portLength'
																	value={this.state.portLength}
																	onHandleInputChange={this.handleInputChange}
																/>
															</div>
														</>
													}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='siteWhichRiverDiv'>
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-14'>
																	Enter a waterway into the text box and press Enter to see it added.
																</strong>
															</Tooltip>
														}>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Waterways <i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i></label>

														</OverlayTrigger>
														<InputMultiSelectComponent name='waterways'
															value={this.state.waterways}
															options={this.sortArray(arrWaterWays)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
													</div>

													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20' id='siteBADistanceToNavigableWater'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Distance to navigable water (miles)</label>
														<InputBoxComponent type='number' min='0' name='navigableWater'
															value={this.state.navigableWater}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
												</Row>
												<hr />
											</div>
											<div id='servingCarriersForm' className='display-none'>
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Serving Rail Carriers</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Select class I connections</label>
														<InputMultiSelectComponent
															name='siteClassOneConnections'
															value={this.state.siteClassOneConnections}
															options={this.sortArray(this.state.classOneConnectionsArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
														/>
														{/* <Select
															closeMenuOnSelect={false}
															name='siteClassOneConnections'
															value={this.state.siteClassOneConnections}
															isMulti
															onHandleMultiSelect={this.handleMultiSelect}
															options={sortArray(this.state.classOneConnectionsArr)}
														/> */}
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Short Lines</label>
														<InputMultiSelectComponent
															name='siteShortLines'
															value={this.state.siteShortLines}
															options={shortLinesOptionsTmp}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															reduceFontSize={true}
														/>
														{/* <Select
															closeMenuOnSelect={false}
															name='siteShortLines'
															value={this.state.siteShortLines}
															isMulti
															onHandleMultiSelect={this.handleMultiSelect}
															options={shortLinesOptionsTmp}
														/> */}
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Rail in place</label>
														<InputMultiSelectComponent
															name='selectedRailInPlace'
															value={this.state.selectedRailInPlace}
															options={this.sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect1}
															isMulti={false}
														/>
													</div>
													{
														this.state.selectedRailInPlace && this.state.selectedRailInPlace.toLowerCase().includes('Yes'.toLowerCase()) &&
														<div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Railcar storage</label>
																<InputMultiSelectComponent
																	name='railcarStorage'
																	value={this.state.railcarStorage}
																	options={this.sortArray(this.state.railcarStorageArr)}
																	onHandleMultiSelect={this.handleMultiSelect}
																	isMulti={true}
																/>
															</div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Unit train capable</label>
																<InputMultiSelectComponent
																	name='unitTrainCapable'
																	value={this.state.unitTrainCapable}
																	options={this.sortArray(this.state.selectOptionsArr)}
																	onHandleSingleSelect={this.handleMultiSelect1}
																	isMulti={false}
																/>
															</div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Railcar spots/capacity</label>
																<InputBoxComponent
																	type='number'
																	min='0'
																	name='railcarCapacity'
																	value={this.state.railcarCapacity}
																	onHandleInputChange={this.handleInputChange}
																/>
															</div>
														</div>
													}

												</Row>
												{/* <Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Rail in place option</label>
														<InputBoxComponent type='text' name='siteRailInPlaceOption' value={ this.state.siteRailInPlaceOption } onHandleInputChange={ this.handleInputChange } />
													</div>
												</Row> */}
											</div>

											<div id='additionalAttributesForm' className='display-none'>
												{/* <hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} style={{fontSize: 20}}>Additional Attributes</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Parking Capacity for</label>
														<InputMultiSelectComponent name='parkingCapacity' value={this.state.parkingCapacity} options={ this.sortArray(this.state.parkingCapacityArr) } onHandleMultiSelect={ this.handleMultiSelect } isMulti={true} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Nearest Interstate</label>
														<InputBoxComponent type='text' name='nearestInterstate' value={this.state.nearestInterstate} onHandleInputChange={ this.handleInputChange } />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Miles to Interstate</label>
														<InputBoxComponent type='text' name='milesToInterstate' value={this.state.milesToInterstate} onHandleInputChange={ this.handleInputChange } />
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Nearest Airport</label>
														<InputBoxComponent type='text' name='nearestAirport' value={this.state.nearestAirport} onHandleInputChange={ this.handleInputChange } />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Miles to Airport</label>
														<InputBoxComponent type='text' name='milesToAirport' value={this.state.milesToAirport} onHandleInputChange={ this.handleInputChange } />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Silos</label>
														<InputMultiSelectComponent name='silos' value={this.state.silos} options={ this.sortArray(this.state.selectOptionsArr) } onHandleSingleSelect={ this.handleMultiSelect } isMulti={false} />
													</div>
												</Row> */}
											</div>

											<div id='checkboxForm' className='display-none'>
												<hr />
												{
													this.state.selectedPropertyType.includes("Sites") &&
													<Row>
														<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
															{/* <InputCheckboxComponent
																name='plgCertification'
																checked={this.state.plgCertification}
																onHandleCheckboxChange={this.handleCheckboxChange}
																label='Please contact me about PLG site certification'
															/> */}
														</div>
													</Row>
												}
												{
													this.state.selectedPropertyType.includes("Warehouse") &&
													<Row>
														<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
															{/* <InputCheckboxComponent name='warehouseAudits'
																checked={this.state.warehouseAudits}
																onHandleCheckboxChange={this.handleCheckboxChange}
																label='Provide information about Warehouse Audits'
															/> */}
														</div>
													</Row>
												}
												<Row>
													<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
														{/* <InputCheckboxComponent
															name='siteQualification'
															checked={this.state.siteQualification}
															onHandleCheckboxChange={this.handleCheckboxChange}
															label='I would like to receive RFI/RFP’s for which my site is qualified'
														/> */}
													</div>
												</Row>
											</div>

											<Row>
												<div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 margin-bottom-20'>
													<button disabled={this.state.submitbutton} type='button' className='btn btn-default border-radius-25-imp' onClick={this.updatePlace}>
														{
															this.state.submitbutton ?
																<i className="fa fa-spinner fa-spin"></i> :
																''


														}
														Submit</button>


												</div>
												<div className='col-lg-3 col-md-3 col-sm-6 col-xs-6 margin-bottom-20'>
													<button disabled={this.state.submitbutton} type='button' className='btn btn-default border-radius-25-imp' onClick={this.cancelEditChanges}>Cancel Edit</button>
												</div>
												<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
													{
														this.state.submitbutton ?
															<span> Your listing will be submitted shortly.</span> :
															''


													}
												</div>

											</Row>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
				<Modal dialogClassName='custom-modal' show={this.state.showSuccessModal} onHide={this.handleSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Listing Updated
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Your Listing has been updated successfully!
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				{/* Error modal */}
				<Modal dialogClassName='custom-modal' show={this.state.showErrorModal} onHide={this.handleErrorModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGErrorIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Something went wrong! Try Again.
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.handleErrorModal}>Cancel</button>
					</Modal.Footer>
				</Modal>

				{/* Show uploaded images */}
				<Modal dialogClassName='custom-modal' show={this.state.showUploadedImagesModal} onHide={this.handleUploadedImagesModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							{
								this.state.uploadedImagesArr.length > 0 ?
									this.state.uploadedImagesArr.map((link) =>
										<div key={Math.random()} style={{ width: '50%', float: 'left', padding: 15 }}>
											<div><img src={link.url} /></div>
											<div style={{ wordBreak: 'break-word' }}>{link.url.substring(link.url.lastIndexOf('/') + 1, link.url.length)}</div>
											<i className='fa fa-trash cursor-pointer font-size-22-imp' style={{ color: 'red' }} onClick={() => this.confirmDeleteImage(link.url)}></i>
										</div>
									) : (
										<h2>No image present.</h2>
									)
							}
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef', clear: 'both' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleUploadedImagesModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				{/* Show uploaded PDF */}
				<Modal dialogClassName='custom-modal' show={this.state.showUploadedPdfModal} onHide={this.handleUploadedPdfModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							{
								this.state.uploadedImagesArr.length > 0 ?
									this.state.uploadedImagesArr.map((link) =>
										<div key={Math.random()} style={{ width: '50%', float: 'left', padding: 15 }}>
											{/* <div><img src={link.url} /></div> */}
											<div style={{ wordBreak: 'break-word' }}><a href={link.url} >{link.url.substring(link.url.lastIndexOf('/') + 1, link.url.length)}</a></div>
											<i className='fa fa-trash cursor-pointer font-size-22-imp' style={{ color: 'red' }} onClick={() => this.confirmDeleteImage(link.url)}></i>
										</div>
									) : (
										<h2>No PDF present.</h2>
									)
							}
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef', clear: 'both' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleUploadedPdfModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				{/* Delete confirmation modal */}
				<Modal show={this.state.showDeleteImageModal} onHide={this.handleDeleteImageModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp' onClick={this.deleteImage}>Confirm</button>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.handleDeleteImageModal}>Cancel</button>
					</Modal.Footer>
				</Modal>

				{/* Delete Image success modal */}
				<Modal dialogClassName='custom-modal' show={this.state.showDeleteImageSuccessModal} onHide={this.handleDeleteImageSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Image Deleted
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Image has been deleted successfully!
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleDeleteImageSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				{/* Delete PDF success modal */}
				<Modal dialogClassName='custom-modal' show={this.state.showDeletePdfSuccessModal} onHide={this.handleDeletePdfSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Brochure Deleted
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Brochure has been deleted successfully!
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleDeletePdfSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				{/* Create a draggable Marker  */}

				<Modal dialogClassName='custom-modal-step3' show={this.state.showmarkerModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton onClick={this.CloseDraggableMarkerModal}></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<EditDraggableMarker coordinates={this.state.coordinates} address={this.state.address} />
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.SaveDraggableMarkerModal}>Save</button>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.CloseDraggableMarkerModal}>Cancel</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}
}

export default withRouter(EditPlace);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)

const SVGErrorIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)