import React, { useEffect, useState } from 'react';
import {
	CardElement,
	ElementsConsumer,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import { NavLink, Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Elements } from '@stripe/react-stripe-js';
import UserProfile from './UserProfile';
import '../css/priceplans.css';
import { useHistory } from 'react-router-dom';
const cardStyle = {
	style: {
		base: {
			color: '#32325d',
			fontFamily: 'Arial, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '20px',

			'::placeholder': {
				color: '#32325d'
			}
		},

		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a'
		}
	}
};
const CheckoutStrip = ({ }) => {
	const elements = useElements();
	const history = useHistory();
	const [subscriptionId, setSubscriptionId] = useState('');
	const [emailId, setemailId] = useState('');
	const [cardfill, setcardfill] = useState(false);
	const [CardAvailable, setCardAvailable] = useState(false);
	const [userSubscriptionId, setuserSubscriptionId] = useState('');
	const [userSubId, setuserSubId] = useState('');
	const [userPlanId, setuserPlanId] = useState('');
	const [userDowngradePlanId, setuserDowngradePlanId] = useState('');
	const stripe = useStripe();
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const [disabled, setDisabled] = useState(true);
	//const planNamesArr = localStorage.getItem('planNames') ? JSON.parse(localStorage.getItem('planNames')) : [];
	const planData = JSON.parse(sessionStorage.getItem('planNames'));
	if (planData) {

		var id = planData.id;
	}
	else {
		history.push('/')
	}

	const email = emailId;

	console.log('ID for the plan', id)
	console.log('email id of the user', email)
	let customerId
	let clientSecret
	let priceId
	const handleChange = async (event) => {

		setDisabled(event.empty);
		setError(event.error ? event.error.message : '');
	};

	useEffect(() => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile', {
			email: UserProfile.getEmail(),
		})
			.then(function (response) {
				console.log(response);
				setSubscriptionId(response.data[0].sw_stripe_subscription_id)
				setemailId(response.data[0].sw_email)
				setuserPlanId(response.data[0].sw_plan_id)
				setuserDowngradePlanId(response.data[0].sw_downgrade_plan_id)
				localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
				localStorage.setItem('membershipOptions', response.data[0].options);
				console.log("sub id:", response.data[0].sw_stripe_subscription_id)
				if (response.data[0].sw_stripe_subscription_id != null) {
					
					axios.post(process.env.REACT_APP_BASE_URL + '/api/get-subscription-status',
						{
							headers: {
								'Content-Type': 'application/json',
							},
							subscriptionsid: response.data[0].sw_stripe_subscription_id,
							// subscriptionsid:"sub_K9SXuSOCaxAKhR"
						}).then(response => {
							//alert("inside response")
							if (response.status === 200) {
								console.log("discount :", response.data.discount)
								//alert("success")
								if (response.data.discount == null) {
									setCardAvailable(true);
								}
							}
							else {
								alert("Something went wrong")
							}
						});


				}
				else {
					//alert("Something  wrong")
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const paymentConferm = async (event) => {
		const cardElement = elements.getElement(CardElement);
		clientSecret = event;
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});
		const paymentIntent = stripe.confirmCardPayment(
			clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement),

			}
		}).then(statusofuser => {
			console.log('paymentIntent:', statusofuser.paymentIntent)
			try {
				if (statusofuser.paymentIntent.status == 'succeeded') {
					setError(null);
					setProcessing(false);
					setSucceeded(true);

					console.log('status', statusofuser.paymentIntent.status)
					if (statusofuser.paymentIntent.status == 'succeeded') {

						console.log('we want this sub id', userSubId)
						var subid = localStorage.getItem('SubscriptionId');
						var cusid = localStorage.getItem('customerId');
						console.log('subid is local storage:', subid)
						if (subid === '') {
						} else {
							axios.post(process.env.REACT_APP_BASE_URL + '/api/update-user-details', {
								subscription_id: subid,
								customer_id: cusid,
								user_plan_id: planData.id,
								usr_email: email,
							})
								.then(function (response) {
									console.log('responce of update-user-details', response);
									axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile', {
										email: email,
									})
										.then(function (response) {
											if (response.status === 200) {
												localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
												localStorage.setItem('membershipOptions', response.data[0].options);
												history.push('/upgrade-success');
											}
										})
										.catch(function (error) {
											console.log(error);
										});
								})
								.catch(function (error) {
									console.log(error);
								});

						}

					}
				}

			} catch (error) {
				setError(<span style={{ color: "red", marginLeft: 15 }}>Payment failed.</span>);
				setProcessing(false);
			}

		})
	}

	const handlesubmit = async (event) => {
		event.preventDefault();
		setProcessing(true);
		if (!stripe || !elements) {
			return;
		}
		if (id == 3) {
			priceId = 'price_1K2dBhI9aRcuI4Jh6Qr5fxnx'
		}
		else if (id == 4) {
			priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om'
		}
		else if (id == 5) {
			priceId = 'price_1JidsnI9aRcuI4JhRaVojddT'
		}
		else if (id == 6) {
			priceId = 'price_1JidtLI9aRcuI4JhAgcKR8aI'
		}

		if (userPlanId == 2 || userDowngradePlanId == 2) {
			console.log("inside new customer creation")
			await axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
				email: email,
				//customerId:'',
			})
				.then(function (response) {
					console.log(response);
					customerId = response.data.customer.id
					console.log('customer id:', customerId)
					localStorage.setItem('customerId', response.data.customer.id);
					//console.log('price id',priceId)
					//customerId=this.setState({customerId})  
					//create subscription for the customer on the stripe
					axios.post(process.env.REACT_APP_BASE_URL + '/api/create-subscription', {
						priceId: priceId,
						customerId: customerId,
					})
						.then(function (response) {
							console.log(response);
							clientSecret = response.data.clientSecret
							console.log('client secret', clientSecret)
							console.log('client new sub id', response.data.subscriptionId)
							setuserSubId(response.data.subscriptionId)
							localStorage.setItem('SubscriptionId', response.data.subscriptionId);
							console.log('userSubscriptionId', userSubId);


							paymentConferm(clientSecret);

							//alert('subscription created')
						})
						.catch(function (error) {
							console.log(error);
						});
					//console.log('clinet secret:', clientSecret)

				})
				.catch(function (error) {
					console.log(error);
				});


		}
		else {

			await axios.post(process.env.REACT_APP_BASE_URL + '/api/update-user-plan', {
				email: email,
				planid: id,
				priceId: priceId,
				subscriptionID: subscriptionId,

				//customerId:'',
			})
				.then(function (response) {
					console.log('responce of update-user-plan', response);
					axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile', {
						email: email,
					})
						.then(function (response) {
							if (response.status === 200) {
								localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
								localStorage.setItem('membershipOptions', response.data[0].options);
								history.push('/upgrade-success');
							}
						})
						.catch(function (error) {
							console.log(error);
						});
				})
				.catch(function (error) {
					console.log(error);
				});



		}


		// if (error) {
		// 	setError(`Payment failed ${error.message}`);
		// 	setProcessing(false);
		// } else {
		// 	setError(null);
		// 	setProcessing(false);
		// 	setSucceeded(true);
		// }

	};

	return (
		<>
			{/* // <Elements stripe={promise}> */}
			<form id='payment-form' onSubmit={handlesubmit}>
				{
					CardAvailable == true ?
						<>
							{
								(userPlanId == 3 || userPlanId == 4 || userPlanId == 5) && userDowngradePlanId != 2 ?
									<>
										<input style={{ marginLeft: 15 }} type="checkbox" name='terms1' className='form-check-input' checked={cardfill} onChange={(e) => { setcardfill(e.target.checked) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> Pay with the previous card.</b><br /><br />
									</>
									:
									<>

									</>

							}
						</>
						:
						<>
						</>
				}

				{
					cardfill ?

						<>
							<br />
							<button style={{ marginLeft: 15 }} type='button' className='border-radius-25-imp btn btn-default ' id='place-order-btn' onClick={handlesubmit} >Proceed</button>
						</>
						:
						<>
							<CardElement options={cardStyle} onChange={handleChange} /><br />
							<button style={{ marginLeft: 15 }}
								disabled={processing || disabled || succeeded}
								id='submit'
							>
								<span id='button-text'>
									{processing ? (
										<div className='spinner' id='spinner'></div>
									) : (
										'Pay now'
									)}
								</span>
							</button>
						</>


				}






				{/* Show any error that happens when processing the payment */}
				{error && (
					<div className='card-error' role='alert'>
						{error}
					</div>
				)}
				{/* Show a success message upon completion */}
				<p className={succeeded ? 'result-message' : 'result-message hidden'}>
					<span style={{ color: 'green', marginLeft: 15 }}>Payment Successful</span>
				</p>
			</form>
			{/* // </Elements > */}
		</>
	);
};

export { CheckoutStrip };
