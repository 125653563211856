import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
// import HomePageFooter from './HomePageFooter';
import HomePageFooter from './HomePage/qloud/footerComponents';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Home from './HomePage';
import logo from '../logo.svg';
import InputBoxComponent from './InputBoxComponent';
import {Helmet} from "react-helmet";
import axios from 'axios';
import '../css/priceplans.css';
import MultiStepRegistration from './Register/MultiStepRegistration';
import UserProfile from './UserProfile';
import SimpleReactValidator from 'simple-react-validator';


const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: Header) => void;
    data?: any;
    shoppingCartCount?: number;
    bookmarksCount?: number;
    logo?: string;
    btnBackgroundColor?: string;
    iconColor?: string;
    secondaryColor?: string;
    history: Array<any>;
}


class PlansAndPricing extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    loginValidator: SimpleReactValidator = null;
    OTPValidator: SimpleReactValidator = null;
    helpers: any = null;

    constructor(props: PHeader) {
        super(props);
        this.state = {
            showLoginModal: false,
            showForgotPasswordModal: false,
            login_email: '',
            login_password: '',
            isLoggedIn: false,
            nextURL: '',
            propertyType: '',
            propertySubType: '',
            fpEmail: '',
            showLoginSuccessModal: false,
            showLogoutSuccessModal: false,
            showRegistrationModal: false,
            fname: '',
            lname: '',
            companyName: '',
            password: '',
            confirm_password: '',
            email: '',
            showRegistrationSuccessModal: false,
            reacatpcha_value: null,
            // shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
            bookmarksCount: props.bookmarksCount ? props.bookmarksCount : 0,
            shoppingCartCount: props.shoppingCartCount ? props.shoppingCartCount : 0,
        }
        //this.handleMyListing = this.handleMyListing.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.checkIfLoginPage = this.checkIfLoginPage.bind(this);
        this.setLoginEmail = this.setLoginEmail.bind(this);
        this.setLoginPassword = this.setLoginPassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);


        this.validator = new SimpleReactValidator({
            validators: {
                phone: {  // name the rule
                    message: 'The :phone must be a valid phone number.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                    required: true  // optional
                }
            }
        });
    }

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }
   
    componentWillMount() {
        this.loginValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        });
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
        this.OTPValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }


    handleShow(nextURL, type = '', subType = '') {
        this.setState({
            nextURL: nextURL,
            propertyType: type,
            propertySubType: subType,
        });

        if (cookies.get('token') !== undefined) {
            fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
                    'x-access-token': cookies.get('token')
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        if (nextURL === '/my-profile') {
                            // this.props.data.history.push('/create');
                            this.props.history.push({
                                pathname: '/my-profile',
                                state: {
                                    propertyType: this.state.propertyType,
                                    propertySubType: this.state.propertySubType,
                                }
                            })
                        }
                    } else {
                        UserProfile.forgetEmail();
                        cookies.remove('token');
                        this.props.history.push('/');
                        window.scrollTo(0, 0);
                        // const error = new Error(res.error);
                        // throw error;
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false, redirect: true });
                });
        } else {
            this.handleOpen();
        }
    }
    handleOpen = () => {
        this.setState({ showRegistrationModal: true });
    }
    handleOTPVerification = () => {
        if (this.OTPValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    otp: this.state.otp,
                    login_email: this.state.login_email,
                }).then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        this.handleOTPModal();
                        this.handleLoginSuccessResponse(response);
                    }
                }).catch(function (error) {
                    // handle your error
                });
        } else {
            this.OTPValidator.showMessages();
            this.forceUpdate();
        }
    }
    logoutUser() {
        // UserProfile.forgetEmail();
        // UserProfile.forgetFirstName();
        // UserProfile.forgetLastName();
        cookies.remove('token');
        localStorage.clear();
        this.setState({ isLoggedIn: false, showLogoutSuccessModal: true });
        this.props.data.history.push('/');
    }
    handleClose() {
        this.setState({ showRegistrationModal: false });
    }
    checkIfLoginPage() {
        if (this.props.data.location.pathname === '/register') {
            this.handleClose();
        } else {
            this.props.data.history.push({
                pathname: '/register',
                state: {
                    nextURL: '/create',
                }
            })
        }
    }
    setLoginEmail(event) {
        this.setState({ login_email: event.target.value });
    }
    setLoginPassword(event) {
        this.setState({ login_password: event.target.value });
    }
    handleLogin() {
        if (this.loginValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    login_email: this.state.login_email,
                    login_password: this.state.login_password,
                }).then((response: {
                    status: number
                    , data: {
                        bookmarks_str: string
                        , email: string
                        , first_name: string
                        , last_name: string
                        , max_no_entries: string
                        , options: string
                        , place_names_arr: string //Array<any>
                        , user_type: string
                        , token: string
                    }
                }) => {
                    document.getElementById('login_error').style.display = 'none';
                    document.getElementById('verify_email_error').style.display = 'none';
                    console.log(response);
                    this.handleClose();
                    // alert(window.location.href)
                    if (response.status === 200) {
                        let bookmarksCount = 0;
                        console.log('response.data')
                        console.log(response.data)
                        if (response.data.bookmarks_str) {
                            const bookmarksArr = response.data.bookmarks_str.split(',');
                            bookmarksCount = bookmarksArr.length;
                        }
                        localStorage.setItem('bookmarks', response.data.bookmarks_str);
                        localStorage.setItem('listingNames', response.data.place_names_arr);
                        localStorage.setItem('maxNoEntries', response.data.max_no_entries);
                        localStorage.setItem('membershipOptions', response.data.options);

                        if (response.data.user_type === 'admin') {
                            this.setState({
                                showOTPModal: true
                            });
                        } else {
                            this.handleLoginSuccessResponse(response);
                        }
                    } else {
                        this.setState({ isLoggedIn: false });
                        if (response.status === 204) {
                            document.getElementById('verify_email_error').style.display = 'block';
                            document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
                        } else {
                            document.getElementById('login_error').style.display = 'block';
                            document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                }).catch((error) => {
                    // handle your error
                    // document.getElementById('login_error').style.display = 'block';
                    // document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                });
        } else {
            this.loginValidator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    handleShowForgotPasswordModal = () => {
        this.setState({ showForgotPasswordModal: true });
        this.handleClose();
    }
    handleLoginSuccessModal() {
        this.setState({ showLoginSuccessModal: false });
    }

    handleRegistrationSuccessModal = () => {
        this.setState({ showRegistrationSuccessModal: false });
    }

    showRegistrationModal = () => {
        this.setState({
            showRegistrationModal: true,
            showLoginModal: false,
        });
    }
    handleForgotPasswordClose = () => {
        this.setState({ showForgotPasswordModal: false });
        this.handleOpen();
    }
    setFPEmail = (event) => {
        this.setState({ fpEmail: event.target.value });
    }
    handleLogoutSuccessModal = () => {
        this.setState({ showLogoutSuccessModal: false });
    }
    handleOTPModal = () => {
        this.setState({ showOTPModal: false });
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    }

    handleRegistrationModal = () => {
        this.setState({ showRegistrationModal: false });
    }
    handleLoginSuccessResponse = response => {
        UserProfile.setEmail(response.data.email);
        UserProfile.setFirstName(response.data.first_name);
        UserProfile.setLastName(response.data.last_name);
        UserProfile.setUserType(response.data.user_type);
        UserProfile.setCompanyName(response.data.companyName);

        this.setState({
            isLoggedIn: true,
            showLoginSuccessModal: true,
        });

        cookies.set('token', response.data.token);
        this.forceUpdate();

        if (this.props.data.location.pathname === '/register' || this.props.data.location.pathname === '/success' || this.state.nextURL === '/create') {
            // this.props.data.history.push('/create');
            this.props.data.history.push({
                pathname: '/create',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
        else if (this.state.nextURL === '/listing') {
            // this.props.data.history.push('/listing');
            this.props.data.history.push({
                pathname: '/listing',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
    }
    sendFPEmail = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
            {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                fpEmail: this.state.fpEmail
            }).then(response => {
                document.getElementById('fp_success').style.display = 'block';
                document.getElementById('fp_error').style.display = 'none';
            }).catch(function (error) {
                document.getElementById('fp_success').style.display = 'none';
                document.getElementById('fp_error').style.display = 'block';
                // alert('Something went wrong! Please try after sometime.')
            });
    }


    child: Header = null;



    render() {

        return (
            <>
                <Helmet>
                    <title>Terms And Conditions</title>
                    <meta property="og:url" content={window.location.href} />
                    <meta name='description' content="Terms And Conditions" />
                    <meta property='og:title' content="Terms And Conditions" />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>

                <Header data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 1500, fontFamily: "Roboto, sans-serif" }}>
                    <div className="justify-content-center">
                    <div className='col-lg-2 col-md-12 col-sm-12 padding-0 ' ></div> 
                        <div className='col-lg-8 col-md-12 col-sm-12 padding-0 ' >

                            <div className="container-fluid" style={{ marginTop: 100, fontFamily: "Roboto, sans-serif" }} >
                                <h1 className="content_area" style={{ fontWeight: 600, fontFamily: 'Roboto, sans-serif', marginLeft: 15 }}>Privacy Policy </h1>
                                {/* <p style={{ fontSize: "18px", marginLeft: 15 }} className="copy margin--top-c4">Select a subscription that best fits your needs, or contact us for corporate or custom solutions. </p> */}

                                <div className='container-fluid' >
                                    <p className="para_content-new">

                                        This privacy policy is designed to tell you about the information we, Professional Logistics Group, Inc. (“PLG” or “we”), collect on the Industrial Navigator website (accessed via the domain name <b>industrialnavigator.com</b>) (“Website”), what we do with that information, your rights with respect to that information, and related topics.
                                        <br /><br /><b>What we do <br /><br /></b>
                                        PLG provides the Website for users to market and locate industrial sites and supply chain services throughout North America.

                                        <br /><br /><b>Information we collect <br /><br /></b>
                                        <i>Non-Personal Information</i>
                                        <br /><br />When you browse our Website, you do so anonymously, meaning no personally identifiable information is collected.  Through Google Analytics, we receive aggregated non-personally identifiable information (i.e. information we cannot connect to any specific individual) including:


                                        <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                            <li>length of time of visit</li>
                                            <li>the Website pages visited  </li>
                                            <li>the amount of time spent on each page </li>
                                            <li>the referring site details (such as Google search or LinkedIn) that brought you to our page (if any)</li>
                                            <li>the type of browser used (Google Chrome, Firefox, Internet Explorer, etc.) </li>
                                            <li>general geographic location by country (United States, Canada, United Kingdom, etc.) and city (if available) </li>
                                            <li>gender (if available) </li>
                                            <li>language used (if available) </li>
                                            <li>operating system (Windows, Macintosh, iOS, Android, etc.) </li>
                                            <li>device type (mobile vs. desktop)</li>
                                            <li>screen size (for mobile devices) </li>
                                        </ul>
                                        <br /><br /><i>Personally Identifiable Information</i> <br /><br />
                                        We collect personally identifiable information (information that relates to a specific use and through which we can identify you) only when you voluntarily provide it to us by signing up for a plan, by logging in to the Website, by opting in to receive emails, and filling out the “Contact Us” form on our Website.

                                        <div style={{ marginLeft: 20 }}>
                                            <br /><br /><i>Sign Up Information</i> <br />
                                            When you sign up for a plan (including the Basic Plan), we collect certain information from you to create your account (“Sign Up Information”).  Sign Up Information includes your first and last name, the name of your company, your email address, and a password that you select.  We also ask general questions to understand your interest in the Website, your planned use of Website features and how you heard about us.

                                            <br /><br /><i>Contact Us Information</i> <br />
                                            When you fill out the “Contact Us” form on the Website we collect information you input into the form (“Contact Us Information”).  Contact Us Information includes your first and last name, your email, and whatever information you include in your message to us.

                                            <br /><br /><i>Email Opt-In</i> <br />
                                            We also collect personally identifiable information if you sign-up or opt-in to receive emails from us, including marketing and other informational emails.  If you opt-in to receive emails, we also collect individualized information about who opened a specific email and click through information.

                                        </div>
                                        <br /><br /> The above is a full description of the types of information we have collected about consumers in the past 12 months.
                                        <br /><br /><b>Information we do not collect</b><br /><br />
                                        We do not collect information not explicitly described above.
                                        We also do not require or collect Social Security numbers, driver’s license or other government issued ID numbers, financial account numbers, health insurance or medical identification numbers, account passwords, security question and answers, or biometric information.  Additional we do not seek or collect personally identifiable information about racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, sexual orientation or health information.
                                        <br /><br /><b>When we collect this information</b><br /><br />
                                        We collect non-personal information whenever you browse our Website.

                                        <br />We collect personally identifiable information only when you voluntarily provide it to us either when you sign up for a plan, when you opt-in to receive emails from us, or when you fill out the Contact Us form.
                                        <br /><br /><b>What we do with this information</b><br /><br />
                                        <div style={{ marginLeft: 20 }}>
                                            <i>Non-Personally Identifiable Information:</i> We us the non-personal information to understand how many, when and where visitors come to our Website and how they got there. We also use it to understand which parts of our Wesbsite are most relevant and how, when and where they are being used.  This data is provided to us by Google Analytics, and we cannot identify any individual user who visits our Website. You can find out more about Google’s privacy and security <a target='_blank' href='https://services.google.com/fh/files/misc/ga360_privacy-and-security_feature-brief_final.pdf'> here</a>.

                                            <br /><br /><i>Sign Up Information: </i> Sign Up Information is associated with your account on the Website.  If you view a listing, your name, email and company name may be provided to the user who posted the listing if their account provides them with access to know who viewed their listing and follow up.  That information may also be used to inform the user who posted the listing the number of times you viewed the listing and how often.  We use your answers to the general questions for market research for the Website.

                                            <br /><br /><i>Contact Us Information:</i> Contact Us Information is used to respond to your request, question, comment, or other information you provide in your message.  We delete Contact Us Information after one year after the request, question or comment has been resolved.

                                            <br /><br /><i>Email Opt-In: </i> Email Opt-In information is used to send you emails from us that you have selected to receive.  All emails that you sign up to receive from us contain “unsubscribe” links.  You can also unsubscribe at any time by contacting us directly.

                                        </div>

                                        <br /><br /><b>Your Rights With Respect to Your Information</b><br /><br />
                                        You may have heard about the General Data Protection Regulation (“GDPR”) in Europe, as well as other similar regulations and laws elsewhere. Such laws give people certain rights with respect to their personally identifiable information collected by us. Accordingly, PLG recognizes and intends to comply with these laws to the extent they are applicable, and provide those rights for all users, except as limited by applicable law. These rights include:

                                        <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                            <li><b>Right to Disclosure </b>– This is your right to request that we inform you of the specific pieces and categories of personally identifiable information we have collected about you, the categories of sources from which we collect that information, what we use it for, what we disclose to others, and to whom we disclose that information.  We do not sell your personal information. </li>
                                            <li><b>Right of Access</b> – This includes your right to access the personally identifiable information we gather about you, and your right to obtain information about the collection, use, sharing, disclosure, storage, security and processing of that information. </li>
                                            <li><b>Right to Correction </b>– This is your right to request correction of your personally identifiable information. </li>
                                            <li><b>Right to Delete</b> – This is your right to request, subject to certain limitations under applicable law, that your personally identifiable information be deleted from our possession (also known as the “Right to be forgotten”).  However, if applicable law requires us to comply with your request to delete such information, fulfillment of your request may prevent you from accessing features of the site that are only available to subscribers (you will still be able to use certain basic features of the Website – such as seeing general listings in a location – but will not be able to see details of listings or perform any searches). </li>
                                            <li><b>Right to Complain</b> – You have the right to make a complaint regarding our handling of your personally identifiable information with the appropriate authority.  </li>
                                            <li><b>Right to Restrict Processing</b> – This is your right to request restriction of how and why your personally identifiable information is used or processed. </li>
                                            <li><b>Right to Object</b> – This is your right, in certain situations, to object to how or why your personally identifiable information is processed.</li>
                                            <li><b>Right to Portability</b> – This is your right to receive the personally identifiable information we have about you and the right to transmit it to another party.</li>
                                            <li><b>Right to not be subject to Automated Decision-Making</b> – This is your right to object and prevent any decision that could have a legal, or similarly significant, effect on you from being made solely based on automated processes. This right is limited, however, if the decision is necessary for performance of any contract between you and us, is allowed by applicable law, or is based on your explicit consent.</li>
                                        </ul>
                                        <br />PLG does not discriminate against any user, in terms of price, service, or otherwise, for exercising these rights.  However, as noted above, the exercise of certain rights may prevent or limit your ability to continue to use certain features of the Website.
                                        <br />You, or your authorized agent, can exercise these rights by making a request to PLG by contacting us using any of the contact information located <a href="#bottom">below.</a> Upon receiving a verifiable request – verified by comparing information you provide in your request to the information we already have – by a user to exercise these rights, PLG intends to comply within a reasonable time, but in no event more than 30 days, unless we notify you otherwise in writing. If the request is uniquely burdensome or otherwise causes PLG to incur significant costs, we reserve the right to request reimbursement, to the extent allowed by applicable law or regulation.
                                        <br />To submit a request, please provide at least three of the following (a) first and last name (as you provided them to us), (b) the email address you provided to us (unless the request is made by email), (c) your company name, and (d) the last time you logged in to the Website.  Additionally, depending on the request, you may be asked to provide a signed declaration.  If we are unable to sufficiently verify your identity, we may use other methods of verification or may be required to deny the request.
                                        <br />If you have questions about exercising these rights, or need assistance, please contact us at the information located <a href="#bottom">below.</a>
                                        <br />If you wish to designate an agent to make a request on your behalf please contact us at the information located <a href="#bottom">below.</a>

                                        <br /><br /><b>Retention of Your Information<br /><br /></b>
                                        We retain your personally identifiable information only as long as necessary for the purpose for which it was collected or as required for necessary business purposes.  For example, Sign Up Information is retained so long as you have not terminated your account, and Contact Us Information is kept to respond to your question or request and delete the information once we have closed the issue (unless we need to keep the information for legal reasons).

                                        <br /><br /><b>Processors we use<br /><br /></b>
                                        As part of the services we provide, and only to the extent necessary, we may use certain third party processors to process some or all of your personally identifiable information. The processors we use for this purpose, and a short description of what they do, are:

                                        <ul style={{ marginLeft: 20 }}>
                                            <br /><li><b>Google Analytics:</b> We use Google Analytics to gather and report to us non-personal information about the use of our website.  Google Analytics may also collect other information that is not provided to us (such as IP address) and the collection of which we cannot control.  You can learn about Google Analytics’ privacy and security <a target='_blank' href='https://services.google.com/fh/files/misc/ga360_privacy-and-security_feature-brief_final.pdf'> here.</a></li>
                                            <br /><li><b>Amazon Web Services:</b>  Amazon Web Services (“AWS”) is our cloud host provider and may store certain personally identifiable information for us.  You can learn more about AWS’ privacy and security <a target='_blank' href='https://aws.amazon.com/compliance/data-privacy-faq/'> here.</a>  </li>
                                            <br /><li><b>Mailchimp:</b>  Mailchimp is a marketing automation platform and an email marketing service.  FST uses Mailchimp to send emails to its users who have not opted out of such emails.  You can learn about Mailchimp’s privacy <a target='_blank' href='https://mailchimp.com/legal/privacy/'> here.</a>, and Mailchimp’s security <a target='_blank' href='https://mailchimp.com/about/security/'> here.</a></li>
                                            <br /><li><b>Stripe:</b> Stripe is our vendor for accepting and processing payments.  You can learn about Stripe’s privacy and security <a target='_blank' href='https://stripe.com/privacy'> here.</a></li>

                                        </ul>

                                        <br /><br /><b>Cookies and Log Files<br /><br /></b>
                                        Our Website uses 1st party and 3rd party cookies.  Cookies are small strings of text that are sent by a website to your browser on your computer’s hard drive. Cookies can be used to recognize you when you return to a website, to maintain your Web session while you browse throughout the website, as well as help provide you with a better, more personalized experience.

                                        <br />The 1st party cookies on the Website are used solely for functionality or general Website analytics.

                                        <br />The 3rd Party cookies on the Website are utilized by Google Analytics to track how visitors get to our Website and how they use our Website.

                                        <br />Most web browsers automatically accept cookies, but it is possible to change your browser setup so that it does not accept cookies. To learn more about cookies, including how to see which cookies have been set and how to manage and delete them, please visit:  www.allaboutcookies.org.


                                        <br /><br /><b>Do Not Track<br /><br /></b>
                                        When you visit a website, your browser automatically shares certain information, such as your IP address and other device information. Some of this information may also be sent to third-party content providers (for example:  advertisers, website analytic companies, and others) that provide content on the website. Such sharing may allow the website and/or content providers to track you over time and across multiple websites. Today, many browsers offer Do Not Track plugins that allow you to express a preference that you not be tracked, and that tell websites that you visit, by sending an electronic beacon, this preference. At this time, our Website does not respond to Do Not Track beacons sent by browser plugins.  However, while PLG does collect information about the site you came from to get to the Website, we do not track where you go to next after visiting our Website.

                                        <br /><br /><b>Data Security<br /><br /></b>
                                        We are committed to keeping your information safe.  Therefore, PLG utilizes reasonable security procedures and practices appropriate to the nature of the personally identifiable information we collect and store.  Among other things, PLG utilizes techniques such as but not limited to; encryption for transmission /storage, access and identity controls and proper disposal of equipment and data, to manage information.  However, no protection method, hardware, or software is 100% perfect and information can be lost, even with appropriate protections.  You understand and agree that there is no such thing as perfect security and therefore PLG cannot, and is not, guaranteeing, ensuring or warranting that the information that you provide and we collect about you will never be compromised, and that you are accepting that risk.

                                        <br /><br /><b>Other sharing of your information<br /><br /></b>
                                        If we have personally identifiable information we may be required by law to respond to valid subpoenas, court orders, and other types of legal requests. We may also personally identifiable information, as required by law, with the relevant legal authorities. We may also share your information to defend ourselves against any lawsuit, claim or similar action, or to investigate potential violations of our policies or otherwise protect PLG or the Website.

                                        <br />If PLG or the Website is involved in a merger, acquisition, or sale of assets, we will give affected users whose contact information they have provided to us, and which information has not been deleted under this Policy, notice before their information becomes subject to a different privacy policy and the opportunity to delete such information prior to any other privacy policy going into effect.

                                        <br /><br /><b>Accuracy of Information<br /><br /></b>
                                        We endeavor to keep accurate and up-to-date records to help ensure the integrity of the services we offer. It is your responsibility, however, to ensure that your information is accurate, complete and up-to-date. If you would like to review the personally identifiable information we have about you, or if you know there is an inaccuracy, or need to make a change to any personally identifiable information you have provided to us, please update the information using any of the methods <a href="#bottom">below.</a>  Please include your name and the email address you previously provided to us when you contact us.
                                        Additionally, PLG does not warrant, guarantee, vouch for, or confirm the information contained in listings posted by others on the Website.  Your use or reliance on that information is at your own risk.
                                        <br /><br /><b>Opt Out<br /><br /></b>
                                        Even after you have consented to our collection and use of your personally identifiable information, you may opt-out of having your personally identifiable information data collected or used by us by contacting us via any of the methods <a href="#bottom">below.</a>
                                        Additionally, marketing and other emails you opt-in to receive from us emails have links enabling you to opt-out of receiving those emails in the future.
                                        <br /><br /><b>Children<br /><br /></b>
                                        In compliance with the Children’s Online Privacy Protection Act, 15 U.S.C. §§ 6501-06 and 16 C.F.R. Part 312, we and our Website do not knowingly collect information from children. By using our Website and/or contacting us, you represent that you are not under 18 years of age. If we discover that we have personally identifiable information of a person under 18 years of age, PLG will delete all information relating to that person and will cease any communications with them.

                                        <br /><br /><b>Location of Website and Data <br /><br /></b>
                                        This Website is operated in the United States. If you are located in the European Union or elsewhere outside of the United States, please be aware that any information you provide to us will be transferred to the United States. By using our Website and/or providing us with your information, you consent to this transfer.

                                        <br /><br /><b>Changes to this privacy policy <br /><br /></b>
                                        We may change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent, and will not reduce any rights guaranteed under applicable law. We always indicate the date the last changes were published at the top of this Privacy Policy.





                                        <div id='bottom'>
                                            <br /><br /><b>Contact information<br /><br /></b>
                                            If you have questions about, or issues with, this privacy policy, or wish to exercise any rights or opt-outs under this privacy policy, please contact us as follows:

                                            <br /><br />By email at:	privacy@industrialnavigator.com

                                            <br /><br />By mail at:
                                            <br />Professional Logistics Group, Inc.
                                            <br />Attn: Privacy manager
                                            <br />125 South Clark St., 17th Floor
                                            <br />Chicago, Illinois, 60603

                                        </div>


                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>






                <HomePageFooter />
                <Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Forgot Password
                                    </h2>
                                    <form style={{ width: '95%', marginLeft: '2%', marginRight: '2%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email.</div>
                                        <div id='fp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        {/* <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
                                        </div> */}
                                        <input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />

                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div>
                            <div style={{ padding: '3rem', backgroundColor: 'white' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
                                        <img src={logo} />
                                    </div>
                                    <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
                                        <div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Enter email address and password to login</div>
                                        <div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
                                        <div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='login_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setLoginEmail} value={this.state.login_email} />
                                        </div>
                                        {this.loginValidator.message('email', this.state.login_email, 'required')}
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword} value={this.state.login_password} />
                                        </div>
                                        {this.loginValidator.message('password', this.state.login_password, 'required')}
                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin}>Sign In</button>
                                        </div>
                                        <div className='col_full center padding-top-20'>
                                            <span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
                                        </div>
                                    </form>
                                    <div style={{ paddingTop: '1rem' }}>
                                        <small>
                                            <div className='text-center text-black font-weight-bold'>
                                                Don't have an account? <span className='cursor-pointer color-blue' onClick={this.showRegistrationModal}>Sign Up</span></div>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Login Successful
                            </h2>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showLogoutSuccessModal} onHide={this.handleLogoutSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Logout Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                You are logged out of the system
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLogoutSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName='custom-modal-step3' show={this.state.showRegistrationModal} onHide={this.handleRegistrationModal} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
                        <div>
                            <div>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '30px auto' }}>
                                        <img src={logo} />
                                    </div>
                                    {/* <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Welcome to Industrial Navigator!
									</h2> */}
                                    <MultiStepRegistration />
                                    {/* <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
										<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Please enter your details below to register for Industrial Navigator.</div>

										<div id='regi_errors' className='margin-top-15'></div>

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
										</div>
										{this.validator.message('first name', this.state.fname, 'required')}

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
										</div>
										{this.validator.message('last name', this.state.lname, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
										</div>
										{this.validator.message('company name', this.state.companyName, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail.bind(this)} value={this.state.email} />
										</div>
										{this.validator.message('email', this.state.email, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
										</div>
										{this.validator.message('password', this.state.password, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
										</div>
										{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
										<ReCAPTCHA
											ref={recaptchaRef}
											// size='invisible'
											className='margin-top-15'
											onChange={this.onRecaptchaChange.bind(this)}
											sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
										/>
										{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
										<div className='col_full center padding-top-20'>
											<button className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
										</div>
									</form> */}
                                    {/* <div style={{ paddingTop: '1rem' }}>
										<small>
											<div className='text-center text-black font-weight-bold'>
												Already have an account? <span className='cursor-pointer color-blue' onClick={this.checkIfLoginPage}>Sign In</span></div>
										</small>
									</div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Registration Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                Please verify your email address before adding a listing.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showOTPModal} onHide={this.handleOTPModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Verify OTP
                                    </h2>
                                    <form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='otp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
                                        <div id='otp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        <InputBoxComponent type='number' min='0' name='otp' onHandleInputChange={this.handleInputChange} />
                                        {this.OTPValidator.message('otp', this.state.otp, 'required')}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPVerification}>Verify</button>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default withRouter(PlansAndPricing);

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)