import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserProfile from './UserProfile';

interface PSideBarLTE {
}

class SSideBarLTE {
    constructor() {
    }
}

export default class SideBarLTE extends Component<PSideBarLTE, SSideBarLTE> {
    render() {
        return (
            <aside className='main-sidebar vertical-line'>
                <section className='sidebar'>
                    {/* <div className='user-panel margin-bottom-20'>
						<div className='pull-left name-initials'>
							{
								UserProfile.getFirstName().charAt(0).toUpperCase()
								+ UserProfile.getLastName().charAt(0).toUpperCase()
							}
						</div>
						<div className='pull-left info'>
							<p>
								{
									UserProfile.getFirstName().charAt(0).toUpperCase()
									+ UserProfile.getFirstName().slice(1)
								}&nbsp;
                                {
									UserProfile.getLastName().charAt(0).toUpperCase()
									+ UserProfile.getLastName().slice(1)
								}
							</p>
							<a href='#'><i className='fa fa-circle text-success'></i> Online</a>
						</div>
					</div> */}
                    {/* <form action='#' method='get' className='sidebar-form'>
                        <div className='input-group'>
                        <input type='text' name='q' className='form-control' placeholder='Search...' />
                        <span className='input-group-btn'>
                                <button type='submit' name='search' id='search-btn' className='btn btn-flat'><i className='fa fa-search'></i>
                                </button>
                            </span>
                        </div>
                    </form> */}
                    <ul className='sidebar-menu text-left' data-widget='tree'>
                        <li className='header'>MAIN NAVIGATION</li>
                        <li>
                            <Link to={{ pathname: '/my-profile' }}>
                                {/* <i className='fa fa-users'></i> */}
                                <span>My Profile</span>
                                <span className='pull-right-container'>
                                    {/* <span className='label label-primary pull-right'>4</span> */}
                                </span>
                            </Link>
                        </li>
                        <li>
                            <Link to={{ pathname: '/my-listing' }}>
                                {/* <i className='fa fa-users'></i> */}
                                <span>Place Listings</span>
                                <span className='pull-right-container'>
                                    {/* <span className='label label-primary pull-right'>4</span> */}
                                </span>
                            </Link>
                        </li>
                        {
                            (UserProfile.getUserType('user_type') === 'admin') &&
                            <li>
                                <Link to={{ pathname: '/memberships' }}>
                                    {/* <i className='fa fa-users'></i> */}
                                    <span>Memberships</span>
                                    <span className='pull-right-container'>
                                    </span>
                                </Link>
                            </li>
                        }
                        {
                            (UserProfile.getUserType('user_type') === 'admin') &&
                            <li>
                                <Link to={{ pathname: '/private-clients' }}>
                                    {/* <i className='fa fa-users'></i> */}
                                    <span>Private Clients</span>
                                    <span className='pull-right-container'>
                                    </span>
                                </Link>
                            </li>
                        }
                        {
                            (UserProfile.getUserType('user_type') === 'Private Client') &&
                            <li>
                                <Link to={{ pathname: '/adjust-browse-listings' }}>
                                    {/* <i className='fa fa-users'></i> */}
                                    <span>Customizations</span>
                                    <span className='pull-right-container'>
                                    </span>
                                </Link>
                            </li>
                        }
                        {
                            (UserProfile.getUserType('user_type') === 'admin') &&
                            <li>
                                <Link to={{ pathname: '/all-users' }}>
                                    {/* <i className='fa fa-users'></i> */}
                                    <span>All Users</span>
                                    <span className='pull-right-container'>
                                    </span>
                                </Link>
                            </li>
                        }
                        {
                            (UserProfile.getUserType('user_type') === 'admin') &&
                            <li>
                                <Link to={{ pathname: '/seomodule' }}>
                                    {/* <i className='fa fa-users'></i> */}
                                    <span>SEO Module</span>
                                    <span className='pull-right-container'>
                                    </span>
                                </Link>
                            </li>
                        }
                        <li>
                            <Link to={{ pathname: '/import-listings' }}>
                                {/* <i className='fa fa-users'></i> */}
                                <span>Import Listings</span>
                                <span className='pull-right-container'>
                                    {/* <span className='label label-primary pull-right'>4</span> */}
                                </span>
                            </Link>
                        </li>
                        {
                            (UserProfile.getUserType('user_type') === 'admin') &&
                            <li>
                                <Link to={{ pathname: '/notification' }}>
                                    {/* <i className='fa fa-users'></i> */}
                                    <span>Notifications</span>
                                    <span className='pull-right-container'>
                                    </span>
                                </Link>
                            </li>
                        }

                        {
                            (UserProfile.getUserType('user_type') === 'admin') &&
                            <li>
                                <Link to={{ pathname: '/site-customization' }}>
                                    {/* <i className='fa fa-users'></i> */}
                                    <span>Site Customization</span>
                                    <span className='pull-right-container'>
                                    </span>
                                </Link>
                            </li>
                        }

                        {/* <li>
                            <a href='#'>
                                <i className='fa fa-th'></i> <span>Widgets</span>
                                <span className='pull-right-container'>
                                <small className='label pull-right bg-green'>new</small>
                                </span>
                            </a>
                        </li>
                        <li className='treeview'>
                            <a href='#'>
                                <i className='fa fa-pie-chart'></i>
                                <span>Charts</span>
                                <span className='pull-right-container'>
                                <i className='fa fa-angle-left pull-right'></i>
                                </span>
                            </a>
                            <ul className='treeview-menu'>
                                <li><a href='#'><i className='fa fa-circle-o'></i> ChartJS</a></li>
                                <li><a href='#'><i className='fa fa-circle-o'></i> Morris</a></li>
                                <li><a href='#'><i className='fa fa-circle-o'></i> Flot</a></li>
                                <li><a href='#'><i className='fa fa-circle-o'></i> Inline charts</a></li>
                            </ul>
                        </li>
                        <li>
                            <ul className='treeview-menu'>
                                <li><a href='#'><i className='fa fa-circle-o'></i> Simple tables</a></li>
                                <li><a href='#'><i className='fa fa-circle-o'></i> Data tables</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href='#'>
                                <i className='fa fa-calendar'></i> <span>Calendar</span>
                                <span className='pull-right-container'>
                                <small className='label pull-right bg-red'>3</small>
                                <small className='label pull-right bg-blue'>17</small>
                                </span>
                            </a>
                        </li> */}
                        {/* <li>
                            <a href='#'>
                                <i className='fa fa-envelope'></i> <span>Mailbox</span>
                                <span className='pull-right-container'>
                                <small className='label pull-right bg-yellow'>12</small>
                                <small className='label pull-right bg-green'>16</small>
                                <small className='label pull-right bg-red'>5</small>
                                </span>
                            </a>
                        </li> */}
                    </ul>
                </section>
            </aside>
        )
    }
}