import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesShort extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesShort extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesShort = null;

	constructor(parent: NorthAmericanRailLinesShort, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesShort extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesShort, SNorthAmericanRailLinesShort> {

	static dataSource: string = 'NARL_SHORT-6zmxxz';
	static sourceLayer: string = 'NARL_SHORT';

	constructor(props: PNorthAmericanRailLinesShort) {
		super(props, NorthAmericanRailLinesShort.dataSource, NorthAmericanRailLinesShort.sourceLayer);
		this.state = new SNorthAmericanRailLinesShort(this, NorthAmericanRailLinesShort.dataSource, NorthAmericanRailLinesShort.sourceLayer);
	}

	getDefaultLineColor(): string {
		return this.getShortLineColor();
	}
}
