import React, { useState, useEffect } from "react";
import {
    CardElement,
    ElementsConsumer,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { Form } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import UserProfile from './UserProfile';
import axios from 'axios';
import { Elements } from "@stripe/react-stripe-js";
import '../css/priceplans.css';
import { useHistory } from "react-router-dom";
import { Cookies } from 'react-cookie';
import LinkedInTag from 'react-linkedin-insight';
const cookies = new Cookies();
const promise = loadStripe("pk_live_51JQZCTI9aRcuI4Jh6A4nCIJAOhTzdxjXDvRZ1HYx6GsqY1EazrOV5PV3oYL6qUQHbDMboqDjJgeXipyZbzpXhzId00IX8JmTkY");
const cardStyle = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "20px",
            "::placeholder": {
                color: "#32325d"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
};
const CheckoutStrip = ({ }) => {
    const elements = useElements();
    const stripe = useStripe();
    const history = useHistory();
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [fname, setfname] = useState('');
    const [lname, setlname] = useState('');
    const [companyName, setcompanyName] = useState('');
    const [password, setpassword] = useState('');
    const [coupancode, setcoupancode] = useState('');
    const [email, setemail] = useState('');
    const [confirm_password, setconfirm_password] = useState('');
    const [INNews, setINNews] = useState(true);
    const [PLGnews, setPLGnews] = useState(true);
    const [termsandcondition, settermsandcondition] = useState(false);
    const [isCoupancodeAvailable, setisCoupancodeAvailable] = useState(false);
    const [id, setID] = useState(0);
    const [planNameChosen, setplanNameChosen] = useState('No Plan');
    const [planPrice, setplanPrice] = useState('No Price');
    let customerId
    let clientSecret
    let priceId
    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    };
    const handleLoginSuccessResponse = async (response) => {

        console.log('order is updated with success');
        localStorage.removeItem('listingNames');

        //this.setState({ showPlaceSuccessModal: true });
        var placeOrderDiv = document.getElementById('place-order-div');
        var placeOrderBtn = document.getElementById('place-order-btn');
        //placeOrderDiv.removeChild(placeOrderBtn);
        console.log('inside handleLoginSuccessResponse', response);
        UserProfile.setEmail(response.data.email);
        UserProfile.setFirstName(response.data.first_name);
        UserProfile.setLastName(response.data.last_name);
        UserProfile.setUserType(response.data.user_type);
        UserProfile.setCompanyName(response.data.companyName);


        cookies.set('token', response.data.token);
        console.log(response.data);
        localStorage.removeItem('planNames');
        localStorage.removeItem('UseremailId');
        localStorage.removeItem('PaymentIntent');
        history.push('/upgrade-success')
        //this.setState({ showPlaceSuccessModal: true });
        //this.props.history.push('/');
    }
    const autoLogin = async () => {
        try {
            console.log('handle auto login');
            axios.post(process.env.REACT_APP_BASE_URL + '/api/auto-login-user',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    login_email: email,
                    login_password: password,
                }).then((response = {
                    status: Number
                    , data: {
                        bookmarks_str: String
                        , email: String
                        , first_name: String
                        , last_name: String
                        , max_no_entries: String
                        , options: String
                        , place_names_arr: String //Array<any>
                        , user_type: String
                        , token: String
                    }
                }) => {
                    // document.getElementById('login_error').style.display = 'none';
                    // document.getElementById('verify_email_error').style.display = 'none';
                    // console.log('autoLogin api response: ', response);

                    //this.handleClose();

                    if (response.status === 200) {
                        let bookmarksCount = 0;
                        console.log('response.data')
                        console.log(response.data)
                        if (response.data.bookmarks_str) {
                            const bookmarksArr = response.data.bookmarks_str.split(',');
                            bookmarksCount = bookmarksArr.length;
                        }
                        localStorage.setItem('bookmarks', response.data.bookmarks_str);
                        localStorage.setItem('listingNames', response.data.place_names_arr);
                        localStorage.setItem('maxNoEntries', response.data.max_no_entries);
                        localStorage.setItem('membershipOptions', response.data.options);
                        localStorage.removeItem('planchoseninfo');

                        handleLoginSuccessResponse(response);
                    } else {
                        this.setState({ isLoggedIn: false });
                        if (response.status === 204) {
                            // document.getElementById('verify_email_error').style.display = 'block';
                            // document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
                        } else {
                            // document.getElementById('login_error').style.display = 'block';
                            // document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                }).catch((error) => {
                    console.log('error auto login api: ', error);
                    // handle your error
                    document.getElementById('login_error').style.display = 'block';
                    document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                });
        } catch (err) {
            console.log('error in auto login');
            console.error(err);
        }
    }
    const autoLoginUser = async () => {
        console.log("inside autologin method");
        axios.post(process.env.REACT_APP_BASE_URL + '/api/create-order',
            {
                headers: { 'Content-Type': 'application/json' },
                usr_email: email,
                place_ids: localStorage.getItem('planNames') ? localStorage.getItem('planNames') : '',
                order_type: 'membership_plan',
                payment_type: "online",
                plan_id: id,
                customer_id: localStorage.getItem('CustomerId'),
                subscription_id: localStorage.getItem('SubscriptionId'),

            }).then(res => {
                console.log(res)
                console.log("after create order");
                if (res.status === 200) {
                    //alert("coupan order success");
                    autoLogin();

                }
                else {
                    alert(" failed")
                }
            });
    }
    const paymentConferm = async (event) => {
        const cardElement = elements.getElement(CardElement);
        clientSecret = event;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,

        });
        const paymentIntent = stripe.confirmCardPayment(
            clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),

            }


        }).then(statusofuser => {
            console.log("paymentIntent:", statusofuser.paymentIntent)
            try {
                if (statusofuser.paymentIntent.status == "succeeded") {
                    setError(null);
                    setProcessing(false);
                    setSucceeded(true);

                    console.log("status", statusofuser.paymentIntent.status)
                    if (statusofuser.paymentIntent.status == "succeeded") {
                        localStorage.setItem('PaymentIntent', JSON.stringify({ statusofuser }));
                        autoLoginUser();

                    }
                }

            } catch (error) {
                //{error.message}
                setError(<span style={{ color: "red" }}>Payment failed.</span>);
                setProcessing(false);
                //alert("Payent Failed")
            }


        })
    }

    const handlesubmit = async (event) => {
        if (!termsandcondition) {
            alert("plase accept terms and conditions")

        }
        else {

            // event.preventDefault();
            setProcessing(true);
            if (!stripe || !elements) {
                return;
            }
            // if (id == 3) {
            // 	priceId = 'price_1JjOYtI9aRcuI4Jh7V3hOhIL'
            // }
            // else if (id == 4) {
            // 	priceId = 'price_1JjXTOI9aRcuI4JhD5AzCG4z'
            // }
            // else if (id == 5) {
            // 	priceId = 'price_1JidsnI9aRcuI4JhRaVojddT'
            // }
            // else if (id == 6) {
            // 	priceId = 'price_1JidtLI9aRcuI4JhAgcKR8aI'
            // }
            if (id == 3) {
                priceId = 'price_1K2dBhI9aRcuI4Jh6Qr5fxnx'
            }
            else if (id == 4) {
                priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om'
            }
            else if (id == 5) {
                priceId = 'price_1JidsnI9aRcuI4JhRaVojddT'
            }
            else if (id == 6) {
                priceId = 'price_1JidtLI9aRcuI4JhAgcKR8aI'
            }
            //create customer on the stripe
            console.log("emaid and price id for the current user:", email, priceId);
            await axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
                email: email,
                //customerId:'',
            })
                .then(function (response) {
                    console.log(response);
                    customerId = response.data.customer.id;
                    localStorage.setItem('CustomerId', response.data.customer.id);
                    console.log("customer id:", customerId)
                    axios.post(process.env.REACT_APP_BASE_URL + '/api/create-subscription', {
                        priceId: priceId,
                        customerId: customerId,
                    })
                        .then(function (response) {
                            console.log(response);
                            //setclientSecret(response.data.clientSecret)
                            clientSecret = response.data.clientSecret
                            console.log("client secret", clientSecret)

                            localStorage.setItem('SubscriptionId', response.data.subscriptionId);
                            paymentConferm(clientSecret);
                        })
                        .catch(function (error) {
                            console.log(error);
                        });

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        if (localStorage.getItem('planchoseninfo') != 'undefined') {
            if (JSON.parse(localStorage.getItem('planchoseninfo')).id != 0) {
                setID(Number(JSON.parse(localStorage.getItem('planchoseninfo')).id))
                setplanNameChosen(JSON.parse(localStorage.getItem('planchoseninfo')).name)
                setplanPrice(JSON.parse(localStorage.getItem('planchoseninfo')).price)
            }
            else {
                // alert("Id Not Found")
                history.push('/')
            }
        }
        else {
            history.push('/')
        }
    }, [])

    const checkFormValidation = async (event) => {
        return new Promise((resolve, reject) => {
            //here our function should be implemented 
            event.preventDefault();
            if (id == 0) {
                history.push('/')
            }
            else {
                if (fname == '') {
                    alert("Enter first name")
                }
                else {
                    if (lname == '') {
                        alert("Enter last name")
                    }
                    else {
                        if (companyName == '') {
                            alert("Enter company name")
                        }
                        else {
                            if (email == '') {
                                alert("Enter email")
                            }
                            else {
                                if (password == '') {
                                    alert("Enter password")
                                }
                                else {
                                    if (confirm_password == '') {
                                        alert("Enter confirm password")
                                    }
                                    else {
                                        if (password != confirm_password) {
                                            alert("Password does not matches")
                                        }
                                        else {
                                            if (!termsandcondition) {
                                                alert("Accept terms and conditions")
                                            }
                                            else {
                                                if (isCoupancodeAvailable) {
                                                    if (coupancode == '') {
                                                        alert("Coupan code cannot be blank")
                                                    }
                                                    else {
                                                        registerUserToDB();
                                                    }
                                                }
                                                else {
                                                    registerUserToDB();
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }


        });
    }
    const registerUserToDB = async (event) => {
        return new Promise((resolve, reject) => {
            LinkedInTag.track('9791956');
            //here our function should be implemented  
            axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user-new-checkout',
                {
                    headers: { 'Content-Type': 'application/json' },
                    fname: fname,
                    lname: lname,
                    email: email,
                    companyName: companyName,
                    password: password,
                }).then(response => {
                    // handle your response;
                    if (response.status === 200) {
                        if (isCoupancodeAvailable) {
                            createCouponOrder()
                        } else {
                            handlesubmit()
                        }
                    }
                }).catch((error) => {
                    console.log('error')
                    console.log(error)
                    // handle your error
                    const regi_errors = document.getElementById('regi_errors');
                    regi_errors.innerHTML = '';
                    const error_html = document.createElement('div');
                    error_html.innerHTML = 'You already have an account, please login! Or Somethings wrong with details provided!';
                    // if (error.response.status === 600 && error.response.data.error === 'email exists') {
                    //     error_html.innerHTML = 'You already have an account, please login!';
                    // } else {
                    //     for (let i = 0; i < error.response.data.errors.length; i++) {
                    //         error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
                    //     }
                    // }
                    document.getElementById('regi_errors').appendChild(error_html);
                    document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
                    regi_errors.scrollIntoView({ behavior: 'smooth' })
                });

        });
    }


    const createCouponOrder = () => {

        try {
            //var email = email;
            console.log("email id in coupan order", email)
            const user_email = email;
            //const promocode_obj = { "SME": "promo_1Jk2QqI9aRcuI4JhhulVFQCc", "SDG": "promo_1Jk2RUI9aRcuI4JhrLpj3tIy", "EARLY": "promo_1Jk2SKI9aRcuI4JhLmnxomZt" }
            const promocode_obj = { SME: "promo_1Jk2QqI9aRcuI4JhhulVFQCc", SDG: "promo_1JkB4UI9aRcuI4JhfXn1sw0h", EARLY: "promo_1JkB3mI9aRcuI4Jhko6ZlaLQ" }
            const code = coupancode;
            //alert(promocode_obj[code])
            const promotion_code = promocode_obj[code];

            if (id == 3) {
                //priceId = 'price_1JjOYtI9aRcuI4Jh7V3hOhIL'
                //priceId for the one day subscription
                priceId = 'price_1K2dBhI9aRcuI4Jh6Qr5fxnx'

            }
            else if (id == 4) {
                priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om'
            }
            axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
                email: email,

                //customerId:'',
            }).then(function (response) {
                console.log("responce data email", response.data.customer.email);
                localStorage.setItem('CustomerId', response.data.customer.id);
                customerId = response.data.customer.id
                console.log("customer id:", customerId)
                console.log("price id", priceId)
                console.log("promo code", promotion_code)

                axios.post(process.env.REACT_APP_BASE_URL + '/api/create-coupan-order', {

                    priceId: priceId,
                    customerId: customerId,
                    promotion_code: promotion_code,
                })
                    .then(function (response) {
                        console.log('response succes', response);
                        if (response.status === 200) {

                            localStorage.setItem('SubscriptionId', response.data.subscription.id);
                            console.log("subscription id for coupan order:", response.data.subscription.id)
                            console.log("email id for updating order:", user_email)

                            axios.post(process.env.REACT_APP_BASE_URL + '/api/update-subscription-id-upgrade-coupan-order', {
                                usr_email: user_email,
                                subscription_id: response.data.subscription.id,
                                plan_id: id,
                                'source':'For Searcher/Lister',
                            }).then()

                            autoLogin();

                        }

                    })
                    .catch(function (error) {
                        console.log('response error', error);
                        alert("Invalid promo code used.")
                        console.log(error);
                    });

            });
        } catch (err) {
            console.log('error in order update');
            console.error(err);
        }
    }


    return (
        <>

            <section className='margin-bottom-0' >
                <div class="container" style={{ minHeight: 800, marginTop: 50 }}>
                    <div className='col-lg-12 '>
                        <div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
                        <div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>

                    </div>
                    <form id="payment-form" onSubmit={checkFormValidation}>
                        <div className='color-black font-size-18 margin-bottom-20' style={{ color: '#474747', fontSize: 25 }}>Please enter your details below to register for Industrial Navigator.</div>

                        <div id='regi_errors' className='margin-top-15'></div>
                        <div style={{ width: "100%", paddingLeft: -10 }} className='input-group margin-top-15 col-lg-12'>
                            <b style={{ marginLeft: -15, fontSize: 25 }} className="col-lg-4"> {planNameChosen}</b>
                        </div>
                        <div style={{ width: "100%", paddingLeft: -10 }} className='input-group margin-top-15 col-lg-12'>
                            <b style={{ marginLeft: -15, fontSize: 25 }} className="col-lg-4"> {planPrice}</b>
                        </div>


                        <div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
                            <input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={(event) => setfname(event.target.value)} value={fname} />
                        </div>

                        <div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
                            <input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={(event) => setlname(event.target.value)} value={lname} />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={(event) => setcompanyName(event.target.value)} value={companyName} />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={(event) => setemail(event.target.value)} value={email} />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={(event) => setpassword(event.target.value)} value={password} />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={(event) => setconfirm_password(event.target.value)} value={confirm_password} />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input style={{ marginLeft: 0 }} type="checkbox" checked={INNews} name='INNews' className='form-check-input' onChange={(e) => { setINNews(e.target.checked) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> Get the latest Industrial Navigator news delivered right to your inbox!</b><br /><br />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input style={{ marginLeft: 0 }} type="checkbox" checked={PLGnews} name='PLGnews' className='form-check-input' onChange={(e) => { setPLGnews(e.target.checked) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> I am also interested in PLG Consulting services news and information!</b><br /><br />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input style={{ marginLeft: 0 }} type="checkbox" checked={termsandcondition} name='termsandcondition' className='form-check-input' onChange={(e) => { settermsandcondition(e.target.checked) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> I agree to the  website <Link target="_blank" to={{ pathname: '/terms-and-conditions' }} >terms and conditions</Link>.</b><br /><br />
                        </div>
                        <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                            <input style={{ marginLeft: 0 }} type="checkbox" checked={isCoupancodeAvailable} name='isCoupancodeAvailable' className='form-check-input' onChange={(e) => { setisCoupancodeAvailable(e.target.checked) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> I have a coupon code.</b><br /><br />
                        </div>
                        {
                            isCoupancodeAvailable ?
                                <>

                                    <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
                                        <input type='text' name='coupancode' className='form-control input-lg not-dark required email' placeholder='Enter Coupon Code ...' onChange={(event) => setcoupancode(event.target.value)} value={coupancode} />
                                    </div>
                                    <div className='col_full center padding-top-20'>
                                        <button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={checkFormValidation}>Register</button>
                                    </div>
                                </>
                                :
                                <>
                                    <CardElement options={cardStyle} onChange={handleChange} /><br />
                                    <button
                                        disabled={processing || disabled || succeeded}
                                        id="submit"
                                    >
                                        <span id="button-text">
                                            {processing ? (
                                                <div className="spinner" id="spinner"></div>
                                            ) : (
                                                "Pay now"
                                            )}
                                        </span>
                                    </button>

                                    {/* Show any error that happens when processing the payment */}
                                    {error && (
                                        <div className="card-error" role="alert">
                                            {error}
                                        </div>
                                    )}
                                    {/* Show a success message upon completion */}
                                    <p className={succeeded ? "result-message" : "result-message hidden"}>
                                        <span style={{ color: "green" }}>Payment Successful</span>
                                    </p>
                                </>
                        }

                    </form>
                </div>
            </section>
        </>
    );
};

export { CheckoutStrip };
