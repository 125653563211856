import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection';
import Search from "../../public/assets/revslider/assets/Search.svg";
const Index = props => {
    
    return (
        <>
            <section className="section-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12">
                            <img style={{maxWidht:570,maxHeight:331,marginTop:-70}} src={Search} className="img-fluid reduce-img " alt="qloud" />
                        </div>
                        <div style={{marginTop:-20}} className="col-lg-7 col-sm-12 mb-lg-0 mb-5">
                            <TitleSection 
                                className='iq-title-box-2 text-left iq-title-box-1'
                                title='Industrial Site Search Made Easy'
                                description='It is easy to find the industrial sites, terminals, transload facilities, warehouses, distribution centers needed to build and strengthen your supply chain. Advanced filtering allows you to quickly find the sites and logistics assets that have the capabilities and features you need. Map layers allow you to see how the different links in your supply chain relate to crucial transportation resources, such as rail lines, pipelines, and highways.'
                                titleIcon='' />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Index