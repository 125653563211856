import React, { Component, lazy, Suspense } from 'react';
import Listing from './components/Listing';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import PlansAndPricing from './components/PlansAndPricing';
import Details from './components/Details';
import CreatePlace from './components/CreatePlace';
import MyProfile from './components/MyProfile';
import MyListing from './components/MyListing';
import Registration from './components/Registration';
import Checkout from './components/Checkout';
import Payment from './components/Payment';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import UserProfile from './components/UserProfile';
import withAuth from './components/withAuth';
import VerificationSuccess from './components/VerificationSuccess';
import RecoverPassword from './components/RecoverPassword';
import EditPlace from './components/EditPlace';
import Bookmarks from './components/Bookmarks';
import Places from './components/Places';
import Membership from './components/Membership/index';
import EditMembership from './components/Membership/EditMembership';
import CreateMembership from './components/Membership/Create';
import PrivateLabel from './components/PrivateLabel/index';
import Createprivateclient from './components/PrivateLabel/Create';
import CheckoutSubscription from './components/CheckoutSubscription';
import UpgradePlan from './components/UpgradePlan';
import UpgradePlanCheckout from './components/checkoutUpgrade';
import UpgradeSuccess from './components/UpgradeSuccess';
import DowngradePlan from './components/DowngradePlan';
import CheckoutDowngrade from './components/CheckoutDowngrade';
import firebase from './components/FireBase';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import AdjustBrowseOptions from './components/PrivateLabel/AdjustBrowseOptions';
import HomePageNew from './components/HomePage/HomePage';
import AllUsers from './components/AllUsers';
import NewListing from './components/NewListing';
import ExternalListingsUsers from './components/ListingsForExternalClients';
import DCMA from './components/DCMA';
import Lister from './components/ListerHome';
import Searcher from './components/SearcherHome';
import PricateClientInfo from './components/PrivateClientHome'
import EditPrivateClient from './components/PrivateLabel/EditPrivateClient';
import SeoModule from './components/SEOModule/index';
import CreatePageSEO from './components/SEOModule/Create';
import EditPageSEO from './components/SEOModule/EditPageDetails';
import ImportListings from './components/ImportListings';
import NewSignUpFlow from './components/CheckoutNew';
import RedirectSearch from './components/RedirectSearch';
import ReactGA from 'react-ga';
import RouteChangeTracker from './components/GoogleAnalytics';
import Notifications from './components/Notifications';
import SiteCustomization from './components/SiteCustomization';
import FilteredMapView from './components/FilteredMapView';
import NorthAmericanIndustrialSites from './components/NorthAmericanIndustrialSites';
import NorthAmericanTerminalAndTransloads from './components/NorthAmericanTerminalAndTransloads';
import NorthAmericanWarehouses from './components/NorthAmericanWarehouses';




const TRACKING_ID = "G-WNXN3ME3S8"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
console.log('firebase object', firebase)
console.log('google analytics', RouteChangeTracker)

class App extends Component {

	render() {
		return (
			<Router>


				{/* <Route path={'/home'} exact component={HomePageNew} /> */}
				{/* <Route path={'/browse-listings'} exact component={withAuth(Listing)} /> */}
				{/* <Route path={'/details'} render={(props) => <Details {...props} key={Math.random()}/>} />         */}
				<Route path={'/'} exact component={HomePage} />
				<Route exact path='/lister' component={Lister} />
				<Route exact path='/searcher' component={Searcher} />
				<Route exact path='/private-clients-info' component={PricateClientInfo} />
				<Route path={'/create-listings'} component={CreatePlace} />
				<Route path={'/my-listing'} component={withAuth(MyListing)} />
				<Route path={'/my-profile'} component={withAuth(MyProfile)} />
				<Route path={'/register'} render={() => (
					UserProfile.getEmail() ? (
						<Redirect to='/' />
					) : (
						<Registration />
					)
				)} />
				<Route path={'/checkout'} render={() => (
					UserProfile.getEmail() ? (
						<Checkout />
					) : (
						<Checkout />
					)
				)} />
				<Route path={'/checkout-subscription'} render={() => (
					UserProfile.getEmail() ? (
						<CheckoutSubscription />
					) : (
						<CheckoutSubscription />
					)
				)} />
				<Route path={'/make-payment'} render={() => (
					UserProfile.getEmail() ? (
						<Payment />
					) : (
						<Payment />
					)
				)} />
				<Route path={'/success'} component={VerificationSuccess} />
				<Route path={'/search-listings'} component={RedirectSearch} />

				<Route path={'/reset-password'} component={RecoverPassword} />
				<Route path={'/edit-place-:id'} component={EditPlace} />
				<Route path={'/details-:id'} component={Details} />
				<Route path={'/bookmarks'} component={Bookmarks} />
				<Route exact path='/places-:id' component={Places} />
				<Route exact path='/about-us' component={AboutUs} />
				<Route exact path='/upgrade-plan' component={withAuth(UpgradePlan)} />
				<Route exact path='/downgrade-plan' component={withAuth(DowngradePlan)} />
				<Route exact path='/checkout-upgrade' component={withAuth(UpgradePlanCheckout)} />
				<Route exact path='/checkout-downgrade' component={withAuth(CheckoutDowngrade)} />
				<Route exact path='/upgrade-success' component={withAuth(UpgradeSuccess)} />
				<Route exact path='/plans-and-pricing' component={PlansAndPricing} />
				<Route exact path='/Browse-for-private-:id' component={Listing} />
				<Route exact path='/terms-and-conditions' component={TermsAndConditions} />
				<Route exact path='/privacy-policy' component={PrivacyPolicy} />

				{/* Admin specific routes starts */}
				<Route path={'/memberships'} component={Membership} />
				<Route path={'/all-users'} component={AllUsers} />
				<Route path={'/edit-membership-:id'} component={EditMembership} />
				<Route exact path={'/create-membership'} component={CreateMembership} />

				<Route path={'/Private-clients'} component={PrivateLabel} />
				<Route exact path={'/create-private-client'} component={Createprivateclient} />
				<Route exact path={'/adjust-browse-listings'} component={AdjustBrowseOptions} />
				<Route exact path={'/edit-private-client-details-:id'} component={EditPrivateClient} />
				{/* <Route exact path='/listings' component={NewListing} /> */}
				<Route path={'/view-listings'} exact component={NewListing} />
				<Route path={'/view-listings-:id'} exact component={ExternalListingsUsers} />
				<Route exact path='/dcma' component={DCMA} />

				<Route exact path='/seomodule' component={SeoModule} />
				<Route exact path='/configure-page-seo' component={CreatePageSEO} />
				<Route exact path='/edit-page-seo-:id' component={EditPageSEO} />
				<Route exact path='/import-listings' component={ImportListings} />
				<Route exact path='/register-and-checkout' component={NewSignUpFlow} />
				<Route exact path='/notification' component={Notifications} />
				<Route exact path='/site-customization' component={SiteCustomization} />
				<Route exact path='/north-american-:id' component={FilteredMapView} />
				<Route exact path='/search-north-american-industrial-sites' component={NorthAmericanIndustrialSites} />
				<Route exact path='/search-north-american-terminal-and-transloads-sites' component={NorthAmericanTerminalAndTransloads} />
				<Route exact path='/search-north-american-warehouse-and-distributions-sites' component={NorthAmericanWarehouses} />
				{/* Admin routes ends PrivateLabel */}

			</Router>
		);
	}
}

export default App;