import React, { Component } from 'react';
import '../custom.css';
import { Alert } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import { Cookies } from 'react-cookie';
import UserProfile from './UserProfile';

const cookies = new Cookies();

interface PVerificationSuccess {
	history: Array<string>;
}

class SVerificationSuccess {
	constructor() {
	}
}

class VerificationSuccess extends Component<PVerificationSuccess, SVerificationSuccess> {

	child: Header = null;

	constructor(props: PVerificationSuccess) {
		super(props);
	}

	componentWillMount() {
		console.log(cookies.get('email'))
		const email = cookies.get('email');
		const first_name = cookies.get('first_name');
		const last_name = cookies.get('last_name');
		if ((email !== '' || email !== undefined)
			&& (first_name !== '' || first_name !== undefined)
			&& (last_name !== '' || last_name !== undefined)
		) {
			UserProfile.setEmail(email);
			UserProfile.setFirstName(first_name);
			UserProfile.setLastName(last_name);
			const login_token = cookies.get('token');
			cookies.remove('token');
			cookies.remove('email');
			cookies.remove('first_name');
			cookies.remove('last_name');
			cookies.set('token', login_token);
			this.props.history.push('/create');
		} else {
			this.props.history.push('/');
		}
	}

	render() {
		return (
			<div className='padding-bottom-50'>
				<Header data={this.props} onRef={ref => (this.child = ref)} />
				<div id='page-content' style={{ paddingBottom: '50px', minHeight: 'auto' }}>
					<div className='row'>
						<div className='col-lg-12 col-md-12 col-sm-12'>
							<Alert variant='success' style={{ opacity: 1, marginTop: 20 }}>
								<h2>Email Verified!</h2>
								<p>
									Your email verification is successful.
								</p>
							</Alert>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

export default VerificationSuccess;