import siteIcon from '../help-icon.png';
// import siteIcon from '../site-marker.png';

export const ListingHelpData = {
	title: `<h1 style="font-size: 22px;">Welcome to Industrial Navigator Search Functionality</h1>`,
	// body: `
	// 	<p style="font-size: 14px;">Please start off by selecting a <b>property/facility type</b> to get the full menu of
	// 	possible filters for each type of listing. You may select multiple listing types.
	// 	<p style="font-size: 14px;">If you need more information on what the different fields mean, please click on the information icon`
	// 	+
	// 		`<img src='` + siteIcon + `' width='30' alt='help' />`
	// 	+
	// 	`to see the definitions for fields that require explanation.</p>
	// 	<p style="font-size: 14px;">You may also select locations such as one or many states, or an address with radius. You may type in
	// 	the radius for as large or small a distance desired.
	// 	</p>
	// 	<p style="font-size: 14px;">You may select keywords as well, using a comma to include multiple criteria, or a + to include criteria only
	// 	pertinent to what should be found in the listing. So for example, using rail,cargo will find listings that have EITHER
	// 	rail or cargo. Using rail+cargo will find listings that have both keywords included.
	// 	</p>
	// 	<p style="font-size: 14px;">Have questions? We are here to help - please notify us here so we can get you back on track!</p>
	// `
	body: `
		<p style="font-size: 14px;">There are four ways to search for sites and facilities, which may be used separately or in combination:
		<ul style="list-style-type: disc;">
		<li ><b>BY CATEGORY:</b> Select one or more property/facility type: (Industrial Sites, Ports, Terminals and Transloads or Warehouse DCs. You can refine your results further by filtering for additional criteria that is specific to each property/facility type, which will appear after you choose one or more facility type. Look for the i icon for information about search criteria that may require additional explanation. </li><br />
		<li><b>BY LOCATION:</b> Select one or more states, or type in the name of a city, address or location and set the desired radius search by location. </li><br />
		<li><b>BY KEYWORD:</b> Search for text within the listing. Use a comma (,)  between keywords to search for multiple (either/ or) keywords within a listing or use a plus sign (+) to find listings that include all keywords. </li><br />
		<li><b>BY MAP:</b> Find the area where you are interested in searching for listings. You can toggle on or off map layers, such as railroad networks and major ports, to help you find the sites that have the right transportation assets nearby.</li>
		</ul>
		
		<p style="font-size: 14px;">Depending on your plan level, you may not have access to all search capabilities or map layers.</p>
		<p style="font-size: 14px;">Have questions? Need help? Contact us at <span style="color:#0094e9"> info@industrialnavigator.com</span> and a representative will respond.</p>
	`
}