class StringUtils {
	isEmpty = (str: string): boolean => {
		return (str === '' || str === null || str === undefined);
	}

	isNotEmpty = (str: string): boolean => {
		return !this.isEmpty(str);
	}
}

const instance: StringUtils = new StringUtils();
export default instance;
