import { jsPDF } from 'jspdf';
import * as font from '../../assets/fonts/Gotham-Pro-Black-Bold';

import PdfDetailsDocGen from './PdfDetailsDocGen';
import axios from 'axios';

export default class PdfBookmarksDocGen extends PdfDetailsDocGen {

	printTitlePage = () => {
		const { pageHeight, pageWidth, doc } = this;
		const vCenter: number = pageHeight / 2 - 30;
		const hCenter: number = pageWidth / 2 - 40;
		//this.addImage('https://images.unsplash.com/photo-1466803136990-7c174b34ff32?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=277', 'JPEG', hCenter -5, vCenter -25, 80, 30);
		//this.addImage('../../IN-logo.png', 'JPEG', hCenter, vCenter, 80, 15);
		this.addImage('https://skywalker-production.s3.amazonaws.com/Logos/privateclientmailinatorcom-9279810222.png', 'JPEG', hCenter - 10, vCenter + 5, 90, 15);
		this.addFileToVFS('Gotham Pro Black-bold.ttf', font.font);
		this.addFont('Gotham Pro Black-bold.ttf', 'Gotham Pro Black', 'bold');
		this.setFont('Gotham Pro Black', 'bold');
		this.setTextColor(7, 52, 90);
		this.setFontSize(26);
		this.text('Favorite List', hCenter + 8, vCenter + 35);
		// this.text('Industrial Navigator', hCenter -10, vCenter + 20);
		this.addPage();
		this.setFont('Arial', 'normal');
		this.setTextColor(0, 0, 0);
	}

	createBookmarksPDF = (arrGenPdf: Array<any>, features: Array<any>) => {
		if (arrGenPdf.length <= 0) {
			alert('Select places to create PDF');
			return;
		}
		this.initPdfDocument();
		this.printTitlePage();
		let y: number = 20;
		const promise = new Promise((resolve, reject) => {
			for (let index = 0; index < arrGenPdf.length; index++) {
				for (let fIndex = 0; fIndex < features.length; fIndex++) {
	
					if (features[fIndex].rec_id !== arrGenPdf[index]) {
						continue;
					}
					axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
						{
							headers: {
								'Content-Type': 'application/json',
								// 'x-access-token': cookies.get('token')
							},
							// withCredentials: true,
							imgurl: features[fIndex].properties.image[0].url,
						}).then(response => {
							if (response.status === 200) {
								var final_base_64_url = 'data:image/jpeg;base64,' + response.data.base64;
								features[fIndex].properties.image[0].url = final_base_64_url;
								axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
									{
										headers: {
											'Content-Type': 'application/json',
											// 'x-access-token': cookies.get('token')
										},
										// withCredentials: true,
										imgurl: features[fIndex].properties.listingLogo,
									}).then(response => {
										if (response.status === 200) {
											var final_base_64_url_logo = 'data:image/jpeg;base64,' + response.data.base64;
											features[fIndex].properties.listingLogo = final_base_64_url_logo;
											y = this.printPdfFields(features[fIndex].properties, y);
											if (index !== arrGenPdf.length - 1) {
												this.addPage();
												y = 20;
											}
											if (index == arrGenPdf.length -1 ) {
												resolve(index);
											}
										}
									}).catch(function (error) {
										console.log(error)
										y = this.printPdfFields(features[fIndex].properties, y);
										if (index !== arrGenPdf.length - 1) {
											this.addPage();
											y = 20;
										}
										alert('Image Correpted...Something went wrong')
										if (index == arrGenPdf.length -1 ) {
											resolve(index);
										}
									});
							}
						}).catch(function (error) {
							console.log(error)
							y = this.printPdfFields(features[fIndex].properties, y);
							if (index !== arrGenPdf.length - 1) {
								this.addPage();
								y = 20;
							}
							if (index == arrGenPdf.length -1 ) {
								resolve(index);
							}
							alert('Image Correpted...Something went wrong')
						});
					// y = this.printPdfFields(features[fIndex].properties, y);
				}
				
			}
			


		});

		promise.then((d) => {
			setTimeout(() => {
				this.doc.save('IndustrialNavigator.pdf');
			}, 3000);
			// this.doc.save('IndustrialNavigator.pdf');
		});
		
		
	}

}
