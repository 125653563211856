import siteIcon from '../help-icon.png';
// import siteIcon from '../site-marker.png';

export const ListingsHelpDataForRealEstateModal = {
	title: `<h1 style="font-size: 25px;">YOUR ONE-STOP MARKETING TOOL FOR INDUSTRIAL SITES!</h1>`,
	body: `
	<div class="row">
		<div class="col-lg-6 col-md-6">
			<div class="iq-icon-box">
				<div class="icon-box-img">
				<iframe class="video" width="250" height="220" src="https://www.youtube.com/embed/_WEVvJkycwo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<div class="icon-box-content">
				<p style="font-size:18px"><u><b>Search Benefits:</b></u></p>
					<ul  style="list-style-type: disc;">
						<li> Your sites are visible to active prospects who understand their value</li>
						<li> Your listing contains relevant criteria against only other industrial sites</li>
						<li> Prospects can more easily find your sites and connect with you for listing details</li>					
					</ul>
				</div> 
			</div>
		</div>
		<div class="col-lg-6 col-md-6">
			<div class="iq-icon-box">
				<div class="icon-box-img">
				<iframe class="video" width="250" height="220" src="https://www.youtube.com/embed/wBOq4vdUWOc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
				<div class="icon-box-content">
				<p style="font-size:18px"><u><b>Listing Benefits:</b></u></p>
					<ul  style="list-style-type: disc;">
						<li> List up to 100 sites</li>
						<li> Get premium placement within search results</li>
						<li> Promote your sites via email and social media</li>	
						<li> Track your listing views</li>				
					</ul>
				</div>
			</div>
		</div>
	
	</div><br />
		<p style="font-size: 14px;">Have questions or need help? Contact us at<span style="color:#0094e9"> info@industrialnavigator.com</span>.</p>
		
	`
}