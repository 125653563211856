import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePage/qloud/footerComponents';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Helmet } from "react-helmet";
import axios from 'axios';
import '../css/priceplans.css';
import SimpleReactValidator from 'simple-react-validator';


const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: HomePageHeader) => void;
    data?: any;
}


class NorthAmericanSites extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    helpers: any = null;

    constructor(props: PHeader) {
        super(props);
        this.state = {
            email: ''
        }
    }

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }




    child: HomePageHeader = null;



    render() {

        return (
            <>
                <Helmet>
                    <title>North American Ports,Terminals And Transloads</title>
                    <meta property="og:url" content={window.location.href} />
                    <meta name='description' content="North American Port,Terminals And Transloads" />
                    <meta property='og:title' content="North American Port,Terminals And Transloads" />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>

                <HomePageHeader data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 1200, fontFamily: "Roboto, sans-serif" }}>
                    <div className="justify-content-center">
                        <div className='col-lg-2 col-md-12 col-sm-12 padding-0 ' ></div>
                        <div className='col-lg-8 col-md-12 col-sm-12 padding-0 ' >

                            <div className="container-fluid" style={{ marginTop: 100, fontFamily: "Roboto, sans-serif" }} >
                                <h1 className="content_area" style={{ fontWeight: 600, fontFamily: 'Roboto, sans-serif', marginLeft: 15 }}>North American Ports,Terminals And Transloads</h1>
                                <div className='container-fluid' >
                                    <p className="para_content-new">
                                        <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>

                                            <li>
                                                <h2>Transloads & Terminals by Geography</h2>
                                                <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                                    <li><h3>Country</h3>
                                                        <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-in-canada' }}><span>Transloads & Terminals - Canada</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-in-mexico' }}><span>Transloads & Terminals - Mexico</span></Link></li>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-in-us' }}><span>Transloads & Terminals - US</span></Link></li>
                                                        </ul>
                                                    </li>

                                                    <li><h3 style={{ paddingTop: 25 }}>US States</h3>
                                                        <ul className='row' style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                            <li>
                                                                <div className="col-md-4">
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-alabama' }}><span>Alabama</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-arizona' }}><span>Arizona</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-arkansas' }}><span>Arkansas</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-california' }}><span>California</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-colorado' }}><span>Colorado</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-connecticut' }}><span>Connecticut</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-delaware' }}><span>Delaware</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-florida' }}><span>Florida</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-georgia' }}><span>Georgia</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-idaho' }}><span>Idaho</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-illinois' }}><span>Illinois</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-indiana' }}><span>Indiana</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-iowa' }}><span>Iowa</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-kansas' }}><span>Kansas</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-kentucky' }}><span>Kentucky</span></Link></div></li>

                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="col-md-4">
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-louisiana' }}><span>Louisiana</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-maine' }}><span>Maine</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-maryland' }}><span>Maryland</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-massachusetts' }}><span>Massachusetts</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-michigan' }}><span>Michigan</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-minnesota' }}><span>Minnesota</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-mississippi' }}><span>Mississippi</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-missouri' }}><span>Missouri</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-montana' }}><span>Montana</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-nebraska' }}><span>Nebraska</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-nevada' }}><span>Nevada</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-new-hampshire' }}><span>New Hampshire</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-new-jersey' }}><span>New Jersey</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-new-mexico' }}><span>New Mexico</span></Link></div></li>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="col-md-4">
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-new-york' }}><span>New York</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-north-carolina' }}><span>North Carolina</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-ohio' }}><span>Ohio</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-oklahoma' }}><span>Oklahoma</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-oregon' }}><span>Oregon</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-pennsylvania' }}><span>Pennsylvania</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-south-carolina' }}><span>South Carolina</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-south-dakota' }}><span>South Dakota</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-tennessee' }}><span>Tennessee</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-texas' }}><span>Texas</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-utah' }}><span>Utah</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-virginia' }}><span>Virginia</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-west-virginia' }}><span>West Virginia</span></Link></div></li>
                                                                    <li><div className="col-md-12"><Link to={{ pathname: '/north-american-transloads-and-terminals-wisconsin' }}><span>Wisconsin</span></Link></div></li>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <h3 style={{ paddingTop: 25 }}>Transloads in Rail Gateways</h3>
                                                        <ul>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-chicago' }}><span>Chicago</span></Link></li>
                                                            <li></li>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-kansas-city' }}><span>Kansas City</span></Link></li>
                                                            <li></li>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-st-louis' }}><span>St. Louis</span></Link></li>
                                                            <li></li>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-memphis' }}><span>Memphis</span></Link></li>
                                                            <li></li>
                                                            <li><Link to={{ pathname: '/north-american-transloads-and-terminals-new-orleans' }}><span>New Orleans</span></Link></li>
                                                            <li></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h2 style={{ paddingTop: 25 }}>Transloads By Railway Network</h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>

                                                    <li><Link to={{ pathname: '/north-american-UP-transloads' }}><span> UP Transloads</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-BNSF-transloads' }}><span> BNSF Transloads</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-CP-transloads' }}><span> CP Transloads</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-KCS-transloads' }}><span> KCS Transloads</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-CN-transloads' }}><span> CN Transloads</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-NS-transloads' }}><span> NS Transloads</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-CSX-transloads' }}><span> CSX Transloads</span></Link></li>

                                                </ul>

                                            </li>



                                            <li>
                                                <h2 style={{ paddingTop: 25 }}>Ports and Terminals by Capability</h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                    <li><Link to={{ pathname: '/north-american-port-terminal-and-transloads-capable-for-tank' }}><span>Tank Terminals </span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-port-terminal-and-transloads-capable-for-ports-and-marine' }}><span>Ports and Marine Terminals</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-port-terminal-and-transloads-capable-for-rail' }}><span>Rail Terminals </span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-port-terminal-and-transloads-capable-for-unit-train-rail' }}><span>Unit Train Rail Terminals </span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-port-terminal-and-transloads-capable-for-warehousing' }}><span> Transloads with Warehousing</span></Link></li>
                                                    {/* <li><Link to={{ pathname: '/north-american-port-terminal-and-transloads-capable-for-pipeline' }}><span>Transload to/from Pipeline ** </span></Link></li> */}

                                                </ul>

                                            </li>



                                            <li>
                                                <h2 style={{ paddingTop: 25 }}>Transloads by General Commodity Format</h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-general-commodity-like-dry-bulk' }}><span>Dry Bulk</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-general-commodity-like-liquid-bulk' }}><span>Liquid Bulk</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-general-commodity-like-breakbulk' }}><span>Breakbulk</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-general-commodity-like-project-cargo' }}><span>Project Cargo</span></Link></li>
                                                </ul>

                                            </li>


                                            <li>
                                                <h2 style={{ paddingTop: 25 }}>Transloads by Specific Commodity</h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-aggregates' }}><span>Aggregates</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-chemicals' }}><span>Chemicals</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-coal' }}><span>Coal</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-crude-oil' }}><span>Crude Oil</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-dry-bulk' }}><span>Dry Bulk</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-fertilizer' }}><span>Fertilizer</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-forest-products' }}><span>Forest products</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-grains' }}><span>Grains</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-hazmat' }}><span>Hazmat</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-lumber' }}><span>Lumber</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-metals' }}><span>Metals</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-petcoke' }}><span>Petcoke</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-terminal-and-transloads-useful-for-specific-commodity-like-plastics' }}><span>Plastics</span></Link></li>
                                                </ul>

                                            </li>
                                        </ul>


                                    </p><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <HomePageFooter />




            </>
        )
    }
}

export default withRouter(NorthAmericanSites);

