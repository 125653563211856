import React from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ListingHelpData } from '../constant/ListingHelpData';
import { ListingHelpDataForSiteSelectionModal } from '../constant/ListingHelpDataForSiteSelectionModal';
import { ListingsHelpDataForRealEstateModal } from '../constant/ListingsHelpDataForRealEstateModal';
import InputCheckboxComponent from './InputCheckboxComponent';
import TakeTour from './TakeTour';

const ListingHelpModal = ({ handleClose, show, token }) => {

    const [showListingHelpModal, setListingHelpModal] = useState<any>(false);
    if (token == 'site-selectors') {
        return (
            <Modal dialogClassName='custom-modal-help-data' show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px', float: 'left' }}>
                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        {ListingHelpDataForSiteSelectionModal.title.indexOf('</') !== -1
                            ? (
                                <div dangerouslySetInnerHTML={{ __html: ListingHelpDataForSiteSelectionModal.title.replace(/(<? *script)/gi, 'illegalscript') }} >
                                </div>
                            )
                            : ListingHelpDataForSiteSelectionModal.title
                        }
                    </div>
                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>

                        {ListingHelpDataForSiteSelectionModal.body.indexOf('</') !== -1
                            ? (
                                <div dangerouslySetInnerHTML={{ __html: ListingHelpDataForSiteSelectionModal.body.replace(/(<? *script)/gi, 'illegalscript') }} >
                                </div>
                            )
                            : ListingHelpDataForSiteSelectionModal.body
                        }

                    </div>

                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <InputCheckboxComponent
                            name='classOneMultiservedCheckbox'
                            checked={showListingHelpModal}
                            onHandleCheckboxChange={(name: string, value: boolean) => {
                                console.log(value)
                                setListingHelpModal(value)
                                localStorage.setItem('showListingHelpModal', showListingHelpModal);
                            }}
                            label='Do not show this again!'
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                    <button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        )

    } else if (token == 'real-estate-bd') {
        return (
            <Modal dialogClassName='custom-modal-help-data' show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px', float: 'left' }}>
                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        {ListingsHelpDataForRealEstateModal.title.indexOf('</') !== -1
                            ? (
                                <div dangerouslySetInnerHTML={{ __html: ListingsHelpDataForRealEstateModal.title.replace(/(<? *script)/gi, 'illegalscript') }} >
                                </div>
                            )
                            : ListingsHelpDataForRealEstateModal.title
                        }
                    </div>
                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>

                        {ListingsHelpDataForRealEstateModal.body.indexOf('</') !== -1
                            ? (
                                <div dangerouslySetInnerHTML={{ __html: ListingsHelpDataForRealEstateModal.body.replace(/(<? *script)/gi, 'illegalscript') }} >
                                </div>
                            )
                            : ListingsHelpDataForRealEstateModal.body
                        }

                    </div>

                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        <InputCheckboxComponent
                            name='classOneMultiservedCheckbox'
                            checked={showListingHelpModal}
                            onHandleCheckboxChange={(name: string, value: boolean) => {
                                console.log(value)
                                setListingHelpModal(value)
                                localStorage.setItem('showListingHelpModal', showListingHelpModal);
                            }}
                            label='Do not show this again!'
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                    <button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        )
    }
    else {
        return (
            <Modal show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px', float: 'left' }}>
                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                        {ListingHelpData.title.indexOf('</') !== -1
                            ? (
                                <div dangerouslySetInnerHTML={{ __html: ListingHelpData.title.replace(/(<? *script)/gi, 'illegalscript') }} >
                                </div>
                            )
                            : ListingHelpData.title
                        }
                    </div>
                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>

                        {ListingHelpData.body.indexOf('</') !== -1
                            ? (
                                <div dangerouslySetInnerHTML={{ __html: ListingHelpData.body.replace(/(<? *script)/gi, 'illegalscript') }} >
                                </div>
                            )
                            : ListingHelpData.body
                        }

                    </div>
                    <div className='col-lg-12 adjust_list_help_modal' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                    <TakeTour backgroundColor="rgb(0, 148, 233)" /><br />
                        <InputCheckboxComponent
                            name='classOneMultiservedCheckbox'
                            checked={showListingHelpModal}
                            onHandleCheckboxChange={(name: string, value: boolean) => {
                                console.log(value)
                                setListingHelpModal(value)
                                localStorage.setItem('showListingHelpModal', showListingHelpModal);
                            }}
                            label='Do not show this again!'
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>


                    <button type='button' id='lilstHelpModal' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        )
    }


};

export default ListingHelpModal;
