import React, { Component } from 'react';
import Header from './HeaderLTE';
import SideBar from './SideBarLTE';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from './UserProfile';
import '../css/adminLTE-custom.css';
import DataTable from 'react-data-table-component';
import BulkUpload from './BulkUpload';
import memoize from 'memoize-one';
import { Link } from 'react-router-dom';
import { Modal, Button, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Footer from './Footer';
import Details from './PrivateDetails'
import MetaTags from 'react-meta-tags';

const columns = memoize(clickHandler => [
    {
        name: 'First Name',
        selector: 'sw_firstname',
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: 'sw_lastname',
        sortable: true,
    },
    {
        name: 'Company Name',
        selector: 'sw_companyname',
        sortable: true,
    },

    {
        name: 'Email',
        selector: 'sw_email',
        sortable: true,

    },
    {
        name: 'Plan Chosen',
        selector: 'sw_plan_id',
        sortable: true,
    },
    {
        name: 'Number Of Listings',
        selector: 'sw_number_listings',
        sortable: true,
    },
    {
        name: 'Joined Date',
        selector: 'start_date',
        sortable: true,
        width: '125px',
    },

]);



interface PMyListing {
    history: Array<string>;
}

class SMyListing {
    data: Array<any> = [];    
    showDeleteModal: boolean = false;
    showupdateplanModal: boolean = false;
    showChangeStatusModal: boolean = false;
    showWhoViewrsListingsModal: boolean = false;
    showAdminDesktopModal: boolean = false;
    detailsModal: boolean = false;

    constructor() {

    }

}

class MyListing extends Component<PMyListing, SMyListing> {

    _isMounted = false;

    constructor(props: PMyListing) {
        super(props);
        this.state = new SMyListing();
        this.getAllUsers = this.getAllUsers.bind(this);
    }


    handleButtonClick = (event) => {
        const id = event.target.getAttribute('data-id');
        const curr_array = id.split(',');
        const n = curr_array.length
        const buttonAction = event.target.getAttribute('data-action');

        if (buttonAction === 'edit') {
            this.props.history.push('/edit-place-' + id);
        }
        else if (buttonAction === 'delete') {

            this.setState({               
                
                showDeleteModal: true
            });
        }
        else if (buttonAction === 'details') {
            //this.getListingsAnalytics(curr_array[0])
        }
        else if (buttonAction === 'list-details') {
            localStorage.setItem("rec_id", curr_array[0])
            this.setState({ detailsModal: true });

        }
        else {
            //alert(curr_array)
            this.setState({                
                showChangeStatusModal: true
            });
        }
    }
    
   

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillMount() {
        this._isMounted = true;       
        this.getAllUsers();

    }

    getAllUsers = () => {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/get-users', {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
        })
            .then(res => {
                const features = res.data;
                const users_list = [];
                for (let i = 0; i < features.length; i++) {
                    let planName;
                    if (features[i].sw_plan_id == 2) {
                        planName = 'Basic'
                    } else if (features[i].sw_plan_id == 3) {
                        planName = 'Power Search'
                    }
                    else if (features[i].sw_plan_id == 4) {
                        planName = 'Power Pro'
                    }
                    else if (features[i].sw_plan_id == 5) {
                        planName = 'Power Search Yearly'
                    }
                    else if (features[i].sw_plan_id == 6) {
                        planName = 'Power Pro Yearly'
                    }
                    else if (features[i].sw_plan_id == 7) {
                        planName = 'Private Client'
                    }
                    else if (features[i].sw_plan_id == 8) {
                        planName = 'Unlimited Listings'
                    }
                    else{
                        planName = 'Plan Not Chosen'
                    }
                    const property_obj = {
                        client_id: features[i].id,
                        sw_companyname: features[i].sw_companyname,
                        sw_email: features[i].sw_email,
                        sw_number_listings: features[i].sw_number_listings,
                        start_date: features[i].created_at.substring(0, 10),
                        sw_firstname: features[i].sw_firstname,
                        sw_lastname: features[i].sw_lastname,
                        sw_plan_id: planName,
                    }
                    users_list.push(property_obj)



                }
                this.setState({ data: users_list });


            })
            .catch(err => {
            });
    }

    

    handleDeleteModal = () => {
        this.setState({         
          
            showDeleteModal: false
        });
    }
    

    callback = () => {
        this.getAllUsers();
    }
    




    render() {
        return (
            <div style={{ backgroundColor: '#222D32' }}>
                <MetaTags>
                    <title>All Users</title>

                </MetaTags>
                <Header />
                <SideBar />
                <div className='content-wrapper'>
                    <section className='content-header' style={{ backgroundColor: 'white' }}>
                        <div className='row'>
                            <div className='col-md-12'>

                                <div className='col-md-12'>
                                    <div className='col-md-6' style={{
                                        flex: '1 0 auto'
                                        , color: 'rgba(0, 0, 0, 0.87)'
                                        , fontSize: 30
                                        , fontWeight: 700
                                        , marginTop: 15
                                        , marginBottom: 30
                                    }}
                                    >
                                        <div >All Users</div>
                                    </div>
                                    <div className='col-md-6' style={{
                                        flex: '1 0 auto'
                                        , color: 'rgba(0, 0, 0, 0.87)'
                                        , marginTop: 15
                                        , marginBottom: 30
                                    }}
                                    >
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <DataTable
                                        title=''
                                        columns={columns(this.handleButtonClick)}
                                        data={this.state.data}
                                        defaultSortField='start_date'
                                        // onSort={this.handleSort}
                                        pagination={true}
                                        paginationPerPage={50}
                                        defaultSortAsc={false}
                                        paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
                                    />
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
                
                <Footer />
            </div>
        )
    }
}

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
    </svg>
)

const DetailsModal = ({ handleClose, show, children }) => (
    <Modal dialogClassName='quick-view-modal width-80' size='large' aria-labelledby='contained-modal-title-lg' show={show} onHide={handleClose} animation={false}>
        <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
        <Modal.Body style={{ background: 'white', marginBottom: '0px' }}>
            <div  >
                {children}
            </div>
        </Modal.Body>
        <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
            <button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
        </Modal.Footer>
    </Modal>
);

export default withRouter(MyListing);