import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import React, { useEffect, useState } from "react";

function Step4New(props) {
	const [plan, setplan] = useState({});

	useEffect(() => {
	 var planid = props.getState("plan", "");
	  axios.get(process.env.REACT_APP_BASE_URL + '/api/get-plan-byid/' + planid, {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
		})
		.then(res => {
			var plansdata = res.data[0];
			setplan(res.data[0]);
            props.setState('plan_id',10);
			//console.log(Number(plansdata.membership_level_cost));
			
		})
		.catch(err => {
			console.log('error ',err);
		});
  	}, []);


	const validator = new SimpleReactValidator({
        element: (message, className) => <div className='text-danger'>{message}</div>
    });

  return (
	  	<form id="Step4New">
			<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>
				Checkout
			</div>
			<div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-8" style={{padding:5 }}>
                        <label>Subscription Plan :</label>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4" style={{padding:5 }}>{plan.membership_level_name}</div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-8" style={{padding:5 }}>
                        <label>Amount</label>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4" style={{padding:5 }}>{Number(plan.membership_level_cost) > 0 ? '$' : ''} {Number(plan.membership_level_cost) > 0 ? plan.membership_level_cost : 'Free'}</div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-8" style={{padding:5 }}>
                        <label>Total</label>
                    </div>
                    <div className="col-xs-6 col-sm-6 col-md-4" style={{padding:5 }}><b>{Number(plan.membership_level_cost) > 0 ? '$' : ''} {Number(plan.membership_level_cost)}</b></div>
                </div>
                <div className="row">
                    <div className="col-xs-6 col-sm-6 col-md-8" style={{padding:5 }}></div>
                    <div className="col-xs-6 col-sm-6 col-md-4" style={{padding:5 }}>
                    </div>
                </div>
		</form>
  );
}

export default Step4New;
