import React, { Component } from 'react';
import '../custom.css';
import { Breadcrumb, BreadcrumbItem, Modal, Row, Alert, Button, OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
import logo from '../logo.svg';
import Features from './Features';
import { Cookies } from 'react-cookie';
import { Link, withRouter } from 'react-router-dom';
import ReactMapboxGl, { Layer, Feature, ZoomControl } from 'react-mapbox-gl';
import siteIcon from '../site-marker.png';
import InputBoxComponent from './InputBoxComponent';
import portTerminalIcon from '../port-terminal.png';
import otherTerminalIcon from '../terminaltrans.png';
import warehouseIcon from '../warehouse-marker.png';
import axios from 'axios';
import UserProfile from './UserProfile';
import addressIcon from '../assets/icons/address.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import MediaQuery from 'react-responsive';
import MetaTags from 'react-meta-tags';
import SimpleReactValidator from 'simple-react-validator';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import PdfDetailsDocGen from './pdf/PdfDetailsDocGen';
import LinkedInTag from 'react-linkedin-insight';
import { Helmet } from "react-helmet";
import { CopyToClipboard } from 'react-copy-to-clipboard'

const siteImage = new Image();
siteImage.src = siteIcon;
siteImage.width = 28;
siteImage.height = 28;
(siteImage as any).alpha = 0;
export const siteImages = ['siteImage', siteImage];

const portTerminalImage = new Image();
portTerminalImage.src = portTerminalIcon;
portTerminalImage.width = 28;
portTerminalImage.height = 28;
(portTerminalImage as any).alpha = 0;
export const portTerminalImages = ['portTerminalImage', portTerminalImage];

const otherTerminalImage = new Image();
otherTerminalImage.src = otherTerminalIcon;
otherTerminalImage.width = 28;
otherTerminalImage.height = 28;
(otherTerminalImage as any).alpha = 0;
const otherTerminalImages = ['otherTerminalImage', otherTerminalImage];

const warehouseImage = new Image();
warehouseImage.src = warehouseIcon;
warehouseImage.width = 28;
warehouseImage.height = 28;
(warehouseImage as any).alpha = 0;
export const warehouseImages = ['warehouseImage', warehouseImage];

const Mapbox = ReactMapboxGl({
	accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
});

interface PDetails {
	match: any;
	history: any;
	onStyleLoad?: (map: any) => any;
}

class SDetails {

	constructor() {
	}
}
const cookies = new Cookies();

class Details extends Component<PDetails, any> {
	child: Header = null;
	onDrag: any = null;


	validator: SimpleReactValidator = null;
	loginValidator: SimpleReactValidator = null;
	OTPValidator: SimpleReactValidator = null;
	registerValidator: SimpleReactValidator = null;

	constructor(props: PDetails) {
		super(props);
		this.state = {
			zoom: [16],
			showContactUs: false,
			firstname: UserProfile.getFirstName() ? UserProfile.getFirstName() : '',
			lastname: UserProfile.getLastName() ? UserProfile.getLastName() : '',
			email: UserProfile.getEmail() ? UserProfile.getEmail() : '',
			message: '',
			show: false,
			formattedPrice: '',
			renderSatelliteMap: true,
			mapType: '',

			place: '',
			center: '',
			markerPoints: '',
			place_owner_name: '',
			place_id: '',
			addFavModal: false,
			deleteFavModal: false,
			bookmarksCount: 0,



			fname: '',
			lname: '',
			companyName: '',
			password: '',
			confirm_password: '',
			reg_email: '',
			showRegistrationSuccessModal: false,
			reacatpcha_value: '',
			meta_title: this.props.match.params.id,
			meta_discription: '',
			meta_keyword: '',
			listingsStatus: 'Inactive',


		}
		this.goBack = this.goBack.bind(this);
	}
	componentWillMount() {
		this.loginValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
		this.OTPValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		this.registerValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
	}
	componentDidMount() {
		this.changeMapStyle();
		var urlarray = this.props.match.params.id.split("-")
		this.getData(urlarray[urlarray.length - 1]);
		var newArray = urlarray.splice(-1)
		this.setState({ meta_title: urlarray.toString().replaceAll(',', '-') });



	}

	handleRegistrationModal = () => {
		this.setState({ showRegistrationModal: false });
	}

	setFName(event) {
		this.setState({ fname: event.target.value });
	}

	setLName(event) {
		this.setState({ lname: event.target.value });
	}

	setCompanyName(event) {
		this.setState({ companyName: event.target.value });
	}

	setPassword(event) {
		this.setState({ password: event.target.value });
	}

	setConfirmPassword(event) {
		this.setState({ confirm_password: event.target.value });
	}

	setEmailreg(event) {
		this.setState({ reg_email: event.target.value });
	}

	onRecaptchaChange = event => {
		console.log('Captcha value: ', event);
		this.setState({ reacatpcha_value: event });
		const regi_errors = document.getElementById('contact_error');
		regi_errors.innerHTML = '';
		document.getElementById("contact_error").classList.remove("alert-danger");
	};

	registerUser(event) {
		if (this.registerValidator.allValid()) {
			LinkedInTag.track('9791956');
			axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user',
				{
					headers: { 'Content-Type': 'application/json' },
					fname: this.state.fname,
					lname: this.state.lname,
					email: this.state.reg_email,
					companyName: this.state.companyName,
					password: this.state.password,
					reacatpcha_value: this.state.reacatpcha_value,
					'source': 'View Listings',
				}).then(response => {
					// handle your response;
					if (response.status === 200) {
						this.setState({
							fname: '',
							lname: '',
							companyName: '',
							//password: '',
							confirm_password: '',
							//reg_email: '',
							reacatpcha_value: null,
							showRegistrationSuccessModal: true,
							showRegistrationModal: false
						});

					}
				}).catch((error) => {
					console.log('error')
					alert('failed')
					console.log(error.response)
					// handle your error
					const regi_errors = document.getElementById('regi_errors');
					regi_errors.innerHTML = '';
					const error_html = document.createElement('div');
					if (error.response.status === 600 && error.response.data.error === 'email exists') {
						error_html.innerHTML = 'You already have an account, please login!';
					} else if (error.response.status === 601) {
						error_html.innerHTML = 'Captcha verification failed, please try again!';
					} else {
						for (let i = 0; i < error.response.data.errors.length; i++) {
							error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
						}
					}
					document.getElementById('regi_errors').appendChild(error_html);
					document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
					regi_errors.scrollIntoView({ behavior: 'smooth' })
				});
		} else {
			this.registerValidator.showMessages();
			this.forceUpdate();
		}
	}

	handleRegistrationSuccessModal = () => {
		this.setState({ showRegistrationSuccessModal: false });
		axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
			{
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				login_email: this.state.reg_email,
				login_password: this.state.password,
			}).then((response: {
				status: number
				, data: {
					bookmarks_str: string
					, email: string
					, first_name: string
					, last_name: string
					, max_no_entries: string
					, options: string
					, place_names_arr: string //Array<any>
					, user_type: string
					, token: string
				}
			}) => {
				document.getElementById('login_error').style.display = 'none';
				document.getElementById('verify_email_error').style.display = 'none';
				console.log(response);
				this.handleClose();
				// alert(window.location.href)
				if (response.status === 200) {
					let bookmarksCount = 0;
					console.log('response.data')
					console.log(response.data)
					if (response.data.bookmarks_str) {
						const bookmarksArr = response.data.bookmarks_str.split(',');
						bookmarksCount = bookmarksArr.length;
					}
					localStorage.setItem('bookmarks', response.data.bookmarks_str);
					localStorage.setItem('listingNames', response.data.place_names_arr);
					localStorage.setItem('maxNoEntries', response.data.max_no_entries);
					localStorage.setItem('membershipOptions', response.data.options);

					if (response.data.user_type === 'admin') {
						this.setState({
							showOTPModal: true
						});
					} else {
						this.handleLoginSuccessResponse(response);
					}
				} else {
					this.setState({ isLoggedIn: false });
					if (response.status === 204) {
						document.getElementById('verify_email_error').style.display = 'block';
						document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
					} else {
						document.getElementById('login_error').style.display = 'block';
						document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
					}
				}
			}).catch((error) => {
				// handle your error
				const errorEl = document.getElementById('login_error');
				if (errorEl) {
					errorEl.style.display = 'block';
					errorEl.scrollIntoView({ behavior: 'smooth' });
				}
			});
	}
	handleLoginSuccessResponse = response => {
		console.log("response", response)
		UserProfile.setEmail(response.data.email);
		UserProfile.setFirstName(response.data.first_name);
		UserProfile.setLastName(response.data.last_name);
		UserProfile.setCompanyName(response.data.companyName);
		UserProfile.setUserType(response.data.user_type);

		this.setState({
			isLoggedIn: true,
			showLoginSuccessModal: true,
		});

		cookies.set('token', response.data.token);
		this.forceUpdate();

		// if (this.props.data.location.pathname === '/register' || this.props.data.location.pathname === '/success' || this.state.nextURL === '/create-listings') {
		// 	// this.props.data.history.push('/create');
		// 	this.props.data.history.push({
		// 		pathname: '/create-listings',
		// 		state: {
		// 			propertyType: this.state.propertyType,
		// 			propertySubType: this.state.propertySubType,
		// 		}
		// 	})
		// }
		// else if (this.state.nextURL === '/view-listings') {
		// 	// this.props.data.history.push('/listing');
		// 	this.props.data.history.push({
		// 		pathname: '/view-listings',
		// 		state: {
		// 			propertyType: this.state.propertyType,
		// 			propertySubType: this.state.propertySubType,
		// 		}
		// 	})
		// }
	}
	handleLoginSuccessModal() {
		this.setState({ showLoginSuccessModal: false });
	}
	showRegistrationModal = () => {
		this.setState({
			showRegistrationModal: true,
			showLoginModal: false,
		});
	}
	handleOpen = () => {
		this.setState({ showLoginModal: true });
	}
	handleClose = () => {
		this.setState({ showLoginModal: false });
	}
	handleShowForgotPasswordModal = () => {
		this.setState({ showForgotPasswordModal: true });
		this.handleClose();
	}

	handleForgotPasswordClose = () => {
		this.setState({ showForgotPasswordModal: false });
		this.handleOpen();
	}



	getData = async (id) => {
		await axios.post(process.env.REACT_APP_BASE_URL + '/api/get-place-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			rec_id: id,
		})
			.then(res => {
				// console.log(res.data.feature)
				var place = res.data.feature;
				if (res.status === 200) {
					var formatter = new Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: 0
					});
					let formattedPrice = formatter.format(place.properties.price);
					let meta_disc = '';
					let meta_keyword = '';
					if (place.properties.propertyType.includes('Industrial Sites & Properties')) {
						if (place.properties.address == undefined || '' || null) {
							var sentence_one = 'This is a industrial site & property located at ' + place.properties.city + ' ' + place.properties.state
						} else {
							var sentence_one = 'This is a industrial site & property located at ' + place.properties.address
						}
						if (place.properties.acreage == undefined || '' || null) {
							var sentence_two = '.This site is for ' + place.properties.propertyFor
						} else {
							var sentence_two = 'that sits on ' + place.properties.acreage + ' acres. This site is for ' + place.properties.propertyFor
						}
						if (place.properties.capabilities == undefined || ' ' || null) {
							var sentence_three = ''
						} else {
							var sentence_three = ', and it is suitable for ' + (place.properties.capabilities != undefined ? place.properties.capabilities.toLowerCase().replaceAll('*', ','):'')
						}
						meta_disc = sentence_one + ' listed by ' + place.properties.organizationName + sentence_two + '. It does not have a building included' + sentence_three
						meta_keyword = place.properties.propertyType + "," + place.properties.listingName + ',' + place.properties.organizationName + ',' + place.properties.acreage + ' acres' + ',' + place.properties.propertyFor + ',' + (place.properties.capabilities != undefined ? place.properties.capabilities.toLowerCase().replaceAll('*', ','):'') + ',' + place.properties.building

					}
					else if (place.properties.propertyType.includes('Warehouse/Distribution Center')) {
						if (place.properties.address == undefined || '' || null) {
							var sentence_one = 'This is a warehouse/distribution center located at' + place.properties.city + ',' + place.properties.state
						} else {
							var sentence_one = 'This is a warehouse/distribution center located at ' + place.properties.address
						}
						if (place.properties.DockLevelDoors == undefined || ' ' || null) {
							var sentence_two = ''
						} else {
							var sentence_two = ' It has a total of ' + place.properties.DockLevelDoors + ' dock doors.'
						}
						if (place.properties.TotalWarehouseArea == undefined || ' ' || null) {
							var sentence_three = ''
						} else {
							var sentence_three = ' that has ' + place.properties.TotalWarehouseArea + ' sq ft total area'
						}
						meta_disc = sentence_one + ' listed by ' + place.properties.organizationName + sentence_three + '.' + sentence_two
						meta_keyword = place.properties.propertyType + "," + place.properties.listingName + ',' + place.properties.organizationName + ',' + place.properties.propertyFor + (place.properties.capabilities != undefined ? place.properties.capabilities.toLowerCase().replaceAll('*', ','):'') + 'railInPlace:' + place.properties.railInPlace + ',' + ' TotalDockDoors: ' + place.properties.TotalDockDoors + ', TotalWarehouseAvailableArea:' + place.properties.TotalWarehouseAvailableArea + ', railInPlace: ' + place.properties.railInPlace
					}
					else {
						// if (place.properties.address == undefined || '' || null) {
						// 	var sentence_one = 'This is a ' + place.properties.propertyType + ' located at ' + place.properties.city + ',' + place.properties.state
						// } else {
						// 	var sentence_one = 'This is a ' + place.properties.propertyType + ' located at ' + place.properties.address
						// }
						// if (place.properties.docInPlace == 'Yes') {
						// 	var sentence_two = 'This site has a dock and is near the following waterways: ' + place.properties.waterways.replaceAll('*', ',')
						// } else {
						// 	var sentence_two = 'This site is near the following waterways:' + place.properties.waterways.replaceAll('*', ',')
						// }
						if (place.properties.capabilities == undefined || '' || null) {
							var sentence_one = ''
						} else {

							var sentence_one = (place.properties.capabilities != undefined ? place.properties.capabilities.toLowerCase().replaceAll('*', ','):'') + ' capable'
						}
						if (place.properties.productsHandled == undefined || '' || null) {
							var sentence_two = ''
						} else {
							var sentence_two = ' handles ' + (place.properties.productsHandled != undefined ? place.properties.productsHandled.toLowerCase().replaceAll('*', ',') :'')
						}
						if (place.properties.classOneConnections == undefined || '' || null) {
							var sentence_three = ''
						} else {
							var sentence_three = ' near ' + place.properties.classOneConnections.toLowerCase().replaceAll('*', ',') + ' class I connection'
						}
						if (place.properties.shortLines == undefined || '' || null) {
							var sentence_four = ''
						} else {
							var sentence_four = ' and near ' + place.properties.shortLines.toLowerCase().replaceAll('*', ',') + ' shortlines'
						}
						if (place.properties.unitTrainCapable == 'Yes') {
							var sentence_five = 'and is unit train capable.'
						} else {
							var sentence_five = ' '
						}
						if (place.properties.waterways == undefined || ' ' || null) {
							var sentence_six = ''
						} else {
							var sentence_six = ' and near ' + (place.properties.waterways && place.properties.waterways.toLowerCase().replaceAll('*', ',') + ' waterways')

						}
						meta_disc = sentence_one + sentence_two + sentence_three + sentence_four + sentence_five + sentence_six
						meta_keyword = place.properties.propertyType + "," + place.properties.listingName + ',' + place.properties.organizationName + ',' + place.properties.acreage + place.properties.propertyFor + (place.properties.capabilities && place.properties.capabilities.replaceAll('*', ',')) + (place.properties.shortLines && place.properties.shortLines.replaceAll('*', ',')) + (place.properties.productsHandled && place.properties.productsHandled.replaceAll('*', ',')) + (place.properties.waterways && place.properties.waterways.replaceAll('*', ',')) + (place.properties.classOneConnections && place.properties.classOneConnections.replaceAll('*', ',')) + ', railInPlace: ' + place.properties.railInPlace + ', unitTrainCapable: ' + place.properties.unitTrainCapable
					}

					this.setState({
						place: place,
						center: place.geometry.coordinates,
						markerPoints: [{ lng: place.geometry.coordinates[0] - 0.00002, lat: place.geometry.coordinates[1] - 0.00002 }, { lng: place.geometry.coordinates[0] + 0.00002, lat: place.geometry.coordinates[1] + 0.00002 }],
						place_owner_name: place.client_first_name,
						place_id: place.rec_id,
						meta_discription: meta_disc,
						meta_keyword: meta_keyword,
						listingsStatus: place.active == 'Yes' ? "Active" : "Inactive",
						formattedPrice: formattedPrice
					}, () => {
						const bookmarksStr = localStorage.getItem('bookmarks') ? localStorage.getItem('bookmarks') : '';
						if (bookmarksStr) {
							const bookmarksArr = bookmarksStr.split(',');
							if (bookmarksArr.includes(place.rec_id)) {
								document.getElementById('bookmark-btn').classList.add('color-faint-blue');
							}
							this.setState({
								bookmarksCount: bookmarksArr.length
							})
						}
					});
				}
				else {
					this.setState({
						listingsStatus: "Inactive",
					})
				}
			}).catch(err => {
				this.setState({
					listingsStatus: "Inactive",
				})
				console.log(err);

			});
	}

	onToggleHover(cursor, { map }) {
		map.getCanvas().style.cursor = cursor;
	}

	onStyleLoad(map) {
		const { onStyleLoad } = this.props;
		return onStyleLoad && onStyleLoad(map);
	};

	markerClick = (place, feature) => {
		this.setState({
			center: place.geometry.coordinates,
			zoom: [16],
			place,
			markerPoints: [{ lng: place.geometry.coordinates[0] - 0.00002, lat: place.geometry.coordinates[1] - 0.00002 }, { lng: place.geometry.coordinates[0] + 0.00002, lat: place.geometry.coordinates[1] + 0.00002 }]
		});
	};

	goBack() {
		// this.props.history.goBack();
		this.props.history.push('/view-listings');
	}

	toggleContinueReading() {
		if (document.getElementById('description').style.maxHeight === '250px') {
			document.getElementById('description').style.maxHeight = '50px';
			document.getElementById('description').style.overflow = 'hidden';
		}
		else {
			document.getElementById('description').style.maxHeight = '250px';
			document.getElementById('description').style.overflow = 'visible';
		}
	}

	handleContactUsClose = () => {
		this.setState({ showContactUs: false });
	}

	handleContactUsShow = () => {
		if (cookies.get('token') == undefined) {
			this.handleOpen();
		}
		else {
			this.setState({ showContactUs: true });
		}
	}

	setFirstName = (event) => {
		this.setState({ firstname: event.target.value });
	}
	handleInputChange = (name, value) => {
		this.setState({ otp: value });
	}
	handleOTPModal = () => {
		this.setState({ showOTPModal: false });
	}
	handleOTPVerification = () => {
		if (this.OTPValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					otp: this.state.otp,
					login_email: this.state.login_email,
				}).then(response => {
					console.log(response);
					if (response.status === 200) {
						this.handleOTPModal();
						this.handleLoginSuccessResponse(response);
					}
				}).catch(function (error) {
					// handle your error
				});
		} else {
			this.OTPValidator.showMessages();
			this.forceUpdate();
		}
	}

	setLastName = (event) => {
		this.setState({ lastname: event.target.value });
	}

	setEmail = (event) => {
		this.setState({ email: event.target.value });
	}

	setMessage = (event) => {
		this.setState({ message: event.target.value });
	}

	handleShow = () => {
		this.setState({ show: true });
	}

	handleDismiss = () => {
		this.setState({ show: false });
	}

	handleContactUs = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us',
			{
				headers: {
					'Content-Type': 'application/json',
					// 'x-access-token': cookies.get('token')
				},
				// withCredentials: true,
				place_owner_name: this.state.place_owner_name,
				place_id: this.state.place_id,
				name: this.state.firstname + ' ' + this.state.lastname,
				email: this.state.email,
				message: this.state.message,
			}).then(response => {
				console.log(response)
				if (response.status === 200) {
					this.handleContactUsClose();
					this.handleShow();
					document.getElementById('main-content').scrollIntoView({ behavior: 'smooth' });
				} else {
					this.handleDismiss();
				}
			}).catch(function (error) {
				console.log(error)
			});
	}

	changeMapStyle = () => {
		this.setState({ renderSatelliteMap: !this.state.renderSatelliteMap });
		if (this.state.renderSatelliteMap) {
			this.setState({ mapType: process.env.REACT_APP_MAPBOX_STYLE_SATELLITE });
		} else {
			this.setState({ mapType: process.env.REACT_APP_MAPBOX_STYLE_STREETS });
		}
		this.forceUpdate();
	}

	formatPhoneNumber(phoneNumberString) {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
		var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
		if (match) {
			var intlCode = (match[1] ? '+1 ' : '')
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
		}
		return null
	}

	createStrFromArr = (inputArr) => {
		var arr = inputArr.split('*');
		var str = '';
		for (var k = 0; k < arr.length; k++) {
			if (str) {
				str = str + ', ' + arr[k];
			} else {
				str = arr[k];
			}
		}
		return str;
	}

	createPDF = () => {
		// this.showpdfModal()
		if (cookies.get('token') !== undefined) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
				{
					headers: {
						'Content-Type': 'application/json',
						// 'x-access-token': cookies.get('token')
					},
					// withCredentials: true,
					imgurl: this.state.place.properties.image[0].url,
				}).then(response => {
					if (response.status === 200) {
						var final_base_64_url = 'data:image/jpeg;base64,' + response.data.base64;
						this.state.place.properties.image[0].url = final_base_64_url;
						axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
							{
								headers: {
									'Content-Type': 'application/json',
									// 'x-access-token': cookies.get('token')
								},
								// withCredentials: true,
								imgurl: this.state.place.properties.listingLogo,
							}).then(response => {
								if (response.status === 200) {
									var final_base_64_url_logo = 'data:image/jpeg;base64,' + response.data.base64;
									this.state.place.properties.listingLogo = final_base_64_url_logo;
									// console.log('this.state.place.properties',this.state.place.properties);
									(new PdfDetailsDocGen()).createPDF(this.state.place.properties);
								}
							}).catch(function (error) {
								console.log(error)
								alert('Image Correpted...Something went wrong')
							});
					}
				}).catch(function (error) {
					console.log(error)
					alert('Image Correpted...Something went wrong')
				});

			// (new PdfDetailsDocGen()).createPDF(this.state.place.properties);
		}
		else {
			this.handleOpen();
		}

	}

	addToBookmarkList = (place_id) => {
		console.log(place_id)
		if (cookies.get('token') == undefined) {
			this.handleOpen();
		}
		else {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/update-bookmark-list', {
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				place_id: place_id,
				email: this.state.email
			})
				.then(res => {
					console.log(res.data)
					localStorage.setItem('bookmarks', res.data.bookmarks);
					let bookmarksArr = [];
					if (res.data.bookmarks) {
						bookmarksArr = res.data.bookmarks.split(',');
					}
					const bookmarksCount = bookmarksArr.length;
					this.setState({
						bookmarksCount: bookmarksCount
					})
					if (res.data.type === 'add') {
						document.getElementById('bookmark-btn').classList.add('color-faint-blue');
						this.showAddFavModal();
					} else {
						this.showDeleteFavModal();
						document.getElementById('bookmark-btn').classList.remove('color-faint-blue');
					}
				}).catch(err => {
					console.log(err);
				});
		}
	}

	showAddFavModal = () => {
		this.setState({ addFavModal: true });
	}

	hideAddFavModal = () => {
		this.setState({ addFavModal: false });
	}

	showDeleteFavModal = () => {
		this.setState({ deleteFavModal: true });
	}

	hideDeleteFavModal = () => {
		this.setState({ deleteFavModal: false });
	}

	getFirstImage = (json) => {
		for (var key in json) {
			if (json.hasOwnProperty(key)) {
				var url = json[key].url;
				return url;
			}
		}
	}
	setLoginEmail(event) {
		this.setState({ login_email: event.target.value });
	}

	setLoginPassword(event) {
		this.setState({ login_password: event.target.value });
	}
	openInNewTab = (url) => {
		if (!url.match(/^https?:\/\//i)) {
			url = 'http://' + url;
		}
		window.open(url, '_blank', 'noopener,noreferrer');
	}
	handleLogin() {
		if (this.loginValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					login_email: this.state.login_email,
					login_password: this.state.login_password,
				}).then((response: {
					status: number
					, data: {
						bookmarks_str: string
						, email: string
						, first_name: string
						, last_name: string
						, max_no_entries: string
						, options: string
						, place_names_arr: string //Array<any>
						, user_type: string
						, token: string
					}
				}) => {
					document.getElementById('login_error').style.display = 'none';
					document.getElementById('verify_email_error').style.display = 'none';
					console.log(response);
					this.handleClose();
					// alert(window.location.href)
					if (response.status === 200) {
						let bookmarksCount = 0;
						console.log('response.data')
						console.log(response.data)
						if (response.data.bookmarks_str) {
							const bookmarksArr = response.data.bookmarks_str.split(',');
							bookmarksCount = bookmarksArr.length;
						}
						localStorage.setItem('bookmarks', response.data.bookmarks_str);
						localStorage.setItem('listingNames', response.data.place_names_arr);
						localStorage.setItem('maxNoEntries', response.data.max_no_entries);
						localStorage.setItem('membershipOptions', response.data.options);

						if (response.data.user_type === 'admin') {
							this.setState({
								showOTPModal: true
							});
						} else {
							this.handleLoginSuccessResponse(response);
						}
					} else {
						this.setState({ isLoggedIn: false });
						if (response.status === 204) {
							document.getElementById('verify_email_error').style.display = 'block';
							document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
						} else {
							document.getElementById('login_error').style.display = 'block';
							document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
						}
					}
				}).catch((error) => {
					// handle your error
					const errorEl = document.getElementById('login_error');
					if (errorEl) {
						errorEl.style.display = 'block';
						errorEl.scrollIntoView({ behavior: 'smooth' });
					}
				});
		} else {
			this.loginValidator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}
	setFPEmail = (event) => {
		this.setState({ fpEmail: event.target.value });
	}

	sendFPEmail = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
			{
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				fpEmail: this.state.fpEmail
			}).then(response => {
				document.getElementById('fp_success').style.display = 'block';
				document.getElementById('fp_error').style.display = 'none';
			}).catch(function (error) {
				document.getElementById('fp_success').style.display = 'none';
				document.getElementById('fp_error').style.display = 'block';
				// alert('Something went wrong! Please try after sometime.')
			});
	}
	copyShareableLink = () => {
		alert('Link copied in clipboard!')
	}

	render() {
		const { center, zoom, place, markerPoints } = this.state;
		const feature = place;
		const sharableString = place ? place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-') + '-' + place.properties.city + '-' + place.properties.state + '-' + place.properties.listingName + '-' + place.rec_id : '';
		return (
			<div className='padding-bottom-50'>
				<Helmet>
					<title>{this.state.meta_title}</title>
					<meta property="og:url" content={window.location.href} />
					<meta name='description' content={this.state.meta_discription} />
					<meta name="keywords" content={this.state.meta_keyword} />
					<link rel="canonical" href={window.location.href} />
					<meta property='og:title' content={this.state.meta_title} />
					<meta property='og:description' content={place && place.properties.description} />
					<meta property='og:image' content={place && place.properties.image[0].url ? place && place.properties.image[0].url : ''} />
				</Helmet>
				<Header data={this.props} onRef={ref => (this.child = ref)} bookmarksCount={this.state.bookmarksCount} />
				<div id='page-content' style={{ minHeight: '85vh' }}>
					<Breadcrumb style={{ paddingTop: '10px' }}>
						<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
						<Link to={{ pathname: '/listing' }}><span className='color-white'>Listings</span></Link>&nbsp;/&nbsp;
						<BreadcrumbItem active>Details</BreadcrumbItem>
					</Breadcrumb>
					{
						this.state.listingsStatus == "Active" ?

							<section className='container'>
								{place &&
									<div className='row'>
										<div className='col-md-4 col-sm-4' id='detail-sidebar'>
											<aside className='col-md-12 col-sm-12 padding-top-20 padding-0'>
												{/* <div className='pull-left'>
											<div style={{ marginTop: 5 }}>
												<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.goBack}><i className='fa fa-arrow-left'></i>&nbsp;Back to Map</button>
											</div>
										</div> */}

												<div className='pull-left'>
													{/* <div className='pull-left' style={{ padding: 10 }}>
												<OverlayTrigger placement='bottom' overlay={
													<Tooltip id='tooltip'>
														<strong className='font-size-16'>Share on Facebook</strong>
													</Tooltip>
												}>
													<FacebookShareButton url={window.location.href}>
														<i className='color-blue fa fa-2x fa-facebook cursor-pointer'></i>
													</FacebookShareButton>
												</OverlayTrigger>
											</div> */}

													<div className='pull-left' style={{ padding: 10 }}>
														<OverlayTrigger placement='bottom' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>Share on Twitter</strong>
															</Tooltip>
														}>
															<TwitterShareButton url={window.location.href}>
																<i className='color-blue fa fa-2x fa-twitter cursor-pointer'></i>
															</TwitterShareButton>
														</OverlayTrigger>
													</div>

													<div className='pull-left' style={{ padding: 10 }}>
														<OverlayTrigger placement='bottom' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>Share on LinkedIn</strong>
															</Tooltip>
														}>
															<a href={"https://www.linkedin.com/shareArticle?mini=true&url=https://share.industrialnavigator.com/industrialnavigator.php?" + sharableString.replaceAll(' ', '-').toLowerCase().replaceAll('&', '')} target="_blank"><i className="fab fa-2x fa-linkedin-in color-blue fa  cursor-pointer"></i></a>

														</OverlayTrigger>
													</div>

													<div className='pull-left' style={{ padding: 10 }}>
														<OverlayTrigger placement='bottom' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>Share on Email</strong>
															</Tooltip>
														}>
															<EmailShareButton onClick={() => { }} openShareDialogOnClick url={window.location.href} subject={'Industrial Navigator'} body={'Industrial Navigator'}>
																<i className='color-blue fa fa-2x fa-envelope cursor-pointer'></i>
															</EmailShareButton>
														</OverlayTrigger>
													</div>
													<div className='pull-left' style={{ padding: 10 }}>
														<OverlayTrigger placement='bottom' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>Copy Link</strong>
															</Tooltip>
														}>
															<CopyToClipboard text={window.location.href}>
																<i className='color-blue fa fa-2x fa-link cursor-pointer' onClick={this.copyShareableLink}></i>
															</CopyToClipboard>
														</OverlayTrigger>
													</div>

													<div className='pull-left' style={{ right: 15, top: 25, width: 30, padding: 10 }}>
														<OverlayTrigger placement='bottom' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>Generate PDF</strong>
															</Tooltip>
														}>
															<i className='color-blue fa fa-2x fa-file-pdf-o cursor-pointer' onClick={this.createPDF}></i>
														</OverlayTrigger>
													</div>
												</div>
											</aside>

											<MediaQuery minDeviceWidth={768}>
												<aside id='detail-sidebar' className='padding-top-20 clear-both'>
													<section className='address-box'>
														<header>
															<img className='position-absolute top-12' src={addressIcon} width='20' />
															<h2 className='margin-left-25 margin-bottom-0'>Address</h2>
														</header>
														<address style={{ marginBottom: 0 }} className='clear-both'>
															<div className='margin-left-25 clear-both'>{place.properties.address}</div>
														</address>
													</section>
												</aside>
											</MediaQuery>
										</div>
										<div className='col-md-8 col-sm-8 pull-left'>
											<section className='block' id='main-content'>
												{this.state.show &&
													<Alert bsStyle='success' onDismiss={this.handleDismiss}>
														<h2 style={{ marginTop: 0, marginBottom: 10, color: 'white' }}>Success!</h2>
														<p>
															Your request has been sent. We will get in touch with you!
														</p>
													</Alert>
												}
												<header className='page-title'>
													<div className='title col-lg-12 padding-0'>
														<h1>
															{place.properties.listingName}
															&nbsp;
															<OverlayTrigger placement='bottom' overlay={
																<Tooltip id='tooltip'>
																	<strong className='font-size-16'>Add to Favorites</strong>
																</Tooltip>
															}>
																<i id='bookmark-btn' className='cursor-pointer fa fa-star color-blue' onClick={() => this.addToBookmarkList(this.state.place_id)}></i>
															</OverlayTrigger>
														</h1>
														<figure className='font-size-17'>{place.properties.city}</figure>

														<MediaQuery maxDeviceWidth={768}>
															<aside id='detail-sidebar' className='padding-top-20 clear-both'>
																<div className='address-box'>
																	<header className='row'>
																		<img className='position-absolute' src={addressIcon} width='20' />
																		<h2 className='margin-left-25 margin-bottom-0'>Address</h2>
																	</header>
																	<address style={{ marginBottom: 0 }} className='clear-both'>
																		<div className='margin-left-15 clear-both'>{place.properties.address}</div>
																	</address>
																</div>
															</aside>
														</MediaQuery>
													</div>
												</header>
												<div className='row'>
													<div className='col-mg-12 col-sm-12'>
														<section>
															<article className='item-gallery margin-bottom-30 border-bottom-1'>
																<div className='real-estate-item-image'>
																	<div className='badge badge-danger background-dark-blue font-size-14-imp font-weight-bold-imp'>FOR {place.properties.propertyFor}</div>
																	<Carousel
																		autoPlay={true}
																		stopOnHover={true}
																		infiniteLoop={true}
																		swipeable={true}
																		dynamicHeight={true}
																		showThumbs={place.properties.image && place.properties.image.length > 1 ? true : false}
																		emulateTouch={true}
																		interval={3000}
																		className={place.properties.image && place.properties.image.length > 1 ? 'margin-bottom-120' : ''}
																	>
																		{place.properties.image.map((value, index) => {
																			return <div key={index}><img src={value.url} /></div>
																		})}
																	</Carousel>
																	{/* {
																this.state.place.properties.price !== ''
																?
																	<div className='real-estate-item-price font-weight-bold'>
																		<span className='font-size-25-imp'>{ this.state.formattedPrice }</span><span className='font-size-18-imp font-weight-normal'>{ this.state.place.properties.perUnit != '' && '/'+this.state.place.properties.perUnit }</span>
																	</div>
																:
																	<div className='real-estate-item-price font-weight-bold'>
																		<span className='font-size-25-imp'>$0</span><span className='font-size-18-imp font-weight-normal'>{ this.state.place.properties.perUnit != '' && '/'+this.state.place.properties.perUnit }</span>
																	</div>
															}
															<div className='position-absolute' style={{ bottom: 0, width: '100%' }}>
																<span className='text-uppercase contact-us font-weight-bold' role='button' onClick={this.handleContactUsShow.bind(this)}>Contact Us</span>
															</div> */}
																</div>
															</article>
															{
																place.properties.contactName &&
																<article className='margin-bottom-30 border-bottom-1'>
																	<header>
																		<h2 className='margin-bottom-15 text-decoration-underline text-decoration-underline'>Contact Information</h2>
																	</header>
																	<div>
																		<div className='font-size-15' style={{ fontWeight: 600, marginBottom: 10 }}>
																			{place.properties.contactName ? place.properties.contactName : ''}{place.properties.contactTitle ? '/' + place.properties.contactTitle : ''}
																		</div>
																		<div className='font-size-14 color-black' style={{ fontWeight: 600, marginBottom: 10 }}>
																			{/* {place.properties.companyWebsiteUrl && place.properties.companyWebsiteUrl != '' && 'Company Website: ' + place.properties.companyWebsiteUrl} */}
																			{
																				place.properties.companyWebsiteUrl && place.properties.companyWebsiteUrl != '' &&
																				<a onClick={() => this.openInNewTab(place.properties.companyWebsiteUrl)}>Company Website</a>
																			}
																		</div>
																		<div className='font-size-16 position-relative color-blue'>
																			<i className='fa fa-2x fa-address-book margin-bottom-20 cursor-pointer' aria-hidden='true' onClick={this.handleContactUsShow.bind(this)}></i>
																			<span className='position-absolute cursor-pointer font-weight-bold' style={{ marginLeft: 10, top: 4 }} onClick={this.handleContactUsShow.bind(this)}>Contact</span>
																		</div>
																	</div>
																</article>
															}
															{
																place.properties.organizationName &&
																<article className='margin-bottom-30 border-bottom-1'>
																	<header>
																		<h2 className='margin-bottom-15 text-decoration-underline'>Organization Name</h2>
																	</header>
																	{
																		place.properties.listingLogo !== '' &&
																		<div style={{ maxWidth: "40%", height: 40, maxHeight: 40, marginBottom: 10 }}>
																			<img style={{ height: '100%' }} src={place.properties.listingLogo} />
																		</div>
																	}
																	<p>{place.properties.organizationName !== undefined && place.properties.organizationName !== '' ? place.properties.organizationName : ''}</p>
																</article>
															}
															{
																place.properties.description &&
																<article className='margin-bottom-30 border-bottom-1'>
																	<header>
																		<h2 className='margin-bottom-15 text-decoration-underline'>Description</h2>
																	</header>
																	<p>{place.properties.description.replace(/[^\x00-\x7F]/g, '')}</p>
																</article>
															}
															<Features feature={feature} />
															<article className='padding-top-30 margin-bottom-30 clear-both'>
																<div className='map position-relative'>
																	<div id='map' className='has-parallax'>
																		<Mapbox
																			style={this.state.mapType}
																			onStyleLoad={map => {
																				this.onStyleLoad(map);
																			}}
																			zoom={zoom}
																			onDrag={this.onDrag}
																			center={center}
																			containerStyle={{
																				height: '300px'
																			}}
																		>
																			<ZoomControl />

																			{feature.properties.propertyType === 'Industrial Sites & Properties' &&
																				<Layer
																					type='symbol'
																					id='siteMarker'
																					layout={{
																						'icon-image': 'siteImage',
																						'icon-allow-overlap': false
																					}}
																					images={siteImages}
																				>
																					{feature !== undefined &&
																						<Feature
																							key={feature.rec_id}
																							onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																							onMouseLeave={this.onToggleHover.bind(this, '')}
																							onClick={this.markerClick.bind(this, feature)}
																							coordinates={feature.geometry.coordinates}
																						/>
																					}
																				</Layer>
																			}
																			{feature.properties.propertyType === 'Port Terminals' &&
																				<Layer
																					type='symbol'
																					id='portTerminalMarker'
																					layout={{
																						'icon-image': 'portTerminalImage',
																						'icon-allow-overlap': false
																					}}
																					images={portTerminalImages}
																				>
																					{feature !== undefined &&
																						<Feature
																							key={feature.rec_id}
																							onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																							onMouseLeave={this.onToggleHover.bind(this, '')}
																							coordinates={feature.geometry.coordinates}
																						/>
																					}
																				</Layer>
																			}

																			{feature.properties.propertyType === 'Terminals and Transload Facilities' &&
																				<Layer
																					type='symbol'
																					id='otherTerminalMarker'
																					layout={{
																						'icon-image': 'otherTerminalImage',
																						'icon-allow-overlap': false
																					}}
																					images={otherTerminalImages}
																				>
																					{feature !== undefined &&
																						<Feature
																							key={feature.rec_id}
																							onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																							onMouseLeave={this.onToggleHover.bind(this, '')}
																							coordinates={feature.geometry.coordinates}
																						/>
																					}
																				</Layer>
																			}

																			{feature.properties.propertyType === 'Warehouse/Distribution Center' &&
																				<Layer
																					type='symbol'
																					id='warehouseMarker'
																					layout={{
																						'icon-image': 'warehouseImage',
																						'icon-allow-overlap': false
																					}}
																					images={warehouseImages}
																				>
																					{feature !== undefined &&
																						<Feature
																							key={feature.rec_id}
																							onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																							onMouseLeave={this.onToggleHover.bind(this, '')}
																							coordinates={feature.geometry.coordinates}
																						/>
																					}
																				</Layer>
																			}
																		</Mapbox>
																		<div style={{ position: 'absolute', top: 20, left: 20, right: 20, height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
																			<Button onClick={this.changeMapStyle}>Change style</Button>
																		</div>
																	</div>
																</div>
															</article>
														</section>
													</div>
												</div>
											</section>
										</div>
									</div>
								}

							</section>
							:
							<section className='container'>
								<div style={{ textAlign: 'center', marginTop: 50 }} className='row'>
									<div className="contant_box_404">
										<h3 className="h2">
											Sorry, but this listing no longer exists!
										</h3>

										<p>Check out over 1000+ industrial sites, port, terminals, transload facilities, warehouses, and distribution
											centers <a href="/view-listings" className="link_404">here</a>!</p>


									</div>
								</div>
							</section>

					}


				</div>
				<Footer />
				{place &&
					<Modal dialogClassName='custom-contact-modal-95'
						show={this.state.showContactUs}
						onHide={this.handleContactUsClose}
						animation={false}
					>
						<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
						<div className='container-fluid'>
							<div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
								<div className='col-md-6 col-sm-0 col-xs-0' style={{ backgroundImage: 'url(' + this.getFirstImage(place.properties.image) + ')', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: 'auto' }}>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12' style={{ padding: '3rem' }}>
									<h2 className='text-black font-size-30' style={{ paddingTop: '1rem' }}>Contact us</h2>
									<h4 className='text-black font-size-16'>Enter your contact details and your message will be sent to the contact name and organization name listed.</h4>
									<form className='col-md-12 col-xs-12'>
										<Row className='col-md-12 col-xs-12'>
											<div className='col-md-6 col-sm-12 col-xs-12'>
												<label className='font-weight-bold' htmlFor=''>Contact Name</label>
												<input type='text' className='form-control margin-bottom-5' value={place.properties.contactName} readOnly></input>
											</div>
											<div className='col-md-6 col-sm-12 col-xs-12'>
												<label className='font-weight-bold' htmlFor=''>Organization Name</label>
												<input type='text' className='form-control margin-bottom-5' value={place.properties.organizationName} readOnly></input>
											</div>
											<div className='col-md-6 col-sm-12 col-xs-12'>
												<label className='font-weight-bold' htmlFor=''>Your First Name</label>
												<input type='text' className='form-control margin-bottom-5' onChange={this.setFirstName} value={this.state.firstname}></input>
											</div>
											<div className='col-md-6 col-sm-12 col-xs-12'>
												<label className='font-weight-bold' htmlFor=''>Your Last Name</label>
												<input type='text' className='form-control margin-bottom-5' onChange={this.setLastName} value={this.state.lastname}></input>
											</div>
											<div className='col-md-6 col-sm-12 col-xs-12'>
												<label className='font-weight-bold' htmlFor=''>Your Email</label>
												<input type='email' className='form-control margin-bottom-5' onChange={this.setEmail} value={this.state.email}></input>
											</div>
											<div className='col-md-12 col-sm-12 col-xs-12'>
												<label className='font-weight-bold' htmlFor=''>Your Message</label>
												<textarea className='form-control margin-bottom-5' onChange={this.setMessage} value={this.state.message}></textarea>
											</div>
											<div className='col-md-12 col-sm-12 col-xs-12'>
												<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleContactUs}>Submit</button>
											</div>
										</Row>
									</form>
								</div>
							</div>
						</div>
					</Modal>
				}


				<ConfirmationModal show={this.state.addFavModal} handleClose={this.hideAddFavModal} >
					<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
						<div className='event-type info'>
							<div className='event-indicator '>
								<SVGIcon />
							</div>
						</div>
						<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
							Place added to favorites
						</h2>
						<p className='text-muted font-size-16-imp margin-bottom-0'>
							Place has been added to favorites successfully!
						</p>
					</div>
				</ConfirmationModal>

				<ConfirmationModal show={this.state.deleteFavModal} handleClose={this.hideDeleteFavModal} >
					<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
						<div className='event-type warning'>
							<div className='event-indicator '>
								<SVGErrorIcon />
							</div>
						</div>
						<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
							Place removed from favorites
						</h2>
						<p className='text-muted font-size-16-imp margin-bottom-0'>
							Place has been removed from favorites successfully!
						</p>
					</div>
				</ConfirmationModal>
				<Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body style={{ padding: '2px' }}>

						<div>
							<div style={{ padding: '2px', backgroundColor: 'white' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
										<img src={logo} />
									</div>
									<Tabs defaultActiveKey="Sign Up"  >
										<Tab eventKey="Sign Up" title="Sign Up" style={{ color: "black" }}>
											<>
												<div>
													<div style={{ backgroundColor: '#f8f9fa', paddingTop: '.5rem', borderRadius: 6 }}>

														<form id='slider-subscribe-form' style={{ width: '99%', marginLeft: '0%', paddingBottom: '10px' }} className='nobottommargin reduce-margin-form'>
															<div className='color-black  margin-bottom-20' style={{ color: '#474747', fontSize: 14 }}>Welcome! By registering, you will start with 5 listings to add to our platform! You may also browse our extensive listings. Note that some features will not be available with Basic. Please upgrade at any time to take advantage of all Industrial Navigator has to offer in the admin section. Do you already have an account with us? Click the Login tab above to sign in.</div>

															<div id='regi_errors' className='margin-top-15'></div>

															<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
															</div>
															{this.registerValidator.message('first name', this.state.fname, 'required')}

															<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
															</div>
															{this.registerValidator.message('last name', this.state.lname, 'required')}
															<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
															</div>
															{this.registerValidator.message('company name', this.state.companyName, 'required')}
															<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='email' name='reg_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmailreg.bind(this)} value={this.state.reg_email} />
															</div>
															{this.registerValidator.message('email', this.state.reg_email, 'required')}
															<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
															</div>
															{this.registerValidator.message('password', this.state.password, 'required')}
															<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
															</div>
															{this.registerValidator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
															{/* <ReCAPTCHA
																			ref={recaptchaRef}
																			// size='invisible'
																			className='margin-top-15'
																			onChange={this.onRecaptchaChange.bind(this)}
																			sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
																		/>
																		{this.registerValidator.message('captcha', this.state.reacatpcha_value, 'required')} */}
															<div className='col_full center padding-top-20'>
																<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
															</div>
														</form>
													</div>
												</div>
											</>
										</Tab>
										<Tab eventKey="Login" title="Login" style={{ color: "black" }}>
											<>
												<form id='slider-subscribe-form' style={{ paddingBottom: '10px' }} className='nobottommargin'>
													<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747', marginTop: 20 }}>Enter email address and password to login</div>
													<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
													<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
													<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
														<input type='email' name='login_email' className='form-control input-lg not-dark required email col-lg-12' placeholder='Enter your Email..' onChange={this.setLoginEmail.bind(this)} value={this.state.login_email} />
													</div>
													{this.loginValidator.message('email', this.state.login_email, 'required')}
													<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
														<input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword.bind(this)} value={this.state.login_password} />
													</div>
													{this.loginValidator.message('password', this.state.login_password, 'required')}
													<div className='col_full center padding-top-20'>
														<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin.bind(this)}>Sign In</button>
													</div>
													<div className='col_full center padding-top-20'>
														<span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
													</div>
												</form>
											</>
										</Tab>

									</Tabs>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Registration Successful
							</h2><br />
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Congratulations on creating a Basic account!<br /><br />Now that you have subscribed, you may create and list up 5 properties on our platform for free. You may also browse all listings in our app. Note that some browsing features will not be available with the Basic plan. If you want to experience all of what Industrial Navigator has to offer, please upgrade by clicking on "Admin" -&gt; My Profile. For any questions, please email us at info@industrialnavigator.com, or call +1 (312) 957-7757.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showOTPModal} onHide={this.handleOTPModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5' }}>
						<div>
							<div style={{ padding: '3rem' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto' }}>
										<img src={logo} />
									</div>
									<h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Verify OTP
									</h2>
									<form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
										<div id='otp_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email.</div>
										<div id='otp_success' className='alert alert-success' style={{ display: 'none' }}> Password link should be sent to your email address.</div>
										<InputBoxComponent type='number' min='0' name='otp' onHandleInputChange={this.handleInputChange} />
										{this.OTPValidator.message('otp', this.state.otp, 'required')}
									</form>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPVerification}>Verify</button>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5' }}>
						<div>
							<div style={{ padding: '3rem' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto' }}>
										<img src={logo} />
									</div>
									<h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Forgot Password
									</h2>
									<form style={{ width: '95%', marginLeft: '2%', marginRight: '2%', paddingBottom: 10 }} className='nobottommargin'>
										<div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
										<div id='fp_success' className='alert alert-success' style={{ display: 'none' }}> Password link should be sent to your email address.</div>
										{/* <div className='input-group margin-top-15 col-lg-12'>
											<input type='email' name='fpEmail' className='input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
										</div> */}
										<input type='email' name='fpEmail' className='input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />

										<div className='col_full center padding-top-20'>
											<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Login Successful
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
			</div>
		);
	}
}

export default withRouter(Details);

const ConfirmationModal = ({ handleClose, show, children }) => {
	return (
		<Modal show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
			<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
			<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
				<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
					{children}
				</div>
			</Modal.Body>
			<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
				<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
			</Modal.Footer>
		</Modal>
	);
};

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)

const SVGErrorIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)