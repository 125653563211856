import React from "react";
import axios from 'axios';
import Features from '../Features';

class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            place: '',
            listingName: '',
            ListingAddress: '',
            ListingImage: '',
            ListingsOrgName: '',
            ListingContactName: '',
            ListingContact: '',
            ListingContactEmail: '',
            ListingContactTitle: '',
            companyWebsiteUrl:'',
            ListingsLogo: '',
            ListingDescription: '',
            ListingMajorfeatuers: '',
            ListingSpecifications: '',
            ListingPropertyType: '',
            formattedPrice: ''
        }

    }
    componentDidMount() {

        this.getData(this.props.rec_id);
    }
    getData = async (id) => {
        await axios.post(process.env.REACT_APP_BASE_URL + '/api/get-place-details', {
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            rec_id: id,
        })
            .then(res => {
                // console.log('placedata', res.data.feature)
                const place = res.data.feature;

                // console.log(res.data.feature.properties.image[0].url)
                if (res.status === 200) {
                    var formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 0
                    });
                    let formattedPrice = formatter.format(place.properties.price);

                    this.setState({
                        place: place,
                        listingName: res.data.feature.properties.listingName,
                        ListingAddress: res.data.feature.properties.address,
                        ListingImage: res.data.feature.properties.image[0].url,
                        ListingsOrgName: res.data.feature.properties.organizationName,
                        ListingContactName: res.data.feature.properties.contactName,
                        ListingContact: res.data.feature.properties.contact,
                        ListingContactEmail: res.data.feature.properties.contactEmail,
                        ListingContactTitle: res.data.feature.properties.contactTitle,
                        companyWebsiteUrl:res.data.feature.properties.companyWebsiteUrl,
                        ListingsLogo: res.data.feature.properties.listingLogo,
                        ListingDescription: res.data.feature.properties.description,
                        ListingMajorfeatuers: res.data.feature.properties.majorFeatures,
                        ListingSpecifications: res.data.feature.properties.specifications,
                        ListingPropertyType: res.data.feature.properties.propertyType,
                        formattedPrice: formattedPrice
                    })
                }
            }).catch(err => {
                console.log(err);
            });
    }
    render() {
        const { center, zoom, place, markerPoints } = this.state;
        const feature = place;
        return (
            <div className='padding-bottom-50'>

                <div className="container" id='page-content' style={{ padding: 50 }} >

                    <div style={{ display: 'block', minHeight: 950,padding:50 }}>
                        <b style={{ fontSize: 25 }} className='title-color-pdf'><u>{this.state.listingName}</u></b><br />
                        <p style={{ fontSize: 15 }}>{this.state.ListingAddress}</p>
                        <img style={{ height: 400, width: 600 }} src={this.state.ListingImage} /><br />

                        <h2 style={{marginBottom:15}} className='title-color-pdf'><u>Organization Name</u></h2>
                        <img style={{ height: 70, width: 100 }} src={this.state.ListingsLogo} /><br />
                        <b>{this.state.ListingsOrgName} </b>

                        <h2 style={{marginBottom:10}} className='title-color-pdf'><u>Contact Information</u></h2>
                        <b style={{marginTop:0}}>{this.state.ListingContactName}</b><br />
                        <b>{this.state.ListingContactTitle}</b><br />
                        <b>{this.state.ListingContact}</b><br />
                        <b>{this.state.ListingContactEmail}</b><br />
                        <b>{this.state.companyWebsiteUrl}</b><br />
                        <h2 style={{marginBottom:10}} className='title-color-pdf'><u>Description</u></h2>
                        <p style={{fontSize:15}}>{this.state.ListingDescription}</p>
                    </div>
                    {/* <div style={{ display: 'block', minHeight: 900 }}>
                        <h2 style={{marginBottom:10}} className='title-color-pdf'><u>Description</u></h2>
                        <p>{this.state.ListingDescription}</p>
                    </div> */}
                    {/* <div className='col-lg-8 col-lg-offset-2 temp margin-bottom-20'>
                        {console.log('placefeature',feature)}
                        <Features feature={feature} /> 
                    </div> */}
                    {/* <h4>{this.state.ListingMajorfeatuers}</h4>
                    <h1>Specifications</h1>
                    <h4>{this.state.ListingSpecifications}</h4>
                    <h1>Other Features</h1> */}

                </div>







            </div>
        );
    }
}

export default TableComponent;