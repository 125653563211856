import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesCn extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesCn extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesCn = null;

	constructor(parent: NorthAmericanRailLinesCn, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesCn extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesCn, SNorthAmericanRailLinesCn> {

	static dataSource: string = 'NARL_CN-062mfp';
	static sourceLayer: string = 'NARL_CN';

	constructor(props: PNorthAmericanRailLinesCn) {
		super(props, NorthAmericanRailLinesCn.dataSource, NorthAmericanRailLinesCn.sourceLayer);
		this.state = new SNorthAmericanRailLinesCn(this, NorthAmericanRailLinesCn.dataSource, NorthAmericanRailLinesCn.sourceLayer);
	}
}
