import React, { Component } from 'react';
import '../theme.css';
import { Link } from 'react-router-dom';

const getYearFromDate = () => {
	const date = new Date();
	return date.getFullYear();
}

interface PFooter {
	backgroundColor?: string;
}

class SFooter {
}

class Footer extends Component<PFooter, SFooter> {
	constructor(props: PFooter) {
		super(props);
	}

	render() {
		return (
			<footer className='footer'
				style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : '#008fe4', clear: 'both' }}
			>
				<div
					className='font-Gotham-Pro-Medium'
					style={{ backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : '#008fe4', paddingTop: 30, paddingBottom: 30 }}
				>
					<div className='container-fluid show-inline-footer'>
						<div className='col-lg-4 col-md-4 col-sm-5 col-xs-6 font-size-13' style={{ color: '#FFFFFF' }}>
							<div style={{ paddingTop: 5, paddingBottom: 5 }}>
								© Copyright {getYearFromDate()} Industrial Navigator
							</div>
						</div>
						<div className='font-size-13'
							style={{ color: '#FFFFFF' }}
						>
							<Link target="_blank" style={{ margin: 0, color: "white" }} to={{ pathname: '/terms-and-conditions' }} ><div className='pull-right' style={{ marginLeft: 20, paddingTop: 5, paddingBottom: 5 }}>Terms of Use</div></Link>
							<Link target="_blank" style={{ margin: 0, color: "white" }} to={{ pathname: '/privacy-policy' }} ><div className='pull-right' style={{ marginLeft: 20, paddingTop: 5, paddingBottom: 5 }}>Privacy Policy</div></Link>
							<Link style={{ margin: 0, color: "white" }} to={{ pathname: '/dcma' }} ><div className='pull-right' style={{ paddingTop: 5, paddingBottom: 5 }}>DCMA</div></Link>
						</div>
					</div>

					<div className='container-fluid hide-inline-footer'>
						<div className='col-md-8 font-size-13' style={{ color: '#FFFFFF' }}>
							<div style={{ paddingTop: 5, paddingBottom: 5 }}>
								© Copyright {getYearFromDate()} Industrial Navigator
							</div>
						</div>
						<div className='col-md-8 font-size-13'
							style={{ color: '#FFFFFF' }}
						>
							<Link style={{ margin: 30, color: "white" }} to={{ pathname: '/dcma' }} ><div className='col-md-4' style={{ paddingTop: 5, paddingBottom: 5 }}>DCMA</div></Link>
							<Link target="_blank" style={{ margin: 30, color: "white" }} to={{ pathname: '/terms-and-conditions' }} ><div className='col-md-4' style={{ paddingTop: 5, paddingBottom: 5 }}>Terms of Use</div></Link>
							<Link target="_blank" style={{ margin: 30, color: "white" }} to={{ pathname: '/privacy-policy' }} ><div className='col-md-4' style={{ paddingTop: 5, paddingBottom: 5 }}>Privacy Policy</div></Link>
						</div>
					</div>

				</div>
			</footer>
		)
	}
}

export default Footer;