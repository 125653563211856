import { LabelValue, sortArray, joinFilterV } from './Types';

export const arrShortLines: Array<LabelValue> = [
	{
		'label': 'A&R TERMINAL RAILROAD - (ART)',
		'value': 'A&R Terminal Railroad'
	},
	{
		'label': 'ABERDEEN AND ROCKFISH RAILROAD - (AR)',
		'value': 'Aberdeen and Rockfish Railroad'
	},
	{
		'label': 'ABERDEEN, CAROLINA AND WESTERN RAILWAY',
		'value': 'Aberdeen, Carolina and Western Railway'
	},
	{
		'label': 'ACADIANA RAILWAY - (AKDN)',
		'value': 'Acadiana Railway'
	},
	{
		'label': 'ADAMS-WARNOCK RAILWAY - (AWRY)',
		'value': 'Adams-Warnock Railway'
	},
	{
		'label': 'ADRIAN AND BLISSFIELD RAILROAD - (ADBF)',
		'value': 'Adrian and Blissfield Rail Road'
	},
	{
		'label': 'AFFTON TERMINAL SERVICES RAILROAD',
		'value': 'Affton Terminal Services Railroad'
	},
	{
		'label': 'AG VALLEY RAILROAD - (AVRR)',
		'value': 'Ag Valley Railroad'
	},
	{
		'label': 'AIKEN RAILWAY - (AIKR)',
		'value': 'Aiken Railway'
	},
	{
		'label': 'AIRLAKE TERMINAL RAILWAY - (ALT)',
		'value': 'Airlake Terminal Railway'
	},
	{
		'label': 'AKRON BARBERTON CLUSTER RAILWAY - (AB)',
		'value': 'Akron Barberton Cluster Railway'
	},
	{
		'label': 'ALABAMA AND GULF COAST RAILWAY - (AGR)',
		'value': 'Alabama and Gulf Coast Railway'
	},
	{
		'label': 'ALABAMA AND TENNESSEE RIVER RAILWAY - (ATN)',
		'value': 'Alabama and Tennessee River Railway'
	},
	{
		'label': 'ALABAMA EXPORT RAILROAD - (ALE)',
		'value': 'Alabama Export Railroad'
	},
	{
		'label': 'ALABAMA SOUTHERN RAILROAD - (ABS)',
		'value': 'Alabama Southern Railroad'
	},
	{
		'label': 'ALABAMA WARRIOR RAILWAY - (ABWR)',
		'value': 'Alabama Warrior Railway'
	},
	{
		'label': 'ALAMO GULF COAST RAILROAD - (AGCR)',
		'value': 'Alamo Gulf Coast Railroad'
	},
	{
		'label': 'ALAMO NORTH TEXAS RAILROAD',
		'value': 'Alamo North Texas Railroad'
	},
	{
		'label': 'ALASKA RAILROAD - (ARR)',
		'value': 'Alaska Railroad'
	},
	{
		'label': 'ALBANY AND EASTERN RAILROAD - (AERC)',
		'value': 'Albany and Eastern Railroad'
	},
	{
		'label': 'ALBANY PORT RAILROAD - (APRR)',
		'value': 'Albany Port Railroad'
	},
	{
		'label': 'ALEXANDER RAILROAD - (ARC)',
		'value': 'Alexander Railroad'
	},
	{
		'label': 'ALIQUIPPA AND OHIO RIVER RAILROAD - (AOR)',
		'value': 'Aliquippa and Ohio River Railroad'
	},
	{
		'label': 'ALLEGHENY VALLEY RAILROAD - (AVR)',
		'value': 'Allegheny Valley Railroad'
	},
	{
		'label': 'ALLENTOWN AND AUBURN RAILROAD - (ALLN)',
		'value': 'Allentown and Auburn Railroad'
	},
	{
		'label': 'ALTON AND SOUTHERN RAILWAY - (ALS)',
		'value': 'Alton and Southern Railway'
	},
	{
		'label': 'AN RAILWAY - (ARCX)',
		'value': 'AN Railway'
	},
	{
		'label': 'ANGELINA AND NECHES RIVER RAILROAD - (ANR)',
		'value': 'Angelina and Neches River Railroad'
	},
	{
		'label': 'ANN ARBOR RAILROAD - (AA)',
		'value': 'Ann Arbor Railroad'
	},
	{
		'label': 'APACHE RAILWAY - (APA)',
		'value': 'Apache Railway'
	},
	{
		'label': 'APPALACHIAN AND OHIO RAILROAD - (AO)',
		'value': 'Appalachian and Ohio Railroad'
	},
	{
		'label': 'ARCADE AND ATTICA RAILROAD - (ARA)',
		'value': 'Arcade and Attica Railroad'
	},
	{
		'label': 'ARIZONA AND CALIFORNIA RAILROAD - (ARZC)',
		'value': 'Arizona and California Railroad'
	},
	{
		'label': 'ARIZONA CENTRAL RAILROAD - (AZCR)',
		'value': 'Arizona Central Railroad'
	},
	{
		'label': 'ARIZONA EASTERN RAILWAY',
		'value': 'Arizona Eastern Railway'
	},
	{
		'label': 'ARKANSAS–OKLAHOMA RAILROAD',
		'value': 'Arkansas–Oklahoma Railroad'
	},
	{
		'label': 'ARKANSAS AND MISSOURI RAILROAD - (AM)',
		'value': 'Arkansas and Missouri Railroad'
	},
	{
		'label': 'ARKANSAS MIDLAND RAILROAD - (AKMD)',
		'value': 'Arkansas Midland Railroad'
	},
	{
		'label': 'ARKANSAS SOUTHERN RAILROAD - (ARS)',
		'value': 'Arkansas Southern Railroad'
	},
	{
		'label': 'ARKANSAS, LOUISIANA AND MISSISSIPPI RAILROAD',
		'value': 'Arkansas, Louisiana and Mississippi Railroad'
	},
	{
		'label': 'ASHLAND RAILWAY - (ASRY)',
		'value': 'Ashland Railway'
	},
	{
		'label': 'ASHTABULA, CARSON AND JEFFERSON RAILROAD - (ACJR)',
		'value': 'Ashtabula, Carson and Jefferson Railroad'
	},
	{
		'label': 'AT&L RAILROAD',
		'value': 'AT&L Railroad'
	},
	{
		'label': 'ATLANTIC AND WESTERN RAILWAY - (ATW)',
		'value': 'Atlantic and Western Railway'
	},
	{
		'label': 'AUSTIN WESTERN RAILROAD - (AWRR)',
		'value': 'Austin Western Railroad'
	},
	{
		'label': 'AUTAUGA NORTHERN RAILROAD - (AUT)',
		'value': 'Autauga Northern Railroad'
	},
	{
		'label': 'B&H RAIL CORPORATION',
		'value': 'B&H Rail Corporation'
	},
	{
		'label': 'BAJA CALIFORNIA RAILROAD - (BJRR)',
		'value': 'Baja California Railroad'
	},
	{
		'label': 'BALDWIN CITY & SOUTHERN RAILROAD - (BCSR)',
		'value': 'Baldwin City & Southern Railroad'
	},
	{
		'label': 'BALLARD TERMINAL RAILROAD - (BDTL)',
		'value': 'Ballard Terminal Railroad'
	},
	{
		'label': 'BATON ROUGE SOUTHERN RAILROAD - (BRS)',
		'value': 'Baton Rouge Southern Railroad'
	},
	{
		'label': 'BATTEN KILL RAILROAD - (BKRR)',
		'value': 'Batten Kill Railroad'
	},
	{
		'label': 'BAUXITE AND NORTHERN RAILWAY - (BXN)',
		'value': 'Bauxite and Northern Railway'
	},
	{
		'label': 'BAY COLONY RAILROAD - (BCLR)',
		'value': 'Bay Colony Railroad'
	},
	{
		'label': 'BAY LINE RAILROAD - (BAYL)',
		'value': 'Bay Line Railroad'
	},
	{
		'label': 'BAYWAY TERMINAL SWITCHING COMPANY',
		'value': 'Bayway Terminal Switching Company'
	},
	{
		'label': 'BEECH MOUNTAIN RAILROAD - (BEEM)',
		'value': 'Beech Mountain Railroad'
	},
	{
		'label': 'BEE LINE RAILROAD - (BLRR)',
		'value': 'Bee Line Railroad'
	},
	{
		'label': 'BELLINGHAM INTERNATIONAL RAILROAD - (BIRR)',
		'value': 'Bellingham International Railroad'
	},
	{
		'label': 'BELT RAILWAY OF CHICAGO',
		'value': 'Belt Railway of Chicago'
	},
	{
		'label': 'BELVIDERE AND DELAWARE RIVER RAILWAY',
		'value': 'Belvidere and Delaware River Railway'
	},
	{
		'label': 'BESSEMER AND LAKE ERIE RAILROAD - (BLE)',
		'value': 'Bessemer and Lake Erie Railroad'
	},
	{
		'label': 'BG&CM RAILROAD - (BGCM)',
		'value': 'BG&CM Railroad'
	},
	{
		'label': 'BIG 4 TERMINAL RAILROAD',
		'value': 'Big 4 Terminal Railroad'
	},
	{
		'label': 'BIG SPRING RAIL SYSTEM - (BSR)',
		'value': 'Big Spring Rail System'
	},
	{
		'label': 'BIGHORN DIVIDE AND WYOMING RAILROAD - (BDW)',
		'value': 'Bighorn Divide and Wyoming Railroad'
	},
	{
		'label': 'BIRMINGHAM TERMINAL RAILWAY - (BHRR)',
		'value': 'Birmingham Terminal Railway'
	},
	{
		'label': 'BI-STATE DEVELOPMENT AGENCY - (BSDA)',
		'value': 'Bi-State Development Agency'
	},
	{
		'label': 'BLACK RIVER AND WESTERN CORPORATION',
		'value': 'Black River and Western Corporation'
	},
	{
		'label': 'BLACKLANDS RAILROAD - (BLR)',
		'value': 'Blacklands Railroad'
	},
	{
		'label': 'BLACKWELL NORTHERN GATEWAY RAILROAD - (BNG)',
		'value': 'Blackwell Northern Gateway Railroad'
	},
	{
		'label': 'BLOOMER LINE',
		'value': 'Bloomer Line'
	},
	{
		'label': 'BLUE RAPIDS RAILWAY - (BRRX)',
		'value': 'Blue Rapids Railway'
	},
	{
		'label': 'BLUE RIDGE SOUTHERN RAILROAD - (BLU)',
		'value': 'Blue Ridge Southern Railroad'
	},
	{
		'label': 'BNSF RAILWAY - (BNSF)',
		'value': 'BNSF Railway'
	},
	{
		'label': 'BOGALUSA BAYOU RAILROAD - (BBAY)',
		'value': 'Bogalusa Bayou Railroad'
	},
	{
		'label': 'BOISE VALLEY RAILROAD - (BVRR)',
		'value': 'Boise Valley Railroad'
	},
	{
		'label': 'BOONE AND SCENIC VALLEY RAILROAD',
		'value': 'Boone and Scenic Valley Railroad'
	},
	{
		'label': 'BORDER TRANSLOAD & TRANSFER',
		'value': 'Border Transload & Transfer'
	},
	{
		'label': 'BORDER PACIFIC RAILROAD - (BOP)',
		'value': 'Border Pacific Railroad'
	},
	{
		'label': 'BRANDYWINE VALLEY RAILROAD - (BVRY)',
		'value': 'Brandywine Valley Railroad'
	},
	{
		'label': 'BRANFORD STEAM RAILROAD - (BRFD)',
		'value': 'Branford Steam Railroad'
	},
	{
		'label': 'BROOKHAVEN RAIL - (BHR)',
		'value': 'Brookhaven Rail'
	},
	{
		'label': 'BROWNSVILLE AND RIO GRANDE INTERNATIONAL RAILROAD - (BRG)',
		'value': 'Brownsville and Rio Grande International Railroad'
	},
	{
		'label': 'BUCKINGHAM BRANCH RAILROAD - (BB)',
		'value': 'Buckingham Branch Railroad'
	},
	{
		'label': 'BUCYRUS INDUSTRIAL RAILROAD - (BIRL)',
		'value': 'Bucyrus Industrial Railroad'
	},
	{
		'label': 'BUFFALO AND PITTSBURGH RAILROAD - (BPRR)',
		'value': 'Buffalo and Pittsburgh Railroad'
	},
	{
		'label': 'BUFFALO SOUTHERN RAILROAD - (BSOR)',
		'value': 'Buffalo Southern Railroad'
	},
	{
		'label': 'BURLINGTON JUNCTION RAILWAY - (BJRY)',
		'value': 'Burlington Junction Railway'
	},
	{
		'label': 'BUTTE, ANACONDA AND PACIFIC RAILWAY',
		'value': 'Butte, Anaconda and Pacific Railway'
	},
	{
		'label': 'C&NC RAILROAD - (CNUR)',
		'value': 'C&NC Railroad'
	},
	{
		'label': 'CALDWELL COUNTY RAILROAD - (CWCY)',
		'value': 'Caldwell County Railroad'
	},
	{
		'label': 'CALIFORNIA NORTHERN RAILROAD - (CFNR)',
		'value': 'California Northern Railroad'
	},
	{
		'label': 'CAMDEN & SOUTHERN RAILROAD - (CAMD)',
		'value': 'Camden & Southern Railroad'
	},
	{
		'label': 'CAMP CHASE RAILWAY - (CAMY)',
		'value': 'Camp Chase Railway'
	},
	{
		'label': 'CANADIAN NATIONAL RAILWAY - (CNN)',
		'value': 'Canadian National Railway'
	},
	{
		'label': 'CANADIAN PACIFIC RAILWAY - (CPVM)',
		'value': 'Canadian Pacific Railway'
	},
	{
		'label': 'CANEY FORK AND WESTERN RAILROAD - (CFWR)',
		'value': 'Caney Fork and Western Railroad'
	},
	{
		'label': 'CANTON RAILROAD - (CTN)',
		'value': 'Canton Railroad'
	},
	{
		'label': 'CAPE FEAR RAILWAYS - (CFR)',
		'value': 'Cape Fear Railways'
	},
	{
		'label': 'CAPE MAY SEASHORE LINES - (CMSL)',
		'value': 'Cape May Seashore Lines'
	},
	{
		'label': 'CAROLINA COASTAL RAILWAY - (CLNA)',
		'value': 'Carolina Coastal Railway'
	},
	{
		'label': 'CAROLINA PIEDMONT RAILROAD',
		'value': 'Carolina Piedmont Railroad'
	},
	{
		'label': 'CASCADE AND COLUMBIA RIVER RAILROAD - (CSCD)',
		'value': 'Cascade and Columbia River Railroad'
	},
	{
		'label': 'CATERPARROTT RAILNET',
		'value': 'CaterParrott Railnet'
	},
	{
		'label': 'CBEC RAILROAD',
		'value': 'CBEC Railroad'
	},
	{
		'label': 'CEDAR RAPIDS AND IOWA CITY RAILWAY - (CIC)',
		'value': 'Cedar Rapids and Iowa City Railway'
	},
	{
		'label': 'CENTRAL CALIFORNIA TRACTION COMPANY - (CCT)',
		'value': 'Central California Traction Company'
	},
	{
		'label': 'CENTRAL INDIANA AND WESTERN RAILROAD - (CEIW)',
		'value': 'Central Indiana and Western Railroad'
	},
	{
		'label': 'CENTRAL MAINE AND QUEBEC RAILWAY - (CMQX)',
		'value': 'Central Maine and Quebec Railway'
	},
	{
		'label': 'CENTRAL MIDLAND RAILWAY - (CMR)',
		'value': 'Central Midland Railway'
	},
	{
		'label': 'CENTRAL MONTANA RAIL, INC.',
		'value': 'Central Montana Rail, Inc.'
	},
	{
		'label': 'CENTRAL NEW ENGLAND RAILROAD - (CNZR)',
		'value': 'Central New England Railroad'
	},
	{
		'label': 'CENTRAL NEW YORK RAILROAD - (CNYK)',
		'value': 'Central New York Railroad'
	},
	{
		'label': 'CENTRAL OREGON AND PACIFIC RAILROAD - (CORP)',
		'value': 'Central Oregon and Pacific Railroad'
	},
	{
		'label': 'CENTRAL RAILROAD OF INDIANA - (CIND)',
		'value': 'Central Railroad of Indiana'
	},
	{
		'label': 'CENTRAL RAILROAD OF INDIANAPOLIS',
		'value': 'Central Railroad of Indianapolis'
	},
	{
		'label': 'CENTRAL WASHINGTON RAILROAD - (CWA)',
		'value': 'Central Washington Railroad'
	},
	{
		'label': 'CG RAILWAY',
		'value': 'CG Railway'
	},
	{
		'label': 'CHARLOTTE SOUTHERN RAILROAD - (CHS)',
		'value': 'Charlotte Southern Railroad'
	},
	{
		'label': 'CHATTAHOOCHEE INDUSTRIAL RAILROAD - (CIRR)',
		'value': 'Chattahoochee Industrial Railroad'
	},
	{
		'label': 'CHATTOOGA AND CHICKAMAUGA RAILWAY',
		'value': 'Chattooga and Chickamauga Railway'
	},
	{
		'label': 'CHESAPEAKE AND ALBEMARLE RAILROAD - (CA)',
		'value': 'Chesapeake and Albemarle Railroad'
	},
	{
		'label': 'CHESAPEAKE AND INDIANA RAILROAD - (CKIN)',
		'value': 'Chesapeake and Indiana Railroad'
	},
	{
		'label': 'CHESSIE LOGISTICS - (CLCY)',
		'value': 'Chessie Logistics'
	},
	{
		'label': 'CHESTNUT RIDGE RAILROAD',
		'value': 'Chestnut Ridge Railroad'
	},
	{
		'label': 'CHICAGO–CHEMUNG RAILROAD',
		'value': 'Chicago–Chemung Railroad'
	},
	{
		'label': 'CHICAGO HEIGHTS TERMINAL TRANSFER - (CHTT)',
		'value': 'Chicago Heights Terminal Transfer'
	},
	{
		'label': 'CHICAGO JUNCTION RAILWAY - (CJR)',
		'value': 'Chicago Junction Railway'
	},
	{
		'label': 'CHICAGO PORT & RAIL',
		'value': 'Chicago Port & Rail'
	},
	{
		'label': 'CHICAGO PORT RAILROAD - (CPC)',
		'value': 'Chicago Port Railroad'
	},
	{
		'label': 'CHICAGO RAIL LINK - (CRL)',
		'value': 'Chicago Rail Link'
	},
	{
		'label': 'CHICAGO SOUTH SHORE AND SOUTH BEND RAILROAD',
		'value': 'Chicago South Shore and South Bend Railroad'
	},
	{
		'label': 'CHICAGO, FORT WAYNE AND EASTERN RAILROAD',
		'value': 'Chicago, Fort Wayne and Eastern Railroad'
	},
	{
		'label': 'CHICAGO, ST. PAUL & PACIFIC RAILROAD - (CSPX)',
		'value': 'Chicago, St. Paul & Pacific Railroad'
	},
	{
		'label': 'CICERO CENTRAL RAILROAD - (CERR)',
		'value': 'Cicero Central Railroad'
	},
	{
		'label': 'CIMARRON VALLEY RAILROAD - (CVR)',
		'value': 'Cimarron Valley Railroad'
	},
	{
		'label': 'CINCINNATI EASTERN RAILROAD',
		'value': 'Cincinnati Eastern Railroad'
	},
	{
		'label': 'CITY OF PRINEVILLE RAILWAY - (COP)',
		'value': 'City of Prineville Railway'
	},
	{
		'label': 'CLACKAMAS VALLEY RAILROAD',
		'value': 'Clackamas Valley Railroad'
	},
	{
		'label': 'CLARENDON AND PITTSFORD RAILROAD - (CLP)',
		'value': 'Clarendon and Pittsford Railroad'
	},
	{
		'label': 'CLEVELAND AND CUYAHOGA RAILROAD',
		'value': 'Cleveland and Cuyahoga Railroad'
	},
	{
		'label': 'CLEVELAND PORT RAILROAD',
		'value': 'Cleveland Port Railroad'
	},
	{
		'label': 'CLEVELAND WORKS RAILWAY - (CWRO)',
		'value': 'Cleveland Works Railway'
	},
	{
		'label': 'CLINTON TERMINAL RAILROAD - (CTR)',
		'value': 'Clinton Terminal Railroad'
	},
	{
		'label': 'CLOQUET TERMINAL RAILROAD - (CTRR)',
		'value': 'Cloquet Terminal Railroad'
	},
	{
		'label': 'CMC RAILROAD - (CMC)',
		'value': 'CMC Railroad'
	},
	{
		'label': 'COFFEEN AND WESTERN RAILROAD - (CAEG)',
		'value': 'Coffeen and Western Railroad'
	},
	{
		'label': 'COLORADO AND WYOMING RAILWAY - (CW)',
		'value': 'Colorado and Wyoming Railway'
	},
	{
		'label': 'COLUMBIA AND COWLITZ RAILWAY - (CLC)',
		'value': 'Columbia and Cowlitz Railway'
	},
	{
		'label': 'COLUMBIA BASIN RAILROAD - (CBRW)',
		'value': 'Columbia Basin Railroad'
	},
	{
		'label': 'COLUMBIA BUSINESS CENTER RAILROAD',
		'value': 'Columbia Business Center Railroad'
	},
	{
		'label': 'COLUMBIA TERMINAL RAILROAD - (CT)',
		'value': 'Columbia Terminal Railroad'
	},
	{
		'label': 'COLUMBIA & WALLA WALLA RAILWAY',
		'value': 'Columbia & Walla Walla Railway'
	},
	{
		'label': 'COLUMBUS AND CHATTAHOOCHEE RAILROAD - (CCHA)',
		'value': 'Columbus and Chattahoochee Railroad'
	},
	{
		'label': 'COLUMBUS AND GREENVILLE RAILWAY - (CAGY)',
		'value': 'Columbus and Greenville Railway'
	},
	{
		'label': 'COLUMBUS AND OHIO RIVER RAILROAD - (CUOH)',
		'value': 'Columbus and Ohio River Rail Road'
	},
	{
		'label': 'COMMONWEALTH RAILWAY - (CWRY)',
		'value': 'Commonwealth Railway'
	},
	{
		'label': 'CONECUH VALLEY RAILROAD - (COEH)',
		'value': 'Conecuh Valley Railroad'
	},
	{
		'label': 'CONNECTICUT SOUTHERN RAILROAD - (CSO)',
		'value': 'Connecticut Southern Railroad'
	},
	{
		'label': 'COOPERSVILLE AND MARNE RAILWAY',
		'value': 'Coopersville and Marne Railway'
	},
	{
		'label': 'COOS BAY RAIL LINK',
		'value': 'Coos Bay Rail Link'
	},
	{
		'label': 'COPPER BASIN RAILWAY - (CBRY)',
		'value': 'Copper Basin Railway'
	},
	{
		'label': 'CORPUS CHRISTI TERMINAL RAILROAD - (CCPN)',
		'value': 'Corpus Christi Terminal Railroad'
	},
	{
		'label': 'CRAB ORCHARD AND EGYPTIAN RAILROAD',
		'value': 'Crab Orchard and Egyptian Railroad'
	},
	{
		'label': 'CSX TRANSPORTATION - (CSX)',
		'value': 'CSX Transportation'
	},
	{
		'label': 'DAKOTA & IOWA RAILROAD - (DI)',
		'value': 'Dakota & Iowa Railroad'
	},
	{
		'label': 'D&W RAILROAD',
		'value': 'D&W Railroad'
	},
	{
		'label': 'DAKOTA NORTHERN RAILROAD - (DN)',
		'value': 'Dakota Northern Railroad'
	},
	{
		'label': 'DAKOTA SOUTHERN RAILWAY - (DSRC)',
		'value': 'Dakota Southern Railway'
	},
	{
		'label': 'DAKOTA, MISSOURI VALLEY AND WESTERN RAILROAD - (DMVW)',
		'value': 'Dakota, Missouri Valley and Western Railroad'
	},
	{
		'label': 'DALLAS, GARLAND AND NORTHEASTERN RAILROAD - (DGNO)',
		'value': 'Dallas, Garland and Northeastern Railroad'
	},
	{
		'label': 'DARDANELLE AND RUSSELLVILLE RAILROAD - (DR)',
		'value': 'Dardanelle and Russellville Railroad'
	},
	{
		'label': 'DE QUEEN AND EASTERN RAILROAD',
		'value': 'De Queen and Eastern Railroad'
	},
	{
		'label': 'DECATUR CENTRAL RAILROAD',
		'value': 'Decatur Central Railroad'
	},
	{
		'label': 'DECATUR & EASTERN ILLINOIS RAILROAD - (DREI)',
		'value': 'Decatur & Eastern Illinois Railroad'
	},
	{
		'label': 'DECATUR JUNCTION RAILWAY - (DT)',
		'value': 'Decatur Junction Railway'
	},
	{
		'label': 'DELAWARE–LACKAWANNA RAILROAD',
		'value': 'Delaware–Lackawanna Railroad'
	},
	{
		'label': 'DELRAY CONNECTING RAILROAD - (DC)',
		'value': 'Delray Connecting Railroad'
	},
	{
		'label': 'DELMARVA CENTRAL RAILROAD - (DCR)',
		'value': 'Delmarva Central Railroad'
	},
	{
		'label': 'DELTA SOUTHERN RAILROAD - (DSRR)',
		'value': 'Delta Southern Railroad'
	},
	{
		'label': 'DELTA VALLEY AND SOUTHERN RAILWAY - (DVS)',
		'value': 'Delta Valley and Southern Railway'
	},
	{
		'label': 'DENVER ROCK ISLAND RAILROAD - (DRIR)',
		'value': 'Denver Rock Island Railroad'
	},
	{
		'label': 'DEPEW, LANCASTER AND WESTERN RAILROAD - (DLWR)',
		'value': 'Depew, Lancaster and Western Railroad'
	},
	{
		'label': 'DESERET POWER RAILROAD',
		'value': 'Deseret Power Railroad'
	},
	{
		'label': 'DETROIT CONNECTING RAILROAD - (DCON)',
		'value': 'Detroit Connecting Railroad'
	},
	{
		'label': 'DOVER AND DELAWARE RIVER RAILROAD',
		'value': 'Dover and Delaware River Railroad'
	},
	{
		'label': 'DOVER & ROCKAWAY RIVER RAILROAD - (DRRV)',
		'value': 'Dover & Rockaway River Railroad'
	},
	{
		'label': 'DUBOIS COUNTY RAILROAD - (DCRR)',
		'value': 'Dubois County Railroad'
	},
	{
		'label': 'EAST BROOKFIELD AND SPENCER RAILROAD - (EBSR)',
		'value': 'East Brookfield and Spencer Railroad'
	},
	{
		'label': 'EAST CAMDEN AND HIGHLAND RAILROAD - (EACH)',
		'value': 'East Camden and Highland Railroad'
	},
	{
		'label': 'EAST COOPER AND BERKELEY RAILROAD - (ECBR)',
		'value': 'East Cooper and Berkeley Railroad'
	},
	{
		'label': 'EAST ERIE COMMERCIAL RAILROAD - (EEC)',
		'value': 'East Erie Commercial Railroad'
	},
	{
		'label': 'EAST JERSEY RAILROAD - (EJR)',
		'value': 'East Jersey Railroad'
	},
	{
		'label': 'EAST PENN RAILROAD - (ESPN)',
		'value': 'East Penn Railroad'
	},
	{
		'label': 'EAST TENNESSEE RAILWAY - (ETRY)',
		'value': 'East Tennessee Railway'
	},
	{
		'label': 'EAST TROY ELECTRIC RAILROAD',
		'value': 'East Troy Electric Railroad'
	},
	{
		'label': 'EASTERN ALABAMA RAILWAY - (EARY)',
		'value': 'Eastern Alabama Railway'
	},
	{
		'label': 'EASTERN IDAHO RAILROAD - (EIRR)',
		'value': 'Eastern Idaho Railroad'
	},
	{
		'label': 'EASTERN ILLINOIS RAILROAD - (DREI)',
		'value': 'Eastern Illinois Railroad'
	},
	{
		'label': 'EASTERN KENTUCKY RAILWAY COMPANY',
		'value': 'Eastern Kentucky Railway Company'
	},
	{
		'label': 'EASTERN MAINE RAILWAY',
		'value': 'Eastern Maine Railway'
	},
	{
		'label': 'EFFINGHAM RAILROAD - (EFRR)',
		'value': 'Effingham Railroad'
	},
	{
		'label': 'EL DORADO AND WESSON RAILWAY - (EDW)',
		'value': 'El Dorado and Wesson Railway'
	},
	{
		'label': 'ELIZABETHTOWN INDUSTRIAL RAILROAD - (EZR)',
		'value': 'Elizabethtown Industrial Railroad'
	},
	{
		'label': 'ELK RIVER RAILROAD - (ELKR)',
		'value': 'Elk River Railroad'
	},
	{
		'label': 'ELKHART AND WESTERN RAILROAD - (EWR)',
		'value': 'Elkhart and Western Railroad'
	},
	{
		'label': 'ELLIS AND EASTERN COMPANY - (EERZ)',
		'value': 'Ellis and Eastern Company'
	},
	{
		'label': 'ESCALANTE WESTERN RAILWAY - (ESWR)',
		'value': 'Escalante Western Railway'
	},
	{
		'label': 'ESCANABA AND LAKE SUPERIOR RAILROAD - (ELS)',
		'value': 'Escanaba and Lake Superior Railroad'
	},
	{
		'label': 'EVANSVILLE WESTERN RAILWAY - (EVWR)',
		'value': 'Evansville Western Railway'
	},
	{
		'label': 'EVERETT RAILROAD - (EV)',
		'value': 'Everett Railroad'
	},
	{
		'label': 'FAIRFIELD SOUTHERN COMPANY',
		'value': 'Fairfield Southern Company'
	},
	{
		'label': 'FALLS ROAD RAILROAD - (FRR)',
		'value': 'Falls Road Railroad'
	},
	{
		'label': 'FARMRAIL CORPORATION - (FMRC)',
		'value': 'Farmrail Corporation'
	},
	{
		'label': 'FFG&C RAILROAD',
		'value': 'FFG&C Railroad'
	},
	{
		'label': 'FINGER LAKES RAILWAY - (FGLK)',
		'value': 'Finger Lakes Railway'
	},
	{
		'label': 'FIRST COAST RAILROAD - (FCRD)',
		'value': 'First Coast Railroad'
	},
	{
		'label': 'FLATS INDUSTRIAL RAILROAD - (FIR)',
		'value': 'Flats Industrial Railroad'
	},
	{
		'label': 'FLORIDA CENTRAL RAILROAD - (FCEN)',
		'value': 'Florida Central Railroad'
	},
	{
		'label': 'FLORIDA EAST COAST RAILWAY - (FEC)',
		'value': 'Florida East Coast Railway'
	},
	{
		'label': 'FLORIDA, GULF AND ATLANTIC RAILROAD',
		'value': 'Florida, Gulf and Atlantic Railroad'
	},
	{
		'label': 'FLORIDA MIDLAND RAILROAD - (FMID)',
		'value': 'Florida Midland Railroad'
	},
	{
		'label': 'FLORIDA NORTHERN RAILROAD - (FNOR)',
		'value': 'Florida Northern Railroad'
	},
	{
		'label': 'FORDYCE AND PRINCETON RAILROAD - (FP)',
		'value': 'Fordyce and Princeton Railroad'
	},
	{
		'label': 'FORE RIVER TRANSPORTATION CORPORATION - (FRVT)',
		'value': 'Fore River Transportation Corporation'
	},
	{
		'label': 'FORT SMITH RAILROAD - (FSR)',
		'value': 'Fort Smith Railroad'
	},
	{
		'label': 'FORT WORTH AND WESTERN RAILROAD - (FWWR)',
		'value': 'Fort Worth and Western Railroad'
	},
	{
		'label': 'FOXVILLE AND NORTHERN RAILROAD - (FN)',
		'value': 'Foxville and Northern Railroad'
	},
	{
		'label': 'FREDONIA VALLEY RAILROAD - (FVRR)',
		'value': 'Fredonia Valley Railroad'
	},
	{
		'label': 'FREIGHTCAR SHORT LINE',
		'value': 'FreightCar Short Line'
	},
	{
		'label': 'FTRL RAILWAY',
		'value': 'FTRL Railway'
	},
	{
		'label': 'FULTON COUNTY RAILROAD - (FC)',
		'value': 'Fulton County Railroad'
	},
	{
		'label': 'FULTON COUNTY RAILWAY - (FCR)',
		'value': 'Fulton County Railway'
	},
	{
		'label': 'GALVESTON RAILROAD - (GRRM)',
		'value': 'Galveston Railroad'
	},
	{
		'label': 'GARDEN CITY WESTERN RAILWAY',
		'value': 'Garden City Western Railway'
	},
	{
		'label': 'GARDENDALE RAILROAD - (GRD)',
		'value': 'Gardendale Railroad'
	},
	{
		'label': 'GARY RAILWAY - (GRW)',
		'value': 'Gary Railway'
	},
	{
		'label': 'GATEWAY EASTERN RAILWAY',
		'value': 'Gateway Eastern Railway'
	},
	{
		'label': 'GATEWAY INDUSTRIAL RAILROAD',
		'value': 'Gateway Industrial Railroad'
	},
	{
		'label': 'GEAUX GEAUX RAILROAD - (GOGR)',
		'value': 'Geaux Geaux Railroad'
	},
	{
		'label': 'GEORGES CREEK RAILWAY - (GCK)',
		'value': 'Georges Creek Railway'
	},
	{
		'label': 'GEORGETOWN RAILROAD - (GRR)',
		'value': 'Georgetown Railroad'
	},
	{
		'label': 'GEORGIA AND FLORIDA RAILWAY - (GFRR)',
		'value': 'Georgia and Florida Railway'
	},
	{
		'label': 'GEORGIA CENTRAL RAILWAY - (GC)',
		'value': 'Georgia Central Railway'
	},
	{
		'label': 'GEORGIA NORTHEASTERN RAILROAD - (GNRR)',
		'value': 'Georgia Northeastern Railroad'
	},
	{
		'label': 'GEORGIA SOUTHERN RAILWAY - (GS)',
		'value': 'Georgia Southern Railway'
	},
	{
		'label': 'GEORGIA SOUTHWESTERN RAILROAD - (GSWR)',
		'value': 'Georgia Southwestern Railroad'
	},
	{
		'label': 'GEORGIA WOODLANDS RAILROAD - (GWRC)',
		'value': 'Georgia Woodlands Railroad'
	},
	{
		'label': 'GETTYSBURG AND NORTHERN RAILROAD - (GET)',
		'value': 'Gettysburg and Northern Railroad'
	},
	{
		'label': 'GLOBAL CONTAINER TERMINALS NEW YORK',
		'value': 'Global Container Terminals New York'
	},
	{
		'label': 'GOLDEN ISLES TERMINAL RAILROAD - (GITM)',
		'value': 'Golden Isles Terminal Railroad'
	},
	{
		'label': 'GOLDEN TRIANGLE RAILROAD - (GTRA)',
		'value': 'Golden Triangle Railroad'
	},
	{
		'label': 'GOOSE LAKE RAILWAY - (GOOS)',
		'value': 'Goose Lake Railway'
	},
	{
		'label': 'GRAFTON AND UPTON RAILROAD - (GU)',
		'value': 'Grafton and Upton Railroad'
	},
	{
		'label': 'GRAINBELT CORPORATION - (GNBC)',
		'value': 'Grainbelt Corporation'
	},
	{
		'label': 'GRAND ELK RAILROAD - (GDLK)',
		'value': 'Grand Elk Railroad'
	},
	{
		'label': 'GRAND RAPIDS EASTERN RAILROAD - (GR)',
		'value': 'Grand Rapids Eastern Railroad'
	},
	{
		'label': 'GRAND RIVER RAILWAY - (GRRW)',
		'value': 'Grand River Railway'
	},
	{
		'label': 'GREAT LAKES CENTRAL RAILROAD - (GLC)',
		'value': 'Great Lakes Central Railroad'
	},
	{
		'label': 'GREAT NORTHWEST RAILROAD - (GRNW)',
		'value': 'Great Northwest Railroad'
	},
	{
		'label': 'GREAT WALTON RAILROAD - (GRWR)',
		'value': 'Great Walton Railroad'
	},
	{
		'label': 'GREAT WESTERN RAILWAY OF COLORADO - (GWR)',
		'value': 'Great Western Railway of Colorado'
	},
	{
		'label': 'CENTRAL TEXAS & COLORADO RIVER - (CTXR)',
		'value': 'Central Texas & Colorado River'
	},
	{
		'label': 'CARLTON TRAIN RAILWAY COMPANY - (CTRW)',
		'value': 'Carlton Train Railway Company'
	},
	{
		'label': 'KETTLE FALLS INTERNATIONAL RAILWAY - (KFR)',
		'value': 'Kettle Falls International Railway'
	},
	{
		'label': 'SAVANNAH INDUSTRIAL TRANSPORTATON - (SIT)',
		'value': 'Savannah Industrial Transportaton'
	},
	{
		'label': 'GREEN MOUNTAIN RAILROAD - (GMRC)',
		'value': 'Green Mountain Railroad'
	},
	{
		'label': 'GREENVILLE AND WESTERN RAILWAY - (GRLW)',
		'value': 'Greenville and Western Railway'
	},
	{
		'label': 'GRENADA RAILWAY',
		'value': 'Grenada Railway'
	},
	{
		'label': 'GULF COAST SWITCHING - (GCSC)',
		'value': 'Gulf Coast Switching'
	},
	{
		'label': 'HAINESPORT SECONDARY RAILROAD',
		'value': 'Hainesport Secondary Railroad'
	},
	{
		'label': 'HAMPTON AND BRANCHVILLE RAILROAD - (HB)',
		'value': 'Hampton and Branchville Railroad'
	},
	{
		'label': 'HARTWELL RAILROAD - (HRT)',
		'value': 'Hartwell Railroad'
	},
	{
		'label': 'HEART OF GEORGIA RAILROAD - (HOG)',
		'value': 'Heart of Georgia Railroad'
	},
	{
		'label': 'HERITAGE RAILROAD - (HR)',
		'value': 'Heritage Railroad'
	},
	{
		'label': 'HERRIN RAILROAD - (HIRW)',
		'value': 'Herrin Railroad'
	},
	{
		'label': 'HIGH POINT, THOMASVILLE & DENTON RAILROAD - (HPTD)',
		'value': 'High Point, Thomasville & Denton Railroad'
	},
	{
		'label': 'HILTON & ALBANY RAILROAD - (HAL)',
		'value': 'Hilton & Albany Railroad'
	},
	{
		'label': 'HONDO RAILWAY - (HHRV)',
		'value': 'Hondo Railway'
	},
	{
		'label': 'HOFFMAN TRANSPORTATION',
		'value': 'Hoffman Transportation'
	},
	{
		'label': 'HOOSIER SOUTHERN RAILROAD - (HOS)',
		'value': 'Hoosier Southern Railroad'
	},
	{
		'label': 'HOUSATONIC RAILROAD - (HRRC)',
		'value': 'Housatonic Railroad'
	},
	{
		'label': 'HUNTSVILLE AND MADISON COUNTY AIRPORT AUTHORITY',
		'value': 'Huntsville and Madison County Airport Authority'
	},
	{
		'label': 'HUNTSVILLE AND MADISON COUNTY RAILROAD AUTHORITY - (HMCR)',
		'value': 'Huntsville and Madison County Railroad Authority'
	},
	{
		'label': 'HURON AND EASTERN RAILWAY - (HESR)',
		'value': 'Huron and Eastern Railway'
	},
	{
		'label': 'HUSSEY TERMINAL RAILROAD',
		'value': 'Hussey Terminal Railroad'
	},
	{
		'label': 'IDAHO AND SEDALIA TRANSPORTATION COMPANY - (ISRC)',
		'value': 'Idaho and Sedalia Transportation Company'
	},
	{
		'label': 'IDAHO NORTHERN AND PACIFIC RAILROAD - (INPR)',
		'value': 'Idaho Northern and Pacific Railroad'
	},
	{
		'label': 'ILLINI TERMINAL RAILROAD',
		'value': 'Illini Terminal Railroad'
	},
	{
		'label': 'ILLINOIS AND MIDLAND RAILROAD - (IMRR)',
		'value': 'Illinois and Midland Railroad'
	},
	{
		'label': 'ILLINOIS RAILWAY - (IRYM)',
		'value': 'Illinois Railway'
	},
	{
		'label': 'ILLINOIS WESTERN RAILROAD - (CIW)',
		'value': 'Illinois Western Railroad'
	},
	{
		'label': 'INDIANA AND OHIO RAILWAY - (IORY)',
		'value': 'Indiana and Ohio Railway'
	},
	{
		'label': 'INDIANA EASTERN RAILROAD - (HOSC)',
		'value': 'Indiana Eastern Railroad'
	},
	{
		'label': 'INDIANA HARBOR BELT RAILROAD - (IHB)',
		'value': 'Indiana Harbor Belt Railroad'
	},
	{
		'label': 'INDIANA NORTHEASTERN RAILROAD - (IN)',
		'value': 'Indiana Northeastern Railroad'
	},
	{
		'label': 'INDIANA RAILROAD - (BIR)',
		'value': 'Indiana Rail Road'
	},
	{
		'label': 'INDIANA SOUTHERN RAILROAD - (ISRR)',
		'value': 'Indiana Southern Railroad'
	},
	{
		'label': 'INDIANA SOUTHWESTERN RAILWAY - (ISW)',
		'value': 'Indiana Southwestern Railway'
	},
	{
		'label': 'IOWA INTERSTATE RAILROAD - (IAIS)',
		'value': 'Iowa Interstate Railroad'
	},
	{
		'label': 'IOWA & MIDDLETOWN RAILWAY - (IAMR)',
		'value': 'Iowa & Middletown Railway'
	},
	{
		'label': 'IOWA NORTHERN RAILWAY - (IANR)',
		'value': 'Iowa Northern Railway'
	},
	{
		'label': 'IOWA RIVER RAILROAD - (IARR)',
		'value': 'Iowa River Railroad'
	},
	{
		'label': 'IOWA SOUTHERN RAILWAY - (ISRY)',
		'value': 'Iowa Southern Railway'
	},
	{
		'label': 'IOWA TRACTION RAILROAD',
		'value': 'Iowa Traction Railroad'
	},
	{
		'label': 'ITHACA CENTRAL RAILROAD - (ITHR)',
		'value': 'Ithaca Central Railroad'
	},
	{
		'label': 'JACKSON & LANSING RAILROAD - (JAIL)',
		'value': 'Jackson & Lansing Railroad'
	},
	{
		'label': 'JACKSONVILLE PORT TERMINAL RAILROAD - (JXP)',
		'value': 'Jacksonville Port Terminal Railroad'
	},
	{
		'label': 'JOPPA AND EASTERN RAILROAD - (JERX)',
		'value': 'Joppa and Eastern Railroad'
	},
	{
		'label': 'JUNIATA TERMINAL COMPANY',
		'value': 'Juniata Terminal Company'
	},
	{
		'label': 'JUNIATA VALLEY RAILROAD - (JVRR)',
		'value': 'Juniata Valley Railroad'
	},
	{
		'label': 'KANAWHA RIVER RAILROAD - (KNWA)',
		'value': 'Kanawha River Railroad'
	},
	{
		'label': 'KANAWHA RIVER TERMINAL',
		'value': 'Kanawha River Terminal'
	},
	{
		'label': 'KANKAKEE, BEAVERVILLE AND SOUTHERN RAILROAD - (KBSR)',
		'value': 'Kankakee, Beaverville and Southern Railroad'
	},
	{
		'label': 'KANSAS AND OKLAHOMA RAILROAD - (AOK)',
		'value': 'Kansas and Oklahoma Railroad'
	},
	{
		'label': 'KANSAS CITY SOUTHERN RAILWAY - (KCS)',
		'value': 'Kansas City Southern Railway'
	},
	{
		'label': 'KANSAS CITY TRANSPORTATION LINES',
		'value': 'Kansas City Transportation Lines'
	},
	{
		'label': 'KAW RIVER RAILROAD - (KAW)',
		'value': 'Kaw River Railroad'
	},
	{
		'label': 'KENDALLVILLE TERMINAL RAILWAY - (KTR)',
		'value': 'Kendallville Terminal Railway'
	},
	{
		'label': 'KENNEWICK TERMINAL RAILROAD',
		'value': 'Kennewick Terminal Railroad'
	},
	{
		'label': 'KENTUCKY AND TENNESSEE RAILWAY - (KT)',
		'value': 'Kentucky and Tennessee Railway'
	},
	{
		'label': 'KEOKUK JUNCTION RAILWAY - (KJRY)',
		'value': 'Keokuk Junction Railway'
	},
	{
		'label': 'KIAMICHI RAILROAD - (KRR)',
		'value': 'Kiamichi Railroad'
	},
	{
		'label': 'KINGMAN TERMINAL RAILROAD - (KGTR)',
		'value': 'Kingman Terminal Railroad'
	},
	{
		'label': 'KINSTON AND SNOW HILL RAILROAD - (KSH)',
		'value': 'Kinston and Snow Hill Railroad'
	},
	{
		'label': 'KISKI JUNCTION RAILROAD - (KJR)',
		'value': 'Kiski Junction Railroad'
	},
	{
		'label': 'KLAMATH NORTHERN RAILWAY - (KNOR)',
		'value': 'Klamath Northern Railway'
	},
	{
		'label': 'KM RAILWAYS',
		'value': 'KM Railways'
	},
	{
		'label': 'KNOXVILLE AND HOLSTON RIVER RAILROAD - (KXHR)',
		'value': 'Knoxville and Holston River Railroad'
	},
	{
		'label': 'KODAK PARK RAILROAD',
		'value': 'Kodak Park Railroad'
	},
	{
		'label': 'KWT RAILWAY',
		'value': 'KWT Railway'
	},
	{
		'label': 'KYLE RAILROAD - (KYLE)',
		'value': 'Kyle Railroad'
	},
	{
		'label': 'LAKE MICHIGAN AND INDIANA RAILROAD - (LMIC)',
		'value': 'Lake Michigan and Indiana Railroad'
	},
	{
		'label': 'LAKE STATE RAILWAY - (LSRC)',
		'value': 'Lake State Railway'
	},
	{
		'label': 'LAKE SUPERIOR AND ISHPEMING RAILROAD - (LSI)',
		'value': 'Lake Superior and Ishpeming Railroad'
	},
	{
		'label': 'LAKE TERMINAL RAILROAD - (LT)',
		'value': 'Lake Terminal Railroad'
	},
	{
		'label': 'LANCASTER AND CHESTER RAILWAY - (LC)',
		'value': 'Lancaster and Chester Railway'
	},
	{
		'label': 'LANDISVILLE RAILROAD - (LR)',
		'value': 'Landisville Railroad'
	},
	{
		'label': 'LAPEER INDUSTRIAL RAILROAD - (LIRR)',
		'value': 'Lapeer Industrial Railroad'
	},
	{
		'label': 'LASALLE RAILWAY',
		'value': 'LaSalle Railway'
	},
	{
		'label': 'LAURINBURG AND SOUTHERN RAILROAD - (LRS)',
		'value': 'Laurinburg and Southern Railroad'
	},
	{
		'label': 'LEETSDALE INDUSTRIAL TERMINAL RAILWAY',
		'value': 'Leetsdale Industrial Terminal Railway'
	},
	{
		'label': 'LEHIGH RAILWAY - (LRWY)',
		'value': 'Lehigh Railway'
	},
	{
		'label': 'LEHIGH VALLEY RAIL MANAGEMENT – BETHLEHEM DIVISION',
		'value': 'Lehigh Valley Rail Management – Bethlehem Division'
	},
	{
		'label': 'LEHIGH VALLEY RAIL MANAGEMENT – JOHNSTOWN DIVISION',
		'value': 'Lehigh Valley Rail Management – Johnstown Division'
	},
	{
		'label': 'LEWISBURG AND BUFFALO CREEK RAILROAD',
		'value': 'Lewisburg and Buffalo Creek Railroad'
	},
	{
		'label': 'LITTLE KANAWHA RIVER RAILROAD',
		'value': 'Little Kanawha River Railroad'
	},
	{
		'label': 'LITTLE ROCK AND WESTERN RAILWAY - (LRWN)',
		'value': 'Little Rock and Western Railway'
	},
	{
		'label': 'LITTLE ROCK PORT AUTHORITY RAILROAD',
		'value': 'Little Rock Port Authority Railroad'
	},
	{
		'label': 'LIVE OAK RAILROAD - (LVOR)',
		'value': 'Live Oak Railroad'
	},
	{
		'label': 'LIVONIA, AVON AND LAKEVILLE RAILROAD - (LAL)',
		'value': 'Livonia, Avon and Lakeville Railroad'
	},
	{
		'label': 'LONGVIEW SWITCHING COMPANY - (LVSW)',
		'value': 'Longview Switching Company'
	},
	{
		'label': 'LORAIN NORTHERN RAILROAD',
		'value': 'Lorain Northern Railroad'
	},
	{
		'label': 'LOS ANGELES JUNCTION RAILWAY - (LAJ)',
		'value': 'Los Angeles Junction Railway'
	},
	{
		'label': 'LOUISIANA AND DELTA RAILROAD',
		'value': 'Louisiana and Delta Railroad'
	},
	{
		'label': 'LOUISIANA AND NORTH WEST RAILROAD - (LNW)',
		'value': 'Louisiana and North West Railroad'
	},
	{
		'label': 'LOUISIANA SOUTHERN RAILROAD',
		'value': 'Louisiana Southern Railroad'
	},
	{
		'label': 'LOUISVILLE AND INDIANA RAILROAD - (LIRC)',
		'value': 'Louisville and Indiana Railroad'
	},
	{
		'label': 'LUBBOCK AND WESTERN RAILWAY - (LBWR)',
		'value': 'Lubbock and Western Railway'
	},
	{
		'label': 'LUCAS OIL RAIL LINES',
		'value': 'Lucas Oil Rail Lines'
	},
	{
		'label': 'LUXAPALILA VALLEY RAILROAD - (LXVR)',
		'value': 'Luxapalila Valley Railroad'
	},
	{
		'label': 'LUZERNE AND SUSQUEHANNA RAILWAY - (LSX)',
		'value': 'Luzerne and Susquehanna Railway'
	},
	{
		'label': 'LYCOMING VALLEY RAILROAD - (LVRR)',
		'value': 'Lycoming Valley Railroad'
	},
	{
		'label': 'M&B RAILROAD - (MNBR)',
		'value': 'M&B Railroad'
	},
	{
		'label': 'MADISON RAILROAD - (CMPA)',
		'value': 'Madison Railroad'
	},
	{
		'label': 'MAHONING VALLEY RAILWAY',
		'value': 'Mahoning Valley Railway'
	},
	{
		'label': 'MAINE NORTHERN RAILWAY - (MNR)',
		'value': 'Maine Northern Railway'
	},
	{
		'label': 'MANNING RAIL - (MAN)',
		'value': 'Manning Rail'
	},
	{
		'label': 'MANUFACTURERS\' JUNCTION RAILWAY - (MJ)',
		'value': 'Manufacturers\' Junction Railway'
	},
	{
		'label': 'MARQUETTE RAIL, LLC',
		'value': 'Marquette Rail, LLC'
	},
	{
		'label': 'MARYLAND AND DELAWARE RAILROAD - (MDDE)',
		'value': 'Maryland and Delaware Railroad'
	},
	{
		'label': 'MARYLAND MIDLAND RAILWAY - (MMID)',
		'value': 'Maryland Midland Railway'
	},
	{
		'label': 'MASSACHUSETTS CENTRAL RAILROAD - (MSCR)',
		'value': 'Massachusetts Central Railroad'
	},
	{
		'label': 'MASSACHUSETTS COASTAL RAILROAD - (MCRL)',
		'value': 'Massachusetts Coastal Railroad'
	},
	{
		'label': 'MASSENA TERMINAL RAILROAD - (MSTR)',
		'value': 'Massena Terminal Railroad'
	},
	{
		'label': 'MEEKER SOUTHERN RAILROAD - (MSN)',
		'value': 'Meeker Southern Railroad'
	},
	{
		'label': 'MERIDIAN SOUTHERN RAILWAY - (MDS)',
		'value': 'Meridian Southern Railway'
	},
	{
		'label': 'MG RAIL, INC.',
		'value': 'MG Rail, Inc.'
	},
	{
		'label': 'MICHIGAN SHORE RAILROAD - (MS)',
		'value': 'Michigan Shore Railroad'
	},
	{
		'label': 'MICHIGAN SOUTHERN RAILROAD - (NDW)',
		'value': 'Michigan Southern Railroad'
	},
	{
		'label': 'MID-MICHIGAN RAILROAD - (MMRR)',
		'value': 'Mid-Michigan Railroad'
	},
	{
		'label': 'MIDDLETOWN AND HUMMELSTOWN RAILROAD - (MIDH)',
		'value': 'Middletown and Hummelstown Railroad'
	},
	{
		'label': 'MIDDLETOWN AND NEW JERSEY RAILROAD - (MNJ)',
		'value': 'Middletown and New Jersey Railroad'
	},
	{
		'label': 'MINERAL RANGE RAILROAD',
		'value': 'Mineral Range Railroad'
	},
	{
		'label': 'MINNESOTA COMMERCIAL RAILWAY - (MNNR)',
		'value': 'Minnesota Commercial Railway'
	},
	{
		'label': 'MINNESOTA NORTHERN RAILROAD - (MNN)',
		'value': 'Minnesota Northern Railroad'
	},
	{
		'label': 'MINNESOTA PRAIRIE LINE, INC. - (MPLI)',
		'value': 'Minnesota Prairie Line, Inc.'
	},
	{
		'label': 'MINNESOTA, DAKOTA AND WESTERN RAILWAY - (MDW)',
		'value': 'Minnesota, Dakota and Western Railway'
	},
	{
		'label': 'MISSION MOUNTAIN RAILROAD - (MMT)',
		'value': 'Mission Mountain Railroad'
	},
	{
		'label': 'MISSISSIPPI CENTRAL RAILROAD - (MSCI)',
		'value': 'Mississippi Central Railroad'
	},
	{
		'label': 'MISSISSIPPI DELTA RAILROAD - (MSDR)',
		'value': 'Mississippi Delta Railroad'
	},
	{
		'label': 'MISSISSIPPI EXPORT RAILROAD - (MSE)',
		'value': 'Mississippi Export Railroad'
	},
	{
		'label': 'MISSISSIPPI SOUTHERN RAILROAD - (MSR)',
		'value': 'Mississippi Southern Railroad'
	},
	{
		'label': 'MISSISSIPPI TENNESSEE RAILROAD - (MTNR)',
		'value': 'Mississippi Tennessee Railroad'
	},
	{
		'label': 'ITAWAMBA MISSISSIPPIAN RAILWAY',
		'value': 'Itawamba Mississippian Railway'
	},
	{
		'label': 'MISSOURI AND NORTHERN ARKANSAS RAILROAD - (MNA)',
		'value': 'Missouri and Northern Arkansas Railroad'
	},
	{
		'label': 'MISSOURI NORTH CENTRAL RAILROAD - (MNC)',
		'value': 'Missouri North Central Railroad'
	},
	{
		'label': 'MODESTO AND EMPIRE TRACTION COMPANY - (MET)',
		'value': 'Modesto and Empire Traction Company'
	},
	{
		'label': 'MOHAWK, ADIRONDACK AND NORTHERN RAILROAD - (MHWA)',
		'value': 'Mohawk, Adirondack and Northern Railroad'
	},
	{
		'label': 'MONTANA RAIL LINK - (MRL)',
		'value': 'Montana Rail Link'
	},
	{
		'label': 'MORRISTOWN AND ERIE RAILWAY - (OCTR)',
		'value': 'Morristown and Erie Railway'
	},
	{
		'label': 'MOSCOW, CAMDEN AND SAN AUGUSTINE RAILROAD - (MCSA)',
		'value': 'Moscow, Camden and San Augustine Railroad'
	},
	{
		'label': 'MOUNT HOOD RAILROAD',
		'value': 'Mount Hood Railroad'
	},
	{
		'label': 'MOUNT VERNON TERMINAL RAILWAY - (MVT)',
		'value': 'Mount Vernon Terminal Railway'
	},
	{
		'label': 'NAPOLEON, DEFIANCE & WESTERN RAILROAD',
		'value': 'Napoleon, Defiance & Western Railroad'
	},
	{
		'label': 'NASHVILLE AND EASTERN RAILROAD - (NERR)',
		'value': 'Nashville and Eastern Railroad'
	},
	{
		'label': 'NASHVILLE AND WESTERN RAILROAD - (NWR)',
		'value': 'Nashville and Western Railroad'
	},
	{
		'label': 'NATCHEZ RAILWAY - (NTZR)',
		'value': 'Natchez Railway'
	},
	{
		'label': 'NAUGATUCK RAILROAD COMPANY - (NAUG)',
		'value': 'Naugatuck Railroad Company'
	},
	{
		'label': 'NORTHAMPTON SWITCHING COMPANY',
		'value': 'Northampton Switching Company'
	},
	{
		'label': 'NEBRASKA CENTRAL RAILROAD - (NCRC)',
		'value': 'Nebraska Central Railroad'
	},
	{
		'label': 'NEBRASKA KANSAS COLORADO RAILWAY',
		'value': 'Nebraska Kansas Colorado Railway'
	},
	{
		'label': 'NEBRASKA NORTHWESTERN RAILROAD - (NNW)',
		'value': 'Nebraska Northwestern Railroad'
	},
	{
		'label': 'NEVADA INDUSTRIAL SWITCH',
		'value': 'Nevada Industrial Switch'
	},
	{
		'label': 'NEW CASTLE INDUSTRIAL RAILROAD - (NCIR)',
		'value': 'New Castle Industrial Railroad'
	},
	{
		'label': 'NEW CENTURY AIRCENTER RAILROAD',
		'value': 'New Century AirCenter Railroad'
	},
	{
		'label': 'NEW ENGLAND CENTRAL RAILROAD - (NECR)',
		'value': 'New England Central Railroad'
	},
	{
		'label': 'NEW ENGLAND SOUTHERN RAILROAD - (NEGS)',
		'value': 'New England Southern Railroad'
	},
	{
		'label': 'NEW HAMPSHIRE CENTRAL RAILROAD - (NHCR)',
		'value': 'New Hampshire Central Railroad'
	},
	{
		'label': 'NEW HAMPSHIRE NORTHCOAST CORPORATION',
		'value': 'New Hampshire Northcoast Corporation'
	},
	{
		'label': 'NEW HOPE AND IVYLAND RAILROAD - (NHRR)',
		'value': 'New Hope and Ivyland Railroad'
	},
	{
		'label': 'NEW JERSEY RAIL CARRIERS, LLC',
		'value': 'New Jersey Rail Carriers, LLC'
	},
	{
		'label': 'NEW JERSEY SEASHORE LINES - (NJSL)',
		'value': 'New Jersey Seashore Lines'
	},
	{
		'label': 'NEW ORLEANS AND GULF COAST RAILWAY - (NOGC)',
		'value': 'New Orleans and Gulf Coast Railway'
	},
	{
		'label': 'NEW ORLEANS PUBLIC BELT RAILROAD - (NOPB)',
		'value': 'New Orleans Public Belt Railroad'
	},
	{
		'label': 'NEW YORK AND ATLANTIC RAILWAY - (NYA)',
		'value': 'New York and Atlantic Railway'
	},
	{
		'label': 'NEW YORK AND LAKE ERIE RAILROAD',
		'value': 'New York and Lake Erie Railroad'
	},
	{
		'label': 'NEW YORK AND OGDENSBURG RAILWAY - (NYOG)',
		'value': 'New York and Ogdensburg Railway'
	},
	{
		'label': 'NEW YORK NEW JERSEY RAIL, LLC',
		'value': 'New York New Jersey Rail, LLC'
	},
	{
		'label': 'NEW YORK, SUSQUEHANNA AND WESTERN RAILWAY',
		'value': 'New York, Susquehanna and Western Railway'
	},
	{
		'label': 'NEWBURGH AND SOUTH SHORE RAILROAD - (NSR)',
		'value': 'Newburgh and South Shore Railroad'
	},
	{
		'label': 'NITTANY AND BALD EAGLE RAILROAD',
		'value': 'Nittany and Bald Eagle Railroad'
	},
	{
		'label': 'NORFOLK AND PORTSMOUTH BELT LINE RAILROAD - (NPB)',
		'value': 'Norfolk and Portsmouth Belt Line Railroad'
	},
	{
		'label': 'NORFOLK SOUTHERN RAILWAY - (NS)',
		'value': 'Norfolk Southern Railway'
	},
	{
		'label': 'NORTH CAROLINA AND VIRGINIA RAILROAD - (NCVA)',
		'value': 'North Carolina and Virginia Railroad'
	},
	{
		'label': 'NORTH LOUISIANA & ARKANSAS RAILROAD - (NLA)',
		'value': 'North Louisiana & Arkansas Railroad'
	},
	{
		'label': 'NORTH SHORE RAILROAD - (NSHR)',
		'value': 'North Shore Railroad'
	},
	{
		'label': 'NORTHERN LINES RAILWAY - (NLR)',
		'value': 'Northern Lines Railway'
	},
	{
		'label': 'NORTHERN OHIO AND WESTERN RAILWAY - (NOW)',
		'value': 'Northern Ohio and Western Railway'
	},
	{
		'label': 'NORTHERN PLAINS RAILROAD - (NPR)',
		'value': 'Northern Plains Railroad'
	},
	{
		'label': 'NORTHWESTERN OKLAHOMA RAILROAD - (NOKL)',
		'value': 'Northwestern Oklahoma Railroad'
	},
	{
		'label': 'NORTHWESTERN PACIFIC RAILROAD - (NWP)',
		'value': 'Northwestern Pacific Railroad'
	},
	{
		'label': 'OAKLAND GLOBAL RAIL ENTERPRISE - (OGRE)',
		'value': 'Oakland Global Rail Enterprise'
	},
	{
		'label': 'OGEECHEE RAILWAY - (OGEE)',
		'value': 'Ogeechee Railway'
	},
	{
		'label': 'OHI-RAIL CORPORATION - (OHIC)',
		'value': 'Ohi-Rail Corporation'
	},
	{
		'label': 'OHIO CENTRAL RAILROAD - (IOCR)',
		'value': 'Ohio Central Railroad'
	},
	{
		'label': 'OHIO SOUTH CENTRAL RAILROAD - (OSCR)',
		'value': 'Ohio South Central Railroad'
	},
	{
		'label': 'OHIO SOUTHERN RAILROAD - (OSRR)',
		'value': 'Ohio Southern Railroad'
	},
	{
		'label': 'OHIO TERMINAL RAILWAY - (OHIO)',
		'value': 'Ohio Terminal Railway'
	},
	{
		'label': 'OIL CREEK AND TITUSVILLE LINES, INC.',
		'value': 'Oil Creek and Titusville Lines, Inc.'
	},
	{
		'label': 'OLD AUGUSTA RAILROAD - (OAR)',
		'value': 'Old Augusta Railroad'
	},
	{
		'label': 'OLYMPIA AND BELMORE RAILROAD - (OYLO)',
		'value': 'Olympia and Belmore Railroad'
	},
	{
		'label': 'OMAHA, LINCOLN AND BEATRICE RAILWAY - (OLB)',
		'value': 'Omaha, Lincoln and Beatrice Railway'
	},
	{
		'label': 'ONTARIO CENTRAL RAILROAD - (ONCT)',
		'value': 'Ontario Central Railroad'
	},
	{
		'label': 'ONTARIO MIDLAND RAILROAD - (OMID)',
		'value': 'Ontario Midland Railroad'
	},
	{
		'label': 'ORANGE PORT TERMINAL RAILWAY - (OPT)',
		'value': 'Orange Port Terminal Railway'
	},
	{
		'label': 'OREGON EASTERN RAILROAD - (OERR)',
		'value': 'Oregon Eastern Railroad'
	},
	{
		'label': 'OREGON PACIFIC RAILROAD - (OPR)',
		'value': 'Oregon Pacific Railroad'
	},
	{
		'label': 'OREGON RAILCONNECT',
		'value': 'Oregon Railconnect'
	},
	{
		'label': 'OTTER TAIL VALLEY RAILROAD - (OTVR)',
		'value': 'Otter Tail Valley Railroad'
	},
	{
		'label': 'OUACHITA RAILROAD - (OUCH)',
		'value': 'Ouachita Railroad'
	},
	{
		'label': 'OWEGO AND HARFORD RAILWAY - (OHRY)',
		'value': 'Owego and Harford Railway'
	},
	{
		'label': 'OZARK VALLEY RAILROAD - (OVRR)',
		'value': 'Ozark Valley Railroad'
	},
	{
		'label': 'PACIFIC HARBOR LINE, INC. - (PHL)',
		'value': 'Pacific Harbor Line, Inc.'
	},
	{
		'label': 'PACIFIC SUN RAILROAD - (PSRR)',
		'value': 'Pacific Sun Railroad'
	},
	{
		'label': 'PADUCAH AND ILLINOIS RAILROAD - (PI)',
		'value': 'Paducah and Illinois Railroad'
	},
	{
		'label': 'PADUCAH AND LOUISVILLE RAILWAY - (PAL)',
		'value': 'Paducah and Louisville Railway'
	},
	{
		'label': 'PALOUSE RIVER AND COULEE CITY RAILROAD - (PCC)',
		'value': 'Palouse River and Coulee City Railroad'
	},
	{
		'label': 'PAN AM RAILWAYS - (GRS)',
		'value': 'Pan Am Railways'
	},
	{
		'label': 'PAN AM SOUTHERN - (PAS)',
		'value': 'Pan Am Southern'
	},
	{
		'label': 'PANHANDLE NORTHERN RAILROAD - (PNR)',
		'value': 'Panhandle Northern Railroad'
	},
	{
		'label': 'PATRIOT WOODS RAILROAD',
		'value': 'Patriot Woods Railroad'
	},
	{
		'label': 'PECOS VALLEY SOUTHERN RAILWAY - (PVS)',
		'value': 'Pecos Valley Southern Railway'
	},
	{
		'label': 'PEE DEE RIVER RAILWAY - (PDRR)',
		'value': 'Pee Dee River Railway'
	},
	{
		'label': 'PEND OREILLE VALLEY RAILROAD - (POVA)',
		'value': 'Pend Oreille Valley Railroad'
	},
	{
		'label': 'PENINSULA TERMINAL COMPANY - (PT)',
		'value': 'Peninsula Terminal Company'
	},
	{
		'label': 'PENNSYLVANIA AND SOUTHERN RAILWAY - (PSCC)',
		'value': 'Pennsylvania and Southern Railway'
	},
	{
		'label': 'PENNSYLVANIA SOUTHWESTERN RAILROAD - (PSWR)',
		'value': 'Pennsylvania Southwestern Railroad'
	},
	{
		'label': 'PERRY COUNTY RAILROAD',
		'value': 'Perry County Railroad'
	},
	{
		'label': 'PERU INDUSTRIAL RAILROAD - (PIRL)',
		'value': 'Peru Industrial Railroad'
	},
	{
		'label': 'PICKENS RAILWAY, HONEA PATH DIVISION',
		'value': 'Pickens Railway, Honea Path Division'
	},
	{
		'label': 'PIONEER INDUSTRIAL RAILROAD',
		'value': 'Pioneer Industrial Railroad'
	},
	{
		'label': 'PIONEER VALLEY RAILROAD - (PVRR)',
		'value': 'Pioneer Valley Railroad'
	},
	{
		'label': 'PITTSBURGH AND OHIO CENTRAL RAILROAD - (POHC)',
		'value': 'Pittsburgh and Ohio Central Railroad'
	},
	{
		'label': 'PITTSBURGH, ALLEGHENY AND MCKEES ROCKS RAILROAD - (PAM)',
		'value': 'Pittsburgh, Allegheny and McKees Rocks Railroad'
	},
	{
		'label': 'PLAINSMAN SWITCHING COMPANY - (PMSW)',
		'value': 'Plainsman Switching Company'
	},
	{
		'label': 'PLAINVIEW TERMINAL COMPANY - (PTC)',
		'value': 'Plainview Terminal Company'
	},
	{
		'label': 'POINT COMFORT AND NORTHERN RAILWAY',
		'value': 'Point Comfort and Northern Railway'
	},
	{
		'label': 'PORT BIENVILLE SHORT LINE RAILROAD',
		'value': 'Port Bienville Short Line Railroad'
	},
	{
		'label': 'PORT HARBOR RAILROAD - (PHRR)',
		'value': 'Port Harbor Railroad'
	},
	{
		'label': 'PORT JERSEY RAILROAD - (PJR)',
		'value': 'Port Jersey Railroad'
	},
	{
		'label': 'PORT MANATEE RAILROAD',
		'value': 'Port Manatee Railroad'
	},
	{
		'label': 'PORT OF BEAUMONT RAILROAD',
		'value': 'Port of Beaumont Railroad'
	},
	{
		'label': 'PORT OF CATOOSA TERMINAL RAILROAD - (POCA)',
		'value': 'Port of Catoosa Terminal Railroad'
	},
	{
		'label': 'PORT OF MUSKOGEE RAILROAD - (PMKX)',
		'value': 'Port of Muskogee Railroad'
	},
	{
		'label': 'PORT OF PALM BEACH DISTRICT',
		'value': 'Port of Palm Beach District'
	},
	{
		'label': 'PORT OF TUCSON RAILROAD',
		'value': 'Port of Tucson Railroad'
	},
	{
		'label': 'PORT RAIL, INC - (PRI)',
		'value': 'Port Rail, Inc'
	},
	{
		'label': 'PORT TERMINAL RAILROAD ASSOCIATION - (PTRA)',
		'value': 'Port Terminal Railroad Association'
	},
	{
		'label': 'PORT TERMINAL RAILROAD OF SOUTH CAROLINA - (PTR)',
		'value': 'Port Terminal Railroad of South Carolina'
	},
	{
		'label': 'PORTLAND AND WESTERN RAILROAD - (PNWR)',
		'value': 'Portland and Western Railroad'
	},
	{
		'label': 'PORTLAND TERMINAL RAILROAD',
		'value': 'Portland Terminal Railroad'
	},
	{
		'label': 'PORTLAND VANCOUVER JUNCTION RAILROAD - (PVJR)',
		'value': 'Portland Vancouver Junction Railroad'
	},
	{
		'label': 'PRESCOTT AND NORTHWESTERN RAILROAD - (PNW)',
		'value': 'Prescott and Northwestern Railroad'
	},
	{
		'label': 'PROGRESSIVE RAIL, INC. - (PGR)',
		'value': 'Progressive Rail, Inc.'
	},
	{
		'label': 'PROVIDENCE AND WORCESTER RAILROAD - (PW)',
		'value': 'Providence and Worcester Railroad'
	},
	{
		'label': 'PUGET SOUND AND PACIFIC RAILROAD - (PSAP)',
		'value': 'Puget Sound and Pacific Railroad'
	},
	{
		'label': 'QUINCY RAILROAD - (QRR)',
		'value': 'Quincy Railroad'
	},
	{
		'label': 'QUINWOOD COAL COMPANY',
		'value': 'Quinwood Coal Company'
	},
	{
		'label': 'RAINER RAIL',
		'value': 'Rainer Rail'
	},
	{
		'label': 'RAWHIDE SHORT LINE',
		'value': 'Rawhide Short Line'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/ALLENTOWN LINES',
		'value': 'R.J. Corman Railroad/Allentown Lines'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/BARDSTOWN LINE',
		'value': 'R.J. Corman Railroad/Bardstown Line'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/CAROLINA LINE',
		'value': 'R.J. Corman Railroad/Carolina Line'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/CENTRAL KENTUCKY LINES',
		'value': 'R.J. Corman Railroad/Central Kentucky Lines'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/CLEVELAND LINE',
		'value': 'R.J. Corman Railroad/Cleveland Line'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/MEMPHIS LINE',
		'value': 'R.J. Corman Railroad/Memphis Line'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/PENNSYLVANIA LINES',
		'value': 'R.J. Corman Railroad/Pennsylvania Lines'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/TENNESSEE TERMINAL',
		'value': 'R.J. Corman Railroad/Tennessee Terminal'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/TEXAS LINES',
		'value': 'R.J. Corman Railroad/Texas Lines'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/WEST VIRGINIA LINE',
		'value': 'R.J. Corman Railroad/West Virginia Line'
	},
	{
		'label': 'R.J. CORMAN RAILROAD/WESTERN OHIO LINES',
		'value': 'R.J. Corman Railroad/Western Ohio Lines'
	},
	{
		'label': 'RAPID CITY, PIERRE AND EASTERN RAILROAD - (RCPE)',
		'value': 'Rapid City, Pierre and Eastern Railroad'
	},
	{
		'label': 'RARITAN CENTRAL RAILWAY - (RCRY)',
		'value': 'Raritan Central Railway'
	},
	{
		'label': 'READING BLUE MOUNTAIN AND NORTHERN RAILROAD - (RBMN)',
		'value': 'Reading Blue Mountain and Northern Railroad'
	},
	{
		'label': 'RED RIVER VALLEY AND WESTERN RAILROAD',
		'value': 'Red River Valley and Western Railroad'
	},
	{
		'label': 'REPUBLIC N&T RAILROAD',
		'value': 'Republic N&T Railroad'
	},
	{
		'label': 'RICEBORO SOUTHERN RAILWAY - (RSOR)',
		'value': 'Riceboro Southern Railway'
	},
	{
		'label': 'RICHMOND PACIFIC RAILROAD - (RPRC)',
		'value': 'Richmond Pacific Railroad'
	},
	{
		'label': 'RIO VALLEY SWITCHING COMPANY - (RVSC)',
		'value': 'Rio Valley Switching Company'
	},
	{
		'label': 'RIPLEY & NEW ALBANY RAILROAD',
		'value': 'Ripley & New Albany Railroad'
	},
	{
		'label': 'RIVERPORT RAILROAD - (RVPR)',
		'value': 'Riverport Railroad'
	},
	{
		'label': 'ROCHESTER AND SOUTHERN RAILROAD',
		'value': 'Rochester and Southern Railroad'
	},
	{
		'label': 'ROCK AND RAIL LLC',
		'value': 'Rock and Rail LLC'
	},
	{
		'label': 'ROCKDALE, SANDOW AND SOUTHERN RAILROAD - (RSS)',
		'value': 'Rockdale, Sandow and Southern Railroad'
	},
	{
		'label': 'ROGUE VALLEY TERMINAL RAILROAD - (RVT)',
		'value': 'Rogue Valley Terminal Railroad'
	},
	{
		'label': 'REPUBLIC SHORT LINE',
		'value': 'Republic Short Line'
	},
	{
		'label': 'S&L RAILROAD',
		'value': 'S&L Railroad'
	},
	{
		'label': 'SABINE RIVER AND NORTHERN RAILROAD - (SRN)',
		'value': 'Sabine River and Northern Railroad'
	},
	{
		'label': 'SACRAMENTO SOUTHERN RAILROAD - (SSRR)',
		'value': 'Sacramento Southern Railroad'
	},
	{
		'label': 'SACRAMENTO VALLEY RAILROAD - (SAV)',
		'value': 'Sacramento Valley Railroad'
	},
	{
		'label': 'SALT LAKE, GARFIELD AND WESTERN RAILWAY - (SLGW)',
		'value': 'Salt Lake, Garfield and Western Railway'
	},
	{
		'label': 'SAN ANTONIO CENTRAL RAILROAD - (SAC)',
		'value': 'San Antonio Central Railroad'
	},
	{
		'label': 'SAN DIEGO AND IMPERIAL VALLEY RAILROAD',
		'value': 'San Diego and Imperial Valley Railroad'
	},
	{
		'label': 'SAN FRANCISCO BAY RAILWAY - (SFBR)',
		'value': 'San Francisco Bay Railway'
	},
	{
		'label': 'SAN JOAQUIN VALLEY RAILROAD - (SJVR)',
		'value': 'San Joaquin Valley Railroad'
	},
	{
		'label': 'SAN MANUEL ARIZONA RAILROAD - (SMA)',
		'value': 'San Manuel Arizona Railroad'
	},
	{
		'label': 'SAN LUIS AND RIO GRANDE RAILROAD - (SLRG)',
		'value': 'San Luis and Rio Grande Railroad'
	},
	{
		'label': 'SAN LUIS CENTRAL RAILROAD - (SLC)',
		'value': 'San Luis Central Railroad'
	},
	{
		'label': 'SAN PEDRO VALLEY RAILROAD',
		'value': 'San Pedro Valley Railroad'
	},
	{
		'label': 'SANTA TERESA SOUTHERN RAILROAD - (STS)',
		'value': 'Santa Teresa Southern Railroad'
	},
	{
		'label': 'SAND SPRINGS RAILWAY - (SS)',
		'value': 'Sand Springs Railway'
	},
	{
		'label': 'SANDERSVILLE RAILROAD - (SAN)',
		'value': 'Sandersville Railroad'
	},
	{
		'label': 'SANTA CRUZ, BIG TREES AND PACIFIC RAILWAY',
		'value': 'Santa Cruz, Big Trees and Pacific Railway'
	},
	{
		'label': 'SANTA MARIA VALLEY RAILROAD - (SMV)',
		'value': 'Santa Maria Valley Railroad'
	},
	{
		'label': 'SARATOGA AND NORTH CREEK RAILROAD',
		'value': 'Saratoga and North Creek Railroad'
	},
	{
		'label': 'SAVAGE BINGHAM AND GARFIELD RAILROAD - (SBG)',
		'value': 'Savage Bingham and Garfield Railroad'
	},
	{
		'label': 'SAVANNAH PORT TERMINAL RAILROAD - (SAPT)',
		'value': 'Savannah Port Terminal Railroad'
	},
	{
		'label': 'SEAVIEW RAILROAD',
		'value': 'Seaview Railroad'
	},
	{
		'label': 'SEMINOLE GULF RAILWAY',
		'value': 'Seminole Gulf Railway'
	},
	{
		'label': 'SEMO PORT RAILROAD - (SE)',
		'value': 'SEMO Port Railroad'
	},
	{
		'label': 'SEQUATCHIE VALLEY SWITCHING COMPANY',
		'value': 'Sequatchie Valley Switching Company'
	},
	{
		'label': 'SHAMOKIN VALLEY RAILROAD - (SVRR)',
		'value': 'Shamokin Valley Railroad'
	},
	{
		'label': 'SHENANDOAH VALLEY RAILROAD - (SV)',
		'value': 'Shenandoah Valley Railroad'
	},
	{
		'label': 'SIERRA NORTHERN RAILWAY - (SERA)',
		'value': 'Sierra Northern Railway'
	},
	{
		'label': 'SISSETON MILBANK RAILROAD - (SMRR)',
		'value': 'Sisseton Milbank Railroad'
	},
	{
		'label': 'SJRE RAILROAD',
		'value': 'SJRE Railroad'
	},
	{
		'label': 'SMS RAIL LINES OF NEW YORK, LLC - (SNY)',
		'value': 'SMS Rail Lines of New York, LLC'
	},
	{
		'label': 'SMS RAIL SERVICE, INC. - (SLRS)',
		'value': 'SMS Rail Service, Inc.'
	},
	{
		'label': 'SOUTH BRANCH VALLEY RAILROAD - (SBVR)',
		'value': 'South Branch Valley Railroad'
	},
	{
		'label': 'SOUTH BUFFALO RAILWAY - (SB)',
		'value': 'South Buffalo Railway'
	},
	{
		'label': 'SOUTH CAROLINA CENTRAL RAILROAD - (CPDR)',
		'value': 'South Carolina Central Railroad'
	},
	{
		'label': 'SOUTH CENTRAL FLORIDA EXPRESS, INC. - (SCXF)',
		'value': 'South Central Florida Express, Inc.'
	},
	{
		'label': 'SOUTH CENTRAL TENNESSEE RAILROAD',
		'value': 'South Central Tennessee Railroad'
	},
	{
		'label': 'SOUTH CHICAGO AND INDIANA HARBOR RAILWAY',
		'value': 'South Chicago and Indiana Harbor Railway'
	},
	{
		'label': 'SOUTH KANSAS AND OKLAHOMA RAILROAD - (SKOL)',
		'value': 'South Kansas and Oklahoma Railroad'
	},
	{
		'label': 'SOUTH PLAINS LAMESA RAILROAD - (SLAL)',
		'value': 'South Plains Lamesa Railroad'
	},
	{
		'label': 'SOUTH PLAINS SWITCHING, LTD - (SAW)',
		'value': 'South Plains Switching, Ltd'
	},
	{
		'label': 'SOUTHERN CALIFORNIA RAILROAD',
		'value': 'Southern California Railroad'
	},
	{
		'label': 'SOUTHERN ELECTRIC RAILROAD COMPANY',
		'value': 'Southern Electric Railroad Company'
	},
	{
		'label': 'SOUTHERN INDIANA RAILWAY - (SIND)',
		'value': 'Southern Indiana Railway'
	},
	{
		'label': 'SOUTHERN RAILROAD OF NEW JERSEY',
		'value': 'Southern Railroad of New Jersey'
	},
	{
		'label': 'SOUTHERN SWITCHING COMPANY - (SSC)',
		'value': 'Southern Switching Company'
	},
	{
		'label': 'SOUTHWEST GULF RAILROAD',
		'value': 'Southwest Gulf Railroad'
	},
	{
		'label': 'SOUTHWEST PENNSYLVANIA RAILROAD - (SWP)',
		'value': 'Southwest Pennsylvania Railroad'
	},
	{
		'label': 'SOUTHWESTERN RAILROAD - (GSWR)',
		'value': 'Southwestern Railroad'
	},
	{
		'label': 'SQUAW CREEK SOUTHERN RAILROAD - (SCS)',
		'value': 'Squaw Creek Southern Railroad'
	},
	{
		'label': 'ST. CROIX VALLEY RAILROAD - (SCXY)',
		'value': 'St. Croix Valley Railroad'
	},
	{
		'label': 'ST. LAWRENCE AND ATLANTIC RAILROAD - (SLQA)',
		'value': 'St. Lawrence and Atlantic Railroad'
	},
	{
		'label': 'ST. MARIES RIVER RAILROAD - (STMA)',
		'value': 'St. Maries River Railroad'
	},
	{
		'label': 'ST. MARY\'S RAILROAD',
		'value': 'St. Mary\'s Railroad'
	},
	{
		'label': 'ST. MARYS RAILWAY WEST',
		'value': 'St. Marys Railway West'
	},
	{
		'label': 'STILLWATER CENTRAL RAILROAD - (SLWC)',
		'value': 'Stillwater Central Railroad'
	},
	{
		'label': 'ST. PAUL AND PACIFIC NORTHWEST RAILROAD',
		'value': 'St. Paul and Pacific Northwest Railroad'
	},
	{
		'label': 'ST. PAUL AND PACIFIC RAILROAD - (MILW)',
		'value': 'St. Paul and Pacific Railroad'
	},
	{
		'label': 'STOCKTON PUBLIC BELT RAILWAY',
		'value': 'Stockton Public Belt Railway'
	},
	{
		'label': 'STOCKTON TERMINAL AND EASTERN RAILROAD - (STE)',
		'value': 'Stockton Terminal and Eastern Railroad'
	},
	{
		'label': 'STOURBRIDGE RAILROAD - (LASB)',
		'value': 'Stourbridge Railroad'
	},
	{
		'label': 'STRASBURG RAILROAD - (SRC)',
		'value': 'Strasburg Rail Road'
	},
	{
		'label': 'SWAN RANCH RAILROAD - (SRRR)',
		'value': 'Swan Ranch Railroad'
	},
	{
		'label': 'TACOMA RAIL - (TRMW)',
		'value': 'Tacoma Rail'
	},
	{
		'label': 'TACOMA RAIL MOUNTAIN DIVISION - (TRMW)',
		'value': 'Tacoma Rail Mountain Division'
	},
	{
		'label': 'TAZEWELL AND PEORIA RAILROAD - (TZPR)',
		'value': 'Tazewell and Peoria Railroad'
	},
	{
		'label': 'TEMPLE AND CENTRAL TEXAS RAILWAY - (TC)',
		'value': 'Temple and Central Texas Railway'
	},
	{
		'label': 'TENNESSEE SOUTHERN RAILROAD - (TSRR)',
		'value': 'Tennessee Southern Railroad'
	},
	{
		'label': 'TENNKEN RAILROAD - (TKEN)',
		'value': 'Tennken Railroad'
	},
	{
		'label': 'TERMINAL RAILROAD ASSOCIATION OF ST. LOUIS - (TRRA)',
		'value': 'Terminal Railroad Association of St. Louis'
	},
	{
		'label': 'TERMINAL RAILWAY ALABAMA STATE DOCKS - (TASD)',
		'value': 'Terminal Railway Alabama State Docks'
	},
	{
		'label': 'TEXAS & NEW MEXICO RAILROAD',
		'value': 'Texas & New Mexico Railroad'
	},
	{
		'label': 'TEXAS AND EASTERN RAILROAD - (TESR)',
		'value': 'Texas and Eastern Railroad'
	},
	{
		'label': 'TEXAS AND NORTHERN RAILWAY - (TN)',
		'value': 'Texas and Northern Railway'
	},
	{
		'label': 'TEXAS AND OKLAHOMA RAILROAD - (TXOR)',
		'value': 'Texas and Oklahoma Railroad'
	},
	{
		'label': 'TEXAS CENTRAL BUSINESS LINES CORPORATION',
		'value': 'Texas Central Business Lines Corporation'
	},
	{
		'label': 'TEXAS CITY TERMINAL RAILWAY - (TCT)',
		'value': 'Texas City Terminal Railway'
	},
	{
		'label': 'TEXAS NORTH WESTERN RAILWAY',
		'value': 'Texas North Western Railway'
	},
	{
		'label': 'TEXAS NORTHEASTERN RAILROAD',
		'value': 'Texas Northeastern Railroad'
	},
	{
		'label': 'TEXAS PACIFICO TRANSPORTATION - (TXPF)',
		'value': 'Texas Pacifico Transportation'
	},
	{
		'label': 'TEXAS ROCK CRUSHER RAILWAY - (TXR)',
		'value': 'Texas Rock Crusher Railway'
	},
	{
		'label': 'TEXAS SOUTH-EASTERN RAILROAD - (TSE)',
		'value': 'Texas South-Eastern Railroad'
	},
	{
		'label': 'TEXAS, GONZALES AND NORTHERN RAILWAY - (TXGN)',
		'value': 'Texas, Gonzales and Northern Railway'
	},
	{
		'label': 'THERMAL BELT RAILWAY - (TBRY)',
		'value': 'Thermal Belt Railway'
	},
	{
		'label': 'THREE NOTCH RAILROAD - (TNHR)',
		'value': 'Three Notch Railroad'
	},
	{
		'label': 'TIMBER ROCK RAILROAD',
		'value': 'Timber Rock Railroad'
	},
	{
		'label': 'TOLEDO JUNCTION RAILROAD',
		'value': 'Toledo Junction Railroad'
	},
	{
		'label': 'TOLEDO, PEORIA AND WESTERN RAILWAY - (TPW)',
		'value': 'Toledo, Peoria and Western Railway'
	},
	{
		'label': 'TOMAHAWK RAILWAY - (TR)',
		'value': 'Tomahawk Railway'
	},
	{
		'label': 'TRADEPOINT RAIL - (TPR)',
		'value': 'Tradepoint Rail'
	},
	{
		'label': 'TRONA RAILWAY - (TRC)',
		'value': 'Trona Railway'
	},
	{
		'label': 'TULSA–SAPULPA UNION RAILWAY',
		'value': 'Tulsa–Sapulpa Union Railway'
	},
	{
		'label': 'TURNERS ISLAND, LLC',
		'value': 'Turners Island, LLC'
	},
	{
		'label': 'TWIN CITIES AND WESTERN RAILROAD',
		'value': 'Twin Cities and Western Railroad'
	},
	{
		'label': 'TYBURN RAILROAD - (TYBR)',
		'value': 'Tyburn Railroad'
	},
	{
		'label': 'TYNER TERMINAL RAILWAY - (TNTX)',
		'value': 'Tyner Terminal Railway'
	},
	{
		'label': 'UNION CITY TERMINAL RAILROAD - (UCTR)',
		'value': 'Union City Terminal Railroad'
	},
	{
		'label': 'UNION COUNTY INDUSTRIAL RAILROAD - (UCIR)',
		'value': 'Union County Industrial Railroad'
	},
	{
		'label': 'UNION PACIFIC RAILROAD - (UP)',
		'value': 'Union Pacific Railroad'
	},
	{
		'label': 'UNION RAILROAD - (URR)',
		'value': 'Union Railroad'
	},
	{
		'label': 'UPPER MERION AND PLYMOUTH RAILROAD - (UMP)',
		'value': 'Upper Merion and Plymouth Railroad'
	},
	{
		'label': 'UTAH RAILWAY - (UTAH)',
		'value': 'Utah Railway'
	},
	{
		'label': 'UTAH CENTRAL RAILWAY - (UCRY)',
		'value': 'Utah Central Railway'
	},
	{
		'label': 'V&S RAILWAY - (VST)',
		'value': 'V&S Railway'
	},
	{
		'label': 'VALDOSTA RAILWAY - (VR)',
		'value': 'Valdosta Railway'
	},
	{
		'label': 'VANDALIA RAILROAD - (VRRC)',
		'value': 'Vandalia Railroad'
	},
	{
		'label': 'VENTURA COUNTY RAILROAD - (VCRR)',
		'value': 'Ventura County Railroad'
	},
	{
		'label': 'VERMILION VALLEY RAILROAD',
		'value': 'Vermilion Valley Railroad'
	},
	{
		'label': 'VERMONT RAILWAY - (CV)',
		'value': 'Vermont Railway'
	},
	{
		'label': 'VICKSBURG SOUTHERN RAILROAD - (VSOR)',
		'value': 'Vicksburg Southern Railroad'
	},
	{
		'label': 'VIRGINIA SOUTHERN RAILROAD - (VSRR)',
		'value': 'Virginia Southern Railroad'
	},
	{
		'label': 'WABASH CENTRAL RAILROAD - (WBCR)',
		'value': 'Wabash Central Railroad'
	},
	{
		'label': 'WALKING HORSE RAILROAD - (WHRR)',
		'value': 'Walking Horse Railroad'
	},
	{
		'label': 'WALLOWA UNION RAILROAD AUTHORITY - (WURR)',
		'value': 'Wallowa Union Railroad Authority'
	},
	{
		'label': 'WARREN AND SALINE RIVER RAILROAD - (WSR)',
		'value': 'Warren and Saline River Railroad'
	},
	{
		'label': 'WARREN AND TRUMBULL RAILROAD - (WTRM)',
		'value': 'Warren and Trumbull Railroad'
	},
	{
		'label': 'WASHINGTON AND IDAHO RAILWAY - (WIR)',
		'value': 'Washington and Idaho Railway'
	},
	{
		'label': 'WASHINGTON COUNTY RAILROAD - (WACR)',
		'value': 'Washington County Railroad'
	},
	{
		'label': 'WASHINGTON EASTERN RAILROAD - (WER)',
		'value': 'Washington Eastern Railroad'
	},
	{
		'label': 'WASHINGTON ROYAL LINE',
		'value': 'Washington Royal Line'
	},
	{
		'label': 'WELLSBORO AND CORNING RAILROAD - (WCOR)',
		'value': 'Wellsboro and Corning Railroad'
	},
	{
		'label': 'WEST BELT RAILWAY - (WBRW)',
		'value': 'West Belt Railway'
	},
	{
		'label': 'WEST ISLE LINE - (WFS)',
		'value': 'West Isle Line'
	},
	{
		'label': 'WEST MICHIGAN RAILROAD - (WMI)',
		'value': 'West Michigan Railroad'
	},
	{
		'label': 'WEST SHORE RAILROAD CORPORATION',
		'value': 'West Shore Railroad Corporation'
	},
	{
		'label': 'WEST TENNESSEE RAILROAD - (WTNN)',
		'value': 'West Tennessee Railroad'
	},
	{
		'label': 'WESTERN MARYLAND SCENIC RAILROAD - (WMSR)',
		'value': 'Western Maryland Scenic Railroad'
	},
	{
		'label': 'WESTERN NEW YORK AND PENNSYLVANIA RAILROAD - (WNYP)',
		'value': 'Western New York and Pennsylvania Railroad'
	},
	{
		'label': 'WESTERN RAILROAD - (ACWR)',
		'value': 'Western Rail Road'
	},
	{
		'label': 'WESTERN WASHINGTON RAILROAD - (WWR)',
		'value': 'Western Washington Railroad'
	},
	{
		'label': 'WHEELING AND LAKE ERIE RAILWAY - (WE)',
		'value': 'Wheeling and Lake Erie Railway'
	},
	{
		'label': 'WHITE DEER AND READING RAILROAD',
		'value': 'White Deer and Reading Railroad'
	},
	{
		'label': 'WICHITA TERMINAL ASSOCIATION - (WTA)',
		'value': 'Wichita Terminal Association'
	},
	{
		'label': 'WICHITA, TILLMAN AND JACKSON RAILWAY - (WTJR)',
		'value': 'Wichita, Tillman and Jackson Railway'
	},
	{
		'label': 'WILLAMETTE VALLEY RAILWAY - (WVR)',
		'value': 'Willamette Valley Railway'
	},
	{
		'label': 'WILMINGTON TERMINAL RAILROAD - (WTRY)',
		'value': 'Wilmington Terminal Railroad'
	},
	{
		'label': 'WILMINGTON AND WESTERN RAILROAD - (WAW)',
		'value': 'Wilmington and Western Railroad'
	},
	{
		'label': 'WINCHESTER AND WESTERN RAILROAD - (WW)',
		'value': 'Winchester and Western Railroad'
	},
	{
		'label': 'WINSTON-SALEM SOUTHBOUND RAILWAY - (WSS)',
		'value': 'Winston-Salem Southbound Railway'
	},
	{
		'label': 'WIREGRASS CENTRAL RAILROAD - (WGCR)',
		'value': 'Wiregrass Central Railroad'
	},
	{
		'label': 'WISCONSIN AND SOUTHERN RAILROAD - (WSOR)',
		'value': 'Wisconsin and Southern Railroad'
	},
	{
		'label': 'WISCONSIN GREAT NORTHERN RAILROAD - (WGNS)',
		'value': 'Wisconsin Great Northern Railroad'
	},
	{
		'label': 'WISCONSIN NORTHERN RAILROAD - (WINV)',
		'value': 'Wisconsin Northern Railroad'
	},
	{
		'label': 'WOLF CREEK RAILROAD - (WCKR)',
		'value': 'Wolf Creek Railroad'
	},
	{
		'label': 'YAKIMA CENTRAL RAILROAD',
		'value': 'Yakima Central Railroad'
	},
	{
		'label': 'YADKIN VALLEY RAILROAD - (YVRR)',
		'value': 'Yadkin Valley Railroad'
	},
	{
		'label': 'YELLOWSTONE VALLEY RAILROAD - (YSVR)',
		'value': 'Yellowstone Valley Railroad'
	},
	{
		'label': 'YORK RAILWAY - (YRC)',
		'value': 'York Railway'
	},
	{
		'label': 'YOUNGSTOWN AND AUSTINTOWN RAILROAD - (YARR)',
		'value': 'Youngstown and Austintown Railroad'
	},
	{
		'label': 'YOUNGSTOWN AND SOUTHEASTERN RAILROAD',
		'value': 'Youngstown and Southeastern Railroad'
	},
	{
		'label': 'YOUNGSTOWN BELT RAILROAD - (YB)',
		'value': 'Youngstown Belt Railroad'
	},
	{
		'label': 'YREKA WESTERN RAILROAD - (YW)',
		'value': 'Yreka Western Railroad'
	}
];

export const shortLinesAllFilter: string = joinFilterV(arrShortLines);

export const shortLinesOptions: Array<LabelValue> = [{ 'label': 'ALL', 'value': 'ALL' }, ...sortArray(arrShortLines)];
