import React, { Component } from 'react';
import '../homepage.css';
import HomePageHeader from './HomePageHeader';
import { Link } from 'react-router-dom';
import HomePageFooter from './HomePageFooter';
import UserProfile from './UserProfile';
import { Cookies } from 'react-cookie';
import { Tabs, Tab, Modal, Collapse, Well, Button, ProgressBar, Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import SimpleReactValidator from 'simple-react-validator';
import 'react-slideshow-image/dist/styles.css';
import ListIconFiltering, {
    getStorageItem
    , getSelectedPropertyTypeAll
} from './ListIconFiltering';
import { LiFilter, createIntermediateArr, createMinMaxValueArr } from './LiFilter';
import {
    LabelValue
    , removeDuplicatesFromArr
    , splitFilter
    , joinFilter
    , joinFilterV
    , TGeoResponse
    , TInFeature
} from '../constant/Types';
import * as MapboxGl from 'mapbox-gl';
import InputMultiSelectComponent from './InputMultiSelectComponent';
import { propertyTypesOptions } from '../constant/PropertyType';
import InputAddressComponent from './InputAddressComponent';
import { arrStates } from '../constant/CountryUSA';
import StringUtils from '../utils/StringUtils';
import { getPreciseDistance } from 'geolib';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import IndustrialSite from '../components/HomePage/public/assets/images/work/IndustrialSite.svg';
import Port from '../components/HomePage/public/assets/images/work/Port.svg';
import Transload from '../components/HomePage/public/assets/images/work/Transload.svg';
import Warehouse from '../components/HomePage/public/assets/images/work/Warehouse.svg';



const { Option } = Select;
const cookies = new Cookies();



// const fadeImages = [
//     Banner1,
//     Banner2,
//     Banner3,
// ];
export const sizeIcoSmall: number = 21;
export const sizeIcoBig: number = 32;
export const placeIcoSmall: number = 43;
export const placeIcoBig: number = 54;
export declare type FitBounds = [[number, number], [number, number]];
export const setStorageItem = (item: string, value: string = ''): void => {
    if (value) {
        localStorage.setItem(item, value);
    } else {
        localStorage.removeItem(item);
    }
}
const initialFitBounds: FitBounds = [[-135, 30.00639444], [-60.237767219999995, 45.935964170000005]];
const isEqualFitBounds = (fitBounds01: FitBounds, fitBounds02: FitBounds) => {
    if (fitBounds01[0][0] !== fitBounds02[0][0]) {
        return false;
    }
    if (fitBounds01[0][1] !== fitBounds02[0][1]) {
        return false;
    }
    if (fitBounds01[1][0] !== fitBounds02[1][0]) {
        return false;
    }
    if (fitBounds01[1][1] !== fitBounds02[1][1]) {
        return false;
    }
    return true;
}
interface PListIconFiltering {
    filterResults: (filter: LiFilter
        , intermediateFeatures: Array<TInFeature>
        , flagPostponedCreate: boolean
        , callback?: () => void) => void;
    handleInitialZoom: () => void;
    updateMapBounds: () => void;
    updateResponse: (flagLoading: boolean, response: { features: Array<TInFeature> }, callback?: () => void) => void;
    sizeIco: number;
    placeIco: number;
    activeHighlightedColor: string;
    privateClientId: string;
}
interface PHomePage {
}
class TIdxCoordinate {
    idx: number = null;
    coordinate: number = null;
}
interface PListing {
    match?: { params: { id: string } };
}

class SHomePage {
    firstname: string = UserProfile.getFirstName() ? UserProfile.getFirstName() : '';
    lastname: string = UserProfile.getLastName() ? UserProfile.getLastName() : '';
    email: string = UserProfile.getEmail() ? UserProfile.getEmail() : '';
    message: string = '';
    showSuccessModal: boolean = false;
    openSearch: boolean = false;
    showfun: boolean = false;
    openPortTerminalsTransloads: boolean = false;
    openWarehouses: boolean = false;
    openSiteBlock1: boolean = false;
    openSiteBlock2: boolean = false;
    openPTTBlock1: boolean = false;
    openPTTBlock2: boolean = false;
    // displayImage: string = fadeImages[0];

    // showIndustrialSites: boolean = true;
    showOfferSites: boolean = true;
    showSearchSites: boolean = false;
    // showPTT: boolean = false;
    showPTTOwner: boolean = false;
    showPTTCustomer: boolean = false;
    // showWarehouse: boolean = false;
    showWarehouseSeller: boolean = false;
    showWarehouseBuyer: boolean = false;
    loading: boolean = false;
    openWarehouseBlock1: boolean = false;
    openWarehouseBlock2: boolean = false;



    showNumberColumnsInListing: number = 2;
    innerWidthToHideListingButtons: number = 767;
    innerWidth: number = window.innerWidth;
    innerHeight: number = window.innerHeight;
    listFilteringRef: ListIconFiltering = null;
    fitBounds: FitBounds = initialFitBounds;
    flagLoading: boolean = true;
    flagStyleLoading: boolean = true;
    flyToOptions: number = 0.8;
    flagPerformZoomForPrivateClient: boolean = false;
    flagDisableZoom: boolean = false;
    flagMapMoveUpdateListing: boolean = true;
    flagMeasureDistances: boolean = false;
    mapboxRef: React.Component = null;
    flagToPreventMapResizeCycle: boolean = false;
    match?: { params: { id: string } };
    organizationName: string = '';
    indoorStorage: string = '';
    parkingCapacity: string = '';
    nearestInterstate: string = '';
    milesToInterstate: string = '';
    nearestAirport: string = '';
    milesToAirport: string = '';
    silos: string = '';
    shortLinesDataLayer: string = '';
    classOneConnectionsDataLayer: string = '';
    cityCoordinates: Array<number> = [];
    showCrudeOilPipelines: boolean = false;
    showPetroleumProductPipelines: boolean = false;
    showMajorPorts: boolean = false;
    showRoadways: boolean = false;

    showIndustrialSites: boolean = true;
    showTerminalsAndTransloads: boolean = true;
    showPortTerminals: boolean = true;
    showWarehouses: boolean = true;
    showIntermodalData: boolean = false;
    showClassOneConnOverlay: boolean = false;

    sitesGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
    portTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
    otherTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
    warehousesGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
    multiPurposeGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
    response: {
        features: Array<TInFeature>
        , latitudeSorted: Array<TIdxCoordinate>
        , longitudeSorted: Array<TIdxCoordinate>
    }
        = {
            features: []
            , latitudeSorted: []
            , longitudeSorted: []
        };
    filter: LiFilter = new LiFilter();
    requiredStateArr: Array<LabelValue> = [];
    sizeIco: number;
    placeIco: number;
    activeHighlightedColor: string;
    privateClientId: string;

    constructor() {
    }
}

class HomePage extends Component<PHomePage, SHomePage> {
    child: HomePageHeader = null;
    validator: SimpleReactValidator = null;

    constructor(props: PHomePage) {
        super(props);
        // localStorage.setItem('propertyFor', 'Lease or Sale');
        this.state = new SHomePage();
    }

    openSearchBox() {
        this.setState({
            openSearch: !this.state.openSearch
        })
    }

    hanldeBlueSectionScroll = () => {
        document.getElementById('blue-section').scrollIntoView({ behavior: 'smooth' });
    }

    parentHandleShow() {
        // this.child.handleShow(nextURL, type, subType);
        alert('Choose only one property type')
    }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
        fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
                'x-access-token': cookies.get('token')
            },
        })
            .then(res => {
                if (res.status === 200) {
                    this.setState({ loading: false });
                } else {
                    console.log('in else part ' + res.status)
                    cookies.remove('token');
                    UserProfile.forgetEmail();
                    UserProfile.forgetFirstName();
                    UserProfile.forgetLastName();
                    this.forceUpdate();
                }
            })
            .catch(err => {
                // this.setState({ loading: false, redirect: true });
            });
        this.loadParametersFromLocalStorage();
    }
    loadParametersFromLocalStorage = () => {
        const filter: LiFilter = new LiFilter().copy(this.state.filter);
        // for (let i = 0; i < arrSiteFormKey.length; i++) {
        //  const key: string = arrSiteFormKey[i].key;
        //  const value: string = getStorageItem(key);
        //  filter[key] = value;
        // }
        // for (let i = 0; i < arrTerminalPortFormKey.length; i++) {
        //  const key: string = arrTerminalPortFormKey[i].key;
        //  const value: string = getStorageItem(key);
        //  filter[key] = value;
        // }
        // for (let i = 0; i < arrWarehouseFormKey.length; i++) {
        //  const key: string = arrWarehouseFormKey[i].key;
        //  const value: string = getStorageItem(key);
        //  filter[key] = value;
        // }
        // for (let i = 0; i < arrMarineAccessFormKey.length; i++) {
        //  const key: string = arrMarineAccessFormKey[i].key;
        //  const value: string = getStorageItem(key);
        //  filter[key] = value;
        //  if (key === 'marineAccess' && value) {
        //      filter.bargeAccess = (value.includes('Barge') || value.includes('Vessel')) ? 'Yes' : '';
        //  }
        // }
        // for (let i = 0; i < arrServingCarriersFormKey.length; i++) {
        //  const key: string = arrServingCarriersFormKey[i].key;
        //  const value: string = getStorageItem(key);
        //  filter[key] = value;
        //  if (key === 'marineAccess' && value) {
        //      filter.bargeAccess = (value.includes('Barge') || value.includes('Vessel')) ? 'Yes' : '';
        //  }
        // }
        const classOneMultiservedCheckbox: boolean = getStorageItem('classOneMultiservedCheckbox') === 'true' ? true : false;
        filter.classOneMultiservedCheckbox = (classOneMultiservedCheckbox ? (true === classOneMultiservedCheckbox) : false);
        const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
        filter.selectedPropertyType = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
        filter.searchByKeywordValue = getStorageItem('searchByKeywordValue');
        filter.selectedUsaStates = getStorageItem('selectedUsaStates');
        filter.address = getStorageItem('address');
        filter.radius = Number(getStorageItem('radius', '0'));
        const cityCoordinates = getStorageItem('cityCoordinates');
        // filter.cityCoordinates = StringUtils.isNotEmpty(cityCoordinates) ? JSON.parse(cityCoordinates) : [];
        filter.city = getStorageItem('city');
        filter.usaState = getStorageItem('usaState');
        filter.zipcode = getStorageItem('zipcode');
        filter.country = getStorageItem('country');
        filter.flagRadiusDisabled = (filter.cityCoordinates && filter.cityCoordinates.length > 1) ? false : true;
        this.setState({
            filter: filter,
        }, () => {
            const { state } = this;
            const { selectedPropertyType } = state.filter;
            const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
            const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
            const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
            const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
            const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
            if (flagIndustrialSitesProperties) {
                this.setListingFor(selectedPropertyType);
            }
            if (flagWarehouse) {
                this.setListingFor(selectedPropertyType);
                // this.addPTTOptionInListingFor();
            }
            if (flagMultipurpose) {
                this.setListingFor(selectedPropertyType);
                // this.addPTTOptionInListingFor();
            }
            // this.createFiltersIntern(true);
        });
    }

    componentDidMount() {
        //document.getElementById('offerSites').classList.add('active');
    }

    scrollToLearnMore = () => {
        // document.getElementById('learn-more').scrollIntoView({behavior: 'smooth'});

        const element = document.getElementById('learn-more');
        const offset = 45;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - 75 - offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }
    renderIconFilters = (): React.ReactElement => {
        const { state, props } = this;
        const {
            showNumberColumnsInListing
            , innerWidthToHideListingButtons
            , innerWidth
            // , specificClientConfiguration
        } = state;
        const flagSmallIcons: boolean = innerWidth <= innerWidthToHideListingButtons;
        const sizeIco: number = flagSmallIcons ? sizeIcoSmall : sizeIcoBig;
        const placeIco: number = flagSmallIcons ? placeIcoSmall : placeIcoBig;
        const activeHighlightedColor = null;
        const keySuffix = '_' + sizeIco + '_' + placeIco + '_' + activeHighlightedColor;
        return (
            <ListIconFiltering
                key={'keySuffix_' + keySuffix}
                ref={this.updateListFilteringRef}
                filterResults={
                    (filter: LiFilter, intermediateFeatures: Array<TInFeature>, flagPostponedCreate: boolean) => {
                        this.filterResults(filter, intermediateFeatures, flagPostponedCreate)
                    }}
                handleInitialZoom={() => this.handleInitialZoom()}
                updateMapBounds={() => this.updateMapBounds()}
                updateResponse={
                    (flagLoading: boolean, response: { features: Array<TInFeature> }, callback?: () => void) => {
                        this.updateResponse(flagLoading, response, callback);
                    }}
                sizeIco={sizeIco}
                placeIco={placeIco}
                activeHighlightedColor={activeHighlightedColor}
                privateClientId={this.getPrivateClientId()}
            />
        )
    }
    getPrivateClientId(): string {
        // const { match } = this.props;
        // if (!match || !match.params || !match.params.id) {
        //  return null;
        // }
        return '0';
    }
    updateListFilteringRef = (el: ListIconFiltering) => {
        if (el) {
            this.setState({
                listFilteringRef: el
            });
        }
    }
    updateResponse = (flagLoading: boolean
        , response: { features: Array<TInFeature> } = { features: [] }
        , callback?: () => void
    ) => {
        const responseAdjusted = this.adjustResposeWithIndexData(response);
        this.setState({
            flagLoading: flagLoading
            , response: responseAdjusted
        }, callback);
    }
    adjustResposeWithIndexData = (response: { features: Array<TInFeature> }): {
        features: Array<TInFeature>
        , latitudeSorted: Array<TIdxCoordinate>
        , longitudeSorted: Array<TIdxCoordinate>
    } => {
        const res: {
            features: Array<TInFeature>
            , latitudeSorted: Array<TIdxCoordinate>
            , longitudeSorted: Array<TIdxCoordinate>
        } = {
            ...response
            , latitudeSorted: []
            , longitudeSorted: []
        };
        res.latitudeSorted = this.getLatitudeToIndexArr(response.features);
        res.longitudeSorted = this.getLongitudeToIndexArr(response.features);
        return res;
    }
    handleInitialZoom = () => {
        this.closePopup();
        this.updateMapBounds();
    }
    getLatitudeToIndexArr = (features: Array<TInFeature>): Array<TIdxCoordinate> => {
        const res: Array<TIdxCoordinate> = [];
        for (let i = 0; i < features.length; i++) {
            const inFeature: TInFeature = features[i];
            const lng = inFeature.geometry.coordinates[0];
            const lat = inFeature.geometry.coordinates[1];
            res.push({ idx: i, coordinate: lat });
        }
        return this.sortToIndexArr(res);
    }

    getLongitudeToIndexArr = (features: Array<TInFeature>): Array<TIdxCoordinate> => {
        const res: Array<TIdxCoordinate> = [];
        for (let i = 0; i < features.length; i++) {
            const inFeature: TInFeature = features[i];
            const lng = inFeature.geometry.coordinates[0];
            const lat = inFeature.geometry.coordinates[1];
            res.push({ idx: i, coordinate: lng });
        }
        return this.sortToIndexArr(res);
    }

    sortToIndexArr = (res: Array<TIdxCoordinate>): Array<TIdxCoordinate> => {
        return res.sort(this.compareToIndexArr);
    }

    compareToIndexArr = (a: TIdxCoordinate, b: TIdxCoordinate) => {
        if (a.coordinate < b.coordinate) {
            return -1;
        }
        if (a.coordinate > b.coordinate) {
            return 1;
        }
        return 0;
    }
    updateMapBounds = () => {
        const fitBounds: FitBounds = this.getMapFitBounds();
        this.setFitBounds(fitBounds);
    }
    setFitBounds = (fitBounds: FitBounds, callback?: () => void): boolean => {
        if (isEqualFitBounds(this.state.fitBounds, fitBounds)) {
            return false;
        }
        this.setState({
            fitBounds: fitBounds,
        }, callback);
        return true;
    }
    closePopup = () => {
        // this.setState({
        //  place: undefined,
        // });
    }
    filterResults(filter: LiFilter, intermediateFeatures: Array<TInFeature>, flagPostponedCreate: boolean, callback?: () => void) {
        const { state } = this;
        // const {
        //   specificClientConfiguration
        //  , flagPerformZoomForPrivateClient
        //  , flagDisableZoom
        // } = state;
        const { searchByKeywordValue, selectedPropertyType } = filter;
        let fitBounds: FitBounds = flagPostponedCreate ? state.fitBounds : this.getMapFitBounds();
        let parameter;

        // if (specificClientConfiguration.filteredListing4ShortLines && specificClientConfiguration.filteredListing4ShortLines.length > 0) {
        //  parameter = 'shortLines';
        //  const propertyTypeArr = splitFilter(joinFilterV(specificClientConfiguration.filteredListing4ShortLines));
        //  intermediateFeatures = createIntermediateArr(propertyTypeArr, intermediateFeatures, parameter);
        // }

        if (selectedPropertyType !== '' && selectedPropertyType !== null) {
            parameter = 'propertyType';
            const propertyTypeArr = [selectedPropertyType];
            intermediateFeatures = createIntermediateArr(propertyTypeArr, intermediateFeatures, parameter, selectedPropertyType, '');
        } else {
            intermediateFeatures.length = 0;
        }

        if (state.organizationName) {
            parameter = 'organizationName';
            const organizationNameArr = [state.organizationName];
            intermediateFeatures = createIntermediateArr(organizationNameArr, intermediateFeatures, parameter, '', '');
        }

        const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
        const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
        const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
        const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
        const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
        if (flagIndustrialSitesProperties || flagMultipurpose) {
            if (filter.selectedPropertyFor !== '' && filter.selectedPropertyFor !== null) {
                if (filter.selectedPropertyFor !== 'Lease and/or Sale') {
                    parameter = 'propertyFor';
                    const propertyForArr = [filter.selectedPropertyFor];
                    intermediateFeatures = createIntermediateArr(propertyForArr, intermediateFeatures, parameter, selectedPropertyType, '');
                }
            }

            if (filter.minAcreage !== '') {
                parameter = 'acreage';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.minAcreage, intermediateFeatures, 'min');
            }

            if (filter.maxAcreage !== '') {
                parameter = 'acreage';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.maxAcreage, intermediateFeatures, 'max');
            }

            if (filter.minPrice !== '') {
                parameter = 'price';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.minPrice, intermediateFeatures, 'min');
            }

            if (filter.maxPrice !== '') {
                parameter = 'price';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.maxPrice, intermediateFeatures, 'max');
            }

            if (filter.siteZoning !== '') {
                parameter = 'zoning';
                const siteZoningArr = [filter.siteZoning];
                intermediateFeatures = createIntermediateArr(siteZoningArr, intermediateFeatures, parameter, 'Sites', '');
            }

            if (filter.selectedBuildingOption !== '' && filter.selectedBuildingOption !== null) {
                parameter = 'building';
                const selectedBuildingOptionArr = [filter.selectedBuildingOption];
                intermediateFeatures = createIntermediateArr(selectedBuildingOptionArr, intermediateFeatures, parameter, 'Sites', '');
            }

            if (filter.siteMinSquareFeet !== '') {
                parameter = 'squareFeet';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.siteMinSquareFeet, intermediateFeatures, 'min');
            }

            if (filter.siteMaxSquareFeet !== '') {
                parameter = 'squareFeet';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.siteMaxSquareFeet, intermediateFeatures, 'max');
            }

            if (filter.siteMinInteriorRoofHeight !== '') {
                parameter = 'interiorRoofHeight';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.siteMinInteriorRoofHeight, intermediateFeatures, 'min');
            }

            if (filter.siteSuitableFor !== '' && filter.siteSuitableFor !== null) {
                parameter = 'suitableFor';
                const siteSuitableForArr = [filter.siteSuitableFor];
                intermediateFeatures = createIntermediateArr(siteSuitableForArr, intermediateFeatures, parameter, 'Sites', '');
            }

            if (filter.sitePipelineAccess != '' && filter.sitePipelineAccess !== null) {
                parameter = 'pipelineAccess';
                const sitePipelineAccessArr = [filter.sitePipelineAccess];
                intermediateFeatures = createIntermediateArr(sitePipelineAccessArr, intermediateFeatures, parameter, 'Sites', '');
            }

            // if (state.selectedWaterSewer !== '' && state.selectedWaterSewer !== null) {
            //  parameter = 'waterSewerInPlace';
            //  const selectedWaterSewerArr = [state.selectedWaterSewer];
            //  intermediateFeatures = createIntermediateArr(selectedWaterSewerArr, intermediateFeatures, parameter, 'Sites', '');
            // }

            if (filter.selectedPowerToSite !== '' && filter.selectedPowerToSite !== null) {
                parameter = 'electricPowerInPlace';
                const selectedPowerToSiteArr = [filter.selectedPowerToSite];
                intermediateFeatures = createIntermediateArr(selectedPowerToSiteArr, intermediateFeatures, parameter, 'Sites', '');
            }

            if (filter.minServiceVoltage !== '') {
                parameter = 'serviceVoltage';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.minServiceVoltage, intermediateFeatures, 'min');
            }

            if (filter.maxServiceVoltage !== '') {
                parameter = 'serviceVoltage';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.maxServiceVoltage, intermediateFeatures, 'max');
            }

            if (filter.minDistanceToSubstation !== '') {
                parameter = 'distanceToSubstation';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.minDistanceToSubstation, intermediateFeatures, 'min');
            }

            if (filter.maxDistanceToSubstation !== '') {
                parameter = 'distanceToSubstation';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.maxDistanceToSubstation, intermediateFeatures, 'max');
            }

            if (filter.naturalGasInPlace !== '' && filter.naturalGasInPlace !== null) {
                parameter = 'naturalGasInPlace';
                const naturalGasInPlaceArr = [filter.naturalGasInPlace];
                intermediateFeatures = createIntermediateArr(naturalGasInPlaceArr, intermediateFeatures, parameter, 'Sites', '');
            }

            if (filter.minLineDiameter !== '') {
                parameter = 'lineDiameter';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.minLineDiameter, intermediateFeatures, 'min');
            }

            if (filter.maxLineDiameter !== '') {
                parameter = 'lineDiameter';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.maxLineDiameter, intermediateFeatures, 'max');
            }

            if (filter.siteEnvionmental !== '' && filter.siteEnvionmental !== null) {
                parameter = 'environmental';
                const siteEnvionmentalArr = [filter.siteEnvionmental];
                intermediateFeatures = createIntermediateArr(siteEnvionmentalArr, intermediateFeatures, parameter, 'Sites', '');
            }

            if (filter.airAttainmentStatus !== '' && filter.airAttainmentStatus !== null) {
                parameter = 'airAttainmentStatus';
                const airAttainmentStatusArr = [filter.airAttainmentStatus];
                intermediateFeatures = createIntermediateArr(airAttainmentStatusArr, intermediateFeatures, parameter, 'Sites', '');
            }
        }

        // // Ports, Terminals and Transloads Search & Filters
        if (flagPortTerminals || flagTerminalsTransload || flagMultipurpose) {
            if (filter.capabilities !== '' && filter.capabilities !== null) {
                parameter = 'capabilities';
                const capabilitiesArr = [filter.capabilities];
                intermediateFeatures = createIntermediateArr(capabilitiesArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.equipments !== '' && filter.equipments !== null) {
                parameter = 'equipments';
                const equipmentsArr = [filter.equipments];
                intermediateFeatures = createIntermediateArr(equipmentsArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.railcarStorageCapacity !== '') {
                parameter = 'railcarStorageCapacity';
                const railcarStorageCapacityArr = [filter.railcarStorageCapacity];
                intermediateFeatures = createIntermediateArr(railcarStorageCapacityArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.productsHandled !== '' && filter.productsHandled !== null) {
                parameter = 'productsHandled';
                const productsHandledArr = [filter.productsHandled];
                intermediateFeatures = createIntermediateArr(productsHandledArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.productStorage !== '' && filter.productStorage !== null) {
                parameter = 'productStorage';
                const productStorageArr = [filter.productStorage];
                intermediateFeatures = createIntermediateArr(productStorageArr, intermediateFeatures, parameter, '', '');
            }

            if (state.indoorStorage !== '' && state.indoorStorage !== null) {
                parameter = 'indoorStorage';
                const indoorStorageArr = [state.indoorStorage];
                intermediateFeatures = createIntermediateArr(indoorStorageArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.pttMinAcreage) {
                parameter = 'pttAcreage';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.pttMinAcreage, intermediateFeatures, 'min');
            }

            if (filter.pttMaxAcreage) {
                parameter = 'pttAcreage';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.pttMaxAcreage, intermediateFeatures, 'max');
            }
        }

        // Warehouse Criteria Search & Filters
        if (flagWarehouse || flagMultipurpose) {
            if (filter.selectedPropertyFor1 !== '' && filter.selectedPropertyFor1 !== null) {
                if (filter.selectedPropertyFor1 !== 'Lease and/or Sale') {
                    parameter = 'propertyFor';
                    const propertyForArr = [filter.selectedPropertyFor1];
                    intermediateFeatures = createIntermediateArr(propertyForArr, intermediateFeatures, parameter, selectedPropertyType, '');
                }
            }

            if (filter.warehouseMinPrice) {
                parameter = 'warehousePrice';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMinPrice, intermediateFeatures, 'min');
            }

            if (filter.warehouseMaxPrice) {
                parameter = 'warehousePrice';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMaxPrice, intermediateFeatures, 'max');
            }

            if (filter.warehouseCapabilities) {
                parameter = 'warehouseCapabilities';
                const warehouseCapabilitiesArr = [filter.warehouseCapabilities];
                intermediateFeatures = createIntermediateArr(warehouseCapabilitiesArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.warehouseMinSize !== '') {
                parameter = 'warehouseMinSize';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMinSize, intermediateFeatures, 'min');
            }

            if (filter.warehouseMaxSize !== '') {
                parameter = 'warehouseMaxSize';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMaxSize, intermediateFeatures, 'max');
            }

            if (filter.temperatureControl !== '' && filter.temperatureControl !== null) {
                parameter = 'temperatureControl';
                const temperatureControlArr = [filter.temperatureControl];
                intermediateFeatures = createIntermediateArr(temperatureControlArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.parkingSpaces !== '') {
                parameter = 'parkingSpaces';
                const parkingSpacesArr = [filter.parkingSpaces];
                intermediateFeatures = createIntermediateArr(parkingSpacesArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.foodGrade !== '' && filter.foodGrade !== null) {
                parameter = 'foodGrade';
                const foodGradeArr = [filter.foodGrade];
                intermediateFeatures = createIntermediateArr(foodGradeArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.freeTradeZone !== '' && filter.freeTradeZone !== null) {
                parameter = 'freeTradeZone';
                const freeTradeZoneArr = [filter.freeTradeZone];
                intermediateFeatures = createIntermediateArr(freeTradeZoneArr, intermediateFeatures, parameter, '', '');
            }

            if (filter.minRoofHeight) {
                parameter = 'roofHeight';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.minRoofHeight, intermediateFeatures, 'min');
            }

            if (filter.maxRoofHeight) {
                parameter = 'roofHeight';
                intermediateFeatures = createMinMaxValueArr(parameter, filter.maxRoofHeight, intermediateFeatures, 'max');
            }
        }

        // // Marine Access Search & Filters
        if (filter.marineAccess !== '' && filter.marineAccess !== null) {
            parameter = 'marineAccess';
            const marineAccessArr = [filter.marineAccess];
            intermediateFeatures = createIntermediateArr(marineAccessArr, intermediateFeatures, parameter, '', '');
        }

        if (filter.bargeAccess !== '' && filter.bargeAccess !== null) {
            parameter = 'bargeAccess';
            const selectedSiteBargeAccessArr = [filter.bargeAccess];
            intermediateFeatures = createIntermediateArr(selectedSiteBargeAccessArr, intermediateFeatures, parameter, '', '');
        }

        if (filter.docInPlace !== '' && filter.docInPlace !== null) {
            parameter = 'docInPlace';
            const docInPlaceArr = [filter.docInPlace];
            intermediateFeatures = createIntermediateArr(docInPlaceArr, intermediateFeatures, parameter, '', '');
        }

        if (filter.navigableWater !== '') {
            parameter = 'distancetoWater';
            const navigableWaterArr = [filter.navigableWater];
            intermediateFeatures = createIntermediateArr(navigableWaterArr, intermediateFeatures, parameter, '', '');
        }

        if (filter.waterways !== '' && filter.waterways !== null) {
            parameter = 'waterways';
            const waterwaysArr = [filter.waterways];
            intermediateFeatures = createIntermediateArr(waterwaysArr, intermediateFeatures, parameter, '', '');
        }

        // Serving Rail Carriers Search & Filters

        // this.setState({
        //  shortLines:localStorage.getItem('shortLinesLabel') ? localStorage.getItem('shortLinesLabel'):''
        // })
        if (filter.shortLinesLabel !== '' && filter.shortLinesLabel !== null) {
            parameter = 'shortLines';
            const shortLinesArr = [filter.shortLinesLabel];
            intermediateFeatures = createIntermediateArr(shortLinesArr, intermediateFeatures, parameter, '', '');
        }

        // code to create array if multi-served class I conn and/or Class I conn selected
        const tempIntermediateFeatures = intermediateFeatures;
        const classOneMultiservedArr = [];
        if (filter.classOneMultiservedCheckbox && filter.classOneConnections) {
            // create array of records which contains multiple class I conn
            for (let k = 0; k < tempIntermediateFeatures.length; k++) {
                if (tempIntermediateFeatures[k].properties.classOneConnections.includes('*')) {
                    classOneMultiservedArr.push(tempIntermediateFeatures[k]);
                }
            }

            parameter = 'classOneConnections';
            const siteClassOneConnectionsArr = [filter.classOneConnections];
            intermediateFeatures = createIntermediateArr(siteClassOneConnectionsArr, intermediateFeatures, parameter, '', '');

            // concat above 2 arrays
            Array.prototype.push.apply(intermediateFeatures, classOneMultiservedArr);

        } else if (filter.classOneMultiservedCheckbox) {
            // create array of records which contains multiple class I conn
            for (let k = 0; k < tempIntermediateFeatures.length; k++) {
                if (tempIntermediateFeatures[k].properties.classOneConnections.includes('*')) {
                    classOneMultiservedArr.push(tempIntermediateFeatures[k]);
                }
            }
            intermediateFeatures = classOneMultiservedArr;

        } else if (filter.classOneConnections) {
            parameter = 'classOneConnections';
            const siteClassOneConnectionsArr = [filter.classOneConnections];
            intermediateFeatures = createIntermediateArr(siteClassOneConnectionsArr, intermediateFeatures, parameter, '', '');
        } else {
            console.log('Both class one conn and multi-served checkbox are not selected')
        }

        if (filter.railInPlace !== '' && filter.railInPlace !== null) {
            parameter = 'railInPlace';
            const selectedRailInPlaceArr = [filter.railInPlace];
            intermediateFeatures = createIntermediateArr(selectedRailInPlaceArr, intermediateFeatures, parameter, '', '');
        }

        if (filter.railcarStorage !== '' && filter.railcarStorage !== null) {
            parameter = 'railcarStorage';
            const railcarStorageArr = [filter.railcarStorage];
            intermediateFeatures = createIntermediateArr(railcarStorageArr, intermediateFeatures, parameter, '', '');
        }

        if (filter.unitTrainCapable) {
            parameter = 'unitTrainCapable';
            const unitTrainCapableArr = [filter.unitTrainCapable];
            intermediateFeatures = createIntermediateArr(unitTrainCapableArr, intermediateFeatures, parameter, '', '');
        }

        if (filter.railcarCapacity) {
            parameter = 'railcarCapacity';
            intermediateFeatures = createMinMaxValueArr(parameter, filter.railcarCapacity, intermediateFeatures, 'min');
        }

        // Additional Attributes Search and Filters
        if (state.parkingCapacity !== '' && state.parkingCapacity !== null) {
            parameter = 'parkingCapacity';
            const parkingCapacityArr = [state.parkingCapacity];
            intermediateFeatures = createIntermediateArr(parkingCapacityArr, intermediateFeatures, parameter, '', '');
        }

        if (state.nearestInterstate !== '') {
            parameter = 'nearestInterstate';
            const nearestInterstateArr = [state.nearestInterstate];
            intermediateFeatures = createIntermediateArr(nearestInterstateArr, intermediateFeatures, parameter, '', '');
        }

        if (state.milesToInterstate !== '') {
            parameter = 'milesToInterstate';
            const milesToInterstateArr = [state.milesToInterstate];
            intermediateFeatures = createIntermediateArr(milesToInterstateArr, intermediateFeatures, parameter, '', '');
        }

        if (state.nearestAirport !== '') {
            parameter = 'nearestAirport';
            const nearestAirportArr = [state.nearestAirport];
            intermediateFeatures = createIntermediateArr(nearestAirportArr, intermediateFeatures, parameter, '', '');
        }

        if (state.milesToAirport !== '') {
            parameter = 'milesToAirport';
            const milesToAirportArr = [state.milesToAirport];
            intermediateFeatures = createIntermediateArr(milesToAirportArr, intermediateFeatures, parameter, '', '');
        }

        if (state.silos !== '' && state.silos !== null) {
            parameter = 'silos';
            const silosArr = [state.silos];
            intermediateFeatures = createIntermediateArr(silosArr, intermediateFeatures, parameter, '', '');
        }

        intermediateFeatures = removeDuplicatesFromArr(intermediateFeatures);

        const originalResponse = getStorageItem('originalResponse');
        const geoResponse: { features: Array<TInFeature> } = originalResponse && originalResponse.length > 0 ? JSON.parse(originalResponse) : [];

        const sitesGeoJSONArray: { features: Array<TInFeature> } = { features: null };
        const warehousesGeoJSONArray: { features: Array<TInFeature> } = { features: null };
        const portTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: null };
        const otherTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: null };
        const multiPurposeGeoJSONArray: { features: Array<TInFeature> } = { features: null };

        // Filter real estates that contain the values from the search field in properties
        const minSymbolsToSearch = 2
        if (searchByKeywordValue && searchByKeywordValue.length >= minSymbolsToSearch) {
            intermediateFeatures = this.filterEstatesByKeywords(intermediateFeatures, filter.searchByKeywordValue);
        }

        const realEstates = this.filterEstatesByTypes(intermediateFeatures);

        geoResponse.features = intermediateFeatures;
        sitesGeoJSONArray.features = realEstates.industrialSites;
        portTerminalsGeoJSONArray.features = realEstates.portTerminals;
        otherTerminalsGeoJSONArray.features = realEstates.terminalsAndFacilities;
        warehousesGeoJSONArray.features = realEstates.warehouseAndDistributionCenter;
        multiPurposeGeoJSONArray.features = realEstates.multiPurpose;
        // if (flagPerformZoomForPrivateClient) {
        //  let deltaPlus: number = 4.0;
        //  if (specificClientConfiguration.outlineInDegrees) {
        //      deltaPlus = specificClientConfiguration.outlineInDegrees;
        //  }
        //  fitBounds = this.calculateBounds4Features(intermediateFeatures, deltaPlus, fitBounds);
        // } else if (!flagPostponedCreate && !flagDisableZoom) {
        //  fitBounds = this.calculateBounds4Features(intermediateFeatures, 2.2, fitBounds);
        // }
        fitBounds = this.calculateBounds4Features(intermediateFeatures, 2.2, fitBounds);
        const flagMajorports: boolean = getStorageItem('showMajorPorts') != 'true' ? false : true;
        const flagshowIntermodalData: boolean = getStorageItem('showIntermodalData') != 'true' ? false : true;
        const flagshowCrudeOilPipelines: boolean = getStorageItem('showCrudeOilPipelines') != 'true' ? false : true;
        const flagshowPetroleumProductPipelines: boolean = getStorageItem('showPetroleumProductPipelines') != 'true' ? false : true;
        const flagshowRoadways: boolean = getStorageItem('showRoadways') != 'true' ? false : true;
        const flagclassOneConnectionsDataLayer: string = getStorageItem('classOneConnectionsDataLayer') ? getStorageItem('classOneConnectionsDataLayer') : '';
        const flagshortLinesDataLayer: string = getStorageItem('shortLinesDataLayer') ? getStorageItem('shortLinesDataLayer') : '';
        const showIndustrialSites = flagIndustrialSitesProperties;
        const showTerminalsAndTransloads = flagTerminalsTransload;
        const showPortTerminals = flagPortTerminals;
        const showWarehouses = flagWarehouse;
        const responseAdjusted = this.adjustResposeWithIndexData(geoResponse);
        this.setState({
            response: responseAdjusted
            , sitesGeoJSONArray: sitesGeoJSONArray
            , warehousesGeoJSONArray: warehousesGeoJSONArray
            , portTerminalsGeoJSONArray: portTerminalsGeoJSONArray
            , otherTerminalsGeoJSONArray: otherTerminalsGeoJSONArray
            , multiPurposeGeoJSONArray: multiPurposeGeoJSONArray
            , cityCoordinates: filter.cityCoordinates
            , fitBounds: fitBounds
            , flagPerformZoomForPrivateClient: false
            , flagDisableZoom: false
            , classOneConnectionsDataLayer: flagclassOneConnectionsDataLayer
            , shortLinesDataLayer: flagshortLinesDataLayer
            , showMajorPorts: flagMajorports
            , showRoadways: flagshowRoadways
            , showIntermodalData: flagshowIntermodalData
            , showCrudeOilPipelines: flagshowCrudeOilPipelines
            , showPetroleumProductPipelines: flagshowPetroleumProductPipelines
            , showIndustrialSites: showIndustrialSites
            , showTerminalsAndTransloads: showTerminalsAndTransloads
            , showPortTerminals: showPortTerminals
            , showWarehouses: showWarehouses
        }, callback);
    }
    getMapFitBounds = (flagPerformResize: boolean = true): FitBounds => {
        return this.getMapBounds(true, flagPerformResize);
    }
    flagToPreventMapResizeCycle: boolean = false;
    getMapBounds = (flagCheckLoaded: boolean, flagPerformResize: boolean = true): FitBounds => {
        let fitBounds: FitBounds = this.state.fitBounds;
        const map = this.getMapRef();
        if (flagCheckLoaded && map) {
            flagCheckLoaded = map.loaded();
        } else {
            flagCheckLoaded = true;
        }
        if (map && flagCheckLoaded && !this.state.flagLoading) {
            if (flagPerformResize) {
                this.flagToPreventMapResizeCycle = true;
                map.resize();
                this.flagToPreventMapResizeCycle = false;
            }
            const lngLatBounds = map.getBounds();
            const southWest: [number, number] = [lngLatBounds.getWest(), lngLatBounds.getSouth()];
            const northEast: [number, number] = [lngLatBounds.getEast(), lngLatBounds.getNorth()];
            fitBounds = [southWest, northEast];
        }
        return fitBounds;
    }
    getMapRef = (): MapboxGl.Map => {
        const { state } = this;
        if (!state) {
            return null;
        }
        const { mapboxRef } = state;
        if (!mapboxRef) {
            return null;
        }
        const stateMapbox: any = mapboxRef.state;
        if (!stateMapbox) {
            return null;
        }
        const map: MapboxGl.Map = stateMapbox.map;
        if (!map) {
            return null;
        }
        return map;
    }
    calculateBounds4Features = (intermediateFeatures: Array<TInFeature>, deltaPlus: number = 0.0, boudsDefault: FitBounds = null): FitBounds => {
        let res: FitBounds = boudsDefault;
        if (intermediateFeatures.length <= 0) {
            return res;
        }
        let lngMin = 1000000000, lngMax = -1000000000;
        let latMin = 1000000000, latMax = -1000000000;
        for (let i = 0; i < intermediateFeatures.length; i++) {
            const lng = intermediateFeatures[i].geometry.coordinates[0];
            const lat = intermediateFeatures[i].geometry.coordinates[1];
            if (lngMin > lng) {
                lngMin = lng;
            }
            if (lngMax < lng) {
                lngMax = lng;
            }
            if (latMin > lat) {
                latMin = lat;
            }
            if (latMax < lat) {
                latMax = lat;
            }
        }
        lngMin -= deltaPlus;
        latMin -= deltaPlus;
        lngMax += deltaPlus;
        latMax += deltaPlus;
        res = [[lngMin, latMin], [lngMax, latMax]];
        return res;
    }
    filterEstatesByKeywords = (data: Array<TInFeature>, searchByKeywordValue: string): Array<TInFeature> => {
        const keywords = searchByKeywordValue.toLowerCase().split(',');
        const minSymbolsToSearch = 2;
        const multipleKeywordsDelimeter = '+';
        return data.filter(({ properties: { ...restProps } = {} }) => {
            // convert real estates attributes values into a single string
            const props = Object.values(restProps)
                .filter((value) => value && typeof value !== 'object')
                .join(',')
                .toLowerCase();
            return keywords.some((keyword) => {
                // find real estate which includes all keywords
                if (keyword.includes(multipleKeywordsDelimeter)) {
                    const multipleKeywords = keyword
                        .split('+')
                        .filter((keyword) => keyword && keyword.length >= minSymbolsToSearch);
                    if (!multipleKeywords.length) return true;
                    return multipleKeywords.every((keyword) => props.includes(keyword));
                }
                return props.includes(keyword);
            });
        });
    }

    filterEstatesByTypes = (data: Array<TInFeature>): any => {
        const types = {
            industrialSites: 'Industrial Sites & Properties',
            portTerminals: 'Port Terminals',
            terminalsAndFacilities: 'Terminals and Transload Facilities',
            warehouseAndDistributionCenter: 'Warehouse/Distribution Center',
            multiPurpose: 'Multipurpose',
        }
        const result = Object.keys(types).reduce((obj, key) => {
            const groupedByType: Array<TInFeature> = data.filter(({ properties: { propertyType = '' } = {} }) => {
                const propertyTypeArr: Array<string> = [
                    'Industrial Sites & Properties*Port Terminals*Warehouse/Distribution Center',
                    'Industrial Sites & Properties*Terminals and Transload Facilities*Warehouse/Distribution Center',
                    'Industrial Sites & Properties*Port Terminals',
                    'Industrial Sites & Properties*Terminals and Transload Facilities',
                    'Port Terminals*Warehouse/Distribution Center',
                    'Terminals and Transload Facilities*Warehouse/Distribution Center',
                    'Industrial Sites & Properties*Warehouse/Distribution Center',
                ];
                if (types[key] === 'Multipurpose') {
                    if (propertyTypeArr.includes(propertyType)) {
                        return true;
                    }
                }
                if (propertyType) {
                    return propertyType === types[key];
                }
                return false;
            });
            obj[key] = groupedByType;
            return obj;
        }, {});
        return result;
    }
    setFirstName = (event) => {
        this.setState({ firstname: event.target.value });
    }

    setLastName = (event) => {
        this.setState({ lastname: event.target.value });
    }

    setEmail = (event) => {
        this.setState({ email: event.target.value });
    }

    setMessage = (event) => {
        this.setState({ message: event.target.value });
    }

    handleContactUs = () => {
        if (this.validator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us',
                {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'x-access-token': cookies.get('token')
                    },
                    // withCredentials: true,
                    place_owner_name: '',
                    place_id: '',
                    name: this.state.firstname + ' ' + this.state.lastname,
                    email: this.state.email,
                    message: this.state.message,
                }).then(response => {
                    if (response.status === 200) {
                        this.setState({
                            message: '',
                            showSuccessModal: true,
                        });
                    } else {

                    }
                }).catch(function (error) {
                    console.log(error)
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleSuccessModal = () => {
        this.setState({ showSuccessModal: false });
    }

    closeInfoBox = () => {
        this.setState({
            openSiteBlock1: false,
            openSiteBlock2: false,
            openPTTBlock1: false,
            openPTTBlock2: false,
            openWarehouseBlock1: false,
            openWarehouseBlock2: false
        });
    }
    handleSelectPropertyType = (name: string, valueArr: Array<LabelValue>) => {
        console.log("valueArr", valueArr)
        const selectedPropertyType = joinFilterV(valueArr);
        const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
        const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
        const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
        const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
        const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
        if (flagIndustrialSitesProperties) {
            this.setListingFor(selectedPropertyType);
        }
        if (flagWarehouse) {
            // this.addColdStorageInCapabilities();
            this.setListingFor(selectedPropertyType);
        }
        if (flagMultipurpose) {
            this.setListingFor(selectedPropertyType);
            // this.addColdStorageInCapabilities();
        }
        if (!flagWarehouse && !flagMultipurpose) {
            // this.removePTTOptionFromListingFor();
        }
        const filter: LiFilter = new LiFilter().copy(this.state.filter);
        filter.selectedPropertyType = selectedPropertyType;
        this.setState({
            filter: filter
        }, () => {
            // this.createFilters();
            setStorageItem(name, selectedPropertyType);
        });
    }
    setListingFor = (value: string) => {
        if (value.includes('Warehouse') || value.includes('Multipurpose')) {
            // this.addPTTOptionInListingFor();
        }
    }
    resetAddress = (callback?: () => void) => {
        const filter: LiFilter = new LiFilter().copy(this.state.filter);
        filter.resetAddress();
        this.setState({
            filter: filter,
        }, () => {
            setStorageItem('selectedUsaStates');
            setStorageItem('city');
            setStorageItem('usaState');
            setStorageItem('zipcode');
            setStorageItem('country');
            setStorageItem('address');
            if (callback) {
                callback();
            }
        });
    }
    clearAddress = () => {
        this.handleAddressChange('', [], '', '', '', '');
    }
    handleAddressChange = (
        address: string
        , coordinates: Array<number>
        , city: string
        , usaState: string
        , zipCode: string
        , country: string
    ) => {
        const flagCoordinates: boolean = coordinates !== null && coordinates.length > 1;
        const { state } = this;
        const filter: LiFilter = new LiFilter().copy(state.filter);
        filter.selectedUsaStates = '';
        filter.radius = 0;
        filter.address = address ? address : '';
        filter.city = city ? city : '';
        filter.usaState = usaState ? usaState : '';
        filter.zipcode = zipCode ? zipCode : '';
        filter.country = country ? country : '';
        filter.flagRadiusDisabled = flagCoordinates ? false : true;
        filter.cityCoordinates = flagCoordinates ? coordinates : [];
        this.setState({
            filter: filter,
        }, () => {
            setStorageItem('selectedUsaStates');
            setStorageItem('radius', '' + filter.radius);
            setStorageItem('address', filter.address);
            setStorageItem('cityCoordinates', JSON.stringify(filter.cityCoordinates));
            setStorageItem('city', filter.city);
            setStorageItem('usaState', filter.usaState);
            setStorageItem('zipcode', filter.zipcode);
            setStorageItem('country', filter.country);
            // this.createFilters();
        });
    }



    render() {
        return (
            <section className="iq-fancy-box-section"> 

                <div className="container">
                <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center iq-title-box iq-title-default iq-title-box-2 wow fadeInUp" data-wow-duration="0.6s">
                                {/* <span className="iq-subtitle">How It's Work</span> */}
                                <div style={{ paddingTop: 20 }} className="iq-btn-container">
                                    <Link to={{
                                        pathname: "/view-listings", state: {
                                            propertyType: 'Industrial Sites & Properties*Port Terminals*Terminals and Transload Facilities*Warehouse/Distribution Center',
                                            clearfilter : "yes"
                                        }
                                    }}>
                                        <span className="iq-button iq-btn-round has-icon btn-icon-right d-inline" style={{ marginBottom: 5, color: 'white' }}>Search All Listings</span></Link>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={'col-lg-3 mb-lg-0 mb-5'} >
                            <div className="iq-process iq-process-step-style-6 text-center wow fadeInUp" data-wow-duration="0.6s">
                                <div className="iq-process-step">
                                    <div className="iq-step-content">
                                        <img style={{ maxHeight: 200, maxWidth: 200 }} className="hover-img img-fluid" src={IndustrialSite} alt="fancybox" />
                                    </div>
                                    <div className="iq-step-text-area">
                                        <h4 style={{ fontSize: '1.563em', color: '#142149' }} className="iq-step-title mt-3 mb-3">Industrial Sites</h4>
                                        <span style={{ fontSize: '1rem', fontWeight: 300, fontFamily: 'Roboto, sans-serif', lineHeight: 1.5, color: '#4a4a4a', overflowX: 'hidden' }} className="iq-step-desc">A full range of true industrial 20+ acre greenfield and brownfield sites and buildings.</span>
                                    </div>
                                    <div style={{ paddingTop: 45 }} className="iq-btn-container">
                                        <Link to={{
                                            pathname: "/view-listings", state: {
                                                propertyType: 'Industrial Sites & Properties',
                                                clearfilter : "yes"
                                            }
                                        }}>
                                            <span className="iq-button iq-btn-round has-icon btn-icon-right d-inline" style={{ marginBottom: 5, color: 'white' }}>Search</span></Link>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-3 mb-lg-0 mb-5'} >
                            <div className="iq-process iq-process-step-style-6 text-center wow fadeInUp" data-wow-duration="0.6s">
                                <div className="iq-process-step">
                                    <div className="iq-step-content">
                                        <img style={{ maxHeight: 200, maxWidth: 200 }} className="hover-img img-fluid" src={Port} alt="fancybox" />
                                    </div>
                                    <div className="iq-step-text-area">
                                        <h4 style={{ fontSize: '1.563em', color: '#142149' }} className="iq-step-title mt-3 mb-3">Ports</h4>
                                        <span style={{ fontSize: '1rem', fontWeight: 300, fontFamily: 'Roboto, sans-serif', lineHeight: 1.5, color: '#4a4a4a', overflowX: 'hidden' }} className="iq-step-desc">Port facilities, bulk terminals and transload facilities handling containers, bulk, breakbulk and project cargo.</span>
                                    </div>
                                    <div style={{ paddingTop: 45 }} className="iq-btn-container">
                                        <Link to={{
                                            pathname: "/view-listings", state: {
                                                propertyType: 'Port Terminals',
                                                clearfilter : "yes"
                                            }
                                        }}>
                                            <span className="iq-button iq-btn-round has-icon btn-icon-right d-inline" style={{ marginBottom: 5, color: 'white' }}>Search</span></Link>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-3 mb-lg-0 mb-5'} >
                            <div className="iq-process iq-process-step-style-6 text-center wow fadeInUp" data-wow-duration="0.6s">
                                <div className="iq-process-step">
                                    <div className="iq-step-content">
                                        <img style={{ maxHeight: 200, maxWidth: 200 }} className="hover-img img-fluid" src={Transload} alt="fancybox" />
                                    </div>
                                    <div className="iq-step-text-area">
                                        <h4 style={{ fontSize: '1.563em', color: '#142149' }} className="iq-step-title mt-3 mb-3">Terminals & Transloads</h4>
                                        <span style={{ fontSize: '1rem', fontWeight: 300, fontFamily: 'Roboto, sans-serif', lineHeight: 1.5, color: '#4a4a4a', overflowX: 'hidden' }} className="iq-step-desc">Port facilities, bulk terminals and transload facilities handling containers, bulk, breakbulk and project cargo.</span>
                                    </div>
                                    <div style={{ paddingTop: 45 }} className="iq-btn-container">
                                        <Link to={{
                                            pathname: "/view-listings", state: {
                                                propertyType: 'Terminals and Transload Facilities',
                                                clearfilter : "yes"
                                            }
                                        }}>
                                            <span className="iq-button iq-btn-round has-icon btn-icon-right d-inline" style={{ marginBottom: 5, color: 'white' }}>Search</span></Link>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className={'col-lg-3 mb-lg-0 mb-5'} >
                            <div className="iq-process iq-process-step-style-6 text-center wow fadeInUp" data-wow-duration="0.6s">
                                <div className="iq-process-step">
                                    <div className="iq-step-content">
                                        <img style={{ maxHeight: 200, maxWidth: 200 }} className="hover-img img-fluid" src={Warehouse} alt="fancybox" />
                                    </div>
                                    <div className="iq-step-text-area">
                                        <h4 style={{ fontSize: '1.563em', color: '#142149' }} className="iq-step-title mt-3 mb-3">Warehouses</h4>
                                        <span style={{ fontSize: '1rem', fontWeight: 300, fontFamily: 'Roboto, sans-serif', lineHeight: 1.5, color: '#4a4a4a', overflowX: 'hidden' }} className="iq-step-desc">Covered storage of 100,000+ sq. ft. for sale or lease, as well as on demand 3PL/public warehouse service throughout North America</span>
                                    </div>
                                    <div style={{ paddingTop: 20 }} className="iq-btn-container">
                                        <Link to={{
                                            pathname: "/view-listings", state: {
                                                propertyType: 'Warehouse/Distribution Center',
                                                clearfilter : "yes"
                                            }
                                        }}>
                                            <span className="iq-button iq-btn-round has-icon btn-icon-right d-inline" style={{ marginBottom: 5, color: 'white' }}>Search</span></Link>
                                            </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
            </section>
        )
    }
}

export default HomePage;


