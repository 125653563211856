import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import gtag from 'ga-gtag'
class HomePageVideo extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showVideoModal: false
        }
    }
    componentWillMount() {

    }

    showVideoFunModal = () => {
        gtag('event', 'home-page-banner-introduction-video', {
			poll_title: 'home-page-banner-introduction-video',
		  })
        this.setState({
            showVideoModal: true,
        });

    }
    hideModalGetHelp = () => {
        this.setState({
            showVideoModal: false,
        });

    }

    render() {
        return (
            <>
                <div className='video-homepage-bg'>
                    <div className='video-homepage-button'>
                        <i onClick={this.showVideoFunModal} style={{ color: 'rgb(255, 255, 255)', fontSize: 25, cursor: 'pointer' }} class="fa fa-play" aria-hidden="true"></i>

                    </div>
                    <div style={{ marginLeft: 70 }}>
                        <h1 style={{ zIndex: 12, fontSize: 18, fontWeight: 700, borderColor: 'rgb(94, 114, 144)', color: 'rgb(20, 33, 73)', transformOrigin: '50% 50% 0px', textTransform: 'none' }} className='textgyreadventor'>Learn More</h1>
                        <h3 style={{ zIndex: 12, fontSize: 14, fontWeight: 400, borderColor: 'rgb(94, 114, 144)', color: 'rgb(20, 33, 73)', transformOrigin: '50% 50% 0px', textTransform: 'none', fontFamily: 'Roboto, sans-serif' }}>Introduction to Industrial Navigator</h3>
                    </div>


                </div><br />

                <Modal dialogClassName='custom-modal-help-data' show={this.state.showVideoModal} onHide={this.hideModalGetHelp} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ paddingTop: '2rem', paddingLeft: '1rem', paddingRight: '1rem' }} closeButton>
                        <h3 style={{ fontSize: 20 }}>Introduction to Industrial Navigator</h3>

                    </Modal.Header>
                    <Modal.Body style={{ background: 'white', paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                    <iframe class="video iframe-width" width="500" height="400" src="https://www.youtube.com/embed/Mj0cGDxfffs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideModalGetHelp}>Close</button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}

export default HomePageVideo;
