import React, { Component } from 'react';
import '../homepage.css';
import HomePageHeader from './HomePageHeader';
import { Link } from 'react-router-dom';
import HomePageFooter from './HomePageFooter';
import UserProfile from './UserProfile';
import { Cookies } from 'react-cookie';
import { Tabs, Tab, Modal, Collapse, Well, Button, ProgressBar, Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import SimpleReactValidator from 'simple-react-validator';
import 'react-slideshow-image/dist/styles.css';
import ReCAPTCHA from 'react-google-recaptcha';
import LinkedInTag from 'react-linkedin-insight';
const recaptchaRef = React.createRef();
const cookies = new Cookies();


interface PHomePage {
}

class SHomePage {
    firstname: string = UserProfile.getFirstName() ? UserProfile.getFirstName() : '';
    lastname: string = UserProfile.getLastName() ? UserProfile.getLastName() : '';
    email: string = UserProfile.getEmail() ? UserProfile.getEmail() : '';
    company: string = '';
    message: string = '';
    mobile: string = '';
    Title: string = '';
    Captcha: string = '';
    contactusbutton: boolean = false;
    showSuccessModal: boolean = false;
    loading: boolean = false;
    INNews: boolean = true;
    PLGnews: boolean = true;

    constructor() {
    }
}

class HomePage extends Component<PHomePage, SHomePage> {
    child: HomePageHeader = null;
    validator: SimpleReactValidator = null;

    constructor(props: PHomePage) {
        super(props);
        // localStorage.setItem('propertyFor', 'Lease or Sale');
        this.state = new SHomePage();



    }



    componentWillMount() {
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
        // fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
        //         'x-access-token': cookies.get('token')
        //     },
        // })
        //     .then(res => {
        //         if (res.status === 200) {
        //             this.setState({ loading: false });
        //         } else {
        //             console.log('in else part ' + res.status)
        //             cookies.remove('token');
        //             UserProfile.forgetEmail();
        //             UserProfile.forgetFirstName();
        //             UserProfile.forgetLastName();
        //             this.forceUpdate();
        //         }
        //     })
        //     .catch(err => {
        //         // this.setState({ loading: false, redirect: true });
        //     });
    }

    componentDidMount() {
        //document.getElementById('offerSites').classList.add('active');
    }
    onRecaptchaChange = event => {
        console.log('Captcha value: ', event);
        this.setState({ Captcha: event });
        const regi_errors = document.getElementById('regi_errors');
        regi_errors.innerHTML = '';
        document.getElementById("regi_errors").classList.remove("alert-danger");
    };


    setFirstName = (event) => {
        this.setState({ firstname: event.target.value });
    }

    setLastName = (event) => {
        this.setState({ lastname: event.target.value });
    }
    setCompany = (event) => {
        this.setState({ company: event.target.value });
    }
    setTitle = (event) => {
        this.setState({ Title: event.target.value });
    }
    setMobile = (event) => {
        this.setState({ mobile: event.target.value });
    }

    setEmail = (event) => {
        this.setState({ email: event.target.value });
    }

    setMessage = (event) => {
        this.setState({ message: event.target.value });
    }

    handleContactUs = () => {
        if (this.validator.allValid()) {
            LinkedInTag.track('9791964');
            if (this.state.Captcha == undefined || this.state.Captcha == '') {
                const regi_errors = document.getElementById('regi_errors');
                regi_errors.innerHTML = '';
                let error_html = document.createElement('div');
                error_html.innerHTML = 'Please Select Captcha';
                document.getElementById('regi_errors').appendChild(error_html);
                document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
            } else if (this.state.Captcha != undefined || this.state.Captcha != '') {
                const regi_errors = document.getElementById('regi_errors');
                regi_errors.innerHTML = '';
                document.getElementById("regi_errors").classList.remove("alert-danger");
                this.setState({ contactusbutton: true })
                axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us-private-label',
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            // 'x-access-token': cookies.get('token')
                        },
                        // withCredentials: true,
                        name: this.state.firstname + ' ' + this.state.lastname,
                        email: this.state.email,
                        company: this.state.company,
                        Title: this.state.Title,
                        Mobile: this.state.mobile,
                        message: this.state.message,
                        INNews: this.state.INNews ? "Yes" : "No",
                        PLGnews: this.state.PLGnews ? "Yes" : "No",
                        reacatpcha_value: this.state.Captcha,
                    }).then(response => {
                        if (response.status === 200) {
                            this.setState({
                                message: '',
                                showSuccessModal: true,
                                contactusbutton: false,
                            });
                        } else {
                            alert("Something Went Wrong")
                            this.setState({
                                contactusbutton: false,
                            });
                        }
                    }).catch(function (error) {
                        console.log(error)
                        let error_html = document.createElement('div');
                        error_html.innerHTML = 'Captcha verification failed';
                        document.getElementById('regi_errors').appendChild(error_html);
                        document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
                    });
            }

        } else {
            this.validator.showMessages();
            this.forceUpdate();
        }
    }

    handleSuccessModal = () => {
        this.setState({ showSuccessModal: false });
    }



    render() {
        return (
            <div style={{ fontFamily: 'Roboto, sans-serif', fontSize: '1rem', fontWeight: 300, lineHeight: 1.5, color: ' #4a4a4a', overflowX: 'hidden' }} className='body-home'>

                <div className='col-lg-12 col-md-12 padding-top-30'>
                    <div className='row'>
                        <form className="wpcf7">
                            <div className="row">
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10 '>
                                    <input type='text' className='inputtypecontact margin-bottom-0-imp' onChange={this.setFirstName} value={this.state.firstname} required placeholder='Your First Name'></input>
                                    {this.validator.message('first name', this.state.firstname, 'required')}
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <input type='text' className='inputtypecontact margin-bottom-0-imp' onChange={this.setLastName} value={this.state.lastname} required placeholder='Your Last Name'></input>
                                    {this.validator.message('last name', this.state.lastname, 'required')}
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <input type='text' className='inputtypecontact margin-bottom-0-imp' onChange={this.setCompany} value={this.state.company} required placeholder='Your Company Name'></input>
                                    {this.validator.message('company name', this.state.company, 'required')}
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <input type='text' className='inputtypecontact margin-bottom-0-imp' onChange={this.setTitle} value={this.state.Title} required placeholder='Your Title'></input>
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <input type='email' className='inputtypecontact margin-bottom-0-imp' onChange={this.setEmail} value={this.state.email} required placeholder='Your Email'></input>
                                    {this.validator.message('email', this.state.email, 'required')}
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <input type='text' className='inputtypecontact margin-bottom-0-imp' onChange={this.setMobile} value={this.state.mobile} required placeholder='Your mobile number'></input>
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <textarea className='form-control margin-bottom-0-imp' onChange={this.setMessage} value={this.state.message} placeholder='Your message' rows={6}></textarea>
                                    {this.validator.message('message', this.state.message, 'required')}
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <input type="checkbox" checked={this.state.INNews} name='INNews' className='form-check-input' onChange={(e) => { this.setState({ INNews: !this.state.INNews }) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> Get the latest Industrial Navigator news delivered right to your inbox!</b><br /><br />
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <input type="checkbox" checked={this.state.PLGnews} name='PLGnews' className='form-check-input' onChange={(e) => { this.setState({ PLGnews: !this.state.PLGnews }) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> I am also interested in PLG Consulting services news and information!</b><br /><br />

                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                    <div id='regi_errors' className='text-center'></div>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        onChange={this.onRecaptchaChange}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}

                                    />
                                </div>
                                <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>

                                    <Button disabled={this.state.contactusbutton} id='contactusButon' style={{ borderRadius: '0px !important', background: '#4DAFFF', height: 50, width: '100%', fontSize: 18, paddingLeft: 25, paddingRight: 25 }} onClick={this.handleContactUs}>
                                        <span className='pull-left '>Contact Us</span>
                                        {
                                            this.state.contactusbutton ?
                                                <i className="fa fa-spinner fa-spin fs-30"></i> :
                                                ''


                                        }
                                        <span className='pull-right'><RightArrowSVGIcon /></span></Button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>




                <Modal show={this.state.showSuccessModal} onHide={this.handleSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Contact Request Sent
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                Your request has been sent. We will get in touch with you!
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleSuccessModal}>Close</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default HomePage;

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)


const RightArrowSVGIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
    >
        <g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
            <g fill='#FFF' transform='translate(-1166 -2563)'>
                <g transform='translate(-27)'>
                    <g transform='translate(0 2140)'>
                        <g transform='translate(857 120)'>
                            <g transform='translate(0 286)'>
                                <g transform='translate(336 17)'>
                                    <path d='M10.334.244L20 10l-9.666 9.756-1.167-1.179 7.671-7.744H0V9.167h16.839L9.167 1.422 10.334.244z'></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)