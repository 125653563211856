import { FeatureCollection } from 'geojson';

const majorPortsJson: FeatureCollection =
{
	'type': 'FeatureCollection',
	'features': [
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 1, 'OBJECTID': 1, 'ID': 124, 'PORT': 'C4947', 'PORT_NAME': 'Unalaska Island, AK', 'GRAND_TOTA': 1652281, 'FOREIGN_TO': 1236829, 'IMPORTS': 426251, 'EXPORTS': 810578, 'DOMESTIC': 415452 }, 'geometry': { 'type': 'Point', 'coordinates': [-168.938199999710662, 53.759610000020977] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 2, 'OBJECTID': 2, 'ID': 85, 'PORT': 'C4410', 'PORT_NAME': 'Kahului, Maui, HI', 'GRAND_TOTA': 3615449, 'FOREIGN_TO': 20391, 'IMPORTS': 20391, 'EXPORTS': 0, 'DOMESTIC': 3595058 }, 'geometry': { 'type': 'Point', 'coordinates': [-156.472800000020328, 20.898610000380238] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 3, 'OBJECTID': 3, 'ID': 116, 'PORT': 'C4405', 'PORT_NAME': 'Kawaihae Harbor, HI', 'GRAND_TOTA': 2034621, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2034621 }, 'geometry': { 'type': 'Point', 'coordinates': [-155.833299999681657, 20.037780000067926] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 4, 'OBJECTID': 4, 'ID': 113, 'PORT': 'C4430', 'PORT_NAME': 'Nawiliwili, Kauai, HI', 'GRAND_TOTA': 2069212, 'FOREIGN_TO': 22066, 'IMPORTS': 22066, 'EXPORTS': 0, 'DOMESTIC': 2047146 }, 'geometry': { 'type': 'Point', 'coordinates': [-159.35309999973407, 21.961109999827457] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 5, 'OBJECTID': 5, 'ID': 52, 'PORT': 'C4458', 'PORT_NAME': 'Barbers Point, Oahu, HI', 'GRAND_TOTA': 9854176, 'FOREIGN_TO': 7384757, 'IMPORTS': 6683744, 'EXPORTS': 701013, 'DOMESTIC': 2469419 }, 'geometry': { 'type': 'Point', 'coordinates': [-158.10890000013822, 21.297229999983575] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 6, 'OBJECTID': 6, 'ID': 41, 'PORT': 'C4420', 'PORT_NAME': 'Honolulu, HI', 'GRAND_TOTA': 15181890, 'FOREIGN_TO': 1439915, 'IMPORTS': 1146253, 'EXPORTS': 293662, 'DOMESTIC': 13741975 }, 'geometry': { 'type': 'Point', 'coordinates': [-157.871700000283454, 21.311109999912151] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 7, 'OBJECTID': 7, 'ID': 109, 'PORT': 'C4400', 'PORT_NAME': 'Hilo, HI', 'GRAND_TOTA': 2129034, 'FOREIGN_TO': 20362, 'IMPORTS': 20362, 'EXPORTS': 0, 'DOMESTIC': 2108672 }, 'geometry': { 'type': 'Point', 'coordinates': [-155.0758000003635, 19.728609999822265] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 8, 'OBJECTID': 8, 'ID': 82, 'PORT': 'C4831', 'PORT_NAME': 'Nikishka, AK', 'GRAND_TOTA': 4173279, 'FOREIGN_TO': 345110, 'IMPORTS': 89375, 'EXPORTS': 255735, 'DOMESTIC': 3828169 }, 'geometry': { 'type': 'Point', 'coordinates': [-151.314400000092377, 60.747929999989488] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 9, 'OBJECTID': 9, 'ID': 87, 'PORT': 'C4820', 'PORT_NAME': 'Anchorage, AK', 'GRAND_TOTA': 3252349, 'FOREIGN_TO': 1142384, 'IMPORTS': 1070471, 'EXPORTS': 71913, 'DOMESTIC': 2109965 }, 'geometry': { 'type': 'Point', 'coordinates': [-149.895000000379611, 61.237780000086417] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 10, 'OBJECTID': 10, 'ID': 27, 'PORT': 'C4816', 'PORT_NAME': 'Valdez, AK', 'GRAND_TOTA': 25807750, 'FOREIGN_TO': 249648, 'IMPORTS': 0, 'EXPORTS': 249648, 'DOMESTIC': 25558102 }, 'geometry': { 'type': 'Point', 'coordinates': [-146.346399999741919, 61.124730000130796] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 11, 'OBJECTID': 11, 'ID': 130, 'PORT': 'C4978', 'PORT_NAME': 'Kivilina, AK', 'GRAND_TOTA': 1359589, 'FOREIGN_TO': 3367, 'IMPORTS': 3367, 'EXPORTS': 0, 'DOMESTIC': 1356222 }, 'geometry': { 'type': 'Point', 'coordinates': [-164.53890000013152, 67.719179999985016] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 12, 'OBJECTID': 12, 'ID': 128, 'PORT': 'C4100', 'PORT_NAME': 'San Diego, CA', 'GRAND_TOTA': 1572162, 'FOREIGN_TO': 1301084, 'IMPORTS': 1244610, 'EXPORTS': 56474, 'DOMESTIC': 271078 }, 'geometry': { 'type': 'Point', 'coordinates': [-117.178400000264745, 32.708209999763774] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 13, 'OBJECTID': 13, 'ID': 90, 'PORT': 'C4340', 'PORT_NAME': 'Redwood City, CA', 'GRAND_TOTA': 2957553, 'FOREIGN_TO': 2743833, 'IMPORTS': 2352764, 'EXPORTS': 391069, 'DOMESTIC': 213720 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.21401199961349, 37.505360000269583] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 14, 'OBJECTID': 14, 'ID': 126, 'PORT': 'C4335', 'PORT_NAME': 'San Francisco, CA', 'GRAND_TOTA': 1604190, 'FOREIGN_TO': 895252, 'IMPORTS': 820003, 'EXPORTS': 75249, 'DOMESTIC': 708938 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.399041999779854, 37.806664999659269] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 15, 'OBJECTID': 15, 'ID': 34, 'PORT': 'C4345', 'PORT_NAME': 'Oakland, CA', 'GRAND_TOTA': 19373876, 'FOREIGN_TO': 17371852, 'IMPORTS': 8001995, 'EXPORTS': 9369857, 'DOMESTIC': 2002024 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.308100000064826, 37.821520000055834] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 16, 'OBJECTID': 16, 'ID': 24, 'PORT': 'C4350', 'PORT_NAME': 'Richmond, CA', 'GRAND_TOTA': 27255061, 'FOREIGN_TO': 18399389, 'IMPORTS': 13503409, 'EXPORTS': 4895980, 'DOMESTIC': 8855672 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.374233000202878, 37.924237000220607] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 17, 'OBJECTID': 17, 'ID': 117, 'PORT': 'C4150', 'PORT_NAME': 'Port Hueneme, CA', 'GRAND_TOTA': 1987668, 'FOREIGN_TO': 1978850, 'IMPORTS': 1795913, 'EXPORTS': 182937, 'DOMESTIC': 8818 }, 'geometry': { 'type': 'Point', 'coordinates': [-119.20847499961657, 34.148244000358737] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 18, 'OBJECTID': 18, 'ID': 10, 'PORT': 'C4120', 'PORT_NAME': 'Los Angeles, CA', 'GRAND_TOTA': 67806137, 'FOREIGN_TO': 59834464, 'IMPORTS': 39642613, 'EXPORTS': 20191851, 'DOMESTIC': 7971673 }, 'geometry': { 'type': 'Point', 'coordinates': [-118.241000000112649, 33.777280000002655] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 19, 'OBJECTID': 19, 'ID': 7, 'PORT': 'C4110', 'PORT_NAME': 'Long Beach, CA', 'GRAND_TOTA': 86536154, 'FOREIGN_TO': 76189229, 'IMPORTS': 53588309, 'EXPORTS': 22600920, 'DOMESTIC': 10346925 }, 'geometry': { 'type': 'Point', 'coordinates': [-118.20949999975322, 33.739570000056425] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 20, 'OBJECTID': 20, 'ID': 77, 'PORT': 'C4270', 'PORT_NAME': 'Stockton, CA', 'GRAND_TOTA': 5248146, 'FOREIGN_TO': 5247906, 'IMPORTS': 2943312, 'EXPORTS': 2304594, 'DOMESTIC': 240 }, 'geometry': { 'type': 'Point', 'coordinates': [-121.315600000175536, 37.952699999902748] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 21, 'OBJECTID': 21, 'ID': 100, 'PORT': 'C4660', 'PORT_NAME': 'Coos Bay, OR', 'GRAND_TOTA': 2344508, 'FOREIGN_TO': 2289415, 'IMPORTS': 217950, 'EXPORTS': 2071465, 'DOMESTIC': 55093 }, 'geometry': { 'type': 'Point', 'coordinates': [-124.209500000115767, 43.363510000279902] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 22, 'OBJECTID': 22, 'ID': 40, 'PORT': 'C4626', 'PORT_NAME': 'Kalama, WA', 'GRAND_TOTA': 15796458, 'FOREIGN_TO': 14758767, 'IMPORTS': 387714, 'EXPORTS': 14371053, 'DOMESTIC': 1037691 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.862799999678302, 46.020479999842856] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 23, 'OBJECTID': 23, 'ID': 31, 'PORT': 'C4644', 'PORT_NAME': 'Portland, OR', 'GRAND_TOTA': 23228424, 'FOREIGN_TO': 15670578, 'IMPORTS': 2403561, 'EXPORTS': 13267017, 'DOMESTIC': 7557846 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.665299999962812, 45.478810000257937] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 24, 'OBJECTID': 24, 'ID': 50, 'PORT': 'C4636', 'PORT_NAME': 'Vancouver, WA', 'GRAND_TOTA': 10527470, 'FOREIGN_TO': 9263945, 'IMPORTS': 1440265, 'EXPORTS': 7823680, 'DOMESTIC': 1263525 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.680599999880727, 45.622440000045977] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 25, 'OBJECTID': 25, 'ID': 97, 'PORT': 'C4702', 'PORT_NAME': 'Grays Harbor, WA', 'GRAND_TOTA': 2755772, 'FOREIGN_TO': 2646633, 'IMPORTS': 119426, 'EXPORTS': 2527207, 'DOMESTIC': 109139 }, 'geometry': { 'type': 'Point', 'coordinates': [-124.176146000079115, 46.904155999945296] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 26, 'OBJECTID': 26, 'ID': 43, 'PORT': 'C4622', 'PORT_NAME': 'Longview, WA', 'GRAND_TOTA': 13738906, 'FOREIGN_TO': 12519284, 'IMPORTS': 352839, 'EXPORTS': 12166445, 'DOMESTIC': 1219622 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.914000000319561, 46.142219999800766] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 27, 'OBJECTID': 27, 'ID': 148, 'PORT': 'C4718', 'PORT_NAME': 'Olympia, WA', 'GRAND_TOTA': 820111, 'FOREIGN_TO': 563471, 'IMPORTS': 0, 'EXPORTS': 563471, 'DOMESTIC': 256640 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.908900000346918, 47.068269999984203] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 28, 'OBJECTID': 28, 'ID': 32, 'PORT': 'C4720', 'PORT_NAME': 'Tacoma, WA', 'GRAND_TOTA': 22849184, 'FOREIGN_TO': 19624407, 'IMPORTS': 6814144, 'EXPORTS': 12810263, 'DOMESTIC': 3224777 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.451500000246682, 47.289660000116641] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 29, 'OBJECTID': 29, 'ID': 26, 'PORT': 'C4722', 'PORT_NAME': 'Seattle, WA', 'GRAND_TOTA': 26046093, 'FOREIGN_TO': 20403948, 'IMPORTS': 10126665, 'EXPORTS': 10277283, 'DOMESTIC': 5642145 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.359217999792023, 47.587711000257926] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 30, 'OBJECTID': 30, 'ID': 147, 'PORT': 'C4725', 'PORT_NAME': 'Everett, WA', 'GRAND_TOTA': 838014, 'FOREIGN_TO': 230374, 'IMPORTS': 159808, 'EXPORTS': 70566, 'DOMESTIC': 607640 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.229400000293268, 47.984760000030228] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 31, 'OBJECTID': 31, 'ID': 49, 'PORT': 'C4730', 'PORT_NAME': 'Anacortes, WA', 'GRAND_TOTA': 11038886, 'FOREIGN_TO': 3115380, 'IMPORTS': 1184533, 'EXPORTS': 1930847, 'DOMESTIC': 7923506 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.599610000413847, 48.495943000269243] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 32, 'OBJECTID': 32, 'ID': 146, 'PORT': 'C4800', 'PORT_NAME': 'Ketchikan, AK', 'GRAND_TOTA': 870290, 'FOREIGN_TO': 153899, 'IMPORTS': 39275, 'EXPORTS': 114624, 'DOMESTIC': 716391 }, 'geometry': { 'type': 'Point', 'coordinates': [-131.649999999690408, 55.341929999833084] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 33, 'OBJECTID': 33, 'ID': 59, 'PORT': 'C2420', 'PORT_NAME': 'Brownsville, TX', 'GRAND_TOTA': 8348358, 'FOREIGN_TO': 4986724, 'IMPORTS': 4259947, 'EXPORTS': 726777, 'DOMESTIC': 3361634 }, 'geometry': { 'type': 'Point', 'coordinates': [-97.398138000242639, 25.952201000183674] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 34, 'OBJECTID': 34, 'ID': 5, 'PORT': 'C2423', 'PORT_NAME': 'Corpus Christi, TX', 'GRAND_TOTA': 93468323, 'FOREIGN_TO': 69224592, 'IMPORTS': 21718570, 'EXPORTS': 47506022, 'DOMESTIC': 24243731 }, 'geometry': { 'type': 'Point', 'coordinates': [-97.397889999647205, 27.812770000001805] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 35, 'OBJECTID': 35, 'ID': 84, 'PORT': 'C2411', 'PORT_NAME': 'Victoria, TX', 'GRAND_TOTA': 3860635, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 3860635 }, 'geometry': { 'type': 'Point', 'coordinates': [-96.972470000305449, 28.691310000149471] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 36, 'OBJECTID': 36, 'ID': 76, 'PORT': 'C2410', 'PORT_NAME': 'Matagorda Port Lv Pt Com, TX', 'GRAND_TOTA': 5379731, 'FOREIGN_TO': 2014237, 'IMPORTS': 659730, 'EXPORTS': 1354507, 'DOMESTIC': 3365494 }, 'geometry': { 'type': 'Point', 'coordinates': [-96.564119999994944, 28.595400000370145] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 37, 'OBJECTID': 37, 'ID': 28, 'PORT': 'C2408', 'PORT_NAME': 'Freeport, TX', 'GRAND_TOTA': 25446078, 'FOREIGN_TO': 20898788, 'IMPORTS': 8004379, 'EXPORTS': 12894409, 'DOMESTIC': 4547290 }, 'geometry': { 'type': 'Point', 'coordinates': [-95.370700000017465, 28.96133000023617] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 38, 'OBJECTID': 38, 'ID': 53, 'PORT': 'C2417', 'PORT_NAME': 'Galveston, TX', 'GRAND_TOTA': 9111500, 'FOREIGN_TO': 4720353, 'IMPORTS': 2054282, 'EXPORTS': 2666071, 'DOMESTIC': 4391147 }, 'geometry': { 'type': 'Point', 'coordinates': [-94.812699999681911, 29.310490000324506] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 39, 'OBJECTID': 39, 'ID': 2, 'PORT': 'C2012', 'PORT_NAME': 'Houston, TX', 'GRAND_TOTA': 268930047, 'FOREIGN_TO': 191083422, 'IMPORTS': 73863287, 'EXPORTS': 117220135, 'DOMESTIC': 77846625 }, 'geometry': { 'type': 'Point', 'coordinates': [-95.293679000020958, 29.747023000388641] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 40, 'OBJECTID': 40, 'ID': 15, 'PORT': 'C2404', 'PORT_NAME': 'Texas City, TX', 'GRAND_TOTA': 42682311, 'FOREIGN_TO': 25483184, 'IMPORTS': 5795169, 'EXPORTS': 19688015, 'DOMESTIC': 17199127 }, 'geometry': { 'type': 'Point', 'coordinates': [-94.918091000093398, 29.363067999807804] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 41, 'OBJECTID': 41, 'ID': 17, 'PORT': 'C2416', 'PORT_NAME': 'Port Arthur, TX', 'GRAND_TOTA': 39851706, 'FOREIGN_TO': 28979946, 'IMPORTS': 9189716, 'EXPORTS': 19790230, 'DOMESTIC': 10871760 }, 'geometry': { 'type': 'Point', 'coordinates': [-93.960690000138058, 29.831420000085078] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 42, 'OBJECTID': 42, 'ID': 4, 'PORT': 'C2395', 'PORT_NAME': 'Beaumont, TX', 'GRAND_TOTA': 100244231, 'FOREIGN_TO': 61846657, 'IMPORTS': 27617441, 'EXPORTS': 34229216, 'DOMESTIC': 38397574 }, 'geometry': { 'type': 'Point', 'coordinates': [-94.08811199963624, 30.087163000369568] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 43, 'OBJECTID': 43, 'ID': 134, 'PORT': 'C2394', 'PORT_NAME': 'Orange, TX', 'GRAND_TOTA': 1235508, 'FOREIGN_TO': 133, 'IMPORTS': 96, 'EXPORTS': 37, 'DOMESTIC': 1235375 }, 'geometry': { 'type': 'Point', 'coordinates': [-93.818315000153248, 30.045487999640148] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 44, 'OBJECTID': 44, 'ID': 12, 'PORT': 'C2254', 'PORT_NAME': 'Lake Charles, LA', 'GRAND_TOTA': 56908344, 'FOREIGN_TO': 29066473, 'IMPORTS': 16894334, 'EXPORTS': 12172139, 'DOMESTIC': 27841871 }, 'geometry': { 'type': 'Point', 'coordinates': [-93.28683900005305, 30.106405999887812] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 45, 'OBJECTID': 45, 'ID': 88, 'PORT': 'I2227', 'PORT_NAME': 'Central Louisiana Regional Port', 'GRAND_TOTA': 3211068, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 3211068 }, 'geometry': { 'type': 'Point', 'coordinates': [-92.440674000296426, 31.312083000112647] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 46, 'OBJECTID': 46, 'ID': 61, 'PORT': 'C1910', 'PORT_NAME': 'Port Fourchon, LA', 'GRAND_TOTA': 7836594, 'FOREIGN_TO': 122354, 'IMPORTS': 15380, 'EXPORTS': 106974, 'DOMESTIC': 7714240 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.205278999594199, 29.121944999712454] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 47, 'OBJECTID': 47, 'ID': 106, 'PORT': 'C2030', 'PORT_NAME': 'Iberia, LA', 'GRAND_TOTA': 2172388, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2172388 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.843794999804132, 29.93731399999286] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 48, 'OBJECTID': 48, 'ID': 110, 'PORT': 'C2224', 'PORT_NAME': 'Terrebonne, LA, Port of', 'GRAND_TOTA': 2122316, 'FOREIGN_TO': 930, 'IMPORTS': 930, 'EXPORTS': 0, 'DOMESTIC': 2121386 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.659050999807604, 29.579070000043792] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 49, 'OBJECTID': 49, 'ID': 1, 'PORT': 'C2253', 'PORT_NAME': 'South Louisiana, LA, Port of', 'GRAND_TOTA': 275512500, 'FOREIGN_TO': 141536873, 'IMPORTS': 38252692, 'EXPORTS': 103284181, 'DOMESTIC': 133975627 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.617939999681852, 30.033450000329701] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 50, 'OBJECTID': 50, 'ID': 8, 'PORT': 'C2252', 'PORT_NAME': 'Baton Rouge, LA', 'GRAND_TOTA': 82234811, 'FOREIGN_TO': 35084717, 'IMPORTS': 6284412, 'EXPORTS': 28800305, 'DOMESTIC': 47150094 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.19934000015607, 30.422920000079408] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 51, 'OBJECTID': 51, 'ID': 141, 'PORT': 'I2270', 'PORT_NAME': 'Natchez, MS', 'GRAND_TOTA': 1045109, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1045109 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.430732000033572, 31.547237999901483] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 52, 'OBJECTID': 52, 'ID': 89, 'PORT': 'I2276', 'PORT_NAME': 'Vicksburg, MS', 'GRAND_TOTA': 2962979, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2962979 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.900559999789678, 32.334699999896742] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 53, 'OBJECTID': 53, 'ID': 136, 'PORT': 'I2269', 'PORT_NAME': 'Lake Providence, LA', 'GRAND_TOTA': 1147254, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1147254 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.153610000281631, 32.794719999915188] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 54, 'OBJECTID': 54, 'ID': 91, 'PORT': 'I2271', 'PORT_NAME': 'Greenville, MS', 'GRAND_TOTA': 2944932, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2944932 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.126869000311288, 33.359231999779354] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 55, 'OBJECTID': 55, 'ID': 13, 'PORT': 'C2255', 'PORT_NAME': 'Plaquemines, LA, Port of', 'GRAND_TOTA': 56850137, 'FOREIGN_TO': 25735724, 'IMPORTS': 6605611, 'EXPORTS': 19130113, 'DOMESTIC': 31114413 }, 'geometry': { 'type': 'Point', 'coordinates': [-89.687447000101415, 29.480003999785009] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 56, 'OBJECTID': 56, 'ID': 6, 'PORT': 'C2251', 'PORT_NAME': 'New Orleans, LA', 'GRAND_TOTA': 93332543, 'FOREIGN_TO': 43810994, 'IMPORTS': 18402293, 'EXPORTS': 25408701, 'DOMESTIC': 49521549 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.085255999984795, 29.914140000137532] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 57, 'OBJECTID': 57, 'ID': 114, 'PORT': 'C2083', 'PORT_NAME': 'Gulfport, MS', 'GRAND_TOTA': 2052691, 'FOREIGN_TO': 2025585, 'IMPORTS': 1374591, 'EXPORTS': 650994, 'DOMESTIC': 27106 }, 'geometry': { 'type': 'Point', 'coordinates': [-89.085330000442525, 30.352159999780564] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 58, 'OBJECTID': 58, 'ID': 23, 'PORT': 'C2004', 'PORT_NAME': 'Pascagoula, MS', 'GRAND_TOTA': 27358043, 'FOREIGN_TO': 17521892, 'IMPORTS': 8833016, 'EXPORTS': 8688876, 'DOMESTIC': 9836151 }, 'geometry': { 'type': 'Point', 'coordinates': [-88.558790000283437, 30.348020000195824] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 59, 'OBJECTID': 59, 'ID': 11, 'PORT': 'C2005', 'PORT_NAME': 'Mobile, AL', 'GRAND_TOTA': 58635622, 'FOREIGN_TO': 36516319, 'IMPORTS': 19104863, 'EXPORTS': 17411456, 'DOMESTIC': 22119303 }, 'geometry': { 'type': 'Point', 'coordinates': [-88.041130000319797, 30.72527000000661] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 60, 'OBJECTID': 60, 'ID': 108, 'PORT': 'C2016', 'PORT_NAME': 'Panama City, FL', 'GRAND_TOTA': 2140388, 'FOREIGN_TO': 1717564, 'IMPORTS': 656103, 'EXPORTS': 1061461, 'DOMESTIC': 422824 }, 'geometry': { 'type': 'Point', 'coordinates': [-84.199260000265483, 30.190090000216639] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 61, 'OBJECTID': 61, 'ID': 79, 'PORT': 'C2023', 'PORT_NAME': 'Port Manatee, FL', 'GRAND_TOTA': 4763728, 'FOREIGN_TO': 3486373, 'IMPORTS': 3213890, 'EXPORTS': 272483, 'DOMESTIC': 1277355 }, 'geometry': { 'type': 'Point', 'coordinates': [-82.602709999600734, 27.659999999799723] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 62, 'OBJECTID': 62, 'ID': 22, 'PORT': 'C2021', 'PORT_NAME': 'Tampa, FL', 'GRAND_TOTA': 31006487, 'FOREIGN_TO': 12216828, 'IMPORTS': 8053152, 'EXPORTS': 4163676, 'DOMESTIC': 18789659 }, 'geometry': { 'type': 'Point', 'coordinates': [-82.522350000320714, 27.785340000128098] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 63, 'OBJECTID': 63, 'ID': 58, 'PORT': 'C2164', 'PORT_NAME': 'Miami, FL', 'GRAND_TOTA': 8371129, 'FOREIGN_TO': 8309232, 'IMPORTS': 4965413, 'EXPORTS': 3343819, 'DOMESTIC': 61897 }, 'geometry': { 'type': 'Point', 'coordinates': [-80.181641999923116, 25.782862000181144] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 64, 'OBJECTID': 64, 'ID': 29, 'PORT': 'C2163', 'PORT_NAME': 'Port Everglades, FL', 'GRAND_TOTA': 25022351, 'FOREIGN_TO': 11646824, 'IMPORTS': 8136979, 'EXPORTS': 3509845, 'DOMESTIC': 13375527 }, 'geometry': { 'type': 'Point', 'coordinates': [-80.117801000028521, 26.093392000071571] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 65, 'OBJECTID': 65, 'ID': 112, 'PORT': 'C2162', 'PORT_NAME': 'Palm Beach, FL', 'GRAND_TOTA': 2094734, 'FOREIGN_TO': 1527539, 'IMPORTS': 384057, 'EXPORTS': 1143482, 'DOMESTIC': 567195 }, 'geometry': { 'type': 'Point', 'coordinates': [-80.052669999622992, 26.769039999836934] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 66, 'OBJECTID': 66, 'ID': 75, 'PORT': 'C2160', 'PORT_NAME': 'Port Canaveral, FL', 'GRAND_TOTA': 5436994, 'FOREIGN_TO': 4045149, 'IMPORTS': 4032876, 'EXPORTS': 12273, 'DOMESTIC': 1391845 }, 'geometry': { 'type': 'Point', 'coordinates': [-80.608150000109461, 28.414090000060813] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 67, 'OBJECTID': 67, 'ID': 35, 'PORT': 'C2017', 'PORT_NAME': 'Jacksonville, FL', 'GRAND_TOTA': 17999036, 'FOREIGN_TO': 9721385, 'IMPORTS': 7744659, 'EXPORTS': 1976726, 'DOMESTIC': 8277651 }, 'geometry': { 'type': 'Point', 'coordinates': [-81.665125000278167, 30.322756999810611] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 68, 'OBJECTID': 68, 'ID': 99, 'PORT': 'C780', 'PORT_NAME': 'Brunswick, GA', 'GRAND_TOTA': 2503937, 'FOREIGN_TO': 2419557, 'IMPORTS': 1139371, 'EXPORTS': 1280186, 'DOMESTIC': 84380 }, 'geometry': { 'type': 'Point', 'coordinates': [-81.499879999884115, 31.158559999744998] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 69, 'OBJECTID': 69, 'ID': 16, 'PORT': 'C776', 'PORT_NAME': 'Savannah, GA', 'GRAND_TOTA': 41273947, 'FOREIGN_TO': 40138093, 'IMPORTS': 22512768, 'EXPORTS': 17625325, 'DOMESTIC': 1135854 }, 'geometry': { 'type': 'Point', 'coordinates': [-81.095381999766403, 32.084711000216075] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 70, 'OBJECTID': 70, 'ID': 30, 'PORT': 'C773', 'PORT_NAME': 'Charleston, SC', 'GRAND_TOTA': 24822636, 'FOREIGN_TO': 22784122, 'IMPORTS': 14144869, 'EXPORTS': 8639253, 'DOMESTIC': 2038514 }, 'geometry': { 'type': 'Point', 'coordinates': [-79.921595000123617, 32.788780999960537] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 71, 'OBJECTID': 71, 'ID': 115, 'PORT': 'I6109', 'PORT_NAME': 'Tulsa, Port of Catoosa, OK', 'GRAND_TOTA': 2044890, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2044890 }, 'geometry': { 'type': 'Point', 'coordinates': [-95.740019999660191, 36.218200000003336] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 72, 'OBJECTID': 72, 'ID': 129, 'PORT': 'I2385', 'PORT_NAME': 'Kansas City, MO', 'GRAND_TOTA': 1374284, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1374284 }, 'geometry': { 'type': 'Point', 'coordinates': [-94.607620000327657, 39.115000000193575] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 73, 'OBJECTID': 73, 'ID': 71, 'PORT': 'I2320', 'PORT_NAME': 'St. Paul, MN', 'GRAND_TOTA': 5813777, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 5813777 }, 'geometry': { 'type': 'Point', 'coordinates': [-93.061900000408329, 44.951569999803638] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 74, 'OBJECTID': 74, 'ID': 139, 'PORT': 'I2268', 'PORT_NAME': 'Rosedale, MS', 'GRAND_TOTA': 1066185, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1066185 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.0220600004061, 33.813551000098421] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 75, 'OBJECTID': 75, 'ID': 137, 'PORT': 'I2293', 'PORT_NAME': 'Helena, AR', 'GRAND_TOTA': 1118011, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1118011 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.58322999991033, 34.521860000365805] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 76, 'OBJECTID': 76, 'ID': 48, 'PORT': 'I2294', 'PORT_NAME': 'Memphis, TN', 'GRAND_TOTA': 11055740, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 11055740 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.061979999694685, 35.181769999752454] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 77, 'OBJECTID': 77, 'ID': 102, 'PORT': 'I2288', 'PORT_NAME': 'New Madrid County, MO, Port of', 'GRAND_TOTA': 2259548, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2259548 }, 'geometry': { 'type': 'Point', 'coordinates': [-89.568659999677948, 36.526836999731586] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 78, 'OBJECTID': 78, 'ID': 132, 'PORT': 'I2304', 'PORT_NAME': 'Hickman-Fulton County Riverport, KY', 'GRAND_TOTA': 1283282, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1283282 }, 'geometry': { 'type': 'Point', 'coordinates': [-89.205352000227023, 36.581544000340209] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 79, 'OBJECTID': 79, 'ID': 122, 'PORT': 'I2302', 'PORT_NAME': 'Paducah-McCracken County Riverport, KY', 'GRAND_TOTA': 1813468, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1813468 }, 'geometry': { 'type': 'Point', 'coordinates': [-88.693177000200478, 37.129275999735064] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 80, 'OBJECTID': 80, 'ID': 19, 'PORT': 'I2310', 'PORT_NAME': 'St. Louis, MO and IL', 'GRAND_TOTA': 37426710, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 37426710 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.229610000218614, 38.569540000195474] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 81, 'OBJECTID': 81, 'ID': 140, 'PORT': 'I2301', 'PORT_NAME': 'Southeast Missouri Port, MO', 'GRAND_TOTA': 1054128, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1054128 }, 'geometry': { 'type': 'Point', 'coordinates': [-89.934880000411752, 37.973320000211807] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 82, 'OBJECTID': 82, 'ID': 72, 'PORT': 'I2307', 'PORT_NAME': 'Kaskaskia, IL, Port of', 'GRAND_TOTA': 5776030, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 5776030 }, 'geometry': { 'type': 'Point', 'coordinates': [-90.046145999849372, 38.143331999700969] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 83, 'OBJECTID': 83, 'ID': 119, 'PORT': 'I2371', 'PORT_NAME': 'Guntersville, AL', 'GRAND_TOTA': 1918572, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1918572 }, 'geometry': { 'type': 'Point', 'coordinates': [-86.287505999626859, 34.373904000069643] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 84, 'OBJECTID': 84, 'ID': 69, 'PORT': 'I2370', 'PORT_NAME': 'Nashville, TN', 'GRAND_TOTA': 6154679, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 6154679 }, 'geometry': { 'type': 'Point', 'coordinates': [-86.774670000410055, 36.165130000267354] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 85, 'OBJECTID': 85, 'ID': 105, 'PORT': 'I2372', 'PORT_NAME': 'Chattanooga, TN', 'GRAND_TOTA': 2225953, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2225953 }, 'geometry': { 'type': 'Point', 'coordinates': [-85.326819999916353, 35.024479999937768] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 86, 'OBJECTID': 86, 'ID': 51, 'PORT': 'I2332', 'PORT_NAME': 'Mount Vernon, IN', 'GRAND_TOTA': 10332103, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 10332103 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.87500000036907, 37.922080000113439] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 87, 'OBJECTID': 87, 'ID': 123, 'PORT': 'I2329', 'PORT_NAME': 'Henderson County Riverport, KY', 'GRAND_TOTA': 1757755, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1757755 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.593777999828873, 37.842685000209833] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 88, 'OBJECTID': 88, 'ID': 92, 'PORT': 'I2331', 'PORT_NAME': 'Owensboro, KY', 'GRAND_TOTA': 2872596, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2872596 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.111687999977363, 37.776366999874384] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 89, 'OBJECTID': 89, 'ID': 37, 'PORT': 'L3749', 'PORT_NAME': 'Chicago, IL', 'GRAND_TOTA': 16866792, 'FOREIGN_TO': 1689286, 'IMPORTS': 1586551, 'EXPORTS': 102735, 'DOMESTIC': 15177506 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.637989999586765, 41.886620000123123] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 90, 'OBJECTID': 90, 'ID': 45, 'PORT': 'L3738', 'PORT_NAME': 'Indiana Harbor, IN', 'GRAND_TOTA': 11910541, 'FOREIGN_TO': 173417, 'IMPORTS': 173417, 'EXPORTS': 0, 'DOMESTIC': 11737124 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.445470000193325, 41.675859999897462] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 91, 'OBJECTID': 91, 'ID': 125, 'PORT': 'L3737', 'PORT_NAME': 'Buffington, IN', 'GRAND_TOTA': 1634865, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 1634865 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.416139999878595, 41.645229999965899] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 92, 'OBJECTID': 92, 'ID': 56, 'PORT': 'L3736', 'PORT_NAME': 'Gary, IN', 'GRAND_TOTA': 8600449, 'FOREIGN_TO': 84286, 'IMPORTS': 84286, 'EXPORTS': 0, 'DOMESTIC': 8516163 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.325130000391525, 41.612020000135729] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 93, 'OBJECTID': 93, 'ID': 57, 'PORT': 'L3739', 'PORT_NAME': 'Burns Waterway Harbor, IN', 'GRAND_TOTA': 8496097, 'FOREIGN_TO': 973688, 'IMPORTS': 973688, 'EXPORTS': 0, 'DOMESTIC': 7522409 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.155210000172261, 41.643249999892497] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 94, 'OBJECTID': 94, 'ID': 67, 'PORT': 'I2333', 'PORT_NAME': 'Louisville, KY', 'GRAND_TOTA': 6241476, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 6241476 }, 'geometry': { 'type': 'Point', 'coordinates': [-85.760993000130256, 38.26147000010436] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 95, 'OBJECTID': 95, 'ID': 18, 'PORT': 'I2338', 'PORT_NAME': 'Cincinnati-Northern KY, Ports of', 'GRAND_TOTA': 38534187, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 38534187 }, 'geometry': { 'type': 'Point', 'coordinates': [-84.504499999639009, 39.094030000273605] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 96, 'OBJECTID': 96, 'ID': 20, 'PORT': 'L3924', 'PORT_NAME': 'Duluth-Superior, MN and WI', 'GRAND_TOTA': 35102200, 'FOREIGN_TO': 8316881, 'IMPORTS': 612677, 'EXPORTS': 7704204, 'DOMESTIC': 26785319 }, 'geometry': { 'type': 'Point', 'coordinates': [-92.096407999554742, 46.778362000078673] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 97, 'OBJECTID': 97, 'ID': 36, 'PORT': 'L3926', 'PORT_NAME': 'Two Harbors, MN', 'GRAND_TOTA': 17208207, 'FOREIGN_TO': 3816588, 'IMPORTS': 152808, 'EXPORTS': 3663780, 'DOMESTIC': 13391619 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.662610000157869, 47.004280000304071] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 98, 'OBJECTID': 98, 'ID': 66, 'PORT': 'L3928', 'PORT_NAME': 'Silver Bay, MN', 'GRAND_TOTA': 6248656, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 6248656 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.256388000059275, 47.294207999985566] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 99, 'OBJECTID': 99, 'ID': 101, 'PORT': 'L3756', 'PORT_NAME': 'Milwaukee, WI', 'GRAND_TOTA': 2301557, 'FOREIGN_TO': 1230900, 'IMPORTS': 1013787, 'EXPORTS': 217113, 'DOMESTIC': 1070657 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.899670000028891, 42.988240000004311] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 100, 'OBJECTID': 100, 'ID': 131, 'PORT': 'L3728', 'PORT_NAME': 'Grand Haven, MI', 'GRAND_TOTA': 1321003, 'FOREIGN_TO': 280638, 'IMPORTS': 280638, 'EXPORTS': 0, 'DOMESTIC': 1040365 }, 'geometry': { 'type': 'Point', 'coordinates': [-86.235570000290167, 43.066940000037526] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 101, 'OBJECTID': 101, 'ID': 107, 'PORT': 'L3778', 'PORT_NAME': 'Green Bay, WI', 'GRAND_TOTA': 2156416, 'FOREIGN_TO': 697579, 'IMPORTS': 689368, 'EXPORTS': 8211, 'DOMESTIC': 1458837 }, 'geometry': { 'type': 'Point', 'coordinates': [-88.018117999620316, 44.51621500000104] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 102, 'OBJECTID': 102, 'ID': 80, 'PORT': 'L3803', 'PORT_NAME': 'Port Inland, MI', 'GRAND_TOTA': 4327245, 'FOREIGN_TO': 141901, 'IMPORTS': 13994, 'EXPORTS': 127907, 'DOMESTIC': 4185344 }, 'geometry': { 'type': 'Point', 'coordinates': [-85.862790000091792, 45.955079999904292] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 103, 'OBJECTID': 103, 'ID': 96, 'PORT': 'L3627', 'PORT_NAME': 'Port Dolomite, MI', 'GRAND_TOTA': 2766412, 'FOREIGN_TO': 196646, 'IMPORTS': 69354, 'EXPORTS': 127292, 'DOMESTIC': 2569766 }, 'geometry': { 'type': 'Point', 'coordinates': [-84.312810000448948, 45.991390000260118] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 104, 'OBJECTID': 104, 'ID': 63, 'PORT': 'L3845', 'PORT_NAME': 'Presque Isle, MI', 'GRAND_TOTA': 7473094, 'FOREIGN_TO': 1232946, 'IMPORTS': 52299, 'EXPORTS': 1180647, 'DOMESTIC': 6240148 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.385165999948953, 46.577366999901656] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 105, 'OBJECTID': 105, 'ID': 143, 'PORT': 'L3844', 'PORT_NAME': 'Marquette, MI', 'GRAND_TOTA': 987596, 'FOREIGN_TO': 315710, 'IMPORTS': 254808, 'EXPORTS': 60902, 'DOMESTIC': 671886 }, 'geometry': { 'type': 'Point', 'coordinates': [-87.287030000384533, 46.511129999846695] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 106, 'OBJECTID': 106, 'ID': 21, 'PORT': 'I2348', 'PORT_NAME': 'Huntington - Tristate', 'GRAND_TOTA': 34245342, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 34245342 }, 'geometry': { 'type': 'Point', 'coordinates': [-82.419980000073735, 38.433610000165316] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 107, 'OBJECTID': 107, 'ID': 54, 'PORT': 'L3204', 'PORT_NAME': 'Toledo, OH', 'GRAND_TOTA': 8899825, 'FOREIGN_TO': 4251660, 'IMPORTS': 2035160, 'EXPORTS': 2216500, 'DOMESTIC': 4648165 }, 'geometry': { 'type': 'Point', 'coordinates': [-83.507509999872426, 41.662940000286746] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 108, 'OBJECTID': 108, 'ID': 135, 'PORT': 'L3202', 'PORT_NAME': 'Monroe, MI', 'GRAND_TOTA': 1195210, 'FOREIGN_TO': 74885, 'IMPORTS': 40080, 'EXPORTS': 34805, 'DOMESTIC': 1120325 }, 'geometry': { 'type': 'Point', 'coordinates': [-83.390608999811562, 41.915133999925601] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 109, 'OBJECTID': 109, 'ID': 103, 'PORT': 'L3213', 'PORT_NAME': 'Sandusky, OH', 'GRAND_TOTA': 2247534, 'FOREIGN_TO': 1055997, 'IMPORTS': 55523, 'EXPORTS': 1000474, 'DOMESTIC': 1191537 }, 'geometry': { 'type': 'Point', 'coordinates': [-82.712270000397424, 41.470219999858998] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 110, 'OBJECTID': 110, 'ID': 120, 'PORT': 'L3212', 'PORT_NAME': 'Marblehead, OH', 'GRAND_TOTA': 1889279, 'FOREIGN_TO': 162174, 'IMPORTS': 149684, 'EXPORTS': 12490, 'DOMESTIC': 1727105 }, 'geometry': { 'type': 'Point', 'coordinates': [-82.709060000087035, 41.529620000191251] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 111, 'OBJECTID': 111, 'ID': 46, 'PORT': 'L3217', 'PORT_NAME': 'Cleveland, OH', 'GRAND_TOTA': 11778910, 'FOREIGN_TO': 1663276, 'IMPORTS': 1641801, 'EXPORTS': 21475, 'DOMESTIC': 10115634 }, 'geometry': { 'type': 'Point', 'coordinates': [-81.67190999987362, 41.478519999935159] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 112, 'OBJECTID': 112, 'ID': 118, 'PORT': 'L3218', 'PORT_NAME': 'Fairport Harbor, OH', 'GRAND_TOTA': 1957879, 'FOREIGN_TO': 63351, 'IMPORTS': 63351, 'EXPORTS': 0, 'DOMESTIC': 1894528 }, 'geometry': { 'type': 'Point', 'coordinates': [-81.294069999885579, 41.766659999687946] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 113, 'OBJECTID': 113, 'ID': 81, 'PORT': 'L3219', 'PORT_NAME': 'Ashtabula, OH', 'GRAND_TOTA': 4254537, 'FOREIGN_TO': 446254, 'IMPORTS': 446254, 'EXPORTS': 0, 'DOMESTIC': 3808283 }, 'geometry': { 'type': 'Point', 'coordinates': [-80.791719999885586, 41.918730000172815] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 114, 'OBJECTID': 114, 'ID': 33, 'PORT': 'I2358', 'PORT_NAME': 'Pittsburgh, PA', 'GRAND_TOTA': 21567015, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 21567015 }, 'geometry': { 'type': 'Point', 'coordinates': [-80.016120000417956, 40.442340000286514] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 115, 'OBJECTID': 115, 'ID': 70, 'PORT': 'C766', 'PORT_NAME': 'Wilmington, NC', 'GRAND_TOTA': 6039927, 'FOREIGN_TO': 5660231, 'IMPORTS': 3377654, 'EXPORTS': 2282577, 'DOMESTIC': 379696 }, 'geometry': { 'type': 'Point', 'coordinates': [-77.953953999979603, 34.239278000103141] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 116, 'OBJECTID': 116, 'ID': 98, 'PORT': 'C764', 'PORT_NAME': 'Morehead City, NC', 'GRAND_TOTA': 2740648, 'FOREIGN_TO': 1602856, 'IMPORTS': 900009, 'EXPORTS': 702847, 'DOMESTIC': 1137792 }, 'geometry': { 'type': 'Point', 'coordinates': [-76.724209999854594, 34.716929999991791] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 117, 'OBJECTID': 117, 'ID': 9, 'PORT': 'C5700', 'PORT_NAME': 'Virginia, VA, Port of', 'GRAND_TOTA': 71774349, 'FOREIGN_TO': 67203249, 'IMPORTS': 12801746, 'EXPORTS': 54401503, 'DOMESTIC': 4571100 }, 'geometry': { 'type': 'Point', 'coordinates': [-76.311739999562647, 36.866420000120833] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 118, 'OBJECTID': 118, 'ID': 14, 'PORT': 'C700', 'PORT_NAME': 'Baltimore, MD', 'GRAND_TOTA': 44778259, 'FOREIGN_TO': 37507215, 'IMPORTS': 13445442, 'EXPORTS': 24061773, 'DOMESTIC': 7271044 }, 'geometry': { 'type': 'Point', 'coordinates': [-76.517120000289182, 39.208990000340613] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 119, 'OBJECTID': 119, 'ID': 65, 'PORT': 'C299', 'PORT_NAME': 'New Castle, DE', 'GRAND_TOTA': 6522640, 'FOREIGN_TO': 2754729, 'IMPORTS': 2625347, 'EXPORTS': 129382, 'DOMESTIC': 3767911 }, 'geometry': { 'type': 'Point', 'coordinates': [-75.561579999683744, 39.656680000110903] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 120, 'OBJECTID': 120, 'ID': 64, 'PORT': 'C554', 'PORT_NAME': 'Wilmington, DE', 'GRAND_TOTA': 6603444, 'FOREIGN_TO': 5485535, 'IMPORTS': 3848681, 'EXPORTS': 1636854, 'DOMESTIC': 1117909 }, 'geometry': { 'type': 'Point', 'coordinates': [-75.506969999613872, 39.715889999656902] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 121, 'OBJECTID': 121, 'ID': 42, 'PORT': 'L3321', 'PORT_NAME': 'Detroit, MI', 'GRAND_TOTA': 14837762, 'FOREIGN_TO': 3327383, 'IMPORTS': 3297612, 'EXPORTS': 29771, 'DOMESTIC': 11510379 }, 'geometry': { 'type': 'Point', 'coordinates': [-83.109586000361318, 42.269094000260175] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 122, 'OBJECTID': 122, 'ID': 68, 'PORT': 'L3509', 'PORT_NAME': 'St. Clair, MI', 'GRAND_TOTA': 6192710, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 6192710 }, 'geometry': { 'type': 'Point', 'coordinates': [-82.494129999693556, 42.826630000097147] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 123, 'OBJECTID': 123, 'ID': 104, 'PORT': 'L3617', 'PORT_NAME': 'Alpena, MI', 'GRAND_TOTA': 2237836, 'FOREIGN_TO': 144695, 'IMPORTS': 101256, 'EXPORTS': 43439, 'DOMESTIC': 2093141 }, 'geometry': { 'type': 'Point', 'coordinates': [-83.422269999829481, 45.055600000025002] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 124, 'OBJECTID': 124, 'ID': 62, 'PORT': 'L3620', 'PORT_NAME': 'Calcite, MI', 'GRAND_TOTA': 7759361, 'FOREIGN_TO': 205235, 'IMPORTS': 78651, 'EXPORTS': 126584, 'DOMESTIC': 7554126 }, 'geometry': { 'type': 'Point', 'coordinates': [-83.7755999999913, 45.392930000182211] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 125, 'OBJECTID': 125, 'ID': 83, 'PORT': 'L3619', 'PORT_NAME': 'Stoneport, MI', 'GRAND_TOTA': 4141455, 'FOREIGN_TO': 277188, 'IMPORTS': 71094, 'EXPORTS': 206094, 'DOMESTIC': 3864267 }, 'geometry': { 'type': 'Point', 'coordinates': [-83.420781000025244, 45.298455000191495] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 126, 'OBJECTID': 126, 'ID': 133, 'PORT': 'L3813', 'PORT_NAME': 'Drummond Island, MI', 'GRAND_TOTA': 1260854, 'FOREIGN_TO': 314909, 'IMPORTS': 30582, 'EXPORTS': 284327, 'DOMESTIC': 945945 }, 'geometry': { 'type': 'Point', 'coordinates': [-83.885559999922776, 45.992449999996097] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 127, 'OBJECTID': 127, 'ID': 86, 'PORT': 'L3220', 'PORT_NAME': 'Conneaut, OH', 'GRAND_TOTA': 3487058, 'FOREIGN_TO': 29762, 'IMPORTS': 29762, 'EXPORTS': 0, 'DOMESTIC': 3457296 }, 'geometry': { 'type': 'Point', 'coordinates': [-80.548599999900503, 41.966710000262147] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 128, 'OBJECTID': 128, 'ID': 44, 'PORT': 'C5251', 'PORT_NAME': 'Marcus Hook, PA', 'GRAND_TOTA': 12205883, 'FOREIGN_TO': 5174929, 'IMPORTS': 3244335, 'EXPORTS': 1930594, 'DOMESTIC': 7030954 }, 'geometry': { 'type': 'Point', 'coordinates': [-75.404240000415115, 39.81543999992801] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 129, 'OBJECTID': 129, 'ID': 39, 'PORT': 'C5252', 'PORT_NAME': 'Paulsboro, NJ', 'GRAND_TOTA': 16121201, 'FOREIGN_TO': 11459677, 'IMPORTS': 11037825, 'EXPORTS': 421852, 'DOMESTIC': 4661524 }, 'geometry': { 'type': 'Point', 'coordinates': [-75.22654999968799, 39.826890000156126] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 130, 'OBJECTID': 130, 'ID': 95, 'PORT': 'C297', 'PORT_NAME': 'Chester, PA', 'GRAND_TOTA': 2788947, 'FOREIGN_TO': 2570075, 'IMPORTS': 2195396, 'EXPORTS': 374679, 'DOMESTIC': 218872 }, 'geometry': { 'type': 'Point', 'coordinates': [-75.322199999738501, 39.854230000206684] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 131, 'OBJECTID': 131, 'ID': 25, 'PORT': 'C552', 'PORT_NAME': 'Philadelphia, PA', 'GRAND_TOTA': 26656373, 'FOREIGN_TO': 16188350, 'IMPORTS': 15136804, 'EXPORTS': 1051546, 'DOMESTIC': 10468023 }, 'geometry': { 'type': 'Point', 'coordinates': [-75.202191000372792, 39.918819999993325] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 132, 'OBJECTID': 132, 'ID': 74, 'PORT': 'C551', 'PORT_NAME': 'Camden-Gloucester, NJ', 'GRAND_TOTA': 5500928, 'FOREIGN_TO': 3939068, 'IMPORTS': 3356143, 'EXPORTS': 582925, 'DOMESTIC': 1561860 }, 'geometry': { 'type': 'Point', 'coordinates': [-75.104320000223922, 39.943050000051358] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 133, 'OBJECTID': 133, 'ID': 94, 'PORT': 'C298', 'PORT_NAME': 'Penn Manor, PA', 'GRAND_TOTA': 2829337, 'FOREIGN_TO': 2763362, 'IMPORTS': 2245832, 'EXPORTS': 517530, 'DOMESTIC': 65975 }, 'geometry': { 'type': 'Point', 'coordinates': [-74.740810000208327, 40.135980000079805] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 134, 'OBJECTID': 134, 'ID': 3, 'PORT': 'C398', 'PORT_NAME': 'New York, NY and NJ', 'GRAND_TOTA': 140281992, 'FOREIGN_TO': 93631637, 'IMPORTS': 74890489, 'EXPORTS': 18741148, 'DOMESTIC': 46650355 }, 'geometry': { 'type': 'Point', 'coordinates': [-74.038356999904764, 40.673949000227203] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 135, 'OBJECTID': 135, 'ID': 121, 'PORT': 'C311', 'PORT_NAME': 'Bridgeport, CT', 'GRAND_TOTA': 1881034, 'FOREIGN_TO': 34830, 'IMPORTS': 34830, 'EXPORTS': 0, 'DOMESTIC': 1846204 }, 'geometry': { 'type': 'Point', 'coordinates': [-73.178859999710255, 41.172000000328872] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 136, 'OBJECTID': 136, 'ID': 111, 'PORT': 'C522', 'PORT_NAME': 'Port Jefferson, NY', 'GRAND_TOTA': 2117189, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 2117189 }, 'geometry': { 'type': 'Point', 'coordinates': [-73.072160000317893, 40.948810000222728] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 137, 'OBJECTID': 137, 'ID': 55, 'PORT': 'C1507', 'PORT_NAME': 'New Haven, CT', 'GRAND_TOTA': 8838236, 'FOREIGN_TO': 2823800, 'IMPORTS': 2588839, 'EXPORTS': 234961, 'DOMESTIC': 6014436 }, 'geometry': { 'type': 'Point', 'coordinates': [-72.904729999810499, 41.298829999841828] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 138, 'OBJECTID': 138, 'ID': 60, 'PORT': 'C191', 'PORT_NAME': 'Providence, RI', 'GRAND_TOTA': 8338434, 'FOREIGN_TO': 4478045, 'IMPORTS': 3794205, 'EXPORTS': 683840, 'DOMESTIC': 3860389 }, 'geometry': { 'type': 'Point', 'coordinates': [-71.398439999693721, 41.811780000039576] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 139, 'OBJECTID': 139, 'ID': 73, 'PORT': 'C505', 'PORT_NAME': 'Albany, NY', 'GRAND_TOTA': 5712209, 'FOREIGN_TO': 942081, 'IMPORTS': 681283, 'EXPORTS': 260798, 'DOMESTIC': 4770128 }, 'geometry': { 'type': 'Point', 'coordinates': [-73.74815999963289, 42.642709999942802] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 140, 'OBJECTID': 140, 'ID': 38, 'PORT': 'C149', 'PORT_NAME': 'Boston, MA', 'GRAND_TOTA': 16163552, 'FOREIGN_TO': 10974619, 'IMPORTS': 9292657, 'EXPORTS': 1681962, 'DOMESTIC': 5188933 }, 'geometry': { 'type': 'Point', 'coordinates': [-71.052290000035896, 42.350939000244608] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 141, 'OBJECTID': 141, 'ID': 93, 'PORT': 'C135', 'PORT_NAME': 'Portsmouth, NH', 'GRAND_TOTA': 2868572, 'FOREIGN_TO': 2179462, 'IMPORTS': 2179462, 'EXPORTS': 0, 'DOMESTIC': 689110 }, 'geometry': { 'type': 'Point', 'coordinates': [-70.738059999963767, 43.073979999770472] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 142, 'OBJECTID': 142, 'ID': 78, 'PORT': 'C128', 'PORT_NAME': 'Portland, ME', 'GRAND_TOTA': 4833299, 'FOREIGN_TO': 3490000, 'IMPORTS': 3454132, 'EXPORTS': 35868, 'DOMESTIC': 1343299 }, 'geometry': { 'type': 'Point', 'coordinates': [-70.251339999594563, 43.649509999762756] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 143, 'OBJECTID': 143, 'ID': 127, 'PORT': 'C112', 'PORT_NAME': 'Searsport, ME', 'GRAND_TOTA': 1576615, 'FOREIGN_TO': 1281857, 'IMPORTS': 1250200, 'EXPORTS': 31657, 'DOMESTIC': 294758 }, 'geometry': { 'type': 'Point', 'coordinates': [-68.924969999625802, 44.452849999920929] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 144, 'OBJECTID': 144, 'ID': 138, 'PORT': 'C2151', 'PORT_NAME': 'Ponce, PR', 'GRAND_TOTA': 1090198, 'FOREIGN_TO': 1089466, 'IMPORTS': 1032807, 'EXPORTS': 56659, 'DOMESTIC': 732 }, 'geometry': { 'type': 'Point', 'coordinates': [-66.620996000352903, 17.978613000085073] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 145, 'OBJECTID': 145, 'ID': 47, 'PORT': 'C2136', 'PORT_NAME': 'San Juan, PR', 'GRAND_TOTA': 11737059, 'FOREIGN_TO': 6831339, 'IMPORTS': 6408225, 'EXPORTS': 423114, 'DOMESTIC': 4905720 }, 'geometry': { 'type': 'Point', 'coordinates': [-66.099890000077295, 18.442329000185815] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 146, 'OBJECTID': 146, 'ID': 145, 'PORT': 'C4240', 'PORT_NAME': 'Sacramento, CA', 'GRAND_TOTA': 898044, 'FOREIGN_TO': 898044, 'IMPORTS': 733008, 'EXPORTS': 165036, 'DOMESTIC': 0 }, 'geometry': { 'type': 'Point', 'coordinates': [-122.399041999779854, 37.806664999659269] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 147, 'OBJECTID': 147, 'ID': 142, 'PORT': 'I2094', 'PORT_NAME': 'Morgan City, LA, Port of', 'GRAND_TOTA': 991600, 'FOREIGN_TO': 51, 'IMPORTS': 51, 'EXPORTS': 0, 'DOMESTIC': 991549 }, 'geometry': { 'type': 'Point', 'coordinates': [-91.280491000179737, 29.420290000226256] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 148, 'OBJECTID': 148, 'ID': 144, 'PORT': 'I6111', 'PORT_NAME': 'Little Rock, AR, Port of', 'GRAND_TOTA': 914190, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 914190 }, 'geometry': { 'type': 'Point', 'coordinates': [-92.358281000340696, 34.790791000257002] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 149, 'OBJECTID': 149, 'ID': 150, 'PORT': 'I6112', 'PORT_NAME': 'Fort Smith, AR, Port of', 'GRAND_TOTA': 788939, 'FOREIGN_TO': 0, 'IMPORTS': 0, 'EXPORTS': 0, 'DOMESTIC': 788939 }, 'geometry': { 'type': 'Point', 'coordinates': [-94.385441999658141, 35.44520200009255] } },
		{ 'type': 'Feature', 'properties': { 'OBJECTID_1': 150, 'OBJECTID': 150, 'ID': 149, 'PORT': 'L3230', 'PORT_NAME': 'Buffalo, NY', 'GRAND_TOTA': 791358, 'FOREIGN_TO': 244512, 'IMPORTS': 244512, 'EXPORTS': 0, 'DOMESTIC': 546846 }, 'geometry': { 'type': 'Point', 'coordinates': [-78.909476000143357, 42.937633000232054] } }
	]
}

export default majorPortsJson;
