import React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import * as MapboxGL from 'mapbox-gl';

export enum PipelineTheme {
	Light = 0,
	Dark = 1,
}

interface PCrudeOilPipelinesLayer {
	theme: PipelineTheme;
}

class SCrudeOilPipelinesLayer {
	ref: CrudeOilPipelinesLayer = null;
	lines: boolean = true;
	labels: boolean = true;

	constructor(parent: CrudeOilPipelinesLayer) {
		this.ref = parent;
	}
}

export default class CrudeOilPipelinesLayer extends React.Component<PCrudeOilPipelinesLayer, SCrudeOilPipelinesLayer> {

	static dataSource: string = 'Crude_Oil_Pipelines-6f8gfk';

	constructor(props) {
		super(props);
		this.state = new SCrudeOilPipelinesLayer(this);
	}

	render() {
		const { state, props } = this;
		const dataSource: string = CrudeOilPipelinesLayer.dataSource;
		return (
			<React.Fragment>
				{
					state.lines &&
					<Layer
						id='crude-oil-pipelines-lines'
						type='line'
						sourceId={dataSource}
						sourceLayer={dataSource}
						layerOptions={{
							'source-layer': { dataSource },
						}}
						layout={{ 'visibility': 'visible' }}
						paint={{
							'line-color': '#993333',
							'line-dasharray': [4, 1],
							'line-width': [
								'interpolate', ['exponential', 2], ['zoom'],
								10, 3
								, 14, 4
								, 20, 10
								, 22, 16
								, 24, 22
							]
						}}
					/>
				}
				{
					state.labels &&
					<Layer
						id='crude-oil-pipelines-labels'
						type='symbol'
						source={dataSource}
						sourceId={dataSource}
						sourceLayer={dataSource}
						layout={{
							'symbol-placement': 'line',
							'text-font': ['Open Sans Regular'],
							'text-field': '{Opername} - {Pipename}',
							'text-size': 16,
						}}
						paint={{
							'text-color': (
								props.theme === PipelineTheme.Light
									? '#ffffff'
									: (props.theme === PipelineTheme.Dark ? '#000000' : '#777777')
							),
							'text-halo-color': (
								props.theme === PipelineTheme.Light
									? '#000000'
									: (props.theme === PipelineTheme.Dark ? '#ffffff' : '#aaaaaa')
							),
							'text-halo-width': 2,
						}}
					/>
				}
			</React.Fragment>
		)
	}
}
