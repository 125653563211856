import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection'
const Index = props => {
    const [supports] = useState([
        { title: 'Boost SEO ranking' },
        { title: 'Social Sharing' },
        { title: 'Marketing' },
        { title: 'Retention' },
        { title: 'Visual Reviews' },
        { title: 'Reviews Generation' }
    ]);
    const [List1] = useState([
        { title: 'Engineers and Developers', content: '', timer: 50, symbol: '+' },
        // { title: 'Customers', content: 'k', timer: 100, symbol: '+' }
    ]);
    const [List2] = useState([
        { title: 'Instances Deployed', content: 'm', timer: 35, symbol: '+' },
        // { title: 'Support answers per month', content: 'm', timer: 15, symbol: '+' }
    ]);
    return (
        <>
            <section className="iq-counter-section pt-0 iq-pb-70 section-content">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-lg-6 mb-lg-0 mb-5"> */}
                        <div className="col-lg-12 mb-lg-0 mb-5">
                            <div className="iq-title-box iq-title-default">
                                <h2 style={{ fontFamily: 'TeXGyreAdventor-Bold, sans-serif', textTransform: 'none', color: '#142149', lineHeight: '1.5em', fontWeight: 800, letterSpacing: '1px' }} className="iq-title "> Putting You on the Map</h2>
                                <p style={{ fontSize: '1rem', fontFamily: 'Roboto, sans-serif', color: '#4a4a4a', fontWeight: 300, lineHeight: 1.5 }} className="iq-title-desc ">
                                Private Label brings all of the search and marketing capabilities of Industrial Navigator to your website, under your brand identity.<br />
                                </p><br />
                                <ul style={{ listStyleType: 'disc',marginLeft:20 }}>
                                    <li>Market a portfolio of properties that you own, broker, or are located along your rail network.</li>
                                    <li>Show the capabilities of a network of transload facilities, terminals, or warehouses.</li>
                                    <li>A clean and easy user interface allows your website browsers to navigate a series of commercial properties and logistics-related assets.</li>
                                    <li>Market properties by e-mail campaigns and on social media, with tools built into Industrial Navigator</li>
                                    <li>The user interface allows collaborators both inside and outside your organization to gather and submit information about sites and assets to be included on the map, which will be published only when confirmed and properly authorized.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Index