import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab,Alert, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
// import HomePageFooter from './HomePageFooter';
import HomePageFooter from './HomePage/qloud/footerComponents';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Home from './HomePage';
import logo from '../logo.svg';
import InputBoxComponent from './InputBoxComponent';
import axios from 'axios';
import '../css/priceplans.css';
import MultiStepRegistration from './Register/MultiStepRegistration';
import UserProfile from './UserProfile';
import SimpleReactValidator from 'simple-react-validator';
import HomePageHeader from './HomePageHeader';
import {Helmet} from "react-helmet";
import gtag from 'ga-gtag';

const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: Header) => void;
    data?: any;
    shoppingCartCount?: number;
    bookmarksCount?: number;
    logo?: string;
    btnBackgroundColor?: string;
    iconColor?: string;
    secondaryColor?: string;
    history: Array<any>;
    location: any;
}


class PlansAndPricing extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    loginValidator: SimpleReactValidator = null;
    OTPValidator: SimpleReactValidator = null;
    helpers: any = null;
    

    constructor(props: PHeader) {
        super(props);
        this.state = {
            showLoginModal: false,
            showForgotPasswordModal: false,
            getHelpModal: false,
            showAlert: false,
            login_email: '',
            login_password: '',
            isLoggedIn: false,
            nextURL: '',
            propertyType: '',
            propertySubType: '',
            fpEmail: '',
            showLoginSuccessModal: false,
            showLogoutSuccessModal: false,
            showRegistrationModal: false,
            fname: '',
            lname: '',
            companyName: '',
            password: '',
            confirm_password: '',
            email: '',
            showRegistrationSuccessModal: false,
            reacatpcha_value: null,
            // shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
            bookmarksCount: props.bookmarksCount ? props.bookmarksCount : 0,
            shoppingCartCount: props.shoppingCartCount ? props.shoppingCartCount : 0,

            PageUrl:' ',
	        PageTitle:' ',
	        PageMetaRobot:' ',
	        PageMetaDiscription:' ',
        }
        //this.handleMyListing = this.handleMyListing.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.checkIfLoginPage = this.checkIfLoginPage.bind(this);
        this.setLoginEmail = this.setLoginEmail.bind(this);
        this.setLoginPassword = this.setLoginPassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);


        this.validator = new SimpleReactValidator({
            validators: {
                phone: {  // name the rule
                    message: 'The :phone must be a valid phone number.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                    required: true  // optional
                }
            }
        });
    }
    showModalGetHelp = () => {
        this.setState({
            getHelpModal: true,
        });

    }
    hideModalGetHelp = () => {
        this.setState({
            getHelpModal: false,
        });

    }
    handleShowAlert = () => {
		this.setState({ showAlert: true });
	}

	handleDismiss = () => {
		this.setState({ showAlert: false });
	}
    setFirstNameContact = (event) => {
		this.setState({ firstName: event.target.value });
	}

	setLastNameContact = (event) => {
		this.setState({ lastName: event.target.value });
	}

	setEmailContact = (event) => {
		this.setState({ email: event.target.value });
	}

	setMessageContact = (event) => {
		this.setState({ message: event.target.value });
	}
	handleContactUsGeneral = () => {

		if (this.state.firstName && this.state.lastName && this.state.email && this.state.message != '' || null) {
			this.setState({ contactusbutton: true })
			document.getElementById("contactusButon").setAttribute('disabled', 'disabled');
			axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us',
				{
					headers: {
						'Content-Type': 'application/json',
						// 'x-access-token': cookies.get('token')
					},
					// withCredentials: true,
					place_owner_name: '',
					place_id: '',
					name: this.state.firstName + ' ' + this.state.lastName,
					email: this.state.email,
					message: this.state.message,
				}).then(response => {
					if (response.status === 200) {
						this.handleShowAlert()
						this.setState({
							message: '',
							contactusbutton: false,
							// showSuccessModal: true,
						});
					} else {
						this.handleDismiss()
						alert('Something went wrong')
						this.setState({ contactusbutton: false });
					}
				}).catch(function (error) {
					console.log(error)
				});
		} else {
			// this.validator.showMessages();
			alert('Please fill all necessary information')
			this.forceUpdate();
		}
	}

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }

    componentWillMount() {
        this.getMetaTags();
        this.loginValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        });
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
        this.OTPValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }
    getMetaTags = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-page-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const plans = res.data;
				// console.log(res.data)
				for (let i = 0; i < plans.length; i++) {
					if(plans[i].PageUrl == window.location.pathname ){
						this.setState({ 
							PageUrl: plans[i].PageUrl, 
							PageTitle: plans[i].PageTitle,
							PageMetaRobot: plans[i].PageMetaRobot,
							PageMetaDiscription: plans[i].PageMetaDiscription,
						});
						return 0;
					}
					else {
						this.setState({ 
							PageUrl: ' ', 
							PageTitle: ' ',
							PageMetaRobot: ' ',
							PageMetaDiscription: ' ',
						});
					}
				}
			})
			.catch(err => {
			});
	}
    componentDidMount() {
        if (this.props.location.state !== undefined) {
            if (cookies.get('token') == undefined) {
                this.handleOpen();
            }

        }
    }

    handleShow(nextURL, type = '', subType = '') {
        this.setState({
            nextURL: nextURL,
            propertyType: type,
            propertySubType: subType,
        });

        if (cookies.get('token') !== undefined) {
            fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
                    'x-access-token': cookies.get('token')
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        if (nextURL === '/my-profile') {
                            // this.props.data.history.push('/create');
                            this.props.history.push({
                                pathname: '/my-profile',
                                state: {
                                    propertyType: this.state.propertyType,
                                    propertySubType: this.state.propertySubType,
                                }
                            })
                        }
                    } else {
                        UserProfile.forgetEmail();
                        cookies.remove('token');
                        this.props.history.push('/');
                        window.scrollTo(0, 0);
                        // const error = new Error(res.error);
                        // throw error;
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false, redirect: true });
                });
        } else {
            this.handleOpen();
        }
    }
    handleOpen = () => {
        gtag('event', 'pp-reg-click', {poll_title: 'pp-reg-click', })
        this.setState({ showRegistrationModal: true });
    }
    handleOTPVerification = () => {
        if (this.OTPValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    otp: this.state.otp,
                    login_email: this.state.login_email,
                }).then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        this.handleOTPModal();
                        this.handleLoginSuccessResponse(response);
                    }
                }).catch(function (error) {
                    // handle your error
                });
        } else {
            this.OTPValidator.showMessages();
            this.forceUpdate();
        }
    }
    logoutUser() {
        // UserProfile.forgetEmail();
        // UserProfile.forgetFirstName();
        // UserProfile.forgetLastName();
        cookies.remove('token');
        localStorage.clear();
        this.setState({ isLoggedIn: false, showLogoutSuccessModal: true });
        this.props.data.history.push('/');
    }
    handleClose() {
        this.setState({ showRegistrationModal: false });
    }
    checkIfLoginPage() {
        if (this.props.data.location.pathname === '/register') {
            this.handleClose();
        } else {
            this.props.data.history.push({
                pathname: '/register',
                state: {
                    nextURL: '/create',
                }
            })
        }
    }
    setLoginEmail(event) {
        this.setState({ login_email: event.target.value });
    }
    setLoginPassword(event) {
        this.setState({ login_password: event.target.value });
    }
    handleLogin() {
        if (this.loginValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    login_email: this.state.login_email,
                    login_password: this.state.login_password,
                }).then((response: {
                    status: number
                    , data: {
                        bookmarks_str: string
                        , email: string
                        , first_name: string
                        , last_name: string
                        , max_no_entries: string
                        , options: string
                        , place_names_arr: string //Array<any>
                        , user_type: string
                        , token: string
                    }
                }) => {
                    document.getElementById('login_error').style.display = 'none';
                    document.getElementById('verify_email_error').style.display = 'none';
                    console.log(response);
                    this.handleClose();
                    // alert(window.location.href)
                    if (response.status === 200) {
                        let bookmarksCount = 0;
                        console.log('response.data')
                        console.log(response.data)
                        if (response.data.bookmarks_str) {
                            const bookmarksArr = response.data.bookmarks_str.split(',');
                            bookmarksCount = bookmarksArr.length;
                        }
                        localStorage.setItem('bookmarks', response.data.bookmarks_str);
                        localStorage.setItem('listingNames', response.data.place_names_arr);
                        localStorage.setItem('maxNoEntries', response.data.max_no_entries);
                        localStorage.setItem('membershipOptions', response.data.options);

                        if (response.data.user_type === 'admin') {
                            this.setState({
                                showOTPModal: true
                            });
                        } else {
                            this.handleLoginSuccessResponse(response);
                        }
                    } else {
                        this.setState({ isLoggedIn: false });
                        if (response.status === 204) {
                            document.getElementById('verify_email_error').style.display = 'block';
                            document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
                        } else {
                            document.getElementById('login_error').style.display = 'block';
                            document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                }).catch((error) => {
                    // handle your error
                    // document.getElementById('login_error').style.display = 'block';
                    // document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                });
        } else {
            this.loginValidator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    handleShowForgotPasswordModal = () => {
        this.setState({ showForgotPasswordModal: true });
        this.handleClose();
    }
    handleLoginSuccessModal() {
        this.setState({ showLoginSuccessModal: false });
    }

    handleRegistrationSuccessModal = () => {
        this.setState({ showRegistrationSuccessModal: false });
    }

    showRegistrationModal = () => {
        this.setState({
            showRegistrationModal: true,
            showLoginModal: false,
        });
    }
    handleForgotPasswordClose = () => {
        this.setState({ showForgotPasswordModal: false });
        this.handleOpen();
    }
    setFPEmail = (event) => {
        this.setState({ fpEmail: event.target.value });
    }
    handleLogoutSuccessModal = () => {
        this.setState({ showLogoutSuccessModal: false });
    }
    handleOTPModal = () => {
        this.setState({ showOTPModal: false });
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    }

    handleRegistrationModal = () => {
        this.setState({ showRegistrationModal: false });
    }
    handleLoginSuccessResponse = response => {
        UserProfile.setEmail(response.data.email);
        UserProfile.setFirstName(response.data.first_name);
        UserProfile.setLastName(response.data.last_name);
        UserProfile.setUserType(response.data.user_type);
        UserProfile.setCompanyName(response.data.companyName);

        this.setState({
            isLoggedIn: true,
            showLoginSuccessModal: true,
        });

        cookies.set('token', response.data.token);
        this.forceUpdate();

        if (this.props.data.location.pathname === '/register' || this.props.data.location.pathname === '/success' || this.state.nextURL === '/create') {
            // this.props.data.history.push('/create');
            this.props.data.history.push({
                pathname: '/create',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
        else if (this.state.nextURL === '/listing') {
            // this.props.data.history.push('/listing');
            this.props.data.history.push({
                pathname: '/listing',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
    }
    sendFPEmail = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
            {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                fpEmail: this.state.fpEmail
            }).then(response => {
                document.getElementById('fp_success').style.display = 'block';
                document.getElementById('fp_error').style.display = 'none';
            }).catch(function (error) {
                document.getElementById('fp_success').style.display = 'none';
                document.getElementById('fp_error').style.display = 'block';
                // alert('Something went wrong! Please try after sometime.')
            });
    }


    // child: Header = null;
    child: HomePageHeader = null;


    render() {

        return (
            <>
                <Helmet>
                    <title>{this.state.PageTitle != " " ? this.state.PageTitle : "Plans And Pricing"}</title>
                    <meta property="og:url" content={window.location.href} />
                    <meta name='description' content={this.state.PageMetaDiscription != " " ? this.state.PageMetaDiscription : "Discription"} />
                    <meta name="robots" content={this.state.PageMetaRobot != " " ? this.state.PageMetaRobot : " "} />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>

                {/* <Header data={this.props} onRef={ref => (this.child = ref)} /> */}
                <HomePageHeader data={this.props} onRef={ref => (this.child = ref)} /> 

                <section className='margin-bottom-0' style={{ minHeight: 1250,marginTop:50, fontFamily: "Roboto, sans-serif" }}>
                    <div className="d-flex justify-content-center">
                        <div className='col-lg-8 col-md-12 col-sm-12 padding-0 make-width-large' >
                            {/*<div style={{ marginLeft: "200px", marginTop: "100px", marginRight: "200px" }} >*/}
                            <div className="container-fluid" style={{ marginTop: 100, fontFamily: "Roboto, sans-serif" }} >
                                <h1 className="content_area page-title-text" style={{ marginLeft: 15, fontWeight: 600, fontFamily: 'Roboto, sans-serif',color:'rgb(20, 33, 73)' }}>Plans <span style={{color:'rgb(0, 157, 245)'}} className="highlight">And Pricing</span> </h1>
                                <p style={{ fontSize: "15px", marginLeft: 15 }} className="copy margin--top-c4 para_content">Select a subscription that best fits your needs, or contact us for corporate or custom solutions. </p>

                                <div className='container-fluid' >
                                    <Tabs defaultActiveKey="Monthly"  >
                                        <Tab eventKey="Monthly" title="Monthly Plans" style={{ color: "#4a4a4a" }}>
                                            <>

                                                <div style={{ padding: 0, textDecoration: "none" }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
                                                    <div className="box-sizes reduce-table-height-1" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
                                                        <div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium">


                                                                <h2 style={{ fontSize: 30 ,color: "rgb(20, 33, 73)"}} className="h5 pricingPlans__header">BASIC</h2>
                                                                <p style={{ fontSize: 15 ,color: "#4a4a4a" }} className="copy margin--top-c4">Get started marketing and browsing. </p>

                                                            </div>
                                                        </div>

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15,marginTop:30 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

                                                                <div className="flex flex--bottom" aria-hidden="true">
                                                                    <div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
                                                                        <div style={{color: "rgb(20, 33, 73)"}} className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">FREE</div>
                                                                        <div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer"></div>
                                                                        <div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
                                                                        <div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
                                                                    </div>

                                                                    <div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
                                                                    </div>
                                                                </div><br /><br />
                                                                <div className="experiment">

                                                                    <div className="margin--bottom-c3">
                                                                        {/* <a role='button' className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: "#00B2FF", color: 'white', height: 40, width: "100%", fontSize: 16 }} onClick={this.handleShow.bind(this, '/my-profile', '', '')}>GET STARTED</a> */}
                                                                        <Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={this.handleShow.bind(this, '/my-profile', '', '')} style={{ border: '1px solid white', borderRadius: '0px !important', background: "#00B2FF", color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><br />

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
                                                            <ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

                                                                <li className="margin--bottom-c4"> Search by Location    </li>
                                                                <li className="margin--bottom-c4">Message listers for pricing and details  </li>
                                                                <li className="margin--bottom-c4">Basic map layers (Class I railroads,major highways, major ports)</li>
                                                                <li className="margin--bottom-c4">5 free listings </li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div style={{ padding: 0 }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
                                                    <div className="box-sizes reduce-table-height-1" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
                                                        <div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium">


                                                                <h2 style={{ fontSize: 30 ,color: "rgb(20, 33, 73)"}} className="h5 pricingPlans__header">  POWER SEARCH</h2>
                                                                <p style={{ fontSize: 15 ,color: "#4a4a4a"}} className="copy margin--top-c4">Expand your search and listing capabilities </p>

                                                            </div>
                                                        </div>

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15,marginTop:30 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

                                                                <div className="flex flex--bottom " aria-hidden="true">
                                                                    <div  style={{color: "rgb(20, 33, 73)"}} className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
                                                                        <div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
                                                                        <div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">29</div>
                                                                        <div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
                                                                        <div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
                                                                    </div>

                                                                    <div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28,color:'rgb(20, 33, 73)' }}>/per month                      </div>
                                                                </div>



                                                                <br /><br />
                                                                <div className="experiment">

                                                                    <div className="margin--bottom-c3">
                                                                        <Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={this.handleShow.bind(this, '/my-profile', '', '')} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><br />

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
                                                            <ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

                                                                <li className="margin--bottom-c4"> All Basic functionality +   </li>
                                                                <li className="margin--bottom-c4">Search by keyword  </li>
                                                                <li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
                                                                <li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
                                                                <li className="margin--bottom-c4">Export map results and views</li>
                                                                <li className="margin--bottom-c4">Up to 25 listings</li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div style={{ padding: 0 }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
                                                    <div className="box-sizes reduce-table-height-1" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
                                                        <div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium">


                                                                <h2 style={{ fontSize: 30,color: "rgb(20, 33, 73)" }} className="h5 pricingPlans__header">POWER PRO</h2>
                                                                <p style={{ fontSize: 15 ,color: "#4a4a4a"}} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

                                                            </div>
                                                        </div>

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15,marginTop:30 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

                                                                <div className="flex flex--bottom" aria-hidden="true">
                                                                    <div className="formattedPrice flex flex--left"  style={{color: "rgb(20, 33, 73)"}}data-behavior="formattedPrice" data-plan="premium">
                                                                        <div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
                                                                        <div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">49</div>
                                                                        <div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
                                                                        <div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
                                                                    </div>

                                                                    <div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 ,color:'rgb(20, 33, 73)'}}>
                                                                        &nbsp;/per month</div>
                                                                </div>

                                                                <p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

                                                                <br /><br />
                                                                <div className="experiment">

                                                                    <div className="margin--bottom-c3">
                                                                        <Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={this.handleShow.bind(this, '/my-profile', '', '')} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><br />

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
                                                            <ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

                                                                <li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
                                                                <li className="margin--bottom-c4">See who has viewed your listings </li>
                                                                <li className="margin--bottom-c4">Premium placement within search results </li>
                                                                <li className="margin--bottom-c4">Up to 100 listings </li>
                                                            </ul>
                                                        </div>

                                                    </div>

                                                </div>

                                                <div style={{ padding: 0 }} className='col-md-3 mt-0 mt-md-4 padding-top-30'>
                                                    <div className="box-sizes reduce-table-height-1" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 850 }}>
                                                        <div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium">


                                                                <h2 style={{ fontSize: 30,color: "rgb(20, 33, 73)" }} className="h5 pricingPlans__header">Private Label and Custom Solutions</h2>
                                                                <p style={{ fontSize: 15 ,color: "#4a4a4a"}} className="copy margin--top-c4">Explore custom solutions </p>

                                                            </div>
                                                        </div><br />

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

                                                                <div className="flex flex--bottom" aria-hidden="true">
                                                                    <div className="formattedPrice1 flex flex--left"  style={{color: "rgb(20, 33, 73)"}} data-behavior="formattedPrice" data-plan="premium">
                                                                        <div className="formattedPrice1__price--symbol--left" data-behavior="formattedPrice__symbol">Contact For Pricing</div>
                                                                        <div className="formattedPrice1__price--integer" data-behavior="formattedPrice__integer"></div>
                                                                        <div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
                                                                        <div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
                                                                    </div>
                                                                </div>


                                                                <hr />
                                                                <div className="experiment">

                                                                    <div className="margin--bottom-c3">
                                                                        <p style={{ fontSize: 15,color: "#4a4a4a" }} className="copy margin--top-c4">Call (312) 957-7757<br /><a onClick={this.showModalGetHelp}>info@industrialnavigator.com</a></p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
                                                            <ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

                                                                <li className="margin--bottom-c4">Implant the functionality of Industrial Navigator within your website to market your assets and services    </li>
                                                                <li className="margin--bottom-c4">Create custom map layers  </li>
                                                                <li className="margin--bottom-c4">Market research and logistics optimization  </li>
                                                                <li className="margin--bottom-c4">Multi-user subscriptions </li>
                                                            </ul>
                                                        </div>

                                                        {/*

<h3 style={{ textAlign: 'center', height: 30 }}>PRIVATE LABEL AND CORPORATE SOLUTIONS</h3>
<h4 style={{ fontSize: 20, verticalAlign: 'text-top' }} className="text-center">
Contact for pricing
</h4>

<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Explore custom solutions</p>

<hr style={{ marginLeft: 20, marginRight: 20 }} />

<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
<li style={{ margin: 8 }}> Implant the functionality of Industrial Navigator on your website to market your assets and services</li>
<li style={{ margin: 8 }}> Create custom map layers </li>
<li style={{ margin: 8 }}> Market research and logistics optimization</li>
<li style={{ margin: 8 }}> Multi-user subscriptions</li>

</ul>
<div className="d-flex justify-content-center">

<label style={{ color: '#000', position: 'absolute', bottom: '2%', fontSize: 18 }}>
Call (312) 957-7757
</label>

</div>
*/}
                                                    </div>
                                                </div>


                                            </>
                                        </Tab>
                                        <Tab eventKey="Yearly" title="Yearly Plans" style={{ color: "black" }}>
                                            <>
                                                <div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
                                                    <div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
                                                        <div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium">


                                                                <h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
                                                                <p style={{ fontSize: 15,color: "#4a4a4a" }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

                                                            </div>
                                                        </div>

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15,marginTop:30 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

                                                                <div className="flex flex--bottom" aria-hidden="true">
                                                                    <div className="formattedPrice flex flex--left"style={{color: "rgb(20, 33, 73)"}} data-behavior="formattedPrice" data-plan="premium">
                                                                        <div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
                                                                        <div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">299 </div>
                                                                        <div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
                                                                        <div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
                                                                    </div>

                                                                    <div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28,color: "rgb(20, 33, 73)"}}>
                                                                        &nbsp;/per year                      </div>
                                                                </div>



                                                                <br /><br />
                                                                <div className="experiment">

                                                                    <div className="margin--bottom-c3">
                                                                        <Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={this.handleShow.bind(this, '/my-profile', '', '')} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><br />

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 ,color: "#4a4a4a"}}>
                                                            <ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

                                                                <li className="margin--bottom-c4"> All Basic functionality +   </li>
                                                                <li className="margin--bottom-c4">Search by keyword  </li>
                                                                <li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
                                                                <li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
                                                                <li className="margin--bottom-c4">Export map results and views</li>
                                                                <li className="margin--bottom-c4">Up to 25 listings</li>
                                                            </ul>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div style={{ padding: 0 }} className='col-md-6 mt-0 mt-md-4 padding-top-30'>
                                                    <div className="box-sizes" style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 760 }}>
                                                        <div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium">


                                                                <h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
                                                                <p style={{ fontSize: 15,color: "#4a4a4a" }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

                                                            </div>
                                                        </div>

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15,marginTop:30   }}>
                                                            <div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

                                                                <div className="flex flex--bottom" aria-hidden="true">
                                                                    <div className="formattedPrice flex flex--left" style={{color: "rgb(20, 33, 73)"}} data-behavior="formattedPrice" data-plan="premium">
                                                                        <div className="formattedPrice__price--symbol--left price_plans_less" data-behavior="formattedPrice__symbol">$</div>
                                                                        <div className="formattedPrice__price--integer price_plans_less" data-behavior="formattedPrice__integer">499</div>
                                                                        <div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
                                                                        <div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
                                                                    </div>

                                                                    <div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28,color: "rgb(20, 33, 73)" }}>/per year </div>
                                                                </div>

                                                                <p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

                                                                <br /><br />
                                                                <div className="experiment">

                                                                    <div className="margin--bottom-c3">
                                                                        <Button className='font-Gotham-Pro-Medium font-weight-normal-imp' onClick={this.handleShow.bind(this, '/my-profile', '', '')} style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div><br />

                                                        <div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 ,color: "#4a4a4a"}}>
                                                            <ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

                                                                <li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
                                                                <li className="margin--bottom-c4">See who has viewed your listings </li>
                                                                <li className="margin--bottom-c4">Premium placement within search results </li>
                                                                <li className="margin--bottom-c4">Up to 100 listings </li>
                                                            </ul>
                                                        </div>

                                                    </div>

                                                </div>


                                            </>
                                        </Tab>

                                    </Tabs>



                                </div>
                            </div>
                        </div>
                    </div>
                </section>






                <HomePageFooter />
                <Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Forgot Password
                                    </h2>
                                    <form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
                                        <div id='fp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
                                        </div>
                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div>
                            <div style={{ padding: '3rem', backgroundColor: 'white' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
                                        <img src={logo} />
                                    </div>
                                    <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
                                        <div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Enter email address and password to login</div>
                                        <div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
                                        <div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='login_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setLoginEmail} value={this.state.login_email} />
                                        </div>
                                        {this.loginValidator.message('email', this.state.login_email, 'required')}
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword} value={this.state.login_password} />
                                        </div>
                                        {this.loginValidator.message('password', this.state.login_password, 'required')}
                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin}>Sign In</button>
                                        </div>
                                        <div className='col_full center padding-top-20'>
                                            <span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
                                        </div>
                                    </form>
                                    <div style={{ paddingTop: '1rem' }}>
                                        <small>
                                            <div className='text-center text-black font-weight-bold'>
                                                Don't have an account? <span className='cursor-pointer color-blue' onClick={this.showRegistrationModal}>Sign Up</span></div>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Login Successful
                            </h2>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showLogoutSuccessModal} onHide={this.handleLogoutSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Logout Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                You are logged out of the system
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLogoutSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName='custom-modal-step3' show={this.state.showRegistrationModal} onHide={this.handleRegistrationModal} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
                        <div>
                            <div>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '30px auto' }}>
                                        <img src={logo} />
                                    </div>
                                    {/* <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Welcome to Industrial Navigator!
									</h2> */}
                                    <MultiStepRegistration />
                                    {/* <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
										<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Please enter your details below to register for Industrial Navigator.</div>

										<div id='regi_errors' className='margin-top-15'></div>

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
										</div>
										{this.validator.message('first name', this.state.fname, 'required')}

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
										</div>
										{this.validator.message('last name', this.state.lname, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
										</div>
										{this.validator.message('company name', this.state.companyName, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail.bind(this)} value={this.state.email} />
										</div>
										{this.validator.message('email', this.state.email, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
										</div>
										{this.validator.message('password', this.state.password, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
										</div>
										{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
										<ReCAPTCHA
											ref={recaptchaRef}
											// size='invisible'
											className='margin-top-15'
											onChange={this.onRecaptchaChange.bind(this)}
											sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
										/>
										{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
										<div className='col_full center padding-top-20'>
											<button className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
										</div>
									</form> */}
                                    {/* <div style={{ paddingTop: '1rem' }}>
										<small>
											<div className='text-center text-black font-weight-bold'>
												Already have an account? <span className='cursor-pointer color-blue' onClick={this.checkIfLoginPage}>Sign In</span></div>
										</small>
									</div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Registration Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                Please verify your email address before adding a listing.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showOTPModal} onHide={this.handleOTPModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Verify OTP
                                    </h2>
                                    <form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='otp_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email.</div>
                                        <div id='otp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        <InputBoxComponent type='number' min='0' name='otp' onHandleInputChange={this.handleInputChange} />
                                        {this.OTPValidator.message('otp', this.state.otp, 'required')}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPVerification}>Verify</button>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.getHelpModal} onHide={this.hideModalGetHelp} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ paddingTop: '2rem', paddingLeft: '1rem', paddingRight: '1rem' }} closeButton>
                        <h3 style={{ fontSize: 20 }}>CONTACT US</h3>

                    </Modal.Header>
                    <Modal.Body style={{ background: 'white', paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
                        <div>
                            <b style={{ fontWeight: 500, fontSize: 16 }}> Please email us at info@industrialnavigator.com or call us at +1 (312) 957-7757 for any feedback or questions.</b>
                        </div>
                        {
                            this.state.showAlert &&
                            <Alert bsStyle='success' onDismiss={this.handleDismiss}>
                                <h2 style={{ marginTop: 0, marginBottom: 10,color:'white' }}>Success!</h2>
                                <p>
                                    Your message has been sent!
                                </p>
                            </Alert>
                        }
                        <div style={{ paddingLeft: 0, paddingRight: 0 }} className='col-lg-12 col-md-12 mt-5 mt-md-0 padding-top-30'>
                            <div className='row'>
                                <form style={{ width: '100%' }}>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <input type='text' className='form-control margin-bottom-0-imp' onChange={this.setFirstNameContact} value={this.state.firstName} required placeholder='Your First Name'></input>
                                        {/* {this.validator.message('first name', this.state.firstName, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <input type='text' className='form-control margin-bottom-0-imp' onChange={this.setLastNameContact} value={this.state.lastName} required placeholder='Your Last Name'></input>
                                        {/* {this.validator.message('last name', this.state.lastName, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <input type='email' className='form-control margin-bottom-0-imp' onChange={this.setEmailContact} value={this.state.email} required placeholder='Your Email'></input>
                                        {/* {this.validator.message('email', this.state.email, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <textarea className='form-control margin-bottom-0-imp' onChange={this.setMessageContact} value={this.state.message} placeholder='Your message' rows={6}></textarea>
                                        {/* {this.validator.message('message', this.state.message, 'required')} */}
                                    </div>
                                    <div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
                                        <Button disabled={this.state.contactusbutton} id='contactusButon' style={{ borderRadius: '0px !important', background: '#4DAFFF', height: 50, width: '100%', fontSize: 18, paddingLeft: 25, paddingRight: 25 }} onClick={this.handleContactUsGeneral}>
                                            <span className='pull-left font-Gotham-Pro-Medium font-weight-normal'>Contact Us</span>
                                            {
                                                this.state.contactusbutton ?
                                                    <i className="fa fa-spinner fa-spin fs-30"></i> :
                                                    ''


                                            }
                                            <span className='pull-right'><RightArrowSVGIcon /></span></Button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideModalGetHelp}>Close</button>
                    </Modal.Footer>
                </Modal>


            </>
        )
    }
}

export default PlansAndPricing;

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)
const RightArrowSVGIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 20 20'
	>
		<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
			<g fill='#FFF' transform='translate(-1166 -2563)'>
				<g transform='translate(-27)'>
					<g transform='translate(0 2140)'>
						<g transform='translate(857 120)'>
							<g transform='translate(0 286)'>
								<g transform='translate(336 17)'>
									<path d='M10.334.244L20 10l-9.666 9.756-1.167-1.179 7.671-7.744H0V9.167h16.839L9.167 1.422 10.334.244z'></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)