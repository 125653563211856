import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
// import ReCAPTCHA from 'react-google-recaptcha';
// const recaptchaRef = React.createRef();

class Step3 extends Component {

	constructor(props) {
		super(props);

		this.state = {
			fname: '',
			lname: '',
			companyName: '',
			password: '',
			confirm_password: '',
			email: '',
			// reacatpcha_value: null,
		}
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
	}

	handleInputChange  = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	// onRecaptchaChange(value) {
	// 	console.log('Captcha value: ', value);
	// 	this.setState({ reacatpcha_value: value });
	// }

	render() {

		return (
			<h2>Step 3</h2>
		)
	}
}

export default Step3;