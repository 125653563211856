import React, { Component } from 'react';
import Header from '../HeaderLTE';
import SideBar from '../SideBarLTE';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from '../UserProfile';
import '../../css/adminLTE-custom.css';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';
import { Modal, Button, Row } from 'react-bootstrap';
import Footer from '../Footer';

const columns = memoize(clickHandler => [
	{
		name: 'Page Name',
		selector: 'PageName',
		sortable: true,
	},
	{
		name: 'Page URL',
		selector: 'PageUrl',
		sortable: true,
	},
	{
		name: 'Page Title',
		selector: 'PageTitle',
		sortable: true,
		// width: '125px',
	},
	{
		name: 'Page Meta Description',
		selector: 'PageMetaDiscription',
		sortable: true,
		// width: '125px'
	},
	{
		name: 'Page Meta Robots',
		selector: 'PageMetaRobot',
		sortable: true,
		// width: '150px'
	},
	{
		cell: (row) => (
			<button className='btn btn-default border-radius-25-imp'
				onClick={clickHandler} data-id={row.id} data-action='edit'
			>
				Edit
			</button>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	},
	{
		cell: (row) => (
			<button className='btn btn-danger border-radius-25-imp'
				onClick={clickHandler} data-id={row.id} data-action='delete'
			>
				Delete
			</button>
		),
		ignoreRowClick: true,
		allowOverflow: true,
		button: true,
	}
]);

class Membership extends Component {

	// _isMounted = false;

	constructor(props) {
		super(props);
		this.state ={
			showDeleteModal: false,
			data: []
		}
	}

	handleButtonClick = (event) => {
		const id = event.target.getAttribute('data-id');
		const buttonAction = event.target.getAttribute('data-action');

		if (buttonAction === 'edit') {
			// this.props.history.push('/edit-place-' + id);
			this.props.history.push('/edit-page-seo-' + id);
		} else {
			this.setState({
				deleteRecId: id,
				showDeleteModal: true
			});
		}
	}



	componentWillUnmount() {
		// this._isMounted = false;
	}

	componentDidMount() {
        // this._isMounted = true;
		this.getPageDetails();
	}

	getPageDetails = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-page-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const plans = res.data;
				// console.log(res.data)
				const plan_list = [];
				for (let i = 0; i < plans.length; i++) {
					const property_obj = {
						id: plans[i].ID,
						PageName: plans[i].PageName,
						PageUrl: plans[i].PageUrl,
						PageTitle: plans[i].PageTitle,
                        PageMetaRobot: plans[i].PageMetaRobot,
						PageMetaDiscription: plans[i].PageMetaDiscription,
					}
					plan_list.push(property_obj)
				}
				this.setState({ data: plan_list });
			})
			.catch(err => {
			});
	}

	handleDeleteRecord = async () => {
		const id = this.state.deleteRecId;
		localStorage.removeItem('_delete');
		await axios.post(process.env.REACT_APP_BASE_URL + '/api/delete-page-record', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			rec_id: id,
		})
			.then(res => {
				if (res.status === 200) {
					this.setState({
						showDeleteModal: false
					});
					this.callback();
				}
			})
	}

	handleDeleteModal = () => {
		this.setState({
			deleteRecId: null,
			showDeleteModal: false
		});
	}

	callback = () => {
		this.getPageDetails();
	}

	configureNewPage = () => {
		console.log('this.props')
		console.log(this.props)
		this.props.history.push('/configure-page-seo');
	}

	render() {
		return (
			<div style={{ backgroundColor: '#222D32' }}>
				<Header />
				<SideBar />
				<div className='content-wrapper'>
					<section className='content-header' style={{ backgroundColor: 'white' }}>
						<div className='row'>
							<div className='col-md-12'>
								<div className='col-md-12'>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, fontSize: 30
										, fontWeight: 700
										, marginTop: 15
										, marginBottom: 30
									}}
									>
										<div>SEO Module</div>
									</div>
									<div className='col-md-6' style={{
										flex: '1 0 auto'
										, color: 'rgba(0, 0, 0, 0.87)'
										, marginTop: 15
										, marginBottom: 30
									}}
									>
										<button className='btn btn-default border-radius-25-imp pull-right' onClick={this.configureNewPage}>
											Configure New Page
										</button>
									</div>
								</div>
								<div className='col-md-12'>
									<DataTable
										title=''
										columns={columns(this.handleButtonClick)}
										data={this.state.data}
										defaultSortField='start_date'
										// onSort={this.handleSort}
										pagination={true}
										paginationPerPage={50}
										defaultSortAsc={false}
										paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
									/>
								</div>
							</div>
						</div>
					</section>
				</div>

				{/* Delete modal */}
				<Modal show={this.state.showDeleteModal} onHide={this.handleDeleteModal}
					style={{ padding: '0px!important', opacity: 1 }}
					animation={false}
				>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure?
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-danger border-radius-25-imp'
							onClick={this.handleDeleteRecord}
						>
							Confirm
						</button>
						<button type='button'
							className='btn btn-default border-radius-25-imp margin-left-15'
							onClick={this.handleDeleteModal}
						>
							Cancel
						</button>
					</Modal.Footer>
				</Modal>

				<Footer />
			</div>
		)
	}
}

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)

export default withRouter(Membership);