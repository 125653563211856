import React, { Component } from 'react';
import { Overlay, Button } from 'react-bootstrap';

interface PFilterButtonIcon  {
	renderIconContent?: (sizeIco: number) => React.ReactNode;
	isActiveFilter?: () => boolean;
	children?:React.ReactNode;
	isModalActive?: boolean;
	isDisabled?: boolean;
	isExtended?: boolean;
	customTooltipClass?: string;
	customContentClass?: string;
	customButtonClass?: string;
	renderTooltipContent?: () => React.ReactNode;
	getChildHeight?: () => number;
	btnColor?: string;
	backgroundColor?: string;
	toolTipBackgroundColor?: string;
	sizeIco: number;
	placeIco: number;
	activeHighlightedColor: string;
}

class SFilterButtonIcon {
	ref: FilterButtonIcon = null;
	buttonRef: HTMLButtonElement = null;

	isModalActive: boolean = null;
	isDisabled: boolean = null;
	customTooltipClass: string = '';
	customContentClass: string = '';
	customButtonClass: string = '';
	autoHide: boolean = true;
	show: boolean = false;
	flagTooltip: boolean = false;
	innerWidth: number = window.innerWidth;
	innerHeight: number = window.innerHeight;
	sizeIco: number = 32;
	placeIco: number = 54;
	activeHighlightedColor: string = null;

	constructor(ref: FilterButtonIcon) {
		this.ref = ref;
		if (ref) {
			const { props } = ref;
			this.reInit(props);
		}
		//
		this.autoHide = this.isModalActive ? false : true;
	}

	reInit(props: PFilterButtonIcon) {
		this.isModalActive = props.isModalActive !== undefined ? props.isModalActive : this.isModalActive;
		this.isDisabled = props.isDisabled !== undefined ? props.isDisabled : this.isDisabled;
		this.customTooltipClass = props.customTooltipClass !== undefined ? props.customTooltipClass : this.customTooltipClass;
		this.customContentClass = props.customContentClass !== undefined ? props.customContentClass : this.customContentClass;
		this.customButtonClass = props.customButtonClass !== undefined ? props.customButtonClass : this.customButtonClass;
		this.sizeIco = props.sizeIco !== undefined ? props.sizeIco : 32;
		this.placeIco = props.placeIco !== undefined ? props.placeIco : 54;
		this.activeHighlightedColor = props.activeHighlightedColor !== undefined ? props.activeHighlightedColor : this.activeHighlightedColor;
	}

	copy(copy: SFilterButtonIcon): SFilterButtonIcon {
		if (!copy) {
			copy = new SFilterButtonIcon(null);
		}
		this.isModalActive = copy.isModalActive;
		this.isDisabled = copy.isDisabled;
		this.customTooltipClass = copy.customTooltipClass;
		this.customContentClass = copy.customContentClass;
		this.customButtonClass = copy.customButtonClass;
		this.autoHide = copy.autoHide;
		this.show = copy.show;
		this.flagTooltip = copy.flagTooltip;
		this.innerWidth = copy.innerWidth;
		this.innerHeight = copy.innerHeight;
		this.sizeIco = copy.sizeIco;
		this.placeIco = copy.placeIco;
		this.activeHighlightedColor = copy.activeHighlightedColor;
		return this;
	}

	setButtonRef = (buttonRef: HTMLButtonElement) => {
		this.buttonRef = buttonRef;
	}
}

export default class FilterButtonIcon extends Component<PFilterButtonIcon, SFilterButtonIcon> {

	constructor(props: PFilterButtonIcon) {
		super(props);
		this.state = new SFilterButtonIcon(this);
		this.componentConstruct();
	}

	componentConstruct() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize = () => {
		if (!this.state.show) {
			this.setState({
				innerWidth: window.innerWidth
				, innerHeight: window.innerHeight
			});
			return;
		}
		this.setState({
			show: false
			, innerWidth: window.innerWidth
			, innerHeight: window.innerHeight
		});
	};

	render() {
		const { state, props } = this;
		const {
			isModalActive
			, sizeIco
			, placeIco
			, activeHighlightedColor
		} = state;
		const tooltipClasses = `custom-tooltip ${state.customTooltipClass}`.trim();
		const contentWrapperClasses = `custom-tooltip-content-wrapper ${state.customContentClass}`.trim();
		const buttonWrapperClasses = `custom-tooltip-button-wrapper ${state.customButtonClass}`.trim();
		const tooltipContent = state.flagTooltip && !state.show ? props.renderTooltipContent() : null;
		const iconContent = props.renderIconContent ? props.renderIconContent(sizeIco) : null;
		const flagTooltipContent = tooltipContent === null ? false : true;
		const isActiveFilter = props.isActiveFilter && props.isActiveFilter() ? true : false;
		const childHeight: number = props.getChildHeight ? props.getChildHeight() : 0;
		const maxHeight: number = state.innerHeight > 263 ? state.innerHeight - 263 : 10;
		const btnColor = props.btnColor ? 'black' : '#07345a';
		const backgroundColor = props.backgroundColor ? '#f78e1c' : '#07345a';
		const title = iconContent !== null ? '1' : '0';
		return (
			<div
				style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}
				onMouseEnter={() => {
					if (!state.isDisabled) {
						return;
					}
					this.setState({ flagTooltip: true }, () => {
					});
				}}
				onMouseLeave={() => {
					if (!state.isDisabled) {
						return;
					}
					this.setState({ flagTooltip: false }, () => {
					});
				}}
			>
				<Button
					key={'btn_' + activeHighlightedColor}
					ref={state.setButtonRef}
					disabled={state.isDisabled}
					style={Object.assign({}, {
						color: isActiveFilter ? (activeHighlightedColor ? activeHighlightedColor : '#0094e9') : (state.isDisabled ? '#a3a3a3' : '#383838'),
						backgroundColor: isActiveFilter ? 'rgba(255,255,255,0.7)' : 'rgba(255,255,255,0.7)',
					}, state.isDisabled && {
						opacity: 1,
					})}
					onClick={() => {
						this.setState({ show: !state.show }, () => {
						});
					}}
					onMouseEnter={() => {
						this.setState({ flagTooltip: true }, () => {
						});
					}}
					onMouseLeave={() => {
						this.setState({ flagTooltip: false }, () => {
						});
					}}
				>
					{
						iconContent
					}
				</Button>
				<Overlay
					key={'fb_' + title + '_' + state.show + '_' + props.children.toString()}
					target={state.buttonRef}
					show={state.show}
					placement='bottom'
					rootClose
					onHide={() => {
						if (this.state.autoHide) {
							this.setState({ show: false });
						}
					}}
				>
					<div
						key={'fbdiv03_' + title + '_' + state.show + '_' + props.children.toString()}
						className={tooltipClasses}
						style={{
							marginTop: 10,
							overflowX: 'visible',
							overflowY: maxHeight < childHeight ? 'scroll' : 'visible',
							maxHeight: maxHeight
						}}
					>
						<div
							key={'fbdiv04_' + title + '_' + state.show + '_' + props.children.toString()}
							className={contentWrapperClasses}
							style={{
								overflow: 'visible',
								width: '99%',
							}}
						>
							<div className='custom-tooltip-content'>
								{
									props.children
								}
							</div>
							<div className={buttonWrapperClasses}>
								<button className='custom-tooltip-close-button'
									onClick={() => {
										this.setState({ show: !state.show });
									}}
									style={Object.assign({
									}, {
										backgroundColor: backgroundColor
										, maxWidth: 100
										, justifyContent: 'space-around'
									}, {
										backgroundColor: backgroundColor
									}, activeHighlightedColor && {
										backgroundColor: activeHighlightedColor
									})}
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</Overlay>
				<Overlay
					key={'fb_' + title + '_' + state.flagTooltip + '_' + state.show + '_' + props.children.toString()}
					target={state.buttonRef}
					show={flagTooltipContent && state.flagTooltip && (!state.show || state.isDisabled)}
					placement='bottom'
				>
					<div
						key={'fbdiv01_' + title + '_' + state.flagTooltip + '_' + state.show + '_' + props.children.toString()}
						className={tooltipClasses}
						style={Object.assign({}
							, { backgroundColor: backgroundColor ? backgroundColor : '#4596FF' }
							, props.toolTipBackgroundColor && { backgroundColor: props.toolTipBackgroundColor }
						)}
					>
						<div
							key={'fbdiv02_' + title + '_' + state.flagTooltip + '_' + state.show + '_' + props.children.toString()}
							className={contentWrapperClasses}
						>
							<div style={Object.assign({}, { width: 'auto' })}>
								{
									tooltipContent
								}
							</div>
						</div>
					</div>
				</Overlay>
			</div>
		)
	}
}
