import { LabelValue, sortArray, joinFilterV } from './Types';

export const arrClassOneConnections: Array<LabelValue> = [
	{ 'label': 'BNSF', 'value': 'BNSF' },
	{ 'label': 'CN', 'value': 'CN' },
	{ 'label': 'CP', 'value': 'CP' },
	{ 'label': 'CSX', 'value': 'CSX' },
	{ 'label': 'KCS', 'value': 'KCS' },
	{ 'label': 'NS', 'value': 'NS' },
	{ 'label': 'UP', 'value': 'UP' },
];

export const classOneConnectionsAllFilter: string = joinFilterV(
	[
		...arrClassOneConnections
	]
);

export const classOneConnectionsOptions: Array<LabelValue>
	= [
		{ 'label': 'ALL', 'value': classOneConnectionsAllFilter }
		, ...sortArray(arrClassOneConnections)
	];


export const arrRadius: Array<LabelValue> = [
	{ 'label': '5', 'value': '5' },
	{ 'label': '10', 'value': '10' },
	{ 'label': '25', 'value': '25' },
	{ 'label': '50', 'value': '50' },
	{ 'label': '100', 'value': '100' },
	{ 'label': '200', 'value': '200' },
];

export const radiusOptions: Array<LabelValue>
	= [
		...arrRadius
	];

export const arrListingFor: Array<LabelValue> = [
	{ 'label': 'For Lease', 'value': 'For Lease' },
	{ 'label': 'For Sale', 'value': 'For Sale' },
	// { 'label': 'Lease and/or Sale', 'value': 'Lease and/or Sale' },
];

export const listingForOptionsInit: Array<LabelValue>
	= [
		...sortArray(arrListingFor)
	];

export const arrListingFor1: Array<LabelValue> = [
	{ 'label': 'For Lease', 'value': 'For Lease' },
	{ 'label': 'For Sale', 'value': 'For Sale' },
	{ 'label': 'For 3PL/Public Warehouse Services', 'value': 'For 3PL/Public Warehouse Services' }
];

export const listingForOptionsInit1: Array<LabelValue>
	= [
		...sortArray(arrListingFor1)
	];

export const listingForOptionsExt: Array<LabelValue>
	= [
		...sortArray(arrListingFor)
		, { 'label': 'For 3PL/Public Warehouse Services', 'value': 'For 3PL/Public Warehouse Services' }
	];

export const arrZoning: Array<LabelValue> = [
	{ 'label': 'Opportunity Zone', 'value': 'Opportunity Zone' },
	{ 'label': 'FTZ', 'value': 'FTZ' },
	{ 'label': 'Industrial', 'value': 'Industrial' },
	{ 'label': 'Warehouse', 'value': 'Warehouse' },
	{ 'label': 'Commercial', 'value': 'Commercial' },
	{ 'label': 'Tax Increment Financing (TIF)', 'value': 'Tax Increment Financing (TIF)' },
];

export const zoningOptions: Array<LabelValue>
	= [
		...sortArray(arrZoning)
	];

export const arrSiteSuitableFor: Array<LabelValue> = [
	// { 'label': 'Distribution/warehouse', 'value': 'Distribution/warehouse' },
	// { 'label': 'Cold storage', 'value': 'Cold storage' },
	// { 'label': 'Manufacturing', 'value': 'Manufacturing' },
	// { 'label': 'Indoor rail', 'value': 'Indoor rail' },
	// { 'label': 'Indoor truck', 'value': 'Indoor truck' },
	{ 'label': 'Heavy Industrial', 'value': 'Heavy Industrial' },
	{ 'label': 'Light manufacturing', 'value': 'Light manufacturing' },
	{ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' },
];

export const siteSuitableForOptions: Array<LabelValue>
	= [
		...sortArray(arrSiteSuitableFor)
	];

export const arrPipelineAccess: Array<LabelValue> = [
	{ 'label': 'Natural gas', 'value': 'Natural gas' },
	{ 'label': 'Refined products', 'value': 'Refined products' },
	{ 'label': 'NGLs', 'value': 'NGLs' },
	{ 'label': 'Crude', 'value': 'Crude' }
];

export const pipelineAccessOptions: Array<LabelValue>
	= [
		...sortArray(arrPipelineAccess)
	];

export const arrEnvionmental: Array<LabelValue> = [
	{ 'label': 'Phase I complete', value: 'Phase I complete' },
	{ 'label': 'Phase II complete', value: 'Phase II complete' }
];

export const envionmentalOptions: Array<LabelValue>
	= [
		...sortArray(arrEnvionmental)
	];

export const arrAirAttainmentStatus: Array<LabelValue> = [
	{ 'label': 'Attainment', 'value': 'Attainment' },
	{ 'label': 'Non-Attainment for 8-hour Ozone', 'value': 'Non-Attainment for 8-hour Ozone' },
];

export const airAttainmentStatusOptions: Array<LabelValue>
	= [
		...sortArray(arrAirAttainmentStatus)
	];

export const arrRailcarStorage: Array<LabelValue> = [
	{ 'label': 'SIT', 'value': 'SIT' },
	{ 'label': 'Loaded', 'value': 'Loaded' },
	{ 'label': 'Empty', 'value': 'Empty' },
	{ 'label': 'Loaded hazmat', 'value': 'Loaded hazmat' },
	{ 'label': 'Hazmat residue', 'value': 'Hazmat residue' },
];

export const railcarStorageOptions: Array<LabelValue>
	= [
		...sortArray(arrRailcarStorage)
	];

export const arrFacility: Array<LabelValue> = [
	{ 'label': 'Bulk terminals', 'value': 'Bulk terminals' },
	{ 'label': 'Warehouse', 'value': 'Warehouse' },
	{ 'label': 'Port', 'value': 'Port' }
];

export const facilityOptions: Array<LabelValue>
	= [
		...sortArray(arrFacility)
	];

export const arrCapabilities: Array<LabelValue> = [
	{ 'label': 'Rail', 'value': 'Rail' },
	{ 'label': 'Barge', 'value': 'Barge' },
	{ 'label': 'Truck', 'value': 'Truck' },
	{ 'label': 'Tug', 'value': 'Tug' },
	{ 'label': 'Warehouse', 'value': 'Warehouse' },
	{ 'label': 'Tank storage', 'value': 'Tank storage' },
	{ 'label': 'Bulk outside storage', 'value': 'Bulk outside storage' },
	{ 'label': 'Unit train', 'value': 'Unit train' },
	{ 'label': 'Storage-in-transit (SIT)', 'value': 'Storage-in-transit (SIT)' },
	{ 'label': 'Hazmat', 'value': 'Hazmat' },
	{ 'label': 'Laydown yard', 'value': 'Laydown yard' },
	{ 'label': 'Intermodal', 'value': 'Intermodal' },
];

export const capabilitiesOptionsInit: Array<LabelValue>
	= [
		...sortArray(arrCapabilities)
	];

export const capabilitiesOptionsExt: Array<LabelValue>
	= sortArray([
		...arrCapabilities
		, { 'label': 'Cold storage', 'value': 'Cold storage' }
	]);

export const arrEquipments: Array<LabelValue> = [
	{ 'label': 'Front-end loader', 'value': 'Front-end loader' },
	{ 'label': 'Stacker/reclaimer', 'value': 'Stacker/reclaimer' },
	{ 'label': 'RT crane', 'value': 'RT crane' },
	{ 'label': 'Belt conveyor', 'value': 'Belt conveyor' },
	{ 'label': 'Railcar rotary dump', 'value': 'Railcar rotary dump' },
	{ 'label': 'Railcar bottom dump', 'value': 'Railcar bottom dump' },
	{ 'label': 'Shiploader', 'value': 'Shiploader' },
	{ 'label': 'Barge unloader', 'value': 'Barge unloader' },
	{ 'label': 'Liquid pumps', 'value': 'Liquid pumps' },
	{ 'label': 'Forklift', 'value': 'Forklift' },
	{ 'label': 'Container handling', 'value': 'Container handling' },
];

export const equipmentsOptions: Array<LabelValue>
	= [
		...sortArray(arrEquipments)
	];

export const arrSelect: Array<LabelValue> = [
	{ 'label': 'Yes', 'value': 'Yes' },
	{ 'label': 'No', 'value': 'No' }
];

export const selectOptions: Array<LabelValue>
	= [
		...sortArray(arrSelect)
	];

export const arrFacilityProductsHandled: Array<LabelValue> = [ 
	{ 'label': 'Metals', 'value': 'Metals' },
				{ 'label': 'Forest products', 'value': 'Forest products' },
				{ 'label': 'Project Cargo', 'value': 'Project Cargo' },
				{ 'label': 'Aggregates', 'value': 'Aggregates' },
				{ 'label': 'Grains', 'value': 'Grains' },
				{ 'label': 'Fertilizer', 'value': 'Fertilizer' },
				{ 'label': 'Coal', 'value': 'Coal' },
				{ 'label': 'Petcoke', 'value': 'Petcoke' },
				{ 'label': 'Lumber', 'value': 'Lumber' },
				{ 'label': 'Fly ash', 'value': 'Fly ash' },
				{ 'label': 'Hazmat', 'value': 'Hazmat' },
				{ 'label': 'Liquid Bulk', 'value': 'Liquid Bulk' },
				{ 'label': 'Breakbulk', 'value': 'Breakbulk' },
				{ 'label': 'Chemicals', 'value': 'Chemicals' },
				{ 'label': 'Plastics', 'value': 'Plastics' },
				{ 'label': 'Sand', 'value': 'Sand' },
				{ 'label': 'Dry Bulk', 'value': 'Dry Bulk' },
				{ 'label': 'Crude Oil', 'value': 'Crude Oil' },
				{ 'label': 'Refined products', 'value': 'Refined products' },
				{ 'label': 'Other', 'value': 'Other' },
];

export const facilityProductsHandledOptions: Array<LabelValue>
	= [
		...sortArray(arrFacilityProductsHandled)
	];

export const arrTemperatureControl: Array<LabelValue> = [
	{ 'label': 'Chilled (+33 to +65 degrees)', 'value': 'Chilled (+33 to +65 degrees)' },
	{ 'label': 'Frozen (-10 to +32 degrees)', 'value': 'Frozen (-10 to +32 degrees)' },
	{ 'label': 'Dry', 'value': 'Dry' },
	{ 'label': 'Ambient', 'value': 'Ambient' },
];

export const temperatureControlOptions: Array<LabelValue>
	= [
		...sortArray(arrTemperatureControl)
	];

export const arrMarineAccess: Array<LabelValue> = [
	{ 'label': 'Barge/Shallow', 'value': 'Barge/Shallow' },
	{ 'label': 'Vessel/Deepwater', 'value': 'Vessel/Deepwater' },
];

export const marineAccessOptions: Array<LabelValue>
	= [
		...sortArray(arrMarineAccess)
	];

export const arrFacilityPipelineAccess: Array<LabelValue> = [
	{ 'label': 'Natural gas', 'value': 'Natural gas' },
	{ 'label': 'Refined products', 'value': 'Refined products' },
	{ 'label': 'NGLs', 'value': 'NGLs' },
	{ 'label': 'Crude', 'value': 'Crude' }
];

export const facilityPipelineAccessOptions: Array<LabelValue>
	= [
		...sortArray(arrFacilityPipelineAccess)
	];

export const arrWaterways: Array<LabelValue> = [
];

export const waterwaysOptions: Array<LabelValue>
	= [
		...sortArray(arrWaterways)
	];

export const arrProductStorage: Array<LabelValue> = [
	{ 'label': 'Tankage', 'value': 'Tankage' },
	{ 'label': 'Silos', 'value': 'Silos' },
	{ 'label': 'Warehouse', 'value': 'Warehouse' },
	{ 'label': 'Outdoor', 'value': 'Outdoor' },
	{ 'label': 'Indoor Storage', 'value': 'Indoor Storage' },
];

export const productStorageOptions: Array<LabelValue>
	= [
		...sortArray(arrProductStorage)
	];

export const arrParkingCapacity: Array<LabelValue> = [
	{ 'label': 'Cars', 'value': 'Cars' },
	{ 'label': 'Trailers/Containers', 'value': 'Trailers/Containers' }
];

export const parkingCapacityOptions: Array<LabelValue>
	= [
		...sortArray(arrParkingCapacity)
	];

export const arrWarehouseCapabilities: Array<LabelValue> = [
	{ 'label': 'Packaging', 'value': 'Packaging' },
	{ 'label': 'Drumming', 'value': 'Drumming' },
	{ 'label': 'Hazmat', 'value': 'Hazmat' },
	{ 'label': 'Built to Suit', 'value': 'Built to Suit' },
	{ 'label': 'Pick & Pack', 'value': 'Pick & Pack' },
	{ 'label': 'Kitting', 'value': 'Kitting' },
	{ 'label': 'Other', 'value': 'Other' },
];

export const warehouseCapabilitiesOptions: Array<LabelValue>
	= [
		...sortArray(arrWarehouseCapabilities)
	];
