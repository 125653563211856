import React, { Component, Fragment } from 'react';
import axios from 'axios';
import lodash from 'lodash';
import { Link } from 'react-router-dom';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getPreciseDistance } from 'geolib';
import LZString from 'lz-string';

import { arrStates } from '../constant/CountryUSA';
import {
	classOneConnectionsOptions
	, radiusOptions
	, listingForOptionsInit
	, listingForOptionsInit1
	, listingForOptionsExt
	, zoningOptions
	, siteSuitableForOptions
	, pipelineAccessOptions
	, envionmentalOptions
	, airAttainmentStatusOptions
	, railcarStorageOptions
	, capabilitiesOptionsInit
	, capabilitiesOptionsExt
	, equipmentsOptions
	, selectOptions
	, facilityProductsHandledOptions
	, marineAccessOptions
	// , waterwaysOptions
	, productStorageOptions
	, warehouseCapabilitiesOptions
} from '../constant/DropDownLists';
import { HelpData } from '../constant/HelpData';
import {
	arrSiteFormKey
	, arrTerminalPortFormKey
	, arrWarehouseFormKey
	, arrMarineAccessFormKey
	, arrServingCarriersFormKey
} from '../constant/ListForm';
import { propertyTypesOptions } from '../constant/PropertyType';
import { shortLinesOptions, shortLinesAllFilter } from '../constant/ShortLines';
import { waterwaysOptions, waterwaysAllFilter } from '../constant/WaterWays';
import {
	LabelValue
	, sortArray
	, removeDuplicatesFromArr
	, splitFilter
	, joinFilterL
	, joinFilterV
	, reJoinFilter
	, TGeoResponse
	, TInFeature
	, FormItem
} from '../constant/Types';
import StringUtils from '../utils/StringUtils';
import FilterButtonIcon from './FilterButtonIcon';
import HelpModal from './HelpModal';
import { SVGIcon } from './Icons';
import InputAddressComponent from './InputAddressComponent';
import InputBoxComponent from './InputBoxComponent';
import InputCheckboxComponent from './InputCheckboxComponent';
import InputMultiSelectComponent from './InputMultiSelectComponent';
import { LiFilter, createIntermediateArr } from './LiFilter'
import SearchInput from './SearchInput'

const SEARCH_INPUT_TIMEOUT = 750;

let originalResponseCache: string = null;

export const getStorageItem = (item: string, defaultVal: string = ''): string => {
	let res: string = localStorage.getItem(item) ? localStorage.getItem(item) : defaultVal;
	if (item === 'originalResponse') {
		if (originalResponseCache === null) {
			res = LZString.decompress(res);
			originalResponseCache = res;
		} else {
			res = originalResponseCache;
		}
	}
	return res;
}

export const setStorageItem = (item: string, value: string = ''): void => {
	if (value) {
		if (item === 'originalResponse') {
			originalResponseCache = value;
			value = LZString.compress(value);
		}
		localStorage.setItem(item, value);
	} else {
		if (item === 'originalResponse') {
			originalResponseCache = null;
		}
		localStorage.removeItem(item);
	}
}

export const getSelectedPropertyTypeAll = (): string => {
	const valueArr: Array<LabelValue> = [];
	if (valueArr.length === 0) {
		valueArr.push({ 'label': 'Industrial Sites & Properties', 'value': 'Industrial Sites & Properties' });
		valueArr.push({ 'label': 'Terminals and Transload Facilities', 'value': 'Terminals and Transload Facilities' });
		valueArr.push({ 'label': 'Port Terminals', 'value': 'Port Terminals' });
		valueArr.push({ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' });
	}
	const selectedPropertyTypeAll = joinFilterV(valueArr);
	return selectedPropertyTypeAll;
}

interface PListIconFiltering {
	filterResults: (filter: LiFilter
		, intermediateFeatures: Array<TInFeature>
		, flagPostponedCreate: boolean
		, callback?: () => void) => void;
	handleInitialZoom: () => void;
	updateMapBounds: () => void;
	updateResponse: (flagLoading: boolean, response: { features: Array<TInFeature> }, callback?: () => void) => void;
	sizeIco: number;
	placeIco: number;
	activeHighlightedColor: string;
	privateClientId: string;
}

class SListIconFiltering {
	ref: ListIconFiltering = null;
	propertyFacilityRef: HTMLDivElement = null;
	//
	showServingCarrierHelp: boolean = false;
	showMarineAccessHelp: boolean = false;
	showWarehouseHelp: boolean = false;
	showPortTerminalHelp: boolean = false;
	showGeneralHelp: boolean = false;
	showSiteHelp: boolean = false;
	showPortTerminaHelp: boolean = false;
	showFiltersClearedModal: boolean = false;
	filterModalMessage: string = '';
	//
	flagMultiClassIMouseOver: boolean = false;
	//
	filter: LiFilter = new LiFilter();
	//
	debounceOnSearchByKeywordChange: any = null;
	//
	defaultRadius: number = 200;
	listingForArr: Array<LabelValue> = [...listingForOptionsInit];
	listingForArr1: Array<LabelValue> = [...listingForOptionsInit1];
	capabilitiesArr: Array<LabelValue> = [...capabilitiesOptionsInit];
	requiredStateArr: Array<LabelValue> = [];
	cityArr: Array<LabelValue> = [];
	sizeIco: number = 32;
	placeIco: number = 54;
	activeHighlightedColor: string = null;

	constructor(ref: ListIconFiltering) {
		this.ref = ref;
		if (ref) {
			const { props } = ref;
			this.reInit(props);
		}
	}

	reInit(props: PListIconFiltering) {
		if (this.ref) {
			this.debounceOnSearchByKeywordChange = lodash.debounce(this.ref.onSearchByKeywordChange, SEARCH_INPUT_TIMEOUT);
		}
		this.sizeIco = props.sizeIco !== undefined ? props.sizeIco : 32;
		this.placeIco = props.placeIco !== undefined ? props.placeIco : 54;
		this.activeHighlightedColor = props.activeHighlightedColor !== undefined ? props.activeHighlightedColor : this.activeHighlightedColor;
	}

	copy(copy: SListIconFiltering): SListIconFiltering {
		if (!copy) {
			copy = new SListIconFiltering(null);
		}
		this.showServingCarrierHelp = copy.showServingCarrierHelp;
		this.showMarineAccessHelp = copy.showMarineAccessHelp;
		this.showWarehouseHelp = copy.showWarehouseHelp;
		this.showPortTerminalHelp = copy.showPortTerminalHelp;
		this.showGeneralHelp = copy.showGeneralHelp;
		this.showSiteHelp = copy.showSiteHelp;
		this.showPortTerminaHelp = copy.showPortTerminaHelp;
		this.showFiltersClearedModal = copy.showFiltersClearedModal;
		this.filterModalMessage = copy.filterModalMessage;
		//
		this.flagMultiClassIMouseOver = copy.flagMultiClassIMouseOver;
		//
		this.filter.copy(copy.filter);
		//
		this.debounceOnSearchByKeywordChange = copy.debounceOnSearchByKeywordChange;
		//
		this.defaultRadius = copy.defaultRadius;
		this.listingForArr = copy.listingForArr;
		this.listingForArr1 = copy.listingForArr1;
		this.capabilitiesArr = copy.capabilitiesArr;
		this.requiredStateArr = copy.requiredStateArr;
		this.cityArr = copy.cityArr;
		this.sizeIco = copy.sizeIco;
		this.placeIco = copy.placeIco;
		this.activeHighlightedColor = copy.activeHighlightedColor;
		return this;
	}
}

export default class ListIconFiltering extends Component<PListIconFiltering, SListIconFiltering> {

	constructor(props: PListIconFiltering) {
		super(props);
		this.state = new SListIconFiltering(this);
	}

	componentWillMount() {
		fetch(process.env.REACT_APP_BASE_URL + '/api/get-geojson', {
			// headers: {
			// 	'Content-Type': 'application/json',
			// 	'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
			// 	'x-access-token': cookies.get('token')
			// },
		})
			.then(response => response.json())
			.then(geoData => {
				const data: { features: Array<TInFeature> } = geoData.geoJSON;
				data.features = data.features.reverse();
				const flags: Array<boolean> = [];
				const cityArrTmp: Array<LabelValue> = [];
				const requiredStateArrTmp: Array<LabelValue> = [];
				for (let i = 0; i < data.features.length; i++) {
					if (data.features[i].properties.city !== undefined) {
						if (flags[data.features[i].properties.city]) {
							continue;
						}
						flags[data.features[i].properties.city] = true;
						cityArrTmp.push({
							label: data.features[i].properties.city,
							value: data.features[i].properties.city
						});
					}
					arrStates.filter((item: LabelValue) => {
						if (item.value === data.features[i].properties.state) {
							requiredStateArrTmp.push(item);
						}
					})
				}
				setStorageItem('originalResponse', JSON.stringify(data));
				this.setState({
					requiredStateArr: sortArray(removeDuplicatesFromArr(requiredStateArrTmp)),
					cityArr: sortArray(cityArrTmp),
				}, () => {
					this.props.updateResponse(false, data, () => {
						this.loadParametersFromLocalStorage();
					});
				});
			});
	}

	loadParametersFromLocalStorage = () => {
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		for (let i = 0; i < arrSiteFormKey.length; i++) {
			const key: string = arrSiteFormKey[i].key;
			const value: string = getStorageItem(key);
			filter[key] = value;
		}
		for (let i = 0; i < arrTerminalPortFormKey.length; i++) {
			const key: string = arrTerminalPortFormKey[i].key;
			const value: string = getStorageItem(key);
			filter[key] = value;
		}
		for (let i = 0; i < arrWarehouseFormKey.length; i++) {
			const key: string = arrWarehouseFormKey[i].key;
			const value: string = getStorageItem(key);
			filter[key] = value;
		}
		for (let i = 0; i < arrMarineAccessFormKey.length; i++) {
			const key: string = arrMarineAccessFormKey[i].key;
			const value: string = getStorageItem(key);
			filter[key] = value;
			if (key === 'marineAccess' && value) {
				filter.bargeAccess = (value.includes('Barge') || value.includes('Vessel')) ? 'Yes' : '';
			}
		}
		for (let i = 0; i < arrServingCarriersFormKey.length; i++) {
			const key: string = arrServingCarriersFormKey[i].key;
			const value: string = getStorageItem(key);
			filter[key] = value;
			if (key === 'marineAccess' && value) {
				filter.bargeAccess = (value.includes('Barge') || value.includes('Vessel')) ? 'Yes' : '';
			}
		}
		const classOneMultiservedCheckbox: boolean = getStorageItem('classOneMultiservedCheckbox') === 'true' ? true : false;
		filter.classOneMultiservedCheckbox = (classOneMultiservedCheckbox ? (true === classOneMultiservedCheckbox) : false);
		const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
		filter.selectedPropertyType = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
		filter.searchByKeywordValue = getStorageItem('searchByKeywordValue');
		filter.selectedUsaStates = getStorageItem('selectedUsaStates');
		filter.address = getStorageItem('address');
		filter.radius = Number(getStorageItem('radius', '0'));
		const cityCoordinates = getStorageItem('cityCoordinates');
		filter.cityCoordinates = StringUtils.isNotEmpty(cityCoordinates) ? JSON.parse(cityCoordinates) : [];
		filter.city = getStorageItem('city');
		filter.usaState = getStorageItem('usaState');
		filter.zipcode = getStorageItem('zipcode');
		filter.country = getStorageItem('country');
		filter.flagRadiusDisabled = (filter.cityCoordinates && filter.cityCoordinates.length > 1) ? false : true;
		this.setState({
			filter: filter,
		}, () => {
			const { state } = this;
			const { selectedPropertyType } = state.filter;
			const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
			const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
			const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
			const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
			const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
			if (flagIndustrialSitesProperties) {
				this.setListingFor(selectedPropertyType);
			}
			if (flagWarehouse) {
				this.setListingFor(selectedPropertyType);
				// this.addPTTOptionInListingFor();
			}
			if (flagMultipurpose) {
				this.setListingFor(selectedPropertyType);
				// this.addPTTOptionInListingFor();
			}
			this.createFiltersIntern(true);
		});
	}

	setPropertyFacilityRef = (el: HTMLDivElement) => {
		if (el) {
			this.setState({
				propertyFacilityRef: el
			});
		}
	}

	showServingCarrierHelpModal = () => {
		this.setState({ showServingCarrierHelp: true });
	}

	hideServingCarrierHelpModal = () => {
		this.setState({ showServingCarrierHelp: false });
	}

	showMarineAccessHelpModal = () => {
		this.setState({ showMarineAccessHelp: true });
	}

	hideMarineAccessHelpModal = () => {
		this.setState({ showMarineAccessHelp: false });
	}

	showWarehouseHelpModal = () => {
		this.setState({ showWarehouseHelp: true });
	}

	hideWarehouseHelpModal = () => {
		this.setState({ showWarehouseHelp: false });
	}

	showPortTerminalHelpModal = () => {
		this.setState({ showPortTerminalHelp: true });
	}

	hidePortTerminalHelpModal = () => {
		this.setState({ showPortTerminalHelp: false });
	}

	showGeneralHelpModal = () => {
		this.setState({ showGeneralHelp: true });
	}

	hideGeneralHelpModal = () => {
		this.setState({ showGeneralHelp: false });
	}

	showSiteHelpModal = () => {
		this.setState({ showSiteHelp: true });
	}

	hideSiteHelpModal = () => {
		this.setState({ showSiteHelp: false });
	}

	showPortTerminaHelpModal = () => {
		this.setState({ showPortTerminaHelp: true });
	}

	hidePortTerminaHelpModal = () => {
		this.setState({ showPortTerminaHelp: false });
	}

	handleFiltersClearedModal = () => {
		this.setState({
			showFiltersClearedModal: false,
			filterModalMessage: ''
		});
	}

	render() {
		const { state, props } = this;
		return (
			<Fragment>
				{
					this.renderFiltering()
				}
				{
					this.renderDlgDeleteConfirmationModal()
				}
			</Fragment>
		)
	}

	renderDlgDeleteConfirmationModal = (): React.ReactElement => {
		const { state } = this;
		return (
			<Modal show={state.showFiltersClearedModal} onHide={this.handleFiltersClearedModal} animation={false}>
				<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
				<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
					<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
						<div className='event-type info'>
							<div className='event-indicator '>
								<SVGIcon />
							</div>
						</div>
						<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
							{this.state.filterModalMessage} filters cleared!
						</h2>
					</div>
				</Modal.Body>
				<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
					<button type='button' className='btn btn-danger border-radius-25-imp'
						onClick={this.handleFiltersClearedModal}
					>
						Close
					</button>
				</Modal.Footer>
			</Modal>
		)
	}

	renderFiltering() {
		const { state, props } = this;
		const {
			filter
			, sizeIco
			, placeIco
			, activeHighlightedColor
		} = state;
		const {
			privateClientId
		} = props;
		const { selectedPropertyType } = filter;
		const marineAccessKeys = [
			'Port Terminals'
			, 'Industrial Sites & Properties'
			, 'Terminals and Transload Facilities'
			, 'Multipurpose'
		];
		// Uncomment state values below 
		// in case the filtered objects will appear on the map without selecting specific property type
		const propertyTypeFilterValues: Array<string> = [
			filter.selectedPropertyType,
			// state.minAcreage,
			// state.maxAcreage,
			// state.siteZoning,
			// state.selectedBuildingOption,
			// state.siteMinSquareFeet,
			// state.siteMaxSquareFeet,
			// state.siteMinInteriorRoofHeight,
			// state.siteMaxInteriorRoofHeight,
			// state.siteSuitableFor,
			// state.sitePipelineAccess,
			// state.selectedWaterSewer,
			// state.selectedPowerToSite,
			// state.siteEnvionmental,
			// state.airAttainmentStatus,
			// state.capabilities,
			// state.equipments,
			// state.railcarStorageCapacity,
			// state.productsHandled,
			// state.productStorage,
			// state.indoorStorage,
			// state.pttMinAcreage,
			// state.pttMaxAcreage,
			// state.warehouseCapabilities,
			// state.temperatureControl,
			// state.foodGrade,
			// state.freeTradeZone,
			// state.warehouseMinSize,
			// state.warehouseMaxSize,
			// state.minRoofHeight,
			// state.maxRoofHeight,
			// state.parkingSpaces,
			filter.marineAccess,
			filter.bargeAccess,
			filter.docInPlace,
			filter.waterways,
			filter.navigableWater,
			filter.classOneConnections,
			filter.shortLinesLabel,
			filter.railInPlace,
			filter.railcarStorage,
			filter.unitTrainCapable,
			filter.railcarCapacity,
		];
		const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
		const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
		const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
		const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
		const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
		const classOneConnectionsOptionsTmp: Array<LabelValue> = [...classOneConnectionsOptions];
		const shortLinesOptionsTmp: Array<LabelValue> = [...shortLinesOptions];
		let flagMultiClassI = (!!privateClientId) ? true : false;
		let flagKeywords = (!!privateClientId) ? true : false;
		const membershipOptionsStr = getStorageItem('membershipOptions');
		if (membershipOptionsStr) {
			const membershipOptionsArr: Array<{
				multi_class_I: string
				, favorites: string
				, dmsg: string
				, classI: string
				, shortline: string
				, intermodal: string
				, ports: string
				, keywords: string
			}> = JSON.parse(membershipOptionsStr);
			if (membershipOptionsArr && membershipOptionsArr.length >= 1) {
				flagMultiClassI = new Boolean(membershipOptionsArr[0].multi_class_I).valueOf();
				flagKeywords = new Boolean(membershipOptionsArr[0].keywords).valueOf();
			}
		}
		const keySuffix = '_' + flagMultiClassI + '_' + flagKeywords + '_' + sizeIco + '_' + placeIco + '_' + activeHighlightedColor;
		return (
			<React.Fragment
				key={'Filtering_' + keySuffix}
			// style={{
			// 	display: 'flex',
			// 	height: 40,
			// 	alignItems: 'center',
			// 	backgroundColor: 'rgba(255,255,255,0.7)',
			// }}
			>
				<FilterButtonIcon
					customTooltipClass={`responsive-custom-tooltip  ${filter.selectedPropertyType && 'custom-tooltip-content-screen-height'}`}
					customContentClass='custom-tooltip-reverse-content '
					customButtonClass='custom-button-horizontal-indent '
					isActiveFilter={() => propertyTypeFilterValues.some(value => !!value)}
					//title='Property/facility type'
					renderIconContent={(sizeIco: number) => this.renderIconFacility(sizeIco)}
					isDisabled={false}
					renderTooltipContent={() => this.renderTtPropertyFacility()}
					getChildHeight={() => {
						return state.propertyFacilityRef ? state.propertyFacilityRef.scrollHeight : 0;
					}}
					sizeIco={sizeIco}
					placeIco={placeIco}
					activeHighlightedColor={activeHighlightedColor}
					toolTipBackgroundColor={activeHighlightedColor}
				>
					<div ref={this.setPropertyFacilityRef}>
						<div className='col-md-12 col-sm-12 margin-bottom-20'>
							<div className='text-uppercase font-weight-bold pull-left cursor-pointer hover' style={{ fontSize: 12, paddingBottom: 5 }}>I am looking for</div>
							<InputMultiSelectComponent
								name='selectedPropertyType'
								value={filter.selectedPropertyType}
								options={propertyTypesOptions}
								onHandleMultiSelect={this.handleSelectPropertyType}
								isMulti={true}
							/>
						</div>
						<div
							key={'more_filters_' + filter.selectedPropertyType}
							id='more-filters'
							className={`width-100p z-index-10 background-white clear-both scrollbar-safari`}
						>
							<div className={(flagIndustrialSitesProperties || flagMultipurpose) ? '' : 'display-none'}>
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
									<div className='row clear-both'>
										<div className='col-md-12 col-sm-12 margin-bottom-20' style={{ borderTop: '1px solid #e2e2e2' }}></div>
									</div>
									<div className='row col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-left-right-15-imp'>
										<div className='pull-left cursor-pointer'
											onClick={this.showSiteHelpModal}
										>
											<i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
											<span style={{ marginLeft: 5, position: 'relative', bottom: 3 }}>
												{/* Add label */}
												{HelpData.siteSearch.label.indexOf('</') !== -1
													? (
														<div dangerouslySetInnerHTML={{ __html: HelpData.siteSearch.label.replace(/(<? *script)/gi, 'illegalscript') }} >
														</div>
													)
													: HelpData.siteSearch.label
												}
											</span>
										</div>
										<HelpModal show={state.showSiteHelp} handleClose={this.hideSiteHelpModal} >
											<div className='col-lg-12' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '3rem' }}>
												<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
													{/* Add title */}
													{HelpData.siteSearch.title.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.siteSearch.title.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.siteSearch.title
													}
												</h2>
												<div className='font-size-16-imp margin-bottom-0'>
													{/* Add body */}
													{HelpData.siteSearch.body.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.siteSearch.body.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.siteSearch.body
													}
												</div>
											</div>
										</HelpModal>
									</div>
								</div>
								<div className='row col-md-12 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='col-lg-12 col-md-12 col-sm-12 text-uppercase pull-left' style={{ fontSize: 18, fontWeight: 'bold' }}>
										Industrial Sites Criteria
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											For Sale/Lease
											<span className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('selectedPropertyFor')}
												style={Object.assign({
													marginLeft: 10,
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='selectedPropertyFor'
											value={filter.selectedPropertyFor}
											options={state.listingForArr}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Price($)
										</div>
										<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
											<InputBoxComponent height='large' type='number' min='0' name='minPrice'
												value={filter.minPrice}
												onHandleInputChange={this.handleInputChange}
												placeholder='Min'
											/>
											<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
											<InputBoxComponent height='large' type='number' min='0' name='maxPrice'
												value={filter.maxPrice}
												onHandleInputChange={this.handleInputChange}
												placeholder='Max'
											/>
										</div>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Acreage
										</div>
										<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
											<InputBoxComponent height='large' type='number' min='0' name='minAcreage'
												value={filter.minAcreage}
												onHandleInputChange={this.handleInputChange}
												placeholder='Min'
											/>
											<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
											<InputBoxComponent height='large' type='number' min='0' name='maxAcreage'
												value={filter.maxAcreage}
												onHandleInputChange={this.handleInputChange}
												placeholder='Max'
											/>
										</div>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Zoning
										</div>
										<InputMultiSelectComponent name='siteZoning'
											value={filter.siteZoning}
											options={zoningOptions}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Pipeline Access
										</div>
										<InputMultiSelectComponent name='sitePipelineAccess'
											value={filter.sitePipelineAccess}
											options={pipelineAccessOptions}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Building
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('selectedBuildingOption')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='selectedBuildingOption'
											value={filter.selectedBuildingOption}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div id='buildingOptionsIfYesDiv' className={`${filter.selectedBuildingOption && filter.selectedBuildingOption === 'Yes' ? '' : 'display-none'}`}>
									<div className='col-md-6 col-sm-12 margin-bottom-20 clear-both padding-left-right-15-imp'>
										<div className='form-group'>
											<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Building Sq. Ft</div>
											<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
												<InputBoxComponent height='large' type='number' min='0' name='siteMinSquareFeet'
													value={filter.siteMinSquareFeet}
													onHandleInputChange={this.handleInputChange}
													placeholder='Min'
												/>
												<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
												<InputBoxComponent height='large' type='number' min='0' name='siteMaxSquareFeet'
													value={filter.siteMaxSquareFeet}
													onHandleInputChange={this.handleInputChange}
													placeholder='Max'
												/>
											</div>
										</div>
									</div>

									<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
										<div className='form-group'>
											<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Clear Height (in ft)</div>
											<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
												<InputBoxComponent height='large' type='number' min='0'
													name='siteMinInteriorRoofHeight'
													value={filter.siteMinInteriorRoofHeight}
													onHandleInputChange={this.handleInputChange}
													placeholder='Min'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Electric Service in place
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('selectedPowerToSite')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='selectedPowerToSite'
											value={filter.selectedPowerToSite}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className={`${filter.selectedPowerToSite && filter.selectedPowerToSite === 'Yes' ? '' : 'display-none'}`}>
									<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
										<div className='form-group'>
											<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Service Voltage (kV)</div>
											<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
												<InputBoxComponent height='large' type='number' min='0' name='minServiceVoltage'
													value={filter.minServiceVoltage}
													onHandleInputChange={this.handleInputChange}
													placeholder='Min'
												/>
												<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
												<InputBoxComponent height='large' type='number' min='0' name='maxServiceVoltage'
													value={filter.maxServiceVoltage}
													onHandleInputChange={this.handleInputChange}
													placeholder='Max'
												/>
											</div>
										</div>
									</div>

									<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
										<div className='form-group'>
											<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Distance to Substation (miles)</div>
											<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
												<InputBoxComponent height='large' type='number' min='0' name='minDistanceToSubstation'
													value={filter.minDistanceToSubstation}
													onHandleInputChange={this.handleInputChange}
													placeholder='Min'
												/>
												<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
												<InputBoxComponent height='large' type='number' min='0' name='maxDistanceToSubstation'
													value={filter.maxDistanceToSubstation}
													onHandleInputChange={this.handleInputChange}
													placeholder='Max'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Natural gas service in place
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('naturalGasInPlace')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='naturalGasInPlace'
											value={filter.naturalGasInPlace}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className={`${filter.naturalGasInPlace && filter.naturalGasInPlace === 'Yes' ? '' : 'display-none'}`}>
									<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
										<div className='form-group'>
											<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Line diameter (inches)</div>
											<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
												<InputBoxComponent height='large' type='number' min='0' name='minLineDiameter'
													value={filter.minLineDiameter}
													onHandleInputChange={this.handleInputChange}
													placeholder='Min'
												/>
												<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
												<InputBoxComponent height='large' type='number' min='0' name='maxLineDiameter'
													value={filter.maxLineDiameter}
													onHandleInputChange={this.handleInputChange}
													placeholder='Max'
												/>
											</div>
										</div>
									</div>
								</div>

								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Suitable for</div>
										<InputMultiSelectComponent name='siteSuitableFor'
											value={filter.siteSuitableFor}
											options={siteSuitableForOptions}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Environmental
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('siteEnvionmental')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent name='siteEnvionmental'
											value={filter.siteEnvionmental}
											options={envionmentalOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Air attainment status
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('airAttainmentStatus')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent name='airAttainmentStatus'
											value={filter.airAttainmentStatus}
											options={airAttainmentStatusOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
							</div>

							<div id='terminalPortForm' className={`clear-both ${(flagPortTerminals || flagTerminalsTransload || flagMultipurpose) ? 'scrollbar-safari' : 'display-none'}`}>
								<div className='row col-md-12 col-sm-12 padding-left-right-15-imp'>
									<div className='col-lg-6 col-md-6 col-sm-12'>
										<div className='pull-left cursor-pointer' onClick={this.showPortTerminalHelpModal}>
											<i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
											<span style={{ marginLeft: 5, position: 'relative', bottom: 3 }}>
												{HelpData.pttSearch.label}	{/* Add label */}
											</span>
										</div>
										<HelpModal show={state.showPortTerminalHelp} handleClose={this.hidePortTerminalHelpModal} >
											<div className='col-lg-12' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '3rem' }}>
												<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
													{/* Add title */}
													{HelpData.pttSearch.title.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.pttSearch.title.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.pttSearch.title
													}
												</h2>
												<div className='font-size-16-imp margin-bottom-0'>
													{/* Add body */}
													{HelpData.pttSearch.body.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.pttSearch.body.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.pttSearch.body
													}
												</div>
											</div>
										</HelpModal>
									</div>
								</div>
								<div className='row col-md-12 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='col-lg-12 col-md-12 col-sm-12 text-uppercase pull-left' style={{ fontSize: 18, fontWeight: 'bold' }}>
										Ports, Terminals, and Transloads Criteria
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp' style={{}}>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Capabilities
										</div>
										<InputMultiSelectComponent name='capabilities'
											value={filter.capabilities}
											options={state.capabilitiesArr}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp' style={{}}>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Equipment
										</div>
										<InputMultiSelectComponent name='equipments'
											options={equipmentsOptions}
											value={filter.equipments}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className={`col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp ${!filter.capabilities.includes('Rail') && 'display-none'}`}
									id='railcarStorageCapacityDiv'
									style={{ marginBottom: 28 }}
								>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Railcar storage capacity
										</div>
										<InputBoxComponent height='large'
											type='number'
											min='0'
											name='railcarStorageCapacity'
											value={filter.railcarStorageCapacity}
											onHandleInputChange={this.handleInputChange}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12  margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Product storage
										</div>
										<InputMultiSelectComponent
											name='productStorage'
											value={filter.productStorage}
											options={productStorageOptions}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12  margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Products handled
										</div>
										<InputMultiSelectComponent
											name='productsHandled'
											value={filter.productsHandled}
											options={facilityProductsHandledOptions}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Ports, Terminals &amp; Transloads Acreage
										</div>
										<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
											<InputBoxComponent height='large' type='number' min='0'
												name='pttMinAcreage'
												value={filter.pttMinAcreage}
												onHandleInputChange={this.handleInputChange}
												placeholder='Min'
											/>
											<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
											<InputBoxComponent height='large' type='number' min='0'
												name='pttMaxAcreage'
												value={filter.pttMaxAcreage}
												onHandleInputChange={this.handleInputChange}
												placeholder='Max'
											/>
										</div>
									</div>
								</div>
							</div>

							<div id='warehouseForm' className={`${(flagWarehouse || flagMultipurpose) ? '' : 'display-none'}`}>
								<div className='row col-md-12 col-sm-12 padding-left-right-15-imp'>
									<div className='col-lg-6 col-md-6 col-sm-12'>
										<div className='pull-left cursor-pointer' onClick={this.showWarehouseHelpModal}>
											<i className='fa fa-info-circle font-size-15-imp' aria-hidden='true'></i>
											<span style={{ marginLeft: 5, position: 'relative', bottom: 3 }}>
												{HelpData.warehoueSearch.label}	{/* Add label */}
											</span>
										</div>
										<HelpModal show={state.showWarehouseHelp} handleClose={this.hideWarehouseHelpModal} >
											<div className='col-lg-12' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '3rem' }}>
												<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
													{/* Add title */}
													{HelpData.warehoueSearch.title.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.warehoueSearch.title.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.warehoueSearch.title
													}
												</h2>
												<div className='font-size-16-imp margin-bottom-0'>
													{/* Add body */}
													{HelpData.warehoueSearch.body.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.warehoueSearch.body.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.warehoueSearch.body
													}
												</div>
											</div>
										</HelpModal>
									</div>
								</div>
								<div className='row col-md-12 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='col-lg-12 col-md-12 col-sm-12 text-uppercase pull-left' style={{ fontSize: 18, fontWeight: 'bold' }}>
										Warehouse/Distribution Center Criteria
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											For Sale/Lease/3PL
											<span className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('selectedPropertyFor1')}
												style={Object.assign({
													marginLeft: 10,
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='selectedPropertyFor1'
											value={filter.selectedPropertyFor1}
											options={state.listingForArr1}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-6 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Price($)
										</div>
										<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
											<InputBoxComponent height='large' type='number' min='0'
												name='warehouseMinPrice' value={filter.warehouseMinPrice}
												onHandleInputChange={this.handleInputChange}
												placeholder='Min'
											/>
											<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
											<InputBoxComponent height='large' type='number' min='0'
												name='warehouseMaxPrice' value={filter.warehouseMaxPrice}
												onHandleInputChange={this.handleInputChange}
												placeholder='Max'
											/>
										</div>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Warehouse Capabilities
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('warehouseCapabilities')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent name='warehouseCapabilities'
											value={filter.warehouseCapabilities}
											options={warehouseCapabilitiesOptions}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Temperature control
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('temperatureControl')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent name='temperatureControl'
											value={filter.temperatureControl}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Food grade
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('foodGrade')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent name='foodGrade'
											value={filter.foodGrade}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											FTZ (Foreign Trade Zone)
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('freeTradeZone')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent name='freeTradeZone'
											value={filter.freeTradeZone}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Available Area (sq. ft.)
										</div>
										<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
											<InputBoxComponent height='large'
												type='number' min='0'
												name='warehouseMinSize'
												value={filter.warehouseMinSize}
												onHandleInputChange={this.handleInputChange}
												placeholder='Min'
											/>
											<span style={{ width: '10%', display: 'table-cell', textAlign: 'center' }}>to</span>
											<InputBoxComponent height='large'
												type='number' min='0'
												name='warehouseMaxSize'
												value={filter.warehouseMaxSize}
												onHandleInputChange={this.handleInputChange}
												placeholder='Max'
											/>
										</div>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Clear Height
										</div>
										<div className='' style={{ display: 'table', tableLayout: 'fixed' }}>
											<InputBoxComponent height='large'
												type='number'
												min='0'
												name='maxRoofHeight'
												value={filter.maxRoofHeight}
												onHandleInputChange={this.handleInputChange}
												placeholder='Max'
											/>
										</div>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Parking spaces (for both inbound and outbound)
										</div>
										<InputBoxComponent height='large'
											size='small'
											type='number'
											min='0'
											name='parkingSpaces'
											value={filter.parkingSpaces}
											onHandleInputChange={this.handleInputChange}
										/>
									</div>
								</div>
								<hr />
							</div>

							<div id='marineAccessForm' className={`${marineAccessKeys.some(key => filter.selectedPropertyType.includes(key)) ? '' : 'display-none'}`}>
								<div className='row clear-both'>
									<div className='col-md-12 col-sm-12 margin-bottom-20' style={{ borderTop: '1px solid #e2e2e2' }}></div>
								</div>
								<div className='row col-md-12 col-sm-12 padding-left-right-15-imp'>
									<div className='col-lg-6 col-md-6 col-sm-12'>
										<div className='pull-left cursor-pointer' onClick={this.showMarineAccessHelpModal}>
											<i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
											<span style={{ marginLeft: 5, position: 'relative', bottom: 3 }}>
												{HelpData.marineAccessSearch.label}	{/* Add label */}
											</span>
										</div>
										<HelpModal show={state.showMarineAccessHelp} handleClose={this.hideMarineAccessHelpModal} >
											<div className='col-lg-12' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '3rem' }}>
												<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
													{/* Add title */}
													{HelpData.marineAccessSearch.title.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.marineAccessSearch.title.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.marineAccessSearch.title
													}
												</h2>
												<div className='font-size-16-imp margin-bottom-0'>
													{/* Add body */}
													{HelpData.marineAccessSearch.body.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.marineAccessSearch.body.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.marineAccessSearch.body
													}
												</div>
											</div>
										</HelpModal>
									</div>
								</div>
								<div className='row col-md-12 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='col-lg-12 col-md-12 col-sm-12 text-uppercase pull-left' style={{ fontSize: 18, fontWeight: 'bold' }}>
										Marine access
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Marine access</div>
										<InputMultiSelectComponent
											name='marineAccess'
											value={filter.marineAccess}
											options={marineAccessOptions}
											onHandleMultiSelect={(name: string, valueArr: Array<LabelValue>) => {
												const str = joinFilterV(valueArr);
												this.setBargeAccess(str);
												this.handleMultiSelect(name, valueArr);
											}}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp' id='facilityBargeAccessDropdown'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Barge/Vessel access
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('bargeAccess')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='bargeAccess'
											value={filter.bargeAccess}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className={`col-md-6 col-sm-12 col-xs-12 margin-bottom-20 ${filter.bargeAccess !== 'Yes' && 'display-none'} padding-left-right-15-imp`} id='docInPlaceDiv'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Dock in place now?
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('docInPlace')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='docInPlace'
											value={filter.docInPlace}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className={`col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp ${filter.bargeAccess !== 'Yes' && 'display-none'}`} id='siteWhichRiverDiv'>
									<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
										Waterways
									</div>
									<InputMultiSelectComponent
										name='waterways'
										value={filter.waterways}
										options={waterwaysOptions}
										onHandleMultiSelect={this.handleMultiSelect}
										isMulti={true}
									/>
								</div>

								<div className='col-md-6 col-sm-12 col-xs-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
										Distance to navigable water (miles)
									</div>
									<InputBoxComponent
										height='large'
										size='small'
										type='number'
										min='0'
										name='navigableWater'
										value={filter.navigableWater}
										onHandleInputChange={this.handleInputChange}
									/>
								</div>
							</div>

							<div id='servingCarriersForm' className={`${!!filter.selectedPropertyType ? '' : 'display-none'}`}>
								<div className='row clear-both'>
									<div className='col-md-12 col-sm-12 margin-bottom-20' style={{ borderTop: '1px solid #e2e2e2' }}></div>
								</div>
								<div className='row col-md-12 col-sm-12 padding-left-right-15-imp'>
									<div className='col-lg-6 col-md-6 col-sm-12'>
										<div className='pull-left cursor-pointer' onClick={this.showServingCarrierHelpModal}>
											<i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
											<span style={{ marginLeft: 5, position: 'relative', bottom: 3 }}>
												{HelpData.servingCarrierSearch.label}	{/* Add label */}
											</span>
										</div>
										<HelpModal show={state.showServingCarrierHelp} handleClose={this.hideServingCarrierHelpModal} >
											<div className='col-lg-12' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem', paddingBottom: '3rem' }}>
												<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
													{/* Add title */}
													{HelpData.servingCarrierSearch.title.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.servingCarrierSearch.title.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.servingCarrierSearch.title
													}
												</h2>
												<div className='font-size-16-imp margin-bottom-0'>
													{/* Add body */}
													{HelpData.servingCarrierSearch.body.indexOf('</') !== -1
														? (
															<div dangerouslySetInnerHTML={{ __html: HelpData.servingCarrierSearch.body.replace(/(<? *script)/gi, 'illegalscript') }} >
															</div>
														)
														: HelpData.servingCarrierSearch.body
													}
												</div>
											</div>
										</HelpModal>
									</div>
								</div>
								<div className='row col-md-12 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='col-lg-12 col-md-12 col-sm-12 text-uppercase pull-left' style={{ fontSize: 18, fontWeight: 'bold' }}>
										Rail Access
									</div>
								</div>
								<div className='col-md-12 col-sm-12 padding-left-right-15-imp clear-both'>
									{
										flagMultiClassI ?
											<div className='form-group text-uppercase' style={{ fontSize: 12 }}
											>
												<InputCheckboxComponent
													name='classOneMultiservedCheckbox'
													checked={filter.classOneMultiservedCheckbox}
													disabled={!flagMultiClassI}
													onHandleCheckboxChange={(name: string, value: boolean) => {
														setStorageItem('classOneMultiservedCheckbox', '' + value);
														const filter: LiFilter = new LiFilter().copy(this.state.filter);
														filter.classOneMultiservedCheckbox = value;
														this.setState({ filter: filter }, () => {
															this.createFilters();
														});
													}}
													label='Mutli-served class I connections'
												/>
											</div>
											:
											<OverlayTrigger placement='top' trigger='click' ref='dataLayerOverlay' overlay={
												<Tooltip id='tooltip'>
													<div className='form-group'
														style={Object.assign({
														}, {
															display: 'flex',
															flexDirection: 'column',
															padding: 10,
															fontSize: 16,
															fontWeight: 'bold',
															color: 'rgba(3, 61, 245, 1.0)',
															textTransform: 'none',
														}, activeHighlightedColor && {
															backgroundColor: 'white',
														}) as React.CSSProperties}
													>
														<button
															type='button'
															className='close'
															style={{ position: 'absolute', right: 4, top: 4 }}
															onClick={() => (this.refs.dataLayerOverlay as OverlayTrigger).hide()}
														>
															<span style={{ float: 'right' }} aria-hidden='true'>×</span>
															<span className='sr-only'>Close</span>
														</button>
														<div>
															{
																'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
															}
															<a href={'/plans-and-pricing'}
																style={{
																	fontSize: 16
																	, fontWeight: 'bold'
																	, color: 'rgba(3, 61, 245, 1.0)'
																	, textDecoration: 'underline'
																}}
															>
																<span
																	style={{
																		fontSize: 16
																		, fontWeight: 'bold'
																		, color: 'rgba(3, 61, 245, 1.0)'
																		, textDecoration: 'underline'
																	}}
																>
																	Plans and Pricing
																</span>
															</a>
														</div>
													</div>
												</Tooltip>
											}>
												<div className='form-group text-uppercase' style={{ fontSize: 12 }}
												>
													<InputCheckboxComponent
														name='classOneMultiservedCheckbox'
														checked={filter.classOneMultiservedCheckbox}
														disabled={!flagMultiClassI}
														onHandleCheckboxChange={(name: string, value: boolean) => {
															setStorageItem('classOneMultiservedCheckbox', '' + value);
															const filter: LiFilter = new LiFilter().copy(this.state.filter);
															filter.classOneMultiservedCheckbox = value;
															this.setState({ filter: filter }, () => {
																this.createFilters();
															});
														}}
														label='Mutli-served class I connections'
													/>
												</div>
											</OverlayTrigger>
									}
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Select class I connections
										</div>
										<InputMultiSelectComponent
											name='classOneConnections'
											value={filter.classOneConnections}
											options={classOneConnectionsOptionsTmp}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Short Lines
										</div>
										<InputMultiSelectComponent
											name='shortLinesLabel'
											value={filter.shortLinesLabel}
											options={shortLinesOptionsTmp}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
											reduceFontSize={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Rail in place
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('railInPlace')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='railInPlace'
											value={filter.railInPlace}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Railcar storage
										</div>
										<InputMultiSelectComponent
											name='railcarStorage'
											value={filter.railcarStorage}
											options={railcarStorageOptions}
											onHandleMultiSelect={this.handleMultiSelect}
											isMulti={true}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Unit train capable
											<span
												className='pull-right cursor-pointer color-blue'
												onClick={() => this.resetSelectDropdown('unitTrainCapable')}
												style={Object.assign({
												}, activeHighlightedColor && {
													color: activeHighlightedColor,
												})}
											>
												clear
											</span>
										</div>
										<InputMultiSelectComponent
											name='unitTrainCapable'
											value={filter.unitTrainCapable}
											options={selectOptions}
											onHandleSingleSelect={this.handleSingleSelect}
											isMulti={false}
										/>
									</div>
								</div>
								<div className='col-md-6 col-sm-12 margin-bottom-20 padding-left-right-15-imp'>
									<div className='form-group'>
										<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
											Railcar spots/capacity
										</div>
										<InputBoxComponent height='large' size='small' type='number' min='0'
											name='railcarCapacity'
											value={filter.railcarCapacity}
											onHandleInputChange={this.handleInputChange}
											placeholder='Min'
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</FilterButtonIcon>
				<FilterButtonIcon
					isActiveFilter={() => (!!filter.selectedUsaStates || !!filter.address)}
					//title='Location'
					renderIconContent={(sizeIco: number) => this.renderIconLocation(sizeIco)}
					isDisabled={false}
					renderTooltipContent={() => this.renderTtLocation()}
					sizeIco={sizeIco}
					placeIco={placeIco}
					activeHighlightedColor={activeHighlightedColor}
					toolTipBackgroundColor={activeHighlightedColor}
				>
					<React.Fragment>

						<div className='margin-bottom-20'>
							<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
								Select states (US locations only)
							</div>
							<InputMultiSelectComponent name='selectedUsaStates'
								value={filter.selectedUsaStates}
								options={state.requiredStateArr}
								onHandleMultiSelect={(name: string, valueArr: Array<LabelValue>) => {
									this.resetAddress(() => {
										const str = joinFilterV(valueArr);
										const filter: LiFilter = new LiFilter().copy(this.state.filter);
										filter.selectedUsaStates = str;
										this.setState({
											filter: filter
										}, () => {
											this.createFilters();
											setStorageItem(name, str);
										});
									});
								}}
								isMulti={true}
							/>
						</div>
						<div className='margin-bottom-10 text-center font-weight-bold'>OR</div>
						<div>
							<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
								Select address (Enter a Intl or US addr)
								{
									(!filter.cityCoordinates || filter.cityCoordinates.length < 2) ? null :
										<button type='button'
											className='close text-uppercase font-weight-bold'
											onClick={() => this.clearAddress()}
											style={Object.assign({
												fontSize: 12,
												width: 50,
												marginRight: 20,
											}, activeHighlightedColor && {
												color: activeHighlightedColor,
											})}
										>
											clear
										</button>
								}
							</div>

							<InputAddressComponent hideAutoCompleteBorder
								onHandleAddressChange={this.handleAddressChange}
								value={filter.address}
							/>




							<div style={{ marginBottom: 5 }}></div>
						</div>
						<div>
							<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, padding: '10px 0px' }}>
								Radius
							</div>
							<InputMultiSelectComponent name='radius'
								value={'' + filter.radius}
								options={radiusOptions}
								onHandleSingleSelect={this.handleRadiusSelect}
								isMulti={false}
								isDisabled={filter.flagRadiusDisabled}
							/>
						</div>

					</React.Fragment>
				</FilterButtonIcon>
				{
					flagKeywords ?
						<FilterButtonIcon
							isActiveFilter={() => !!filter.searchByKeywordValue}
							//title='Keywords'
							renderIconContent={(sizeIco: number) => this.renderIconKeywords(sizeIco)}
							isDisabled={false}
							renderTooltipContent={() => this.renderTtKeywords()}
							sizeIco={sizeIco}
							placeIco={placeIco}
							activeHighlightedColor={activeHighlightedColor}
							toolTipBackgroundColor={activeHighlightedColor}
						>
							<div>
								{/* <div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
									Search by keywords
								</div> */}
								<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
									Search by keywords
									<span
										className='pull-right cursor-pointer color-blue'
										onClick={() => this.resetkeywordsearch()}
										style={Object.assign({
										}, activeHighlightedColor && {
											color: activeHighlightedColor,
										})}
									>
										clear
									</span>
								</div>
								<SearchInput value={filter.searchByKeywordValue}
									handleInputChange={state.debounceOnSearchByKeywordChange}
								/>
							</div>
						</FilterButtonIcon>
						:
						<FilterButtonIcon
							isActiveFilter={() => !!filter.searchByKeywordValue}
							//title='Keywords'
							renderIconContent={(sizeIco: number) => this.renderIconKeywords(sizeIco)}
							isDisabled={true}
							toolTipBackgroundColor={activeHighlightedColor ? 'white' : 'white'}
							renderTooltipContent={() => {
								return (
									<div className='form-group'
										style={Object.assign({
										}, {
											fontSize: 16,
											fontWeight: 'bold',
											color: 'rgba(3, 61, 245, 1.0)',
											//color: 'white',
											textTransform: 'none',
											backgroundColor: 'white',
										}, activeHighlightedColor && {
											backgroundColor: 'white',
										}) as React.CSSProperties}
									>
										{
											'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
										}
										< Link to={{ pathname: '/plans-and-pricing' }
										}
											style={{
												fontSize: 16
												, fontWeight: 'bold'
												, color: 'rgba(3, 61, 245, 1.0)'
												, textDecoration: 'underline'
											}}
										>
											<span
												style={{
													fontSize: 16
													, fontWeight: 'bold'
													, color: 'rgba(3, 61, 245, 1.0)'
													, textDecoration: 'underline'
												}}
											>
												Plans and Pricing
											</span>
										</Link>
									</div>
								)
							}
							}
							sizeIco={sizeIco}
							placeIco={placeIco}
							activeHighlightedColor={activeHighlightedColor}
						>
							<div>
								{/* <div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
									Search by keywords
								</div> */}
								<div className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
									Search by keywords
									<span
										className='pull-right cursor-pointer color-blue'
										onClick={() => this.resetkeywordsearch()}
										style={Object.assign({
										}, activeHighlightedColor && {
											color: activeHighlightedColor,
										})}
									>
										clear
									</span>
								</div>
								<SearchInput value={filter.searchByKeywordValue}
									handleInputChange={state.debounceOnSearchByKeywordChange}
								/>
							</div>
						</FilterButtonIcon >
				}
				{/* {
					<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
						<Button
							style={Object.assign({}, {
								backgroundColor: 'rgba(255,255,255,0.7)',
								color: '#383838',
							})}
							onClick={() => this.resetFilters()}
						>
							
							<OverlayTrigger placement='top' overlay={
								<Tooltip id='tooltip'>
									<strong className='font-size-16'>
										Clear Filters
									</strong>
								</Tooltip>
							}>
								<svg viewBox='0 0 512 512' style={{
									width: sizeIco,
									height: sizeIco,
								}}>
									<path fill='currentColor'
										d='M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z'
									>
									</path>
								</svg>

							</OverlayTrigger>

						</Button>
					</div>
				} */}
				{
					<FilterButtonIcon
						isActiveFilter={() => (false)}
						//title='Location'
						renderIconContent={(sizeIco: number) => this.renderIconClearFilters(sizeIco)}
						isDisabled={false}
						renderTooltipContent={() => null}
						sizeIco={sizeIco}
						placeIco={placeIco}
						activeHighlightedColor={activeHighlightedColor}
						toolTipBackgroundColor={activeHighlightedColor}
					>
						<React.Fragment>

							<div className='margin-bottom-10'>
								<span className='text-uppercase font-weight-bold' role='button' onClick={() => this.resetPropertySearchFilters()}>CLEAR LISTING CRITERIA FILTERS</span>
							</div>
							<div className='margin-bottom-10'>
								<span className='text-uppercase font-weight-bold' role='button' onClick={() => this.resetSearchLocationFilters()}>Clear Search Location Filters</span>
							</div>
							<div className='margin-bottom-10'>
								<span className='text-uppercase font-weight-bold' role='button' onClick={() => this.resetKeywordSearchFilters()}>Clear Keyword Search Filters</span>
							</div>
							<div className='margin-bottom-10'>
								<span className='text-uppercase font-weight-bold' role='button' onClick={() => this.resetDataLayersFilters()}>Clear Data Layers Filters</span>
							</div>
							<div className='margin-bottom-10'>
								<span className='text-uppercase font-weight-bold' role='button' onClick={() => this.resetFilters()}>Clear All Filters</span>
							</div>


						</React.Fragment>
					</FilterButtonIcon>
				}
			</React.Fragment >
		)
	}

	resetSelectDropdown = (name: string) => {
		this.handleInputChange(name, '');
	}

	resetFilters = () => {
		const { state, props } = this;
		const filter: LiFilter = new LiFilter();
		localStorage.setItem('showMajorPorts', 'false')
		localStorage.removeItem('classOneConnectionsDataLayer')
		if (!localStorage.getItem('PrivateClientData')) {
			localStorage.removeItem('shortLinesDataLayer')
		}
		localStorage.setItem('showIntermodalData', 'false')
		localStorage.setItem('showCrudeOilPipelines', 'false')
		localStorage.setItem('showPetroleumProductPipelines', 'false')
		localStorage.setItem('showRoadways', 'false')
		this.setState({
			filter: filter,
			showFiltersClearedModal: true,
			filterModalMessage: 'All'
		}, () => {
			Object.keys(filter).forEach(key => setStorageItem(key));
			const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
			setStorageItem('selectedPropertyType', selectedPropertyTypeAll);
			filter.selectedPropertyType = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
			this.createFilters(() => {
				props.handleInitialZoom();
			});

		})

	}
	resetPropertySearchFilters = () => {
		const { state, props } = this;
		console.log('old filters', this.state.filter.selectedPropertyFor)
		const filter: LiFilter = new LiFilter();
		console.log('new filters', filter)
		this.state.filter.selectedPropertyFor = '';
		localStorage.removeItem('selectedPropertyFor')
		this.state.filter.selectedPowerToSite = '';
		localStorage.removeItem('selectedPowerToSite')
		this.state.filter.minServiceVoltage = '';
		localStorage.removeItem('minServiceVoltage')
		this.state.filter.maxServiceVoltage = '';
		localStorage.removeItem('maxServiceVoltage')
		this.state.filter.selectedPropertyFor1 = '';
		localStorage.removeItem('selectedPropertyFor1')
		this.state.filter.naturalGasInPlace = '';
		localStorage.removeItem('naturalGasInPlace')
		this.state.filter.minLineDiameter = '';
		localStorage.removeItem('minLineDiameter')
		this.state.filter.minDistanceToSubstation = '';
		localStorage.removeItem('minDistanceToSubstation')
		this.state.filter.maxDistanceToSubstation = '';
		localStorage.removeItem('maxDistanceToSubstation')
		this.state.filter.maxLineDiameter = '';
		localStorage.removeItem('maxLineDiameter')
		this.state.filter.minPrice = '';
		localStorage.removeItem('minPrice')
		this.state.filter.maxPrice = '';
		localStorage.removeItem('maxPrice')
		this.state.filter.minAcreage = '';
		localStorage.removeItem('minAcreage')
		this.state.filter.siteZoning = '';
		localStorage.removeItem('siteZoning')
		this.state.filter.maxAcreage = '';
		localStorage.removeItem('maxAcreage')
		this.state.filter.sitePipelineAccess = '';
		localStorage.removeItem('sitePipelineAccess')
		this.state.filter.selectedBuildingOption = '';
		localStorage.removeItem('selectedBuildingOption')
		this.state.filter.siteMinSquareFeet = '';
		localStorage.removeItem('siteMinSquareFeet')
		this.state.filter.siteMaxSquareFeet = '';
		localStorage.removeItem('siteMaxSquareFeet')
		this.state.filter.siteMinInteriorRoofHeight = '';
		localStorage.removeItem('siteMinInteriorRoofHeight')
		this.state.filter.siteSuitableFor = '';
		localStorage.removeItem('siteSuitableFor')
		this.state.filter.siteEnvionmental = '';
		localStorage.removeItem('siteEnvionmental')
		this.state.filter.airAttainmentStatus = '';
		localStorage.removeItem('airAttainmentStatus')
		this.state.filter.capabilities = '';
		localStorage.removeItem('capabilities')
		this.state.filter.equipments = '';
		localStorage.removeItem('equipments')
		this.state.filter.railcarStorageCapacity = '';
		localStorage.removeItem('railcarStorageCapacity')
		this.state.filter.productStorage = '';
		localStorage.removeItem('productStorage')
		this.state.filter.productsHandled = '';
		localStorage.removeItem('productsHandled')
		this.state.filter.pttMinAcreage = '';
		localStorage.removeItem('pttMinAcreage')
		this.state.filter.pttMaxAcreage = '';
		localStorage.removeItem('pttMaxAcreage')
		this.state.filter.warehouseMinPrice = '';
		localStorage.removeItem('warehouseMinPrice')
		this.state.filter.warehouseMaxPrice = '';
		localStorage.removeItem('warehouseMaxPrice')
		this.state.filter.warehouseCapabilities = '';
		localStorage.removeItem('warehouseCapabilities')
		this.state.filter.temperatureControl = '';
		localStorage.removeItem('temperatureControl')
		this.state.filter.foodGrade = '';
		localStorage.removeItem('foodGrade')
		this.state.filter.freeTradeZone = '';
		localStorage.removeItem('freeTradeZone')
		this.state.filter.warehouseMinSize = '';
		localStorage.removeItem('warehouseMinSize')
		this.state.filter.warehouseMaxSize = '';
		localStorage.removeItem('warehouseMaxSize')
		this.state.filter.minRoofHeight = '';
		localStorage.removeItem('minRoofHeight')
		this.state.filter.maxRoofHeight = '';
		localStorage.removeItem('maxRoofHeight')
		this.state.filter.parkingSpaces = '';
		localStorage.removeItem('parkingSpaces')
		this.state.filter.marineAccess = '';
		localStorage.removeItem('marineAccess')
		this.state.filter.bargeAccess = '';
		localStorage.removeItem('bargeAccess')
		this.state.filter.docInPlace = '';
		localStorage.removeItem('docInPlace')
		this.state.filter.waterways = '';
		localStorage.removeItem('waterways')
		this.state.filter.navigableWater = '';
		localStorage.removeItem('navigableWater')
		this.state.filter.classOneMultiservedCheckbox = false;
		localStorage.removeItem('classOneMultiservedCheckbox')
		this.state.filter.classOneConnections = '';
		localStorage.removeItem('classOneConnectionsDataLayer')
		this.state.filter.shortLinesLabel = '';
		localStorage.removeItem('shortLinesLabel')
		this.state.filter.railInPlace = '';
		localStorage.removeItem('railInPlace')
		this.state.filter.railcarStorage = '';
		localStorage.removeItem('railcarStorage')
		this.state.filter.unitTrainCapable = '';
		localStorage.removeItem('unitTrainCapable')
		this.state.filter.railcarCapacity = '';
		localStorage.removeItem('railcarCapacity')
		Object.keys(filter).forEach(key => setStorageItem(key));
		const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
		setStorageItem('selectedPropertyType', selectedPropertyTypeAll);
		this.state.filter.selectedPropertyType = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
		this.createFilters(() => {
			props.handleInitialZoom();
		});
		this.setState({
			showFiltersClearedModal: true,
			filterModalMessage: 'Listing criteria search '
		})

	}
	resetSearchLocationFilters = () => {
		const { state, props } = this;
		// console.log('old filters', this.state.filter.selectedPropertyFor)
		const filter: LiFilter = new LiFilter();
		// console.log('new filters', filter)
		this.state.filter.selectedUsaStates = '';
		localStorage.removeItem('selectedUsaStates')
		this.state.filter.address = '';
		localStorage.removeItem('address')
		this.clearAddress()
		this.state.filter.radius = 0;
		localStorage.removeItem('radius')
		this.createFilters(() => {
			props.handleInitialZoom();
		});
		this.setState({
			showFiltersClearedModal: true,
			filterModalMessage: 'Location search '
		})
	}
	resetKeywordSearchFilters = () => {
		const { state, props } = this;
		// console.log('old filters', this.state.filter.selectedPropertyFor)
		const filter: LiFilter = new LiFilter();
		// console.log('new filters', filter)
		this.state.filter.searchByKeywordValue = '';
		localStorage.removeItem('searchByKeywordValue')
		this.createFilters(() => {
			props.handleInitialZoom();
		});
		this.setState({
			showFiltersClearedModal: true,
			filterModalMessage: 'Keyword search '
		})
	}
	resetDataLayersFilters = () => {
		const { state, props } = this;
		localStorage.setItem('showMajorPorts', 'false')
		localStorage.removeItem('classOneConnectionsDataLayer')
		if (!localStorage.getItem('PrivateClientData')) {
			localStorage.removeItem('shortLinesDataLayer')
		}
		// localStorage.removeItem('shortLinesDataLayer')
		localStorage.setItem('showIntermodalData', 'false')
		localStorage.setItem('showCrudeOilPipelines', 'false')
		localStorage.setItem('showPetroleumProductPipelines', 'false')
		localStorage.setItem('showRoadways', 'false')

		this.createFilters(() => {
			props.handleInitialZoom();
		});
		this.setState({
			showFiltersClearedModal: true,
			filterModalMessage: 'Data Layers '
		})

	}


	setBargeAccess = (value: string) => {
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		if (value.includes('Barge') || value.includes('Vessel')) {
			filter.bargeAccess = 'Yes';
		} else {
			filter.bargeAccess = '';
		}
		setStorageItem('bargeAccess', filter.bargeAccess);
		this.setState({ filter: filter });
	}

	setListingFor = (value: string) => {
		if (value.includes('Warehouse') || value.includes('Multipurpose')) {
			// this.addPTTOptionInListingFor();
		}
	}

	addPTTOptionInListingFor = () => {
		this.setState({ listingForArr: [...listingForOptionsExt] });
	}

	removePTTOptionFromListingFor = () => {
		const { state } = this;
		const filter: LiFilter = new LiFilter().copy(state.filter);
		filter.selectedPropertyFor = reJoinFilter(filter.selectedPropertyFor, 'For 3PL/Public Warehouse Services');
		this.setState({
			listingForArr: [...listingForOptionsInit]
			, filter: filter
		});
	}

	addColdStorageInCapabilities = () => {
		this.setState({ capabilitiesArr: [...capabilitiesOptionsExt] });
	}

	removeColdStorageFromCapabilities = () => {
		this.setState({ capabilitiesArr: [...capabilitiesOptionsInit] });
	}

	handleSelectPropertyType = (name: string, valueArr: Array<LabelValue>) => {
		const selectedPropertyType = joinFilterV(valueArr);
		const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
		const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
		const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
		const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
		const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
		if (flagIndustrialSitesProperties) {
			this.setListingFor(selectedPropertyType);
		}
		if (flagWarehouse) {
			this.addColdStorageInCapabilities();
			this.setListingFor(selectedPropertyType);
		}
		if (flagMultipurpose) {
			this.setListingFor(selectedPropertyType);
			this.addColdStorageInCapabilities();
		}
		if (!flagWarehouse && !flagMultipurpose) {
			// this.removePTTOptionFromListingFor();
		}
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		filter.selectedPropertyType = selectedPropertyType;
		this.setState({
			filter: filter
		}, () => {
			this.createFilters();
			setStorageItem(name, selectedPropertyType);
		});
	}

	handleMultiSelect = (name: string, valueArr: Array<LabelValue>) => {
		const str = joinFilterV(valueArr);
		this.handleInputChange(name, str);
	}

	handleSingleSelect = (name: string, value: LabelValue) => {
		this.handleInputChange(name, value.value);
	}

	handleInputChange = (name: string, value: string | number) => {
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		filter[name] = value;
		this.setState({
			filter: filter
		}, () => {
			this.createFilters();
			setStorageItem(name, '' + value);
		});
	}

	handleRadiusSelect = (name: string, value: LabelValue) => {
		let radius: number = Number(value.value);
		if (radius === NaN) {
			radius = this.state.defaultRadius;
		}
		this.handleInputChange(name, radius);
	}

	handleAddressChange = (
		address: string
		, coordinates: Array<number>
		, city: string
		, usaState: string
		, zipCode: string
		, country: string
	) => {
		const flagCoordinates: boolean = coordinates !== null && coordinates.length > 1;
		const { state } = this;
		const filter: LiFilter = new LiFilter().copy(state.filter);
		filter.selectedUsaStates = '';
		filter.radius = flagCoordinates ? state.defaultRadius : 0;
		filter.address = address ? address : '';
		filter.city = city ? city : '';
		filter.usaState = usaState ? usaState : '';
		filter.zipcode = zipCode ? zipCode : '';
		filter.country = country ? country : '';
		filter.flagRadiusDisabled = flagCoordinates ? false : true;
		filter.cityCoordinates = flagCoordinates ? coordinates : [];
		this.setState({
			filter: filter,
		}, () => {
			setStorageItem('selectedUsaStates');
			setStorageItem('radius', '' + filter.radius);
			setStorageItem('address', filter.address);
			setStorageItem('cityCoordinates', JSON.stringify(filter.cityCoordinates));
			setStorageItem('city', filter.city);
			setStorageItem('usaState', filter.usaState);
			setStorageItem('zipcode', filter.zipcode);
			setStorageItem('country', filter.country);
			this.createFilters();
		});
	}

	clearAddress = () => {
		this.handleAddressChange('', [], '', '', '', '');
	}
	resetkeywordsearch = () => {
		localStorage.removeItem('searchByKeywordValue')
		this.handleInputChange(this.state.debounceOnSearchByKeywordChange, null)
		this.loadParametersFromLocalStorage();

	}

	createPostponedFilters(callback?: () => void) {
		setTimeout(() => {
			this.createFiltersIntern(true, callback);
		}, 10);
	}

	createFilters(callback?: () => void) {
		this.createFiltersIntern(false, callback);
	}

	createFiltersIntern(flagPostponedCreate: boolean, callback?: () => void) {
		const { props, state } = this;
		const { filter } = state;
		let parameter: string;
		const originalResponse = getStorageItem('originalResponse');
		const geoResponse: TGeoResponse = originalResponse && originalResponse.length > 0 ? JSON.parse(originalResponse) : null;

		let intermediateFeatures: Array<TInFeature> = [];
		intermediateFeatures = geoResponse !== null ? geoResponse.features : [];

		if (StringUtils.isNotEmpty(filter.selectedUsaStates)) {
			parameter = 'selectedUsaStates';
			const usaStatesArr = [filter.selectedUsaStates];
			intermediateFeatures = createIntermediateArr(usaStatesArr, intermediateFeatures, parameter, '', '');
		}

		if (StringUtils.isNotEmpty(filter.city)) {
			let searchUrl: string = 'city=' + filter.city;
			if (StringUtils.isNotEmpty(filter.zipcode)) {
				searchUrl += '&postalcode=' + filter.zipcode;
			}
			if (StringUtils.isNotEmpty(filter.usaState)) {
				searchUrl += '&state=' + filter.usaState;
			}
			if (StringUtils.isNotEmpty(filter.country)) {
				searchUrl += '&country=' + filter.country;
			}
			axios.get('https://nominatim.openstreetmap.org/search?' + searchUrl + '&format=json')
				.then((response) => {
					const currentFeatures = intermediateFeatures;
					const filteredFeatures: Array<TInFeature> = [];
					const radius: number = Number(filter.radius);
					const includedIndexes: Set<number> = new Set<number>();
					let kInBounds = -1;
					if (filter.cityCoordinates && filter.cityCoordinates.length >= 2) {
						const ccLon = filter.cityCoordinates[0];
						const ccLat = filter.cityCoordinates[1];
						for (let k = 0; k < response.data.length; k++) {
							const dataK = response.data[k];
							const boundingbox = dataK.boundingbox;
							const cityLatitude: number = parseFloat(dataK.lat);
							const cityLongitude: number = parseFloat(dataK.lon);
							if (boundingbox[0] <= ccLat && ccLat <= boundingbox[1]
								&& boundingbox[2] <= ccLon && ccLon <= boundingbox[3]) {
								kInBounds = k;
								break;
							}
						}
					}
					for (let k = 0; k < response.data.length; k++) {
						if (kInBounds >= 0 && kInBounds !== k) {
							continue;
						}
						const dataK = response.data[k];
						const cityLatitude: number = parseFloat(dataK.lat);
						const cityLongitude: number = parseFloat(dataK.lon);
						for (let i = 0; i < currentFeatures.length; i++) {
							if (!currentFeatures[i].geometry.coordinates || includedIndexes.has(i)) {
								continue;
							}
							const itemLatitude: number = currentFeatures[i].geometry.coordinates[1];
							const itemLongitude: number = currentFeatures[i].geometry.coordinates[0];
							const distanceInMeters = getPreciseDistance(
								{ latitude: cityLatitude, longitude: cityLongitude },
								{ latitude: itemLatitude, longitude: itemLongitude }
							);
							const miles: number = distanceInMeters * 0.000621371;
							if (miles <= radius) {
								includedIndexes.add(i);
								filteredFeatures.push(currentFeatures[i]);
							}
						}
					}
					props.filterResults(filter, filteredFeatures, flagPostponedCreate, callback);
				}).catch((error) => {
					console.log(error);
					alert('Something went wrong, please try again!');
				});
		} else {
			if (StringUtils.isNotEmpty(filter.country)) {
				parameter = 'country';
				const countryArr = [filter.country];
				intermediateFeatures = createIntermediateArr(countryArr, intermediateFeatures, parameter, '', '');
			}
			if (StringUtils.isNotEmpty(filter.usaState)) {
				parameter = 'selectedUsaStates';
				const usaStatesArr = [filter.usaState];
				intermediateFeatures = createIntermediateArr(usaStatesArr, intermediateFeatures, parameter, '', '');
			}
			props.filterResults(filter, intermediateFeatures, flagPostponedCreate, callback);
		}
	}

	setBuildingOption(value: string) {
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		filter.selectedBuildingOption = value;
		this.setState({ filter: filter }, () => {
			setStorageItem('selectedBuildingOption', value);
			this.createFilters();
		});
	}

	onSearchByKeywordChange = (value: string) => {
		const { state } = this;
		if (value === state.filter.searchByKeywordValue) {
			return;
		}
		const filter: LiFilter = new LiFilter().copy(state.filter);
		filter.searchByKeywordValue = value;
		this.setState({ filter: filter }, () => {
			setStorageItem('searchByKeywordValue', value);
			this.createFilters();
		});
	}

	resetRadius = () => {
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		filter.radius = 0;
		this.setState({
			filter: filter,
		}, () => {
			setStorageItem('radius');
		});
	}

	resetAddress = (callback?: () => void) => {
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		filter.resetAddress();
		this.setState({
			filter: filter,
		}, () => {
			setStorageItem('selectedUsaStates');
			setStorageItem('city');
			setStorageItem('usaState');
			setStorageItem('zipcode');
			setStorageItem('country');
			setStorageItem('address');
			if (callback) {
				callback();
			}
		});
	}

	resetStates = () => {
		const filter: LiFilter = new LiFilter().copy(this.state.filter);
		filter.selectedUsaStates = '';
		this.setState({
			filter: filter
		}, () => {
			setStorageItem('selectedUsaStates');
		});
	}

	renderTtItem = (label: string, value: string): React.ReactNode => {
		return (
			<React.Fragment>
				<span style={{ color: 'white' }}>
					<b>
						{
							label
						}
					</b>
				</span>
				<span style={{ color: 'white' }}>
					{
						value
					}
				</span>
			</React.Fragment>
		)
	}

	renderTtNext = (label: string, value01: string, value02: string = ''): React.ReactNode => {
		if (!value01 && !value02) {
			return null;
		}
		if (!value01 && value02) {
			value01 = '0';
		}
		return (
			<React.Fragment>
				<br />
				{
					this.renderTtItem(label, '' + value01 + (value02 ? ' - ' + value02 : ''))
				}
			</React.Fragment>
		)
	}

	renderTtLocation = (): React.ReactNode => {
		const { state } = this;
		const { filter } = state;
		const selectedUsaStates = filter.selectedUsaStates ? filter.selectedUsaStates.replaceAll('*', ', ') : '';
		if (selectedUsaStates) {
			return (
				<div key={'tt_location_' + selectedUsaStates}>
					{
						this.renderTtItem('Location: ', selectedUsaStates)
					}
				</div>
			)
		}
		if (!filter.address) {
			return null;
		}
		return (
			<div key={'tt_location_' + filter.address}>
				{
					this.renderTtItem('Location: ', filter.address)
				}
				{
					filter.radius <= 0 ? '' :
						this.renderTtNext('Radius: ', '' + filter.radius)
				}
			</div>
		)
	}

	renderTtKeywords = (): React.ReactNode => {
		const { state } = this;
		const { filter } = state;
		if (!filter.searchByKeywordValue) {
			return null;
		}
		const keywords = filter.searchByKeywordValue ? filter.searchByKeywordValue.replaceAll('*', ', ') : '';
		return (
			<div key={'tt_keywords_' + keywords}>
				{
					this.renderTtItem('Keywords: ', keywords)
				}
			</div>
		)
	}

	renderTtPropertyFacility = (): React.ReactNode => {
		const { state } = this;
		const { filter } = state;
		const { selectedPropertyType } = filter;
		if (!selectedPropertyType) {
			return null;
		}
		const selectedPropertyTypeLabel = selectedPropertyType ? selectedPropertyType.replaceAll('*', ', ') : '';
		const marineAccessKeys = ['Port Terminals', 'Industrial Sites & Properties', 'Terminals and Transload Facilities', 'Multipurpose'];
		const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
		const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
		const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
		const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
		const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
		return (
			<div key={'tt_prop_facility_type_' + selectedPropertyType}>
				<div className='font-size-17 color-white clear-both margin-bottom-10'><u>Active Filters:</u></div>
				{
					this.renderTtItem('Property/facility type: ', selectedPropertyTypeLabel)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('For Sale/Lease: ', filter.selectedPropertyFor)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('For Sale/Lease/3PL: ', filter.selectedPropertyFor1)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Price: ', filter.minPrice, filter.maxPrice)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Acreage: ', filter.minAcreage, filter.maxAcreage)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Zoning: ', filter.siteZoning)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Pipeline Access: ', filter.sitePipelineAccess)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Building: ', filter.selectedBuildingOption)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) && filter.selectedBuildingOption !== 'Yes' ? '' :
						this.renderTtNext('Building Sq. Ft: ', filter.siteMinSquareFeet, filter.siteMaxSquareFeet)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Clear Height (in ft): ', filter.siteMinInteriorRoofHeight)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Suitable for: ', filter.siteSuitableFor)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Environmental: ', filter.siteEnvionmental)
				}
				{
					!(flagIndustrialSitesProperties || flagMultipurpose) ? '' :
						this.renderTtNext('Air attainment status: ', filter.airAttainmentStatus)
				}
				{
					!(flagPortTerminals || flagTerminalsTransload || flagMultipurpose) ? '' :
						this.renderTtNext('Capabilities: ', filter.capabilities)
				}
				{
					!(flagPortTerminals || flagTerminalsTransload || flagMultipurpose) ? '' :
						this.renderTtNext('Equipment: ', filter.equipments)
				}
				{
					!(flagPortTerminals || flagTerminalsTransload || flagMultipurpose) ? '' :
						this.renderTtNext('Railcar storage capacity: ', filter.railcarStorageCapacity)
				}
				{
					!(flagPortTerminals || flagTerminalsTransload || flagMultipurpose) ? '' :
						this.renderTtNext('Product storage: ', filter.productStorage)
				}
				{
					!(flagPortTerminals || flagTerminalsTransload || flagMultipurpose) ? '' :
						this.renderTtNext('Products handled: ', filter.productsHandled)
				}
				{
					!(flagPortTerminals || flagTerminalsTransload || flagMultipurpose) ? '' :
						this.renderTtNext('Ports, Terminals & Transloads Acreage: ', filter.pttMinAcreage, filter.pttMaxAcreage)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Price: ', filter.warehouseMinPrice, filter.warehouseMaxPrice)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Warehouse Capabilities: ', filter.warehouseCapabilities)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Temperature control: ', filter.temperatureControl)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Food grade: ', filter.foodGrade)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('FTZ (Foreign Trade Zone): ', filter.freeTradeZone)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Available sq. ft: ', filter.warehouseMinSize, filter.warehouseMaxSize)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Clear Height: ', filter.minRoofHeight, filter.maxRoofHeight)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Parking spaces: ', filter.parkingSpaces)
				}
				{
					!(marineAccessKeys.some(key => filter.selectedPropertyType.includes(key))) ? '' :
						this.renderTtNext('Marine access: ', filter.marineAccess)
				}
				{
					!(flagWarehouse || flagMultipurpose) ? '' :
						this.renderTtNext('Barge/Vessel access: ', filter.bargeAccess)
				}
				{
					!(marineAccessKeys.some(key => filter.selectedPropertyType.includes(key))) && filter.bargeAccess !== 'Yes' ? '' :
						this.renderTtNext('Dock in place now: ', filter.docInPlace)
				}
				{
					!(marineAccessKeys.some(key => filter.selectedPropertyType.includes(key))) && filter.bargeAccess !== 'Yes' ? '' :
						this.renderTtNext('Waterways: ', filter.waterways)
				}
				{
					!(marineAccessKeys.some(key => filter.selectedPropertyType.includes(key))) ? '' :
						this.renderTtNext('Distance to navigable water (miles): ', filter.navigableWater)
				}
				{
					!(!!filter.classOneMultiservedCheckbox) ? '' :
						this.renderTtNext('Multi-served Class I connection: ', filter.classOneMultiservedCheckbox ? 'On' : '')
				}
				{
					!(!!filter.selectedPropertyType) ? '' :
						this.renderTtNext('Class I connections: ', filter.classOneConnections)
				}
				{
					!(!!filter.selectedPropertyType) ? '' :
						this.renderTtNext('Short Lines: ', filter.shortLinesLabel)
				}
				{
					!(!!filter.selectedPropertyType) ? '' :
						this.renderTtNext('Rail in place: ', filter.railInPlace)
				}
				{
					!(!!filter.selectedPropertyType) ? '' :
						this.renderTtNext('Railcar storage: ', filter.railcarStorage)
				}
				{
					!(!!filter.selectedPropertyType) ? '' :
						this.renderTtNext('Unit train capable: ', filter.unitTrainCapable)
				}
				{
					!(!!filter.selectedPropertyType) ? '' :
						this.renderTtNext('Railcar spots/capacity: ', filter.railcarCapacity)
				}
			</div>
		)
	}

	renderIconFacility = (sizeIco: number): React.ReactNode => {
		return (
			/* {
				ListingType
			} */
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
						Property Search
					</strong>
				</Tooltip>
			}>
				<svg viewBox='0 0 512 512' className='filter_listing_criteria' style={{
					width: sizeIco,
					height: sizeIco,
				}}>
					<path fill='currentColor'
						d='M475.115 163.781L336 252.309v-68.28c0-18.916-20.931-30.399-36.885-20.248L160 252.309V56c0-13.255-10.745-24-24-24H24C10.745 32 0 42.745 0 56v400c0 13.255 10.745 24 24 24h464c13.255 0 24-10.745 24-24V184.029c0-18.917-20.931-30.399-36.885-20.248z'
					>
					</path>
				</svg>

			</OverlayTrigger>

		)
	}

	renderIconLocation = (sizeIco: number): React.ReactNode => {
		return (
			/* {
				Location
			} */
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
						Search Location
					</strong>
				</Tooltip>
			}>
				<svg viewBox='0 0 384 512' className='filter_location' style={{
					width: sizeIco,
					height: sizeIco,
				}}>
					<path fill='currentColor'
						d='M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z'
					>
					</path>
				</svg>

			</OverlayTrigger>


		)
	}

	renderIconKeywords = (sizeIco: number): React.ReactNode => {
		return (
			/* {
				Keywords
			} */
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
						Keyword Search
					</strong>
				</Tooltip>
			}>
				<svg className='keyword_search_icon' viewBox='0 0 512 512' style={{
					width: sizeIco,
					height: sizeIco,
				}}>
					<path fill='currentColor'
						d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'
					>
					</path>
				</svg>

			</OverlayTrigger>

		)
	}
	renderIconClearFilters = (sizeIco: number): React.ReactNode => {
		return (
			/* {
				Location
			} */
			<OverlayTrigger placement='top' overlay={
				<Tooltip id='tooltip'>
					<strong className='font-size-16'>
						Clear Filters
					</strong>
				</Tooltip>
			}>
				<svg viewBox='0 0 512 512' className='clear_filters' style={{
					width: sizeIco,
					height: sizeIco,
				}}>
					<path fill='currentColor'
						d='M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z'
					>
					</path>
				</svg>

			</OverlayTrigger>


		)
	}
}
