import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesCsxt extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesCsxt extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesCsxt = null;

	constructor(parent: NorthAmericanRailLinesCsxt, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesCsxt extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesCsxt, SNorthAmericanRailLinesCsxt> {

	static dataSource: string = 'NARL_CSXT-0rjuq1';
	static sourceLayer: string = 'NARL_CSXT';

	constructor(props: PNorthAmericanRailLinesCsxt) {
		super(props, NorthAmericanRailLinesCsxt.dataSource, NorthAmericanRailLinesCsxt.sourceLayer);
		this.state = new SNorthAmericanRailLinesCsxt(this, NorthAmericanRailLinesCsxt.dataSource, NorthAmericanRailLinesCsxt.sourceLayer);
	}
}
