import React, { Component } from 'react';
import { Button, Modal, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../custom.css';
import logo from '../logo.svg';
import UserProfile from './UserProfile';
import axios from 'axios';
import Bookmarkcomponent from './Bookmarks';
import SimpleReactValidator from 'simple-react-validator';
import InputBoxComponent from './InputBoxComponent';
import MultiStepRegistration from './Register/MultiStepRegistration';
import { Cookies } from 'react-cookie';
import ReCAPTCHA from 'react-google-recaptcha';
import LinkedInTag from 'react-linkedin-insight';
import gtag from 'ga-gtag';
// import Step1 from '../components/Register/Step1';

import { getStorageItem } from './ListIconFiltering';
let bookmarkCount;
const cookies = new Cookies();
const recaptchaRef = React.createRef();

interface PHeader {
	onRef?: (header?: Header) => void;
	data?: any;
	shoppingCartCount?: number;
	bookmarksCount?: number;
	logo?: string;
	btnBackgroundColor?: string;
	iconColor?: string;
	secondaryColor?: string;
	privateClientId?: string;
}

class SHeader {
	constructor() {
	}
}

class Header extends Component<PHeader, any> {

	validator: SimpleReactValidator = null;
	loginValidator: SimpleReactValidator = null;
	OTPValidator: SimpleReactValidator = null;
	helpers: any = null;

	constructor(props: PHeader) {
		super(props);
		this.state = {
			showLoginModal: false,
			showNotificationModal: false,
			notification_data: '',
			bookmarksModal: false,
			showForgotPasswordModal: false,
			login_email: '',
			login_password: '',
			isLoggedIn: false,
			nextURL: '',
			propertyType: '',
			propertySubType: '',
			fpEmail: '',
			showLoginSuccessModal: false,
			showLogoutSuccessModal: false,

			showRegistrationModal: false,
			fname: '',
			lname: '',
			companyName: '',
			password: '',
			confirm_password: '',
			email: '',
			showRegistrationSuccessModal: false,
			reacatpcha_value: null,
			// shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
			bookmarksCount: props.bookmarksCount ? props.bookmarksCount : 0,
			shoppingCartCount: props.shoppingCartCount ? props.shoppingCartCount : 0,
		}
		this.handleMyListing = this.handleMyListing.bind(this);
		this.logoutUser = this.logoutUser.bind(this);
		this.handleClose = this.handleClose.bind(this);
		this.checkIfLoginPage = this.checkIfLoginPage.bind(this);
		this.setLoginEmail = this.setLoginEmail.bind(this);
		this.setLoginPassword = this.setLoginPassword.bind(this);
		this.handleLogin = this.handleLogin.bind(this);

		this.validator = new SimpleReactValidator({
			validators: {
				phone: {  // name the rule
					message: 'The :phone must be a valid phone number.',
					rule: (val: any, params?: any, validator?: any) => {
						return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)
					},
					messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
					required: true  // optional
				} as any
			}
		});

		// console.log('in header props')
		// console.log(props)
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.data) {
			const bookmarksCount = this.state.bookmarksCount;
			if (nextProps.data.location.pathname === '/bookmarks' || nextProps.data.location.pathname.includes('details') || nextProps.data.location.pathname === '/view-listings') {
				if (nextProps.bookmarksCount !== bookmarksCount) {
					this.setState({
						bookmarksCount: nextProps.bookmarksCount
					})
				}
			}
			const shoppingCartCount = this.state.shoppingCartCount;
			if (nextProps.shoppingCartCount !== shoppingCartCount) {
				this.setState({
					shoppingCartCount: nextProps.shoppingCartCount
				})
			}
		}
	}

	updateShoppingcart = () => {
		this.setState({ shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0 })
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		this.props.onRef(this);
		let bookmarksCount = 0;
		const bookmarksStr = localStorage.getItem('bookmarks') ? localStorage.getItem('bookmarks') : '';
		if (bookmarksStr) {
			const bookmarksArr = bookmarksStr.split(',');
			bookmarksCount = bookmarksArr.length;
		}

		this.setState({
			bookmarksCount: bookmarksCount
		});
		if (sessionStorage.getItem('showNotificationModal')) {
			// this.setState({
			// 	showNotificationModal: localStorage.getItem('showNotificationModal')
			// })
		} else {
			let this_keyword = this
			this.checkNotification(this_keyword)
		}
	}

	checkNotification(this_keyword) {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-notification-info', {
			id: 1
		})
			.then(function (response) {
				console.log("API response:", response)
				this_keyword.setState({
					showNotificationModal: response.data[0].is_visible == 1 ? true : false,
					notification_data: response.data[0].description_data
				});
				sessionStorage.setItem('showNotificationModal', 'not_visible');
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	handleNotificationModal = () => {
		this.setState({ showNotificationModal: false });
	}

	componentWillUnmount() {
		this.props.onRef(undefined);
	}

	componentWillMount() {
		this.loginValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		this.OTPValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
	}
	handleOTPVerification = () => {
		if (this.OTPValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					otp: this.state.otp,
					login_email: this.state.login_email,
				}).then(response => {
					console.log(response);
					if (response.status === 200) {
						this.handleOTPModal();
						this.handleLoginSuccessResponse(response);
					}
				}).catch(function (error) {
					// handle your error
				});
		} else {
			this.OTPValidator.showMessages();
			this.forceUpdate();
		}
	}

	handleMyListing() {
		this.props.data.history.push('/my-listing');
	}

	logoutUser() {
		// UserProfile.forgetEmail();
		// UserProfile.forgetFirstName();
		// UserProfile.forgetLastName();
		cookies.remove('token');
		localStorage.clear();
		this.setState({ isLoggedIn: false, showLogoutSuccessModal: true });
		this.props.data.history.push('/');
	}

	handleLogoutSuccessModal = () => {
		this.setState({ showLogoutSuccessModal: false });
	}

	handleShow(nextURL, type = '', subType = '') {
		this.setState({
			nextURL: nextURL,
			propertyType: type,
			propertySubType: subType,
		});

		if (cookies.get('token') !== undefined) {
			fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
					'x-access-token': cookies.get('token')
				},
			})
				.then(res => {
					if (res.status === 200) {
						if (nextURL === '/create-listings') {
							// this.props.data.history.push('/create');
							this.props.data.history.push({
								pathname: '/create-listings',
								state: {
									propertyType: this.state.propertyType,
									propertySubType: this.state.propertySubType,
								}
							})
						} else if (nextURL === '/view-listings') {
							// this.props.data.history.push('/listing');
							this.props.data.history.push({
								pathname: '/view-listings',
								state: {
									propertyType: type,
									propertySubType: subType,
								}
							})
						}
					} else {
						UserProfile.forgetEmail();
						cookies.remove('token');
						this.props.data.history.push('/');
						window.scrollTo(0, 0);
						// const error = new Error(res.error);
						// throw error;
					}
				})
				.catch(err => {
					console.error(err);
					this.setState({ loading: false, redirect: true });
				});
		} else {
			this.handleOpen();
		}
	}

	handleOpen = () => {
		this.setState({ showLoginModal: true });
	}

	handleClose() {
		this.setState({ showLoginModal: false });
	}

	handleShowForgotPasswordModal = () => {
		this.setState({ showForgotPasswordModal: true });
		this.handleClose();
	}

	handleForgotPasswordClose = () => {
		this.setState({ showForgotPasswordModal: false });
		this.handleOpen();
	}

	setFPEmail = (event) => {
		this.setState({ fpEmail: event.target.value });
	}

	sendFPEmail = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
			{
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				fpEmail: this.state.fpEmail
			}).then(response => {
				document.getElementById('fp_success').style.display = 'block';
				document.getElementById('fp_error').style.display = 'none';
			}).catch(function (error) {
				document.getElementById('fp_success').style.display = 'none';
				document.getElementById('fp_error').style.display = 'block';
				// alert('Something went wrong! Please try after sometime.')
			});
	}

	setLoginEmail(event) {
		this.setState({ login_email: event.target.value });
	}

	setLoginPassword(event) {
		this.setState({ login_password: event.target.value });
	}

	checkIfLoginPage() {
		if (this.props.data.location.pathname === '/register') {
			this.handleClose();
		} else {
			this.props.data.history.push({
				pathname: '/register',
				state: {
					nextURL: '/create-listings',
				}
			})
		}
	}
	handleLoginSuccessResponse = response => {
		UserProfile.setEmail(response.data.email);
		UserProfile.setFirstName(response.data.first_name);
		UserProfile.setLastName(response.data.last_name);
		UserProfile.setUserType(response.data.user_type);
		UserProfile.setCompanyName(response.data.companyName);

		this.setState({
			isLoggedIn: true,
			showLoginSuccessModal: true,
		});

		cookies.set('token', response.data.token);
		this.forceUpdate();

		if (this.props.data.location.pathname === '/register' || this.props.data.location.pathname === '/success' || this.state.nextURL === '/create-listings') {
			// this.props.data.history.push('/create');
			this.props.data.history.push({
				pathname: '/create-listings',
				state: {
					propertyType: this.state.propertyType,
					propertySubType: this.state.propertySubType,
				}
			})
		}
		else if (this.state.nextURL === '/view-listings') {
			// this.props.data.history.push('/listing');
			this.props.data.history.push({
				pathname: '/view-listings',
				state: {
					propertyType: this.state.propertyType,
					propertySubType: this.state.propertySubType,
				}
			})
		}
	}

	handleLogin() {
		if (this.loginValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					login_email: this.state.login_email,
					login_password: this.state.login_password,
				}).then((response: {
					status: number
					, data: {
						bookmarks_str: string
						, email: string
						, first_name: string
						, last_name: string
						, max_no_entries: string
						, options: string
						, place_names_arr: string //Array<any>
						, user_type: string
						, token: string
					}
				}) => {
					document.getElementById('login_error').style.display = 'none';
					document.getElementById('verify_email_error').style.display = 'none';
					console.log(response);
					this.handleClose();
					// alert(window.location.href)
					if (response.status === 200) {
						let bookmarksCount = 0;
						console.log('response.data')
						console.log(response.data)
						if (response.data.bookmarks_str) {
							const bookmarksArr = response.data.bookmarks_str.split(',');
							bookmarksCount = bookmarksArr.length;
						}
						localStorage.setItem('bookmarks', response.data.bookmarks_str);
						localStorage.setItem('listingNames', response.data.place_names_arr);
						localStorage.setItem('maxNoEntries', response.data.max_no_entries);
						localStorage.setItem('membershipOptions', response.data.options);

						if (response.data.user_type === 'admin') {
							this.setState({
								showOTPModal: true
							});
						} else {
							this.handleLoginSuccessResponse(response);
						}
					} else {
						this.setState({ isLoggedIn: false });
						if (response.status === 204) {
							document.getElementById('verify_email_error').style.display = 'block';
							document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
						} else {
							document.getElementById('login_error').style.display = 'block';
							document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
						}
					}
				}).catch((error) => {
					// handle your error
					document.getElementById('login_error').style.display = 'block';
					document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
				});
		} else {
			this.loginValidator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}
	handleLoginSuccessModal() {
		this.setState({ showLoginSuccessModal: false });
	}

	showRegistrationModal = () => {
		gtag('event', 'home-reg-click', {poll_title: 'home-reg-click', })
		this.setState({
			showRegistrationModal: true,
			showLoginModal: false,
		});
	}

	handleRegistrationModal = () => {
		this.setState({ showRegistrationModal: false });
	}

	setFName(event) {
		this.setState({ fname: event.target.value });
	}

	setLName(event) {
		this.setState({ lname: event.target.value });
	}

	setCompanyName(event) {
		this.setState({ companyName: event.target.value });
	}

	setPassword(event) {
		this.setState({ password: event.target.value });
	}

	setConfirmPassword(event) {
		this.setState({ confirm_password: event.target.value });
	}

	setEmail(event) {
		this.setState({ email: event.target.value });
	}

	onRecaptchaChange(value) {
		console.log('Captcha value: ', value);
		this.setState({ reacatpcha_value: value });
	}

	registerUser(event) {
		if (this.validator.allValid()) {
			LinkedInTag.track('9791956');
			axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user',
				{
					headers: { 'Content-Type': 'application/json' },
					fname: this.state.fname,
					lname: this.state.lname,
					email: this.state.email,
					companyName: this.state.companyName,
					password: this.state.password,
					reacatpcha_value: this.state.reacatpcha_value,
				}).then(response => {
					// handle your response;
					if (response.status === 200) {
						this.setState({
							fname: '',
							lname: '',
							companyName: '',
							password: '',
							confirm_password: '',
							email: '',
							reacatpcha_value: null,
							showRegistrationSuccessModal: true,
							showRegistrationModal: false
						});
					}
				}).catch(function (error) {
					console.log('error')
					console.log(error.response)
					// handle your error
					const regi_errors = document.getElementById('regi_errors');
					regi_errors.innerHTML = '';
					let error_html = document.createElement('div');
					if (error.response.status === 600 && error.response.data.error === 'email exists') {
						error_html.innerHTML = 'You already have an account, please login!';
					} else if (error.response.status === 601) {
						error_html.innerHTML = 'Captcha verification failed, please try again!';
					} else {
						for (let i = 0; i < error.response.data.errors.length; i++) {
							error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
						}
					}
					document.getElementById('regi_errors').appendChild(error_html);
					document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
					regi_errors.scrollIntoView({ behavior: 'smooth' })
				});
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}
	handleOTPModal = () => {
		this.setState({ showOTPModal: false });
	}
	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleRegistrationSuccessModal = () => {
		this.setState({ showRegistrationSuccessModal: false });
	}
	hideprivateFavModal = () => {
		// this.setState({
		// 	bookmarksCountPrivate: JSON.parse(localStorage.getItem('bookmarksprivate')) ? JSON.parse(localStorage.getItem('bookmarksprivate')).length : 0
		// })
		this.updatebookmarkcountpublic();
		this.setState({ bookmarksModal: false });

	}
	updatebookmarkcountpublic = () => {
		let bookmarksCount = 0;
		const bookmarksStr = getStorageItem('bookmarks');
		if (bookmarksStr) {
			const bookmarksArr = bookmarksStr.split(',');
			bookmarksCount = bookmarksArr.length;
		}
		this.setState({
			bookmarksCount: bookmarksCount
		});
	}
	showModalPrivateFav = () => {
		this.updatebookmarkcountpublic();
		this.setState({
			bookmarksModal: true,
		});

	}

	render() {
		const { state, props } = this;
		const {
			privateClientId
		} = props;
		const isLoggedIn = UserProfile.getEmail();
		let flagMultiClassI = (!!privateClientId) ? true : false;
		let flagFavorites = (!!privateClientId) ? true : false;
		const membershipOptionsStr = getStorageItem('membershipOptions');
		if (membershipOptionsStr) {
			const membershipOptionsArr: Array<{
				multi_class_I: string
				, favorites: string
				, dmsg: string
				, classI: string
				, shortline: string
				, intermodal: string
				, ports: string
				, keywords: string
			}> = JSON.parse(membershipOptionsStr);
			if (membershipOptionsArr && membershipOptionsArr.length >= 1) {
				flagMultiClassI = new Boolean(membershipOptionsArr[0].multi_class_I).valueOf();
				flagFavorites = new Boolean(membershipOptionsArr[0].favorites).valueOf();
			}
		}
		const keySuffix = '_' + flagMultiClassI + '_' + flagFavorites;
		return (
			<React.Fragment key={'Header_' + keySuffix}>
				<nav className='navbar navbar-default visible-xs-custom margin-bottom-0'>
					<div className='container-fluid'>
						<div className='navbar-header float-none'>
							<button type='button' style={{ width: 50 }} className='navbar-toggle navbar-toggle-custom collapsed' data-toggle='collapse' data-target='#navbar' aria-expanded='false' aria-controls='navbar'>
								<span className='sr-only'>Toggle navigation</span>
								<span className='icon-bar'></span>
								<span className='icon-bar'></span>
								<span className='icon-bar'></span>
							</button>
							{
								// (!this.props || !this.props.data || this.props.data.location.pathname === '/create-listings' || this.props.data.location.pathname === '/plans-and-pricing' || this.props.data.location.pathname === '/about-us' || this.props.data.location.pathname === '/checkout-subscription' || this.props.data.location.pathname === '/checkout-upgrade' || this.props.data.location.pathname === '/upgrade-success' || this.props.data.location.pathname === '/privacy-policy' || this.props.data.location.pathname === '/terms-and-conditions' || this.props.data.location.pathname === '/places-:id') ? null : (
									(!this.props || !this.props.data || this.props.data.location.pathname == '/view-listings' || this.props.data.location.pathname == '/view-listings-real-estate-bd' || this.props.data.location.pathname == '/view-listings-site-selectors') ?  (
									flagFavorites ?
										<React.Fragment>
											{
												<li className='pull-right' style={{ display: 'inline-block', marginRight: '10px', padding: '10.5px 15px 22.5px 15px', position: 'relative', top: 8 }}>
													{/* <Link to='/bookmarks'> */}
													<Link onClick={this.showModalPrivateFav}>
														<i className='fa fa-star fs-30'
															style={{ marginTop: '-10px', color: this.props.iconColor ? this.props.iconColor : '#053359' }}
															aria-hidden='true'
														>
														</i>
														<span className='shoping-cart-number'
															style={{ marginTop: '-22px', backgroundColor: this.props.secondaryColor ? this.props.secondaryColor : '' }}
														>
															{
																bookmarkCount = getStorageItem('bookmarks') ? getStorageItem('bookmarks').split(',').length : 0

															}
															{/* {bookmarkCount} */}
														</span>
													</Link>
												</li>
											}
										</React.Fragment>
										:
										<React.Fragment>
											<OverlayTrigger placement='bottom' trigger='click' ref='dataLayerOverlay101' overlay={
												<Tooltip id='tooltip'>
													<div className='form-group'
														style={{
															display: 'flex'
															, flexDirection: 'column'
															, padding: 10
															, fontSize: 16
															, fontWeight: 'bold'
															, color: 'rgba(3, 61, 245, 1.0)'
															, textTransform: 'none'
														}}
													>
														<button
															type='button'
															className='close'
															style={{ position: 'absolute', right: 4, top: 4 }}
															onClick={() => (this.refs.dataLayerOverlay101 as OverlayTrigger).hide()}
														>
															<span aria-hidden='true'>×</span>
															<span className='sr-only'>Close</span>
														</button>
														<div>
															{
																'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
															}
															<a href={'/plans-and-pricing'}
																style={{
																	fontSize: 16
																	, fontWeight: 'bold'
																	, color: 'rgba(3, 61, 245, 1.0)'
																	, textDecoration: 'underline'
																}}
															>
																<span
																	style={{
																		fontSize: 16
																		, fontWeight: 'bold'
																		, color: 'rgba(3, 61, 245, 1.0)'
																		, textDecoration: 'underline'
																	}}
																>
																	Plans and Pricing
																</span>
															</a>
														</div>
													</div>
												</Tooltip>
											}>
												{
													<li className='pull-right'
														style={{
															display: 'inline-block'
															, padding: '6.5px 15px 22.5px 15px'
															, position: 'relative'
															, opacity: 0.5
															, marginRight: '12px'


														}}
													>
														<i className='fa fa-star fs-30'
															style={{ marginTop: 5, color: this.props.iconColor ? this.props.iconColor : '#053359' }}
															aria-hidden='true'
														>
														</i>
														<span className='shoping-cart-number'
															style={{ marginTop: '-10px', backgroundColor: this.props.secondaryColor ? this.props.secondaryColor : '' }}
														>
															{
																bookmarkCount = getStorageItem('bookmarks') ? getStorageItem('bookmarks').split(',').length : 0

															}
															{/* {bookmarkCount} */}
														</span>
													</li>
												}
											</OverlayTrigger>
										</React.Fragment>
								):
								null
							}

							<Link className='img-responsive' style={{ height: 25, marginTop: 10, marginLeft: 15 }} to={{ pathname: '/' }}><img style={{ height: '100%' }} src={this.props.logo ? this.props.logo : logo} alt='logo' /></Link>
						</div>
						<div id='navbar' className='navbar-collapse collapse background-white nav-collapse-custom' aria-expanded='false'>
							<ul className='nav navbar-nav' style={{ width: '100%' }}>

								<li className=''><Link to={{ pathname: '/' }} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>Home</Link></li>
								<li className=''><Link to={{ pathname: '/about-us' }} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>About Us</Link></li>

								<li className='text-black'>
									{/* <div style={{paddingLeft:0}} className="dropdown text-black font-size-17 font-weight-500 font-family-montserrat hover">
										<a style={{ color: '#142149',paddingLeft:0 }} className="dropdown-toggle" type="button" data-toggle="dropdown">Solutions
											<span className="caret"></span></a>
										<ul className="dropdown-menu">
											<li><a style={{ color: '#142149' }} className="dropdown-item" href="/searcher">For Searchers</a></li>
											<li><a style={{ color: '#142149' }} className="dropdown-item" href="/lister">For Listers</a></li>
											<li><a style={{ color: '#142149' }} className="dropdown-item" href="/private-clients-info">Private Label</a></li>
										</ul>
									</div> */}
									<Link style={{ color: '#142149' }} className="dropdown-toggle dropdown text-black font-size-17 font-weight-500 font-family-montserrat" type="button" data-toggle="dropdown">Solutions
										<span className="caret"></span></Link>
									<ul className="dropdown-menu">
										<li><a style={{ color: '#142149' }} className="dropdown-item" href="/searcher">For Searchers</a></li>
										<li><a style={{ color: '#142149' }} className="dropdown-item" href="/lister">For Listers</a></li>
										<li><a style={{ color: '#142149' }} className="dropdown-item" href="/private-clients-info">Private Label</a></li>
									</ul>
								</li>
								<li><Link to={{ pathname: '/plans-and-pricing' }} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>Plans And Pricing</Link></li>
								<li><Link to={{ pathname: '/view-listings' }} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>View Listings</Link></li>
								<li><Link to={{ pathname: '/create-listings' }} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>Create Listings</Link></li>
								<li><Link onClick={() => window.location.href = "https://insights.industrialnavigator.com"} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>Insights Blog</Link></li>
								<li style={{ margin: 0 }} className='row'>
									<React.Fragment>
										<li className='' style={{ display: 'inline-block', padding: '15px 15px 15px 0px', float: "left" }}><a style={{ padding: 10, color: 'black' }} href="https://twitter.com/IndNavigator" target='_blank' className="nav-item"><i className="fa fs-30 fa-twitter"></i></a></li>
										<li className='' style={{ display: 'inline-block', padding: '15px 15px 15px 0px', float: "left" }}><a style={{ padding: 10, color: 'black' }} href="https://www.linkedin.com/showcase/industrial-navigator%C2%AE/?viewAsMember=true" target='_blank' className="nav-item"><i className="fa fs-30 fa-linkedin"></i></a></li>
									</React.Fragment>
								</li>
								{/* <li><a role='button' className='text-black font-size-17 font-weight-500 font-family-montserrat hover' onClick={this.handleShow.bind(this, '/view-listings', '', '')}>Browse</a></li>

								<li><a role='button' className='text-black font-size-17 font-weight-500 font-family-montserrat hover' onClick={this.handleShow.bind(this, '/create-listings', '', '')}>Create</a></li> */}

								{isLoggedIn ? (
									<React.Fragment>

										<li className='' style={{ display: 'inline-block', padding: '15px 15px 15px 15px', float: "left" }}><a style={{ padding: 10 }} className="nav-item login iq-button iq-btn-outline iq-btn-round" onClick={this.logoutUser}>Logout</a>
										</li>
										<li className='' style={{ display: 'inline-block', padding: '15px 15px 15px 15px', float: "left" }}><a style={{ padding: 10 }} className="nav-item login iq-button iq-btn-outline iq-btn-round" onClick={this.handleMyListing}>
											{/* {
												UserProfile.getUserType('user_type') === 'admin'
													?
													<div >GO TO ADMIN</div>
													:
													<div >MY ADMIN</div>
											} */}
											<div >Admin</div>
										</a></li>
									</React.Fragment>
								) : (
									<React.Fragment>
										<li className='' style={{ display: 'inline-block', padding: '15px 15px 15px 15px', float: "left" }}><a style={{ padding: 10, color: 'black' }} className="nav-item login iq-button iq-btn-outline iq-btn-round" onClick={this.handleShow.bind(this, '', '', '')}>Login</a></li>
										<li className='' style={{ display: 'inline-block', padding: '15px 15px 15px 15px', float: "left" }}><a style={{ padding: 9, backgroundColor: 'rgb(0, 123, 252)', }} className="nav-item  iq-button default iq-btn-round" onClick={this.showRegistrationModal}><div style={{ padding: 3, color: 'white' }}>Sign Up</div></a></li>
									</React.Fragment>
								)}

							</ul>
						</div>
					</div>
				</nav>
				<nav className='navbar navbar-default hidden-xs-custom-mid margin-bottom-0' style={{ zIndex: 999, maxHeight: 75, background: 'white', opacity: 1, boxShadow: '0px 0px 30px 0px rgb(0 0 0 / 10%)', left: 0, right: 0, top: -1, fontFamily: 'Roboto, sans-serif' }}>
					<div className=''>
						<div className='col-lg-12 col-md-12'>
							<div className='col-lg-2 col-md-2 pull-left' style={{ paddingTop: 10 }}>
								{
									this.props.logo ?
										<div className='' style={{ width: 78, padding: 4 }}>
											<Link className='img-responsive' to={{ pathname: '/' }}><img src={this.props.logo} alt='logo' /></Link>
										</div>
										:
										<div className='' style={{ width: 170, paddingTop: 10 }}>
											<Link className='img-responsive' to={{ pathname: '/' }}><img src={logo} alt='logo' /></Link>
										</div>
								}
							</div>
							<div className='col-lg-7 col-md-7 text-center' style={{ padding: 0 }}>
								<ul className='' style={{ marginBottom: 0 }}>
									<li style={{ display: 'inline-block', padding: '2.5px 5px 22.5px 10px' }}><Link to={{ pathname: '/about-us' }} style={{ color: 'black', fontSize: 15 }} className=' nav-item'>About Us</Link></li>
									<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 10px' }}>
										{/* <Link to={{ pathname: '/about-us' }} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>About Us</Link> */}
										<div style={{ color: 'black', fontSize: 15 }} className='dropdown nav-item'>
											<Link style={{ color: '#142149', fontSize: 15 }} className="dropdown-toggle" type="button" data-toggle="dropdown">Solutions
												<span className="caret"></span></Link>
											<ul className="dropdown-menu">
												<li><a style={{ color: '#142149' }} className="dropdown-item" href="/searcher">For Searchers</a></li>
												<li><a style={{ color: '#142149' }} className="dropdown-item" href="/lister">For Listers</a></li>
												<li><a style={{ color: '#142149' }} className="dropdown-item" href="/private-clients-info">Private Label</a></li>
											</ul>
										</div>
									</li>
									<li className='hide-header-element' style={{ display: 'inline-block', padding: '22.5px 10px 22.5px 10px' }}><Link to={{ pathname: '/plans-and-pricing' }} style={{ color: 'black', fontSize: 15 }} className=' nav-item'>Plans and Pricing</Link></li>
									<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 10px' }}><Link to={{ pathname: '/view-listings' }} style={{ color: 'black', fontSize: 15 }} className=' nav-item'>View Listings</Link></li>
									<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 10px' }}><Link to={{ pathname: '/create-listings' }} style={{ color: 'black', fontSize: 15 }} className=' nav-item'>Create Listings</Link></li>
									<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 10px' }}><a href='https://insights.industrialnavigator.com/' style={{ color: 'black', fontSize: 15 }} className=' nav-item'>Insights Blog</a></li>

									<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 10px', fontSize: 14 }}><a style={{ color: '#142149' }} href="https://twitter.com/IndNavigator" target='_blank' className='nav-item'><i className="fa fs-20 fa-twitter"></i></a></li>
									<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 10px', fontSize: 14 }}><a style={{ color: '#142149' }} href="https://www.linkedin.com/showcase/industrial-navigator%C2%AE/?viewAsMember=true" target='_blank' className='nav-item'><i className="fa fs-20 fa-linkedin"></i></a></li>


								</ul>
							</div>
							<div className='col-lg-3 col-md-3 pull-right' style={{ paddingTop: 20 }}>
								<ul className='float-right' style={{ marginBottom: 0, float: 'right' }}>
									{
										//(!this.props || !this.props.data || this.props.data.location.pathname === '/create' || this.props.data.location.pathname === '/plans-and-pricing' || this.props.data.location.pathname === '/about-us' || this.props.data.location.pathname === '/checkout-subscription' || this.props.data.location.pathname === '/checkout-upgrade' || this.props.data.location.pathname === '/upgrade-success' || this.props.data.location.pathname === '/privacy-policy' || this.props.data.location.pathname === '/terms-and-conditions'|| this.props.data.location.pathname === '/places-:id') ? null : (
											(!this.props || !this.props.data || this.props.data.location.pathname == '/view-listings' || this.props.data.location.pathname == '/view-listings-real-estate-bd' || this.props.data.location.pathname == '/view-listings-site-selectors') ?  (
											flagFavorites ?
												<React.Fragment>
													{
														<li className='' style={{ display: 'inline-block', padding: '5px 10px 22.5px 5px', position: 'relative', top: 8 }}>
															<Link onClick={this.showModalPrivateFav}>
																<i className='fa fa-star fs-30'
																	style={{ color: this.props.iconColor ? this.props.iconColor : '#053359' }}
																	aria-hidden='true'
																>
																</i>
																<span className='shoping-cart-number'
																	style={{ backgroundColor: this.props.secondaryColor ? this.props.secondaryColor : '', right: 2, top: -5 }}
																>
																	{
																		bookmarkCount = getStorageItem('bookmarks') ? getStorageItem('bookmarks').split(',').length : 0

																	}
																	{/* {bookmarkCount} */}
																</span>
															</Link>
														</li>
													}
												</React.Fragment>
												:
												<React.Fragment>
													<OverlayTrigger placement='bottom' trigger='click' ref='dataLayerOverlay101' overlay={
														<Tooltip id='tooltip'>
															<div className='form-group'
																style={{
																	display: 'flex'
																	, flexDirection: 'column'
																	, padding: 10
																	, fontSize: 16
																	, fontWeight: 'bold'
																	, color: 'rgba(3, 61, 245, 1.0)'
																	, textTransform: 'none'
																}}
															>
																<button
																	type='button'
																	className='close'
																	style={{ position: 'absolute', right: 4, top: 4 }}
																	onClick={() => (this.refs.dataLayerOverlay101 as OverlayTrigger).hide()}
																>
																	<span className='pull-right' aria-hidden='true'>×</span>
																	<span className='sr-only'>Close</span>
																</button>
																<div>
																	{
																		'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
																	}
																	<a href={'/plans-and-pricing'}
																		style={{
																			fontSize: 16
																			, fontWeight: 'bold'
																			, color: 'rgba(3, 61, 245, 1.0)'
																			, textDecoration: 'underline'
																		}}
																	>
																		<span
																			style={{
																				fontSize: 16
																				, fontWeight: 'bold'
																				, color: 'rgba(3, 61, 245, 1.0)'
																				, textDecoration: 'underline'
																			}}
																		>
																			Plans and Pricing
																		</span>
																	</a>
																</div>
															</div>
														</Tooltip>
													}>
														{
															<li className=''
																style={{
																	display: 'inline-block'
																	, padding: '2.5px 5px 22.5px 5px'
																	, position: 'relative'
																	, opacity: 0.5
																	, top: 8
																}}
															>
																<i className='fa fa-star fs-30'
																	style={{ color: this.props.iconColor ? this.props.iconColor : '#053359' }}
																	aria-hidden='true'
																>
																</i>
																<span className='shoping-cart-number'
																	style={{ backgroundColor: this.props.secondaryColor ? this.props.secondaryColor : '', right: -3, top: '-7px' }}
																>
																	{
																		bookmarkCount = getStorageItem('bookmarks') ? getStorageItem('bookmarks').split(',').length : 0

																	}
																	{/* {bookmarkCount} */}
																</span>
															</li>
														}
													</OverlayTrigger>
												</React.Fragment>
										)
										: null
									}
									{isLoggedIn ? (
										<React.Fragment>

											{/* <li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}><Button className='font-Gotham-Pro-Medium' style={{ border: '2px solid rgba(255,255,255,0.75)', borderRadius: '0px !important', background: 'transparent', color: 'white', height: 50, width: 150 }} onClick={this.logoutUser}>LOGOUT</Button></li> */}
											<li style={{ display: 'inline-block', padding: '5px 5px 22.5px 5px', fontSize: 15 }} className="d-inline"><a className="nav-item login iq-button iq-btn-outline iq-btn-round" onClick={this.handleMyListing}>
												{/* <li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}><Button className='font-Gotham-Pro-Medium' style={{ border: '2px solid rgba(255,255,255,0.75)', borderRadius: '0px !important', background: 'transparent', color: 'white', height: 50, width: 150 }} onClick={this.handleMyListing}> */}
												{
													UserProfile.getUserType('user_type') === 'admin'
														?
														<div >Admin</div>
														:
														<div >Admin</div>
												}
											</a></li>
											<li style={{ display: 'inline-block', padding: '5px 5px 22.5px 5px', fontSize: 15 }} className="d-inline"><a style={{ border: '2px solid rgb(0, 157, 245)' }} className="nav-item signup iq-button default iq-btn-round" onClick={this.logoutUser}>Logout</a></li>


										</React.Fragment>
									) : (
										<React.Fragment>
											{/* <li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}><Button className='font-Gotham-Pro-Medium' style={{ border: '2px solid rgba(255,255,255,0.75)', borderRadius: '0px !important', background: 'transparent', color: 'white', height: 50, width: 150 }} onClick={this.handleShow.bind(this, '', '', '')}>Login / Sign Up</Button></li> */}
											<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 5px', fontSize: 16 }} className="d-inline"><a className="float-right nav-item login iq-button iq-btn-outline iq-btn-round" onClick={this.handleShow.bind(this, '', '', '')}>Login</a></li>
											<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 5px', fontSize: 16 }} className="d-inline"><a style={{ border: '2px solid rgb(0, 157, 245)' }} className="nav-item signup iq-button default iq-btn-round" onClick={this.showRegistrationModal}>Sign Up</a></li>

										</React.Fragment>
									)}
								</ul>
							</div>




						</div>

					</div>
				</nav>

				<nav className='navbar navbar-default hidden-xs-custom margin-bottom-0' style={{ paddingLeft: 30, maxHeight: 75, zIndex: 999, paddingRight: 30, background: 'white', opacity: 1, boxShadow: '0px 0px 30px 0px rgb(0 0 0 / 10%)', left: 0, right: 0, top: -1, fontFamily: 'Roboto, sans-serif' }}>
					<div className='col-lg-12 col-md-12'>
						<div style={{ paddingLeft: 0 }} className='col-lg-2 col-md-2 pull-left' >
							{
								this.props.logo ?
									// <div className='pull-left' style={{ width: 100, padding: 4 }}>
									// 	<Link className='img-responsive' to={{ pathname: '/' }}><img src={this.props.logo} alt='logo' /></Link>
									// </div>
									<div className='col-lg-12 col-md-2 pull-left' style={{ paddingTop: 10, paddingLeft: 0 }}>
										<Link className='img-responsive' style={{ height: 30, paddingLeft: -10, marginTop: 10 }} to={{ pathname: '/' }}><img style={{ height: '100%' }} src={this.props.logo} alt='logo' /></Link>
									</div>
									:
									// <div className='pull-left' style={{ width: 200, paddingTop: 18 }}>
									// 	<Link className='img-responsive' to={{ pathname: '/' }}><img src={logo} alt='logo' /></Link>
									// </div>
									<div className='col-lg-12 col-md-2 pull-left' style={{ paddingTop: 10, paddingLeft: 0 }}>
										<Link className='img-responsive' style={{ height: 30, paddingLeft: -10, marginTop: 10 }} to={{ pathname: '/' }}><img style={{ height: '100%' }} src={logo} alt='logo' /></Link>
									</div>
							}
						</div>
						<div className='col-lg-7 col-md-7 text-center' style={{}}>
							<ul style={{ marginBottom: 0, marginLeft: 5, paddingLeft: 0 }}>
								<li style={{ display: 'inline-block', padding: '22.5px 7px 22.5px 7px', fontSize: 15 }}><Link to={{ pathname: '/about-us' }} style={{ color: 'black' }} className='nav-item'>About Us</Link></li>
								<li style={{ display: 'inline-block', padding: '22.5px 7px 22.5px 7px', fontSize: 15 }}>
									{/* <Link to={{ pathname: '/about-us' }} className='text-black font-size-17 font-weight-500 font-family-montserrat hover'>About Us</Link> */}
									<div className="dropdown nav-item">
										<Link style={{ color: 'black', fontSize: 15 }} className="dropdown-toggle" type="button" data-toggle="dropdown">Solutions
											<span className="caret"></span></Link>
										<ul className="dropdown-menu">
											<li><a style={{ color: '#142149' }} className="dropdown-item" href="/searcher">For Searchers</a></li>
											<li><a style={{ color: '#142149' }} className="dropdown-item" href="/lister">For Listers</a></li>
											<li><a style={{ color: '#142149' }} className="dropdown-item" href="/private-clients-info">Private Label</a></li>
										</ul>
									</div>
								</li>
								<li style={{ display: 'inline-block', padding: '22.5px 7px 22.5px 7px', fontSize: 15 }}><Link to={{ pathname: '/plans-and-pricing' }} style={{ color: 'black' }} className='nav-item'>Plans and Pricing</Link></li>
								<li style={{ display: 'inline-block', padding: '22.5px 7px 22.5px 7px', fontSize: 15 }}><Link to={{ pathname: '/view-listings' }} style={{ color: 'black' }} className='nav-item'>View Listings</Link></li>
								<li style={{ display: 'inline-block', padding: '22.5px 7px 22.5px 7px', fontSize: 15 }}><Link to={{ pathname: '/create-listings' }} style={{ color: 'black' }} className='nav-item'>Create Listings</Link></li>
								<li style={{ display: 'inline-block', padding: '22.5px 7px 22.5px 7px', fontSize: 15 }}><a href='https://insights.industrialnavigator.com/' style={{ color: 'black' }} className='nav-item'>Insights Blog</a></li>
								<li style={{ display: 'inline-block', padding: '22.5px 10px 22.5px 10px', fontSize: 14 }}><a style={{ color: '#142149' }} href="https://twitter.com/IndNavigator" target='_blank' className='nav-item'><i className="fa fs-20 fa-twitter"></i></a></li>
								<li style={{ display: 'inline-block', padding: '22.5px 10px 22.5px 10px', fontSize: 14 }}><a style={{ color: '#142149' }} href="https://www.linkedin.com/showcase/industrial-navigator%C2%AE/?viewAsMember=true" target='_blank' className='nav-item'><i className="fa fs-20 fa-linkedin"></i></a></li>

								{/* <li style={{ display: 'inline-block', padding: '22.5px 15px 22.5px 15px' }}><span role='button' className='text-black font-size-17 font-weight-500 font-family-montserrat hover' onClick={this.handleShow.bind(this, '/view-listings', '', '')}>Browse</span></li>
								<li style={{ display: 'inline-block', padding: '22.5px 15px 22.5px 15px' }}><span role='button' className='text-black font-size-17 font-weight-500 font-family-montserrat hover' onClick={this.handleShow.bind(this, '/create-listings', '', '')}>Create</span></li> */}
							</ul>


						</div>
						<div style={{ paddingRight: 0 }} className='col-lg-3 col-md-3 pull-right' >
							<ul style={{ marginTop: 25, paddingRight: 0 }} className='pull-right margin-bottom-0'>
								{
									//(!this.props || !this.props.data || this.props.data.location.pathname === '/create-listings' || this.props.data.location.pathname === '/plans-and-pricing' || this.props.data.location.pathname === '/about-us' || this.props.data.location.pathname === '/checkout-subscription' || this.props.data.location.pathname === '/checkout-upgrade' || this.props.data.location.pathname === '/upgrade-success' || this.props.data.location.pathname === '/privacy-policy' || this.props.data.location.pathname === '/terms-and-conditions' || this.props.data.location.pathname === '/places-:id') ? null : (
									(!this.props || !this.props.data || this.props.data.location.pathname == '/view-listings' || this.props.data.location.pathname == '/view-listings-real-estate-bd' || this.props.data.location.pathname == '/view-listings-site-selectors') ?  (
										flagFavorites ?
											<React.Fragment>
												{
													<li  style={{ display: 'inline-block', padding: '2.5px 15px 22.5px 15px', position: 'relative', top: 8 }}>
														<Link onClick={this.showModalPrivateFav}>
															<i className='fa fa-star fs-30 bookmark_icon'
																style={{ color: this.props.iconColor ? this.props.iconColor : '#053359' }}
																aria-hidden='true'
															>
															</i>
															<span className='shoping-cart-number'
																style={{ backgroundColor: this.props.secondaryColor ? this.props.secondaryColor : '', top: '-7px' }}
															>
																{
																	bookmarkCount = getStorageItem('bookmarks') ? getStorageItem('bookmarks').split(',').length : 0

																}
																{/* {bookmarkCount} */}
															</span>
														</Link>
													</li>
												}
											</React.Fragment>
											:
											<React.Fragment>
												<OverlayTrigger placement='bottom' trigger='click' ref='dataLayerOverlay101' overlay={
													<Tooltip id='tooltip'>
														<div className='form-group'
															style={{
																display: 'flex'
																, flexDirection: 'column'
																, padding: 10
																, fontSize: 16
																, fontWeight: 'bold'
																, color: 'rgba(3, 61, 245, 1.0)'
																, textTransform: 'none'
															}}
														>
															<button
																type='button'
																className='close'
																style={{ position: 'absolute', right: 4, top: 4 }}
																onClick={() => (this.refs.dataLayerOverlay101 as OverlayTrigger).hide()}
															>
																<span aria-hidden='true'>×</span>
																<span className='sr-only'>Close</span>
															</button>
															<div>
																{
																	'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
																}
																<a href={'/plans-and-pricing'}
																	style={{
																		fontSize: 16
																		, fontWeight: 'bold'
																		, color: 'rgba(3, 61, 245, 1.0)'
																		, textDecoration: 'underline'
																	}}
																>
																	<span
																		style={{
																			fontSize: 16
																			, fontWeight: 'bold'
																			, color: 'rgba(3, 61, 245, 1.0)'
																			, textDecoration: 'underline'
																		}}
																	>
																		Plans and Pricing
																	</span>
																</a>
															</div>
														</div>
													</Tooltip>
												}>
													{
														<li 
															style={{
																display: 'inline-block'
																, padding: '2.5px 15px 22.5px 15px'
																, position: 'relative'
																, opacity: 0.5
																, top: 8
															}}
														>
															<i className='fa fa-star fs-30 bookmark_icon'
																style={{ color: this.props.iconColor ? this.props.iconColor : '#053359' }}
																aria-hidden='true'
															>
															</i>
															<span className='shoping-cart-number'
																style={{ backgroundColor: this.props.secondaryColor ? this.props.secondaryColor : '', top: '-7px' }}
															>
																{
																	bookmarkCount = getStorageItem('bookmarks') ? getStorageItem('bookmarks').split(',').length : 0

																}
																{/* {bookmarkCount} */}
															</span>
														</li>
													}
												</OverlayTrigger>
											</React.Fragment>
									)
									:
									null 
								}
								{isLoggedIn ? (
									<React.Fragment>

										<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 5px', fontSize: 15 }} className="d-inline"><a className="nav-item signup iq-button iq-btn-outline iq-btn-round" onClick={this.handleMyListing}>
											{/* <li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}><Button className='font-Gotham-Pro-Medium' style={{ border: '2px solid rgba(255,255,255,0.75)', borderRadius: '0px !important', background: 'transparent', color: 'white', height: 50, width: 150 }} onClick={this.handleMyListing}> */}
											{
												UserProfile.getUserType('user_type') === 'admin'
													?
													<div >Admin</div>
													:
													<div >Admin</div>
											}
										</a></li>
										<li style={{ display: 'inline-block', padding: '22.5px 5px 22.5px 5px', fontSize: 15 }} className="d-inline"><a style={{ border: '2px solid rgb(0, 157, 245)' }} className="nav-item signup iq-button default iq-btn-round" onClick={this.logoutUser}>Logout</a></li>
										{/* <li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}>
											<Button variant='outline-success btn btn-default' className='height-40 text-uppercase border-radius-25-imp' onClick={this.logoutUser}
												style={{
													backgroundColor: this.props.btnBackgroundColor ? this.props.btnBackgroundColor : '#053359'
												}}
											>
												Logout
											</Button>
										</li>
										<li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}>
											<Button variant='outline-success btn btn-default' className='height-40 text-uppercase border-radius-25-imp'
												style={{
													backgroundColor: this.props.btnBackgroundColor ? this.props.btnBackgroundColor : '#053359'
												}} onClick={this.handleMyListing}
											>
												{
													UserProfile.getUserType('user_type') === 'admin'
														?
														<div >Go to Admin</div>
														:
														<div >My Admin</div>
												}
											</Button>
										</li> */}
									</React.Fragment>
								) : (
									<React.Fragment>
										{/* <li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}><Button className='font-Gotham-Pro-Medium' style={{ border: '2px solid rgba(255,255,255,0.75)', borderRadius: '0px !important', background: 'transparent', color: 'white', height: 50, width: 150 }} onClick={this.handleShow.bind(this, '', '', '')}>Login / Sign Up</Button></li> */}
										<li style={{ display: 'inline-block', padding: '22.5px 10px 22.5px 10px', fontSize: 16 }} className="d-inline"><a className="float-right nav-item login iq-button iq-btn-outline iq-btn-round" onClick={this.handleShow.bind(this, '', '', '')}>Login</a></li>
										<li style={{ display: 'inline-block', padding: '22.5px 0px 22.5px 10px', fontSize: 16 }} className="d-inline"><a style={{ border: '2px solid rgb(0, 157, 245)' }} className="nav-item signup iq-button default iq-btn-round" onClick={this.showRegistrationModal}>Sign Up</a></li>

									</React.Fragment>
									// <React.Fragment>
									// 	<li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}>
									// 		<Button variant='outline-success mr-sm-2 btn btn-default' className='height-40 text-uppercase border-radius-25-imp custom-button-header' onClick={this.showRegistrationModal}
									// 			style={{
									// 				backgroundColor: this.props.btnBackgroundColor ? this.props.btnBackgroundColor : '#053359'
									// 			}}
									// 		>
									// 			Sign Up
									// 		</Button>
									// 	</li>
									// 	<li className='pull-right' style={{ display: 'inline-block', padding: '15px 15px 15px 15px' }}>
									// 		<Button variant='outline-success mr-sm-2 btn btn-default' className='height-40 text-uppercase border-radius-25-imp custom-button-header' onClick={this.handleShow.bind(this, '', '', '')}
									// 			style={{
									// 				backgroundColor: this.props.btnBackgroundColor ? this.props.btnBackgroundColor : '#053359'
									// 			}}
									// 		>
									// 			Login
									// 		</Button>
									// 	</li>
									// </React.Fragment>
								)}
								{/*
								{
									(!this.props || !this.props.data) ? null : (
										this.props.data.location.pathname == '/create' || this.props.data.location.pathname == '/checkout' ?
											<li className='pull-right' style={{ display: 'inline-block', padding: '22.5px 15px 22.5px 15px', position: 'relative' }}>
												<span><i className='fa fa-shopping-cart fs-30' aria-hidden='true'></i>
													<span className='shoping-cart-number'>{this.state.shoppingCartCount}</span>
												</span>
											</li>
											: ''
									)
								}
								*/}

							</ul>
						</div>

					</div>
				</nav>

				<Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5' }}>
						<div>
							<div style={{ padding: '3rem' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto' }}>
										<img src={logo} />
									</div>
									<h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Forgot Password
									</h2>
									<form style={{ width: '95%', marginLeft: '2%', marginRight: '2%', paddingBottom: 10 }} className='nobottommargin'>
										<div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
										<div id='fp_success' className='alert alert-success' style={{ display: 'none' }}> Password link should be sent to your email address.</div>
										{/* <div className='input-group margin-top-15 col-lg-12'>
											<input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
										</div> */}
										<input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />

										<div className='col_full center padding-top-20'>
											<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal show={this.state.showNotificationModal} onHide={this.handleNotificationModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGIconNotification />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none', marginTop: 20 }}>
								Notification Update
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								{this.state.notification_data}
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleNotificationModal}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>
						<div>
							<div style={{ padding: '3rem', backgroundColor: 'white' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
										<img src={logo} />
									</div>
									<form id='slider-subscribe-form' style={{ paddingBottom: '10px' }} className='nobottommargin'>
										<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Enter email address and password to login</div>
										<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
										<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
										<div style={{ width: '100%' }} className='input-group margin-top-15 col-lg-12'>
											<input type='email' name='login_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setLoginEmail} value={this.state.login_email} />
										</div>
										{this.loginValidator.message('email', this.state.login_email, 'required')}
										<div style={{ width: '100%' }} className='input-group margin-top-15 col-lg-12'>
											<input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword} value={this.state.login_password} />
										</div>
										{this.loginValidator.message('password', this.state.login_password, 'required')}
										<div className='col_full center padding-top-20'>
											<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin}>Sign In</button>
										</div>
										<div className='col_full center padding-top-20'>
											<span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
										</div>
									</form>
									<div style={{ paddingTop: '1rem' }}>
										<small>
											<div className='text-center text-black font-weight-bold'>
												Don't have an account? <span className='cursor-pointer color-blue' onClick={this.showRegistrationModal}>Sign Up</span></div>
										</small>
									</div>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Login Successful
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showLogoutSuccessModal} onHide={this.handleLogoutSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Logout Successful
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								You are logged out of the system
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLogoutSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName='custom-modal-step3' show={this.state.showRegistrationModal} onHide={this.handleRegistrationModal} style={{ opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
						<div>
							<div>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '30px auto' }}>
										<img src={logo} />
									</div>
									{/* <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Welcome to Industrial Navigator!
									</h2> */}
									<MultiStepRegistration />
									{/* <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
										<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Please enter your details below to register for Industrial Navigator.</div>

										<div id='regi_errors' className='margin-top-15'></div>

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
										</div>
										{this.validator.message('first name', this.state.fname, 'required')}

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
										</div>
										{this.validator.message('last name', this.state.lname, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
										</div>
										{this.validator.message('company name', this.state.companyName, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail.bind(this)} value={this.state.email} />
										</div>
										{this.validator.message('email', this.state.email, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
										</div>
										{this.validator.message('password', this.state.password, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
										</div>
										{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
										<ReCAPTCHA
											ref={recaptchaRef}
											// size='invisible'
											className='margin-top-15'
											onChange={this.onRecaptchaChange.bind(this)}
											sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
										/>
										{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
										<div className='col_full center padding-top-20'>
											<button className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
										</div>
									</form> */}
									{/* <div style={{ paddingTop: '1rem' }}>
										<small>
											<div className='text-center text-black font-weight-bold'>
												Already have an account? <span className='cursor-pointer color-blue' onClick={this.checkIfLoginPage}>Sign In</span></div>
										</small>
									</div> */}
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Registration Successful
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Thanks for subscribing to Industrial Navigator, a unique web tool that simplifies the process of finding and marketing industrial properties and logistics assets.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showOTPModal} onHide={this.handleOTPModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5' }}>
						<div>
							<div style={{ padding: '3rem' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto' }}>
										<img src={logo} />
									</div>
									<h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Verify OTP
									</h2>
									<form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
										<div id='otp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
										<div id='otp_success' className='alert alert-success' style={{ display: 'none' }}> Password link should be sent to your email address.</div>
										<InputBoxComponent type='number' min='0' name='otp' onHandleInputChange={this.handleInputChange} />
										{this.OTPValidator.message('otp', this.state.otp, 'required')}
									</form>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPVerification}>Verify</button>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal dialogClassName='custom-modal-step3' show={this.state.bookmarksModal} onHide={this.hideprivateFavModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '0rem', marginBottom: '0px' }}>
						<div className='reduce-padding-small' style={{ paddingTop: '1rem', width: '99%', paddingLeft: '1rem', paddingRight: '1rem' }}>

							<Bookmarkcomponent />

						</div>

					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideprivateFavModal}>Close</button>
					</Modal.Footer>
				</Modal>

				{/* <Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>

						<div>
							<div style={{ padding: '1rem', backgroundColor: 'white' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
										<img src={logo} />
									</div>
									<Tabs defaultActiveKey="Login"  >
										<Tab eventKey="Login" title="Login" style={{ color: "black" }}>
											<>
												<form id='slider-subscribe-form' style={{ paddingBottom: '10px' }} className='nobottommargin'>
													<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747', marginTop: 20 }}>Enter email address and password to login</div>
													<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
													<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
													<div style={{ width: '100%' }} className='input-group margin-top-15 col-lg-12'>
														<input type='email' name='login_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setLoginEmail} value={this.state.login_email} />
													</div>
													{this.loginValidator.message('email', this.state.login_email, 'required')}
													<div style={{ width: '100%' }} className='input-group margin-top-15 col-lg-12'>
														<input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword} value={this.state.login_password} />
													</div>
													{this.loginValidator.message('password', this.state.login_password, 'required')}
													<div className='col_full center padding-top-20'>
														<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin}>Sign In</button>
													</div>
													<div className='col_full center padding-top-20'>
														<span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
													</div>
												</form>
											</>
										</Tab>
										<Tab eventKey="Sign Up" title="Sign Up" style={{ color: "black" }}>
											<>
												<div>
													<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
														<form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
															<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Please enter your details below to register for Industrial Navigator.</div>

															<div id='regi_errors' className='margin-top-15'></div>

															<div className='input-group margin-top-15 col-lg-12'>
																<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
															</div>
															{this.validator.message('first name', this.state.fname, 'required')}

															<div className='input-group margin-top-15 col-lg-12'>
																<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
															</div>
															{this.validator.message('last name', this.state.lname, 'required')}
															<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
															</div>
															{this.validator.message('company name', this.state.companyName, 'required')}
															<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail.bind(this)} value={this.state.email} />
															</div>
															{this.validator.message('email', this.state.email, 'required')}
															<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
															</div>
															{this.validator.message('password', this.state.password, 'required')}
															<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
															</div>
															{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
															<ReCAPTCHA
																ref={recaptchaRef}
																// size='invisible'
																className='margin-top-15'
																onChange={this.onRecaptchaChange.bind(this)}
																sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
															/>
															{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
															<div className='col_full center padding-top-20'>
																<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
															</div>
														</form>
													</div>
												</div>
											</>
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal> */}
			</React.Fragment>
		)
	}


}

export default Header;

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)
const SVGIconNotification = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)


