import React, { Component, useState, useEffect } from 'react';
//import  firebase  from 'firebase/compat/app';
import * as firebase from 'firebase/app'
import 'firebase/analytics';
import "firebase/performance";
const firebaseConfig = {
  apiKey: "AIzaSyCHEOJocvRr2smTLqYQns5XMjiI8ItkkSY",
  authDomain: "industrial-navigator-ad442.firebaseapp.com",
  projectId: "industrial-navigator-ad442",
  storageBucket: "industrial-navigator-ad442.appspot.com",
  messagingSenderId: "405498242063",
  appId: "1:405498242063:web:6db573752c7ce661acef7a",
  measurementId: "G-4TJLPHWP6V"
};


if (typeof window !== 'undefined' && !firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  console.log("firebase initialization successfully")
  if ('measurementId' in firebaseConfig) {
    const analytics = firebase.analytics();

    //console.log("firebase analytics initialization ",analytics)
    firebase.analytics().logEvent('notification_received');
    // Initialize Performance Monitoring and get a reference to the service
    const perf = firebase.performance();
  }
}



export default firebase;
