import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import ReCAPTCHA from 'react-google-recaptcha';
const recaptchaRef = React.createRef();

class Step2 extends Component {

	constructor(props) {
		super(props);

		this.state = {
			fname: '',
			lname: '',
			companyName: '',
			password: '',
			confirm_password: '',
			email: '',
			// reacatpcha_value: null,
		}
	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
	}

	handleInputChange  = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	}

	// onRecaptchaChange(value) {
	// 	console.log('Captcha value: ', value);
	// 	this.setState({ reacatpcha_value: value });
	// }

	render() {

		return (
			<form
            				id='questionaire-form'
            				style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }}
            				className='nobottommargin'
            			>
            				<h2>Step 2</h2>
            				<div
            					className='text-center color-black font-size-18 margin-bottom-20'
            					style={{ color: '#474747' }}
            				>
            					Please answer questions here.
            				</div>

            				<div id='regi_errors' className='margin-top-15'></div>

            				<div
            					className='input-group margin-top-15 col-lg-12'
            				>
            					<input
            						type='text'
            						name='question_1'
            						className='form-control input-lg not-dark required email'
            						placeholder='How did you find Industrial Navigator?'
            						onChange={this.handleInputChange}
            						value={this.state.fname}
            					/>
            				</div>
            				{this.validator.message('answer ', this.state.fname, 'required')}

            				{/* <ReCAPTCHA
            					ref={recaptchaRef}
            					// size='invisible'
            					className='margin-top-15'
            					onChange={this.onRecaptchaChange.bind(this)}
            					sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
            				/>
            				{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}

            				<div
            					className='col_full center padding-top-20'
            				>
            					<button
            						className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp'
            						type='button'
            						name='template-contactform-submit'
            						value='submit'
            						onClick={this.registerUser.bind(this)}
            					>
            						Register
            					</button>
							</div> */}
            			</form>
		)
	}
}

export default Step2;