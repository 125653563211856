import React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import * as MapboxGL from 'mapbox-gl';

import { PipelineTheme } from './CrudeOilPipelinesLayer';
import { portTerminalImages } from '../Details';
import portTerminalIcon from '../../port-terminal.png';

const majorPortTerminalImage = new Image();
majorPortTerminalImage.src = portTerminalIcon;
majorPortTerminalImage.width = 24;
majorPortTerminalImage.height = 24;
(majorPortTerminalImage as any).alpha = 0.4;
export const majorPortTerminalImages = ['majorPortTerminalImage', majorPortTerminalImage];

interface PMajorPortsLayer {
	theme: PipelineTheme;
}

class SMajorPortsLayer {
	ref: MajorPortsLayer = null;
	circles: boolean = false;
	icons: boolean = true;
	labels: boolean = true;

	constructor(parent: MajorPortsLayer) {
		this.ref = parent;
	}
}

export default class MajorPortsLayer extends React.Component<PMajorPortsLayer, SMajorPortsLayer> {

	static dataSource: string = 'Major_Ports-7wugs2';

	constructor(props: PMajorPortsLayer) {
		super(props);
		this.state = new SMajorPortsLayer(this);
	}

	render() {
		const { state, props } = this;
		const dataSource: string = MajorPortsLayer.dataSource;
		return (
			<React.Fragment>
				{
					state.circles &&
					<Layer
						id='major-ports-circles'
						type='circle'
						sourceId={dataSource}
						sourceLayer={dataSource}
						layerOptions={{
							'source-layer': { dataSource },
						}}
						layout={{ 'visibility': 'visible' }}
						paint={{
							'circle-color': '#0066ff',
						}}
					/>
				}
				{
					state.icons &&
					<Layer
						id='major-ports-icons'
						type='symbol'
						sourceId={dataSource}
						sourceLayer={dataSource}
						layerOptions={{
							'source-layer': { dataSource },
						}}
						layout={{
							'visibility': 'visible',
							//'icon-image': 'portTerminalImage',
							'icon-image': 'majorPortTerminalImage',
							'icon-allow-overlap': true,
						}}
						//images={portTerminalImages}
						images={majorPortTerminalImages}
					/>
				}
				{
					state.labels &&
					<Layer
						id='major-ports-labels'
						type='symbol'
						source={dataSource}
						sourceId={dataSource}
						sourceLayer={dataSource}
						layout={{
							'symbol-placement': 'point',
							'text-font': ['Open Sans Regular'],
							'text-field': '{PORT_NAME}',
							'text-size': 16,
						}}
						paint={{
							'text-color': (
								props.theme === PipelineTheme.Light
									? '#ffffff'
									: (props.theme === PipelineTheme.Dark ? '#000000' : '#888888')
							),
						}}
					/>
				}
			</React.Fragment>
		)
	}
}
