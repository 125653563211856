import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePage/qloud/footerComponents';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Helmet } from "react-helmet";
import axios from 'axios';
import '../css/priceplans.css';
import SimpleReactValidator from 'simple-react-validator';


const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: HomePageHeader) => void;
    data?: any;
}


class NorthAmericanSites extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    helpers: any = null;

    constructor(props: PHeader) {
        super(props);
        this.state = {
            email: ''
        }
    }

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }

    componentWillMount() {
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
    }




    child: HomePageHeader = null;



    render() {

        return (
            <>
                <Helmet>
                    <title>North American Port,Terminals And Transloads</title>
                    <meta property="og:url" content={window.location.href} />
                    <meta name='description' content="North American Port,Terminals And Transloads" />
                    <meta property='og:title' content="North American Port,Terminals And Transloads" />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>

                <HomePageHeader data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 1000, fontFamily: "Roboto, sans-serif" }}>
                    <div className="justify-content-center">
                        <div className='col-lg-2 col-md-12 col-sm-12 padding-0 ' ></div>
                        <div className='col-lg-8 col-md-12 col-sm-12 padding-0 ' >

                            <div className="container-fluid" style={{ marginTop: 100, fontFamily: "Roboto, sans-serif" }} >
                                <h1 className="content_area" style={{ fontWeight: 600, fontFamily: 'Roboto, sans-serif', marginLeft: 15 }}>North American Warehouses and Distribution Centers</h1>
                                <div className='container-fluid' >
                                    <p className='para_content-new'>
                                        <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                            <li>
                                                <h2 style={{paddingTop:25}}>Warehouse and Distribution Centers Offerings</h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-for-lease' }}><span>Warehouses and Distribution Centers for Lease</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-for-sale' }}><span>Warehouses and Distribution Centers for Sale</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-for-third-party-public-warehouse-services' }}><span>Public Warehouse Services / Third Party Logistics Services</span></Link></li>

                                                </ul>

                                            </li>



                                            <li>
                                                <h2 style={{paddingTop:25}}>Warehouse and Distribution Centers by Capabilities</h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-capable-for-temperature-control' }}><span>Temperature Control</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-capable-for-food-grade' }}><span>Food Grade</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-capable-for-foreign-trade-zone' }}><span>Foreign Trade Zone</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-capable-for-rail-served-warehouses' }}><span>Rail Served Warehouses</span></Link></li>
                                                </ul>

                                            </li>
                                            <li>
                                                <h2 style={{paddingTop:25}}>Warehouse By State</h2>
                                                <ul style={{ listStyleType: 'none', marginLeft: 20 }}>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-arizona' }}><span>Arizona Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-california' }}><span>California Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-georgia' }}><span>Georgia Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-iowa' }}><span>Iowa Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-illinois' }}><span>Illinois Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-indiana' }}><span>Indiana Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-kentucky' }}><span>Kentucky Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-missouri' }}><span>Missouri Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-ohio' }}><span>Ohio Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-tennessee' }}><span>Tennessee Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-texas' }}><span>Texas Warehouses</span></Link></li>
                                                    <li><Link to={{ pathname: '/north-american-warehouse-and-distribution-centers-washington' }}><span>Washington Warehouses</span></Link></li>
                                                </ul>

                                            </li>

                                        </ul>


                                    </p><br /><br />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <HomePageFooter />




            </>
        )
    }
}

export default withRouter(NorthAmericanSites);

