import React, { Component } from 'react';
import '../registration.css';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import { withRouter } from 'react-router';
import { Modal } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactPlayer from 'react-player';
import LinkedInTag from 'react-linkedin-insight';

const recaptchaRef = React.createRef();

interface PRegistration {
	history: any;
	location: any;
}

class SRegistration {
	fname: string = '';
	lname: string = '';
	companyName: string = '';
	// username: string = '';
	password: string = '';
	confirm_password: string = '';
	email: string = '';
	phone: string = '';
	city: string = '';
	usaState: string = '';
	nextURL: string = '';
	showRegistrationSuccessModal: boolean = false;
	reacatpcha_value: string = null;

	constructor() {
	}
}

class Registration extends Component<PRegistration, SRegistration> {

	validator: SimpleReactValidator = null;
	helpers: any = null;
	child: any = null;
	myFormRef: any = null;
	usaState: any = null;

	constructor(props: PRegistration) {
		super(props);
		this.state = new SRegistration();
		this.setFName = this.setFName.bind(this);
		this.setLName = this.setLName.bind(this);
		this.setCompanyName = this.setCompanyName.bind(this);
		// this.setUsername = this.setUsername.bind(this);
		this.setPassword = this.setPassword.bind(this);
		this.setConfirmPassword = this.setConfirmPassword.bind(this);
		this.setEmail = this.setEmail.bind(this);
		this.setPhone = this.setPhone.bind(this);
		this.setCity = this.setCity.bind(this);
		this.setUsaStateValue = this.setUsaStateValue.bind(this);
		this.registerUser = this.registerUser.bind(this);

		this.validator = new SimpleReactValidator({
			validators: {
				phone: {  // name the rule
					message: 'The :phone must be a valid phone number.',
					rule: (val, params, validator) => {
						return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)
					},
					messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
					required: true  // optional
				}
			}
		});

	}

	componentWillMount() {
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		if (this.props.history.location.state !== undefined && this.props.history.location.state.nextURL === '/create') {
			this.setState({ nextURL: this.props.location.state.nextURL });
		}
	}

	componentDidMount() {
		// axios.post(process.env.REACT_APP_BASE_URL+'/api/send-confirm-email',
		// {
		//     headers: {'Content-Type': 'application/json'},
		//     email: 'mahesh@e-arth.in',
		// }).then(response => {
		//     // handle your response;

		// }).catch(function(error) {

		// });
	}

	setFName(event) {
		this.setState({ fname: event.target.value });
	}

	setLName(event) {
		this.setState({ lname: event.target.value });
	}

	setCompanyName(event) {
		this.setState({ companyName: event.target.value });
	}

	// setUsername(event) {
	//     this.setState({ username: event.target.value });
	// }

	setPassword(event) {
		this.setState({ password: event.target.value });
	}

	setConfirmPassword(event) {
		this.setState({ confirm_password: event.target.value });
	}

	setEmail(event) {
		this.setState({ email: event.target.value });
	}

	setPhone(event) {
		this.setState({ phone: event.target.value });
	}

	setCity(event) {
		this.setState({ city: event.target.value });
	}

	setUsaStateValue(event) {
		this.setState({ usaState: event.target.value });
	}

	onRecaptchaChange(value) {
		console.log('Captcha value: ', value);
		this.setState({ reacatpcha_value: value });
	}


	registerUser(event) {
		if (this.validator.allValid()) {
            LinkedInTag.track('9791956');
			axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user',
				{
					headers: { 'Content-Type': 'application/json' },
					fname: this.state.fname,
					lname: this.state.lname,
					email: this.state.email,
					companyName: this.state.companyName,
					// username: this.state.username,
					password: this.state.password,
					phone: this.state.phone,
					city: this.state.city,
					usaState: this.state.usaState,
					reacatpcha_value: this.state.reacatpcha_value,
					'source':'Quick Signup',
				}).then(response => {
					// handle your response;
					if (response.status === 200) {
						this.setState({ showRegistrationSuccessModal: true });

						(document.getElementById('usaState') as any).selectedIndex = 0;
						this.setState({
							fname: '',
							lname: '',
							companyName: '',
							password: '',
							confirm_password: '',
							email: '',
							phone: '',
							city: '',
							usaState: '',
							reacatpcha_value: null
						});
					}
				}).catch((error) => {
					// handle your error
					const regi_errors = document.getElementById('regi_errors');
					regi_errors.innerHTML = '';
					const error_html = document.createElement('div');
					if (error.response.status === 600 && error.response.data.error === 'email exists') {
						error_html.innerHTML = 'You already have an account, please login!';
					}
					else if (error.response.status === 601) {
						error_html.innerHTML = 'Captcha verification failed, please try again!';
					}
					else {
						for (let i = 0; i < error.response.data.errors.length; i++) {
							error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
						}
					}
					document.getElementById('regi_errors').appendChild(error_html);
					document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
					regi_errors.scrollIntoView({ behavior: 'smooth' })
				});
		} else {
			this.validator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}

	handleRegistrationSuccessModal() {
		this.setState({ showRegistrationSuccessModal: false });
	}

	render() {
		return (
			<div>
				<Header data={this.props} onRef={ref => (this.child = ref)} />
				<div>
					<div id='wrapper' className='clearfix'>
						<section id='slider' className='slider-element slider-parallax full-screen' style={{ minHeight: 'calc(100vh - 70px)' }}>
							<div className='row margin-0'>
								<div className='col-xl-5 col-md-5 full-screen' style={{ borderTop: '1px solid #ccc' }}>
									<div className='vertical-middle subscribe-widget' data-loader='button'>
										<div className='col-padding padding-top-20'>
											<div className='heading-block nobottomborder leftmargin-sm'>
												<h1 className='color-black font-size-30 font-weight-bold'>Register</h1>
												<span className='font-size-18 margin-bottom-20'>Please enter your details below to register for Industrial Navigator.</span>
											</div>
											<div className='widget-subscribe-form-result'></div>
											<form id='slider-subscribe-form' ref={(el) => this.myFormRef = el} className='nobottommargin'>
												<div id='regi_errors' className='margin-top-15'></div>
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName} value={this.state.fname} />
												</div>
												{this.validator.message('first name', this.state.fname, 'required')}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName} value={this.state.lname} />
												</div>
												{this.validator.message('last name', this.state.lname, 'required')}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName} value={this.state.companyName} />
												</div>
												{this.validator.message('company name', this.state.companyName, 'required')}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail} value={this.state.email} />
												</div>
												{this.validator.message('email', this.state.email, 'required')}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword} value={this.state.password} />
												</div>
												{this.validator.message('password', this.state.password, 'required')}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword} value={this.state.confirm_password} />
												</div>
												{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='text' name='phone' className='form-control input-lg not-dark required email' placeholder='Enter your Phone Number..' onChange={this.setPhone} value={this.state.phone} />
												</div>
												{this.validator.message('phone number', this.state.phone, 'required|phone')}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<input type='text' name='city' className='form-control input-lg not-dark required email' placeholder='Enter your City..' onChange={this.setCity} value={this.state.city} />
												</div>
												{this.validator.message('city', this.state.city, 'required')}
												<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
													<select id='usaState' className='form-control font-weight-normal' onChange={this.setUsaStateValue} value={this.usaState}>
														<option value=''>Select State..</option>
														<option value='AL'>Alabama</option>
														<option value='AK'>Alaska</option>
														<option value='AZ'>Arizona</option>
														<option value='AR'>Arkansas</option>
														<option value='CA'>California</option>
														<option value='CO'>Colorado</option>
														<option value='CT'>Connecticut</option>
														<option value='DE'>Delaware</option>
														<option value='DC'>District Of Columbia</option>
														<option value='FL'>Florida</option>
														<option value='GA'>Georgia</option>
														<option value='HI'>Hawaii</option>
														<option value='ID'>Idaho</option>
														<option value='IL'>Illinois</option>
														<option value='IN'>Indiana</option>
														<option value='IA'>Iowa</option>
														<option value='KS'>Kansas</option>
														<option value='KY'>Kentucky</option>
														<option value='LA'>Louisiana</option>
														<option value='ME'>Maine</option>
														<option value='MD'>Maryland</option>
														<option value='MA'>Massachusetts</option>
														<option value='MI'>Michigan</option>
														<option value='MN'>Minnesota</option>
														<option value='MS'>Mississippi</option>
														<option value='MO'>Missouri</option>
														<option value='MT'>Montana</option>
														<option value='NE'>Nebraska</option>
														<option value='NV'>Nevada</option>
														<option value='NH'>New Hampshire</option>
														<option value='NJ'>New Jersey</option>
														<option value='NM'>New Mexico</option>
														<option value='NY'>New York</option>
														<option value='NC'>North Carolina</option>
														<option value='ND'>North Dakota</option>
														<option value='OH'>Ohio</option>
														<option value='OK'>Oklahoma</option>
														<option value='OR'>Oregon</option>
														<option value='PA'>Pennsylvania</option>
														<option value='RI'>Rhode Island</option>
														<option value='SC'>South Carolina</option>
														<option value='SD'>South Dakota</option>
														<option value='TN'>Tennessee</option>
														<option value='TX'>Texas</option>
														<option value='UT'>Utah</option>
														<option value='VT'>Vermont</option>
														<option value='VA'>Virginia</option>
														<option value='WA'>Washington</option>
														<option value='WV'>West Virginia</option>
														<option value='WI'>Wisconsin</option>
														<option value='WY'>Wyoming</option>
													</select>
												</div>
												{this.validator.message('usaState', this.state.usaState, 'required')}
												<ReCAPTCHA
													ref={recaptchaRef}
													// size='invisible'
													className='margin-top-15'
													onChange={this.onRecaptchaChange.bind(this)}
													sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
												/>
												{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
												<div className='col_full center padding-top-20'>
													<button className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser}>Register</button>
												</div>
											</form>
										</div>
									</div>
								</div>
								<div className='col-xl-7 col-md-7 full-screen center nopadding' style={{ height: '100vh' }}>
									<ReactPlayer
										className='react-player'
										url={process.env.REACT_APP_VIDEO_URL}
										playing={true}
										loop={true}
										width='100%'
										height='105vh'
										config={{
											file: {
												attributes: {
													autoPlay: true,
													muted: true
												}
											}
										}}
									/>
								</div>
							</div>
						</section>
					</div>
				</div>
				<Footer />
				<Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Registration Successful
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Please verify your email address before adding a listing.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}
}

export default withRouter(Registration);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)