import React, { useState } from 'react';
import TitleSection from '../../qloud/titlesection'
import createlist from "../../public/assets/images/work/datalayernew.png";
import { Link } from 'react-router-dom';
const Index = props => {
    const [supports] = useState([
        { title: 'Basic & advanced map layers including Class I and shortline railroads, intermodal terminals, major ports, crude oil terminals & refined product pipelines' },
        { title: 'List up to 100 properties' },
        { title: 'Premium placement within search results' },
        { title: 'Share listings via email and social media' },
        { title: 'See who has viewed your listings' },
        { title: 'Search by location' },
        { title: 'Search by keyword' },
        { title: 'Filter by 40+ search criteria' }
    ]);
    const [List1] = useState([
        { title: 'Engineers and Developers', content: '', timer: 50, symbol: '+' },
        { title: 'Customers', content: 'k', timer: 100, symbol: '+' }
    ]);
    const [List2] = useState([
        { title: 'Instances Deployed', content: 'm', timer: 35, symbol: '+' },
        { title: 'Support answers per month', content: 'm', timer: 15, symbol: '+' }
    ]);
    return (
        <>
            <section className="iq-counter-section pt-0 iq-pb-70">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-lg-0 mb-5">

                            <TitleSection className='iq-title-box-2'
                                title='Subscriptions for  Listers'
                                subTitle='About Us'
                                description='Industrial Navigator Power Search subscriptions have all of the capabilities needed to market your industrial sites and transportation assets.'
                                titleIcon='' />

                            <div className="iq-list iq-two-column">
                                <ul className="iq-list-with-icon">
                                    {
                                        supports.map((support, index) => (
                                            <li key={index}><i className="fa fa-check"></i>{support.title}</li>

                                        ))
                                    }
                                </ul>
                            </div>
                            <div style={{ marginBottom: '20px' }} className="iq-btn-container mt-4">
                               <div class="btn-group" role="group" aria-label="Basic example">
                                    <button style={{ background: '#009df5', color: '#ffffff' ,minWidth:170}} type="button" class="btn">
                                        <Link style={{ color: '#ffffff' }} to={{ pathname: "/register-and-checkout", planinfo: { id: 4,name:'Power Pro Monthly',price:'$49/month' } }}>
                                        Power Pro Monthly<br />$49/month 
                                        </Link>
                                        </button>
                                    <button style={{ background: '#053359', color: '#ffffff',minWidth:170 }} type="button" class="btn btn-secondary">
                                        <Link style={{ color: '#ffffff' }} to={{ pathname: "/register-and-checkout" , planinfo: { id: 6,name:'Power Pro Yearly',price:'$499/year' }}}>
                                        Power Pro Annual<br />$499/year
                                        </Link>
                                        </button>
                                    <button style={{ background: '#008fe4', color: '#ffffff' ,minWidth:170,minHeight:59}} type="button" class="btn btn-secondary"><a style={{ color: '#ffffff' }} href="/plans-and-pricing">See All Plans</a> </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1 col-md-2 col-sm-12 wow fadeInUp" data-wow-delay="0.4s"></div>
                        <div className="col-lg-5">
                            <div className="iq-counter text-center iq-box-shadow iq-counter-style-1">
                                <div className="counter-content">
                                    <img src={createlist} className="img-fluid" alt="qloud" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default Index