import React, { useState } from "react";
import {
	CardElement,
	ElementsConsumer,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { NavLink, Link } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import UserProfile from './UserProfile';
import axios from 'axios';
import { Elements } from "@stripe/react-stripe-js";
import '../css/priceplans.css';
import { useHistory } from "react-router-dom";
import { Cookies } from 'react-cookie';
import LinkedInTag from 'react-linkedin-insight';
const cookies = new Cookies();


const promise = loadStripe("pk_live_51JQZCTI9aRcuI4Jh6A4nCIJAOhTzdxjXDvRZ1HYx6GsqY1EazrOV5PV3oYL6qUQHbDMboqDjJgeXipyZbzpXhzId00IX8JmTkY");
const cardStyle = {
	style: {
		base: {
			color: "#32325d",
			fontFamily: 'Arial, sans-serif',
			fontSmoothing: "antialiased",
			fontSize: "20px",
			"::placeholder": {
				color: "#32325d"
			}
		},
		invalid: {
			color: "#fa755a",
			iconColor: "#fa755a"
		}
	}
};
const CheckoutStrip = ({ }) => {
	const elements = useElements();
	const stripe = useStripe();
	const history = useHistory();
	const [cardfill, setcardfill] = useState(false);
	const [terms, setterms] = useState(false);
	const [succeeded, setSucceeded] = useState(false);
	const [error, setError] = useState(null);
	const [processing, setProcessing] = useState('');
	const [disabled, setDisabled] = useState(true);
	const planNamesArr = localStorage.getItem('planNames') ? JSON.parse(localStorage.getItem('planNames')) : [];
	const id = planNamesArr[0].id;
	const emailid = localStorage.getItem('UseremailId') ? JSON.parse(localStorage.getItem('UseremailId')) : [];
	const email = emailid.email;
	const password = emailid.password;
	console.log("ID for the plan", id)
	console.log("email id of the user", email)
	let customerId
	let clientSecret
	let priceId
	const handleChange = async (event) => {
		// Listen for changes in the CardElement
		// and display any errors as the customer types their card details
		setDisabled(event.empty);
		setError(event.error ? event.error.message : "");
	};
	const handleLoginSuccessResponse = async (response) => {

		console.log('order is updated with success');
		localStorage.removeItem('listingNames');

		//this.setState({ showPlaceSuccessModal: true });
		var placeOrderDiv = document.getElementById('place-order-div');
		var placeOrderBtn = document.getElementById('place-order-btn');
		//placeOrderDiv.removeChild(placeOrderBtn);
		console.log('inside handleLoginSuccessResponse', response);
		UserProfile.setEmail(response.data.email);
		UserProfile.setFirstName(response.data.first_name);
		UserProfile.setLastName(response.data.last_name);
		UserProfile.setUserType(response.data.user_type);
		UserProfile.setCompanyName(response.data.companyName);


		cookies.set('token', response.data.token);
		console.log(response.data);
		localStorage.removeItem('planNames');
		localStorage.removeItem('UseremailId');
		localStorage.removeItem('PaymentIntent');
		history.push('/upgrade-success')
		//this.setState({ showPlaceSuccessModal: true });
		//this.props.history.push('/');
	}
	const autoLogin = async () => {
		try {
			console.log('handle auto login');
			axios.post(process.env.REACT_APP_BASE_URL + '/api/auto-login-user',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					login_email: email,
					login_password: password,
				}).then((response = {
					status: Number
					, data: {
						bookmarks_str: String
						, email: String
						, first_name: String
						, last_name: String
						, max_no_entries: String
						, options: String
						, place_names_arr: String //Array<any>
						, user_type: String
						, token: String
					}
				}) => {
					document.getElementById('login_error').style.display = 'none';
					document.getElementById('verify_email_error').style.display = 'none';
					console.log('autoLogin api response: ', response);

					//this.handleClose();

					if (response.status === 200) {
						let bookmarksCount = 0;
						console.log('response.data')
						console.log(response.data)
						if (response.data.bookmarks_str) {
							const bookmarksArr = response.data.bookmarks_str.split(',');
							bookmarksCount = bookmarksArr.length;
						}
						localStorage.setItem('bookmarks', response.data.bookmarks_str);
						localStorage.setItem('listingNames', response.data.place_names_arr);
						localStorage.setItem('maxNoEntries', response.data.max_no_entries);
						localStorage.setItem('membershipOptions', response.data.options);

						handleLoginSuccessResponse(response);
					} else {
						this.setState({ isLoggedIn: false });
						if (response.status === 204) {
							document.getElementById('verify_email_error').style.display = 'block';
							document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
						} else {
							document.getElementById('login_error').style.display = 'block';
							document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
						}
					}
				}).catch((error) => {
					console.log('error auto login api: ', error);
					// handle your error
					document.getElementById('login_error').style.display = 'block';
					document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
				});
		} catch (err) {
			console.log('error in auto login');
			console.error(err);
		}
	}
	const autoLoginUser = async () => {
		console.log("inside autologin method");
		axios.post(process.env.REACT_APP_BASE_URL + '/api/create-order',
			{
				headers: { 'Content-Type': 'application/json' },
				usr_email: email,
				place_ids: localStorage.getItem('planNames') ? localStorage.getItem('planNames') : '',
				order_type: 'membership_plan',
				payment_type: "online",
				plan_id: id,
				customer_id: localStorage.getItem('CustomerId'),
				subscription_id: localStorage.getItem('SubscriptionId'),

			}).then(res => {
				console.log(res)
				console.log("after create order");
				if (res.status === 200) {
					//alert("coupan order success");
					autoLogin();

				}
				else {
					alert(" failed")
				}
			});
	}
	const paymentConferm = async (event) => {
		const cardElement = elements.getElement(CardElement);
		clientSecret = event;
		const { error, paymentMethod } = await stripe.createPaymentMethod({
			type: "card",
			card: cardElement,

		});
		const paymentIntent = stripe.confirmCardPayment(
			clientSecret, {
			payment_method: {
				card: elements.getElement(CardElement),

			}


		}).then(statusofuser => {
			console.log("paymentIntent:", statusofuser.paymentIntent)
			try {
				if (statusofuser.paymentIntent.status == "succeeded") {
					setError(null);
					setProcessing(false);
					setSucceeded(true);
	
					console.log("status", statusofuser.paymentIntent.status)
					if (statusofuser.paymentIntent.status == "succeeded") {
						localStorage.setItem('PaymentIntent', JSON.stringify({ statusofuser }));
						autoLoginUser();
	
					}
				}
				
			} catch (error) {
				//{error.message}
				setError(<span style={{color:"red"}}>Payment failed.</span>);
				setProcessing(false);
				//alert("Payent Failed")
			}
			
			
		})
	}

	const handlesubmit = async (event) => {
		console.log("value of cardfill", cardfill)
		if (!cardfill) {
			alert("plase accept terms and conditions")

		}

		else {

            LinkedInTag.track('9791956');
			event.preventDefault();
			setProcessing(true);
			if (!stripe || !elements) {
				return;
			}
			// if (id == 3) {
			// 	priceId = 'price_1JjOYtI9aRcuI4Jh7V3hOhIL'
			// }
			// else if (id == 4) {
			// 	priceId = 'price_1JjXTOI9aRcuI4JhD5AzCG4z'
			// }
			// else if (id == 5) {
			// 	priceId = 'price_1JidsnI9aRcuI4JhRaVojddT'
			// }
			// else if (id == 6) {
			// 	priceId = 'price_1JidtLI9aRcuI4JhAgcKR8aI'
			// }
			if (id == 3) {
				priceId = 'price_1K2dBhI9aRcuI4Jh6Qr5fxnx'
			}
			else if (id == 4) {
				priceId = 'price_1K2dBKI9aRcuI4JhdmXbA2om'
			}
			else if (id == 5) {
				priceId = 'price_1JidsnI9aRcuI4JhRaVojddT'
			}
			else if (id == 6) {
				priceId = 'price_1JidtLI9aRcuI4JhAgcKR8aI'
			}
			//create customer on the stripe
			console.log("emaid and price id for the current user:", email, priceId);
			await axios.post(process.env.REACT_APP_BASE_URL + '/api/create-customer', {
				email: email,
				//customerId:'',
			})
				.then(function (response) {
					console.log(response);
					customerId = response.data.customer.id;
					localStorage.setItem('CustomerId', response.data.customer.id);
					console.log("customer id:", customerId)
					//console.log("price id",priceId)
					//customerId=this.setState({customerId})  
					//create subscription for the customer on the stripe
					axios.post(process.env.REACT_APP_BASE_URL + '/api/create-subscription', {
						priceId: priceId,
						customerId: customerId,
					})
						.then(function (response) {
							console.log(response);
							//setclientSecret(response.data.clientSecret)
							clientSecret = response.data.clientSecret
							console.log("client secret", clientSecret)

							localStorage.setItem('SubscriptionId', response.data.subscriptionId);
							paymentConferm(clientSecret);

							//alert("subscription created")
						})
						.catch(function (error) {
							console.log(error);
						});
					//console.log("clinet secret:", clientSecret)

				})
				.catch(function (error) {
					console.log(error);
				});


			// if (error) {
			// 	setError(`Payment failed ${error.message}`);
			// 	setProcessing(false);
			// } else {
			// 	setError(null);
			// 	setProcessing(false);
			// 	setSucceeded(true);
			// }
		}
	};

	return (
		<>
			{/* // <Elements stripe={promise}> */}
			<form id="payment-form" onSubmit={handlesubmit}>
				<input style={{ marginLeft: 0 }} type="checkbox" name='terms1' className='form-check-input' checked={cardfill} onChange={(e) => { setcardfill(e.target.checked) }} /><b style={{ marginLeft: 15 }} className="form-check-label"> I agree to the  website <Link target="_blank" to={{ pathname: '/terms-and-conditions' }} >terms and conditions</Link>.</b><br /><br />

				<CardElement options={cardStyle} onChange={handleChange} /><br />
				{
					cardfill ?

						<button
							disabled={processing || disabled || succeeded}
							id="submit"
						>
							<span id="button-text">
								{processing ? (
									<div className="spinner" id="spinner"></div>
								) : (
									"Pay now"
								)}
							</span>
						</button>


						:
						<>
							<button
								disabled={true}
								id="submit"
							>
								<span id="button-text">
									{processing ? (
										<div className="spinner" id="spinner"></div>
									) : (
										"Pay now"
									)}
								</span>
							</button>
							
							</>

							
							
				}


								{/* Show any error that happens when processing the payment */}
								{error && (
									<div className="card-error" role="alert">
										{error}
									</div>
								)}
								{/* Show a success message upon completion */}
								<p className={succeeded ? "result-message" : "result-message hidden"}>
									<span style={{ color: "green" }}>Payment Successful</span>
								</p>
							</form>
							{/* // </Elements > */}
						</>
	);
};

				export {CheckoutStrip};
