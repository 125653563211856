import React, { useEffect, lazy ,Suspense} from 'react';
import { index } from './config/plugins';
import logoImg from './public/assets/images/logo-black.png';
import Layout from './Layouts/MainLayout';
import PortfoiloDetail from './searcher/details';
import Footer from './searcher/footer';
import Property from './searcher/property';
import TopBanner from './qloud/topbanner';
// const Layout = lazy(() => import('./Layouts/MainLayout'));
// const Home = lazy(() => import('./home'));
// const PortfoiloDetail = lazy(() => import('./searcher/details'));
// const Footer = lazy(() => import('./searcher/footer'));
// const Property = lazy(() => import('./searcher/property'));
// const TopBanner = lazy(() => import('./qloud/topbanner'));

const Index = props => {


    useEffect(() => {
        setTimeout(() => {
            index();
        }, 500);
    });

    return (
        <>
             <Layout mainHeaderClass={"header-main "} logoImg={logoImg}>
                    <TopBanner title="Search with Industrial Navigator" />
                    <PortfoiloDetail />
                    <Property />
                    <Footer />
                </Layout>

        </>
    );
}

export default Index;
