import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { Carousel } from 'react-responsive-carousel';
import MediaQuery from 'react-responsive';
import html2canvas from 'html2canvas';
import { Modal, Breadcrumb, BreadcrumbItem, Button, OverlayTrigger, Tooltip, Row, Alert, Tabs, Tab } from 'react-bootstrap';
import ReactMapboxGl, { Layer, Marker, Feature, Popup, ZoomControl } from 'react-mapbox-gl';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import * as MapboxGl from 'mapbox-gl';
import { Feature as GeoFeature, Point } from 'geojson';
import MetaTags from 'react-meta-tags';
import Switch from 'react-switch';
import { Helmet } from "react-helmet";
import * as turf from '@turf/turf';
import siteIcon from '../site-marker.png';
import portTerminalIcon from '../port-terminal.png';
import otherTerminalIcon from '../terminaltrans.png';
import warehouseIcon from '../warehouse-marker.png';
import multiPurposeIcon from '../multi.png';
import intermodalIcon from '../intermodal.png';
import majorPortsIcon from '../major-port.png';
import PDFGen from './pdf/PDFgenerate'
import Header from './Header';
import { SVGIcon } from './Icons';
import Features from './Features';
import UserProfile from './UserProfile';
import InputMultiSelectComponent from './InputMultiSelectComponent';
import { LiFilter, createIntermediateArr, createMinMaxValueArr } from './LiFilter'
import ListIconFiltering, {
	getStorageItem
	, getSelectedPropertyTypeAll
} from './ListIconFiltering';
import SimpleReactValidator from 'simple-react-validator';
import CrudeOilPipelinesLayer, { PipelineTheme } from './MapboxLayers/CrudeOilPipelinesLayer';
import MajorPortsLayer from './MapboxLayers/MajorPortsLayer';
import NorthAmericanRailLinesBnsf from './MapboxLayers/NorthAmericanRailLinesBnsf';
import NorthAmericanRailLinesCn from './MapboxLayers/NorthAmericanRailLinesCn';
import NorthAmericanRailLinesCprs from './MapboxLayers/NorthAmericanRailLinesCprs';
import NorthAmericanRailLinesCsxt from './MapboxLayers/NorthAmericanRailLinesCsxt';
import NorthAmericanRailLinesKcs from './MapboxLayers/NorthAmericanRailLinesKcs';
import NorthAmericanRailLinesNs from './MapboxLayers/NorthAmericanRailLinesNs';
import NorthAmericanRailLinesShort from './MapboxLayers/NorthAmericanRailLinesShort';
import NorthAmericanRailLinesShortExt from './MapboxLayers/NorthAmericanRailLinesShortExt';
import NorthAmericanRailLinesUp from './MapboxLayers/NorthAmericanRailLinesUp';
import PetroleumProductPipelinesLayer from './MapboxLayers/PetroleumProductPipelinesLayer';
import MapCluster, { TFeature } from './MapCluster';
import MapCluster4MajorPort from './MapCluster4MajorPort';
import TimerSignUp from './TimerSignUp';
import {
	classOneConnectionsOptions
} from '../constant/DropDownLists';
import intermodalJSON, { TIntermodal } from '../constant/Intermodal';
import majorPortsJson from '../constant/MajorPorts';
import { roadWaysMapLayersLines, roadWaysMapLayersText } from '../constant/MapboxRoadWays';
import {
	siteImages
	, portTerminalImages
	, otherTerminalImages
	, warehouseImages
	, multiPurposeImages
} from '../constant/MapImages';
import { ClassOne } from '../constant/RailroadNameToAcronym';
import { shortLinesOptions, shortLinesAllFilter } from '../constant/ShortLines';
import {
	LabelValue
	, removeDuplicatesFromArr
	, splitFilter
	, joinFilter
	, joinFilterV
	, TInFeature
} from '../constant/Types';
import InputCheckboxComponent from './InputCheckboxComponent';
import PdfDetailsDocGen from './pdf/PdfDetailsDocGen';
import { cpuUsage } from 'process';
import ListingHelpModal from './ListingHelpModal';
import ReactToPrint from 'react-to-print';
import Bookmarkprivatecomponent from './PrivateLabel/Bookmarkprivate';
import logo from '../logo.svg';
import InputBoxComponent from './InputBoxComponent';
import MultiStepRegistration from './Register/MultiStepRegistration';
import LinkedInTag from 'react-linkedin-insight';
import ReCAPTCHA from 'react-google-recaptcha';
import TakeTour from './TakeTour';
import gtag from 'ga-gtag';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Mapshot from './Mapshot';
export const setStorageItem = (item: string, value: string = ''): void => {
	if (value) {
		localStorage.setItem(item, value);
	} else {
		localStorage.removeItem(item);
	}
}
const recaptchaRef = React.createRef();
let componentRef;

const CLUSTER_COLORS: {
	site: string,
	warehouse: string,
	portTerminal: string,
	otherTerminal: string,
	multiPurpose: string,
	intermodal: string,
	majorPorts: string,
} = {
	site: '#2FCB71',
	warehouse: '#F1C40E',
	portTerminal: '#016be6',
	otherTerminal: '#BA0303',
	multiPurpose: '#FF6800',
	intermodal: '#434f59',
	majorPorts: '#003366',
}

export const sizeIcoSmall: number = 21;
export const sizeIcoBig: number = 32;
export const placeIcoSmall: number = 43;
export const placeIcoBig: number = 54;

export declare type FitBounds = [[number, number], [number, number]];
// const initialFitBounds: FitBounds = [[-135, 30.00639444], [-60.237767219999995, 45.935964170000005]];


const fitBoundsStr: string = getStorageItem('initialFitBounds');
const fitBoundsFromStorage: FitBounds = fitBoundsStr ? JSON.parse(fitBoundsStr) : [[-135, 30.00639444], [-60.237767219999995, 45.935964170000005]];
const initialFitBounds: FitBounds = fitBoundsFromStorage;
setStorageItem('initialFitBounds', JSON.stringify(initialFitBounds));



const isEqualFitBounds = (fitBounds01: FitBounds, fitBounds02: FitBounds) => {
	if (fitBounds01[0][0] !== fitBounds02[0][0]) {
		return false;
	}
	if (fitBounds01[0][1] !== fitBounds02[0][1]) {
		return false;
	}
	if (fitBounds01[1][0] !== fitBounds02[1][0]) {
		return false;
	}
	if (fitBounds01[1][1] !== fitBounds02[1][1]) {
		return false;
	}
	return true;
}

const cookies = new Cookies();

const Mapbox = ReactMapboxGl({
	accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
	maxZoom: 20,
	minZoom: window.innerWidth <= 767 && 3,
	doubleClickZoom: false,
	preserveDrawingBuffer: true,
});

const geojson: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
	'type': 'FeatureCollection',
	'features': []
};

// Used to draw a line between points
const linestring: GeoJSON.Feature = {
	'type': 'Feature',
	'geometry': {
		'type': 'LineString',
		'coordinates': [],
	},
	'properties': null,
};

class TClientUiConfiguration {
	flagAvailableClassIConnections: boolean = true;
	flagAvailableCrudeOilPipelines: boolean = true;
	flagAvailablePetroleumPipelines: boolean = true;
	flagAvailableIntermodalTerminals: boolean = true;
	flagAvailableMajorPorts: boolean = true;
	flagAvailableRoadways: boolean = true;
	//
	colorNavbar: string = null;
	colorSlider: string = null;
	Accentcolor: string = null;
	outlineInDegrees: number = null;
	//
	shortLinesDataAvailable: string = null;
	//
	logoUrl: string = '';
	//
	filteredListing4ShortLines: Array<LabelValue> = null;

	constructor() {
	}

	copy(copy: TClientUiConfiguration): TClientUiConfiguration {
		this.flagAvailableClassIConnections = copy.flagAvailableClassIConnections;
		this.flagAvailableCrudeOilPipelines = copy.flagAvailableCrudeOilPipelines;
		this.flagAvailablePetroleumPipelines = copy.flagAvailablePetroleumPipelines;
		this.flagAvailableIntermodalTerminals = copy.flagAvailableIntermodalTerminals;
		this.flagAvailableMajorPorts = copy.flagAvailableMajorPorts;
		this.flagAvailableRoadways = copy.flagAvailableRoadways;
		this.colorNavbar = copy.colorNavbar;
		this.outlineInDegrees = copy.outlineInDegrees;
		this.colorSlider = copy.colorSlider;
		this.Accentcolor = copy.Accentcolor;
		this.shortLinesDataAvailable = copy.shortLinesDataAvailable;
		this.logoUrl = copy.logoUrl;
		this.filteredListing4ShortLines = copy.filteredListing4ShortLines;
		return this;
	}
}

class TIdxCoordinate {
	idx: number = null;
	coordinate: number = null;
}

interface PListing {
	match?: { params: { id: string } };
	location: any;
	history: Array<string>;
}

class SListing {

	// --- filter candidates responsible state properties ---
	planId: string = '';
	bookmarksPrivateLabelModal: boolean = false;
	ListItemModal: boolean = false;
	getHelpModal: boolean = false;
	limitedOfferSignUpModal: boolean = false;
	bookmarksCountPrivate: number = 0
	bookmarksCount: number = 0;
	modalVisible: boolean = false;
	showLoginModal: boolean = false;
	showForgotPasswordModal: boolean = false;
	fpEmail: string = '';
	isLoggedIn: boolean = false;
	login_email: string = '';
	otp: string = '';
	nextURL: string = '';
	propertyType: string = '';
	propertySubType: string = '';
	showRegistrationModal: boolean = false;
	fname: string = '';
	lname: string = '';
	companyName: string = '';
	password: string = '';
	confirm_password: string = '';
	reg_email: string = '';
	showRegistrationSuccessModal: boolean = false;
	reacatpcha_value: string = '';
	headerBackgroundColor: 'transparent';
	showOTPModal: boolean = false;
	showLoginSuccessModal: boolean = false;
	login_password: string = '';
	selectedWaterSewer: string = '';
	selectedPowerToSite: string = '';
	shortLines: string = ''; // Comma-separated list
	indoorStorage: string = '';
	parkingCapacity: string = '';
	nearestInterstate: string = '';
	milesToInterstate: string = '';
	nearestAirport: string = '';
	milesToAirport: string = '';
	silos: string = '';
	classOneConnectionsDataLayer: string = '';
	shortLinesDataLayer: string = '';
	showContactUs: boolean = false;
	pdfModal: boolean = false;
	firstName: string = UserProfile.getFirstName() ? UserProfile.getFirstName() : '';
	lastName: string = UserProfile.getLastName() ? UserProfile.getLastName() : '';
	email: string = UserProfile.getEmail() ? UserProfile.getEmail() : '';
	message: string = '';
	contactusbutton: boolean = false;
	showAlert: boolean = false;
	renderSatelliteMapInQV: boolean = true;
	mapType: string = '';
	addFavModal: boolean = false;
	deleteFavModal: boolean = false;
	intermodalPlace: TIntermodal = undefined;

	majorPortPlace: GeoFeature = undefined;

	showIntermodalData: boolean = false;

	showCrudeOilPipelines: boolean = false;
	showPetroleumProductPipelines: boolean = false;
	showMajorPorts: boolean = false;
	showRoadways: boolean = false;

	showIndustrialSites: boolean = true;
	showTerminalsAndTransloads: boolean = true;
	showPortTerminals: boolean = true;
	showWarehouses: boolean = true;

	showClassOneConnOverlay: boolean = false;

	organizationName: string = '';

	// --- for private client data ---
	specificClientConfiguration: TClientUiConfiguration = new TClientUiConfiguration();
	// --- ui responsible state properties ---

	flagLoading: boolean = true;
	flagStyleLoading: boolean = true;
	flyToOptions: number = 0.8;
	flagPerformZoomForPrivateClient: boolean = false;
	flagDisableZoom: boolean = false;
	flagMapMoveUpdateListing: boolean = false;
	flagMeasureDistances: boolean = false;

	innerWidth: number = window.innerWidth;
	innerHeight: number = window.innerHeight;

	sitesGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
	portTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
	otherTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
	warehousesGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
	multiPurposeGeoJSONArray: { features: Array<TInFeature> } = { features: [] };
	response: {
		features: Array<TInFeature>
		, latitudeSorted: Array<TIdxCoordinate>
		, longitudeSorted: Array<TIdxCoordinate>
	}
		= {
			features: []
			, latitudeSorted: []
			, longitudeSorted: []
		};

	showNumberColumnsInListing: number = 2;
	innerWidthToHideListingButtons: number = 767;
	renderSatelliteMap: string = 'true';
	mapStyle: string = process.env.REACT_APP_MAPBOX_STYLE_SATELLITE;
	//renderSatelliteMap: string = 'false';
	//mapStyle: string = process.env.REACT_APP_MAPBOX_STYLE_STREETS;
	showRailroadLabels: boolean = true; // Toggle railroad label visibility
	showRailroadLines: boolean = true; // Toggle railroad line visibility
	place: TFeature = undefined;
	cityCoordinates: Array<number> = [];

	mapboxRef: React.Component = null;
	listFilteringRef: ListIconFiltering = null;

	showListingHelpModal: boolean = true;

	flagMapToolbarExpanded: boolean = false;

	fitBounds: FitBounds = initialFitBounds;

	filter: LiFilter = new LiFilter();
	PageUrl: ' ';
	PageTitle: ' ';
	PageMetaRobot: ' ';
	PageMetaDiscription: ' ';
	constructor() {
	}
}

class Listing extends Component<PListing, SListing> {
	buttonRef: any = null;
	child: Header = null;
	validator: SimpleReactValidator = null;
	loginValidator: SimpleReactValidator = null;
	OTPValidator: SimpleReactValidator = null;
	registerValidator: SimpleReactValidator = null;
	bookmarksPrivateLabelModal: boolean = false;
	ListItemModal: boolean = false;
	getHelpModal: boolean = false;
	limitedOfferSignUpModal: boolean = false;
	constructor(props: PListing) {
		super(props);
		this.state = new SListing();

		this.buttonRef = React.createRef();
	}
	showModalPrivateFav = () => {
		this.setState({
			bookmarksPrivateLabelModal: true,
		});

	}
	showModallistItem = () => {

		this.setState({
			ListItemModal: true,
		});

	}
	showModalGetHelp = () => {

		this.setState({
			getHelpModal: true,
		});

	}
	hideModalGetHelp = () => {

		this.setState({
			getHelpModal: false,
		});

	}

	showModalLimitedOfferSignUp = () => {
		let timeStamp = '60'
		const promise = new Promise((resolve, reject) => {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/get-site-customization-info', {
				id: 1
			})
				.then(function (response) {
					console.log("JSON.parse(response.data[0].description_data).count", JSON.parse(response.data[0].description_data).timeSpent)
					timeStamp = JSON.parse(response.data[0].description_data).timeSpent
					resolve(JSON.parse(response.data[0].description_data).timeSpent);
				})
				.catch(function (error) {
					console.log(error);
				});

		});

		promise.then((timeStamp) => {
			setTimeout(() => {
				this.setState({ limitedOfferSignUpModal: true });
				this.trackGoogleAnalyticsEvent('free_trial_open', 'free_trial_open');
			}, Number(timeStamp) * 1000)
		});

	}
	hideLimitedOfferSignUp = () => {
		this.setState({
			limitedOfferSignUpModal: false,
		});
		this.trackGoogleAnalyticsEvent('free_trial_abn', 'free_trial_abn');

	}

	getGeneralMapKey = (strPrefix: string) => {
		const { mapStyle } = this.state;
		return strPrefix + mapStyle;
	}

	getGeneralMapPlaceKey = (strPrefix: string) => {
		return strPrefix;
	}

	getGeneralListPlaceKey = (strPrefix: string) => {
		const { showNumberColumnsInListing } = this.state;
		const fitBounds: FitBounds = this.getMapBounds(false, false);
		const fitBoundsStr: string = this.getFitBoundsAsStr(fitBounds);
		return strPrefix + showNumberColumnsInListing + '_' + fitBoundsStr;
	}

	getRailroadLayerKey = (strPrefix: string) => {
		const { mapStyle, showNumberColumnsInListing, classOneConnectionsDataLayer, shortLinesDataLayer } = this.state;
		return strPrefix + mapStyle + '_' + showNumberColumnsInListing + '_' + classOneConnectionsDataLayer + '_' + shortLinesDataLayer;
	}

	getRailroadClassOneLayerKey = (strPrefix: string, arrToInclude: Array<string>, current: string) => {
		const { mapStyle, showNumberColumnsInListing } = this.state;
		const flagExist: boolean = arrToInclude.some((value: string) => current === value);
		return strPrefix + mapStyle + '_' + showNumberColumnsInListing + '_' + current + (flagExist ? '1' : '0');
	}

	getRailroadShortLayerKey = (strPrefix: string) => {
		const { mapStyle, showNumberColumnsInListing, shortLinesDataLayer } = this.state;
		return strPrefix + mapStyle + '_' + showNumberColumnsInListing + '_' + shortLinesDataLayer;
	}

	getCrudeOilPipelinesLayerKey = (strPrefix: string) => {
		const { mapStyle, showNumberColumnsInListing } = this.state;
		return strPrefix + mapStyle + '_' + showNumberColumnsInListing;
	}

	getPetroleumProductPipelinesLayerKey = (strPrefix: string) => {
		const { mapStyle, showNumberColumnsInListing } = this.state;
		return strPrefix + mapStyle + '_' + showNumberColumnsInListing;
	}

	getMajorPortsLayerKey = (strPrefix: string) => {
		const { mapStyle, showNumberColumnsInListing } = this.state;
		return strPrefix + mapStyle + '_' + showNumberColumnsInListing;
	}

	flagToPreventMapResizeCycle: boolean = false;
	prevIdleDate = new Date();
	updateMapboxRef = (el: React.Component) => {
		if (el && el !== this.state.mapboxRef) {
			this.setState({
				mapboxRef: el
			}, () => {
				const map = this.getMapRef();
				if (!map) {
					return;
				}
				map.on('idle', (ev: any) => {
					const date = new Date();
					if (date.getTime() - this.prevIdleDate.getTime() > 1000 && this.state.flagMapMoveUpdateListing) {
						this.prevIdleDate = new Date();
						this.forceUpdateAfterMapMoveEnd();
						this.prevIdleDate = new Date();
					}
				});
				// map.on('load', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('zoomend', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('dragend', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('resize', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('wheel', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('mouseup', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('moveend', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('boxzoomend', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				// map.on('touchend', (ev: any) => {
				// 	this.forceUpdateAfterMapMoveEnd();
				// });
				map.on('mousemove', (ev: any) => {
					if (!this.state.flagMeasureDistances) {
						return;
					}
					const features = map.queryRenderedFeatures(ev.point, {
						layers: ['measure-points']
					});
					// Change the cursor to a pointer when hovering over a point on the map.
					// Otherwise cursor is a crosshair.
					map.getCanvas().style.cursor = features.length
						? 'pointer'
						: 'crosshair';
				});
			});
		}
	}

	timeoutId: NodeJS.Timeout = null;
	forceUpdateAfterMapMoveEnd = (flagPerformResize: boolean = false) => {
		if (this.flagToPreventMapResizeCycle) {
			return;
		}
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
			this.timeoutId = null;
		}
		const map = this.getMapRef();
		if (map && map.loaded()) {
			this.forceUpdateAfterMapMoveEndByTymer(flagPerformResize);
			return;
		}
		// this.timeoutId = setTimeout(() => {
		// 	const map = this.getMapRef();
		// 	if (map && map.loaded()) {
		// 		this.forceUpdateAfterMapMoveEndByTymer(flagPerformResize);
		// 	}
		// 	const timeoutId = this.timeoutId;
		// 	this.timeoutId = null;
		// 	if (timeoutId) {
		// 		clearTimeout(timeoutId);
		// 	}
		// }, 1000);
	}

	forceUpdateAfterMapMoveEndByTymer = (flagPerformResize: boolean = true) => {
		const fitBounds: FitBounds = this.getMapFitBounds(flagPerformResize);
		this.setFitBounds(fitBounds, () => {
			this.forceUpdate();
		});
	}

	updateListFilteringRef = (el: ListIconFiltering) => {
		if (el) {
			this.setState({
				listFilteringRef: el
			});
		}
	}

	onDrag = () => {
		if (this.state.place) {
			this.setState({
				place: undefined,
			});
		}
	}

	setFitBounds = (fitBounds: FitBounds, callback?: () => void): boolean => {
		if (isEqualFitBounds(this.state.fitBounds, fitBounds)) {
			return false;
		}
		this.setState({
			fitBounds: fitBounds,
		}, callback);
		return true;
	}

	adjustCenter = (fitBounds: FitBounds, lng: number, lat: number, deltaFactor: number = 2.0, widthDefault: number = null): FitBounds => {
		let w = 0.0;
		let h = 0.0;
		if (widthDefault) {
			w = widthDefault;
			h = widthDefault * Math.abs(fitBounds[0][1] - fitBounds[1][1]) / Math.abs(fitBounds[0][0] - fitBounds[1][0]);
		} else {
			w = Math.abs(fitBounds[0][0] - fitBounds[1][0]) / deltaFactor;
			h = Math.abs(fitBounds[0][1] - fitBounds[1][1]) / deltaFactor;
		}
		let hShift = 0.0;
		if (this.state.innerHeight < 600) {
			hShift = h / 1.2;
		} else if (this.state.innerHeight < 800) {
			hShift = h / 2.0;
		}
		fitBounds = [[lng - w, lat - h + hShift], [lng + w, lat + h + hShift]];
		return fitBounds;
	}

	markerClick = (place: TFeature) => {
		// this.trackGoogleAnalyticsEvent(place.properties.listingName.replaceAll(' ', '-') + '-Marker-Click', place.properties.listingName.replaceAll(' ', '-') + '-Marker-Click-On-Map')
		// update map bounds to new one after marker click
		const lng: number = place.geometry.coordinates[0];
		const lat: number = place.geometry.coordinates[1];
		const fitBounds: FitBounds = this.getMapFitBounds();
		const fitBoundsAdjusted: FitBounds = this.adjustCenter(fitBounds, lng, lat, 2.0, 0.5);
		this.setState({
			place: place,
			fitBounds: fitBoundsAdjusted,
			ListItemModal: false
		});
	}

	markersClusterClick = (coordinates: GeoJSON.Position, pointsCount: number) => {
		const lng: number = coordinates[0];
		const lat: number = coordinates[1];
		const fitBounds: FitBounds = this.getMapFitBounds();
		const fitBoundsAdjusted: FitBounds = this.adjustCenter(fitBounds, lng, lat, 8.0);
		this.setState({
			fitBounds: fitBoundsAdjusted,
		});
	}

	intermodalMarkerClick = (intermodalPlace: TIntermodal) => {
		const lng: number = intermodalPlace.LON;
		const lat: number = intermodalPlace.LAT;
		const fitBounds: FitBounds = this.getMapFitBounds();
		const fitBoundsAdjusted: FitBounds = this.adjustCenter(fitBounds, lng, lat);
		this.setState({
			intermodalPlace: intermodalPlace,
			fitBounds: fitBoundsAdjusted,
		});
	}

	majorPortMarkerClick = (feature: GeoFeature) => {
		const point: Point = feature.geometry as Point;
		const lng: number = point.coordinates[0];
		const lat: number = point.coordinates[1];
		const fitBounds: FitBounds = this.getMapFitBounds();
		const fitBoundsAdjusted: FitBounds = this.adjustCenter(fitBounds, lng, lat);
		this.setState({
			majorPortPlace: feature,
			fitBounds: fitBoundsAdjusted,
		});
	}

	onToggleHover(cursor, { map }) {
		map.getCanvas().style.cursor = cursor;
	}

	onToggleMapMoveUpdateListing = (checked: boolean
		, event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent
		, id: string
	) => {
		this.setState({
			flagMapMoveUpdateListing: checked
		});
	}


	componentWillMount() {
		this.getMetaTags();
		require(process.env.REACT_APP_LISTING_GEOJSON_PATH);
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		this.loginValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
		this.OTPValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		this.registerValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		const privateClientId: string = this.getPrivateClientId();
		this.loadParametersFromLocalStorage(() => {
			if (!privateClientId) {
				try {
					fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
						headers: {
							'Content-Type': 'application/json',
							'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
							'x-access-token': cookies.get('token')
						},
					})
						.then(res => {
							this.updateLoadingFlag(false, () => {
								if (res.status !== 200) {
									cookies.remove('token');
									UserProfile.forgetEmail();
									UserProfile.forgetFirstName();
									UserProfile.forgetLastName();
									this.updateLoadingFlag(true, () => {
										this.useInitialFitBounds();
										this.updateLoadingFlag(false, () => {
											this.forceUpdate();
											this.updatePlanIdFromUserProfile(() => {
												this.useInitialFitBounds();
											});
										});
									});
								} else {
									this.updatePlanIdFromUserProfile(() => {
										this.useInitialFitBounds();
									});
								}
							});
						}, res2 => {
							console.log(res2);
							// alert('Something went wrong 3!');
						})
						.catch(err => {
							console.log(err);
							// alert('Something went wrong!');
						});
				} catch (err) {
					console.log(err);
					// alert('Something went wrong 2!');
				}
			} else {
				this.updatePrivateClientConfiguration(privateClientId, () => {
					this.toggleMap(() => {
					});
				});
			}
		});
	}
	getMetaTags = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-page-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const plans = res.data;
				// console.log(res.data)
				for (let i = 0; i < plans.length; i++) {
					if (plans[i].PageUrl == window.location.pathname) {
						this.setState({
							PageUrl: plans[i].PageUrl,
							PageTitle: plans[i].PageTitle,
							PageMetaRobot: plans[i].PageMetaRobot,
							PageMetaDiscription: plans[i].PageMetaDiscription,
						});
						return 0;
					}
					else {
						this.setState({
							PageUrl: ' ',
							PageTitle: ' ',
							PageMetaRobot: ' ',
							PageMetaDiscription: ' ',
						});
					}
				}
			})
			.catch(err => {
			});
	}

	useInitialFitBounds = () => {
		const fitBounds: FitBounds = initialFitBounds;
		const flagToggled: boolean = this.setFitBounds(fitBounds, () => {
			this.toggleMap(() => {
			});
		});
		if (!flagToggled) {
			this.toggleMap(() => {
			});
		}
	}

	updateDimensions = () => {
		this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight }, () => {
			const map = this.getMapRef();
			if (map) {
				map.resize();
			}
		});
	}

	expandMapToolbar = (flagMapToolbarExpanded: boolean) => {
		this.setState({ flagMapToolbarExpanded: flagMapToolbarExpanded }, () => {
		});
	}

	updateBookmarkCount = () => {
		const privateClientId: string = this.getPrivateClientId();
		privateClientId ?

			this.setState({
				bookmarksCountPrivate: JSON.parse(localStorage.getItem('bookmarksprivate')) ? JSON.parse(localStorage.getItem('bookmarksprivate')).length : 0
			})

			:
			this.updatebookmarkcountpublic();

	}
	updatebookmarkcountpublic = () => {
		let bookmarksCount = 0;
		const bookmarksStr = getStorageItem('bookmarks');
		if (bookmarksStr) {
			const bookmarksArr = bookmarksStr.split(',');
			bookmarksCount = bookmarksArr.length;
		}
		this.setState({
			bookmarksCount: bookmarksCount
		});
	}

	componentWillUnmount() {
		document.body.style.overflowY = 'auto';
		window.removeEventListener('resize', this.updateDimensions);
	}

	componentDidMount() {
		this.showListingHelpModal();
		this.updateBookmarkCount();
		if (cookies.get('token') == undefined) {
			this.setCountForNonLoginUsers();
			this.showModalLimitedOfferSignUp();
		}

		window.addEventListener('resize', this.updateDimensions);
		document.body.style.overflowY = 'hidden';

		// if (this.props.location.state.propertyType !== undefined) {
		if (this.props.location.state !== undefined) {
			const filter: LiFilter = new LiFilter();
			if (this.props.location.state.clearfilter) {
				localStorage.setItem('showMajorPorts', 'false')
				localStorage.removeItem('classOneConnectionsDataLayer')
				localStorage.removeItem('shortLinesDataLayer')
				localStorage.setItem('showIntermodalData', 'false')
				localStorage.setItem('showCrudeOilPipelines', 'false')
				localStorage.setItem('showPetroleumProductPipelines', 'false')
				localStorage.setItem('showRoadways', 'false')
				this.setState({
					filter: filter,
				}, () => {
					Object.keys(filter).forEach(key => setStorageItem(key));
					const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
					setStorageItem('selectedPropertyType', selectedPropertyTypeAll);
					filter.selectedPropertyType = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);

				})
			}

			const selectedPropertyType = this.props.location.state.propertyType;
			if (selectedPropertyType == 'Industrial Sites & Properties*Port Terminals*Terminals and Transload Facilities*Warehouse/Distribution Center') {
				this.trackGoogleAnalyticsEvent('go-to-map-click', 'go-to-map-click-homepage')
			}

			// const filter: LiFilter = new LiFilter().copy(this.state.filter);
			// const filter: LiFilter = new LiFilter()
			filter.selectedPropertyType = selectedPropertyType;
			this.setState({
				filter: filter
			}, () => {
				// this.createFilters();
				setStorageItem('selectedPropertyType', selectedPropertyType);
				if (selectedPropertyType != 'Industrial Sites & Properties*Port Terminals*Terminals and Transload Facilities*Warehouse/Distribution Center') {
					this.trackGoogleAnalyticsEvent('Search-' + selectedPropertyType.replaceAll(' ', '-') + '-Click', 'Search-' + selectedPropertyType.replaceAll(' ', '-') + '-Click-Homepage')
				}

			});
		}



		// const privateClientId: string = this.getPrivateClientId();
		// if (privateClientId) {
		// 	this.setState({
		// 		flagPerformZoomForPrivateClient: true,
		// 	}, () => {
		// 		const { listFilteringRef } = this.state;
		// 		if (listFilteringRef) {
		// 			listFilteringRef.createPostponedFilters(() => {
		// 				this.toggleMap();
		// 			});
		// 		}
		// 	});
		// }
	}
	trackGoogleAnalyticsEvent = (event, title) => {
		gtag('event', event, {
			poll_title: title,
		})

	}

	setCountForNonLoginUsers = () => {
		var detailsCount = localStorage.getItem('ListingsDetailsCount')
		if (detailsCount == null) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/get-site-customization-info', {
				id: 1
			})
				.then(function (response) {
					localStorage.setItem("ListingsDetailsCount", JSON.parse(response.data[0].description_data).count)

				})
				.catch(function (error) {
					console.log(error);
				});
		}
	}




	getPrivateClientId(): string {
		const { match } = this.props;
		if (!match || !match.params || !match.params.id) {
			return null;
		}
		return match.params.id;
	}

	hexToRgb(hex: string) {
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		if (result) {
			const r = parseInt(result[1], 16);
			const g = parseInt(result[2], 16);
			const b = parseInt(result[3], 16);
			return r + ',' + g + ',' + b;//return 23,14,45 -> reformat if needed 
		}
		return null;
	}

	updatePrivateClientConfiguration = (privateClientId: string, callback?: () => void) => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-private-clients-configurations',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				email: privateClientId,
			}).then(response => {
				if (response.status === 200) {
					const clientData = JSON.parse(response.data[0].ListingsOptions);
					const { specificClientConfiguration } = this.state;
					var privateobj = {
						'PrivateClientOrg': response.data[0].company,
						'color': this.hexToRgb(clientData.Navbarcolor.substring(1,)),
						'ImgUrl': clientData.logoURL
					}
					localStorage.setItem('PrivateClientData', JSON.stringify(privateobj))
					specificClientConfiguration.flagAvailableClassIConnections = clientData.classI;
					specificClientConfiguration.flagAvailableCrudeOilPipelines = clientData.CrudeOilPipelines;
					specificClientConfiguration.flagAvailablePetroleumPipelines = clientData.PetroleumPipelines;
					specificClientConfiguration.flagAvailableIntermodalTerminals = clientData.IntermodalTerminals;
					specificClientConfiguration.flagAvailableMajorPorts = clientData.MajorPorts;
					specificClientConfiguration.flagAvailableRoadways = clientData.Roadways;
					specificClientConfiguration.colorNavbar = clientData.Navbarcolor;
					specificClientConfiguration.colorSlider = clientData.Slidercolor;
					specificClientConfiguration.Accentcolor = clientData.Accentcolor;
					specificClientConfiguration.outlineInDegrees = Number(clientData.outlineInDegrees);
					const shortLineData: string = joinFilter(clientData.shortlinedata);
					specificClientConfiguration.shortLinesDataAvailable = shortLineData;
					specificClientConfiguration.logoUrl = clientData.logoURL;
					const filteredListing: Array<LabelValue> = JSON.parse(clientData.selectedOption);
					specificClientConfiguration.filteredListing4ShortLines = filteredListing;
					this.setState({
						specificClientConfiguration: new TClientUiConfiguration().copy(specificClientConfiguration),
						shortLinesDataLayer: shortLineData,
						flagPerformZoomForPrivateClient: true,
					}, () => {
						localStorage.setItem('shortLinesDataLayer', shortLineData);
						if (callback) {
							callback();
						}
					});
				} else {
					// alert('Something went wrong!');
				}
			});
	}

	updatePlanIdFromUserProfile = (callback?: () => void) => {
		this.setState({
			planId: '1',
		}, callback);
		// axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
		// 	{
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 		},
		// 		email: UserProfile.getEmail(),
		// 	}).then(response => {
		// 		//console.log(response.data[0])
		// 		if (response.status === 200) {
		// 			this.setState({
		// 				planId: response.data[0].sw_plan_id,
		// 			}, callback);
		// 		} else {
		// 			alert('Something went wrong!');
		// 		}
		// 	});
	}

	showListingHelpModal = () => {
		if (localStorage.getItem('showListingHelpModal')) {
			this.setState({
				showListingHelpModal: false
			})
		} else {
			this.setState({
				showListingHelpModal: true
			})
		}
	}

	hideListingHelpModal = () => {
		this.setState({ showListingHelpModal: false });
	}

	loadParametersFromLocalStorage = (callback: () => void) => {
		this.getModalValuesFromLocalStorage(() => {
			this.getMapStyleFromLocalStorage(() => {
				// this.getShowIntermodalDataFromLocalStorage();
				this.getShowCrudeOilPipelinesFromLocalStorage(() => {
					this.getShowPetroleumProductPipelinesFromLocalStorage(() => {
						this.getShowMajorPortsFromLocalStorage(() => {
							this.getShowRoadwaysFromLocalStorage(() => {
								this.getShowIndustrialSitesFromLocalStorage(() => {
									this.getShowTerminalsAndTransloadsFromLocalStorage(() => {
										this.getShowPortTerminalsFromLocalStorage(() => {
											this.getShowWarehouseFromLocalStorage(() => {
												if (callback) {
													callback();
												}
											});
										});
									});
								});
							});
						});
					});
				});
			});
		});
	}

	updateLoadingFlag = (flagLoading: boolean, callback?: () => void) => {
		this.setState({ flagLoading: flagLoading }, callback);
	}

	getLatitudeToIndexArr = (features: Array<TInFeature>): Array<TIdxCoordinate> => {
		const res: Array<TIdxCoordinate> = [];
		for (let i = 0; i < features.length; i++) {
			const inFeature: TInFeature = features[i];
			const lng = inFeature.geometry.coordinates[0];
			const lat = inFeature.geometry.coordinates[1];
			res.push({ idx: i, coordinate: lat });
		}
		return this.sortToIndexArr(res);
	}

	getLongitudeToIndexArr = (features: Array<TInFeature>): Array<TIdxCoordinate> => {
		const res: Array<TIdxCoordinate> = [];
		for (let i = 0; i < features.length; i++) {
			const inFeature: TInFeature = features[i];
			const lng = inFeature.geometry.coordinates[0];
			const lat = inFeature.geometry.coordinates[1];
			res.push({ idx: i, coordinate: lng });
		}
		return this.sortToIndexArr(res);
	}

	sortToIndexArr = (res: Array<TIdxCoordinate>): Array<TIdxCoordinate> => {
		return res.sort(this.compareToIndexArr);
	}

	compareToIndexArr = (a: TIdxCoordinate, b: TIdxCoordinate) => {
		if (a.coordinate < b.coordinate) {
			return -1;
		}
		if (a.coordinate > b.coordinate) {
			return 1;
		}
		return 0;
	}

	adjustResposeWithIndexData = (response: { features: Array<TInFeature> }): {
		features: Array<TInFeature>
		, latitudeSorted: Array<TIdxCoordinate>
		, longitudeSorted: Array<TIdxCoordinate>
	} => {
		const res: {
			features: Array<TInFeature>
			, latitudeSorted: Array<TIdxCoordinate>
			, longitudeSorted: Array<TIdxCoordinate>
		} = {
			...response
			, latitudeSorted: []
			, longitudeSorted: []
		};
		res.latitudeSorted = this.getLatitudeToIndexArr(response.features);
		res.longitudeSorted = this.getLongitudeToIndexArr(response.features);
		return res;
	}

	updateResponse = (flagLoading: boolean
		, response: { features: Array<TInFeature> } = { features: [] }
		, callback?: () => void
	) => {
		const responseAdjusted = this.adjustResposeWithIndexData(response);
		this.setState({
			flagLoading: flagLoading
			, response: responseAdjusted
		}, callback);
	}

	getModalValuesFromLocalStorage = (callback: () => void) => {
		this.setState({
			classOneConnectionsDataLayer: getStorageItem('classOneConnectionsDataLayer'),
			shortLinesDataLayer: getStorageItem('shortLinesDataLayer'),
		}, callback);
	}

	getMapStyleFromLocalStorage = (callback: () => void) => {
		const showNumberColumnsInListing: string = getStorageItem('showNumberColumnsInListing');
		let showNumberColumnsInListingNum: number = parseInt(showNumberColumnsInListing);
		if (isNaN(showNumberColumnsInListingNum) || showNumberColumnsInListingNum === undefined || showNumberColumnsInListingNum === null) {
			showNumberColumnsInListingNum = 2;
		}
		showNumberColumnsInListingNum = (showNumberColumnsInListingNum < 0 || showNumberColumnsInListingNum > 2)
			? 2 : showNumberColumnsInListingNum;
		const renderSatelliteMap: string = getStorageItem('renderSatelliteMap');
		const mapStyle: string = getStorageItem('mapStyle');
		this.setState({
			showNumberColumnsInListing: showNumberColumnsInListingNum,
			renderSatelliteMap: renderSatelliteMap ? renderSatelliteMap : 'true',
			mapStyle: mapStyle,
			flagStyleLoading: true,
		}, callback);
	}

	getShowIntermodalDataFromLocalStorage = (callback: () => void) => {
		const showIntermodalData: string = getStorageItem('showIntermodalData');
		this.setState({
			showIntermodalData: (showIntermodalData ? ('true' === showIntermodalData) : false)
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	getShowCrudeOilPipelinesFromLocalStorage = (callback: () => void) => {
		const showCrudeOilPipelines: string = getStorageItem('showCrudeOilPipelines');
		this.setState({
			showCrudeOilPipelines: (showCrudeOilPipelines ? ('true' === showCrudeOilPipelines) : false)
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	getShowPetroleumProductPipelinesFromLocalStorage = (callback: () => void) => {
		const showPetroleumProductPipelines: string = getStorageItem('showPetroleumProductPipelines');
		this.setState({
			showPetroleumProductPipelines: (showPetroleumProductPipelines ? ('true' === showPetroleumProductPipelines) : false)
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	getShowMajorPortsFromLocalStorage = (callback: () => void) => {
		const showMajorPorts: string = getStorageItem('showMajorPorts');
		this.setState({
			showMajorPorts: (showMajorPorts ? ('true' === showMajorPorts) : false)
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	getShowRoadwaysFromLocalStorage = (callback: () => void) => {
		const showRoadways: string = getStorageItem('showRoadways');
		this.setState({
			showRoadways: (showRoadways ? ('true' === showRoadways) : false)
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	getShowIndustrialSitesFromLocalStorage = (callback: () => void) => {
		const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
		const selectedPropertyType: string = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
		const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
		this.setState({
			showIndustrialSites: flagIndustrialSitesProperties
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	copyShareableLink = () => {
		alert('Link copied in clipboard!')
	}

	getShowTerminalsAndTransloadsFromLocalStorage = (callback: () => void) => {
		const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
		const selectedPropertyType: string = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
		const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
		this.setState({
			showTerminalsAndTransloads: flagTerminalsTransload
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	getShowPortTerminalsFromLocalStorage = (callback: () => void) => {
		const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
		const selectedPropertyType: string = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
		const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
		this.setState({
			showPortTerminals: flagPortTerminals
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	getShowWarehouseFromLocalStorage = (callback: () => void) => {
		const selectedPropertyTypeAll = getSelectedPropertyTypeAll();
		const selectedPropertyType: string = getStorageItem('selectedPropertyType', selectedPropertyTypeAll);
		const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
		this.setState({
			showWarehouses: flagWarehouse
		}, () => {
			if (callback) {
				callback();
			}
		});
	}

	filterResults(filter: LiFilter, intermediateFeatures: Array<TInFeature>, flagPostponedCreate: boolean, callback?: () => void) {
		const { state } = this;
		const {
			specificClientConfiguration
			, flagPerformZoomForPrivateClient
			, flagDisableZoom
		} = state;
		const { searchByKeywordValue, selectedPropertyType } = filter;
		let fitBounds: FitBounds = flagPostponedCreate ? state.fitBounds : this.getMapFitBounds();
		let parameter;

		if (specificClientConfiguration.filteredListing4ShortLines && specificClientConfiguration.filteredListing4ShortLines.length > 0) {
			parameter = 'shortLines';
			const propertyTypeArr = splitFilter(joinFilterV(specificClientConfiguration.filteredListing4ShortLines));
			intermediateFeatures = createIntermediateArr(propertyTypeArr, intermediateFeatures, parameter);
		}

		if (selectedPropertyType !== '' && selectedPropertyType !== null) {
			parameter = 'propertyType';
			const propertyTypeArr = [selectedPropertyType];
			intermediateFeatures = createIntermediateArr(propertyTypeArr, intermediateFeatures, parameter, selectedPropertyType, '');
		} else {
			intermediateFeatures.length = 0;
		}

		if (state.organizationName) {
			parameter = 'organizationName';
			const organizationNameArr = [state.organizationName];
			intermediateFeatures = createIntermediateArr(organizationNameArr, intermediateFeatures, parameter, '', '');
		}

		const flagIndustrialSitesProperties: boolean = selectedPropertyType.includes('Industrial Sites & Properties');
		const flagPortTerminals: boolean = selectedPropertyType.includes('Port Terminals');
		const flagTerminalsTransload: boolean = selectedPropertyType.includes('Terminals and Transload Facilities');
		const flagWarehouse: boolean = selectedPropertyType.includes('Warehouse/Distribution Center');
		const flagMultipurpose: boolean = selectedPropertyType.includes('Multipurpose');
		if (flagIndustrialSitesProperties || flagMultipurpose) {
			if (filter.selectedPropertyFor !== '' && filter.selectedPropertyFor !== null) {
				if (filter.selectedPropertyFor !== 'Lease and/or Sale') {
					parameter = 'propertyFor';
					const propertyForArr = [filter.selectedPropertyFor];
					intermediateFeatures = createIntermediateArr(propertyForArr, intermediateFeatures, parameter, selectedPropertyType, '');
				}
			}

			if (filter.minAcreage !== '') {
				parameter = 'acreage';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.minAcreage, intermediateFeatures, 'min');
			}

			if (filter.maxAcreage !== '') {
				parameter = 'acreage';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.maxAcreage, intermediateFeatures, 'max');
			}

			if (filter.minPrice !== '') {
				parameter = 'price';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.minPrice, intermediateFeatures, 'min');
			}

			if (filter.maxPrice !== '') {
				parameter = 'price';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.maxPrice, intermediateFeatures, 'max');
			}

			if (filter.siteZoning !== '') {
				parameter = 'zoning';
				const siteZoningArr = [filter.siteZoning];
				intermediateFeatures = createIntermediateArr(siteZoningArr, intermediateFeatures, parameter, 'Sites', '');
			}

			if (filter.selectedBuildingOption !== '' && filter.selectedBuildingOption !== null) {
				parameter = 'building';
				const selectedBuildingOptionArr = [filter.selectedBuildingOption];
				intermediateFeatures = createIntermediateArr(selectedBuildingOptionArr, intermediateFeatures, parameter, 'Sites', '');
			}

			if (filter.siteMinSquareFeet !== '') {
				parameter = 'squareFeet';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.siteMinSquareFeet, intermediateFeatures, 'min');
			}

			if (filter.siteMaxSquareFeet !== '') {
				parameter = 'squareFeet';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.siteMaxSquareFeet, intermediateFeatures, 'max');
			}

			if (filter.siteMinInteriorRoofHeight !== '') {
				parameter = 'interiorRoofHeight';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.siteMinInteriorRoofHeight, intermediateFeatures, 'min');
			}

			if (filter.siteSuitableFor !== '' && filter.siteSuitableFor !== null) {
				parameter = 'suitableFor';
				const siteSuitableForArr = [filter.siteSuitableFor];
				intermediateFeatures = createIntermediateArr(siteSuitableForArr, intermediateFeatures, parameter, 'Sites', '');
			}

			if (filter.sitePipelineAccess != '' && filter.sitePipelineAccess !== null) {
				parameter = 'pipelineAccess';
				const sitePipelineAccessArr = [filter.sitePipelineAccess];
				intermediateFeatures = createIntermediateArr(sitePipelineAccessArr, intermediateFeatures, parameter, 'Sites', '');
			}

			// if (state.selectedWaterSewer !== '' && state.selectedWaterSewer !== null) {
			// 	parameter = 'waterSewerInPlace';
			// 	const selectedWaterSewerArr = [state.selectedWaterSewer];
			// 	intermediateFeatures = createIntermediateArr(selectedWaterSewerArr, intermediateFeatures, parameter, 'Sites', '');
			// }

			if (filter.selectedPowerToSite !== '' && filter.selectedPowerToSite !== null) {
				parameter = 'electricPowerInPlace';
				const selectedPowerToSiteArr = [filter.selectedPowerToSite];
				intermediateFeatures = createIntermediateArr(selectedPowerToSiteArr, intermediateFeatures, parameter, 'Sites', '');
			}

			if (filter.minServiceVoltage !== '') {
				parameter = 'serviceVoltage';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.minServiceVoltage, intermediateFeatures, 'min');
			}

			if (filter.maxServiceVoltage !== '') {
				parameter = 'serviceVoltage';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.maxServiceVoltage, intermediateFeatures, 'max');
			}

			if (filter.minDistanceToSubstation !== '') {
				parameter = 'distanceToSubstation';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.minDistanceToSubstation, intermediateFeatures, 'min');
			}

			if (filter.maxDistanceToSubstation !== '') {
				parameter = 'distanceToSubstation';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.maxDistanceToSubstation, intermediateFeatures, 'max');
			}

			if (filter.naturalGasInPlace !== '' && filter.naturalGasInPlace !== null) {
				parameter = 'naturalGasInPlace';
				const naturalGasInPlaceArr = [filter.naturalGasInPlace];
				intermediateFeatures = createIntermediateArr(naturalGasInPlaceArr, intermediateFeatures, parameter, 'Sites', '');
			}

			if (filter.minLineDiameter !== '') {
				parameter = 'lineDiameter';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.minLineDiameter, intermediateFeatures, 'min');
			}

			if (filter.maxLineDiameter !== '') {
				parameter = 'lineDiameter';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.maxLineDiameter, intermediateFeatures, 'max');
			}

			if (filter.siteEnvionmental !== '' && filter.siteEnvionmental !== null) {
				parameter = 'environmental';
				const siteEnvionmentalArr = [filter.siteEnvionmental];
				intermediateFeatures = createIntermediateArr(siteEnvionmentalArr, intermediateFeatures, parameter, 'Sites', '');
			}

			if (filter.airAttainmentStatus !== '' && filter.airAttainmentStatus !== null) {
				parameter = 'airAttainmentStatus';
				const airAttainmentStatusArr = [filter.airAttainmentStatus];
				intermediateFeatures = createIntermediateArr(airAttainmentStatusArr, intermediateFeatures, parameter, 'Sites', '');
			}
		}

		// // Ports, Terminals and Transloads Search & Filters
		if (flagPortTerminals || flagTerminalsTransload || flagMultipurpose) {
			if (filter.capabilities !== '' && filter.capabilities !== null) {
				parameter = 'capabilities';
				const capabilitiesArr = [filter.capabilities];
				intermediateFeatures = createIntermediateArr(capabilitiesArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.equipments !== '' && filter.equipments !== null) {
				parameter = 'equipments';
				const equipmentsArr = [filter.equipments];
				intermediateFeatures = createIntermediateArr(equipmentsArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.railcarStorageCapacity !== '') {
				parameter = 'railcarStorageCapacity';
				const railcarStorageCapacityArr = [filter.railcarStorageCapacity];
				intermediateFeatures = createIntermediateArr(railcarStorageCapacityArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.productsHandled !== '' && filter.productsHandled !== null) {
				parameter = 'productsHandled';
				const productsHandledArr = [filter.productsHandled];
				intermediateFeatures = createIntermediateArr(productsHandledArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.productStorage !== '' && filter.productStorage !== null) {
				parameter = 'productStorage';
				const productStorageArr = [filter.productStorage];
				intermediateFeatures = createIntermediateArr(productStorageArr, intermediateFeatures, parameter, '', '');
			}

			if (state.indoorStorage !== '' && state.indoorStorage !== null) {
				parameter = 'indoorStorage';
				const indoorStorageArr = [state.indoorStorage];
				intermediateFeatures = createIntermediateArr(indoorStorageArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.pttMinAcreage) {
				parameter = 'pttAcreage';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.pttMinAcreage, intermediateFeatures, 'min');
			}

			if (filter.pttMaxAcreage) {
				parameter = 'pttAcreage';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.pttMaxAcreage, intermediateFeatures, 'max');
			}
		}

		// Warehouse Criteria Search & Filters
		if (flagWarehouse || flagMultipurpose) {
			if (filter.selectedPropertyFor1 !== '' && filter.selectedPropertyFor1 !== null) {
				if (filter.selectedPropertyFor1 !== 'Lease and/or Sale') {
					parameter = 'propertyFor';
					const propertyForArr = [filter.selectedPropertyFor1];
					intermediateFeatures = createIntermediateArr(propertyForArr, intermediateFeatures, parameter, selectedPropertyType, '');
				}
			}

			if (filter.warehouseMinPrice) {
				parameter = 'warehousePrice';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMinPrice, intermediateFeatures, 'min');
			}

			if (filter.warehouseMaxPrice) {
				parameter = 'warehousePrice';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMaxPrice, intermediateFeatures, 'max');
			}

			if (filter.warehouseCapabilities) {
				parameter = 'warehouseCapabilities';
				const warehouseCapabilitiesArr = [filter.warehouseCapabilities];
				intermediateFeatures = createIntermediateArr(warehouseCapabilitiesArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.warehouseMinSize !== '') {
				parameter = 'warehouseMinSize';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMinSize, intermediateFeatures, 'min');
			}

			if (filter.warehouseMaxSize !== '') {
				parameter = 'warehouseMaxSize';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.warehouseMaxSize, intermediateFeatures, 'max');
			}

			if (filter.temperatureControl !== '' && filter.temperatureControl !== null) {
				parameter = 'temperatureControl';
				const temperatureControlArr = [filter.temperatureControl];
				intermediateFeatures = createIntermediateArr(temperatureControlArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.parkingSpaces !== '') {
				parameter = 'parkingSpaces';
				const parkingSpacesArr = [filter.parkingSpaces];
				intermediateFeatures = createIntermediateArr(parkingSpacesArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.foodGrade !== '' && filter.foodGrade !== null) {
				parameter = 'foodGrade';
				const foodGradeArr = [filter.foodGrade];
				intermediateFeatures = createIntermediateArr(foodGradeArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.freeTradeZone !== '' && filter.freeTradeZone !== null) {
				parameter = 'freeTradeZone';
				const freeTradeZoneArr = [filter.freeTradeZone];
				intermediateFeatures = createIntermediateArr(freeTradeZoneArr, intermediateFeatures, parameter, '', '');
			}

			if (filter.minRoofHeight) {
				parameter = 'roofHeight';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.minRoofHeight, intermediateFeatures, 'min');
			}

			if (filter.maxRoofHeight) {
				parameter = 'roofHeight';
				intermediateFeatures = createMinMaxValueArr(parameter, filter.maxRoofHeight, intermediateFeatures, 'max');
			}
		}

		// // Marine Access Search & Filters
		if (filter.marineAccess !== '' && filter.marineAccess !== null) {
			parameter = 'marineAccess';
			const marineAccessArr = [filter.marineAccess];
			intermediateFeatures = createIntermediateArr(marineAccessArr, intermediateFeatures, parameter, '', '');
		}

		if (filter.bargeAccess !== '' && filter.bargeAccess !== null) {
			parameter = 'bargeAccess';
			const selectedSiteBargeAccessArr = [filter.bargeAccess];
			intermediateFeatures = createIntermediateArr(selectedSiteBargeAccessArr, intermediateFeatures, parameter, '', '');
		}

		if (filter.docInPlace !== '' && filter.docInPlace !== null) {
			parameter = 'docInPlace';
			const docInPlaceArr = [filter.docInPlace];
			intermediateFeatures = createIntermediateArr(docInPlaceArr, intermediateFeatures, parameter, '', '');
		}

		if (filter.navigableWater !== '') {
			parameter = 'distancetoWater';
			const navigableWaterArr = [filter.navigableWater];
			intermediateFeatures = createIntermediateArr(navigableWaterArr, intermediateFeatures, parameter, '', '');
		}

		if (filter.waterways !== '' && filter.waterways !== null) {
			parameter = 'waterways';
			const waterwaysArr = [filter.waterways];
			intermediateFeatures = createIntermediateArr(waterwaysArr, intermediateFeatures, parameter, '', '');
		}

		// Serving Rail Carriers Search & Filters

		// this.setState({
		// 	shortLines:localStorage.getItem('shortLinesLabel') ? localStorage.getItem('shortLinesLabel'):''
		// })
		if (filter.shortLinesLabel !== '' && filter.shortLinesLabel !== null) {
			parameter = 'shortLines';
			const shortLinesArr = [filter.shortLinesLabel];
			intermediateFeatures = createIntermediateArr(shortLinesArr, intermediateFeatures, parameter, '', '');
		}

		// code to create array if multi-served class I conn and/or Class I conn selected
		const tempIntermediateFeatures = intermediateFeatures;
		const classOneMultiservedArr = [];
		if (filter.classOneMultiservedCheckbox && filter.classOneConnections) {
			// create array of records which contains multiple class I conn
			for (let k = 0; k < tempIntermediateFeatures.length; k++) {
				if (tempIntermediateFeatures[k].properties.classOneConnections.includes('*')) {
					classOneMultiservedArr.push(tempIntermediateFeatures[k]);
				}
			}

			parameter = 'classOneConnections';
			const siteClassOneConnectionsArr = [filter.classOneConnections];
			intermediateFeatures = createIntermediateArr(siteClassOneConnectionsArr, intermediateFeatures, parameter, '', '');

			// concat above 2 arrays
			Array.prototype.push.apply(intermediateFeatures, classOneMultiservedArr);

		} else if (filter.classOneMultiservedCheckbox) {
			// create array of records which contains multiple class I conn
			for (let k = 0; k < tempIntermediateFeatures.length; k++) {
				if (tempIntermediateFeatures[k].properties.classOneConnections.includes('*')) {
					classOneMultiservedArr.push(tempIntermediateFeatures[k]);
				}
			}
			intermediateFeatures = classOneMultiservedArr;

		} else if (filter.classOneConnections) {
			parameter = 'classOneConnections';
			const siteClassOneConnectionsArr = [filter.classOneConnections];
			intermediateFeatures = createIntermediateArr(siteClassOneConnectionsArr, intermediateFeatures, parameter, '', '');
		} else {
			console.log('Both class one conn and multi-served checkbox are not selected')
		}

		if (filter.railInPlace !== '' && filter.railInPlace !== null) {
			parameter = 'railInPlace';
			const selectedRailInPlaceArr = [filter.railInPlace];
			intermediateFeatures = createIntermediateArr(selectedRailInPlaceArr, intermediateFeatures, parameter, '', '');
		}

		if (filter.railcarStorage !== '' && filter.railcarStorage !== null) {
			parameter = 'railcarStorage';
			const railcarStorageArr = [filter.railcarStorage];
			intermediateFeatures = createIntermediateArr(railcarStorageArr, intermediateFeatures, parameter, '', '');
		}

		if (filter.unitTrainCapable) {
			parameter = 'unitTrainCapable';
			const unitTrainCapableArr = [filter.unitTrainCapable];
			intermediateFeatures = createIntermediateArr(unitTrainCapableArr, intermediateFeatures, parameter, '', '');
		}

		if (filter.railcarCapacity) {
			parameter = 'railcarCapacity';
			intermediateFeatures = createMinMaxValueArr(parameter, filter.railcarCapacity, intermediateFeatures, 'min');
		}

		// Additional Attributes Search and Filters
		if (state.parkingCapacity !== '' && state.parkingCapacity !== null) {
			parameter = 'parkingCapacity';
			const parkingCapacityArr = [state.parkingCapacity];
			intermediateFeatures = createIntermediateArr(parkingCapacityArr, intermediateFeatures, parameter, '', '');
		}

		if (state.nearestInterstate !== '') {
			parameter = 'nearestInterstate';
			const nearestInterstateArr = [state.nearestInterstate];
			intermediateFeatures = createIntermediateArr(nearestInterstateArr, intermediateFeatures, parameter, '', '');
		}

		if (state.milesToInterstate !== '') {
			parameter = 'milesToInterstate';
			const milesToInterstateArr = [state.milesToInterstate];
			intermediateFeatures = createIntermediateArr(milesToInterstateArr, intermediateFeatures, parameter, '', '');
		}

		if (state.nearestAirport !== '') {
			parameter = 'nearestAirport';
			const nearestAirportArr = [state.nearestAirport];
			intermediateFeatures = createIntermediateArr(nearestAirportArr, intermediateFeatures, parameter, '', '');
		}

		if (state.milesToAirport !== '') {
			parameter = 'milesToAirport';
			const milesToAirportArr = [state.milesToAirport];
			intermediateFeatures = createIntermediateArr(milesToAirportArr, intermediateFeatures, parameter, '', '');
		}

		if (state.silos !== '' && state.silos !== null) {
			parameter = 'silos';
			const silosArr = [state.silos];
			intermediateFeatures = createIntermediateArr(silosArr, intermediateFeatures, parameter, '', '');
		}

		intermediateFeatures = removeDuplicatesFromArr(intermediateFeatures);

		const originalResponse = getStorageItem('originalResponse');
		const geoResponse: { features: Array<TInFeature> } = originalResponse && originalResponse.length > 0 ? JSON.parse(originalResponse) : [];

		const sitesGeoJSONArray: { features: Array<TInFeature> } = { features: null };
		const warehousesGeoJSONArray: { features: Array<TInFeature> } = { features: null };
		const portTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: null };
		const otherTerminalsGeoJSONArray: { features: Array<TInFeature> } = { features: null };
		const multiPurposeGeoJSONArray: { features: Array<TInFeature> } = { features: null };

		// Filter real estates that contain the values from the search field in properties
		const minSymbolsToSearch = 2
		if (searchByKeywordValue && searchByKeywordValue.length >= minSymbolsToSearch) {
			intermediateFeatures = this.filterEstatesByKeywords(intermediateFeatures, filter.searchByKeywordValue);
		}

		const realEstates = this.filterEstatesByTypes(intermediateFeatures);

		geoResponse.features = intermediateFeatures;
		sitesGeoJSONArray.features = realEstates.industrialSites;
		portTerminalsGeoJSONArray.features = realEstates.portTerminals;
		otherTerminalsGeoJSONArray.features = realEstates.terminalsAndFacilities;
		warehousesGeoJSONArray.features = realEstates.warehouseAndDistributionCenter;
		multiPurposeGeoJSONArray.features = realEstates.multiPurpose;
		if (flagPerformZoomForPrivateClient) {
			let deltaPlus: number = 4.0;
			if (specificClientConfiguration.outlineInDegrees) {
				deltaPlus = specificClientConfiguration.outlineInDegrees;
			}
			fitBounds = this.calculateBounds4Features(intermediateFeatures, deltaPlus, fitBounds);
		} else if (!flagPostponedCreate && !flagDisableZoom) {
			fitBounds = this.calculateBounds4Features(intermediateFeatures, 2.2, fitBounds);
		}
		const flagMajorports: boolean = getStorageItem('showMajorPorts') != 'true' ? false : true;
		const flagshowIntermodalData: boolean = getStorageItem('showIntermodalData') != 'true' ? false : true;
		const flagshowCrudeOilPipelines: boolean = getStorageItem('showCrudeOilPipelines') != 'true' ? false : true;
		const flagshowPetroleumProductPipelines: boolean = getStorageItem('showPetroleumProductPipelines') != 'true' ? false : true;
		const flagshowRoadways: boolean = getStorageItem('showRoadways') != 'true' ? false : true;
		const flagclassOneConnectionsDataLayer: string = getStorageItem('classOneConnectionsDataLayer') ? getStorageItem('classOneConnectionsDataLayer') : '';
		const flagshortLinesDataLayer: string = getStorageItem('shortLinesDataLayer') ? getStorageItem('shortLinesDataLayer') : '';
		const showIndustrialSites = flagIndustrialSitesProperties;
		const showTerminalsAndTransloads = flagTerminalsTransload;
		const showPortTerminals = flagPortTerminals;
		const showWarehouses = flagWarehouse;
		const responseAdjusted = this.adjustResposeWithIndexData(geoResponse);
		this.setState({
			response: responseAdjusted
			, sitesGeoJSONArray: sitesGeoJSONArray
			, warehousesGeoJSONArray: warehousesGeoJSONArray
			, portTerminalsGeoJSONArray: portTerminalsGeoJSONArray
			, otherTerminalsGeoJSONArray: otherTerminalsGeoJSONArray
			, multiPurposeGeoJSONArray: multiPurposeGeoJSONArray
			, cityCoordinates: filter.cityCoordinates
			, fitBounds: fitBounds
			, flagPerformZoomForPrivateClient: flagPerformZoomForPrivateClient ? (intermediateFeatures.length > 0 ? false : true) : false
			, flagDisableZoom: false
			, classOneConnectionsDataLayer: flagclassOneConnectionsDataLayer
			, shortLinesDataLayer: flagshortLinesDataLayer
			, showMajorPorts: flagMajorports
			, showRoadways: flagshowRoadways
			, showIntermodalData: flagshowIntermodalData
			, showCrudeOilPipelines: flagshowCrudeOilPipelines
			, showPetroleumProductPipelines: flagshowPetroleumProductPipelines
			, showIndustrialSites: showIndustrialSites
			, showTerminalsAndTransloads: showTerminalsAndTransloads
			, showPortTerminals: showPortTerminals
			, showWarehouses: showWarehouses
		}, callback);
	}

	filterByBounds = (intermediateFeatures: Array<TInFeature>
		, latitudeSorted: Array<TIdxCoordinate>
		, longitudeSorted: Array<TIdxCoordinate>
		, bounds: FitBounds
		, deltaPlus: number = 0.0
	): Array<TInFeature> => {
		if (intermediateFeatures.length <= 0) {
			return [];
		}
		let lngMin = bounds[0][0], lngMax = bounds[1][0];
		let latMin = bounds[0][1], latMax = bounds[1][1];
		lngMin -= deltaPlus;
		latMin -= deltaPlus;
		lngMax += deltaPlus;
		latMax += deltaPlus;
		bounds = [[lngMin, latMin], [lngMax, latMax]];
		// const res: Array<TInFeature> = intermediateFeatures.filter((inFeature: TInFeature) => {
		// 	return this.isInBounds(inFeature, bounds);
		// });
		const res: Array<TInFeature> = [];
		let idxLatMin = this.searchCoordinate(latitudeSorted, latMin, this.compareToIndexArr);
		let idxLatMax = this.searchCoordinate(latitudeSorted, latMax, this.compareToIndexArr);
		let idxLngMin = this.searchCoordinate(longitudeSorted, lngMin, this.compareToIndexArr);
		let idxLngMax = this.searchCoordinate(longitudeSorted, lngMax, this.compareToIndexArr);
		//
		let arrSortedFirst: Array<TIdxCoordinate> = null;
		let idxFirstMin: number = null;
		let idxFirstMax: number = null;
		let arrSortedSecond: Array<TIdxCoordinate> = null;
		let idxSecondMin: number = null;
		let idxSecondMax: number = null;
		if (idxLatMax - idxLatMin < idxLngMax - idxLngMin) {
			arrSortedFirst = latitudeSorted;
			idxFirstMin = idxLatMin;
			idxFirstMax = idxLatMax;
			arrSortedSecond = longitudeSorted;
			idxSecondMin = idxLngMin;
			idxSecondMax = idxLngMax;
		} else {
			arrSortedFirst = longitudeSorted;
			idxFirstMin = idxLngMin;
			idxFirstMax = idxLngMax;
			arrSortedSecond = latitudeSorted;
			idxSecondMin = idxLatMin;
			idxSecondMax = idxLatMax;
		}
		const idxSetMinSize: Set<number> = new Set<number>();
		for (let i = idxFirstMin; i <= idxFirstMax && i < arrSortedFirst.length; i++) {
			const idx = arrSortedFirst[i].idx;
			idxSetMinSize.add(idx);
		}
		const idxSet: Set<number> = new Set<number>();
		for (let i = idxSecondMin; i <= idxSecondMax && i < arrSortedSecond.length; i++) {
			const idx = arrSortedSecond[i].idx;
			if (idxSetMinSize.has(idx)) {
				idxSet.add(idx);
			}
		}
		//
		idxSet.forEach((idx: number) => {
			const inFeature: TInFeature = intermediateFeatures[idx];
			res.push(inFeature);
		});
		return res;
	}

	searchCoordinate(arr: Array<TIdxCoordinate>
		, coordinate: number
		, compareFn: (a: TIdxCoordinate, b: TIdxCoordinate
		) => number): number {
		let res = this.binarySearch(arr, { idx: -1, coordinate: coordinate }, this.compareToIndexArr);
		res = this.converIndexToInsertFromBinarySearch(res);
		return res;
	}

	converIndexToInsertFromBinarySearch(idx: number): number {
		if (idx < 0) {
			idx = - idx - 1;
		}
		return idx;
	}

	binarySearch(arr: Array<TIdxCoordinate>
		, element: TIdxCoordinate
		, compareFn: (a: TIdxCoordinate, b: TIdxCoordinate
		) => number) {
		let m = 0;
		let n = arr.length - 1;
		while (m <= n) {
			const k = (n + m) >> 1;
			const cmp = compareFn(element, arr[k]);
			if (cmp > 0) {
				m = k + 1;
			} else if (cmp < 0) {
				n = k - 1;
			} else {
				return k;
			}
		}
		return -m - 1;
	}

	isAnyInBounds = (intermediateFeatures: Array<TInFeature>, bounds: FitBounds): boolean => {
		let res: boolean = false;
		for (let i = 0; !res && i < intermediateFeatures.length; i++) {
			if (this.isInBounds(intermediateFeatures[i], bounds)) {
				res = true;
			}
		}
		return res;
	}

	isInBounds = (inFeature: TInFeature, bounds: FitBounds): boolean => {
		const lngMin = bounds[0][0], lngMax = bounds[1][0];
		const latMin = bounds[0][1], latMax = bounds[1][1];
		const lng = inFeature.geometry.coordinates[0];
		const lat = inFeature.geometry.coordinates[1];
		if (lng < lngMin || lng > lngMax) {
			return false;
		}
		if (lat < latMin || lat > latMax) {
			return false;
		}
		return true;
	}

	calculateBounds4Features = (intermediateFeatures: Array<TInFeature>, deltaPlus: number = 0.0, boudsDefault: FitBounds = null): FitBounds => {
		let res: FitBounds = boudsDefault;
		if (intermediateFeatures.length <= 0) {
			return res;
		}
		let lngMin = 1000000000, lngMax = -1000000000;
		let latMin = 1000000000, latMax = -1000000000;
		for (let i = 0; i < intermediateFeatures.length; i++) {
			const lng = intermediateFeatures[i].geometry.coordinates[0];
			const lat = intermediateFeatures[i].geometry.coordinates[1];
			if (lngMin > lng) {
				lngMin = lng;
			}
			if (lngMax < lng) {
				lngMax = lng;
			}
			if (latMin > lat) {
				latMin = lat;
			}
			if (latMax < lat) {
				latMax = lat;
			}
		}
		lngMin -= deltaPlus;
		latMin -= deltaPlus;
		lngMax += deltaPlus;
		latMax += deltaPlus;
		res = [[lngMin, latMin], [lngMax, latMax]];
		return res;
	}

	filterEstatesByKeywords = (data: Array<TInFeature>, searchByKeywordValue: string): Array<TInFeature> => {
		const keywords = searchByKeywordValue.toLowerCase().split(',');
		const minSymbolsToSearch = 2;
		const multipleKeywordsDelimeter = '+';
		return data.filter(({ properties: { ...restProps } = {} }) => {
			// convert real estates attributes values into a single string
			const props = Object.values(restProps)
				.filter((value) => value && typeof value !== 'object')
				.join(',')
				.toLowerCase();
			return keywords.some((keyword) => {
				// find real estate which includes all keywords
				if (keyword.includes(multipleKeywordsDelimeter)) {
					const multipleKeywords = keyword
						.split('+')
						.filter((keyword) => keyword && keyword.length >= minSymbolsToSearch);
					if (!multipleKeywords.length) return true;
					return multipleKeywords.every((keyword) => props.includes(keyword));
				}
				return props.includes(keyword);
			});
		});
	}

	filterEstatesByTypes = (data: Array<TInFeature>): any => {
		const types = {
			industrialSites: 'Industrial Sites & Properties',
			portTerminals: 'Port Terminals',
			terminalsAndFacilities: 'Terminals and Transload Facilities',
			warehouseAndDistributionCenter: 'Warehouse/Distribution Center',
			multiPurpose: 'Multipurpose',
		}
		const result = Object.keys(types).reduce((obj, key) => {
			const groupedByType: Array<TInFeature> = data.filter(({ properties: { propertyType = '' } = {} }) => {
				const propertyTypeArr: Array<string> = [
					'Industrial Sites & Properties*Port Terminals*Warehouse/Distribution Center',
					'Industrial Sites & Properties*Terminals and Transload Facilities*Warehouse/Distribution Center',
					'Industrial Sites & Properties*Port Terminals',
					'Industrial Sites & Properties*Terminals and Transload Facilities',
					'Port Terminals*Warehouse/Distribution Center',
					'Terminals and Transload Facilities*Warehouse/Distribution Center',
					'Industrial Sites & Properties*Warehouse/Distribution Center',
				];
				if (types[key] === 'Multipurpose') {
					if (propertyTypeArr.includes(propertyType)) {
						return true;
					}
				}
				if (propertyType) {
					return propertyType === types[key];
				}
				return false;
			});
			obj[key] = groupedByType;
			return obj;
		}, {});
		return result;
	}

	showQVModal(GoFor) {
		if (GoFor == "Modal") {
			if (cookies.get('token') !== undefined) {
				this.changeMapStyleInQV();
				this.setState({ modalVisible: true }, () => {
					const bookmarksStr = getStorageItem('bookmarks');
					if (bookmarksStr) {
						const bookmarksArr: Array<string> = bookmarksStr.split(',');
						if (bookmarksArr.includes('' + this.state.place.rec_id)) {
							document.getElementById('bookmark-btn').classList.add('color-faint-blue');
						}
					}
				});
			}
			else {
				var latestDetailsCount = Number(localStorage.getItem('ListingsDetailsCount'))
				if (latestDetailsCount > 0) {
					this.changeMapStyleInQV();
					localStorage.setItem("ListingsDetailsCount", String(latestDetailsCount - 1))
					this.setState({ modalVisible: true }, () => {
						const bookmarksStr = getStorageItem('bookmarks');
						if (bookmarksStr) {
							const bookmarksArr: Array<string> = bookmarksStr.split(',');
							if (bookmarksArr.includes('' + this.state.place.rec_id)) {
								document.getElementById('bookmark-btn').classList.add('color-faint-blue');
							}
						}
					});
				} else {
					this.handleOpen();
				}

			}
		} else {
			var url_string = "https://share.industrialnavigator.com/industrialnavigator.php?" + this.state.place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + this.state.place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + this.state.place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + this.state.place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + this.state.place.rec_id

			if (cookies.get('token') !== undefined) {
				window.open(url_string, "_blank")
			}
			else {
				var latestDetailsCount = Number(localStorage.getItem('ListingsDetailsCount'))
				if (latestDetailsCount > 0) {
					window.open(url_string, "_blank")
				} else {
					this.handleOpen();
				}

			}
		}


	}
	handleOpen = () => {
		this.setState({ showLoginModal: true });
		this.trackGoogleAnalyticsEvent('map_reg_open', 'map_reg_open');
	}
	handleClose = () => {
		this.setState({ showLoginModal: false });
		this.trackGoogleAnalyticsEvent('map_reg_abd', 'map_reg_abd');
	}
	handleShowForgotPasswordModal = () => {
		this.setState({ showForgotPasswordModal: true });
		this.handleClose();
	}

	handleForgotPasswordClose = () => {
		this.setState({ showForgotPasswordModal: false });
		this.handleOpen();
	}

	setFPEmail = (event) => {
		this.setState({ fpEmail: event.target.value });
	}

	sendFPEmail = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
			{
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				fpEmail: this.state.fpEmail
			}).then(response => {
				document.getElementById('fp_success').style.display = 'block';
				document.getElementById('fp_error').style.display = 'none';
			}).catch(function (error) {
				document.getElementById('fp_success').style.display = 'none';
				document.getElementById('fp_error').style.display = 'block';
				// alert('Something went wrong! Please try after sometime.')
			});
	}

	setLoginEmail(event) {
		this.setState({ login_email: event.target.value });
	}

	setLoginPassword(event) {
		this.setState({ login_password: event.target.value });
	}
	handleLogin() {
		if (this.loginValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					login_email: this.state.login_email,
					login_password: this.state.login_password,
				}).then((response: {
					status: number
					, data: {
						bookmarks_str: string
						, email: string
						, first_name: string
						, last_name: string
						, max_no_entries: string
						, options: string
						, place_names_arr: string //Array<any>
						, user_type: string
						, token: string
					}
				}) => {
					document.getElementById('login_error').style.display = 'none';
					document.getElementById('verify_email_error').style.display = 'none';
					console.log(response);
					this.handleClose();
					// alert(window.location.href)
					if (response.status === 200) {
						let bookmarksCount = 0;
						console.log('response.data')
						console.log(response.data)
						if (response.data.bookmarks_str) {
							const bookmarksArr = response.data.bookmarks_str.split(',');
							bookmarksCount = bookmarksArr.length;
						}
						localStorage.setItem('bookmarks', response.data.bookmarks_str);
						localStorage.setItem('listingNames', response.data.place_names_arr);
						localStorage.setItem('maxNoEntries', response.data.max_no_entries);
						localStorage.setItem('membershipOptions', response.data.options);

						if (response.data.user_type === 'admin') {
							this.setState({
								showOTPModal: true
							});
						} else {
							this.handleLoginSuccessResponse(response);
						}
					} else {
						this.setState({ isLoggedIn: false });
						if (response.status === 204) {
							document.getElementById('verify_email_error').style.display = 'block';
							document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
						} else {
							document.getElementById('login_error').style.display = 'block';
							document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
						}
					}
				}).catch((error) => {
					// handle your error
					const errorEl = document.getElementById('login_error');
					if (errorEl) {
						errorEl.style.display = 'block';
						errorEl.scrollIntoView({ behavior: 'smooth' });
					}
				});
		} else {
			this.loginValidator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}
	handleOTPModal = () => {
		this.setState({ showOTPModal: false });
	}
	handleInputChange = (name, value) => {
		this.setState({ otp: value });
	}
	openInNewTab = (url) => {
		if (!url.match(/^https?:\/\//i)) {
			url = 'http://' + url;
		}
		window.open(url, '_blank', 'noopener,noreferrer');
	}

	handleOTPVerification = () => {
		if (this.OTPValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					otp: this.state.otp,
					login_email: this.state.login_email,
				}).then(response => {
					console.log(response);
					if (response.status === 200) {
						this.handleOTPModal();
						this.handleLoginSuccessResponse(response);
					}
				}).catch(function (error) {
					// handle your error
				});
		} else {
			this.OTPValidator.showMessages();
			this.forceUpdate();
		}
	}
	handleLoginSuccessResponse = response => {
		console.log("response", response)
		UserProfile.setEmail(response.data.email);
		UserProfile.setFirstName(response.data.first_name);
		UserProfile.setLastName(response.data.last_name);
		UserProfile.setCompanyName(response.data.companyName);
		UserProfile.setUserType(response.data.user_type);

		this.setState({
			isLoggedIn: true,
			showLoginSuccessModal: true,
		});

		cookies.set('token', response.data.token);
		this.forceUpdate();

		// if (this.props.data.location.pathname === '/register' || this.props.data.location.pathname === '/success' || this.state.nextURL === '/create-listings') {
		// 	// this.props.data.history.push('/create');
		// 	this.props.data.history.push({
		// 		pathname: '/create-listings',
		// 		state: {
		// 			propertyType: this.state.propertyType,
		// 			propertySubType: this.state.propertySubType,
		// 		}
		// 	})
		// }
		// else if (this.state.nextURL === '/view-listings') {
		// 	// this.props.data.history.push('/listing');
		// 	this.props.data.history.push({
		// 		pathname: '/view-listings',
		// 		state: {
		// 			propertyType: this.state.propertyType,
		// 			propertySubType: this.state.propertySubType,
		// 		}
		// 	})
		// }
	}
	handleLoginSuccessModal() {
		this.setState({ showLoginSuccessModal: false });
	}
	showRegistrationModal = () => {
		this.setState({
			showRegistrationModal: true,
			showLoginModal: false,
		});
	}

	handleRegistrationModal = () => {
		this.setState({ showRegistrationModal: false });
	}

	setFName(event) {
		this.setState({ fname: event.target.value });
	}

	setLName(event) {
		this.setState({ lname: event.target.value });
	}

	setCompanyName(event) {
		this.setState({ companyName: event.target.value });
	}

	setPassword(event) {
		this.setState({ password: event.target.value });
	}

	setConfirmPassword(event) {
		this.setState({ confirm_password: event.target.value });
	}

	setEmailreg(event) {
		this.setState({ reg_email: event.target.value });
	}

	onRecaptchaChange = event => {
		console.log('Captcha value: ', event);
		this.setState({ reacatpcha_value: event });
		const regi_errors = document.getElementById('contact_error');
		regi_errors.innerHTML = '';
		document.getElementById("contact_error").classList.remove("alert-danger");
	};

	registerUser(event) {
		if (this.registerValidator.allValid()) {
			this.trackGoogleAnalyticsEvent('map_reg_click', 'map_reg_click')
			LinkedInTag.track('9791956');
			const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*';
			let randon_password = '';
			const charactersLength = characters.length;
			for (let i = 0; i < 6; i++) {
				randon_password += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			this.setState({ password: randon_password });
			axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user',
				{
					headers: { 'Content-Type': 'application/json' },
					fname: this.state.fname,
					lname: this.state.lname,
					email: this.state.reg_email,
					companyName: this.state.companyName,
					password: randon_password,
					reacatpcha_value: this.state.reacatpcha_value,
					'source': 'View Listings',
				}).then(response => {
					// handle your response;
					if (response.status === 200) {
						this.setState({
							fname: '',
							lname: '',
							companyName: '',
							//password: '',
							confirm_password: '',
							//reg_email: '',
							reacatpcha_value: null,
							showRegistrationSuccessModal: true,
							showRegistrationModal: false
						});

					}
				}).catch((error) => {
					console.log('error')
					alert('failed')
					console.log(error.response)
					// handle your error
					const regi_errors = document.getElementById('regi_errors');
					regi_errors.innerHTML = '';
					const error_html = document.createElement('div');
					if (error.response.status === 600 && error.response.data.error === 'email exists') {
						error_html.innerHTML = 'You already have an account, please login!';
					} else if (error.response.status === 601) {
						error_html.innerHTML = 'Captcha verification failed, please try again!';
					} else {
						for (let i = 0; i < error.response.data.errors.length; i++) {
							error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
						}
					}
					document.getElementById('regi_errors').appendChild(error_html);
					document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
					regi_errors.scrollIntoView({ behavior: 'smooth' })
				});
		} else {
			this.registerValidator.showMessages();
			this.forceUpdate();
		}
	}

	handleRegistrationSuccessModal = () => {
		this.setState({ showRegistrationSuccessModal: false });
		axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
			{
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				login_email: this.state.reg_email,
				login_password: this.state.password,
			}).then((response: {
				status: number
				, data: {
					bookmarks_str: string
					, email: string
					, first_name: string
					, last_name: string
					, max_no_entries: string
					, options: string
					, place_names_arr: string //Array<any>
					, user_type: string
					, token: string
				}
			}) => {
				document.getElementById('login_error').style.display = 'none';
				document.getElementById('verify_email_error').style.display = 'none';
				console.log(response);
				this.handleClose();
				// alert(window.location.href)
				if (response.status === 200) {
					let bookmarksCount = 0;
					console.log('response.data')
					console.log(response.data)
					if (response.data.bookmarks_str) {
						const bookmarksArr = response.data.bookmarks_str.split(',');
						bookmarksCount = bookmarksArr.length;
					}
					localStorage.setItem('bookmarks', response.data.bookmarks_str);
					localStorage.setItem('listingNames', response.data.place_names_arr);
					localStorage.setItem('maxNoEntries', response.data.max_no_entries);
					localStorage.setItem('membershipOptions', response.data.options);

					if (response.data.user_type === 'admin') {
						this.setState({
							showOTPModal: true
						});
					} else {
						this.handleLoginSuccessResponse(response);
					}
				} else {
					this.setState({ isLoggedIn: false });
					if (response.status === 204) {
						document.getElementById('verify_email_error').style.display = 'block';
						document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
					} else {
						document.getElementById('login_error').style.display = 'block';
						document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
					}
				}
			}).catch((error) => {
				// handle your error
				const errorEl = document.getElementById('login_error');
				if (errorEl) {
					errorEl.style.display = 'block';
					errorEl.scrollIntoView({ behavior: 'smooth' });
				}
			});
	}



	increaseListingsView = () => {
		console.log('inside listings view count');
		const fullName = UserProfile.getFirstName() + ' ' + UserProfile.getLastName();
		//alert(name)

		axios.post(process.env.REACT_APP_BASE_URL + '/api/increase-listing-view-count', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			rec_id: this.state.place.rec_id,
			email: UserProfile.getEmail(),
			company: UserProfile.getCompanyName(),
			usr_name: fullName,
			viewTime: Math.floor(Date.now() / 1000),
		})
			.then(response => {
				console.log(response)
			});
		return 0;
	}

	closeQVModal = () => {
		this.setState({ modalVisible: false });
		this.increaseListingsView()
	}

	toggleFilterModal = () => {
		const x = document.getElementById('filter-mobile-view');
		if (x.classList.contains('hide')) {
			x.classList.remove('hide');
		} else {
			x.classList.add('hide');
		}
	}

	onStyleLoad = (map: MapboxGl.Map) => {
		map.resize();
	}

	getFirstImage = (json) => {
		for (let key in json) {
			if (json.hasOwnProperty(key)) {
				const url = json[key].url;
				return url;
			}
		}
	}

	formatPrice(price) {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0
		});
		const formattedPrice = formatter.format(price);
		return formattedPrice;
	}

	toggleMap = (callback?: () => void) => {
		if (this.state.renderSatelliteMap === 'true') {
			this.setMapStyle(process.env.REACT_APP_MAPBOX_STYLE_SATELLITE, callback);
		} else if (this.state.renderSatelliteMap === 'false') {
			this.setMapStyle(process.env.REACT_APP_MAPBOX_STYLE_STREETS, callback);
		}
		// this.forceUpdate();
	}

	getMapRef = (): MapboxGl.Map => {
		const { state } = this;
		if (!state) {
			return null;
		}
		const { mapboxRef } = state;
		if (!mapboxRef) {
			return null;
		}
		const stateMapbox: any = mapboxRef.state;
		if (!stateMapbox) {
			return null;
		}
		const map: MapboxGl.Map = stateMapbox.map;
		if (!map) {
			return null;
		}
		return map;
	}

	getMapFitBounds = (flagPerformResize: boolean = true): FitBounds => {
		return this.getMapBounds(true, flagPerformResize);
	}

	getMapFitBoundsAsStr = (): string => {
		const fitBounds: FitBounds = this.getMapFitBounds();
		return this.getFitBoundsAsStr(fitBounds);
	}

	getFitBoundsAsStr = (fitBounds: FitBounds): string => {
		return '' + fitBounds[0][0] + '_' + fitBounds[0][1] + '_' + fitBounds[1][0] + '_' + fitBounds[1][1];
	}

	getMapBounds = (flagCheckLoaded: boolean, flagPerformResize: boolean = true): FitBounds => {
		let fitBounds: FitBounds = this.state.fitBounds;
		const map = this.getMapRef();
		if (flagCheckLoaded && map) {
			flagCheckLoaded = map.loaded();
		} else {
			flagCheckLoaded = true;
		}
		if (map && flagCheckLoaded && !this.state.flagLoading) {
			if (flagPerformResize) {
				this.flagToPreventMapResizeCycle = true;
				map.resize();
				this.flagToPreventMapResizeCycle = false;
			}
			const lngLatBounds = map.getBounds();
			const southWest: [number, number] = [lngLatBounds.getWest(), lngLatBounds.getSouth()];
			const northEast: [number, number] = [lngLatBounds.getEast(), lngLatBounds.getNorth()];
			fitBounds = [southWest, northEast];
		}
		return fitBounds;
	}

	updateMapBounds = () => {
		const fitBounds: FitBounds = this.getMapFitBounds();
		this.setFitBounds(fitBounds);
	}

	setMapStyle = (mapStyle: string, callback?: () => void) => {
		const { state } = this;
		const flagUpdateBouds = (state.mapStyle !== mapStyle);
		const fitBounds: FitBounds = flagUpdateBouds ? this.getMapBounds(false) : state.fitBounds;
		this.setState({
			mapStyle: mapStyle,
			flagStyleLoading: true,
			fitBounds: fitBounds,
		}, () => {
			localStorage.setItem('mapStyle', mapStyle);
			if (state.listFilteringRef) {
				state.listFilteringRef.createPostponedFilters(() => {
					if (callback) {
						callback();
					}
				});
			} else if (callback) {
				callback();
			}
		});
	}

	changeMapStyle = () => {
		if (this.state.renderSatelliteMap === 'false') {
			this.setState({ renderSatelliteMap: 'true' }, () => {
				localStorage.setItem('renderSatelliteMap', 'true');
				this.toggleMap();
			});
		} else {
			this.setState({ renderSatelliteMap: 'false' }, () => {
				localStorage.setItem('renderSatelliteMap', 'false');
				this.toggleMap();
			});
		}
	}

	expandContractMap = (showNumberColumnsInListingNew: number) => {
		if (this.state.showNumberColumnsInListing === showNumberColumnsInListingNew) {
			return;
		}
		this.setState({ showNumberColumnsInListing: showNumberColumnsInListingNew }, () => {
			localStorage.setItem('showNumberColumnsInListing', '' + showNumberColumnsInListingNew);
			//this.toggleMap();
		});
	}

	setMeasureDistances = (flagMeasureDistances: boolean) => {
		if (this.state.flagMeasureDistances === flagMeasureDistances) {
			return;
		}
		this.setState({ flagMeasureDistances: flagMeasureDistances }, () => {
			if (!this.state.flagMeasureDistances) {
				while (geojson.features.length > 0) {
					geojson.features.pop();
				}
				const map = this.getMapRef();
				(map.getSource('geojson') as any).setData(geojson);
				const distanceContainer = document.getElementById('distance');
				distanceContainer.innerHTML = '';
				map.getCanvas().style.cursor = null;
			}
			localStorage.setItem('flagMeasureDistances', '' + flagMeasureDistances);
		});
	}

	changeMapStyleInQV = () => {
		this.setState((prevState) => ({
			renderSatelliteMapInQV: !prevState.renderSatelliteMapInQV,
			mapType: !prevState.renderSatelliteMapInQV
				? process.env.REACT_APP_MAPBOX_STYLE_SATELLITE
				: process.env.REACT_APP_MAPBOX_STYLE_STREETS,
		}));
	}

	closePopup = () => {
		this.setState({
			place: undefined,
		});
	}

	closeIntermodalPopup = () => {
		this.setState({
			intermodalPlace: undefined,
		});
	}

	closePortPopup = () => {
		this.setState({
			majorPortPlace: undefined
		})
	}

	handleInitialZoom = () => {
		this.closePopup();
		this.updateMapBounds();
	}

	handleContactUsClose = () => {
		this.setState({ showContactUs: false });
	}

	handleContactUsShow = () => {
		this.setState({ showContactUs: true });
	}

	setFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ firstName: event.target.value });
	}

	setLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ lastName: event.target.value });
	}

	setEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if (!pattern.test(event.target.value)) {
			document.getElementById("emailValidation").innerHTML = "Please enter valid email."
			this.setState({ email: event.target.value });
		}
		else {
			document.getElementById("emailValidation").innerHTML = ""
			this.setState({ email: event.target.value });
		}

	}

	setMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		this.setState({ message: event.target.value });
	}

	handleContactUs = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us',
			{
				headers: {
					'Content-Type': 'application/json',
					// 'x-access-token': cookies.get('token')
				},
				// withCredentials: true,
				place_owner_name: this.state.place.properties.contactName == "" ? 'Listing Contact' : this.state.place.properties.contactName,
				place_id: this.state.place.rec_id,
				name: this.state.firstName + ' ' + this.state.lastName,
				email: this.state.email,
				message: this.state.message,
			}).then(response => {
				console.log(response)
				if (response.status === 200) {
					this.handleContactUsClose();
					this.handleShow();
					document.getElementById('main-content').scrollIntoView({ behavior: 'smooth' });
				} else {
					this.handleDismiss();
				}
			}).catch(function (error) {
				console.log(error)
			});
	}
	handleContactUsGeneral = () => {
		if (this.validator.allValid()) {
			if (this.state.reacatpcha_value == undefined || this.state.reacatpcha_value == '') {
				const regi_errors = document.getElementById('contact_error');
				regi_errors.innerHTML = '';
				let error_html = document.createElement('div');
				error_html.innerHTML = 'Please Select Captcha';
				document.getElementById('contact_error').appendChild(error_html);
				document.getElementById('contact_error').classList.add('alert', 'alert-danger', 'display-block');
			} else if (this.state.reacatpcha_value != undefined || this.state.reacatpcha_value != '') {
				const regi_errors = document.getElementById('contact_error');
				regi_errors.innerHTML = '';
				document.getElementById("contact_error").classList.remove("alert-danger");
				this.setState({ contactusbutton: true })
				document.getElementById("contactusButon").setAttribute('disabled', 'disabled');
				axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us-added-captcha',
					{
						headers: {
							'Content-Type': 'application/json',
							// 'x-access-token': cookies.get('token')
						},
						// withCredentials: true,
						place_owner_name: '',
						place_id: '',
						name: this.state.firstName + ' ' + this.state.lastName,
						email: this.state.email,
						message: this.state.message,
						reacatpcha_value: this.state.reacatpcha_value,
					}).then(response => {
						if (response.status === 200) {
							this.handleShow()
							this.setState({
								message: '',
								contactusbutton: false
								// showSuccessModal: true,
							});
						} else {
							this.handleDismiss()
							this.setState({ contactusbutton: false });
						}
					}).catch(function (error) {
						console.log(error)
						let error_html = document.createElement('div');
						error_html.innerHTML = 'Captcha verification failed';
						document.getElementById('contact_error').appendChild(error_html);
						document.getElementById('contact_error').classList.add('alert', 'alert-danger', 'display-block');
					});
			}
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	handleShow = () => {
		this.setState({ showAlert: true });
	}

	handleDismiss = () => {
		this.setState({ showAlert: false });
	}

	createPDF = () => {
		// this.showpdfModal()
		if (cookies.get('token') !== undefined) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
				{
					headers: {
						'Content-Type': 'application/json',
						// 'x-access-token': cookies.get('token')
					},
					// withCredentials: true,
					imgurl: this.state.place.properties.image[0].url,
				}).then(response => {
					if (response.status === 200) {
						var final_base_64_url = 'data:image/jpeg;base64,' + response.data.base64;
						this.state.place.properties.image[0].url = final_base_64_url;
						axios.post(process.env.REACT_APP_BASE_URL + '/api/convert-img-url-base64',
							{
								headers: {
									'Content-Type': 'application/json',
									// 'x-access-token': cookies.get('token')
								},
								// withCredentials: true,
								imgurl: this.state.place.properties.listingLogo,
							}).then(response => {
								if (response.status === 200) {
									var final_base_64_url_logo = 'data:image/jpeg;base64,' + response.data.base64;
									this.state.place.properties.listingLogo = final_base_64_url_logo;
									// console.log('this.state.place.properties',this.state.place.properties);
									(new PdfDetailsDocGen()).createPDF(this.state.place.properties);
								}
							}).catch(function (error) {
								console.log(error)
								alert('Image Correpted...Something went wrong')
							});
					}
				}).catch(function (error) {
					console.log(error)
					alert('Image Correpted...Something went wrong')
				});

			// (new PdfDetailsDocGen()).createPDF(this.state.place.properties);
		}
		else {
			this.handleOpen();
		}

	}
	showpdfModal = () => {

		this.setState({ pdfModal: true });
	}
	hidepdfModal = () => {
		this.setState({ pdfModal: false });

	}


	AddbookmarkPrivateClient = (place_id) => {
		const bookmarkArrPrivate = localStorage.getItem('bookmarksprivate') ? JSON.parse(localStorage.getItem('bookmarksprivate')) : [];

		let isPresent = 0;
		console.log('bookmarkArrPrivate:', bookmarkArrPrivate)
		for (let i = 0; i < bookmarkArrPrivate.length; i++) {
			if (bookmarkArrPrivate[i] == place_id) {
				bookmarkArrPrivate.splice(i, 1)
				localStorage.setItem('bookmarksprivate', JSON.stringify(bookmarkArrPrivate))
				this.setState({
					bookmarksCountPrivate: bookmarkArrPrivate.length
				})
				// document.getElementById('bookmark-btn').classList.remove('color-faint-blue');
				document.getElementById('bookmark-btn').style.removeProperty('color');
				this.showDeleteFavModal();
				isPresent = 1;
			}
		}
		if (isPresent == 0) {
			bookmarkArrPrivate.push(place_id)
			//bookmarkArrPrivate = localStorage.getItem('bookmarks')
			localStorage.setItem('bookmarksprivate', JSON.stringify(bookmarkArrPrivate))
			// document.getElementById('bookmark-btn').classList.add('color-faint-blue');
			document.getElementById('bookmark-btn').style.setProperty('color', this.state.specificClientConfiguration.colorNavbar);
			this.showAddFavModal()
			console.log(bookmarkArrPrivate.length)
			this.setState({
				bookmarksCountPrivate: bookmarkArrPrivate.length
			})
		}



	}

	addToBookmarkList = (place_id) => {
		console.log(place_id)
		if (cookies.get('token') !== undefined) {
			console.log('place_id', place_id)
			const privateClientId: string = this.getPrivateClientId();
			console.log('privateClientId', privateClientId)
			//this.AddbookmarkPrivateClient(place_id)
			privateClientId ?

				this.AddbookmarkPrivateClient(place_id)

				:
				<>

					{

						axios.post(process.env.REACT_APP_BASE_URL + '/api/update-bookmark-list', {
							headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
							place_id: place_id,
							email: this.state.email
						})
							.then(res => {
								console.log(res.data)
								localStorage.setItem('bookmarks', res.data.bookmarks);
								let bookmarksArr = [];
								if (res.data.bookmarks) {
									bookmarksArr = res.data.bookmarks.split(',');
								}
								const bookmarksCount = bookmarksArr.length;
								this.setState({
									bookmarksCount: bookmarksCount
								})
								if (res.data.type === 'add') {
									document.getElementById('bookmark-btn').classList.add('color-faint-blue');
									this.showAddFavModal();
								} else {
									this.showDeleteFavModal();
									document.getElementById('bookmark-btn').classList.remove('color-faint-blue');
								}
							}).catch(err => {
								console.log(err);
							})
					}
				</>
		}
		else {
			this.handleOpen();
		}



	}

	showAddFavModal = () => {
		this.setState({ addFavModal: true });
	}

	hideAddFavModal = () => {
		this.setState({ addFavModal: false });
	}
	hideprivateFavModal = () => {
		this.setState({
			bookmarksCountPrivate: JSON.parse(localStorage.getItem('bookmarksprivate')) ? JSON.parse(localStorage.getItem('bookmarksprivate')).length : 0
		})
		this.setState({ bookmarksPrivateLabelModal: false });

	}
	hideListItemModal = () => {

		this.setState({ ListItemModal: false });

	}



	showDeleteFavModal = () => {
		this.setState({ deleteFavModal: true });
	}

	hideDeleteFavModal = () => {
		this.setState({ deleteFavModal: false });
	}

	handleCheckboxChange = (name: string, value: boolean) => {
		localStorage.setItem(name, '' + value);
		this.setState({ [name]: value } as any);
	}

	handleClassOneConnToggle = () => {
		this.setState({ showClassOneConnOverlay: !this.state.showClassOneConnOverlay });
	}

	render() {
		const { state, props } = this;
		const {
			showNumberColumnsInListing
			, place
			, flagLoading
			, specificClientConfiguration
		} = state;
		const privateClientId: string = this.getPrivateClientId();
		return (
			<Fragment>
				<Helmet>
					<title>{this.state.PageTitle != ' ' ? this.state.PageTitle : "Browse Listing"}</title>
					<meta property="og:url" content={window.location.href} />
					<meta name='description' content={this.state.PageMetaDiscription != " " ? this.state.PageMetaDiscription : "Discription"} />
					<meta name="robots" content={this.state.PageMetaRobot != " " ? this.state.PageMetaRobot : " "} />
					<meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
				</Helmet>

				<div>
					{
						!flagLoading ? null :
							<div className='loader-container'>
								<div className='loader'></div>
							</div>
					}
					{
						privateClientId
							?
							<nav className='navbar margin-bottom-0'>
								<div className='pull-left' style={{ paddingTop: 5, marginLeft: 5 }}>
									<Link className='img-responsive' to={{ pathname: '/' }}><img style={{ height: 75 }} src={specificClientConfiguration.logoUrl} alt='logo' /></Link>
								</div>
								<li className='pull-right' style={{ display: 'inline-block', marginRight: '20px', padding: '22.5px 15px 22.5px 15px', position: 'relative' }}>
									<i className='fa fa-star fs-30'
										style={{ marginTop: 0, color: specificClientConfiguration.colorNavbar }}
										aria-hidden='true'
										onClick={this.showModalPrivateFav}
									>
									</i>
									<span className='shoping-cart-number'
										style={{ marginTop: 0, backgroundColor: specificClientConfiguration.Accentcolor }}
									>
										{this.state.bookmarksCountPrivate}
									</span>
								</li>
							</nav>
							:
							<>

								<Header
									data={this.props}
									onRef={ref => (this.child = ref)}
									bookmarksCount={state.bookmarksCount}
									privateClientId={privateClientId}
								/>


							</>
					}
					<Breadcrumb id='top-breadcrumb'
						style={Object.assign({
						}, {
							backgroundColor: 'rgb(0, 148, 233)'
						}, specificClientConfiguration.colorNavbar && {
							backgroundColor: specificClientConfiguration.colorNavbar
						}
						) as React.CSSProperties}
					>
						<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link><span style={{ color: 'white' }}>&nbsp;/&nbsp;</span>
						<BreadcrumbItem active><span style={{ fontSize: 15 }} className='color-white'>Listings</span></BreadcrumbItem>
						<div className='list-item-visible'>
							<i className='fa fa-map-marker fs-30'
								style={{ marginTop: -25, float: 'right', color: 'white' }}
								aria-hidden='true'
								onClick={this.showModallistItem}
							>
							</i>

						</div>

						<i role='button' style={{ marginTop: -25, marginRight: 15, float: 'right', color: 'white' }} onClick={this.showModalGetHelp} className='fa fa-envelope fs-30 font-size-18-imp pull-right gethelp-item-visible' aria-hidden='true'></i>
						<i role='button' style={{ float: 'right', color: 'white' }} onClick={this.showModalGetHelp} className='fa fa-envelope fs-30 font-size-18-imp pull-right gethelplarge-item-visible' aria-hidden='true'></i>
						<span role='button' style={{ color: 'white', marginTop: -10 }} className='pull-right start_tour'><TakeTour backgroundColor="none" /></span>
					</Breadcrumb>
					{
						this.renderSearchResults()
					}
					{
						this.renderDialogs()
					}
					{/* <Footer /> */}
					<ListingHelpModal show={state.showListingHelpModal} handleClose={this.hideListingHelpModal} token="Default Modal"></ListingHelpModal>
				</div>
			</Fragment>
		)
	}

	onMapStyleLoad = (map: MapboxGl.Map) => {
		const { state } = this;
		map.addSource(CrudeOilPipelinesLayer.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.cr8f08lf',
		});
		map.addSource(PetroleumProductPipelinesLayer.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.d76wtxey',
		});
		map.addSource(MajorPortsLayer.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.3ejhlz99',
		});
		map.addSource(NorthAmericanRailLinesBnsf.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.7d3y3ktc',
		});
		map.addSource(NorthAmericanRailLinesCn.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.2qns5lfm',
		});
		map.addSource(NorthAmericanRailLinesCprs.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.dwlaymfl',
		});
		map.addSource(NorthAmericanRailLinesCsxt.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.cvll10x1',
		});
		map.addSource(NorthAmericanRailLinesKcs.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.46dupyo2',
		});
		map.addSource(NorthAmericanRailLinesNs.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.4g4ts3kf',
		});
		map.addSource(NorthAmericanRailLinesShort.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.2waw9ju3',
		});
		map.addSource(NorthAmericanRailLinesShortExt.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.1n8qrtmp',
		});
		map.addSource(NorthAmericanRailLinesUp.dataSource, {
			type: 'vector',
			url: 'mapbox://nhemmady.94wulaz8',
		});
		roadWaysMapLayersLines.forEach((roadWayLayerName: string) => {
			map.setPaintProperty(roadWayLayerName, 'line-opacity', state.showRoadways ? 0.5 : 0);
		});
		roadWaysMapLayersText.forEach((roadWayLayerName: string) => {
			map.setPaintProperty(roadWayLayerName, 'text-opacity', state.showRoadways ? 1 : 0);
			map.setPaintProperty(roadWayLayerName, 'icon-opacity', state.showRoadways ? 1 : 0);
		});
		const source: MapboxGl.GeoJSONSourceRaw = {
			'type': 'geojson',
			'data': geojson,
		};
		map.addSource('geojson', source);
		map.addLayer({
			id: 'measure-points',
			type: 'circle',
			source: 'geojson',
			paint: {
				'circle-radius': 5,
				'circle-color': '#000'
			},
			filter: ['in', '$type', 'Point']
		});
		map.addLayer({
			id: 'measure-lines',
			type: 'line',
			source: 'geojson',
			layout: {
				'line-cap': 'round',
				'line-join': 'round'
			},
			paint: {
				'line-color': '#000',
				'line-width': 2.5
			},
			filter: ['in', '$type', 'LineString']
		});
		map.on('click', (e) => {
			if (!this.state.flagMeasureDistances) {
				return;
			}
			const features = map.queryRenderedFeatures(e.point, {
				layers: ['measure-points']
			});
			// Remove the linestring from the group
			// so we can redraw it based on the points collection.
			if (geojson.features.length > 1) {
				geojson.features.pop();
			}
			const distanceContainer = document.getElementById('distance');
			distanceContainer.innerHTML = '';
			// If a feature was clicked, remove it from the map.
			if (features.length) {
				const id = features[0].properties.id;
				geojson.features = geojson.features.filter(
					(point) => point.properties.id !== id
				);
			} else {
				const point: GeoJSON.Feature = {
					'type': 'Feature',
					'geometry': {
						'type': 'Point',
						'coordinates': [e.lngLat.lng, e.lngLat.lat]
					},
					'properties': {
						'id': String(new Date().getTime())
					}
				};
				geojson.features.push(point);
			}
			if (geojson.features.length > 1) {
				linestring.geometry['coordinates'] = geojson.features.map(
					(point: GeoJSON.Feature<GeoJSON.Geometry>) => point.geometry['coordinates']
				);
				geojson.features.push(linestring);
				// Populate the distanceContainer with total distance
				const value = document.createElement('pre');
				value.setAttribute('style', 'background-color: rgba(0, 0, 0, 0.5);color: #fff;font-size: 11px;line-height: 18px;display: block;margin: 0;padding: 5px 10px;border-radius: 3px;');
				const distanceKm: number = parseFloat('' + turf.length(linestring as any));
				const distanceMiles: number = distanceKm * 0.621371;
				value.textContent = `Total distance: ${distanceMiles.toLocaleString()} miles`;
				distanceContainer.appendChild(value);
			}
			(map.getSource('geojson') as any).setData(geojson);
		});
		this.setState({ flagStyleLoading: false });
	}

	renderIconFilters = (): React.ReactElement => {
		const { state, props } = this;
		const {
			showNumberColumnsInListing
			, innerWidthToHideListingButtons
			, innerWidth
			, specificClientConfiguration
		} = state;
		const flagSmallIcons: boolean = innerWidth <= innerWidthToHideListingButtons;
		const sizeIco: number = flagSmallIcons ? sizeIcoSmall : sizeIcoBig;
		const placeIco: number = flagSmallIcons ? placeIcoSmall : placeIcoBig;
		const activeHighlightedColor = specificClientConfiguration.colorNavbar ? specificClientConfiguration.colorNavbar : null;
		const keySuffix = '_' + sizeIco + '_' + placeIco + '_' + activeHighlightedColor;
		return (
			<ListIconFiltering
				key={'keySuffix_' + keySuffix}
				ref={this.updateListFilteringRef}
				filterResults={
					(filter: LiFilter, intermediateFeatures: Array<TInFeature>, flagPostponedCreate: boolean) => {
						this.filterResults(filter, intermediateFeatures, flagPostponedCreate)
					}}
				handleInitialZoom={() => this.handleInitialZoom()}
				updateMapBounds={() => this.updateMapBounds()}
				updateResponse={
					(flagLoading: boolean, response: { features: Array<TInFeature> }, callback?: () => void) => {
						this.updateResponse(flagLoading, response, callback);
					}}
				sizeIco={sizeIco}
				placeIco={placeIco}
				activeHighlightedColor={activeHighlightedColor}
				privateClientId={this.getPrivateClientId()}
			/>
		)
	}

	renderSearchResults = (): React.ReactElement => {
		const { state, props } = this;
		const { showNumberColumnsInListing } = state;
		const pageCanvas: HTMLDivElement = (document.querySelector('div#page-canvas') as any);
		let offsetTop: number = pageCanvas ? pageCanvas.offsetTop : null;
		if (!offsetTop) {
			const topBreadcrumb: HTMLElement = (document.querySelector('ol#top-breadcrumb') as any);
			offsetTop = topBreadcrumb ? topBreadcrumb.offsetTop + topBreadcrumb.clientHeight : null;
		}
		if (!offsetTop) {
			offsetTop = 125;
		}
		return (
			<div id='page-canvas' style={{

			}}
			>
				<div id='page-content'
					style={{
						height: state.innerHeight > (offsetTop + 2) ? state.innerHeight - (offsetTop + 2) : 250,
						overflowY: 'hidden',
						overflowX: 'hidden'
						//, overflow: 'hidden'
					}}
				>
					<div className='map-canvas list-solid' style={Object.assign({
						height: '100%'
						, overflow: 'visible'
					})}
					>
						{
							showNumberColumnsInListing === 0 ? null :
								this.renderListPlace()
						}
						{
							this.renderMapPlace()
						}
					</div>
				</div>
			</div>
		)
	}

	renderListPlace = (): React.ReactElement => {
		const { state } = this;
		const {
			response
			, showNumberColumnsInListing
			, innerWidthToHideListingButtons
			, innerWidth
			, specificClientConfiguration
		} = state;
		const features = response.features;
		return (
			<>
				<div className='items-list'
					key={this.getGeneralListPlaceKey('ListPlace_')}
					id='items-list'
					style={Object.assign({
						position: 'absolute',
						zIndex: 100,
						top: 60,
						height: '90%',
						//overflowY: 'scroll',
						backgroundColor: 'white',
						paddingLeft: 0,
						paddingTop: 0,
						display: 'block',
						width: '45%',
					}, showNumberColumnsInListing === 2 && {
						width: '45%',
						minWidth: 520,
					}, showNumberColumnsInListing === 1 && {
						width: '25%',
					}, (showNumberColumnsInListing === 0 || innerWidth <= innerWidthToHideListingButtons) && {
						display: 'none',
					}, specificClientConfiguration.colorSlider && {
						backgroundColor: specificClientConfiguration.colorSlider,
					}) as React.CSSProperties}
				>
					<div className='inner' style={{ height: 'auto' }}>
						<div className='scroller1'>
							<div id='listings'>
								<MediaQuery minDeviceWidth={768}>
									<hr style={{ margin: 0 }} />
								</MediaQuery>
								<header>
									<div className='row'
										style={{
											// display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										{/* <div className={showNumberColumnsInListing === 1 ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-7 col-sm-7 col-xs-7'}>
											<h3 className='pull-left'>SEARCH RESULTS</h3>
											<h3 className='pull-right'>Get Help</h3>
										</div> */}
										<div className={showNumberColumnsInListing === 1 ? 'col-md-12 col-sm-12 col-xs-12' : 'col-md-12 col-sm-12 col-xs-12'}>
											<h3 className='pull-left listings_details_section'>SEARCH RESULTS</h3>
											{/* <b role='button' onClick={this.showModalGetHelp} className={showNumberColumnsInListing === 1 ? 'pull-right pull-left-below-1055' : 'pull-right'}>&nbsp;&nbsp;<i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>&nbsp;GET HELP</b> */}
										</div>

									</div>

									<div className='row'>
										<div className={showNumberColumnsInListing === 1 ? 'col-md-12 ' : 'col-md-12'}>

											<Switch
												onChange={this.onToggleMapMoveUpdateListing}
												checked={this.state.flagMapMoveUpdateListing}
												className='toggle_button_icon'
											>
											</Switch>
											<span style={{ fontSize: 16, fontWeight: 400 }}>
												<b style={{ fontSize: 16, fontWeight: 500 }}>&nbsp;&nbsp;Filter listings when moving around the map</b>
											</span>
										</div>

										{/* <div className={showNumberColumnsInListing === 1 ? 'col-md-12 ' : 'col-md-12'}>

											<div className={showNumberColumnsInListing === 1 ? 'col-md-3 ' : 'col-md-2'}>
												<Switch
													onChange={this.onToggleMapMoveUpdateListing}
													checked={this.state.flagMapMoveUpdateListing}
												>
												</Switch>
											</div>
											<div className='col-md-8'>
												<span>
													<h4>Filter listings when moving around the map</h4>
												</span>
											</div>



										</div> */}


									</div>

								</header>

							</div>
						</div>
					</div>

					<div className='items-list'
						key={this.getGeneralListPlaceKey('ListPlace_')}
						id='items-list'
						style={Object.assign({
							position: 'absolute',
							zIndex: 100,
							//top: 120,
							height: '90%',
							overflowY: 'scroll',
							backgroundColor: 'white',
							paddingLeft: 0,
							paddingTop: 0,
							display: 'block',
							width: '100%',
						}, showNumberColumnsInListing === 2 && {
							width: '100%',
							minWidth: 520,
						}, showNumberColumnsInListing === 1 && {
							width: '100%',
						}, (showNumberColumnsInListing === 0 || innerWidth <= innerWidthToHideListingButtons) && {
							display: 'none',
						}, specificClientConfiguration.colorSlider && {
							backgroundColor: specificClientConfiguration.colorSlider,
						}) as React.CSSProperties}
					>
						<div className='inner'>
							<div className='scroller1'>
								<div id='listings'>
									<MediaQuery minDeviceWidth={768}>
										<hr style={{ margin: 0 }} />
									</MediaQuery>

									<div style={{
										height: '100%',
									}}
									>
										{
											this.renderListItems()
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	renderListItems = (): React.ReactElement => {
		const { state } = this;
		const { response, flagMapMoveUpdateListing } = state;
		const fitBounds: FitBounds = this.getMapBounds(false, false);
		let features: Array<TInFeature> = [];
		if (flagMapMoveUpdateListing) {
			features = this.filterByBounds(response.features, response.latitudeSorted, response.longitudeSorted, fitBounds);
		} else {
			response.features.forEach((inFeature: TInFeature) => {
				features.push(inFeature);
			});
		}
		if (features.length <= 0) {
			return (
				<ul className='results grid'>
					<h2 className='text-center'>No Results Found</h2>
				</ul>
			)
		}
		const compare = (a: TInFeature, b: TInFeature) => {
			if (a.client_plan_id && b.client_plan_id) {
				if (a.client_plan_id < b.client_plan_id) {
					return 1;
				}
				if (a.client_plan_id > b.client_plan_id) {
					return -1;
				}
			} else if (a.client_plan_id) {
				return -1;
			} else if (b.client_plan_id) {
				return 1;
			}
			if (a.createdAt < b.createdAt) {
				return 1;
			}
			if (a.createdAt > b.createdAt) {
				return -1;
			}
			return 0;
		};
		features.sort(compare);
		return (
			<ul key={'LI_' + this.getFitBoundsAsStr(fitBounds)}
				className='results grid'
			>
				{
					Object.keys(features).map((key, index) => {
						return this.renderListItem(features[key], key, index);
					})
				}
			</ul>
		)
	}

	renderListItem = (feature: TInFeature, key: string, index: number): React.ReactElement => {
		const { showNumberColumnsInListing } = this.state;
		return (
			<React.Fragment key={'fr_mq_item_' + index + '_' + showNumberColumnsInListing} >
				<MediaQuery key={'mq_item_' + index + '_' + showNumberColumnsInListing} maxDeviceWidth={768}>
					<li key={'mq_list_item01_' + index + '_' + showNumberColumnsInListing}>
						{/* <Link to={{ pathname: '/details-' + feature.rec_id }}> */}
						<div className='item margin-bottom-0' id={'listing-' + key}>
							<div className='real-estate-item-image'>
								{
									feature.properties.listingLogo !== '' &&
									<div style={{
										position: 'absolute',
										height: 'max(20%, 40px)',
									}}
									>
										<img loading="lazy" style={{
											maxHeight: '100%',
											width: '100%',
										}}
											src={feature.properties.listingLogo}
										/>
									</div>
								}
								<div className='height-220' style={{ height: 220 }}>
									<img loading="lazy" src={this.getFirstImage(feature.properties.image)} alt='img' style={{ height: '100%', width: '100%' }} />
								</div>
							</div>
							<div className='real-estate-item-desc text-left padding-bottom-20-imp color-black'>
								{
									feature.properties.listingName &&
									<OverlayTrigger placement='bottom' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>{feature.properties.listingName}</strong>
										</Tooltip>
									}>
										<h3 className='margin-top-0 margin-bottom-20-imp pull-left text-uppercase word-break'
											onClick={this.markerClick.bind(this, feature)}
										>
											{
												feature.properties.listingName
											}
										</h3>
									</OverlayTrigger>
								}
								<div className='clear-both'>
									{feature.properties.city !== '' && feature.properties.city !== undefined ? feature.properties.city + ' · ' : ''}
									{feature.properties.state}</div>
							</div>
						</div>
						{/* </Link> */}
					</li>
				</MediaQuery>
				<MediaQuery minDeviceWidth={768}>
					<li key={'mq_list_item02_' + + index + '_' + showNumberColumnsInListing}
						style={Object.assign({
							display: 'inline-block',
							marginLeft: 5,
							marginRight: 5,
							width: '48%',
						}, (showNumberColumnsInListing === 2) && {
							width: '48%',
						}, (showNumberColumnsInListing === 1) && {
							width: '98%',
						})}
					>
						<div className='item margin-bottom-0' id={'listing-' + key}>
							<div className='real-estate-item-image' onClick={this.markerClick.bind(this, feature)}>
								{
									feature.properties.listingLogo !== '' &&
									<div style={{
										position: 'absolute',
										height: 'max(20%, 40px)',
									}}
									>
										{/* <img style={{
											maxHeight: '100%',
											width: '100%',
										}}
											src={feature.properties.listingLogo}
										/> */}
										<img className='adjust-logo-dimentions' loading="lazy" style={{
											maxHeight: 80,
											maxWidth: 160,
										}}
											src={feature.properties.listingLogo}
										/>
									</div>
								}
								<div className='height-220' style={{ height: 220 }}>
									<img loading="lazy" src={this.getFirstImage(feature.properties.image)}
										alt='img'
										style={{ height: '100%', width: '100%' }}
										onClick={this.markerClick.bind(this, feature)}
									/>
								</div>
							</div>
							<div className='real-estate-item-desc text-left padding-bottom-20-imp color-black'>
								{
									feature.properties.listingName &&
									<OverlayTrigger placement='bottom' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>{feature.properties.listingName}</strong>
										</Tooltip>
									}>
										<h3 className='margin-top-0 margin-bottom-20-imp pull-left text-uppercase word-break'
											onClick={this.markerClick.bind(this, feature)}
										>
											{feature.properties.listingName}
										</h3>
									</OverlayTrigger>
								}
								<div className='clear-both' onClick={this.markerClick.bind(this, feature)}>
									{feature.properties.city !== '' && feature.properties.city !== undefined ? feature.properties.city + ' · ' : ''}
									{feature.properties.state}</div>
							</div>
						</div>
					</li>
				</MediaQuery>
			</React.Fragment>
		)
	}



	renderListPlaceModal = (): React.ReactElement => {
		const { state } = this;
		const {
			response
			// , showNumberColumnsInListing
			, innerWidthToHideListingButtons
			, innerWidth
			, specificClientConfiguration
		} = state;
		const showNumberColumnsInListingModal = 1;
		const features = response.features;
		return (
			<>
				<div className='items-list adjust-margins-search-result'
					key={this.getGeneralListPlaceKey('ListPlace_')}
					id='items-list'
					style={Object.assign({
						position: 'absolute',
						zIndex: 100,
						//top: 60,
						height: '90%',
						//overflowY: 'scroll',
						backgroundColor: 'white',
						paddingLeft: '12%',
						paddingTop: 0,
						display: 'block',
						width: '80%',
					}, showNumberColumnsInListingModal === 1 && {
						width: '75%',
					}, specificClientConfiguration.colorSlider && {
						backgroundColor: 'white',
					}) as React.CSSProperties}
				>

					<div className='items-list'
						key={this.getGeneralListPlaceKey('ListPlace_')}
						id='items-list'
						style={Object.assign({
							position: 'absolute',
							zIndex: 100,
							//top: 120,
							height: '90%',
							overflowY: 'scroll',
							backgroundColor: 'white',
							// paddingLeft: 0,
							paddingTop: 0,
							display: 'block',
							width: '90%',
						}, specificClientConfiguration.colorSlider && {
							backgroundColor: 'white',
						}) as React.CSSProperties}
					>
						<div className='inner'>
							<div className='scroller1'>
								<div id='listings'>
									<MediaQuery minDeviceWidth={768}>
										<hr style={{ margin: 0 }} />
									</MediaQuery>

									<div style={{
										height: '100%',
									}}
									>
										{
											this.renderListItemsSmallModal()
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}

	renderListItemsSmallModal = (): React.ReactElement => {
		const { state } = this;
		const { response, flagMapMoveUpdateListing } = state;
		const fitBounds: FitBounds = this.getMapBounds(false);
		let features: Array<TInFeature> = [];
		if (flagMapMoveUpdateListing) {
			features = this.filterByBounds(response.features, response.latitudeSorted, response.longitudeSorted, fitBounds);
		} else {
			response.features.forEach((inFeature: TInFeature) => {
				features.push(inFeature);
			});
		}
		if (features.length <= 0) {
			return (
				<ul className='results grid'>
					<h2 className='text-center'>No Results Found</h2>
				</ul>
			)
		}
		const compare = (a: TInFeature, b: TInFeature) => {
			if (a.client_plan_id < b.client_plan_id) {
				return 1;
			}
			if (a.client_plan_id > b.client_plan_id) {
				return -1;
			}
			if (a.createdAt < b.createdAt) {
				return 1;
			}
			if (a.createdAt > b.createdAt) {
				return -1;
			}
			return 0;
		};
		features.sort(compare);
		return (
			<ul key={'LI_' + this.getFitBoundsAsStr(fitBounds)}
				className='results grid'
			>
				{
					Object.keys(features).map((key, index) => {
						return this.renderListItemModal(features[key], key, index);
					})
				}
			</ul>
		)
	}


	renderListItemModal = (feature: TInFeature, key: string, index: number): React.ReactElement => {
		const { showNumberColumnsInListing } = this.state;
		return (
			<React.Fragment key={'fr_mq_item_' + index + '_' + showNumberColumnsInListing} >
				<MediaQuery key={'mq_item_' + index + '_' + showNumberColumnsInListing} maxDeviceWidth={768}>
					<li key={'mq_list_item01_' + index + '_' + showNumberColumnsInListing}>
						{/* <Link to={{ pathname: '/details-' + feature.rec_id }}> */}
						<div className='item margin-bottom-0' id={'listing-' + key}>
							<div className='real-estate-item-image'>
								{
									feature.properties.listingLogo !== '' &&
									<div style={{
										position: 'absolute',
										height: 'max(20%, 40px)',
									}}
									>
										<img loading="lazy" style={{
											maxHeight: '100px',
											width: '100px',
										}}
											src={feature.properties.listingLogo}
										/>
									</div>
								}
								<div style={{ height: 'auto' }}>
									<img loading="lazy" src={this.getFirstImage(feature.properties.image)} alt='img' style={{ height: '100%', width: '100%' }} />
								</div>
							</div>
							<div style={{ display: 'block', marginTop: 7 }} className='text-left padding-bottom-20-imp color-black'>
								{
									feature.properties.listingName &&
									<OverlayTrigger placement='bottom' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>{feature.properties.listingName}</strong>
										</Tooltip>
									}>
										<h3 className='margin-top-0 margin-bottom-5 pull-left text-uppercase word-break'
											onClick={this.markerClick.bind(this, feature)}
										>
											{
												feature.properties.listingName
											}
										</h3>
									</OverlayTrigger>
								}
								<div className='clear-both'>
									{feature.properties.city !== '' && feature.properties.city !== undefined ? feature.properties.city + ' · ' : ''}
									{feature.properties.state}</div>
							</div>


						</div>
						{/* </Link> */}
					</li>
				</MediaQuery>
				<MediaQuery minDeviceWidth={768}>
					<li key={'mq_list_item02_' + + index + '_' + showNumberColumnsInListing}
						style={Object.assign({
							display: 'inline-block',
							marginLeft: 5,
							marginRight: 5,
							width: '98%',
						}, (showNumberColumnsInListing === 2) && {
							width: '98%',
						}, (showNumberColumnsInListing === 1) && {
							width: '98%',
						})}
					>
						<div className='item margin-bottom-0' id={'listing-' + key}>
							<div className='real-estate-item-image' onClick={this.markerClick.bind(this, feature)}>
								{
									feature.properties.listingLogo !== '' &&
									<div style={{
										position: 'absolute',
										height: 'max(20%, 40px)',
									}}
									>
										{/* <img style={{
											maxHeight: '100%',
											width: '100%',
										}}
											src={feature.properties.listingLogo}
										/> */}
										<img loading="lazy" className='adjust-logo-dimentions' style={{
											maxHeight: 70,
											maxWidth: 140,
										}}
											src={feature.properties.listingLogo}
										/>
									</div>
								}
								<div className='height-220' style={{ height: 220 }}>
									<img loading="lazy" src={this.getFirstImage(feature.properties.image)}
										alt='img'
										style={{ height: '100%', width: '100%' }}
										onClick={this.markerClick.bind(this, feature)}
									/>
								</div>
							</div>
							<div className='real-estate-item-desc text-left padding-bottom-20-imp color-black'>
								{
									feature.properties.listingName &&
									<OverlayTrigger placement='bottom' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>{feature.properties.listingName}</strong>
										</Tooltip>
									}>
										<h3 className='margin-top-0 margin-bottom-20-imp pull-left text-uppercase word-break'
											onClick={this.markerClick.bind(this, feature)}
										>
											{feature.properties.listingName}
										</h3>
									</OverlayTrigger>
								}
								<div className='clear-both' onClick={this.markerClick.bind(this, feature)}>
									{feature.properties.city !== '' && feature.properties.city !== undefined ? feature.properties.city + ' · ' : ''}
									{feature.properties.state}</div>
							</div>
						</div>
					</li>
				</MediaQuery>
			</React.Fragment>
		)
	}

	renderMapPlace = (): React.ReactElement => {
		const { state } = this;
		const {
			innerWidthToHideListingButtons
			, innerWidth
			, mapStyle
			, flagLoading
			, flagStyleLoading
		} = state;
		const fitBounds: FitBounds = this.getMapFitBounds();
		const flagSmallIcons: boolean = innerWidth <= innerWidthToHideListingButtons;
		const sizeIco: number = flagSmallIcons ? sizeIcoSmall : sizeIcoBig;
		const placeIco: number = flagSmallIcons ? placeIcoSmall : placeIcoBig;
		return (
			<div
				key={this.getGeneralMapPlaceKey('MapPlace_')}
				className='map pull-left general_map_view'
				style={Object.assign({
					width: '100%',
				})}
				id='map_div'
			>
				<div id='map' className='has-parallax'>
					<Mapbox
						ref={this.updateMapboxRef}
						key={this.getGeneralMapKey('Listing_Mapbox_')}
						style={mapStyle}
						onStyleLoad={(map: MapboxGl.Map) => {
							this.onMapStyleLoad(map);
						}}
						onDrag={this.onDrag}
						movingMethod={'easeTo'}
						containerStyle={Object.assign({}, {
							height: '100%',
							//width: '100%',
							overflow: 'inherit',
						}, flagLoading && {
							visibility: 'hidden',
							//}, flagStyleLoading && {
							//	visibility: 'hidden',
						}) as React.CSSProperties}
						fitBounds={fitBounds}
						fitBoundsOptions={{
							linear: true,
						}}
						flyToOptions={{
							speed: state.flyToOptions,
						}}
					>
						{
							this.renderMapContent()
						}
					</Mapbox>
					{
						<div style={Object.assign({}, {
							position: 'absolute'
							, top: 0
							, left: 0
							, display: 'flex'
							, flexDirection: 'row'
							//, flexWrap: 'wrap'
							, justifyContent: 'flex-start'
							, transition: 'width 1s ease-in-out'
							, overflow: 'hidden'
							, zIndex: 3
						}) as any}
						>
							{
								state.innerWidth > 600 ? null :
									<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
										<Button
											style={Object.assign({}, {
												backgroundColor: 'rgba(255,255,255,0.7)',
												color: '#383838',
											})}
											onClick={() => {
												this.expandMapToolbar(!state.flagMapToolbarExpanded);
											}}
										>
											{/* {
												Menu
											} */}
											<svg viewBox='0 0 192 512' style={{
												width: sizeIco,
												height: sizeIco,
											}}>
												<path fill='currentColor'
													d='M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z'
												>
												</path>
											</svg>
										</Button>
									</div>
							}
							{
								false && state.innerWidth <= 600 && !state.flagMapToolbarExpanded ? null :
									<div style={Object.assign({}, {
										display: 'flex'
										, flexDirection: 'row'
										//, flexWrap: 'wrap'
										, transition: 'width 1s ease-in-out'
										, overflow: 'hidden'
									}, {
										width: '0%'
										//, height: '0%'
									}, (state.innerWidth > 600 || state.flagMapToolbarExpanded) && {
										width: '100%'
										//, height: '100%'
									}) as any}
									>
										<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
											<Button
												style={Object.assign({}, {
													backgroundColor: 'rgba(255,255,255,0.7)',
													color: '#383838',
												})}
												onClick={this.changeMapStyle}
											>
												{/* {
													Map/Satellite
												} */}
												{
													state.renderSatelliteMap === 'false'
														?
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	Map
																</strong>
															</Tooltip>
														}>
															<svg viewBox='0 0 512 512' style={{
																width: sizeIco,
																height: sizeIco,
															}}>
																<path fill='currentColor'
																	d='M502.60969,310.04206l-96.70393,96.71625a31.88151,31.88151,0,0,1-45.00765,0L280.572,326.34115l-9.89231,9.90759a190.56343,190.56343,0,0,1-5.40716,168.52287c-4.50077,8.50115-16.39342,9.59505-23.20707,2.79725L134.54715,400.05428l-17.7999,17.79929c.70324,2.60972,1.60965,5.00067,1.60965,7.79793a32.00544,32.00544,0,1,1-32.00544-32.00434c2.79735,0,5.18838.90637,7.7982,1.60959l17.7999-17.79929L4.43129,269.94287c-6.798-6.81342-5.70409-18.6119,2.79735-23.20627a190.58161,190.58161,0,0,1,168.52864-5.407l9.79854-9.79821-80.31053-80.41716a32.002,32.002,0,0,1,0-45.09987L201.96474,9.29814A31.62639,31.62639,0,0,1,224.46868,0a31.99951,31.99951,0,0,1,22.59759,9.29814l80.32615,80.30777,47.805-47.89713a33.6075,33.6075,0,0,1,47.50808,0l47.50807,47.50645a33.63308,33.63308,0,0,1,0,47.50644l-47.805,47.89713L502.71908,265.036A31.78938,31.78938,0,0,1,502.60969,310.04206ZM219.56159,197.433l73.82505-73.82252-68.918-68.9-73.80942,73.80689Zm237.74352,90.106-68.90233-68.9156-73.825,73.82252,68.918,68.9Z'
																>
																</path>
															</svg>
														</OverlayTrigger>
														:
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	Satellite
																</strong>
															</Tooltip>
														}>
															<svg viewBox='0 0 496 512' className='satelite_icon' style={{
																width: sizeIco,
																height: sizeIco,
															}}>
																<path fill='currentColor'
																	d='M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm-32 50.8v11.3c0 11.9-12.5 19.6-23.2 14.3l-24-12c14.9-6.4 30.7-10.9 47.2-13.6zm32 369.8V456c-110.3 0-200-89.7-200-200 0-29.1 6.4-56.7 17.6-81.7 9.9 14.7 25.2 37.4 34.6 51.1 5.2 7.6 11.2 14.6 18.1 20.7l.8.7c9.5 8.6 20.2 16 31.6 21.8 14 7 34.4 18.2 48.8 26.1 10.2 5.6 16.5 16.3 16.5 28v32c0 8.5 3.4 16.6 9.4 22.6 15 15.1 24.3 38.7 22.6 51.3zm42.7 22.7l17.4-46.9c2-5.5 3.3-11.2 4.8-16.9 1.1-4 3.2-7.7 6.2-10.7l11.3-11.3c8.8-8.7 13.7-20.6 13.7-33 0-8.1-3.2-15.9-8.9-21.6l-13.7-13.7c-6-6-14.1-9.4-22.6-9.4H232c-9.4-4.7-21.5-32-32-32s-20.9-2.5-30.3-7.2l-11.1-5.5c-4-2-6.6-6.2-6.6-10.7 0-5.1 3.3-9.7 8.2-11.3l31.2-10.4c5.4-1.8 11.3-.6 15.5 3.1l9.3 8.1c1.5 1.3 3.3 2 5.2 2h5.6c6 0 9.8-6.3 7.2-11.6l-15.6-31.2c-1.6-3.1-.9-6.9 1.6-9.3l9.9-9.6c1.5-1.5 3.5-2.3 5.6-2.3h9c2.1 0 4.2-.8 5.7-2.3l8-8c3.1-3.1 3.1-8.2 0-11.3l-4.7-4.7c-3.1-3.1-3.1-8.2 0-11.3L264 112l4.7-4.7c6.2-6.2 6.2-16.4 0-22.6l-28.3-28.3c2.5-.1 5-.4 7.6-.4 78.2 0 145.8 45.2 178.7 110.7l-13 6.5c-3.7 1.9-6.9 4.7-9.2 8.1l-19.6 29.4c-5.4 8.1-5.4 18.6 0 26.6l18 27c3.3 5 8.4 8.5 14.1 10l29.2 7.3c-10.8 84-73.9 151.9-155.5 169.7z'
																>
																</path>
															</svg>
														</OverlayTrigger>
												}
											</Button>
										</div>
										{
											this.renderIconFilters()
										}
										{
											this.renderDataLayersMapBtnOnToolbar()
										}
										{
											this.renderLegendMapBtnOnToolbar()
										}
										{
											(state.showNumberColumnsInListing === 2 || innerWidth <= innerWidthToHideListingButtons) ? null :
												<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
													<Button
														style={Object.assign({}, {
															backgroundColor: 'rgba(255,255,255,0.7)',
															color: '#383838',
														})}
														onClick={() => {
															const showNumberColumnsInListingNew: number = 2;
															this.expandContractMap(showNumberColumnsInListingNew);
														}}
													>
														{/* {
															2x2 Listing View
														} */}
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	View Listings 2X2
																</strong>
															</Tooltip>
														}>
															<svg viewBox='0 0 512 512' style={{
																width: sizeIco,
																height: sizeIco,
															}}>
																<path fill='currentColor'
																	d='M464 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM224 416H64V160h160v256zm224 0H288V160h160v256z'
																>
																</path>
															</svg>
														</OverlayTrigger>
													</Button>
												</div>
										}
										{
											(state.showNumberColumnsInListing === 1 || innerWidth <= innerWidthToHideListingButtons) ? null :
												<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
													<Button
														style={Object.assign({}, {
															backgroundColor: 'rgba(255,255,255,0.7)',
															color: '#383838',
														})}
														onClick={() => {
															const showNumberColumnsInListingNew: number = 1;
															this.expandContractMap(showNumberColumnsInListingNew);
														}}
													>
														{/* {
															1X1 Listing View
														} */}
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	View Listings 1X1
																</strong>
															</Tooltip>
														}>
															<svg className='view_list_one_icon' viewBox='0 0 448 512' style={{
																width: sizeIco,
																height: sizeIco,
															}}>
																<path fill='currentColor'
																	d='M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z'
																>
																</path>
															</svg>
														</OverlayTrigger>
													</Button>
												</div>
										}
										{
											(state.showNumberColumnsInListing === 0 || innerWidth <= innerWidthToHideListingButtons) ? null :
												<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
													<Button
														style={Object.assign({}, {
															backgroundColor: 'rgba(255,255,255,0.7)',
															color: '#383838',
														})}
														onClick={() => {
															const showNumberColumnsInListingNew: number = 0;
															this.expandContractMap(showNumberColumnsInListingNew);
														}}
													>
														{/* {
															Hide Listings
														} */}
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-16'>
																	Hide Listings
																</strong>
															</Tooltip>
														}>
															<svg viewBox='0 0 640 512' className='hide_listnigs_icon' style={{
																width: sizeIco,
																height: sizeIco,
															}}>
																<path fill='currentColor'
																	d='M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z'
																>
																</path>
															</svg>
														</OverlayTrigger>
													</Button>
												</div>
										}
									</div>
							}
						</div>
					}
					{
						<div id='distance' style={Object.assign({}, {
							position: 'absolute',
							top: 160,
							right: 10,
							zIndex: 1,
						}) as any}
						>
						</div>
					}
					{
						<div style={Object.assign({}, {
							position: 'absolute'
							, top: 100
							, right: 0
							, display: 'flex'
							, flexDirection: 'row'
							//, flexWrap: 'wrap'
							, justifyContent: 'flex-start'
							, transition: 'width 1s ease-in-out'
							, overflow: 'hidden'
							, zIndex: 3
						}) as any}
						>
							<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
								<Button
									style={Object.assign({}, {
										backgroundColor: 'rgba(255,255,255,0.0)',
										color: '#989898',
									}, state.flagMeasureDistances && {
										color: 'rgb(0, 148, 233)',
									})}
									onClick={() => {
										this.setMeasureDistances(!state.flagMeasureDistances);
									}}
								>
									<OverlayTrigger placement='top' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>
												Measure distances
											</strong>
										</Tooltip>
									}>
										<svg viewBox='0 0 256 512' className='measure_distance' style={{
											width: sizeIco,
											height: sizeIco,
										}}>
											<path fill='currentColor'
												d='M168 416c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h88v-64h-88c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h88v-64h-88c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h88v-64h-88c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h88V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v448c0 17.67 14.33 32 32 32h192c17.67 0 32-14.33 32-32v-64h-88z'
											>
											</path>
										</svg>
									</OverlayTrigger>
								</Button>
							</div>
						</div>
					}
					{
						<div style={Object.assign({}, {
							position: 'absolute'
							, top: 150
							, right: 0
							, display: 'flex'
							, flexDirection: 'row'
							//, flexWrap: 'wrap'
							, justifyContent: 'flex-start'
							, transition: 'width 1s ease-in-out'
							, overflow: 'hidden'
							, zIndex: 3
						}) as any}
						>
							<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
								{/* <Mapshot /> */}
							</div>
						</div>
					}
				</div>
			</div>
		)
	}

	renderMapContent = (): React.ReactElement => {
		if (!this.getMapRef()) {
			return null;
		}
		const { state } = this;
		const { place, sitesGeoJSONArray, portTerminalsGeoJSONArray
			, otherTerminalsGeoJSONArray, warehousesGeoJSONArray
			, multiPurposeGeoJSONArray, intermodalPlace, majorPortPlace } = state;
		const siteFeatures = sitesGeoJSONArray.features;
		const portTerminalsFeatures = portTerminalsGeoJSONArray.features;
		const otherTerminalsFeatures = otherTerminalsGeoJSONArray.features;
		const warehouseFeatures = warehousesGeoJSONArray.features;
		const multiPurposeFeatures = multiPurposeGeoJSONArray.features;
		return (
			<Fragment>
				{
					!this.getMapRef() ? null :
						<ZoomControl map={this.getMapRef()} />
				}
				{
					this.renderNorthAmericanRailLines()
				}
				{
					state.showCrudeOilPipelines &&
					<CrudeOilPipelinesLayer
						key={this.getCrudeOilPipelinesLayerKey('Listing_CrudeOilPipelinesLayer_')}
						theme={
							state.mapStyle === process.env.REACT_APP_MAPBOX_STYLE_SATELLITE
								? PipelineTheme.Light
								: (state.mapStyle === process.env.REACT_APP_MAPBOX_STYLE_STREETS
									? PipelineTheme.Dark
									: null)
						}
					/>
				}
				{
					state.showPetroleumProductPipelines &&
					<PetroleumProductPipelinesLayer
						key={this.getPetroleumProductPipelinesLayerKey('Listing_PetroleumProductPipelinesLayer_')}
						theme={
							state.mapStyle === process.env.REACT_APP_MAPBOX_STYLE_SATELLITE
								? PipelineTheme.Light
								: (state.mapStyle === process.env.REACT_APP_MAPBOX_STYLE_STREETS
									? PipelineTheme.Dark
									: null)
						}
					/>
				}
				{
					state.showMajorPorts && majorPortsJson.features.length > 0 &&
					<MapCluster4MajorPort
						key={'majorPortsJson'}
						collection={majorPortsJson}
						clusterColor={CLUSTER_COLORS.majorPorts}
						onCollectionMarkerClick={this.majorPortMarkerClick}
						onMarkersClusterClick={this.markersClusterClick}
						icon={majorPortsIcon}
					/>
				}
				{
					siteFeatures.length > 0 && (
						<MapCluster
							key={'siteFeatures'}
							data={siteFeatures}
							clusterColor={CLUSTER_COLORS.site}
							onMarkerClick={this.markerClick}
							onMarkersClusterClick={this.markersClusterClick}
							icon={siteIcon}
						/>
					)
				}
				{ // TODO: Icons do not appear until filters are changed.
					portTerminalsFeatures.length > 0 &&
					<MapCluster
						key={'portTerminalsFeatures'}
						data={portTerminalsFeatures}
						clusterColor={CLUSTER_COLORS.portTerminal}
						onMarkerClick={this.markerClick}
						onMarkersClusterClick={this.markersClusterClick}
						icon={portTerminalIcon}
					/>
				}
				{ // TODO: Icons do not appear until filters are changed.
					otherTerminalsFeatures.length > 0 &&
					<MapCluster
						key={'otherTerminalsFeatures'}
						data={otherTerminalsFeatures}
						clusterColor={CLUSTER_COLORS.otherTerminal}
						onMarkerClick={this.markerClick}
						onMarkersClusterClick={this.markersClusterClick}
						icon={otherTerminalIcon}
					/>
				}
				{ // TODO: Icons do not appear until filters are changed.
					warehouseFeatures.length > 0 &&
					<MapCluster
						key={'warehouseFeatures'}
						data={warehouseFeatures}
						clusterColor={CLUSTER_COLORS.warehouse}
						onMarkerClick={this.markerClick}
						onMarkersClusterClick={this.markersClusterClick}
						icon={warehouseIcon}
					/>
				}
				{ // TODO: Icons do not appear until filters are changed.
					multiPurposeFeatures.length > 0 &&
					<MapCluster
						key={'multiPurposeFeatures'}
						data={multiPurposeFeatures}
						clusterColor={CLUSTER_COLORS.multiPurpose}
						onMarkerClick={this.markerClick}
						onMarkersClusterClick={this.markersClusterClick}
						icon={multiPurposeIcon}
					/>
				}
				{ // TODO: Icons do not appear until filters are changed.
					state.showIntermodalData && intermodalJSON.length > 0 &&
					<MapCluster
						key={'intermodalJSON'}
						data={intermodalJSON}
						clusterColor={CLUSTER_COLORS.intermodal}
						onMarkerClick={this.intermodalMarkerClick}
						onMarkersClusterClick={this.markersClusterClick}
						icon={intermodalIcon}
					/>
				}
				{
					state.cityCoordinates && state.cityCoordinates.length > 1 &&
					<Marker
						className='map-unclustered-marker'
						key={'pin_location_'}
						coordinates={state.cityCoordinates}
						style={{
							zIndex: 2,
						}}
					>
						<div style={Object.assign({}, {
							height: state.innerWidth > 600 ? 64 : 48
							, width: state.innerWidth > 600 ? 64 : 48
							, display: 'flex'
							, alignItems: 'center'
							, padding: '0px 0px',
							backgroundColor: 'rgba(255,255,255,0.0)',
							color: '#FF0000',
						})}
						>
							{/* {
								Map Pin Location
							} */}
							<svg viewBox='0 0 288 512' style={{
								width: state.innerWidth > 600 ? 48 : 32,
								height: state.innerWidth > 600 ? 48 : 32,
							}}>
								<path fill='currentColor'
									d='M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z'
								>
								</path>
							</svg>
						</div>
					</Marker>
				}
				{majorPortPlace &&
					(
						<Popup
							key={majorPortPlace.properties.OBJECTID}
							coordinates={majorPortPlace.geometry.type === 'Point' ? [majorPortPlace.geometry.coordinates[0], majorPortPlace.geometry.coordinates[1]] : []}
							offset={[0, -20]}
							anchor='bottom'
						>
							<button type='button' className='close' onClick={this.closePortPopup} style={{ position: 'absolute', right: 5, zIndex: 12 }}>
								<span style={{ float: 'right' }} aria-hidden='true'>×</span><span className='sr-only'>Close</span>
							</button>
							<div style={{ padding: 16 }}>
								<div className='text-center font-size-18 font-weight-bold margin-bottom-10'>Port Info</div>
								{
									majorPortPlace.properties.PORT_NAME && <div><span className='font-weight-bold'>Port Name:</span> {majorPortPlace.properties.PORT_NAME}</div>
								}
								{
									majorPortPlace.properties.FOREIGN_TO && <div><span className='font-weight-bold'>Total Foreign Tonnage:</span> {majorPortPlace.properties.FOREIGN_TO}</div>
								}
								{
									majorPortPlace.properties.DOMESTIC && <div><span className='font-weight-bold'>Total Domestic Tonnage:</span> {majorPortPlace.properties.DOMESTIC}</div>
								}
							</div>
						</Popup>
					)
				}
				{
					intermodalPlace &&
					(
						<Popup
							key={intermodalPlace.OBJECTID}
							coordinates={[intermodalPlace.LON, intermodalPlace.LAT]}
							offset={[0, -20]}
							anchor='bottom'
						>
							<button type='button' className='close' onClick={this.closeIntermodalPopup} style={{ position: 'absolute', right: 5, zIndex: 12 }}>
								<span style={{ float: 'right' }} aria-hidden='true'>×</span><span className='sr-only'>Close</span>
							</button>
							<div style={{ padding: 16 }}>
								<div className='text-center font-size-18 font-weight-bold margin-bottom-10'>Intermodal</div>
								{
									intermodalPlace.TERMINAL && <div><span className='font-weight-bold'>Terminal:</span> {intermodalPlace.TERMINAL}</div>
								}
								{
									intermodalPlace.PORT && <div><span className='font-weight-bold'>Port:</span> {intermodalPlace.PORT}</div>
								}
								{
									intermodalPlace.EQUIPMENT && <div><span className='font-weight-bold'>Equipment:</span> {intermodalPlace.EQUIPMENT}</div>
								}
								{
									intermodalPlace.TERM_ADDRE && <div><span className='font-weight-bold'>Address:</span> {intermodalPlace.TERM_ADDRE}, {intermodalPlace.CITY}, {intermodalPlace.STATE} - {intermodalPlace.ZIP_CODE}</div>
								}
								{
									intermodalPlace.DATE && <div><span className='font-weight-bold'>Date:</span> {intermodalPlace.DATE}</div>
								}
								{
									intermodalPlace.RAIL_COMPA && <div><span className='font-weight-bold'>Rail Compa:</span> {intermodalPlace.RAIL_COMPA}</div>
								}
								{
									intermodalPlace.ACCESS_ROA && <div><span className='font-weight-bold'>Access Road:</span> {intermodalPlace.ACCESS_ROA}</div>
								}
								{
									intermodalPlace.SPLC && <div><span className='font-weight-bold'>SPLC:</span> {intermodalPlace.SPLC}</div>
								}
							</div>
						</Popup>
					)
				}
				{place &&
					(
						<Popup
							key={place.rec_id}
							coordinates={place.geometry.coordinates}
							offset={[0, -20]}
							style={{ width: 295, zIndex: 110 }}
							anchor='bottom'
						>
							<div className='leaflet-popup leaflet-zoom-animated' style={{}}>
								<div className='leaflet-popup-content-wrapper'>
									<div className='leaflet-popup-content' style={{}}>
										<div className='infobox '>
											<div className='inner'>
												<div className='image' style={{ position: 'relative' }}>
													<div className='overlay'>
														<div className='wrapper'>
															<div style={{ position: 'absolute', top: 12, left: 0, right: 0 }}>
																<a href='#quick-view' style={{ cursor: 'pointer' }}
																	role='button'
																	onClick={() => this.showQVModal("Modal")}
																	className='quick-view'
																	data-toggle='modal'
																	data-target='#modal'
																>
																	Go to Details
																</a>
																<hr />
																<a target='_blank'
																	role='button'
																	onClick={() => this.showQVModal("Link")}
																	className='quick-view'
																>
																	Open in New Tab
																</a>
															</div>
														</div>
													</div>
													<img src={this.getFirstImage(place.properties.image)} alt='property image' />
												</div>
												<div className='row'>
													<div className='col-md-12'
														style={{
															minHeight: 30
														}}
													>
														<div className='position-relative pull-left'>
															<div className='pull-left margin-top-7 form-group margin-left-15' >
																<OverlayTrigger placement='bottom' overlay={
																	<Tooltip id='social-media-tooltip'>
																		<strong className='font-size-16'>Add to Favorites</strong>
																	</Tooltip>
																}>
																	<i id='bookmark-btn' className='color-black cursor-pointer font-size-18-imp  hover fa fa-star color-white hover'
																		onClick={() => this.addToBookmarkList(place.rec_id)}></i>
																</OverlayTrigger>
															</div>
															{/* <div className='pull-left margin-top-7 form-group margin-left-15'>
																<OverlayTrigger placement='bottom' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>Share on Facebook</strong>
																	</Tooltip>
																}>
																	<FacebookShareButton url={window.location.origin + '/details-' + place.rec_id}>
																		<i className='color-black hover fa font-size-18-imp fa-facebook cursor-pointer'></i>
																	</FacebookShareButton>
																</OverlayTrigger>
															</div> */}

															<div className='pull-left margin-top-7 form-group margin-left-15'>
																<OverlayTrigger placement='bottom' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>Share on Twitter</strong>
																	</Tooltip>
																}>
																	<TwitterShareButton url={window.location.origin + '/details-' + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id}>
																		<i className='color-black hover fa font-size-18-imp fa-twitter cursor-pointer'></i>
																	</TwitterShareButton>
																</OverlayTrigger>
															</div>

															<div className='pull-left margin-top-7 form-group margin-left-15'>
																<OverlayTrigger placement='bottom' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>Share on LinkedIn</strong>
																	</Tooltip>
																}>
																	<a href={"https://www.linkedin.com/shareArticle?mini=true&url=https://share.industrialnavigator.com/industrialnavigator.php?" + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id} target="_blank"><i className="fab fa-linkedin-in cursor-pointer color-black hover fa font-size-18-imp"></i></a>

																</OverlayTrigger>
															</div>
															{/* <div className='pull-left margin-top-7 form-group margin-left-15'>
																<OverlayTrigger placement='bottom' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>Copy link</strong>
																	</Tooltip>
																}>
																	<CopyToClipboard text={"https://share.industrialnavigator.com/industrialnavigator.php?" + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id}>
																		<i className='color-black hover fa font-size-18-imp fa-link cursor-pointer' onClick={this.copyShareableLink}></i>
																	</CopyToClipboard>
																</OverlayTrigger>
															</div> */}

															<div className='pull-left margin-top-7 form-group margin-left-15'>
																<OverlayTrigger placement='bottom' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>Share on Email</strong>
																	</Tooltip>
																}>
																	<EmailShareButton onClick={() => { }}
																		openShareDialogOnClick
																		// url={window.location.href}
																		url={window.location.origin + '/details-' + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id}
																		subject={'Industrial Navigator'}
																		body={'Industrial Navigator'}
																	>
																		<i className='color-black hover fa font-size-18-imp fa-envelope cursor-pointer'></i>
																	</EmailShareButton>
																</OverlayTrigger>
															</div>

															<div className='pull-left margin-top-7 form-group margin-left-15' style={{ right: 15, top: 25, width: 30 }}>
																<OverlayTrigger placement='bottom' overlay={
																	<Tooltip id='tooltip'>
																		<strong className='font-size-16'>Generate PDF</strong>
																	</Tooltip>
																}>
																	<i className='color-black hover fa font-size-18-imp fa-file-pdf-o cursor-pointer' onClick={this.createPDF}></i>
																</OverlayTrigger>
															</div>

														</div>
														<button type='button'
															className='close pull-right margin-right-10'
															onClick={this.closePopup}
															style={{
																position: 'absolute',
																right: 15,
																marginTop: 6,
																zIndex: 12,
																color: 'black',
																width: 21,
															}}
														>
															<span style={{ float: 'right' }} aria-hidden='true'>×</span>
															<span className='sr-only'>Close</span>
														</button>

													</div>
													<div className='col-md-12'>
														{/* <Link to={{ pathname: '/details-' + place.rec_id }} to='#' className='description'> */}
														<label>
															<div className='meta' style={{ padding: 22 }}>

																<h2 className='popup-listing-name'>{place.properties.listingName}</h2>




																<figure style={{
																	color: '#474747',
																	fontSize: 12
																}}>{place.properties.city} ·  {place.properties.state}</figure>
																{/* <i className='fa fa-angle-right'
															style={{
																color: '#4c9cd3',
																height: 10,
																margin: 'auto',
																position: 'absolute',
																bottom: 45,
																right: 16,
															}}>
														</i> */}
															</div>
															{/* </Link> */}
														</label>

													</div>


												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Modal
								dialogClassName='quick-view-modal width-80'
								show={this.state.modalVisible}
								onHide={this.closeQVModal}
								size='large'
								aria-labelledby='contained-modal-title-lg'
								animation={false}
							>
								<Modal.Header style={{ padding: 0 }} closeButton />
								<Modal.Body style={{ marginBottom: 0, borderBottom: 'none', padding: 0 }} id='main-content'>
									<div className='col-lg-12 padding-0'>
										<div className='real-estate-item-image margin-bottom-20'>
											<Carousel
												autoPlay={true}
												stopOnHover={true}
												infiniteLoop={true}
												swipeable={true}
												dynamicHeight={true}
												showThumbs={place.properties.image && place.properties.image.length > 1 ? true : false}
												emulateTouch={true}
												interval={3000}
												className={place.properties.image && place.properties.image.length > 1 ? 'margin-bottom-120' : ''}
											>
												{place.properties.image.map((value, index) => {
													return <div key={index}><img src={value.url} /></div>
												})}
											</Carousel>
											<div style={{ position: 'absolute', height: 100, left: 0, right: 0, bottom: 0, backgroundColor: 'black', opacity: 0.6 }}></div>
											<div className='' style={{ position: 'absolute', bottom: 12, zIndex: 1, width: '99%' }}>
												<div className='col-lg-8 col-lg-offset-2 temp1 '>
													<div className='pull-left color-white reduce-font-size-12px reduce-font-size-15 reduce-font-size-16 reduce-font-size-18  margin-botton-10' style={{ fontSize: '20px', fontWeight: 'bold', paddingRight: '10px', width: '100%' }}>
														{place.properties.listingName}

													</div>
												</div>
												<div className='col-lg-8 col-lg-offset-2 temp1 margin-bottom-30 '>
													<div className='clear-both'
														style={{
															display: 'flex',
														}}
													>
														<i className='fa font-size-20-imp fa-map-marker pull-left color-white reduce-margin-top-10' aria-hidden='true'></i>
														<div className='font-size-14 reduce-font-size-11 pull-left color-white reduce-margin-top-10'
															style={{
																marginLeft: 2,
																whiteSpace: 'nowrap',
															}}
														>
															{place.properties.address}
														</div>
													</div>
												</div>
											</div>
											<div style={{ position: 'absolute', bottom: 0, right: 0, marginRight: '1%', zIndex: 2 }}>
												<div className='pull-left' style={{ padding: 10 }}>
													<OverlayTrigger placement='bottom' overlay={
														<Tooltip id='social-media-tooltip'>
															<strong className='font-size-16'>Add to Favorites</strong>
														</Tooltip>
													}>
														<i id='bookmark-btn' className='cursor-pointer left-allign-icons reduce-font-size-15 fa font-size-20-imp fa-star color-white hover'
															onClick={() => this.addToBookmarkList(place.rec_id)}></i>
													</OverlayTrigger>
												</div>

												{/* <div className='pull-left' style={{ padding: 10 }}>
													<OverlayTrigger placement='bottom' overlay={
														<Tooltip id='social-media-tooltip'>
															<strong className='font-size-16'>Share on Facebook</strong>
														</Tooltip>
													}>
														<FacebookShareButton url={window.location.origin + '/details-' + place.rec_id}>
															<i className='color-white fa left-allign-icons reduce-font-size-15 font-size-20-imp fa-facebook cursor-pointer hover'></i>
														</FacebookShareButton>
													</OverlayTrigger>
												</div> */}

												<div className='pull-left' style={{ padding: 10 }}>
													<OverlayTrigger placement='bottom' overlay={
														<Tooltip id='social-media-tooltip'>
															<strong className='font-size-16'>Share on Twitter</strong>
														</Tooltip>
													}>
														<TwitterShareButton url={window.location.origin + '/details-' + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id}>
															<i className='color-white left-allign-icons reduce-font-size-15 fa font-size-20-imp fa-twitter cursor-pointer hover'></i>
														</TwitterShareButton>
													</OverlayTrigger>
												</div>

												<div className='pull-left' style={{ padding: 10 }}>
													<OverlayTrigger placement='bottom' overlay={
														<Tooltip id='social-media-tooltip'>
															<strong className='font-size-16'>Share on LinkedIn</strong>
														</Tooltip>
													}>
														<a href={"https://www.linkedin.com/shareArticle?mini=true&url=https://share.industrialnavigator.com/industrialnavigator.php?" + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id} target="_blank"><i className="fab fa-linkedin-in color-white left-allign-icons reduce-font-size-15 fa font-size-20-imp cursor-pointer hover"></i></a>
													</OverlayTrigger>
												</div>
												<div className='pull-left' style={{ padding: 10 }}>
													<OverlayTrigger placement='bottom' overlay={
														<Tooltip id='social-media-tooltip'>
															<strong className='font-size-16'>Copy link</strong>
														</Tooltip>
													}>
														<CopyToClipboard text={"https://share.industrialnavigator.com/industrialnavigator.php?" + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id}>
															<i className='color-white left-allign-icons hover fa reduce-font-size-15 font-size-20-imp fa-link cursor-pointer' onClick={this.copyShareableLink}></i>
														</CopyToClipboard>
													</OverlayTrigger>
												</div>

												<div className='pull-left' style={{ padding: 10 }}>
													<OverlayTrigger placement='bottom' overlay={
														<Tooltip id='social-media-tooltip'>
															<strong className='font-size-16'>Share on Email</strong>
														</Tooltip>
													}>
														<EmailShareButton onClick={() => { }} openShareDialogOnClick
															// url={window.location.href}
															url={window.location.origin + '/details-' + place.properties.propertyType.replaceAll('Warehouse/Distribution Center', 'warehouse').replaceAll('Industrial Sites & Properties', 'industrial-site').replaceAll('*', '-').replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.city.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.state.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.properties.listingName.replaceAll(' ', '-').toLowerCase().replaceAll('&', '') + '-' + place.rec_id}
															subject={'Industrial Navigator'}
															body={'Industrial Navigator'}
														>
															<i className='color-white left-allign-icons fa reduce-font-size-15 font-size-20-imp fa-envelope cursor-pointer hover'></i>
														</EmailShareButton>
													</OverlayTrigger>
												</div>

												<div className='pull-left' style={{ top: 25, padding: 10 }}>
													<OverlayTrigger placement='bottom' overlay={
														<Tooltip id='social-media-tooltip'>
															<strong className='font-size-16'>Generate PDF</strong>
														</Tooltip>
													}>
														<i className='color-white fa left-allign-icons reduce-font-size-15 font-size-20-imp fa-file-pdf-o cursor-pointer hover' onClick={this.createPDF}></i>
													</OverlayTrigger>
												</div>
											</div>
										</div>
									</div>

									<div className='col-lg-12 qv-body-content' style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 5, paddingRight: 5 }}>
										{
											state.showAlert &&
											<Alert bsStyle='success' onDismiss={this.handleDismiss}>
												<h2 style={{ marginTop: 0, marginBottom: 10, color: 'white' }}>Success!</h2>
												<p>
													Your message has been sent!
												</p>
											</Alert>
										}
										<div className='col-lg-8 col-lg-offset-2 temp'>
											{
												place.properties.organizationName &&
												<div className='margin-bottom-20 border-bottom-1 color-black'>
													<header>
														<h2 className='margin-bottom-15 text-decoration-underline'>Organization Name</h2>
													</header>
													{
														place.properties.listingLogo !== '' &&
														<div style={{ width: 150, height: 40, maxHeight: 40, marginBottom: 10 }}>
															<img style={{ height: '100%' }} src={place.properties.listingLogo} />
														</div>
													}
													<p>{place.properties.organizationName !== undefined && place.properties.organizationName !== '' ? place.properties.organizationName : ''}</p>
												</div>
											}
										</div>
										<div className='col-lg-8 col-lg-offset-2 temp'>
											{

												<div className='margin-bottom-20 border-bottom-1'>
													<header>
														<h2 className='margin-bottom-15 text-decoration-underline text-decoration-underline color-black'>Contact Information</h2>
													</header>
													<div>
														<div className='font-size-14 color-black' style={{ fontWeight: 600, marginBottom: 10 }}>
															{place.properties.contactName || ''}{place.properties.contactTitle ? '/' + place.properties.contactTitle : ''}
														</div>
														<div className='font-size-14 color-black' style={{ fontWeight: 600, marginBottom: 10 }}>
															{/* {place.properties.companyWebsiteUrl && place.properties.companyWebsiteUrl != '' && 'Company Website: ' + place.properties.companyWebsiteUrl} */}
															{
																place.properties.companyWebsiteUrl && place.properties.companyWebsiteUrl != '' &&
																<a onClick={() => this.openInNewTab(place.properties.companyWebsiteUrl)}>Company Website</a>
															}
														</div>
														{/* {
															this.state.planId == '2' ?
																<>
																	<div style={{ display: 'flex' }} className='position-relative margin-bottom-20 color-blue'>
																		<OverlayTrigger placement='right' overlay={
																			<Tooltip id='social-media-tooltip'>
																				<strong style={{
																					fontSize: 16
																					, fontWeight: 'bold'
																					, color: 'rgba(3, 61, 245, 1.0)'
																					, textDecoration: 'none'
																				}} className='font-size-16'>Please upgrade your plan to Power Search or Power Pro to use this feature. See </strong>
																				<a href={'/plans-and-pricing'}
																					style={{
																						fontSize: 16
																						, fontWeight: 'bold'
																						, color: 'rgba(3, 61, 245, 1.0)'
																						, textDecoration: 'underline'
																					}}
																				>
																					<span
																						style={{
																							fontSize: 16
																							, fontWeight: 'bold'
																							, color: 'rgba(3, 61, 245, 1.0)'
																							, textDecoration: 'underline'
																						}}
																					>
																						Plans and Pricing
																					</span>
																				</a>
																			</Tooltip>
																		}>
																			<div>
																				<i className='fa font-size-20-imp fa-address-book cursor-pointer' aria-hidden='true' ></i>
																				<span className='font-size-14 cursor-pointer font-weight-bold' style={{ marginLeft: 10 }} onClick={this.handleContactUsShow}>Contact</span>
																			</div>
																		</OverlayTrigger>
																	</div>

																</>
																:
																<>
																	<div style={{ display: 'flex' }} className='position-relative margin-bottom-20 color-blue'>
																		<i className='fa font-size-20-imp fa-address-book cursor-pointer' aria-hidden='true' onClick={this.handleContactUsShow}></i>
																		<span className='font-size-14 cursor-pointer font-weight-bold' style={{ marginLeft: 10 }} onClick={this.handleContactUsShow}>Contact</span>
																	</div>
																</>
														} */}
														<div style={{ display: 'flex' }} className='position-relative margin-bottom-20 color-blue'>
															<i className='fa font-size-20-imp fa-address-book cursor-pointer' aria-hidden='true' onClick={this.handleContactUsShow}></i>
															<span className='font-size-14 cursor-pointer font-weight-bold' style={{ marginLeft: 10 }} onClick={this.handleContactUsShow}>Contact</span>
														</div>
													</div>
												</div>
											}
										</div>
										<div className='col-lg-8 col-lg-offset-2 temp'>
											<div className='margin-bottom-20 border-bottom-1 color-black'>
												<header>
													<h2 className='margin-bottom-15 text-decoration-underline'>Description</h2>
												</header>
												<p className='font-size-14'>{place.properties.description}</p>
											</div>
										</div>
										<div className='col-lg-8 col-lg-offset-2 temp'>
											<div className='margin-bottom-20 border-bottom-1 color-black'>
												<header>
													<h2 className='margin-bottom-15 text-decoration-underline'>Brochures</h2>
												</header>
												{
													place.properties.Brochures &&
													<div>
														{
															place.properties.Brochures.map((value, index) => {
																return <div key={index}><a href={value.url} >Download the brochure here.</a></div>
															})
														}
													</div>
												}

											</div>
										</div>
										<div className='col-lg-8 col-lg-offset-2 temp margin-bottom-20'>
											<Features feature={place} />
										</div>
										<div className='col-lg-8 col-lg-offset-2 temp margin-bottom-20'>
											<Mapbox
												style={state.mapType}
												onStyleLoad={map => {
													this.onStyleLoad(map);
												}}
												zoom={[16]}
												center={[place.geometry.coordinates[0], place.geometry.coordinates[1]]}
												containerStyle={{
													height: '300px'
												}}
											>
												<ZoomControl />

												{place.properties.propertyType === 'Industrial Sites & Properties' &&
													<Layer
														type='symbol'
														id='siteMarker'
														layout={{
															'icon-image': 'siteImage',
															'icon-allow-overlap': false
														}}
														images={siteImages}
													>
														{place !== undefined &&
															<Feature
																key={place.rec_id}
																onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																onMouseLeave={this.onToggleHover.bind(this, '')}
																onClick={this.markerClick.bind(this, place)}
																coordinates={place.geometry.coordinates}
															/>
														}
													</Layer>
												}
												{place.properties.propertyType === 'Port Terminals' &&
													<Layer
														type='symbol'
														id='portTerminalMarker'
														layout={{
															'icon-image': 'portTerminalImage',
															'icon-allow-overlap': false
														}}
														images={portTerminalImages}
													>
														{place !== undefined &&
															<Feature
																key={place.rec_id}
																onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																onMouseLeave={this.onToggleHover.bind(this, '')}
																coordinates={place.geometry.coordinates}
															/>
														}
													</Layer>
												}

												{place.properties.propertyType === 'Terminals and Transload Facilities' &&
													<Layer
														type='symbol'
														id='otherTerminalMarker'
														layout={{
															'icon-image': 'otherTerminalImage',
															'icon-allow-overlap': false
														}}
														images={otherTerminalImages}
													>
														{place !== undefined &&
															<Feature
																key={place.rec_id}
																onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																onMouseLeave={this.onToggleHover.bind(this, '')}
																coordinates={place.geometry.coordinates}
															/>
														}
													</Layer>
												}

												{place.properties.propertyType === 'Warehouse/Distribution Center' &&
													<Layer
														type='symbol'
														id='warehouseMarker'
														layout={{
															'icon-image': 'warehouseImage',
															'icon-allow-overlap': false
														}}
														images={warehouseImages}
													>
														{place !== undefined &&
															<Feature
																key={place.rec_id}
																onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																onMouseLeave={this.onToggleHover.bind(this, '')}
																coordinates={place.geometry.coordinates}
															/>
														}
													</Layer>
												}

												{
													(place.properties.propertyType === 'Industrial Sites & Properties*Port Terminals*Warehouse/Distribution Center' ||
														place.properties.propertyType === 'Industrial Sites & Properties*Terminals and Transload Facilities*Warehouse/Distribution Center' ||
														place.properties.propertyType === 'Industrial Sites & Properties*Port Terminals' ||
														place.properties.propertyType === 'Industrial Sites & Properties*Terminals and Transload Facilities' ||
														place.properties.propertyType === 'Port Terminals*Warehouse/Distribution Center' ||
														place.properties.propertyType === 'Terminals and Transload Facilities*Warehouse/Distribution Center' ||
														place.properties.propertyType === 'Industrial Sites & Properties*Warehouse/Distribution Center') &&

													<Layer
														type='symbol'
														id='multiPurposeMarker'
														layout={{
															'icon-image': 'multiPurposeImage',
															'icon-allow-overlap': false
														}}
														images={multiPurposeImages}
													>
														{place !== undefined &&
															<Feature
																key={place.rec_id}
																onMouseEnter={this.onToggleHover.bind(this, 'pointer')}
																onMouseLeave={this.onToggleHover.bind(this, '')}
																coordinates={place.geometry.coordinates}
															/>
														}
													</Layer>
												}

											</Mapbox>
											<div style={{ position: 'absolute', top: 20, left: 20, right: 20, height: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
												<Button onClick={this.changeMapStyleInQV}>Change style</Button>
											</div>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer>
								</Modal.Footer>
							</Modal>
							{/* <Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
								<Modal.Header closeButton></Modal.Header>
								<Modal.Body>
									<div>
										<div style={{ padding: '1rem', backgroundColor: 'white' }}>
											<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
												<div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
													<img src={logo} />
												</div>
												<form id='slider-subscribe-form' style={{ paddingBottom: '10px' }} className='nobottommargin'>
													<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Enter email address and password to login</div>
													<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
													<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
													<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
														<input type='email' name='login_email' className='form-control input-lg not-dark required email col-lg-12' placeholder='Enter your Email..' onChange={this.setLoginEmail.bind(this)} value={this.state.login_email} />
													</div>
													{this.loginValidator.message('email', this.state.login_email, 'required')}
													<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
														<input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword.bind(this)} value={this.state.login_password} />
													</div>
													{this.loginValidator.message('password', this.state.login_password, 'required')}
													<div className='col_full center padding-top-20'>
														<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin.bind(this)}>Sign In</button>
													</div>
													<div className='col_full center padding-top-20'>
														<span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
													</div>
												</form>
												<div style={{ paddingTop: '1rem' }}>
													<small>
														<div className='text-center text-black font-weight-bold'>
															Don't have an account? <span className='cursor-pointer color-blue' onClick={this.showRegistrationModal}>Sign Up</span></div>
													</small>
												</div>
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal> */}
							<Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
								<Modal.Header closeButton></Modal.Header>
								<Modal.Body style={{ padding: '2px' }}>

									<div>
										<div style={{ padding: '2px', backgroundColor: 'white' }}>
											<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
												<div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
													<img src={logo} />
												</div>
												<Tabs defaultActiveKey="Sign Up"  >
													<Tab eventKey="Sign Up" title="Sign Up" style={{ color: "black" }}>
														<>
															<div>
																<div style={{ backgroundColor: '#f8f9fa', paddingTop: '.5rem', borderRadius: 6 }}>

																	<form id='slider-subscribe-form' style={{ width: '99%', marginLeft: '0%', paddingBottom: '10px' }} className='nobottommargin reduce-margin-form'>
																		<div className='color-black  margin-bottom-20' style={{ color: '#474747', fontSize: 14 }}>Hello! You have reached your limit of 5 Industrial Navigator site views. To continue your search, please sign up for a free, basic account or a full-function, subscription account. To view all plans, please visit <a href="/plans-and-pricing">Plans and Pricing</a>, or sign up for free below!</div>

																		<div id='regi_errors' className='margin-top-15'></div>

																		<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																			<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
																		</div>
																		{this.registerValidator.message('first name', this.state.fname, 'required')}

																		<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																			<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
																		</div>
																		{this.registerValidator.message('last name', this.state.lname, 'required')}
																		<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																			<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
																		</div>
																		{this.registerValidator.message('company name', this.state.companyName, 'required')}
																		<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																			<input type='email' name='reg_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmailreg.bind(this)} value={this.state.reg_email} />
																		</div>
																		{this.registerValidator.message('email', this.state.reg_email, 'required')}
																		{/* <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																			<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
																		</div>
																		{this.registerValidator.message('password', this.state.password, 'required')}
																		<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																			<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
																		</div>
																		{this.registerValidator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
																		 */}
																		{/* <ReCAPTCHA
																			ref={recaptchaRef}
																			// size='invisible'
																			className='margin-top-15'
																			onChange={this.onRecaptchaChange.bind(this)}
																			sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
																		/>
																		{this.registerValidator.message('captcha', this.state.reacatpcha_value, 'required')} */}
																		<div className='col_full center padding-top-20'>
																			<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
																		</div>
																	</form>
																</div>
															</div>
														</>
													</Tab>
													<Tab eventKey="Login" title="Login" style={{ color: "black" }}>
														<>
															<form id='slider-subscribe-form' style={{ paddingBottom: '10px' }} className='nobottommargin'>
																<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747', marginTop: 20 }}>Enter email address and password to login</div>
																<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
																<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
																<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																	<input type='email' name='login_email' className='form-control input-lg not-dark required email col-lg-12' placeholder='Enter your Email..' onChange={this.setLoginEmail.bind(this)} value={this.state.login_email} />
																</div>
																{this.loginValidator.message('email', this.state.login_email, 'required')}
																<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																	<input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword.bind(this)} value={this.state.login_password} />
																</div>
																{this.loginValidator.message('password', this.state.login_password, 'required')}
																<div className='col_full center padding-top-20'>
																	<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin.bind(this)}>Sign In</button>
																</div>
																<div className='col_full center padding-top-20'>
																	<span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
																</div>
															</form>
														</>
													</Tab>

												</Tabs>
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
								<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
								<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
									<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
										<div className='event-type info'>
											<div className='event-indicator '>
												<SVGIcon />
											</div>
										</div>
										<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
											Registration Successful
										</h2><br />
										<p className='text-muted text-left font-size-16-imp margin-bottom-0'>
											Congratulations on creating a Basic account!<br />Your unique password is {this.state.password}. You can change it later by clicking on "forgot password" when logging in next time.<br /><br /> Now that you have subscribed, you may create and list up 5 properties on our platform for free. You may also browse all listings in our app. Note that some browsing features will not be available with the Basic plan. If you want to experience all of what Industrial Navigator has to offer, please upgrade by clicking on "Admin" -&gt; My Profile. For any questions, please email us at info@industrialnavigator.com, or call +1 (312) 957-7757.
										</p>
									</div>
								</Modal.Body>
								<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
									<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
								</Modal.Footer>
							</Modal>
							<Modal dialogClassName='custom-modal-step3' show={this.state.showRegistrationModal} onHide={this.handleRegistrationModal} style={{ opacity: 1 }} animation={false}>
								<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
								<Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
									<div>
										<div>
											<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
												<div style={{ width: 200, margin: '30px auto' }}>
													<img src={logo} />
												</div>
												{/* <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Welcome to Industrial Navigator!
									</h2> */}
												<MultiStepRegistration />
												{/* <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
										<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Please enter your details below to register for Industrial Navigator.</div>

										<div id='regi_errors' className='margin-top-15'></div>

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
										</div>
										{this.validator.message('first name', this.state.fname, 'required')}

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
										</div>
										{this.validator.message('last name', this.state.lname, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
										</div>
										{this.validator.message('company name', this.state.companyName, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail.bind(this)} value={this.state.email} />
										</div>
										{this.validator.message('email', this.state.email, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
										</div>
										{this.validator.message('password', this.state.password, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
										</div>
										{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
										<ReCAPTCHA
											ref={recaptchaRef}
											// size='invisible'
											className='margin-top-15'
											onChange={this.onRecaptchaChange.bind(this)}
											sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
										/>
										{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
										<div className='col_full center padding-top-20'>
											<button className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
										</div>
									</form> */}
												{/* <div style={{ paddingTop: '1rem' }}>
										<small>
											<div className='text-center text-black font-weight-bold'>
												Already have an account? <span className='cursor-pointer color-blue' onClick={this.checkIfLoginPage}>Sign In</span></div>
										</small>
									</div> */}
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={this.state.showOTPModal} onHide={this.handleOTPModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
								<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
								<Modal.Body style={{ backgroundColor: '#009df5' }}>
									<div>
										<div style={{ padding: '3rem' }}>
											<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
												<div style={{ width: 200, margin: '0 auto' }}>
													<img src={logo} />
												</div>
												<h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
													Verify OTP
												</h2>
												<form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
													<div id='otp_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email.</div>
													<div id='otp_success' className='alert alert-success' style={{ display: 'none' }}> Password link should be sent to your email address.</div>
													<InputBoxComponent type='number' min='0' name='otp' onHandleInputChange={this.handleInputChange} />
													{this.OTPValidator.message('otp', this.state.otp, 'required')}
												</form>
											</div>
										</div>
									</div>
								</Modal.Body>
								<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
									<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPVerification}>Verify</button>
									<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPModal.bind(this)}>Close</button>
								</Modal.Footer>
							</Modal>
							<Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
								<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
								<Modal.Body style={{ backgroundColor: '#009df5' }}>
									<div>
										<div style={{ padding: '3rem' }}>
											<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
												<div style={{ width: 200, margin: '0 auto' }}>
													<img src={logo} />
												</div>
												<h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
													Forgot Password
												</h2>
												<form style={{ width: '95%', marginLeft: '2%', marginRight: '2%', paddingBottom: 10 }} className='nobottommargin'>
													<div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
													<div id='fp_success' className='alert alert-success' style={{ display: 'none' }}> Password link should be sent to your email address.</div>
													{/* <div className='input-group margin-top-15 col-lg-12'>
											<input type='email' name='fpEmail' className='input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
										</div> */}
													<input type='email' name='fpEmail' className='input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />

													<div className='col_full center padding-top-20'>
														<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
													</div>
												</form>
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
								<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
								<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
									<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
										<div className='event-type info'>
											<div className='event-indicator '>
												<SVGIcon />
											</div>
										</div>
										<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
											Login Successful
										</h2>
									</div>
								</Modal.Body>
								<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
									<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
								</Modal.Footer>
							</Modal>
							<DetailsModal show={this.state.pdfModal} handleClose={this.hidepdfModal} >
								<div style={{ paddingTop: '1rem', paddingLeft: '0rem', paddingRight: '0rem' }}>

									<div>

										<div style={{ display: 'block' }} >
											<PDFGen rec_id={this.state.place.rec_id} ref={(response) => (componentRef = response)} />
										</div>


										<ReactToPrint
											content={() => componentRef}
											trigger={() => <Button className='text-uppercase border-radius-25-imp btn btn-default margin-bottom-20'>
												Print to PDF!
											</Button>}
										/>
									</div>
								</div>
							</DetailsModal>
						</Popup>
					)}
			</Fragment>
		)
	}

	renderDataLayersMapBtnOnToolbar = (): React.ReactElement => {
		const { state } = this;
		const {
			specificClientConfiguration
		} = state;
		const {
			showNumberColumnsInListing
			, innerWidthToHideListingButtons
			, innerWidth
		} = state;
		const flagSmallIcons: boolean = innerWidth <= innerWidthToHideListingButtons;
		const sizeIco: number = flagSmallIcons ? sizeIcoSmall : sizeIcoBig;
		const placeIco: number = flagSmallIcons ? placeIcoSmall : placeIcoBig;
		let flagMultiClassICrudeOilPipelines = false;
		let flagMultiClassIPetroleumProductPipelines = false;
		let flagShortline = false;
		let flagIntermodal = false;
		const membershipOptionsStr = getStorageItem('membershipOptions');
		if (membershipOptionsStr) {
			const membershipOptionsArr: Array<{
				multi_class_I: string
				, favorites: string
				, dmsg: string
				, classI: string
				, shortline: string
				, intermodal: string
				, ports: string
				, keywords: string
			}> = JSON.parse(membershipOptionsStr);
			if (membershipOptionsArr && membershipOptionsArr.length >= 1) {
				const flagMultiClassI = new Boolean(membershipOptionsArr[0].multi_class_I).valueOf();
				flagMultiClassICrudeOilPipelines = flagMultiClassI;
				flagMultiClassIPetroleumProductPipelines = flagMultiClassI;
				flagShortline = new Boolean(membershipOptionsArr[0].shortline).valueOf();
				flagIntermodal = new Boolean(membershipOptionsArr[0].intermodal).valueOf();
			}
		}
		const privateClientId: string = this.getPrivateClientId();
		if (privateClientId) {
			flagIntermodal = specificClientConfiguration.flagAvailableIntermodalTerminals;
			flagMultiClassICrudeOilPipelines = specificClientConfiguration.flagAvailableCrudeOilPipelines;
			flagMultiClassIPetroleumProductPipelines = specificClientConfiguration.flagAvailablePetroleumPipelines;
		}
		const keySuffix = '_' + flagMultiClassICrudeOilPipelines + '_' + flagMultiClassIPetroleumProductPipelines + '_' + flagShortline + '_' + flagIntermodal;
		return (
			<OverlayTrigger key={'DataLayersMapBtn_' + keySuffix} placement='bottom' trigger='click' ref='dataLayerOverlay' overlay={
				<Tooltip id='tooltip' style={{ opacity: 1 }}>
					<div style={{ display: 'flex', flexDirection: 'column', width: 280, padding: 10 }}>
						<button
							type='button'
							className='close'
							style={{ position: 'absolute', width: '100%', boxShadow: 'none', right: 5, top: 8 }}
							onClick={() => (this.refs.dataLayerOverlay as OverlayTrigger).hide()}
						>
							<span style={{ float: 'right' }} aria-hidden='true'>×</span>
							<span className='sr-only'>Close</span>
						</button>
						<div className=''>
							<span className='pull-left font-size-14 font-weight-bold' style={{ paddingBottom: 15 }}>Map Data Layers</span>
						</div>
						{
							!specificClientConfiguration.flagAvailableClassIConnections ? null :
								this.renderClassIRailsFilterComponent()
						}
						{
							specificClientConfiguration.shortLinesDataAvailable === null
								?
								this.renderShortLinesFilterComponent(flagShortline)
								:
								null
						}
						{
							!specificClientConfiguration.flagAvailableIntermodalTerminals ? null :
								this.renderIntermodalTerminalsCheckbox(flagIntermodal)
						}
						{
							!specificClientConfiguration.flagAvailableCrudeOilPipelines ? null :
								this.renderCrudeOilPipelinesCheckbox(flagMultiClassICrudeOilPipelines)
						}
						{
							!specificClientConfiguration.flagAvailablePetroleumPipelines ? null :
								this.renderPetroleumProductPipelinesCheckbox(flagMultiClassIPetroleumProductPipelines)
						}
						{
							!specificClientConfiguration.flagAvailableMajorPorts ? null :
								this.renderMajorPortsCheckbox()
						}
						{
							!specificClientConfiguration.flagAvailableRoadways ? null :
								this.renderRoadwaysCheckbox()
						}
						{
							this.renderIndustrialSitesCheckbox()
						}
						{
							this.renderTerminalsAndTransloadsCheckbox()
						}
						{
							this.renderPortTerminalsCheckbox()
						}
						{
							this.renderWarehousesCheckbox()
						}
					</div>
				</Tooltip>
			}>
				<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
					<Button
						style={Object.assign({}, {
							backgroundColor: 'rgba(255,255,255,0.7)',
							color: '#383838',
						})}
						onClick={() => (this.refs.legendOverlay as OverlayTrigger).hide()}
					>
						{/* {
							Data Layers
						} */}
						<OverlayTrigger placement='top' overlay={
							<Tooltip id='tooltip'>
								<strong className='font-size-16'>
									Data Layers
								</strong>
							</Tooltip>
						}>
							<svg viewBox='0 0 512 512' className='data_layers' style={{
								width: sizeIco,
								height: sizeIco,
							}}>
								<path fill='currentColor'
									d='M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z'
								>
								</path>
							</svg>

						</OverlayTrigger>

					</Button>
				</div>
			</OverlayTrigger>
		)
	}

	renderClassIRailsFilterComponent = (): React.ReactElement => {
		const { state } = this;
		const { specificClientConfiguration } = this.state;
		const classOneConnectionsOptionsTmp: Array<LabelValue> = [...classOneConnectionsOptions];
		return (
			<div className='margin-bottom-5'>
				<OverlayTrigger placement='bottom' ref='classOneConnOverlay' trigger='click' rootClose overlay={
					<Tooltip id='tooltip1' style={{ opacity: 1 }}>
						<div className='text-center' style={{ paddingTop: 20 }}>
							<button
								type='button'
								className='close'
								style={{ position: 'absolute', width: '100%', boxShadow: 'none', right: 5, top: 8 }}
								onClick={() => (this.refs.classOneConnOverlay as OverlayTrigger).hide()}
							>
								<span style={{ float: 'right' }} aria-hidden='true'>×</span>
								<span className='sr-only'>Close</span>
							</button>
							<div className='form-group'>
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0 text-uppercase font-weight-bold'
									style={{ fontSize: 12, paddingBottom: 10 }}
								>
									<span className='pull-left'>Select class I connections </span>
									<span className='pull-right cursor-pointer color-blue'
										style={Object.assign({
										}, {
											// color: 'white'
										}, specificClientConfiguration.colorNavbar && {
											backgroundColor: specificClientConfiguration.colorNavbar
										}
										) as React.CSSProperties}
										onClick={() => {
											this.setState({
												classOneConnectionsDataLayer: ''
											}, () => {
												localStorage.removeItem('classOneConnectionsDataLayer');

											});
										}}
									>
										clear
									</span>
								</div>
								<div className='clear-both'>
									<InputMultiSelectComponent name='classOneConnectionsDataLayer'
										value={state.classOneConnectionsDataLayer}
										options={classOneConnectionsOptionsTmp}
										onHandleMultiSelect={(name: string, valueArr: Array<LabelValue>) => {
											const value: string = joinFilterV(valueArr);
											this.setState({
												classOneConnectionsDataLayer: value
											}, () => {
												localStorage.setItem('classOneConnectionsDataLayer', value);
											});
										}}
										isMulti={true}
									/>
								</div>
							</div>
						</div>
					</Tooltip>
				}>
					<div>
						<input
							type='radio'
							className='pull-left'
							value='classOneConnChecked'
							checked={state.classOneConnectionsDataLayer && state.classOneConnectionsDataLayer.length > 1 ? ('classOneConnChecked' ? true : false) : false}
							onChange={this.onValueChange}
							disabled
							style={{ display: 'none' }}
						/>
						<div className='check'></div>
						<span className='pull-left margin-left-15 font-weight-bold cursor-pointer hoverPrivate'
						>
							Add/Remove Class I Rail
						</span>
					</div>
				</OverlayTrigger>
			</div>
		)
	}

	renderMajorPortsCheckbox = (): React.ReactElement => {
		const { state } = this;
		return (
			<Fragment>
				<div style={{ marginLeft: 4 }}>
					<InputCheckboxComponent className='pull-left hoverPrivate'
						name='showMajorPorts'
						checked={state.showMajorPorts}
						onHandleCheckboxChange={this.handleCheckboxChange}
						label='Major Ports'
					/>
				</div>
			</Fragment>
		)
	}

	renderRoadwaysCheckbox = (): React.ReactElement => {
		const { state } = this;
		return (
			<Fragment>
				<div style={{ marginLeft: 4 }}>
					<InputCheckboxComponent className='pull-left hoverPrivate'
						name='showRoadways'
						checked={state.showRoadways}
						onHandleCheckboxChange={(name: string, value: boolean) => {
							this.handleCheckboxChange(name, value);
							const map = this.getMapRef();
							if (map) {
								roadWaysMapLayersLines.forEach((roadWayLayerName: string) => {
									map.setPaintProperty(roadWayLayerName, 'line-opacity', value ? 0.5 : 0);
								});
								roadWaysMapLayersText.forEach((roadWayLayerName: string) => {
									map.setPaintProperty(roadWayLayerName, 'text-opacity', value ? 1 : 0);
									map.setPaintProperty(roadWayLayerName, 'icon-opacity', value ? 1 : 0);
								});
							}
						}}
						label='Roadways'
					/>
				</div>
			</Fragment>
		)
	}

	handleSelectedPropertyTypeCheckboxChange = (name: string, value: boolean) => {
		this.setState({
			[name]: value
			, flagDisableZoom: true
		} as any, () => {
			const { state } = this;
			const { listFilteringRef
				, showIndustrialSites
				, showTerminalsAndTransloads
				, showPortTerminals
				, showWarehouses
			} = state;
			if (listFilteringRef) {
				const valueArr: Array<LabelValue> = [];
				if (showIndustrialSites) {
					valueArr.push({ 'label': 'Industrial Sites & Properties', 'value': 'Industrial Sites & Properties' });
				}
				if (showTerminalsAndTransloads) {
					valueArr.push({ 'label': 'Terminals and Transload Facilities', 'value': 'Terminals and Transload Facilities' });
				}
				if (showPortTerminals) {
					valueArr.push({ 'label': 'Port Terminals', 'value': 'Port Terminals' });
				}
				if (showWarehouses) {
					valueArr.push({ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' });
				}
				listFilteringRef.handleSelectPropertyType('selectedPropertyType', valueArr);
			}
		});
	}

	renderIndustrialSitesCheckbox = (): React.ReactElement => {
		const { state } = this;
		return (
			<Fragment>
				<div style={{ marginLeft: 4 }}>
					<InputCheckboxComponent className='pull-left hoverPrivate'
						name='showIndustrialSites'
						checked={state.showIndustrialSites}
						onHandleCheckboxChange={this.handleSelectedPropertyTypeCheckboxChange}
						label='Industrial Sites'
					/>
				</div>
			</Fragment>
		)
	}

	renderTerminalsAndTransloadsCheckbox = (): React.ReactElement => {
		const { state } = this;
		return (
			<Fragment>
				<div style={{ marginLeft: 4 }}>
					<InputCheckboxComponent className='pull-left hoverPrivate'
						name='showTerminalsAndTransloads'
						checked={state.showTerminalsAndTransloads}
						onHandleCheckboxChange={this.handleSelectedPropertyTypeCheckboxChange}
						label='Terminals and Transloads'
					/>
				</div>
			</Fragment>
		)
	}

	renderPortTerminalsCheckbox = (): React.ReactElement => {
		const { state } = this;
		return (
			<Fragment>
				<div style={{ marginLeft: 4 }}>
					<InputCheckboxComponent className='pull-left hoverPrivate'
						name='showPortTerminals'
						checked={state.showPortTerminals}
						onHandleCheckboxChange={this.handleSelectedPropertyTypeCheckboxChange}
						label='Port Terminals'
					/>
				</div>
			</Fragment>
		)
	}

	renderWarehousesCheckbox = (): React.ReactElement => {
		const { state } = this;
		return (
			<Fragment>
				<div style={{ marginLeft: 4 }}>
					<InputCheckboxComponent className='pull-left hoverPrivate'
						name='showWarehouses'
						checked={state.showWarehouses}
						onHandleCheckboxChange={this.handleSelectedPropertyTypeCheckboxChange}
						label='Warehouses'
					/>
				</div>
			</Fragment>
		)
	}

	renderPetroleumProductPipelinesCheckbox = (flagEnable: boolean): React.ReactElement => {
		const { state } = this;
		const {
			specificClientConfiguration
		} = state;
		return (
			<Fragment>
				{
					flagEnable ?
						<div style={{ marginLeft: 4 }}>
							<InputCheckboxComponent className='pull-left hoverPrivate'
								name='showPetroleumProductPipelines'
								checked={state.showPetroleumProductPipelines}
								disabled={!flagEnable}
								onHandleCheckboxChange={this.handleCheckboxChange}
								label='Petroleum Product Pipelines'
							//activeHighlightedColor={specificClientConfiguration.colorNavbar}
							/>
						</div>
						:
						<OverlayTrigger placement='top' trigger='click' ref='dataLayerOverlay03' overlay={
							<Tooltip id='tooltip'>
								<div className='form-group'
									style={{
										display: 'flex'
										, flexDirection: 'column'
										, padding: 10
										, fontSize: 16
										, fontWeight: 'bold'
										, color: 'rgba(3, 61, 245, 1.0)'
										, textTransform: 'none'
									}}
								>
									<button
										type='button'
										className='close'
										style={{ position: 'absolute', right: 4, top: 4 }}
										onClick={() => (this.refs.dataLayerOverlay03 as OverlayTrigger).hide()}
									>
										<span style={{ float: 'right' }} aria-hidden='true'>×</span>
										<span className='sr-only'>Close</span>
									</button>
									<div>
										{
											'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
										}
										<a href={'/plans-and-pricing'}
											style={{
												fontSize: 16
												, fontWeight: 'bold'
												, color: 'rgba(3, 61, 245, 1.0)'
												, textDecoration: 'underline'
											}}
										>
											<span
												style={{
													fontSize: 16
													, fontWeight: 'bold'
													, color: 'rgba(3, 61, 245, 1.0)'
													, textDecoration: 'underline'
												}}
											>
												Plans and Pricing
											</span>
										</a>
									</div>
								</div>
							</Tooltip>
						}>
							<div style={{ marginLeft: 4 }}>
								<InputCheckboxComponent className='pull-left hoverPrivate'
									name='showPetroleumProductPipelines'
									checked={state.showPetroleumProductPipelines}
									disabled={!flagEnable}
									onHandleCheckboxChange={this.handleCheckboxChange}
									label='Petroleum Product Pipelines'
								//activeHighlightedColor={specificClientConfiguration.colorNavbar}
								/>
							</div>
						</OverlayTrigger>
				}
			</Fragment>
		)
	}

	renderCrudeOilPipelinesCheckbox = (flagEnable: boolean): React.ReactElement => {
		const { state } = this;
		const {
			specificClientConfiguration
		} = state;
		return (
			<Fragment>
				{
					flagEnable ?
						<div style={{ marginLeft: 4 }}>
							<InputCheckboxComponent className='pull-left hoverPrivate'
								name='showCrudeOilPipelines'
								checked={state.showCrudeOilPipelines}
								disabled={!flagEnable}
								onHandleCheckboxChange={this.handleCheckboxChange}
								label='Crude Oil Pipelines'
							//activeHighlightedColor={specificClientConfiguration.colorNavbar}
							/>
						</div>
						:
						<OverlayTrigger placement='top' trigger='click' ref='dataLayerOverlay02' overlay={
							<Tooltip id='tooltip'>
								<div className='form-group'
									style={{
										display: 'flex'
										, flexDirection: 'column'
										, padding: 10
										, fontSize: 16
										, fontWeight: 'bold'
										, color: 'rgba(3, 61, 245, 1.0)'
										, textTransform: 'none'
									}}
								>
									<button
										type='button'
										className='close'
										style={{ position: 'absolute', right: 4, top: 4 }}
										onClick={() => (this.refs.dataLayerOverlay02 as OverlayTrigger).hide()}
									>
										<span style={{ float: 'right' }} aria-hidden='true'>×</span>
										<span className='sr-only'>Close</span>
									</button>
									<div>
										{
											'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
										}
										<a href={'/plans-and-pricing'}
											style={{
												fontSize: 16
												, fontWeight: 'bold'
												, color: 'rgba(3, 61, 245, 1.0)'
												, textDecoration: 'underline'
											}}
										>
											<span
												style={{
													fontSize: 16
													, fontWeight: 'bold'
													, color: 'rgba(3, 61, 245, 1.0)'
													, textDecoration: 'underline'
												}}
											>
												Plans and Pricing
											</span>
										</a>
									</div>
								</div>
							</Tooltip>
						}>
							<div style={{ marginLeft: 4 }}>
								<InputCheckboxComponent className='pull-left hoverPrivate'
									name='showCrudeOilPipelines'
									checked={state.showCrudeOilPipelines}
									disabled={!flagEnable}
									onHandleCheckboxChange={this.handleCheckboxChange}
									label='Crude Oil Pipelines'
								//activeHighlightedColor={specificClientConfiguration.colorNavbar}
								/>
							</div>
						</OverlayTrigger>
				}
			</Fragment>
		)
	}

	renderIntermodalTerminalsCheckbox = (flagEnable: boolean): React.ReactElement => {
		const { state } = this;
		const {
			specificClientConfiguration
		} = state;
		return (
			<Fragment>
				{
					flagEnable ?
						<div style={{ marginLeft: 4 }}>
							<InputCheckboxComponent className='pull-left hoverPrivate'
								name='showIntermodalData'
								checked={state.showIntermodalData}
								disabled={!flagEnable}
								onHandleCheckboxChange={this.handleCheckboxChange}
								label='Intermodal Terminals'
							//activeHighlightedColor={specificClientConfiguration.colorNavbar}
							/>
						</div>
						:
						<OverlayTrigger placement='top' trigger='click' ref='dataLayerOverlay01' overlay={
							<Tooltip id='tooltip'>
								<div className='form-group'
									style={{
										display: 'flex'
										, flexDirection: 'column'
										, padding: 10
										, fontSize: 16
										, fontWeight: 'bold'
										, color: 'rgba(3, 61, 245, 1.0)'
										, textTransform: 'none'
									}}
								>
									<button
										type='button'
										className='close'
										style={{ position: 'absolute', right: 4, top: 4 }}
										onClick={() => (this.refs.dataLayerOverlay01 as OverlayTrigger).hide()}
									>
										<span style={{ float: 'right' }} aria-hidden='true'>×</span>
										<span className='sr-only'>Close</span>
									</button>
									<div>
										{
											'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
										}
										<a href={'/plans-and-pricing'}
											style={{
												fontSize: 16
												, fontWeight: 'bold'
												, color: 'rgba(3, 61, 245, 1.0)'
												, textDecoration: 'underline'
											}}
										>
											<span
												style={{
													fontSize: 16
													, fontWeight: 'bold'
													, color: 'rgba(3, 61, 245, 1.0)'
													, textDecoration: 'underline'
												}}
											>
												Plans and Pricing
											</span>
										</a>
									</div>
								</div>
							</Tooltip>
						}>
							<div style={{ marginLeft: 4 }}>
								<InputCheckboxComponent className='pull-left hoverPrivate'
									name='showIntermodalData'
									checked={state.showIntermodalData}
									disabled={!flagEnable}
									onHandleCheckboxChange={this.handleCheckboxChange}
									label='Intermodal Terminals'
								//activeHighlightedColor={specificClientConfiguration.colorNavbar}
								/>
							</div>
						</OverlayTrigger>
				}
			</Fragment>
		)
	}

	renderShortLinesFilterComponent = (flagEnable: boolean): React.ReactElement => {
		const { state } = this;
		const shortLinesOptionsTmp: Array<LabelValue> = [...shortLinesOptions];
		const flagAll: boolean = state.shortLinesDataLayer.indexOf('ALL') >= 0;
		const shortLinesDataLayer: string = flagAll ? shortLinesAllFilter : state.shortLinesDataLayer;
		return (
			<div className='margin-bottom-5'>
				{
					flagEnable
						?
						<OverlayTrigger placement='bottom' trigger='click' ref='shortLinesOverlay' rootClose overlay={
							<Tooltip id='tooltip2' style={{ opacity: 1 }}>
								<div className='text-center' style={{ paddingTop: 20 }}>
									<button
										type='button'
										className='close'
										style={{ position: 'absolute', width: '100%', boxShadow: 'none', right: 5, top: 8 }}
										onClick={() => (this.refs.shortLinesOverlay as OverlayTrigger).hide()}
									>
										<span style={{ float: 'right' }} aria-hidden='true'>×</span>
										<span className='sr-only'>Close</span>
									</button>
									<div className='form-group'>
										<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-0 text-uppercase font-weight-bold'
											style={{ fontSize: 12, paddingBottom: 10 }}
										>
											<span className='pull-left'>Select short lines </span>
											<span className='pull-right cursor-pointer color-blue'
												onClick={() => {
													this.setState({
														shortLinesDataLayer: ''
													}, () => {
														localStorage.removeItem('shortLinesDataLayer');
													});
												}}
											>
												clear
											</span>
										</div>
										<div className='clear-both'>
											<InputMultiSelectComponent name='shortLinesDataLayer'
												flagAll={flagAll}
												value={shortLinesDataLayer}
												options={shortLinesOptionsTmp}
												onHandleMultiSelect={(name: string, valueArr: Array<LabelValue>) => {
													const value: string = joinFilterV(valueArr);
													this.setState({
														shortLinesDataLayer: value
													}, () => {
														localStorage.setItem('shortLinesDataLayer', value);
													});
												}}
												isMulti={true}
											/>
										</div>
									</div>
								</div>
							</Tooltip>
						}>
							<div>
								<input
									type='radio'
									className='pull-left'
									value='shortLinesChecked'
									checked={state.shortLinesDataLayer && state.shortLinesDataLayer.length > 1 ? ('shortLinesChecked' ? true : false) : false}
									onChange={this.onValueChange}
									disabled={!flagEnable}
									style={{ display: 'none' }}
								/>
								<div className='check1'></div>
								<span className='pull-left margin-left-15 font-weight-bold cursor-pointer hover'
								>
									Add/Remove Short Lines Rail
								</span>
							</div>
						</OverlayTrigger>
						:
						<OverlayTrigger placement='top' trigger='click' ref='dataLayerOverlay04' overlay={
							<Tooltip id='tooltip'>
								<div className='form-group'
									style={{
										display: 'flex'
										, flexDirection: 'column'
										, padding: 10
										, fontSize: 16
										, fontWeight: 'bold'
										, color: 'rgba(3, 61, 245, 1.0)'
										, textTransform: 'none'
									}}
								>
									<button
										type='button'
										className='close'
										style={{ position: 'absolute', right: 4, top: 4 }}
										onClick={() => (this.refs.dataLayerOverlay04 as OverlayTrigger).hide()}
									>
										<span style={{ float: 'right' }} aria-hidden='true'>×</span>
										<span className='sr-only'>Close</span>
									</button>
									<div>
										{
											'Please upgrade your plan to Power Search or Power Pro to use this feature. See '
										}
										<a href={'/plans-and-pricing'}
											style={{
												fontSize: 16
												, fontWeight: 'bold'
												, color: 'rgba(3, 61, 245, 1.0)'
												, textDecoration: 'underline'
											}}
										>
											<span
												style={{
													fontSize: 16
													, fontWeight: 'bold'
													, color: 'rgba(3, 61, 245, 1.0)'
													, textDecoration: 'underline'
												}}
											>
												Plans and Pricing
											</span>
										</a>
									</div>
								</div>
							</Tooltip>
						}>
							<div
								style={Object.assign({}, {
									cursor: 'not-allowed'
								})}
							>
								<input
									type='radio'
									className='pull-left'
									value='shortLinesChecked'
									checked={state.shortLinesDataLayer && state.shortLinesDataLayer.length > 1 ? ('shortLinesChecked' ? true : false) : false}
									onChange={this.onValueChange}
									disabled={!flagEnable}
									style={{ display: 'none' }}
								/>
								<div className='check1'
									style={Object.assign({}, {
										cursor: 'not-allowed'
										, borderColor: 'rgba(97, 97, 97, 0.5)'
									})}
								/>
								<span className='pull-left margin-left-15 font-weight-bold cursor-pointer hover'
									style={Object.assign({}, {
										cursor: 'not-allowed'
									})}
								>
									Add/Remove Short Lines Rail
								</span>
							</div>
						</OverlayTrigger>
				}
			</div>
		)
	}

	renderLegendMapBtnOnToolbar = (): React.ReactElement => {
		const { state } = this;
		const {
			showNumberColumnsInListing
			, innerWidthToHideListingButtons
			, innerWidth
		} = state;
		const flagSmallIcons: boolean = innerWidth <= innerWidthToHideListingButtons;
		const sizeIco: number = flagSmallIcons ? sizeIcoSmall : sizeIcoBig;
		const placeIco: number = flagSmallIcons ? placeIcoSmall : placeIcoBig;
		return (
			<OverlayTrigger placement='bottom' trigger='click' ref='legendOverlay' overlay={
				<Tooltip id='tooltip' style={{ opacity: 1 }}>
					<div style={{ display: 'flex', flexDirection: 'column', width: 280, padding: 10 }}>
						<button
							type='button'
							className='close'
							style={{ position: 'absolute', width: '100%', boxShadow: 'none', right: 5, top: 8 }}
							onClick={() => (this.refs.legendOverlay as OverlayTrigger).hide()}
						>
							<span style={{ float: 'right' }} aria-hidden='true'>×</span>
							<span className='sr-only'>Close</span>
						</button>
						<div>
							<span className='pull-left font-size-14 font-weight-bold' style={{ paddingBottom: 15 }}>Legend</span>
						</div>
						<div className='margin-bottom-20'>
							<img className='pull-left' src={siteIcon} alt='site icon' width='25' />
							<span className='pull-left font-weight-bold hover' style={{ position: 'relative', top: 4, marginLeft: 10 }}>Industrial Sites &amp; Properties</span>
						</div>
						<div className='margin-bottom-20'>
							<img className='pull-left' src={portTerminalIcon} alt='port icon' width='25' />
							<span className='pull-left font-weight-bold hover' style={{ position: 'relative', top: 4, marginLeft: 10 }}>Port Terminals</span>
						</div>
						<div className='margin-bottom-20'>
							<img className='pull-left' src={otherTerminalIcon} alt='other terminal icon' width='25' />
							<span className='pull-left font-weight-bold hover' style={{ position: 'relative', top: 4, marginLeft: 10 }}>Terminal and Transload Facilities</span>
						</div>
						<div className='margin-bottom-20'>
							<img className='pull-left' src={warehouseIcon} alt='warehouse icon' width='25' />
							<span className='pull-left font-weight-bold hover' style={{ position: 'relative', top: 4, marginLeft: 10 }}>Warehouses</span>
						</div>
						<div className='margin-bottom-20'>
							<img className='pull-left' src={multiPurposeIcon} alt='multipurpose icon' width='25' />
							<span className='pull-left font-weight-bold hover' style={{ position: 'relative', top: 4, marginLeft: 10 }}>Multipurpose</span>
						</div>
						<div className='margin-bottom-20'>
							<img className='pull-left' src={intermodalIcon} alt='multipurpose icon' width='25' />
							<span className='pull-left font-weight-bold hover' style={{ position: 'relative', top: 4, marginLeft: 10 }}>Intermodal Terminals</span>
						</div>
						<div className=''>
							<img className='pull-left' src={majorPortsIcon} alt='multipurpose icon' width='25' />
							<span className='pull-left font-weight-bold hover' style={{ position: 'relative', top: 4, marginLeft: 10 }}>Major Ports</span>
						</div>
					</div>
				</Tooltip>
			}>
				<div style={{ height: placeIco, width: placeIco, display: 'flex', alignItems: 'center', padding: '0px 0px', }}>
					<Button
						style={Object.assign({}, {
							backgroundColor: 'rgba(255,255,255,0.7)',
							color: '#383838',
						})}
						onClick={() => (this.refs.dataLayerOverlay as OverlayTrigger).hide()}
					>
						{/* {
							Legend
						} */}
						<OverlayTrigger placement='top' overlay={
							<Tooltip id='tooltip'>
								<strong className='font-size-16'>
									Legend
								</strong>
							</Tooltip>
						}>
							<svg viewBox='0 0 448 512' className='legend_icon' style={{
								width: sizeIco,
								height: sizeIco,
							}}>
								<path fill='currentColor'
									d='M318.38 208h-39.09c-1.49 27.03-6.54 51.35-14.21 70.41 27.71-13.24 48.02-39.19 53.3-70.41zm0-32c-5.29-31.22-25.59-57.17-53.3-70.41 7.68 19.06 12.72 43.38 14.21 70.41h39.09zM224 97.31c-7.69 7.45-20.77 34.42-23.43 78.69h46.87c-2.67-44.26-15.75-71.24-23.44-78.69zm-41.08 8.28c-27.71 13.24-48.02 39.19-53.3 70.41h39.09c1.49-27.03 6.53-51.35 14.21-70.41zm0 172.82c-7.68-19.06-12.72-43.38-14.21-70.41h-39.09c5.28 31.22 25.59 57.17 53.3 70.41zM247.43 208h-46.87c2.66 44.26 15.74 71.24 23.43 78.69 7.7-7.45 20.78-34.43 23.44-78.69zM448 358.4V25.6c0-16-9.6-25.6-25.6-25.6H96C41.6 0 0 41.6 0 96v320c0 54.4 41.6 96 96 96h326.4c12.8 0 25.6-9.6 25.6-25.6v-16c0-6.4-3.2-12.8-9.6-19.2-3.2-16-3.2-60.8 0-73.6 6.4-3.2 9.6-9.6 9.6-19.2zM224 64c70.69 0 128 57.31 128 128s-57.31 128-128 128S96 262.69 96 192 153.31 64 224 64zm160 384H96c-19.2 0-32-12.8-32-32s16-32 32-32h288v64z'
								>
								</path>
							</svg>

						</OverlayTrigger>

					</Button>
				</div>
			</OverlayTrigger>
		)
	}

	renderDialogs = (): React.ReactElement => {
		const { state, props } = this;
		return (
			<Fragment>
				{
					this.renderDlgContactUsModal()
				}
				{
					this.renderDlgAddedToFavoritesConfirmedModal()
				}
				{
					this.renderDlgRemovedFromFavoritesConfirmedModal()
				}
				{
					this.renderFavoritesForPrivateModal()
				}
				{
					this.renderListItemsModal()
				}
				{
					this.renderGetHelpModal()
				}
				{
					this.renderLimitedOfferSignupModal()
				}
			</Fragment>
		)
	}

	renderDlgContactUsModal = (): React.ReactElement => {
		const { state, props } = this;
		const { place } = state;
		if (!place) {
			return null;
		}
		return (
			<Modal dialogClassName='custom-contact-modal-95'
				show={state.showContactUs}
				onHide={this.handleContactUsClose}
				animation={false}
			>
				<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
				<div className='container-fluid'>
					<div className='row' style={{ display: 'flex', flexWrap: 'wrap' }}>
						{/* <img src={ this.getFirstImage(features[key].properties.image) } alt='img' style={{ height: '100%', width: '100%'}} /> */}
						<div className='col-md-6 col-sm-0 col-xs-0' style={{
							backgroundImage: 'url(' + this.getFirstImage(place.properties.image) + ')'
							, backgroundSize: 'cover'
							, backgroundPosition: 'center'
							, backgroundRepeat: 'no-repeat'
							, height: 'auto'
						}}
						>
						</div>
						<div className='col-md-6 col-sm-12 col-xs-12 reduce_padding_0' style={{ padding: '3rem' }}>
							<h2 className='text-black font-size-30' style={{ paddingTop: '1rem' }}>Contact us</h2>
							<h4 className='text-black font-size-16'>
								Enter your contact details and your message will be sent to the contact name and organization name listed.
							</h4>
							<form className='col-md-12 col-xs-12'>
								<Row className='col-md-12 col-xs-12'>
									<div className='col-md-6 col-sm-12 col-xs-12'>
										<label className='font-weight-bold' htmlFor=''>Contact Name</label>
										<input type='text'
											className='form-control margin-bottom-5'
											value={place.properties.contactName}
											readOnly
										>
										</input>
									</div>
									<div className='col-md-6 col-sm-12 col-xs-12'>
										<label className='font-weight-bold' htmlFor=''>Organization Name</label>
										<input type='text'
											className='form-control margin-bottom-5'
											value={place.properties.organizationName}
											readOnly
										>
										</input>
									</div>
									<div className='col-md-6 col-sm-12 col-xs-12'>
										<label className='font-weight-bold' htmlFor=''>Your First Name</label>
										<input type='text'
											className='form-control margin-bottom-5'
											onChange={this.setFirstName}
											value={state.firstName}
										>
										</input>
									</div>
									<div className='col-md-6 col-sm-12 col-xs-12'>
										<label className='font-weight-bold' htmlFor=''>Your Last Name</label>
										<input type='text'
											className='form-control margin-bottom-5'
											onChange={this.setLastName}
											value={state.lastName}
										>
										</input>
									</div>
									<div className='col-md-6 col-sm-12 col-xs-12'>
										<label className='font-weight-bold' htmlFor=''>Your Email</label>
										<input type='email'
											className='form-control margin-bottom-5'
											onChange={this.setEmail}
											value={state.email}
										>
										</input>
										<p style={{ color: '#a94442' }} id="emailValidation"></p>
									</div>
									<div className='col-md-12 col-sm-12 col-xs-12'>
										<label className='font-weight-bold' htmlFor=''>Your Message</label>
										<textarea className='form-control margin-bottom-5'
											onChange={this.setMessage}
											value={state.message}
										>
										</textarea>
									</div>
									<div className='col-md-12 col-sm-12 col-xs-12'>
										<button type='button'
											className='btn btn-default border-radius-25-imp'
											onClick={this.handleContactUs}
										>
											Submit
										</button>
									</div>
								</Row>
							</form>
						</div>
					</div>
				</div>
			</Modal>
		)
	}

	renderDlgAddedToFavoritesConfirmedModal = (): React.ReactElement => {
		const { state, props } = this;
		return (
			<ConfirmationModal show={state.addFavModal} handleClose={this.hideAddFavModal} >
				<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
					<div className='event-type info'>
						<div className='event-indicator '>
							<SVGIcon />
						</div>
					</div>
					<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
						Place added to favorites
					</h2>
					<p className='text-muted font-size-16-imp margin-bottom-0'>
						Place has been added to favorites successfully!
					</p>
				</div>
			</ConfirmationModal>
		)
	}

	renderDlgRemovedFromFavoritesConfirmedModal = (): React.ReactElement => {
		const { state, props } = this;
		return (
			<ConfirmationModal show={state.deleteFavModal} handleClose={this.hideDeleteFavModal} >
				<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
					<div className='event-type warning'>
						<div className='event-indicator '>
							<SVGErrorIcon />
						</div>
					</div>
					<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
						Place removed from favorites
					</h2>
					<p className='text-muted font-size-16-imp margin-bottom-0'>
						Place has been removed from favorites successfully!
					</p>
				</div>
			</ConfirmationModal>
		)
	}

	renderNorthAmericanRailLines = (): React.ReactElement => {
		const { state } = this;
		const classOneConnectionsDataLayerToInclude: Array<string> = splitFilter(state.classOneConnectionsDataLayer);

		const flagAll: boolean = state.shortLinesDataLayer.indexOf('ALL') >= 0;
		const shortLinesDataLayer: string = flagAll ? shortLinesAllFilter : state.shortLinesDataLayer;
		const shortLinesDataLayerToInclude: Array<string> = splitFilter(shortLinesDataLayer);
		//console.log('flagAll',shortLinesDataLayer)
		//console.log('shortLinesDataLayerToInclude', shortLinesDataLayerToInclude)

		const classOneConnectionsDataLayerBnsf: boolean
			= classOneConnectionsDataLayerToInclude.some((value: string) => ClassOne.BNSF === value);
		const classOneConnectionsDataLayerCn: boolean
			= classOneConnectionsDataLayerToInclude.some((value: string) => ClassOne.CN === value);
		const classOneConnectionsDataLayerCp: boolean
			= classOneConnectionsDataLayerToInclude.some((value: string) => ClassOne.CP === value);
		const classOneConnectionsDataLayerCsx: boolean
			= classOneConnectionsDataLayerToInclude.some((value: string) => ClassOne.CSX === value);
		const classOneConnectionsDataLayerKcs: boolean
			= classOneConnectionsDataLayerToInclude.some((value: string) => ClassOne.KCS === value);
		const classOneConnectionsDataLayerNs: boolean
			= classOneConnectionsDataLayerToInclude.some((value: string) => ClassOne.NS === value);
		const classOneConnectionsDataLayerUp: boolean
			= classOneConnectionsDataLayerToInclude.some((value: string) => ClassOne.UP === value);
		return (
			<React.Fragment>
				{
					(classOneConnectionsDataLayerBnsf) &&
					<NorthAmericanRailLinesBnsf
						key={this.getRailroadClassOneLayerKey('Listing_NorthAmericanRailLinesBnsf_', classOneConnectionsDataLayerToInclude, ClassOne.BNSF)}
						linesToInclude={classOneConnectionsDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
				{
					(classOneConnectionsDataLayerCn) &&
					<NorthAmericanRailLinesCn
						key={this.getRailroadClassOneLayerKey('Listing_NorthAmericanRailLinesCn_', classOneConnectionsDataLayerToInclude, ClassOne.CN)}
						linesToInclude={classOneConnectionsDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
				{
					(classOneConnectionsDataLayerCp) &&
					<NorthAmericanRailLinesCprs
						key={this.getRailroadClassOneLayerKey('Listing_NorthAmericanRailLinesCprs_', classOneConnectionsDataLayerToInclude, ClassOne.CP)}
						linesToInclude={classOneConnectionsDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
				{
					(classOneConnectionsDataLayerCsx) &&
					<NorthAmericanRailLinesCsxt
						key={this.getRailroadClassOneLayerKey('Listing_NorthAmericanRailLinesCsxt_', classOneConnectionsDataLayerToInclude, ClassOne.CSX)}
						linesToInclude={classOneConnectionsDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
				{
					(classOneConnectionsDataLayerKcs) &&
					<NorthAmericanRailLinesKcs
						key={this.getRailroadClassOneLayerKey('Listing_NorthAmericanRailLinesKcs_', classOneConnectionsDataLayerToInclude, ClassOne.KCS)}
						linesToInclude={classOneConnectionsDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
				{
					(classOneConnectionsDataLayerNs) &&
					<NorthAmericanRailLinesNs
						key={this.getRailroadClassOneLayerKey('Listing_NorthAmericanRailLinesNs_', classOneConnectionsDataLayerToInclude, ClassOne.NS)}
						linesToInclude={classOneConnectionsDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
				{
					(classOneConnectionsDataLayerUp) &&
					<NorthAmericanRailLinesUp
						key={this.getRailroadClassOneLayerKey('Listing_NorthAmericanRailLinesUp_', classOneConnectionsDataLayerToInclude, ClassOne.UP)}
						linesToInclude={classOneConnectionsDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
				{/* {
					(state.shortLinesDataLayer) &&
					<NorthAmericanRailLinesShort
						key={this.getRailroadShortLayerKey('Listing_NorthAmericanRailLinesShort_')}
						linesToInclude={shortLinesDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				} */}
				{
					(shortLinesDataLayer) &&
					<NorthAmericanRailLinesShortExt
						key={this.getRailroadShortLayerKey('Listing_NorthAmericanRailLinesShortExt_')}
						linesToInclude={shortLinesDataLayerToInclude}
						flagShowLabels={state.showRailroadLabels}
						flagShowLines={state.showRailroadLines}
					/>
				}
			</React.Fragment>
		)
	}
	renderFavoritesForPrivateModal = (): React.ReactElement => {
		const { state, props } = this;
		return (
			// <BookmarkModal show={state.bookmarksPrivateLabelModal} handleClose={this.hideprivateFavModal} >
			// 	<div  style={{ paddingTop: '1rem', width: '90%', paddingLeft: '3rem', paddingRight: '3rem' }}>

			// 		<Bookmarkprivatecomponent />

			// 	</div>
			// </BookmarkModal>
			<Modal dialogClassName='custom-modal-step3' show={this.state.bookmarksPrivateLabelModal} onHide={this.hideprivateFavModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
				<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
				<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
					<div className='reduce-padding-small' style={{ paddingTop: '1rem', width: '99%', paddingLeft: '3rem', paddingRight: '3rem' }}>

						<Bookmarkprivatecomponent />

					</div>

				</Modal.Body>
				<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
					<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideprivateFavModal}>Close</button>
				</Modal.Footer>
			</Modal>
		)
	}
	renderListItemsModal = (): React.ReactElement => {
		const { state, props } = this;
		return (

			<Modal show={this.state.ListItemModal} onHide={this.hideListItemModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
				<Modal.Header style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }} closeButton>
					<div className='col-8 row'>
						<h3 className='pull-left'>SEARCH RESULTS</h3>
						{/* <b role='button' style={{ paddingRight: '6%', fontSize: 12 }} onClick={this.showModalGetHelp} className='pull-right'>&nbsp;&nbsp;<i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>&nbsp;GET HELP</b> */}
					</div>
					<div className='row'>
						<Switch
							onChange={this.onToggleMapMoveUpdateListing}
							checked={this.state.flagMapMoveUpdateListing}
						>
						</Switch>
						<span style={{ fontSize: 12, fontWeight: 400 }}>
							<b style={{ fontSize: 12, fontWeight: 500 }}>&nbsp;&nbsp;Filter listings when moving around the map</b>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body style={{ background: 'white', paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '3rem', overflowY: 'scroll', height: 600 }}>
					<div >
						{
							this.renderListPlaceModal()
						}
					</div>

				</Modal.Body>
				<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
					<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideListItemModal}>Close</button>
				</Modal.Footer>
			</Modal>
		)
	}
	renderGetHelpModal = (): React.ReactElement => {
		const { state, props } = this;
		return (

			<Modal show={this.state.getHelpModal} onHide={this.hideModalGetHelp} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
				<Modal.Header style={{ paddingTop: '2rem', paddingLeft: '1rem', paddingRight: '1rem' }} closeButton>
					<h3 style={{ fontSize: 20 }}>CONTACT US</h3>

				</Modal.Header>
				<Modal.Body style={{ background: 'white', paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
					<div>
						<b style={{ fontWeight: 500, fontSize: 16 }}> Please email us at info@industrialnavigator.com or call us at +1 (312) 957-7757 for any feedback or questions.</b>
					</div>
					{
						this.state.showAlert &&
						<Alert bsStyle='success' onDismiss={this.handleDismiss}>
							<h2 style={{ marginTop: 0, marginBottom: 10 }}>Success!</h2>
							<p>
								Your message has been sent!
							</p>
						</Alert>
					}
					<div style={{ paddingLeft: 0, paddingRight: 0 }} className='col-lg-12 col-md-12 mt-5 mt-md-0 padding-top-30'>
						<div className='row'>
							<form style={{ width: '100%' }}>
								<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
									<input type='text' className='form-control margin-bottom-0-imp' onChange={this.setFirstName} value={this.state.firstName} required placeholder='Your First Name'></input>
									{this.validator.message('first name', this.state.firstName, 'required')}
								</div>
								<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
									<input type='text' className='form-control margin-bottom-0-imp' onChange={this.setLastName} value={this.state.lastName} required placeholder='Your Last Name'></input>
									{this.validator.message('last name', this.state.lastName, 'required')}
								</div>
								<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
									<input type='email' className='form-control margin-bottom-0-imp' onChange={this.setEmail} value={state.email} required placeholder='Your Email'></input>
									{/* {this.validator.message('email', this.state.reg_email, 'required')} */}
									<p style={{ color: '#a94442' }} id="emailValidation"></p>
								</div>
								<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
									<textarea className='form-control margin-bottom-0-imp' onChange={this.setMessage} value={this.state.message} placeholder='Your message' rows={6}></textarea>
									{this.validator.message('message', this.state.message, 'required')}
								</div>
								<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
									<div id='contact_error' className='text-center'></div>
									<ReCAPTCHA
										ref={recaptchaRef}
										onChange={this.onRecaptchaChange}
										sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}

									/>
								</div>
								<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
									<Button disabled={this.state.contactusbutton} id='contactusButon' style={{ borderRadius: '0px !important', background: '#4DAFFF', height: 50, width: '100%', fontSize: 18, paddingLeft: 25, paddingRight: 25 }} onClick={this.handleContactUsGeneral}>
										<span className='pull-left font-Gotham-Pro-Medium font-weight-normal'>Contact Us</span>
										{
											this.state.contactusbutton ?
												<i className="fa fa-spinner fa-spin fs-30"></i> :
												''


										}
										<span className='pull-right'><RightArrowSVGIcon /></span></Button>
								</div>
							</form>
						</div>
					</div>

				</Modal.Body>
				<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
					<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideModalGetHelp}>Close</button>
				</Modal.Footer>
			</Modal>
		)
	}
	renderLimitedOfferSignupModal = (): React.ReactElement => {
		const { state, props } = this;
		return (

			<Modal dialogClassName='modal-border' show={this.state.limitedOfferSignUpModal} onHide={this.hideLimitedOfferSignUp.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
				<Modal.Header style={{ paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }} closeButton></Modal.Header>
				<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
					<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '2rem', paddingRight: '2rem' }}>
						<TimerSignUp />

					</div>
				</Modal.Body>
				<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
					<button id='limitedOfferSignupModal' type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideLimitedOfferSignUp.bind(this)}>Close</button>
				</Modal.Footer>
			</Modal>
		)
	}
	// 	


	onValueChange = () => {
	}
}

export default Listing;

const GetYearFromDate = () => {
	const date = new Date();
	return date.getFullYear();
}

const ConfirmationModal = ({ handleClose, show, children }) => (
	<Modal show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
			<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);
const BookmarkModal = ({ handleClose, show, children }) => (
	<Modal dialogClassName='custom-modal-step3' show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
			<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);
const listItemsModal = ({ handleClose, show, children }) => (
	<Modal dialogClassName='custom-modal-step3' show={show} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
			<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);
const DetailsModal = ({ handleClose, show, children }) => (
	<Modal dialogClassName='quick-view-modal width-80' size='large' aria-labelledby='contained-modal-title-lg' show={show} onHide={handleClose} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', marginBottom: '0px' }}>
			<div  >
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);

const SVGErrorIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)
const RightArrowSVGIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 20 20'
	>
		<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
			<g fill='#FFF' transform='translate(-1166 -2563)'>
				<g transform='translate(-27)'>
					<g transform='translate(0 2140)'>
						<g transform='translate(857 120)'>
							<g transform='translate(0 286)'>
								<g transform='translate(336 17)'>
									<path d='M10.334.244L20 10l-9.666 9.756-1.167-1.179 7.671-7.744H0V9.167h16.839L9.167 1.422 10.334.244z'></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)