import React, { Component } from 'react';
import '../custom.css';
import { Row, Breadcrumb, BreadcrumbItem, Modal, Button, OverlayTrigger, Tooltip, Alert, Tabs, Tab } from 'react-bootstrap';
import Header from './Header';
import MetaTags from 'react-meta-tags';
import Footer from './Footer';
import ListingInstructions from './ListingInstructions';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import UserProfile from './UserProfile';
import { Cookies } from 'react-cookie';
import { arrShortLines } from '../constant/ShortLines';
import { arrWaterWays } from '../constant/WaterWays';
import InputImageComponent from './InputImageComponent';
import InputPdfComponent from './InputPdfComponent';
import InputTextareaComponent from './InputTextareaComponent';
import InputAddressComponent from './InputAddressComponent';
import InputBoxComponent from './InputBoxComponent';
import InputCheckboxComponent from './InputCheckboxComponent';
import InputMultiSelectComponent from './InputMultiSelectComponent';
import { LabelValue, sortArray, removeDuplicatesFromArr } from '../constant/Types';
import { useState } from 'react';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import mapboxgl from 'mapbox-gl';
import DraggableMarker from './DraggableMarker';
import Select, { StylesConfig } from 'react-select';
import logo from '../logo.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import gtag from 'ga-gtag';
import LinkedInTag from 'react-linkedin-insight';
import {Helmet} from "react-helmet";
const recaptchaRef = React.createRef();
let listings = 0;
let show: false;
let email: string = '';
let newlistings = 0;
let Maxlistings;
let planid;

const cookies = new Cookies();


interface PCreatePlace {
	location: any;
	history: Array<string>;
}

class SCreatePlace {

	constructor() {
	}
}


//console.log("value of listing creat"+listings)

class CreatePlace extends Component<PCreatePlace, any> {


	validator: SimpleReactValidator = null;
	loginValidator: SimpleReactValidator = null;
	OTPValidator: SimpleReactValidator = null;
	registerValidator: SimpleReactValidator = null;
	helpers: any = null;
	child: Header = null;
	PageUrl: ' ';
	PageTitle: ' ';
	PageMetaRobot: ' ';
	PageMetaDiscription: ' ';

	constructor(props) {
		super(props);
		this.state = {
			showNewRecordModal: false,
			showupdateplanModal: false,
			showAlert: false,
			showmarkerModal: false,
			getHelpModal: false,
			submitbutton: false,
			contactusbutton: false,
			firstName: '',
			lastName: '',
			email: '',
			message: '',



			showLoginModal: false,
			showForgotPasswordModal: false,
			fpEmail: '',
			isLoggedIn: false,
			login_email: '',
			otp: '',
			showRegistrationModal: false,
			fname: '',
			lname: '',
			companyName: '',
			password: '',
			confirm_password: '',
			reg_email: '',
			showRegistrationSuccessModal: false,
			reacatpcha_value: '',
			showOTPModal: false,
			showLoginSuccessModal: false,
			login_password: '',




			//this.setlistings = this.setListing.bind(this);
			shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
			selectedPropertyFor: '',
			selectedPropertyType: '',
			acreage: '',
			selectedBuildingOption: '',
			sitesSquareFeet: '',
			sitesInteriorRoofHeight: '',
			docInPlace: '',
			description: '',
			first_name: '',
			last_name: '',
			client_email_id: UserProfile.getEmail(),
			navigableWater: '',
			selectedPowerToSite: '',
			serviceVoltage: '',
			distanceToSubstation: '',
			naturalGasInPlace: '',
			lineDiameter: '',
			selectedWaterSewer: '',
			distanceFromRailway: '',


			shortLinesStr: '',
			placeName: '',
			listingLogo: '',
			imagePreviewUrl: '',
			organizationName: localStorage.getItem('organizationName') != 'null' ? localStorage.getItem('organizationName') : '',
			companyWebsiteUrl: localStorage.getItem('companyWebsiteUrl') != 'null' ? localStorage.getItem('companyWebsiteUrl') : '',
			price: '',
			perUnit: '',
			address: '',
			coordinates: '',
			swap_coordinates:'',
			city: '',
			manualcity: '',
			usaState: '',
			usaStateManual: '',
			zipCode: '',
			country: '',
			selectedImage: '',
			files: [],
			urls: [],
			pdf: [],
			pdfurls: [],
			contactName: localStorage.getItem('contactName') != 'null' ? localStorage.getItem('contactName') : '',
			contactTitle: localStorage.getItem('contactTitle') != 'null' ? localStorage.getItem('contactTitle') : '',
			contactEmail: localStorage.getItem('contactEmail') != 'null' ? localStorage.getItem('contactEmail') : '',
			phone: localStorage.getItem('contactNo') != 'null' ? localStorage.getItem('contactNo') : '',

			zoningArr: [
				{ 'label': 'Opportunity Zone', 'value': 'Opportunity Zone' },
				{ 'label': 'FTZ', 'value': 'FTZ' },
				{ 'label': 'Industrial', 'value': 'Industrial' },
				{ 'label': 'Warehouse', 'value': 'Warehouse' },
				{ 'label': 'Commercial', 'value': 'Commercial' },
				{ 'label': 'Tax Increment Financing (TIF)', 'value': 'Tax Increment Financing (TIF)' },
			],
			siteSuitableForArr: [
				// { 'label': 'Distribution/warehouse', 'value': 'Distribution/warehouse' },
				// { 'label': 'Cold storage', 'value': 'Cold storage' },
				// { 'label': 'Manufacturing', 'value': 'Manufacturing' },
				// { 'label': 'Indoor rail', 'value': 'Indoor rail' },
				// { 'label': 'Indoor truck', 'value': 'Indoor truck' },
				{ 'label': 'Heavy Industrial', 'value': 'Heavy Industrial' },
				{ 'label': 'Light manufacturing', 'value': 'Light manufacturing' },
				{ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' },
			],
			pipelineAccessArr: [
				{ 'label': 'Natural gas', 'value': 'Natural gas' },
				{ 'label': 'Refined products', 'value': 'Refined products' },
				{ 'label': 'NGLs', 'value': 'NGLs' },
				{ 'label': 'Crude', 'value': 'Crude' }
			],
			envionmentalArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'Phase I complete', 'value': 'Phase I complete' },
				{ 'label': 'Phase II complete', 'value': 'Phase II complete' }
			],

			airAttainmentStatusArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'Attainment', 'value': 'Attainment' },
				{ 'label': 'Non-Attainment for 8-hour Ozone', 'value': 'Non-Attainment for 8-hour Ozone' },
			],
			railcarStorageArr: [
				{ 'label': 'SIT', 'value': 'SIT' },
				{ 'label': 'Loaded', 'value': 'Loaded' },
				{ 'label': 'Empty', 'value': 'Empty' },
				{ 'label': 'Loaded hazmat', 'value': 'Loaded hazmat' },
				{ 'label': 'Hazmat residue', 'value': 'Hazmat residue' },
			],

			siteZoning: '',
			siteSuitableFor: '',
			siteClassOneConnections: '',
			siteShortLines: '',
			railcarStorage: '',
			// siteRailInPlaceOption: '',
			siteBargeAccess: '',

			propertyTypeArr: [
				{ 'label': 'Industrial Sites & Properties', 'value': 'Industrial Sites & Properties' },
				{ 'label': 'Ports, Terminals & Transloads', 'value': 'Ports, Terminals & Transloads' },
				{ 'label': 'Warehouse/Distribution Center', 'value': 'Warehouse/Distribution Center' },
			],

			listingForArr: [
				{ 'label': 'For Lease', 'value': 'For Lease' },
				{ 'label': 'For Sale', 'value': 'For Sale' },
				// { 'label': 'Lease and/or Sale', 'value': 'Lease and/or Sale' },
			],

			capabilitiesArr: [
				{ 'label': 'Rail', 'value': 'Rail' },
				{ 'label': 'Barge', 'value': 'Barge' },
				{ 'label': 'Truck', 'value': 'Truck' },
				{ 'label': 'Tug', 'value': 'Tug' },
				{ 'label': 'Warehouse', 'value': 'Warehouse' },
				{ 'label': 'Tank storage', 'value': 'Tank storage' },
				{ 'label': 'Bulk outside storage', 'value': 'Bulk outside storage' },
				{ 'label': 'Unit train', 'value': 'Unit train' },
				{ 'label': 'Storage-in-transit (SIT)', 'value': 'Storage-in-transit (SIT)' },
				{ 'label': 'Hazmat', 'value': 'Hazmat' },
				{ 'label': 'Laydown yard', 'value': 'Laydown yard' },
				{ 'label': 'Intermodal', 'value': 'Intermodal' },
				{ 'label': 'Other', 'value': 'Other' },
			],
			equipmentsArr: [
				{ 'label': 'Front-end loader', 'value': 'Front-end loader' },
				{ 'label': 'Stacker/reclaimer', 'value': 'Stacker/reclaimer' },
				{ 'label': 'RT crane', 'value': 'RT crane' },
				{ 'label': 'Belt conveyor', 'value': 'Belt conveyor' },
				{ 'label': 'Railcar rotary dump', 'value': 'Railcar rotary dump' },
				{ 'label': 'Railcar bottom dump', 'value': 'Railcar bottom dump' },
				{ 'label': 'Shiploader', 'value': 'Shiploader' },
				{ 'label': 'Barge unloader', 'value': 'Barge unloader' },
				{ 'label': 'Liquid pumps', 'value': 'Liquid pumps' },
				{ 'label': 'Forklift', 'value': 'Forklift' },
				{ 'label': 'Container handling', 'value': 'Container handling' },
				{ 'label': 'Other', 'value': 'Other' },
			],
			selectOptionsArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'Yes', 'value': 'Yes' },
				{ 'label': 'No', 'value': 'No' }
			],
			// bulkTypeArr: [
			// 	{ 'label': 'Dry', 'value': 'Dry' },
			// 	{ 'label': 'Liquid', 'value': 'Liquid' },
			// 	{ 'label': 'Breakbulk', 'value': 'Breakbulk' },
			// 	{ 'label': 'Haz Mat', 'value': 'Haz Mat' },
			// ],
			productsHandledArr: [
				{ 'label': 'Metals', 'value': 'Metals' },
				{ 'label': 'Forest products', 'value': 'Forest products' },
				{ 'label': 'Project Cargo', 'value': 'Project Cargo' },
				{ 'label': 'Aggregates', 'value': 'Aggregates' },
				{ 'label': 'Grains', 'value': 'Grains' },
				{ 'label': 'Fertilizer', 'value': 'Fertilizer' },
				{ 'label': 'Coal', 'value': 'Coal' },
				{ 'label': 'Petcoke', 'value': 'Petcoke' },
				{ 'label': 'Lumber', 'value': 'Lumber' },
				{ 'label': 'Fly ash', 'value': 'Fly ash' },
				{ 'label': 'Hazmat', 'value': 'Hazmat' },
				{ 'label': 'Liquid Bulk', 'value': 'Liquid Bulk' },
				{ 'label': 'Breakbulk', 'value': 'Breakbulk' },
				{ 'label': 'Chemicals', 'value': 'Chemicals' },
				{ 'label': 'Plastics', 'value': 'Plastics' },
				{ 'label': 'Sand', 'value': 'Sand' },
				{ 'label': 'Dry Bulk', 'value': 'Dry Bulk' },
				{ 'label': 'Crude Oil', 'value': 'Crude Oil' },
				{ 'label': 'Refined products', 'value': 'Refined products' },
				{ 'label': 'Other', 'value': 'Other' },
			],
			indoorStorageArr: [
				{ 'label': 'Yes', 'value': 'Yes' },
				{ 'label': 'No', 'value': 'No' }
			],
			temperatureControlArr: [
				{ 'label': 'Chilled (+33 to +65 degrees)', 'value': 'Chilled (+33 to +65 degrees)' },
				{ 'label': 'Frozen (-10 to +32 degrees)', 'value': 'Frozen (-10 to +32 degrees)' },
			],
			marineAccessArr: [
				{ 'label': 'Barge/Shallow', 'value': 'Barge/Shallow' },
				{ 'label': 'Vessel/Deepwater', 'value': 'Vessel/Deepwater' },
			],
			usaStateArr: [
				{ 'label': 'Clear value', 'value': '' },
				{ 'label': 'AL', 'value': 'AL' },
				{ 'label': 'AK', 'value': 'AK' },
				{ 'label': 'AZ', 'value': 'AZ' },
				{ 'label': 'AR', 'value': 'AR' },
				{ 'label': 'CA', 'value': 'CA' },
				{ 'label': 'CO', 'value': 'CO' },
				{ 'label': 'CT', 'value': 'CT' },
				{ 'label': 'DE', 'value': 'DE' },
				{ 'label': 'FL', 'value': 'FL' },
				{ 'label': 'GA', 'value': 'GA' },
				{ 'label': 'HI', 'value': 'HI' },
				{ 'label': 'ID', 'value': 'ID' },
				{ 'label': 'IL', 'value': 'IL' },
				{ 'label': 'IN', 'value': 'IN' },
				{ 'label': 'IA', 'value': 'IA' },
				{ 'label': 'KS', 'value': 'KS' },
				{ 'label': 'KY', 'value': 'KY' },
				{ 'label': 'LA', 'value': 'LA' },
				{ 'label': 'ME', 'value': 'ME' },
				{ 'label': 'MD', 'value': 'MD' },
				{ 'label': 'MA', 'value': 'MA' },
				{ 'label': 'MI', 'value': 'MI' },
				{ 'label': 'MN', 'value': 'MN' },
				{ 'label': 'MS', 'value': 'MS' },
				{ 'label': 'MO', 'value': 'MO' },
				{ 'label': 'MT', 'value': 'MT' },
				{ 'label': 'NE', 'value': 'NE' },
				{ 'label': 'NV', 'value': 'NV' },
				{ 'label': 'NH', 'value': 'NH' },
				{ 'label': 'NJ', 'value': 'NJ' },
				{ 'label': 'NM', 'value': 'NM' },
				{ 'label': 'NY', 'value': 'NY' },
				{ 'label': 'NC', 'value': 'NC' },
				{ 'label': 'ND', 'value': 'ND' },
				{ 'label': 'OH', 'value': 'OH' },
				{ 'label': 'OK', 'value': 'OK' },
				{ 'label': 'OR', 'value': 'OR' },
				{ 'label': 'PA', 'value': 'PA' },
				{ 'label': 'RI', 'value': 'RI' },
				{ 'label': 'SC', 'value': 'SC' },
				{ 'label': 'SD', 'value': 'SD' },
				{ 'label': 'TN', 'value': 'TN' },
				{ 'label': 'TX', 'value': 'TX' },
				{ 'label': 'UT', 'value': 'UT' },
				{ 'label': 'VT', 'value': 'VT' },
				{ 'label': 'VA', 'value': 'VA' },
				{ 'label': 'WA', 'value': 'WA' },
				{ 'label': 'WV', 'value': 'WV' },
				{ 'label': 'WI', 'value': 'WI' },
				{ 'label': 'WY', 'value': 'WY' },
			],
			waterwaysArr: [],
			classOneConnectionsArr: [
				{ 'label': 'BNSF', 'value': 'BNSF' },
				{ 'label': 'CN', 'value': 'CN' },
				{ 'label': 'CP', 'value': 'CP' },
				{ 'label': 'CSX', 'value': 'CSX' },
				{ 'label': 'KCS', 'value': 'KCS' },
				{ 'label': 'NS', 'value': 'NS' },
				{ 'label': 'UP', 'value': 'UP' }
			],

			facilities: '',
			capabilities: '',
			equipments: '',
			acres: '',
			majorFeatures: '',
			specifications: '',
			railcarStorageCapacity: '',
			productStorage: '',
			nameplateCapacity: '',
			// bulkType: '',
			productsHandled: '',
			otherCapabilities: '',
			otherEquipments: '',
			otherProductStorage: '',
			otherProductsHandled: '',
			indoorStorage: '',
			marineAccess: '',
			bargeAccess: '',
			portDepth:'',
			portLength:'',
			plgCertification: false,
			warehouseAudits: false,
			siteQualification: false,
			useContactInfo: localStorage.getItem('useContactInfo') === 'true' ? true : false,

			productStorageArr: [
				{ 'label': 'Tankage', 'value': 'Tankage' },
				{ 'label': 'Silos', 'value': 'Silos' },
				{ 'label': 'Warehouse', 'value': 'Warehouse' },
				{ 'label': 'Outdoor', 'value': 'Outdoor' },
				{ 'label': 'Indoor Storage', 'value': 'Indoor Storage' },
				{ 'label': 'Other', 'value': 'Other' },
			],

			parkingCapacityArr: [
				{ 'label': 'Cars', 'value': 'Cars' },
				{ 'label': 'Trailers/containers', 'value': 'Trailers/containers' }
			],
			silos: '',
			showListingExistsModal: false,
			showCancelListingButton: false,
			showCancelListingModal: false,

			pttAcreage: '',
			unitTrainCapable: '',
			railcarCapacity: '',

			warehouseCapabilitiesArr: [
				{ 'label': 'Packaging', 'value': 'Packaging' },
				{ 'label': 'Drumming', 'value': 'Drumming' },
				{ 'label': 'Hazmat', 'value': 'Hazmat' },
				{ 'label': 'Pick & Pack', 'value': 'Pick & Pack' },
				{ 'label': 'Kitting', 'value': 'Kitting' },
				{ 'label': 'Built to Suit', 'value': 'Built to Suit' },
				{ 'label': 'Other', 'value': 'Other' },

			],
			warehouseCapabilities: '',

			warehousePrice: '',
			warehousePricePerUnit: '',
			TotalDockDoors: '',
			DriveInDockDoors: '',
			DockLevelDoors: '',
			TotalWarehouseArea: '',
			TotalWarehouseAvailableArea: '',
			TotalWarehouseOfficeSpaceArea: '',

		};
		//this.setnewlisting = this.setnewlisting.bind(this);
		this.onRemove = this.onRemove.bind(this);
		this.onRemoveLogo = this.onRemoveLogo.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
		this.handlePDFFiles = this.handlePDFFiles.bind(this);
		this.setAcreage = this.setAcreage.bind(this);
		this.savePlace = this.savePlace.bind(this);
		this.setWaterSewer = this.setWaterSewer.bind(this);


		this.validator = new SimpleReactValidator({
			validators: {
				phone: {  // name the rule
					message: 'The :phone must be a valid contact number.',
					rule: (val, params, validator): boolean => {
						return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/i)
					},
					messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
					required: true  // optional
				} as any,
				email: {  // name the rule
					message: 'The :email must be a valid email address.',
					rule: (val, params, validator): boolean => {
						return validator.helpers.testRegex(val, /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
					},
					messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
					required: true  // optional
				} as any

			}


		});
		// this.validator = new SimpleReactValidator({
		// 	validators: {
		// 		Email: {  // name the rule
		// 			message: 'The :email must be a valid email address.',
		// 			rule: (val, params, validator) => {
		// 				return validator.helpers.testRegex(val, /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
		// 			},
		// 			messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
		// 			required: true  // optional
		// 		}				
		// 	}


		// });
	}


	showDraggableMarkerModal = () => {
		this.setState({
			showmarkerModal: true
		})
		sessionStorage.removeItem('address_coordinates')
		sessionStorage.removeItem('temp_address_coordinates')
	}

	SaveDraggableMarkerModal = () => {
		const dragableMarkerData = JSON.parse(sessionStorage.getItem('address_coordinates'))
		var addres_coordinates = sessionStorage.getItem('temp_address_coordinates')
		var temp1 = JSON.parse(addres_coordinates)


		this.setState({
			showmarkerModal: false,
			coordinates: temp1.coordinates,
			swap_coordinates:[temp1.coordinates[1],temp1.coordinates[0]],
			address: temp1.address,
			city: temp1.city,
			country: temp1.country,
			usaState: temp1.state,

		})
		sessionStorage.setItem('address_coordinates', addres_coordinates)

		sessionStorage.removeItem('temp_address_coordinates')
	}

	CloseDraggableMarkerModal = () => {
		sessionStorage.removeItem('temp_address_coordinates')

		this.setState({
			showmarkerModal: false
		})


	}


	updatecount = () => {
		newlistings = 0;
		listings = 0;
		console.log("inside update count")
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				email: UserProfile.getEmail(),
			}).then(response => {
				//console.log(response)
				listings = response.data[0].sw_number_listings;
				localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
				localStorage.setItem('membershipOptions', response.data[0].options);
				const data = {
					email: UserProfile.getEmail(),
					newlistings: Number(listings) + 1
				}
				axios.post(process.env.REACT_APP_BASE_URL + '/api/update-number-listing',
					{
						headers: { 'Content-Type': 'application/json' },
						email: UserProfile.getEmail(),
						newlistings: Number(listings) + 1


					}).then(response => {
						console.log("response1", response)
						if (response.status == 200) {
							this.setState({ showNewRecordModal: true });
							sessionStorage.removeItem("address_coordinates")
							this.setState({ submitbutton: false })

						}
						else {
							alert('Something went wrong')
						}

					}
					);

				//alert(Number(newlistings))
			});

	}

	componentDidMount() {
		if (this.props.location.state !== undefined) {
			this.checkParametersOnLoad();
		}
	}

	checkParametersOnLoad = () => {

		// Show listing already exists modal
		if (this.props.location.state.addMoreClicked === undefined) {
			if (localStorage.getItem('listingNames')) {
				console.log('in if cond')
				this.setState({
					showListingExistsModal: true,
					showCancelListingButton: true
				});
			} else {
				console.log('in else cond')
			}
		}

		// show Cancel listing button if user wants to cancel new listing being created
		if (this.props.location.state.addMoreClicked !== undefined) {
			if (localStorage.getItem('listingNames')) {
				this.setState({
					showCancelListingButton: true
				});
			} else {
				// do nothing
			}
		}
		if (cookies.get('token') !== undefined) {
			fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
					'x-access-token': cookies.get('token')
				},
			})
				.then((res: { status: number }) => {
					if (res.status === 200) {
						if (this.props.location.state.propertyType !== undefined) {
							if (this.props.location.state.propertyType.includes('*')) {
								this.setState({ selectedPropertyType: '' });
							}
							else if (this.props.location.state.propertyType.includes('Port')) {
								this.trackGoogleAnalyticsEvent('list-ports-click','list-ports-click-homepage')
								this.setState({ selectedPropertyType: 'Ports, Terminals & Transloads', address: this.props.location.state.address });
								this.setContactInfoForm();
							}
							else if (this.props.location.state.propertyType.includes('Terminals')) {
								this.trackGoogleAnalyticsEvent('list-terminals-transloads-click','list-Terminals-Transloads-click-homepage')
								this.setState({ selectedPropertyType: 'Ports, Terminals & Transloads', address: this.props.location.state.address });
								this.setContactInfoForm();
							}
							else if (this.props.location.state.propertyType.includes('Warehouse')) {
								this.trackGoogleAnalyticsEvent('list-warehouse-click','list-warehouse-click-homepage')
								this.setState({ selectedPropertyType: 'Warehouse/Distribution Center', address: this.props.location.state.address });
								this.setContactInfoForm();
							}
							else if (this.props.location.state.propertyType.includes('Sites')) {
								this.trackGoogleAnalyticsEvent('list-industrial-sites-click','list-industrial-sites-click-homepage')
								this.setState({ selectedPropertyType: 'Industrial Sites & Properties', address: this.props.location.state.address });
								this.setContactInfoForm();
							}
							else {
								this.unsetContactInfoForm();
							}


							setTimeout(function () {
								if (this.state.selectedPropertyType.includes('Sites')) {
									this.setSiteForm();
									this.setMarineAccessForm();
								}
								if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads') {
									this.setPortTerminalTransloadForm();
									this.setMarineAccessForm();
								}
								if (this.state.selectedPropertyType.includes('Warehouse')) {
									this.setWarehouseForm();
								}
								this.setListingFor(this.state.selectedPropertyType);
								this.setCheckboxForm();
							}.bind(this), 1000);

						}
					} else {
						UserProfile.forgetEmail();
						cookies.remove('token');
						//  this.props.data.history.push('/');
						window.scrollTo(0, 0);
						// const error = new Error(res.error);
						// throw error;
						this.handleOpen();
					}
				})
				.catch((err) => {
					console.error(err);
					this.setState({ loading: false, redirect: true });
				});
		}
		else {
			this.handleOpen();
		}

		// if (this.props.location.state.propertyType !== undefined) {
		// 	this.setState({ selectedPropertyType: this.props.location.state.propertyType });
		// 	setTimeout(function () {
		// 		if (this.state.selectedPropertyType.includes('Sites')) {
		// 			this.setSiteForm();
		// 			this.setMarineAccessForm();
		// 		}
		// 		if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads') {
		// 			this.setPortTerminalTransloadForm();
		// 			this.setMarineAccessForm();
		// 		}
		// 		if (this.state.selectedPropertyType.includes('Warehouse')) {
		// 			this.setWarehouseForm();
		// 		}
		// 		this.setListingFor(this.state.selectedPropertyType);
		// 	}.bind(this), 1000);
		// }
	}
	trackGoogleAnalyticsEvent = (event,title) => {
		gtag('event', event, {
			poll_title: title,
		  })

	}
	setPropertyFor = (event) => {
		this.setState({ selectedPropertyFor: event.target.value });
	}

	setRailInPlace(event) {
		this.setState({ selectedRailInPlace: event.target.value });
	}

	setAcreage(event) {
		this.setState({ acreage: event.target.value });
	}

	setBuildingOption(value) {
		this.setState({ selectedBuildingOption: value });
	}

	setSitesSquareFeet(event) {
		this.setState({ sitesSquareFeet: event.target.value });
	}

	setSitesInteriorRoofHeight(event) {
		this.setState({ sitesInteriorRoofHeight: event.target.value });
	}

	setSiteDocInPlace(event) {
		this.setState({ docInPlace: event.target.value });
	}

	setPowerToSite(event) {
		this.setState({ selectedPowerToSite: event.target.value });
	}

	setWaterSewer(event) {
		this.setState({ selectedWaterSewer: event.target.value });
	}

	componentWillMount() {
		this.getMetaTags();
		this.fetchUserBasicDetails();
		this.fetchuserlistingsdetails();
		this.loginValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		});
		this.OTPValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		this.registerValidator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
	}
	getMetaTags = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-page-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const plans = res.data;
				// console.log(res.data)
				for (let i = 0; i < plans.length; i++) {
					if (plans[i].PageUrl == window.location.pathname) {
						this.setState({
							PageUrl: plans[i].PageUrl,
							PageTitle: plans[i].PageTitle,
							PageMetaRobot: plans[i].PageMetaRobot,
							PageMetaDiscription: plans[i].PageMetaDiscription,
						});
						return 0;
					}
					else {
						this.setState({
							PageUrl: ' ',
							PageTitle: ' ',
							PageMetaRobot: ' ',
							PageMetaDiscription: ' ',
						});
					}
				}
			})
			.catch(err => {
			});
	}

	fetchUserBasicDetails() {
		// alert(UserProfile.getEmail())

		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-basic-info',
			{
				headers: {
					'Content-Type': 'application/json',
					// 'x-access-token': cookies.get('token')
				},
				// withCredentials: true,
				email: UserProfile.getEmail(),
			}).then(response => {
				if (response.status === 200) {
					if (response.data.length > 0) {
						this.setState({
							'first_name': response.data[0].sw_firstname,
							'last_name': response.data[0].sw_lastname,
							'client_email_id': UserProfile.getEmail()
						});
					}
					else {
						this.setState({
							'first_name': response.data.sw_firstname,
							'last_name': response.data.sw_lastname,
							'client_email_id': UserProfile.getEmail()
						});
					}
				}
				else {

				}
			}).catch(function (error) {
				// handle your error
			});
	}
	fetchuserlistingsdetails() {

		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-user-profile',
			{
				headers: {
					'Content-Type': 'application/json',
				},
				email: UserProfile.getEmail(),
			}).then(response => {
				//console.log(response)
				listings = response.data[0].sw_number_listings;
				planid = response.data[0].sw_plan_id;
				localStorage.setItem('maxNoEntries', response.data[0].max_no_entries);
				localStorage.setItem('membershipOptions', response.data[0].options);
			});
	}


	async savePlace(event) {
		// console.log(this.validator.allValid())
		const formData = new FormData();



		if (this.state.listingLogo !== null) {
			for (var i = 0; i < this.state.listingLogo.length; i++) {
				formData.append('logo', this.state.listingLogo[i])
			}
		}

		// if (this.state.selectedImage !== null) {
		// 	for (var i = 0; i < this.state.selectedImage.length; i++) {
		// 		formData.append('file', this.state.selectedImage[i])
		// 	}
		// }

		if (this.state.files !== null) {
			for (var i = 0; i < this.state.files.length; i++) {
				formData.append('file', this.state.files[i])
			}
		}
		if (this.state.pdf !== null) {
			for (var i = 0; i < this.state.pdf.length; i++) {
				formData.append('pdf', this.state.pdf[i])
			}
		}

		var propertyType = '';

		if (this.state.selectedPropertyType.includes('Industrial Sites & Properties') && this.state.selectedPropertyType.includes('Ports, Terminals & Transloads') && this.state.selectedPropertyType.includes('Warehouse')) {
			if (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel')) {
				propertyType = 'Industrial Sites & Properties*Port Terminals*Warehouse/Distribution Center';
			} else {
				propertyType = 'Industrial Sites & Properties*Terminals and Transload Facilities*Warehouse/Distribution Center';
			}
		} else if (this.state.selectedPropertyType.includes('Industrial Sites & Properties') && this.state.selectedPropertyType.includes('Ports, Terminals & Transloads')) {
			if (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel')) {
				propertyType = 'Industrial Sites & Properties*Port Terminals';
			} else {
				propertyType = 'Industrial Sites & Properties*Terminals and Transload Facilities';
			}
		} else if (this.state.selectedPropertyType.includes('Ports, Terminals & Transloads') && this.state.selectedPropertyType.includes('Warehouse')) {
			if (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel')) {
				propertyType = 'Port Terminals*Warehouse/Distribution Center';
			} else {
				propertyType = 'Terminals and Transload Facilities*Warehouse/Distribution Center';
			}
		} else if (this.state.selectedPropertyType.includes('Industrial Sites & Properties') && this.state.selectedPropertyType.includes('Warehouse')) {
			propertyType = 'Industrial Sites & Properties*Warehouse/Distribution Center';
		} else if (this.state.selectedPropertyType === 'Industrial Sites & Properties') {
			propertyType = 'Industrial Sites & Properties';
		} else if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && (this.state.marineAccess.includes('Barge') || this.state.marineAccess.includes('Vessel'))) {
			propertyType = 'Port Terminals';
		} else if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && this.state.marineAccess === '') {
			propertyType = 'Terminals and Transload Facilities';
		} else {
			propertyType = 'Warehouse/Distribution Center';
		}

		// if(this.state.selectedPropertyType === 'Industrial Sites & Properties') {
		// 	propertyType = 'Industrial Sites & Properties';
		// } else if (this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && this.state.marineAccess === 'Barge/Vessel access') {
		// 	propertyType = 'Port Terminals';
		// } else if(this.state.selectedPropertyType === 'Ports, Terminals & Transloads' && this.state.marineAccess === '') {
		// 	propertyType = 'Terminals and Transload Facilities';
		// } else {
		// 	propertyType = 'Warehouses';
		// }

		var placeData;
		if (this.validator.allValid()) {
			var basicData = {
				'propertyType': propertyType,
				'propertyFor': this.state.selectedPropertyFor,
				'listingName': this.state.placeName,
				'listingLogo': '',
				'organizationName': this.state.organizationName,
				'companyWebsiteUrl': this.state.companyWebsiteUrl,
				'image': '',
				'contactName': this.state.contactName,
				'contactTitle': this.state.contactTitle,
				'contactEmail': this.state.contactEmail,
				'contact': this.state.phone,
				'description': this.state.description,
				'city': this.state.manualcity != '' ? this.state.manualcity : this.state.city,
				'state': this.state.usaStateManual != '' ? this.state.usaStateManual : this.state.usaState,
				'zipCode': this.state.zipCode,
				'address': this.state.address,
				'country': this.state.country,
			}

			var siteData = {
				'price': this.state.price,
				'perUnit': this.state.perUnit,
				'acreage': this.state.acreage,
				'zoning': this.state.siteZoning,
				'building': this.state.selectedBuildingOption,
				'squareFeet': this.state.sitesSquareFeet,
				'interiorRoofHeight': this.state.sitesInteriorRoofHeight,
				'suitableFor': this.state.siteSuitableFor,
				'pipelineAccess': this.state.sitePipelineAccess,
				'waterSewerInPlace': this.state.selectedWaterSewer,
				'electricPowerInPlace': this.state.selectedPowerToSite,
				'serviceVoltage': this.state.serviceVoltage,
				'distanceToSubstation': this.state.distanceToSubstation,
				'naturalGasInPlace': this.state.naturalGasInPlace,
				'lineDiameter': this.state.lineDiameter,
				'environmental': this.state.siteEnvironmental,
				'airAttainmentStatus': this.state.airAttainmentStatus,
				'plgCertification': this.state.plgCertification == true ? 'Yes' : 'No',
				'siteQualification': this.state.siteQualification == true ? 'Yes' : 'No',
			}

			var terminalPortTransloadData = {
				'capabilities': this.state.capabilities,
				'equipments': this.state.equipments,
				'railcarStorageCapacity': this.state.railcarStorageCapacity,
				'majorFeatures': this.state.majorFeatures,
				'specifications': this.state.specifications,
				'productStorage': this.state.productStorage,
				// 'nameplateCapacity': this.state.nameplateCapacity,
				// 'bulkType': this.state.bulkType,
				'productsHandled': this.state.productsHandled,
				'otherCapabilities': this.state.otherCapabilities,
				'otherEquipments': this.state.otherEquipments,
				'otherProductStorage': this.state.otherProductStorage,
				'otherProductsHandled': this.state.otherProductsHandled,
				'indoorStorage': this.state.indoorStorage,
				'pttAcreage': this.state.pttAcreage
			}

			var warehouseData = {
				'warehousePrice': this.state.warehousePrice,
				'TotalDockDoors': this.state.TotalDockDoors,
				'DriveInDockDoors': this.state.DriveInDockDoors,
				'DockLevelDoors': this.state.DockLevelDoors,
				'TotalWarehouseArea': this.state.TotalWarehouseArea,
				'TotalWarehouseAvailableArea': this.state.TotalWarehouseAvailableArea,
				'TotalWarehouseOfficeSpaceArea': this.state.TotalWarehouseOfficeSpaceArea,
				'warehousePricePerUnit': this.state.warehousePricePerUnit,
				'warehouseCapabilities': this.state.warehouseCapabilities,
				'warehouseMinSize': this.state.warehouseMinSize,
				'warehouseMaxSize': this.state.warehouseMaxSize,
				'temperatureControl': this.state.temperatureControl,
				'securedParking': this.state.securedParking,
				'parkingSpaces': this.state.parkingSpaces,
				'foodGrade': this.state.foodGrade,
				'freeTradeZone': this.state.freeTradeZone,
				'roofHeight': this.state.roofHeight,
				'warehouseAudits': this.state.warehouseAudits == true ? 'Yes' : 'No',
			}

			var marineAccessData = {
				'marineAccess': this.state.marineAccess,
				'bargeAccess': this.state.bargeAccess,
				'docInPlace': this.state.docInPlace,
				'waterways': this.state.waterways,
				'distancetoWater': this.state.navigableWater,
				'portDepth':this.state.portDepth,
				'portLength':this.state.portLength
			}

			var servingCarriersData = {
				'classOneConnections': this.state.siteClassOneConnections,
				'shortLines': this.state.siteShortLines,
				'railServed': this.state.siteClassOneConnections == '' && this.state.siteShortLines == '' ? 'No':'Yes',
				'railInPlace': this.state.selectedRailInPlace,
				'railcarStorage': this.state.railcarStorage,
				'unitTrainCapable': this.state.unitTrainCapable,
				'railcarCapacity': this.state.railcarCapacity,
			}

			var additionalAttributesData = {
				'parkingCapacity': this.state.parkingCapacity,
				'nearestInterstate': this.state.nearestInterstate,
				'milesToInterstate': this.state.milesToInterstate,
				'nearestAirport': this.state.nearestAirport,
				'milesToAirport': this.state.milesToAirport,
				'silos': this.state.silos,
			}

			var consolidatedObj = Object.assign(basicData, siteData, terminalPortTransloadData, warehouseData, marineAccessData, servingCarriersData, additionalAttributesData);

			var dragableMarker = JSON.parse(sessionStorage.getItem('address_coordinates'));
			var data = this.state.coordinates
			if (typeof (data) == 'string') {
				var data1 = this.state.coordinates.split(",");
				var covalue = data1.map(i => Number(i))

			} else {
				var covalue = this.state.coordinates;
			}

			placeData = {
				'rec_id': null,
				'client_id': null,
				'client_first_name': this.state.first_name,
				'client_last_name': this.state.last_name,
				'client_email_id': UserProfile.getEmail(),
				'client_plan_id': planid,
				'total_views': 0,
				'who_view_listings_obj': [],
				//'who_view_listings_name': " ,",
				'type': 'Feature',
				// 'geometry': {
				// 	'type': 'Point',
				// 	'coordinates': this.state.coordinates
				// },
				'geometry': {
					'type': 'Point',
					// 'coordinates': this.state.coordinates ? this.state.coordinates : dragableMarker.coordinates
					'coordinates': covalue
				},
				'properties': consolidatedObj,
				'orderStatus': 'Successful',
				'active': 'Yes',
				'createdAt': '',
				'updatedAt': '',
			}
			console.log(placeData);

			if (this.state.useContactInfo) {
				localStorage.setItem('useContactInfo', this.state.useContactInfo);
				localStorage.setItem('contactName', this.state.contactName);
				localStorage.setItem('contactTitle', this.state.contactTitle);
				localStorage.setItem('contactEmail', this.state.contactEmail);
				localStorage.setItem('contactNo', this.state.phone);
				localStorage.setItem('companyWebsiteUrl', this.state.companyWebsiteUrl);
				localStorage.setItem('organizationName', this.state.organizationName);
			}
			else {
				localStorage.removeItem('useContactInfo');
				localStorage.removeItem('contactName');
				localStorage.removeItem('contactTitle');
				localStorage.removeItem('contactEmail');
				localStorage.removeItem('contactNo');
				localStorage.removeItem('companyWebsiteUrl');
				localStorage.removeItem('organizationName')
			}

			formData.append('placeData', JSON.stringify(placeData));


			this.setState({ submitbutton: true })
			document.getElementById("saveButon").setAttribute('disabled', 'disabled');


			await axios.post(process.env.REACT_APP_BASE_URL + '/api/create-geojson-record', formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}).then(response => {
				console.log('create response');
				console.log(response);
				this.updatecount();


			}).catch(error => {
				// handle your error
				console.log(error)
				alert('It looks like the size of your images are too large. Please resize the images and re-upload. If you are still having problems please contact us at info@industrialnavigator.com.')
				//document.getElementById("saveButon").setAttribute('enable', 'enable');
				this.setState({ submitbutton: false })
			});
		} else {
			this.validator.showMessages();
			document.getElementById('create-listing-form').scrollIntoView({ behavior: 'smooth' })
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}
	handleNewRecordModal() {
		this.setState({ showNewRecordModal: false });
	}
	handleupdateplanModal = () => {
		this.setState({ showupdateplanModal: true });
	}


	setFacilityAcres = () => {
		document.getElementById('facilityAcresDiv').classList.remove('display-none');
	}

	setSiteBargeAccess = value => {
		if (value.includes('Barge') || value.includes('Vessel')) {
			this.setState({ marineAccess: value, bargeAccess: 'Yes' });
			document.getElementById('siteDocInPlaceDiv').classList.remove('display-none');
			document.getElementById('siteWhichRiverDiv').classList.remove('display-none');
		} else {
			this.setState({ marineAccess: value, bargeAccess: 'No' });
			document.getElementById('siteDocInPlaceDiv').classList.add('display-none');
			document.getElementById('siteWhichRiverDiv').classList.add('display-none');
		}
	}

	setFacilityRailcarStorageCapacity = value => {
		if (value === 'Rail') {
			document.getElementById('facilityRailcarStorageCapacityDiv').classList.remove('display-none');
		}
	}

	removeFacilityRailcarStorageCapacity = () => {
		document.getElementById('facilityRailcarStorageCapacityDiv').classList.add('display-none');
	}

	addColdStorageInCapabilities = () => {
		var capabilitiesArr = this.state.capabilitiesArr;
		var facilities = this.state.facilities;
		var found = false;
		for (var i = 0; i < capabilitiesArr.length; i++) {
			if (capabilitiesArr[i].label === 'Cold storage') {
				found = true;
				break;
			}
		}
		if (found === false) {
			capabilitiesArr.push(
				{ 'label': 'Cold storage', 'value': 'Cold storage' }
			);
		}
		this.setState({ capabilitiesArr: capabilitiesArr });
	}

	removeColdStorageFromCapabilities = () => {
		var capabilitiesArr = this.state.capabilitiesArr;
		for (var i = 0; i < capabilitiesArr.length; i++) {
			if (capabilitiesArr[i].value == 'Cold storage')
				capabilitiesArr.splice(i);
		}
		this.setState({ capabilitiesArr: capabilitiesArr });
	}

	removeFacilityAcres = () => {
		document.getElementById('facilityAcresDiv').classList.add('display-none');
	}

	setContactInfoForm = () => {
		document.getElementById('contactInfoCheckbox').classList.remove('display-none');
	}

	unsetContactInfoForm = () => {
		document.getElementById('contactInfoCheckbox').classList.add('display-none');
	}

	setCheckboxForm = () => {
		document.getElementById('checkboxForm').classList.remove('display-none');
	}

	unsetCheckboxForm = () => {
		document.getElementById('checkboxForm').classList.add('display-none');
	}

	setSiteForm = value => {
		document.getElementById('basicForm').classList.remove('display-none');
		// document.getElementById('marineAccessForm').classList.remove('display-none');
		document.getElementById('servingCarriersForm').classList.remove('display-none');
		document.getElementById('siteForm').classList.remove('display-none');
		document.getElementById('additionalAttributesForm').classList.remove('display-none');
	}

	setListingFor = value => {
		if (value.includes('Sites') || value.includes('Warehouse')) {
			document.getElementById('listingForDropdownDiv').classList.remove('display-none');
		}

		if (value.includes('Warehouse')) {
			this.addPTTOptionInListingFor();
		}
	}

	unsetListingFor = () => {
		document.getElementById('listingForDropdownDiv').classList.add('display-none');
	}

	addPTTOptionInListingFor = () => {
		var listingForArr = this.state.listingForArr;
		var found = false;
		for (let i = 0; i < listingForArr.length; i++) {
			if (listingForArr[i].label === 'For 3PL/Public Warehouse Services') {
				found = true;
				break;
			}
		}
		if (found === false) {
			listingForArr.push(
				{ 'label': 'For 3PL/Public Warehouse Services', 'value': 'For 3PL/Public Warehouse Services' }
			);
		}
		this.setState({ listingForArr: listingForArr });
	}

	removePTTOptionFromListingFor = () => {
		var listingForArr = this.state.listingForArr;
		for (let i = 0; i < listingForArr.length; i++) {
			if (listingForArr[i].value === 'For 3PL/Public Warehouse Services')
				listingForArr.splice(i);
		}
		if (this.state.selectedPropertyFor.includes('For 3PL/Public Warehouse Services')) {
			// remove this selected dropdown value
			var newListingFor = this.state.selectedPropertyFor.split('*');

			for (let j = 0; j < newListingFor.length; j++) {
				if (newListingFor[j] === 'For 3PL/Public Warehouse Services')
					newListingFor.splice(j);
			}

			this.setState({ selectedPropertyFor: newListingFor.length > 0 ? newListingFor.join('*') : null, listingForArr: listingForArr });
		} else {
			this.setState({ listingForArr: listingForArr });
		}
	}

	unsetSiteForm = () => {
		document.getElementById('siteForm').classList.add('display-none');
		// document.getElementById('listingForDropdownDiv').classList.add('display-none');
		this.resetSiteCheckboxes();
	}

	setPortTerminalTransloadForm = value => {
		document.getElementById('basicForm').classList.remove('display-none');
		// document.getElementById('marineAccessForm').classList.remove('display-none');
		document.getElementById('servingCarriersForm').classList.remove('display-none');
		document.getElementById('terminalPortForm').classList.remove('display-none');
		document.getElementById('additionalAttributesForm').classList.remove('display-none');
	}

	unsetPortTerminalTransloadForm = () => {
		document.getElementById('terminalPortForm').classList.add('display-none');
	}

	setWarehouseForm = () => {
		document.getElementById('basicForm').classList.remove('display-none');
		document.getElementById('warehouseForm').classList.remove('display-none');
		document.getElementById('servingCarriersForm').classList.remove('display-none');
		document.getElementById('additionalAttributesForm').classList.remove('display-none');
	}

	unsetWarehouseForm = () => {
		document.getElementById('warehouseForm').classList.add('display-none');
		this.resetWarehouseCheckboxes();
	}

	unsetBasicForm = () => {
		document.getElementById('basicForm').classList.add('display-none');
		// document.getElementById('marineAccessForm').classList.add('display-none');
		document.getElementById('servingCarriersForm').classList.add('display-none');
		document.getElementById('additionalAttributesForm').classList.add('display-none');
	}

	setMarineAccessForm = () => {
		document.getElementById('marineAccessForm').classList.remove('display-none');
	}

	unsetMarineAccessForm = () => {
		document.getElementById('marineAccessForm').classList.add('display-none');
	}

	resetSiteCheckboxes = () => {
		this.setState({
			plgCertification: false,
			siteQualification: false,
		})
	}

	resetWarehouseCheckboxes = () => {
		this.setState({
			warehouseAudits: false,
			siteQualification: false,
		})
	}

	processMultiSelectDependencies = (name, value) => {
		console.log('name')
		console.log(name)
		console.log(value)
		if (name === 'selectedPropertyType') {
			this.setContactInfoForm();
			this.setCheckboxForm();
			if (value === 'Industrial Sites & Properties') {
				this.setSiteForm(value);
				this.setListingFor(value);
				this.setMarineAccessForm();
			} else if (value === 'Ports, Terminals & Transloads') {
				this.setPortTerminalTransloadForm(value);
				this.setMarineAccessForm();
			} else if (value === 'Warehouse/Distribution Center') {
				this.setWarehouseForm();
				// this.setFacilityAcres();
				this.addColdStorageInCapabilities();
				this.setListingFor(value);
			}
		}

		if (name === 'siteMarineAccess') {
			this.setSiteBargeAccess(value);
		} else if (name === 'bargeAccess') {
			this.handleFacilityBargeVesselAccessChange(value);
		} else if (name === 'capabilities') {
			this.setFacilityRailcarStorageCapacity(value);
		}
	}

	handleFacilityBargeVesselAccessChange = (value: any) => {
		alert('Unexpected handleFacilityBargeVesselAccessChange: ' + value);
	}

	handleMultiSelect = (name, valueArr) => {
		if (cookies.get('token') !== undefined) {
			let multiSelectStr = '';
			let multiSelectLabel = '';
			if (Object.prototype.toString.call(valueArr) === '[object Object]') {
				multiSelectStr = valueArr.value;
				multiSelectLabel = valueArr.label;
				this.processMultiSelectDependencies(name, valueArr.value);
			} else {
				if (valueArr != null) {
					for (var i = 0; i < valueArr.length; i++) {
						this.processMultiSelectDependencies(name, valueArr[i].value);
						if (multiSelectStr === '') {
							multiSelectStr = valueArr[i].value;
							multiSelectLabel = valueArr[i].label;
						}
						else {
							multiSelectStr += '*' + valueArr[i].value;
							multiSelectLabel += '*' + valueArr[i].label;
						}
					}
				} else {
					this.processMultiSelectDependencies(name, '');
				}
			}
			console.log('multiSelectStr')
			console.log(multiSelectStr)
			if (name === 'selectedPropertyType') {
				if (multiSelectStr !== '') {
					this.setContactInfoForm();
					this.setCheckboxForm();
				} else {
					this.unsetContactInfoForm();
					this.unsetCheckboxForm();
				}

				if (!multiSelectStr.includes('Sites')) {
					this.unsetSiteForm();
				}
				if (!multiSelectStr.includes('Ports, Terminals & Transloads')) {
					this.unsetPortTerminalTransloadForm();
				}

				if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Ports, Terminals & Transloads')) {
					this.unsetMarineAccessForm();
				}

				if (!multiSelectStr.includes('Warehouse')) {
					this.unsetWarehouseForm();
				}
				if (multiSelectStr === '') {
					this.unsetBasicForm();
				}
				if (!multiSelectStr.includes('Sites') && !multiSelectStr.includes('Warehouse')) {
					this.unsetListingFor();
				}

				if (!multiSelectStr.includes('Warehouse')) {
					this.removePTTOptionFromListingFor();
				}
				// if(multiSelectStr === 'Warehouse/Distribution Center') {
				// 	document.getElementById('silosDropdownDiv').classList.add('display-none');
				// } else {
				// 	document.getElementById('silosDropdownDiv').classList.remove('display-none');
				// }
			}

			// if(name === 'facilities' && !multiSelectStr.includes('Warehouse')) {
			// 	this.removeColdStorageFromCapabilities();
			// 	this.removeFacilityAcres();
			// }

			if (name === 'selectedBuildingOption') {
				this.setBuildingOption(valueArr.value);
			}

			if (name === 'capabilities' && !multiSelectStr.includes('Rail')) {
				this.removeFacilityRailcarStorageCapacity();
			}

			let labelName = name + 'Label';

			this.setState({
				[name]: multiSelectStr,
				[labelName]: multiSelectLabel,
			});
		}
		else {
			this.handleOpen();
		}

	}
	handleOpen = () => {
		this.trackGoogleAnalyticsEvent('cl_reg_modal_open','cl_reg_modal_disp')
		this.setState({ showLoginModal: true });
	}
	handleClose = () => {
		this.trackGoogleAnalyticsEvent('cl_reg_modal_abd','cl_reg_modal_close')
		this.setState({ showLoginModal: false });
	}
	handleShowForgotPasswordModal = () => {
		this.setState({ showForgotPasswordModal: true });
		this.handleClose();
	}

	handleForgotPasswordClose = () => {
		this.setState({ showForgotPasswordModal: false });
		this.handleOpen();
	}

	setFPEmail = (event) => {
		this.setState({ fpEmail: event.target.value });
	}

	sendFPEmail = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
			{
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				fpEmail: this.state.fpEmail
			}).then(response => {
				document.getElementById('fp_success').style.display = 'block';
				document.getElementById('fp_error').style.display = 'none';
			}).catch(function (error) {
				document.getElementById('fp_success').style.display = 'none';
				document.getElementById('fp_error').style.display = 'block';
				// alert('Something went wrong! Please try after sometime.')
			});
	}

	setLoginEmail(event) {
		this.setState({ login_email: event.target.value });
	}

	setLoginPassword(event) {
		this.setState({ login_password: event.target.value });
	}
	handleLogin() {
		if (this.loginValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					login_email: this.state.login_email,
					login_password: this.state.login_password,
				}).then((response: {
					status: number
					, data: {
						bookmarks_str: string
						, email: string
						, first_name: string
						, last_name: string
						, max_no_entries: string
						, options: string
						, place_names_arr: string //Array<any>
						, user_type: string
						, token: string
					}
				}) => {
					document.getElementById('login_error').style.display = 'none';
					document.getElementById('verify_email_error').style.display = 'none';
					console.log(response);
					this.handleClose();
					// alert(window.location.href)
					if (response.status === 200) {
						let bookmarksCount = 0;
						console.log('response.data')
						console.log(response.data)
						if (response.data.bookmarks_str) {
							const bookmarksArr = response.data.bookmarks_str.split(',');
							bookmarksCount = bookmarksArr.length;
						}
						localStorage.setItem('bookmarks', response.data.bookmarks_str);
						localStorage.setItem('listingNames', response.data.place_names_arr);
						localStorage.setItem('maxNoEntries', response.data.max_no_entries);
						localStorage.setItem('membershipOptions', response.data.options);

						if (response.data.user_type === 'admin') {
							this.setState({
								showOTPModal: true
							});
						} else {
							this.handleLoginSuccessResponse(response);
						}
					} else {
						this.setState({ isLoggedIn: false });
						if (response.status === 204) {
							document.getElementById('verify_email_error').style.display = 'block';
							document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
						} else {
							document.getElementById('login_error').style.display = 'block';
							document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
						}
					}
				}).catch((error) => {
					// handle your error
					const errorEl = document.getElementById('login_error');
					if (errorEl) {
						errorEl.style.display = 'block';
						errorEl.scrollIntoView({ behavior: 'smooth' });
					}
				});
		} else {
			this.loginValidator.showMessages();
			// rerender to show messages for the first time
			// you can use the autoForceUpdate option to do this automatically`
			this.forceUpdate();
		}
	}
	handleOTPModal = () => {
		this.setState({ showOTPModal: false });
	}
	// handleInputChange = (name, value) => {
	// 	this.setState({ otp: value });
	// }

	handleOTPVerification = () => {
		if (this.OTPValidator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
				{
					headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
					otp: this.state.otp,
					login_email: this.state.login_email,
				}).then(response => {
					console.log(response);
					if (response.status === 200) {
						this.handleOTPModal();
						this.handleLoginSuccessResponse(response);
					}
				}).catch(function (error) {
					// handle your error
				});
		} else {
			this.OTPValidator.showMessages();
			this.forceUpdate();
		}
	}
	handleLoginSuccessResponse = response => {
		console.log("response", response)
		UserProfile.setEmail(response.data.email);
		UserProfile.setFirstName(response.data.first_name);
		UserProfile.setLastName(response.data.last_name);
		UserProfile.setCompanyName(response.data.companyName);
		UserProfile.setUserType(response.data.user_type);

		this.setState({
			isLoggedIn: true,
			showLoginSuccessModal: true,
		});

		cookies.set('token', response.data.token);
		this.forceUpdate();

	}
	handleLoginSuccessModal() {
		this.setState({ showLoginSuccessModal: false });
	}
	setFName(event) {
		this.setState({ fname: event.target.value });
	}

	setLName(event) {
		this.setState({ lname: event.target.value });
	}

	setCompanyName(event) {
		this.setState({ companyName: event.target.value });
	}

	setPassword(event) {
		this.setState({ password: event.target.value });
	}

	setConfirmPassword(event) {
		this.setState({ confirm_password: event.target.value });
	}

	setEmailreg(event) {
		this.setState({ reg_email: event.target.value });
	}

	onRecaptchaChange = event => {
		console.log('Captcha value: ', event);
		this.setState({ reacatpcha_value: event });
		const regi_errors = document.getElementById('contact_error');
		regi_errors.innerHTML = '';
		document.getElementById("contact_error").classList.remove("alert-danger");
	};

	registerUser(event) {
		
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			

		if (this.registerValidator.allValid()) {
			const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*';
			let randon_password = '';
			const charactersLength = characters.length;
			for ( let i = 0; i < 6; i++ ) {
				randon_password += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			this.setState({ password: randon_password });
			this.trackGoogleAnalyticsEvent('create_reg_click','create_reg_click')
            LinkedInTag.track('9791956');
			axios.post(process.env.REACT_APP_BASE_URL + '/api/register-user',
				{
					headers: { 'Content-Type': 'application/json' },
					fname: this.state.fname,
					lname: this.state.lname,
					email: this.state.reg_email,
					companyName: this.state.companyName,
					password: randon_password,
					reacatpcha_value: this.state.reacatpcha_value,
					'source':'Create Listings',
				}).then(response => {
					// handle your response;
					if (response.status === 200) {
						this.setState({
							fname: '',
							lname: '',
							companyName: '',
							//password: '',
							confirm_password: '',
							//reg_email: '',
							reacatpcha_value: null,
							showRegistrationSuccessModal: true,
							showRegistrationModal: false
						});

					}
				}).catch((error) => {
					console.log('error')
					alert('failed')
					console.log(error.response)
					// handle your error
					const regi_errors = document.getElementById('regi_errors');
					regi_errors.innerHTML = '';
					const error_html = document.createElement('div');
					if (error.response.status === 600 && error.response.data.error === 'email exists') {
						error_html.innerHTML = 'You already have an account, please login!';
					} else if (error.response.status === 601) {
						error_html.innerHTML = 'Captcha verification failed, please try again!';
					} else {
						for (let i = 0; i < error.response.data.errors.length; i++) {
							error_html.innerHTML += error.response.data.errors[i].msg + '<br>';
						}
					}
					document.getElementById('regi_errors').appendChild(error_html);
					document.getElementById('regi_errors').classList.add('alert', 'alert-danger', 'display-block');
					regi_errors.scrollIntoView({ behavior: 'smooth' })
				});
		} else {
			this.registerValidator.showMessages();
			this.forceUpdate();
		}

	}

	handleRegistrationSuccessModal = () => {
		this.setState({ showRegistrationSuccessModal: false });
		axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
			{
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
				login_email: this.state.reg_email,
				login_password: this.state.password,
			}).then((response: {
				status: number
				, data: {
					bookmarks_str: string
					, email: string
					, first_name: string
					, last_name: string
					, max_no_entries: string
					, options: string
					, place_names_arr: string //Array<any>
					, user_type: string
					, token: string
				}
			}) => {
				document.getElementById('login_error').style.display = 'none';
				document.getElementById('verify_email_error').style.display = 'none';
				console.log(response);
				this.handleClose();
				// alert(window.location.href)
				if (response.status === 200) {
					let bookmarksCount = 0;
					console.log('response.data')
					console.log(response.data)
					if (response.data.bookmarks_str) {
						const bookmarksArr = response.data.bookmarks_str.split(',');
						bookmarksCount = bookmarksArr.length;
					}
					localStorage.setItem('bookmarks', response.data.bookmarks_str);
					localStorage.setItem('listingNames', response.data.place_names_arr);
					localStorage.setItem('maxNoEntries', response.data.max_no_entries);
					localStorage.setItem('membershipOptions', response.data.options);

					if (response.data.user_type === 'admin') {
						this.setState({
							showOTPModal: true
						});
					} else {
						this.handleLoginSuccessResponse(response);
					}
				} else {
					this.setState({ isLoggedIn: false });
					if (response.status === 204) {
						document.getElementById('verify_email_error').style.display = 'block';
						document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
					} else {
						document.getElementById('login_error').style.display = 'block';
						document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
					}
				}
			}).catch((error) => {
				// handle your error
				const errorEl = document.getElementById('login_error');
				if (errorEl) {
					errorEl.style.display = 'block';
					errorEl.scrollIntoView({ behavior: 'smooth' });
				}
			});
	}


	handleMultiSelect1 = (name, valueArr) => {
		let multiSelectStr = '';

		if (valueArr != null) {

			for (var i = 0; i < valueArr.length; i++) {
				// if(i == 0){
				// 	multiSelectStr +=  valueArr[i].value;
				// 	console.log('first')
				// }
				// else if(i == valueArr.length -1){
				// 	console.log('2nd')
				// 	multiSelectStr +=  valueArr[i].value;
				// }
				// else{
				// 	console.log('3rd')
				// 	multiSelectStr += '*' + valueArr[i].value;
				// }
				multiSelectStr += '*' + valueArr[i].value;

			}
		}

		if (multiSelectStr.charAt(0) == '*') {
			multiSelectStr = multiSelectStr.substring(1)
		}

		console.log('multiSelectStr', multiSelectStr)




		this.setState({
			[name]: multiSelectStr,
		});
	}

	handleNameChange = value => {
		this.setState({ placeName: value });
	}

	handleContactNameChange = value => {
		this.setState({ contactName: value });
	}

	handleContactTitleChange = value => {
		this.setState({ contactTitle: value });
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
		if (name == 'swap_coordinates') {
			var data = this.state.swap_coordinates
			if (typeof (data) === 'object') {
				var tempCoordinates = data
			} else {
				var temp = data
				var tempCoordinates = temp.split(',')
			}

			this.setState({
				coordinates:[Number(tempCoordinates[1]),Number(tempCoordinates[0])]
			})
			console.log("this.state",this.state)


			axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${tempCoordinates[1]},${tempCoordinates[0]}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`, {
				headers: {
					"Content-type": "application/json"
				}
			})
				.then((response) => {
					console.log("Address response", response.data.features[0].context[1].text)

					const shortCode = []
					for (let i = 0; i < response.data.features[0].context.length; i++) {
						if (response.data.features[0].context[i].short_code) {
							shortCode.push(
								{
									short_code: response.data.features[0].context[i].short_code,
									state: response.data.features[0].context[i].text
								}
							)
						}
					}
					var temp = shortCode[0].short_code.split('-')

					this.setState({
						city: response.data.features[0].context[1].text,
						usaState: temp[1],
						address: response.data.features[0].place_name,
						country: temp[0],
					})

				})
				.catch((error) => {
					console.log("response", error)
				})
		}
	}
	handleEmailInputChange = (name, value) => {
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if (!pattern.test(value)) {
			document.getElementById("emailValidation").innerHTML = "Please enter valid email."
		}
		else {
			document.getElementById("emailValidation").innerHTML = ""
			this.setState({ [name]: value });
		}

	}

	handleContactChange = value => {
		this.setState({ phone: value });
	}

	handleImageChange = (name, event) => {
		console.log('file is')
		console.log(event.target.name)
		console.log(event.target.files)
		this.setState({ [name]: event.target.files });

		let reader = new FileReader();
		let file = event.target.files[0];

		reader.onloadend = () => {
			this.setState({
				imagePreviewUrl: reader.result
			});
		}

		reader.readAsDataURL(file)
	}

	onRemove(index) {
		var { files, urls } = this.state;
		let newFiles = files.filter((file, i) => i !== index);
		let newUrls = urls.filter((url, i) => i !== index);

		this.setState({
			...this.state,
			files: newFiles,
			urls: newUrls
		});
	}
	onRemovePDF(index) {
		var { pdf, pdfurls } = this.state;
		let newFiles = pdf.filter((pdf, i) => i !== index);
		let newUrls = pdfurls.filter((pdfurls, i) => i !== index);

		this.setState({
			...this.state,
			pdf: newFiles,
			pdfurls: newUrls
		});
	}

	onRemoveLogo() {
		var { listingLogo, imagePreviewUrl } = this.state;


		this.setState({
			...this.state,
			listingLogo: '',
			imagePreviewUrl: ''
		});
	}

	handleFiles(file) {

		// this could be refactored to not use the file reader

		var reader = new FileReader();

		reader.onloadend = () => {

			var imageUrl = window.URL.createObjectURL(file);

			this.setState({
				files: [file, ...this.state.files],
				urls: [imageUrl, ...this.state.urls]
			});

		}

		reader.readAsDataURL(file);
	}
	handlePDFFiles(file) {

		// this could be refactored to not use the file reader

		var reader = new FileReader();

		reader.onloadend = () => {

			var imageUrl = window.URL.createObjectURL(file);

			this.setState({
				pdf: [file, ...this.state.pdf],
				pdfurls: [imageUrl, ...this.state.pdfurls]
			});

		}

		reader.readAsDataURL(file);
	}

	changeHandler = (name, event) => {
		console.log('new file is')
		console.log(event.target.name)
		console.log(event.target.files)
		const files = event.target.files;
		[].forEach.call(files, this.handleFiles);
	}
	changeHandlerPDF = (name, event) => {
		console.log('new file is')
		console.log(event.target.name)
		console.log(event.target.files)
		const files = event.target.files;
		[].forEach.call(files, this.handlePDFFiles);
	}

	handlePriceChange = value => {
		this.setState({ price: value });
	}

	handleAddressChange = (address, coordinates, city, usaState, zipCode, country) => {
		var coordinatesnew = [coordinates[1],coordinates[0]]
		this.setState({
			address: address,
			coordinates: coordinates,
			swap_coordinates : coordinatesnew,
			city: city,
			usaState: usaState,
			zipCode: zipCode,
			country: country,
		});
		console.log('this.state', this.state);

	}

	clearAddress = () => {
		this.handleAddressChange('', [], '', '', '', '');

	}

	handleTextareaChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleCheckboxChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleDiscardListing = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/discard-all-listings', {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			email: UserProfile.getEmail()
		}).then(response => {
			console.log('discard-single-listing')
			console.log(response)
			if (response.status === 200) {
				localStorage.removeItem('listingNames');
				this.setState({
					showListingExistsModal: false,
					showCancelListingButton: false,
					shoppingCartCount: 0
				});
			} else {
				alert('Something went wrong! Try again.')
			}
		}).catch(error => {
			// handle your error
			console.log(error)
		});
	}

	hanldleNoProceedToCheckout = () => {

		this.setState({
			showCancelListingModal: false
		});

	}

	hanldleYesProceedToCheckout = () => {
		this.setState({
			selectedPropertyType: ''
		})
		this.setState({
			showCancelListingModal: false
		});
		window.location.reload()

	}

 
	showCancelListing = () => {
		if (this.state.showCancelListingButton === true) {
			this.setState({
				showCancelListingModal: true
			});
		} else {
			this.props.history.push('/');
		}
	}
	showModalGetHelp = () => {
		this.setState({
			getHelpModal: true,
		});

	}
	hideModalGetHelp = () => {
		this.setState({
			getHelpModal: false,
		});

	}
	handleShow = () => {
		this.setState({ showAlert: true });
	}

	handleDismiss = () => {
		this.setState({ showAlert: false });
	}
	setFirstName = (event) => {
		this.setState({ firstName: event.target.value });
	}

	setLastName = (event) => {
		this.setState({ lastName: event.target.value });
	}

	setEmail = (event) => {
		this.setState({ email: event.target.value });
	}

	setMessage = (event) => {
		this.setState({ message: event.target.value });
	}
	handleContactUsGeneral = () => {

		if (this.state.firstName && this.state.lastName && this.state.email && this.state.message != '' || null) {
			if (this.state.reacatpcha_value == undefined || this.state.reacatpcha_value == '') {
				const regi_errors = document.getElementById('contact_error');
				regi_errors.innerHTML = '';
				let error_html = document.createElement('div');
				error_html.innerHTML = 'Please Select Captcha';
				document.getElementById('contact_error').appendChild(error_html);
				document.getElementById('contact_error').classList.add('alert', 'alert-danger', 'display-block');
			} else if (this.state.reacatpcha_value != undefined || this.state.reacatpcha_value != '') {
				const regi_errors = document.getElementById('contact_error');
				regi_errors.innerHTML = '';
				document.getElementById("contact_error").classList.remove("alert-danger");
				this.setState({ contactusbutton: true })
				document.getElementById("contactusButon").setAttribute('disabled', 'disabled');
				axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us-added-captcha',
					{
						headers: {
							'Content-Type': 'application/json',
							// 'x-access-token': cookies.get('token')
						},
						// withCredentials: true,
						place_owner_name: '',
						place_id: '',
						name: this.state.firstName + ' ' + this.state.lastName,
						email: this.state.email,
						message: this.state.message,
						reacatpcha_value: this.state.reacatpcha_value,
					}).then(response => {
						if (response.status === 200) {
							this.handleShow()
							this.setState({
								message: '',
								contactusbutton: false,
								// showSuccessModal: true,
							});
						} else {
							this.handleDismiss()
							alert('Something went wrong')
							this.setState({ contactusbutton: false });
						}
					}).catch(function (error) {
						console.log(error)
						let error_html = document.createElement('div');
						error_html.innerHTML = 'Captcha verification failed';
						document.getElementById('contact_error').appendChild(error_html);
						document.getElementById('contact_error').classList.add('alert', 'alert-danger', 'display-block');
					});




			}

		} else {
			// this.validator.showMessages();
			alert('Please fill all necessary information')
			this.forceUpdate();
		}
	}


	render() {
		if (planid == 2) {

			Maxlistings = 5;

		} else if (planid == 3 || planid == 5) {

			Maxlistings = 25;
		}
		else if (planid == 4 || planid == 6) {

			Maxlistings = 100;

		}

		const { urls, pdfurls, files, isDragging } = this.state;

		const dragableMarkerData = JSON.parse(sessionStorage.getItem('address_coordinates'))
		let { imagePreviewUrl } = this.state;
		let $imagePreview = null;
		if (imagePreviewUrl) {
			$imagePreview = (<img src={imagePreviewUrl} className="imagePreview" />);
		} else {
			$imagePreview = ""
		}

		return (
			<div className='padding-bottom-50'>
				<Helmet>
					<title>{this.state.PageTitle != " " ? this.state.PageTitle : "Create Listing"}</title>
					<meta name='description' content={this.state.PageMetaDiscription != " " ? this.state.PageMetaDiscription : "Discription"} />
					<meta name="robots" content={this.state.PageMetaRobot != " " ? this.state.PageMetaRobot : " "} />
					<meta property="og:url" content={window.location.href} />
					<meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
				</Helmet>
				<Header data={this.props} onRef={ref => (this.child = ref)} shoppingCartCount={this.state.shoppingCartCount} />
				<div id='page-content' style={{ paddingBottom: '50px', minHeight: '100vh', zIndex: 1 }}>
					<div>
						<Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
							<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
							<BreadcrumbItem active>Create</BreadcrumbItem>
							<i role='button' style={{ float: 'right', color: 'white' }} onClick={this.showModalGetHelp} className='fa fa-envelope fs-30 font-size-18-imp pull-right' aria-hidden='true'></i>

						</Breadcrumb>
						<div className='container padding-top-30'>
							<div className='col-md-12 col-sm-12 padding-0-xs'>
								<ListingInstructions />
								<div className='col-md-12 col-sm-12 padding-0-xs'>
									<form style={{ width: '100%' }} id='place_data'>
										<div className='block-heading' id='details' style={{ width: '100%' }}>
											<h4 className='pull-left'><span className='heading-icon'><i className='fa fa-caret-right icon-design'></i><i className='fa fa-home' style={{ color: 'white' }}></i></span>Listing Details</h4>
											{/* {
												this.state.showCancelListingButton && */}
											<button type='button' className='btn btn-danger border-radius-25-imp pull-right margin-top-10' onClick={this.showCancelListing}>Cancel Listing</button>
											{/* } */}
										</div>
										<div className='padding-as25 margin-30 lgray-bg padding-15-xs clear-both' id='create-listing-form'>
											<Row>
												<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
													<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>Listing type</label>
													<InputMultiSelectComponent name='selectedPropertyType'
														value={this.state.selectedPropertyType}
														options={sortArray(this.state.propertyTypeArr)}
														onHandleMultiSelect={this.handleMultiSelect}
														isMulti={true}
													/>
												</div>
												<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='listingForDropdownDiv'>
													<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
														{
															this.state.selectedPropertyType.includes("Warehouse") ? "For Sale/Lease/3PL" : "For Sale/Lease"
														}
													</label>
													<InputMultiSelectComponent name='selectedPropertyFor'
														value={this.state.selectedPropertyFor}
														options={this.state.listingForArr}
														onHandleMultiSelect={this.handleMultiSelect}
														isMulti={true}
													/>
												</div>
											</Row>

											<div id='basicForm' className='display-none'>
												<hr />
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Listing Name</label>
														<InputBoxComponent name='placeName' value={this.state.placeName} onHandleInputChange={this.handleInputChange} />
														{this.validator.message('listing name', this.state.placeName, 'required')}
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-14'>
																	Image names will show when uploaded. If no image names show, nothing has been uploaded.
																</strong>
															</Tooltip>
														}>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>
																Listing Organization Logo (300X100 in pixels) <i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
															</label></OverlayTrigger>
														<InputImageComponent name='listingLogo' multiple={false} onHandleImageChange={this.handleImageChange} />
														{/* <div className="imgPreview">
															{$imagePreview}
														</div> */}
														{
															imagePreviewUrl &&
															<div className="imagePreviewContainer">
																<div className="previewItem">
																	{/* <img className="imagePreview" src={url} /> */}
																	<img src={imagePreviewUrl} className="imagePreview" />
																	<br />

																	<label className="material-icons"
																		style={{ color: 'red', fontSize: 12, justifyContent: 'center', display: 'grid', marginBottom: '-1px' }}
																		onClick={() => this.onRemoveLogo()}>Remove</label>
																</div>
															</div>

														}

													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Contact Organization</label>
														<InputBoxComponent name='organizationName' value={this.state.organizationName} onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Company Website</label>
														<InputBoxComponent name='companyWebsiteUrl' value={this.state.companyWebsiteUrl} onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 clear-both'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Contact Name</label>
														<InputBoxComponent name='contactName' value={this.state.contactName} onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Contact Title</label>
														<InputBoxComponent name='contactTitle' value={this.state.contactTitle} onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Contact Email</label>
														<InputBoxComponent name='contactEmail' value={this.state.contactEmail} type='email' onHandleInputChange={this.handleEmailInputChange} />
														{this.validator.message('contactEmail', this.state.contactEmail, 'email')}
														<p style={{ color: '#a94442' }} id="emailValidation"></p>
														{this.validator.message('email', this.state.contactEmail, 'required')}
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputContact'>Contact Phone</label>
														<InputBoxComponent name='phone' type='number' value={this.state.phone} onHandleInputChange={this.handleInputChange} />
														{this.validator.message('phone', this.state.phone, 'phone')}

													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<OverlayTrigger placement='right' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-14'>
																	Listing Images names will show when uploaded. If no image names show,<br /> nothing has been uploaded.
																	You may multi-select images to upload at once.
																</strong>
															</Tooltip>
														}>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>
																Listing Images (900X600 in pixels) <i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i>
															</label>
														</OverlayTrigger>
														<InputImageComponent name='selectedImage' multiple={true} /*onHandleImageChange={this.handleImageChange}*/ onHandleImageChange={this.changeHandler} />
														<div className="imagePreviewContainer">
															{
																urls && (urls.map((url, i) => (
																	<div className="previewItem">
																		<img className="imagePreview" src={url} />
																		<br />

																		<label className="material-icons"
																			style={{ color: 'red', fontSize: 12, justifyContent: 'center', display: 'grid', marginBottom: '-1px' }}
																			onClick={() => this.onRemove(i)}>Remove</label>

																		{/* <div className="details">
																			<h6>{files[i].name}</h6>
																			<h6>{files[i].size.toLocaleString()} KBs</h6>
																			<h6>{files[i].type}</h6>
																			
																		</div> */}
																	</div>
																)))
															}
														</div>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='usaStateManual'>Brochure upload</label>

														<InputPdfComponent name='selectedImage' multiple={true} /*onHandleImageChange={this.handleImageChange}*/ onHandlePdfChange={this.changeHandlerPDF} />
														<div className="imagePreviewContainer">
															{
																pdfurls && (pdfurls.map((pdfurls, i) => (
																	<div className="previewItem">
																		<img className="imagePreview" src={pdfurls} />
																		<br />

																		<label className="material-icons"
																			style={{ color: 'red', fontSize: 12, justifyContent: 'center', display: 'grid', marginBottom: '-1px' }}
																			onClick={() => this.onRemovePDF(i)}>Remove</label>

																		{/* <div className="details">
																			<h6>{files[i].name}</h6>
																			<h6>{files[i].size.toLocaleString()} KBs</h6>
																			<h6>{files[i].type}</h6>
																			
																		</div> */}
																	</div>
																)))
															}
														</div>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Type address or </label>
														{' '}
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5, cursor: 'pointer', color: '#0070ff' }} htmlFor='' onClick={this.showDraggableMarkerModal}>set location</label>
														<InputAddressComponent onHandleAddressChange={this.handleAddressChange} location={'create_place'} value={this.state.address} />
														{
															this.state.address
															&&
															<button type='button'
																className='close text-uppercase font-weight-bold'
																style={{ fontSize: 12, width: 50, margin: 5, float: 'left' }}
																onClick={() => this.clearAddress()}
															>
																clear
															</button>
														}
														<div style={{ marginBottom: 5 }}></div>
														{this.validator.message('address', this.state.coordinates ? this.state.coordinates : dragableMarkerData, 'required')}
														{/* {
															!sessionStorage.getItem('address_coordinates')
															&&
															this.validator.message('address', this.state.address, 'required')
														} */}

													</div>
													{
														// sessionStorage.getItem('address_coordinates')
														// &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>

															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Coordinates</label>
															{/* <input type="text" value={`${dragableMarkerData.coordinates[1]} , ${dragableMarkerData.coordinates[0]}  `} /> */}
															<InputBoxComponent name='swap_coordinates' value={this.state.swap_coordinates} onHandleInputChange={this.handleInputChange} />
															<div style={{ marginBottom: 5 }}></div>


														</div>
													}


												</Row>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 col-xs-12  clear-both'>
														<p className='text-uppercase' style={{ fontSize: 12, paddingBottom: 5 }}><b>These fields are optional.</b> If you notice in your listing that the city and state fields do not show correctly, please add your city and state fields manually below. </p>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 clear-both'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='manualcity'>City</label>
														<InputBoxComponent name='manualcity' value={this.state.manualcity} onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='usaStateManual'>State</label>

														<InputMultiSelectComponent name='usaStateManual'
															options={sortArray(this.state.usaStateArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.usaStateManual}
														/>
													</div>



												</Row>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Description</label>
														<InputTextareaComponent name='description' onHandleTextareaChange={this.handleTextareaChange} />
													</div>
												</Row>
											</div>

											<div id='siteForm' className='display-none'>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Property Attributes</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Price in $</label>
														<InputBoxComponent type='number' min='0' name='price' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Per Unit</label>
														<InputBoxComponent type='text' name='perUnit' onHandleInputChange={this.handleInputChange} />
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 pull-left margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputMin'>Acreage</label>
														<InputBoxComponent type='number' min='0' name='acreage' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputZoning'>Zoning</label>
														<InputMultiSelectComponent name='siteZoning' options={sortArray(this.state.zoningArr)} onHandleMultiSelect={this.handleMultiSelect} isMulti={true} value={this.state.siteZoning} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Pipeline Access</label>
														<InputMultiSelectComponent name='sitePipelineAccess' options={sortArray(this.state.pipelineAccessArr)} onHandleMultiSelect={this.handleMultiSelect} isMulti={true} value={this.state.sitePipelineAccess} />
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputProperty'>Building</label>
														<InputMultiSelectComponent name='selectedBuildingOption'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.selectedBuildingOption}
														/>
													</div>
													<div id='buildingOptionsIfYesDiv' className={`${this.state.selectedBuildingOption && this.state.selectedBuildingOption === 'Yes' ? '' : 'display-none'}`}>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Building Sq. ft</label>
															<input type='number' min='0' className='form-control' style={{ marginBottom: '5px' }} onChange={this.setSitesSquareFeet.bind(this)} value={this.state.sitesSquareFeet}></input>
														</div>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Clear Height (in ft)</label>
															<input type='number' min='0' className='form-control' style={{ marginBottom: '5px' }} onChange={this.setSitesInteriorRoofHeight.bind(this)} value={this.state.sitesInteriorRoofHeight}></input>
														</div>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Electric Service in place</label>
														<InputMultiSelectComponent name='selectedPowerToSite'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.selectedPowerToSite}
														/>
													</div>
													<div className={`${this.state.selectedPowerToSite && this.state.selectedPowerToSite === 'Yes' ? '' : 'display-none'}`}>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Service Voltage (kV)</label>
															<InputBoxComponent type='number' min='0' name='serviceVoltage' onHandleInputChange={this.handleInputChange} />
														</div>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Distance to Substation (miles)</label>
															<InputBoxComponent type='number' min='0' name='distanceToSubstation' onHandleInputChange={this.handleInputChange} />
														</div>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Natural gas service in place</label>
														<InputMultiSelectComponent name='naturalGasInPlace'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.naturalGasInPlace}
														/>
													</div>
													<div className={`${this.state.naturalGasInPlace && this.state.naturalGasInPlace === 'Yes' ? '' : 'display-none'}`}>
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Line diameter (inches)</label>
															<InputBoxComponent type='number' min='0' name='lineDiameter' onHandleInputChange={this.handleInputChange} />
														</div>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Suitable for</label>
														<InputMultiSelectComponent name='siteSuitableFor'
															options={sortArray(this.state.siteSuitableForArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															value={this.state.siteSuitableFor}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputCity'>Environmental</label>
														<InputMultiSelectComponent name='siteEnvironmental'
															options={sortArray(this.state.envionmentalArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.siteEnvironmental}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Air attainment status</label>
														<InputMultiSelectComponent name='airAttainmentStatus'
															options={sortArray(this.state.airAttainmentStatusArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.airAttainmentStatus}
														/>
													</div>
												</Row>
											</div>

											<div id='terminalPortForm' className='display-none'>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Terminal, Port and Transload Criteria</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Capabilities</label>
														<InputMultiSelectComponent name='capabilities' options={sortArray(this.state.capabilitiesArr)} onHandleMultiSelect={this.handleMultiSelect} isMulti={true} value={this.state.capabilities} />
													</div>
													{
														this.state.capabilities.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Capabilities</label>
															<InputBoxComponent name='otherCapabilities' onHandleInputChange={this.handleInputChange} />
														</div>
													}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Equipment</label>
														<InputMultiSelectComponent name='equipments' options={sortArray(this.state.equipmentsArr)} onHandleMultiSelect={this.handleMultiSelect} isMulti={true} value={this.state.equipments} />
													</div>
													{
														this.state.equipments.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Equipment</label>
															<InputBoxComponent name='otherEquipments' onHandleInputChange={this.handleInputChange} />
														</div>
													}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='facilityRailcarStorageCapacityDiv'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Railcar storage capacity</label>
														<InputBoxComponent type='number' min='0' name='railcarStorageCapacity' onHandleInputChange={this.handleInputChange} />
													</div>
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='facilityAcresDiv'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Acres</label>
														<InputBoxComponent type='number' min='0' name='acres' onHandleInputChange={ this.handleInputChange } />
													</div> */}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Major features</label>
														<InputTextareaComponent name='majorFeatures' onHandleTextareaChange={this.handleTextareaChange} />
													</div>



													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20' style={{ marginBottom: 8 }}>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Specifications</label>
														<InputTextareaComponent name='specifications' onHandleTextareaChange={this.handleTextareaChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Product storage</label>
														<InputMultiSelectComponent name='productStorage' options={sortArray(this.state.productStorageArr)} onHandleMultiSelect={this.handleMultiSelect} isMulti={true} value={this.state.productStorage} />
													</div>
													{
														this.state.productStorage.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Product storage</label>
															<InputBoxComponent name='otherProductStorage' onHandleInputChange={this.handleInputChange} />
														</div>
													}
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='facilityNameplaceDiv' style={{ marginBottom: 30 }}>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Nameplate capacity</label>
														<InputBoxComponent type='text' name='nameplateCapacity' onHandleInputChange={ this.handleInputChange } />
													</div> */}
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Bulk type</label>
														<InputMultiSelectComponent name='bulkType' options={ sortArray(this.state.bulkTypeArr) } onHandleMultiSelect={ this.handleMultiSelect } isMulti={true} />
													</div> */}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Products handled</label>
														<InputMultiSelectComponent name='productsHandled' options={sortArray(this.state.productsHandledArr)} onHandleMultiSelect={this.handleMultiSelect} isMulti={true} value={this.state.productsHandled} />
													</div>
													{
														this.state.productsHandled.toLowerCase().includes('Other'.toLowerCase()) &&
														<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Other Product handled</label>
															<InputBoxComponent name='otherProductsHandled' onHandleInputChange={this.handleInputChange} />
														</div>
													}
													<div className='col-md-4 col-sm-4 col-xs-12 pull-left margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputMin'>Ports, Terminals & Transloads Acreage</label>
														<InputBoxComponent type='number' min='0' name='pttAcreage' onHandleInputChange={this.handleInputChange} />
													</div>
													{/* <div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Indoor storage</label>
														<InputMultiSelectComponent name='indoorStorage' options={ sortArray(this.state.indoorStorageArr) } onHandleSingleSelect={ this.handleMultiSelect } isMulti={false} />
													</div> */}
												</Row>
											</div>

											<div id='warehouseForm' className='display-none'>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Warehouse/Distribution Center Criteria</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Warehouse Price in $</label>
														<InputBoxComponent type='number' min='0' name='warehousePrice' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Per Unit</label>
														<InputBoxComponent type='text' name='warehousePricePerUnit' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Warehouse Capabilities</label>
														<InputMultiSelectComponent name='warehouseCapabilities'
															options={sortArray(this.state.warehouseCapabilitiesArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															value={this.state.warehouseCapabilities}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Min Size (available sq. ft.)</label>
														<InputBoxComponent type='number' min='0' name='warehouseMinSize'
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Max Size (available sq. ft.)</label>
														<InputBoxComponent type='number' min='0' name='warehouseMaxSize'
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Temperature control</label>
														<InputMultiSelectComponent name='temperatureControl'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.temperatureControl}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Secured Parking</label>
														<InputMultiSelectComponent name='securedParking'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.securedParking}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Total dock doors</label>
														<InputBoxComponent type='number' min='0' name='TotalDockDoors' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Drive in dock doors</label>
														<InputBoxComponent type='number' min='0' name='DriveInDockDoors' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Dock level doors </label>
														<InputBoxComponent type='number' min='0' name='DockLevelDoors' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Total area (sq ft) </label>
														<InputBoxComponent type='number' min='0' name='TotalWarehouseArea' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Available area (sq ft)</label>
														<InputBoxComponent type='number' min='0' name='TotalWarehouseAvailableArea' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Office Space (sq ft)</label>
														<InputBoxComponent type='number' min='0' name='TotalWarehouseOfficeSpaceArea' onHandleInputChange={this.handleInputChange} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 clear-both'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Parking spaces (for both inbound and outbound)</label>
														<InputBoxComponent type='number' min='0' name='parkingSpaces'
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Food grade</label>
														<InputMultiSelectComponent name='foodGrade'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.foodGrade}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>FTZ (FOREIGN TRADE ZONE)</label>
														<InputMultiSelectComponent name='freeTradeZone'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.freeTradeZone}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Clear Height (in ft)</label>
														<InputBoxComponent type='number' min='0' name='roofHeight'
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
												</Row>
											</div>

											<div id='marineAccessForm' className='display-none'>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Marine Access</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Marine access</label>
														<InputMultiSelectComponent name='siteMarineAccess'
															options={sortArray(this.state.marineAccessArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															value={this.state.siteMarineAccess}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='siteDocInPlaceDiv'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Dock in place now?</label>
														<select className='form-control font-weight-normal' style={{ marginBottom: '14px', fontWeight: "normal" }} onChange={this.setSiteDocInPlace.bind(this)} value={this.state.docInPlace}>
															<option value=''>Dock in place now?</option>
															<option value='Yes'>Yes</option>
															<option value='No'>No</option>
														</select>
													</div>
													{
														this.state.docInPlace.toLowerCase().includes('Yes'.toLowerCase()) &&
														<>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Depth (ft)</label>
																<InputBoxComponent type='number' min='0' name='portDepth'
																	value={this.state.portDepth}
																	onHandleInputChange={this.handleInputChange}
																/>
															</div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Length (ft)</label>
																<InputBoxComponent type='number' min='0' name='portLength'
																	value={this.state.portLength}
																	onHandleInputChange={this.handleInputChange}
																/>
															</div>
														</>

													}
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20 display-none' id='siteWhichRiverDiv'>
														<OverlayTrigger placement='top' overlay={
															<Tooltip id='tooltip'>
																<strong className='font-size-14'>
																	Enter a waterway into the text box and press Enter to see it added.
																</strong>
															</Tooltip>
														}>
															<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Waterways <i className='fa fa-info-circle font-size-18-imp' aria-hidden='true'></i></label>

														</OverlayTrigger>
														<InputMultiSelectComponent name='waterways'
															options={sortArray(arrWaterWays)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															value={this.state.waterways}
														/>
													</div>

													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20' id='siteBADistanceToNavigableWater'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Distance to navigable water (miles)</label>
														<InputBoxComponent type='number' min='0' name='navigableWater'
															value={this.state.navigableWater}
															onHandleInputChange={this.handleInputChange}
														/>
													</div>
												</Row>
											</div>
											<div id='servingCarriersForm' className='display-none'>
												<hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 20, paddingBottom: 5 }}>Serving Rail Carriers</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Select class I connections</label>
														{/* <InputMultiSelectComponent name='siteClassOneConnections'
															options={sortArray(this.state.classOneConnectionsArr)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															value={this.state.siteClassOneConnections}

														/> */}
														<Select
															closeMenuOnSelect={false}
															name='siteClassOneConnections'
															isMulti
															options={sortArray(this.state.classOneConnectionsArr)}
															defaultValue={this.state.siteShortLines}
															onChange={(value) => this.handleMultiSelect1('siteClassOneConnections', value)}

														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }}>Short Lines</label>
														{/* <InputMultiSelectComponent name='siteShortLines'
															options={sortArray(arrShortLines)}
															onHandleMultiSelect={this.handleMultiSelect}
															isMulti={true}
															reduceFontSize={true}
															value={this.state.siteShortLines}
														/> */}
														<Select
															closeMenuOnSelect={false}
															name='siteShortLines'
															isMulti
															options={sortArray(arrShortLines)}
															defaultValue={this.state.siteShortLines}
															onChange={(value) => this.handleMultiSelect1('siteShortLines', value)}
														/>
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Rail in place</label>
														<InputMultiSelectComponent name='selectedRailInPlace'
															options={sortArray(this.state.selectOptionsArr)}
															onHandleSingleSelect={this.handleMultiSelect}
															isMulti={false}
															value={this.state.selectedRailInPlace}
														/>
													</div>
													{
														this.state.selectedRailInPlace && this.state.selectedRailInPlace.toLowerCase().includes('Yes'.toLowerCase()) &&
														<div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Railcar storage</label>
																<InputMultiSelectComponent name='railcarStorage'
																	options={sortArray(this.state.railcarStorageArr)}
																	onHandleMultiSelect={this.handleMultiSelect}
																	isMulti={true}
																	value={this.state.railcarStorage}
																/>
															</div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor=''>Unit train capable</label>
																<InputMultiSelectComponent name='unitTrainCapable'
																	options={sortArray(this.state.selectOptionsArr)}
																	onHandleSingleSelect={this.handleMultiSelect}
																	isMulti={false}
																	value={this.state.unitTrainCapable}
																/>
															</div>
															<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
																<label className='text-uppercase font-weight-bold' style={{ fontSize: 12, paddingBottom: 5 }} htmlFor='inputDescription'>Railcar spots/capacity</label>
																<InputBoxComponent type='number' min='0' name='railcarCapacity'
																	onHandleInputChange={this.handleInputChange}
																/>
															</div>
														</div>
													}

												</Row>
												{/* <Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Rail in place option</label>
														<InputBoxComponent type='text' name='siteRailInPlaceOption' onHandleInputChange={ this.handleInputChange } />
													</div>
												</Row> */}
											</div>

											<div id='additionalAttributesForm' className='display-none'>
												{/* <hr />
												<Row>
													<div className='col-md-12 col-sm-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} style={{fontSize: 20}}>Additional Attributes</label>
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Parking Capacity for</label>
														<InputMultiSelectComponent name='parkingCapacity' options={ sortArray(this.state.parkingCapacityArr) } onHandleMultiSelect={ this.handleMultiSelect } isMulti={true} />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Nearest Interstate</label>
														<InputBoxComponent type='text' name='nearestInterstate' onHandleInputChange={ this.handleInputChange } />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Miles to Interstate</label>
														<InputBoxComponent type='text' name='milesToInterstate' onHandleInputChange={ this.handleInputChange } />
													</div>
												</Row>
												<Row>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Nearest Airport</label>
														<InputBoxComponent type='text' name='nearestAirport' onHandleInputChange={ this.handleInputChange } />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}}>Miles to Airport</label>
														<InputBoxComponent type='text' name='milesToAirport' onHandleInputChange={ this.handleInputChange } />
													</div>
													<div className='col-md-4 col-sm-4 col-xs-12 margin-bottom-20' id='silosDropdownDiv'>
														<label className='text-uppercase font-weight-bold'  style={{fontSize: 12, paddingBottom: 5}} htmlFor=''>Silos</label>
														<InputMultiSelectComponent name='silos' options={ sortArray(this.state.selectOptionsArr) } onHandleSingleSelect={ this.handleMultiSelect } isMulti={false} />
													</div>
												</Row> */}
											</div>

											<div id='checkboxForm' className='display-none'>
												<hr />
												{/* {
													this.state.selectedPropertyType.includes("Sites") &&
													<Row>
														<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
															<InputCheckboxComponent name='plgCertification'
																checked={this.state.plgCertification}
																onHandleCheckboxChange={this.handleCheckboxChange}
																label='Please contact me about PLG site certification'
															/>
														</div>
													</Row>
												} */}
												{/* {
													this.state.selectedPropertyType.includes("Warehouse") &&
													<Row>
														<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
															<InputCheckboxComponent name='warehouseAudits'
																checked={this.state.warehouseAudits}
																onHandleCheckboxChange={this.handleCheckboxChange}
																label='Provide information about Warehouse Audits'
															/>
														</div>
													</Row>
												} */}
												{/* <Row>
													<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
														<InputCheckboxComponent name='siteQualification'
															checked={this.state.siteQualification}
															onHandleCheckboxChange={this.handleCheckboxChange}
															label='I would like to receive RFI/RFP’s for which my site is qualified'
														/>
													</div>
												</Row> */}
											</div>

											<div id='contactInfoCheckbox' className='display-none'>
												<Row>
													<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
														<InputCheckboxComponent name='useContactInfo'
															checked={this.state.useContactInfo}
															onHandleCheckboxChange={this.handleCheckboxChange}
															label='Use same contact information for next listing.'
														/>
													</div>
												</Row>
												<Row>
													<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 margin-bottom-20'>
														<button disabled={this.state.submitbutton} type='button' className='btn btn-default border-radius-25-imp' onClick={this.savePlace} id='saveButon'>
															{
																this.state.submitbutton ?
																	<i className="fa fa-spinner fa-spin"></i> :
																	''


															}
															Submit
														</button>

														{
															this.state.submitbutton ?
																<span> Your listing will be submitted shortly.</span> :
																''


														}
													</div>

												</Row>

											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				{ /*Success modal for listings this.state.showNewRecordModal*/}
				<Modal show={this.state.showNewRecordModal} onHide={this.handleNewRecordModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10 text-left' style={{ textTransform: 'none' }}>
								Congrats on creating your listing.
								{
									planid == 7 ?
										''
										:
										<span style={{ color: 'black' }}>You have {Number(localStorage.getItem('maxNoEntries') ? localStorage.getItem('maxNoEntries') : null) - listings - 1} listings available to use.</span>

								}
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Do you want to add more listings?
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						{/* <Link to={{
							pathname: '/create-listings',
							state: {
								addMoreClicked: true,
							}
						}}>
							<Button data-dismiss="modal" variant='btn btn-default border-radius-25-imp' className='text-uppercase btn btn-default border-radius-25-imp'>Add More</Button>
						</Link> */}
						<button type='button' className='text-uppercase btn btn-default border-radius-25-imp margin-left-15' onClick={this.hanldleYesProceedToCheckout}>Add More</button>
						<Link to={{
							pathname: '/my-listing',
							state: {
								addMoreClicked: true,
							}
						}}>
							<Button data-dismiss="modal" variant='btn btn-default border-radius-25-imp' className='text-uppercase btn btn-default border-radius-25-imp margin-left-15'>Not Now</Button>
						</Link>
						{/* <button type='button' className='btn btn-default border-radius-25-imp text-uppercase margin-left-15' onClick={this.handleNewRecordModal.bind(this)}>Not Now</button> */}
					</Modal.Footer>
				</Modal>
				{ /*exceed listing modal for listings show={listings >= Number(localStorage.getItem('maxNoEntries') ? localStorage.getItem('maxNoEntries') : null)}*/}
				<Modal show={Number(localStorage.getItem('maxNoEntries')) ? listings >= Number(localStorage.getItem('maxNoEntries')) : false} onHide={this.handleupdateplanModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								You have exceeded your listings for the current plan. Please update your plan to continue  the service.
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Do you want to update your plan?
							</p>
						</div>
					</Modal.Body><br /><br />
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>


						<Link style={{ float: "left" }} to={{ pathname: '/upgrade-plan' }} ><button type='button' className='btn btn-default border-radius-25-imp'  >Update Now</button></Link>
						{/* <Link to={{
							pathname: '/checkout',
							state: {
								addMoreClicked: true,
							}
						}}>
							<Button onclick={(this.handleupdateplanModal)} style={{ float: "left" }} variant='btn btn-default border-radius-25-imp' className='text-uppercase btn btn-default border-radius-25-imp float_left'>Update Now</Button>
							
						</Link> */}
						<Link to={{
							pathname: '/my-listing',
							state: {
								addMoreClicked: true,
							}
						}}>
							<h4>Not Now</h4>
							{/*<Button variant='btn btn-default border-radius-25-imp' className='btn btn-default border-radius-25-imp text-uppercase margin-left-15'>Not  Now</Button>*/}

						</Link>
					</Modal.Footer>
				</Modal>


				{/* Discard Listing confirmation modal */}
				{/*
					<Modal show={this.state.showListingExistsModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
						<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
						<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
							<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
								<div className='event-type warning'>
									<div className='event-indicator '>
										<SVGExclamationIcon />
									</div>
								</div>
								<h4 className='text-black font-size-16 margin-bottom-10' style={{ textTransform: 'none' }}>
									Listings already exists in cart. Do you want discard and create new one?
								</h4>
							</div>
						</Modal.Body>
						<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
							<button type='button' className='btn btn-danger border-radius-25-imp' onClick={this.handleDiscardListing}>Discard</button>
							<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.hanldleProceedToCheckout}>Proceed to checkout</button>
						</Modal.Footer>
					</Modal>
					*/}
				{/*Modal for update plan*/}
				<Modal dialogClassName='custom-modal-step3' show={false} style={{ opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
						<div className="d-flex justify-content-center">
							<form id="upgradeplan" style={{ backgroundColor: "white" }}>
								<div id='regi_errors' className='margin-top-15'></div>
								<div style={{ width: "200px", margin: "30px auto" }}><img src="/static/media/logo.26b073ac.svg" /></div>

								<div className='text-left color-black font-size-18  margin-bottom-20' style={{ color: '#474747', marginLeft: 10 }}><h1>Please upgrade your membership plan that best suits your needs.</h1></div>

								<div className='row container-fluid' >

									<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
										<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 700 }}>
											<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
												<div className="pricingGrid__rowItem" data-plan="premium">


													<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">  POWER SEARCH</h2>
													<p style={{ fontSize: 15 }} className="copy margin--top-c4">Expand your search and listing capabilities </p>

												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
												<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

													<div className="flex flex--bottom" aria-hidden="true">
														<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
															<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
															<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">29</div>
															<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
															<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
														</div>

														<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
															Per month                      </div>
													</div>



													<br /><br />
													<div className="experiment">
														<Link to={{
															pathname: '/checkout',
															state: {
																addMoreClicked: true,
															}
														}}>
															<Button type="submit" className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
														</Link>
														{/*
													<div className="margin--bottom-c3">
														<Button type="submit" onClick={() => { handleOnClick(3) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#8A8A8A', color: 'white', height: 40, width: "100%", fontSize: 16 }} >Get Started</Button>
													</div>
													*/}
													</div>
												</div>
											</div><br />

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
												<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

													<li className="margin--bottom-c4"> All Basic functionality +   </li>
													<li className="margin--bottom-c4">Search by keyword  </li>
													<li className="margin--bottom-c4">Search for listings with access to multiple rail carriers </li>
													<li className="margin--bottom-c4">Advanced map layers (Class I and Shortline railroads, intermodal terminals, crude pipelines and refined product pipelines) </li>
													<li className="margin--bottom-c4">Export map results and views</li>
													<li className="margin--bottom-c4">Up to 25 listings</li>
												</ul>
											</div>
											{/*
						<h3 style={{ marginLeft: 15, fontFamily: "Means Web,Georgia,Times,Times New Roman,serif", marginTop: 30, height: 30 }}>POWER SEARCH</h3>

						<div className="flex flex--bottom" aria-hidden="true" style={{ marginLeft: 15 }}>
							<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
								<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
								<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">29</div>
								<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
								<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
							</div>

							<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
								Per month                      </div>
						</div>

						<p className="padding-five-all text-center" style={{ fontSize: 14, height: 61 }}>Expand your search and listing capabilities</p>

						<hr style={{ marginLeft: 20, marginRight: 20 }} />

						<ul style={{ paddingLeft: 15, paddingRight: 5 }}>
							<li style={{ margin: 8 }}> All Basic functionality +</li>
							<li style={{ margin: 8 }}> Search by keyword</li>
							<li style={{ margin: 8 }}> Search for listings with access to multiple rail carriers</li>
							<li style={{ margin: 8 }}> Advanced map layers (Class I and Shortline railroads, intermodal
								terminals, crude pipelines and refined product pipelines)</li>
							<li style={{ margin: 8 }}> Export map results and views</li>
							<li style={{ margin: 8 }}> Up to 25 listings</li>

						</ul>
						<div className="d-flex justify-content-center">
							<Button type="submit" onClick={() => { handleOnClick(3) }} bsStyle="warning" bsSize="large" style={{ backgroundColor: "#8A8A8A", position: 'absolute', bottom: '2%' }} className="btn btn-default border-radius-25-imp" block>
								Checkout
							</Button>
						</div>
					*/}
										</div>
									</div>

									<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
										<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 700 }}>
											<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
												<div className="pricingGrid__rowItem" data-plan="premium">


													<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">POWER PRO</h2>
													<p style={{ fontSize: 15 }} className="copy margin--top-c4">Market a portfolio of sites or a suite of logistics services. </p>

												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
												<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

													<div className="flex flex--bottom" aria-hidden="true">
														<div className="formattedPrice flex flex--left" data-behavior="formattedPrice" data-plan="premium">
															<div className="formattedPrice__price--symbol--left" data-behavior="formattedPrice__symbol">$</div>
															<div className="formattedPrice__price--integer" data-behavior="formattedPrice__integer">49</div>
															<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
															<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
														</div>

														<div className="copy-regular pricingPlans--compare__perMonth" data-equalize-height="planCard__dynamicContacts" style={{ height: 28 }}>
															Per month                      </div>
													</div>

													<p className="screen-reader-only" data-behavior="formattedPrice__a11y" data-before-text="Starts at" data-after-text="per month for 0 contacts"> ₹23000 per month </p>

													<br /><br />
													<div className="experiment">
														<div className="margin--bottom-c3">
															<Link to={{
																pathname: '/plans-and-pricing',
																state: {
																	addMoreClicked: true,
																}
															}}>
																<Button type="submit" className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >GET STARTED</Button>
															</Link>
														</div>
														{/*
													<div className="margin--bottom-c3">
														<Button type="submit" onClick={() => { handleOnClick(4) }} className='font-Gotham-Pro-Medium font-weight-normal-imp' style={{ border: '1px solid white', borderRadius: '0px !important', background: '#00316B', color: 'white', height: 40, width: "100%", fontSize: 16 }} >Get Started</Button>
													</div>
													*/}
													</div>
												</div>
											</div><br />

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
												<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 341 }}>

													<li className="margin--bottom-c4"> All of Basic and Power Search functionality +    </li>
													<li className="margin--bottom-c4">See who has viewed your listings </li>
													<li className="margin--bottom-c4">Premium placement within search results </li>
													<li className="margin--bottom-c4">Up to 100 listings </li>
												</ul>
											</div>

										</div>

									</div>

									<div style={{ padding: 0 }} className='col-md-4 mt-0 mt-md-4 padding-top-30'>
										<div style={{ backgroundColor: '#ffffff', border: '1px solid #e3e3e3', borderRadius: 5, height: 700 }}>
											<div className="margin--vertical-c4" data-equalize-height="planCard__overview" style={{ height: 139, marginLeft: 15, marginTop: 20 }}>
												<div className="pricingGrid__rowItem" data-plan="premium">


													<h2 style={{ fontSize: 30 }} className="h5 pricingPlans__header">PRIVATE LABEL</h2>
													<p style={{ fontSize: 15 }} className="copy margin--top-c4">Explore custom solutions </p>

												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15 }}>
												<div className="pricingGrid__rowItem" data-plan="premium" data-component-id="pricingPlan" data-entry-id="2QQ6iCdyzPn3liIOc8I7K5" data-context-text="Premium">

													<div className="flex flex--bottom" aria-hidden="true">
														<div className="formattedPrice1 flex flex--left" data-behavior="formattedPrice" data-plan="premium">
															<div className="formattedPrice1__price--symbol--left" data-behavior="formattedPrice__symbol">Contact For Pricing</div>
															<div className="formattedPrice1__price--integer" data-behavior="formattedPrice__integer"></div>
															<div className="formattedPrice__price--fraction" data-behavior="formattedPrice__fraction"></div>
															<div className="formattedPrice__price--symbol--right" data-behavior="formattedPrice__symbol--right"></div>
														</div>
													</div>


													<hr />
													<div className="experiment">

														<div className="margin--bottom-c3">
															<p style={{ fontSize: 15 }} className="copy margin--top-c4">Call (312) 957-7757<br />info@industrialnavigator.com </p>

														</div>
													</div>
												</div>
											</div>

											<div className="margin--vertical-c4" style={{ marginLeft: 15, marginRight: 15, lineHeight: 2 }}>
												<ul className="pricingPlans__featuresRow__list" data-equalize-height="planCard__featuresRow" style={{ height: 303 }}>

													<li className="margin--bottom-c4">Implant the functionality of Industrial Navigator on your website to market your assets and services    </li>
													<li className="margin--bottom-c4">Create custom map layers  </li>
													<li className="margin--bottom-c4">Market research and logistics optimization  </li>
													<li className="margin--bottom-c4">Multi-user subscriptions </li>
												</ul>
											</div>
										</div>
									</div>
								</div>

							</form>

						</div>
					</Modal.Body>
				</Modal>


				{/* Cancel Listing confirmation modal */}
				<Modal show={this.state.showCancelListingModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type warning'>
								<div className='event-indicator '>
									<SVGExclamationIcon />
								</div>
							</div>
							<h4 className='text-black font-size-16 margin-bottom-10' style={{ textTransform: 'none' }}>
								Are you sure you want to cancel your listing?
							</h4>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.hanldleYesProceedToCheckout}>Yes</button>
						<button type='button' className='btn btn-default border-radius-25-imp margin-left-15' onClick={this.hanldleNoProceedToCheckout}>No</button>
					</Modal.Footer>
				</Modal>

				{/* Create a draggable Marker  */}

				<Modal dialogClassName='custom-modal-step3' show={this.state.showmarkerModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton onClick={this.CloseDraggableMarkerModal}></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<DraggableMarker />
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.SaveDraggableMarkerModal}>Save</button>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.CloseDraggableMarkerModal}>Cancel</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.getHelpModal} onHide={this.hideModalGetHelp} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ paddingTop: '2rem', paddingLeft: '1rem', paddingRight: '1rem' }} closeButton>
						<h3 style={{ fontSize: 20 }}>CONTACT US</h3>

					</Modal.Header>
					<Modal.Body style={{ background: 'white', paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
						<div>
							<b style={{ fontWeight: 500, fontSize: 16 }}> Please email us at info@industrialnavigator.com or call us at +1 (312) 957-7757 for any feedback or questions.</b>
						</div>
						{
							this.state.showAlert &&
							<Alert bsStyle='success' onDismiss={this.handleDismiss}>
								<h2 style={{ marginTop: 0, marginBottom: 10, color: 'white' }}>Success!</h2>
								<p>
									Your message has been sent!
								</p>
							</Alert>
						}
						<div style={{ paddingLeft: 0, paddingRight: 0 }} className='col-lg-12 col-md-12 mt-5 mt-md-0 padding-top-30'>
							<div className='row'>
								<form style={{ width: '100%' }}>
									<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
										<input type='text' className='form-control margin-bottom-0-imp' onChange={this.setFirstName} value={this.state.firstName} required placeholder='Your First Name'></input>
										{/* {this.validator.message('first name', this.state.firstName, 'required')} */}
									</div>
									<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
										<input type='text' className='form-control margin-bottom-0-imp' onChange={this.setLastName} value={this.state.lastName} required placeholder='Your Last Name'></input>
										{/* {this.validator.message('last name', this.state.lastName, 'required')} */}
									</div>
									<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
										<input type='email' className='form-control margin-bottom-0-imp' onChange={this.setEmail} value={this.state.email} required placeholder='Your Email'></input>
										{/* {this.validator.message('email', this.state.email, 'required')} */}
									</div>
									<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
										<textarea className='form-control margin-bottom-0-imp' onChange={this.setMessage} value={this.state.message} placeholder='Your message' rows={6}></textarea>
										{/* {this.validator.message('message', this.state.message, 'required')} */}
									</div>
									<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
										<div id='contact_error' className='text-center'></div>
										<ReCAPTCHA
											ref={recaptchaRef}
											onChange={this.onRecaptchaChange}
											sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}

										/>
									</div>
									<div className='col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 margin-bottom-10'>
										<Button disabled={this.state.contactusbutton} id='contactusButon' style={{ borderRadius: '0px !important', background: '#4DAFFF', height: 50, width: '100%', fontSize: 18, paddingLeft: 25, paddingRight: 25 }} onClick={this.handleContactUsGeneral}>
											<span className='pull-left font-Gotham-Pro-Medium font-weight-normal'>Contact Us</span>
											{
												this.state.contactusbutton ?
													<i className="fa fa-spinner fa-spin fs-30"></i> :
													''


											}
											<span className='pull-right'><RightArrowSVGIcon /></span></Button>
									</div>
								</form>
							</div>
						</div>

					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.hideModalGetHelp}>Close</button>
					</Modal.Footer>
				</Modal>


				<Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Body>

						<div>
							<div style={{ padding: '2px', backgroundColor: 'white' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
										<img src={logo} />
									</div>
									<Tabs defaultActiveKey="Sign Up"  >

										<Tab eventKey="Sign Up" title="Sign Up" style={{ color: "black" }}>
											<>
												<div>
													<div style={{ backgroundColor: '#f8f9fa', padding: '.5rem', borderRadius: 6 }}>

														<form id='slider-subscribe-form' style={{ width: '99%', marginLeft: '0%', paddingBottom: '10px' }} className='nobottommargin reduce-margin-form'>
															<div className='color-black font-size-18 margin-bottom-20' style={{ color: '#474747', fontSize: 14 }}>Welcome! By registering, you will start with 5 listings to add to our platform! You may also browse our extensive listings. Note that some features will not be available with Basic. Please upgrade at any time to take advantage of all Industrial Navigator has to offer in the admin section. Do you already have an account with us? Click the Login tab above to sign in.</div>

															<div id='regi_errors' className='margin-top-15'></div>

															<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
															</div>
															{this.registerValidator.message('first name', this.state.fname, 'required')}

															<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
																<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
															</div>
															{this.registerValidator.message('last name', this.state.lname, 'required')}
															<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
															</div>
															{this.registerValidator.message('company name', this.state.companyName, 'required')}
															<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='email' name='reg_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmailreg.bind(this)} value={this.state.reg_email} />
															</div>
															{this.registerValidator.message('email', this.state.reg_email, 'required')}
															{/* <div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
															</div>
															{this.registerValidator.message('password', this.state.password, 'required')}
															<div style={{ width: "100%" }} className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
																<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
															</div>
															{this.registerValidator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
															 */}
															{/* <ReCAPTCHA
																ref={recaptchaRef}
																// size='invisible'
																className='margin-top-15'
																onChange={this.onRecaptchaChange.bind(this)}
																sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
															/>
															{this.registerValidator.message('captcha', this.state.reacatpcha_value, 'required')} */}
															<div className='col_full center padding-top-20'>
																<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
															</div>
														</form>
													</div>
												</div>
											</>
										</Tab>
										<Tab eventKey="Login" title="Login" style={{ color: "black" }}>
											<>
												<form id='slider-subscribe-form' style={{ paddingBottom: '10px' }} className='nobottommargin'>
													<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747', marginTop: 20 }}>Enter email address and password to login</div>
													<div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
													<div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
													<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
														<input type='email' name='login_email' className='form-control input-lg not-dark required email col-lg-12' placeholder='Enter your Email..' onChange={this.setLoginEmail.bind(this)} value={this.state.login_email} />
													</div>
													{this.loginValidator.message('email', this.state.login_email, 'required')}
													<div style={{ width: "100%" }} className='input-group margin-top-15 col-lg-12'>
														<input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword.bind(this)} value={this.state.login_password} />
													</div>
													{this.loginValidator.message('password', this.state.login_password, 'required')}
													<div className='col_full center padding-top-20'>
														<button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin.bind(this)}>Sign In</button>
													</div>
													<div className='col_full center padding-top-20'>
														<span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
													</div>
												</form>
											</>
										</Tab>
									</Tabs>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>
				<Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Registration Successful
							</h2><br />
							<p className='text-muted text-left font-size-16-imp margin-bottom-0'>
								Congratulations on creating a Basic account!<br />Your unique password is {this.state.password}. You can change it later by clicking on "forgot password" when logging in next time.<br /><br /> Now that you have subscribed, you may create and list up 5 properties on our platform for free. You may also browse all listings in our app. Note that some browsing features will not be available with the Basic plan. If you want to experience all of what Industrial Navigator has to offer, please upgrade by clicking on "Admin" -&gt; My Profile. For any questions, please email us at info@industrialnavigator.com, or call +1 (312) 957-7757.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Login Successful
							</h2>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
					</Modal.Footer>
				</Modal>
				<Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ backgroundColor: '#009df5' }}>
						<div>
							<div style={{ padding: '3rem' }}>
								<div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
									<div style={{ width: 200, margin: '0 auto' }}>
										<img src={logo} />
									</div>
									<h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Forgot Password
									</h2>
									<form style={{ width: '95%', marginLeft: '2%', marginRight: '2%', paddingBottom: 10 }} className='nobottommargin'>
										<div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
										<div id='fp_success' className='alert alert-success' style={{ display: 'none' }}> Password link should be sent to your email address.</div>
										{/* <div className='input-group margin-top-15 col-lg-12'>
											<input type='email' name='fpEmail' className='input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
										</div> */}
										<input type='email' name='fpEmail' className='input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />

										<div className='col_full center padding-top-20'>
											<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>

				<Footer />
			</div>

		)

	}
}

export default CreatePlace;

const SVGExclamationIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#000000' d='M11,4.5H13V15.5H11V4.5M13,17.5V19.5H11V17.5H13Z' />
	</svg>
)
const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)
const RightArrowSVGIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='20'
		height='20'
		viewBox='0 0 20 20'
	>
		<g fill='none' fillRule='evenodd' stroke='none' strokeWidth='1'>
			<g fill='#FFF' transform='translate(-1166 -2563)'>
				<g transform='translate(-27)'>
					<g transform='translate(0 2140)'>
						<g transform='translate(857 120)'>
							<g transform='translate(0 286)'>
								<g transform='translate(336 17)'>
									<path d='M10.334.244L20 10l-9.666 9.756-1.167-1.179 7.671-7.744H0V9.167h16.839L9.167 1.422 10.334.244z'></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)