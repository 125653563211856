import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';

import { LabelValue } from '../constant/Types';

interface PInputMultiSelectComponent {
	value?: string;
	flagAll?: boolean;
	onHandleMultiSelect?: (name: string, value: Array<LabelValue>) => void;
	onHandleSingleSelect?: (name: string, value: LabelValue) => void;
	name: string;
	reduceFontSize?: boolean;
	options?: Array<LabelValue>;
	isMulti?: boolean;
	isDisabled?: boolean;
}

class SInputMultiSelectComponent {
	value: Array<LabelValue> | LabelValue = null;

	constructor(ref: InputMultiSelectComponent) {
		this.value = ref.props.isMulti ? [] : null;
		if (ref.props.isMulti && ref.props.onHandleSingleSelect) {
			alert('Wrong config 01');
		} else if (!ref.props.isMulti && ref.props.onHandleMultiSelect) {
			alert('Wrong config 02');
		} else if (ref.props.onHandleMultiSelect && ref.props.onHandleSingleSelect) {
			alert('Wrong config 03');
		} else if (!ref.props.onHandleMultiSelect && !ref.props.onHandleSingleSelect) {
			alert('Wrong config 04');
		}
	}
}

class InputMultiSelectComponent extends Component<PInputMultiSelectComponent, SInputMultiSelectComponent> {

	constructor(props: PInputMultiSelectComponent) {
		super(props);
		this.state = new SInputMultiSelectComponent(this);
	}

	componentDidMount() {
		const { props } = this;
		if (props.value) {
			const values = props.value.split('*');
			const valueArr: Array<LabelValue> = [];
			for (let i = 0; i < values.length; i++) {
				valueArr.push({
					label: values[i],
					value: values[i]
				});
			}
			this.setState({ value: valueArr });
		}
	}

	componentWillReceiveProps(nextProps: PInputMultiSelectComponent) {
		// Added this condition temporary
		if (window.location.pathname === '/create') {
			if (nextProps.value !== '' && nextProps.value !== undefined) {
				if (nextProps.value !== null) {
					const values = nextProps.value.split('*');
					const valueArr: Array<LabelValue> = [];
					for (let i = 0; i < values.length; i++) {
						valueArr.push({
							label: values[i],
							value: values[i]
						});
					}
					this.setState({ value: valueArr });
				} else {
					this.setState({ value: [] });
				}
			}
		} else {
			if (nextProps.value) {
				const values = nextProps.value.split('*');
				const valueArr: Array<LabelValue> = [];
				for (let i = 0; i < values.length; i++) {
					valueArr.push({
						label: values[i],
						value: values[i]
					});
				}
				this.setState({ value: valueArr });
			} else {
				this.setState({ value: [] });
			}
		}
	}

	handleChange = (value: Array<LabelValue> | LabelValue) => {
		const { props } = this;
		this.setState({ value: value });
		if (props.isMulti) {
			props.onHandleMultiSelect(props.name, value as Array<LabelValue>);
		} else {
			props.onHandleSingleSelect(props.name, value as LabelValue);
		}
	}

	render() {
		const { state, props } = this;
		const { flagAll } = props;
		return (
			<CreatableSelect
				className={props.reduceFontSize ? 'clear-both text-left font-size-12' : 'clear-both text-left'}
				name={props.name}
				value={flagAll ? [{ 'label': 'ALL', 'value': 'ALL' }] : state.value}
				onChange={(value: Array<LabelValue>) => this.handleChange(value)}
				options={props.options}
				isMulti={props.isMulti}
				isDisabled={props.isDisabled}
				isLoading={true}
				allowCreateWhileLoading={false}
				noOptionsMessage={ "No Option" }
			/>
		)
	}
}

export default InputMultiSelectComponent;