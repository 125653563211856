import React, { useEffect, lazy, Suspense } from 'react';
import logoImg from './public/assets/images/logo-black.png';
import { index } from './config/plugins';
import Layout from './Layouts/MainLayout';
import TopBanner from './qloud/topbanner';
import Footer from './PrivateClient/footer';
import PortfoiloDetail from './PrivateClient/details';
import About from './PrivateClient/about-us';
import Property from './PrivateClient/property';
// const Layout = lazy(() => import('./Layouts/MainLayout'));
// const Footer = lazy(() => import('./PrivateClient/footer'));
// const PortfoiloDetail = lazy(() => import('./PrivateClient/details'));
// const About = lazy(() => import('./PrivateClient/about-us'));
// const Property = lazy(() => import('./PrivateClient/property'));
// const TopBanner = lazy(() => import('./qloud/topbanner'));

const Index = props => {


    useEffect(() => {
        setTimeout(() => {
            index();
        }, 500);
    });

    return (
        <>
            <Layout mainHeaderClass={"header-main "} logoImg={logoImg}>

                <TopBanner title="Private Label" />
                <About />
                <PortfoiloDetail />
                <Property />
                <Footer />
            </Layout>
        </>
    );
}

export default Index;
