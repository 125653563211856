import React from "react";
import axios from 'axios';
import Features from '../Features';
import PDFgenerate from './PDFgenerate'
let componentRef;
class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            place: '',
            listingName: '',
            ListingAddress: '',
            ListingImage: '',
            ListingsOrgName: '',
            ListingContactName: '',
            ListingContact: '',
            ListingContactEmail: '',
            ListingContactTitle: '',
            ListingsLogo: '',
            ListingDescription: '',
            ListingMajorfeatuers: '',
            ListingSpecifications: '',
            ListingPropertyType: '',
            formattedPrice: ''
        }

    }
    componentDidMount() {
        var generate_pdf_arr = this.props.generate_pdf_arr

    }
    // getData = async (id) => {
    //     await axios.post(process.env.REACT_APP_BASE_URL + '/api/get-place-details', {
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    //         rec_id: id,
    //     })
    //         .then(res => {
    //             console.log('placedata', res.data.feature)
    //             const place = res.data.feature;
    //             console.log(res.data.feature.properties.image[0].url)
    //             if (res.status === 200) {
    //                 var formatter = new Intl.NumberFormat('en-US', {
    //                     style: 'currency',
    //                     currency: 'USD',
    //                     minimumFractionDigits: 0
    //                 });
    //                 let formattedPrice = formatter.format(place.properties.price);

    //                 this.setState({
    //                     place: place,
    //                     listingName: res.data.feature.properties.listingName,
    //                     ListingAddress: res.data.feature.properties.address,
    //                     ListingImage: res.data.feature.properties.image[0].url,
    //                     ListingsOrgName: res.data.feature.properties.organizationName,
    //                     ListingContactName: res.data.feature.properties.contactName,
    //                     ListingContact: res.data.feature.properties.contact,
    //                     ListingContactEmail: res.data.feature.properties.contactEmail,
    //                     ListingContactTitle: res.data.feature.properties.contactTitle,
    //                     ListingsLogo: res.data.feature.properties.listingLogo,
    //                     ListingDescription: res.data.feature.properties.description,
    //                     ListingMajorfeatuers: res.data.feature.properties.majorFeatures,
    //                     ListingSpecifications: res.data.feature.properties.specifications,
    //                     ListingPropertyType: res.data.feature.properties.propertyType,
    //                     formattedPrice: formattedPrice
    //                 })
    //             }
    //         }).catch(err => {
    //             console.log(err);
    //         });
    // }
    render() {
        return (
            <div className='padding-bottom-50'>

                <div className="container" id='page-content' style={{ padding: 50 }} >
                    <div className='text-center' style={{ margin: 100, paddingTop: 150, paddingBottom: 150,minHeight:900,display:'block' }}>
                        <img style={{ height: 'auto', width: 'auto' }} src={JSON.parse(localStorage.getItem('PrivateClientData')).ImgUrl} /><br />
                        <h1 style={{ marginTop: 30, fontSize: 30 }}>{JSON.parse(localStorage.getItem('PrivateClientData')).PrivateClientOrg}</h1>
                        <h1 className='title-color-pdf-fav' style={{ marginTop: 30, fontSize: 30 }}>FAVORITES LIST</h1>
                    </div>


                    {this.props.generate_pdf_arr.map(item => (
                        <>
                            <PDFgenerate rec_id={item} ref={(response) => (componentRef = response)} />

                        </>
                    ))}
                    {/* <PDFgenerate rec_id={121} ref={(response) => (componentRef = response)} />
                    <PDFgenerate rec_id={122} ref={(response) => (componentRef = response)} /> */}

                </div>

            </div>
        );
    }
}

export default TableComponent;