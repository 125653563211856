import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import { Breadcrumb, BreadcrumbItem, Modal } from 'react-bootstrap';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

interface PPlaces {
}

class SPlaces {
	email: string = '';
	newPassword: string = '';
	confirmNewPassword: string = '';
	showPCSuccessModal: boolean = false;

	constructor() {
	}
}

class RecoverPassword extends Component<PPlaces, SPlaces> {

	validator: SimpleReactValidator = null;
	child: Header = null;

	constructor(props: PPlaces) {
		super(props);
		this.state = new SPlaces();
		console.log('para')
		console.log(this.props)
	}
	componentDidMount() {
		//this.setState({ email: cookies.get('temp_email') });
		const queryParams = new URLSearchParams(window.location.search);
		const email = queryParams.get('email');
		//alert(email);
		this.setState({ email: email });

	}

	componentWillMount() {
		// const url = window.location.href;
		// const CryptoJSKey = process.env.REACT_APP_CRYPTOJS_KEY;
		// const explodedURL = url.split('/recover-password/');
		// const cipherText = explodedURL[1];
		// const bytes  = CryptoJS.AES.decrypt(cipherText.toString(), CryptoJSKey);
		// let plaintext = bytes.toString(CryptoJS.enc.Utf8);
		// plaintext = plaintext.split('+');
		// const email = plaintext[0];
		// const timestamp = plaintext[1];
		// this.setState({ email: cookies.get('temp_email') });
		//cookies.remove('temp_email');
		
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		// if(UserProfile.getEmail())
		// {
		// 	console.log(this.props)
		// 	this.props.history.push('/');
		// }
	}

	setNewPassword = (event) => {
		this.setState({ newPassword: event.target.value });
	}

	setConfirmNewPassword = (event) => {
		this.setState({ confirmNewPassword: event.target.value });
	}

	savePassword = () => {
		
		if (this.validator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/update-password',
				{
					headers: {
						'Content-Type': 'application/json',
					},
					email: this.state.email,
					newPassword: this.state.newPassword,
				}).then(response => {
					if (response.data.result === 'success') {
						this.setState({ showPCSuccessModal: true });
						var elem = document.getElementById('recover-password-form');
						elem.parentNode.removeChild(elem);
						document.getElementById('rp_error').style.display = 'none';
					}
				}).catch(function (error) {
					// handle your error
					this.setState({ showPCSuccessModal: false });
					document.getElementById('rp_error').style.display = 'block';
				});
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	render() {
		return (
			<div>
				<Header onRef={ref => (this.child = ref)} />
				<div id='page-content' style={{ paddingBottom: '50px', minHeight: '100vh', zIndex: 1 }}>
					<Breadcrumb style={{ paddingTop: '10px', paddingBottom: '10px' }}>
						<BreadcrumbItem active>Home</BreadcrumbItem>
					</Breadcrumb>
					<div className='container padding-top-30'>
						<div className='col-lg-6 col-lg-offset-3 col-md-10 col-md-offset-1'>
							<div id='rp_error' className='alert alert-danger' style={{ display: 'none' }}>Something went wrong! Please try again later.</div>
							<div className='padding-as25 margin-30 lgray-bg' id='recover-password-form'>
								<div className='row'>
									<form>
										<div className='block-heading' id='details'>
											<h4><span className='heading-icon'><i className='fa fa-caret-right icon-design'></i><i className='fa fa-home' style={{ color: 'white' }}></i></span>Recover Password</h4>
										</div>
										<div className='col-md-12 col-sm-12 margin-bottom-20'>
											<label className='font-weight-bold'>New Password</label>
											<input type='password' className='form-control' style={{ marginBottom: '5px' }} onChange={this.setNewPassword} value={this.state.newPassword}></input>
											{this.validator.message('new password', this.state.newPassword, 'required')}
										</div>
										<div className='col-md-12 col-sm-12 margin-bottom-20'>
											<label className='font-weight-bold'>Confirm New Password</label>
											<input type='password' className='form-control' style={{ marginBottom: '5px' }} onChange={this.setConfirmNewPassword} value={this.state.confirmNewPassword}></input>
											{this.validator.message('confirmNewPassword', this.state.confirmNewPassword, `required|in:${this.state.newPassword}`, { messages: { in: 'Passwords need to match!' } })}
										</div>
										<div className='col-md-12 col-sm-12 margin-bottom-20'>
											<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.savePassword}>Submit</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
				<Modal show={this.state.showPCSuccessModal} onHide={this.handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Password Change Successful
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Your password has beed changed! You can now login.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<Link className='btn btn-default border-radius-25-imp' to={{ pathname: '/' }}>Close</Link>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}

	handleClose: any = null;
}

export default RecoverPassword;

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)