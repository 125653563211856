import React, { useEffect, lazy, Suspense } from 'react';
import { index } from './config/plugins';
import logoImg from './public/assets/images/logo-black.png';
import Layout from './Layouts/MainLayout';
import Footer from './ListerPage/footer';
import PortfoiloDetail from './ListerPage/details';
import About from './ListerPage/about-us';
import Property from './ListerPage/property';
import TopBanner from './qloud/topbanner';
// const Layout = lazy(() => import('./Layouts/MainLayout'));
// const Footer = lazy(() => import('./ListerPage/footer'));
// const PortfoiloDetail = lazy(() => import('./ListerPage/details'));
// const About = lazy(() => import('./ListerPage/about-us'));
// const Property = lazy(() => import('./ListerPage/property'));
// const TopBanner = lazy(() => import('./qloud/topbanner'));

const Index = props => {
    useEffect(() => {
        setTimeout(() => {
            index();
        }, 500);
    });

    return (
        <>
            <Layout mainHeaderClass={"header-main "} logoImg={logoImg}>
                <TopBanner title="List with Industrial Navigator" />
                <PortfoiloDetail />
                <About />
                <Property />
                <Footer />
            </Layout>
        </>
    );
}

export default Index;
