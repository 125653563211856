import React, { useEffect, useState } from "react";
import Header from '../HeaderLTE';
import SideBar from '../SideBarLTE';
import Footer from '../Footer';
import {useHistory} from 'react-router'
import InputMultiSelectComponent from '../InputMultiSelectComponent';
import InputCheckboxComponent from '../InputCheckboxComponent';
import InputBoxComponent from '../InputBoxComponent'
import {withRouter} from 'react-router-dom'
import axios from 'axios';
import { Modal} from 'react-bootstrap';


function EditMembership(props) {

    const SVGIcon = () => (
        <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
            <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
        </svg>
    )

    const history = useHistory();
    //console.log("ID : ", props.match.params.id);

    const [membershipData,setMembershipData] = useState({
        id:props.match.params.id,
        membership_level_name:"",
        membership_level_cost:"",
        max_no_entries:"",
        membership_is_active:""
    });

    

    const hadleInput = async(event) =>{
        event.preventDefault();
        console.log(event);
        setMembershipData({...membershipData,[event.target.name]:event.target.value});
    }
    const [success,setSuccess] = useState(false);


    const  handleClose = () => {
        setSuccess(false);
        history.push('/memberships')
    }


    const membershipFormSubmit = () =>{

        

        axios.post(process.env.REACT_APP_BASE_URL + '/api/update-membership-plan', {
            headers: { 'Content-Type': 'application/json' },
            ...membershipData,options:JSON.stringify([obj])
            // options:
            //     `[{"multi_class_I":${memberCheck.multi_class_I}},{"favorites":${memberCheck.favorites}},{"dmsg":${memberCheck.dmsg}},{"classI":${memberCheck.classI}},{"shortline":${memberCheck.shortline}},{"intermodal":${memberCheck.intermodal}},{"ports":${memberCheck.ports}}]`
        
            })
        .then(function (response) {
           
            console.log("membershipData",membershipData)
            console.log("options",obj)
            console.log(response)
            setSuccess(true);
        })
        .catch(function (error) {
        console.log(error);
        });

    }

    


    useEffect(()=>{
         axios.post(process.env.REACT_APP_BASE_URL + '/api/get-membership-plan', {
            id: props.match.params.id
          })
          .then(function (response) {
              
            setMembershipData({
                ...membershipData,
                membership_level_name: response.data[0].membership_level_name,
                membership_level_cost: response.data[0].membership_level_cost,
                max_no_entries: response.data[0].max_no_entries,
                membership_is_active: response.data[0].active
            })

            //console.log("----",JSON.parse(response.data[0].options[0].multi_class_I))
            //setCheckData(JSON.parse(response.data[0].options))

            setData(JSON.parse(response.data[0].options))

            

          })
          .catch(function (error) {
            console.log(error);
          });
    },[])

    
   const [data,setData] = useState(
    [{
        multi_class_I:false,
        favorites:false,
        dmsg:false,
        classI:false,
        shortline:false,
        intermodal:false,
        ports:false
    }]
   )

   var obj = {
    multi_class_I:data[0].multi_class_I,
    favorites:data[0].favorites,
    dmsg:data[0].dmsg,
    classI:data[0].classI,
    shortline:data[0].shortline,
    intermodal:data[0].intermodal,
    ports:data[0].ports
}
   
let setKeywords = ()=>{
    if(obj.multi_class_I === true){
        obj.multi_class_I = false
    }else if(obj.multi_class_I === false){
        obj.multi_class_I = true
    }else{
        obj.multi_class_I = false
    }
}

let setFavorites = ()=>{
    if(obj.favorites === true){
        obj.favorites = false
    }else if(obj.favorites === false){
        obj.favorites = true
    }else{
        obj.favorites = false
    } 
}

let setDmsg = ()=>{
    if(obj.dmsg === true){
        obj.dmsg = false
    }else if(obj.dmsg === false){
        obj.dmsg = true
    }else{
        obj.dmsg = false
    } 
}

let setClassI = ()=>{
    if(obj.classI === true){
        obj.classI = false
    }else if(obj.classI === false){
        obj.classI = true
    }else{
        obj.classI = false
    } 
}

let setShortline = ()=>{
    if(obj.shortline === true){
        obj.shortline = false
    }else if(obj.shortline === false){
        obj.shortline = true
    }else{
        obj.shortline = false
    } 
}

let setIntermodal = ()=>{
    if(obj.intermodal === true){
        obj.intermodal = false
    }else if(obj.intermodal === false){
        obj.intermodal = true
    }else{
        obj.intermodal = false
    } 
}

let setPorts = ()=>{
    if(obj.ports === true){
        obj.ports = false
    }else if(obj.ports === false){
        obj.ports = true
    }else{
        obj.ports = false
    } 
}


   
   
     const hsub = ()=>{
         
       
         console.log("obj",obj)
        
     }




    return (
        <div style={{ backgroundColor: '#222D32' }}>
            <Header />
            <SideBar />

            <Modal show={success} onHide={handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Membership Updated
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Membership is Updated successfully.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={handleClose}>Close</button>
					</Modal.Footer>
				</Modal>  
              
            <div className='content-wrapper background-white'>
                <section className='content-header'>
                    <div className='row'>
                        <div className='col-md-offset-1 col-md-10'>
                            <div className='col-md-12'>
                                <div style={{ flex: '1 0 auto', color: 'rgba(0,0,0,.87)', fontSize: 22, fontWeight: 400, marginTop: 15, marginBottom: 30 }}>Edit Membership</div>
                                
                                {/* {JSON.stringify(data[0])} */}

                            
                                
                                <form method="post">
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>Memberhip name</label>
                                            <input type="text" 
                                                   name='membership_level_name' 
                                                   value={membershipData.membership_level_name}
                                                  
                                                   onChange={hadleInput} className="form-control"/>
                                            
                                            
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>Memberhip cost (in $)</label>
                                            <input type='number' 
                                                   min='0' 
                                                   name='membership_level_cost'
                                                   style={{height:40}} 
                                                   value={membershipData.membership_level_cost}
                                                   onChange={hadleInput} 
                                                   className="form-control"/>
                                            
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>Max listings allowed</label>
                                            <input type='number' 
                                                   min='0' 
                                                   name='max_no_entries' 
                                                   style={{height:40}}
                                                   value={membershipData.max_no_entries}
                                                   onChange={hadleInput} 
                                                   className="form-control"/>
                                            
                                        </div>
                                    </div>
                                    <div className='col-md-4 clear-both margin-bottom-20'>
                                        <div className='form-group'>
                                            <label>Active</label>
                                            {/* <InputMultiSelectComponent
                                                name='membership_is_active'
                                               
                                                isMulti={false}
                                            /> */}
                                            <select name='membership_is_active' onChange={hadleInput} value={membershipData.membership_is_active}  className="form-control">
                                            <option value="">Select</option>
                                            <option value='1' >Yes</option>
                                                <option value='0'>No</option>
                                            </select>
                                            
                                        </div>
                                    </div>
                                   
                                    <div className='col-md-12 margin-bottom-10 clear-both'>
                                        
                                    <input className='margin-bottom-10 clear-both' 
                                               type='checkbox' 
                                               name='multi_class_I' 
                                               Checked={JSON.stringify(data[0].multi_class_I)}
                                               value={JSON.stringify(data[0].multi_class_I)}  
                                               onClick={setKeywords}/> <label>Keywords</label>
                                    </div>
                                    
                                    <div className='col-md-12 margin-bottom-10 clear-both'>
                                        
                                        <input className='margin-bottom-10 clear-both' 
                                               type='checkbox' 
                                               name='favorites' 
                                               value={JSON.stringify(data[0].favorites)}  
                                               Checked={JSON.stringify(data[0].favorites)}
                                               onClick={setFavorites}
                                               /> <label>Favorites List</label>
                                    </div>

                                    

                                    <div className='col-md-12 margin-bottom-10 clear-both'>
                                        
                                        <input className='margin-bottom-10 clear-both' 
                                               type='checkbox' 
                                               name='dmsg' 
                                               value={JSON.stringify(data[0].dmsg)}  
                                               Checked={JSON.stringify(data[0].dmsg)}
                                               onClick={setDmsg}
                                               /> <label>Direct Message</label>
                                    </div>

                                    

                                    <div className='col-md-12 margin-bottom-10 clear-both'>
                                        
                                        <input className='margin-bottom-10 clear-both' 
                                               type='checkbox' 
                                               name='classI'
                                               value={JSON.stringify(data[0].classI)}  
                                               Checked={JSON.stringify(data[0].classI)}
                                               onClick={setClassI}
                                               /> <label>Class I Railroads</label>
                                    </div>
                                    
                                    <div className='col-md-12 margin-bottom-10 clear-both'>
                                        
                                        <input className='margin-bottom-10 clear-both' 
                                               type='checkbox' 
                                               name='shortline' 
                                               value={JSON.stringify(data[0].shortline)}  
                                               Checked={JSON.stringify(data[0].shortline)}
                                               onClick={setShortline}
                                               /> <label>Short Lines</label>
                                    </div>
                                    
                                    <div className='col-md-12 margin-bottom-10 clear-both'>
                                        
                                        <input className='margin-bottom-10 clear-both' 
                                               type='checkbox' 
                                               name='intermodal' 
                                               value={JSON.stringify(data[0].intermodal)}  
                                               Checked={JSON.stringify(data[0].intermodal)}
                                               onClick={setIntermodal}
                                               /> <label>Intermodal Terminals</label>
                                    </div>
                                    
                                    <div className='col-md-12 margin-bottom-10 clear-both'>
                                        
                                    <input className='margin-bottom-10 clear-both' 
                                               type='checkbox' 
                                               name='ports' 
                                               value={JSON.stringify(data[0].ports)}  
                                               Checked={JSON.stringify(data[0].ports)}
                                               onClick={setPorts} 
                                               /> <label>Major Ports</label>
                                    </div>
                                    
                                    <div className='col-md-12 text-center' style={{ paddingTop: 20 }}>
                                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={membershipFormSubmit}>Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />

        </div>
    )
}
export default withRouter(EditMembership)