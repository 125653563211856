import { LabelValue, sortArray, joinFilterV } from './Types';

export const arrWaterWays: Array<LabelValue> = [
    { 'label': 'ABERDEEN CREEK', 'value': 'ABERDEEN CREEK' },
    { 'label': 'ABSECON CREEK', 'value': 'ABSECON CREEK' },
    { 'label': 'ABSECON INLET', 'value': 'ABSECON INLET' },
    {
        'label': 'ACADIANA NAVIGATION CHANNEL',
        'value': 'ACADIANA NAVIGATION CHANNEL'
    },
    { 'label': 'ACCOTINK CREEK', 'value': 'ACCOTINK CREEK' },
    { 'label': 'ACTIVE PASS', 'value': 'ACTIVE PASS' },
    { 'label': 'ACUSHNET RIVER', 'value': 'ACUSHNET RIVER' },
    { 'label': 'ADAMS BAY', 'value': 'ADAMS BAY' },
    { 'label': 'AFOGNAK BAY', 'value': 'AFOGNAK BAY' },
    { 'label': 'AGATE PASSAGE', 'value': 'AGATE PASSAGE' },
    { 'label': 'AGUADILLA BAY', 'value': 'AGUADILLA BAY' },
    { 'label': 'AIALIK BAY', 'value': 'AIALIK BAY' },
    { 'label': 'ALABAMA RIVER', 'value': 'ALABAMA RIVER' },
    { 'label': 'ALAFIA RIVER', 'value': 'ALAFIA RIVER' },
    { 'label': 'ALALAKEIKI CHANNEL', 'value': 'ALALAKEIKI CHANNEL' },
    { 'label': 'ALAMITOS BAY', 'value': 'ALAMITOS BAY' },
    {
        'label': 'ALBEMARLE & CHESAPEAKE CANAL',
        'value': 'ALBEMARLE & CHESAPEAKE CANAL'
    },
    { 'label': 'ALBEMARLE SOUND', 'value': 'ALBEMARLE SOUND' },
    { 'label': 'ALBERGOTTIE CREEK', 'value': 'ALBERGOTTIE CREEK' },
    { 'label': 'ALENUIHAHA CHANNEL', 'value': 'ALENUIHAHA CHANNEL' },
    {
        'label': 'ALENUIHAHA CHANNEL ACCESS',
        'value': 'ALENUIHAHA CHANNEL ACCESS'
    },
    { 'label': 'ALERT BAY', 'value': 'ALERT BAY' },
    { 'label': 'ALGIERS CANAL', 'value': 'ALGIERS CANAL' },
    { 'label': 'ALLEGHENY RIVER', 'value': 'ALLEGHENY RIVER' },
    { 'label': 'ALLERTON HARBOR', 'value': 'ALLERTON HARBOR' },
    { 'label': 'ALLOWAY RIVER', 'value': 'ALLOWAY RIVER' },
    { 'label': 'ALSEA BAY', 'value': 'ALSEA BAY' },
    { 'label': 'ALSEA RIVER', 'value': 'ALSEA RIVER' },
    { 'label': 'ALTAMAHA RIVER', 'value': 'ALTAMAHA RIVER' },
    {
        'label': 'ALTANTIC DEEP WATER ACCESS',
        'value': 'ALTANTIC DEEP WATER ACCESS'
    },
    {
        'label': 'ALTANTIC DEEP WATER SPUR',
        'value': 'ALTANTIC DEEP WATER SPUR'
    },
    { 'label': 'AMBLER RIVER', 'value': 'AMBLER RIVER' },
    { 'label': 'AMBROSE CHANNEL', 'value': 'AMBROSE CHANNEL' },
    { 'label': 'AMITE RIVER', 'value': 'AMITE RIVER' },
    {
        'label': 'ANACORTES NAVIGATION CHANNEL',
        'value': 'ANACORTES NAVIGATION CHANNEL'
    },
    { 'label': 'ANACOSTIA RIVER', 'value': 'ANACOSTIA RIVER' },
    { 'label': 'ANAHEIM BAY HARBOR', 'value': 'ANAHEIM BAY HARBOR' },
    { 'label': 'ANAHUAC CHANNEL', 'value': 'ANAHUAC CHANNEL' },
    { 'label': 'ANCLOTE RIVER', 'value': 'ANCLOTE RIVER' },
    { 'label': 'ANDREWS RIVER', 'value': 'ANDREWS RIVER' },
    { 'label': 'ANNAPOLIS HARBOR', 'value': 'ANNAPOLIS HARBOR' },
    { 'label': 'ANNISQUAM RIVER', 'value': 'ANNISQUAM RIVER' },
    { 'label': 'ANTILLES HARBOR', 'value': 'ANTILLES HARBOR' },
    { 'label': 'ANTIPOISON CREEK', 'value': 'ANTIPOISON CREEK' },
    { 'label': 'APALACHEE BAY', 'value': 'APALACHEE BAY' },
    { 'label': 'APALACHICOLA BAY', 'value': 'APALACHICOLA BAY' },
    { 'label': 'APALACHICOLA RIVER', 'value': 'APALACHICOLA RIVER' },
    { 'label': 'APE HOLE CREEK', 'value': 'APE HOLE CREEK' },
    { 'label': 'APPOMATTOX RIVER', 'value': 'APPOMATTOX RIVER' },
    { 'label': 'APPONAUG COVE', 'value': 'APPONAUG COVE' },
    { 'label': 'APPOQUINIMINK RIVER', 'value': 'APPOQUINIMINK RIVER' },
    { 'label': 'APRA HARBOR', 'value': 'APRA HARBOR' },
    { 'label': 'AQUIA CREEK', 'value': 'AQUIA CREEK' },
    { 'label': 'ARANSAS CHANNEL', 'value': 'ARANSAS CHANNEL' },
    { 'label': 'ARANSAS PASS', 'value': 'ARANSAS PASS' },
    { 'label': 'ARCATA BAY', 'value': 'ARCATA BAY' },
    { 'label': 'ARCTIC OCEAN', 'value': 'ARCTIC OCEAN' },
    { 'label': 'ARCTIC OCEAN ACCESS', 'value': 'ARCTIC OCEAN ACCESS' },
    { 'label': 'ARCTIC OCEAN SPUR', 'value': 'ARCTIC OCEAN SPUR' },
    { 'label': 'ARCTRIC OCEAN', 'value': 'ARCTRIC OCEAN' },
    { 'label': 'ARECIBO HARBOR', 'value': 'ARECIBO HARBOR' },
    { 'label': 'AREYS COVE', 'value': 'AREYS COVE' },
    { 'label': 'ARKANSAS BAY', 'value': 'ARKANSAS BAY' },
    { 'label': 'ARKANSAS RIVER', 'value': 'ARKANSAS RIVER' },
    {
        'label': 'ARLINGTON-GARROWS  BEND',
        'value': 'ARLINGTON-GARROWS  BEND'
    },
    { 'label': 'ARROYO COLORADO', 'value': 'ARROYO COLORADO' },
    { 'label': 'ARROYO HARBOR', 'value': 'ARROYO HARBOR' },
    { 'label': 'ARTHUR KILL', 'value': 'ARTHUR KILL' },
    { 'label': 'ARTHUR KILL ACCESS', 'value': 'ARTHUR KILL ACCESS' },
    { 'label': 'ASHEPOO RIVER', 'value': 'ASHEPOO RIVER' },
    { 'label': 'ASHLEY RIVER', 'value': 'ASHLEY RIVER' },
    { 'label': 'ATCHAFALAYA', 'value': 'ATCHAFALAYA' },
    { 'label': 'ATCHAFALAYA BAY', 'value': 'ATCHAFALAYA BAY' },
    { 'label': 'ATCHAFALAYA RIVER', 'value': 'ATCHAFALAYA RIVER' },
    {
        'label': 'ATLANTIC DEEP WATER ACCESS',
        'value': 'ATLANTIC DEEP WATER ACCESS'
    },
    {
        'label': 'ATLANTIC DEEP WATER SPINE',
        'value': 'ATLANTIC DEEP WATER SPINE'
    },
    {
        'label': 'ATLANTIC DEEP WATER SPUR',
        'value': 'ATLANTIC DEEP WATER SPUR'
    },
    {
        'label': 'ATLANTIC INTRACOASTAL ACCESS',
        'value': 'ATLANTIC INTRACOASTAL ACCESS'
    },
    {
        'label': 'ATLANTIC INTRACOASTAL WATERWAY',
        'value': 'ATLANTIC INTRACOASTAL WATERWAY'
    },
    {
        'label': 'ATLANTIC SHALLOW WATER ACCESS',
        'value': 'ATLANTIC SHALLOW WATER ACCESS'
    },
    {
        'label': 'ATLANTIC SHALLOW WATER SPUR',
        'value': 'ATLANTIC SHALLOW WATER SPUR'
    },
    { 'label': 'ATLANTIC-PANAMA ACCESS', 'value': 'ATLANTIC-PANAMA ACCESS' },
    {
        'label': 'ATLANTIC-PUERTO RICO ACCESS',
        'value': 'ATLANTIC-PUERTO RICO ACCESS'
    },
    { 'label': 'AUAU CHANNEL', 'value': 'AUAU CHANNEL' },
    { 'label': 'AUAU CHANNEL ACCESS', 'value': 'AUAU CHANNEL ACCESS' },
    { 'label': 'AVERY CANAL', 'value': 'AVERY CANAL' },
    { 'label': 'AWAELE HARBOR', 'value': 'AWAELE HARBOR' },
    { 'label': 'BACK CHANNEL', 'value': 'BACK CHANNEL' },
    { 'label': 'BACK CREEK', 'value': 'BACK CREEK' },
    { 'label': 'BACK RIVER', 'value': 'BACK RIVER' },
    { 'label': 'BAGADUCE RIVER', 'value': 'BAGADUCE RIVER' },
    { 'label': 'BAKER BAY', 'value': 'BAKER BAY' },
    { 'label': 'BAKERS HAULOVER INLET', 'value': 'BAKERS HAULOVER INLET' },
    { 'label': 'BANANA RIVER', 'value': 'BANANA RIVER' },
    { 'label': 'BANANA RIVER ACCESS', 'value': 'BANANA RIVER ACCESS' },
    {
        'label': 'BAPTISTE COLLETTE BAYOU',
        'value': 'BAPTISTE COLLETTE BAYOU'
    },
    { 'label': 'BAR HARBOR', 'value': 'BAR HARBOR' },
    { 'label': 'BARALOF BAY', 'value': 'BARALOF BAY' },
    { 'label': 'BARATARIA BAY', 'value': 'BARATARIA BAY' },
    { 'label': 'BARATARIA WATERWAY', 'value': 'BARATARIA WATERWAY' },
    { 'label': 'BARBERS POINT CHANNEL', 'value': 'BARBERS POINT CHANNEL' },
    { 'label': 'BARBERS POINT HARBOR', 'value': 'BARBERS POINT HARBOR' },
    { 'label': 'BARBOURS CUT', 'value': 'BARBOURS CUT' },
    { 'label': 'BARDEN INLET', 'value': 'BARDEN INLET' },
    { 'label': 'BARKLEY CANAL', 'value': 'BARKLEY CANAL' },
    { 'label': 'BARNEGAT INLET', 'value': 'BARNEGAT INLET' },
    { 'label': 'BARNSTABLE HARBOR', 'value': 'BARNSTABLE HARBOR' },
    { 'label': 'BASS HARBOR', 'value': 'BASS HARBOR' },
    { 'label': 'BASS RIVER', 'value': 'BASS RIVER' },
    { 'label': 'BASTROP BAYOU', 'value': 'BASTROP BAYOU' },
    { 'label': 'BATH CREEK', 'value': 'BATH CREEK' },
    {
        'label': 'BATON ROUGE HARBOR DEVILS SWAMP,LA',
        'value': 'BATON ROUGE HARBOR DEVILS SWAMP,LA'
    },
    { 'label': 'BATTERY CREEK', 'value': 'BATTERY CREEK' },
    { 'label': 'BAY RIDGE CHANNEL', 'value': 'BAY RIDGE CHANNEL' },
    { 'label': 'BAY RIVER', 'value': 'BAY RIVER' },
    { 'label': 'BAYOU BONFOUCA', 'value': 'BAYOU BONFOUCA' },
    { 'label': 'BAYOU CASOTTE', 'value': 'BAYOU CASOTTE' },
    { 'label': 'BAYOU CHICO', 'value': 'BAYOU CHICO' },
    { 'label': 'BAYOU DUPRE', 'value': 'BAYOU DUPRE' },
    { 'label': 'BAYOU GALERE', 'value': 'BAYOU GALERE' },
    { 'label': 'BAYOU GRANDE CAILLOU', 'value': 'BAYOU GRANDE CAILLOU' },
    { 'label': 'BAYOU GROSSE TETE', 'value': 'BAYOU GROSSE TETE' },
    { 'label': 'BAYOU LA BATRE', 'value': 'BAYOU LA BATRE' },
    { 'label': 'BAYOU LA LOUTRE', 'value': 'BAYOU LA LOUTRE' },
    { 'label': 'BAYOU LAFOURCHE', 'value': 'BAYOU LAFOURCHE' },
    { 'label': 'BAYOU LECARPE', 'value': 'BAYOU LECARPE' },
    { 'label': 'BAYOU LITTLE CAILLOU', 'value': 'BAYOU LITTLE CAILLOU' },
    { 'label': 'BAYOU PETIT CAILLOU', 'value': 'BAYOU PETIT CAILLOU' },
    { 'label': 'BAYOU PLAQUEMINE BRULE', 'value': 'BAYOU PLAQUEMINE BRULE' },
    { 'label': 'BAYOU QUEUE DE TORTUE', 'value': 'BAYOU QUEUE DE TORTUE' },
    { 'label': 'BAYOU SCHAFFER', 'value': 'BAYOU SCHAFFER' },
    { 'label': 'BAYOU SEGNETTE', 'value': 'BAYOU SEGNETTE' },
    { 'label': 'BAYOU ST. MALO', 'value': 'BAYOU ST. MALO' },
    { 'label': 'BAYOU TECHE', 'value': 'BAYOU TECHE' },
    { 'label': 'BAYOU TERREBONNE', 'value': 'BAYOU TERREBONNE' },
    { 'label': 'BAYOU YSCLOSKEY', 'value': 'BAYOU YSCLOSKEY' },
    { 'label': 'BAYPORT CHANNEL', 'value': 'BAYPORT CHANNEL' },
    { 'label': 'BAYSWATER CHANNEL', 'value': 'BAYSWATER CHANNEL' },
    { 'label': 'BEACH CREEK', 'value': 'BEACH CREEK' },
    { 'label': 'BEACH HAVEN INLET', 'value': 'BEACH HAVEN INLET' },
    { 'label': 'BEALS HARBOR', 'value': 'BEALS HARBOR' },
    { 'label': 'BEAUFORT INLET', 'value': 'BEAUFORT INLET' },
    { 'label': 'BEAUFORT INLET ACCESS', 'value': 'BEAUFORT INLET ACCESS' },
    { 'label': 'BECHEVIN BAY', 'value': 'BECHEVIN BAY' },
    { 'label': 'BEHM CANAL', 'value': 'BEHM CANAL' },
    { 'label': 'BELFAST HARBOR', 'value': 'BELFAST HARBOR' },
    { 'label': 'BELKOFSKI BAY', 'value': 'BELKOFSKI BAY' },
    { 'label': 'BELLAMY RIVER', 'value': 'BELLAMY RIVER' },
    { 'label': 'BELLINGHAM BAY', 'value': 'BELLINGHAM BAY' },
    { 'label': 'BELMONT SLOUGH', 'value': 'BELMONT SLOUGH' },
    { 'label': 'BENJAMIN RIVER', 'value': 'BENJAMIN RIVER' },
    { 'label': 'BENNETTS CREEK', 'value': 'BENNETTS CREEK' },
    { 'label': 'BERESFORD CREEK', 'value': 'BERESFORD CREEK' },
    { 'label': 'BERING SEA', 'value': 'BERING SEA' },
    { 'label': 'BERING SEA ACCESS', 'value': 'BERING SEA ACCESS' },
    { 'label': 'BERING SEA SPUR', 'value': 'BERING SEA SPUR' },
    { 'label': 'BERRY COVE', 'value': 'BERRY COVE' },
    { 'label': 'BETTERTON HARBOR', 'value': 'BETTERTON HARBOR' },
    { 'label': 'BIG ANNEMESSEX RIVER', 'value': 'BIG ANNEMESSEX RIVER' },
    { 'label': 'BIG BEND CHANNEL', 'value': 'BIG BEND CHANNEL' },
    {
        'label': 'BIG FOOT SLOUGH CHANNEL',
        'value': 'BIG FOOT SLOUGH CHANNEL'
    },
    { 'label': 'BIG SANDY RIVER', 'value': 'BIG SANDY RIVER' },
    { 'label': 'BIG SUNFLOWER RIVER', 'value': 'BIG SUNFLOWER RIVER' },
    { 'label': 'BIG THOROFARE', 'value': 'BIG THOROFARE' },
    { 'label': 'BIG TIMBER CREEK', 'value': 'BIG TIMBER CREEK' },
    { 'label': 'BILOXI BAY', 'value': 'BILOXI BAY' },
    { 'label': 'BIRCH BAY', 'value': 'BIRCH BAY' },
    { 'label': 'BIRCH HARBOR', 'value': 'BIRCH HARBOR' },
    { 'label': 'BIRD CREEK', 'value': 'BIRD CREEK' },
    { 'label': 'BISSEL COVE', 'value': 'BISSEL COVE' },
    { 'label': 'BLACK RIVER', 'value': 'BLACK RIVER' },
    { 'label': 'BLACK ROCK', 'value': 'BLACK ROCK' },
    { 'label': 'BLACK ROCK HARBOR', 'value': 'BLACK ROCK HARBOR' },
    { 'label': 'BLACK WARRIOR RIVER', 'value': 'BLACK WARRIOR RIVER' },
    { 'label': 'BLACKWALNUT COVE', 'value': 'BLACKWALNUT COVE' },
    { 'label': 'BLACKWATER RIVER', 'value': 'BLACKWATER RIVER' },
    { 'label': 'BLAINE HARBOR', 'value': 'BLAINE HARBOR' },
    { 'label': 'BLAIR CHANNEL', 'value': 'BLAIR CHANNEL' },
    { 'label': 'BLOCK ISLAND SOUND', 'value': 'BLOCK ISLAND SOUND' },
    { 'label': 'BLUE HILL BAY', 'value': 'BLUE HILL BAY' },
    { 'label': 'BLUE HILL HARBOR', 'value': 'BLUE HILL HARBOR' },
    { 'label': 'BLUFF CREEK', 'value': 'BLUFF CREEK' },
    { 'label': 'BOCA DE QUADRA HARBOR', 'value': 'BOCA DE QUADRA HARBOR' },
    { 'label': 'BODEGA BAY', 'value': 'BODEGA BAY' },
    { 'label': 'BOGGY BAYOU', 'value': 'BOGGY BAYOU' },
    { 'label': 'BOGUE INLET', 'value': 'BOGUE INLET' },
    { 'label': 'BOHEMIA RIVER', 'value': 'BOHEMIA RIVER' },
    { 'label': 'BOLINAS BAY', 'value': 'BOLINAS BAY' },
    { 'label': 'BOLIVAR ROADS CHANNEL', 'value': 'BOLIVAR ROADS CHANNEL' },
    { 'label': 'BON SECOUR RIVER', 'value': 'BON SECOUR RIVER' },
    { 'label': 'BOOTH BAY', 'value': 'BOOTH BAY' },
    { 'label': 'BOOTHBAY HARBOR', 'value': 'BOOTHBAY HARBOR' },
    { 'label': 'BOSTON HARBOR', 'value': 'BOSTON HARBOR' },
    { 'label': 'BOUNDARY BAY', 'value': 'BOUNDARY BAY' },
    { 'label': 'BOUNDARY PASS', 'value': 'BOUNDARY PASS' },
    { 'label': 'BOURNES POND', 'value': 'BOURNES POND' },
    { 'label': 'BRADFIELD CANAL', 'value': 'BRADFIELD CANAL' },
    { 'label': 'BRANDYWINE RIVER', 'value': 'BRANDYWINE RIVER' },
    { 'label': 'BRANFORD HARBOR', 'value': 'BRANFORD HARBOR' },
    { 'label': 'BRAZOS HARBOR CHANNEL', 'value': 'BRAZOS HARBOR CHANNEL' },
  { 'label': 'BRAZOS RIVER', 'value': 'BRAZOS RIVER' },
  { 'label': 'BRETON BAY', 'value': 'BRETON BAY' },
  { 'label': 'BRETON SOUND', 'value': 'BRETON SOUND' },
  { 'label': 'BRICE CREEK', 'value': 'BRICE CREEK' },
  { 'label': 'BRIDGEPORT HARBOR', 'value': 'BRIDGEPORT HARBOR' },
  { 'label': 'BRIGANTINE INLET', 'value': 'BRIGANTINE INLET' },
  { 'label': 'BRISTOL BAY', 'value': 'BRISTOL BAY' },
  { 'label': 'BRISTOL HARBOR', 'value': 'BRISTOL HARBOR' },
  { 'label': 'BROAD BAY', 'value': 'BROAD BAY' },
  { 'label': 'BROAD CHANNEL', 'value': 'BROAD CHANNEL' },
  { 'label': 'BROAD CREEK', 'value': 'BROAD CREEK' },
  { 'label': 'BROAD CREEK RIVER', 'value': 'BROAD CREEK RIVER' },
  { 'label': 'BROAD RIVER', 'value': 'BROAD RIVER' },
  { 'label': 'BROAD SOUND', 'value': 'BROAD SOUND' },
  { 'label': 'BROADKILL RIVER', 'value': 'BROADKILL RIVER' },
  { 'label': 'BROADWATER CREEK', 'value': 'BROADWATER CREEK' },
  { 'label': 'BRONX RIVER', 'value': 'BRONX RIVER' },
  { 'label': 'BROWNS CREEK', 'value': 'BROWNS CREEK' },
  {
    'label': 'BROWNSVILLE SHIP CHANNEL',
    'value': 'BROWNSVILLE SHIP CHANNEL'
  },
  { 'label': 'BRUNSWICK RIVER', 'value': 'BRUNSWICK RIVER' },
  { 'label': 'BUCARELI BAY', 'value': 'BUCARELI BAY' },
  { 'label': 'BUCKLAND RIVER', 'value': 'BUCKLAND RIVER' },
  { 'label': 'BUCKS HARBOR', 'value': 'BUCKS HARBOR' },
  { 'label': 'BUDD INLET', 'value': 'BUDD INLET' },
  { 'label': 'BUFFALO BAYOU', 'value': 'BUFFALO BAYOU' },
  { 'label': 'BULKHEAD SHOAL CHANNEL', 'value': 'BULKHEAD SHOAL CHANNEL' },
  {
    'label': 'BULKHEAD SHOAL CHANNEL ACCESS',
    'value': 'BULKHEAD SHOAL CHANNEL ACCESS'
  },
  { 'label': 'BULL RIVER', 'value': 'BULL RIVER' },
  { 'label': 'BULLOCKS POINT COVE', 'value': 'BULLOCKS POINT COVE' },
  { 'label': 'BUNKER HARBOR', 'value': 'BUNKER HARBOR' },
  { 'label': 'BURLINGTON HARBOR', 'value': 'BURLINGTON HARBOR' },
  { 'label': 'BURWELL BAY', 'value': 'BURWELL BAY' },
  { 'label': 'BUSH RIVER', 'value': 'BUSH RIVER' },
  { 'label': 'BUTTERMILK CHANNEL', 'value': 'BUTTERMILK CHANNEL' },
  { 'label': 'BUZZARD BAY', 'value': 'BUZZARD BAY' },
  { 'label': 'BUZZARDS BAY', 'value': 'BUZZARDS BAY' },
  { 'label': 'BYRAM HARBOR', 'value': 'BYRAM HARBOR' },
  { 'label': 'BYRAM RIVER', 'value': 'BYRAM RIVER' },
  { 'label': 'CADET BAYOU', 'value': 'CADET BAYOU' },
  { 'label': 'CADLE CREEK', 'value': 'CADLE CREEK' },
  { 'label': 'CALCASIEU LAKE', 'value': 'CALCASIEU LAKE' },
  { 'label': 'CALCASIEU PASS', 'value': 'CALCASIEU PASS' },
  { 'label': 'CALCASIEU RIVER', 'value': 'CALCASIEU RIVER' },
  { 'label': 'CALCASIEU RIVER ACCESS', 'value': 'CALCASIEU RIVER ACCESS' },
  {
    'label': 'CALCASIEU RIVER AT COON ISLAND',
    'value': 'CALCASIEU RIVER AT COON ISLAND'
  },
  {
    'label': 'CALCASIEU RIVER AT DEVILS ELBOW',
    'value': 'CALCASIEU RIVER AT DEVILS ELBOW'
  },
  { 'label': 'CALIBOGUE SOUND', 'value': 'CALIBOGUE SOUND' },
  { 'label': 'CALICO CREEK', 'value': 'CALICO CREEK' },
  { 'label': 'CALUMET RIVER', 'value': 'CALUMET RIVER' },
  { 'label': 'CALUMET-SAG CHANNEL', 'value': 'CALUMET-SAG CHANNEL' },
  { 'label': 'CAMBRIDGE HARBOR', 'value': 'CAMBRIDGE HARBOR' },
  { 'label': 'CAMDEN HARBOR', 'value': 'CAMDEN HARBOR' },
  { 'label': 'CAMP PENDLETON HARBOR', 'value': 'CAMP PENDLETON HARBOR' },
  { 'label': 'CANAPITSIT CHANNEL', 'value': 'CANAPITSIT CHANNEL' },
  { 'label': 'CANAVERAL BARGE CANAL', 'value': 'CANAVERAL BARGE CANAL' },
  { 'label': 'CANAVERAL HARBOR', 'value': 'CANAVERAL HARBOR' },
  { 'label': 'CAPE CHARLES CHANNEL', 'value': 'CAPE CHARLES CHANNEL' },
  {
    'label': 'CAPE CHARLES CITY HARBOR',
    'value': 'CAPE CHARLES CITY HARBOR'
  },
  { 'label': 'CAPE COD BAY', 'value': 'CAPE COD BAY' },
  { 'label': 'CAPE COD CANAL', 'value': 'CAPE COD CANAL' },
  { 'label': 'CAPE FEAR RIVER', 'value': 'CAPE FEAR RIVER' },
  { 'label': 'CAPE MAY CANAL', 'value': 'CAPE MAY CANAL' },
  { 'label': 'CAPE NEDDICK HARBOR', 'value': 'CAPE NEDDICK HARBOR' },
  { 'label': 'CAPE POGE BAY', 'value': 'CAPE POGE BAY' },
  { 'label': 'CAPE PORPOISE HARBOR', 'value': 'CAPE PORPOISE HARBOR' },
  { 'label': 'CAPITAN HARBOR', 'value': 'CAPITAN HARBOR' },
  { 'label': 'CAPTAIN PASS', 'value': 'CAPTAIN PASS' },
  { 'label': 'CARIBBEAN SEA', 'value': 'CARIBBEAN SEA' },
  { 'label': 'CARIBBEAN SEA ACCESS', 'value': 'CARIBBEAN SEA ACCESS' },
  { 'label': 'CARIBBEAN SEA SPUR', 'value': 'CARIBBEAN SEA SPUR' },
  { 'label': 'CARPENTERS BAYOU', 'value': 'CARPENTERS BAYOU' },
  { 'label': 'CARPINTERIA HARBOR', 'value': 'CARPINTERIA HARBOR' },
  { 'label': 'CARQUINEZ STRAIT', 'value': 'CARQUINEZ STRAIT' },
  {
    'label': 'CARQUINEZ STRAIT ACCESS',
    'value': 'CARQUINEZ STRAIT ACCESS'
  },
  { 'label': 'CARR INLET', 'value': 'CARR INLET' },
  { 'label': 'CARRABELLE RIVER', 'value': 'CARRABELLE RIVER' },
  { 'label': 'CARRIBBEAN SEA', 'value': 'CARRIBBEAN SEA' },
  { 'label': 'CARRIBBEAN SEA SPUR', 'value': 'CARRIBBEAN SEA SPUR' },
  { 'label': 'CARTERS CREEK', 'value': 'CARTERS CREEK' },
  { 'label': 'CARVARS HARBOR', 'value': 'CARVARS HARBOR' },
  { 'label': 'CASCO BAY', 'value': 'CASCO BAY' },
  { 'label': 'CASE INLET', 'value': 'CASE INLET' },
  { 'label': 'CASHIE RIVER', 'value': 'CASHIE RIVER' },
  { 'label': 'CASTINE HARBOR', 'value': 'CASTINE HARBOR' },
  { 'label': 'CATHANCE RIVER', 'value': 'CATHANCE RIVER' },
  { 'label': 'CATS POINT CHANNEL', 'value': 'CATS POINT CHANNEL' },
  { 'label': 'CAUCAS CHANNEL', 'value': 'CAUCAS CHANNEL' },
  { 'label': 'CAYUGA-SENECA CANAL', 'value': 'CAYUGA-SENECA CANAL' },
  { 'label': 'CEDAR BAY', 'value': 'CEDAR BAY' },
  { 'label': 'CEDAR BAYOU', 'value': 'CEDAR BAYOU' },
  { 'label': 'CEDAR CREEK', 'value': 'CEDAR CREEK' },
  { 'label': 'CEDAR KEYS', 'value': 'CEDAR KEYS' },
  { 'label': 'CENTER HARBOR', 'value': 'CENTER HARBOR' },
  { 'label': 'CERRITOS CHANNEL', 'value': 'CERRITOS CHANNEL' },
  { 'label': 'CHAMPLAIN CANAL', 'value': 'CHAMPLAIN CANAL' },
  { 'label': 'CHANDLER BAY', 'value': 'CHANDLER BAY' },
  { 'label': 'CHANDLER RIVER', 'value': 'CHANDLER RIVER' },
  { 'label': 'CHANNEL ISLANDS HARBOR', 'value': 'CHANNEL ISLANDS HARBOR' },
  {
    'label': 'CHARENTON NAVIGATION CANAL',
    'value': 'CHARENTON NAVIGATION CANAL'
  },
  { 'label': 'CHARLES RIVER', 'value': 'CHARLES RIVER' },
  { 'label': 'CHARLESTON HARBOR', 'value': 'CHARLESTON HARBOR' },
  {
    'label': 'CHARLESTON HARBOR ENTRANCE CHANNEL',
    'value': 'CHARLESTON HARBOR ENTRANCE CHANNEL'
  },
  { 'label': 'CHARLOTTE HARBOR', 'value': 'CHARLOTTE HARBOR' },
  { 'label': 'CHATHAM HARBOR', 'value': 'CHATHAM HARBOR' },
  { 'label': 'CHATHAM STRAIT', 'value': 'CHATHAM STRAIT' },
  { 'label': 'CHATTAHOOCHEE RIVER', 'value': 'CHATTAHOOCHEE RIVER' },
  { 'label': 'CHECHESSEE RIVER', 'value': 'CHECHESSEE RIVER' },
  { 'label': 'CHEESEQUAKE CREEK', 'value': 'CHEESEQUAKE CREEK' },
  { 'label': 'CHEHALIS RIVER', 'value': 'CHEHALIS RIVER' },
  { 'label': 'CHELSEA RIVER', 'value': 'CHELSEA RIVER' },
  { 'label': 'CHENA RIVER', 'value': 'CHENA RIVER' },
  { 'label': 'CHERRYSTONE INLE', 'value': 'CHERRYSTONE INLE' },
  {
    'label': 'CHESAPEAKE AND DELAWARE CANAL',
    'value': 'CHESAPEAKE AND DELAWARE CANAL'
  },
  { 'label': 'CHESAPEAKE BAY', 'value': 'CHESAPEAKE BAY' },
  { 'label': 'CHESSCONESSEX CREEK', 'value': 'CHESSCONESSEX CREEK' },
  { 'label': 'CHESTER RIVER', 'value': 'CHESTER RIVER' },
  { 'label': 'CHETCO RIVER', 'value': 'CHETCO RIVER' },
  { 'label': 'CHICAGO RIVER', 'value': 'CHICAGO RIVER' },
  {
    'label': 'CHICAGO RIVER ENTRANCE CHNL, IL',
    'value': 'CHICAGO RIVER ENTRANCE CHNL, IL'
  },
  { 'label': 'CHICAGO SHIP CANAL', 'value': 'CHICAGO SHIP CANAL' },
  { 'label': 'CHICAMUXEN CREEK', 'value': 'CHICAMUXEN CREEK' },
  { 'label': 'CHICKASAW CREEK', 'value': 'CHICKASAW CREEK' },
  { 'label': 'CHIGNIK BAY', 'value': 'CHIGNIK BAY' },
  { 'label': 'CHILKOOT INLET', 'value': 'CHILKOOT INLET' },
  { 'label': 'CHIMNEY CREEK', 'value': 'CHIMNEY CREEK' },
  { 'label': 'CHINCOTEAGUE HARBOR', 'value': 'CHINCOTEAGUE HARBOR' },
  { 'label': 'CHINOOK HARBOR', 'value': 'CHINOOK HARBOR' },
  { 'label': 'CHIPOLA RIVER', 'value': 'CHIPOLA RIVER' },
  { 'label': 'CHOCOLATE BAY', 'value': 'CHOCOLATE BAY' },
  { 'label': 'CHOCOLATE BAYOU', 'value': 'CHOCOLATE BAYOU' },
  {
    'label': 'CHOCTAWHATCHEE BAY ENTRANCE',
    'value': 'CHOCTAWHATCHEE BAY ENTRANCE'
  },
  { 'label': 'CHOCTAWHATCHEE RIVER', 'value': 'CHOCTAWHATCHEE RIVER' },
  { 'label': 'CHOPTANK RIVER', 'value': 'CHOPTANK RIVER' },
  { 'label': 'CHOPTANK RIVER ACCESS', 'value': 'CHOPTANK RIVER ACCESS' },
  { 'label': 'CHOWAN RIVER', 'value': 'CHOWAN RIVER' },
  { 'label': 'CHRISTIANSTED HARBOR', 'value': 'CHRISTIANSTED HARBOR' },
  { 'label': 'CHRISTINA RIVER', 'value': 'CHRISTINA RIVER' },
  { 'label': 'CHRISTMAS COVE', 'value': 'CHRISTMAS COVE' },
  { 'label': 'CHUCKATUCK CREEK', 'value': 'CHUCKATUCK CREEK' },
  { 'label': 'CLAIBORNE HARBOR', 'value': 'CLAIBORNE HARBOR' },
  { 'label': 'CLARENCE STRAIT', 'value': 'CLARENCE STRAIT' },
  { 'label': 'CLARENCE STRAIT ACCESS', 'value': 'CLARENCE STRAIT ACCESS' },
  { 'label': 'CLATSKANIE RIVER', 'value': 'CLATSKANIE RIVER' },
  { 'label': 'CLEAR CREEK', 'value': 'CLEAR CREEK' },
  { 'label': 'CLEAR LAKE', 'value': 'CLEAR LAKE' },
  {
    'label': 'CLEARWATER BEACH YACHT HARBOR',
    'value': 'CLEARWATER BEACH YACHT HARBOR'
  },
  { 'label': 'CLEARWATER PASS', 'value': 'CLEARWATER PASS' },
  { 'label': 'CLEARWATER RIVER', 'value': 'CLEARWATER RIVER' },
  { 'label': 'CLINCH RIVER', 'value': 'CLINCH RIVER' },
  { 'label': 'CLINTON HARBOR', 'value': 'CLINTON HARBOR' },
  { 'label': 'CLOUTER CREEK', 'value': 'CLOUTER CREEK' },
  {
    'label': 'CLUBFOOT AND HARLOWE CANAL',
    'value': 'CLUBFOOT AND HARLOWE CANAL'
  },
  { 'label': 'COAL PIER CHANNEL', 'value': 'COAL PIER CHANNEL' },
  { 'label': 'COAN RIVER', 'value': 'COAN RIVER' },
  { 'label': 'COASTERS HARBOR', 'value': 'COASTERS HARBOR' },
  { 'label': 'COBSCOOK BAY', 'value': 'COBSCOOK BAY' },
  { 'label': 'COCHECO RIVER', 'value': 'COCHECO RIVER' },
  { 'label': 'COCKRELLS CREEK', 'value': 'COCKRELLS CREEK' },
  { 'label': 'CODDINGTON COVE', 'value': 'CODDINGTON COVE' },
  { 'label': 'COHANSEY RIVER', 'value': 'COHANSEY RIVER' },
  { 'label': 'COHASSET HARBOR', 'value': 'COHASSET HARBOR' },
  { 'label': 'COLD BAY', 'value': 'COLD BAY' },
  { 'label': 'COLD SPRING HARBOR', 'value': 'COLD SPRING HARBOR' },
  { 'label': 'COLD SPRING INLET', 'value': 'COLD SPRING INLET' },
  { 'label': 'COLLETON RIVER', 'value': 'COLLETON RIVER' },
  { 'label': 'COLORADO RIVER', 'value': 'COLORADO RIVER' },
  { 'label': 'COLUMBIA RIVER', 'value': 'COLUMBIA RIVER' },
  { 'label': 'COLUMBIA SLOUGH', 'value': 'COLUMBIA SLOUGH' },
  { 'label': 'COLVOS PASSAGE', 'value': 'COLVOS PASSAGE' },
  { 'label': 'COMMENCEMENT BAY', 'value': 'COMMENCEMENT BAY' },
  { 'label': 'CONEY ISLAND CREEK', 'value': 'CONEY ISLAND CREEK' },
  { 'label': 'CONGAREE RIVER', 'value': 'CONGAREE RIVER' },
  { 'label': 'CONNECTICUT RIVER', 'value': 'CONNECTICUT RIVER' },
  { 'label': 'CONSOLIDATED SLIP', 'value': 'CONSOLIDATED SLIP' },
  { 'label': 'CONTENTNEA CREEK', 'value': 'CONTENTNEA CREEK' },
  { 'label': 'COOK INLET', 'value': 'COOK INLET' },
  { 'label': 'COOPER RIVER', 'value': 'COOPER RIVER' },
  { 'label': 'COOPER RIVER ACCESS', 'value': 'COOPER RIVER ACCESS' },
  { 'label': 'COOS BAY', 'value': 'COOS BAY' },
  { 'label': 'COOS RIVER', 'value': 'COOS RIVER' },
  { 'label': 'COOSA RIVER', 'value': 'COOSA RIVER' },
  { 'label': 'COPALIS RIVER', 'value': 'COPALIS RIVER' },
  { 'label': 'COQUILLE RIVER', 'value': 'COQUILLE RIVER' },
  { 'label': 'CORDOVA CHANNEL', 'value': 'CORDOVA CHANNEL' },
  { 'label': 'CORDOVA HARBOR', 'value': 'CORDOVA HARBOR' },
  { 'label': 'CORE SOUND', 'value': 'CORE SOUND' },
  { 'label': 'COREA HARBOR', 'value': 'COREA HARBOR' },
  { 'label': 'CORN BROWN HARBOR', 'value': 'CORN BROWN HARBOR' },
  { 'label': 'CORPUS CHRISTI BAY', 'value': 'CORPUS CHRISTI BAY' },
  {
    'label': 'CORPUS CHRISTI CHANNEL ACCESS',
    'value': 'CORPUS CHRISTI CHANNEL ACCESS'
  },
  { 'label': 'CORPUS CHRISTI HARBOR', 'value': 'CORPUS CHRISTI HARBOR' },
  {
    'label': 'CORPUS CHRISTI SHIP CHANNEL',
    'value': 'CORPUS CHRISTI SHIP CHANNEL'
  },
  {
    'label': 'CORPUS CHRISTI SHIP CHANNEL ACCESS',
    'value': 'CORPUS CHRISTI SHIP CHANNEL ACCESS'
  },
  { 'label': 'CORROTOMAN RIVER', 'value': 'CORROTOMAN RIVER' },
  { 'label': 'CORSICA RIVER', 'value': 'CORSICA RIVER' },
  { 'label': 'CORSONS INLET', 'value': 'CORSONS INLET' },
  { 'label': 'COS COB HARBOR', 'value': 'COS COB HARBOR' },
  { 'label': 'COTUIT HARBOR', 'value': 'COTUIT HARBOR' },
  { 'label': 'COURTENAY CHANNEL', 'value': 'COURTENAY CHANNEL' },
  { 'label': 'COW BAYOU CHANNEL', 'value': 'COW BAYOU CHANNEL' },
  { 'label': 'COW COVE', 'value': 'COW COVE' },
  { 'label': 'COWLITZ RIVER', 'value': 'COWLITZ RIVER' },
  { 'label': 'COYOTE CREEK', 'value': 'COYOTE CREEK' },
  { 'label': 'COYOTE HARBOR', 'value': 'COYOTE HARBOR' },
  { 'label': 'CRAB ALLEY BAY', 'value': 'CRAB ALLEY BAY' },
  { 'label': 'CRAB ALLEY CREEK', 'value': 'CRAB ALLEY CREEK' },
  { 'label': 'CRADDOCK CREEK', 'value': 'CRADDOCK CREEK' },
  { 'label': 'CRADLE COVE', 'value': 'CRADLE COVE' },
  { 'label': 'CRANBERRY HARBOR', 'value': 'CRANBERRY HARBOR' },
  { 'label': 'CRANES CREEK', 'value': 'CRANES CREEK' },
  { 'label': 'CRESCENT CITY HARBOR', 'value': 'CRESCENT CITY HARBOR' },
  { 'label': 'CRESCENT HARBOR', 'value': 'CRESCENT HARBOR' },
  { 'label': 'CRIEHAVEN HARBOR', 'value': 'CRIEHAVEN HARBOR' },
  { 'label': 'CROATON SOUND', 'value': 'CROATON SOUND' },
  {
    'label': 'CROSS-FLORIDA BARGE CANAL',
    'value': 'CROSS-FLORIDA BARGE CANAL'
  },
  { 'label': 'CRYSTAL RIVER', 'value': 'CRYSTAL RIVER' },
  { 'label': 'CUCKOLD CREEK', 'value': 'CUCKOLD CREEK' },
  { 'label': 'CUMBERLAND RIVER', 'value': 'CUMBERLAND RIVER' },
  { 'label': 'CUNDYS HARBOR', 'value': 'CUNDYS HARBOR' },
  { 'label': 'CUNNINGHILL COVE', 'value': 'CUNNINGHILL COVE' },
  { 'label': 'CURTIS BAY', 'value': 'CURTIS BAY' },
  { 'label': 'CUTCHOGUE HARBOR', 'value': 'CUTCHOGUE HARBOR' },
  { 'label': 'CUTLER HARBOR', 'value': 'CUTLER HARBOR' },
  { 'label': 'CUTTYHUNK HARBOR', 'value': 'CUTTYHUNK HARBOR' },
  { 'label': 'CUTTYHUNK POND', 'value': 'CUTTYHUNK POND' },
  { 'label': 'CUYLER HARBOR', 'value': 'CUYLER HARBOR' },
  { 'label': 'CYPRESS BAYOU', 'value': 'CYPRESS BAYOU' },
  { 'label': 'CYPRESS CREEK', 'value': 'CYPRESS CREEK' },
  { 'label': 'DABOB BAY', 'value': 'DABOB BAY' },
  { 'label': 'DAGGER CHANNEL', 'value': 'DAGGER CHANNEL' },
  { 'label': 'DAHL CREEK', 'value': 'DAHL CREEK' },
  { 'label': 'DAMARISCOTTA RIVER', 'value': 'DAMARISCOTTA RIVER' },
  { 'label': 'DAMES QUARTER CREEK', 'value': 'DAMES QUARTER CREEK' },
  { 'label': 'DANENMAN POINT', 'value': 'DANENMAN POINT' },
  { 'label': 'DANIA CUT OFF CANAL', 'value': 'DANIA CUT OFF CANAL' },
  { 'label': 'DANVERS RIVER', 'value': 'DANVERS RIVER' },
  { 'label': 'DARIEN RIVER', 'value': 'DARIEN RIVER' },
  { 'label': 'DARK HARBOR', 'value': 'DARK HARBOR' },
  { 'label': 'DAUGHERTY CREEK CANAL', 'value': 'DAUGHERTY CREEK CANAL' },
  { 'label': 'DAVIS CHANNEL', 'value': 'DAVIS CHANNEL' },
  { 'label': 'DAVIS CREEK', 'value': 'DAVIS CREEK' },
  { 'label': 'DAWSON CREEK', 'value': 'DAWSON CREEK' },
  { 'label': 'DEADMAN BAY', 'value': 'DEADMAN BAY' },
  { 'label': 'DECEPTION PASS', 'value': 'DECEPTION PASS' },
  { 'label': 'DECKER CHANNEL', 'value': 'DECKER CHANNEL' },
  { 'label': 'DEEP CREEK', 'value': 'DEEP CREEK' },
  { 'label': 'DEEP RIVER', 'value': 'DEEP RIVER' },
  { 'label': 'DEER RIVER', 'value': 'DEER RIVER' },
  {
    'label': 'DELAWARE AND RARITAN CANAL',
    'value': 'DELAWARE AND RARITAN CANAL'
  },
  { 'label': 'DELAWARE BAY', 'value': 'DELAWARE BAY' },
  {
    'label': 'DELAWARE INTRACOASTAL WATERWAY',
    'value': 'DELAWARE INTRACOASTAL WATERWAY'
  },
  { 'label': 'DELAWARE RIVER', 'value': 'DELAWARE RIVER' },
  { 'label': 'DELAWARE RIVER ACCESS', 'value': 'DELAWARE RIVER ACCESS' },
  { 'label': 'DELCAMBRE CANAL', 'value': 'DELCAMBRE CANAL' },
  { 'label': 'DENNIS CREEK', 'value': 'DENNIS CREEK' },
  { 'label': 'DEPOE BAY', 'value': 'DEPOE BAY' },
  { 'label': 'DETROIT RIVER', 'value': 'DETROIT RIVER' },
  { 'label': 'DICKINSON BAY', 'value': 'DICKINSON BAY' },
  { 'label': 'DICKINSON BAYOU', 'value': 'DICKINSON BAYOU' },
  { 'label': 'DILLINGHAM HARBOR', 'value': 'DILLINGHAM HARBOR' },
  { 'label': 'DIOMEDE ISLAND HARBOR', 'value': 'DIOMEDE ISLAND HARBOR' },
  { 'label': 'DISCOVERY BAY', 'value': 'DISCOVERY BAY' },
  { 'label': 'DIVIDING CREEK', 'value': 'DIVIDING CREEK' },
  { 'label': 'DIXON ENTRANCE', 'value': 'DIXON ENTRANCE' },
  { 'label': 'DOG RIVER', 'value': 'DOG RIVER' },
  { 'label': 'DORCHESTER BAY', 'value': 'DORCHESTER BAY' },
  { 'label': 'DOUBLE BAYOU', 'value': 'DOUBLE BAYOU' },
  { 'label': 'DOUBLE CREEK', 'value': 'DOUBLE CREEK' },
  { 'label': 'DOUGLAS HARBOR', 'value': 'DOUGLAS HARBOR' },
  { 'label': 'DOW BARGE CHANNEL', 'value': 'DOW BARGE CHANNEL' },
  { 'label': 'DRAYTON PASSAGE', 'value': 'DRAYTON PASSAGE' },
  { 'label': 'DRUM INLET', 'value': 'DRUM INLET' },
  { 'label': 'DRY PASS', 'value': 'DRY PASS' },
  { 'label': 'DRY STRAIT', 'value': 'DRY STRAIT' },
  { 'label': 'DUCK ISLAND HARBOR', 'value': 'DUCK ISLAND HARBOR' },
  { 'label': 'DUCK POINT COVE', 'value': 'DUCK POINT COVE' },
  { 'label': 'DUNCAN BAY', 'value': 'DUNCAN BAY' },
  { 'label': 'DUNCAN CANAL', 'value': 'DUNCAN CANAL' },
  { 'label': 'DUXBURY HARBOR', 'value': 'DUXBURY HARBOR' },
  { 'label': 'DYER BAY', 'value': 'DYER BAY' },
  { 'label': 'DYER CREEK', 'value': 'DYER CREEK' },
  { 'label': 'DYMER CREEK', 'value': 'DYMER CREEK' },
  { 'label': 'EAST BASIN CHANNEL', 'value': 'EAST BASIN CHANNEL' },
  { 'label': 'EAST BAY', 'value': 'EAST BAY' },
  { 'label': 'EAST BOOTHBAY HARBOR', 'value': 'EAST BOOTHBAY HARBOR' },
  { 'label': 'EAST BRANCH', 'value': 'EAST BRANCH' },
  { 'label': 'EAST HAVEN CREEK', 'value': 'EAST HAVEN CREEK' },
  { 'label': 'EAST PEARL RIVER', 'value': 'EAST PEARL RIVER' },
  { 'label': 'EAST RIVER', 'value': 'EAST RIVER' },
  { 'label': 'EAST ROCKAWAY CHANNEL', 'value': 'EAST ROCKAWAY CHANNEL' },
  { 'label': 'EAST ROCKAWAY INLET', 'value': 'EAST ROCKAWAY INLET' },
  { 'label': 'EAST SOUND', 'value': 'EAST SOUND' },
  { 'label': 'EASTERN BAY', 'value': 'EASTERN BAY' },
  { 'label': 'EASTPORT HARBOR', 'value': 'EASTPORT HARBOR' },
  { 'label': 'EAU GALLIE HARBOR', 'value': 'EAU GALLIE HARBOR' },
  { 'label': 'ECHO BAY HARBOR', 'value': 'ECHO BAY HARBOR' },
  { 'label': 'ECOLA CREEK', 'value': 'ECOLA CREEK' },
  { 'label': 'EDGARTOWN HARBOR', 'value': 'EDGARTOWN HARBOR' },
  { 'label': 'EDGEMONT BAR CHANNEL', 'value': 'EDGEMONT BAR CHANNEL' },
  { 'label': 'EGEGIK RIVER', 'value': 'EGEGIK RIVER' },
  { 'label': 'EGGEMOGGIN REACH', 'value': 'EGGEMOGGIN REACH' },
  { 'label': 'EIGHTMILE RIVER', 'value': 'EIGHTMILE RIVER' },
  { 'label': 'EL CAPITAN PASSAGE', 'value': 'EL CAPITAN PASSAGE' },
  { 'label': 'ELFIN COVE HARBOR', 'value': 'ELFIN COVE HARBOR' },
  { 'label': 'ELIZABETH RIVER', 'value': 'ELIZABETH RIVER' },
  {
    'label': 'ELIZABETH RIVER, EASTERN BRANCH',
    'value': 'ELIZABETH RIVER, EASTERN BRANCH'
  },
  {
    'label': 'ELIZABETH RIVER, SOUTHERN BRANCH',
    'value': 'ELIZABETH RIVER, SOUTHERN BRANCH'
  },
  { 'label': 'ELK RIVER', 'value': 'ELK RIVER' },
  { 'label': 'ELK RIVER, WV', 'value': 'ELK RIVER, WV' },
  { 'label': 'ELLEN BAY', 'value': 'ELLEN BAY' },
  { 'label': 'ELLIOT COVE', 'value': 'ELLIOT COVE' },
  { 'label': 'ELLIOTT BAY', 'value': 'ELLIOTT BAY' },
  { 'label': 'ELLWOOD HARBOR', 'value': 'ELLWOOD HARBOR' },
  { 'label': 'ELOKOMIN SLOUGH', 'value': 'ELOKOMIN SLOUGH' },
  { 'label': 'EMORY RIVER', 'value': 'EMORY RIVER' },
  { 'label': 'ENGLISHMAN BAY', 'value': 'ENGLISHMAN BAY' },
  { 'label': 'ERIE CANAL', 'value': 'ERIE CANAL' },
  { 'label': 'ERNEST SOUND', 'value': 'ERNEST SOUND' },
  { 'label': 'ESCAMBIA BAY', 'value': 'ESCAMBIA BAY' },
  { 'label': 'ESCAMBIA RIVER', 'value': 'ESCAMBIA RIVER' },
  { 'label': 'ESCATAWPA RIVER', 'value': 'ESCATAWPA RIVER' },
  { 'label': 'ESPIRITO SANTO BAY', 'value': 'ESPIRITO SANTO BAY' },
  { 'label': 'ESSEX RIVER', 'value': 'ESSEX RIVER' },
  { 'label': 'ESTERO BAY', 'value': 'ESTERO BAY' },
  { 'label': 'ETOLIN STRAIT', 'value': 'ETOLIN STRAIT' },
  { 'label': 'FAJARDO HARBOR', 'value': 'FAJARDO HARBOR' },
  { 'label': 'FALMOUTH HARBOR', 'value': 'FALMOUTH HARBOR' },
  { 'label': 'FANCY BLUFF CREEK', 'value': 'FANCY BLUFF CREEK' },
  { 'label': 'FAR ROCKAWAY', 'value': 'FAR ROCKAWAY' },
  { 'label': 'FARM CREEK', 'value': 'FARM CREEK' },
  { 'label': 'FARM RIVER', 'value': 'FARM RIVER' },
  { 'label': 'FARNHAM CREEK', 'value': 'FARNHAM CREEK' },
  { 'label': 'FEATHER RIVER', 'value': 'FEATHER RIVER' },
  { 'label': 'FERRY COVE', 'value': 'FERRY COVE' },
  { 'label': 'FIRE ISLAND INLET', 'value': 'FIRE ISLAND INLET' },
  { 'label': 'FISHERS SOUND', 'value': 'FISHERS SOUND' },
  { 'label': 'FISHING BAY', 'value': 'FISHING BAY' },
  { 'label': 'FISHING CREEK', 'value': 'FISHING CREEK' },
  { 'label': 'FIVE FATHOM CREEK', 'value': 'FIVE FATHOM CREEK' },
  { 'label': 'FIVEMILE RIVER HARBOR', 'value': 'FIVEMILE RIVER HARBOR' },
  { 'label': 'FLANDERS BAY', 'value': 'FLANDERS BAY' },
  { 'label': 'FLATHEAD LAKE', 'value': 'FLATHEAD LAKE' },
  { 'label': 'FLAXMAN ISLAND', 'value': 'FLAXMAN ISLAND' },
  { 'label': 'FLINT RIVER', 'value': 'FLINT RIVER' },
  { 'label': 'FLORIDA BARGE CANAL', 'value': 'FLORIDA BARGE CANAL' },
  {
    'label': 'FLORIDA COASTAL WATERWAY',
    'value': 'FLORIDA COASTAL WATERWAY'
  },
  { 'label': 'FLORIDA KEYS', 'value': 'FLORIDA KEYS' },
  {
    'label': 'FLORIDA POWER CORP. CHANNELS',
    'value': 'FLORIDA POWER CORP. CHANNELS'
  },
  {
    'label': 'FLORIDA SHALLOW WATER ACCESS',
    'value': 'FLORIDA SHALLOW WATER ACCESS'
  },
  {
    'label': 'FLORIDA SHALLOW WATER SPINE',
    'value': 'FLORIDA SHALLOW WATER SPINE'
  },
  { 'label': 'FLOUR BLUFF CHANNEL', 'value': 'FLOUR BLUFF CHANNEL' },
  { 'label': 'FLUSHING BAY BRANCH', 'value': 'FLUSHING BAY BRANCH' },
  { 'label': 'FLUSHING CREEK', 'value': 'FLUSHING CREEK' },
  { 'label': 'FOLLY RIVER', 'value': 'FOLLY RIVER' },
  { 'label': 'FORE RIVER', 'value': 'FORE RIVER' },
  { 'label': 'FOREST RIVER', 'value': 'FOREST RIVER' },
  { 'label': 'FORT PIERCE HARBOR', 'value': 'FORT PIERCE HARBOR' },
  { 'label': 'FORT PIERCE INLET', 'value': 'FORT PIERCE INLET' },
  { 'label': 'FORT POND BAY', 'value': 'FORT POND BAY' },
  { 'label': 'FORTESCUE CREEK', 'value': 'FORTESCUE CREEK' },
  { 'label': 'FOWL RIVER', 'value': 'FOWL RIVER' },
  { 'label': 'FOX RIVER', 'value': 'FOX RIVER' },
  { 'label': 'FRANKLIN CANAL', 'value': 'FRANKLIN CANAL' },
  { 'label': 'FRANKPORT HARBOR', 'value': 'FRANKPORT HARBOR' },
  { 'label': 'FREDERICK SOUND', 'value': 'FREDERICK SOUND' },
  { 'label': 'FREDERIKSTED HARBOR', 'value': 'FREDERIKSTED HARBOR' },
  { 'label': 'FREEPORT CREEK', 'value': 'FREEPORT CREEK' },
  { 'label': 'FREEPORT HARBOR', 'value': 'FREEPORT HARBOR' },
  { 'label': 'FREEPORT SULFUR CANAL', 'value': 'FREEPORT SULFUR CANAL' },
  { 'label': 'FRENCH BROAD RIVER', 'value': 'FRENCH BROAD RIVER' },
  { 'label': 'FRENCHMAN BAY', 'value': 'FRENCHMAN BAY' },
  { 'label': 'FRESHWATER BAYOU CANAL', 'value': 'FRESHWATER BAYOU CANAL' },
  { 'label': 'FRIENDSHIP HARBOR', 'value': 'FRIENDSHIP HARBOR' },
  { 'label': 'FRIPP INLET', 'value': 'FRIPP INLET' },
  { 'label': 'FRONT PORT CHANNEL', 'value': 'FRONT PORT CHANNEL' },
  { 'label': 'GALVESTON BAY', 'value': 'GALVESTON BAY' },
  {
    'label': 'GALVESTON BAY ENTRANCE CHANNEL',
    'value': 'GALVESTON BAY ENTRANCE CHANNEL'
  },
  { 'label': 'GALVESTON CHANNEL', 'value': 'GALVESTON CHANNEL' },
  {
    'label': 'GALVESTON CHANNEL ACCESS',
    'value': 'GALVESTON CHANNEL ACCESS'
  },
  { 'label': 'GANGES HARBOR', 'value': 'GANGES HARBOR' },
  { 'label': 'GARDINERS BAY', 'value': 'GARDINERS BAY' },
  { 'label': 'GASCONADE RIVER', 'value': 'GASCONADE RIVER' },
  { 'label': 'GASTINEAU CHANNEL', 'value': 'GASTINEAU CHANNEL' },
  { 'label': 'GAVIOTA HARBOR', 'value': 'GAVIOTA HARBOR' },
  { 'label': 'GENESEE RIVER', 'value': 'GENESEE RIVER' },
  { 'label': 'GILKEY HARBOR', 'value': 'GILKEY HARBOR' },
  { 'label': 'GLEN COVE CREEK', 'value': 'GLEN COVE CREEK' },
  { 'label': 'GLEN COVE HARBOR', 'value': 'GLEN COVE HARBOR' },
  { 'label': 'GLOUCESTER HARBOR', 'value': 'GLOUCESTER HARBOR' },
  { 'label': 'GOOSE COVE', 'value': 'GOOSE COVE' },
  { 'label': 'GOOSE CREEK', 'value': 'GOOSE CREEK' },
  { 'label': 'GOOSEFARE BAY', 'value': 'GOOSEFARE BAY' },
  { 'label': 'GORDON PASS', 'value': 'GORDON PASS' },
  { 'label': 'GOSHEN CREEK', 'value': 'GOSHEN CREEK' },
  { 'label': 'GOULDSBORO BAY', 'value': 'GOULDSBORO BAY' },
  { 'label': 'GOWANUS CANAL', 'value': 'GOWANUS CANAL' },
  { 'label': 'GOWANUS CREEK CHANNEL', 'value': 'GOWANUS CREEK CHANNEL' },
  { 'label': 'GRAND BAYOU', 'value': 'GRAND BAYOU' },
  { 'label': 'GRAND BAYOU PASS', 'value': 'GRAND BAYOU PASS' },
  { 'label': 'GRAND LAGOON', 'value': 'GRAND LAGOON' },
  { 'label': 'GRAND LAKE', 'value': 'GRAND LAKE' },
  { 'label': 'GRAND PASS', 'value': 'GRAND PASS' },
  { 'label': 'GRAND-TIGRE PASS', 'value': 'GRAND-TIGRE PASS' },
  { 'label': 'GRAVESEND BAY', 'value': 'GRAVESEND BAY' },
  { 'label': 'GRAYS BAY', 'value': 'GRAYS BAY' },
  { 'label': 'GRAYS HARBOR', 'value': 'GRAYS HARBOR' },
  { 'label': 'GRAYS RIVER', 'value': 'GRAYS RIVER' },
  { 'label': 'GREAT CHAZY RIVER', 'value': 'GREAT CHAZY RIVER' },
  {
    'label': 'GREAT DISMAL SWAMP CANAL',
    'value': 'GREAT DISMAL SWAMP CANAL'
  },
  { 'label': 'GREAT EGG INLET', 'value': 'GREAT EGG INLET' },
  { 'label': 'GREAT EGG RIVER', 'value': 'GREAT EGG RIVER' },
  { 'label': 'GREAT HARBOR', 'value': 'GREAT HARBOR' },
  { 'label': 'GREAT KILLS HARBOR', 'value': 'GREAT KILLS HARBOR' },
  { 'label': 'GREAT PECONIC BAY', 'value': 'GREAT PECONIC BAY' },
  { 'label': 'GREAT PEE DEE RIVER', 'value': 'GREAT PEE DEE RIVER' },
  { 'label': 'GREAT SALT POND', 'value': 'GREAT SALT POND' },
  { 'label': 'GREAT SOUTH BAY', 'value': 'GREAT SOUTH BAY' },
  { 'label': 'GREAT WICOMICO RIVER', 'value': 'GREAT WICOMICO RIVER' },
  { 'label': 'GREEN BAY HARBOR', 'value': 'GREEN BAY HARBOR' },
  { 'label': 'GREEN HARBOR', 'value': 'GREEN HARBOR' },
  { 'label': 'GREEN RIVER', 'value': 'GREEN RIVER' },
  { 'label': 'GREENPORT HARBOR', 'value': 'GREENPORT HARBOR' },
  { 'label': 'GREENS BAYOU', 'value': 'GREENS BAYOU' },
  { 'label': 'GREENVALE CREEK', 'value': 'GREENVALE CREEK' },
  { 'label': 'GREENWICH BAY', 'value': 'GREENWICH BAY' },
  { 'label': 'GREENWICH HARBOR', 'value': 'GREENWICH HARBOR' },
  { 'label': 'GROVE RIVER', 'value': 'GROVE RIVER' },
  { 'label': 'GUADALUPE BAY', 'value': 'GUADALUPE BAY' },
  { 'label': 'GUADALUPE SLOUGH', 'value': 'GUADALUPE SLOUGH' },
  { 'label': 'GUANICA HARBOR', 'value': 'GUANICA HARBOR' },
  { 'label': 'GUAYANES HARBOR', 'value': 'GUAYANES HARBOR' },
  { 'label': 'GUAYANILLA HARBOR', 'value': 'GUAYANILLA HARBOR' },
  { 'label': 'GUEMES CHANNEL', 'value': 'GUEMES CHANNEL' },
  { 'label': 'GUILFORD HARBOR', 'value': 'GUILFORD HARBOR' },
  {
    'label': 'GULF AT HOUSTON,TEXAS CITY,GALVESTO',
    'value': 'GULF AT HOUSTON,TEXAS CITY,GALVESTO'
  },
  { 'label': 'GULF COUNTY CANAL', 'value': 'GULF COUNTY CANAL' },
  { 'label': 'GULF DEEP WATER ACCESS', 'value': 'GULF DEEP WATER ACCESS' },
  { 'label': 'GULF DEEP WATER SPINE', 'value': 'GULF DEEP WATER SPINE' },
  { 'label': 'GULF DEEP WATER SPUR', 'value': 'GULF DEEP WATER SPUR' },
  {
    'label': 'GULF INTRACOASTAL WATER',
    'value': 'GULF INTRACOASTAL WATER'
  },
  {
    'label': 'GULF INTRACOASTAL WATERWAY',
    'value': 'GULF INTRACOASTAL WATERWAY'
  },
  {
    'label': 'GULF INTRACOASTAL WATERWAY ACCESS',
    'value': 'GULF INTRACOASTAL WATERWAY ACCESS'
  },
  { 'label': 'GULF OF ALASKA', 'value': 'GULF OF ALASKA' },
  { 'label': 'GULF OF ALASKA SPUR', 'value': 'GULF OF ALASKA SPUR' },
  { 'label': 'GULF OF MAINE', 'value': 'GULF OF MAINE' },
  { 'label': 'GULF OF SANTA CATALINA', 'value': 'GULF OF SANTA CATALINA' },
  { 'label': 'GULF OF THE FARALLONES', 'value': 'GULF OF THE FARALLONES' },
  { 'label': 'GULF-PANAMA ACCESS', 'value': 'GULF-PANAMA ACCESS' },
  { 'label': 'GUNPOWDER RIVER', 'value': 'GUNPOWDER RIVER' },
  { 'label': 'GUNSTON COVE', 'value': 'GUNSTON COVE' },
  { 'label': 'HACK CREEK', 'value': 'HACK CREEK' },
  { 'label': 'HACKENSACK RIVER', 'value': 'HACKENSACK RIVER' },
  { 'label': 'HALE PASS', 'value': 'HALE PASS' },
  { 'label': 'HALE PASSAGE', 'value': 'HALE PASSAGE' },
  { 'label': 'HAMMERSLEY INLET', 'value': 'HAMMERSLEY INLET' },
  { 'label': 'HAMPTON CREEK', 'value': 'HAMPTON CREEK' },
  { 'label': 'HAMPTON HARBOR', 'value': 'HAMPTON HARBOR' },
  { 'label': 'HANA HARBOR', 'value': 'HANA HARBOR' },
  { 'label': 'HANCOCK CREEK', 'value': 'HANCOCK CREEK' },
  { 'label': 'HAO STRAIT', 'value': 'HAO STRAIT' },
  { 'label': 'HARBOR OF REFUGE', 'value': 'HARBOR OF REFUGE' },
  { 'label': 'HARLEM RIVER', 'value': 'HARLEM RIVER' },
  { 'label': 'HARPSWELL SOUND', 'value': 'HARPSWELL SOUND' },
  { 'label': 'HARRASEEKET RIVER', 'value': 'HARRASEEKET RIVER' },
  { 'label': 'HARRINGTON RIVER', 'value': 'HARRINGTON RIVER' },
  { 'label': 'HARRIS CREEK', 'value': 'HARRIS CREEK' },
  { 'label': 'HARRISBURG BEND', 'value': 'HARRISBURG BEND' },
  { 'label': 'HASSLER HARBOR', 'value': 'HASSLER HARBOR' },
  { 'label': 'HATCHET COVE', 'value': 'HATCHET COVE' },
  { 'label': 'HATTERAS CHANNEL', 'value': 'HATTERAS CHANNEL' },
  { 'label': 'HATTERAS INLET', 'value': 'HATTERAS INLET' },
  { 'label': 'HAWAII DEEP WATER LOOP', 'value': 'HAWAII DEEP WATER LOOP' },
  { 'label': 'HAWK COVE', 'value': 'HAWK COVE' },
  { 'label': 'HAY(WEST) HARBOR', 'value': 'HAY(WEST) HARBOR' },
  { 'label': 'HAYCOCK HARBOR', 'value': 'HAYCOCK HARBOR' },
  { 'label': 'HAYNES INLET', 'value': 'HAYNES INLET' },
  { 'label': 'HEAD HARBOR', 'value': 'HEAD HARBOR' },
  { 'label': 'HEARNS COVE', 'value': 'HEARNS COVE' },
  { 'label': 'HECATE STRAIT', 'value': 'HECATE STRAIT' },
  { 'label': 'HELENA HARBOR', 'value': 'HELENA HARBOR' },
  { 'label': 'HELLEN CREEK', 'value': 'HELLEN CREEK' },
  { 'label': 'HEMPSTEAD BAY', 'value': 'HEMPSTEAD BAY' },
  { 'label': 'HEMPSTEAD HARBOR', 'value': 'HEMPSTEAD HARBOR' },
  { 'label': 'HENDRICKS HARBOR', 'value': 'HENDRICKS HARBOR' },
  { 'label': 'HERBERTS CREEK', 'value': 'HERBERTS CREEK' },
  { 'label': 'HEREFORD INLET', 'value': 'HEREFORD INLET' },
  { 'label': 'HERRING BAY', 'value': 'HERRING BAY' },
  { 'label': 'HERRING CREEK', 'value': 'HERRING CREEK' },
  { 'label': 'HERRING RIVER', 'value': 'HERRING RIVER' },
  { 'label': 'HILLS BAY', 'value': 'HILLS BAY' },
  { 'label': 'HILLSBOROUGH BAY', 'value': 'HILLSBOROUGH BAY' },
  { 'label': 'HILLSBOROUGH RIVER', 'value': 'HILLSBOROUGH RIVER' },
  { 'label': 'HILO HARBOR', 'value': 'HILO HARBOR' },
  { 'label': 'HINGHAM BAY', 'value': 'HINGHAM BAY' },
  { 'label': 'HINGHAM HARBOR', 'value': 'HINGHAM HARBOR' },
  { 'label': 'HIWASSEE RIVER', 'value': 'HIWASSEE RIVER' },
  { 'label': 'HOG ISLAND CHANNEL', 'value': 'HOG ISLAND CHANNEL' },
  { 'label': 'HOG NECK BAY', 'value': 'HOG NECK BAY' },
  { 'label': 'HOH RIVER', 'value': 'HOH RIVER' },
  { 'label': 'HOLMES BAY', 'value': 'HOLMES BAY' },
  { 'label': 'HOLMES HARBOR', 'value': 'HOLMES HARBOR' },
  { 'label': 'HOLSTON RIVER', 'value': 'HOLSTON RIVER' },
  { 'label': 'HOMOSASSA RIVER', 'value': 'HOMOSASSA RIVER' },
  { 'label': 'HONGA RIVER', 'value': 'HONGA RIVER' },
  { 'label': 'HONGA RIVER ACCESS', 'value': 'HONGA RIVER ACCESS' },
  { 'label': 'HONOLULU HARBOR', 'value': 'HONOLULU HARBOR' },
  { 'label': 'HONUAPO BAY', 'value': 'HONUAPO BAY' },
  { 'label': 'HOOD CANAL', 'value': 'HOOD CANAL' },
  { 'label': 'HOOKTON SLOUGH', 'value': 'HOOKTON SLOUGH' },
  { 'label': 'HOONAH HARBOR', 'value': 'HOONAH HARBOR' },
  { 'label': 'HOOPER BAY', 'value': 'HOOPER BAY' },
  { 'label': 'HOOPER STRAIT', 'value': 'HOOPER STRAIT' },
  { 'label': 'HOOPULOA BAY', 'value': 'HOOPULOA BAY' },
  { 'label': 'HOOVER CREEK', 'value': 'HOOVER CREEK' },
  { 'label': 'HOPE CREEK', 'value': 'HOPE CREEK' },
  { 'label': 'HORN HARBOR', 'value': 'HORN HARBOR' },
  { 'label': 'HORSESHOE COVE', 'value': 'HORSESHOE COVE' },
  { 'label': 'HOSKINS CREEK', 'value': 'HOSKINS CREEK' },
  { 'label': 'HOUMA NAVIGATION CANAL', 'value': 'HOUMA NAVIGATION CANAL' },
  { 'label': 'HOUSATONIC RIVER', 'value': 'HOUSATONIC RIVER' },
  { 'label': 'HOUSTON PASSAGE', 'value': 'HOUSTON PASSAGE' },
  { 'label': 'HOUSTON SHIP CHANNEL', 'value': 'HOUSTON SHIP CHANNEL' },
  {
    'label': 'HOUSTON SHIP CHANNEL ACCESS',
    'value': 'HOUSTON SHIP CHANNEL ACCESS'
  },
  { 'label': 'HOUSTON TURNING BASIN', 'value': 'HOUSTON TURNING BASIN' },
  { 'label': 'HUDSON RIVER', 'value': 'HUDSON RIVER' },
  { 'label': 'HUGHES PASS', 'value': 'HUGHES PASS' },
  { 'label': 'HULL CREEK', 'value': 'HULL CREEK' },
  { 'label': 'HUMACAO HARBOR', 'value': 'HUMACAO HARBOR' },
  { 'label': 'HUMBOLDT BAY', 'value': 'HUMBOLDT BAY' },
  { 'label': 'HUMBOLDT HARBOR', 'value': 'HUMBOLDT HARBOR' },
  { 'label': 'HUNGERS CREEK', 'value': 'HUNGERS CREEK' },
  { 'label': 'HUNTING BAYOU', 'value': 'HUNTING BAYOU' },
  { 'label': 'HUNTING CREEK', 'value': 'HUNTING CREEK' },
  { 'label': 'HUNTINGTON BAY', 'value': 'HUNTINGTON BAY' },
  { 'label': 'HUNTINGTON HARBOR', 'value': 'HUNTINGTON HARBOR' },
  { 'label': 'HUTCHINSON RIVER', 'value': 'HUTCHINSON RIVER' },
  { 'label': 'HYANNIS HARBOR', 'value': 'HYANNIS HARBOR' },
  { 'label': 'ICY BAY', 'value': 'ICY BAY' },
  { 'label': 'ICY STRAIT', 'value': 'ICY STRAIT' },
  { 'label': 'ILLINOIS RIVER', 'value': 'ILLINOIS RIVER' },
  { 'label': 'INDIAN CREEK', 'value': 'INDIAN CREEK' },
  { 'label': 'INDIAN KEY PASS', 'value': 'INDIAN KEY PASS' },
  { 'label': 'INDIAN RIVER BAY', 'value': 'INDIAN RIVER BAY' },
  { 'label': 'INDIAN RIVER INLET', 'value': 'INDIAN RIVER INLET' },
  {
    'label': 'INNER HARBOR NAVAGATION CANAL',
    'value': 'INNER HARBOR NAVAGATION CANAL'
  },
  {
    'label': 'INNER HARBOR, CHANNEL NO. 2',
    'value': 'INNER HARBOR, CHANNEL NO. 2'
  },
  {
    'label': 'INNER HARBOR, CHANNEL NO. 3',
    'value': 'INNER HARBOR, CHANNEL NO. 3'
  },
  { 'label': 'INNOKO RIVER', 'value': 'INNOKO RIVER' },
  { 'label': 'INTRACOASTAL ACCESS', 'value': 'INTRACOASTAL ACCESS' },
  {
    'label': 'INTRACOASTAL WATERWAY ACCESS',
    'value': 'INTRACOASTAL WATERWAY ACCESS'
  },
  {
    'label': 'INTRACOASTAL WATERWAY ALT. ROUTE',
    'value': 'INTRACOASTAL WATERWAY ALT. ROUTE'
  },
  { 'label': 'IPHIGENIA BAY', 'value': 'IPHIGENIA BAY' },
  { 'label': 'IPSWICH BAY', 'value': 'IPSWICH BAY' },
  { 'label': 'IPSWICH RIVER', 'value': 'IPSWICH RIVER' },
  { 'label': 'IRVINGTON HARBOR', 'value': 'IRVINGTON HARBOR' },
  { 'label': 'ISLAND CREEK', 'value': 'ISLAND CREEK' },
  { 'label': 'ISLAND END RIVER', 'value': 'ISLAND END RIVER' },
  { 'label': 'ISLE AU HAUT THOROFARE', 'value': 'ISLE AU HAUT THOROFARE' },
  { 'label': 'ISLE OF SHOALS HARBOR', 'value': 'ISLE OF SHOALS HARBOR' },
  { 'label': 'ISLE OF WIGHT BAY', 'value': 'ISLE OF WIGHT BAY' },
  { 'label': 'ISLEBORO HARBOR', 'value': 'ISLEBORO HARBOR' },
  { 'label': 'ISTHMUS COVE', 'value': 'ISTHMUS COVE' },
  { 'label': 'IVANOF BAY', 'value': 'IVANOF BAY' },
  { 'label': 'JAMAICA BAY', 'value': 'JAMAICA BAY' },
  { 'label': 'JAMES RIVER', 'value': 'JAMES RIVER' },
  { 'label': 'JAMES RIVER ACCESS', 'value': 'JAMES RIVER ACCESS' },
  { 'label': 'JENKINS CREEK', 'value': 'JENKINS CREEK' },
  { 'label': 'JEREMY CREEK', 'value': 'JEREMY CREEK' },
  { 'label': 'JERICHO BAY', 'value': 'JERICHO BAY' },
  { 'label': 'JEWEL FULTON CHANNEL', 'value': 'JEWEL FULTON CHANNEL' },
  { 'label': 'JOBOS HARBOR', 'value': 'JOBOS HARBOR' },
  { 'label': 'JOHNS BAY', 'value': 'JOHNS BAY' },
  { 'label': 'JOHNS PASS', 'value': 'JOHNS PASS' },
  { 'label': 'JOHNSON BAYOU', 'value': 'JOHNSON BAYOU' },
  { 'label': 'JOHNSONS RIVER', 'value': 'JOHNSONS RIVER' },
  { 'label': 'JONES BAY', 'value': 'JONES BAY' },
  { 'label': 'JONES CREEK', 'value': 'JONES CREEK' },
  { 'label': 'JONES INLET', 'value': 'JONES INLET' },
  { 'label': 'JONESPORT HARBOR', 'value': 'JONESPORT HARBOR' },
  { 'label': 'JORDAN RIVER', 'value': 'JORDAN RIVER' },
  { 'label': 'JOSIAS RIVER', 'value': 'JOSIAS RIVER' },
  { 'label': 'JOY BAY', 'value': 'JOY BAY' },
  { 'label': 'JUNEAU HARBOR', 'value': 'JUNEAU HARBOR' },
  { 'label': 'KACHEMAK BAY', 'value': 'KACHEMAK BAY' },
  { 'label': 'KAHAWHA RIVER', 'value': 'KAHAWHA RIVER' },
  { 'label': 'KAHULUI HARBOR', 'value': 'KAHULUI HARBOR' },
  { 'label': 'KAILUA BAY', 'value': 'KAILUA BAY' },
  { 'label': 'KAILUA HARBOR', 'value': 'KAILUA HARBOR' },
  { 'label': 'KAIWI CHANNEL', 'value': 'KAIWI CHANNEL' },
  { 'label': 'KAKE HARBOR', 'value': 'KAKE HARBOR' },
  { 'label': 'KALAMA RIVER', 'value': 'KALAMA RIVER' },
  { 'label': 'KALAMAZOO RIVER', 'value': 'KALAMAZOO RIVER' },
  { 'label': 'KALAUPAPA HARBOR', 'value': 'KALAUPAPA HARBOR' },
  { 'label': 'KALOHI CHANNEL', 'value': 'KALOHI CHANNEL' },
  { 'label': 'KALOHI CHANNEL ACCESS', 'value': 'KALOHI CHANNEL ACCESS' },
  { 'label': 'KANAWHA RIVER', 'value': 'KANAWHA RIVER' },
  { 'label': 'KANSAS RIVER', 'value': 'KANSAS RIVER' },
  { 'label': 'KASAAN BAY', 'value': 'KASAAN BAY' },
  { 'label': 'KASKASKIA RIVER', 'value': 'KASKASKIA RIVER' },
  { 'label': 'KAUAI CHANNEL', 'value': 'KAUAI CHANNEL' },
  { 'label': 'KAUAI CHANNEL ACCESS', 'value': 'KAUAI CHANNEL ACCESS' },
  { 'label': 'KAUAI DEEP WATER LOOP', 'value': 'KAUAI DEEP WATER LOOP' },
  { 'label': 'KAULAKAHI CHANNEL', 'value': 'KAULAKAHI CHANNEL' },
  { 'label': 'KAUMALAPAU HARBOR', 'value': 'KAUMALAPAU HARBOR' },
  { 'label': 'KAUNAKAKAI HARBOR', 'value': 'KAUNAKAKAI HARBOR' },
  { 'label': 'KAWAIHAE BAY', 'value': 'KAWAIHAE BAY' },
  { 'label': 'KAWAIHAE HARBOR', 'value': 'KAWAIHAE HARBOR' },
  { 'label': 'KEALAIKAHIKI CHANNEL', 'value': 'KEALAIKAHIKI CHANNEL' },
  { 'label': 'KEALAKEKUA BAY', 'value': 'KEALAKEKUA BAY' },
  { 'label': 'KEAUHOU BAY', 'value': 'KEAUHOU BAY' },
  { 'label': 'KEKU STRAIT', 'value': 'KEKU STRAIT' },
  { 'label': 'KENNEBEC RIVER', 'value': 'KENNEBEC RIVER' },
  { 'label': 'KENNEBUNK RIVER', 'value': 'KENNEBUNK RIVER' },
  { 'label': 'KENNEDY ENTRANCE', 'value': 'KENNEDY ENTRANCE' },
  { 'label': 'KENT ISLAND NARROWS', 'value': 'KENT ISLAND NARROWS' },
  { 'label': 'KENTUCKY RIVER', 'value': 'KENTUCKY RIVER' },
  { 'label': 'KETCHIKAN HARBOR', 'value': 'KETCHIKAN HARBOR' },
  { 'label': 'KEWAUNEE HARBOR', 'value': 'KEWAUNEE HARBOR' },
  { 'label': 'KEWEENAW WATERWAY', 'value': 'KEWEENAW WATERWAY' },
  { 'label': 'KIAWAH RIVER', 'value': 'KIAWAH RIVER' },
  { 'label': 'KILISUT HARBOR', 'value': 'KILISUT HARBOR' },
  { 'label': 'KILL VAN KULL', 'value': 'KILL VAN KULL' },
  { 'label': 'KING COVE LAGOON', 'value': 'KING COVE LAGOON' },
  { 'label': 'KING CREEK', 'value': 'KING CREEK' },
  { 'label': 'KING HARBOR', 'value': 'KING HARBOR' },
  { 'label': 'KINGS BAY', 'value': 'KINGS BAY' },
  { 'label': 'KISSIMMEE RIVER', 'value': 'KISSIMMEE RIVER' },
  { 'label': 'KITTY HAWK BAY', 'value': 'KITTY HAWK BAY' },
  { 'label': 'KIZHUYAK BAY', 'value': 'KIZHUYAK BAY' },
  { 'label': 'KLAMATH RIVER', 'value': 'KLAMATH RIVER' },
  { 'label': 'KLAWOCK INLET', 'value': 'KLAWOCK INLET' },
  { 'label': 'KNAPPS NARROWS', 'value': 'KNAPPS NARROWS' },
  { 'label': 'KNITTING MILL CREEK', 'value': 'KNITTING MILL CREEK' },
  { 'label': 'KNOBBS CREEK', 'value': 'KNOBBS CREEK' },
  { 'label': 'KNOWLTENS HARBOR', 'value': 'KNOWLTENS HARBOR' },
  { 'label': 'KOBUK RIVER', 'value': 'KOBUK RIVER' },
  { 'label': 'KODIAK HARBOR', 'value': 'KODIAK HARBOR' },
  { 'label': 'KOLO HARBOR', 'value': 'KOLO HARBOR' },
  { 'label': 'KOOTENAI RIVER', 'value': 'KOOTENAI RIVER' },
  { 'label': 'KOTZEBUE SOUND', 'value': 'KOTZEBUE SOUND' },
  { 'label': 'KOYUKUK RIVER', 'value': 'KOYUKUK RIVER' },
  { 'label': 'KOZEBUL SOUND', 'value': 'KOZEBUL SOUND' },
  { 'label': 'KUSKOKWIM BAY', 'value': 'KUSKOKWIM BAY' },
  { 'label': 'KUSKOKWIM RIVER', 'value': 'KUSKOKWIM RIVER' },
  { 'label': 'KVICHAK BAY', 'value': 'KVICHAK BAY' },
  { 'label': 'KVICHAK RIVER', 'value': 'KVICHAK RIVER' },
  { 'label': 'LA GRANGE BAYOU', 'value': 'LA GRANGE BAYOU' },
  { 'label': 'LA QUINTA CHANNEL', 'value': 'LA QUINTA CHANNEL' },
  {
    'label': 'LA QUINTA CHANNEL ACCESS',
    'value': 'LA QUINTA CHANNEL ACCESS'
  },
  { 'label': 'LA TRAPPE RIVER', 'value': 'LA TRAPPE RIVER' },
  { 'label': 'LACOMBE BAYOU', 'value': 'LACOMBE BAYOU' },
  { 'label': 'LADYSMITH HARBOR', 'value': 'LADYSMITH HARBOR' },
  { 'label': 'LAFAYETTE RIVER', 'value': 'LAFAYETTE RIVER' },
  { 'label': 'LAGOON POND', 'value': 'LAGOON POND' },
  { 'label': 'LAGRANGE CREEK', 'value': 'LAGRANGE CREEK' },
  { 'label': 'LAGUNA MADRE', 'value': 'LAGUNA MADRE' },
  { 'label': 'LAKE BORGNE', 'value': 'LAKE BORGNE' },
  { 'label': 'LAKE CALUMET', 'value': 'LAKE CALUMET' },
  { 'label': 'LAKE CHAMPLAIN', 'value': 'LAKE CHAMPLAIN' },
  { 'label': 'LAKE CHARLES', 'value': 'LAKE CHARLES' },
  { 'label': 'LAKE CRESCENT', 'value': 'LAKE CRESCENT' },
  { 'label': 'LAKE ELOI', 'value': 'LAKE ELOI' },
  { 'label': 'LAKE ERIE', 'value': 'LAKE ERIE' },
  { 'label': 'LAKE GEORGE', 'value': 'LAKE GEORGE' },
  { 'label': 'LAKE HURON', 'value': 'LAKE HURON' },
  { 'label': 'LAKE MAUREPAS', 'value': 'LAKE MAUREPAS' },
  { 'label': 'LAKE MCKELLAR', 'value': 'LAKE MCKELLAR' },
  { 'label': 'LAKE MECHANT', 'value': 'LAKE MECHANT' },
  { 'label': 'LAKE MICHIGAN', 'value': 'LAKE MICHIGAN' },
  { 'label': 'LAKE MONTAUK', 'value': 'LAKE MONTAUK' },
  { 'label': 'LAKE MOULTRIE', 'value': 'LAKE MOULTRIE' },
  { 'label': 'LAKE OF THE WOODS', 'value': 'LAKE OF THE WOODS' },
  { 'label': 'LAKE OGLETON', 'value': 'LAKE OGLETON' },
  { 'label': 'LAKE OKEECHOBEE', 'value': 'LAKE OKEECHOBEE' },
  { 'label': 'LAKE ONTARIO', 'value': 'LAKE ONTARIO' },
  { 'label': 'LAKE PONTCHARTRAIN', 'value': 'LAKE PONTCHARTRAIN' },
  { 'label': 'LAKE RIVER', 'value': 'LAKE RIVER' },
  { 'label': 'LAKE SUPERIOR', 'value': 'LAKE SUPERIOR' },
  { 'label': 'LAKE WASHINGTON', 'value': 'LAKE WASHINGTON' },
  {
    'label': 'LAKE WASHINGTON SHIP CANAL',
    'value': 'LAKE WASHINGTON SHIP CANAL'
  },
  { 'label': 'LAKE WINNEBAGO', 'value': 'LAKE WINNEBAGO' },
  { 'label': 'LAMPREY RIVER', 'value': 'LAMPREY RIVER' },
  { 'label': 'LANCASTER CREEK', 'value': 'LANCASTER CREEK' },
  { 'label': 'LARCHMONT HARBOR', 'value': 'LARCHMONT HARBOR' },
  { 'label': 'LAS MAREAS HARBOR', 'value': 'LAS MAREAS HARBOR' },
  { 'label': 'LAVACA BAY', 'value': 'LAVACA BAY' },
  { 'label': 'LAVACA RIVER', 'value': 'LAVACA RIVER' },
  { 'label': 'LEIPSIC RIVER', 'value': 'LEIPSIC RIVER' },
  {
    'label': 'LEWES AND REHOBOTH CANAL',
    'value': 'LEWES AND REHOBOTH CANAL'
  },
  { 'label': 'LEWIS RIVER', 'value': 'LEWIS RIVER' },
  { 'label': 'LIBERTY BAY', 'value': 'LIBERTY BAY' },
  { 'label': 'LICKING RIVER', 'value': 'LICKING RIVER' },
  { 'label': 'LINEKIN BAY', 'value': 'LINEKIN BAY' },
  { 'label': 'LINKHORN BAY', 'value': 'LINKHORN BAY' },
  {
    'label': 'LITTLE ANNEMESSEX RIVER',
    'value': 'LITTLE ANNEMESSEX RIVER'
  },
  { 'label': 'LITTLE BAY DE NOC', 'value': 'LITTLE BAY DE NOC' },
  { 'label': 'LITTLE BAYOU PIGEON', 'value': 'LITTLE BAYOU PIGEON' },
  { 'label': 'LITTLE CHOPTANK RIVER', 'value': 'LITTLE CHOPTANK RIVER' },
  { 'label': 'LITTLE EGG HARBOR', 'value': 'LITTLE EGG HARBOR' },
  { 'label': 'LITTLE EGG INLET', 'value': 'LITTLE EGG INLET' },
  { 'label': 'LITTLE HARBOR', 'value': 'LITTLE HARBOR' },
  { 'label': 'LITTLE KANAWHA RIVER', 'value': 'LITTLE KANAWHA RIVER' },
  { 'label': 'LITTLE KENNEBEC BAY', 'value': 'LITTLE KENNEBEC BAY' },
  { 'label': 'LITTLE KRUM BAY', 'value': 'LITTLE KRUM BAY' },
  { 'label': 'LITTLE LAKE PASS', 'value': 'LITTLE LAKE PASS' },
  { 'label': 'LITTLE MACHIAS BAY', 'value': 'LITTLE MACHIAS BAY' },
  {
    'label': 'LITTLE MACHINPONGO RIVER',
    'value': 'LITTLE MACHINPONGO RIVER'
  },
  { 'label': 'LITTLE MANATEE RIVER', 'value': 'LITTLE MANATEE RIVER' },
  { 'label': 'LITTLE MYSTIC RIVER', 'value': 'LITTLE MYSTIC RIVER' },
  { 'label': 'LITTLE NECK BAY', 'value': 'LITTLE NECK BAY' },
  { 'label': 'LITTLE OGEECHEE RIVER', 'value': 'LITTLE OGEECHEE RIVER' },
  { 'label': 'LITTLE PECONIC BAY', 'value': 'LITTLE PECONIC BAY' },
  { 'label': 'LITTLE PEE DEE RIVER', 'value': 'LITTLE PEE DEE RIVER' },
  { 'label': 'LITTLE RIVER', 'value': 'LITTLE RIVER' },
  { 'label': 'LITTLE RIVER INLET', 'value': 'LITTLE RIVER INLET' },
  { 'label': 'LITTLE SATILLA RIVER', 'value': 'LITTLE SATILLA RIVER' },
  { 'label': 'LITTLE SUNFLOWER RIVER', 'value': 'LITTLE SUNFLOWER RIVER' },
  { 'label': 'LITTLE WICOMICO RIVER', 'value': 'LITTLE WICOMICO RIVER' },
  { 'label': 'LOBDELL CANAL', 'value': 'LOBDELL CANAL' },
  { 'label': 'LOCKLIES CREEK', 'value': 'LOCKLIES CREEK' },
  { 'label': 'LOCKWOODS FOLLY INLET', 'value': 'LOCKWOODS FOLLY INLET' },
  { 'label': 'LOCKWOODS FOLLY RIVER', 'value': 'LOCKWOODS FOLLY RIVER' },
  { 'label': 'LOCUST FORK RIVER', 'value': 'LOCUST FORK RIVER' },
  { 'label': 'LONG BEACH CHANNEL', 'value': 'LONG BEACH CHANNEL' },
  { 'label': 'LONG BEACH MARINA', 'value': 'LONG BEACH MARINA' },
  {
    'label': 'LONG BEACH OUTER HARBOR',
    'value': 'LONG BEACH OUTER HARBOR'
  },
  { 'label': 'LONG CREEK', 'value': 'LONG CREEK' },
  { 'label': 'LONG ISLAND SOUND', 'value': 'LONG ISLAND SOUND' },
  { 'label': 'LONG POINT CHANNEL', 'value': 'LONG POINT CHANNEL' },
  { 'label': 'LONGBOAT PASS', 'value': 'LONGBOAT PASS' },
  { 'label': 'LOOKOUT BIGHT CHANNEL', 'value': 'LOOKOUT BIGHT CHANNEL' },
  { 'label': 'LOPEZ PASS', 'value': 'LOPEZ PASS' },
  { 'label': 'LOPEZ SOUND', 'value': 'LOPEZ SOUND' },
  { 'label': 'LOWER BAY', 'value': 'LOWER BAY' },
  {
    'label': 'LOWER BAY AT MIDLAND BEACH',
    'value': 'LOWER BAY AT MIDLAND BEACH'
  },
  { 'label': 'LOWER MACHODOC CREEK', 'value': 'LOWER MACHODOC CREEK' },
  { 'label': 'LOWER THOROFARE', 'value': 'LOWER THOROFARE' },
  { 'label': 'LUBEC CHANNEL', 'value': 'LUBEC CHANNEL' },
  { 'label': 'LUMBER RIVER', 'value': 'LUMBER RIVER' },
  { 'label': 'LUMMI BAY', 'value': 'LUMMI BAY' },
  { 'label': 'LUNT HARBOR', 'value': 'LUNT HARBOR' },
  { 'label': 'LYNCHES RIVER', 'value': 'LYNCHES RIVER' },
  { 'label': 'LYNN CANAL', 'value': 'LYNN CANAL' },
  { 'label': 'LYNN HARBOR', 'value': 'LYNN HARBOR' },
  { 'label': 'LYNNHAVEN BAY', 'value': 'LYNNHAVEN BAY' },
  { 'label': 'LYNNHAVEN INLET', 'value': 'LYNNHAVEN INLET' },
  { 'label': 'MAALAEA BAY', 'value': 'MAALAEA BAY' },
  { 'label': 'MACHIAS BAY', 'value': 'MACHIAS BAY' },
  { 'label': 'MACHIAS RIVER', 'value': 'MACHIAS RIVER' },
  { 'label': 'MACKAY CREEK', 'value': 'MACKAY CREEK' },
  { 'label': 'MAD HORSE CREEK', 'value': 'MAD HORSE CREEK' },
  { 'label': 'MADISON HARBOR', 'value': 'MADISON HARBOR' },
  { 'label': 'MAGOTHY RIVER', 'value': 'MAGOTHY RIVER' },
  { 'label': 'MAHON RIVER', 'value': 'MAHON RIVER' },
  { 'label': 'MAIN CHANNEL', 'value': 'MAIN CHANNEL' },
  {
    'label': 'MAIN CHANNEL TURNING BASIN',
    'value': 'MAIN CHANNEL TURNING BASIN'
  },
  { 'label': 'MAIN PASS', 'value': 'MAIN PASS' },
  { 'label': 'MAIN SHIP CHANNEL, NY', 'value': 'MAIN SHIP CHANNEL, NY' },
  { 'label': 'MALDEN RIVER', 'value': 'MALDEN RIVER' },
  { 'label': 'MALLARD BAY', 'value': 'MALLARD BAY' },
  { 'label': 'MAMALA BAY', 'value': 'MAMALA BAY' },
  { 'label': 'MAMARONECK HARBOR', 'value': 'MAMARONECK HARBOR' },
  { 'label': 'MANASQUAN RIVER', 'value': 'MANASQUAN RIVER' },
  { 'label': 'MANATEE RIVER', 'value': 'MANATEE RIVER' },
  { 'label': 'MANCHESTER HARBOR', 'value': 'MANCHESTER HARBOR' },
  { 'label': 'MANHASSET BAY', 'value': 'MANHASSET BAY' },
  { 'label': 'MANOKIN RIVER', 'value': 'MANOKIN RIVER' },
  { 'label': 'MANTUA CREEK', 'value': 'MANTUA CREEK' },
  { 'label': 'MAPLE BAY', 'value': 'MAPLE BAY' },
  { 'label': 'MARATHON HARBOR', 'value': 'MARATHON HARBOR' },
  { 'label': 'MARBLEHEAD HARBOR', 'value': 'MARBLEHEAD HARBOR' },
  { 'label': 'MARE ISLAND STRAIT', 'value': 'MARE ISLAND STRAIT' },
  { 'label': 'MARIANA ISLAND', 'value': 'MARIANA ISLAND' },
  { 'label': 'MARINE CHANNEL', 'value': 'MARINE CHANNEL' },
  { 'label': 'MARINERS HARBOR', 'value': 'MARINERS HARBOR' },
  { 'label': 'MARTIN PENA CHANNEL', 'value': 'MARTIN PENA CHANNEL' },
  {
    'label': 'MARYLAND INTRACOASTAL WATERWAY',
    'value': 'MARYLAND INTRACOASTAL WATERWAY'
  },
  { 'label': 'MASONBORO INLET', 'value': 'MASONBORO INLET' },
  { 'label': 'MASSACHUSETTS BAY', 'value': 'MASSACHUSETTS BAY' },
  { 'label': 'MASSACHUSSETS BAY', 'value': 'MASSACHUSSETS BAY' },
  { 'label': 'MATAGORDA BAY', 'value': 'MATAGORDA BAY' },
  { 'label': 'MATAGORDA SHIP CHANNEL', 'value': 'MATAGORDA SHIP CHANNEL' },
  { 'label': 'MATAWAN CREEK', 'value': 'MATAWAN CREEK' },
  { 'label': 'MATINICUS HARBOR', 'value': 'MATINICUS HARBOR' },
  { 'label': 'MATTAPOISETT HARBOR', 'value': 'MATTAPOISETT HARBOR' },
  { 'label': 'MATTAPONI RIVER', 'value': 'MATTAPONI RIVER' },
  { 'label': 'MATTITUCK HARBOR', 'value': 'MATTITUCK HARBOR' },
  { 'label': 'MATTOX CREEK', 'value': 'MATTOX CREEK' },
  { 'label': 'MAUI DEEP WATER LOOP', 'value': 'MAUI DEEP WATER LOOP' },
  { 'label': 'MAURICE RIVER', 'value': 'MAURICE RIVER' },
  { 'label': 'MAYAGUEZ BAY', 'value': 'MAYAGUEZ BAY' },
  { 'label': 'MAYAGUEZ HARBOR', 'value': 'MAYAGUEZ HARBOR' },
  { 'label': 'MCCREADYS CREEK', 'value': 'MCCREADYS CREEK' },
  { 'label': 'MEDOWMAK RIVER', 'value': 'MEDOWMAK RIVER' },
  { 'label': 'MEDWAY RIVER', 'value': 'MEDWAY RIVER' },
  { 'label': 'MEGANSETT HARBOR', 'value': 'MEGANSETT HARBOR' },
  { 'label': 'MEHERRIN RIVER', 'value': 'MEHERRIN RIVER' },
  { 'label': 'MELBOURNE HARBOR', 'value': 'MELBOURNE HARBOR' },
  { 'label': 'MENEMSHA CREEK', 'value': 'MENEMSHA CREEK' },
  { 'label': 'MERMENTAU RIVER', 'value': 'MERMENTAU RIVER' },
  { 'label': 'MERRICONEAG SOUND', 'value': 'MERRICONEAG SOUND' },
  { 'label': 'MERRIMACK RIVER', 'value': 'MERRIMACK RIVER' },
  { 'label': 'MESSONGO CREEK', 'value': 'MESSONGO CREEK' },
  { 'label': 'METLAKATLA HARBOR', 'value': 'METLAKATLA HARBOR' },
  { 'label': 'METROFANIA BAY', 'value': 'METROFANIA BAY' },
  { 'label': 'MIAMI RIVER', 'value': 'MIAMI RIVER' },
  { 'label': 'MICHOUD CANAL', 'value': 'MICHOUD CANAL' },
  { 'label': 'MIDDLE BAY', 'value': 'MIDDLE BAY' },
  { 'label': 'MIDDLE CHANNEL', 'value': 'MIDDLE CHANNEL' },
  {
    'label': 'MIDDLE HARBOR, WEST BASIN',
    'value': 'MIDDLE HARBOR, WEST BASIN'
  },
  { 'label': 'MIDDLE RIVER', 'value': 'MIDDLE RIVER' },
  { 'label': 'MIDDLE RIVER PASSAGE', 'value': 'MIDDLE RIVER PASSAGE' },
  {
    'label': 'MIDLAND BEACH ACCESS LOWER BAY',
    'value': 'MIDLAND BEACH ACCESS LOWER BAY'
  },
  { 'label': 'MILES RIVER', 'value': 'MILES RIVER' },
  { 'label': 'MILFORD HARBOR', 'value': 'MILFORD HARBOR' },
  { 'label': 'MILFORD HAVEN', 'value': 'MILFORD HAVEN' },
  { 'label': 'MILL CREEK', 'value': 'MILL CREEK' },
  { 'label': 'MILL RIVER', 'value': 'MILL RIVER' },
  { 'label': 'MILLER CHANNEL', 'value': 'MILLER CHANNEL' },
  { 'label': 'MILTON HARBOR', 'value': 'MILTON HARBOR' },
  { 'label': 'MINE CREEK', 'value': 'MINE CREEK' },
  { 'label': 'MINGO RIVER', 'value': 'MINGO RIVER' },
  { 'label': 'MINNESOTA RIVER', 'value': 'MINNESOTA RIVER' },
  {
    'label': 'MINNESOTT BEACH CHANNEL',
    'value': 'MINNESOTT BEACH CHANNEL'
  },
  { 'label': 'MISPILLION RIVER', 'value': 'MISPILLION RIVER' },
  { 'label': 'MISSION BAY', 'value': 'MISSION BAY' },
  { 'label': 'MISSISQUOI RIVER', 'value': 'MISSISQUOI RIVER' },
  { 'label': 'MISSISSIPPI RIV', 'value': 'MISSISSIPPI RIV' },
  { 'label': 'MISSISSIPPI RIVER', 'value': 'MISSISSIPPI RIVER' },
  {
    'label': 'MISSISSIPPI RIVER-GULF OUTLET',
    'value': 'MISSISSIPPI RIVER-GULF OUTLET'
  },
  {
    'label': 'MISSISSIPPI RIVER, SOUTH PASS',
    'value': 'MISSISSIPPI RIVER, SOUTH PASS'
  },
  {
    'label': 'MISSISSIPPI RIVER, SOUTHWEST PASS',
    'value': 'MISSISSIPPI RIVER, SOUTHWEST PASS'
  },
  { 'label': 'MISSISSIPPI SOUND', 'value': 'MISSISSIPPI SOUND' },
  { 'label': 'MISSOURI RIVER', 'value': 'MISSOURI RIVER' },
  { 'label': 'MITCHELL BAY', 'value': 'MITCHELL BAY' },
  { 'label': 'MOBILE BAY', 'value': 'MOBILE BAY' },
  { 'label': 'MOBILE RIVER', 'value': 'MOBILE RIVER' },
  { 'label': 'MOBJACK BAY', 'value': 'MOBJACK BAY' },
  { 'label': 'MOKELUMNE RIVER', 'value': 'MOKELUMNE RIVER' },
  { 'label': 'MONONGAHELA RIVER', 'value': 'MONONGAHELA RIVER' },
  { 'label': 'MONROE CREEK', 'value': 'MONROE CREEK' },
  { 'label': 'MONTCALM LANDING', 'value': 'MONTCALM LANDING' },
  { 'label': 'MONTEREY HARBOR', 'value': 'MONTEREY HARBOR' },
  { 'label': 'MONTEZUMA SLOUGH', 'value': 'MONTEZUMA SLOUGH' },
  { 'label': 'MOORING ANCHORS', 'value': 'MOORING ANCHORS' },
  { 'label': 'MOOSE COVE', 'value': 'MOOSE COVE' },
  { 'label': 'MOOSE RIVER', 'value': 'MOOSE RIVER' },
  { 'label': 'MORATTICO CREEK', 'value': 'MORATTICO CREEK' },
  { 'label': 'MORICHES BAY', 'value': 'MORICHES BAY' },
  { 'label': 'MORICHES INLET', 'value': 'MORICHES INLET' },
  { 'label': 'MORRO BAY HARBOR', 'value': 'MORRO BAY HARBOR' },
  { 'label': 'MORSEBY PASS', 'value': 'MORSEBY PASS' },
  { 'label': 'MOSES LAKE', 'value': 'MOSES LAKE' },
  { 'label': 'MOSQUITO PASS', 'value': 'MOSQUITO PASS' },
  { 'label': 'MOSS LANDING HARBOR', 'value': 'MOSS LANDING HARBOR' },
  { 'label': 'MOUNT DESERT NARROWS', 'value': 'MOUNT DESERT NARROWS' },
  { 'label': 'MOUNT HOPE BAY', 'value': 'MOUNT HOPE BAY' },
  { 'label': 'MT SINAI HARBOR', 'value': 'MT SINAI HARBOR' },
  { 'label': 'MULBERRY CREEK', 'value': 'MULBERRY CREEK' },
  { 'label': 'MULBERRY FORK RIVER', 'value': 'MULBERRY FORK RIVER' },
  { 'label': 'MULLICA RIVER', 'value': 'MULLICA RIVER' },
  { 'label': 'MULTNOMAH CHANNEL', 'value': 'MULTNOMAH CHANNEL' },
  { 'label': 'MURDERKILL RIVER', 'value': 'MURDERKILL RIVER' },
  { 'label': 'MURRELLS INLET', 'value': 'MURRELLS INLET' },
  { 'label': 'MUSCONGUS BAY', 'value': 'MUSCONGUS BAY' },
  { 'label': 'MUSCONGUS SOUND', 'value': 'MUSCONGUS SOUND' },
  { 'label': 'MUSKINGUM RIVER', 'value': 'MUSKINGUM RIVER' },
  { 'label': 'MYSTIC RIVER', 'value': 'MYSTIC RIVER' },
  { 'label': 'NAGUABO HARBOR', 'value': 'NAGUABO HARBOR' },
  { 'label': 'NAKNEK RIVER', 'value': 'NAKNEK RIVER' },
  { 'label': 'NANDUA CREEK', 'value': 'NANDUA CREEK' },
  { 'label': 'NANJEMOY CREEK', 'value': 'NANJEMOY CREEK' },
  { 'label': 'NANSEMOND RIVER', 'value': 'NANSEMOND RIVER' },
  { 'label': 'NANTICOKE RIVER', 'value': 'NANTICOKE RIVER' },
  { 'label': 'NANTICOKE RIVER ACCESS', 'value': 'NANTICOKE RIVER ACCESS' },
  {
    'label': 'NANTUCKET HARBOR OF REGUGE',
    'value': 'NANTUCKET HARBOR OF REGUGE'
  },
  { 'label': 'NANTUCKET SOUND', 'value': 'NANTUCKET SOUND' },
  {
    'label': 'NANTUCKET SOUND, NORTH CHANNEL',
    'value': 'NANTUCKET SOUND, NORTH CHANNEL'
  },
  { 'label': 'NAPA RIVER', 'value': 'NAPA RIVER' },
  { 'label': 'NAPAGUE BAY', 'value': 'NAPAGUE BAY' },
  { 'label': 'NAPEAGUE HARBOR', 'value': 'NAPEAGUE HARBOR' },
  { 'label': 'NAPLES BAY', 'value': 'NAPLES BAY' },
  {
    'label': 'NAPLES/MARCO PASS CHANNEL',
    'value': 'NAPLES/MARCO PASS CHANNEL'
  },
  { 'label': 'NARRAGANSETT BAY', 'value': 'NARRAGANSETT BAY' },
  {
    'label': 'NARRAGANSETT BAY, EAST PASSAGE',
    'value': 'NARRAGANSETT BAY, EAST PASSAGE'
  },
  {
    'label': 'NARRAGANSETT BAY, WEST PASSAGE',
    'value': 'NARRAGANSETT BAY, WEST PASSAGE'
  },
  { 'label': 'NARRAGAUGUS RIVER', 'value': 'NARRAGAUGUS RIVER' },
  {
    'label': 'NARROWS OF LAKE CHAMPLAIN',
    'value': 'NARROWS OF LAKE CHAMPLAIN'
  },
  { 'label': 'NASSAU RIVER', 'value': 'NASSAU RIVER' },
  { 'label': 'NASSAWADOX CREEK', 'value': 'NASSAWADOX CREEK' },
  { 'label': 'NATALBANY RIVER', 'value': 'NATALBANY RIVER' },
  { 'label': 'NAUSET HARBOR', 'value': 'NAUSET HARBOR' },
  { 'label': 'NAVESINK RIVER', 'value': 'NAVESINK RIVER' },
  { 'label': 'NAVY CHANNEL', 'value': 'NAVY CHANNEL' },
  { 'label': 'NAWILIWILI HARBOR', 'value': 'NAWILIWILI HARBOR' },
  { 'label': 'NEABSCO CREEK', 'value': 'NEABSCO CREEK' },
  { 'label': 'NEAH BAY', 'value': 'NEAH BAY' },
  { 'label': 'NEALE SOUND', 'value': 'NEALE SOUND' },
  { 'label': 'NECANICUM RIVER', 'value': 'NECANICUM RIVER' },
  { 'label': 'NECHES RIVER', 'value': 'NECHES RIVER' },
  { 'label': 'NEGRO BAR CHANNEL', 'value': 'NEGRO BAR CHANNEL' },
  { 'label': 'NEHALEM BAY', 'value': 'NEHALEM BAY' },
  { 'label': 'NEPONSET RIVER', 'value': 'NEPONSET RIVER' },
  { 'label': 'NESTUCCA BAY', 'value': 'NESTUCCA BAY' },
  { 'label': 'NETORTS BAY', 'value': 'NETORTS BAY' },
  { 'label': 'NEUSE RIVER', 'value': 'NEUSE RIVER' },
  { 'label': 'NEUSE RIVER ACCESS', 'value': 'NEUSE RIVER ACCESS' },
  { 'label': 'NEW HARBOR', 'value': 'NEW HARBOR' },
  { 'label': 'NEW HAVEN HARBOR', 'value': 'NEW HAVEN HARBOR' },
  {
    'label': 'NEW JERSEY COASTAL WATERWAY',
    'value': 'NEW JERSEY COASTAL WATERWAY'
  },
  {
    'label': 'NEW JERSEY INTRACOASTAL WATERWAY',
    'value': 'NEW JERSEY INTRACOASTAL WATERWAY'
  },
  { 'label': 'NEW LONDON HARBOR', 'value': 'NEW LONDON HARBOR' },
  { 'label': 'NEW MEADOWS RIVER', 'value': 'NEW MEADOWS RIVER' },
  { 'label': 'NEW PASS', 'value': 'NEW PASS' },
  { 'label': 'NEW RIVER', 'value': 'NEW RIVER' },
  { 'label': 'NEW ROCHELLE HARBOR', 'value': 'NEW ROCHELLE HARBOR' },
  { 'label': 'NEW TOPSAIL INLET', 'value': 'NEW TOPSAIL INLET' },
  { 'label': 'NEWARK BAY', 'value': 'NEWARK BAY' },
  { 'label': 'NEWARK SLOUGH', 'value': 'NEWARK SLOUGH' },
  { 'label': 'NEWBEGUN CREEK', 'value': 'NEWBEGUN CREEK' },
  { 'label': 'NEWPORT BAY HARBOR', 'value': 'NEWPORT BAY HARBOR' },
  { 'label': 'NEWPORT HARBOR', 'value': 'NEWPORT HARBOR' },
  { 'label': 'NEWPORT NEWS CREEK', 'value': 'NEWPORT NEWS CREEK' },
  { 'label': 'NEWTOWN CREEK', 'value': 'NEWTOWN CREEK' },
  { 'label': 'NIAGARA RIVER', 'value': 'NIAGARA RIVER' },
  { 'label': 'NIANTIC BAY', 'value': 'NIANTIC BAY' },
  { 'label': 'NICHOLS PASSAGE', 'value': 'NICHOLS PASSAGE' },
  { 'label': 'NIHAU DEEP WATER LOOP', 'value': 'NIHAU DEEP WATER LOOP' },
  { 'label': 'NIKISHKA BAY', 'value': 'NIKISHKA BAY' },
  { 'label': 'NINILCHIK HARBOR', 'value': 'NINILCHIK HARBOR' },
  { 'label': 'NOATAK RIVER', 'value': 'NOATAK RIVER' },
  { 'label': 'NOMINI CREEK', 'value': 'NOMINI CREEK' },
  { 'label': 'NORFOLK HARBOR', 'value': 'NORFOLK HARBOR' },
  { 'label': 'NORTH BAY', 'value': 'NORTH BAY' },
  {
    'label': 'NORTH CAROLINA COASTAL WATERWAY',
    'value': 'NORTH CAROLINA COASTAL WATERWAY'
  },
  { 'label': 'NORTH EDISTO RIVER', 'value': 'NORTH EDISTO RIVER' },
  { 'label': 'NORTH HAVEN HARBOR', 'value': 'NORTH HAVEN HARBOR' },
  { 'label': 'NORTH PORTLAND HARBOR', 'value': 'NORTH PORTLAND HARBOR' },
  {
    'label': 'NORTH PRONG SCHOONER BAYOU',
    'value': 'NORTH PRONG SCHOONER BAYOU'
  },
  { 'label': 'NORTH RIVER', 'value': 'NORTH RIVER' },
  { 'label': 'NORTH SEA HARBOR', 'value': 'NORTH SEA HARBOR' },
  {
    'label': 'NORTHEAST CAPE FEAR RIVER',
    'value': 'NORTHEAST CAPE FEAR RIVER'
  },
  { 'label': 'NORTHEAST HARBOR', 'value': 'NORTHEAST HARBOR' },
  { 'label': 'NORTHEAST RIVER', 'value': 'NORTHEAST RIVER' },
  { 'label': 'NORTHPORT BAY', 'value': 'NORTHPORT BAY' },
  { 'label': 'NORTHUMBERLAND STRAIT', 'value': 'NORTHUMBERLAND STRAIT' },
  { 'label': 'NORTHWEST COVE', 'value': 'NORTHWEST COVE' },
  { 'label': 'NORTHWEST HARBOR', 'value': 'NORTHWEST HARBOR' },
  { 'label': 'NORTON SOUND', 'value': 'NORTON SOUND' },
  { 'label': 'NORWALK HARBOR', 'value': 'NORWALK HARBOR' },
  { 'label': 'NOYACK BAY', 'value': 'NOYACK BAY' },
  { 'label': 'NOYO RIVER', 'value': 'NOYO RIVER' },
  { 'label': 'NUECES BAY', 'value': 'NUECES BAY' },
  { 'label': 'NUKA BAY', 'value': 'NUKA BAY' },
  { 'label': 'NUSHAGAK RIVER', 'value': 'NUSHAGAK RIVER' },
  { 'label': 'OAHU DEEP WATER LOOP', 'value': 'OAHU DEEP WATER LOOP' },
  { 'label': 'OAK BAY', 'value': 'OAK BAY' },
  { 'label': 'OAK BLUFFS HARBOR', 'value': 'OAK BLUFFS HARBOR' },
  { 'label': 'OAK HABOR', 'value': 'OAK HABOR' },
  { 'label': 'OAK ISLAND CHANNEL', 'value': 'OAK ISLAND CHANNEL' },
  { 'label': 'OAKLAND HARBOR', 'value': 'OAKLAND HARBOR' },
  { 'label': 'OBION RIVER', 'value': 'OBION RIVER' },
  { 'label': 'OCCOHANNOCK CREEK', 'value': 'OCCOHANNOCK CREEK' },
  { 'label': 'OCCOQUAN CREEK', 'value': 'OCCOQUAN CREEK' },
  { 'label': 'OCCUPESSATUXET COVE', 'value': 'OCCUPESSATUXET COVE' },
  { 'label': 'OCMULGEE RIVER', 'value': 'OCMULGEE RIVER' },
  { 'label': 'OCONEE RIVER', 'value': 'OCONEE RIVER' },
  { 'label': 'OCRACOKE INLET', 'value': 'OCRACOKE INLET' },
  { 'label': 'OFFATTS BAYOU', 'value': 'OFFATTS BAYOU' },
  { 'label': 'OGEECHEE RIVER', 'value': 'OGEECHEE RIVER' },
  { 'label': 'OHIO RIVER', 'value': 'OHIO RIVER' },
  { 'label': 'OKEECHOBEE WATERWAY', 'value': 'OKEECHOBEE WATERWAY' },
  { 'label': 'OKLAWAHA RIVER', 'value': 'OKLAWAHA RIVER' },
  { 'label': 'OLD BRAZOS RIVER', 'value': 'OLD BRAZOS RIVER' },
  { 'label': 'OLD PLANTATION CREEK', 'value': 'OLD PLANTATION CREEK' },
  { 'label': 'OLD RIVER', 'value': 'OLD RIVER' },
  { 'label': 'OLD ROAD BAY', 'value': 'OLD ROAD BAY' },
  { 'label': 'OLD TAMPA BAY', 'value': 'OLD TAMPA BAY' },
  { 'label': 'OLDSMAN CREEK', 'value': 'OLDSMAN CREEK' },
  { 'label': 'ONANCOCK CREEK', 'value': 'ONANCOCK CREEK' },
  { 'label': 'ONONDAGA LAKE', 'value': 'ONONDAGA LAKE' },
  { 'label': 'ONSET BAY', 'value': 'ONSET BAY' },
  { 'label': 'ORCUTTS HARBOR', 'value': 'ORCUTTS HARBOR' },
  { 'label': 'OREGON INLET', 'value': 'OREGON INLET' },
  { 'label': 'ORIENT HARBOR', 'value': 'ORIENT HARBOR' },
  { 'label': 'OROWOC CREEK', 'value': 'OROWOC CREEK' },
  { 'label': 'OSAGE RIVER', 'value': 'OSAGE RIVER' },
  {
    'label': 'OSSABAW SOUND NORTH CHANNEL',
    'value': 'OSSABAW SOUND NORTH CHANNEL'
  },
  {
    'label': 'OSSABAW SOUND SOUTH CHANNEL',
    'value': 'OSSABAW SOUND SOUTH CHANNEL'
  },
  { 'label': 'OSTRICA CANAL', 'value': 'OSTRICA CANAL' },
  { 'label': 'OSWEGO CANAL', 'value': 'OSWEGO CANAL' },
  { 'label': 'OTTER CREEK', 'value': 'OTTER CREEK' },
  { 'label': 'OUACHITA RIVER', 'value': 'OUACHITA RIVER' },
  { 'label': 'OUTER HARBOR', 'value': 'OUTER HARBOR' },
  {
    'label': 'OUTER HARBOR, EAST CHANNEL',
    'value': 'OUTER HARBOR, EAST CHANNEL'
  },
  {
    'label': 'OUTER HARBOR, WEST CHANNEL',
    'value': 'OUTER HARBOR, WEST CHANNEL'
  },
  {
    'label': 'OUTER SANTA BARBARA PASSAGE',
    'value': 'OUTER SANTA BARBARA PASSAGE'
  },
  { 'label': 'OWLS HEAD HARBOR', 'value': 'OWLS HEAD HARBOR' },
  { 'label': 'OYSTER BAY', 'value': 'OYSTER BAY' },
  { 'label': 'OYSTER BAY HARBOR', 'value': 'OYSTER BAY HARBOR' },
  { 'label': 'OYSTER CHANNEL', 'value': 'OYSTER CHANNEL' },
  { 'label': 'OYSTER RIVER', 'value': 'OYSTER RIVER' },
  { 'label': 'OZONA CHANNEL', 'value': 'OZONA CHANNEL' },
  {
    'label': 'PACIFIC DEEP WATER ACCESS',
    'value': 'PACIFIC DEEP WATER ACCESS'
  },
  {
    'label': 'PACIFIC DEEP WATER SPINE',
    'value': 'PACIFIC DEEP WATER SPINE'
  },
  {
    'label': 'PACIFIC DEEP WATER SPUR',
    'value': 'PACIFIC DEEP WATER SPUR'
  },
  { 'label': 'PACIFIC-PANAMA ACCESS', 'value': 'PACIFIC-PANAMA ACCESS' },
  { 'label': 'PADDY CREEK', 'value': 'PADDY CREEK' },
  { 'label': 'PADILLA BAY', 'value': 'PADILLA BAY' },
  { 'label': 'PAGAN RIVER', 'value': 'PAGAN RIVER' },
  { 'label': 'PAGAN RIVER ACCESS', 'value': 'PAGAN RIVER ACCESS' },
  { 'label': 'PAILOLO CHANNEL', 'value': 'PAILOLO CHANNEL' },
  { 'label': 'PALM HARBOR', 'value': 'PALM HARBOR' },
  { 'label': 'PALMAS ALTAS HARBOR', 'value': 'PALMAS ALTAS HARBOR' },
  { 'label': 'PAMLICO RIVER', 'value': 'PAMLICO RIVER' },
  { 'label': 'PAMLICO RIVER ACCESS', 'value': 'PAMLICO RIVER ACCESS' },
  { 'label': 'PAMLICO SOUND', 'value': 'PAMLICO SOUND' },
  { 'label': 'PAMUNKEY RIVER', 'value': 'PAMUNKEY RIVER' },
  { 'label': 'PANAMA CANAL', 'value': 'PANAMA CANAL' },
  { 'label': 'PANNAMAQUAN RIVER', 'value': 'PANNAMAQUAN RIVER' },
  { 'label': 'PANTEGO CREEK', 'value': 'PANTEGO CREEK' },
  { 'label': 'PARISH CREEK', 'value': 'PARISH CREEK' },
  { 'label': 'PARKER CREEK', 'value': 'PARKER CREEK' },
  { 'label': 'PARKER RIVER', 'value': 'PARKER RIVER' },
  { 'label': 'PARROTTS CREEK', 'value': 'PARROTTS CREEK' },
  { 'label': 'PASCAGOULA HARBOR', 'value': 'PASCAGOULA HARBOR' },
  { 'label': 'PASCAGOULA RIVER', 'value': 'PASCAGOULA RIVER' },
  { 'label': 'PASQUOTANK RIVER', 'value': 'PASQUOTANK RIVER' },
  { 'label': 'PASS A LOUTRE', 'value': 'PASS A LOUTRE' },
  { 'label': 'PASS MANCHAC', 'value': 'PASS MANCHAC' },
  { 'label': 'PASS-A-GRILLE CHANNEL', 'value': 'PASS-A-GRILLE CHANNEL' },
  { 'label': 'PASSAIC RIVER', 'value': 'PASSAIC RIVER' },
  { 'label': 'PASSAMAQUODDY BAY', 'value': 'PASSAMAQUODDY BAY' },
  { 'label': 'PATAPSCO RIVER', 'value': 'PATAPSCO RIVER' },
  { 'label': 'PATAPSCO RIVER ACCESS', 'value': 'PATAPSCO RIVER ACCESS' },
  { 'label': 'PATCHOGUE RIVER', 'value': 'PATCHOGUE RIVER' },
  { 'label': 'PATUXENT RIVER', 'value': 'PATUXENT RIVER' },
  { 'label': 'PAWCATUCK RIVER', 'value': 'PAWCATUCK RIVER' },
  { 'label': 'PAWTUXET COVE', 'value': 'PAWTUXET COVE' },
  { 'label': 'PAWTUXET RIVER', 'value': 'PAWTUXET RIVER' },
  { 'label': 'PEALE PASSAGE', 'value': 'PEALE PASSAGE' },
  { 'label': 'PEARD BAY', 'value': 'PEARD BAY' },
  { 'label': 'PEARL HARBOR', 'value': 'PEARL HARBOR' },
  { 'label': 'PEASE COVE', 'value': 'PEASE COVE' },
  { 'label': 'PECONIC RIVER', 'value': 'PECONIC RIVER' },
  { 'label': 'PECONIC RIVER ACCESS', 'value': 'PECONIC RIVER ACCESS' },
  { 'label': 'PELICAN HARBOR', 'value': 'PELICAN HARBOR' },
  { 'label': 'PEMBROKE CREEK', 'value': 'PEMBROKE CREEK' },
  { 'label': 'PEND OREILLE RIVER', 'value': 'PEND OREILLE RIVER' },
  { 'label': 'PENN COVE', 'value': 'PENN COVE' },
  { 'label': 'PENOBSCOT BAY', 'value': 'PENOBSCOT BAY' },
  { 'label': 'PENOBSCOT RIVER', 'value': 'PENOBSCOT RIVER' },
  { 'label': 'PENSACOLA BAY', 'value': 'PENSACOLA BAY' },
  { 'label': 'PEPPER CREEK', 'value': 'PEPPER CREEK' },
  { 'label': 'PERDIDO BAY', 'value': 'PERDIDO BAY' },
  { 'label': 'PERDIDO PASS', 'value': 'PERDIDO PASS' },
  { 'label': 'PERIL STRAIT', 'value': 'PERIL STRAIT' },
  { 'label': 'PERQUIMANS RIVER', 'value': 'PERQUIMANS RIVER' },
  { 'label': 'PERRY HARBOR', 'value': 'PERRY HARBOR' },
  { 'label': 'PETALUMA RIVER', 'value': 'PETALUMA RIVER' },
  { 'label': 'PHOEBUS CHANNEL', 'value': 'PHOEBUS CHANNEL' },
  { 'label': 'PIANKATANK RIVER', 'value': 'PIANKATANK RIVER' },
  { 'label': 'PIGEON COVE', 'value': 'PIGEON COVE' },
  { 'label': 'PIGEON RIVER', 'value': 'PIGEON RIVER' },
  { 'label': 'PILLAR POINT HARBOR', 'value': 'PILLAR POINT HARBOR' },
  { 'label': 'PINE ISLAND SOUND', 'value': 'PINE ISLAND SOUND' },
  { 'label': 'PINPOINT CREEK', 'value': 'PINPOINT CREEK' },
  { 'label': 'PISCATAQUA RIVER', 'value': 'PISCATAQUA RIVER' },
  { 'label': 'PISCATAWAY CREEK', 'value': 'PISCATAWAY CREEK' },
  { 'label': 'PITHLACHASCOTEE RIVER', 'value': 'PITHLACHASCOTEE RIVER' },
  { 'label': 'PLATTSBURGH HARBOR', 'value': 'PLATTSBURGH HARBOR' },
  { 'label': 'PLEASANT BAY', 'value': 'PLEASANT BAY' },
  { 'label': 'PLEASANT POINT HARBOR', 'value': 'PLEASANT POINT HARBOR' },
  { 'label': 'PLEASANT RIVER', 'value': 'PLEASANT RIVER' },
  { 'label': 'PLUMPER SOUND', 'value': 'PLUMPER SOUND' },
  { 'label': 'PLYMOUTH HARBOR', 'value': 'PLYMOUTH HARBOR' },
  { 'label': 'POCASSET HARBOR', 'value': 'POCASSET HARBOR' },
  { 'label': 'POCASSET RIVER', 'value': 'POCASSET RIVER' },
  { 'label': 'POCOMOKE RIVER', 'value': 'POCOMOKE RIVER' },
  { 'label': 'POCOMOKE RIVER ACCESS', 'value': 'POCOMOKE RIVER ACCESS' },
  { 'label': 'POCOMOKE SOUND', 'value': 'POCOMOKE SOUND' },
  { 'label': 'POKAI BAY', 'value': 'POKAI BAY' },
  { 'label': 'PONCE DE LEON INLET', 'value': 'PONCE DE LEON INLET' },
  { 'label': 'PONCE HARBOR', 'value': 'PONCE HARBOR' },
  { 'label': 'POQUONNOCK RIVER', 'value': 'POQUONNOCK RIVER' },
  { 'label': 'POROPOTANK CREEK', 'value': 'POROPOTANK CREEK' },
  { 'label': 'PORT ALEXANDER', 'value': 'PORT ALEXANDER' },
  { 'label': 'PORT ALLEN HARBOR', 'value': 'PORT ALLEN HARBOR' },
  { 'label': 'PORT ANGELES HARBOR', 'value': 'PORT ANGELES HARBOR' },
  { 'label': 'PORT BOLIVAR HARBOR', 'value': 'PORT BOLIVAR HARBOR' },
  { 'label': 'PORT BROWN', 'value': 'PORT BROWN' },
  { 'label': 'PORT CANAL', 'value': 'PORT CANAL' },
  { 'label': 'PORT CHESTER HARBOR', 'value': 'PORT CHESTER HARBOR' },
  { 'label': 'PORT CLARENCE', 'value': 'PORT CLARENCE' },
  { 'label': 'PORT CLYDE HARBOR', 'value': 'PORT CLYDE HARBOR' },
  { 'label': 'PORT ENSENADA HONDA', 'value': 'PORT ENSENADA HONDA' },
  { 'label': 'PORT EVERGLADES HARBOR', 'value': 'PORT EVERGLADES HARBOR' },
  { 'label': 'PORT GAMBLE BAY', 'value': 'PORT GAMBLE BAY' },
  { 'label': 'PORT GARDNER', 'value': 'PORT GARDNER' },
  { 'label': 'PORT GRAHAM', 'value': 'PORT GRAHAM' },
  { 'label': 'PORT HAMMOND', 'value': 'PORT HAMMOND' },
  { 'label': 'PORT HARVEY', 'value': 'PORT HARVEY' },
  { 'label': 'PORT HEIDEN', 'value': 'PORT HEIDEN' },
  { 'label': 'PORT HENRY HARBOR', 'value': 'PORT HENRY HARBOR' },
  { 'label': 'PORT HESS', 'value': 'PORT HESS' },
  { 'label': 'PORT HUENEME', 'value': 'PORT HUENEME' },
  {
    'label': 'PORT ISABEL SIDE CHANNEL',
    'value': 'PORT ISABEL SIDE CHANNEL'
  },
  { 'label': 'PORT JEFFERSON HARBOR', 'value': 'PORT JEFFERSON HARBOR' },
  { 'label': 'PORT KENT HARBOR', 'value': 'PORT KENT HARBOR' },
  { 'label': 'PORT LAVACA CHANNEL', 'value': 'PORT LAVACA CHANNEL' },
  {
    'label': 'PORT LAVACA CHANNEL ACCESS',
    'value': 'PORT LAVACA CHANNEL ACCESS'
  },
  { 'label': 'PORT LUDLOW', 'value': 'PORT LUDLOW' },
  { 'label': 'PORT MANSFIELD CHANNEL', 'value': 'PORT MANSFIELD CHANNEL' },
  { 'label': 'PORT MOLLER', 'value': 'PORT MOLLER' },
  {
    'label': 'PORT OF PALM BEACH ACCESS',
    'value': 'PORT OF PALM BEACH ACCESS'
  },
  { 'label': 'PORT ORCHARD', 'value': 'PORT ORCHARD' },
  { 'label': 'PORT ORCHARDD', 'value': 'PORT ORCHARDD' },
  { 'label': 'PORT ORFORD', 'value': 'PORT ORFORD' },
  { 'label': 'PORT ROYAL SOUND', 'value': 'PORT ROYAL SOUND' },
  { 'label': 'PORT SUTTON CHANNEL', 'value': 'PORT SUTTON CHANNEL' },
  { 'label': 'PORT TOWNSEND', 'value': 'PORT TOWNSEND' },
  { 'label': 'PORTER RIVER', 'value': 'PORTER RIVER' },
  { 'label': 'PORTERSVILLE BAY', 'value': 'PORTERSVILLE BAY' },
  { 'label': 'PORTLAND CANAL', 'value': 'PORTLAND CANAL' },
  { 'label': 'PORTLAND HARBOR', 'value': 'PORTLAND HARBOR' },
  { 'label': 'PORTSMOUTH HARBOR', 'value': 'PORTSMOUTH HARBOR' },
  { 'label': 'POST CANAL', 'value': 'POST CANAL' },
  { 'label': 'POTEAU RIVER', 'value': 'POTEAU RIVER' },
  { 'label': 'POTOMAC RIVER', 'value': 'POTOMAC RIVER' },
  { 'label': 'POTOMAC RIVER ACCESS', 'value': 'POTOMAC RIVER ACCESS' },
  { 'label': 'POTOWOMUT RIVER', 'value': 'POTOWOMUT RIVER' },
  { 'label': 'POWELL RIVER', 'value': 'POWELL RIVER' },
  { 'label': 'PRESIDENT CHANNEL', 'value': 'PRESIDENT CHANNEL' },
  { 'label': 'PRESUMPSCOTT RIVER', 'value': 'PRESUMPSCOTT RIVER' },
  { 'label': 'PREVOST PASSAGE', 'value': 'PREVOST PASSAGE' },
  { 'label': 'PRIEST RIVER', 'value': 'PRIEST RIVER' },
  { 'label': 'PRINCE RUPERT HARBOR', 'value': 'PRINCE RUPERT HARBOR' },
  { 'label': 'PRINCE WILLIAM SOUND', 'value': 'PRINCE WILLIAM SOUND' },
  { 'label': 'PRINCES BAY', 'value': 'PRINCES BAY' },
  { 'label': 'PROSPECT HARBOR', 'value': 'PROSPECT HARBOR' },
  { 'label': 'PROVIDENCE RIVER', 'value': 'PROVIDENCE RIVER' },
  {
    'label': 'PROVIDENCE RIVER ACCESS',
    'value': 'PROVIDENCE RIVER ACCESS'
  },
  { 'label': 'PROVINCETOWN HARBOR', 'value': 'PROVINCETOWN HARBOR' },
  { 'label': 'PRUDHOE BAY', 'value': 'PRUDHOE BAY' },
  {
    'label': 'PUERTO RICO DEEP WATER LOOP',
    'value': 'PUERTO RICO DEEP WATER LOOP'
  },
  {
    'label': 'PUERTO RICO-PANAMA ACCESS',
    'value': 'PUERTO RICO-PANAMA ACCESS'
  },
  { 'label': 'PUGET SOUND', 'value': 'PUGET SOUND' },
  { 'label': 'PUNALUU HARBOR', 'value': 'PUNALUU HARBOR' },
  { 'label': 'PUNGO CREEK', 'value': 'PUNGO CREEK' },
  { 'label': 'PUNGO RIVER', 'value': 'PUNGO RIVER' },
  { 'label': 'PUNGOTEAGUE CREEK', 'value': 'PUNGOTEAGUE CREEK' },
  { 'label': 'PYLADES CHANNEL', 'value': 'PYLADES CHANNEL' },
  { 'label': 'QUAHOG BAY', 'value': 'QUAHOG BAY' },
  { 'label': 'QUANTICO CREEK', 'value': 'QUANTICO CREEK' },
  { 'label': 'QUEEN CHARLOTTE STRAIT', 'value': 'QUEEN CHARLOTTE STRAIT' },
  { 'label': 'QUEEN CREEK', 'value': 'QUEEN CREEK' },
  { 'label': 'QUEENSTOWN HARBOR', 'value': 'QUEENSTOWN HARBOR' },
  { 'label': 'QUEENSWAY BAY', 'value': 'QUEENSWAY BAY' },
  { 'label': 'QUEETS RIVER', 'value': 'QUEETS RIVER' },
  { 'label': 'QUILCENE BAY', 'value': 'QUILCENE BAY' },
  { 'label': 'QUILLAYUTE RIVER', 'value': 'QUILLAYUTE RIVER' },
  { 'label': 'QUINAULT RIVER', 'value': 'QUINAULT RIVER' },
  { 'label': 'QUINBY CREEK', 'value': 'QUINBY CREEK' },
  { 'label': 'QUINCY BAY', 'value': 'QUINCY BAY' },
  { 'label': 'QUINNIPIAC RIVER', 'value': 'QUINNIPIAC RIVER' },
  { 'label': 'QUISSETT HARBOR', 'value': 'QUISSETT HARBOR' },
  { 'label': 'RACOON CREEK', 'value': 'RACOON CREEK' },
  { 'label': 'RAHWAY RIVER', 'value': 'RAHWAY RIVER' },
  { 'label': 'RAINY LAKE', 'value': 'RAINY LAKE' },
  { 'label': 'RAINY RIVER', 'value': 'RAINY RIVER' },
  { 'label': 'RAINY RIVER ACCESS', 'value': 'RAINY RIVER ACCESS' },
  { 'label': 'RANCOCAS RIVER', 'value': 'RANCOCAS RIVER' },
  { 'label': 'RAPPAHANNOCK RIVER', 'value': 'RAPPAHANNOCK RIVER' },
  { 'label': 'RARITAN BAY', 'value': 'RARITAN BAY' },
  { 'label': 'RARITAN RIVER', 'value': 'RARITAN RIVER' },
  { 'label': 'RED HOOK CHANNEL', 'value': 'RED HOOK CHANNEL' },
  { 'label': 'RED RIVER', 'value': 'RED RIVER' },
  { 'label': 'REDBIRD CREEK', 'value': 'REDBIRD CREEK' },
  { 'label': 'REDWOOD CITY HARBOR', 'value': 'REDWOOD CITY HARBOR' },
  { 'label': 'RESURRECTION BAY', 'value': 'RESURRECTION BAY' },
  { 'label': 'REVILLAGIGADO CHANNEL', 'value': 'REVILLAGIGADO CHANNEL' },
  { 'label': 'REYNOLDS CHANNEL', 'value': 'REYNOLDS CHANNEL' },
  { 'label': 'RHODE ISLAND SOUND', 'value': 'RHODE ISLAND SOUND' },
  { 'label': 'RHODE RIVER', 'value': 'RHODE RIVER' },
  { 'label': 'RICE CREEK', 'value': 'RICE CREEK' },
  { 'label': 'RICH INLET', 'value': 'RICH INLET' },
  { 'label': 'RICH PASSAGE', 'value': 'RICH PASSAGE' },
  { 'label': 'RICHARDSON BAY', 'value': 'RICHARDSON BAY' },
  { 'label': 'RICHMOND HARBOR', 'value': 'RICHMOND HARBOR' },
  { 'label': 'RICHMOND ISLAND HARBOR', 'value': 'RICHMOND ISLAND HARBOR' },
  { 'label': 'RINCON CANAL', 'value': 'RINCON CANAL' },
  { 'label': 'ROANOKE RIVER', 'value': 'ROANOKE RIVER' },
  { 'label': 'ROANOKE SOUND', 'value': 'ROANOKE SOUND' },
  { 'label': 'ROBERT S. KERR LAKE', 'value': 'ROBERT S. KERR LAKE' },
  { 'label': 'ROCK HALL HARBOR', 'value': 'ROCK HALL HARBOR' },
  { 'label': 'ROCK HARBOR', 'value': 'ROCK HARBOR' },
  { 'label': 'ROCKAWAY INLET', 'value': 'ROCKAWAY INLET' },
  { 'label': 'ROCKLAND HARBOR', 'value': 'ROCKLAND HARBOR' },
  { 'label': 'ROCKPORT HARBOR', 'value': 'ROCKPORT HARBOR' },
  { 'label': 'RODERE CANAL', 'value': 'RODERE CANAL' },
  { 'label': 'ROGUE RIVER', 'value': 'ROGUE RIVER' },
  { 'label': 'ROQUE ISLAND HARBOR', 'value': 'ROQUE ISLAND HARBOR' },
  { 'label': 'ROSARIO STRAIT', 'value': 'ROSARIO STRAIT' },
  { 'label': 'ROSIER CREEK', 'value': 'ROSIER CREEK' },
  { 'label': 'ROSS CREEK', 'value': 'ROSS CREEK' },
  { 'label': 'ROUGE RIVER', 'value': 'ROUGE RIVER' },
  { 'label': 'ROYAL RIVER', 'value': 'ROYAL RIVER' },
  { 'label': 'RUDEE INLET', 'value': 'RUDEE INLET' },
  { 'label': 'RYE HARBOR', 'value': 'RYE HARBOR' },
  { 'label': 'SAANICH INLET', 'value': 'SAANICH INLET' },
  { 'label': 'SABINE LAKE', 'value': 'SABINE LAKE' },
  {
    'label': 'SABINE NECHES WATERWAY, TX',
    'value': 'SABINE NECHES WATERWAY, TX'
  },
  { 'label': 'SABINE PASS', 'value': 'SABINE PASS' },
  { 'label': 'SABINE RIVER', 'value': 'SABINE RIVER' },
  { 'label': 'SABINE RIVER ACCESS', 'value': 'SABINE RIVER ACCESS' },
  { 'label': 'SACO BAY', 'value': 'SACO BAY' },
  { 'label': 'SACO RIVER', 'value': 'SACO RIVER' },
  { 'label': 'SACRAMENTO RIVER', 'value': 'SACRAMENTO RIVER' },
  {
    'label': 'SACRAMENTO RIVER SHIP CHANNEL',
    'value': 'SACRAMENTO RIVER SHIP CHANNEL'
  },
  { 'label': 'SAG HARBOR', 'value': 'SAG HARBOR' },
  { 'label': 'SAGAMORE CREEK', 'value': 'SAGAMORE CREEK' },
  { 'label': 'SAGINAW RIVER', 'value': 'SAGINAW RIVER' },
  { 'label': 'SAKONNET RIVER', 'value': 'SAKONNET RIVER' },
  { 'label': 'SALEM CANAL', 'value': 'SALEM CANAL' },
  { 'label': 'SALEM HARBOR', 'value': 'SALEM HARBOR' },
  { 'label': 'SALEM RIVER', 'value': 'SALEM RIVER' },
  { 'label': 'SALINAS BAY', 'value': 'SALINAS BAY' },
  { 'label': 'SALKEHATCHIE RIVER', 'value': 'SALKEHATCHIE RIVER' },
  { 'label': 'SALMON CREEK', 'value': 'SALMON CREEK' },
  { 'label': 'SALSBURY COVE', 'value': 'SALSBURY COVE' },
  { 'label': 'SALT BAYOU', 'value': 'SALT BAYOU' },
  { 'label': 'SAMISH BAY', 'value': 'SAMISH BAY' },
  { 'label': 'SAMPIT RIVER', 'value': 'SAMPIT RIVER' },
  { 'label': 'SAMPIT RIVER ACCESS', 'value': 'SAMPIT RIVER ACCESS' },
  {
    'label': 'SAMPIT RIVER AND POINT CHANNELS, SC',
    'value': 'SAMPIT RIVER AND POINT CHANNELS, SC'
  },
  { 'label': 'SAN ANTONIO BAY', 'value': 'SAN ANTONIO BAY' },
  { 'label': 'SAN ANTONIO RIVER', 'value': 'SAN ANTONIO RIVER' },
  { 'label': 'SAN BERNARD RIVER', 'value': 'SAN BERNARD RIVER' },
  { 'label': 'SAN CARLOS BAY', 'value': 'SAN CARLOS BAY' },
  { 'label': 'SAN CLEMENTE HARBOR', 'value': 'SAN CLEMENTE HARBOR' },
  { 'label': 'SAN DIEGO HARBOR', 'value': 'SAN DIEGO HARBOR' },
  { 'label': 'SAN FRANCISCO BAY', 'value': 'SAN FRANCISCO BAY' },
  {
    'label': 'SAN FRANCISCO BAY ENTRANCE',
    'value': 'SAN FRANCISCO BAY ENTRANCE'
  },
  { 'label': 'SAN FRANCISCO HARBOR', 'value': 'SAN FRANCISCO HARBOR' },
  { 'label': 'SAN GABRIEL RIVER', 'value': 'SAN GABRIEL RIVER' },
  { 'label': 'SAN JOAQUIN RIVER', 'value': 'SAN JOAQUIN RIVER' },
  { 'label': 'SAN JUAN DE FUCA', 'value': 'SAN JUAN DE FUCA' },
  { 'label': 'SAN JUAN HARBOR', 'value': 'SAN JUAN HARBOR' },
  { 'label': 'SAN LUIS OBISPO BAY', 'value': 'SAN LUIS OBISPO BAY' },
  { 'label': 'SAN PABLO BAY', 'value': 'SAN PABLO BAY' },
  { 'label': 'SAN PEDRO BAY', 'value': 'SAN PEDRO BAY' },
  { 'label': 'SAN PEDRO CHANNEL', 'value': 'SAN PEDRO CHANNEL' },
  {
    'label': 'SAN PEDRO CHANNEL ACCESS',
    'value': 'SAN PEDRO CHANNEL ACCESS'
  },
  { 'label': 'SAN PEDRO CHANNEL SPUR', 'value': 'SAN PEDRO CHANNEL SPUR' },
  { 'label': 'SAN RAFAEL CREEK', 'value': 'SAN RAFAEL CREEK' },
  { 'label': 'SAND COVE', 'value': 'SAND COVE' },
  { 'label': 'SANDY HOOK BAY', 'value': 'SANDY HOOK BAY' },
  { 'label': 'SANDY HOOK CHANNEL', 'value': 'SANDY HOOK CHANNEL' },
  { 'label': 'SANTA BARBARA CHANNEL', 'value': 'SANTA BARBARA CHANNEL' },
  {
    'label': 'SANTA BARBARA CHANNEL ACCESS',
    'value': 'SANTA BARBARA CHANNEL ACCESS'
  },
  { 'label': 'SANTA BARBARA HARBOR', 'value': 'SANTA BARBARA HARBOR' },
  { 'label': 'SANTA CRUZ HARBOR', 'value': 'SANTA CRUZ HARBOR' },
  { 'label': 'SANTA MONICA BAY', 'value': 'SANTA MONICA BAY' },
  { 'label': 'SANTA MONICA HARBOR', 'value': 'SANTA MONICA HARBOR' },
  { 'label': 'SANTEE RIVER', 'value': 'SANTEE RIVER' },
  { 'label': 'SAPELO RIVER', 'value': 'SAPELO RIVER' },
  { 'label': 'SAPELO SOUND', 'value': 'SAPELO SOUND' },
  { 'label': 'SARAH CREEK', 'value': 'SARAH CREEK' },
  { 'label': 'SARATOGA PASSAGE', 'value': 'SARATOGA PASSAGE' },
  { 'label': 'SASANOA RIVER', 'value': 'SASANOA RIVER' },
  { 'label': 'SASSAFRAS RIVER', 'value': 'SASSAFRAS RIVER' },
  { 'label': 'SATELLITE CHANNEL', 'value': 'SATELLITE CHANNEL' },
  { 'label': 'SATILLA RIVER', 'value': 'SATILLA RIVER' },
  { 'label': 'SAUGATUCK RIVER', 'value': 'SAUGATUCK RIVER' },
  { 'label': 'SAUGUS RIVER', 'value': 'SAUGUS RIVER' },
  { 'label': 'SAVANNAH RIVER', 'value': 'SAVANNAH RIVER' },
  { 'label': 'SCAMMON BAY', 'value': 'SCAMMON BAY' },
  { 'label': 'SCARBORO RIVER', 'value': 'SCARBORO RIVER' },
  { 'label': 'SCHOODIC HARBOR', 'value': 'SCHOODIC HARBOR' },
  { 'label': 'SCHOONER BAYOU CANAL', 'value': 'SCHOONER BAYOU CANAL' },
  { 'label': 'SCHUYLKILL CANAL', 'value': 'SCHUYLKILL CANAL' },
  { 'label': 'SCHUYLKILL RIVER', 'value': 'SCHUYLKILL RIVER' },
  { 'label': 'SCITUATE HARBOR', 'value': 'SCITUATE HARBOR' },
  { 'label': 'SCOTTS CREEK', 'value': 'SCOTTS CREEK' },
  { 'label': 'SCUPPERNONG RIVER', 'value': 'SCUPPERNONG RIVER' },
  { 'label': 'SEA LEVEL CHANNEL', 'value': 'SEA LEVEL CHANNEL' },
  { 'label': 'SEAL HARBOR', 'value': 'SEAL HARBOR' },
  { 'label': 'SEAPLANE HARBOR', 'value': 'SEAPLANE HARBOR' },
  { 'label': 'SEARSPORT HARBOR', 'value': 'SEARSPORT HARBOR' },
  { 'label': 'SEBASCO HARBOR', 'value': 'SEBASCO HARBOR' },
  { 'label': 'SEBASTION INLET', 'value': 'SEBASTION INLET' },
  { 'label': 'SEEKONK RIVER', 'value': 'SEEKONK RIVER' },
  { 'label': 'SELAWIK RIVER', 'value': 'SELAWIK RIVER' },
  { 'label': 'SELDOVIA HARBOR', 'value': 'SELDOVIA HARBOR' },
  { 'label': 'SENECA CANAL', 'value': 'SENECA CANAL' },
  { 'label': 'SENECA CREEK', 'value': 'SENECA CREEK' },
  { 'label': 'SESUIT HARBOR', 'value': 'SESUIT HARBOR' },
  { 'label': 'SEVERN RIVER', 'value': 'SEVERN RIVER' },
  { 'label': 'SEWARD HARBOR', 'value': 'SEWARD HARBOR' },
  { 'label': 'SHALLOTTE INLET', 'value': 'SHALLOTTE INLET' },
  { 'label': 'SHALLOTTE RIVER', 'value': 'SHALLOTTE RIVER' },
  { 'label': 'SHALLOWBAG BAY', 'value': 'SHALLOWBAG BAY' },
  { 'label': 'SHARK RIVER', 'value': 'SHARK RIVER' },
  { 'label': 'SHEEPSCOT BAY', 'value': 'SHEEPSCOT BAY' },
  { 'label': 'SHEEPSCOT RIVER', 'value': 'SHEEPSCOT RIVER' },
  { 'label': 'SHEEPSHEAD BAY', 'value': 'SHEEPSHEAD BAY' },
  {
    'label': 'SHEFFIELD ISLAND HARBOR',
    'value': 'SHEFFIELD ISLAND HARBOR'
  },
  { 'label': 'SHELIKOF STRAIT', 'value': 'SHELIKOF STRAIT' },
  { 'label': 'SHELTER ISLAND SOUND', 'value': 'SHELTER ISLAND SOUND' },
  { 'label': 'SHEM CREEK', 'value': 'SHEM CREEK' },
  { 'label': 'SHILSHOLE BAY', 'value': 'SHILSHOLE BAY' },
  { 'label': 'SHINNECOCK BAY', 'value': 'SHINNECOCK BAY' },
  { 'label': 'SHINNECOCK CANAL', 'value': 'SHINNECOCK CANAL' },
  { 'label': 'SHINNECOCK INLET', 'value': 'SHINNECOCK INLET' },
  { 'label': 'SHIPPING CREEK', 'value': 'SHIPPING CREEK' },
  { 'label': 'SHIPYARD CREEK', 'value': 'SHIPYARD CREEK' },
  { 'label': 'SIDNEY CHANNEL', 'value': 'SIDNEY CHANNEL' },
  { 'label': 'SILETZ BAY', 'value': 'SILETZ BAY' },
  { 'label': 'SILVER LAKE', 'value': 'SILVER LAKE' },
  { 'label': 'SIMILK BAY', 'value': 'SIMILK BAY' },
  { 'label': 'SIMS BAYOU', 'value': 'SIMS BAYOU' },
  { 'label': 'SINCLAIR INLET', 'value': 'SINCLAIR INLET' },
  { 'label': 'SITKA HARBOR', 'value': 'SITKA HARBOR' },
  { 'label': 'SITKALIDAK STRAIT', 'value': 'SITKALIDAK STRAIT' },
  { 'label': 'SIUSLAW RIVER', 'value': 'SIUSLAW RIVER' },
  { 'label': 'SKAGET BAY', 'value': 'SKAGET BAY' },
  { 'label': 'SKAGIT RIVER', 'value': 'SKAGIT RIVER' },
  { 'label': 'SKAKET BAY', 'value': 'SKAKET BAY' },
  {
    'label': 'SKAKET BAY - PORT SUSAYN, WA',
    'value': 'SKAKET BAY - PORT SUSAYN, WA'
  },
  { 'label': 'SKAMOKAWA CREEK', 'value': 'SKAMOKAWA CREEK' },
  { 'label': 'SKAMOKAWA SLOUGH', 'value': 'SKAMOKAWA SLOUGH' },
  { 'label': 'SKIDAWAY RIVER', 'value': 'SKIDAWAY RIVER' },
  { 'label': 'SKIPANON CHANNEL', 'value': 'SKIPANON CHANNEL' },
  { 'label': 'SLAUGHTER CREEK', 'value': 'SLAUGHTER CREEK' },
  { 'label': 'SLIP NO. 1', 'value': 'SLIP NO. 1' },
  { 'label': 'SLOCUM CREEK', 'value': 'SLOCUM CREEK' },
  { 'label': 'SLOCUM CREEK ACCESS', 'value': 'SLOCUM CREEK ACCESS' },
  { 'label': 'SMITH CREEK', 'value': 'SMITH CREEK' },
  { 'label': 'SMITH RIVER', 'value': 'SMITH RIVER' },
  { 'label': 'SMITHS COVE', 'value': 'SMITHS COVE' },
  { 'label': 'SMITHS CREEK', 'value': 'SMITHS CREEK' },
  { 'label': 'SMITHTOWN BAY', 'value': 'SMITHTOWN BAY' },
  { 'label': 'SMYRNA RIVER', 'value': 'SMYRNA RIVER' },
  { 'label': 'SNAKE RIVER', 'value': 'SNAKE RIVER' },
  { 'label': 'SNOHOMISH RIVER', 'value': 'SNOHOMISH RIVER' },
  { 'label': 'SOMES SOUND', 'value': 'SOMES SOUND' },
  { 'label': 'SOMMES SOUND', 'value': 'SOMMES SOUND' },
  { 'label': 'SONOMA CREEK', 'value': 'SONOMA CREEK' },
  { 'label': 'SOUTH BAY', 'value': 'SOUTH BAY' },
  { 'label': 'SOUTH BRISTOL HARBOR', 'value': 'SOUTH BRISTOL HARBOR' },
  {
    'label': 'SOUTH CAROLINA COASTAL WATERWAY',
    'value': 'SOUTH CAROLINA COASTAL WATERWAY'
  },
  { 'label': 'SOUTH CREEK', 'value': 'SOUTH CREEK' },
  { 'label': 'SOUTH EDISTO RIVER', 'value': 'SOUTH EDISTO RIVER' },
  { 'label': 'SOUTH RIVER', 'value': 'SOUTH RIVER' },
  { 'label': 'SOUTH SLOUGH', 'value': 'SOUTH SLOUGH' },
  { 'label': 'SOUTHEAST BASIN', 'value': 'SOUTHEAST BASIN' },
  { 'label': 'SOUTHOLD BAY', 'value': 'SOUTHOLD BAY' },
  { 'label': 'SOUTHPORT HARBOR', 'value': 'SOUTHPORT HARBOR' },
  { 'label': 'SOUTHPORT, NC', 'value': 'SOUTHPORT, NC' },
  { 'label': 'SOUTHWEST HARBOR', 'value': 'SOUTHWEST HARBOR' },
  { 'label': 'SOUTHWEST SLIP', 'value': 'SOUTHWEST SLIP' },
  { 'label': 'SPARKMAN-YBOR CHANNELS', 'value': 'SPARKMAN-YBOR CHANNELS' },
  { 'label': 'SPARROWS POINT CHANNEL', 'value': 'SPARROWS POINT CHANNEL' },
  { 'label': 'SPESUTIE NARROWS', 'value': 'SPESUTIE NARROWS' },
  { 'label': 'SPOKANE RIVER', 'value': 'SPOKANE RIVER' },
  { 'label': 'SQUAMSCOTT RIVER', 'value': 'SQUAMSCOTT RIVER' },
  { 'label': 'ST GEORGE RIVER', 'value': 'ST GEORGE RIVER' },
  { 'label': 'ST. ALBANS HARBOR', 'value': 'ST. ALBANS HARBOR' },
  { 'label': 'ST. ANDREW BAY', 'value': 'ST. ANDREW BAY' },
  { 'label': 'ST. AUGUSTINE HARBOR', 'value': 'ST. AUGUSTINE HARBOR' },
  { 'label': 'ST. AUGUSTINE INLET', 'value': 'ST. AUGUSTINE INLET' },
  { 'label': 'ST. CATHERINE SOUND', 'value': 'ST. CATHERINE SOUND' },
  { 'label': 'ST. CATHERINES SOUND', 'value': 'ST. CATHERINES SOUND' },
  { 'label': 'ST. CLAIR RIVER', 'value': 'ST. CLAIR RIVER' },
  { 'label': 'ST. CLAIR RIVER ACCESS', 'value': 'ST. CLAIR RIVER ACCESS' },
  { 'label': 'ST. CLEMENT BAY', 'value': 'ST. CLEMENT BAY' },
  { 'label': 'ST. CROIX RIVER', 'value': 'ST. CROIX RIVER' },
  { 'label': 'ST. FRANCIS RIVER', 'value': 'ST. FRANCIS RIVER' },
  { 'label': 'ST. GEORGE RIVER', 'value': 'ST. GEORGE RIVER' },
  {
    'label': 'ST. GEORGE RIVER ACCESS',
    'value': 'ST. GEORGE RIVER ACCESS'
  },
  { 'label': 'ST. HELENA SOUND', 'value': 'ST. HELENA SOUND' },
  { 'label': 'ST. JEROME CREEK', 'value': 'ST. JEROME CREEK' },
  { 'label': 'ST. JOE RIVER', 'value': 'ST. JOE RIVER' },
  { 'label': 'ST. JOHNS RIVER', 'value': 'ST. JOHNS RIVER' },
  { 'label': 'ST. JONES RIVER', 'value': 'ST. JONES RIVER' },
  { 'label': 'ST. JOSEPH BAY', 'value': 'ST. JOSEPH BAY' },
  { 'label': 'ST. JOSEPH RIVER', 'value': 'ST. JOSEPH RIVER' },
  { 'label': 'ST. LAWRENCE RIVER', 'value': 'ST. LAWRENCE RIVER' },
  { 'label': 'ST. LEONARD CREEK', 'value': 'ST. LEONARD CREEK' },
  { 'label': 'ST. LOUIS BAY', 'value': 'ST. LOUIS BAY' },
  { 'label': 'ST. LUCIE INLET', 'value': 'ST. LUCIE INLET' },
  { 'label': 'ST. LUCIE RIVER', 'value': 'ST. LUCIE RIVER' },
  { 'label': 'ST. LUCIE RIVER ACCESS', 'value': 'ST. LUCIE RIVER ACCESS' },
  { 'label': 'ST. MARKS RIVER', 'value': 'ST. MARKS RIVER' },
  { 'label': 'ST. MARYS RIVER', 'value': 'ST. MARYS RIVER' },
  { 'label': 'ST. MARYS RIVER', 'value': 'ST. MARYS RIVER' },
  { 'label': 'ST. MICHAELS HARBOR', 'value': 'ST. MICHAELS HARBOR' },
  { 'label': 'ST. PATRICKS CREEK', 'value': 'ST. PATRICKS CREEK' },
  { 'label': 'ST. PAUL HARBOR', 'value': 'ST. PAUL HARBOR' },
  { 'label': 'ST. PETERS CREEK', 'value': 'ST. PETERS CREEK' },
  { 'label': 'ST. SIMONS SOUND', 'value': 'ST. SIMONS SOUND' },
  { 'label': 'ST. THOMAS HARBOR', 'value': 'ST. THOMAS HARBOR' },
  { 'label': 'STAGE HARBOR', 'value': 'STAGE HARBOR' },
  { 'label': 'STAMFORD HARBOR', 'value': 'STAMFORD HARBOR' },
  { 'label': 'STARLINGS CREEK', 'value': 'STARLINGS CREEK' },
  { 'label': 'STAVE ISLAND HARBOR', 'value': 'STAVE ISLAND HARBOR' },
  { 'label': 'STEINHATCHEE RIVER', 'value': 'STEINHATCHEE RIVER' },
  { 'label': 'STEPHENS PASSAGE', 'value': 'STEPHENS PASSAGE' },
  { 'label': 'STIKINE RIVER', 'value': 'STIKINE RIVER' },
  { 'label': 'STIKINE STRAIT', 'value': 'STIKINE STRAIT' },
  { 'label': 'STILLAGUAMISH RIVER', 'value': 'STILLAGUAMISH RIVER' },
  { 'label': 'STILLPOND CREEK', 'value': 'STILLPOND CREEK' },
  { 'label': 'STOCKTON HARBOR', 'value': 'STOCKTON HARBOR' },
  { 'label': 'STONEY CREEK', 'value': 'STONEY CREEK' },
  { 'label': 'STONINGTON HARBOR', 'value': 'STONINGTON HARBOR' },
  { 'label': 'STONO RIVER', 'value': 'STONO RIVER' },
  { 'label': 'STONY BROOK HARBOR', 'value': 'STONY BROOK HARBOR' },
  { 'label': 'STRAIT OF CANSO', 'value': 'STRAIT OF CANSO' },
  { 'label': 'STRAIT OF GEORGIA', 'value': 'STRAIT OF GEORGIA' },
  { 'label': 'STUART CHANNEL', 'value': 'STUART CHANNEL' },
  { 'label': 'STURGEON BAY', 'value': 'STURGEON BAY' },
  { 'label': 'STUTTS CREEK', 'value': 'STUTTS CREEK' },
  { 'label': 'SUISON BAY', 'value': 'SUISON BAY' },
  { 'label': 'SUISON BAY CHANNEL', 'value': 'SUISON BAY CHANNEL' },
  { 'label': 'SUKKWAN STRAIT', 'value': 'SUKKWAN STRAIT' },
  { 'label': 'SULLIVAN HARBOR', 'value': 'SULLIVAN HARBOR' },
  { 'label': 'SUMNER STRAIT', 'value': 'SUMNER STRAIT' },
  { 'label': 'SUMPAWANUS INLET', 'value': 'SUMPAWANUS INLET' },
  { 'label': 'SUSITNA RIVER', 'value': 'SUSITNA RIVER' },
  { 'label': 'SUSQUEHANNA RIVER', 'value': 'SUSQUEHANNA RIVER' },
  { 'label': 'SUWANNEE RIVER', 'value': 'SUWANNEE RIVER' },
  { 'label': 'SUWANNEE SOUND', 'value': 'SUWANNEE SOUND' },
  { 'label': 'SWAN CREEK', 'value': 'SWAN CREEK' },
  {
    'label': 'SWANQUARTER BAY CHANNEL',
    'value': 'SWANQUARTER BAY CHANNEL'
  },
  {
    'label': 'SWANQUARTER BAY CHANNEL ACCESS',
    'value': 'SWANQUARTER BAY CHANNEL ACCESS'
  },
  { 'label': 'SWANSON CHANNEL', 'value': 'SWANSON CHANNEL' },
  { 'label': 'SWANSON PASS', 'value': 'SWANSON PASS' },
  { 'label': 'SWIFT CREEK', 'value': 'SWIFT CREEK' },
  { 'label': 'SWINOMISH CHANNEL', 'value': 'SWINOMISH CHANNEL' },
  { 'label': 'SYDNOR BAYOU', 'value': 'SYDNOR BAYOU' },
  { 'label': 'TABBS CREEK', 'value': 'TABBS CREEK' },
  { 'label': 'TAIYA INLET', 'value': 'TAIYA INLET' },
  { 'label': 'TALLABOA BAY', 'value': 'TALLABOA BAY' },
  { 'label': 'TAMPA BAY', 'value': 'TAMPA BAY' },
  { 'label': 'TANANA RIVER', 'value': 'TANANA RIVER' },
  { 'label': 'TANGIER SOUND', 'value': 'TANGIER SOUND' },
  { 'label': 'TAR RIVER', 'value': 'TAR RIVER' },
  { 'label': 'TASKINAS CREEK', 'value': 'TASKINAS CREEK' },
  { 'label': 'TAUNTON RIVER', 'value': 'TAUNTON RIVER' },
  { 'label': 'TAYLOR BAYOU', 'value': 'TAYLOR BAYOU' },
  {
    'label': 'TAYLOR BAYOU, EAST BASIN',
    'value': 'TAYLOR BAYOU, EAST BASIN'
  },
  { 'label': 'TAYLORS CREEK', 'value': 'TAYLORS CREEK' },
  { 'label': 'TCHEFUNCTE RIVER', 'value': 'TCHEFUNCTE RIVER' },
  { 'label': 'TEACHES HOLE CHANNEL', 'value': 'TEACHES HOLE CHANNEL' },
  { 'label': 'TEDIOUS CREEK', 'value': 'TEDIOUS CREEK' },
  { 'label': 'TENANTS HARBOR', 'value': 'TENANTS HARBOR' },
  { 'label': 'TENNESSEE RIVER', 'value': 'TENNESSEE RIVER' },
  {
    'label': 'TENNESSEE TOMBIGBEE WATERWAY',
    'value': 'TENNESSEE TOMBIGBEE WATERWAY'
  },
  { 'label': 'TENSAS RIVER', 'value': 'TENSAS RIVER' },
  { 'label': 'TERREBONE BAY', 'value': 'TERREBONE BAY' },
  { 'label': 'TEXAS CITY CHANNEL', 'value': 'TEXAS CITY CHANNEL' },
  { 'label': 'THAMES RIVER', 'value': 'THAMES RIVER' },
  { 'label': 'THATCHER PASS', 'value': 'THATCHER PASS' },
  { 'label': 'THE JAWS', 'value': 'THE JAWS' },
  { 'label': 'THE NARROWS', 'value': 'THE NARROWS' },
  { 'label': 'THE RIGOLETS', 'value': 'THE RIGOLETS' },
  { 'label': 'THE STRAITS', 'value': 'THE STRAITS' },
  { 'label': 'THEODORE SHIP CHANNEL', 'value': 'THEODORE SHIP CHANNEL' },
  { 'label': 'THIMBLE SHOAL CHANNEL', 'value': 'THIMBLE SHOAL CHANNEL' },
  { 'label': 'THOROFARE BAY CHANNEL', 'value': 'THOROFARE BAY CHANNEL' },
  {
    'label': 'THOROFARE BAY CHANNEL ACCESS',
    'value': 'THOROFARE BAY CHANNEL ACCESS'
  },
  { 'label': 'THREE MILE CREEK', 'value': 'THREE MILE CREEK' },
  { 'label': 'TIANA BAY', 'value': 'TIANA BAY' },
  { 'label': 'TICONDEROGA RIVER', 'value': 'TICONDEROGA RIVER' },
  { 'label': 'TIDEWATER REACH', 'value': 'TIDEWATER REACH' },
  { 'label': 'TILGHMAN ISLAND HARBOR', 'value': 'TILGHMAN ISLAND HARBOR' },
  { 'label': 'TILLAMOOK BAY', 'value': 'TILLAMOOK BAY' },
  { 'label': 'TIMBALIER BAY', 'value': 'TIMBALIER BAY' },
  { 'label': 'TOGIAK BAY', 'value': 'TOGIAK BAY' },
  { 'label': 'TOMBIGBEE RIVER', 'value': 'TOMBIGBEE RIVER' },
  { 'label': 'TOMS RIVER', 'value': 'TOMS RIVER' },
  { 'label': 'TONGASS NARROWS', 'value': 'TONGASS NARROWS' },
  { 'label': 'TOPSAIL BEACH CHANNEL', 'value': 'TOPSAIL BEACH CHANNEL' },
  { 'label': 'TOTUSKEY CREEK', 'value': 'TOTUSKEY CREEK' },
  { 'label': 'TOWN CREEK', 'value': 'TOWN CREEK' },
  { 'label': 'TOWN RIVER', 'value': 'TOWN RIVER' },
  { 'label': 'TOWNSEND INLET', 'value': 'TOWNSEND INLET' },
  { 'label': 'TRADEWATER RIVER', 'value': 'TRADEWATER RIVER' },
  { 'label': 'TRED AVON RIVER', 'value': 'TRED AVON RIVER' },
  { 'label': 'TRENT RIVER', 'value': 'TRENT RIVER' },
  { 'label': 'TRES PALACIOS BAY', 'value': 'TRES PALACIOS BAY' },
  { 'label': 'TRINCOMALI CHANNEL', 'value': 'TRINCOMALI CHANNEL' },
  { 'label': 'TRINITY BAY', 'value': 'TRINITY BAY' },
  { 'label': 'TRINITY RIVER', 'value': 'TRINITY RIVER' },
  { 'label': 'TRINOMALI CHANNEL', 'value': 'TRINOMALI CHANNEL' },
  { 'label': 'TUCKAHOE RIVER', 'value': 'TUCKAHOE RIVER' },
  { 'label': 'TUCKER BAYOU', 'value': 'TUCKER BAYOU' },
  { 'label': 'TUCKERTON CREEK', 'value': 'TUCKERTON CREEK' },
  { 'label': 'TURKEY BEND', 'value': 'TURKEY BEND' },
  { 'label': 'TURN PORT', 'value': 'TURN PORT' },
  { 'label': 'TURNAGAIN ARM', 'value': 'TURNAGAIN ARM' },
  { 'label': 'TURNER CREEK', 'value': 'TURNER CREEK' },
  { 'label': 'TURTLE RIVER', 'value': 'TURTLE RIVER' },
  { 'label': 'TUXEDNI BAY', 'value': 'TUXEDNI BAY' },
  { 'label': 'TYASKIN CREEK', 'value': 'TYASKIN CREEK' },
  { 'label': 'TYBEE ROADS', 'value': 'TYBEE ROADS' },
  { 'label': 'TYLER CREEK', 'value': 'TYLER CREEK' },
  { 'label': 'UGASHIK RIVER', 'value': 'UGASHIK RIVER' },
  { 'label': 'UMBRELLA BAY', 'value': 'UMBRELLA BAY' },
  { 'label': 'UMPQUA RIVER', 'value': 'UMPQUA RIVER' },
  { 'label': 'UNALASKA BAY', 'value': 'UNALASKA BAY' },
  { 'label': 'UNIMAK PASS', 'value': 'UNIMAK PASS' },
  { 'label': 'UNION RIVER', 'value': 'UNION RIVER' },
  { 'label': 'UPPER BAY', 'value': 'UPPER BAY' },
  { 'label': 'UPPER GOOSE CREEK', 'value': 'UPPER GOOSE CREEK' },
  { 'label': 'UPPER MACHODOC CREEK', 'value': 'UPPER MACHODOC CREEK' },
  { 'label': 'UPPER SAN JACINTO BAY', 'value': 'UPPER SAN JACINTO BAY' },
  { 'label': 'UPPER THOROFARE', 'value': 'UPPER THOROFARE' },
  { 'label': 'URBANNA CREEK', 'value': 'URBANNA CREEK' },
  { 'label': 'VALDEZ HARBOR', 'value': 'VALDEZ HARBOR' },
  { 'label': 'VENICE INLET', 'value': 'VENICE INLET' },
  { 'label': 'VENTURA HARBOR', 'value': 'VENTURA HARBOR' },
  { 'label': 'VERDIGRIS RIVER', 'value': 'VERDIGRIS RIVER' },
  { 'label': 'VERMILION BAY', 'value': 'VERMILION BAY' },
  { 'label': 'VERMILION BAY OFFSHORE', 'value': 'VERMILION BAY OFFSHORE' },
  { 'label': 'VERMILION RIVER', 'value': 'VERMILION RIVER' },
  { 'label': 'VERNON RIVER', 'value': 'VERNON RIVER' },
  { 'label': 'VICTORIA BARGE CANAL', 'value': 'VICTORIA BARGE CANAL' },
  { 'label': 'VICTORIA HARBOR', 'value': 'VICTORIA HARBOR' },
  { 'label': 'VIEQUES PASSAGE', 'value': 'VIEQUES PASSAGE' },
  { 'label': 'VINEYARD HAVEN HARBOR', 'value': 'VINEYARD HAVEN HARBOR' },
  { 'label': 'VINEYARD SOUND', 'value': 'VINEYARD SOUND' },
  { 'label': 'VINTON WATERWAY', 'value': 'VINTON WATERWAY' },
  {
    'label': 'VIRGINIA INTRACOASTAL ACCESS',
    'value': 'VIRGINIA INTRACOASTAL ACCESS'
  },
  {
    'label': 'VIRGINIA INTRACOASTAL WATERWAY',
    'value': 'VIRGINIA INTRACOASTAL WATERWAY'
  },
  { 'label': 'WACCAMAW RIVER', 'value': 'WACCAMAW RIVER' },
  { 'label': 'WACHAPREAGUE CHANNEL', 'value': 'WACHAPREAGUE CHANNEL' },
  { 'label': 'WAIALUA BAY', 'value': 'WAIALUA BAY' },
  { 'label': 'WAKE ISLAND HARBOR', 'value': 'WAKE ISLAND HARBOR' },
  { 'label': 'WALLABOUT CHANNEL', 'value': 'WALLABOUT CHANNEL' },
  { 'label': 'WALLACE CHANNEL', 'value': 'WALLACE CHANNEL' },
  { 'label': 'WANDO RIVER', 'value': 'WANDO RIVER' },
  { 'label': 'WAREHAM HARBOR', 'value': 'WAREHAM HARBOR' },
  { 'label': 'WARREN RIVER', 'value': 'WARREN RIVER' },
  { 'label': 'WARROAD RIVER', 'value': 'WARROAD RIVER' },
  { 'label': 'WARWICK COVE', 'value': 'WARWICK COVE' },
  { 'label': 'WARWICK RIVER', 'value': 'WARWICK RIVER' },
  { 'label': 'WASHINGTON CANAL', 'value': 'WASHINGTON CANAL' },
  { 'label': 'WASHINGTON CHANNEL', 'value': 'WASHINGTON CHANNEL' },
  { 'label': 'WASSAW SOUND', 'value': 'WASSAW SOUND' },
  { 'label': 'WATCH HILL COVE', 'value': 'WATCH HILL COVE' },
  { 'label': 'WATEREE RIVER', 'value': 'WATEREE RIVER' },
  { 'label': 'WATSON BAYOU', 'value': 'WATSON BAYOU' },
  { 'label': 'WAYCAKE CREEK', 'value': 'WAYCAKE CREEK' },
  { 'label': 'WEEKS BAY', 'value': 'WEEKS BAY' },
  { 'label': 'WEIR RIVER', 'value': 'WEIR RIVER' },
  { 'label': 'WELLAND CANAL', 'value': 'WELLAND CANAL' },
  { 'label': 'WELLFLEET HARBOR', 'value': 'WELLFLEET HARBOR' },
  { 'label': 'WELLS HARBOR', 'value': 'WELLS HARBOR' },
  { 'label': 'WESKEAG RIVER', 'value': 'WESKEAG RIVER' },
  { 'label': 'WEST BASIN', 'value': 'WEST BASIN' },
  { 'label': 'WEST BAY', 'value': 'WEST BAY' },
  { 'label': 'WEST BRANCH', 'value': 'WEST BRANCH' },
  { 'label': 'WEST COTE BLANCHE BAY', 'value': 'WEST COTE BLANCHE BAY' },
  { 'label': 'WEST PEARL RIVER', 'value': 'WEST PEARL RIVER' },
  { 'label': 'WEST RIVER', 'value': 'WEST RIVER' },
  { 'label': 'WEST SOUND', 'value': 'WEST SOUND' },
  { 'label': 'WESTCHESTER CREEK', 'value': 'WESTCHESTER CREEK' },
  { 'label': 'WESTCOTT COVE', 'value': 'WESTCOTT COVE' },
  { 'label': 'WESTERN BAY', 'value': 'WESTERN BAY' },
  { 'label': 'WESTERN BRANCH', 'value': 'WESTERN BRANCH' },
  { 'label': 'WESTERN WAY', 'value': 'WESTERN WAY' },
  { 'label': 'WESTPORT HARBOR', 'value': 'WESTPORT HARBOR' },
  { 'label': 'WESTPORT RIVER', 'value': 'WESTPORT RIVER' },
  { 'label': 'WESTPORT SLOUGH', 'value': 'WESTPORT SLOUGH' },
  { 'label': 'WETHERSFIELD COVE', 'value': 'WETHERSFIELD COVE' },
  { 'label': 'WEYMOUTH BACK RIVER', 'value': 'WEYMOUTH BACK RIVER' },
  { 'label': 'WEYMOUTH FORE RIVER', 'value': 'WEYMOUTH FORE RIVER' },
  { 'label': 'WHALE BRANCH', 'value': 'WHALE BRANCH' },
  { 'label': 'WHEELER BAY', 'value': 'WHEELER BAY' },
  { 'label': 'WHITE OAK CREEK', 'value': 'WHITE OAK CREEK' },
  { 'label': 'WHITE OAK RIVER', 'value': 'WHITE OAK RIVER' },
  { 'label': 'WHITE POINT CHANNEL', 'value': 'WHITE POINT CHANNEL' },
  { 'label': 'WHITE RIVER', 'value': 'WHITE RIVER' },
  { 'label': 'WHITESTONE NARROWS', 'value': 'WHITESTONE NARROWS' },
  { 'label': 'WHITINGS CREEK', 'value': 'WHITINGS CREEK' },
  { 'label': 'WHITTIER HARBOR', 'value': 'WHITTIER HARBOR' },
  { 'label': 'WICKFORD HARBOR', 'value': 'WICKFORD HARBOR' },
  { 'label': 'WICOMICO RIVER', 'value': 'WICOMICO RIVER' },
  { 'label': 'WILLAMETTE RIVER', 'value': 'WILLAMETTE RIVER' },
  { 'label': 'WILLAPA BAY', 'value': 'WILLAPA BAY' },
  { 'label': 'WILLAPA RIVER', 'value': 'WILLAPA RIVER' },
  { 'label': 'WILLIAMS CREEK', 'value': 'WILLIAMS CREEK' },
  { 'label': 'WILLOUGHBY BAY', 'value': 'WILLOUGHBY BAY' },
  { 'label': 'WILMINGTON RIVER', 'value': 'WILMINGTON RIVER' },
  {
    'label': 'WILMINGTON RIVER ACCESS',
    'value': 'WILMINGTON RIVER ACCESS'
  },
  { 'label': 'WILSON POINT HARBOR', 'value': 'WILSON POINT HARBOR' },
  { 'label': 'WINTER HARBOR', 'value': 'WINTER HARBOR' },
  { 'label': 'WINTHROP HARBOR', 'value': 'WINTHROP HARBOR' },
  { 'label': 'WINYAH BAY', 'value': 'WINYAH BAY' },
  { 'label': 'WISHART POINT CHANNEL', 'value': 'WISHART POINT CHANNEL' },
  { 'label': 'WISHKAH RIVER', 'value': 'WISHKAH RIVER' },
  { 'label': 'WITCHMERE HARBOR', 'value': 'WITCHMERE HARBOR' },
  { 'label': 'WOLF RIVER', 'value': 'WOLF RIVER' },
  { 'label': 'WONSQUEAK HARBOR', 'value': 'WONSQUEAK HARBOR' },
  { 'label': 'WOOD ISLAND HARBOR', 'value': 'WOOD ISLAND HARBOR' },
  { 'label': 'WOODBRIDGE CREEK', 'value': 'WOODBRIDGE CREEK' },
  { 'label': 'WOODBURY CREEK', 'value': 'WOODBURY CREEK' },
  { 'label': 'WOODS HOLE CHANNEL', 'value': 'WOODS HOLE CHANNEL' },
  { 'label': 'WOOLEY POND', 'value': 'WOOLEY POND' },
  { 'label': 'WORMLEY CREEK', 'value': 'WORMLEY CREEK' },
  { 'label': 'WRANGELL HARBOR', 'value': 'WRANGELL HARBOR' },
  { 'label': 'WRANGELL NARROWS', 'value': 'WRANGELL NARROWS' },
  { 'label': 'WRIGHTS CREEK', 'value': 'WRIGHTS CREEK' },
  { 'label': 'WYE RIVER', 'value': 'WYE RIVER' },
  { 'label': 'YABUCOA HARBOR', 'value': 'YABUCOA HARBOR' },
  { 'label': 'YAKUTAT BAY', 'value': 'YAKUTAT BAY' },
  { 'label': 'YAQUINA BAY', 'value': 'YAQUINA BAY' },
  { 'label': 'YAQUINA RIVER', 'value': 'YAQUINA RIVER' },
  { 'label': 'YAZOO RIVER', 'value': 'YAZOO RIVER' },
  { 'label': 'YELLOW MILL CHANNEL', 'value': 'YELLOW MILL CHANNEL' },
  { 'label': 'YEOCOMICO RIVER', 'value': 'YEOCOMICO RIVER' },
  { 'label': 'YORK RIVER', 'value': 'YORK RIVER' },
  { 'label': 'YORK SPIT CHANNEL', 'value': 'YORK SPIT CHANNEL' },
  { 'label': 'YOUGHIOGHENY RIVER', 'value': 'YOUGHIOGHENY RIVER' },
  { 'label': 'YOUNGS BAY', 'value': 'YOUNGS BAY' },
  { 'label': 'YUKON RIVER', 'value': 'YUKON RIVER' }
];

export const waterwaysAllFilter: string = joinFilterV(arrWaterWays);

export const waterwaysOptions: Array<LabelValue> = [{ 'label': 'ALL', 'value': 'ALL' }, ...sortArray(arrWaterWays)];
