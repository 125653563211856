import React, { Component } from 'react';
import Header from '../HeaderLTE';
import SideBar from '../SideBarLTE';
import axios from 'axios';
import { withRouter } from 'react-router';
import UserProfile from '../UserProfile';
import '../../css/adminLTE-custom.css';
import { Modal, Button, Row } from 'react-bootstrap';
import Footer from '../Footer';
import InputBoxComponent from '../InputBoxComponent';
import SimpleReactValidator from 'simple-react-validator';
import InputMultiSelectComponent from '../InputMultiSelectComponent';
import InputCheckboxComponent from '../InputCheckboxComponent';

class CreateMembership extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectOptionsArr: [
				{ 'label': 'Yes', 'value': 'Yes' },
				{ 'label': 'No', 'value': 'No' }
			],
			client_fname: '',
			client_lname: '',
			client_email: '',
			company: '',
			max_no_entries: '',
			client_password: '',
			showDeleteModal: false,
			showSuccessModal: false,
			showFailureModal: false,
			multi_class_I: false,
			keyword: false,
			favorites: false,
			dmsg: false,
			classI: false,
			shortline: false,
			intermodal: false,
			ports: false,
		}

		this.validator = new SimpleReactValidator(
			{
				element: (message, className) => <div className='text-danger'>{message}</div>,
				validators: {
					phone: {  // name the rule
						message: 'The phone must be a valid contact number.',
						rule: (val, params, validator) => {
							return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/i)
						},
						messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
						required: true  // optional
					},
					email: {  // name the rule
						message: 'The email must be a valid email address.',
						rule: (val, params, validator) => {
							return validator.helpers.testRegex(val, /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)
						},
						messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
						required: true  // optional
					}
	
				}
	
	
			}
		);
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleMultiSelect = (name, valueArr) => {
		console.log(name)
		console.log(valueArr)
		console.log(valueArr.value)
		this.setState({
			[name]: valueArr.value
		})
	}
	// submit form
	createPrivateClient() {	
			
		if (this.validator.allValid()) {
			//alert("valid")
			try {
				axios.post(process.env.REACT_APP_BASE_URL + '/api/register-private-member',
					{
						headers: { 'Content-Type': 'application/json' },
						email: this.state.client_email,
						fname: this.state.client_fname,
						lname: this.state.client_lname,
						companyName: this.state.company,
						password: this.state.client_password,
						

					}).then(response => {
						console.log("response", response)
						if (response.data.error) {
							alert("Email already exist")
							//this.setState({ showFailureModal: true });
						} else {
							axios.post(process.env.REACT_APP_BASE_URL + '/api/create-private-member',
								{
									headers: { 'Content-Type': 'application/json' },
									admin_email: UserProfile.getEmail(),
									client_email: this.state.client_email,
									client_name: this.state.client_fname + ' ' + this.state.client_lname,
									company: this.state.company,
									

								}).then(response => {
									// handle your response;
									this.setState({ showSuccessModal: true });
								}).catch(function (error) {
									// handle your error
									this.setState({ showFailureModal: true });
								});

						}
					
					})
			} catch (error) {
				console.log(error)
				this.setState({ showFailureModal: true });
			}


		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	handleSuccessModal = () => {
		this.setState({
			showSuccessModal: false
		}, () => {
			this.props.history.push('/private-clients');
		});
	}

	handleCheckboxChange = (name, value) => {
		console.log(name)
		console.log(value)
		this.setState({ [name]: value });
	}

	render() {
		return (
			<div style={{ backgroundColor: '#222D32' }}>
				<Header />
				<SideBar />
				<div className='content-wrapper background-white'>
					<section className='content-header'>
						<div className='row'>
							<div className='col-md-offset-1 col-md-10'>
								<div className='col-md-12'>
									<div style={{ flex: '1 0 auto', color: 'rgba(0,0,0,.87)', fontSize: 22, fontWeight: 400, marginTop: 15, marginBottom: 30 }}>Create client</div>
									<form>
										<div className='col-md-4'>
											<div className='form-group'>
												<label>Client First Name</label>
												<InputBoxComponent name='client_fname' value={this.state.client_fname} onHandleInputChange={this.handleInputChange} />
												{this.validator.message('client_fname', this.state.client_fname, 'required')}
											</div>
										</div>
										<div className='col-md-4'>
											<div className='form-group'>
												<label>Client Last Name</label>
												<InputBoxComponent name='client_lname' value={this.state.client_lname} onHandleInputChange={this.handleInputChange} />
												{this.validator.message('client_lname', this.state.client_lname, 'required')}
											</div>
										</div>
										<div className='col-md-4'>
											<div className='form-group'>
												<label>Email address </label>
												<InputBoxComponent name='client_email' value={this.state.client_email} onHandleInputChange={this.handleInputChange} />
												{this.validator.message('client_email', this.state.contactEmail, 'email')}
												{this.validator.message('client_email', this.state.client_email, 'required')}
											</div>
										</div>
										<div className='col-md-4'>
											<div className='form-group'>
												<label>Company </label>
												<InputBoxComponent name='company' value={this.state.company} onHandleInputChange={this.handleInputChange} />
												{this.validator.message('company', this.state.company, 'required')}
											</div>
										</div>
										<div className='col-md-4'>
											<div className='form-group'>
												<label>Password </label>
												<InputBoxComponent name='client_password' value={this.state.client_password} onHandleInputChange={this.handleInputChange} />
												{this.validator.message('client_password', this.state.client_password, 'required')}
											</div>
										</div>
										{/* <div className='col-md-4'>
											<div className='form-group'>
												<label>Memberhip cost (in $)</label>
												<InputBoxComponent type='number' min='0' name='membership_level_cost' value={this.state.membership_level_cost} onHandleInputChange={this.handleInputChange} />
												{this.validator.message('membership name', this.state.membership_level_cost, 'required')}
											</div>
										</div>
										<div className='col-md-4'>
											<div className='form-group'>
												<label>Max listings allowed</label>
												<InputBoxComponent type='number' min='0' name='max_no_entries' value={this.state.max_no_entries} onHandleInputChange={this.handleInputChange} />
												{this.validator.message('membership name', this.state.max_no_entries, 'required')}
											</div>
										</div>
										<div className='col-md-4 clear-both margin-bottom-20'>
											<div className='form-group'>
												<label>Active</label>
												<InputMultiSelectComponent
													name='membership_is_active'
													value={this.state.membership_is_active}
													options={this.state.selectOptionsArr}
													onHandleSingleSelect={this.handleMultiSelect}
													isMulti={false}
												/>
												{this.validator.message('active', this.state.membership_is_active, 'required')}
											</div>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='multi_class_I'
												checked={this.state.multi_class_I}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Multi Class I'
											/>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='keyword'
												checked={this.state.keyword}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Keywords'
											/>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='favorites'
												checked={this.state.favorites}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Favorites List'
											/>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='dmsg'
												checked={this.state.dmsg}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Direct Message'
											/>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='classI'
												checked={this.state.classI}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Class I Railroads'
											/>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='shortline'
												checked={this.state.shortline}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Short Lines'
											/>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='intermodal'
												checked={this.state.intermodal}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Intermodal Terminals'
											/>
										</div>
										<div className='col-md-12 margin-bottom-10 clear-both'>
											<InputCheckboxComponent name='ports'
												checked={this.state.ports}
												onHandleCheckboxChange={this.handleCheckboxChange}
												label='Major Ports'  
											/>
										</div>*/}
										<div className='col-md-12 text-center' style={{ paddingTop: 20 }}>
											<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.createPrivateClient.bind(this)} >Create</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
				</div>
				<Modal show={this.state.showProfileSuccessModal} onHide={this.handleClose} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Verify Email
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								We have sent email verification link to your new email id, please verify it for next login. You are not able to Login until you verify it.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleProfileSuccessModal}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showSuccessModal} onHide={this.handleSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Private member added successfully.
							</h2>
							{/* <p className='text-muted font-size-16-imp margin-bottom-0'>
								Membership is added successfully.
							</p> */}
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleSuccessModal}>Close</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showFailureModal} onHide={this.handleFailureModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type error'>
								<div className='event-indicator '>
									<SVGIconError />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Error
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Something went wrong, try again later!
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleFailureModal}>Close</button>
					</Modal.Footer>
				</Modal>

				<Footer />
			</div>
		)
	}
}

export default withRouter(CreateMembership);

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)

const SVGIconError = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' />
	</svg>
)