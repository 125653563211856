const UserProfile = ((): any => {
	let email = '';
	let first_name = '';
	let last_name = '';
	let user_type = '';
	let companyName = '';

	const getEmail = () => {
		email = localStorage.getItem('email');
		return email;    // Or pull this from cookie/localStorage
	};

	const setEmail = (email) => {
		localStorage.setItem('email', email);
		// Also set this in cookie/localStorage
	};

	const forgetEmail = (email) => {
		localStorage.removeItem('email');
		// Also set this in cookie/localStorage
	};
	const getCompanyName = () => {
		email = localStorage.getItem('companyName');
		return email;    // Or pull this from cookie/localStorage
	};

	const setCompanyName = (companyName) => {
		localStorage.setItem('companyName', companyName);
		// Also set this in cookie/localStorage
	};

	const forgetCompanyName = (companyName) => {
		localStorage.removeItem('companyName');
		// Also set this in cookie/localStorage
	};

	const getFirstName = () => {
		first_name = localStorage.getItem('first_name');
		return first_name;
	}

	const setFirstName = (first_name) => {
		localStorage.setItem('first_name', first_name);
	};

	const forgetFirstName = (first_name) => {
		localStorage.removeItem('first_name');
	};

	const getLastName = () => {
		last_name = localStorage.getItem('last_name');
		return last_name;
	}

	const setLastName = (last_name) => {
		localStorage.setItem('last_name', last_name);
	};

	const forgetLastName = (last_name) => {
		localStorage.removeItem('last_name');
	};

	const setUserType = (user_type) => {
		localStorage.setItem('user_type', user_type);
	};

	const getUserType = (user_type) => {
		user_type = localStorage.getItem('user_type');
		return user_type;
	};

	const forgetUserType = (last_name) => {
		localStorage.removeItem('user_type');
	};

	return {
		getEmail: getEmail,
		setEmail: setEmail,
		forgetEmail: forgetEmail,
		getCompanyName: getCompanyName,
		setCompanyName: setCompanyName,
		forgetCompanyName: forgetCompanyName,
		getFirstName: getFirstName,
		setFirstName: setFirstName,
		forgetFirstName: forgetFirstName,
		getLastName: getLastName,
		setLastName: setLastName,
		forgetLastName: forgetLastName,
		setUserType: setUserType,
		getUserType: getUserType,
		forgetUserType: forgetUserType,
	}

})();

export default UserProfile;