import { jsPDF, Font } from 'jspdf';
import { PDFFIELDS, TChildSection, TSection } from '../../constant/PdfFields';

import BasePdfDocGen from './BasePdfDocGen';
var color = JSON.parse(localStorage.getItem('PrivateClientData')) ? JSON.parse(localStorage.getItem('PrivateClientData')).color.split(',') : [7, 52, 90];
var i = 1;
export default class PdfDetailsDocGen extends BasePdfDocGen {

	printChildSectionWithLabel = (propName: string, childSection: TChildSection, y: number): number => {
		const { pageHeight, pageWidth } = this;
		const label: string = childSection.label;
		if (childSection.type !== 'text' && childSection.type !== 'number') {
			return y;
		}
		if (!propName) {
			return y;
		}
		this.setFontSize(childSection.fontSize);
		const text: string = childSection.type === 'text'
			? propName.replace(/\*/g, ', ').replace(/[^\x00-\x7F]/g, '')
			: childSection.type === 'number'
				? propName.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				: ''
			;
		const content: string | Array<string> = this.splitTextToSize(label + ' ' + text, pageWidth - 20 - 20);
		const textDimensions: { w: number; h: number } = this.getTextDimensions(content);
		const textHeight: number = textDimensions.h;
		const font: Font = this.getFont();
		this.setFont('Arial', 'bold');
		const labelWidth: number = this.getTextWidth(label + ' ');
		this.text(label, 20, y);
		this.setFont('Arial', font.fontStyle);
		const lines: number = (content.length ? 1 + content.length : 1);
		const contentHeight: number = Math.ceil(textHeight);
		if (content.length) {
			this.text(content[0].substr(label.length + 1), 20 + labelWidth, y);
			let textDimensionsSub: { w: number; h: number } = this.getTextDimensions(content[0]);
			let yPlus = y + Math.ceil(textDimensionsSub.h);
			for (let k = 1; k < content.length; k++) {
				this.text(content[k], 20, yPlus);
				textDimensionsSub = this.getTextDimensions(content[k]);
				yPlus += Math.ceil(textDimensionsSub.h);
			}
		} else {
			this.text(text, 20 + labelWidth, y);
		}
		const multiSelect: boolean = childSection.multiSelect;
		y = y + contentHeight + 1;
		return y;
	}

	printChildSectionNoLabel = (propNameTmp: string, childSection: TChildSection, y: number): number => {
		const { pageHeight, pageWidth } = this;
		const name: string = childSection.name;
		if (childSection.type === 'text') {
			const propName: string = propNameTmp;
			this.setFontSize(childSection.fontSize);
			if (propName) {
				const text: string = propName.replaceAll('\t', ' ').replaceAll('\n', ' ').replace(/[^\x00-\x7F]/g, '');
				const content: string | Array<string> = this.splitTextToSize(text, pageWidth - 20 - 20);
				const textDimensions: { w: number; h: number } = this.getTextDimensions(content);
				const textHeight: number = textDimensions.h;
				if (i <= 3) {
					i = i + 1;
					this.setFont('Arial', 'bold');
					this.setTextColor(Number(color[0]), Number(color[1]), Number(color[2]));
				} else {
					this.setTextColor(0, 0, 0);
					const font: Font = this.getFont();
					this.setFont('Arial', 'normal');
				}
				this.text(content, 20, y, { maxWidth: pageWidth - 20 - 20 });
				const font: Font = this.getFont();
				this.setFont('Arial', 'normal');
				this.setTextColor(0, 0, 0);
				const lines: number = (content.length ? content.length : 1);
				const contentHeight: number = Math.ceil(textHeight);
				y = y + (lines > 1 ? 6 : 0) + contentHeight;
			}
		} else {
			const propName: any = propNameTmp;
			if (name === 'image' && propName.length > 0) {
				this.addImage(propName[0].url, 'JPEG', 20, y, 170, 90);
				y = y + 100;
			} else if (propName) {
				this.addImage(propName, 'JPEG', 20, y, 35, 15);
				y = y + 20;
			}
		}
		return y;
	}

	printChildSection = (properties: any, childSection: TChildSection, y: number, flagLastInSection: boolean): number => {
		const name: string = childSection.name;
		const propNameTmp: any = properties[name];
		if (childSection.displayLabel === true) {
			y = this.printChildSectionWithLabel(propNameTmp, childSection, y);
		} else {
			y = this.printChildSectionNoLabel(propNameTmp, childSection, y);
		}
		if (flagLastInSection && name !== 'listingName') {
			y = y + 5;
		}
		return y;
	}

	printHeadSection = (properties: any, section: TSection, y: number): number => {
		this.setFontSize(section.fontSize + 3);
		const font: Font = this.getFont();
		this.setFont('Arial', 'bold');
		this.setTextColor(Number(color[0]), Number(color[1]), Number(color[2]));
		this.text(section.heading, 20, y);
		this.setTextColor(0, 0, 0);
		this.setFont('Arial', font.fontStyle);
		y = y + 0.8;
		this.setLineWidth(0.5);
		const textWidth = this.getTextWidth(section.heading);
		this.line(20, y, 20 + textWidth, y);
		y = y + 6;
		return y;
	}

	printSection = (properties: any, section: TSection, y: number): number => {
		if (section.displayHeading === true) {
			if (this.doc !== null) {
				this.doc = null;
				const yTmp = this.printHeadSection(properties, section, y);
				const flagNewDocPage = this.isNewDocPage(yTmp, 20);
				this.doc = this.docStorage;
				if (flagNewDocPage) {
					y = this.addDocPage(yTmp, 20);
				}
			}
			y = this.printHeadSection(properties, section, y);
		}
		const childrenArr: Array<TChildSection> = section.children;
		for (let j = 0; j < childrenArr.length; j++) {
			if (this.doc !== null) {
				this.doc = null;
				const yTmp = this.printChildSection(properties, childrenArr[j], y, (j === childrenArr.length - 1));
				const flagNewDocPage = this.isNewDocPage(yTmp, 20);
				this.doc = this.docStorage;
				if (flagNewDocPage) {
					y = this.addDocPage(yTmp, 20);
				}
			}
			y = this.printChildSection(properties, childrenArr[j], y, (j === childrenArr.length - 1));
		}
		return y;
	}

	printPdfFields = (properties: any, y: number): number => {
		const pdfFields: Array<TSection> = PDFFIELDS;
		for (let i = 0; i < pdfFields.length; i++) {
			this.doc = null;
			const yTmp = this.printSection(properties, pdfFields[i], y);
			const flagNewDocPage = this.isNewDocPage(yTmp, 20);
			this.doc = this.docStorage;
			if (flagNewDocPage) {
				y = this.addDocPage(yTmp, 20);
			}
			y = this.printSection(properties, pdfFields[i], y);
		}
		return y;
	}

	createPDF = (properties: any) => {
		this.initPdfDocument();
		let y: number = 20;
		y = this.printPdfFields(properties, y);
		this.doc.save(properties.listingName + '.pdf');
	}

}
