import React, { Component,lazy } from 'react';
import '../homepage.css';
import HomePageHeader from './HomePageHeader';
import UserProfile from './UserProfile';
import { Cookies } from 'react-cookie';
import { Tabs, Tab, Modal, Collapse, Well, Button, ProgressBar, Glyphicon } from 'react-bootstrap';
import axios from 'axios';
import MetaTags from 'react-meta-tags';
import SimpleReactValidator from 'simple-react-validator';
import 'react-slideshow-image/dist/styles.css';
import Banner1 from '../assets/img/industrialsite.jpg';
import Banner2 from '../assets/img/Ports.jpg';
import Banner3 from '../assets/img/Warehouse.jpg';
import Lister from './HomePage/Lister';
import {Helmet} from "react-helmet";
const cookies = new Cookies();



const fadeImages = [
	Banner1,
	Banner2,
	Banner3,
];

interface PHomePage {
}

class SHomePage {
	firstname: string = UserProfile.getFirstName() ? UserProfile.getFirstName() : '';
	lastname: string = UserProfile.getLastName() ? UserProfile.getLastName() : '';
	email: string = UserProfile.getEmail() ? UserProfile.getEmail() : '';
	message: string = '';
	showSuccessModal: boolean = false;
	openSite: boolean = false;
	openPortTerminalsTransloads: boolean = false;
	openWarehouses: boolean = false;
	openSiteBlock1: boolean = false;
	openSiteBlock2: boolean = false;
	openPTTBlock1: boolean = false;
	openPTTBlock2: boolean = false;
	displayImage: string = fadeImages[0];

	// showIndustrialSites: boolean = true;
	showOfferSites: boolean = true;
	showSearchSites: boolean = false;
	// showPTT: boolean = false;
	showPTTOwner: boolean = false;
	showPTTCustomer: boolean = false;
	// showWarehouse: boolean = false;
	showWarehouseSeller: boolean = false;
	showWarehouseBuyer: boolean = false;
	loading: boolean = false;
	openWarehouseBlock1: boolean = false;
	openWarehouseBlock2: boolean = false;

	PageUrl:'';
	PageTitle:'';
	PageMetaRobot:'';
	PageMetaDiscription:'';

	constructor() {
	}
}

class HomePage extends Component<PHomePage, SHomePage> {
	child: HomePageHeader = null;
	validator: SimpleReactValidator = null;

	constructor(props: PHomePage) {
		super(props);
		// localStorage.setItem('propertyFor', 'Lease or Sale');
		this.state = new SHomePage();
	}

	scrollToSection() {
		var element = document.getElementById('about');
		element.scrollIntoView({ behavior: 'smooth', block: 'start' });
	}

	hanldeBlueSectionScroll = () => {
		document.getElementById('blue-section').scrollIntoView({ behavior: 'smooth' });
	}

	parentHandleShow(nextURL, type = '', subType = '') {
		this.child.handleShow(nextURL, type, subType);
	}

	componentWillMount() {
		this.getMetaTags()
		this.validator = new SimpleReactValidator({
			element: (message, className) => <div className='text-danger'>{message}</div>
		})
		fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
				'x-access-token': cookies.get('token')
			},
		})
			.then(res => {
				if (res.status === 200) {
					this.setState({ loading: false });
				} else {
					console.log('in else part ' + res.status)
					cookies.remove('token');
					UserProfile.forgetEmail();
					UserProfile.forgetFirstName();
					UserProfile.forgetLastName();
					this.forceUpdate();
				}
			})
			.catch(err => {
				// this.setState({ loading: false, redirect: true });
			});
	}
	getMetaTags = () => {
		axios.post(process.env.REACT_APP_BASE_URL + '/api/get-page-details', {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
			email: UserProfile.getEmail(),
		})
			.then(res => {
				const plans = res.data;
				// console.log(res.data)
				for (let i = 0; i < plans.length; i++) {
					if(plans[i].PageUrl == window.location.pathname ){
						this.setState({ 
							PageUrl: plans[i].PageUrl, 
							PageTitle: plans[i].PageTitle,
							PageMetaRobot: plans[i].PageMetaRobot,
							PageMetaDiscription: plans[i].PageMetaDiscription,
						});
					}
					
				}
			})
			.catch(err => {
			});
	}

	componentDidMount() {
		//document.getElementById('offerSites').classList.add('active');
	}

	scrollToLearnMore = () => {
		// document.getElementById('learn-more').scrollIntoView({behavior: 'smooth'});

		const element = document.getElementById('learn-more');
		const offset = 45;
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - 75 - offset;

		window.scrollTo({
			top: offsetPosition,
			behavior: 'smooth'
		});
	}

	setFirstName = (event) => {
		this.setState({ firstname: event.target.value });
	}

	setLastName = (event) => {
		this.setState({ lastname: event.target.value });
	}

	setEmail = (event) => {
		this.setState({ email: event.target.value });
	}

	setMessage = (event) => {
		this.setState({ message: event.target.value });
	}

	handleContactUs = () => {
		if (this.validator.allValid()) {
			axios.post(process.env.REACT_APP_BASE_URL + '/api/contact-us',
				{
					headers: {
						'Content-Type': 'application/json',
						// 'x-access-token': cookies.get('token')
					},
					// withCredentials: true,
					place_owner_name: '',
					place_id: '',
					name: this.state.firstname + ' ' + this.state.lastname,
					email: this.state.email,
					message: this.state.message,
				}).then(response => {
					if (response.status === 200) {
						this.setState({
							message: '',
							showSuccessModal: true,
						});
					} else {

					}
				}).catch(function (error) {
					console.log(error)
				});
		} else {
			this.validator.showMessages();
			this.forceUpdate();
		}
	}

	handleSuccessModal = () => {
		this.setState({ showSuccessModal: false });
	}

	closeInfoBox = () => {
		this.setState({
			openSiteBlock1: false,
			openSiteBlock2: false,
			openPTTBlock1: false,
			openPTTBlock2: false,
			openWarehouseBlock1: false,
			openWarehouseBlock2: false
		});
	}

	render() {
		return (
			<div style={{fontFamily: 'Roboto, sans-serif',fontSize: '1rem',fontWeight: 300,lineHeight: 1.5,color:' #4a4a4a',overflowX: 'hidden'}} className='body-home'>
				
				<Helmet>
                    <title>{this.state.PageTitle != "" ? this.state.PageTitle : "For Listers"}</title>
                    <meta name='description' content={this.state.PageMetaDiscription != "" ? this.state.PageMetaDiscription : "Discription"} />
                    <meta name="robots" content={this.state.PageMetaRobot != "" ? this.state.PageMetaRobot : ""} />
					<meta property="og:url" content={window.location.href} />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                </Helmet>
				<div className=''>
					<HomePageHeader data={this.props} onRef={ref => (this.child = ref)} />
				</div>
				<Lister />
				



				<Modal show={this.state.showSuccessModal} onHide={this.handleSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
					<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
					<Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
						<div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
							<div className='event-type info'>
								<div className='event-indicator '>
									<SVGIcon />
								</div>
							</div>
							<h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
								Contact Request Sent
							</h2>
							<p className='text-muted font-size-16-imp margin-bottom-0'>
								Your request has been sent. We will get in touch with you!
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
						<button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleSuccessModal}>Close</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}
}

export default HomePage;

const SVGIcon = () => (
	<svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
		<path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
	</svg>
)

