import React, { useState } from 'react';
import TitleSection from '../qloud/titlesection'
import search from "../public/assets/revslider/assets/Search.svg";
import createlist from "../public/assets/revslider/assets/CreateListing.svg";

const Index = props => {

    return (
        <>
            <section className="iq-pb-70 section-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-sm-12 align-self-center mb-5 mb-lg-0 wow fadeInUp" data-wow-delay="0.2s">
                            <TitleSection
                                className='text-left iq-title-box-2'
                                title='Industrial Strength Solutions'
                                subTitle='Why Choose'
                                titleIcon=''
                                description='Industrial Navigator is designed solely for finding and marketing industrial properties and transportation-related assets. There are no commercial or residential listings to distract from the business at hand. Filters help searchers quickly narrow down their choices on the parameters that matter most to industrial users. Useful map layers can be toggled on or off so that key aspects of the transportation infrastructure can be viewed alongside listings.'
                            />
                        </div>
                        <div className="col-lg-7 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="iq-icon-box iq-icon-box-style-8  wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="icon-box-img">
                                            <img style={{maxHeight:150}} src={search} className="img-fluid" alt="qloud" />
                                        </div>
                                        <div className="icon-box-content">
                                            <h5 className="icon-box-title title-searchbox"><a style={{color: '#142149',fontWeight:800,fontSize:'1.3em',fontFamily:'TeXGyreAdventor-Bold, sans-serif'}} href="/searcher">Searcher Solutions</a></h5>
                                            <p className="icon-box-desc">Easily find industrial sites, terminals, transload facilities, warehouses, distribution centers needed to build and strengthen your supply chain. Learn more about how our unique app makes searching easy.<br /></p>
                                        </div>
                                        <div style={{ paddingTop: 20 }} className="iq-btn-container">
                                            <a className="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="/searcher">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="iq-icon-box iq-icon-box-style-8  wow fadeInUp" data-wow-delay="0.2s">
                                        <div className="icon-box-img">
                                            <img style={{maxHeight:150}} src={createlist} className="img-fluid" alt="qloud" />
                                        </div>
                                        <div className="icon-box-content">
                                            <h5 className="icon-box-title title-searchbox"><a style={{color: '#142149',fontWeight:800,fontSize:'1.3em',fontFamily:'TeXGyreAdventor-Bold, sans-serif'}} href="/lister">Lister Solutions</a></h5>
                                            <p className="icon-box-desc">Industrial Navigator makes it easy for prospects who want to buy or lease your industrial properties or use your supply chain services and assets. Learn more about how easy and effective our listing feature can be for your organization.</p>
                                        </div>
                                        <div style={{ paddingTop: 20 }} className="iq-btn-container">
                                            <a className="iq-button iq-btn-round has-icon btn-icon-right d-inline" href="/lister">Learn More</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Index