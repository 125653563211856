import { FormItem } from './Types';

export const getDefaultFormData = (data: Array<FormItem>): any => {
	const result: any = {};
	data.forEach((input: FormItem) => {
		result[input.key] = '';
		if (input.type === 'dropdown') {
			const labelKey = input.key + 'Label';
			result[labelKey] = '';
		}
	})
	return result;
}

export const arrSiteInput: Array<FormItem> = [
	{ key: 'organizationName', type: 'input' },
	{ key: 'selectedPropertyType', type: 'dropdown' },
	{ key: 'selectedPropertyFor', type: 'dropdown' },
	{ key: 'selectedPropertyFor1', type: 'dropdown' },
	{ key: 'minAcreage', type: 'input' },
	{ key: 'maxAcreage', type: 'input' },
	{ key: 'minPrice', type: 'input' },
	{ key: 'maxPrice', type: 'input' },
	{ key: 'siteZoning', type: 'dropdown' },
	{ key: 'selectedBuildingOption', type: 'dropdown' },
	{ key: 'siteMinSquareFeet', type: 'input' },
	{ key: 'siteMaxSquareFeet', type: 'input' },
	{ key: 'siteMinInteriorRoofHeight', type: 'input' },
	// { key: 'siteMaxInteriorRoofHeight', type: 'input' },
	{ key: 'siteSuitableFor', type: 'dropdown' },
	{ key: 'sitePipelineAccess', type: 'dropdown' },
	{ key: 'selectedWaterSewer', type: 'dropdown' },
	{ key: 'selectedPowerToSite', type: 'dropdown' },
	{ key: 'siteEnvionmental', type: 'dropdown' },
	{ key: 'airAttainmentStatus', type: 'dropdown' },
];

export const arrSiteFormKey: Array<{ key: string }> = [
	{ key: 'organizationName' },
	{ key: 'selectedPropertyType' },
	{ key: 'selectedPropertyTypeLabel' },
	{ key: 'selectedPropertyFor' },
	{ key: 'selectedPropertyFor1' },
	{ key: 'selectedPropertyForLabel' },
	{ key: 'minAcreage' },
	{ key: 'maxAcreage' },
	{ key: 'minPrice' },
	{ key: 'maxPrice' },
	{ key: 'siteZoning' },
	{ key: 'siteZoningLabel' },
	{ key: 'selectedBuildingOption' },
	{ key: 'selectedBuildingOptionLabel' },
	{ key: 'siteMinSquareFeet' },
	{ key: 'siteMaxSquareFeet' },
	{ key: 'siteMinInteriorRoofHeight' },
	// { key: 'siteMaxInteriorRoofHeight' },
	{ key: 'siteSuitableFor' },
	{ key: 'siteSuitableForLabel' },
	{ key: 'sitePipelineAccess' },
	{ key: 'sitePipelineAccessLabel' },
	{ key: 'selectedWaterSewer' },
	{ key: 'selectedWaterSewerLabel' },
	{ key: 'selectedPowerToSite' },
	{ key: 'selectedPowerToSiteLabel' },
	{ key: 'siteEnvionmental' },
	{ key: 'siteEnvionmentalLabel' },
	{ key: 'airAttainmentStatus' },
	{ key: 'airAttainmentStatusLabel' }
];

export const arrTerminalPortInput: Array<FormItem> = [
	{ key: 'capabilities', type: 'dropdown' },
	{ key: 'equipments', type: 'dropdown' },
	{ key: 'railcarStorageCapacity', type: 'input' },
	{ key: 'productsHandled', type: 'dropdown' },
	{ key: 'productStorage', type: 'dropdown' },
	{ key: 'indoorStorage', type: 'dropdown' },
	{ key: 'pttMinAcreage', type: 'input' },
	{ key: 'pttMaxAcreage', type: 'input' },
];

export const arrTerminalPortFormKey: Array<{ key: string }> = [
	{ key: 'capabilities' },
	{ key: 'capabilitiesLabel' },
	{ key: 'equipments' },
	{ key: 'equipmentsLabel' },
	{ key: 'railcarStorageCapacity' },
	{ key: 'productStorage' },
	{ key: 'productStorageLabel' },
	{ key: 'productsHandled' },
	{ key: 'productsHandledLabel' },
	{ key: 'indoorStorage' },
	{ key: 'indoorStorageLabel' },
	{ key: 'pttMinAcreage' },
	{ key: 'pttMaxAcreage' },
];

export const arrWarehouseInput: Array<FormItem> = [
	{ key: 'warehouseMinPrice', type: 'input' },
	{ key: 'warehouseMaxPrice', type: 'input' },
	{ key: 'warehouseCapabilities', type: 'dropdown' },
	{ key: 'warehouseMinSize', type: 'input' },
	{ key: 'warehouseMaxSize', type: 'input' },
	{ key: 'temperatureControl', type: 'dropdown' },
	{ key: 'parkingSpaces', type: 'input' },
	{ key: 'foodGrade', type: 'dropdown' },
	{ key: 'freeTradeZone', type: 'dropdown' },
	{ key: 'minRoofHeight', type: 'input' },
	{ key: 'maxRoofHeight', type: 'input' },
];

export const arrWarehouseFormKey: Array<{ key: string }> = [
	{ key: 'warehouseMinPrice' },
	{ key: 'warehouseMaxPrice' },
	{ key: 'warehouseCapabilities' },
	{ key: 'warehouseMinSize' },
	{ key: 'warehouseMaxSize' },
	{ key: 'temperatureControl' },
	{ key: 'temperatureControlLabel' },
	{ key: 'parkingSpaces' },
	{ key: 'foodGrade' },
	{ key: 'foodGradeLabel' },
	{ key: 'freeTradeZone' },
	{ key: 'freeTradeZoneLabel' },
	{ key: 'minRoofHeight' },
	{ key: 'maxRoofHeight' },
];

export const arrMarineAccessInput: Array<FormItem> = [
	{ key: 'marineAccess', type: 'dropdown' },
	{ key: 'bargeAccess', type: 'dropdown' },
	{ key: 'docInPlace', type: 'dropdown' },
	{ key: 'navigableWater', type: 'input' },
	{ key: 'waterways', type: 'dropdown' }
];

export const arrMarineAccessFormKey: Array<{ key: string }> = [
	{ key: 'marineAccess' },
	{ key: 'marineAccessLabel' },
	{ key: 'bargeAccess' },
	{ key: 'bargeAccessLabel' },
	{ key: 'docInPlace' },
	{ key: 'docInPlaceLabel' },
	{ key: 'navigableWater' },
	{ key: 'waterways' },
	{ key: 'waterwaysLabel' },
];

export const arrServingCarriersInput: Array<FormItem> = [
	{ key: 'classOneConnectionsDataLayer', type: 'dropdown' },
	{ key: 'classOneConnections', type: 'dropdown' },
	{ key: 'shortLines', type: 'dropdown' },
	{ key: 'railInPlace', type: 'dropdown' },
	{ key: 'railcarStorage', type: 'dropdown' },
	{ key: 'unitTrainCapable', type: 'dropdown' },
	{ key: 'railcarCapacity', type: 'input' },
];

export const arrServingCarriersFormKey: Array<{ key: string }> = [
	{ key: 'classOneConnectionsDataLayer' },
	{ key: 'classOneConnections' },
	{ key: 'shortLines' },
	{ key: 'shortLinesLabel' },
	{ key: 'railInPlace' },
	{ key: 'railInPlaceLabel' },
	{ key: 'railcarStorage' },
	{ key: 'railcarStorageLabel' },
	{ key: 'parkingCapacity' },
	{ key: 'parkingCapacityLabel' },
	{ key: 'nearestInterstate' },
	{ key: 'milesToInterstate' },
	{ key: 'nearestAirport' },
	{ key: 'milesToAirport' },
	{ key: 'silos' },
	{ key: 'silosLabel' },
	{ key: 'unitTrainCapable' },
	{ key: 'unitTrainCapableLabel' },
	{ key: 'railcarCapacity' },
];

export const arrAdditionalAttributesInput: Array<FormItem> = [
	{ key: 'parkingCapacity', type: 'dropdown' },
	{ key: 'nearestInterstate', type: 'input' },
	{ key: 'milesToInterstate', type: 'input' },
	{ key: 'nearestAirport', type: 'input' },
	{ key: 'milesToAirport', type: 'input' },
	{ key: 'silos', type: 'dropdown' },
];

export const formsInputs: {
	siteInput: Array<FormItem>,
	terminalPortInput: Array<FormItem>,
	warehouseInput: Array<FormItem>,
	marineAccessInput: Array<FormItem>,
	servingCarriersInput: Array<FormItem>,
} = {
	siteInput: arrSiteInput,
	terminalPortInput: arrTerminalPortInput,
	warehouseInput: arrWarehouseInput,
	marineAccessInput: arrMarineAccessInput,
	servingCarriersInput: arrServingCarriersInput,
}
