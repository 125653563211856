import NorthAmericanRailLinesLayer
, {
	PNorthAmericanRailLinesLayer
	, SNorthAmericanRailLinesLayer
} from './NorthAmericanRailLinesLayer';

export interface PNorthAmericanRailLinesCprs extends PNorthAmericanRailLinesLayer {
}

export class SNorthAmericanRailLinesCprs extends SNorthAmericanRailLinesLayer {
	ref: NorthAmericanRailLinesCprs = null;

	constructor(parent: NorthAmericanRailLinesCprs, dataSource: string, sourceLayer: string) {
		super(parent, dataSource, sourceLayer);
		this.ref = parent;
		const { props } = parent;
	}
}

export default class NorthAmericanRailLinesCprs extends NorthAmericanRailLinesLayer<PNorthAmericanRailLinesCprs, SNorthAmericanRailLinesCprs> {

	static dataSource: string = 'NARL_CPRS-dbdwjn';
	static sourceLayer: string = 'NARL_CPRS';

	constructor(props: PNorthAmericanRailLinesCprs) {
		super(props, NorthAmericanRailLinesCprs.dataSource, NorthAmericanRailLinesCprs.sourceLayer);
		this.state = new SNorthAmericanRailLinesCprs(this, NorthAmericanRailLinesCprs.dataSource, NorthAmericanRailLinesCprs.sourceLayer);
	}
}
