import $ from 'jquery';
let WOW;

if (typeof window !== 'undefined') {

    window.$ = $;
    window.jQuery = $;
    import('../public/assets/js/appear');
    require('popper.js');
    //('bootstrap');
    WOW = require('wowjs');
}

const index = () => {
    loaderInit();
    stickyHeader();
    toggleDropdown();
    backToTop();
    accordion();
    accordionTwo();
    wowInit();
    counterJs();
    progressBar();
    isotope();
};

// Function for the sticky header...
const stickyHeader = () => {
    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 100) {
            $('header').addClass('menu-sticky');
        } else {
            $('header').removeClass('menu-sticky');
        }
    });
};

const wowInit = () => {
    const elementExist = checkElement('class', 'wow');

    if (elementExist) {

        new WOW.WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            live: false
        }).init();
    }
};

// Function for the Loader...
const loaderInit = () => {
    $('#load').fadeOut();
    $('#loading').delay(1000).fadeOut('slow');
};


// Function for toggle dropdown...
const toggleDropdown = () => {

    $(".nav-item.dropdown").mouseenter(function () {
        $(this).addClass("menu-show");
    });

    $(".nav-item.dropdown").mouseleave(function () {
        $(this).removeClass("menu-show");
    });
    $('.navbar-nav li a').on('click', function (e) {
        let anchor = $(this);
        $('html, body').stop().animate({
            // scrollTop: $(anchor.attr('href')).offset().top - 0
        }, 1500);
        e.preventDefault();
    });
    $('.navbar-nav li a').on('click', function () {
        // $(this).removeClass('active');
        $(this).parent('.nav-item.dropdown').addClass('menu-show');
    });
    // this.headerHeight();
};



const backToTop = () => {

    $('#back-to-top').fadeOut();
    $(window).on("scroll", function () {
        if ($(this).scrollTop() > 250) {
            $('#back-to-top').fadeIn(1400);
        } else {
            $('#back-to-top').fadeOut(400);
        }
    });
};

const accordion = () => {
    $('.iq-accordion .iq-accordion-block .accordion-details').hide();
    $('.iq-accordion .iq-accordion-block:first').addClass('accordion-active').children().slideDown('slow');
    $('.iq-accordion .iq-accordion-block').on('click', function (e) {
        e.preventDefault();
        if ($(this).children('div.accordion-details').is(':hidden')) {
            $('.iq-accordion .iq-accordion-block').removeClass('accordion-active').children('div.accordion-details ').slideUp('slow');
            $(this).toggleClass('accordion-active').children('div.accordion-details').slideDown('slow');
        }
    });
};

const accordionTwo = () => {
    $('.iq-faq .iq-block .iq-details').hide();
    $('.iq-faq .iq-block:first').addClass('iq-active').children().slideDown('slow');
    $('.iq-faq .iq-block').on("click", function () {
        if ($(this).children('div').is(':hidden')) {
            $('.iq-faq .iq-block').removeClass('iq-active').children('div').slideUp('slow');
            $(this).toggleClass('iq-active').children('div').slideDown('slow');
        }
    });
}
const checkElement = (type, element) => {
    let found = false;
    let elements;
    switch (type) {
        case 'class':
            elements = document.getElementsByClassName(element);

            if (elements !== undefined && elements !== null && elements.length > 0) {
                found = true;
            }
            break;

        case 'id':
            elements = document.getElementById(element);

            if (elements !== undefined && elements !== null) {
                found = true;
            }
            break;
    }


    return found;
};

const progressBar = () => {
    const elementExist = checkElement('class', 'iq-progress-bar');
    if (elementExist) {
        $('.iq-progress-bar > span').each(function () {
            var $this = $(this);
            var width = $this.data('percent');
            $this.css({
                'transition': 'width 2s'
            });
            setTimeout(function () {
                $this.appear(function () {
                    $this.css('width', width + '%');
                });
            }, 500);
        });
    }
};


const counterJs = () => {
    //-- Plugin implementation
    $.fn.countTo = function (options) {
        return this.each(function () {
            //-- Arrange
            let FRAME_RATE = 60; // Predefine default frame rate to be 60fps
            let $el = $(this);
            let countFrom = 0;
            let countTo = parseInt($el.attr('data-to'));
            let countSpeed = $el.attr('data-speed'); // Number increment per second

            //-- Action
            let rafId;
            let increment;
            let currentCount = countFrom;
            let countAction = function () {              // Self looping local function via requestAnimationFrame
                if (currentCount < countTo) {              // Perform number incremeant
                    $el.text(Math.floor(currentCount));     // Update HTML display
                    increment = countSpeed / FRAME_RATE;    // Calculate increment step
                    currentCount += increment;              // Increment counter
                    rafId = requestAnimationFrame(countAction);
                } else {                                  // Terminate animation once it reaches the target count number
                    $el.text(countTo);                      // Set to the final value before everything stops
                    //cancelAnimationFrame(rafId);
                }
            };
            rafId = requestAnimationFrame(countAction); // Initiates the looping function
        });
    };


    $('.timer').countTo();

};

const revslider_showDoubleJqueryError = (sliderID) => {
   
};


const isotope = () => {
    if ($(".isotope").length) {

        $('.isotope').isotope({
            itemSelector: '.iq-grid-item',
        });

        // filter items on button click
        $('.isotope-filters').on('click', 'button', function () {
            var filterValue = $(this).attr('data-filter');
            $('.isotope').isotope({
                resizable: true,
                filter: filterValue
            });
            $('.isotope-filters button').removeClass('active');
            $(this).addClass('active');
        });
    }

    if ($(".iq-masonry-block").length) {
        var $msnry = $('.iq-masonry-block .iq-masonry');
        if ($msnry) {
            var $filter = $('.iq-masonry-block .isotope-filters');
            $msnry.isotope({
                percentPosition: true,
                resizable: true,
                itemSelector: '.iq-masonry-block .iq-masonry-item',
                masonry: {
                    gutterWidth: 0
                }
            });
            // bind filter button click
            $filter.on('click', 'button', function () {
                var filterValue = $(this).attr('data-filter');
                $msnry.isotope({
                    filter: filterValue
                });
            });

            $filter.each(function (i, buttonGroup) {
                var $buttonGroup = $(buttonGroup);
                $buttonGroup.on('click', 'button', function () {
                    $buttonGroup.find('.active').removeClass('active');
                    $(this).addClass('active');
                });
            });
        }
    }
}




export { index }