import React, { Component } from 'react';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Tab, Tabs, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Header from './Header';
import Footer from './Footer';
// import HomePageFooter from './HomePageFooter';
import HomePageFooter from './HomePage/qloud/footerComponents';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import Home from './HomePage';
import logo from '../logo.svg';
import InputBoxComponent from './InputBoxComponent';
import axios from 'axios';
import '../css/priceplans.css';
import MultiStepRegistration from './Register/MultiStepRegistration';
import UserProfile from './UserProfile';
import SimpleReactValidator from 'simple-react-validator';
import {Helmet} from "react-helmet";


const cookies = new Cookies();
interface PHeader {
    onRef?: (header?: Header) => void;
    data?: any;
    shoppingCartCount?: number;
    bookmarksCount?: number;
    logo?: string;
    btnBackgroundColor?: string;
    iconColor?: string;
    secondaryColor?: string;
    history: Array<any>;
}


class PlansAndPricing extends Component<PHeader, any> {
    validator: SimpleReactValidator = null;
    loginValidator: SimpleReactValidator = null;
    OTPValidator: SimpleReactValidator = null;
    helpers: any = null;

    constructor(props: PHeader) {
        super(props);
        this.state = {
            showLoginModal: false,
            showForgotPasswordModal: false,
            login_email: '',
            login_password: '',
            isLoggedIn: false,
            nextURL: '',
            propertyType: '',
            propertySubType: '',
            fpEmail: '',
            showLoginSuccessModal: false,
            showLogoutSuccessModal: false,
            showRegistrationModal: false,
            fname: '',
            lname: '',
            companyName: '',
            password: '',
            confirm_password: '',
            email: '',
            showRegistrationSuccessModal: false,
            reacatpcha_value: null,
            // shoppingCartCount: localStorage.getItem('listingNames') ? JSON.parse(localStorage.getItem('listingNames')).length : 0,
            bookmarksCount: props.bookmarksCount ? props.bookmarksCount : 0,
            shoppingCartCount: props.shoppingCartCount ? props.shoppingCartCount : 0,
        }
        //this.handleMyListing = this.handleMyListing.bind(this);
        this.logoutUser = this.logoutUser.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.checkIfLoginPage = this.checkIfLoginPage.bind(this);
        this.setLoginEmail = this.setLoginEmail.bind(this);
        this.setLoginPassword = this.setLoginPassword.bind(this);
        this.handleLogin = this.handleLogin.bind(this);


        this.validator = new SimpleReactValidator({
            validators: {
                phone: {  // name the rule
                    message: 'The :phone must be a valid phone number.',
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[1]{1} \([0-9]{3}\) [0-9]{3}-[0-9]{4}$/)
                    },
                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                    required: true  // optional
                }
            }
        });
    }

    // componentWillUnmount() {
    // 	this.props.onRef(undefined);
    // }

    componentWillMount() {
        this.loginValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        });
        this.validator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
        this.OTPValidator = new SimpleReactValidator({
            element: (message, className) => <div className='text-danger'>{message}</div>
        })
        console.log("Build Number :1")
    }


    handleShow(nextURL, type = '', subType = '') {
        this.setState({
            nextURL: nextURL,
            propertyType: type,
            propertySubType: subType,
        });

        if (cookies.get('token') !== undefined) {
            fetch(process.env.REACT_APP_BASE_URL + '/api/checkToken', {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': process.env.REACT_APP_BASE_URL,
                    'x-access-token': cookies.get('token')
                },
            })
                .then(res => {
                    if (res.status === 200) {
                        if (nextURL === '/my-profile') {
                            // this.props.data.history.push('/create');
                            this.props.history.push({
                                pathname: '/my-profile',
                                state: {
                                    propertyType: this.state.propertyType,
                                    propertySubType: this.state.propertySubType,
                                }
                            })
                        }
                    } else {
                        UserProfile.forgetEmail();
                        cookies.remove('token');
                        this.props.history.push('/');
                        window.scrollTo(0, 0);
                        // const error = new Error(res.error);
                        // throw error;
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false, redirect: true });
                });
        } else {
            this.handleOpen();
        }
    }
    handleOpen = () => {
        this.setState({ showRegistrationModal: true });
    }
    handleOTPVerification = () => {
        if (this.OTPValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/verify-otp',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    otp: this.state.otp,
                    login_email: this.state.login_email,
                }).then(response => {
                    console.log(response);
                    if (response.status === 200) {
                        this.handleOTPModal();
                        this.handleLoginSuccessResponse(response);
                    }
                }).catch(function (error) {
                    // handle your error
                });
        } else {
            this.OTPValidator.showMessages();
            this.forceUpdate();
        }
    }
    logoutUser() {
        // UserProfile.forgetEmail();
        // UserProfile.forgetFirstName();
        // UserProfile.forgetLastName();
        cookies.remove('token');
        localStorage.clear();
        this.setState({ isLoggedIn: false, showLogoutSuccessModal: true });
        this.props.data.history.push('/');
    }
    handleClose() {
        this.setState({ showRegistrationModal: false });
    }
    checkIfLoginPage() {
        if (this.props.data.location.pathname === '/register') {
            this.handleClose();
        } else {
            this.props.data.history.push({
                pathname: '/register',
                state: {
                    nextURL: '/create',
                }
            })
        }
    }
    setLoginEmail(event) {
        this.setState({ login_email: event.target.value });
    }
    setLoginPassword(event) {
        this.setState({ login_password: event.target.value });
    }
    handleLogin() {
        if (this.loginValidator.allValid()) {
            axios.post(process.env.REACT_APP_BASE_URL + '/api/login-user',
                {
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    login_email: this.state.login_email,
                    login_password: this.state.login_password,
                }).then((response: {
                    status: number
                    , data: {
                        bookmarks_str: string
                        , email: string
                        , first_name: string
                        , last_name: string
                        , max_no_entries: string
                        , options: string
                        , place_names_arr: string //Array<any>
                        , user_type: string
                        , token: string
                    }
                }) => {
                    document.getElementById('login_error').style.display = 'none';
                    document.getElementById('verify_email_error').style.display = 'none';
                    console.log(response);
                    this.handleClose();
                    // alert(window.location.href)
                    if (response.status === 200) {
                        let bookmarksCount = 0;
                        console.log('response.data')
                        console.log(response.data)
                        if (response.data.bookmarks_str) {
                            const bookmarksArr = response.data.bookmarks_str.split(',');
                            bookmarksCount = bookmarksArr.length;
                        }
                        localStorage.setItem('bookmarks', response.data.bookmarks_str);
                        localStorage.setItem('listingNames', response.data.place_names_arr);
                        localStorage.setItem('maxNoEntries', response.data.max_no_entries);
                        localStorage.setItem('membershipOptions', response.data.options);

                        if (response.data.user_type === 'admin') {
                            this.setState({
                                showOTPModal: true
                            });
                        } else {
                            this.handleLoginSuccessResponse(response);
                        }
                    } else {
                        this.setState({ isLoggedIn: false });
                        if (response.status === 204) {
                            document.getElementById('verify_email_error').style.display = 'block';
                            document.getElementById('verify_email_error').scrollIntoView({ behavior: 'smooth' });
                        } else {
                            document.getElementById('login_error').style.display = 'block';
                            document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                        }
                    }
                }).catch((error) => {
                    // handle your error
                    // document.getElementById('login_error').style.display = 'block';
                    // document.getElementById('login_error').scrollIntoView({ behavior: 'smooth' });
                });
        } else {
            this.loginValidator.showMessages();
            // rerender to show messages for the first time
            // you can use the autoForceUpdate option to do this automatically`
            this.forceUpdate();
        }
    }
    handleShowForgotPasswordModal = () => {
        this.setState({ showForgotPasswordModal: true });
        this.handleClose();
    }
    handleLoginSuccessModal() {
        this.setState({ showLoginSuccessModal: false });
    }

    handleRegistrationSuccessModal = () => {
        this.setState({ showRegistrationSuccessModal: false });
    }

    showRegistrationModal = () => {
        this.setState({
            showRegistrationModal: true,
            showLoginModal: false,
        });
    }
    handleForgotPasswordClose = () => {
        this.setState({ showForgotPasswordModal: false });
        this.handleOpen();
    }
    setFPEmail = (event) => {
        this.setState({ fpEmail: event.target.value });
    }
    handleLogoutSuccessModal = () => {
        this.setState({ showLogoutSuccessModal: false });
    }
    handleOTPModal = () => {
        this.setState({ showOTPModal: false });
    }

    handleInputChange = (name, value) => {
        this.setState({ [name]: value });
    }

    handleRegistrationModal = () => {
        this.setState({ showRegistrationModal: false });
    }
    handleLoginSuccessResponse = response => {
        UserProfile.setEmail(response.data.email);
        UserProfile.setFirstName(response.data.first_name);
        UserProfile.setLastName(response.data.last_name);
        UserProfile.setUserType(response.data.user_type);
        UserProfile.setCompanyName(response.data.companyName);

        this.setState({
            isLoggedIn: true,
            showLoginSuccessModal: true,
        });

        cookies.set('token', response.data.token);
        this.forceUpdate();

        if (this.props.data.location.pathname === '/register' || this.props.data.location.pathname === '/success' || this.state.nextURL === '/create') {
            // this.props.data.history.push('/create');
            this.props.data.history.push({
                pathname: '/create',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
        else if (this.state.nextURL === '/listing') {
            // this.props.data.history.push('/listing');
            this.props.data.history.push({
                pathname: '/listing',
                state: {
                    propertyType: this.state.propertyType,
                    propertySubType: this.state.propertySubType,
                }
            })
        }
    }
    sendFPEmail = () => {
        axios.post(process.env.REACT_APP_BASE_URL + '/api/forgot-password',
            {
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                fpEmail: this.state.fpEmail
            }).then(response => {
                document.getElementById('fp_success').style.display = 'block';
                document.getElementById('fp_error').style.display = 'none';
            }).catch(function (error) {
                document.getElementById('fp_success').style.display = 'none';
                document.getElementById('fp_error').style.display = 'block';
                // alert('Something went wrong! Please try after sometime.')
            });
    }


    child: Header = null;



    render() {

        return (
            <>
                <Helmet>
                    <title>Terms And Conditions</title>
                    <meta name='description' content="Terms And Conditions" />
                    <meta property='og:title' content="Terms And Conditions" />
                    <meta property='og:image' content="https://skywalker-production.s3.amazonaws.com/logo.png" />
                    <meta property="og:url" content={window.location.href} />
                </Helmet>

                <Header data={this.props} onRef={ref => (this.child = ref)} />

                <section className='margin-bottom-0' style={{ minHeight: 1400, fontFamily: "Roboto, sans-serif" }}>
                    <div className="justify-content-center">
                    <div className='col-lg-2 col-md-12 col-sm-12 padding-0 ' ></div>
                        <div className='col-lg-8 col-md-12 col-sm-12 padding-0 ' >

                            <div className="container-fluid" style={{ marginTop: 100, fontFamily: "Roboto, sans-serif" }} >
                                <h1 className="content_area" style={{ fontWeight: 600, fontFamily: 'Roboto, sans-serif', marginLeft: 15 }}>Digital Millenium Copyright Act Policy </h1>
                                {/* <p style={{ fontSize: "18px", marginLeft: 15 }} className="copy margin--top-c4">Select a subscription that best fits your needs, or contact us for corporate or custom solutions. </p> */}

                                <div className='container-fluid' >
                                    <p className="para_content-new">

                                        It is Professional Logistics Group, Inc.’s policy to respond to notifications of alleged copyright infringement properly provided to us by the copyright owner or the copyright owner’s legal agent pursuant to the Digital Millennium Copyright Act (DMCA).
                                        <br /><br />
                                        <u>DMCA Notification of Claimed Infringement</u>
                                        <br /><br />If you believe that your content has been copied in a way that constitutes copyright infringement, please provide us with <b>written notice</b> that includes the following information:
                                        <br /><br />

                                        <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                                            <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works are covered by a single notification, a representative list of such works. </li>
                                            <li>Identification of the material that you claim to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit PLG to locate the material. </li>
                                            <li>Information reasonably sufficient to permit PLG to contact the complaining party, such as mailing address, telephone number, and, if available, an email address.</li>
                                            <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law. </li>
                                            <li>A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>

                                        </ul>
                                       Send your notice to our agent for notices of claimed copyright infringement as follows:<br /><br />
                                        Email:  cbonura@plgconsulting.com<br />
                                        U.S. mail: 125 S. Clark St.,17th Floor, Chicago, IL 60603.<br />


                                        <br /><br />
                                        <u>DMCA Counter-notification</u>
                                        <br /><br />If you believe your material has been removed in error, please provide us with a <b>written counter-notification</b> that includes the following information:
                                        <br /><br />

                                        <ul style={{ listStyleType: 'disc', marginLeft: 20 }}>
                                            <li>Your physical or electronic signature.</li>
                                            <li>Identification description of the material that has been removed or to which access has been disabled</li>
                                            <li>The URL or a sufficient description of the location at which the material appeared before it was removed or access to it was disabled.</li>
                                            <li>A statement under penalty of perjury that the subscriber has a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.</li>
                                            <li>Your name, address, and telephone number</li>
                                            <li>A statement that you consent to the jurisdiction of Federal District Court for the judicial district in which your address is located, or if the your address is outside of the United States, for any judicial district in which PLG may be found, and that you will accept service of process from the person who provided the original DMCA notification or an agent of such person.</li>

                                        </ul>
                                        Send your counter-notice to our agent for notices of claimed copyright infringement as follows: <br /><br />
                                        Email:  cbonura@plgconsulting.com<br />
                                        U.S. mail: 125 S. Clark St.,17th Floor, Chicago, IL 60603.<br />

                                        Build No:61

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>






                <HomePageFooter />          
                <Modal show={this.state.showForgotPasswordModal} onHide={this.handleForgotPasswordClose} style={{ padding: '0px!important', opacity: 1 }}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Forgot Password
                                    </h2>
                                    <form style={{ width: '95%', marginLeft: '2%', marginRight: '2%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='fp_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email.</div>
                                        <div id='fp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        {/* <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />
                                        </div> */}
                                        <input type='email' name='fpEmail' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setFPEmail} value={this.state.fpEmail} />

                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.sendFPEmail}>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal dialogClassName='custom-modal modal-border' show={this.state.showLoginModal} onHide={this.handleClose} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div>
                            <div style={{ padding: '3rem', backgroundColor: 'white' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto', marginBottom: 30 }}>
                                        <img src={logo} />
                                    </div>
                                    <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
                                        <div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Enter email address and password to login</div>
                                        <div id='login_error' className='alert alert-danger' style={{ display: 'none' }}>Invalid email or password.</div>
                                        <div id='verify_email_error' className='alert alert-danger' style={{ display: 'none' }}>Please verify your email address for login.</div>
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='email' name='login_email' className='form-control input-lg not-dark required email' placeholder='Enter your Email..' onChange={this.setLoginEmail} value={this.state.login_email} />
                                        </div>
                                        {this.loginValidator.message('email', this.state.login_email, 'required')}
                                        <div className='input-group margin-top-15 col-lg-12'>
                                            <input type='password' name='login_password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setLoginPassword} value={this.state.login_password} />
                                        </div>
                                        {this.loginValidator.message('password', this.state.login_password, 'required')}
                                        <div className='col_full center padding-top-20'>
                                            <button className='btn btn-default border-radius-25-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.handleLogin}>Sign In</button>
                                        </div>
                                        <div className='col_full center padding-top-20'>
                                            <span role='button' className='color-blue font-weight-bold' onClick={this.handleShowForgotPasswordModal}>Forgot Password?</span>
                                        </div>
                                    </form>
                                    <div style={{ paddingTop: '1rem' }}>
                                        <small>
                                            <div className='text-center text-black font-weight-bold'>
                                                Don't have an account? <span className='cursor-pointer color-blue' onClick={this.showRegistrationModal}>Sign Up</span></div>
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showLoginSuccessModal} onHide={this.handleLoginSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Login Successful
                            </h2>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLoginSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showLogoutSuccessModal} onHide={this.handleLogoutSuccessModal} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Logout Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                You are logged out of the system
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button className='btn btn-default border-radius-25-imp' type='button' value='submit' onClick={this.handleLogoutSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal dialogClassName='custom-modal-step3' show={this.state.showRegistrationModal} onHide={this.handleRegistrationModal} style={{ opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5', padding: 5 }}>
                        <div>
                            <div>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '30px auto' }}>
                                        <img src={logo} />
                                    </div>
                                    {/* <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
										Welcome to Industrial Navigator!
									</h2> */}
                                    <MultiStepRegistration />
                                    {/* <form id='slider-subscribe-form' style={{ width: '80%', marginLeft: '10%', paddingBottom: '10px' }} className='nobottommargin'>
										<div className='text-center color-black font-size-18 margin-bottom-20' style={{ color: '#474747' }}>Please enter your details below to register for Industrial Navigator.</div>

										<div id='regi_errors' className='margin-top-15'></div>

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='fname' className='form-control input-lg not-dark required email' placeholder='Enter your First Name..' onChange={this.setFName.bind(this)} value={this.state.fname} />
										</div>
										{this.validator.message('first name', this.state.fname, 'required')}

										<div className='input-group margin-top-15 col-lg-12'>
											<input type='text' name='lname' className='form-control input-lg not-dark required email' placeholder='Enter your Last Name..' onChange={this.setLName.bind(this)} value={this.state.lname} />
										</div>
										{this.validator.message('last name', this.state.lname, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='text' name='companyName' className='form-control input-lg not-dark required email' placeholder='Enter your Company Name..' onChange={this.setCompanyName.bind(this)} value={this.state.companyName} />
										</div>
										{this.validator.message('company name', this.state.companyName, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='email' name='email' className='form-control input-lg not-dark required email' placeholder='Enter your Email Address..' onChange={this.setEmail.bind(this)} value={this.state.email} />
										</div>
										{this.validator.message('email', this.state.email, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='password' className='form-control input-lg not-dark required email' placeholder='Enter your Password..' onChange={this.setPassword.bind(this)} value={this.state.password} />
										</div>
										{this.validator.message('password', this.state.password, 'required')}
										<div className='col-lg-12 col-md-12 col-sm-12 input-group margin-top-15'>
											<input type='password' name='confrim-password' className='form-control input-lg not-dark required email' placeholder='Confirm Password..' onChange={this.setConfirmPassword.bind(this)} value={this.state.confirm_password} />
										</div>
										{this.validator.message('confirm_password', this.state.confirm_password, `required|in:${this.state.password}`, { messages: { in: 'Passwords need to match!' } })}
										<ReCAPTCHA
											ref={recaptchaRef}
											// size='invisible'
											className='margin-top-15'
											onChange={this.onRecaptchaChange.bind(this)}
											sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
										/>
										{this.validator.message('captcha', this.state.reacatpcha_value, 'required')}
										<div className='col_full center padding-top-20'>
											<button className='button button-border button-circle t500 noleftmargin topmargin-sm background-blue-imp border-none-imp' type='button' name='template-contactform-submit' value='submit' onClick={this.registerUser.bind(this)}>Register</button>
										</div>
									</form> */}
                                    {/* <div style={{ paddingTop: '1rem' }}>
										<small>
											<div className='text-center text-black font-weight-bold'>
												Already have an account? <span className='cursor-pointer color-blue' onClick={this.checkIfLoginPage}>Sign In</span></div>
										</small>
									</div> */}
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={this.state.showRegistrationSuccessModal} onHide={this.handleRegistrationSuccessModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ background: 'white', padding: '1rem', marginBottom: '0px' }}>
                        <div className='text-center' style={{ paddingTop: '1rem', paddingLeft: '3rem', paddingRight: '3rem' }}>
                            <div className='event-type info'>
                                <div className='event-indicator '>
                                    <SVGIcon />
                                </div>
                            </div>
                            <h2 className='text-black font-size-30 margin-bottom-10' style={{ textTransform: 'none' }}>
                                Registration Successful
                            </h2>
                            <p className='text-muted font-size-16-imp margin-bottom-0'>
                                Please verify your email address before adding a listing.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleRegistrationSuccessModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showOTPModal} onHide={this.handleOTPModal.bind(this)} style={{ padding: '0px!important', opacity: 1 }} animation={false}>
                    <Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
                    <Modal.Body style={{ backgroundColor: '#009df5' }}>
                        <div>
                            <div style={{ padding: '3rem' }}>
                                <div style={{ backgroundColor: '#f8f9fa', padding: '1.5rem', borderRadius: 6 }}>
                                    <div style={{ width: 200, margin: '0 auto' }}>
                                        <img src={logo} />
                                    </div>
                                    <h2 className='text-center text-black font-size-30' style={{ paddingTop: '1rem' }}>
                                        Verify OTP
                                    </h2>
                                    <form style={{ width: '80%', marginLeft: '10%', paddingBottom: 10 }} className='nobottommargin'>
                                        <div id='otp_error' className='alert alert-danger' style={{ display: 'none' }}>Your email address is not in our records. Please sign up again.</div>
                                        <div id='otp_success' className='alert alert-success' style={{ display: 'none' }}>Password reset link has been sent on your email id.</div>
                                        <InputBoxComponent type='number' min='0' name='otp' onHandleInputChange={this.handleInputChange} />
                                        {this.OTPValidator.message('otp', this.state.otp, 'required')}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPVerification}>Verify</button>
                        <button type='button' className='btn btn-default border-radius-25-imp' onClick={this.handleOTPModal.bind(this)}>Close</button>
                    </Modal.Footer>
                </Modal>

            </>
        )
    }
}

export default withRouter(PlansAndPricing);

const SVGIcon = () => (
    <svg style={{ width: 60, height: 60 }} viewBox='0 0 24 24'>
        <path fill='#fff' d='M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z' />
    </svg>
)