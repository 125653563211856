import React, { Component } from 'react';

interface PInputBoxComponent {
	onHandleInputChange: (name: string, value: string) => void;
	name: string;
	type?: string;
	min?: string;
	className?: string;
	size?: string;
	height?: string;
	value?: string;
	placeholder?: string;
}

class SInputBoxComponent {
	value: string = '';

	constructor() {
	}
}

class InputBoxComponent extends Component<PInputBoxComponent, SInputBoxComponent> {
	constructor(props: PInputBoxComponent) {
		super(props);
		this.state = new SInputBoxComponent();
	}

	componentWillReceiveProps(nextProps) {
		// if(nextProps.value !== '' && nextProps.value !== undefined) {
		if (nextProps.value !== undefined) {
			if (nextProps.value !== null) {
				this.setState({ value: nextProps.value });
			} else {
				this.setState({ value: '' });
			}
		}
	}

	handleChange = event => {
		const value = event.target.value.replace(/[^\x00-\x7F]/g, '');
		this.setState({ value: value });
		this.props.onHandleInputChange(event.target.name, value);
	}

	render() {
		return (
			<input
				name={this.props.name}
				type={this.props.type}
				min={this.props.min ? this.props.min : ''}
				className={this.props.className ? 'form-control ' + this.props.className : 'form-control'}
				style={{
					marginBottom: 0,
					width: this.props.size && this.props.size === 'small' ? '35%' : '100%',
					height: this.props.height && this.props.height === 'large' ? 54 : 'auto'
				}}
				onChange={this.handleChange}
				value={this.state.value || this.props.value}
				placeholder={this.props.placeholder ? this.props.placeholder : ''}
				autoComplete='off'
			/>
		)
	}
}

export default InputBoxComponent;