import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { Modal, Breadcrumb, BreadcrumbItem, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import MediaQuery from 'react-responsive';
import InputCheckboxComponent from './InputCheckboxComponent';
import axios from 'axios';
import Details from './PrivateDetails'
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, EmailShareButton } from 'react-share';
import UserProfile from './UserProfile';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

interface PPlaces {
	match: any;
}

class SPlaces {
	email: string = UserProfile.getEmail() ? UserProfile.getEmail() : '';
	features: Array<any> = [];
	copySuccess: string = '';
	detailsModal: boolean = false;

	constructor() {
	}
}

class Places extends Component<PPlaces, SPlaces> {
	child: Header = null;

	constructor(props: PPlaces) {
		super(props);
		this.state = new SPlaces();
	}

	componentWillMount() {
		console.log(this.props);
		const place_ids_str = this.props.match.params.id;
		axios.get(process.env.REACT_APP_BASE_URL + '/api/get-place-list/' + place_ids_str, {
			// place_ids_str: place_ids_str
		})
			.then(response => {
				console.log(response)

				if (response.status === 200) {
					this.setState({
						features: response.data.places,
					});
				}
			});
	}
	showDetailsModal = (rec_id) => {
		localStorage.setItem("rec_id", rec_id)
		this.setState({ detailsModal: true });
	}
	hideDetailsModal = () => {
		this.setState({ detailsModal: false });
		localStorage.removeItem('rec_id');
	}

	getFirstImage = (json) => {
		for (let key in json) {
			if (json.hasOwnProperty(key)) {
				const url = json[key].url;
				return url;
			}
		}
	}

	formatPrice(price) {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 0
		});
		const formattedPrice = formatter.format(price);
		return formattedPrice;
	}

	componentDidMount() {
		(document.getElementsByTagName('title') as any).content = 'My new page keywords!!';
	}

	copyToClipboard = (e) => {
		const dummy = document.createElement('input'),
			text = window.location.href;

		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);
		this.setState({
			copySuccess: 'Link copied in clipboard!'
		})
	}

	render() {
		const { features } = this.state;
		return (
			<div className='padding-bottom-50'>
				<Helmet>
					<title>Industrial Navigator | Favorites</title>
					<meta name='description' content='Someone using Industrial Navigator has shared places that you may be interested in!. Click on the link to start browsing! Industrial Navigator makes industrial site searches across industrial sites, ports and terminals, warehouses, and more!' />
					<meta property='og:title' content='Industrial Navigator | Favorites' />
					<meta property="og:url" content={window.location.href} />
					<meta property='og:description' content='Someone using Industrial Navigator has shared places that you may be interested in!. Click on the link to start browsing! Industrial Navigator makes industrial site searches across industrial sites, ports and terminals, warehouses, and more!' />
					<meta property='og:image' content='https://skywalker-production.s3.amazonaws.com/logo.png' />
				</Helmet>
				<Header data={this.props} onRef={ref => (this.child = ref)} />
				<div id='page-content' style={{ minHeight: '100vh' }}>
					<Breadcrumb>
						<Link to={{ pathname: '/' }}><span className='color-white'>Home</span></Link>&nbsp;/&nbsp;
						<BreadcrumbItem active>Places</BreadcrumbItem>
					</Breadcrumb>
					<section className='container-fluid reduce-padding-0'>
						<Col lg={12} md={12} sm={12}>
							<h2 style={{ marginTop: 25 }} className='col-lg-12 col-md-12 col-sm-12 col-xs-12 font-size-25 reduce-padding-0'>Listings on Industrial Navigator</h2>
							<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginBottom: 20 }}>
								<div className='pull-left' style={{ padding: 10 }}>
									<OverlayTrigger placement='bottom' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>Share on Twitter</strong>
										</Tooltip>
									}>
										<TwitterShareButton url={"https://www.linkedin.com/shareArticle?mini=true&url=https://share.industrialnavigator.com/sharedplacelist.php?" + this.props.match.params.id}>
											<i className='color-blue fa fa-2x fa-twitter cursor-pointer'></i>
										</TwitterShareButton>
									</OverlayTrigger>
								</div>

								<div className='pull-left' style={{ padding: 10 }}>
									<OverlayTrigger placement='bottom' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>Share on LinkedIn</strong>
										</Tooltip>
									}>
										<a href={"https://www.linkedin.com/shareArticle?mini=true&url=https://share.industrialnavigator.com/sharedplacelist.php?" + this.props.match.params.id} target="_blank"><i className="fab fa-2x fa-linkedin-in color-blue fa  cursor-pointer"></i></a>

									</OverlayTrigger>
								</div>

								<div className='pull-left' style={{ padding: 10 }}>
									<OverlayTrigger placement='bottom' overlay={
										<Tooltip id='tooltip'>
											<strong className='font-size-16'>Share on Email</strong>
										</Tooltip>
									}>
										<EmailShareButton onClick={() => { }} openShareDialogOnClick url={"https://www.linkedin.com/shareArticle?mini=true&url=https://share.industrialnavigator.com/sharedplacelist.php?" + this.props.match.params.id} subject={'Industrial Navigator'} body={'Industrial Navigator'}>
											<i className='color-blue fa fa-2x fa-envelope cursor-pointer'></i>
										</EmailShareButton>
									</OverlayTrigger>
								</div>
							</div>
							{/* {
								document.queryCommandSupported('copy') &&
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginBottom: 20 }}>
									<button onClick={this.copyToClipboard} className='height-40 text-uppercase border-radius-25-imp btn btn-default'>Copy Shareable Link</button>
									{
										this.state.copySuccess &&
										<span style={{ color: 'green', marginLeft: 20, fontSize: 20 }}>{this.state.copySuccess}</span>
									}
								</div>
							} */}
						</Col>
						<ul className='col-lg-12 col-md-12 col-sm-12 col-xs-12 reduce-padding-0' style={{ listStyle: 'none' }}>
							{
								features.length > 0 ?
									Object.keys(features).map((key, index) => (
										<li key={index} className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
											<div className='item margin-bottom-0 pull-left' id={'listing-' + key} style={{ padding: 25, width: '100%' }}>
												<div className='col-lg-3 col-md-3 col-sm-4 col-xs-12' style={{ padding: 0 }}>
													<div className='real-estate-item-image pull-left' style={{ width: 'auto' }}>
														{/* {
                                                        features[key].properties.propertyType.includes('Industrial Sites & Properties') &&
                                                        <div className='badge badge-danger background-dark-blue font-weight-bold-imp'>{ features[key].properties.propertyFor }</div>
                                                    } */}
														<div className='reduce-img-230' >
															<img src={this.getFirstImage(features[key].properties.image)} alt='img' style={{ height: '100%', width: '100%' }} />
														</div>
														{/* {
                                                        features[key].properties.propertyType.includes('Industrial Sites & Properties') && 
                                                        <div className='real-estate-item-price font-weight-bold'>
                                                            <span className='font-size-23-imp text-uppercase'>{ this.formatPrice(features[key].properties.price) }
                                                            { features[key].properties.perUnit != '' && '/'+features[key].properties.perUnit }</span>
                                                        </div>
                                                    } */}
													</div>
												</div>
												<div style={{ paddingLeft: 0 }} className='col-lg-9 col-md-9 col-sm-8 col-xs-12'>
													<div className='real-estate-item-desc reduce-padding-0 text-left pull-left' style={{ marginTop: 0, marginLeft: 0 }}>
														<div style={{}}>
															<h3 onClick={() => this.showDetailsModal(features[key].rec_id)} className='margin-top-0 margin-bottom-20-imp pull-left word-break-all text-uppercase font-size-20-imp'>
																{features[key].properties.listingName}
															</h3>
															{/* <Link to={{ pathname: '/details-' + features[key].rec_id }} className='detail'>
																<h4 className='margin-top-0 margin-bottom-20-imp pull-left word-break-all text-uppercase reduce-fontsize-15 font-size-20-imp'>{features[key].properties.listingName.length != 0 && features[key].properties.listingName.length > 25 ?
																	features[key].properties.listingName.substring(0, 20) + '...' : features[key].properties.listingName
																}</h4>
															</Link> */}
														</div>
														{
															features[key].properties.listingLogo &&
															<div className='clear-both pull-left  margin-bottom-20' style={{ width: 150, height: 40 }}>
																<img style={{ height: '100%' }} src={features[key].properties.listingLogo} />
															</div>
														}
														{
															features[key].properties.city &&
															<div className='clear-both font-size-18 reduce-fontsize-15 margin-bottom-20'>
																{features[key].properties.city ? features[key].properties.city + ' · ' : ''}
																{features[key].properties.state}
															</div>
														}
														{
															features[key].properties.description &&
															<div className='clear-both font-size-18 reduce-fontsize-15 margin-bottom-20'>
																{
																	features[key].properties.description.split('', 200).reduce((o, c) => o.length === 199 ? `${o}${c}...` : `${o}${c}`, '')
																}
															</div>
														}
														<div className='clear-both'>
															<Link onClick={() => this.showDetailsModal(features[key].rec_id)} role='button' className='height-40 reduce-fontsize-15 text-uppercase border-radius-25-imp btn btn-default'>More Details</Link>
														</div>
													</div>
												</div>
											</div>
										</li>
									))
									:
									<h2 className='text-center'>No Favorites Found</h2>
							}
						</ul>
					</section>
					<DetailsModal show={this.state.detailsModal} handleClose={this.hideDetailsModal} >
						<div style={{ paddingTop: '1rem', paddingLeft: '0rem', paddingRight: '0rem' }}>

							<Details />
						</div>
					</DetailsModal>
				</div>
				<Footer />
			</div>
		)
	}
}

export default withRouter(Places);
const DetailsModal = ({ handleClose, show, children }) => (
	<Modal dialogClassName='quick-view-modal width-80' size='large' aria-labelledby='contained-modal-title-lg' show={show} onHide={handleClose} animation={false}>
		<Modal.Header style={{ padding: 0 }} closeButton></Modal.Header>
		<Modal.Body style={{ background: 'white', marginBottom: '0px' }}>
			<div  >
				{children}
			</div>
		</Modal.Body>
		<Modal.Footer style={{ display: 'block', padding: '10px', borderTop: '1px solid #e9ecef' }}>
			<button type='button' className='btn btn-default border-radius-25-imp' onClick={handleClose}>Close</button>
		</Modal.Footer>
	</Modal>
);